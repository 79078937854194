import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadingOutlinedIcon from "@mui/icons-material/DownloadingOutlined";
import Dialog from "@mui/material/Dialog";
import { useId, useState } from "react";
import { useDispatch } from "react-redux";
import { APP_DISPATCH, showNotification } from "../../../redux";
import axios from "../../../utils/axios";
import { BD_SHIFTS_TEMPLATE } from "../../../constants/url";
import { PRODUCT_ID } from "../../../constants";

type Props = {
	handleClose: () => void;
};

const ShiftUploadModal = ({ handleClose }: Props) => {
	const [upload] = useId();
	const dispatch = useDispatch<APP_DISPATCH>();
	const [loader, setLoader] = useState(false);
	const [errors, setErrors] = useState<any>([]);
	const [done, setDone] = useState(false);

	const onFileChange = async (event: any) => {
		const file = event.target.files ? event.target.files[0] : null;
		if (!file) return;
		if (file?.name.split(".")?.[1] != "xls" && file?.name.split(".")?.[1] != "xlsx") {
			dispatch(showNotification({ message: "File type should be .xls or .xlsx", severity: "error" }));
			return;
		}

		setLoader(true);
		const formData = new FormData();
		formData.append("imgUpload", file, file?.name);

		try {
			await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/shifts/shiftsUpload`, formData);
			setLoader(false);
			setDone(true);
			setErrors([]);
			// return { success: res?.data?.success ?? true, message: res?.data?.message || "Excel file uploaded successfully. You will receive an email once the upload is complete" };
		} catch (error: any) {
			setLoader(false);
			setDone(false);
			const errorText =
				typeof error?.response?.data?.err === "string"
					? error?.response?.data?.err
					: error?.message || "Error Uploading Shifts";
			if (Array.isArray(errorText)) {
				setErrors(errorText);
			} else {
				setErrors([errorText]);
			}
		}
	};

	return (
		<Dialog onClose={handleClose} open={true}>
			<div
				className="bulk_download-modal">
				<div className="title">
					<h3>Bulk Upload</h3>
					<button onClick={handleClose}>
						<CloseOutlinedIcon htmlColor="#8f8f8f" />
					</button>
				</div>
				{errors.length > 0 ? (
					<ul
						style={{
							maxHeight: "500px",
							overflowY: "auto",
						}}>
						{errors.map((e: any, idx: any) => (
							<li style={{ color: "red", marginBottom: "10px" }} key={idx}>
								{idx + 1}. {e}
							</li>
						))}
					</ul>
				) : done ? (
					<p>Excel file uploaded successfully. You will receive an email once the upload is complete</p>
				) : (
					<div className="action_buttons">
						{BD_SHIFTS_TEMPLATE.length > 0 && (
								<a
									href={`${BD_SHIFTS_TEMPLATE}?productid=${localStorage.getItem(PRODUCT_ID) ?? "2"}`}
									download
									rel="noreferrer"
									target="_blank"
									title="Download Template for Bulk Upload">
									<DownloadingOutlinedIcon /> Template
								</a>
							)}
						<label htmlFor={upload}>
							<input type="file" accept=".xls,.xlsx" id={upload} onChange={onFileChange} />
							{loader ? (
								<CircularProgress
									sx={{
										width: "20px !important",
										height: "20px !important",
										color: "white",
									}}
								/>
							) : (
								<CloudUploadOutlinedIcon />
							)}
							Upload
						</label>
					</div>
				)}
			</div>
		</Dialog>
	);
};

export default ShiftUploadModal;
