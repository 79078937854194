import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Dispatch, FC, SetStateAction } from "react";
import { TASK_FILTER_COLUMN, TASK_TYPE } from "../../../@types";
import IndividualFilter from "../../common/datagrid/IndividualFilter";
import { useAdminCompanySettings } from "../../useAdminEntitlements";
type COLUMN_TITLES =
	| "Check In Time"
	| "Check Out Time"
	| "Address"
	| "Description"
	| "Client"
	| "Task Type"
	| "Task Name";
type Props = {
	numFilters: number;
	setFilterOpen: Dispatch<SetStateAction<boolean>>;
	tasks: TASK_TYPE[];
	filters: Record<TASK_FILTER_COLUMN, string[]>;
	setFilters: Dispatch<SetStateAction<Record<TASK_FILTER_COLUMN, string[]>>>;
};
const FilterTasks: FC<Props> = ({ numFilters, setFilterOpen, tasks, setFilters, filters }) => {
	const resetFilters = () => {
		setFilters({
			taskDescription: [],
			checkinTime: [],
			checkoutTime: [],
			address: [],
			clientInfo: [],
			adminAssigned: [],
		});
	};
	const customTaskFlag = useAdminCompanySettings("customEntityMigrationPhase");
	// o - default
	// 1 - custom and default
	// 2 - custom
	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
		<form
			onSubmit={(e) => e.preventDefault()}
			onClick={(e) => e.stopPropagation()}
			onKeyPress={(e) => e.stopPropagation()}>
			<div className="filter__header">
				<h5>
					<FilterAltOutlinedIcon />
					Filters
					<span className="right-notification center" style={{ display: "flex", padding: 0 }}>
						{numFilters}
					</span>
				</h5>
				<button onClick={() => setFilterOpen(false)}>
					<CloseSharpIcon htmlColor="#42444A" />
				</button>
			</div>
			<div className="accordion__group">
				<IndividualFilter<COLUMN_TITLES, TASK_FILTER_COLUMN>
					filters={filters}
					name="taskDescription"
					title={customTaskFlag === 2 ? "Task Name" : "Description"}
					data={tasks.map((task) => task.taskDescription || "")}
					ids={tasks.map((task) => task.taskDescription || "")}
					setFilters={setFilters}
				/>
				<IndividualFilter<COLUMN_TITLES, TASK_FILTER_COLUMN>
					filters={filters}
					name="address"
					title="Address"
					data={tasks.map((task) => task.address || "")}
					ids={tasks.map((task) => task.address || "")}
					setFilters={setFilters}
				/>
				<IndividualFilter<COLUMN_TITLES, TASK_FILTER_COLUMN>
					filters={filters}
					name="clientInfo"
					title="Client"
					data={tasks.map((task) => task.clientInfo?.clientName || "")}
					ids={tasks.map((task) => task.clientInfo?.clientName || "")}
					setFilters={setFilters}
				/>
				<IndividualFilter<COLUMN_TITLES, TASK_FILTER_COLUMN>
					filters={filters}
					name="adminAssigned"
					title="Task Type"
					data={["Self Assigned", "Admin Assigned", "PJP Assigned"]}
					ids={["0", "1", "2"]}
					setFilters={setFilters}
				/>
			</div>
			<div className="filter__buttons">
				<button onClick={resetFilters} disabled={numFilters === 0} type="reset">
					Reset
				</button>
			</div>
		</form>
	);
};

export default FilterTasks;
