import { DataGridPro, GridColDef, GridSelectionModel, GridValueGetterParams } from "@mui/x-data-grid-pro";
import _cloneDeep from "lodash/cloneDeep";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import useFetch from "../../useFetch";
import { useDispatch, useSelector } from "react-redux";
import { EXPENSE_REQUEST_TYPE, PROFILES_DATA } from "../../../@types";
import { expenseApproveReject, markExpensePaidOut } from "../../../api/expense";
import { DATA_GRID_CUSTOMIZATION_OPTIONS, DATA_GRID_NAMES,DG_STYLES } from "../../../constants";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import ColumnModifierSidebar from "../../common/datagrid/ColumnModifierSidebar";
import CustomColumnMenu from "../../common/datagrid/CustomColumnMenu";
import RightSidebar from "../../common/RightSidebar";
import useAdminEntitlements from "../../useAdminEntitlements";
import ExpenseActionButtons from "./ExpenseActionButtons";
import { currency_map } from "../../../constants/currency_map";
import { formatTheDataFromSlice, getTheSavedColumns, modifyColumOrder, modifyColumnWidth } from "../../../utils";
import { setColumnConfigurations, setColumnSortModel, setPinnedColumns } from "../../../redux/slices/dataGridSlice";
import { formatDateTo_DDMMYYYY } from "../../../utils/validations";

type Props = {
	requests: EXPENSE_REQUEST_TYPE[];
	filteredRequests: EXPENSE_REQUEST_TYPE[];
	setRequests: Dispatch<SetStateAction<EXPENSE_REQUEST_TYPE[]>>;
	setSelectedRows: Dispatch<SetStateAction<GridSelectionModel>>;
	loading: boolean;
	setSelectedExpenseId: Dispatch<SetStateAction<string | null>>;
	refetch: () => void;
	profiles: PROFILES_DATA[];
};
export const findNextApprovalAmount = (request: EXPENSE_REQUEST_TYPE) => {
	const expenseAuthIndex = request.expenseAuth.findIndex((e) => e.actionPerformed === -1);
	let amountToBeApproved;
	//if this is not the first approver, then we need to find the reimbursement amount that the previous admin
	//has put in, we have to approve only what they have approved....
	if (expenseAuthIndex > 0) {
		amountToBeApproved = request.expenseAuth[expenseAuthIndex - 1].reimbursementAmount;
	} else {
		//if we are the first approver,then just approve what they have claimed.
		amountToBeApproved = request.amountClaimed || 0;
	}
	return amountToBeApproved;
};
const findAmountClaimedOfRequest = (request: EXPENSE_REQUEST_TYPE) => request.amountClaimed;

const findLastApprovedAmount = (request: EXPENSE_REQUEST_TYPE): number | null => {
	//find the last approved index
	const expenseAuthIndex = request.expenseAuth.reverse().findIndex((e) => e.actionPerformed === 1);
	let amountToBeApproved;
	//return the amount reimbursed for the last approved entry
	if (expenseAuthIndex > -1) {
		amountToBeApproved = request.expenseAuth[expenseAuthIndex].reimbursementAmount!;
	} else {
		amountToBeApproved = null;
	}
	return amountToBeApproved;
};

const ExpenseRequestTable: FC<Props> = ({
	requests,
	setSelectedRows,
	filteredRequests,
	loading,
	setSelectedExpenseId,
	refetch,
	profiles,
}) => {
	const [isUpdating, setIsUpdating] = useState(false);
	const [pageSize, setPageSize] = useState(20);
	const user = useSelector((state: ROOT_STATE) => state.user);
	const dispatch = useDispatch<APP_DISPATCH>();
	const [expandColumn, setExpandColumn] = useState(false);
	const userWritePermission = useAdminEntitlements("expense:expenseRequests", "write");
	const [columns, setColumns] = useState<GridColDef[]>([]);
	const { data: currencyData, loading: isCurrencyLoading } = useFetch<any>("/preferences/account");
	const currencyCode = currency_map[currencyData.data?.currencyCode]?.code || "";
	const tempColumns = useMemo(
		() => [
			{
				field: "date",
				headerName: "Expense Date",
				width: 120,
				renderCell: (params: GridValueGetterParams<any, EXPENSE_REQUEST_TYPE>) => (
					<>{formatDateTo_DDMMYYYY(params.row.date)}</>
				),
			},
			{
				field: "firstName",
				headerName: "Employee Name",
				width: 150,
				renderCell: (params: GridValueGetterParams<any, EXPENSE_REQUEST_TYPE>) => (
					<>{params.row.firstName + " " + params.row.lastName}</>
				),
			},
			{
				field: "description",
				headerName: "Description",
				width: 150,
				sortable: false,
			},
			{
				field: "categories",
				headerName: "Categories",
				width: 150,
				sortable: false,
				renderCell: (params: GridValueGetterParams<any, EXPENSE_REQUEST_TYPE>) => {
					const expenseCat =
						params.row.expenseDetails && Array.isArray(params.row.expenseDetails)
							? params.row.expenseDetails.map((exp) => (
									<div key={exp.expenseID}>
										<strong>{exp.category}: </strong>
										<span className="rupee">{exp.amountClaimed}</span>
									</div>
							  ))
							: [];
					return <div className="expense_categories">{expenseCat.map((cat) => cat)}</div>;
				},
			},
			{
				field: "amountClaimed",
				headerName: `Claimed Amount(${currencyCode})`,
				width: 150,
				renderCell: (params: GridValueGetterParams<any, EXPENSE_REQUEST_TYPE>) => {
					const amount = findAmountClaimedOfRequest(params.row);
					return <span className="rupee">{amount}</span>;
				},
			},
			{
				field: "reimbursementAmount",
				headerName: `Last Approved Amount(${currencyCode})`,
				width: 150,
				sortable: false,
				renderCell: (params: GridValueGetterParams<any, EXPENSE_REQUEST_TYPE>) => (
					<span className="rupee">
						{params.row.expenseAuth.length > 0 && (findLastApprovedAmount(params.row) || "")}
					</span>
				),
			},
			{
				field: "comment",
				headerName: "Comment",
				width: 200,
				sortable: false,
				renderCell: (params: GridValueGetterParams<any, EXPENSE_REQUEST_TYPE>) =>
					params.row.expenseAuth.length > 0 && params.row.expenseAuth.at(-1)?.comment,
			},
			{
				field: "pendingWith",
				headerName: "Pending With",
				width: 150,
				sortable: false,
				renderCell: (params: GridValueGetterParams<any, EXPENSE_REQUEST_TYPE>) =>
					typeof params.row.pendingWith?.adminName === "string" ? params.row.pendingWith?.adminName : "",
			},
			{
				field: "profileID",
				headerName: "Team",
				width: 200,
				renderCell: (params: GridValueGetterParams<any, EXPENSE_REQUEST_TYPE>) => {
					const team = profiles.find((profile) => profile.profileID === params.row.profileID);
					return team?.profileName;
				},
			},
			{
				field: "createdTs",
				headerName: "Submission Date",
				width: 150,
				renderCell: (params: GridValueGetterParams<any, EXPENSE_REQUEST_TYPE>) =>
					typeof params.row.createdTs === "string" ? formatDateTo_DDMMYYYY(params.row.createdTs) : "-",
			},
			{
				field: "lastModifiedTs",
				headerName: "Last Modified At",
				width: 150,
				renderCell: (params: GridValueGetterParams<any, EXPENSE_REQUEST_TYPE>) =>
					typeof params.row.lastModifiedTs === "string"
						? formatDateTo_DDMMYYYY(params.row.lastModifiedTs)
						: "-",
			},
			{
				field: "status",
				headerName: "Status",
				width: 130,
				renderCell: (params: GridValueGetterParams<any, EXPENSE_REQUEST_TYPE>) => {
					const status =
						params.row.status === 0
							? "Rejected"
							: params.row.status === 1
							? "Approved"
							: params.row.status === 2
							? "Paid Out"
							: "Pending";
					return <span className={`role_badge ${status}`}>{status}</span>;
				},
			},
			{
				field: "action",
				headerName: "Action",
				width: 150,
				sortable: false,
				renderCell: (params: GridValueGetterParams<any, EXPENSE_REQUEST_TYPE>) =>
					(
						<ExpenseActionButtons
							status={params.row.status}
							canAdminApprove={params.row.pendingWith?.adminID === user.adminDetails.adminID || false}
							approveRequest={(reason: string, amount?: number) =>
								approveRequest(params.row.expenseSummaryID, reason, amount)
							}
							amountClaimed={findNextApprovalAmount(params.row) || 0}
							rejectRequest={(reason: string) => rejectRequest(params.row.expenseSummaryID, reason)}
							name={params.row.firstName + " " + params.row.lastName}
							link={`/expense/expense_request/${params.row.expenseSummaryID}`}
							canAdminPayout={
								params.row.status === 1 && params.row.expenseAuth.at(-1)?.adminID === user.adminDetails.adminID
							}
							paidOutCallback={() => paidOutRequest(params.row.expenseSummaryID)}
							userWritePermission={userWritePermission}
						/>
					) 
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[requests, user.currencyCode, isCurrencyLoading, currencyData?.data, userWritePermission]
	);

	// to get the columns for the grid either from local or default columns

	useEffect(() => {
		if (!isCurrencyLoading && currencyData?.data) setColumns(tempColumns);
	}, [tempColumns, isCurrencyLoading, currencyData.data]);

	const approveRequest = async (id: string, reason: string, amount?: number) => {
		if (!id || id.length === 0 || amount === undefined || isNaN(amount)) {
			dispatch(showNotification({ message: "Enter an amount", severity: "error" }));
			return;
		}
		const updatedRequests = [...requests];
		const requestIndex = updatedRequests.findIndex((r) => r.expenseSummaryID === id);
		if (requestIndex < 0) return;
		setIsUpdating(true);
		const expenseAuth = _cloneDeep(updatedRequests[requestIndex].expenseAuth);
		//find the index of the expanse_detail with the highest timestamp
		const expenseAuthIndex = expenseAuth.findIndex((e) => e.actionPerformed === -1);
		updatedRequests[requestIndex].status = 1;
		updatedRequests[requestIndex].expenseAuth[expenseAuthIndex].comment = reason;
		updatedRequests[requestIndex].expenseAuth[expenseAuthIndex].reimbursementAmount = amount;

		const { success, message } = await expenseApproveReject([updatedRequests[requestIndex]]);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		refetch();
		setIsUpdating(false);
	};
	const rejectRequest = async (id: string, reason: string) => {
		if (!id || id.length === 0) return;
		const updatedRequests = [...requests];
		const requestIndex = updatedRequests.findIndex((r) => r.expenseSummaryID === id);
		if (requestIndex < 0) return;
		setIsUpdating(true);
		const expenseAuth = _cloneDeep(updatedRequests[requestIndex].expenseAuth);
		const expenseAuthIndex = expenseAuth.findIndex((e) => e.actionPerformed === -1);
		updatedRequests[requestIndex].status = 0;
		updatedRequests[requestIndex].expenseAuth[expenseAuthIndex].comment = reason;
		const { success, message } = await expenseApproveReject([updatedRequests[requestIndex]]);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		await refetch();
		setIsUpdating(false);
	};

	const paidOutRequest = async (id: string) => {
		if (!id || id.length === 0) return;
		const updatedRequests = [...requests];
		const requestIndex = updatedRequests.findIndex((r) => r.expenseSummaryID === id);
		if (requestIndex < 0) return;
		setIsUpdating(true);
		updatedRequests[requestIndex].status = 2;
		const { success, message } = await markExpensePaidOut([updatedRequests[requestIndex]]);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		setIsUpdating(false);
		await refetch();
	};
	const getColum = useSelector((state: any) => state.dataGrid);
	const savedColumnConf = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.expense_expense_request,
		DATA_GRID_CUSTOMIZATION_OPTIONS.columns_array
	);
	const savedColumnSortModel = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.expense_expense_request,
		DATA_GRID_CUSTOMIZATION_OPTIONS.sort_model
	);
	const savedPinnedColumns = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.expense_expense_request,
		DATA_GRID_CUSTOMIZATION_OPTIONS.pinned_column
	);
	const formattedcolumn = formatTheDataFromSlice(savedColumnConf, columns) || [];

	return (
		<div className="datagrid-table">
			<button className="filter__button" onClick={() => setExpandColumn((prev) => !prev)}>
				<img src="/images/filter.svg" />
			</button>
			<DataGridPro
				sx={DG_STYLES}
				rows={filteredRequests}
				getRowId={(row) => row.expenseSummaryID}
				columns={formattedcolumn?.length > 0 ? formattedcolumn : columns}
				sortModel={savedColumnSortModel?.length > 0 ? savedColumnSortModel : []}
				pinnedColumns={savedPinnedColumns && Object.keys(savedPinnedColumns).length > 0 ? savedPinnedColumns : {}}
				loading={loading || isUpdating}
				pageSize={pageSize}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[5, 10, 20, 50]}
				rowHeight={70}
				checkboxSelection
				disableSelectionOnClick
				onRowClick={(params) => userWritePermission && setSelectedExpenseId(params.id as string)}
				disableColumnFilter
				pagination
				onSelectionModelChange={(selectionModel) => setSelectedRows(selectionModel)}
				components={{ ColumnMenu: CustomColumnMenu }}
				onColumnOrderChange={(params) => {
					const modifiedColumn = modifyColumOrder(
						DATA_GRID_NAMES.expense_expense_request,
						params,
						formattedcolumn?.length > 0 ? formattedcolumn : columns
					);
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.expense_expense_request,
						columnArray: modifiedColumn,
					};
					dispatch(setColumnConfigurations(newColumnConfigurations));
				}}
				onSortModelChange={(params) => {
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.expense_expense_request,
						sortModel: params,
					};
					dispatch(setColumnSortModel(newColumnConfigurations));
				}}
				onColumnWidthChange={(params) => {
					const modifiedColumn = modifyColumnWidth(params, formattedcolumn?.length > 0 ? formattedcolumn : columns);
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.expense_expense_request,
						columnArray: modifiedColumn,
					};
					dispatch(setColumnConfigurations(newColumnConfigurations));
				}}
				onPinnedColumnsChange={(params) => {
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.expense_expense_request,
						pinnedColumns: params,
					};
					dispatch(setPinnedColumns(newColumnConfigurations));
				}}
			/>

			{expandColumn && (
				<RightSidebar expanded={expandColumn} setExpanded={setExpandColumn}>
					<ColumnModifierSidebar
						setExpanded={setExpandColumn}
						columns={columns}
						localString={DATA_GRID_NAMES.expense_expense_request}
					/>
				</RightSidebar>
			)}
		</div>
	);
};

export default ExpenseRequestTable;
