/* eslint-disable react/jsx-no-target-blank */
import Dialog from "@mui/material/Dialog";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { PRODUCT_ID } from "../../constants";
type Props = {
	bulkDownloadUrl?: string;
	handleClose: () => void;
};

const BulkDownloadModal = ({ bulkDownloadUrl, handleClose }: Props) => (
	<Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
		<div className="bulk_download-modal">
			<div className="title">
				<h3>Bulk Download</h3>
				<button onClick={() => handleClose()}>
					<CloseOutlinedIcon htmlColor="#8f8f8f" />
				</button>
			</div>
			<div className="action_buttons">
				{bulkDownloadUrl && bulkDownloadUrl.length > 0 && (
					<a href={`${bulkDownloadUrl}?productid=${localStorage.getItem(PRODUCT_ID) ?? "2"}`} target="_blank">
						<CloudDownloadOutlinedIcon />
						Download
					</a>
				)}
			</div>
		</div>
	</Dialog>
);

export default BulkDownloadModal;
