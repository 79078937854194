/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { FC, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";
import { useDispatch } from "react-redux";
import { APP_DISPATCH, showNotification } from "../../redux";

type Props = {
	roleID: string;
	moduleName:string;
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialog-paper": {
		maxWidth: "450px",
		height: "220px",
		padding: "16px",
	},
}));

const ReadOnlyButton: FC<Props> = ({ roleID, moduleName }) => {
	const [open, setOpen] = useState(false);
	const textToCopy = `${`https://panel.unolo.com/organization/roles/${roleID}?moduleName=${moduleName}`}`;
	const [isCopied, setIsCopied] = useState(false);
	const COPY_SUCCESS_DURATION = 1500;
	const dispatch = useDispatch<APP_DISPATCH>();
	const handleCopyClick = async () => {
		try {
			setIsCopied(true);
			const resetCopyStatus = () => {
				setIsCopied(false);
			};
			navigator.clipboard.writeText(textToCopy);
			setTimeout(resetCopyStatus, COPY_SUCCESS_DURATION);
		} catch (err) {
			console.log(err);
			dispatch(
				showNotification({
					message: `Unable to copy the link.`,
					severity: "error",
				})
			);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};
	return (
		<div style={{display:"flex", gap:"10px", flexWrap:"wrap"}}>
			{" "}
			<button className="read-only-button" style={{height:"34px"}}>
				<VisibilityIcon sx={{ fontSize: "14px" }} />
				<p className="read-only">Read Only</p>
			</button>
			{open && (
				<BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
					<div className="request-access-wrapper">
						<h6 className="title">Request Access</h6>
						<hr style={{ margin: "10px 0px 16px 0px" }} />
						<div className="description-text">
							<p className="description">
								You don't have edit access to this module. To get edit access, copy the below link and send it to your
								admin and ask them to open it.
							</p>
							<div className="copy">
								<p>{textToCopy}</p>
								<button onClick={handleCopyClick} className="read-only-button">
									{!isCopied ? (
										<ContentCopyIcon sx={{ fontSize: "small" }} />
									) : (
										<p style={{ color: "#ef9943" }}>Copied</p>
									)}
								</button>
							</div>
						</div>
					</div>
				</BootstrapDialog>
			)}
			<button onClick={()=> setOpen(true)} className="request-access-button">
				<p style={{fontFamily:"SFUIText-Regular, Helvetica, Arial, sans-serif", fontWeight:"100"}}>Request edit access</p>
			</button>
		</div>
	);
};

export default ReadOnlyButton;
