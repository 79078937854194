/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Battery0BarOutlinedIcon from "@mui/icons-material/Battery0BarOutlined";
import Battery2BarOutlinedIcon from "@mui/icons-material/Battery2BarOutlined";
import Battery4BarOutlinedIcon from "@mui/icons-material/Battery4BarOutlined";
import Battery5BarOutlinedIcon from "@mui/icons-material/Battery5BarOutlined";
import BatteryAlertOutlinedIcon from "@mui/icons-material/BatteryAlertOutlined";
import CrisisAlertOutlinedIcon from "@mui/icons-material/CrisisAlertOutlined";
import DirectionsWalkOutlinedIcon from "@mui/icons-material/DirectionsWalkOutlined";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DangerousIcon from "@mui/icons-material/Dangerous";
import SignalWifi4BarIcon from "@mui/icons-material/SignalWifi4Bar";
import SignalWifiConnectedNoInternet4Icon from "@mui/icons-material/SignalWifiConnectedNoInternet4";
import MobileScreenShareOutlinedIcon from "@mui/icons-material/MobileScreenShareOutlined";
import InfoIcon from "@mui/icons-material/Info";
import _memoize from "lodash/memoize";
import moment from "moment";
import "moment-timezone";
import { FC, ReactNode, useState } from "react";
import { EXECUTIVE_USER, TIMELINE } from "../../../@types";
import { DEFAULT_TIMEZONE, TIME_SHOW_FORMAT } from "../../../constants";
import useAdminInfo from "../../useAdminInfo";
import Tooltip from "@mui/material/Tooltip";
import { Button, IconButton } from "@mui/material";
import { uuid } from "uuidv4";
import BadAppStatusModal from "../../common/BadAppStatusModal";
import TaskDetailModal from "../../reusable/TaskDetailModal";
import LaunchIcon from "@mui/icons-material/Launch";

type PARTIAL_USER = Pick<EXECUTIVE_USER, "employeeID" | "firstName" | "lastName" | "parentAdminID" | "internalEmpID">;
export const showBatteryPct = _memoize((batteryPct: number | undefined | null) => {
	if (batteryPct === null || batteryPct === undefined || batteryPct <= 0) return <></>;
	if (isNaN(batteryPct)) {
		return (
			<button className="battery_icons" title="Battery Not Available">
				<BatteryAlertOutlinedIcon className="battery" htmlColor="#f54747" />
			</button>
		);
	}
	if (batteryPct <= 20) {
		return (
			<button className="battery_icons" title={`Battery: ${batteryPct.toFixed(2)}%`}>
				<Battery0BarOutlinedIcon className="battery" htmlColor="#f54747" />
				{batteryPct.toFixed(0)}%
			</button>
		);
	}
	// if (batteryPct < 10) {
	// 	return (
	// 		<button className="battery_icons" title={`Battery: ${batteryPct.toFixed(2)}%`}>
	// 			<Battery1BarOutlinedIcon className="battery" htmlColor="#ef9943" />
	// 			{batteryPct.toFixed(0)}%
	// 		</button>
	// 	);
	// }
	// if (batteryPct > 10 && batteryPct < 40) {
	// 	return (
	// 		<button className="battery_icons" title={`Battery: ${batteryPct.toFixed(2)}%`}>
	// 			<Battery2BarOutlinedIcon className="battery" htmlColor="#ef9943" />
	// 			{batteryPct.toFixed(0)}%
	// 		</button>
	// 	);
	// }
	// if (batteryPct >= 40 && batteryPct < 60) {
	// 	return (
	// 		<button className="battery_icons" title={`Battery: ${batteryPct.toFixed(2)}%`}>
	// 			<Battery3BarOutlinedIcon className="battery" htmlColor="#ef9943" />
	// 			{batteryPct.toFixed(0)}%
	// 		</button>
	// 	);
	// }
	if (batteryPct >= 20 && batteryPct < 50) {
		return (
			<button className="battery_icons" title={`Battery: ${batteryPct.toFixed(2)}%`}>
				<Battery2BarOutlinedIcon className="battery" htmlColor="#ef9943" />
				{batteryPct.toFixed(0)}%
			</button>
		);
	}
	if (batteryPct >= 50 && batteryPct < 80) {
		return (
			<button className="battery_icons" title={`Battery: ${batteryPct.toFixed(2)}%`}>
				<Battery4BarOutlinedIcon className="battery" htmlColor="#25a8f4" />
				{batteryPct.toFixed(0)}%
			</button>
		);
	}
	if (batteryPct >= 80) {
		return (
			<button className="battery_icons" title={`Battery: ${batteryPct.toFixed(2)}%`}>
				<Battery5BarOutlinedIcon className="battery" htmlColor="#40a636" />
				{batteryPct.toFixed(0)}%
			</button>
		);
	}
});
type Props = {
	eventTypeID: number;
	ts: number;
	eventParam: string | number | undefined;
	photoURL: string | undefined;
	skip?: number | undefined | null;
	skipReason?: string | undefined | null;
};
export const EventInsideTask: FC<Props> = ({ ts, eventTypeID, eventParam, photoURL, skip, skipReason }) => {
	const [isImageBroken, setIsImageBroken] = useState(false);
	const { tz, companyID } = useAdminInfo();
	const eventTime = moment(ts)
		.tz(tz || DEFAULT_TIMEZONE)
		.format(TIME_SHOW_FORMAT);
	if (eventTypeID === 13)
		return (
			<div className={`inline__event event`}>
				<DirectionsWalkOutlinedIcon htmlColor="#40a636" />
				<h5>User started the app first Time</h5> <time>{eventTime}</time>
			</div>
		);
	else if (eventTypeID === 8)
		return (
			<>
				<div className={`inline__event event punched_in`}>
					<CrisisAlertOutlinedIcon htmlColor="#40a636" />
					<h5>User Punched In</h5> <time>{eventTime}</time>
					<h5>{ }</h5>
					{skip == 1 && (
						<div className="odo_reason">
							<h4>Odometer Skipped</h4>
							<div className="display_reason">
								<div className="reason-label">Reason :</div>
								<div className="reason-text">{skipReason}</div>
							</div>
						</div>
					)}
					{photoURL && photoURL.length > 0 && (
						<a
							href={process.env.NEXT_PUBLIC_S3_URL! + photoURL}
							onClick={(e) => isImageBroken && e.preventDefault()}
							target="blank"
							style={{ marginLeft: 30, marginTop: 5 }}
							className="event_image_container">
							<img
								style={{
									borderRadius: 10,
									width: "50px",
									height: "70px",
									objectFit: "cover",
									objectPosition: "center",
								}}
								onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
									e.currentTarget.src = "/images/brokenImage.png";
									setIsImageBroken(true);
								}}
								alt=""
								src={process.env.NEXT_PUBLIC_S3_URL! + photoURL}
							/>
							{isImageBroken && (
								<Tooltip
									title="Image has not been uploaded from phone, please ask the user to Sync from the app."
									placement="right">
									<IconButton className="toastButton">
										<InfoIcon />
									</IconButton>
								</Tooltip>
							)}
						</a>
					)}
				</div>
			</>
		);
	else if (eventTypeID === 9)
		return (
			<div className={`inline__event event punched_out`}>
				<CrisisAlertOutlinedIcon htmlColor="#f54747" />
				<h5>User Punched Out</h5> <time>{eventTime}</time>
				{photoURL && photoURL.length > 0 && (
					<>
						<a
							href={process.env.NEXT_PUBLIC_S3_URL! + photoURL}
							onClick={(e) => isImageBroken && e.preventDefault()}
							target="blank"
							style={{ marginLeft: 30, marginTop: 5 }}
							className="event_image_container">
							<img
								style={{
									borderRadius: 10,
									width: "50px",
									height: "70px",
									objectFit: "cover",
									objectPosition: "center",
								}}
								onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
									e.currentTarget.src = "/images/brokenImage.png";
									setIsImageBroken(true);
								}}
								src={process.env.NEXT_PUBLIC_S3_URL! + photoURL}
								alt=""
							/>
							{isImageBroken && (
								<Tooltip
									title="Image has not been uploaded from phone, please ask the user to Sync from the app."
									placement="right">
									<IconButton className="toastButton">
										<InfoIcon />
									</IconButton>
								</Tooltip>
							)}
						</a>
					</>
				)}
			</div>
		);
	else if (eventTypeID === 15)
		return (
			<div className={`inline__event event `}>
				<MobileScreenShareOutlinedIcon htmlColor="#25a8f4" />
				<h5>Boot Completed</h5> <time>{eventTime}</time>
			</div>
		);
	else if (eventTypeID === 25)
		return (
			<div className={`inline__event event `}>
				<EditLocationOutlinedIcon htmlColor="#f54747" />
				<h5>GPS Spoof Detected</h5> <time>{eventTime}</time>
			</div>
		);
	else if (eventTypeID === 3)
		if (eventParam == 1)
			return (
				<div className={`inline__event event `}>
					<LocationOnOutlinedIcon htmlColor="#40a636" />
					<h5>GPS turned On</h5> <time>{eventTime}</time>
				</div>
			);
		else
			return (
				<div className={`inline__event event `}>
					<LocationOnOutlinedIcon htmlColor="#f54747" />
					<h5>GPS turned Off</h5> <time>{eventTime}</time>
				</div>
			);
	else if (eventTypeID === 14 && companyID === 14193)
		// app killed
		return (
			<div className={`inline__event event `}>
				<DangerousIcon htmlColor="#f54747" />
				<h5>App killed</h5> <time>{eventTime}</time>
			</div>
		);
	else if (eventTypeID === 35 && companyID === 14193) {
		if (eventParam == 1)
			// internet status
			return (
				<div className={`inline__event event `}>
					<SignalWifi4BarIcon htmlColor="#40a636" />
					<h5>Connected to internet</h5> <time>{eventTime}</time>
				</div>
			);
		else
			return (
				<div className={`inline__event event `}>
					<SignalWifiConnectedNoInternet4Icon htmlColor="#f54747" />
					<h5>Disconnected from internet</h5> <time>{eventTime}</time>
				</div>
			);
	} else return <></>;
};
type Props2 = {
	name: string;
	batteryLevel: number;
	address: string;
	children?: ReactNode;
};
export const HaltDisplayInfo: FC<Props2> = ({ name, address, batteryLevel, children }) => {
	const [badAppSetting, setBadAppSetting] = useState(false);
	return (
		<div className="info">
			<div className="info__header">
				<h4>
					{name}
					{name === "Missing Location Data" && (
						<Button sx={{ padding: "0px", minWidth: "0px" }} onClick={() => setBadAppSetting(!badAppSetting)}>
							{" "}
							<InfoOutlinedIcon htmlColor="grey" sx={{ rotate: "0deg !important", width: "18px" }} />{" "}
						</Button>
					)}
				</h4>
				{address !== "undefined" && showBatteryPct(batteryLevel)}
			</div>
			<address>{address !== "undefined" ? address : ""}</address>
			{children}
			{badAppSetting && <BadAppStatusModal onClose={() => setBadAppSetting(!badAppSetting)} />}
		</div>
	);
};
type PropsForm = {
	forms: NonNullable<TIMELINE["visits"][number]["forms"]>;
	selectedEmployee: PARTIAL_USER | null;
};
export const DisplayForm: FC<PropsForm> = ({ forms, selectedEmployee }) => {
	const [expandForm, setExpandForm] = useState(false);
	return (
		<div className={`accordion form ${expandForm ? "open" : ""}`}>
			<div className="accordion__header form" onClick={() => setExpandForm((e) => !e)}>
				<h5>Forms ({forms.length})</h5>
				<button
					onClick={(evt) => {
						evt.stopPropagation();
						setExpandForm((e) => !e);
					}}>
					<KeyboardArrowDownOutlinedIcon htmlColor="#696969" className="accordion_button" />
				</button>
			</div>
			{expandForm && (
				<div className="accordion__body form_body">
					<small>{forms[0].formTitle}</small>
					{forms.map((form) => (
						<ul className="form_item" key={form.formTemplateID}>
							{form.data &&
								Object.values(form.data)
									.sort((a, b) => a.seq - b.seq)
									.map((data) => (
										<>
											{data?.fieldValue ? (
												data?.fieldValue.split(",").map((x) => {
													if (data.dataType === "file" || data.dataType === "photo") {
														return (
															<a
																key={uuid()}
																href={`https://s3.ap-south-1.amazonaws.com/formdata.unolo.com/${selectedEmployee?.employeeID
																	}/${encodeURIComponent(x.trim())}`}
																download={x}
																rel="noreferrer"
																target="_blank"
																className="form_item_subsection">
																<div>
																	<h5>{data.fieldName}</h5>
																	<div>
																		<p
																			style={{
																				color: "#0f45ff",
																				textDecoration: "underline",
																				textUnderlineOffset: "4px",
																			}}>
																			{data.dataType === "file" ? "File" : "Photo"}
																		</p>
																	</div>
																</div>
															</a>
														);
													}
													if (data.dataType === "client") {
														return (
															<a
																key={uuid()}
																href={encodeURI(`https://panel.unolo.com/clients_sites/clients/${x.trim()}}`)}
																rel="noreferrer"
																target="_blank"
																className="form_item_subsection">
																<div>
																	<h5>{data.fieldName}</h5>
																	<div>
																		<p
																			style={{
																				color: "#0f45ff",
																				textDecoration: "underline",
																				textUnderlineOffset: "4px",
																			}}>
																			Client
																		</p>
																	</div>
																</div>
															</a>
														);
													} else {
														return (
															<div key={uuid()} className="form_item_subsection">
																<h5>{data.fieldName}</h5>
																<div>
																	<p style={{ color: "#000" }}>{x}</p>
																</div>
															</div>
														);
													}
												})
											) : (
												<div className="form_item_subsection" key={uuid()}>
													<h5>{data.fieldName}</h5>
													<div>
														{data.dataType === "file" || data.dataType === "photo" ? (
															<>
																<p>No Image/File is found.</p>
															</>
														) : (
															<p>Employee did not submit any answer for this question.</p>
														)}
													</div>
												</div>
											)}
										</>
									))}
						</ul>
					))}
				</div>
			)}
		</div>
	);
};

type PropsPhotos = {
	photos: NonNullable<TIMELINE["visits"][number]["evidence"]>;
	timeLineData: TIMELINE;
};

export const DisplayPhotosInsideVisit: FC<PropsPhotos> = ({ photos, timeLineData }) => {
	const [expandPhotos, setExpandPhotos] = useState(false);
	const [isImageBroken, setIsImageBroken] = useState({});
	const checkIfOdometerImage = () => {
		const data = photos.find((each) => {
			const data = timeLineData?.odoReadings?.find((eachOdometer: any) => {
				if (eachOdometer?.inPhotoID === each.photoID || eachOdometer?.outPhotoID === each.photoID) {
					return eachOdometer;
				}
			});

			if (data) return true;
		});
		if (data) return data;
		return null;
	};

	const findOdoReadings = (photoID: string) => {
		const data: any = timeLineData?.odoReadings?.find((each: any) => {
			if (each?.hasOwnProperty("inPhotoID") && each["inPhotoID"] === photoID) {
				return true;
			}
			if (each?.hasOwnProperty("outPhotoID") && each["outPhotoID"] === photoID) {
				return true;
			}
			return false;
		});
		if (data) {
			if (data.inPhotoID === photoID) return { type: "in", reading: data.inReading };
			if (data.outPhotoID === photoID) return { type: "out", reading: data.outReading };
		}
		return null;
	};

	return (
		<div className={`accordion photos ${expandPhotos ? "open" : ""}`}>
			<div className="accordion__header photos" onClick={() => setExpandPhotos((e) => !e)}>
				<h5>
					Photo {checkIfOdometerImage() && "(Odometer)"} ({photos.length})
				</h5>
				<button
					onClick={(evt) => {
						evt.stopPropagation();
						setExpandPhotos((e) => !e);
					}}>
					<KeyboardArrowDownOutlinedIcon htmlColor="#696969" className="accordion_button" />
				</button>
			</div>
			{expandPhotos && (
				<div className="accordion__body photos_body">
					{photos.length > 0 &&
						photos.map((photo) => (
							<figure key={photo.photoID}>
								<a href={process.env.NEXT_PUBLIC_S3_URL + photo.photoURL} target="blank">
									<img
										src={process.env.NEXT_PUBLIC_S3_URL + photo.photoURL}
										onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
											e.currentTarget.src = "/images/brokenImage.png";
											setIsImageBroken((prev) => ({ ...prev, [photo.photoID]: true }));
										}}
										alt=""
									/>
								</a>
								{isImageBroken[photo.photoID] && (
									<Tooltip
										title="Image has not been uploaded from phone, please ask the user to Sync from the app."
										placement="right">
										<IconButton className="toastButton" sx={{ transform: "rotate(-90deg)" }}>
											<InfoIcon />
										</IconButton>
									</Tooltip>
								)}
								<figcaption>
									<h6>{photo.description || "Unnamed Image"}</h6>
									<p>
										<span>Synced</span>
									</p>
									<p className="manual_odo_reading">
										{checkIfOdometerImage() &&
											`${findOdoReadings(photo.photoID)?.type === "in"
												? "In Reading :"
												: findOdoReadings(photo.photoID)?.type === "out"
													? "Out Reading :"
													: ""
											} ${findOdoReadings(photo.photoID)?.reading ?? 0} `}
									</p>
								</figcaption>
							</figure>
						))}
				</div>
			)}
		</div>
	);
};

type PropsTask = {
	task: ADD_TIME_STAMP_GENERIC<NonNullable<TIMELINE["visits"][number]["tasks"]>, "TASK">[0];
	timeLineData: TIMELINE;
	selectedEmployee: PARTIAL_USER | null;
};
export const TasksInsideVisit: FC<PropsTask> = ({ task, timeLineData, selectedEmployee }) => {
	const PREFIX_LINK = window.location.origin;
	const [expandTask, setExpandTask] = useState(false);
	const [isValidS3Link, setIsValidS3Link] = useState(true);
	const [openTaskModal, setOpenTaskModal] = useState(false);
	const formsInsideTask: ADD_TIME_STAMP_GENERIC<TIMELINE["visits"][number]["forms"], "FORM"> =
		task.forms && Array.isArray(task.forms) ? task.forms.map((t) => ({ ...t, type: "FORM" })) : [];
	const photosInsideTask: ADD_TIME_STAMP_GENERIC<TIMELINE["visits"][number]["evidence"], "PHOTO"> =
		task.evidence && Array.isArray(task.evidence) ? task.evidence.map((t) => ({ ...t, type: "PHOTO" })) : [];
	const photosNotInsideTask = task.photoID
		? { photoID: task.photoID, isSefie: task.isSelfie, photoURL: task.photoURL }
		: null;
	const formNotInsideTask = task.formTemplateID
		? { formTemplateID: task.formTemplateID, formDescription: task.formDescription, data: task.data }
		: null;
	const totalActions = [...formsInsideTask, ...photosInsideTask].sort((a, b) => a.timestamp - b.timestamp);
	const kindOfVisit = task.clientInfo ? "visit_with_meeting" : "visit_without_meeting";
	const { tz } = useAdminInfo();
	const handleClick = (clientName:string) => {
		// ou can add any additional click handling logic here if needed
		localStorage.setItem("clientName", clientName);
	  };
	if (photosNotInsideTask && photosNotInsideTask.isSefie === 1) return <></>;
	return (
		<div className={`task__info`}>
			<TaskDetailModal id={task.taskID || ""} openTaskModal={openTaskModal} setOpenTaskModal={setOpenTaskModal} />
			<div
				className={`accordion task ${expandTask ? "open" : ""}`}
				style={{ marginTop: task?.checkin && !task?.checkout ? "1.6rem" : "10px", position: "relative" }}>
				{/* indicator to show inprogress tasks */}
				{task?.checkin && !task?.checkout && (
					<span
						style={{
							backgroundColor: "#269B1B",
							color: "white",
							padding: "0px 10px",
							fontSize: "12px",
							position: "absolute",
							bottom: "100%",
							left: "0.5rem",
							borderTopLeftRadius: "5px",
							borderTopRightRadius: "5px",
							letterSpacing: "0.5px",
						}}>
						In Progress
					</span>
				)}
				<div className={`accordion__header ${kindOfVisit}`} onClick={() => setExpandTask((e) => !e)}>
					<h5>
						Task <span>{task.clientInfo && "(Meeting With " + task.clientInfo.clientName + ")"}</span>
					</h5>
					<button
						onClick={(evt) => {
							evt.stopPropagation();
							setExpandTask((e) => !e);
						}}>
						<KeyboardArrowDownOutlinedIcon htmlColor="#696969" className="accordion_button" />
					</button>
				</div>
				{expandTask && (
					<div className="accordion__body">
						{task?.customEntity === null ? (
							<>
								<h6>Description :</h6> <p>{task?.taskDescription}</p>
							</>
						) : (
							<>
								<h6>Task Type :</h6> <p>{task?.customEntity?.customEntityName}</p>
							</>
						)}
						<h6>Address :</h6> <p>{task?.address?.trim() || "-"}</p>
						<h6>Start Time :</h6>{" "}
						
						<p>
							{moment(task.checkin)
								.tz(tz || DEFAULT_TIMEZONE)
								.format("HH:mm")}
						</p>
						<h6>End Time :</h6>{" "}
						<p>
							{task.checkout
								? moment(task.checkout)
									.tz(tz || DEFAULT_TIMEZONE)
									.format("HH:mm")
								: "Not completed yet"}
						</p>
						{task.exitTime !== null && task.exitTime !== 0 && (
							<>
								<h6 className="exitTime_heading">Exit Time :</h6>{" "}
								<p className="exitTime_details">
									{moment(task.exitTime)
										.tz(tz || DEFAULT_TIMEZONE)
										.format("HH:mm")}{" "}
									<Tooltip
										title="What time the Employee left the client area. This can be different from task end time since the Employee may end the task after they have left the client area."
										placement="right">
										<IconButton className="toastButton">
											<InfoIcon />
										</IconButton>
									</Tooltip>
								</p>
							</>
						)}
						{kindOfVisit === "visit_with_meeting" && (
							<>
								<h6>Client/Site Name :</h6>
								<div style={{ display: "flex", gap: "5px" }}>
									<p style={{ margin: "0px" }}>{task.clientInfo?.clientName}</p>

									<a
										href={task.clientInfo?.employeeID === -2 ? `${PREFIX_LINK}/clients_sites/sites/site/${task.clientInfo.clientID}` : `${PREFIX_LINK}/clients_sites/clients/clientOverview/${task.clientInfo?.clientID}`}
										target="_blank"
										rel="noreferrer"
										style={{ display: "flex", justifyContent: "flex-start", gap: "5px" }}
										//@ts-ignore
										onClick={handleClick(task.clientInfo?.clientName)}>
										<LaunchIcon style={{ fontSize: "14px", rotate: "0deg" }} />
										
									</a>
								</div>
								<h6>Contact :</h6> <p>{task.clientInfo?.proprietorName}</p>
								<h6>Phone :</h6> <p>{task.clientInfo?.phoneNumber}</p>
							</>
						)}
						{photosNotInsideTask && photosNotInsideTask.isSefie === 0 && (
							<>
								<h6>Image :</h6>
								<a href={process.env.NEXT_PUBLIC_S3_URL! + photosNotInsideTask?.photoURL} target="blank">
									<img
										width={50}
										height={50}
										style={{ borderRadius: 10 }}
										alt={task.taskDescription ?? ""}
										src={process.env.NEXT_PUBLIC_S3_URL! + photosNotInsideTask?.photoURL}
										onError={(e: any) => {
											e.currentTarget.src = "/images/brokenImage.png";
											setIsValidS3Link(false);
										}}
									/>
									{!isValidS3Link && (
										<Tooltip
											title="Image has not been uploaded from phone, please ask the user to Sync from the app."
											placement="right">
											<IconButton className="toastButton">
												<InfoIcon />
											</IconButton>
										</Tooltip>
									)}
								</a>
							</>
						)}
						{formNotInsideTask && (
							<>
								<h6>Form :</h6>
								<ul className="form_item">
									{formNotInsideTask.data &&
										Object.values(formNotInsideTask.data)
											.sort((a, b) => a.seq - b.seq)
											.map((data) => (
												<li key={data.fieldID}>
													<h5>{data.fieldName}</h5>
													<p>{data.fieldValue}</p>
												</li>
											))}
								</ul>
							</>
						)}
						<h6>More Detail :</h6>{" "}
						<p>
							<button onClick={() => setOpenTaskModal(true)} style={{ cursor: "pointer" }}>
								<OpenInNewIcon sx={{ width: "18px", marginLeft: "6px", transform: "rotate(-90deg)" }} />
							</button>
						</p>
					</div>
				)}
			</div>
			{totalActions.length > 0 &&
				totalActions.map((action) => {
					if (action.type === "FORM")
						return <DisplayForm forms={[action]} key={action.formTemplateID} selectedEmployee={selectedEmployee} />;
					if (action.type === "PHOTO")
						return <DisplayPhotosInsideVisit key={action.photoID} photos={[action]} timeLineData={timeLineData} />;
				})}
		</div>
	);
};

type Props4 = {
	duration: number;
	startTime: string;
};
export const DisplayJourneyInfo: FC<Props4> = ({ duration, startTime }) => (
	<div className="journey_info">
		<h5>{startTime}</h5>
		<p>({convertMilliSecondsToHourAndMinute(duration)})</p>
	</div>
);

const convertMilliSecondsToHourAndMinute = (duration: number) => {
	//if a halt is less than 60 seconds, then just show it as 1 minute - otherwise it starts confusing people...
	let durationToConsider = duration;
	if (durationToConsider < 60000) {
		durationToConsider = 60000;
	}
	const hours = Math.floor(durationToConsider / (1000 * 60 * 60));
	const minutes = Math.floor((durationToConsider % (1000 * 60 * 60)) / (1000 * 60));
	return `${hours}h ${minutes}m`;
};
export type HALT_TYPE = "EVENT" | "FORM" | "PHOTO" | "TASK";
export type ADD_TIME_STAMP_GENERIC<TArray extends any[] | undefined, TType extends HALT_TYPE> = {
	[key in keyof TArray]: TArray[key] & { timestamp: number; type: TType };
};
