import axios from "../../utils/axios";
import { ATTENDANCE_SETTINGS, CUSTOM_FIELD_METADATA, INTEGRATION_INFO } from "../../@types";
import { integrationMapping } from "../../constants/integrationMapping";

axios.defaults.withCredentials = true;

type RETURN_TYPE = Promise<{ success: boolean; message: string }>;
type CUSTOM_RETURN_TYPE = Promise<{ success: boolean; message: string; data: any }>;

const updateTimeZoneSettings = async (dataDisplayTzFlag: 0 | 1, timezone: string): RETURN_TYPE => {
	try {
		const res = await axios.put(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/account/timezone`, {
			timezone,
			dataDisplayTzFlag,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Changed Timezone",
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error in Changing Timezone";
		return { success: false, message: errorText };
	}
};

const updateCurrencySettings = async (currency: string): RETURN_TYPE => {
	try {
		const res = await axios.put(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/account/currency`, {
			currency,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Changed Currency",
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error in Changing Currency";
		return { success: false, message: errorText };
	}
};

export { updateTimeZoneSettings, updateCurrencySettings };

export const updateAttendanceSettings = async (
	body: ATTENDANCE_SETTINGS,
	adminID: number,
	selectTeams?: number[]
): RETURN_TYPE => {
	try {
		const res = await axios.post(
			`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/attendance/${adminID}`,
			body, // pass the body directly
			{
				params: selectTeams,
			}
		);
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Changed Attendance Settings",
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error in Changing Attendance Settings";
		return { success: false, message: errorText };
	}
};
export const updateComplianceSettings = async (days: number): RETURN_TYPE => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/compliance`, {
			badAppSettingDays: days,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Changed Compliance Settings",
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error in Changing Compliance Settings";
		return { success: false, message: errorText };
	}
};
export const updateCompanyLevelTaskSettings = async (radius: number): RETURN_TYPE => {
	try {
		const res = await axios.put(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/task/geofenceRadius`, {
			taskGeofenceRadius: radius,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Changed Company Level Task Settings",
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error in Changing Company Level Task Settings";
		return { success: false, message: errorText };
	}
};
export const addTaskDescriptions = async (desc: string, profileID: number): RETURN_TYPE => {
	try {
		const res = await axios.post(
			`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/task/profiles/${profileID}/taskDescription`,
			{
				taskDescriptions: [desc],
			}
		);
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Added Task Description",
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error in Adding Task Description";
		return { success: false, message: errorText };
	}
};
export const updateTeamLevelTaskDDSettings = async (
	settings: any,
	profileID: number,
	selectTeams?: number[]
): RETURN_TYPE => {
	try {
		const res = await axios.put(
			`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/task/profiles/${profileID}`,
			{
				oneTaskPerClientPerDay: settings.oneTaskPerClientPerDay,
				clientMandatoryForTask: settings.clientMandatoryForTask,
				formTemplateID: settings.formTemplateID,
				fixedTaskDescriptions: settings.fixedTaskDescriptions,
				taskSelfieEnabled: settings.taskSelfieEnabled,
				// in the backend isOtpVerificationEnabledForTasks variable is expected to be isOtpVerificationEnableForTasks
				isOtpVerificationEnableForTasks: settings.isOtpVerificationEnabledForTasks,
			},
			{
				params: selectTeams,
			}
		);
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Changed Team Level Task Settings",
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error in Changing Team Level Task Settings";
		return { success: false, message: errorText };
	}
};
export const deleteTaskDescriptions = async (ids: number[], profileID: number): RETURN_TYPE => {
	try {
		const res = await axios.put(
			`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/task/profiles/${profileID}/taskDescription`,
			{
				taskDescriptionIDs: ids,
			}
		);
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Deleted Team Level Task Description",
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error in Changing Team Level Task Description";
		return { success: false, message: errorText };
	}
};

export const updateClientCompanyLevelSettings = async (vasa: number, radius: number): RETURN_TYPE => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/clients`, {
			vasaForClients: vasa,
			taskGeofenceRadius: radius,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Changed Company Level Settings",
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error in Changing Company Level Settings";
		return { success: false, message: errorText };
	}
};
export const updateTeamLevelClientsSettings = async (
	settings: any,
	profileID: number,
	selectTeams?: number[]
): RETURN_TYPE => {
	try {
		const res = await axios.post(
			`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/clients/${profileID}`,
			{
				clientsEnabled: settings.clientsEnabled,
				clientVisibleToAll: settings.clientVisibleToAll,
				clientModificationBehaviour: settings.clientModificationBehaviour,
				isOTPVerificationEnableForClient: settings.isOTPVerificationEnableForClient,
			},
			{
				params: selectTeams,
			}
		);
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Changed Team Level Clients Settings",
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error in Changing Team Level Clients Settings";
		return { success: false, message: errorText };
	}
};
export const updateTeamLevelLocationSettings = async (
	settings: any,
	profileID: number,
	selectTeams?: number[]
): RETURN_TYPE => {
	try {
		const res = await axios.put(
			`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/location-tracking/profiles/${profileID}`,
			{
				updateFrequency: settings.updateFrequency,
				showDistance: settings.showDistance,
				updateFrequencySec: settings.updateFrequencySec,
				distanceBetweenTasksOnly: settings.distanceBetweenTasksOnly,
			},
			{
				params: selectTeams,
			}
		);
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Changed Team Level Location tracking Settings",
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error in Changing Team Level Location tracking Settings";
		return { success: false, message: errorText };
	}
};
export const upsertCustomField = async (field: CUSTOM_FIELD_METADATA): RETURN_TYPE => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/upsertEntityMetadata`, {
			fieldName: field.fieldName.trim(),
			isRequired: field.isRequired,
			dataType: field.dataType,
			entity: field.entity,
			active: field.active,
			fieldID: field.fieldID,
			options: field.options,
			primaryField: field.primaryField,
			isDeleted: field.isDeleted ?? 0,
			write: field.write ?? 1,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Updated Custom Fields`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Updating Custom FIelds`;
		return { success: false, message: errorText };
	}
};

export const upsertTeamLevelOrderSettings = async (
	emails: string[],
	isOtpVerificationEnabledForOrders: 0 | 1,
	showOrdersCollectionDetails: 0 | 1,
	discountEnabledOrders: 0 | 1,
	showOrdersRecipients: 0 | 1,
	profileID: number,
	selectTeams?: number[]
): RETURN_TYPE => {
	try {
		const res = await axios.post(
			`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/orders/${profileID}`,
			{
				defaultEmailRecipients: emails,
				isOtpVerificationEnabledForOrders: isOtpVerificationEnabledForOrders,
				showOrdersCollectionDetails: showOrdersCollectionDetails,
				discountEnabledOrders: discountEnabledOrders,
				showOrdersRecipients: showOrdersRecipients,
				profileID: profileID,
			},
			{
				params: selectTeams,
			}
		);
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Updated Order Settings`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Updating Order Settings`;
		return { success: false, message: errorText };
	}
};
export const changeModuleSettings = async (featureID: number, enable: 0 | 1): RETURN_TYPE => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/modules`, {
			featureID,
			enable,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Updated Module Settings`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Updating Module Settings`;
		return { success: false, message: errorText };
	}
};
export const enableIntegration = async (entityName: string, integrationID: number, status: 0 | 1): RETURN_TYPE => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/setIntegrationState`, {
			entityName,
			integrationID,
			status,
		});
		return {
			success: res?.data?.success ?? true,
			message:
				res?.data?.message ||
				`${integrationMapping[integrationID]?.name} ${entityName} Integration ${status == 1 ? "Enabled" : "Disabled"}`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Updating ${integrationMapping[integrationID]?.name} Integration`;
		return { success: false, message: errorText };
	}
};
export const upsertIntegration = async (
	integrationID: number,
	keyValues: INTEGRATION_INFO["keyValues"]
): RETURN_TYPE => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/upsertIntegration`, {
			keyValues,
			integrationID,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `${integrationMapping[integrationID]?.name} Credentials Saved`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Saving ${integrationMapping[integrationID]?.name} Credentials`;
		return { success: false, message: errorText };
	}
};

export const addSiteTypes = async <T>(siteTypes: T[]): RETURN_TYPE => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/sites`, {
			siteTypes,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Added New Site Types`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Adding New Site Types`;
		return { success: false, message: errorText };
	}
};
export const deleteSiteTypes = async <T>(siteTypeIDs: T[]): RETURN_TYPE => {
	try {
		const res = await axios.put(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/sites`, {
			siteTypeIDs,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Deleted Site Types`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Deleting New Site Types`;
		return { success: false, message: errorText };
	}
};

export const upsertExpenseApproval = async (
	usesAdminHierarchy: number,
	approverOne: number | null,
	approverTwo: number | null,
	approverThree: number | null,
	backUpApprover1: number | null,
	backUpApprover2: number | null,
	restrictedAdminCount: number | null,
	expenseQuestionOne: string | null,
	expenseQuestionTwo: string | null
): RETURN_TYPE => {
	try {
		const res = await axios.put(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/expenses`, {
			usesAdminHierarchy,
			restrictedAdminCount,
			approvers: { approverOne, approverTwo, approverThree },
			backUpApprovers: { backUpApprover1, backUpApprover2 },
			expenseQuestionOne,
			expenseQuestionTwo,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Updated Expense Approval Method`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Updating Expense Approval Method`;
		return { success: false, message: errorText };
	}
};
export const upsertLeaveApproval = async (
	usesAdminHierarchy: number,
	approverOne: number | null,
	approverTwo: number | null,
	approverThree: number | null,
	backUpApprover1: number | null,
	backUpApprover2: number | null,
	restrictedAdminCount: number | null
): RETURN_TYPE => {
	try {
		const res = await axios.put(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/changeApprovalOptions`, {
			usesAdminHierarchy,
			restrictedAdminCount,
			approvers: { approverOne, approverTwo, approverThree },
			backUpApprovers: { backUpApprover1, backUpApprover2 },
			entityType: "LEAVE",
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Updated Leave Approval Method`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Updating Leave Approval Method`;
		return { success: false, message: errorText };
	}
};
export const upsertAttendanceApproval = async (
	usesAdminHierarchy: number,
	approverOne: number | null,
	approverTwo: number | null,
	approverThree: number | null,
	backUpApprover1: number | null,
	backUpApprover2: number | null,
	restrictedAdminCount: number | null
): RETURN_TYPE => {
	try {
		const res = await axios.put(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/changeApprovalOptions`, {
			usesAdminHierarchy,
			restrictedAdminCount,
			approvers: { approverOne, approverTwo, approverThree },
			backUpApprovers: { backUpApprover1, backUpApprover2 },
			entityType: "ATTENDANCE_OVERRIDE",
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Updated Leave Approval Method`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Updating Leave Approval Method`;
		return { success: false, message: errorText };
	}
};
type CATEGORY = {
	category: string;
	expenseCategoryID: string;
	deleted?: boolean;
};
export const addExpenseCategory = async (categories: CATEGORY[]): RETURN_TYPE => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/expenses/expenseCategory`, {
			categories,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Added Expense Category`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Adding Expense Category`;
		return { success: false, message: errorText };
	}
};
export const deleteExpenseCategory = async (categories: CATEGORY[]): RETURN_TYPE => {
	try {
		const res = await axios.put(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/expenses/expenseCategory`, {
			categories,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Deleted Expense Restriction`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Deleting Expense Restriction`;
		return { success: false, message: errorText };
	}
};
export const updateExpenseRestrictions = async (
	expenseRestrictionModel: number,
	expenseRestrictionDays: number,
	profileID: number,
	selectTeams?: number[]
): RETURN_TYPE => {
	try {
		const res = await axios.put(
			`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/expenses/profiles/${profileID}`,
			{
				expenseRestrictionModel,
				expenseRestrictionDays,
			},
			{
				params: selectTeams,
			}
		);
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Updated Expense Restriction`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Updating Expense Restriction`;
		return { success: false, message: errorText };
	}
};

/**
 * expense limit
 */

export const addExpenseLimit = async (expenseLimits: any): RETURN_TYPE => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/expenses/expenseLimits`, {
			...expenseLimits,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Added Expense Limit`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Adding Expense Limit`;
		return { success: false, message: errorText };
	}
};

/**
 * conveyance limit
 */

export const addConveyanceDistOrAmouLimit = async (conveyanceLimits: any): RETURN_TYPE => {
	try {
		const res = await axios.post(
			`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/expenses/conveyanceLimits`,
			{
				...conveyanceLimits,
			}
		);
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Added Conveyance Limit`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Adding Conveyance Limit`;
		return { success: false, message: errorText };
	}
};

export const updateConveyanceDistOrAmouFlag = async (conveyanceFlag: any): RETURN_TYPE => {
	try {
		const res = await axios.post(
			`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/expenses/setConveyanceLimitFlag`,
			{
				...conveyanceFlag,
			}
		);
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Conveyance Limit Type Changed`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in changing conveyance limit`;
		return { success: false, message: errorText };
	}
};

export const addConveyanceLimit = async (conveyanceLimits: any): RETURN_TYPE => {
	try {
		const res = await axios.post(
			`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/expenses/conveyanceSettings`,
			{
				...conveyanceLimits,
			}
		);
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Added Conveyance Limit`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Adding Conveyance Limit`;
		return { success: false, message: errorText };
	}
};

export async function updateOdoSettings(odoSetting: any, profileID: number, selectTeams?: number[]): RETURN_TYPE {
	try {
		const res = await axios.put(
			`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/odo/${profileID}`,
			{
				allowOdometerSkip: odoSetting.allowOdometerSkip,
				odometerEnabled: odoSetting.odometerEnabled,
				autoConveyanceEnabled: odoSetting.autoConveyanceEnabled,
			},
			{
				params: selectTeams,
			}
		);
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Updated Odometer Settings`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Updating Odometer Settings`;
		return { success: false, message: errorText };
	}
}

export async function upsertCrmIntegration(crmIntegration: any): RETURN_TYPE {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/upsertCrmIntegrationInfo`, {
			...crmIntegration,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Updated CRM Integration`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Updating CRM Integration`;
		return { success: false, message: errorText };
	}
}

export async function getUnoloFieldsMetadata(option: any): CUSTOM_RETURN_TYPE {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/getUnoloFieldsMetadata`, {
			...option,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Updated CRM Integration`,
			data: res?.data?.fieldsMetadata,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Updating CRM Integration`;
		return { success: false, message: errorText, data: {} };
	}
}

export async function getzohoFieldsMetadata(option: any): CUSTOM_RETURN_TYPE {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/getzohoFieldsMetadata`, {
			...option,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Updated CRM Integration`,
			data: res?.data?.fieldsMetadata,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Updating CRM Integration`;
		return { success: false, message: errorText, data: {} };
	}
}

export async function fetchCrmFieldMapping(): CUSTOM_RETURN_TYPE {
	try {
		const res = await axios.get(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/fetchCrmFieldMapping`);
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Updated CRM Integration`,
			data: res?.data?.crmFieldMapping,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Updating CRM Integration`;
		return { success: false, message: errorText, data: [] };
	}
}

export async function upsertCrmFieldMapping(option: any): RETURN_TYPE {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/upsertCrmFieldMapping`, {
			...option,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Updated CRM Integration`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in Updating CRM Integration`;
		return { success: false, message: errorText };
	}
}
