import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import { DESIGNATIONS_DATA, EXPENSE_SETTING, PROFILES_DATA } from "../../../@types";
import { addConveyanceLimit } from "../../../api/settings";
import { TABLE_STYLE } from "../../../constants";
import { showNotification } from "../../../redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialog-paper": {
		minWidth: "650px",
		padding: theme.spacing(2),
	},
}));

type ROWS_DATA = {
	limitAgainst: null | number;
	carRate: null | number;
	bikeRate: null | number;
	conveyanceLimitArray: EXPENSE_SETTING["conveyanceLimits"];
};

type Props = {
	handleClose: () => void;
	profiles: PROFILES_DATA[];
	designations: DESIGNATIONS_DATA[];
	setConveyanceLimits: Dispatch<SetStateAction<EXPENSE_SETTING["conveyanceLimits"]>>;
	row: ROWS_DATA;
};

const ConveyanceModal: FC<Props> = ({ handleClose, profiles, designations, setConveyanceLimits, row }) => {
	const dispatch = useDispatch();
	const [loader, setLoader] = useState<boolean>(false);
	const [selectedRow, setSelectedRow] = useState(row);

	const changeLimits = (data: any) => {
		let changedData = { ...selectedRow };
		if (data.limitAgainst || data.limitAgainst === 0) {
			changedData = { ...changedData, limitAgainst: data.limitAgainst };
			const tempConveyanceLimits: EXPENSE_SETTING["conveyanceLimits"] = [];
			if (data.limitAgainst === 0) {
				tempConveyanceLimits.push({
					designationID: null,
					profileID: null,
					carRate: 10,
					bikeRate: 6,
				});
				changedData = {
					...changedData,
					carRate: 10,
					bikeRate: 6,
				};
			} else if (data.limitAgainst === 1) {
				profiles.forEach((profile) => {
					tempConveyanceLimits.push({
						designationID: null,
						profileID: profile.profileID,
						carRate: 10,
						bikeRate: 6,
					});
				});
				changedData = {
					...changedData,
					carRate: null,
					bikeRate: null,
				};
			} else {
				designations.forEach((designation) => {
					tempConveyanceLimits.push({
						designationID: designation.designationID,
						profileID: null,
						carRate: 10,
						bikeRate: 6,
					});
				});
				changedData = {
					...changedData,
					carRate: null,
					bikeRate: null,
				};
			}

			changedData = {
				...changedData,
				limitAgainst: data.limitAgainst,
				conveyanceLimitArray: tempConveyanceLimits,
			};
		} else if (data.profileID) {
			let limitData = selectedRow.conveyanceLimitArray;
			let isPresent = false;
			limitData = limitData.map((limit) => {
				if (limit.profileID === data.profileID) {
					isPresent = true;
					if (data.carRate || data.carRate === 0) {
						return { ...limit, carRate: data.carRate };
					} else {
						return { ...limit, bikeRate: data.bikeRate };
					}
				}
				return { ...limit };
			});
			if (!isPresent) {
				if (data.carRate || data.carRate === 0) {
					limitData.push({
						profileID: data.profileID,
						designationID: null,
						carRate: data.carRate,
						bikeRate: null,
					});
				} else {
					limitData.push({
						profileID: data.profileID,
						designationID: null,
						carRate: null,
						bikeRate: data.bikeRate,
					});
				}
			}
			changedData = { ...changedData, conveyanceLimitArray: limitData };
		} else if (data.designationID) {
			let limitData = selectedRow.conveyanceLimitArray;
			let isPresent = false;
			limitData = limitData.map((limit) => {
				if (limit.designationID === data.designationID) {
					isPresent = true;
					if (data.carRate || data.carRate === 0) {
						return { ...limit, carRate: data.carRate };
					} else {
						return { ...limit, bikeRate: data.bikeRate };
					}
				}
				return { ...limit };
			});

			if (!isPresent) {
				if (data.carRate || data.carRate === 0) {
					limitData.push({
						designationID: data.designationID,
						profileID: null,
						carRate: data.carRate,
						bikeRate: null,
					});
				} else {
					limitData.push({
						designationID: data.designationID,
						profileID: null,
						carRate: null,
						bikeRate: data.bikeRate,
					});
				}
			}
			changedData = { ...changedData, conveyanceLimitArray: limitData };
		} else if (data.carRate || data.carRate === 0 || data.bikeRate || data.bikeRate === 0) {
			let limitData = selectedRow.conveyanceLimitArray;
			limitData = limitData.map((limit) => {
				if (data.carRate || data.carRate === 0) {
					return { ...limit, carRate: data.carRate };
				} else {
					return { ...limit, bikeRate: data.bikeRate };
				}
			});
			changedData = { ...changedData, conveyanceLimitArray: limitData };
		}
		setSelectedRow({ ...changedData });
	};

	const saveConveyanceLimit = async () => {
		const dataTosend = {};
		const allLimitArray = selectedRow.conveyanceLimitArray.map((d) => {
			delete d.conveyanaceLimitID;
			return d;
		});

		dataTosend["conveyanceLimitData"] = allLimitArray;
		setLoader(true);
		const { success, message } = await addConveyanceLimit(dataTosend);
		setLoader(false);
		if (success) {
			setConveyanceLimits([...selectedRow.conveyanceLimitArray]);
			handleClose();
		}
		dispatch(showNotification({ message: message, severity: success ? "success" : "error" }));
	};

	return (
		<BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
			<div className="expense_limit_modal_body">
				<div className="modal_header">
					<span>Conveyance limit</span>
					<CloseIcon onClick={handleClose} />
				</div>
				<div className="divider"></div>
				<div className="modal_mid">
					<div className="boxes against">
						<p>Against:</p>
						<select
							onChange={(e) => changeLimits({ limitAgainst: +e.target.value })}
							value={selectedRow.limitAgainst ?? 0}>
							<option value={0}>Default</option>
							<option value={1}>Team</option>
							<option value={2}>Designation</option>
						</select>
					</div>
				</div>
				<div className="modal_table">
					<TableContainer component={Paper} sx={{ height: "400px" }}>
						<Table sx={{ ...TABLE_STYLE, maxHeight: "200px" }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>
										{selectedRow.limitAgainst ? (selectedRow.limitAgainst == 1 ? "Team" : "Designation") : ""}
									</TableCell>
									<TableCell>Car Rate(₹)</TableCell>
									<TableCell>Bike Rate(₹)</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{selectedRow.limitAgainst ? (
									selectedRow.limitAgainst == 1 ? (
										profiles.map((profile) => (
											<TableRow key={profile.profileID}>
												<TableCell>{profile.profileName}</TableCell>
												<TableCell>
													<input
														type="number"
														value={selectedRow.conveyanceLimitArray
															.find((d) => d.profileID === profile.profileID)
															?.carRate?.toString()}
														onChange={(e) => changeLimits({ profileID: profile.profileID, carRate: +e.target.value })}
														required
													/>
												</TableCell>
												<TableCell>
													<input
														type="number"
														value={selectedRow.conveyanceLimitArray
															.find((d) => d.profileID === profile.profileID)
															?.bikeRate?.toString()}
														onChange={(e) => changeLimits({ profileID: profile.profileID, bikeRate: +e.target.value })}
														required
													/>
												</TableCell>
											</TableRow>
										))
									) : (
										designations.map((designation) => (
											<TableRow key={designation.designationID}>
												<TableCell>{designation.name}</TableCell>
												<TableCell>
													<input
														type="number"
														value={selectedRow.conveyanceLimitArray
															.find((d) => d.designationID === designation.designationID)
															?.carRate?.toString()}
														onChange={(e) =>
															changeLimits({ designationID: designation.designationID, carRate: +e.target.value })
														}
														required
													/>
												</TableCell>
												<TableCell>
													<input
														type="number"
														value={selectedRow.conveyanceLimitArray
															.find((d) => d.designationID === designation.designationID)
															?.bikeRate?.toString()}
														onChange={(e) =>
															changeLimits({ designationID: designation.designationID, bikeRate: +e.target.value })
														}
														required
													/>
												</TableCell>
											</TableRow>
										))
									)
								) : (
									<TableRow key={"company"}>
										<TableCell>{"All"}</TableCell>
										<TableCell>
											<input
												value={selectedRow.conveyanceLimitArray[0]["carRate"]?.toString()}
												type="number"
												onChange={(e) => changeLimits({ carRate: +e.target.value })}
											/>
										</TableCell>
										<TableCell>
											<input
												value={selectedRow.conveyanceLimitArray[0]["bikeRate"]?.toString()}
												type="number"
												onChange={(e) => changeLimits({ bikeRate: +e.target.value })}
											/>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</div>
			<DialogActions
				sx={{
					justifyContent: "flex-end",
				}}>
				<Button
					variant="contained"
					onClick={saveConveyanceLimit}
					color="primary"
					sx={{
						marginTop: "10px",
						borderRadius: "8px",
					}}>
					{!loader ? "Save" : <CircularProgress size={20} sx={{ color: "white" }} />}
				</Button>
			</DialogActions>
		</BootstrapDialog>
	);
};

export default ConveyanceModal;
