export const integrationMapping = {
    1: {
        name:"GreytHR",
        databaseName:"greyThr"
    },
    2: {
        name:"Clients Webhooks",
        databaseName:"clientsWebhooks"
    },
    3: {
        name:"Attendance Webhooks",
        databaseName:"attendanceWebhooks"
    },
    4: {
        name:"Tasks Webhooks",
        databaseName:"tasksWebhooks"
    },
    5: {
        name:"Zoho CRM",
        databaseName:"zohoCRMForClients"
    },
    6: {
        name:"Forms Webhooks",
        databaseName:"formsWebhooks"
    },
    7: {
        name:"Photos Web Hooks",
        databaseName:"photosWebhooks"
    },
    8: {
        name:"Zoho People",
        databaseName:"zohoPeople"
    },
    9: {
        name:"HR One",
        databaseName:"hrOneAttendanceSwipe"
    }
};

export const customWebhooks = [
	{
		integrationID: 2,
		integrationName: "clientsWebhooks",
		status: 0,
		keyValues: [
			{
				keyTypeID: 3,
				keyValue: "",
				keyName: "URL",
			},
			{
				keyTypeID: 5,
				keyValue: "",
				keyName: "API_KEY_HEADER",
			},
			{
				keyTypeID: 4,
				keyValue: "",
				keyName: "API_KEY",
			},
		],
		entityName: "clients",
	},
	{
		integrationID: 3,
		integrationName: "attendanceWebhooks",
		status: 0,
		keyValues: [
			{
				keyTypeID: 3,
				keyValue: "",
				keyName: "URL",
			},
			{
				keyTypeID: 5,
				keyValue: "",
				keyName: "API_KEY_HEADER",
			},
			{
				keyTypeID: 4,
				keyValue: "",
				keyName: "API_KEY",
			},
		],
		entityName: "attendance",
	},
	{
		integrationID: 4,
		integrationName: "tasksWebhooks",
		status: 0,
		keyValues: [
			{
				keyTypeID: 3,
				keyValue: "",
				keyName: "URL",
			},
			{
				keyTypeID: 5,
				keyValue: "",
				keyName: "API_KEY_HEADER",
			},
			{
				keyTypeID: 4,
				keyValue: "",
				keyName: "API_KEY",
			},
		],
		entityName: "tasks",
	},
	{
		integrationID: 6,
		integrationName: "formsWebhooks",
		status: 0,
		keyValues: [
			{
				keyTypeID: 3,
				keyValue: "",
				keyName: "URL",
			},
			{
				keyTypeID: 5,
				keyValue: "",
				keyName: "API_KEY_HEADER",
			},
			{
				keyTypeID: 4,
				keyValue: "",
				keyName: "API_KEY",
			},
		],
		entityName: "forms",
	},
	{
		integrationID: 7,
		integrationName: "photosWebhooks",
		status: 0,
		keyValues: [
			{
				keyTypeID: 3,
				keyValue: "",
				keyName: "URL",
			},
			{
				keyTypeID: 5,
				keyValue: "",
				keyName: "API_KEY_HEADER",
			},
			{
				keyTypeID: 4,
				keyValue: "",
				keyName: "API_KEY",
			},
		],
		entityName: "photo",
	},
];