import { CircularProgress } from "@mui/material";
import _debounce from "lodash/debounce";
import { FC, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { updateComplianceSettings } from "../../../api/settings";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import useAdminEntitlements from "../../useAdminEntitlements";
import useFetch from "../../useFetch";
import ReadOnlyButton from "../../common/ReadOnlyButton";
import { useSelector } from "react-redux";

const ComplianceSettings: FC = () => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const { data, loading } = useFetch<number>("/preferences/compliance");
	const userWritePermission = useAdminEntitlements("settings:compliance", "write");
	const { roleID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);
	useEffect(() => {
		if (!loading && data?.data) {
			const inputElement = document.querySelectorAll(".module-settings input")[0] as HTMLInputElement;
			if (inputElement) inputElement.value = `${data.data}`;
		}
	}, [data?.data, loading]);

	const debouncedChanges = useMemo(
		() =>
			_debounce(async function (e) {
				if (e.target.value === "") {
					e.target.value = 0;
				}
				else if (e.target.value.startsWith("0") && e.target.value.length > 1) {
					e.target.value = e.target.value.substring(1);
				}
				const { success, message } = await updateComplianceSettings(e.target.value);
				dispatch(showNotification({ message, severity: success ? "success" : "error" }));
			}, 500),
		[dispatch]
	);
	useEffect(() => () => debouncedChanges.cancel());
	if (loading) {
		return (
			<div className="small_circular-spinner">
				<CircularProgress />
			</div>
		);
	}
	return (
		<div className="module-settings">
			<div className="section">
				<div style={{display:"flex", gap:"10px", alignItems:"center", marginBottom:"8px"}}><p className="title" style={{marginBottom:"0px"}}>Bad settings limit</p>
				{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="settings"/> : null}</div>
				<p className="subtitle">
					The number of consecutive days allowed for bad app settings before making watching video and uploading
					settings screenshot mandatory
				</p>
				<input
					disabled={!userWritePermission}
					type="number"
					name="badSettingsLimit"
					min={0}
					defaultValue={0}
					onChange={debouncedChanges}
				/>
			</div>
		</div>
	);
};
export default ComplianceSettings;
