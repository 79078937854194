import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";
import { FC, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import { APP_DISPATCH, showNotification } from "../../redux";
import { useDispatch } from "react-redux";
type Props = {
	roleID?: string;
	moduleName?: string;
};
const NotAuthorized: FC<Props> = ({ roleID, moduleName }) => {
	const [isCopied, setIsCopied] = useState(false);
	const textToCopy = `${`https://panel.unolo.com/organization/roles/${roleID}?moduleName=${moduleName}`}`;
	const COPY_SUCCESS_DURATION = 1500;
	const dispatch = useDispatch<APP_DISPATCH>();
	const handleCopyClick = () => {
		try {
			setIsCopied(true);
			const resetCopyStatus = () => {
				setIsCopied(false);
			};
			navigator.clipboard.writeText(textToCopy);
			setTimeout(resetCopyStatus, COPY_SUCCESS_DURATION);
		} catch (err) {
			console.log(err);
			dispatch(
				showNotification({
					message: `Unable to copy the link.`,
					severity: "error",
				})
			);
		}
	};

	return (
		<section className="support datagrid-common-style ">
			<div>
				<NotInterestedOutlinedIcon htmlColor="#f54747" />
				<h2 style={{ marginBottom: "10px" }}>Contact your admin for access.</h2>
				{roleID ? (
					<>
						<p style={{ fontSize: "14px", fontWeight: 600 }}>
							You don't have access to this module. To get access, copy the link below and send it your admin and ask
							them to open it.
						</p>
						<div className="description">
							<p>{textToCopy}</p>
							<button className="read-only-button" onClick={handleCopyClick}>
								{!isCopied ? (
									<>
										<p className="read-only">Copy</p>
										<ContentCopyIcon sx={{ fontSize: "small", height: "15px !important", width: "15px !important" }} />
									</>
								) : (
									<>
										<p style={{ color: "#ef9943" }}>Copied</p>
										<DoneIcon sx={{ fontSize: "small", height: "15px !important", width: "15px !important" }} />
									</>
								)}
							</button>
						</div>
					</>
				) : (
					<p>You don't have read access to this module.</p>
				)}
			</div>
		</section>
	);
};

export default NotAuthorized;
