import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import debounce from "lodash/debounce";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PROFILES_DATA } from "../../../@types";
import { updateExpenseRestrictions } from "../../../api/settings";
import { DD_STYLES } from "../../../constants";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { sortByString } from "../../../utils";
import useAdminEntitlements from "../../useAdminEntitlements";
import useFetch from "../../useFetch";
import ReadOnlyButton from "../../common/ReadOnlyButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TeamSelector from "../../common/TeamSelector";

const renderSelectValue = (value: null | string) => (
	<div>
		Assigning To: <span style={{ marginLeft: "8px", fontWeight: "normal" }}>{value}</span>
	</div>
);
type FETCH = {
	expenseRestrictionDays: number;
	expenseRestrictionModel: 0 | 1 | 2;
};
type Props = {
	roleID: string;
}
let once = true;
const ExpenseRestrictions: FC<Props> = ({ roleID }) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const [selectedProfileId, setSelectedProfileId] = useState<undefined | number>(undefined);
	const { data: profileData, loading: profileLoading } = useFetch<PROFILES_DATA[]>("/users/profiles");
	const [currentSetting, setCurrentSetting] = useState<null | FETCH>(null);
	const selectedProfile = profileData?.data?.find((setting) => setting.profileID === selectedProfileId) || null;
	const userWritePermission = useAdminEntitlements("settings:expense", "write");
	const { data, loading, refetch } = useFetch<FETCH>(`/preferences/expenses/profiles/${selectedProfileId}`);
	const allTeams = profileData?.data || [];

	const isLoading = profileLoading || loading;
	const [expanded, setExpanded] = useState(false)

	useEffect(() => {
		if (!profileLoading && profileData?.data && once) {
			setSelectedProfileId(profileData.data[0].profileID);
			const defaultProfileId =
				profileData.data.find((setting) => setting.profileName === ("default" || "Default"))?.profileID ||
				profileData.data[0].profileID;
			setSelectedProfileId(defaultProfileId);
			once = false;
		}
	}, [profileLoading, profileData?.data]);

	useEffect(() => {
		if (!loading && data?.data) {
			setCurrentSetting(data.data);
			const number = document.getElementById("expenseRestrictionDays") as HTMLInputElement;
			if (data?.data?.expenseRestrictionModel !== 0 && number) {
				number.value = `${data?.data?.expenseRestrictionDays}`;
			}
		}
	}, [data?.data, loading]);

	useEffect(() => {
		if (!profileLoading && profileData?.data) {
			setSelectedProfileId(profileData.data[0].profileID);
			const defaultProfileId =
				profileData.data.find((setting) => setting.profileName === ("default" || "Default"))?.profileID ||
				profileData.data[0].profileID;
			setSelectedProfileId(defaultProfileId);
		}
	}, [profileLoading]);

	const handleChange = async (key: keyof FETCH, value: FETCH[keyof FETCH], selectTeams?: number[]) => {
		const updateSetting = { ...currentSetting! };
		updateSetting[key] = value as 0 | 1 | 2;
		const { expenseRestrictionModel, expenseRestrictionDays } = updateSetting;

		if (expenseRestrictionDays && expenseRestrictionModel === 2 && (expenseRestrictionDays > 28 || expenseRestrictionDays < 1)) {
			dispatch(showNotification({ severity: "error", message: "Please Select Days between 1 and 28" }));
			setCurrentSetting(updateSetting);
			return;
		}
		if (expenseRestrictionDays && expenseRestrictionModel === 1 && (expenseRestrictionDays > 50 || expenseRestrictionDays < 1)) {
			dispatch(showNotification({ severity: "error", message: "Please Select Days between 1 and 50" }));
			setCurrentSetting(updateSetting);
			return;
		}

		const { success, message } = await updateExpenseRestrictions(
			updateSetting.expenseRestrictionModel,
			updateSetting.expenseRestrictionDays,
			selectedProfileId!,
			selectTeams??[]
		);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		refetch();
	};
	useEffect(() => {
		const number = document.getElementById("expenseRestrictionDays") as HTMLInputElement;
		if (currentSetting && currentSetting?.expenseRestrictionModel !== 0 && number) {
			number.value = `${currentSetting.expenseRestrictionDays}`;
		}
	}, [currentSetting, currentSetting?.expenseRestrictionModel]);

	const handleChangeProfile = (id: number) => {
		setSelectedProfileId(id);
		const number = document.getElementById("expenseRestrictionDays") as HTMLInputElement;
		if (currentSetting && currentSetting?.expenseRestrictionModel !== 0 && number) {
			number.value = `${currentSetting.expenseRestrictionDays}`;
		}
	};
	const debouncedChanges = debounce(async function (value: number) {
		handleChange("expenseRestrictionDays", value);
	}, 500);

	if (profileLoading) {
		return (
			<div className="small_circular-spinner">
				<CircularProgress />
			</div>
		);
	}
	return (
		<div className="task-settings">
			<div className="team-level-task-settings expense-restrictions">
			{expanded && <TeamSelector expanded={expanded} setExpanded={setExpanded} handleChange={handleChange}/> }
				<div className="team-header">
					<div className="team-level-header">
						<h3 style={{ display: "flex", gap: 10, alignItems: "center" }}>
							<PeopleAltOutlinedIcon />
							<p className="title">Team Level Settings</p>
							{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="settings" /> : null}
						</h3>
					</div>
					<button
						className="add mui-button"
						onClick={() => setExpanded(!expanded)}
						disabled={
							(!userWritePermission)
						}>
						<ContentCopyIcon sx={{ marginRight: "5px" }}/> 
						Copy Team Settings
					</button>
				</div>
				<div className="horizontal-line"></div>
				<Select
					onChange={(e) => handleChangeProfile(+e.target.value!)}
					disabled={isLoading}
					value={selectedProfileId || ""}
					sx={{
						...DD_STYLES,
						maxWidth: "320px",
						marginBottom: "2rem",
						color: "#0F45FF",
					}}
					renderValue={() => renderSelectValue(selectedProfile?.profileName || null)}>
					{allTeams
						?.sort((a, b) => sortByString(a.profileName, b.profileName))
						.map((profile) => (
							<MenuItem key={profile.profileID} value={profile.profileID}>
								{profile.profileName}
							</MenuItem>
						))}
				</Select>
				<div className="team-level-grid">
					<div className="section">
						<p className="title">Expense Restrictions</p>
						{currentSetting?.expenseRestrictionModel === 0 && (
							<p className="subtitle">Define how late expenses can be raised.</p>
						)}
						{currentSetting?.expenseRestrictionModel === 1 && (
							<p className="subtitle">
								Prevent Missing Expenses after a time period. Example:- Can't claim expenses more than 30 days in the
								past.
							</p>
						)}
						{currentSetting?.expenseRestrictionModel === 2 && (
							<p className="subtitle">
								Prevent Missing Expenses after a time period into the next month. Example:- Can't claim expenses for
								October for more than 5 days in November.
							</p>
						)}
						<div>
							<Select
								disabled={!userWritePermission || isLoading}
								sx={DD_STYLES}
								value={currentSetting?.expenseRestrictionModel ?? 0}
								onChange={(e) => handleChange("expenseRestrictionModel", +e.target.value)}>
								<MenuItem value={0}>No Restriction</MenuItem>
								<MenuItem value={1}>Calender Days</MenuItem>
								<MenuItem value={2}>Next Month</MenuItem>
							</Select>
						</div>
						{currentSetting?.expenseRestrictionModel !== 0 && (
							<input
								style={{ marginTop: 10 }}
								type="number"
								disabled={!userWritePermission || isLoading}
								placeholder="Enter number of days"
								name="expenseRestrictionDays"
								id="expenseRestrictionDays"
								min={0}
								max={currentSetting?.expenseRestrictionModel === 1 ? 50 : 28}
								onChange={(e) => debouncedChanges(+e.target.value)}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExpenseRestrictions;
