import { DASHBOARD } from "../../../@types";
type Props = {
	orders: DASHBOARD["orders"];
};
const Orders = ({ orders }: Props) => (
	<div className="sub_table">
		<div className="rows head">
			<span>Client</span>
			<span>Amount</span>
		</div>
		{orders &&
			Array.isArray(orders) &&
			orders.map((order) => (
				<div className="rows" key={order.orderID}>
					<span>{order?.client?.clientName}</span>
					<span>{order.orderValue}</span>
				</div>
			))}
	</div>
);

export default Orders;
