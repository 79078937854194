/* eslint-disable no-alert */
import { FC, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { deleteGeoFencePoolById } from "../../../api/clients_sites";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { useDispatch } from "react-redux";

type Props = {
	geofencePool: any;
	setSelectedGeofencePoolID: any;
	setIsGeofencePoolEdit: any;
	setGeofencePools: any;
};

const GeofencePoolView: FC<Props> = ({
	geofencePool,
	setSelectedGeofencePoolID,
	setIsGeofencePoolEdit,
	setGeofencePools,
}) => {
	const [geofencePoolName, setGeofencePoolName] = useState<null | string>("");
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch<APP_DISPATCH>();

	useEffect(() => {
		if (geofencePool) {
			setGeofencePoolName(geofencePool.name);
		}
	}, [geofencePool]);

	const deleteGeoFencePool = async () => {
		setLoading(true);
		const { success, message } = await deleteGeoFencePoolById(geofencePool.geofencePoolID);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (success) {
			setSelectedGeofencePoolID(null);
			setGeofencePools((prev: any) => prev.filter((item: any) => item.geofencePoolID !== geofencePool.geofencePoolID));
		}
		setLoading(false);
	};

	return (
		<div className="user_add geofence_pool_sidebar">
			<div className="geofence_add_edit">
				<ArrowBackIcon
					sx={{ cursor: "pointer" }}
					onClick={() => {
						setSelectedGeofencePoolID(null);
					}}
				/>
				<div>{geofencePoolName}</div>
			</div>
			<div className="geofence_add_edit_form">
				<div style={{ width: "100%", overflow: "auto", margin: "1rem 0" }}>
					<ul>
						{geofencePool["geofenceList"].map((geofence: any) => (
							<li key={geofence["geofenceID"]}>{geofence["name"]}</li>
						))}
					</ul>
				</div>
				<div className="footer">
					<Button
						size="medium"
						variant="contained"
						color="error"
						disabled={loading}
						startIcon={<DeleteOutlinedIcon />}
						onClick={deleteGeoFencePool}>
						Delete
					</Button>
					<Button
						disabled={loading}
						size="medium"
						variant="contained"
						startIcon={<EditOutlinedIcon />}
						onClick={() => {
							setIsGeofencePoolEdit(true);
						}}>
						Edit
					</Button>
				</div>
			</div>
		</div>
	);
};

export default GeofencePoolView;
