import moment from "moment";
import { Dispatch, SetStateAction, useState } from "react";
import { DateRangePicker, Range } from "react-date-range";
import { DATE_TYPE_FORMAT } from "../../constants";

type Props = {
	setDateRange: any;
	dateRange: any;
	controlDisplay: Dispatch<SetStateAction<boolean>>;
	localString: string;
	allowFutureDates?: boolean;
};

function CustomDateRange({ dateRange, setDateRange, controlDisplay, localString, allowFutureDates = true }: Props) {
	const [state, setState] = useState<Range[]>([
		{
			startDate: new Date(moment(new Date(dateRange[0])).format("YYYY-MM-DD")),
			endDate: new Date(moment(new Date(dateRange[1])).format("YYYY-MM-DD")),
			key: "selection",
		},
	]);

	const handleApplyClick = () => {
		setDateRange(() => {
			localStorage.setItem(
				localString,
				JSON.stringify([moment(new Date(state[0].startDate!)).valueOf(), moment(new Date(state[0].endDate!)).valueOf()])
			);
			return [
				moment(new Date(state[0].startDate!)).format(DATE_TYPE_FORMAT),
				moment(new Date(state[0].endDate!)).format(DATE_TYPE_FORMAT),
			];
		});
		controlDisplay(false);
	};

	return (
		<div className="customDateRangePicker" style={{ position: "relative" }}>
			<div className="customDateRangePicker_wrapper">
				<DateRangePicker
					onChange={(item: any) => {
						if (allowFutureDates) {
							return setState([item.selection]);
						} else {
							if (item.selection.endDate < new Date()) {
								return setState([item.selection]);
							} else {
								return setState([
									{
										...item.selection,
										endDate: new Date(),
									},
								]);
							}
						}
					}}
					moveRangeOnFirstSelection={false}
					ranges={state}
					direction="horizontal"
					maxDate={allowFutureDates ? undefined : new Date()}
				/>
				<div
					style={{
						width: "100%",
						backgroundColor: "#ffffff",
						display: "flex",
						flexDirection: "row-reverse",
						padding: "10px",
						gap: "10px",
					}}>
					<button
						style={{
							backgroundColor: "#1976d2",
							color: "white",
							border: "none",
							height: "32px",
							borderRadius: "5px",
							paddingInline: "5px",
							padding: "5px 15px",
							fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
						}}
						onClick={() => handleApplyClick()}>
						Apply
					</button>
					<button
						style={{
							height: "32px",
							padding: "5px 10px",
							background: "#f5f5f5",
							color: "black",
							border: "1px solid #f5f5f5",
							borderRadius: "4px",
							boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
							fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
						}}
						onClick={() => controlDisplay(false)}>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
}

export default CustomDateRange;
