import axios from "../../utils/axios";
axios.defaults.withCredentials = true;
type RATING = {
	empID: number;
	manufacturer: string;
};
export const notifyDefaulters = async (
	ratingArray: RATING[],
	code: 2 | 4
): Promise<{ success: boolean; message: string }> => {
	const data = JSON.stringify({ ratingArray: ratingArray, code: code });
	try {
		const res = await axios({
			method: "POST",
			url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/compliance/notifyDefaulters`,
			data: `data=${data}`,
			headers: { "content-type": `application/x-www-form-urlencoded; charset=UTF-8` },
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Successfully Notified Employee(s)`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Notifying Employee(s)";
		return { success: false, message: errorText };
	}
};
export const a = "";
