import { TableCell, TableFooter, TableRow } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import { ORDER_TYPE } from "../../../@types";
type Props = {
	orders: ORDER_TYPE[];
};
const columns = [
	"Date",
	"Employee Name",
	"SKU Name",
	"Client Name",
	"Category",
	"Description",
	"QTY",
	"Price(Rs)",
	"Total(Rs)",
];
const SKUDetailsTable = ({ orders }: Props) => {
	const options = {
		search: true,
		textLabels: {
			body: {
				noMatch: "No Rows",
			},
		},
		elevation: 1,
		download: true,
		print: true,
		viewColumns: true,
		responsive: "standard",
		fixedHeader: true,
		filter: true,
		selectableRowsHideCheckboxes: true,
		tableBodyMaxHeight: "calc(100vh - 210px)",
		draggableColumns: {
			enabled: true,
		},
		rowsPerPageOptions: [10, 20, 50, 100],
		draggable: true,
		customTableBodyFooterRender: (opts: any) => {
			let totalQuantityFilteredSum = 0,
				orderTotalFilteredSum = 0,
				collectionAmountFilteredSum = 0;
			opts.data.forEach((data: any) => {
				totalQuantityFilteredSum += parseInt(data["data"][6]);
				orderTotalFilteredSum += parseFloat(data["data"][7]);
				collectionAmountFilteredSum += parseFloat(data["data"][8]);
			});
			const totalQuantity =
				orders && Array.isArray(orders)
					? orders
							.reduce((acc, order) => acc + order.lineItems.reduce((acc, lineItem) => acc + lineItem.quantity, 0), 0)
							.toFixed(2)
					: 0;
			const totalPrice =
				orders && Array.isArray(orders)
					? orders
							.reduce((acc, order) => acc + order.lineItems.reduce((acc, lineItem) => acc + lineItem.price, 0), 0)
							.toFixed(2)
					: 0;
			const totalPriceWithQuantity =
				orders && Array.isArray(orders)
					? orders
							.reduce(
								(acc, order) =>
									acc + order.lineItems.reduce((acc, lineItem) => acc + lineItem.price * lineItem.quantity, 0),
								0
							)
							.toFixed(2)
					: 0;
			return (
				<TableFooter>
					<TableRow>
						<TableCell colSpan={6}>Sub Total (Filtered) </TableCell>
						<TableCell>{totalQuantityFilteredSum.toFixed(2)}</TableCell>
						<TableCell>{orderTotalFilteredSum.toFixed(2)}</TableCell>
						<TableCell>{collectionAmountFilteredSum.toFixed(2)}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell colSpan={6}>Sub Total </TableCell>
						<TableCell>{totalQuantity}</TableCell>
						<TableCell>{totalPrice}</TableCell>
						<TableCell>{totalPriceWithQuantity}</TableCell>
					</TableRow>
				</TableFooter>
			);
		},
	};
	const data: any[] = [];
	orders.forEach(
		(row) =>
			row.lineItems &&
			Array.isArray(row.lineItems) &&
			row.lineItems.forEach((lineItem) => {
				data.push([
					row.processingDate,
					row.employeeName,
					lineItem.skuName,
					row.clientName ?? "",
					lineItem.category,
					lineItem.productDescription,
					lineItem.quantity,
					lineItem.price,
					lineItem.price * lineItem.quantity,
				]);
			})
	);
	return (
		<ThemeProvider theme={createTheme()}>
			{/* @ts-expect-error dont know why*/}
			<MUIDataTable title="SKU Details" data={data} columns={columns} options={options} />
		</ThemeProvider>
	);
};

export default SKUDetailsTable;
