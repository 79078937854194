/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useMutation } from "@apollo/client";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import ReactSelect from "react-select";
import { v4 as uuidv4 } from "uuid";
import { ACCESS_CONTROL_USER, EXECUTIVE_USER, TASK_TYPE } from "../../../@types";
import { DATE_TYPE_FORMAT } from "../../../constants";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { UPSERT_PENDING_TASK, UPSERT_TASK } from "../../../schema/tasks";
import useAdminEntitlements from "../../useAdminEntitlements";

const createdBy = (PTask: TASK_TYPE, addAdmins: Omit<ACCESS_CONTROL_USER, "employees">[]) => {
	if (PTask.owningAdminID) {
		const admin = addAdmins.find((a) => a.adminID === PTask.owningAdminID);
		return admin ? `${admin.firstname} ${admin.lastname}` : "NA";
	}
	return "NA";
};

const NEW_FORM_DATA: TASK_TYPE = {
	taskID: uuidv4(),
	clientID: null,
	employeeID: 112334, //change this immediately
	date: moment().format(DATE_TYPE_FORMAT), //change this for passed date
	adminAssigned: 1, //if created form panel
	checkinTime: null,
	checkoutTime: null,
	lat: null,
	lon: null,
	taskDescription: null,
	startTime: null,
	endTime: null,
	address: null,
	timestamp: moment().valueOf(),
	lastModifiedByAdminID: null,
	lastUpdatedAdminID: null,
	inPool: null,
	createdByAdminID: null,
	createdByEmpID: null,
	createdBySourceID: null,
	createdTs: null,
	lastModifiedByEmpID: null,
	lastModifiedBySourceID: null,
	lastModifiedTs: null,
	customFieldsComplex: null,
	clientInfo: null,
	repeatedTaskMetadata: null,
};

type Props2 = {
	// eslint-disable-next-line no-unused-vars
	handleSidebarOpenPT: (selectedPTTaskID: string) => void;
	executives: EXECUTIVE_USER[];
	allAdmins: Omit<ACCESS_CONTROL_USER, "employees">[];
	PTasks: TASK_TYPE[];
	setTasks: Dispatch<SetStateAction<TASK_TYPE[] | null>>;
	setPTasks: Dispatch<SetStateAction<TASK_TYPE[] | null>>;
	date: string;
	tasks: TASK_TYPE[];
};

const JobJar: FC<Props2> = ({
	handleSidebarOpenPT,
	executives,
	allAdmins,
	PTasks,
	setTasks,
	setPTasks,
	date,
	tasks,
}) => {
	const [expand, setExpand] = useState(window.innerWidth > 768);
	const userWritePermission = useAdminEntitlements("tasks:assign", "write");

	const [expandedTask, setExpandedTask] = useState<any>(null);
	const [assignedEmployee, setAssignedEmployee] = useState<any>(null);
	const [upsertTask] = useMutation(UPSERT_TASK);
	const [upsertPendingTask] = useMutation(UPSERT_PENDING_TASK);
	const dispatch = useDispatch<APP_DISPATCH>();

	const handleClose = () => {
		setExpandedTask(null);
		setAssignedEmployee(null);
	};

	const [loader, setLoader] = useState(false);

	const assignToExecutive = async (PTask: TASK_TYPE) => {
		const toSendFormData = {
			...NEW_FORM_DATA,
			employeeID: assignedEmployee?.value,
			address: PTask?.address,
			startTime: PTask?.startTime,
			endTime: PTask?.endTime,
			lat: PTask?.lat,
			lon: PTask?.lon,
			taskDescription: PTask.taskDescription,
			createdTs: PTask?.createdTs,
		};

		const toSend = {
			fromDate: moment(toSendFormData.date).format(DATE_TYPE_FORMAT),
			input: {
				address: toSendFormData.address,
				adminAssigned: toSendFormData.adminAssigned,
				clientID: toSendFormData.clientID,
				createdTs: toSendFormData.createdTs,
				date: toSendFormData.date,
				employeeID: toSendFormData.employeeID,
				endTime: toSendFormData.endTime,
				lastModifiedTs: moment().valueOf(),
				lat: toSendFormData.lat,
				lon: toSendFormData.lon,
				startTime: toSendFormData.startTime,
				taskDescription: toSendFormData.taskDescription,
				taskID: toSendFormData.taskID,
				timestamp: toSendFormData.timestamp,
				customFieldsComplex: toSendFormData.customFieldsComplex
					? toSendFormData.customFieldsComplex.map((field) => ({
							fieldID: field.fieldID,
							fieldValue: field.fieldValue,
							selectedOptions: field.selectedOptions,
					  }))
					: null,
			},
		};

		const toSendPending = {
			input: {
				...PTask,
				pendingTaskStatus: "assigned",
				lastModifiedTs: moment().valueOf(),
				linkedTaskID: toSendFormData.taskID,
			},
		};

		try {
			setLoader(true);
			const response = await upsertTask({ variables: toSend });
			dispatch(showNotification({ message: "Successfully assigned Task", severity: "success" }));
			setTasks([...tasks, ...response.data.upsert_task?.data.filter((task: TASK_TYPE) => task.date === date)]);

			await upsertPendingTask({ variables: toSendPending });
			setPTasks(PTasks.filter((d) => d.pendingTaskID !== PTask.pendingTaskID));
			setLoader(false);
		} catch {
			setLoader(false);
			dispatch(showNotification({ message: "Error occurred in assigning Task", severity: "error" }));
		}
	};
	return (
		<div>
			<div className="title">
				<h3>Pending Tasks ({PTasks?.length ?? 0})</h3>
				<div className="buttons">
					<button className={`${expand ? "active dropdown" : "dropdown"}`} onClick={() => setExpand((e) => !e)}>
						<KeyboardArrowDownOutlinedIcon />
					</button>
				</div>
			</div>
			{expand && PTasks.length > 0 && (
				<ul className="job_jar_list">
					{PTasks.map((PTask) => (
						<li className="task_card" key={PTask.pendingTaskID}>
							<div className="header" onClick={() => userWritePermission && handleSidebarOpenPT(PTask.pendingTaskID!)}>
								<h4>
									{PTask.taskDescription && (
										<>
											<span>Description</span>
											{PTask.taskDescription}
										</>
									)}
								</h4>
								<button disabled={!userWritePermission} onClick={() => handleSidebarOpenPT(PTask.pendingTaskID!)}>
									<EditOutlinedIcon />
								</button>
							</div>
							<div className="action">
								<div>
									<span>Scheduled at</span>
									<h6>
										{PTask.startTime ? moment(PTask.startTime).format("hh:mm A") : "NA"} -{" "}
										{PTask.endTime ? moment(PTask.endTime).format("hh:mm A") : "NA"}
									</h6>
								</div>
								<div>
									<span>Pending with</span>
									<h6>{createdBy(PTask, allAdmins)}</h6>
								</div>
							</div>
							<ul className="footer">
								{expandedTask && expandedTask === PTask["pendingTaskID"] ? (
									<div className="assign_to">
										<div className="top">
											<h6>Transfer to Executive</h6>
											<CloseIcon onClick={() => handleClose()} />
										</div>
										<div className="main_selection">
											<ReactSelect
												value={assignedEmployee}
												onChange={(e) => setAssignedEmployee(e)}
												isClearable={true}
												placeholder={"Select executive"}
												className="react-select"
												options={executives?.map((i: any) => ({
													value: i.employeeID,
													label: i.lastName ? i.firstName + " " + i.lastName : i.firstName,
												}))}
											/>
										</div>
										<div className="footer">
											<button type="submit" onClick={() => assignToExecutive(PTask)} disabled={!assignedEmployee}>
												{!loader ? "Apply" : <CircularProgress />}
											</button>
										</div>
									</div>
								) : (
									<li aria-hidden onClick={() => setExpandedTask(PTask["pendingTaskID"]!)}>
										<h6>Assign Task</h6>
										<AddOutlinedIcon />
									</li>
								)}
							</ul>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default JobJar;
