import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PROFILES_DATA } from "../../../@types";
import { updateTeamLevelClientsSettings } from "../../../api/settings";
import { DD_STYLES } from "../../../constants";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import { sortByString } from "../../../utils";
import useAdminEntitlements, { useAdminCompanySettings } from "../../useAdminEntitlements";
import useFetch from "../../useFetch";
import ReadOnlyButton from "../../common/ReadOnlyButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSelector } from "react-redux";
import TeamSelector from "../../common/TeamSelector";
type FETCH = {
	companyLevel: {
		vasaForClients: 0 | 1;
	};
	teamLevel: {
		clientVisibleToAll: 0 | 1 | 2;
		clientModificationBehaviour: 0 | 1 | 2 | 3; //0->can do all,1-> cannot add,2->cannot edit,3->cannot delete
		clientsEnabled: 1 | 0;
		profileID: number;
		profileName: string;
		isOTPVerificationEnableForClient: 1 | 0;
	}[];
};
const renderSelectValue = (value: null | string) => (
	<div>
		Assigning To: <span style={{ marginLeft: "8px", fontWeight: "bold" }}>{value}</span>
	</div>
);
const TeamLevelClientSettings = () => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const { data, loading: loadingClients, refetch } = useFetch<FETCH>("/preferences/clients");
	const { data: profileData, loading: profileLoading } = useFetch<PROFILES_DATA[]>("/users/profiles");
	const loading = loadingClients || profileLoading;
	const customTaskFlag = useAdminCompanySettings("customEntityMigrationPhase");

	const [profiles, setProfiles] = useState<PROFILES_DATA[]>([]);
	const [selectedProfile, setSelectedProfile] = useState<PROFILES_DATA["profileName"] | null>(null);
	const [selectedSetting, setSelectedSetting] = useState<null | FETCH["teamLevel"][0]>(null);
	const userWritePermission = useAdminEntitlements("settings:clients", "write");
	const { roleID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);

	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		if (!profileLoading && profileData?.data) {
			setProfiles(profileData.data);
			setSelectedProfile(profileData.data[0].profileName);
		}
	}, [profileLoading, profileData?.data]);

	useEffect(() => {
		if (!loading && data.data && selectedProfile) {
			const currSettings = data.data.teamLevel.find((setting) => setting.profileName === selectedProfile) || null;
			setSelectedSetting(currSettings);
		}
	}, [data?.data, loading, loadingClients, selectedProfile]);

	const handleChange = async (value: number | string, key: string, selectTeams?: number[]) => {
		const newValue = typeof value === "number" ? value : +value;
		if (!key || key.length === 0 || !selectedSetting) {
			dispatch(showNotification({ message: "Error: Please try again or Reload the page", severity: "error" }));
			return;
		}
		const updatedSettings = { ...selectedSetting, [key]: newValue };
		const profile = profiles.find((profile) => profile.profileName === selectedProfile);
		if (!profile) {
			dispatch(showNotification({ message: "Client Side error, Please reload the page", severity: "error" }));
			return;
		}
		const { success, message } = await updateTeamLevelClientsSettings(updatedSettings, profile.profileID!, selectTeams);
		if (success) {
			refetch();
			setSelectedSetting(updatedSettings);
		}
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
	};

	return (
		<div className="team-level-task-settings">
			{expanded && <TeamSelector expanded={expanded} setExpanded={setExpanded} handleChange={handleChange} />}
			<div className="team-header">
				<div className="team-level-header">
					<PeopleAltOutlinedIcon className="svgicons" />
					<p className="title">Team Level Settings</p>
					{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="settings" /> : null}
				</div>
				<button className="add mui-button" onClick={() => setExpanded(!expanded)} disabled={!userWritePermission}>
					<ContentCopyIcon sx={{ marginRight: "5px" }} />
					Copy Team Settings
				</button>
			</div>
			<div className="horizontal-line"></div>
			<Select
				onChange={(e) => setSelectedProfile(e.target.value)}
				value={selectedProfile}
				sx={{
					...DD_STYLES,
					maxWidth: "320px",
					marginBottom: "2rem",
					color: "#0F45FF",
				}}
				renderValue={(selectedValue) => renderSelectValue(selectedValue)}>
				{profiles
					?.sort((a, b) => sortByString(a.profileName, b.profileName))
					.map((profile) => (
						<MenuItem key={profile.profileID} value={profile.profileName}>
							{profile.profileName}
						</MenuItem>
					))}
			</Select>
			<div className="team-level-grid">
				{/* removed as a part of custom task phase 2 migration */}
				<div className="section">
					<p className="title">Client Modification</p>
					{customTaskFlag ? (
						<>
							<p className="subtitle">Are Employees allowed to Edit Clients</p>
							<Select
								value={selectedSetting?.clientModificationBehaviour ?? 1}
								sx={DD_STYLES}
								disabled={!userWritePermission}
								onChange={(e) => handleChange(+e.target.value, "clientModificationBehaviour")}>
								<MenuItem value={1}>Yes</MenuItem>
								<MenuItem value={3}>No</MenuItem>
							</Select>
						</>
					) : (
						<>
							<p className="subtitle">Are Employees allowed to Add/Edit Clients</p>
							<Select
								value={selectedSetting?.clientModificationBehaviour ?? 0}
								sx={DD_STYLES}
								disabled={!userWritePermission}
								onChange={(e) => handleChange(+e.target.value, "clientModificationBehaviour")}>
								<MenuItem value={0}>Add and Edit allowed (Default)</MenuItem>
								<MenuItem value={1}>Only Edit</MenuItem>
								<MenuItem value={2}>Only Add</MenuItem>
								<MenuItem value={3}>Add and Edit not allowed</MenuItem>
							</Select>
						</>
					)}
				</div>
				<div className="section">
					<p className="title">Client visibility</p>
					<p className="subtitle">
						Controls who the client is visible to by default when created by a Employee in the Unolo app
					</p>
					<Select
						value={selectedSetting?.clientVisibleToAll ?? 0}
						sx={DD_STYLES}
						disabled={!userWritePermission}
						onChange={(e) => handleChange(e.target.value, "clientVisibleToAll")}>
						<MenuItem value={1}>To everyone</MenuItem>
						<MenuItem value={0}>Only to Employee</MenuItem>
						<MenuItem value={2}>Only to Employee's team</MenuItem>
					</Select>
				</div>
				<div className="section">
					<p className="title">OTP Enabled</p>
					<p className="subtitle">OTP verification to verify client contact details</p>
					<Select
						value={selectedSetting?.isOTPVerificationEnableForClient ?? 0}
						sx={DD_STYLES}
						disabled={!userWritePermission}
						onChange={(e) => handleChange(e.target.value, "isOTPVerificationEnableForClient")}>
						<MenuItem value={1}>Yes</MenuItem>
						<MenuItem value={0}>No</MenuItem>
					</Select>
				</div>
			</div>
		</div>
	);
};
export default TeamLevelClientSettings;
