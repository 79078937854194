var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3hAvzJCs3HA1Z8oU0Q-fH"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { configureScope } from "@sentry/nextjs";
const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.01,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  replaysSessionSampleRate: 0.0001,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0.01,

  integrations: [new Sentry.Replay({
    maskAllText: false,
  }
  )],
  beforeSend(event) {
    // Check if the request was made over HTTPS
    if (window.location.protocol !== "https:") {
      // Do not send the event for HTTP requests
      return null;
    }
    // If the request was made over HTTPS, allow the event to be sent
    return event;
  },

});

configureScope((scope) => {
  scope.setUser({
    companyID: localStorage.getItem("companyID") || "NA",
    adminID: localStorage.getItem("adminID") || "NA",
  });
  scope.setTags({
    companyID: localStorage.getItem("companyID") || "NA",
    adminID: localStorage.getItem("adminID") || "NA",
  });
});
