import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { FC, useState } from "react";
import { CircularProgress } from "@mui/material";
import ExpenseModal from "./ExpenseModal";

type Props = {
	canAdminApprove: boolean;
	isMarked: boolean;
	isUpdated: boolean;
	approveRequest: (reason: string, amount?: number) => void;
	rejectRequest: (reason: string) => void;
	paidOutCallback: () => void;
	name: string;
	status: -1 | 0 | 1 | 2 | 3; // "Pending" | "Rejected" | "Approved" | "Paid Out" | "In Progress";
	lastApprovedAmount: number;
	canAdminPayout: boolean;
	userWritePermission:any;
};
const ExpenseActionButtonsV1: FC<Props> = ({
	isMarked,
	isUpdated,
	rejectRequest,
	approveRequest,
	name,
	status,
	lastApprovedAmount,
	canAdminApprove,
	canAdminPayout,
	paidOutCallback,
	userWritePermission
}) => {
	const [open, setOpen] = useState(false);
	const [isReject, setIsReject] = useState(false);
	return (
		<div className="action-buttons">
			<button className="view" disabled={!userWritePermission}>
				<RemoveRedEyeOutlinedIcon htmlColor="#819dff" />
			</button>
			{canAdminPayout &&
				(isMarked ? (
					<CircularProgress />
				) : (
					<button
						type="button"
						className="paid_out"
						onClick={(e) => {
							e.stopPropagation();
							paidOutCallback();
						}}
						disabled={!userWritePermission}>
						Pay out
					</button>
				))}
			{(status === -1 || status === 3) &&
				canAdminApprove &&
				(isUpdated ? (
					<CircularProgress />
				) : (
					<>
						<button
							onClick={(e) => {
								e.stopPropagation();
								setIsReject(false);
								setOpen(true);
							}}
							disabled={!userWritePermission}>
							<DoneOutlinedIcon htmlColor="#40a636" />
						</button>
						<button
							onClick={(e) => {
								e.stopPropagation();
								setIsReject(true);
								setOpen(true);
							}}
							disabled={!userWritePermission}>
							<CloseOutlinedIcon htmlColor="#F54747" />
						</button>
					</>
				))}
			{open && (status === -1 || status === 3) && (
				<ExpenseModal
					isReject={isReject}
					approveHandler={approveRequest}
					rejectHandler={rejectRequest}
					handleClose={() => setOpen(false)}
					name={name}
					amountClaimed={lastApprovedAmount}
				/>
			)}
		</div>
	);
};

export default ExpenseActionButtonsV1;
