import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { FC, useState } from "react";
import useAdminEntitlements from "../../useAdminEntitlements";
import LeavesModal from "./LeavesModal";
type Props = {
	approveRequest: () => void;
	// eslint-disable-next-line no-unused-vars
	rejectRequest: (reason: string) => void;
	name: string;
};
const LeavesApproveReject: FC<Props> = ({ rejectRequest, approveRequest, name }) => {
	const [open, setOpen] = useState(false);
	const [isReject, setIsReject] = useState(false);
	const userWritePermission = useAdminEntitlements("attendance:leaves", "write");

	const handleClose = () => {
		setOpen(false);
	};
	return (
		<>
			<div className="action-buttons">
				<button
					disabled={!userWritePermission}
					onClick={(e) => {
						e.stopPropagation();
						setOpen(true);
						setIsReject(false);
					}}>
					<DoneOutlinedIcon htmlColor="#40A636" />
				</button>
				<button
					disabled={!userWritePermission}
					onClick={(e) => {
						e.stopPropagation();
						setOpen(true);
						setIsReject(true);
					}}>
					<CloseOutlinedIcon htmlColor="#F54747" />
				</button>
			</div>
			{open && (
				<LeavesModal
					isReject={isReject}
					approveRequest={approveRequest}
					rejectRequest={rejectRequest}
					handleClose={handleClose}
					name={name}
				/>
			)}
		</>
	);
};

export default LeavesApproveReject;
