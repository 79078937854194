import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_USERS = gql`
	query getUsers {
		get_users_by_admin_id {
			firstName
			lastName
			employeeID
		}
	}
`;
