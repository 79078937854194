import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import { useRouter } from "next/router";
import { FC } from "react";
import { useQuery } from "react-query";
import { Layout, SubmoduleTabs } from "..";
import { SUBMODULE_DATA } from "../../@types";
import { fetchSidebarMetaData } from "../../utils";
import NotAuthorized from "../common/NotAuthorized";
import useAdminEntitlements from "../useAdminEntitlements";
import { useSelector } from "react-redux";
import { ROOT_STATE } from "../../redux";
import { PRODUCT_ID, UNOLO_BIOMETRIC } from "../../constants";
const clientsSubmodule: SUBMODULE_DATA[] = [
	{
		label: "Clients",
		icon: <GppGoodOutlinedIcon />,
		path: "/clients_sites/clients",
	},
	{
		label: "Sites",
		icon: <PeopleAltOutlinedIcon />,
		path: "/clients_sites/sites",
	},
	{
		label: "Geofences",
		icon: <SecurityOutlinedIcon />,
		path: "/clients_sites/geofences",
	},
];
const biometriClientsSubmodule: SUBMODULE_DATA[] = [
	{
		label: "Sites",
		icon: <PeopleAltOutlinedIcon />,
		path: "/clients_sites/sites",
	},
];
type Props = {
	title: string;
	children: any;
	tab: number;
};
const LayoutClients: FC<Props> = ({ title, children, tab }) => {
	const { data } = useQuery("subscriptionMetadata", fetchSidebarMetaData, {
		staleTime: 200000,
		cacheTime: 200000,
	});
	if (data?.data?.data?.sites === 0) {
		const index = clientsSubmodule.findIndex((item) => "/clients_sites/sites" === item?.path);
		// @ts-ignore
		clientsSubmodule[index].hide = true;
	}

	const userReadPermissionClient = useAdminEntitlements("clientsAndSites:clients", "read");
	const userReadPermissionSites = useAdminEntitlements("clientsAndSites:sites", "read");
	const userReadPermissionGeofences = useAdminEntitlements("clientsAndSites:geofences", "read");

	const router = useRouter();
	const isClientRead = router.pathname.includes("/clients_sites/clients") && userReadPermissionClient;
	const isSiteRead = router.pathname.includes("/clients_sites/sites") && userReadPermissionSites;
	const isGeofenceRead = router.pathname.includes("/clients_sites/geofences") && userReadPermissionGeofences;

	const { roleID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);
	const productID = window ? localStorage.getItem(PRODUCT_ID) ?? "2" : "2";

	return (
		<Layout title={title}>
			<SubmoduleTabs
				submoduleData={productID == UNOLO_BIOMETRIC ? biometriClientsSubmodule : clientsSubmodule}
				tab={tab}
			/>
			{isClientRead || isSiteRead || isGeofenceRead ? (
				children
			) : (
				<NotAuthorized roleID={roleID} moduleName="clientsAndSites" />
			)}
		</Layout>
	);
};

export default LayoutClients;
