import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import PeopleOutlineSharpIcon from "@mui/icons-material/PeopleOutlineSharp";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import { useRouter } from "next/router";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Layout, SubmoduleTabs } from "..";
import { SUBMODULE_DATA } from "../../@types";
import { ROOT_STATE } from "../../redux";
import NotAuthorized from "../common/NotAuthorized";
import useAdminEntitlements from "../useAdminEntitlements";

const organizationSubmoduleData: SUBMODULE_DATA[] = [
	{
		label: "Employees",
		icon: <PeopleOutlineSharpIcon />,
		path: "/organization/field_executive",
	},
	{
		label: "Admins",
		icon: <GppGoodOutlinedIcon />,
		path: "/organization/access-control",
	},
	{
		label: "Roles",
		icon: <AssignmentIndOutlinedIcon />,
		path: "/organization/roles",
	},
	{
		label: "Designation",
		icon: <BadgeOutlinedIcon />,
		path: "/organization/designation",
	},
	{
		label: "Teams",
		icon: <WorkspacesIcon />,
		path: "/organization/teams",
	},
];

type Props = {
	title: string;
	children: any;
	tab: number;
};
const OrganizationLayout: FC<Props> = ({ title, children, tab }) => {
	const userReadPermissionFE = useAdminEntitlements("organization:employees", "read");
	const userReadPermissionAC = useAdminEntitlements("organization:admin", "read");
	const userReadPermissionRoles = useAdminEntitlements("organization:roles", "read");
	const userReadPermissionDesignation = useAdminEntitlements("organization:designation", "read");
	const userReadPermissionTeams = useAdminEntitlements("organization:teams", "read");

	const router = useRouter();
	const isFERead = router.pathname.includes("field_executive") && userReadPermissionFE;
	const isACread = router.pathname.includes("access-control") && userReadPermissionAC;
	const isRoleRead = router.pathname.includes("roles") && userReadPermissionRoles;
	const isDesignationRead = router.pathname.includes("designation") && userReadPermissionDesignation;
	const isTeamRead = router.pathname.includes("teams") && userReadPermissionTeams;
	const { roleID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);
	return (
		<Layout title={title}>
			<SubmoduleTabs submoduleData={organizationSubmoduleData} tab={tab} />
			{isFERead || isACread || isRoleRead || isDesignationRead || isTeamRead ? (
				children
			) : (
				<NotAuthorized roleID={roleID} moduleName="organization" />
			)}
		</Layout>
	);
};

export default OrganizationLayout;
