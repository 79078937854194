
// mui
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import { FC } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialog-paper": {
		width: "650px",
		borderRadius: "10px",
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
		display: "flex",
		justifyContent: "flex-end",
		paddingRight: "1.5rem",
		gap: "0.5rem",
	},
}));


type Props = {
	handleClose: () => void;
};

const MessageModal: FC<Props> = ({
	handleClose,
}) => <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
	<div
		style={{
			display: "flex",
			padding: "16px",
			alignItems: "center",
			width: "100%",
			justifyContent: "center",
			flexDirection: "column",
		}}>
			<p
				style={{
					textAlign: "center",
					margin: "1.5rem 0 0.5rem 0",
					fontSize: "16px",
					fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
				}}>
					<p style={{ lineHeight: "1.5rem" }}>
						After enabling <strong>Leaves Export</strong> it is mandatory to enter new credentials and click on <strong>Next</strong> button to provide required access.
					</p>
			</p>
			<p
				className="font-m-12"
				style={{
					color: "#ef9943",
				}}>
				<WarningOutlinedIcon sx={{ width: "15px", margin: "0 4px 2px 0" }} />
				Enable process will fail if you don't complete the authentication with new credentials.
			</p>
	</div>
		<DialogActions
			sx={{
				justifyContent: "center",
				marginBottom: "1rem",
			}}>
			<button
				style={{
					textTransform: "capitalize",
					background: "#f5f5f5",
					color: "black",
					border: "1px solid #f5f5f5",
					padding: "5px 14px",
					borderRadius: "4px",
					boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
					fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
				}}
				onClick={(e) => {
					e.stopPropagation();
					handleClose();
				}}>
				OK
			</button>
		</DialogActions>
</BootstrapDialog>

export default MessageModal;