import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Switch } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { FC, useEffect, useMemo, useState } from "react";
import { FEATURE } from "../../../@types";
import { DG_STYLES } from "../../../constants";

type Props = {
	features: FEATURE[];
	loading: boolean;
	// eslint-disable-next-line no-unused-vars
	updateTheStatusOfNotification: (status: number, id: number) => void;
};
const NotificationTable: FC<Props> = ({ features, loading, updateTheStatusOfNotification }) => {
	const [columns, setColumns] = useState<GridColDef[]>([]);

	const tempColumns = useMemo(
		() => [
			{
				field: "moduleName",
				headerName: "Module",
				sortable: false,
				flex: 1,
				minWidth: 200,
				renderCell: (params: GridValueGetterParams) => (
					<div>
						{params.row.moduleName.charAt(0).toUpperCase() +
							params.row.moduleName.slice(1).toLowerCase() +
							(params.row.moduleName === "GPS" ? " On/Off" : "")}
					</div>
				),
			},
			{
				field: "state",
				headerName: "Subscribe",
				width: 150,
				sortable: false,
				renderCell: (params: GridValueGetterParams) => {
					if (params["row"]["isMandatory"]) {
						return <NotInterestedIcon />;
					}
					return (
						<Switch
							checked={params.row.status === 1 ? true : false}
							onChange={() => updateTheStatusOfNotification(params.row.status, params.row.moduleID)}
						/>
					);
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[features]
	);
	useEffect(() => {
		setColumns(tempColumns);
	}, [tempColumns]);

	return (
		<div className="datagrid-table">
			<DataGridPro
				sx={DG_STYLES}
				getRowId={(row) => row.moduleID}
				rows={features}
				columns={columns}
				loading={loading}
				rowsPerPageOptions={[5, 10, 20, 50]}
				rowHeight={70}
				disableSelectionOnClick
				disableColumnFilter
			/>
		</div>
	);
};

export default NotificationTable;
