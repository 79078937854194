import { DataGridPro, GridColDef, GridSelectionModel, GridValueGetterParams } from "@mui/x-data-grid-pro";
import _cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { LEAVE_TYPE } from "../../../@types";
import { approveRejectLeave } from "../../../api/attendance";
import { DG_STYLES } from "../../../constants";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import ColumnEmployee from "../../common/datagrid/ColumnEmployee";
import CustomColumnMenu from "../../common/datagrid/CustomColumnMenu";
import LeavesApproveReject from "./LeavesApproveReject";
import { useSelector } from "react-redux";
import axios from "axios";
type Props = {
	leaves: LEAVE_TYPE[];
	setLeaves: Dispatch<SetStateAction<LEAVE_TYPE[]>>;
	filteredLeaves: LEAVE_TYPE[];
	setSelectedRows: Dispatch<SetStateAction<GridSelectionModel>>;
	loading: boolean;
};
const LeavesTable: FC<Props> = ({ leaves, setLeaves, filteredLeaves, setSelectedRows, loading }) => {
	const [columns, setColumns] = useState<GridColDef[]>([]);
	const [pageSize, setPageSize] = useState(20);
	const dispatch = useDispatch<APP_DISPATCH>();
	const { adminID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);
	const [leaveSettings, setLeaveSettings] = useState<any>(null);
	const tempColumns = useMemo(
		() => [
			{
				field: "employee",
				headerName: "Employee Name",
				renderCell: (params: GridValueGetterParams) => (
					<ColumnEmployee name={params.row.name} avatar={params.row.imgUrl} />
				),
				flex: 1,
				minWidth: 200,
			},
			{
				field: "duration",
				headerName: "Duration",
				flex: 1,
				minWidth: 200,
				renderCell: (params: GridValueGetterParams) => (
					<div className="duration">
						<time>
							{params.row.startDate ? moment(params.row.startDate).format("MMM DD YYYY") : ""} {" - "}
							{params.row.endDate ? moment(params.row.endDate).format("MMM DD YYYY") : ""}
						</time>
						<span>
							{params.row.leavesInRange} Day{params.row.leavesInRange > 1 && "s"}
						</span>
					</div>
				),
			},
			{
				field: "reason",
				headerName: "Leave Reason",
				flex: 1,
				minWidth: 200,
				renderCell: (params: GridValueGetterParams) => params.row.reason ?? "-",
			},
			{
				field: "rejectionReason",
				headerName: "Reject Reason",
				flex: 1,
				minWidth: 200,
				renderCell: (params: GridValueGetterParams) => params.row.rejectionReason ?? "-",
			},
			{
				field: "totalLeavesTaken",
				headerName: "Total Leaves Taken",
				flex: 1,
				minWidth: 150,
			},
			{
				field: "approved",
				headerName: "Status",
				flex: 1,
				minWidth: 100,
				renderCell: (params: GridValueGetterParams) => {
					const state =
						params.row.approved === 0
							? "Rejected"
							: params.row.approved === 1
							? "Approved"
							: params.row.approved === 2
							? "Pending"
							: "Deleted";
					return <span className={`role_badge ${state}`}>{state}</span>;
				},
			},
			{
				field: "action",
				headerName: "Action",
				width: 100,
				renderCell: (params: GridValueGetterParams) => {
					const isCurrentAdminBackupApprover =
						adminID === leaveSettings?.approvalOptions?.backUpApprover1 ||
						adminID === leaveSettings?.approvalOptions?.backUpApprover2;

					return params.row.approved === 2 || (isCurrentAdminBackupApprover && params?.row?.state === "PENDING") ? (
						<LeavesApproveReject
							approveRequest={() => approveLeave(params.row.rangeID)}
							rejectRequest={(reason: string) => rejectLeave(params.row.rangeID, reason)}
							name={params.row.name}
						/>
					) : (
						"-"
					);
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[leaves]
	);
	useEffect(() => {
		const getApprovalOptions = async () => {
			try {
				const response = await axios.get(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/getApprovalOptions`, {
					params: {
						entityType: "LEAVE",
					},
					withCredentials: true,
				});
				setLeaveSettings(response?.data?.data);
			} catch (error) {
				console.error(`Error: ${error}`);
			}
		};

		getApprovalOptions();
	}, []);
	useEffect(() => {
		setColumns(tempColumns);
	}, [tempColumns]);

	const rejectLeave = async (id: string, reason: string) => {
		if (id.length === 0) return;
		const updatedLeaves = _cloneDeep(leaves);
		const leavesIndex = updatedLeaves.findIndex((r) => r.rangeID === id);
		if (leavesIndex < 0) return;
		updatedLeaves[leavesIndex].approved = 0;
		updatedLeaves[leavesIndex].rejectionReason = reason ?? "";
		const { success, message } = await approveRejectLeave([updatedLeaves[leavesIndex]]);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (!success) return;
		setLeaves(updatedLeaves);
	};

	const approveLeave = async (id: string) => {
		if (id.length === 0) return;
		const updatedLeaves = _cloneDeep(leaves);
		const leavesIndex = updatedLeaves.findIndex((r) => r.rangeID === id);
		if (leavesIndex < 0) return;
		updatedLeaves[leavesIndex].approved = 1;
		const { success, message } = await approveRejectLeave([updatedLeaves[leavesIndex]]);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (!success) return;
		setLeaves(updatedLeaves);
	};
	return (
		<div className="datagrid-table">
			<DataGridPro
				sx={DG_STYLES}
				rows={filteredLeaves}
				getRowId={(row) => row.rangeID}
				columns={columns}
				loading={loading}
				pageSize={pageSize}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[5, 10, 20, 50]}
				rowHeight={70}
				checkboxSelection
				disableSelectionOnClick
				disableColumnFilter
				pagination
				onSelectionModelChange={(selectionModel) => setSelectedRows(selectionModel)}
				components={{ ColumnMenu: CustomColumnMenu }}
			/>
		</div>
	);
};

export default LeavesTable;
