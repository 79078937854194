//react
import { useRouter } from "next/router";
import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
//utils
import { Layout } from "..";
import { SUBMODULE_DATA } from "../../@types";
import { fetchSidebarMetaData } from "../../utils";
import NotAuthorized from "../common/NotAuthorized";
import SubmoduleTabs from "../common/SubmoduleTabs";
import useAdminEntitlements from "../useAdminEntitlements";
import { APP_DISPATCH, ROOT_STATE } from "../../redux";
import { setSubscriptionMetadata } from "../../redux/slices/subscriptionMetadataSlice";
//mui
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import BarChartIcon from "@mui/icons-material/BarChart";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ViewAgendaOutlinedIcon from "@mui/icons-material/ViewAgendaOutlined";
import WhereToVoteOutlinedIcon from "@mui/icons-material/WhereToVoteOutlined";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
const dashboardSubmoduleData: SUBMODULE_DATA[] = [
	{
		label: "Overview",
		icon: <BarChartIcon />,
		path: "/",
	},
	{
		label: "Live Location",
		icon: <LocationOnOutlinedIcon />,
		path: "/dashboard/live_location",
	},
	{
		label: "Timeline",
		icon: <WhereToVoteOutlinedIcon />,
		path: "/dashboard/timeline",
	},
	{
		label: "Card View",
		icon: <ViewAgendaOutlinedIcon />,
		path: "/dashboard/card_view",
	},
	{
		label: "Compliance Status",
		icon: <InfoOutlinedIcon />,
		path: "/dashboard/compliance_status",
	},
	// kept for future use if needed
	// {
	// 	label: "Site Attendance",
	// 	icon: <PersonPinCircleIcon />,
	// 	path: "/dashboard/site_attendance",
	// },
	{
		label: "Site Attendance",
		icon: <PersonPinCircleIcon />,
		path: "/dashboard/site_attendance_beta",
	},
];

type Props = {
	title: string;
	children: any;
	tab: number;
};
const DashboardLayout: FC<Props> = ({ title, children, tab }) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const [showModules, setShowModules] = useState(false);
	const { isLoading, data, error } = useQuery("subscriptionMetadata", fetchSidebarMetaData, {
		staleTime: 200000,
		cacheTime: 200000,
		refetchOnWindowFocus: true,
		refetchOnReconnect: true,
		refetchInterval: 200000,
	});

	const shouldHideSubModule = (module: string, data: any) => {
		switch (module) {
			case "Overview":
				return false;
			case "Live Location":
				return data?.data?.liveLocation === 1 ? false : true;
			case "Timeline":
				return data?.data?.timeline === 1 ? false : true;
			case "Card View":
				return data?.data?.cardView === 1 ? false : true;
			case "Compliance Status":
				return data?.data?.compliance === 1 ? false : true;
			case "Site Attendance":
				return data?.data?.siteAttendance === 1 ? false : true;
			default:
				return false;
		}
	};

	useEffect(() => {
		if (error) {
			console.log(error);
			return;
		}
		dispatch(setSubscriptionMetadata(data?.data));
		if (data?.data && !isLoading) {
			dashboardSubmoduleData.forEach((module: any) => {
				if (shouldHideSubModule(module.label, data?.data)) {
					module.hide = true;
				} else {
					module.hide = false;
				}
			});
			setShowModules(true);
		}
	}, [data, isLoading]);

	const userReadPermissionRD = useAdminEntitlements("dashboard:overview", "read");
	const userReadPermissionCV = useAdminEntitlements("dashboard:cardView", "read");
	const userReadPermissionMV = useAdminEntitlements("dashboard:liveLocation", "read");
	const userReadPermissionTimeline = useAdminEntitlements("dashboard:timeline", "read");
	const userReadPermissionCompliance = useAdminEntitlements("dashboard:complainceStatus", "read");
	const userReadPermissionSiteAttendance = useAdminEntitlements("dashboard:siteAttendance", "read");
	const userReadPermissionSiteAttendanceBeta = useAdminEntitlements("dashboard:siteAttendance", "read");

	const router = useRouter();

	const isDORead = router.pathname === "/" && userReadPermissionRD;
	const isCVRead = router.pathname.includes("card_view") && userReadPermissionCV;
	const isMVread = router.pathname.includes("live_location") && userReadPermissionMV;
	const isTimelineRead = router.pathname.includes("timeline") && userReadPermissionTimeline;
	const isComplianceRead = router.pathname.includes("compliance_status") && userReadPermissionCompliance;
	const isSiteAttendanceRead = router.pathname.includes("site_attendance") && userReadPermissionSiteAttendance;
	const isSiteAttendanceBetaRead = router.pathname.includes("site_attendance") && userReadPermissionSiteAttendanceBeta;

	const adminInfo = useSelector((state: ROOT_STATE) => state.user);
	const filteredDashboardSubmoduleData = dashboardSubmoduleData.filter((item) => item && !item.hide);

	return (
		<Layout title={title}>
			{!isLoading && showModules && <SubmoduleTabs submoduleData={filteredDashboardSubmoduleData} tab={tab} />}
			{isDORead ||
			isCVRead ||
			isMVread ||
			isTimelineRead ||
			isComplianceRead ||
			isSiteAttendanceRead ||
			isSiteAttendanceBetaRead ? (
				children
			) : (
				<NotAuthorized roleID={adminInfo.adminDetails.roleID} moduleName="Dashboard" />
			)}
		</Layout>
	);
};

export default DashboardLayout;
