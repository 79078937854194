import axios from "../../utils/axios";
import { ACCESS_CONTROL_USER, SAMPLE_DATA } from "../../@types";
import { orgFormStatus } from "../../constants/organizationConstant";
axios.defaults.withCredentials = true;
type RETURN_TYPE = Promise<{ success: boolean; message: string }>;

const checkforDuplicateEmail = async (email: string): RETURN_TYPE => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/admin/checkDuplicateAdminsv2`, {
			adminEmail: email,
		});
		if (res?.data?.duplicatesFound === true) {
			throw new Error(res?.data?.message);
		}
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully checked selected Admin",
		};
	} catch (error: any) {
		console.error(error);
		const errorText = "Duplicate Admin Email found";
		return { success: false, message: errorText };
	}
};
export const saveAdminHierarchyNew = async (
	admin: SAMPLE_DATA,
	deletedAdmins: number[],
	allExecutives: any[],
	extraParams: object
): Promise<{ admins: ACCESS_CONTROL_USER[]; success: boolean; message: string }> => {
	// this is the case for add and edit only, for delete operation email check is not required
	if (deletedAdmins.length === 0 && extraParams["action"] !== orgFormStatus.DELETE) {
		if (extraParams["action"] === orgFormStatus.ADD) {
			const { success, message } = await checkforDuplicateEmail(extraParams["email"]);
			if (!success) return { success, message, admins: [] };
		} else if (extraParams["action"] === orgFormStatus.EDIT && extraParams["isEmailChanged"]) {
			const { success, message } = await checkforDuplicateEmail(extraParams["email"]);
			if (!success) return { success, message, admins: [] };
		}
	}

	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/admin/saveAdminHierarchy/v2`, {
			adminData: admin,
			deletedAdmins: deletedAdmins,
		});
		const data = (await getAdminData(allExecutives)) as ACCESS_CONTROL_USER[];
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully updated selected Admin",
			admins: data,
		};
	} catch (error: any) {
		if (error.response.status === 400) {
			return { success: false, message: error.response.data.status, admins: [] };
		}
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error updating selected Admin";
		return { success: false, message: errorText, admins: [] };
	}
};
export const getAdminData = async (allExecutives: any[]) => {
	try {
		const res = await axios.get(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/admin/pullAdmins/v2`, {
			headers: { credentials: "include" },
		});
		const admins = (res.data?.data?.dataSource as ACCESS_CONTROL_USER[]) || [];
		const updatedAdmins = admins.map((user) => {
			const admin = admins.find((adm) => user.parentId === adm.id);
			const employees = allExecutives?.filter((emp: any) => emp.parentAdminID === user.id);
			return {
				...user,
				employees,
				reportingManager: admin ? admin.firstname + " " + admin.lastname : "-",
			};
		});
		return updatedAdmins;
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error updating selected Admin";
		return { success: false, message: errorText };
	}
};
