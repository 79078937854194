import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Link from "next/link";
import { FC, useState } from "react";
import { EXECUTIVE_USER } from "../../@types";
import FEDeleteModal from "./FieldExecutive/FEDeleteModal";
type Props = {
	link?: string;
	handleUserId?: () => void;
	// eslint-disable-next-line no-unused-vars
	deleteHandler: (executive: number | null, transfer: boolean) => void;
	name: string;
	executives?: EXECUTIVE_USER[];
	isTest: boolean;
	userWritePermission:any;
	selectedRows:any;
};
const ColumnActionButtons: FC<Props> = ({ link, handleUserId, deleteHandler, name, executives, isTest, selectedRows,userWritePermission }) => {
	const [open, setOpen] = useState(false);
	return (
        <div className="action-buttons">
			{link &&
				link?.length > 0 &&
				(isTest ? (
					<button style={{ cursor: "no-drop" }} disabled={!userWritePermission}>
						<EditOutlinedIcon htmlColor="#8F8F8F" />
					</button>
				) : (
					<Link prefetch={false} href={link} passHref legacyBehavior>
						<button disabled={!userWritePermission}>
							<EditOutlinedIcon htmlColor="#8F8F8F" />
						</button>
					</Link>
				))}
			{handleUserId && (
				<button onClick={() => handleUserId()} disabled={!userWritePermission}>
					<EditOutlinedIcon htmlColor="#8F8F8F" />
				</button>
			)}
			<button
				onClick={(e) => {
					e.stopPropagation();
					setOpen(true);
				}} disabled={!userWritePermission}>
				<DeleteIcon htmlColor="#F54747" />
			</button>
			{open && (
				<FEDeleteModal
					deleteHandler={deleteHandler}
					handleClose={() => setOpen(false)}
					name={name}
					executives={executives!}
					selectedRows={selectedRows}
				/>
			)}
		</div>
    );
};

export default ColumnActionButtons;
