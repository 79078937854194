// redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// libraries
import "moment-timezone";
// query
import { SHIFT_TEMPLATE_TYPE, USER_SHIFT_TYPE } from "../../../@types";
// utils
import axios from "../../../utils/axios";

export const getShiftsData = createAsyncThunk("shifts/getShiftsData", async (_, thunkAPI) => {
	try {
		return await axios.get(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/shifts/v2`);
	} catch (error) {
		return thunkAPI.rejectWithValue("Error fetching shifts data");
	}
});

const formatUserShiftsData = (shifts: Record<string, USER_SHIFT_TYPE>[]): USER_SHIFT_TYPE[] => {
	const employeeIDs = Object.keys(shifts);
	return employeeIDs.map((employeeID) => ({ ...shifts[employeeID], employeeID, id: employeeID }));
};

const formatShiftsData = (
	editableShifts: Record<string, SHIFT_TEMPLATE_TYPE>[],
	readOnlyShifts: Record<string, SHIFT_TEMPLATE_TYPE>[]
): SHIFT_TEMPLATE_TYPE[] => {
	const editableShiftsIDs = Object.keys(editableShifts);
	const readOnlyShirtsIDs = Object.keys(readOnlyShifts);

	const editableShiftsArray = editableShiftsIDs.map(
		(editableShiftID: any) =>
			({
				...editableShifts[editableShiftID],
				readOnly: false,
				shiftTemplateID: editableShiftID,
			} as SHIFT_TEMPLATE_TYPE)
	);
	const readOnlyShiftsArray = readOnlyShirtsIDs.map(
		(readOnlyShiftID: any) =>
			({
				...readOnlyShifts[readOnlyShiftID],
				readOnly: true,
				shiftTemplateID: readOnlyShiftID,
			} as SHIFT_TEMPLATE_TYPE)
	);
	return [...editableShiftsArray, ...readOnlyShiftsArray];
};

const initialState = {
	shiftTemplates: [] as SHIFT_TEMPLATE_TYPE[],
	userShifts: [] as USER_SHIFT_TYPE[],
	filteredUserShifts: [] as USER_SHIFT_TYPE[],
	loading: true,
	searchTerm: "",
};

const shiftSlice = createSlice({
	name: "shifts",
	initialState,
	reducers: {
		setUserShifts: (state, action) => {
			state.userShifts = action.payload.userShifts;
		},
		setShiftTemplates: (state, action) => {
			state.shiftTemplates = action.payload.shiftTemplates;
		},
		setSearchTerm: (state, action) => {
			state.searchTerm = action.payload;
			state.filteredUserShifts = state.userShifts.filter((shift) =>
				(shift?.firstName + shift?.lastName)
					.replace(/ /g, "")
					.toLowerCase()
					?.includes(state.searchTerm.replace(/ /g, "").toLowerCase())
			);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getShiftsData.fulfilled, (state, action: any) => {
			const { shifts, editableShiftTemplates, readOnlyShiftTemplates } = action.payload.data.data;
			state.shiftTemplates = formatShiftsData(editableShiftTemplates, readOnlyShiftTemplates);
			state.userShifts = formatUserShiftsData(shifts);
			state.filteredUserShifts = formatUserShiftsData(shifts);
			state.loading = false;
		});
		builder.addCase(getShiftsData.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getShiftsData.rejected, (state) => {
			state.loading = false;
		});
	},
});

export const { setUserShifts, setShiftTemplates, setSearchTerm } = shiftSlice.actions;

export default shiftSlice.reducer;
