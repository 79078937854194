/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import _cloneDeep from "lodash/cloneDeep";
import _orderBy from "lodash/orderBy";
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { FORM_QUESTION_TYPE, FORM_SECTION_TYPE, FORM_TEMPLATE_TYPE } from "../../../@types";
import { ROOT_STATE } from "../../../redux";
import FormQuestions from "./FormQuestions";
import FormSectionHeader from "./FormSectionHeader";
import { updateFormSection, updateFormTemplate } from "./FormUtils";
import { TextField } from "@mui/material";
import moment from "moment";
type Props = {
	formTemplate: FORM_TEMPLATE_TYPE;
	setFormTemplate: Dispatch<SetStateAction<FORM_TEMPLATE_TYPE>>;
	userWritePermission: boolean;
};
const FormBuilder: FC<Props> = ({ formTemplate, setFormTemplate, userWritePermission }) => {
	const user = useSelector((state: ROOT_STATE) => state.user);
	const [currentElementId, setCurrentElementId] = useState<string | null>(
		formTemplate.formSections[0].formSectionID || null
	);
	const dragCurrentItem = useRef<{
		questionIndex: number | null;
		sectionIndex: number | null;
	}>({
		questionIndex: null,
		sectionIndex: null,
	});
	const dragOverItem = useRef<{
		questionIndex: number | null;
		sectionIndex: number | null;
	}>({
		questionIndex: null,
		sectionIndex: null,
	});
	const currentSectionId = useRef<number>(0);
	const [isWarningDisplayed, setIsWarningDisplayed] = useState(false);
	const dragStart = (position: number, currentSIdx: number) => {
		if (dragCurrentItem.current?.questionIndex === null || isNaN(dragCurrentItem.current?.questionIndex!))
			dragCurrentItem.current = {
				questionIndex: position,
				sectionIndex: currentSIdx,
			};
	};

	const dragEnter = (position: number, currentSIdx: number) => {
		dragOverItem.current = {
			questionIndex: position,
			sectionIndex: currentSIdx,
		};
	};

	const drop = (sIdx: number) => {
		if (
			dragCurrentItem.current === null ||
			dragCurrentItem.current.questionIndex === null ||
			dragCurrentItem.current.sectionIndex === null ||
			dragOverItem.current === null ||
			dragOverItem.current.questionIndex === null ||
			dragOverItem.current.sectionIndex === null ||
			(dragCurrentItem.current.questionIndex === dragOverItem.current.questionIndex &&
				dragCurrentItem.current.sectionIndex === dragOverItem.current.sectionIndex)
		) {
			dragCurrentItem.current.questionIndex = null;
			dragOverItem.current.questionIndex = null;
			return;
		}
		const { tempFormTemplate } = updateFormSection(formTemplate, sIdx);
		tempFormTemplate.lastModifiedByAdminID = user.adminDetails.adminID!;
		tempFormTemplate.formSections[dragCurrentItem.current.sectionIndex].lastModifiedByAdminID =
			user.adminDetails.adminID!;
		tempFormTemplate.formSections[dragOverItem.current.sectionIndex].lastModifiedByAdminID = user.adminDetails.adminID!;
		const toMoveQuestion = _cloneDeep(
			tempFormTemplate.formSections[dragCurrentItem.current.sectionIndex].formQuestions[
				dragCurrentItem.current.questionIndex
			]
		);
		tempFormTemplate.formSections[dragCurrentItem.current.sectionIndex].formQuestions.splice(
			dragCurrentItem.current.questionIndex,
			1
		);
		tempFormTemplate.formSections[dragOverItem.current.sectionIndex].formQuestions.splice(
			dragOverItem.current.questionIndex,
			0,
			toMoveQuestion
		);
		tempFormTemplate.formSections[dragCurrentItem.current.sectionIndex].formQuestions.forEach((question, index) => {
			question.sequence = index;
		});
		tempFormTemplate.formSections[dragOverItem.current.sectionIndex].formQuestions.forEach((question, index) => {
			question.sequence = index;
		});
		dragCurrentItem.current = {
			questionIndex: null,
			sectionIndex: null,
		};
		dragOverItem.current = {
			questionIndex: null,
			sectionIndex: null,
		};
		setFormTemplate(tempFormTemplate);
	};

	const addNewQuestion = () => {
		const { tempFormTemplate, time } = updateFormTemplate(formTemplate);
		tempFormTemplate.lastModifiedByAdminID = user.adminDetails.adminID!;

		const tempQuestions = _cloneDeep(tempFormTemplate.formSections[currentSectionId.current].formQuestions);
		const newQuestion: FORM_QUESTION_TYPE = {
			questionID: uuidv4(),
			questionType: "varchar",
			createdByAdminID: user.adminDetails.adminID!,
			createdTs: time,
			lastModifiedByAdminID: time,
			clientsInRange: false,
			lastModifiedTs: time,
			options: [
				{
					correct: null,
					optionValue: "",
					createdByAdminID: user.adminDetails.adminID!,
					lastModifiedByAdminID: user.adminDetails.adminID!,
					createdTs: time,
					lastModifiedTs: time,
					nextSection: null,
				},
			],
			question: "",
			required: false,
			score: formTemplate.defaultPointValue || 1,
			sequence: tempQuestions.length + 1,
		};
		tempQuestions.push(newQuestion);
		tempQuestions.forEach((question, index) => {
			question.sequence = index;
		});
		tempFormTemplate.formSections[currentSectionId.current].formQuestions = tempQuestions;
		setFormTemplate(tempFormTemplate);
	};

	const addNewSection = () => {
		const { tempFormTemplate, time } = updateFormTemplate(formTemplate);
		const tempSections = _cloneDeep(tempFormTemplate.formSections);
		const lastSectionIndex = tempSections.length - 1;
		const newSection: FORM_SECTION_TYPE = {
			formSectionID: uuidv4(),
			sectionTitle: "",
			sectionDescription: "",
			formQuestions: [],
			createdTs: time,
			lastModifiedTs: time,
			seqNumber: tempSections.length,
			lastModifiedByAdminID: user.adminDetails.adminID!,
			createdByAdminID: user.adminDetails.adminID!,
			nextSection: "TERMINATE",
		};
		tempSections.push(newSection);
		tempSections.forEach((section, idx) => ({ ...section, seqNumber: idx }));
		tempSections[lastSectionIndex].nextSection = newSection.formSectionID;
		tempFormTemplate.formSections = tempSections;
		setFormTemplate(tempFormTemplate);
	};

	const handleJumpToNextSection = (sectionId: string, currentSectionIndex: number) => {
		if (!sectionId || sectionId.length === 0) return;
		const { tempFormTemplate } = updateFormSection(formTemplate, currentSectionIndex);
		tempFormTemplate.lastModifiedByAdminID = user.adminDetails.adminID!;
		tempFormTemplate.formSections[currentSectionIndex].lastModifiedByAdminID = user.adminDetails.adminID!;
		tempFormTemplate.formSections[currentSectionIndex].nextSection = sectionId;
		setFormTemplate(tempFormTemplate);
	};
	const handleFormTitleChange = (e: string) => {
		const tempFormTemplate = _cloneDeep(formTemplate);
		tempFormTemplate.formTitle = e;
		tempFormTemplate.lastModifiedByAdminID = user.adminDetails.adminID!;
		const time = moment().valueOf();
		tempFormTemplate.lastModifiedTs = time;
		setFormTemplate(tempFormTemplate);
	};
	const handleFormDescChange = (e: string) => {
		const tempFormTemplate = _cloneDeep(formTemplate);
		tempFormTemplate.formDescription = e;
		tempFormTemplate.lastModifiedByAdminID = user.adminDetails.adminID!;
		const time = moment().valueOf();
		tempFormTemplate.lastModifiedTs = time;
		setFormTemplate(tempFormTemplate);
	};

	useEffect(() => {
		const movingMenu = document.querySelector("[data-id='moving-menu']")! as HTMLElement;
		const activeElement = document.querySelector("[data-id='form-builder'] .active") as HTMLElement;
		const formBuilder = document.querySelector("[data-id='form-builder']")?.getBoundingClientRect()!;
		if (currentElementId === null || activeElement === null || movingMenu === null) return;
		const activeElementBounds = activeElement.getBoundingClientRect();
		const heightBetweenElements = activeElementBounds.top - formBuilder.top;
		const heightBetweenMovingMenu = activeElementBounds.height - movingMenu.getBoundingClientRect().height;
		movingMenu.style.top = `${heightBetweenElements + heightBetweenMovingMenu / 2}px`;
	}, [currentElementId]);

	useEffect(() => {
		var url = window.location.href;
		if (url.includes(formTemplate.formTemplateID)) {
			//includes() method determines whether a string contains specified string.
			setIsWarningDisplayed(true);
		}
	}, []);

	return (
		<div className="form_builder" data-id="form-builder">
			<div>
				{isWarningDisplayed && (
					<p className="form_edit_warning">
						We recommend that you do not alter the form or change the type of question. It is likely that all previous
						form responses will be updated if the information is changed.
					</p>
				)}
				<div className="form_builder-section" style={{ borderTopLeftRadius: "8px", gap: "0px", marginTop: "80px" }}>
					<div className="form_heading">Form</div>
					<div
						className="title"
						style={{ display: "grid", gridTemplateColumns: "1fr auto", alignItems: "center", gap: "10px" }}>
						<TextField
							fullWidth
							inputProps={{
								style: { color: "#000", backgroundColor: "#fff", padding: "10px 8px 5px 8px" },
								maxLength: 200,
							}}
							variant="outlined"
							placeholder="Enter Form Title"
							name="formTitle"
							required
							value={formTemplate.formTitle}
							onChange={(e) => e.target.value.length <= 200 && handleFormTitleChange(e.target.value)}
							disabled={!userWritePermission}
						/>
						<p
							style={{
								fontSize: "12px",
								width: "60px",
								pointerEvents: !userWritePermission ? "none" : "auto",
							}}>
							{formTemplate?.formTitle.length} / 200
						</p>
					</div>
					<div style={{ display: "grid", gridTemplateColumns: "1fr auto", alignItems: "center", gap: "10px" }}>
						<TextField
							fullWidth
							inputProps={{
								style: {
									color: "#000",
									backgroundColor: "#fff",
									padding: "10px 8px",
									flex: "auto",
								},
							}}
							variant="outlined"
							placeholder="Form Description"
							name="formDescription"
							required
							value={formTemplate.formDescription}
							onChange={(e) => e.target.value.length <= 200 && handleFormDescChange(e.target.value)}
							disabled={!userWritePermission}
						/>
						<p style={{ fontSize: "12px", width: "60px", pointerEvents: !userWritePermission ? "none" : "auto" }}>
							{formTemplate?.formDescription.length} / 200
						</p>
					</div>
				</div>
				{Array.isArray(formTemplate.formSections) &&
					formTemplate.formSections.length > 0 &&
					_orderBy(formTemplate.formSections, ["seqNumber"], ["asc"]).map((formSection, sectionIndex) => (
						<>
							<div
								key={formSection.formSectionID + "-" + sectionIndex}
								className={`form_builder-section ${currentElementId === formSection.formSectionID ? "active" : ""}`}
								onMouseDown={() => {
									currentSectionId.current = sectionIndex;
									setCurrentElementId(formSection.formSectionID);
								}}>
								<FormSectionHeader
									id={formSection.formSectionID}
									sectionTitle={formSection.sectionTitle}
									sectionDescription={formSection.sectionDescription || ""}
									length={formTemplate.formSections.length}
									index={sectionIndex}
									currentElementId={currentElementId}
									setFormTemplate={setFormTemplate}
									formTemplate={formTemplate}
									userWritePermission={userWritePermission}
								/>
							</div>
							{Array.isArray(formSection.formQuestions) &&
								formSection.formQuestions.length > 0 &&
								_orderBy(formSection.formQuestions, ["sequence"], ["asc"]).map((formQuestion, questionIndex) => (
									<div
										className={`form_builder-question ${currentElementId === formQuestion.questionID ? "active" : ""}`}
										key={formQuestion.questionID + "-" + questionIndex}
										onMouseDown={() => {
											currentSectionId.current = sectionIndex;
											setCurrentElementId(formQuestion.questionID);
										}}
										draggable
										onDragStart={() => dragStart(questionIndex, sectionIndex)}
										onDragEnter={() => dragEnter(questionIndex, sectionIndex)}
										onDragEnd={() => drop(sectionIndex)}>
										<FormQuestions
											length={formSection.formQuestions.length}
											index={questionIndex}
											id={formQuestion.questionID}
											formSectionIndex={sectionIndex}
											question={formQuestion}
											currentElementId={currentElementId}
											setFormTemplate={setFormTemplate}
											formTemplate={formTemplate}
											userWritePermission={userWritePermission}
										/>
									</div>
								))}
							{sectionIndex < formTemplate.formSections.length - 1 && (
								<div className="section_footer">
									After Section {sectionIndex + 1}
									<select
										value={formSection.nextSection || ""}
										onChange={(e) => handleJumpToNextSection(e.target.value, sectionIndex)}>
										<option value="">Select a Value</option>
										{formTemplate.formSections.map((section, index) => {
											if (sectionIndex >= index) return null;
											return (
												<option key={section.formSectionID + "-" + index} value={section.formSectionID}>
													Jump to Section {index + 1} ({section.sectionTitle})
												</option>
											);
										})}
										<option value="TERMINATE">Submit Form</option>
									</select>
								</div>
							)}
						</>
					))}
			</div>
			<div className="movingMenu" data-id="moving-menu">
				<button type="button" onClick={addNewQuestion}>
					<AddCircleOutlineIcon sx={{ color: "#5f6368" }} />
				</button>
				<button type="button" onClick={addNewSection}>
					<SplitscreenIcon sx={{ color: "#5f6368" }} />
				</button>
			</div>
		</div>
	);
};

export default FormBuilder;
