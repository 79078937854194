import { useQuery } from "@apollo/client";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { MenuItem, Select } from "@mui/material";
import { debounce } from "lodash";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { ATTENDANCE_SETTINGS, FORM_TEMPLATE_TYPE, SITEPOOLS_DATA, SITES_DATA } from "../../../@types";
import { updateAttendanceSettings } from "../../../api/settings";
import { DD_STYLES } from "../../../constants";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import { GET_PARTIAL_FORMS } from "../../../schema";
import { sortByString } from "../../../utils";
import useAdminEntitlements from "../../useAdminEntitlements";
import useFetch from "../../useFetch";
import ReadOnlyButton from "../../common/ReadOnlyButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSelector } from "react-redux";
import TeamSelector from "../../common/TeamSelector";
const renderSelectValue = (value: null | string) => (
	<div>
		Assigning To: <span style={{ marginLeft: "8px", fontWeight: "bold" }}>{value}</span>
	</div>
);
type FETCH_DATA = {
	teamLevel: ATTENDANCE_SETTINGS[];
};
type Props = {
	subsMetadata: any;
};
let once = true;
const TeamLevelAttendanceSettings: FC<Props> = ({ subsMetadata }) => {
	const {
		data: attData,
		loading: attendanceSettingLoading,
		refetch,
	} = useFetch<FETCH_DATA>("/preferences/attendance");
	const { data, loading: loadingForms } = useQuery(GET_PARTIAL_FORMS);
	const { data: dataSites, loading: loadingSites } = useFetch<SITES_DATA[]>("/sites");
	const { data: dataSitePool, loading: loadingSitePool } = useFetch<SITEPOOLS_DATA[]>("/sites/sitepools");

	const dispatch = useDispatch<APP_DISPATCH>();
	const [selectedProfile, setSelectedProfile] = useState<ATTENDANCE_SETTINGS | null>(null);
	const userWritePermission = useAdminEntitlements("settings:attendance", "write");
	const { roleID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);
	const allSitePools = Array.isArray(dataSitePool.sitepools)
		? dataSitePool.sitepools?.sort((a, b) => sortByString(a.name ?? "", b.name ?? ""))
		: [];
	const allSitesData = Array.isArray(dataSites.sites)
		? dataSites.sites?.sort((a, b) => sortByString(a.clientName ?? "", b.clientName ?? ""))
		: [];
	const loading = attendanceSettingLoading || loadingSites || loadingSitePool || loadingForms;

	const [expanded, setExpanded] = useState(false);
	useEffect(() => {
		if (attData?.data?.teamLevel && !loading && once) {
			const defaultProfile =
				attData.data.teamLevel.find((setting) => setting.profileName === ("default" || "Default")) ||
				attData.data.teamLevel[0];
			setSelectedProfile(defaultProfile);
			once = false;
		} else if (attData?.data?.teamLevel && !loading && !once) {
			setSelectedProfile((prev) => prev || attData.data.teamLevel[0]);
		}
	}, [attData?.data?.teamLevel, loading]);

	const handleChange = useCallback(
		async (value: number | string | null, key: string, selectTeams?: number[]) => {
			if (!key || key.length === 0 || !selectedProfile) {
				dispatch(showNotification({ message: "Error: Please try again or Reload the page", severity: "error" }));
				return;
			}
			const updatedSettings = { ...selectedProfile, [key]: value };
			const profileID = selectedProfile.profileID;
			if (!profileID || !selectedProfile) {
				dispatch(showNotification({ message: "Client Side error, Please reload the page", severity: "error" }));
				return;
			}
			if (key === "locationBasedAutoAttendanceSiteID") {
				updatedSettings.locationBasedAutoAttendanceSitePoolID = null;
			}
			if (key === "locationBasedAutoAttendanceSitePoolID") {
				updatedSettings.locationBasedAutoAttendanceSiteID = null;
			}
			if (key === "explicitLogin" && updatedSettings.explicitLogin !== 2) {
				updatedSettings.locationBasedAutoAttendanceSiteID = null;
				updatedSettings.locationBasedAutoAttendanceSitePoolID = null;
			}
			if (
				updatedSettings.explicitLogin === 2 &&
				updatedSettings.locationBasedAutoAttendanceSiteID === null &&
				updatedSettings.locationBasedAutoAttendanceSitePoolID === null
			) {
				setSelectedProfile(updatedSettings);
				dispatch(showNotification({ message: "Please Select at least one Site or Sitepool", severity: "error" }));
				return;
			}
			const { success, message } = await updateAttendanceSettings(updatedSettings, profileID, selectTeams);
			if (success) refetch();
			setSelectedProfile(updatedSettings);
			dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		},
		[selectedProfile]
	);

	const formMenuItems = useMemo(() => {
		if (!data || !data.get_form_metadata_by_companyID_v2) return [];
		return data?.get_form_metadata_by_companyID_v2
			.filter(
				(item: any) =>
					item?.visibility?.teamIDs?.includes(selectedProfile?.profileID) || item?.visibility?.visibleToAll === true
			)
			?.sort((a: any, b: any) => sortByString(a.formTitle ?? "", b.formTitle ?? ""))
			.map((item: Pick<FORM_TEMPLATE_TYPE, "formTemplateID" | "formTitle">) => (
				<MenuItem key={item.formTemplateID} value={item.formTemplateID}>
					{item.formTitle}
				</MenuItem>
			));
	}, [data, selectedProfile]);

	const debouncedChanges = useMemo(
		() =>
			debounce(async function (e: number) {
				handleChange(e, "manualAutoSignOutAfter");
			}, 500),
		[handleChange]
	);
	function changeProfile(id: number) {
		const profile = attData?.data?.teamLevel.find((p) => p.profileID === id);
		if (profile) setSelectedProfile(profile);
	}
	const input = document.getElementById("manualNumber") as HTMLInputElement;
	if (input) input.value = selectedProfile?.manualAutoSignOutAfter?.toString() ?? "";
	return (
		<div className="team-level-attendance-settings">
			{expanded && <TeamSelector expanded={expanded} setExpanded={setExpanded} handleChange={handleChange} />}
			<div className="team-header">
				<div className="team-level-header">
					<PeopleAltOutlinedIcon style={{ fontSize: "18px", marginRight: "10px" }} />
					<p className="title">Team Level Settings</p>
					{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="settings" /> : null}
				</div>
				<button className="add mui-button" onClick={() => setExpanded(!expanded)} disabled={!userWritePermission}>
					<ContentCopyIcon sx={{ marginRight: "5px" }} />
					Copy Team Settings
				</button>
			</div>

			<div className="horizontal-line"></div>
			<Select
				onChange={(e) => changeProfile(+e.target.value!)}
				value={selectedProfile}
				sx={{
					...DD_STYLES,
					maxWidth: "320px",
					marginBottom: "2rem",
					color: "#0F45FF",
				}}
				renderValue={() => renderSelectValue(selectedProfile?.profileName || null)}>
				{attData?.data?.teamLevel
					?.sort((a, b) => sortByString(a.profileName, b.profileName))
					.map((profile) => (
						<MenuItem key={profile.profileID} value={profile.profileID}>
							{profile.profileName}
						</MenuItem>
					))}
			</Select>

			<div className="team-level-grid">
				<p className="label">Punch In/Out Options</p>
				<div className="section">
					<p className="title">Mode</p>
					{selectedProfile?.explicitLogin === 1 && <p className="subtitle">Employee manually marks attendance.</p>}
					{selectedProfile?.explicitLogin === 0 && (
						<p className="subtitle">Automatic punch in/out as soon as shift start/ends.</p>
					)}
					{selectedProfile?.explicitLogin === 2 && (
						<p className="subtitle">Automatic punch in/out as soon as they enter a site(s).</p>
					)}
					<Select
						onChange={(e) => handleChange(e.target.value as number, "explicitLogin")}
						name="explicitLogin"
						disabled={!userWritePermission || loading}
						value={selectedProfile?.explicitLogin ?? 0}
						sx={{ ...DD_STYLES }}>
						<MenuItem value={1}>Manual</MenuItem>
						{subsMetadata?.attendanceTimeBased === 1 && <MenuItem value={0}>Time Based Auto Attendance</MenuItem>}
						{subsMetadata?.attendanceSiteBased === 1 && <MenuItem value={2}>Location Based Auto Attendance</MenuItem>}
					</Select>
				</div>
				{selectedProfile && selectedProfile.explicitLogin === 2 && (
					<>
						<div className="section">
							<p className="title">Sites</p>
							<p className="subtitle">Select site(s) on which their attendance should automatically get marked.</p>
							<Select
								disabled={!userWritePermission || loading}
								onChange={(e) =>
									handleChange(e.target.value === -1 ? null : e.target.value, "locationBasedAutoAttendanceSiteID")
								}
								name="locationBasedAutoAttendanceSiteID"
								value={selectedProfile?.locationBasedAutoAttendanceSiteID ?? -1}
								sx={DD_STYLES}>
								<MenuItem value={-1}>None</MenuItem>
								{allSitesData.map((site) => (
									<MenuItem value={site.clientID} key={site.clientID}>
										{site.clientName}
									</MenuItem>
								))}
							</Select>
						</div>
						<div className="section">
							<p className="title">Site Pools</p>
							<p className="subtitle">Select sitepool(s) on which their attendance should automatically get marked.</p>
							<Select
								disabled={!userWritePermission || loading}
								onChange={(e) =>
									handleChange(e.target.value === -1 ? null : e.target.value, "locationBasedAutoAttendanceSitePoolID")
								}
								name="locationBasedAutoAttendanceSitePoolID"
								value={selectedProfile?.locationBasedAutoAttendanceSitePoolID ?? -1}
								sx={DD_STYLES}>
								<MenuItem value={-1}>None</MenuItem>
								{allSitePools.map((site) => (
									<MenuItem value={site.sitePoolID} key={site.sitePoolID}>
										{site.name}
									</MenuItem>
								))}
							</Select>
						</div>
					</>
				)}
				{selectedProfile && selectedProfile.explicitLogin === 1 && (
					<>
						{subsMetadata?.attendanceSignOutTimeBased === 1 && (
							<div className="section">
								<p className="title">Auto Sign Out</p>
								<p className="subtitle">
									Android Only: Employee is automatically signed out after selected minutes post shift/roster end.
								</p>
								<Select
									disabled={!userWritePermission || loading}
									onChange={(e) => handleChange((e.target.value || 0) as number, "manualAutoSignOutAfter")}
									name="manualAutoSignOutAfter"
									value={selectedProfile?.manualAutoSignOutAfter ?? 0}
									sx={DD_STYLES}>
									<MenuItem
										value={
											selectedProfile?.manualAutoSignOutAfter === -1 ? 0 : selectedProfile?.manualAutoSignOutAfter
										}>
										On
									</MenuItem>
									<MenuItem value={-1}>Off</MenuItem>
								</Select>
								{selectedProfile?.manualAutoSignOutAfter !== -1 && (
									<input
										style={{ marginTop: "15px" }}
										min={0}
										disabled={!userWritePermission || loading}
										type="number"
										className="manualNumber"
										id="manualNumber"
										defaultValue={(selectedProfile?.manualAutoSignOutAfter as number) ?? 0}
										onChange={(event) => debouncedChanges(+event.target.value)}
									/>
								)}
							</div>
						)}
						{subsMetadata?.attendanceEarlyPunchOut === 1 && (
							<div className="section">
								<p className="title">Prevent Early Punch Out</p>
								<p className="subtitle">Prevent Punching out before shift ends time.</p>
								<Select
									disabled={!userWritePermission || loading}
									onChange={(e) => handleChange((e.target.value || 0) as number, "forbidPunchOutBeforeShiftEnd")}
									name="forbidPunchOutBeforeShiftEnd"
									value={selectedProfile?.forbidPunchOutBeforeShiftEnd ?? 0}
									sx={DD_STYLES}>
									<MenuItem value={1}>On</MenuItem>
									<MenuItem value={0}>Off</MenuItem>
								</Select>
							</div>
						)}

						{subsMetadata?.attendanceAuth === 1 && (
							<div className="section">
								<p className="title">Authentication</p>
								<p className="subtitle">Use onboard phone authentication when marking attendance.</p>
								<Select
									disabled={!userWritePermission || loading}
									onChange={(e) => handleChange(e.target.value as number, "authentication")}
									name="authentication"
									value={selectedProfile?.authentication ?? 0}
									style={{ marginTop: "0px" }}
									sx={DD_STYLES}>
									<MenuItem value={1}>Finger Lock / Face Recognition</MenuItem>
									<MenuItem value={2}>Take Selfie</MenuItem>
									<MenuItem value={0}>Off</MenuItem>
								</Select>
							</div>
						)}
					</>
				)}
			</div>
			<div className="team-level-grid">
				<p className="label">Attendance Calculation Options</p>
				<div className="section">
					<p className="title">Calculation method</p>
					{selectedProfile?.attendanceCalcType === 0 && (
						<p className="subtitle">Attendance calculated on the basis of total time spent on duty.</p>
					)}
					{selectedProfile?.attendanceCalcType === 1 && (
						<p className="subtitle">Attendance calculated on the basis of time spent at base site.</p>
					)}
					{selectedProfile?.attendanceCalcType === 2 && (
						<p className="subtitle">Attendance calculated on the basis of time spent at all sites in site pool.</p>
					)}
					{subsMetadata?.attendanceSiteCalculation === 1 ? (
						<Select
							onChange={(e) => handleChange(e.target.value as number, "attendanceCalcType")}
							name="attendanceCalcType"
							disabled={!userWritePermission || loading}
							value={selectedProfile?.attendanceCalcType ?? 0}
							sx={DD_STYLES}>
							<MenuItem value={0}>Time</MenuItem>
							<MenuItem value={1}>Time at base site</MenuItem>
							<MenuItem value={2}>Time at site pool</MenuItem>
						</Select>
					) : (
						<Select
							onChange={(e) => handleChange(e.target.value as number, "attendanceCalcType")}
							name="attendanceCalcType"
							disabled={!userWritePermission || loading}
							value={selectedProfile?.attendanceCalcType ?? 0}
							sx={DD_STYLES}>
							<MenuItem value={0}>Time</MenuItem>
						</Select>
					)}
				</div>

				<div className="section">
					<p className="title">Strict Mode</p>
					<p className="subtitle">Attendance mark in mandatory to count hours worked towards attendance</p>
					<Select
						onChange={(e) => handleChange(e.target.value as number, "strictMode")}
						name="strictMode"
						disabled={!userWritePermission}
						value={selectedProfile?.strictMode ?? ""}
						sx={DD_STYLES}>
						<MenuItem value={1}>On</MenuItem>
						<MenuItem value={0}>Off</MenuItem>
					</Select>
				</div>
			</div>
			{(subsMetadata?.randomReAuth === 1 ||
				subsMetadata?.showAdminName === 1 ||
				subsMetadata?.attendanceForm === 1 ||
				subsMetadata?.attendanceShowVasa === 1) && (
					<div className="team-level-grid">
						<p className="label">Miscellaneous</p>

						{selectedProfile?.authentication !== 0 && subsMetadata?.randomReAuth === 1 && (
							<div className="section">
								<p className="title">Random re-authentication</p>
								<p className="subtitle">Enable random re-authentication</p>
								<Select
									onChange={(e) => handleChange(e.target.value as number, "randomReAuth")}
									name="randomReAuth"
									disabled={!userWritePermission || loading}
									value={selectedProfile?.randomReAuth ?? 0}
									sx={DD_STYLES}>
									<MenuItem value={1}>On</MenuItem>
									<MenuItem value={0}>Off</MenuItem>
								</Select>
							</div>
						)}
						{subsMetadata?.showAdminName === 1 && (
							<div className="section">
								<p className="title">Admin name</p>
								<p className="subtitle">Show admin name on sign in/out reminder</p>
								<Select
									onChange={(e) => handleChange(e.target.value as number, "adminNameOnSignInOutEvent")}
									name="adminNameOnSignInOutEvent"
									disabled={!userWritePermission || loading}
									value={selectedProfile?.adminNameOnSignInOutEvent ?? 0}
									sx={DD_STYLES}>
									<MenuItem value={1}>On</MenuItem>
									<MenuItem value={0}>Off</MenuItem>
								</Select>
							</div>
						)}
						{subsMetadata?.attendanceForm === 1 && (
							<div className="section">
								<p className="title">Attendance Mark-IN Form</p>
								<p className="subtitle">Form to be filled in when marking attendance in</p>
								<Select
									onChange={(e) => handleChange(e.target.value === -1 ? null : e.target.value, "inAttFormID")}
									name="inAttFormID"
									disabled={!userWritePermission || loading}
									value={selectedProfile?.inAttFormID ?? -1}
									sx={DD_STYLES}>
									<MenuItem value={-1}>None</MenuItem>
									{formMenuItems}
								</Select>
							</div>
						)}
						{subsMetadata?.attendanceForm === 1 && (
							<div className="section">
								<p className="title">Attendance Mark-OUT Form</p>
								<p className="subtitle">Form to be filled in when marking attendance out</p>
								<Select
									onChange={(e) => handleChange(e.target.value === -1 ? null : e.target.value, "outAttFormID")}
									name="outAttFormID"
									disabled={!userWritePermission || loading}
									value={selectedProfile?.outAttFormID ?? -1}
									sx={DD_STYLES}>
									<MenuItem value={-1}>None</MenuItem>
									{formMenuItems}
								</Select>
							</div>
						)}
						{subsMetadata?.attendanceShowVasa === 1 && (
							<div className="section">
								<p className="title">Show VASA Tasks</p>
								<p className="subtitle">Show VASA tasks in admin panel and employee apps</p>
								<Select
									onChange={(e) => handleChange(e.target.value as number, "showAutoTask")}
									name="showAutoTask"
									disabled={!userWritePermission || loading}
									value={selectedProfile?.showAutoTask ?? 0}
									sx={DD_STYLES}>
									<MenuItem value={1}>On</MenuItem>
									<MenuItem value={0}>Off</MenuItem>
								</Select>
							</div>
						)}
					</div>
				)}
		</div>
	);
};
export default TeamLevelAttendanceSettings;
