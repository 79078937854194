import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import moment from "moment";
import { Dispatch, FC, FormEvent, SetStateAction } from "react";
import { FORMS_FILTER_COLUMNS, PARTIAL_FORM_TEMPLATE } from "../../../@types";
import IndividualFilter from "../../common/datagrid/IndividualFilter";
type COLUMN_TITLES = "Title" | "Created Date" | "Last Modified Date";
type Props = {
	numFilters: number;
	setFilterOpen: Dispatch<SetStateAction<boolean>>;
	forms: PARTIAL_FORM_TEMPLATE[];
	filters: Record<FORMS_FILTER_COLUMNS, string[]>;
	setFilters: Dispatch<SetStateAction<Record<FORMS_FILTER_COLUMNS, string[]>>>;
};
const FilterForms: FC<Props> = ({ numFilters, setFilterOpen, forms, setFilters, filters }) => {
	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
	};

	const resetFilters = () => {
		setFilters({ formTitle: [], createdTs: [], lastModifiedTs: [] });
	};
	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
		<form onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()} onKeyPress={(e) => e.stopPropagation()}>
			<div className="filter__header">
				<h5>
					<FilterAltOutlinedIcon />
					Filters
					<span className="right-notification center" style={{ display: "flex", padding: 0 }}>
						{numFilters}
					</span>
				</h5>
				<button onClick={() => setFilterOpen(false)}>
					<CloseSharpIcon htmlColor="#42444A" />
				</button>
			</div>
			<div className="accordion__group">
				<IndividualFilter<COLUMN_TITLES, FORMS_FILTER_COLUMNS>
					filters={filters}
					name="formTitle"
					title="Title"
					data={forms.map((form) => form.formTitle)}
					ids={forms.map((form) => form.formTitle)}
					setFilters={setFilters}
				/>
				<IndividualFilter<COLUMN_TITLES, FORMS_FILTER_COLUMNS>
					filters={filters}
					name="createdTs"
					title="Created Date"
					ids={forms.map((form) => JSON.stringify(form.createdTs))}
					data={forms.map((form) => moment(form.createdTs).format("DD-MM-YYYY"))}
					setFilters={setFilters}
				/>
				<IndividualFilter<COLUMN_TITLES, FORMS_FILTER_COLUMNS>
					filters={filters}
					name="lastModifiedTs"
					title="Last Modified Date"
					ids={forms.map((form) => JSON.stringify(form.lastModifiedTs))}
					data={forms.map((form) => moment(form.lastModifiedTs).format("DD-MM-YYYY"))}
					setFilters={setFilters}
				/>
			</div>
			<div className="filter__buttons">
				<button onClick={resetFilters} disabled={numFilters===0} type="reset">
					Reset
				</button>
			</div>
		</form>
	);
};

export default FilterForms;
