import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { CircularProgress, Modal } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ENTITY_METADATA, EXPENSE_REQUEST_TYPE_V1 } from "../../../@types";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import { checkDateFormat, findCurrentAdvanceExpenseConveyanceAuth } from "../../../utils";
import useAdminEntitlements from "../../useAdminEntitlements";
import { formatDateTo_DDMMYYYY } from "../../../utils/validations";

// componests
// import ExpenseDescriptionV1 from "./ExpenseDescriptionV1";
import ExpenseFormV1 from "./ExpenseFormV1";

// query
import { useMutation } from "@apollo/client";
import { UPDATE_EXPENSE_SUMMARY_AND_AUTHORIZATION } from "../../../schema/expenses";

import moment from "moment";
import ExpenseDescriptionV1 from "./ExpenseDescriptionV1";
type Props = {
	open: boolean;
	handleClose: () => void;
	selectedExpenseId: string;
	setSelectedExpenseId: Dispatch<SetStateAction<string | null>>;
	filteredExpenses: EXPENSE_REQUEST_TYPE_V1[];
	requests: EXPENSE_REQUEST_TYPE_V1[];
	loadingEntity: boolean;
	refetch: () => void;
	entityMetadata: ENTITY_METADATA[];
	refetchEntity: () => void;
};

const ExpenseDetailsModalV1 = ({
	open,
	handleClose,
	selectedExpenseId,
	filteredExpenses,
	setSelectedExpenseId,
	loadingEntity,
	refetch,
	requests,
	entityMetadata,
	refetchEntity,
}: Props) => {
	const user = useSelector((state: ROOT_STATE) => state.user);
	const [selectedExpense, setSelectedExpense] = useState(
		requests.find((exp) => exp.expenseSummaryID === selectedExpenseId)!
	);
	// any error related to file or image upload
	const [imageOrFileError, setImageOrFileError] = useState("");
	// any custom error related to custom fields
	const [customFieldErrors, setCustomFieldErrors] = useState<any[]>([]);
	const [startLoading, setStartLoading] = useState(false);
	const userWritePermission = useAdminEntitlements("expense:expenseRequests", "write");
	const dispatch = useDispatch<APP_DISPATCH>();
	const [update_expense_summary_and_authorization, { loading: isUpdated, error: updateError }] = useMutation(
		UPDATE_EXPENSE_SUMMARY_AND_AUTHORIZATION
	);
	const handleSubmit = async (type: "approve" | "reject" | "payOut") => {
		let state;
		let status;
		switch (type) {
			case "approve":
				state = "Approved";
				status = 1;
				break;
			case "reject":
				state = "Rejected";
				status = 0;
				break;
			case "payOut":
				state = "Paid Out";
				status = 2;
				break;
			default:
				throw new Error("Invalid type");
		}
		if (!type || !selectedExpense || !selectedExpenseId) {
			dispatch(
				showNotification({ message: `Expense or ExpenseId ${selectedExpenseId} not found `, severity: "error" })
			);
			return;
		}
		const newRequest = { ...selectedExpense };
		const pendingExpenseAuth = findCurrentAdvanceExpenseConveyanceAuth(newRequest);
		// if there are multiple pending expense Auth, the throw a error
		if (typeof pendingExpenseAuth === "string") {
			dispatch(showNotification({ message: pendingExpenseAuth, severity: "error" }));
			return;
		}
		// otherwise take the auth and check for amount only when the amount is being approved
		if (type === "approve") {
			if (pendingExpenseAuth.reimbursementAmount === null || isNaN(pendingExpenseAuth.reimbursementAmount)) {
				dispatch(showNotification({ message: "Enter an amount", severity: "error" }));
				return;
			}
		}
		if (imageOrFileError.length > 0) {
			dispatch(
				showNotification({
					message: imageOrFileError,
					severity: "error",
				})
			);
			return;
		}

		if (customFieldErrors.length > 0) {
			dispatch(
				showNotification({
					message: customFieldErrors[0],
					severity: "error",
				})
			);
			return;
		}
		const dateTypeCustomFields = entityMetadata?.filter((value: ENTITY_METADATA) => value.dataType === "date");

		const inputCustomFieldsComplex = selectedExpense.customFieldsComplex?.map((item: any) => {
			const data = dateTypeCustomFields.find((value: ENTITY_METADATA) => value.fieldID === item.fieldID);
			return {
				fieldID: item.fieldID,
				fieldValue: data ? checkDateFormat(item.fieldValue) : item.fieldValue,
				fieldValue2: item.fieldValue2,
				fieldValue3: item.fieldValue3,
				selectedOptions: item.selectedOptions,
				selectedFieldValues: item.selectedFieldValues,
			};
		});
		const expenseSummaryAndAuthorizationInput = {
			expenseSummaryID: newRequest.expenseSummaryID,
			lastModifiedTs: moment().valueOf(),
			status: status,
			expensesAuthorizationInput:
				type === "payOut" // if type is payOut then this parameter should be null
					? null
					: {
						expenseSummaryID: newRequest.expenseSummaryID,
						reimbursementAmount: type === "approve" ? pendingExpenseAuth.reimbursementAmount : null,
						rejectReason: type === "reject" ? pendingExpenseAuth.comment : null,
						comment: pendingExpenseAuth.comment,
					},
			customFieldsComplex: inputCustomFieldsComplex,
		};
		setStartLoading(true);

		const data = await update_expense_summary_and_authorization({
			variables: { expenseSummaryAndAuthorizationInput },
		});
		if (data.errors || updateError) {
			dispatch(
				showNotification({
					message: "error occurred while updating expense",
					severity: "error",
				})
			);
		} else {
			dispatch(
				showNotification({
					message: updateError ? `Couldn't ${state} Request` : `Successfully ${state} Selected Request`,
					severity: updateError ? "error" : "success",
				})
			);
		}
		refetch();
	};

	const canCurrentAdminPayout =
		selectedExpense &&
		(selectedExpense.status === 1 || selectedExpense.status === 3) &&
		selectedExpense.authorizationInfo?.payoutAdminID === user.adminDetails.adminID;

	const canApprove =
		selectedExpense && selectedExpense.authorizationInfo?.pendingAdmin?.adminID === user.adminDetails.adminID!;
		
	const nextExpense = () => {
		const index = filteredExpenses.findIndex((exp) => exp.expenseSummaryID === selectedExpenseId);
		return filteredExpenses[index + 1]?.expenseSummaryID;
	};

	const prevExpense = () => {
		const index = filteredExpenses.findIndex((exp) => exp.expenseSummaryID === selectedExpenseId);
		return filteredExpenses[index - 1]?.expenseSummaryID;
	};

	useEffect(() => {
		setSelectedExpense(requests.find((exp) => exp.expenseSummaryID === selectedExpenseId)!);
	}, [requests, selectedExpenseId]);
	useEffect(()=>{
		setStartLoading(false)
	},[selectedExpense]);
	return (
		<Modal open={open} onClose={handleClose} aria-labelledby="Detailed Expense Modal">
			<section className="view_request expense_modal">
				<section className="modal_title">
					<div className="left">
						<h3>
							{selectedExpense?.userInfo?.firstName + " " + selectedExpense?.userInfo?.lastName}{" "}
							{`(${formatDateTo_DDMMYYYY(selectedExpense?.date)})`}
						</h3>
						<div className="move_buttons">
							{prevExpense() && (
								<button onClick={() => setSelectedExpenseId(prevExpense())} title="prev">
									<KeyboardArrowLeftOutlinedIcon />
								</button>
							)}
							{nextExpense() && (
								<button onClick={() => setSelectedExpenseId(nextExpense())} className="ml-auto" title="next">
									<KeyboardArrowRightOutlinedIcon />
								</button>
							)}
						</div>
					</div>
					<button onClick={() => setSelectedExpenseId(null)}>
						<CloseIcon />
					</button>
				</section>
				{selectedExpense && Object.keys(selectedExpense).length > 0 ? (
					<form onSubmit={(e) => e.preventDefault()}>
						<ExpenseFormV1
							selectedExpense={selectedExpense}
							setSelectedExpense={setSelectedExpense}
							canApprove={canApprove}
						/>

						<header>
							{selectedExpense.status === 2 && <div className="status">Paid Out</div>}
							{selectedExpense.status === 0 && <div className="status Rejected">Rejected</div>}
							{selectedExpense.status === 1 &&
								(canCurrentAdminPayout ? (
									<button className="status" onClick={() => handleSubmit("payOut")}
									disabled={!userWritePermission || startLoading}
									>
										{startLoading || isUpdated ? (
											<CircularProgress style={{ width: "20px", height: "20px", color: "white" }} />
										) : (
											"Pay Out"
										)}
									</button>
								) : (
									<div className="status Approved">Approved</div>
								))}
						</header>
						{(selectedExpense.status === -1 || selectedExpense.status === 3) && (
							<div style={{ marginLeft: "auto" }} className="approval_actions">
								<button
									type="button"
									data-id="reject"
									className="Rejected"
									disabled={!canApprove || !userWritePermission || startLoading}
									onClick={() => handleSubmit("reject")}>
									{startLoading || isUpdated ? (
										<CircularProgress style={{ width: "20px", height: "20px", color: "white" }} />
									) : (
										"Reject"
									)}
								</button>
								<button
									type="submit"
									data-id="approve"
									disabled={!canApprove || !userWritePermission || startLoading}
									onClick={() => handleSubmit("approve")}>
									{startLoading || isUpdated ? (
										<CircularProgress style={{ width: "20px", height: "20px", color: "white" }} />
									) : (
										"Approve"
									)}
								</button>
							</div>
						)}
						{userWritePermission === 0 && (
							<ul className="errors">
								<li>You don't have Permission to Approve/Reject this request</li>
							</ul>
						)}

						<ExpenseDescriptionV1
							selectedExpense={selectedExpense}
							setSelectedExpense={setSelectedExpense}
							loadingEntity={loadingEntity}
							refetchEntity={refetchEntity}
							entityMetadata={entityMetadata}
							setImageOrFileError={setImageOrFileError}
							setCustomFieldErrors={setCustomFieldErrors}
							currencyCode={""}
						/>
					</form>
				) : (
					<div className="small_circular-spinner">
						<CircularProgress />
					</div>
				)}
			</section>
		</Modal>
	);
};

export default ExpenseDetailsModalV1;
