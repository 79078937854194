import React, { Dispatch, FC, SetStateAction, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { updateAttendanceStatus } from "../../../api/attendance";
import { useDispatch, useSelector } from "react-redux";
import { APP_DISPATCH, ROOT_STATE, setAttendanceStatus, showNotification } from "../../../redux";
import { ATTENDANCE_STATUS_ROWS } from "../../../@types";

type DATA_TO_UPDATE = {
	employeeID: string;
	from: string;
	to: string;
};

type Props = {
	setLoader: Dispatch<SetStateAction<boolean>>;
	params: any;
	setRefetch: Dispatch<SetStateAction<number>>;
	userWritePermission:boolean;
};

const dropDownData = [
	{ id: "PR", value: "PR" },
	{ id: "HD", value: "HD" },
	{ id: "AB", value: "AB" },
	{ id: "CL", value: "CL" },
	{ id: "SL", value: "SL" },
	{ id: "EL", value: "EL" },
	{ id: "WO", value: "WO" },
	{ id: "PE", value: "PE" },
	{ id: "PH", value: "PH" },
];

const getColorBasedOnStatus = (value: string) => {
	switch (value) {
		case "PR":
			return {
				symbolColor: "#40A636",
				backgroundColor: "#ECF6EB",
			};
		case "HD":
			return {
				symbolColor: "#EF9943",
				backgroundColor: "#FEF6EF",
			};
		case "AB":
			return {
				symbolColor: "#F54747",
				backgroundColor: "#FEEDED",
			};
		case "CL":
			return {
				symbolColor: "#0F45FF",
				backgroundColor: "#E8EDFF",
			};
		case "SL":
			return {
				symbolColor: "#d705f1d9",
				backgroundColor: "#D9F0FD",
			};
		case "EL":
			return {
				symbolColor: "#611414c4",
				backgroundColor: "#ECF6EB",
			};
		case "WO":
			return {
				symbolColor: "#466bff",
				backgroundColor: "#ECF6EB",
			};
		case "PE":
			return {
				symbolColor: "#ffd700",
				backgroundColor: "#ECF6EB",
			};
		case "PH":
			return {
				symbolColor: "#466bff",
				backgroundColor: "#ECF6EB",
			};
		default:
			return {
				symbolColor: "#000",
				backgroundColor: "#fff",
			};
	}
};

const getNameFromCode = (code: string) => {
	switch (code) {
		case "PR":
			return "totalDaysPresent";
		case "HD":
			return "totalDaysHalfDay";
		case "AB":
			return "totalDaysAbsent";
		case "WO":
			return "totalDaysNotWorking";
		case "PE":
			return "totalDaysPenalty";
		default:
			return "";
	}
};

// eslint-disable-next-line no-unused-vars
const StatusDropdown: FC<Props> = ({ setLoader, params, setRefetch, userWritePermission }) => {
	const [value, setvalue] = useState(params["formattedValue"]);
	const dispatch = useDispatch<APP_DISPATCH>();
	const { attendanceStatus } = useSelector((state: ROOT_STATE) => state.attendanceStatus);

	/**need to test */
	const updateCount = (params: DATA_TO_UPDATE, attendanceStatus: ATTENDANCE_STATUS_ROWS[]) => {
		let tempRows = [];
		tempRows = attendanceStatus.map((row) => {
			if (row.employeeID === params.employeeID) {
				const fromCount = row[getNameFromCode(params.from)];
				const toCount = row[getNameFromCode(params.to)];
				return { ...row, [getNameFromCode(params.from)]: +fromCount - 1, [getNameFromCode(params.to)]: +toCount + 1 };
			}
			return { ...row };
		});
		dispatch(setAttendanceStatus({ attendanceStatus: JSON.parse(JSON.stringify(tempRows)) }));
	};

	const onStatusChange = async (e: any) => {
		e.preventDefault();

		const dataToSend = {
			[params.row.employeeID]: {
				[params.field]: {
					from: value,
					to: e.target.value,
				},
			},
		};
		setLoader(true);
		const { success, message } = await updateAttendanceStatus(dataToSend);
		setLoader(false);

		if (success) {
			setRefetch((e) => e + 1);
			setvalue(e.target.value);
			const dateToUpdate: DATA_TO_UPDATE = { employeeID: params.row.employeeID, from: value, to: e.target.value };
			updateCount(dateToUpdate, attendanceStatus);
		}
		dispatch(showNotification({ message: message, severity: success ? "success" : "error" }));
	};
	return (
		<div>
			{value !== "-" ? (
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={value}
					onChange={(e) => {
						if(!userWritePermission) return;
						onStatusChange(e);
					}}
					sx={{
						width: "8rem",
						height: "2.3rem",
						borderRadius: "10px",
						fontSize: "14px",
						fontFamily: "SFUIText-Regular",
						color: getColorBasedOnStatus(value).symbolColor,
						backgroundColor: getColorBasedOnStatus(value).backgroundColor,
					}}
					disabled={!userWritePermission}>
					{dropDownData.map((item) => (
						<MenuItem key={item.id} value={item.id}>
							{item.value}
						</MenuItem>
					))}
				</Select>
			) : (
				<div>-</div>
			)}
		</div>
	);
};

export default StatusDropdown;
