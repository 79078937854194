//react
import { useEffect, useState } from "react";

// types, constants, utils
import { PRODUCT_TYPE, PROFILES_DATA } from "../../../../@types";

//mui
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Checkbox,
	FormControlLabel,
	Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type props = {
	title: string;
	data: PRODUCT_TYPE[];
	filters: any;
	name: string;
	onChangeCheckbox: any;
	profiles:PROFILES_DATA[];
};
function IndividualVisibiltyFilter({ data, onChangeCheckbox, name, title, filters, profiles }: props) {
	const [localList, setLocalList] = useState<number[]>([]);
	const [searchInput, setSearchInput] = useState("");

	const getUniqueEntries = (data: PRODUCT_TYPE[]) => {
		const tempArr: number[] = [];
		const obj = {};
		data.forEach((item: PRODUCT_TYPE) => {
			item[name].map((x:any) =>{
				if(obj[x.value] == undefined && x!== null && x!== undefined){
					obj[x.value] = 1;
					tempArr.push(x.value);
				}
			})
		});
		return tempArr;
	};

	const handleSearching = (searchTerm: string) => {
		if (searchTerm === "") {
			const tempArray = getUniqueEntries(data);
			setLocalList(tempArray);
			return;
		}

		// to store the unique profile ID 
		const tempProfileID: number[] = [];

		const prof = profiles.filter((i) =>
			i.profileName.replace(/ /g, "").toLowerCase().includes(searchTerm.replace(/ /g, "").toLowerCase())
		);
		prof.forEach((x) => {
			if (!tempProfileID.includes(x.profileID)) {
				tempProfileID.push(x.profileID);
			}
		});

		setLocalList(tempProfileID);
	};

	useEffect(() => {
		const tempArr: number[] = getUniqueEntries(data);
		setLocalList(tempArr);
	}, [data]);

	return (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<h6>Visible To</h6>
			</AccordionSummary>
			<AccordionDetails sx={{ margin: 0, padding: 0 }}>
				<>
					<FormControlLabel
						sx={{
							border: "1px solid #dfdfdf",
							width: "100%",
							marginLeft: "15px",
							borderBottom: "None",
							height: "48px",
							display: "flex",
							justifyContent: "space-between",
							flexDirection: "row-reverse",
							padding: "0 23px 0 15px",
						}}
						label={
							<Typography variant="h6" style={{ fontSize: "14px" }}>
								Everyone
							</Typography>
						}
						control={
							<Checkbox
								size="small"
								checked={filters.hasOwnProperty("visibility") && filters[name].includes(1)}
								value={1}
								onChange={(e) => onChangeCheckbox(e, 1, "visibility")}
							/>
						}

						// labelPlacement="start"
					/>
				</>
			</AccordionDetails>
			<Box sx={{ margin: 0, padding: 0, marginLeft: "15px", borderLeft: "1px solid #dfdfdf" }}>
				<Accordion sx={{ borderTop: "1px solid #dfdfdf" }}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<h6>Teams</h6>
					</AccordionSummary>
					<AccordionDetails>
						<input
							type="search"
							className="search"
							value={searchInput}
							placeholder={`Search Team`}
							onChange={(e) => {
								setSearchInput(e.target.value);
								handleSearching(e.target.value);
							}}
						/>
						{localList && localList?.length === 0 ? (
							<Typography variant="body2">No data found</Typography>
						) : (
							localList
								.filter((item) => item !== 1)
								.map((list: any, index: any) => (
									<FormControlLabel
										key={index}
										sx={{ width: "100%" }}
										label={profiles.find((profile) => profile.profileID === list)?.profileName}
										control={
											<Checkbox
												size="small"
												checked={filters.hasOwnProperty("visibility") && filters[name].includes(list)}
												value={list}
												onChange={(e) => onChangeCheckbox(e, list, "visibility")}
											/>
										}
									/>
								))
						)}
					</AccordionDetails>
				</Accordion>
			</Box>
		</Accordion>
	);
}
export default IndividualVisibiltyFilter;
