import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { useState } from "react";

const LoginSlider = () => {
	const [selected, setSelected] = useState<0 | 1 | 2>(0);
	return (
		<section className="login__slider">
			<header>
				<img src="/images/login/unolo_logo.png" width={120} height={30} />
			</header>
			<div className="slider">
				<figure className={`first__slide ${selected === 0 ? "selected" : ""}`}>
					<img src="/images/login/1.jpg" height={800} width={800} />
					<figcaption>
						<h6>Meet real time tracking software</h6>
						<p>Real-time location tracking, attendance & task management tool to manage your field force.</p>
					</figcaption>
				</figure>
				<figure className={`second__slide ${selected === 1 ? "selected" : ""}`}>
					<img src="/images/login/2.jpg" height={800} width={800} />
					<figcaption>
						<h6>Your Field Force with Unolo</h6>
						<p>
							Our field force management software suite - location tracking, automated attendance and task management -
							is everything your business needs to grow.
						</p>
					</figcaption>
				</figure>
				<figure className={`third__slide ${selected === 2 ? "selected" : ""}`}>
					<img src="/images/login/3.png" height={800} width={800} />
					<figcaption>
						<h6>Unolo can help you with your drive for productivity</h6>
						<p>Real-time location tracking, attendance & task management tool to manage your field force.</p>
					</figcaption>
				</figure>
			</div>
			<div className="change__buttons">
				<button
					onClick={() => {
						if (selected > 0) {
							setSelected((selected - 1) as 0 | 1 | 2);
						}
					}}>
					<ArrowBackIosNewOutlinedIcon />
				</button>
				<div className="change__buttons--dots">
					<button className={`${selected === 0 ? "selected" : ""}`} onClick={() => setSelected(0)}>
						<span />
					</button>
					<button className={`${selected === 1 ? "selected" : ""}`} onClick={() => setSelected(1)}>
						<span />
					</button>
					<button className={`${selected === 2 ? "selected" : ""}`} onClick={() => setSelected(2)}>
						<span />
					</button>
				</div>
				<button
					onClick={() => {
						if (selected < 2) {
							setSelected((selected + 1) as 0 | 1 | 2);
						}
					}}>
					<ArrowForwardIosOutlinedIcon />
				</button>
			</div>
		</section>
	);
};

export default LoginSlider;
