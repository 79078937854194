// react
import { Dispatch, FC, SetStateAction, useId, useState } from "react";
// mui
import Dialog from "@mui/material/Dialog";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import DownloadingOutlinedIcon from "@mui/icons-material/DownloadingOutlined";
// components
import ExcelProcess from "./ExcelProcess";
// libraries
import { utils, writeFile } from "xlsx";
// utils
import useExcelUploadUtil from "../../../../../common/useExcelUploadUtil";

const excelDataOffset = 0;
const excelUploadLimit = 1000;
// TODO:
const entity = "Options";

type Props = {
	setBulkUploadModalOpen: Dispatch<SetStateAction<boolean>>;
	selectedField: any;
	setDetailsAndSettingsForFields: any;
};

const UploadModal: FC<Props> = ({ setBulkUploadModalOpen, selectedField, setDetailsAndSettingsForFields }) => {
	const [upload] = useId();

	const [showValidationModal, setShowValidationModal] = useState(false);
	const [headers, setHeaders] = useState<any>([]);
	const [rowsData, setRowsData] = useState<any>([]);

	const dataToSend = {
		setShowValidationModal,
		setHeaders,
		setRowsData,
		excelDataOffset,
		excelUploadLimit,
		entity,
	};

	/**
	 * useExcelUploadUtil provide the extended functions for some operation on excel upload
	 */
	const { uploadExcel } = useExcelUploadUtil(dataToSend);

	const sendDataToExcelProcess = {
		setBulkUploadModalOpen,
		headers,
		rowsData,
		setShowValidationModal,
		selectedField,
		setDetailsAndSettingsForFields,
	};

	const downloadTemplate = () => {
		const options = selectedField?.options?.filter((option:any)=>{ 
			if(option?.isDeleted || option?.softDeleted || option.optionValue==="" ) return false ;
			else return true;
		} ).map((each: any) => ({ [selectedField?.fieldName]: each.optionValue }));
		// generate workbook and worksheet objects
		const wb = utils.book_new();
		const ws = utils.json_to_sheet(options.length === 0 ? [{ [selectedField?.fieldName]: null }] : options);

		utils.book_append_sheet(wb, ws, selectedField?.fieldName);
		/* fix headers */
		utils.sheet_add_aoa(wb, [[selectedField?.fieldName]], { origin: "A1" });
		writeFile(wb, `${selectedField?.fieldName}_options` + ".xlsx");
	};

	return (
		<>
			{showValidationModal ? (
				<ExcelProcess {...sendDataToExcelProcess} />
			) : (
				// <></>
				<Dialog onClose={() => setBulkUploadModalOpen(false)} aria-labelledby="customized-dialog-title" open={true}>
					<div className="bulk_download-modal">
						<div className="title">
							<h3>Bulk Upload</h3>
							<button title="Close Modal" onClick={() => setBulkUploadModalOpen(false)}>
								<CloseOutlinedIcon htmlColor="#8f8f8f" />
							</button>
						</div>
						<div className="action_buttons">
							<button
								style={{ fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif" }}
								onClick={downloadTemplate}>
								<DownloadingOutlinedIcon /> Template
							</button>

							<label htmlFor={upload}>
								<input type="file" accept=".xls,.xlsx" id={upload} onChange={uploadExcel} />
								<CloudUploadOutlinedIcon />
								Upload
							</label>
						</div>
					</div>
				</Dialog>
			)}
		</>
	);
};

export default UploadModal;
