import { CircularProgress } from "@mui/material";
import { useRouter } from "next/router";
import { FC, FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { confirmResetPassword } from "../../api/login";
import { APP_DISPATCH, showNotification } from "../../redux";
type Props = {
	email: string;
};
const ResetPassword: FC<Props> = ({ email }) => {
	const [loading, setLoading] = useState(false);
	const [passwords, setPasswords] = useState({
		newPassword: "",
		newConfirmedPassword: "",
		otp: "",
	});
	const [errors, setErrors] = useState("");
	const router = useRouter();
	const dispatch = useDispatch<APP_DISPATCH>();
	const handleResetPassword = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (passwords.newPassword !== passwords.newConfirmedPassword) {
			setErrors("Passwords do not match");
			return;
		}
		setErrors("");
		setLoading(true);
		const { success, message } = await confirmResetPassword(email, passwords.otp, passwords.newPassword);
		if (!success) setErrors(message);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		setLoading(false);
		if (success) router.push("/login");
	};
	return (
		<form className="login_form" onSubmit={handleResetPassword}>
			<h2>Reset Password</h2>
			<div className="container">
				<label htmlFor="otp">
					<b>Enter OTP</b>
				</label>
				<input
					type="text"
					placeholder="Enter OTP"
					name="otp"
					className="otp"
					required
					autoComplete="off"
					value={passwords.otp}
					onChange={(e) => setPasswords((prev) => ({ ...prev, otp: e.target.value }))}
				/>
				<label htmlFor="newPassword" style={{ marginBlock: "15px 5px" }}>
					<b>Enter New Password</b>
				</label>
				<input
					type="password"
					placeholder="Enter New Password"
					name="newPassword"
					minLength={6}
					required
					value={passwords.newPassword}
					onChange={(e) => setPasswords((prev) => ({ ...prev, newPassword: e.target.value }))}
				/>
				<label htmlFor="newConfirmedPassword" style={{ marginBlock: "15px 5px" }}>
					<b>Confirm Password</b>
				</label>
				<input
					type="password"
					placeholder="Confirm Password"
					name="newConfirmedPassword"
					required
					minLength={6}
					value={passwords.newConfirmedPassword}
					onChange={(e) => setPasswords((prev) => ({ ...prev, newConfirmedPassword: e.target.value }))}
				/>
				<ul className="errors">
					<li>{errors}</li>
				</ul>
				<button type="submit" disabled={loading}>
					{loading ? <CircularProgress style={{ width: "20px", height: "20px", color: "white" }} /> : "Update"}
				</button>
			</div>
		</form>
	);
};

export default ResetPassword;
