// eslint-disable-next-line import/prefer-default-export

export const attendanceTypes = {
	ABSENT: 0,
	PRESENT: 1,
	WEEKLY_OFF: 3,
	PENDING_APPROVAL: 11,
	LEAVE: 5,
	PUBLIC_HOLIDAY: 10,
	NOT_YET_SET: -1,
};

export const attendanceStatusTypes : any= {
	0: {
		label: "Absent",
		symbol: "AB",
		symbolColor: "#F54747",
		backgroundColor: "#FEEDED",
		timelineMessage: "Employee did not punch in.",
	},
	1: {
		label: "Present",
		symbol: "PR",
		symbolColor: "#40A636",
		backgroundColor: "#ECF6EB",
		timelineMessage: "No data found.",
	},
	3: {
		label: "Weekly Off",
		symbol: "WO",
		symbolColor: "#466bff",
		backgroundColor: "#D9F0FD",
		timelineMessage: "Employee had a Weekly Off.",
	},
	11: {
		label: "Pending Approval",
		symbol: "PN",
		symbolColor: "#ef9943",
		backgroundColor: "#D9F0FD",
		timelineMessage: "To be Approved.",
	},
	5: {
		label: "Leave",
		symbol: null,
		symbolColor: "#EF9943",
		backgroundColor: "#FEF6EF",
		timelineMessage: "Employee was on Leave.",
	},
	6: {
		label: "Present Over Time",
		symbol: "PR",
		symbolColor: "#40A636",
		backgroundColor: "#ECF6EB",
		timelineMessage: "No data found.",
	},
	7: {
		label: "Half Day Over Time",
		symbol: "PR",
		symbolColor: "#40A636",
		backgroundColor: "#ECF6EB",
		timelineMessage: "No data found.",
	},
	10: {
		label: "Public Holiday",
		symbol: "PH",
		symbolColor: "#d705f1d9",
		backgroundColor: "#D9F0FD",
		timelineMessage: "Employee had a Public Holiday.",
	},
};

export const attendanceStatusAuthentication = {
	0: {
		0: true,
		1: true,
		3: true,
		4: true,
		5: false,
		10: true,
	},
	1: {
		0: true,
		1: true,
		3: true,
		4: true,
		5: false,
		10: true,
	},
	3: {
		0: true,
		1: true,
		3: true,
		4: true,
		5: false,
		10: true,
	},
	4: {
		0: true,
		1: true,
		3: true,
		4: true,
		5: false,
		10: true,
	},
	5: {
		0: true,
		1: true,
		3: true,
		4: true,
		5: false,
		10: true,
	},
	10: {
		0: true,
		1: true,
		3: true,
		4: true,
		5: false,
		10: true,
	},
	// TODO:
	// pending approval to be added
};

// contains list of possible allowed changes from one type to another
