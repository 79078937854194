import React, { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
	Box,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	Switch,
	TextField,
	Button,
} from "@mui/material";
import Link from "next/link";
import "moment-timezone";
import { FC, FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { INTEGRATION_INFO } from "../../../../@types";
import { enableIntegration, upsertIntegration } from "../../../../api/settings";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../../redux";
import useAdminInfo from "../../../useAdminInfo";
import useFetch from "../../../useFetch";
import DoneIcon from "@mui/icons-material/Done";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { CRM_AUTH_STATUS } from "../../../../constants/zohoCrmConstants";
import useAdminEntitlements from "../../../useAdminEntitlements";
import ReadOnlyButton from "../../../common/ReadOnlyButton";
import { useSelector } from "react-redux";
type Props = {
	zohoFormData: INTEGRATION_INFO | undefined;
};

const ZohoCRMIntegrations: FC<Props> = ({ zohoFormData }) => {
	const { companyID } = useAdminInfo();
	const userWritePermission = useAdminEntitlements("settings:integrations", "write");
	const disabled = companyID === 255 || !userWritePermission;
	const dispatch = useDispatch<APP_DISPATCH>();
	const [isSaving, setIsSaving] = useState(false);
	const [status, setStatus] = useState(zohoFormData?.status ?? 0);
	const [isDisabled, setIsDisabled] = useState(zohoFormData?.status === 1 ? false : true);
	const [openRedirectModal, setOpenRedirectModal] = useState(false);
	const [statusMsgFromAuth, setStatusMsgFromAuth] = useState(0);
	const [zohoCred, setZohoCred] = useState<Record<string, INTEGRATION_INFO["keyValues"][0]>>({
		email: { keyTypeID: 10, keyValue: zohoFormData?.keyValues.find((k) => k.keyTypeID === 10)?.keyValue ?? "" },
		clientID: { keyTypeID: 6, keyValue: zohoFormData?.keyValues.find((k) => k.keyTypeID === 6)?.keyValue ?? "" },
		clientSecret: { keyTypeID: 7, keyValue: zohoFormData?.keyValues.find((k) => k.keyTypeID === 7)?.keyValue ?? "" },
		dataCenter: { keyTypeID: 8, keyValue: zohoFormData?.keyValues.find((k) => k.keyTypeID === 8)?.keyValue ?? "" },
		environment: { keyTypeID: 9, keyValue: zohoFormData?.keyValues.find((k) => k.keyTypeID === 9)?.keyValue ?? "" },
	});

	const { data, refetch } = useFetch("/preferences/getCrmOauthStatus");
	const { roleID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);

	const handleZohoSubmit = async (e: FormEvent<HTMLFormElement>) => {
		setIsSaving(true);
		e.preventDefault();
		const { success, message } = await upsertIntegration(5, Object.values(zohoCred));
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		setIsSaving(false);
		setOpenRedirectModal(true);
		refetch();
	};

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setZohoCred((prev) => ({ ...prev, [name]: { keyTypeID: zohoCred[name].keyTypeID, keyValue: value } }));
	};

	const enableZohoSwitch = async (prevStatus: 0 | 1) => {
		if (isNaN(prevStatus)) return;
		const newState = prevStatus === 0 ? 1 : 0;
		const { success, message } = await enableIntegration("clients", 5, newState);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (!success) return;
		setIsDisabled(newState === 1 ? false : true);
		setStatus(newState);
	};

	const checkStatus = async (data: any) => {
		if (data?.status) {
			await setStatusMsgFromAuth(data?.status[0]?.crmOauthStatus);
		}
	};
	useEffect(() => {
		checkStatus(data);
	}, [data]);

	const getStatusFun = () => (
		<>
			<span>{`Zoho CRM Status: `}</span>
			{statusMsgFromAuth === CRM_AUTH_STATUS.success ? (
				<DoneIcon htmlColor="#00ff00" />
			) : (
				<AccessTimeIcon htmlColor="#ff0000" />
			)}
			<span>{statusMsgFromAuth === CRM_AUTH_STATUS.success ? " Successful" : " Pending"}</span>
		</>
	);

	return (
        <div className="zoho-crm">
			<div className="back-title">
				<Link prefetch={false} href="/settings/integrations">

                    <ArrowBackIcon htmlColor="#000" />

                </Link>
				<p>Back</p>
				{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="integrations" /> : null}
			</div>
			<div className="divider"></div>
			<h5>{getStatusFun()}</h5>
			<div className="integration_state">
				<FormControlLabel
					control={<Switch checked={status === 1} />}
					label={status === 1 ? "Enabled" : "Disabled"}
					onChange={() => enableZohoSwitch(status as 0 | 1)}
					disabled={disabled}
				/>
			</div>
			<form className="crm-form" onSubmit={handleZohoSubmit}>
				<TextField
					disabled={isDisabled || disabled}
					type="email"
					required
					label="Zoho Email ID"
					name="email"
					onChange={handleChange}
					value={zohoCred.email.keyValue}
				/>
				<TextField
					required
					name="clientID"
					disabled={isDisabled || disabled}
					label="Client ID"
					onChange={handleChange}
					value={zohoCred.clientID.keyValue}
				/>
				<TextField
					disabled={isDisabled || disabled}
					required
					name="clientSecret"
					label="Client Secret"
					onChange={handleChange}
					value={zohoCred.clientSecret.keyValue}
				/>
				<FormControl fullWidth>
					<InputLabel id="dataCenter">Data Center</InputLabel>
					<Select
						name="dataCenter"
						required
						labelId="dataCenter"
						disabled={isDisabled || disabled}
						label="Data Center"
						value={zohoCred.dataCenter.keyValue}
						onChange={handleChange}>
						<MenuItem value="https://accounts.zoho.com/">US</MenuItem>
						<MenuItem value="https://accounts.zoho.com.au/">AU</MenuItem>
						<MenuItem value="https://accounts.zoho.eu/">EU</MenuItem>
						<MenuItem value="https://accounts.zoho.in/">IN</MenuItem>
						<MenuItem value="https://accounts.zoho.com.cn/">CN</MenuItem>
						<MenuItem value="https://accounts.zoho.jp/">JP</MenuItem>
					</Select>
				</FormControl>
				<FormControl fullWidth>
					<InputLabel id="environment">ENVIRONMENT</InputLabel>
					<Select
						name="environment"
						required
						labelId="environment"
						disabled={isDisabled || disabled}
						label="ENVIRONMENT"
						value={zohoCred.environment.keyValue}
						onChange={handleChange}>
						<MenuItem selected value="PRODUCTION">
							Production
						</MenuItem>
						<MenuItem value="SANDBOX">Sandbox</MenuItem>
						<MenuItem value="DEVELOPMENT">Development</MenuItem>
					</Select>
				</FormControl>
				<button type="submit" disabled={isSaving || disabled}>
					Next
				</button>
				<Modal
					open={openRedirectModal}
					onClose={() => setOpenRedirectModal(false)}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
					style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					<Box sx={{ bgcolor: "background.paper", boxShadow: 24, p: 4, borderRadius: 4 }}>
						<h5 id="modal-title">You will be redirected to the Zoho to complete the authorization.</h5>
						<p style={{ marginTop: 20 }} id="modal-description">
							Please click Okay to continue.
						</p>
						<Button
							href={`${zohoCred?.dataCenter?.keyValue}oauth/v2/auth?scope=${
								!disabled
									? "ZohoCRM.modules.ALL,ZohoCRM.settings.ALL,ZohoCRM.settings.modules.ALL,ZohoCRM.users.ALL"
									: "ZohoCRM.modules.ALL,ZohoCRM.settings.ALL,ZohoCRM.settings.modules.ALL,ZohoSubscriptions.subscriptions.ALL,ZohoSubscriptions.invoices.ALL,ZohoSubscriptions.hostedpages.ALL,ZohoSubscriptions.creditnotes.ALL,ZohoSubscriptions.customers.ALL,ZohoBooks.Invoices.ALL, ZohoCRM.users.ALL"
							}&client_id=${
								zohoCred?.clientID?.keyValue
							}&state=testing&response_type=code&access_type=offline&redirect_uri=${
								process.env.NEXT_PUBLIC_BACKEND_URL
							}/api/v1/preferences/zohoIntegration`}
							style={{ marginTop: 20 }}
							variant="contained"
							target={"_blank"}>
							Okay
						</Button>
					</Box>
				</Modal>
			</form>
		</div>
    );
};

export default ZohoCRMIntegrations;
