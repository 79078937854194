import { Drawer } from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";
type Props = {
	expanded: boolean;
	setExpanded: Dispatch<SetStateAction<boolean>>;
	children: JSX.Element | JSX.Element[];
	anchor?: "left" | "right";
	crmTemplate?: any;
};
const RightSidebar: FC<Props> = ({ expanded, setExpanded, children, anchor = "right", crmTemplate }) => (
	<Drawer anchor={anchor} open={expanded} onClose={() => setExpanded(false)}>
		<aside className={`${crmTemplate ? crmTemplate : "right-sidebar"}`}>{children}</aside>
	</Drawer>
);

export default RightSidebar;
