import AddIcon from "@mui/icons-material/Add";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Link from "next/link";
import { FC, useEffect, useState } from "react";
import {
	EXECUTIVE_USER,
	GEOFENCE_DATA,
	GEOFENCE_POOLS_DATA,
	PROFILES_DATA,
	SITEPOOLS_DATA,
	SITES_DATA,
} from "../../../@types";
import { sortByString } from "../../../utils";
import useFetch from "../../useFetch";
import { useSelector } from "react-redux";
import { ROOT_STATE } from "../../../redux";
import { PRODUCT_ID, UNOLO_BIOMETRIC } from "../../../constants";

type Props = {
	show: boolean;
	formData: EXECUTIVE_USER;
	// eslint-disable-next-line no-unused-vars
	handleChange: (event: any) => void;
};
type DESIGNATION_TYPE = {
	name: string;
	designationID: number;
};

const AdvanceUserForm: FC<Props> = ({ show, formData, handleChange }) => {
	const productID = window ? localStorage.getItem(PRODUCT_ID) ?? "2" : "2";
	const { data: dataSitePool, loading: loadingSitePool, refetch: refetchSitePool } = useFetch<any>("/sites/sitepools");
	const { data: dataGFence, loading: loadingGFence, refetch: refetchGFence } = useFetch<any>("/geofence");
	const { data: dataGPools, loading: loadingGPools, refetch: refetchGPools } = useFetch<any>("/geofence/geofencePools");
	const { data: dataSites, refetch: refetchSites, loading: loadingSites } = useFetch<SITES_DATA[]>("/sites");
	const { data: dataProf, loading: loadingProf, refetch: refetchProf } = useFetch<PROFILES_DATA[]>("/users/profiles");
	const [sitePoolsData, setSitePoolsData] = useState<SITEPOOLS_DATA[]>([]);
	const [sitesData, setSitesData] = useState<SITES_DATA[]>([]);
	const [geofence, setGeofence] = useState<GEOFENCE_DATA[]>([]);
	const [geofencePools, setGeofencePools] = useState<GEOFENCE_POOLS_DATA[]>([]);
	const {
		data: dataDesignation,
		refetch: refetchDesignation,
		loading: loadingDesig,
	} = useFetch<DESIGNATION_TYPE[]>("/preferences/designations");
	const {
		susbscriptionMetadata: { data: subsMetadata },
	} = useSelector((state: ROOT_STATE) => ({
		susbscriptionMetadata: state.susbscriptionMetadata,
	}));

	useEffect(() => {
		if (!loadingSitePool && dataSitePool?.sitepools) {
			setSitePoolsData([...dataSitePool.sitepools]);
		}
		if (!loadingGFence && dataGFence?.geofence) {
			setGeofence([...dataGFence.geofence]);
		}
		if (!loadingGPools && dataGPools?.geofencePools) {
			setGeofencePools([...dataGPools.geofencePools]);
		}
		if (!loadingSites && dataSites?.sites) {
			setSitesData([...dataSites.sites]);
		}
	}, [
		dataSitePool,
		dataGFence,
		dataGPools,
		loadingSitePool,
		loadingGFence,
		loadingGPools,
		loadingSites,
		dataSites?.sites,
	]);

	return (
		<div style={{ display: show ? "grid" : "none" }} className="advance-field-container">
			<div className="basic-form">
				<FormControl fullWidth style={{ display: "grid", gridTemplateColumns: "10fr 55px 55px", columnGap: 10 }}>
					<InputLabel id="profileID">Select Team</InputLabel>
					<Select
						value={formData.profileID ?? ""}
						labelId="profileID"
						onChange={handleChange}
						name="profileID"
						label="Select Team">
						{dataProf?.data
							?.sort((a, b) => sortByString(a.profileName, b.profileName))
							.map((item) => (
								<MenuItem key={item.profileID} value={item.profileID}>
									{item.profileName}
								</MenuItem>
							))}
					</Select>
					<Link href="/organization/teams" target="blank">
						<AddIcon />
					</Link>
					<button disabled={loadingProf} className="refetch" type="button" onClick={refetchProf}>
						<RefreshOutlinedIcon />
					</button>
					<FormHelperText>
						Group people who work together into teams. For example, Sales or Service. Note that most settings can be
						configured at a team level.
					</FormHelperText>
				</FormControl>
				<FormControl fullWidth style={{ display: "grid", gridTemplateColumns: "10fr 55px 55px", columnGap: 10 }}>
					<InputLabel id="designation">Select Designation</InputLabel>
					<Select
						value={formData.designationID ?? ""}
						labelId="designationID"
						name="designationID"
						label="Select Designation"
						onChange={handleChange}>
						{dataDesignation?.data
							?.sort((a, b) => sortByString(a.name, b.name))
							?.map((item) => (
								<MenuItem key={item.designationID} value={item.designationID}>
									{item.name}
								</MenuItem>
							))}
					</Select>
					<Link href="/organization/designation" target="blank">
						<AddIcon />
					</Link>
					<button
						disabled={loadingDesig}
						className="refetch"
						type="button"
						onClick={refetchDesignation}
						title="Refetch Designation">
						<RefreshOutlinedIcon />
					</button>
					<FormHelperText>
						Choose their designation. Note that expense limits can be configured at a designation level.
					</FormHelperText>
				</FormControl>
				<TextField label="Enter City Name" name="city" onChange={handleChange} value={formData.city ?? ""} />
				{subsMetadata?.sites === 1 && (
					<FormControl fullWidth style={{ display: "grid", gridTemplateColumns: "10fr 55px 55px", columnGap: 10 }}>
						<InputLabel id="base-site">Select Base Site</InputLabel>
						<Select
							value={formData.BaseGeoFenceID ?? "None"}
							name="BaseGeoFenceID"
							labelId="base-site"
							label="Select Base Site"
							onChange={handleChange}>
							<MenuItem value="None">None</MenuItem>
							{sitesData
								?.sort((a, b) => sortByString(a.clientName, b.clientName))
								.map((item, i) => (
									<MenuItem key={i} value={item.clientID}>
										{item.clientName}
									</MenuItem>
								))}
						</Select>
						<Link href="/clients_sites/sites/site/create" target="blank">
							<AddIcon />
						</Link>
						<button
							className="refetch"
							disabled={loadingSites}
							type="button"
							onClick={refetchSites}
							title="Refetch Sites">
							<RefreshOutlinedIcon />
						</button>
						<FormHelperText>
							If your Employee normally works out of one location, create a site for them and you can automatically
							monitor their attendance using Site Attendance.
						</FormHelperText>
					</FormControl>
				)}
				{(subsMetadata?.sites === 1 || subsMetadata?.clients === 1) && (
					<FormControl fullWidth style={{ display: "grid", gridTemplateColumns: "10fr 55px 55px", columnGap: 10 }}>
						<InputLabel id="site-pool">Select Site Pool</InputLabel>
						<Select
							value={formData.sitePoolID ?? "None"}
							name="sitePoolID"
							labelId="site-pool"
							label="Select Site Pole"
							onChange={handleChange}>
							<MenuItem value="None">None</MenuItem>
							{sitePoolsData
								?.sort((a, b) => sortByString(a.name, b.name))
								?.map((item) => (
									<MenuItem key={item.sitePoolID} value={item.sitePoolID}>
										{item.name}
									</MenuItem>
								))}
						</Select>
						<Link href="/clients_sites/sites/sitepool/create" target="blank">
							<AddIcon />
						</Link>
						<button
							disabled={loadingSitePool}
							className="refetch"
							type="button"
							onClick={refetchSitePool}
							title="Refetch Site Pool">
							<RefreshOutlinedIcon />
						</button>
						<FormHelperText>
							If your Employee normally works out of multiple sites, create a sitepool for them and you can
							automatically monitor their attendance using Site Attendance.
						</FormHelperText>
					</FormControl>
				)}
				{subsMetadata?.defaultTransportMode === 1 && (
					<FormControl fullWidth>
						<InputLabel id="defaultTransportMode">Select Default Transport Mode</InputLabel>
						<Select
							value={formData.defaultTransportMode ?? 0}
							name="defaultTransportMode"
							labelId="defaultTransportMode"
							label="Select Default Transport Mode"
							onChange={handleChange}>
							<MenuItem value={0}>Bike</MenuItem>
							<MenuItem value={1}>Car</MenuItem>
						</Select>
						<FormHelperText >
							This option controls which mode of transport is used by default when <Link href="/settings/odo" style={{
								display: "inline",
								background: "none",
								fontSize: "inherit",
								verticalAlign: "inherit"
							}} target="blank">{` Auto Conveyance `}</Link>is turned on.
						</FormHelperText>
					</FormControl>
				)}
			</div>
			{(subsMetadata?.clients === 1 || subsMetadata?.sites === 1) && (
				<div className="grouped-form">
					<div className="group">
						<h4>Attendance Restriction IN (Site or Sitepool)</h4>
						<FormControl fullWidth style={{ display: "grid", gridTemplateColumns: "10fr 55px 55px", columnGap: 10 }}>
							<InputLabel id="attendance-site">Select Site</InputLabel>
							<Select
								value={formData.attendanceRestrictionToSiteID ?? "None"}
								name="attendanceRestrictionToSiteID"
								labelId="attendance-site"
								label="Select Site"
								onChange={handleChange}>
								<MenuItem value="None">None</MenuItem>
								{sitesData
									?.sort((a, b) => sortByString(a.clientName, b.clientName))
									.map((item, i) => (
										<MenuItem key={i} value={item.clientID}>
											{item.clientName}
										</MenuItem>
									))}
							</Select>
							<Link href="/clients_sites/sites/site/create" target="blank" title="Add Sites">
								<AddIcon />
							</Link>
							<button
								className="refetch"
								disabled={loadingSites}
								type="button"
								onClick={refetchSites}
								title="Refetch Sites">
								<RefreshOutlinedIcon />
							</button>
							<FormHelperText> Restrict punching in location to this site.</FormHelperText>
						</FormControl>
						<span className="or">OR</span>
						<FormControl fullWidth style={{ display: "grid", gridTemplateColumns: "10fr 55px 55px", columnGap: 10 }}>
							<InputLabel id="attendance-sitepool">Select Sitepool</InputLabel>
							<Select
								value={formData.attendanceRestrictionToSitePoolID ?? "None"}
								name="attendanceRestrictionToSitePoolID"
								labelId="attendance-sitepool"
								label="Select Sitepool"
								onChange={handleChange}>
								<MenuItem value="None">None</MenuItem>
								{sitePoolsData
									?.sort((a, b) => sortByString(a.name, b.name))
									?.map((item) => (
										<MenuItem key={item.sitePoolID} value={item.sitePoolID}>
											{item.name}
										</MenuItem>
									))}
							</Select>
							<Link href="/clients_sites/sites/sitepool/create" target="blank" title="Add Site Pool">
								<AddIcon />
							</Link>
							<button
								disabled={loadingSitePool}
								className="refetch"
								type="button"
								onClick={refetchSitePool}
								title="Refetch Site Pool">
								<RefreshOutlinedIcon />
							</button>
							<FormHelperText> Restrict punching in location to this sitepool.</FormHelperText>
						</FormControl>
					</div>
					<div className="group">
						<h4>Attendance Restriction OUT (Site or Sitepool)</h4>
						<FormControl fullWidth style={{ display: "grid", gridTemplateColumns: "10fr 55px 55px", columnGap: 10 }}>
							<InputLabel id="attendance-site">Select Site</InputLabel>
							<Select
								value={formData.attendanceOutRestrictionToSiteID ?? "None"}
								name="attendanceOutRestrictionToSiteID"
								labelId="attendance-site"
								label="Select Site"
								onChange={handleChange}>
								<MenuItem value="None">None</MenuItem>
								{sitesData
									?.sort((a, b) => sortByString(a.clientName, b.clientName))
									.map((item, i) => (
										<MenuItem key={i} value={item.clientID}>
											{item.clientName}
										</MenuItem>
									))}
							</Select>
							<Link href="/clients_sites/sites/site/create" target="blank" title="Add Sites">
								<AddIcon />
							</Link>
							<button
								className="refetch"
								disabled={loadingSites}
								type="button"
								onClick={refetchSites}
								title="Refetch Sites">
								<RefreshOutlinedIcon />
							</button>
							<FormHelperText> Restrict punching out location to this site.</FormHelperText>
						</FormControl>
						<span className="or">OR</span>
						<FormControl fullWidth style={{ display: "grid", gridTemplateColumns: "10fr 55px 55px", columnGap: 10 }}>
							<InputLabel id="attendance-sitepool">Select Sitepool</InputLabel>
							<Select
								value={formData.attendanceOutRestrictionToSitePoolID ?? "None"}
								name="attendanceOutRestrictionToSitePoolID"
								labelId="attendance-sitepool"
								label="Select Sitepool"
								onChange={handleChange}>
								<MenuItem value="None">None</MenuItem>
								{sitePoolsData
									?.sort((a, b) => sortByString(a.name, b.name))
									?.map((item) => (
										<MenuItem key={item.sitePoolID} value={item.sitePoolID}>
											{item.name}
										</MenuItem>
									))}
							</Select>
							<Link href="/clients_sites/sites/sitepool/create" target="blank" title="Add Site Pool">
								<AddIcon />
							</Link>
							<button
								disabled={loadingSitePool}
								className="refetch"
								type="button"
								onClick={refetchSitePool}
								title="Refetch Site Pool">
								<RefreshOutlinedIcon />
							</button>
							<FormHelperText> Restrict punching out location to this sitepool.</FormHelperText>
						</FormControl>
					</div>
					{productID === UNOLO_BIOMETRIC ? null : (
						<div className="group">
							<h4>Geofence</h4>
							<FormControl fullWidth style={{ display: "grid", gridTemplateColumns: "10fr 55px 55px", columnGap: 10 }}>
								<InputLabel id="geofence">Select Geofence</InputLabel>
								<Select
									value={formData.geofences ?? "None"}
									labelId="geofence"
									name="geofences"
									label="Select Geofence"
									onChange={handleChange}>
									<MenuItem value="None">None</MenuItem>
									{geofence
										?.sort((a, b) => sortByString(a.name, b.name))
										?.map((item) => (
											<MenuItem key={item.geofenceID} value={item.geofenceID}>
												{item.name}
											</MenuItem>
										))}
								</Select>
								<Link href="/clients_sites/geofences" target="blank" title="Add Site Pool">
									<AddIcon />
								</Link>
								<button
									disabled={loadingGFence}
									className="refetch"
									type="button"
									onClick={refetchGFence}
									title="Refetch Site Pool">
									<RefreshOutlinedIcon />
								</button>
								<FormHelperText>Get a notification whenever a Employee goes in or out of an area.</FormHelperText>
							</FormControl>
							<span className="or">OR</span>
							<FormControl fullWidth style={{ display: "grid", gridTemplateColumns: "10fr 55px 55px", columnGap: 10 }}>
								<InputLabel id="geofence-pool">Select Geofence Pool</InputLabel>
								<Select
									value={formData.geofencePool ?? "None"}
									name="geofencePool"
									labelId="geofence-pool"
									label="Select Geofence Pool"
									onChange={handleChange}>
									<MenuItem value="None">None</MenuItem>
									{geofencePools
										?.sort((a, b) => sortByString(a.name, b.name))
										?.map((item) => (
											<MenuItem key={item.geofencePoolID} value={item.geofencePoolID}>
												{item.name}
											</MenuItem>
										))}
								</Select>
								<Link href="/clients_sites/geofences" target="blank" title="Add Site Pool">
									<AddIcon />
								</Link>
								<button
									disabled={loadingGPools}
									className="refetch"
									type="button"
									onClick={refetchGPools}
									title="Refetch Site Pool">
									<RefreshOutlinedIcon />
								</button>
								<FormHelperText>
									Get a notification whenever a Employee goes in or out of multiple areas.
								</FormHelperText>
							</FormControl>
						</div>
					)}
				</div>
			)}
		</div>
	);
};
export default AdvanceUserForm;
