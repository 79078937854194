import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { FC, useState } from "react";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import { EXECUTIVE_USER } from "../../../@types";
import { sortByString } from "../../../utils";
import { PRODUCT_ID, UNOLO_BIOMETRIC } from "../../../constants";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialog-paper": {
		maxWidth: "600px",
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

type Props = {
	// eslint-disable-next-line no-unused-vars
	selectedRows: any;
	deleteHandler: (executive: number | null, transfer: boolean) => void;
	handleClose: () => void;
	name: string;
	executives: EXECUTIVE_USER[];
	id?: any;
};

const FEDeleteModal: FC<Props> = ({ selectedRows, deleteHandler, handleClose, name, executives }) => {
	const sortedNames = executives
		.filter((e) => !selectedRows.includes(e.employeeID))
		.sort((a, b) => sortByString(a.firstName, b.firstName));
	const [executive, setExecutive] = useState<number | null>(sortedNames[0]?.employeeID);
	const [confirmationText, setConfirmationText] = useState("");
	const isDeleteButtonEnabled = confirmationText.toLowerCase() === "confirm";
	const productID = window ? localStorage.getItem(PRODUCT_ID) ?? "2" : "2";
	const [transfer, setTransfer] = useState(productID === UNOLO_BIOMETRIC ? false : true);

	return (
		<BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
			<div
				style={{
					display: "flex !important",
					padding: "15px 20px",
					alignItems: "center",
					width: "100%",
					justifyContent: "center",
				}}>
				<h6
					style={{
						margin: ".1rem 0 0.5rem 0",
						fontSize: "16px",
						fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
						fontWeight: 400,
					}}>
					Delete <strong>{name ?? "Unnamed User(s)"}</strong> ?
				</h6>
				{productID === UNOLO_BIOMETRIC ? null : (
					<div className="warning-box">
						<p className="font-r-14" style={{ color: "#f01a1a" }}>
							<WarningOutlinedIcon className="warning-icon" />
							You are deleting all location, travel, attendance, expense, orders and leaves data for the selected user.
							Once deleted, this data cannot be recovered/downloaded.
						</p>
					</div>
				)}

				<div
					className="group"
					style={{
						padding: "15px",
						display: "grid",
						gap: "20px",
					}}>
					{productID === UNOLO_BIOMETRIC ? null : (
						<>
							<p className="font-r-14">
								You can choose transfer clients, scheduled tasks, photos and forms of the selected user.
							</p>
							<label className="radio_executive">
								<input
									type="radio"
									name="delete"
									checked={!transfer ?? false}
									onClick={() => {
										setExecutive(null);
										setTransfer(false);
									}}
								/>
								Delete without transfer
								<p
									className="font-r-14"
									style={{ color: "#EF9943", marginLeft: "10px", visibility: transfer ? "hidden" : "visible" }}>
									(Note: clients will be made visible to everybody)
								</p>
							</label>

							<label className="radio_executive">
								<input
									type="radio"
									name="delete"
									checked={transfer ?? false}
									onClick={() => {
										setExecutive(executives[0].employeeID);
										setTransfer(true);
									}}
								/>
								Transfer to another employee
							</label>
							<select
								className={`select_executives ${!transfer ? "disabled" : ""}`}
								onChange={(e) => setExecutive(+e.target.value)}
								disabled={!transfer ?? false}>
								{sortedNames.map((executive: EXECUTIVE_USER) => (
									<option key={executive.employeeID} value={executive.employeeID}>
										{executive.firstName} {executive.lastName}
									</option>
								))}
							</select>
						</>
					)}

					<div className="note-container">
						<p className="font-r-14" style={{ color: "#505050", marginBottom: "10px" }}>
							Please make sure you have understood the risks associated with deleting the user. Type "confirm" in the
							box below to proceed.
						</p>
						<div className="confirmation-input">
							<input
								type="text"
								placeholder={`Type here`}
								value={confirmationText}
								onChange={(e) => setConfirmationText(e.target.value)}
							/>
						</div>
					</div>
					<DialogActions
						sx={{
							justifyContent: "center",
						}}>
						<Button variant="contained" onClick={handleClose} color="inherit">
							Cancel
						</Button>
						<Button
							variant="contained"
							onClick={() => {
								if (isDeleteButtonEnabled) {
									deleteHandler(executive, transfer);
									handleClose();
								}
							}}
							color="error"
							disabled={!isDeleteButtonEnabled}>
							Delete
						</Button>
					</DialogActions>
				</div>
			</div>
		</BootstrapDialog>
	);
};

export default FEDeleteModal;
