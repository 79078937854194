// react
import { useEffect, useMemo, useState } from "react";
// mui
import { Box } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
// utils
import { DG_STYLES } from "../../../constants";
// query
import { ORDER_TYPE } from "../../../@types";
// libraries
import { v4 as uuidv4 } from "uuid";
// components
import CustomColumnMenu from "../../common/datagrid/CustomColumnMenu";
import CustomDataGridExport from "../../common/datagrid/CustomDataGridExport";

type Props = {
	setSkuColumns: any;
	setSkuData: any;
	skuData: any;
	skuColumns: any;
	currencySymbol: string;
	searchTerm: any;
	filteredData: ORDER_TYPE[];
};

const SKUDetailsTableV2 = ({
	setSkuData,
	setSkuColumns,
	skuData,
	skuColumns,
	currencySymbol,
	searchTerm,
	filteredData,
}: Props) => {
	const tempColumns = useMemo(() => {
		const cols = [
			{
				field: "processingDate",
				headerName: "Date",
				flex: 1,
				width: 100,
				renderCell: (params: any) => (params.value ?? "-"),
			},
			{
				field: "invoiceNumber",
				headerName: "Order Invoice Number",
				flex: 1,
				width: 100,
				renderCell: (params: any) => (params.value ? params.value : "-"),
			},
			{
				field: "employeeName",
				headerName: "Employee Name",
				flex: 1,
				width: 220,
				renderCell: (params: any) => (params.value ? params.value : "-"),
			},
			{
				field: "skuName",
				headerName: "SKU Name",
				flex: 1,
				width: 220,
				renderCell: (params: any) => (params.value ? params.value : "-"),
			},
			{
				field: "category",
				headerName: "Category",
				flex: 1,
				width: 220,
				renderCell: (params: any) => (params.value ? params.value : "-"),
			},
			{
				field: "clientName",
				headerName: "Client Name",
				flex: 1,
				width: 220,
				renderCell: (params: any) => (params.value ? params.value : "-"),
			},
			{
				field: "teamName",
				headerName: "Team Name",
				width: 150,
				renderCell: (params: any) => (params.value ? params.value : "-"),
			},
			{
				field: "productDescription",
				headerName: "Product Descriptions",
				flex: 1,
				width: 250,
				renderCell: (params: any) => (params.value ? params.value : "-"),
			},
			{
				field: "quantity",
				headerName: "Quantity",
				flex: 1,
				width: 100,
				renderCell: (params: any) => (params.value ? params.value : "-"),
			},
			{
				field: "price",
				headerName: `Price(${currencySymbol})`,
				flex: 1,
				width: 100,
				renderCell: (params: any) => (params.value ? params.value : "-"),
			},
			{
				field: "amount",
				headerName: `Total Amount(${currencySymbol})`,
				flex: 1,
				width: 100,
				renderCell: (params: any) => (params.value ? params.value : "-"),
			},
		];
		return cols;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredData]);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(20);
	const pageChangeHandler = (newPage: number) => {
		setPage(newPage);
	};
	const pageSizeChangeHandler = (newPageSize: number) => {
		setPageSize(newPageSize);
		setPage(0);
	};

	useEffect(() => {
		const filteredProducts: ORDER_TYPE[] = filteredData;
		const skuItemsWithProcessingDate = [];
		for (const order of filteredProducts) {
			const employeeName = `${order.userInfo.firstName} ${order.userInfo.lastName}`;
			const dateParts = order.processingDate.split("-");
			const processingDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
			const invoiceNumber = order.invoiceNumber;
			const orderItems = order.orderItems;
			const clientName = order.clientName;
			const teamName = order?.userInfo?.profileName;
			for (const item of orderItems) {
				const uniqueId = uuidv4();
				const skuName = item.sku?.skuName;
				const productDescription = item.sku?.skuDescription;
				const category = item.sku?.category;
				const price = item.price;
				const amount = item.amount;
				const quantity = item.quantity;
				const itemWithProcessingDate = {
					skuName,
					quantity,
					category,
					productDescription,
					invoiceNumber,
					price,
					amount,
					processingDate,
					uniqueId,
					employeeName,
					clientName,
					teamName,
				};
				skuItemsWithProcessingDate.push(itemWithProcessingDate);
			}
		}
		setSkuData([...skuItemsWithProcessingDate]);
		setSkuColumns(tempColumns);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tempColumns, searchTerm]);
	return (
		<Box className="datagrid-table">
			<DataGridPro
				sx={DG_STYLES}
				rows={skuData}
				columns={skuColumns}
				getRowId={(row) => row.uniqueId}
				page={page}
				onPageChange={pageChangeHandler}
				pageSize={pageSize}
				onPageSizeChange={pageSizeChangeHandler}
				rowsPerPageOptions={[5, 10, 20, 50, 100]}
				rowHeight={70}
				disableColumnFilter
				pagination
				components={{ ColumnMenu: CustomColumnMenu, Toolbar: () => <CustomDataGridExport fileName="SKU_data" /> }}
			/>
		</Box>
	);
};

export default SKUDetailsTableV2;
