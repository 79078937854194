import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Dispatch, FC, SetStateAction } from "react";
import { ATTENDANCE_REQUESTS_DATA, ATTENDANCE_REQUEST_COLUMNS } from "../../../@types";
import IndividualFilter from "../../common/datagrid/IndividualFilter";
type COLUMN_TITLES = "Employee Name" | "Date" | "Team" | "From" | "To" | "Status" | "Pending Admin" | "Submission Date";
type Props = {
	numFilters: number;
	setFilterOpen: Dispatch<SetStateAction<boolean>>;
	attendanceRequests: ATTENDANCE_REQUESTS_DATA[];
	filters: Record<ATTENDANCE_REQUEST_COLUMNS, string[]>;
	setFilters: Dispatch<SetStateAction<Record<ATTENDANCE_REQUEST_COLUMNS, string[]>>>;
};
const FilterOverride: FC<Props> = ({ numFilters, setFilterOpen, attendanceRequests, setFilters, filters }) => {
	const resetFilters = () => {
		setFilters({ name: [], formattedDate: [], from: [], to: [], pendingWith: [], state: [], submissionDate: [], profileName: [], });
	};
	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
		<form
			onSubmit={(e) => e.preventDefault()}
			onClick={(e) => e.stopPropagation()}
			onKeyPress={(e) => e.stopPropagation()}>
			<div className="filter__header">
				<h5>
					<FilterAltOutlinedIcon />
					Filters
					<span className="right-notification center" style={{ display: "flex", padding: 0 }}>
						{numFilters}
					</span>
				</h5>
				<button onClick={() => setFilterOpen(false)}>
					<CloseSharpIcon htmlColor="#42444A" />
				</button>
			</div>
			<div className="accordion__group">
				<IndividualFilter<COLUMN_TITLES, ATTENDANCE_REQUEST_COLUMNS>
					filters={filters}
					name="name"
					setFilters={setFilters}
					title="Employee Name"
					data={attendanceRequests.map((request) => request.firstName + " " + request.lastName)}
					ids={attendanceRequests.map((request) => request.firstName + " " + request.lastName)}
				/>
				<IndividualFilter<COLUMN_TITLES, ATTENDANCE_REQUEST_COLUMNS>
					filters={filters}
					name="formattedDate"
					title="Date"
					setFilters={setFilters}
					data={attendanceRequests.map((request) => request.formattedDate)}
					ids={attendanceRequests.map((request) => request.formattedDate)}
				/>
				<IndividualFilter<COLUMN_TITLES, ATTENDANCE_REQUEST_COLUMNS>
					filters={filters}
					name="profileName"
					title="Team"
					setFilters={setFilters}
					data={attendanceRequests.map((request) => request.profileName)}
					ids={attendanceRequests.map((request) => request.profileName)}
				/>
				<IndividualFilter<COLUMN_TITLES, ATTENDANCE_REQUEST_COLUMNS>
					filters={filters}
					name="pendingWith"
					title="Pending Admin"
					setFilters={setFilters}
					data={attendanceRequests.map((request) => request.pendingAdmin)}
					ids={attendanceRequests.map((request) => request.pendingAdmin)}
				/>
				<IndividualFilter<COLUMN_TITLES, ATTENDANCE_REQUEST_COLUMNS>
					filters={filters}
					name="state"
					title="Status"
					setFilters={setFilters}
					//@ts-ignore
					data={attendanceRequests.map((request) => request.state)}
					ids={attendanceRequests.map((request) => request.state)}
				/>

				<IndividualFilter<COLUMN_TITLES, ATTENDANCE_REQUEST_COLUMNS>
					filters={filters}
					name="submissionDate"
					title="Submission Date"
					setFilters={setFilters}
					data={attendanceRequests.map((request) => request.submissionDate)}
					ids={attendanceRequests.map((request) => request.submissionDate)}
				/>
			</div>
			<div className="filter__buttons">
				<button onClick={resetFilters} disabled={numFilters === 0} type="reset">
					Reset
				</button>
			</div>
		</form>
	);
};

export default FilterOverride;
