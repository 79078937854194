import { Button, CircularProgress, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateCurrencySettings, updateTimeZoneSettings } from "../../../api/settings";
import { DD_STYLES } from "../../../constants";
import { countries } from "../../../constants/countries";
import { currency_map } from "../../../constants/currency_map";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import { sortByString } from "../../../utils";
import useAdminEntitlements from "../../useAdminEntitlements";
import useFetch from "../../useFetch";
import LogoUploadSection from "../../common/FileUpload";
import PasswordChangeSidebar from "../../common/PasswordChangeSidebar";
import RightSidebar from "../../common/RightSidebar";
import ReadOnlyButton from "../../common/ReadOnlyButton";
import { useSelector } from "react-redux";
const currencyData = Object.keys(currency_map).map((key) => ({
	value: key,
	label: key + " - " + currency_map[key]["name_plural"],
}));

const countriesData = Object.keys(countries);

type ACCOUNT_SETTINGS_TYPE = {
	timezone: string;
	currencyCode: string;
	dataDisplayTz: 0 | 1;
};
type FETCH = ACCOUNT_SETTINGS_TYPE;
const AccountSettings = () => {
	const { data, loading } = useFetch<FETCH>("/preferences/account");
	const [accountSettings, setAccountSettings] = useState<ACCOUNT_SETTINGS_TYPE>({} as ACCOUNT_SETTINGS_TYPE);
	const dispatch = useDispatch<APP_DISPATCH>();
	const userWritePermission = useAdminEntitlements("settings:account", "write");
	const [expanded, setExpanded] = useState(false);
	const { roleID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);
	useEffect(() => {
		if (!loading && data?.data) {
			setAccountSettings(data.data);
		}
	}, [data?.data, loading]);

	const handleChange = async (
		value: ACCOUNT_SETTINGS_TYPE[keyof ACCOUNT_SETTINGS_TYPE],
		key: keyof ACCOUNT_SETTINGS_TYPE
	) => {
		const updatedSettings = { ...accountSettings, [key]: value };
		const { success, message } =
			key === "currencyCode"
				? await updateCurrencySettings(updatedSettings.currencyCode)
				: await updateTimeZoneSettings(updatedSettings.dataDisplayTz, updatedSettings.timezone);

		dispatch(showNotification({ message: message, severity: success ? "success" : "error" }));
		if (success) setAccountSettings(updatedSettings);
	};

	if (loading || Object.keys(accountSettings).length === 0) {
		return (
			<div className="small_circular-spinner">
				<CircularProgress />
			</div>
		);
	}

	return (
		<div className="account-settings">
			{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="settings" /> : null}
			<div className="account-settings-part">
				<div className="section">
					<p className="title">Timezone</p>
					<p className="subtitle">Choose default company timezone.</p>
					<Select
						onChange={(e) => handleChange(e.target.value, "timezone")}
						name="timezone"
						required
						value={accountSettings.timezone}
						sx={{ ...DD_STYLES }}
						disabled={!userWritePermission}>
						{countriesData
							?.sort((a, b) => sortByString(a, b))
							.map((item) => (
								<MenuItem key={item} value={item}>
									<div style={{ width: "100%", padding: "5px" }}>{item}</div>
								</MenuItem>
							))}
					</Select>
				</div>
				<div className="section">
					<p className="title">Currency</p>
					<p className="subtitle">Please choose an appropriate currency.</p>
					<Select
						onChange={(e) => handleChange(e.target.value, "currencyCode")}
						name="currencyCode"
						disabled={!userWritePermission}
						required
						value={accountSettings.currencyCode}
						sx={{ ...DD_STYLES, textAlign: "left !important" }}>
						{currencyData
							?.sort((a, b) => sortByString(a.label, b.label))
							.map((item) => (
								<MenuItem key={item.value} value={item.value}>
									<div style={{ width: "100%", padding: "5px" }}>{item.label}</div>
								</MenuItem>
							))}
					</Select>
				</div>
				<div className="section">
					<p className="title">Display User Data Timezone</p>
					<p className="subtitle">Display user data in company timezone.</p>
					<Select
						onChange={(e) => handleChange(e.target.value, "dataDisplayTz")}
						name="dataDisplayTz"
						disabled={!userWritePermission}
						required
						value={accountSettings.dataDisplayTz}
						sx={{ ...DD_STYLES, marginTop: "auto" }}>
						<MenuItem value={0}>
							<div style={{ width: "100%", padding: "5px" }}>Disabled</div>
						</MenuItem>
						<MenuItem value={1}>
							<div style={{ width: "100%", padding: "5px" }}>Enabled</div>
						</MenuItem>
					</Select>
				</div>

				<div className="section">
					<p className="title">Update company logo</p>
					<p className="subtitle">
						Note: Ideal logo size is up to 150 x 50 pixels and max size is 250kb. Supported image types are PNG, JPG.
					</p>
					<LogoUploadSection />
				</div>
				<div className="section">
					<p className="title">Change Password</p>
					<p className="subtitle">Please enter your current password and new password to change your password.</p>
					<Button
						onClick={() => setExpanded(true)}
						disabled={!userWritePermission}
						variant="contained"
						style={{ backgroundColor: "#1976d2" }}
						className="mui-button">
						Change Password
					</Button>

					{expanded && (
						<RightSidebar expanded={expanded} setExpanded={setExpanded}>
							<PasswordChangeSidebar setExpanded={setExpanded} />
						</RightSidebar>
					)}
				</div>
			</div>
		</div>
	);
};
export default AccountSettings;
