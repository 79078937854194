import {
	Checkbox,
	FormControlLabel,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import { FC } from "react";
import { SPECIFIC_PERMISSION } from "../../../@types";

type Props = {
	permissions: SPECIFIC_PERMISSION[];
	// eslint-disable-next-line no-unused-vars
	onChange: (e: any) => void;
	// eslint-disable-next-line no-unused-vars
	checkAllOptions: (e: any) => void;
	name: string;
	isParentEditingAllowed: boolean;
	subsMetadata:any;
};
const PermissionFunctionalityTable: FC<Props> = ({
	permissions,
	onChange,
	checkAllOptions,
	name,
	isParentEditingAllowed,
	subsMetadata
}) => {
	function createData(functionality_name: string, read: boolean, write: boolean) {
		return { functionality_name, read, write };
	}

	const whetherToShowFeaturePermission = (modFuncName: string) => {
		switch (modFuncName) {
			case "Rosters":
				return subsMetadata?.rosters === 1 ? true : false;
			case "Live Location":
				return subsMetadata?.liveLocation === 1 ? true : false;
			case "Timeline":
				return subsMetadata?.timeline === 1 ? true : false;
			case "Card View":
				return subsMetadata?.cardView === 1 ? true : false;
			case "Compliance Status":
				return subsMetadata?.compliance === 1 ? true : false;
			case "Site Attendance":
				return subsMetadata?.siteAttendance === 1 ? true : false;
			case "Advance Requests":
				return subsMetadata?.advances === 1 ? true : false;
			case "Expenses":
				return subsMetadata?.expenses === 1 ? true : false;
			case "Sales":
				return subsMetadata?.orders === 1 ? true : false;
			case "Productivity":
				return subsMetadata?.tasks === 1 ? true : false;
			case "Client":
				return subsMetadata?.clients === 1 ? true : false;
			case "Location and Travel":
				return true;
			case "Scheduled Reports":
				return subsMetadata?.scheduledReports === 1 ? true : false;
			case "Custom Fields":
				return subsMetadata?.noOfCustomFields !== 0 ? true : false;
			case "Odometer":
				return subsMetadata?.odometer === 1 ? true : false;
			case "Orders":
				return subsMetadata?.orders === 1 ? true : false;
			case "Site Types":
				return subsMetadata?.clients === 1 && subsMetadata?.sites === 1 ? true : false;
			case "SMS":
				return subsMetadata?.sms === 1 ? true : false;
			case "Tasks":
				return subsMetadata?.tasks === 1 ? true : false;
			case "Clients":
				return subsMetadata?.clients === 1 ? true : false;
			case "Sites":
				return subsMetadata?.sites === 1 ? true : false;
			case "Geofences":
				return subsMetadata?.noOfGeofences !== 0 ? true : false;
			case "Integrations":
				return subsMetadata?.Integrations === 1 ? true : false;
			case "Location Tracking":
				return subsMetadata?.locationTracking === 1 ? true : false;
			case "Modules":
				return subsMetadata?.modules === 1 ? true : false;
			case "Expense":
				return subsMetadata?.expenses === 1 ? true : false;
			case "Compliance":
				return subsMetadata?.compliance === 1 ? true : false;
			default:
				return true;
		}
	};

	const rows = permissions
		.filter((permission) => whetherToShowFeaturePermission(permission.modFuncName))
		.map((permission) =>
			createData(permission.modFuncName, !!permission.permissions.read, !!permission.permissions.write)
		);
		
	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 500 }}>
				<TableHead>
					<TableRow sx={{ backgroundColor: "#F4F4F5" }}>
						<TableCell align="left">Functionality Name</TableCell>
						<TableCell align="left">
							<FormControlLabel
								value="read"
								control={<Checkbox size="small" />}
								label="Read"
								name={name}
								onChange={checkAllOptions}
								disabled={isParentEditingAllowed}
								style={{ cursor: isParentEditingAllowed ? "not-allowed" : "auto" }}
							/>
						</TableCell>
						<TableCell align="left">
							<FormControlLabel
								value="write"
								control={<Checkbox size="small" />}
								label="Write"
								name={name}
								onChange={checkAllOptions}
								disabled={isParentEditingAllowed}
								style={{ cursor: isParentEditingAllowed ? "not-allowed" : "auto" }}
							/>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow key={row.functionality_name}>
							<TableCell component="th" scope="row">
								{row.functionality_name}
							</TableCell>
							<TableCell align="left">
								<FormControlLabel
									name={row.functionality_name}
									value="read"
									control={<Checkbox size="small" checked={row.read ?? false} />}
									label=""
									onChange={onChange}
									disabled={isParentEditingAllowed}
									style={{ cursor: isParentEditingAllowed ? "not-allowed" : "auto" }}
								/>
							</TableCell>
							<TableCell align="left">
								<FormControlLabel
									name={row.functionality_name}
									value="write"
									control={<Checkbox size="small" checked={row.write ?? false} />}
									label=""
									onChange={onChange}
									disabled={isParentEditingAllowed}
									style={{ cursor: isParentEditingAllowed ? "not-allowed" : "auto" }}
								/>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default PermissionFunctionalityTable;
