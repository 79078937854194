/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useEffect, useMemo, useState } from "react";
import _debounce from "lodash/debounce";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import useAdminEntitlements from "../../useAdminEntitlements";

type Props = {
	geofencePools: any;
	geofenceIDs: any;
	setSelectedGeofencePoolID: any;
	setIsGeofenceTab: any;
	isGeofenceTab: boolean;
};
const GeofencePoolList: FC<Props> = ({
	geofencePools,
	geofenceIDs,
	setSelectedGeofencePoolID,
	setIsGeofenceTab,
	isGeofenceTab,
}) => {
	const [searchTerm, setSearchTerm] = useState("");
	const userWritePermission = useAdminEntitlements("clientsAndSites:geofences", "write");

	const debouncedSearch = useMemo(
		() =>
			_debounce(function (e) {
				setSearchTerm(e.target.value);
			}, 500),
		[]
	);
	useEffect(() => () => {
		debouncedSearch.cancel();
	});
	const filteredGeofencePools = geofencePools.filter((g: any) =>
		g.name.toLowerCase().replace(/ /g, "").includes(searchTerm.replace(/ /g, "").toLowerCase())
	);

	return (
		<div className="user_add geofence_pool_sidebar">
			<div className="button-group">
				<button
					onClick={() => setIsGeofenceTab(true)}
					className={`group ${isGeofenceTab ? "selected" : ""}`}
					style={{ height: "36px", flex: 1 }}>
					<FormatListBulletedOutlinedIcon />
					Geofence
				</button>
				<button
					onClick={() => setIsGeofenceTab(false)}
					className={`group ${!isGeofenceTab ? "selected" : ""}`}
					style={{ height: "36px", flex: 1 }}>
					<MapOutlinedIcon />
					Geofence Pools
				</button>
			</div>
			<input type="search" onChange={debouncedSearch} placeholder="Search Geofence..." className="search" />
			<ul style={{ height: "calc(100vh - 360px)" }}>
				{filteredGeofencePools.map((geofencePool: any) => (
					<li
						key={geofencePool.geofencePoolID}
						onClick={() => {
							if (!userWritePermission) return;
							setSelectedGeofencePoolID(geofencePool.geofencePoolID);
						}}
						className="geofence_pool_list_item">
						<div>
							<h3>{geofencePool.name}</h3>
						</div>
						{/* <div className="right_list_item" style={{display: "flex", alignItems: "center", gap: "10px"}}> */}
						<div className="count_badge">{geofenceIDs[geofencePool.geofencePoolID].length} Geofences</div>
						{/* <EditOutlinedIcon
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedGeofenceID(geofencePool.geofencePoolID);
                                    editPolygon(geofencePool.geofencePoolID, regionMarkers);
                                }}
                            /> */}
						{/* </div> */}
					</li>
				))}
			</ul>
		</div>
	);
};

export default GeofencePoolList;
