import BusinessIcon from "@mui/icons-material/Business";
import { MenuItem, Select, Skeleton } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateClientCompanyLevelSettings } from "../../../api/settings";
import { DD_STYLES } from "../../../constants";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import useAdminEntitlements from "../../useAdminEntitlements";
import useFetch from "../../useFetch";
import ReadOnlyButton from "../../common/ReadOnlyButton";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
type FETCH = {
	companyLevel: {
		vasaForClients: 0 | 1;
		taskGeofenceRadius: number;
	};
	teamLevel: {
		clientVisibleToAll: 0 | 1 | 2;
		clientsEnabled: 1 | 0;
		profileID: number;
		profileName: string;
	}[];
};

const CompanyLevelClientSettings: FC = () => {
	const { data, loading } = useFetch<FETCH>("/preferences/clients");
	const dispatch = useDispatch<APP_DISPATCH>();
	const [vasa, setVasa] = useState(0);
	const [taskGeofenceRadius, setTaskGeofenceRadius] = useState(0);
	const userWritePermission = useAdminEntitlements("settings:clients", "write");
	const { roleID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);

	useEffect(() => {
		if (!loading && data?.data?.companyLevel) {
			setVasa(data.data.companyLevel.vasaForClients);
			setTaskGeofenceRadius(data.data.companyLevel.taskGeofenceRadius);
		}
	}, [data?.data?.companyLevel, loading]);

	const handleChange = async (value: number | string) => {
		const newValue = typeof value === "number" ? value : +value;
		const { success, message } = await updateClientCompanyLevelSettings(newValue, taskGeofenceRadius);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (success) setVasa(newValue);
	};

	const debouncedUpdate = useCallback(
		debounce((value: number) => {
			updateClientCompanyLevelSettings(vasa, value).then(({ success, message }) => {
				dispatch(showNotification({ message, severity: success ? "success" : "error" }));
			});
		}, 700),
		[]
	);

	const handleClientRadiusChange = (value: number) => {
		debouncedUpdate(value);
	};

	return (
		<div className="company-level-task-settings">
			<div className="company-level-header">
				<BusinessIcon className="svgicons" />
				<p className="title">Company Level Settings</p>

				{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="settings" /> : null}
			</div>
			<div className="horizontal-line"></div>
			<div className="d-flex gap-2">
				{loading ? (
					<div className="section">
						<p className="title">
							<Skeleton variant="text" width={300} height={30} />
						</p>
						<p className="subtitle">
							<Skeleton variant="text" width={300} height={65} />
						</p>
					</div>
				) : (
					<div className="section">
						<p className="title">VASA enabled for clients</p>
						<p className="subtitle">Generate VASA events for clients</p>
						<Select
							disabled={!userWritePermission}
							onChange={(e) => handleChange(e.target.value)}
							value={vasa}
							sx={DD_STYLES}>
							<MenuItem value={1}>On</MenuItem>
							<MenuItem value={0}>Off</MenuItem>
						</Select>
					</div>
				)}

				{loading ? (
					<div className="section">
						<p className="title">
							<Skeleton variant="text" width={300} height={30} />
						</p>
						<p className="subtitle">
							<Skeleton variant="text" width={300} height={65} />
						</p>
					</div>
				) : (
					<div className="section">
						<p className="title">Default Radius for clients</p>
						<p className="subtitle">Default radius for all client added from the app</p>

						<input
							type="number"
							disabled={!userWritePermission}
							className="form-control"
							placeholder="Enter radius"
							value={taskGeofenceRadius}
							min={0}
							onChange={(e) => {
								console.log(e.target.value, typeof e.target.value, +e.target.value);
								if (+e.target.value < 0) {
									dispatch(showNotification({ message: "Radius cannot be negative", severity: "error" }));
									return;
								}
								setTaskGeofenceRadius(+e.target.value);
								handleClientRadiusChange(+e.target.value);
							}}
							style={{
								width: "100%",
								padding: "5px",
								height: "40px",
								borderRadius: "8px",
								outline: "none",
								fontWeight: "bold",
							}}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default CompanyLevelClientSettings;
