/* eslint-disable no-unused-vars */
import { AxiosResponse } from "axios";
import { useRouter } from "next/router";
import "moment-timezone";
import "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PRODUCT_ID } from "../constants";
import { APP_DISPATCH, logoutAllSystem, showNotification } from "../redux";
import axios from "../utils/axios";
import { subscriptionMapping } from "../constants/subscriptionMapping";
axios.defaults.withCredentials = true;
export default function useFetch<T extends any>(url: string) {
	type FETCH_RESPONSE = {
		status: boolean;
	} & { [x: string]: T };
	const productId = (window && (localStorage.getItem(PRODUCT_ID) as "1" | "2" | "5" | "6" | "7" |"8" | "9")) || "2";
	const router = useRouter();

	const dispatch = useDispatch<APP_DISPATCH>();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<unknown | null>(null);
	const [data, setData] = useState<FETCH_RESPONSE>({} as FETCH_RESPONSE);
	const refetch = async () => {
		await fetchData();
	};
	const fetchData = async () => {
		setLoading(true);
		try {
			const response: AxiosResponse<FETCH_RESPONSE> = await axios.get(
				`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1${url}`
			);
			if (response.status === 203) {
				router.push("/not_authorized");
				dispatch(
					showNotification({
						message: `You are not authorized to use ${subscriptionMapping[productId]} Panel`,
						severity: "error",
					})
				);
				return;
			}

			setData({ ...response.data });
			setError(null);
		} catch (e: any) {
			console.log(e);
			console.log("🚨🚨🚨🚨🚨🚨🚨🚨", e.response?.status);
			if (e.response?.status === 401) dispatch(logoutAllSystem());
			setError(e);
		}
		setLoading(false);
	};
	useEffect(() => {
		if (!url?.includes("undefined")) fetchData();
	}, [dispatch, url, router, productId]);

	return { data, loading, error, refetch };
}
