import { Alert, Snackbar } from "@mui/material";
import { FC } from "react";
import { SNACKBAR_SEVERITY_TYPE } from "../../@types";
import Slide from "@mui/material/Slide";
type Props = {
	message: string;
	duration?: number;
	severity: SNACKBAR_SEVERITY_TYPE;
	open: boolean;
	handleClose: () => void;
};
const SnackBar: FC<Props> = ({ open, duration = 4000, handleClose, message, severity }) => (
	<Snackbar
		open={open}
		autoHideDuration={duration}
		onClose={handleClose}
		TransitionComponent={Slide}
		anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
		<Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
			{message}
		</Alert>
	</Snackbar>
);

export default SnackBar;
