/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import _orderBy from "lodash/orderBy";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { CONVEYANCE_TYPE, EVIDENCE_TYPE } from "../../../@types";
import ImageCarousel from "../Request/ImageCarousel";
import { conveyanceRequestStatus } from "../../../constants/conveyanceConstant";
import { ROOT_STATE } from "../../../redux";
import { useSelector } from "react-redux";
import { AccessTime } from "@mui/icons-material";
import { checkS3LinkValidity } from "../../../utils";
import FileCard from "../../common/FileCard";
import { formatDateTo_DDMMYYYY } from "../../../utils/validations";

type Props = {
	request: CONVEYANCE_TYPE;
	setRequest: Dispatch<SetStateAction<CONVEYANCE_TYPE>>;
};
const ConveyanceDescription: FC<Props> = ({ request }) => {
	const [open, setOpen] = useState(false);
	const user = useSelector((state: ROOT_STATE) => state.user);
	const [evidences, setEvidences] = useState<EVIDENCE_TYPE[]>([]);
	const [fileUploads, setFileUploads] = useState<any[]>([]);

	const [currentIndex, setCurrentIndex] = useState(0);
	const allExpenseAuth = request.expenseAuth;

	const totalUniqueExpenseRequests = allExpenseAuth.reduce((accum, curr) => {
		if (curr.adminID === user.adminDetails.adminID && curr.actionPerformed === conveyanceRequestStatus.PENDING) {
			return accum;
		} else return accum + 1;
	}, 0);

	const getStatus = (actionPerformed: number) => {
		switch (actionPerformed) {
			case conveyanceRequestStatus.REJECTED:
				return "Rejected";
			case conveyanceRequestStatus.APPROVED:
				return "Approved";
			case conveyanceRequestStatus.PAIDOUT:
				return "Paid Out";
			case conveyanceRequestStatus.PENDING:
				return "Pending";
			default:
				return "Pending";
		}
	};
	useEffect(() => {
		const getEvidences = async () => {
			const evidences: EVIDENCE_TYPE[] =
				request.evidences && typeof request.evidences === "string"
					? request.evidences.length > 0
						? JSON.parse(request.evidences)
						: []
					: [];
			const tranformedEvidences = await Promise.all(
				evidences.map(async (evidence) => {
					const isValidS3Link = await checkS3LinkValidity(evidence.photoURL);
					return {
						...evidence,
						isValidS3Link,
					};
				})
			);

			setEvidences(tranformedEvidences);
		};
		getEvidences();
	}, [request.evidences]);

	useEffect(() => {
		const getFileUploads = async () => {
			const fileUploads: any[] =
				request.fileUploads && typeof request.fileUploads === "string"
					? request.fileUploads.length > 0
						? JSON.parse(request.fileUploads)
						: []
					: [];
			const tranformedFileUploads = await Promise.all(
				fileUploads.map(async (fileUpload) => {
					const isValidS3Link = await checkS3LinkValidity(user?.adminDetails?.companyID + "/" + fileUpload.fileURL);
					return {
						...fileUpload,
						isValidS3Link,
					};
				})
			);
			setFileUploads(tranformedFileUploads);
		};
		getFileUploads();
	}, [request.fileUploads]);

	return (
		<div className="expanse_description">
			<div className="description">
				<h4 style={{ marginRight: "10px", marginBottom:"10px"}}>Description</h4>
				<p>{request.description}</p>
			</div>
			{((Array.isArray(request.conveyanceDetails) && request.conveyanceDetails.length > 0) ||
				(Array.isArray(evidences) && evidences.length > 0)) && (
					<TableContainer component={Paper} className="expense_tables" elevation={0} sx={{ marginTop: "20px" }}>
						<Table sx={{ minWidth: 550 }} aria-label="Expense Details">
							<TableHead>
								<TableRow>
									<TableCell>Mode</TableCell>
									<TableCell>Distance Claimed(KM)</TableCell>
									<TableCell>Odometer In Reading(KM)</TableCell>
									<TableCell>Odometer Out Reading(KM)</TableCell>
									<TableCell>Amount</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Array.isArray(request.conveyanceDetails) &&
									request.conveyanceDetails.map((conveyanceDetail) => (
										<TableRow
											key={conveyanceDetail.conveyanceID}
											sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
											<TableCell>
												{conveyanceDetail.mode === 1 && <strong>Car: </strong>}
												{conveyanceDetail.mode === 2 && <strong>Bike: </strong>}
												{conveyanceDetail.mode === 3 && <strong>Public Transport </strong>}
											</TableCell>
											<TableCell>{conveyanceDetail.distanceClaimed}</TableCell>
											<TableCell>{conveyanceDetail.inReading ?? ""}</TableCell>
											<TableCell>{conveyanceDetail.outReading ?? ""}</TableCell>
											<TableCell component="th" scope="row">
												Rs. {conveyanceDetail.amountClaimed}
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
						<div className="p-3">
							{evidences.length > 0 && (
								<div>
									<p style={{ color: "#6c757d", paddingBottom: "10px" }}>Evidences (click below to view):</p>
									<div className="image_gallery">
										{evidences.map((evidence, i) => (
											<>
												<div key={evidence.photoID} style={{ display: "flex", flexDirection: "column" }}>
													<img
														key={evidence.photoID}
														onClick={() => {
															setCurrentIndex(i);
															setOpen(true);
														}}
														src={process.env.NEXT_PUBLIC_S3_URL + evidence.photoURL}
														alt="evidence"
														onError={(e: any) => {
															e.currentTarget.src = "/images/brokenImage.png";
														}}
													/>

													<Tooltip
														title="Image has not been uploaded from phone, please ask the user to Sync from the app."
														placement="top"
														hidden={evidence.isValidS3Link}
														sx={{ marginTop: "5px" }}>
														<AccessTime fontSize="small" color="primary" />
													</Tooltip>
												</div>
											</>
										))}
									</div>
								</div>
							)}
							{request?.fileUploads !== null && request?.fileUploads !== "" && (
								<div>
									<p style={{ color: "#6c757d", paddingTop: "20px" }}>Files (click below to view or download):</p>
									<div style={{ display: "flex", flexWrap: "wrap", gap: "20px", marginTop: "10px" }}>
										{fileUploads.map((file: any, i: any) => (
											<>
												<FileCard file={file} i={i} user={user} isValidLink={file.isValidS3Link} />
											</>
										))}
									</div>
								</div>
							)}
						</div>
					</TableContainer>
				)}
			{evidences.length > 0 && (
				<ImageCarousel
					open={open}
					handleClose={() => setOpen(false)}
					evidences={evidences}
					currentIndex={currentIndex}
					setCurrentIndex={setCurrentIndex}
				/>
			)}
			<h4 style={{ marginTop: "30px", marginBottom:"10px"}}>History</h4>
			{Array.isArray(request.expenseAuth) && request.expenseAuth.length > 0 && (
				<TableContainer component={Paper} className="expense_tables" elevation={0}>
					<Table sx={{ minWidth: 550 }} aria-label="Audit Trail">
						<TableHead>
							<TableRow>
								<TableCell>Date</TableCell>
								<TableCell>Time</TableCell>
								<TableCell>Name of Approver</TableCell>
								<TableCell>Comment</TableCell>
								<TableCell>Amount</TableCell>
								<TableCell>Status</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Array.isArray(request.expenseAuth) &&
								_orderBy(request.expenseAuth, ["timestamp"], ["asc"]).map((auth, i: number) => {
									const status = getStatus(auth.actionPerformed);

									if (
										auth.actionPerformed === conveyanceRequestStatus.PENDING &&
										auth.adminID === user?.adminDetails?.adminID
									)
										return;

									if (
										auth.actionPerformed === conveyanceRequestStatus.PENDING &&
										auth.adminID !== user?.adminDetails?.adminID
									)
										return (
											<TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
												<TableCell colSpan={6} align={"center"} className="pending_with_others">
													<span className={`role_badge ${status}`}>
														{status} with <b>{auth.adminName}</b>
													</span>
												</TableCell>
											</TableRow>
										);

									return (
										<TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
											<TableCell>{formatDateTo_DDMMYYYY(auth.timestamp)}</TableCell>
											<TableCell>{formatDateTo_DDMMYYYY(auth.timestamp)}</TableCell>
											<TableCell>{auth.adminName}</TableCell>
											<TableCell>{auth.comment || ""}</TableCell>
											<TableCell>{auth.reimbursementAmount ?? "-"}</TableCell>
											<TableCell>
												<span className={`role_badge ${status}`}>{status}</span>
											</TableCell>
										</TableRow>
									);
								})}
							{totalUniqueExpenseRequests === 0 && (
								<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell colSpan={6} align={"center"} className="pending_with_others">
										<span>No Data</span>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</div>
	);
};

export default ConveyanceDescription;
