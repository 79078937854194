import { createSlice } from "@reduxjs/toolkit";
import { ATTENDANCE_STATUS_ROWS } from "../../../@types";

const initialState = {
	attendanceStatus: [] as ATTENDANCE_STATUS_ROWS[],
};

const attendanceStatusSlice = createSlice({
	name: "attendanceStatus",
	initialState,
	reducers: {
		setAttendanceStatus: (state, action) => {
			state.attendanceStatus = action.payload.attendanceStatus;
		},
	},
});

export const { setAttendanceStatus } = attendanceStatusSlice.actions;

export default attendanceStatusSlice.reducer;
