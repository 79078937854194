// eslint-disable-next-line import/prefer-default-export
import TitleIcon from "@mui/icons-material/Title";
import NumbersIcon from "@mui/icons-material/Numbers";
import TodayIcon from "@mui/icons-material/Today";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PersonIcon from "@mui/icons-material/Person";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import HomeIcon from "@mui/icons-material/Home";
import ImageIcon from "@mui/icons-material/Image";
import ArticleIcon from "@mui/icons-material/Article";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import HouseIcon from "@mui/icons-material/House";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

export const ELEMENT_TO_SETTING_MAP = {
	Text: {
		dataType: "varchar",
		settingName: "varcharSettings",
		settings: {
			required: false,
			write: true,
			editAfterSubmit: false,
			allowAdminToEditAfterSubmit: false,
		},
	},
	Number: {
		dataType: "number",
		settingName: "numberSettings",
		settings: {
			required: false,
			write: true,
			editAfterSubmit: false,
			allowAdminToEditAfterSubmit: false,
		},
	},
	Date: {
		dataType: "date",
		settingName: "dateSettings",
		settings: {
			required: false,
			write: true,
			editAfterSubmit: false,
			allowAdminToEditAfterSubmit: false,
		},
	},
	Contact: {
		dataType: "contact",
		settingName: "contactSettings",
		settings: {
			required: false,
			write: true,
			editAfterSubmit: false,
			allowAdminToEditAfterSubmit: false,
		},
	},
	Address: {
		dataType: "address",
		settingName: "addressSettings",
		settings: {
			required: false,
			write: true,
			editAfterSubmit: false,
			allowAdminToEditAfterSubmit: false,
		},
	},
	Forms: {
		dataType: "form",
		settingName: "formSettings",
		settings: {
			required: false,
			editAfterSubmit: false,
		},
	},
	Dropdown: {
		dataType: "dropDown",
		settingName: "dropDownSettings",
		settings: {
			required: false,
			write: true,
			editAfterSubmit: false,
			allowAdminToEditAfterSubmit: false,
		},
	},
	Checkbox: {
		dataType: "checkbox",
		settingName: "checkboxSettings",
		settings: {
			required: false,
			write: true,
			editAfterSubmit: false,
			allowAdminToEditAfterSubmit: false,
		},
	},
	Client: {
		dataType: "client",
		settingName: "clientSettings",
		settings: {
			required: true,
			clientModification: false,
			allowClientSelectionByEmployee: true,
			clientGeoValidation: true,
			oneTaskPerClientPerDay: false,
			isOtpVerificationEnabledForTasks: false,
		},
	},
	Site: {
		dataType: "site",
		settingName: "siteSettings",
		settings: {
			required: true,
			siteGeoValidation: true,
			oneTaskPerClientPerDay: false,
		},
	},
	Location: {
		dataType: "metaLocation",
		settingName: "locationSettings",
		settings: {
			metaLocationGeoValidation: true,
			metaLocationGeoValidationRadius: 200,
		},
	},
	Image: {
		dataType: "photo",
		settingName: "photoSettings",
		settings: {
			required: false,
			write: true,
			editAfterSubmit: false,
			taskPhoto: false,
			selfiePhoto: false,
			allowAdminToEditAfterSubmit: false,
		},
	},
	File: {
		dataType: "file",
		settingName: "fileSettings",
		settings: {
			required: false,
			write: true,
			editAfterSubmit: false,
			allowAdminToEditAfterSubmit: false,
		},
	},
	Order: {
		dataType: "order",
		settingName: "orderSettings",
		settings: {
			required: false,
		},
	},
	Time: {
		dataType: "time",
		settingName: "timeSettings",
		settings: {
			required: false,
			write: true,
			editAfterSubmit: false,
		},
	},
};
export const allOptionsMap = {
	// General Options
	varchar: <TitleIcon fontSize="small" />,
	number: <NumbersIcon fontSize="small" />,
	date: <TodayIcon fontSize="small" />,
	contact: <LocalPhoneIcon fontSize="small" />,
	address: <HomeIcon fontSize="small" />,
	form: <ArticleIcon fontSize="small" />,
	order: <LocalShippingIcon fontSize="small" />,
	time: <AccessTimeIcon fontSize="small" />,

	// Multi-Select Options
	dropDown: <ArrowDropDownCircleIcon fontSize="small" />,
	checkbox: <CheckBoxIcon fontSize="small" />,

	// Task Location Options
	client: <PersonIcon fontSize="small" />,
	site: <HouseIcon fontSize="small" />,
	metaLocation: <GpsFixedIcon fontSize="small" />,

	// Attachment Options
	photo: <ImageIcon fontSize="small" />,
	file: <InsertDriveFileIcon fontSize="small" />,
};

export const dataTypeToTextMap = {
	varchar: "Text",
	number: "Number",
	date: "Date",
	contact: "Contact",
	address: "Address",
	form: "Forms",
	dropDown: "Dropdown",
	checkbox: "Checkbox",
	client: "Client",
	site: "Site",
	metaLocation: "Location",
	photo: "Image",
	file: "File",
	order: "Order",
	time: "Time",
};

export const PARTNAMES = {
	fieldName: "fieldName",
	sectionTitle: "sectionTitle",
	requiredSettings: "requiredSettings",
	readOnlySettings: "readOnlySettings",
	clientModification: "clientModification",
	allowClientSelectionByEmployee: "allowClientSelectionByEmployee",
	clientGeoValidation: "clientGeoValidation",
	oneTaskPerClientPerDay: "oneTaskPerClientPerDay",
	isOtpVerificationEnabledForTasks: "isOtpVerificationEnabledForTasks",
	clientsInRange: "clientsInRange",
	siteGeoValidation: "siteGeoValidation",
	metaLocationGeoValidation: "metaLocationGeoValidation",
	metaLocationGeoValidationRadius: "metaLocationGeoValidationRadius",
	addOption: "addOption",
	updateOption: "updateOption",
	deleteOption: "deleteOption",
	updateNextSection: "updateNextSection",
	updateOptionNextSection: "updateOptionNextSection",
	setFormID: "setFormID",
	editAfterSubmit: "editAfterSubmit",
	taskPhoto: "taskPhoto",
	selfiePhoto: "selfiePhoto",
	allowAdminToEditAfterSubmit: "allowAdminToEditAfterSubmit",
};

export const getSettingNameFromDataType = (dataType: string) => {
	switch (dataType) {
		case "varchar":
			return "varcharSettings";
		case "number":
			return "numberSettings";
		case "date":
			return "dateSettings";
		case "contact":
			return "contactSettings";
		case "address":
			return "addressSettings";
		case "form":
			return "formSettings";
		case "dropDown":
			return "dropDownSettings";
		case "checkbox":
			return "checkboxSettings";
		case "photo":
			return "photoSettings";
		case "file":
			return "fileSettings";
		case "client":
			return "clientSettings";
		case "site":
			return "siteSettings";
		case "metaLocation":
			return "locationSettings";
		case "order":
			return "orderSettings";
		case "time":
			return "timeSettings";
		default:
			return "";
	}
};

export const clientSettings = [
	{ key: PARTNAMES.clientModification, label: "Allow Client Creation by Employee", prop: "clientModification" },
	{
		key: PARTNAMES.allowClientSelectionByEmployee,
		label: "Allow To Select Client by Employee",
		prop: "allowClientSelectionByEmployee",
	},
	{ key: PARTNAMES.clientGeoValidation, label: "Geo verify client", prop: "clientGeoValidation" },
	{
		key: PARTNAMES.oneTaskPerClientPerDay,
		label: "Restrict 1 Task per client per day",
		prop: "oneTaskPerClientPerDay",
	},
	{
		key: PARTNAMES.isOtpVerificationEnabledForTasks,
		label: "OTP Verification",
		prop: "isOtpVerificationEnabledForTasks",
	},
];
