/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Dispatch, SetStateAction } from "react";

import { FC, useEffect, useState } from "react";
import { ENTITY_METADATA, EVIDENCE_TYPE, EXPENSE_REQUEST_TYPE_V1 } from "../../../@types";
import { useSelector } from "react-redux";
import ImageCarousel from "./ImageCarousel";
import { ROOT_STATE } from "../../../redux";
import { expenseRequestStatus } from "../../../constants/expenseConstant";
import { AccessTime } from "@mui/icons-material";
import { checkS3LinkValidity } from "../../../utils";
import FileCard from "../../common/FileCard";
import moment from "moment";
import { DATE_FORMAT_TO_SHOW, TIME_SHOW_FORMAT } from "../../../constants";

// mui
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomFieldsComplex from "../../common/CustomFieldsComplex";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
	({ theme }) => ({
		border: `1px solid ${theme.palette.divider}`,
		borderRadius: "12px",
		// "&:not(:last-child)": {
		// 	borderBottom: 0,
		//   },
		"&::before": {
			display: "none",
		},
	})
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(180deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(0),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(0),
	borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

type Props = {
	selectedExpense: EXPENSE_REQUEST_TYPE_V1;
	setSelectedExpense: Dispatch<SetStateAction<EXPENSE_REQUEST_TYPE_V1>>;
	loadingEntity: boolean;
	refetchEntity: () => void;
	entityMetadata: ENTITY_METADATA[];
	setImageOrFileError: any;
	setCustomFieldErrors: any;
	currencyCode:any;
};

const ExpenseDescriptionV1: FC<Props> = ({
	selectedExpense,
	setSelectedExpense,
	loadingEntity,
	refetchEntity,
	entityMetadata,
	setImageOrFileError,
	setCustomFieldErrors,
	currencyCode
}) => {
	const [open, setOpen] = useState(false);
	const user = useSelector((state: ROOT_STATE) => state.user);
	const [evidences, setEvidences] = useState<EVIDENCE_TYPE[]>([]);
	const [fileUploads, setFileUploads] = useState<any[]>([]);
	const [currentIndex, setCurrentIndex] = useState(0);

	// const [fieldsData, setFieldsData] = useState<EXPENSE_REQUEST_TYPE_V1>({...selectedExpense});
	const [expanded, setExpanded] = useState(true); // State for expansion

	const handleExpand = () => {
		setExpanded(!expanded); // Toggle expansion state
	};
	const allExpenseAuth = selectedExpense.authorizationInfo.history;
	const totalUniqueExpenseRequests = allExpenseAuth.reduce((accum, curr) => {
		if (curr.adminID === user.adminDetails.adminID && curr.actionPerformed === expenseRequestStatus.PENDING) {
			return accum;
		} else return accum + 1;
	}, 0);

	const getStatus = (actionPerformed: number) => {
		switch (actionPerformed) {
			case expenseRequestStatus.REJECTED:
				return "Rejected";
			case expenseRequestStatus.APPROVED:
				return "Approved";
			case expenseRequestStatus.PAIDOUT:
				return "Paid Out";
			case expenseRequestStatus.PENDING:
				return "Pending";
			default:
				return "Pending";
		}
	};
	const expenseDetails = selectedExpense.expenseExpenseDetails;

	useEffect(() => {
		const getEvidences = async () => {
			const evidences: EVIDENCE_TYPE[] =
				selectedExpense.evidences && typeof selectedExpense.evidences === "string"
					? selectedExpense.evidences.length > 0
						? JSON.parse(selectedExpense.evidences)
						: []
					: [];
			const tranformedEvidences = await Promise.all(
				evidences.map(async (evidence) => {
					const isValidS3Link = await checkS3LinkValidity(evidence.photoURL);
					return {
						...evidence,
						isValidS3Link,
					};
				})
			);

			setEvidences(tranformedEvidences);
		};
		getEvidences();
	}, [selectedExpense.evidences]);

	useEffect(() => {
		const getFileUploads = async () => {
			const fileUploads: any[] =
				selectedExpense.fileUploads && typeof selectedExpense.fileUploads === "string"
					? selectedExpense.fileUploads.length > 0
						? JSON.parse(selectedExpense.fileUploads)
						: []
					: [];
			const tranformedFileUploads = await Promise.all(
				fileUploads.map(async (fileUpload) => {
					const isValidS3Link = await checkS3LinkValidity(user?.adminDetails?.companyID + "/" + fileUpload.fileURL);
					return {
						...fileUpload,
						isValidS3Link,
					};
				})
			);
			setFileUploads(tranformedFileUploads);
		};
		getFileUploads();
	}, [selectedExpense.fileUploads]);

	return (
		<div className="expanse_description">
			<div className="description">
				<Typography variant="h6" fontSize={"18px"} sx={{ marginRight: "10px" }}>Description:</Typography>
				<p>{selectedExpense.description}</p>
			</div>
			{((Array.isArray(expenseDetails) && expenseDetails.length > 0) ||
				(Array.isArray(evidences) && evidences.length > 0)) && (
					<TableContainer
						component={Paper}
						className="expense_tables"
						elevation={0}
						sx={{ marginTop: "20px" }}>
						<Table sx={{ minWidth: 550 }} aria-label="Expense Details">
							<TableHead>
								<TableRow>
									<TableCell>Expense Category</TableCell>
									<TableCell>Amount Claimed</TableCell>
									<TableCell>Expense Description</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Array.isArray(expenseDetails) &&
									expenseDetails.map((expenseDetail) => (
										<TableRow key={expenseDetail.expenseID} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
											<TableCell>{expenseDetail.category}</TableCell>
											<TableCell>
												{currencyCode} {expenseDetail.amountClaimed}
											</TableCell>

											<TableCell component="th" scope="row">
												{expenseDetail.description}
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
						<div className="p-3">
							{evidences.length > 0 && (
								<div>
									<p style={{ color: "#6c757d", paddingBottom: "10px" }}>Evidences (click below to view):</p>
									<div className="image_gallery">
										{evidences.map((evidence, i) => (
											<>
												<div key={evidence.photoID} style={{ display: "flex", flexDirection: "column" }}>
													<img
														key={evidence.photoID}
														onClick={() => {
															setCurrentIndex(i);
															setOpen(true);
														}}
														src={process.env.NEXT_PUBLIC_S3_URL + evidence.photoURL}
														alt="evidence"
														onError={(e: any) => {
															e.currentTarget.src = "/images/brokenImage.png";
														}}
													/>

													<Tooltip
														title="Image has not been uploaded from phone, please ask the user to Sync from the app."
														placement="top"
														hidden={evidence.isValidS3Link}
														sx={{ marginTop: "5px" }}>
														<AccessTime fontSize="small" color="primary" />
													</Tooltip>
												</div>
											</>
										))}
									</div>
								</div>
							)}
							{selectedExpense?.fileUploads !== null && selectedExpense?.fileUploads !== "" && (
								<div>
									<p style={{ color: "#6c757d" }}>Files (click below to view or download):</p>
									<div
										style={{
											display: "flex",
											flexWrap: "wrap",
											gap: "20px",
											marginTop: "10px",
										}}>
										{fileUploads.map((file: any, i: any) => (
											<>
												<FileCard file={file} user={user} i={i} isValidLink={file.isValidS3Link} />
											</>
										))}
									</div>
								</div>
							)}
						</div>
					</TableContainer>
				)}
			{evidences.length > 0 && (
				<ImageCarousel
					open={open}
					handleClose={() => setOpen(false)}
					evidences={evidences}
					currentIndex={currentIndex}
					setCurrentIndex={setCurrentIndex}
				/>
			)}
			<Accordion sx={{ marginTop: "30px" }} expanded={expanded} onChange={handleExpand}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
					<Typography  variant="h6" fontSize={"18px"}>Expense Fields</Typography>

				</AccordionSummary>
				<AccordionDetails>
					<div className="custom_fields_complex_expense">
						<div className="title">
							<h5>Custom Fields</h5>
							<a href="/settings/custom-fields?entity=Expenses" target="_blank">
								<AddIcon />
							</a>
							<button disabled={loadingEntity} className="refetch" type="button" onClick={() => refetchEntity()}>
								<RefreshOutlinedIcon />
							</button>
						</div>
						<CustomFieldsComplex
							fieldsData={selectedExpense}
							setFieldsData={setSelectedExpense}
							missingEntities={entityMetadata}
							setImageOrFileError={setImageOrFileError}
							setCustomFieldErrors={setCustomFieldErrors}
						/>
					</div>
				</AccordionDetails>
			</Accordion>

			<Typography  variant="h6" fontSize={"18px"} sx={{ marginTop: "30px" }}>History</Typography>
			{Array.isArray(allExpenseAuth) && allExpenseAuth.length > 0 && (
				<TableContainer component={Paper} className="expense_tables" elevation={0}>
					<Table sx={{ minWidth: 550 }} aria-label="Audit Trail">
						<TableHead>
							<TableRow>
								<TableCell>Date</TableCell>
								<TableCell>Time</TableCell>
								<TableCell>Name of Approver</TableCell>
								<TableCell>Comment</TableCell>
								<TableCell>Amount</TableCell>
								<TableCell>Status</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Array.isArray(allExpenseAuth) &&
								allExpenseAuth.map((auth: any, i: number) => {
									const status = getStatus(auth.actionPerformed);
									const formatDate = (date: string) => moment(date).format(DATE_FORMAT_TO_SHOW);
									const formatTime = (date: string) => moment(date).format(TIME_SHOW_FORMAT);

									let insertionStatusContent = null;
									if (auth.insertionStatus !== undefined && auth.insertionStatus >= 0 && auth.insertionStatus <= 4) {
										switch (auth.insertionStatus) {
											case 0:
												insertionStatusContent = "Approval process reset after rejection and updated";
												break;
											case 1:
												insertionStatusContent = "Approval process reinitiated with the new model";
												break;
											case 2:
												insertionStatusContent = "Admin deleted, so approval request transferred to parent admin";
												break;
											case 3:
												insertionStatusContent =
													"Fixed approver changed hence approval request transferred to the relevant new admin";
												break;
											case 4:
												insertionStatusContent = "Parent admin has been changed";
												break;
											default:
												insertionStatusContent = null;
												break;
										}
									}

									return (
										<React.Fragment key={i}>
											<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
												<TableCell>{formatDate(auth.lastModifiedTs)}</TableCell>
												<TableCell>{formatTime(auth.lastModifiedTs)}</TableCell>
												<TableCell>{auth.adminName}</TableCell>
												<TableCell>{auth.comment || "-"}</TableCell>
												<TableCell>{auth.reimbursementAmount ?? "-"}</TableCell>
												<TableCell>
													<span className={`role_badge ${status}`}>{status}</span>
												</TableCell>
											</TableRow>
											{insertionStatusContent && ( //special case :- when the model is changed
												<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
													{/* <TableCell>{formatDate(auth.timestamp)}</TableCell>
													<TableCell>{formatTime(auth.lastModifiedTs)}</TableCell> */}
													<TableCell colSpan={12} align={"center"}>
														<span className={`role_badge`}>{insertionStatusContent}</span>
													</TableCell>
												</TableRow>
											)}
										</React.Fragment>
									);
								})}
							{totalUniqueExpenseRequests === 0 && (
								<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell colSpan={6} align={"center"} className="pending_with_others">
										<span>No Data</span>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</div>
	);
};

export default ExpenseDescriptionV1;
