/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Dispatch, FC, SetStateAction } from "react";
import { ADVANCE_REQUEST_TYPE, ENTITY_METADATA } from "../../../@types";
import IndividualDefaulFilter from "../Filter/IndividualDefaultFilter";
import IndividualCustomFieldsFilter from "../Filter/IndividualCustomFiledsFilter";
type Props = {
	numFilters: number;
	setFilterOpen: Dispatch<SetStateAction<boolean>>;
	filters: any;
	setFilters: Dispatch<SetStateAction<any>>;
	requests: ADVANCE_REQUEST_TYPE[];
	localStorageKey: string;
	setRequests: Dispatch<SetStateAction<any>>;
	entityMetadata: ENTITY_METADATA[];
};
const FilterAdvance: FC<Props> = ({
	numFilters,
	setFilterOpen,
	requests,
	setFilters,
	filters,
	localStorageKey,
	setRequests,
	entityMetadata,
}) => {
	const deselectAllAndPutOriginalRows = () => {
		setFilters({});
	};
	const ResetAllAndPutOriginalRows = () => {
		setFilters({});
		setRequests(() => [...requests]);
	};
	const onChangeCheckbox = (e: { target: { checked: any } }, item: string, name: string) => {
		if (!e.target.checked && Object.keys(filters).length == 1 && filters[Object.keys(filters)[0]].length == 1) {
			deselectAllAndPutOriginalRows();
			return; //if checkbox is unchecked and filter has one key only then set the filters to empty
		} else if (!e.target.checked) {
			// if filter is unchecked , remove that filter from filters obj
			let tempArray = requests.filter((e) => JSON.stringify(e) != JSON.stringify(item));
			const tempFilters = filters;
			tempArray = tempFilters[name].filter((item1: string) => item != item1);
			tempFilters[name] = tempArray;
			if (tempFilters[name].length === 0) {
				delete tempFilters[name];
			}
			if (localStorageKey && localStorageKey.length > 0)
				localStorage.setItem(localStorageKey, JSON.stringify(tempFilters));
			setFilters({ ...tempFilters });
		} else {
			//if checkbox is selected , add the filter key and values
			const tempFilters = filters;
			if (tempFilters[name] == undefined) {
				tempFilters[name] = [];
				tempFilters[name].push(item);
			} else {
				tempFilters[name].push(item);
			}
			if (localStorageKey && localStorageKey.length > 0)
				localStorage.setItem(localStorageKey, JSON.stringify(tempFilters));
			setFilters({ ...tempFilters });
		}
	};
	return (
		<form
			onSubmit={(e) => e.preventDefault()}
			onClick={(e) => e.stopPropagation()}
			onKeyPress={(e) => e.stopPropagation()}>
			<div className="filter__header">
				<h5>
					<FilterAltOutlinedIcon />
					Filters
					<span className="right-notification center" style={{ display: "flex", padding: 0 }}>
						{numFilters}
					</span>
				</h5>
				<button onClick={() => setFilterOpen(false)}>
					<CloseSharpIcon htmlColor="#42444A" />
				</button>
			</div>
			<div className="accordion__group">
				<p>Default Fields</p>
				{
					<IndividualDefaulFilter
						filters={filters}
						name="name"
						title="Employee Name"
						data={requests}
						onChangeCheckbox={onChangeCheckbox}
					/>
				}
				{
					<IndividualDefaulFilter
						filters={filters}
						name="designationName"
						title="Designation"
						data={requests}
						onChangeCheckbox={onChangeCheckbox}
					/>
				}
				{
					<IndividualDefaulFilter
						filters={filters}
						name="pendingAdmin"
						title="Pending With"
						data={requests}
						onChangeCheckbox={onChangeCheckbox}
					/>
				}
				{
					<IndividualDefaulFilter
						filters={filters}
						name="team"
						title="Team"
						data={requests}
						onChangeCheckbox={onChangeCheckbox}
					/>
				}
				{
					<IndividualDefaulFilter
						filters={filters}
						name="categories"
						title="Category"
						data={requests}
						onChangeCheckbox={onChangeCheckbox}
					/>
				}
				{
					<IndividualDefaulFilter
						filters={filters}
						name="amountProposed"
						title="Amount Proposed"
						data={requests}
						onChangeCheckbox={onChangeCheckbox}
					/>
				}
				<p>Custom Fields</p>
				{entityMetadata
					.filter(
						(metadata) =>
							metadata.active === 1 &&
							metadata.isDefault === 0 &&
							metadata.dataType !== "photo" &&
							metadata.dataType !== "file" &&
							metadata.dataType !== "form" &&
							metadata.dataType !== "employee"
					)
					.sort((a, b) => a.fieldName.localeCompare(b.fieldName))
					.map((metadata: any) => {
						if (metadata.dataType === "contact") {
							return (
								<>
									<IndividualCustomFieldsFilter
										name={metadata.fieldName + " Name"}
										key={metadata.fieldID}
										title={metadata.fieldName + " Name"}
										filters={filters}
										metadata={metadata}
										dataType={metadata.dataType}
										data={requests}
										onChangeCheckbox={onChangeCheckbox}
									/>
									<IndividualCustomFieldsFilter
										name={metadata.fieldName + " Number"}
										key={metadata.fieldID}
										title={metadata.fieldName + " Number"}
										filters={filters}
										metadata={metadata}
										dataType={metadata.dataType}
										data={requests}
										onChangeCheckbox={onChangeCheckbox}
									/>
								</>
							);
						}
						return (
							<IndividualCustomFieldsFilter
								name={metadata.fieldName}
								key={metadata.fieldID}
								title={metadata.fieldName}
								filters={filters}
								metadata={metadata}
								dataType={metadata.dataType}
								data={requests}
								onChangeCheckbox={onChangeCheckbox}
							/>
						);
					})}
			</div>
			<div className="filter__buttons">
				<button onClick={() => ResetAllAndPutOriginalRows()} disabled={numFilters === 0}>
					Reset
				</button>
			</div>
		</form>
	);
};

export default FilterAdvance;
