import { useMutation, useQuery } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { DataGridPro, GridColDef, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { FC, useEffect, useMemo, useState } from "react";
import { COMMUNICATION_RULES, PROFILES_DATA } from "../../../@types";
import { DG_STYLES } from "../../../constants";
import RightSidebar from "../../common/RightSidebar";
import useAdminEntitlements from "../../useAdminEntitlements";
import useFetch from "../../useFetch";
import ReadOnlyButton from "../../common/ReadOnlyButton";
import InvoiceRulesSidebar from "../Rules/InvoiceRulesSidebar";
import { DELETE_COMMUNICATION_RULES, GET_INVOICE_COMMUNICATION_RULES } from "../../../schema/communication_rules";

type Props = {
	roleID:string;
}
const CompanyLevelOrderSettings:FC<Props> = ({roleID}) => {
	const [columns, setColumns] = useState<GridColDef[]>([]);
	const { data: profileData, loading: profileLoading } = useFetch<PROFILES_DATA[]>("/users/profiles");
	const [expanded, setExpanded] = useState(false);
	const [isUpdating, seIsUpdating] = useState(false);
	const { data: dataAdmins, loading: loadingAdmins } = useFetch<any>("/admin/pullAdmins");
	const allAdmins = dataAdmins?.data?.dataSource ?? [];

	const [deleteRulesBatch] = useMutation(DELETE_COMMUNICATION_RULES);
	const { loading, data, refetch } = useQuery<{ get_rules_by_companyID: COMMUNICATION_RULES[] }>(
		GET_INVOICE_COMMUNICATION_RULES
	);
	const isLoading = loading || profileLoading || isUpdating || loadingAdmins;
	const userWritePermission = useAdminEntitlements("settings:orders", "write");
	const [selectedRowId, setSelectedRowId] = useState<null | number>(null);
	const selectedSetting: null | COMMUNICATION_RULES = useMemo(() => {
		if (selectedRowId === null) return null;
		return data?.get_rules_by_companyID.find((d) => d.ruleID === selectedRowId) ?? null;
	}, [data?.get_rules_by_companyID, selectedRowId]);

	const tempColumns = useMemo(
		() => [
			{
				field: "who",
				headerName: "Who to Send To ?",
				flex: 1,
				minWidth: 200,
				renderCell: (params: GridValueGetterParams<unknown, COMMUNICATION_RULES>) => {
					if (params.row.who === "ADMIN") return "Admin";
					if (params.row.who === "CLIENT") return "Client";
					if (params.row.who === "FIXED_CONTACT") return "Fixed Contact";
					if (params.row.who === "RM") return "Reporting Manager";
					else return params.row.who;
				},
			},
			{
				field: "whoParameter",
				headerName: "Contact",
				flex: 1,
				minWidth: 200,
				renderCell: (params: GridValueGetterParams<unknown, COMMUNICATION_RULES>) => {
					if (params.row.who === "ADMIN") {
						const admin = allAdmins.find((a: any) => a.id === +params.row.whoParameter!);
						return admin ? admin.firstname + " " + admin.lastname : "";
					}
					if (params.row.who === "CLIENT") return "Client";
					if (params.row.who === "FIXED_CONTACT") return params.row.whoParameter;
					if (params.row.who === "RM") return "Reporting Manager";
					else return params.row.who;
				},
			},
			{
				field: "when",
				headerName: "When to Send ?",
				flex: 1,
				minWidth: 200,
				renderCell: (params: GridValueGetterParams<unknown, COMMUNICATION_RULES>) => {
					if (params.row.when === "EOD") return "End of Day";
					if (params.row.when === "IMMEDIATE") return "Immediate";
					else return params.row.when;
				},
			},
			{
				field: "how",
				headerName: "How to Send?",
				flex: 1,
				minWidth: 200,
				renderCell: (params: GridValueGetterParams<unknown, COMMUNICATION_RULES>) => {
					if (params.row.how === "EMAIL") return "Email";
					if (params.row.how === "SMS") return "SMS";
					if (params.row.how === "WA") return "WA";
					else return params.row.how;
				},
			},
			{
				field: "profileID",
				headerName: "Team",
				flex: 1,
				minWidth: 200,
				renderCell: (params: GridValueGetterParams<unknown, COMMUNICATION_RULES>) => {
					if (params.row.profileID === -1) {
						return "All Teams";
					}
					const profile = profileData?.data?.find((profile) => profile.profileID === params.row.profileID);
					return profile?.profileName || "Unknown";
				},
			},
			{
				field: "state",
				headerName: "Action",
				width: 120,
				renderCell: (params: GridValueGetterParams<unknown, COMMUNICATION_RULES>) =>
						<div className="action-buttons">
							<button type="button" onClick={() => openSidebar(params.row.ruleID)} disabled={!userWritePermission}>
								<EditIcon htmlColor="#8F8F8F" />
							</button>
							<button
								title="Delete"
								onClick={(e) => {
									e.stopPropagation();
									deleteInvoice(params.row.ruleID);
								}}
								disabled={!userWritePermission}>
								<DeleteIcon htmlColor="#F54747" />
							</button>
						</div>
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[data?.get_rules_by_companyID, profileData?.data, userWritePermission]
	);
	useEffect(() => setColumns(tempColumns), [tempColumns]);

	const closeSidebar = () => {
		setExpanded(false);
		setSelectedRowId(null);
	};

	const openSidebar = (id: number | null) => {
		setSelectedRowId(id);
		setExpanded(true);
	};
	const deleteInvoice = async (id: number) => {
		if (isNaN(id)) return;
		seIsUpdating(true);
		await deleteRulesBatch({ variables: { ruleIDs: [id] } });
		await refetch();
		seIsUpdating(false);
	};
	return (
		<div className="team-level-task-settings company_level_order" style={{ marginTop: 15 }}>
			<div className="team-level-header">
				<PeopleAltOutlinedIcon />
				<p className="title">Company Level Settings</p>
				{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="settings"/> : null}
			</div>
			<div className="horizontal-line"></div>
			<div className="header">
				<div>
					<h4>Receipt Communication Rules</h4>
					<p>Define who and when the receipt of the order should be sent to</p>
				</div>
				<button disabled={!userWritePermission} onClick={() => openSidebar(null)} className="add mui-button">
					<AddIcon sx={{ marginRight: "7px" }} /> <div>Add</div>
				</button>
			</div>
			<div className="datagrid-table" style={{ height: 500 }}>
				<DataGridPro
					sx={DG_STYLES}
					getRowId={(row) => row.ruleID || 1}
					rows={data?.get_rules_by_companyID || []}
					columns={columns}
					loading={isLoading}
					rowsPerPageOptions={[5, 10, 20, 50]}
					rowHeight={70}
					disableSelectionOnClick
					disableColumnFilter
					onRowClick={(params) => userWritePermission && openSidebar(params.row.ruleID)}
					pagination
				/>
			</div>
			{expanded && (
				<RightSidebar expanded={expanded} setExpanded={closeSidebar}>
					<InvoiceRulesSidebar
						closeSidebar={closeSidebar}
						selectedSetting={selectedSetting}
						allTeams={profileData?.data || []}
						refetch={refetch}
						allAdmins={allAdmins}
						ruleFor={""}
					/>
				</RightSidebar>
			)}
		</div>
	);
};

export default CompanyLevelOrderSettings;