import axios from "../../utils/axios";
import { v4 as uuidv4 } from "uuid";
import { EXECUTIVE_USER } from "../../@types";
import { UNOLO_BIOMETRIC } from "../../constants";
axios.defaults.withCredentials = true;

type DELETE_MULTIPLE_USERS_TYPE = {
	employeeID: number;
	newOwnerID: number | "None";
	parentAdminID: number;
	userID: string;
	rekognitionFaceID: string | null;
};

// type EMPLOYEE_ACTIVATION_TYPE = Array<number>;

export const deleteMultipleUsers = async (
	body: DELETE_MULTIPLE_USERS_TYPE[]
): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.post(
			`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/users/deleteMultipleUsersAndTransferOwnershipNonCRM`,
			{ usersData: body }
		);
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully deleted selected users",
		};
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error deleting selected users";
		return { success: false, message: errorText };
	}
};

export const createNewExecutive = async (body: EXECUTIVE_USER): Promise<{ success: boolean; message: string }> => {
	// Now we are recieving productID in body
	// So that we can decide whether we have to send formData to createBiometricUser API or JSON to existing createUsersv2 API
	const usersToInsert = [];
	const sendToUser = {
		id: uuidv4(),
		firstName: body.firstName,
		lastName: body.lastName,
		internalEmpID: body.internalEmpID || undefined,
		emailID: body.emailID,
		mobileNumber: body.mobileNumber,
		profileID: body.profileID,
		designationID: body.designationID,
		parentAdminID: body.parentAdminID,
		city: body.city || undefined,
		joiningDate: body.joiningDate || undefined,
	};
	const sendToUserSettings = {
		// @ts-ignore
		baseLocationID: body.BaseGeoFenceID || body.BaseGeoFenceID == 0 ? body.BaseGeoFenceID : null,
		autoTaskSitePoolID: body.sitePoolID ? body.sitePoolID : null,
		linkedGeofenceID: body.geofences || null,
		linkedGeofencePoolID: body.geofencePool || null,
		attendanceRestrictionToSiteID: body.attendanceRestrictionToSiteID ? body.attendanceRestrictionToSiteID : null,
		attendanceRestrictionToSitePoolID: body.attendanceRestrictionToSitePoolID
			? body.attendanceRestrictionToSitePoolID
			: null,
		attendanceOutRestrictionToSiteID: body.attendanceOutRestrictionToSiteID
			? body.attendanceOutRestrictionToSiteID
			: null,
		attendanceOutRestrictionToSitePoolID: body.attendanceOutRestrictionToSitePoolID
			? body.attendanceOutRestrictionToSitePoolID
			: null,
		defaultTransportMode: body.defaultTransportMode || 0,
	};
	usersToInsert.push({
		sendToUser,
		sendToUserSettings,
	});
	try {
		if (body.productID === UNOLO_BIOMETRIC) {
			const formData = new FormData();
			// @ts-ignore
			// TS Ignore is added because profilePicture and profilePictureExtension could be null when productID is not UNOLO_BIOMETRIC
			formData.append("imgUpload", body.profilePicture);
			formData.append("usersToInsert", JSON.stringify(usersToInsert));

			const res = await axios.post(
				`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/users/createBiometricUser`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",

						// @ts-ignore
						extension: body.profilePictureExtension,
					},
				}
			);
			return { success: res?.data?.success ?? true, message: res?.data?.message || "New Employee created" };
		} else {
			const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/users/createUserv2`, {
				usersToInsert: JSON.stringify(usersToInsert),
			});
			return { success: res?.data?.success ?? true, message: res?.data?.message || "New Employee created" };
		}
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.status === "string"
				? error?.response?.data?.status
				: error?.message || "Error Creating new Employee";
		return { success: false, message: errorText };
	}
};

export const profilePicUpdate = async (
	image: any,
	extension: ".jpg" | ".jpeg" | ".svg" | ".png" | ".bmp",
	employeeid: number,
	alreadyimg: boolean
): Promise<{ success: boolean; message: string }> => {
	const formData = new FormData();
	formData.append("imgUpload", image, image.name);
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/users/profilePicUpdate`, formData, {
			headers: {
				extension,
				employeeid,
				alreadyimg,
			},
		});
		return { success: res?.data?.success ?? true, message: res?.data?.message || "Profile Picture Uploaded" };
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Uploading Profile Picture";
		return { success: false, message: errorText };
	}
};
/**
 * This function will be used to find whether a profile picture is valid to be indexed to rekognition.
 * This can accept both File(From HTML File Upload) and Link of File (From Bulk Upload)
 * @param image Either File or File Link
 * @param isFile Boolean variable indicating whether image is file or link
 * @param extension extension of the html uploaded file or png by default if it is link
 * @returns success or error
 */
export const validRekognitionImage = async (
	image: any,
	isFile: boolean,
	extension: ".jpg" | ".jpeg" | ".svg" | ".png" | ".bmp"
): Promise<{ success: boolean; message: string }> => {
	const formData = new FormData();
	if (isFile) {
		formData.append("imgFile", image, image.name);
	} else {
		formData.append("imgLink", image);
	}
	try {
		const res = await axios.post(
			`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/users/validRekognitionImage`,
			formData,
			{
				headers: {
					extension,
				},
			}
		);
		return { success: res?.data?.success ?? true, message: res?.data?.message || "Profile Picture is Valid" };
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Validating Profile Picture";
		return { success: false, message: errorText };
	}
};

export const productImageUpload = async (
	image: any,
	imagenum: number,
	extension: ".jpg" | ".jpeg" | ".svg" | ".png" | ".bmp",
	skuid: string
): Promise<{ status: string; imageUrl: string }> => {
	const formData = new FormData();
	formData.append("imgUpload", image, image.name);
	try {
		const res = await axios.post(
			`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/products/productImageUpload`,
			formData,
			{
				headers: {
					imagenum,
					extension,
					skuid,
				},
			}
		);

		return { status: res.data?.status, imageUrl: res.data?.imageUrl };
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Uploading SKU Image";
		return { status: "failed", imageUrl: errorText };
	}
};

export const editFieldExecutive = async (user: EXECUTIVE_USER): Promise<{ success: boolean; message: string }> => {
	if (!user.userID && user.userID === "Do Not Enter") {
		return {
			success: false,
			message: "This User Cannot be edited",
		};
	}
	const usersData = [];
	const toSendToUsers = {
		firstName: user.firstName,
		lastName: user.lastName,
		employeeID: user.employeeID,
		internalEmpID: user.internalEmpID,
		emailID: user.emailID,
		mobileNumber: user.mobileNumber,
		profileID: user.profileID,
		designationID: user.designationID,
		parentAdminID: user.parentAdminID,
		city: user.city || undefined,
		userID: user.userID ? user.userID : null,
		joiningDate: user.joiningDate ? user.joiningDate.split(" ")[0] : null,
	};
	const toSendToUserSettings = {
		// @ts-ignore
		baseLocationID: user.BaseGeoFenceID && user.BaseGeoFenceID != 0 ? user.BaseGeoFenceID : null,
		autoTaskSitePoolID: user.sitePoolID ? user.sitePoolID : null,
		linkedGeofenceID: user.geofences || null,
		linkedGeofencePoolID: user.geofencePool || null,
		attendanceRestrictionToSiteID: user.attendanceRestrictionToSiteID ? user.attendanceRestrictionToSiteID : null,
		attendanceRestrictionToSitePoolID: user.attendanceRestrictionToSitePoolID
			? user.attendanceRestrictionToSitePoolID
			: null,
		attendanceOutRestrictionToSiteID: user.attendanceOutRestrictionToSiteID
			? user.attendanceOutRestrictionToSiteID
			: null,
		attendanceOutRestrictionToSitePoolID: user.attendanceOutRestrictionToSitePoolID
			? user.attendanceOutRestrictionToSitePoolID
			: null,
		defaultTransportMode: user.defaultTransportMode ?? 0,
	};
	usersData.push({
		toSendToUsers,
		toSendToUserSettings,
	});
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/users/editMultipleUsers`, {
			usersData: JSON.stringify(usersData),
		});
		return { success: res?.data?.success ?? true, message: res?.data?.message || "Employee info changed" };
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error changing Employee Info";
		return { success: false, message: errorText };
	}
};

export const resetMultipleDevices = async (employeeIDs: number[]): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/users/resetMultipleUsersDevice`, {
			employeeIDs: JSON.stringify(employeeIDs),
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully reset multiple users",
		};
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Resetting multiple users";
		return { success: false, message: errorText };
	}
};

export const resendMultipleInvites = async (toSend: any[]): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/users/resendInvites`, {
			usersData: JSON.stringify(toSend),
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Successfully Sent ${toSend.length ?? "multiple"} Invites`,
		};
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error Sending ${toSend.length ?? "multiple"}  Invites`;
		return { success: false, message: errorText };
	}
};

export const activateUsers = async (body: Array<number>): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/users/activate`, { employeeIDs: body });

		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Successfully granted access to selected user${body.length > 1 ? "s" : ""}`,
		};
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || `Error in granting access for selected user${body.length > 1 ? "s" : ""}`;
		return { success: false, message: errorText };
	}
};

export const deactivateUsers = async (body: Array<number>): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/users/deactivate`, {
			employeeIDs: body,
		});

		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully revoked access for selected users",
		};
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error in revoking access for selected users";
		return { success: false, message: errorText };
	}
};

export const deleteTestData = async (): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.delete(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/onboarding/testData`);

		return {
			success: res?.data?.success ?? true,
			message:
				res?.data?.message || "Successfully initiated deletion process do reload after sometime to see the changes.",
		};
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error deleting sample data";
		return { success: false, message: errorText };
	}
};
