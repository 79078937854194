/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
//react
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useTransition } from "react";
//library
import __debounce from "lodash/debounce";
//types, utils
import { CLIENTS_DATA } from "../../../@types";
import { findAvatarInitials } from "../../../utils";
//mui
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import { Avatar } from "@mui/material";

type Props = {
	clients: CLIENTS_DATA[];
	isLoading: boolean;
	setVariables: Dispatch<SetStateAction<any>>;
	setSelectedClientId: Dispatch<SetStateAction<string | null>>;
	// eslint-disable-next-line no-unused-vars
	onClientClicked: (clientID: string) => void;
	expanded: boolean;
};
const INCREASE_LIMIT = 20;
const ClientList: FC<Props> = ({
	clients,
	setVariables,
	setSelectedClientId,
	isLoading,
	onClientClicked,
	expanded,
}) => {
	const [isPending, startTransition] = useTransition();
	const observer = useRef<IntersectionObserver | null>(null);
	const lastElementRef = useCallback(
		(node: HTMLLIElement | null) => {
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					startTransition(() => {
						setVariables((variables: any) => ({
							...variables,
							take: variables.take + INCREASE_LIMIT,
						}));
					});
				}
			});
			if (node) observer.current.observe(node);
		},
		[setVariables]
	);

	const debouncedResults = useMemo(
		() =>
			__debounce(function (e) {
				setVariables((variables: any) => ({
					...variables,
					searchKey: e.target.value,
				}));
			}, 500),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	useEffect(() => () => debouncedResults.cancel());

	return (
		<div className={expanded ? "user_add client_sidebar open" : "user_add client_sidebar close"}>
			<input type="search" placeholder="Search Here.." className="search" onChange={debouncedResults} />

			<ul>
				{clients?.map((client, idx) => (
					<li
						ref={idx === clients.length - 1 ? lastElementRef : null}
						key={client.clientID}
						onClick={() => {
							setSelectedClientId(client.clientID);
							onClientClicked(client.clientID);
						}}>
						<picture>
							<Avatar sx={{ backgroundColor: "#D9F0FD", color: "#25A8F4" }}>
								{findAvatarInitials(client.clientName)}
							</Avatar>
						</picture>
						<div>
							<h3>{client.clientName}</h3>
							<small>{client.phoneNumber}</small>
						</div>
						<AddLocationAltOutlinedIcon />
					</li>
				))}
			</ul>
			{isPending || (isLoading && <h3 style={{ textAlign: "center" }}>Loading...</h3>)}
		</div>
	);
};

export default ClientList;
