import { ApolloProvider } from "@apollo/client";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-phone-input-2/lib/style.css";
import "react-phone-number-input/style.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import "reactflow/dist/style.css";
import { Spinner } from "../components";
import ClientOnly from "../components/ClientOnly";
import { store, persistor } from "../redux";
import createClient from "../schema/apollo";
import "../styles/main.scss";
import "../styles/normalize.css";
import "../styles/global.css";
import { Userpilot } from "userpilot";
import { PersistGate } from "redux-persist/integration/react";
import { createTheme, ThemeProvider } from "@mui/material";
// console.log("initializing user pulot!");
// Initialize Userpilot
Userpilot.initialize("NX-ff8924b7");

// we are using secondary color for close buttons in modals
const theme = createTheme({
	palette: {
		secondary: {
			light: "#757575",
			main: "#757575",
			dark: "#757575",
		},
	},
});

const MyApp = ({ Component, pageProps }: AppProps) => {
	const [queryClient] = useState(() => new QueryClient());
	const [loading, setLoading] = useState(false);
	const [client, setClient] = useState<any>({});
	const router = useRouter();
	useEffect(() => {
		setLoading(true);
		const fetchClient = async () => {
			const newClient: any = await createClient();

			if (newClient) {
				setClient(newClient);
				setLoading(false);
			}
		};
		fetchClient();
		//https://docs.userpilot.com/article/22-install-userpilot-on-single-page-application-frameworks
		Userpilot.reload();
	}, [router.asPath]);
	if (loading) return <Spinner />;

	return (
		<ApolloProvider client={client}>
			<ClientOnly>
				<QueryClientProvider client={queryClient}>
					{/* @ts-ignore */}
					<Provider store={store}>
						<PersistGate loading={null} persistor={persistor}>
							<ThemeProvider theme={theme}>
								<Component {...pageProps} />
							</ThemeProvider>
							<ReactQueryDevtools initialIsOpen={false} />
						</PersistGate>
					</Provider>
				</QueryClientProvider>
			</ClientOnly>
		</ApolloProvider>
	);
};

export default MyApp;
