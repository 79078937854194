// settings

import { gql } from "@apollo/client";

export const GET_INVOICE_COMMUNICATION_RULES = gql`
	query getRulesByCompanyID($commRulesEntityType: commRulesEntityType!) {
		get_rules_by_companyID(commRulesEntityType: $commRulesEntityType) {
			ruleID
			entityType
			companyID
			profileID
			what
			when
			how
			who
			whoParameter
			timestamp
			createdTs
			lastModifiedTs
			createdByAdminID
			lastModifiedByAdminID
		}
	}
`;

export const UPSERT_COMMUNICATION_RULES = gql`
	mutation UPSERT_RULES_BATCH($input: [CommRuleInput!]!) {
		upsert_rules_batch(input: $input) {
			rulesInserted
			rulesUpdated
		}
	}
`;

export const DELETE_COMMUNICATION_RULES = gql`
	mutation DeleteRulesBatch($ruleIDs: [Int!]!) {
		delete_rules_batch(ruleIDs: $ruleIDs) {
			failed
		}
	}
`;