import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import { FC } from "react";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialog-paper": {
		maxWidth: "350px",
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

type Props = {
	handler: () => void;
	handleClose: () => void;
	name: string;
	warningText?: string;
	type: number;
};

const ActivationModal: FC<Props> = ({ handler, handleClose, name, warningText, type }) => (
	<BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
		<div
			style={{
				display: "flex !important",
				padding: "16px",
				alignItems: "center",
				width: "100%",
				justifyContent: "center",
			}}>
			{type == 0 ? (
				<PlaylistRemoveIcon sx={{ width: "100%", height: "56px", marginTop: "1rem", color: "#f54747" }} />
			) : (
				<PlaylistAddCheckIcon sx={{ width: "100%", height: "56px", marginTop: "1rem", color: "green" }} />
			)}
			<p
				style={{
					textAlign: "center",
					margin: "1.5rem 0 0.5rem 0",
					fontSize: "14px",
					fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
				}}>
				Are you sure you want to {type == 0 ? "Revoke Access for" : "Grant Access for"}{" "}
				<strong>{name ?? "Unnamed User(s)"}</strong>
			</p>
			{warningText && warningText.length > 0 && (
				<p className="font-m-14" style={{ color: "#ef9943", display: "flex", alignItems: "center", marginTop: "20px" }}>
					<WarningOutlinedIcon sx={{ width: "20px", margin: "0 4px -6px 0", verticalAlign: "middle" }} />
					{warningText}
				</p>
			)}
		</div>
		<DialogActions
			sx={{
				justifyContent: "center",
				marginBottom: "1rem",
			}}>
			<Button
				variant="contained"
				onClick={(e) => {
					e.stopPropagation();
					handleClose();
				}}
				color="inherit">
				Cancel
			</Button>
			<Button
				variant="contained"
				onClick={(e) => {
					e.stopPropagation();
					handler();
					handleClose();
				}}
				color={`${type == 0 ? "warning" : "success"}`}>
				{type == 0 ? "Revoke Access" : "Grant Access"}
			</Button>
		</DialogActions>
	</BootstrapDialog>
);

export default ActivationModal;
