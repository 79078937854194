import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { FC, useState } from "react";
import DeleteModal from "../../common/DeleteModal";
import { useRouter } from "next/router";
type Props = {
    deleteHandler: () => void;
    name: string;
    // eslint-disable-next-line no-unused-vars
    moduleName?: string;
    data?: any;
    width?: boolean;
    userWritePermission: any;
};
const DataGridActionButtons: FC<Props> = ({
    deleteHandler,
    name,
    width,
    data,
    moduleName,
    userWritePermission,
}) => {
    const [open, setOpen] = useState(false);
    const router = useRouter()
    return (
        <div className="action-buttons">
            <button disabled={!userWritePermission}  onClick={(e) => {
                    e.stopPropagation();
                    router.push(`/clients_sites/clients/${data[0]}`)
                }}>
                <EditOutlinedIcon htmlColor="#8F8F8F" />
            </button>
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                }}
                style={{ width: width ? "26px" : "" }}
                disabled={!userWritePermission}>
                <DeleteIcon htmlColor="#F54747" />
            </button>
            {open && (
                <DeleteModal
                    deleteHandler={deleteHandler}
                    handleClose={() => setOpen(false)}
                    name={name}
                    data={data}
                    moduleName={moduleName}
                />
            )}
        </div>
    );
};

export default DataGridActionButtons;