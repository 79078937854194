// react
import { FC, useState } from "react";

// mui
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

// components
import DeleteModal from "../../common/DeleteModal";

type Props = {
	handleCustomFieldID: () => void;
	deleteHandler: () => void;
	name: string;
	moduleName?: string;
	data: any;
	userWritePermission: any;
};

const ActionButtons: FC<Props> = ({
	handleCustomFieldID,
	deleteHandler,
	name,
	moduleName,
	data,
	userWritePermission,
}) => {
	// states
	const [open, setOpen] = useState(false);

	const isOrderStatusField = name === "Order Status";

	return (
		<div className="action-buttons">
			<button onClick={() => handleCustomFieldID()} disabled={!userWritePermission}>
				<EditOutlinedIcon htmlColor="#8F8F8F" />
			</button>
			<button
				onClick={(e) => {
					e.stopPropagation();
					setOpen(true);
				}}
				disabled={isOrderStatusField || !userWritePermission}>
				<DeleteIcon htmlColor="#F54747" />
			</button>
			{open && (
				<DeleteModal
					deleteHandler={deleteHandler}
					handleClose={() => setOpen(false)}
					name={name}
					moduleName={"custom field"}
					data={data}
				/>
			)}
		</div>
	);
};

export default ActionButtons;
