/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { Dispatch, FC, FormEvent, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { UPSERT_ORDER_BATCH } from "../../../schema/orders";
import CustomFieldsComplex from "../../common/CustomFieldsComplex";
import Link from "next/link";
import AddIcon from "@mui/icons-material/Add";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { ENTITY_METADATA, ORDER_TYPE } from "../../../@types";
import moment from "moment";
import dayjs from "dayjs";

type Props = {
	setExpand: Dispatch<SetStateAction<boolean>>;
	editableRow: any;
	setEditableRow: Dispatch<SetStateAction<ORDER_TYPE | null>>;
	fetchOrders: () => void;
	entityMetadata: ENTITY_METADATA[];
	refetchEntity: () => void;
	entityLoad: boolean;
	currencySymbol: string;
};

const OrderSidebarForm: FC<Props> = ({
	setExpand,
	editableRow,
	setEditableRow,
	fetchOrders,
	entityMetadata,
	refetchEntity,
	entityLoad,
}) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const [upsert_order_batch, { loading, error }] = useMutation(UPSERT_ORDER_BATCH);
	// any error related to file or image upload
	const [imageOrFileError, setImageOrFileError] = useState("");
	// any custom error related to custom fields
	const [customFieldErrors, setCustomFieldErrors] = useState<any[]>([]);
	// custom fields which are suppose to be filled
	const [missingEntities, setMissingEntities] = useState<ENTITY_METADATA[]>([]);

	useEffect(() => {
		const updatedMissingEntities = entityMetadata?.filter(
			(value: ENTITY_METADATA) => value.isMandatory === 0 && value.active === 1
		);
		if (updatedMissingEntities && updatedMissingEntities.length > 0) {
			setMissingEntities(updatedMissingEntities);
		}
	}, [entityLoad, entityMetadata]);

	const checkDateFormat = (date: string | null) => {
		if (date === null) return null;
		const isValidDayJsDate = dayjs.isDayjs(date);

		// Check if the date is already in the correct format
		if (dayjs(date, "DD-MM-YYYY", true).isValid()) {
			// if a dayjs object then convert into string
			if (isValidDayJsDate) {
				return dayjs(date).format("YYYY-MM-DD");
			} else {
				return date;
			}
		} else if (dayjs(date, "YYYY-MM-DD", true).isValid()) {
			if (isValidDayJsDate) {
				return dayjs(date).format("YYYY-MM-DD");
			} else {
				return date;
			}
		}
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		// input data format to send

		if (imageOrFileError.length > 0) {
			dispatch(
				showNotification({
					message: imageOrFileError,
					severity: "error",
				})
			);
			return;
		}

		if (customFieldErrors.length > 0) {
			dispatch(
				showNotification({
					message: customFieldErrors[0],
					severity: "error",
				})
			);
			return;
		}

		const inputOrderItems = editableRow.orderItems.map((item: any) => ({
			skuID: item.skuID,
			amount: item.amount,
			price: item.price,
			quantity: item.quantity,
			productDescription: item.productDescription,
			discAmt: item.discAmt,
			totalTaxAmount: item.totalTaxAmount,
		}));

		const dateTypeCustomFields = entityMetadata?.filter((value: ENTITY_METADATA) => value.dataType === "date");
		const inputCustomFieldsComplex = editableRow.customFieldsComplex?.map((item: any) => {
			const data = dateTypeCustomFields.find((value: ENTITY_METADATA) => value.fieldID === item.fieldID);
			return {
				fieldID: item.fieldID,
				fieldValue: data ? checkDateFormat(item.fieldValue) : item.fieldValue,
				fieldValue2: item.fieldValue2,
				fieldValue3: item.fieldValue3,
				selectedOptions: item.selectedOptions,
				selectedFieldValues: item.selectedFieldValues,
			};
		});

		const inputRow = {
			orderID: editableRow.orderID,
			taskID: editableRow.taskID,
			clientID: editableRow.clientID,
			lat: editableRow.lat,
			lon: editableRow.lon,
			recipients: editableRow.recipients,
			orderItems: inputOrderItems,
			orderValue: editableRow.orderValue,
			taxValue: editableRow.taxValue,
			payments: editableRow.payments,
			paymentCollected: editableRow.paymentCollected,
			customFieldsComplex: inputCustomFieldsComplex,
			createdTs: editableRow.createdTs,
			lastModifiedTs: moment().valueOf(),
			otpVerified: editableRow.otpVerified,
		};
		await upsert_order_batch({ variables: { input: [inputRow] } });
		dispatch(
			showNotification({
				message: error ? "Order details could not be saved" : "Order details saved successfully",
				severity: error ? "error" : "success",
			})
		);
		setExpand(false);
		fetchOrders();
	};

	return (
        <div className="user_add tasks_edit" id="user_add">
			<div className="user_add-title">
				<h1>{"Edit"} Order</h1>
				<button onClick={() => setExpand(false)}>
					<CloseOutlinedIcon />
				</button>
			</div>
			<form className="product_add-form" onSubmit={handleSubmit}>
				<div className="custom_fields_complex">
					<div className="title">
						<h5>Custom Fields</h5>
						<Link
                            prefetch={false}
                            href="/settings/custom-fields?entity=Orders"
                            target="blank ">

                            <AddIcon />

                        </Link>
						<button disabled={entityLoad} className="refetch" type="button" onClick={() => refetchEntity()}>
							<RefreshOutlinedIcon />
						</button>
					</div>
					{missingEntities && Array.isArray(missingEntities) && missingEntities.length > 0 && (
						<CustomFieldsComplex
							fieldsData={editableRow}
							setFieldsData={setEditableRow}
							missingEntities={missingEntities}
							setImageOrFileError={setImageOrFileError}
							setCustomFieldErrors={setCustomFieldErrors}
							isDisabled={false}
						/>
					)}
				</div>

				<div className="submit_buttons">
					<button type="reset" disabled={loading} onClick={() => setExpand(false)}>
						Cancel
					</button>
					<button type="submit" disabled={loading}>
						{loading ? <CircularProgress style={{ width: "20px", height: "20px", marginRight: "10px" }} /> : "Save"}
					</button>
				</div>
			</form>
		</div>
    );
};

export default OrderSidebarForm;
