/* eslint-disable jsx-a11y/label-has-associated-control */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FormControlLabel, Switch, TextField, CircularProgress } from "@mui/material";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { INTEGRATION_INFO } from "../../../@types";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import useAdminEntitlements from "../../useAdminEntitlements";
import ReadOnlyButton from "../../common/ReadOnlyButton";
import { useMutation, useQuery } from "@apollo/client";
import { GET_INTEGRATIONS, UPSERT_INTEGRATION, UPSERT_INTEGRATION_STATE } from "../../../schema/hrOne";

const GreytHRIntegrations = () => {
	const [currentGreytHRState, setCurrentGreytHRState] = useState(0);
	const userWritePermission = useAdminEntitlements("settings:integrations", "write");
	const { roleID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);
	const dispatch = useDispatch<APP_DISPATCH>();
	const [greytHRKey, setGreytHRKey] = useState<INTEGRATION_INFO["keyValues"][number]>({
		keyTypeID: 4,
		keyValue: "",
	});
	const [greytHRHostName, setGreytHRHostName] = useState<INTEGRATION_INFO["keyValues"][number]>({
		keyTypeID: 1,
		keyValue: "",
	});
	const [greytHRPemFile, setGreytHRPemFile] = useState<INTEGRATION_INFO["keyValues"][number]>({
		keyTypeID: 2,
		keyValue: "",
	});
	const {
		data: get_integrations,
		loading,
		refetch,
	} = useQuery(GET_INTEGRATIONS, {
		variables: {
			entityName: "attendance",
		},
	});
	const [upsertIntegrationState] = useMutation(UPSERT_INTEGRATION_STATE);
	const [upsertIntegrations] = useMutation(UPSERT_INTEGRATION);

	useEffect(() => {
		if (!loading && get_integrations) {
			const greyThrData = get_integrations?.get_integrations.filter((data: any) => data.integrationID === 1);
			if (greyThrData.length > 0) {
				const apiKey = greyThrData[0]?.keyValues.find((k: any) => k.keyTypeID === 4)?.keyValue ?? "";
				const hostName = greyThrData[0]?.keyValues.find((k: any) => k.keyTypeID === 1)?.keyValue ?? "";
				const pemFile = greyThrData[0]?.keyValues.find((k: any) => k.keyTypeID === 2)?.keyValue ?? "";
				const integrationState = greyThrData[0]?.status;
				setGreytHRKey({ keyTypeID: 4, keyValue: apiKey });
				setCurrentGreytHRState(integrationState);
				setGreytHRHostName({ keyTypeID: 1, keyValue: hostName });
				setGreytHRPemFile({ keyTypeID: 2, keyValue: pemFile });
			}
			if (!greyThrData) {
				dispatch(showNotification({ message: "GreytHR data not found", severity: "error" }));
				return;
			}
		}
	}, [loading, get_integrations]);

	const enableGreytHRSwitch = async (prevStatus: 0 | 1) => {
		const newState = prevStatus === 0 ? 1 : 0;
		try {
			const res = await upsertIntegrationState({
				variables: {
					input: { integrationID: 1, status: newState, entityName: "attendance" },
				},
			});
			if (res) {
				dispatch(showNotification({ message: res.data.upsert_integration_state.message, severity: "success" }));
				refetch();
			}
		} catch (error) {
			dispatch(showNotification({ message: "Something went wrong", severity: "error" }));
			return;
		}

		const integrationState = newState;
		setCurrentGreytHRState(integrationState);
	};

	const setPemFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) return;
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.readAsText(file, "UTF-8");
		reader.onload = (e) => {
			const pemFileContent = e.target?.result;
			if (pemFileContent && typeof pemFileContent === "string") {
				setGreytHRPemFile((p) => ({ ...p, keyValue: pemFileContent?.trim() as string }));
			}
		};
		reader.onerror = (event) => {
			dispatch(showNotification({ message: "Error reading pem file", severity: "error" }));
			console.error(event);
		};
	};

	const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!userWritePermission) return;
		if (currentGreytHRState === 0) return;
		const gretHrIntegrationData = {
			integrationID: 1,
			integrationName: "greytHR",
			entityName: "attendance",
			status: currentGreytHRState,
			keyValues: [
				{
					keyTypeID: 4,
					keyValue: greytHRKey.keyValue,
				},
				{
					keyTypeID: 1,
					keyValue: greytHRHostName.keyValue,
				},
				{
					keyTypeID: 2,
					keyValue: greytHRPemFile.keyValue,
				},
			],
		};
		try {
			const res = await upsertIntegrations({
				variables: {
					input: gretHrIntegrationData,
				},
			});
			if (res) {
				dispatch(showNotification({ message: res.data.upsert_integrations.message, severity: "success" }));
			}
		} catch (error) {
			dispatch(showNotification({ message: "Something went wrong", severity: "error" }));
			return;
		}
	};

	const inputDisabled = loading || !userWritePermission;
	return (
        <form className="zoho-crm" onSubmit={submitForm}>
			<div className="back-title">
				<Link prefetch={false} passHref href="/settings/integrations" legacyBehavior>
					<ArrowBackIcon />
				</Link>
				<p>GreytHR Integrations</p>
				{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="integrations" /> : null}
			</div>
			<div className="divider"></div>
			{loading ? (
				<div className="centerise_the_content" style={{ width: "100%", height: "100px" }}>
					<CircularProgress />
				</div>
			) : (
				<>
					{currentGreytHRState === 0 && userWritePermission ? (
						<ul className="errors">
							<li>This Integration is disabled</li>
						</ul>
					) : null}
					<div className="integration_state">
						<FormControlLabel
							control={<Switch checked={currentGreytHRState === 1} />}
							label={currentGreytHRState === 1 ? "Enabled" : "Disabled"}
							onChange={() => enableGreytHRSwitch(currentGreytHRState as 0 | 1)}
							disabled={inputDisabled}
							sx={{ cursor: !userWritePermission ? "not-allowed !important" : "pointer", pointerEvents: "auto" }}
						/>
					</div>
					<div className="crm-form">
						<TextField
							disabled={currentGreytHRState === 0 || inputDisabled}
							required
							label="API KEY"
							value={greytHRKey.keyValue ?? ""}
							onChange={(e) => setGreytHRKey((p) => ({ ...p, keyValue: e.target.value }))}
							size="small"
						/>
						<TextField
							disabled={currentGreytHRState === 0 || inputDisabled}
							required
							label="HOSTNAME"
							value={greytHRHostName.keyValue ?? ""}
							onChange={(e) => setGreytHRHostName((p) => ({ ...p, keyValue: e.target.value }))}
							size="small"
						/>
					</div>
					<div className="image">
						<label
							className="image-input"
							htmlFor="img"
							style={{ backgroundColor: !userWritePermission || currentGreytHRState === 0 ? "#dfdfdf" : "#1976d2" }}>
							<p>Upload File</p>
							<input
								required
								disabled={currentGreytHRState === 0 || inputDisabled}
								type="file"
								accept=".pem"
								name="pemFile"
								onChange={setPemFile}
							/>
						</label>
						<p className="file-title">{greytHRPemFile.keyValue.length > 0 ? "Pem file content" : null}</p>
						<p
							className="file-desc"
							style={{
								height: greytHRPemFile.keyValue.length > 0 ? "150px" : "100px",
								overflowY: greytHRPemFile.keyValue.length > 0 ? "scroll" : "inherit",
							}}>
							{greytHRPemFile.keyValue.length > 0 ? greytHRPemFile.keyValue : "No file uploaded"}
						</p>
					</div>
					<div className="integeration_message">
						<p style={{ fontWeight: 600 }}>
							<i>Note- For successful integration, please make sure to follow the below steps.</i>
						</p>
						<ol type="1">
							<li>
								1. Make sure you set the Employee ID in the page{" "}
								<a
									style={{
										fontSize: "12px",
										fontWeight: "800",
										textDecoration: "underline",
										textUnderlineOffset: "3px",
										color: "#0d6efd",
										cursor: "pointer",
									}}
									href={"https://panel.unolo.com/organization/field_executive"}
									target="blank">
									https://panel.unolo.com/organization/field_executive
								</a>
								.{" "}
							</li>
							<li>2. Make sure the same Employee ID is mapped to the employee in GreytHR. </li>
							<li>3. Make sure that the option to allow employee code as an access card is enabled in GreytHR.</li>
						</ol>
					</div>
					<button type="submit" disabled={!userWritePermission || currentGreytHRState === 0} style={{ float: "right" }}>
						Save
					</button>
				</>
			)}
		</form>
    );
};

export default GreytHRIntegrations;
