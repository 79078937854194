import { createSlice } from "@reduxjs/toolkit";

type subscriptionMetadata = {
    data: any
}
const initialState: subscriptionMetadata = {
    data: null
};

const subscriptionMetadata = createSlice({
	name: "subscriptionMetadata",
	initialState,
	reducers: {
		setSubscriptionMetadata: (state, action) => {
			if (!action.payload) return;
			const data = action.payload;
			state.data = data.data ;
		},
	},
});

export const { setSubscriptionMetadata,  } = subscriptionMetadata.actions;

export default subscriptionMetadata.reducer;
