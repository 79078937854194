import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { CircularProgress, Modal } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EXPENSE_REQUEST_TYPE } from "../../../@types";
import { expenseApproveReject, markExpensePaidOut } from "../../../api/expense";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import { findCurrentExpenseConveyanceAuth } from "../../../utils";
import useAdminEntitlements from "../../useAdminEntitlements";
import ExpanseDescription from "./ExpanseDescription";
import ExpenseForm from "./ExpenseForm";
import { formatDateTo_DDMMYYYY } from "../../../utils/validations";

type Props = {
	open: boolean;
	handleClose: () => void;
	selectedExpenseId: string;
	setSelectedExpenseId: Dispatch<SetStateAction<string | null>>;
	filteredExpenses: EXPENSE_REQUEST_TYPE[];
	requests: EXPENSE_REQUEST_TYPE[];
	setRequests: Dispatch<SetStateAction<EXPENSE_REQUEST_TYPE[]>>;
	refetch: () => void;
	expenseQuestionData:any
};

const ExpenseDetailsModal = ({
	open,
	handleClose,
	selectedExpenseId,
	filteredExpenses,
	setSelectedExpenseId,
	requests,
	refetch,
	expenseQuestionData
}: Props) => {
	const user = useSelector((state: ROOT_STATE) => state.user);
	const [selectedExpense, setSelectedExpense] = useState(
		filteredExpenses.find((exp) => exp.expenseSummaryID === selectedExpenseId)!
	);

	const userWritePermission = useAdminEntitlements("expense:expenseRequests", "write");

	const dispatch = useDispatch<APP_DISPATCH>();
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async (type: "approve" | "reject") => {
		if (!type || !selectedExpense || !selectedExpenseId) {
			dispatch(
				showNotification({ message: `Expense or ExpenseId ${selectedExpenseId} not found `, severity: "error" })
			);
			return;
		}
		const newRequest = { ...selectedExpense };
		newRequest.status = type === "reject" ? 0 : 1;
		const pendingExpenseAuth = findCurrentExpenseConveyanceAuth(newRequest);
		// if there are multiple pending expense Auth, the throw a error
		if (typeof pendingExpenseAuth === "string") {
			dispatch(showNotification({ message: pendingExpenseAuth, severity: "error" }));
			return;
		}
		// otherwise take the auth and check for amount
		if (pendingExpenseAuth.reimbursementAmount === null || isNaN(pendingExpenseAuth.reimbursementAmount)) {
			dispatch(showNotification({ message: "Enter an amount", severity: "error" }));
			return;
		}
		setIsLoading(true);
		const { success, message } = await expenseApproveReject([newRequest]);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		refetch();
		setIsLoading(false);
	};
	const handlePaidOut = async () => {
		if (!selectedExpense || !selectedExpenseId) return;
		setIsLoading(true);
		const newRequest = { ...selectedExpense };
		newRequest.status = 2;
		const { success, message } = await markExpensePaidOut([newRequest]);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		refetch();
		setIsLoading(false);
	};

	const canCurrentAdminPayout =
		selectedExpense &&
		selectedExpense.status === 1 &&
		selectedExpense.expenseAuth.at(-1)?.adminID === user.adminDetails.adminID;

	const canApprove = selectedExpense && selectedExpense.pendingWith?.adminID === user.adminDetails.adminID!;

	const nextExpense = () => {
		const index = filteredExpenses.findIndex((exp) => exp.expenseSummaryID === selectedExpenseId);
		return filteredExpenses[index + 1]?.expenseSummaryID;
	};

	const prevExpense = () => {
		const index = filteredExpenses.findIndex((exp) => exp.expenseSummaryID === selectedExpenseId);
		return filteredExpenses[index - 1]?.expenseSummaryID;
	};

	useEffect(() => {
		setSelectedExpense(requests.find((exp) => exp.expenseSummaryID === selectedExpenseId)!);
	}, [requests, selectedExpenseId]);

	return (
		<Modal open={open} onClose={handleClose} aria-labelledby="Detailed Expense Modal">
			<section className="view_request expense_modal">
				<section className="modal_title">
					<div className="left">
						<h3>
							{selectedExpense.firstName + " " + selectedExpense.lastName} {`(${formatDateTo_DDMMYYYY(selectedExpense.date)})`}
						</h3>
						<div className="move_buttons">
							{prevExpense() && (
								<button onClick={() => setSelectedExpenseId(prevExpense())} title="prev">
									<KeyboardArrowLeftOutlinedIcon />
								</button>
							)}
							{nextExpense() && (
								<button onClick={() => setSelectedExpenseId(nextExpense())} className="ml-auto" title="next">
									<KeyboardArrowRightOutlinedIcon />
								</button>
							)}
						</div>
					</div>
					<button onClick={() => setSelectedExpenseId(null)}>
						<CloseIcon />
					</button>
				</section>
				{selectedExpense && Object.keys(selectedExpense).length > 0 ? (
					<form onSubmit={(e) => e.preventDefault()}>
						<header>
							{selectedExpense.status === 2 && <div className="status">Paid Out</div>}
							{selectedExpense.status === 0 && <div className="status Rejected">Rejected</div>}
							{selectedExpense.status === 1 &&
								(canCurrentAdminPayout ? (
									<button className="status" onClick={handlePaidOut}>
										{isLoading ? (
											<CircularProgress style={{ width: "20px", height: "20px", color: "white" }} />
										) : (
											"Pay Out"
										)}
									</button>
								) : (
									<div className="status Approved">Approved</div>
								))}
						</header>
						<ExpenseForm request={selectedExpense} setRequest={setSelectedExpense} canApprove={canApprove} />
						{selectedExpense.status === -1 && (
							<div style={{ marginLeft: "auto" }} className="approval_actions">
								<button
									type="button"
									data-id="reject"
									className="Rejected"
									disabled={!canApprove || !userWritePermission || isLoading}
									onClick={() => handleSubmit("reject")}>
									{isLoading ? (
										<CircularProgress style={{ width: "20px", height: "20px", color: "white" }} />
									) : (
										"Reject"
									)}
								</button>
								<button
									type="button"
									data-id="approve"
									disabled={!canApprove || !userWritePermission || isLoading}
									onClick={() => handleSubmit("approve")}>
									{isLoading ? (
										<CircularProgress style={{ width: "20px", height: "20px", color: "white" }} />
									) : (
										"Approve"
									)}
								</button>
							</div>
						)}
						{userWritePermission === 0 && (
							<ul className="errors">
								<li>You don't have Permission to Approve/Reject this request</li>
							</ul>
						)}
						<ExpanseDescription request={selectedExpense} expenseQuestionData = {expenseQuestionData}/>
					</form>
				) : (
					<div className="small_circular-spinner">
						<CircularProgress />
					</div>
				)}
			</section>
		</Modal>
	);
};

export default ExpenseDetailsModal;
