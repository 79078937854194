import axios from "../../utils/axios";
import { PERMISSION_PAYLOAD } from "../../@types";
axios.defaults.withCredentials = true;

export const deleteSelectedRoles = async (roleID: string[]): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/roles/deleteRoles`, {
			roleIDs: roleID,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully deleted selected Role",
		};
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Deleting Selected Role";
		return { success: false, message: errorText };
	}
};
type Props = {
	createdBy: string;
	createdTs: string;
	roleDescription: string;
	roleName: string;
	roleID: string;
};
export const updateExistingRole = async (
	roleID: string,
	body: Props,
	permissionBody: PERMISSION_PAYLOAD[]
): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.put(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/roles/${roleID}`, {
			role: {
				roleName: body.roleName,
				roleDescription: body.roleDescription,
			},
			permissions: permissionBody,
		});
		return { success: true, message: res.data?.data || "Successfully Updated Role" };
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Updating Role";
		return { success: false, message: errorText };
	}
};
export const addNewRole = async (
	roleID: string,
	body: Props,
	permissionBody: PERMISSION_PAYLOAD[]
): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/roles/${roleID}`, {
			role: {
				roleName: body.roleName,
				roleDescription: body.roleDescription,
			},
			permissions: permissionBody,
		});
		return { success: true, message: res.data?.data || "Successfully Created New Role" };
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Creating New Role";
		return { success: false, message: errorText };
	}
};

export const addNewDesignation = async <T>(designations: T[]): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/designations`, {
			designations,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Add New Designation Successfully`,
		};
	} catch (error: any) {
		const errorText =
			typeof error.response.data.error === "string"
				? error.response.data.error
				: error?.message || `Error in Adding New Designation`;
		return { success: false, message: errorText };
	}
};
export const deleteDesignations = async <T>(designations: T[]): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.put(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/designations`, {
			designations,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Designation Deleted Successfully`,
		};
	} catch (error: any) {
		const errorText =
			typeof error.response.data.error === "string"
				? error.response.data.error
				: error?.message || `Error in Deleting Designation`;
		return { success: false, message: errorText };
	}
};
export const deleteProfile = async (profileID: number): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios({
			method: "post",
			data: `profileID=${profileID}`,
			url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/deleteTeam`,
			headers: {
				"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
			},
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Profile Deleted Successfully`,
		};
	} catch (error: any) {
		const errorText =
			typeof error.response.data.error === "string"
				? error.response.data.error
				: error?.message || `Error in Deleting Profile`;
		return { success: false, message: errorText };
	}
};
export const addNewTeam = async (
	profileName: string,
	profileID: number,
	hierarchicalParentNodeID?: string | null
): Promise<{ success: boolean; message: string; profileID: number }> => {
	const data = profileID
		? { profileName, profileID, hierarchicalParentNodeID }
		: { profileName, hierarchicalParentNodeID }; // if profileID is present then it is for updation
	try {
		const res = await axios({
			method: "post",
			data: data,
			url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/createNewTeam`,
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
			},
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Added New Profile Successfully`,
			profileID: res.data?.profile?.profileID,
		};
	} catch (error: any) {
		const errorText =
			typeof error.response.data.error === "string"
				? error.response.data.error
				: error?.message || `Error in Adding New Profile`;
		return { success: false, message: errorText, profileID: 1 };
	}
};
