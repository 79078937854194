import { FC, useEffect, useState } from "react";
import { Button, CircularProgress, FormControl, FormControlLabel, FormLabel, Switch, TextField } from "@mui/material";
import { CUSTOM_WEBHOOKS } from "../../../@types";
import {
	GET_INTEGRATIONS_BY_COMPANY_ID,
	UPSERT_ENABLE_WEBHOOK_INTEGRATION,
	UPSERT_SUBMIT_WEBHOOK_INTEGRATION,
} from "../../../schema/webhooks";
import { useMutation, useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { customWebhooks } from "../../../constants/integrationMapping";

type Props = {
	userWritePermission:any;
}
const Webhooks: FC<Props> = ({userWritePermission}) => {
	const { data: get_integrations_by_companyID, loading } = useQuery(GET_INTEGRATIONS_BY_COMPANY_ID);
	const [upsertIntegrationState] = useMutation(UPSERT_ENABLE_WEBHOOK_INTEGRATION);
	const [upsertIntegrations] = useMutation(UPSERT_SUBMIT_WEBHOOK_INTEGRATION);
	const [customWebhooksData, setCustomWebhooksData] = useState<CUSTOM_WEBHOOKS[]>([]);
	const [errors, setErrors] = useState({ keyTypeID: null, message: "", integrationID: null });
	const dispatch = useDispatch<APP_DISPATCH>();

	useEffect(() => {
		if (!loading && get_integrations_by_companyID) {
			const filteredCustomWebhooks = get_integrations_by_companyID?.get_integrations_by_companyID.filter(
				(integ: any) =>
					integ.integrationID === 2 ||
					integ.integrationID === 3 ||
					integ.integrationID === 4 ||
					integ.integrationID === 6 ||
					integ.integrationID === 7
			);
			if (filteredCustomWebhooks.length === 0) {
				setCustomWebhooksData(customWebhooks);
			} else {
				filteredCustomWebhooks.map((data: any) => {
					const index = customWebhooks.findIndex((idx: any) => data.integrationID === idx.integrationID);
					customWebhooks[index].status = data.status;
					data.keyValues.map((key: any) => {
						const idx = customWebhooks[index].keyValues.findIndex((id: any) => id.keyTypeID === key.keyTypeID);
						if (idx >= 0) {
							customWebhooks[index].keyValues[idx].keyValue = key.keyValue;
						}
					});
				});
				setCustomWebhooksData(customWebhooks);
			}
		}
	}, [loading, get_integrations_by_companyID]);

	// to send data if any integeration is enabled or diabled
	const handleChange = async (e: React.ChangeEvent<HTMLInputElement>, id: number | string) => {
		const temp = [...customWebhooksData];
		const index = temp.findIndex((i: any) => i.integrationID === id);
		temp[index].status = e.target.checked === true ? 1 : 0;
		const dataToSend = {
			integrationID: id,
			status: e.target.checked === true ? 1 : 0,
			entityName: temp[index].entityName,
		};
		try {
			const res = await upsertIntegrationState({ variables: { input: dataToSend } });
			if (res.data.upsert_integration_state.status === false) {
				dispatch(
					showNotification({
						message: "Something went wrong!",
						severity: "error",
					})
				);
				return;
			} else {
				dispatch(
					showNotification({
						message:
							e.target.checked === true
								? `Successfully enabled the ${temp[index].entityName} integration.`
								: `Successfully disabled the ${temp[index].entityName} integration.`,
						severity: "success",
					})
				);
				setCustomWebhooksData(temp);
			}
		} catch (error) {
			dispatch(
				showNotification({
					message: "Something went wrong!",
					severity: "error",
				})
			);
			return;
		}
		setErrors({ keyTypeID: null, message: "", integrationID: null });
	};

	const handleKeyValueChange = (e: any, id: number, keyTypeID: number) => {
		const { value } = e.target;
		const temp = [...customWebhooksData];
		const index = temp.findIndex((idx) => idx.integrationID === id);
		temp[index].keyValues.map((key) => {
			if (key.keyTypeID === keyTypeID) {
				key.keyValue = value;
			}
		});
		setCustomWebhooksData(temp);
		setErrors({ keyTypeID: null, message: "", integrationID: null });
	};

	// to validate the if header name is present then header value is mandatory or vice versa.
	const validateInputKeyValues = (filterEnabledData: CUSTOM_WEBHOOKS[]) => {
		const error: any = { keyTypeID: null, message: "", integrationID: null };
		filterEnabledData.map((data) => {
			if (data.keyValues[0].keyValue.length == 0) {
				error.message = `Please fill the required field.`;
				error.keyTypeID = 3;
				error.integrationID = data.integrationID;
				setErrors(error);
				return error;
			} else if (
				(data.keyValues[1].keyValue.length > 0 && data.keyValues[2].keyValue.length === 0) ||
				(data.keyValues[2].keyValue.length > 0 && data.keyValues[1].keyValue.length === 0)
			) {
				error.message = `Please fill both the header name and value.`;
				error.keyTypeID = 5;
				error.integrationID = data.integrationID;
				setErrors(error);
				return error;
			}
		});
		return error;
	};

	//to save the integrations data
	const saveUpsertIntegration = async () => {
		const filterEnabledData = customWebhooksData.filter((data) => data.status === 1);
		const newError = validateInputKeyValues(filterEnabledData);
		if (newError.message.length > 0) {
			return;
		}
		try {
			const res = await upsertIntegrations({ variables: { input: filterEnabledData } });
			if (res.data.upsert_integrations.status === false) {
				dispatch(
					showNotification({
						message: "Something went wrong. Please try again later.",
						severity: "error",
					})
				);
				setErrors({ keyTypeID: null, message: "", integrationID: null });
				return;
			} else {
				dispatch(
					showNotification({
						message: "Saved successfully.",
						severity: "success",
					})
				);
				setErrors({ keyTypeID: null, message: "", integrationID: null });
			}
		} catch (error) {
			dispatch(
				showNotification({
					message: "Something went wrong.Please try again later.",
					severity: "error",
				})
			);
			setErrors({ keyTypeID: null, message: "", integrationID: null });
			return;
		}
	};

	return (
		<div className="integrations">
			{!loading &&
				customWebhooksData.length > 0 &&
				customWebhooksData.map((data: any) => (
					<div key={data.integrationID}>
						<div className="integrations-header">
							<p>
								{data.entityName.charAt(0).toUpperCase() + data.entityName.slice(1)}
								{` Wehbook`}
							</p>
						</div>
						<div className="wehbooks-body">
							<div className="zoho-crm integration-box">
								<div className="box-body">
									<FormControl variant="standard" className="form-control">
										<FormLabel className="form-label">{`Send all ${data.entityName} that are created/edited in Unolo to any system via a POST request.`}</FormLabel>
										<FormControlLabel
											control={
												<Switch
													checked={data.status === 1 ? true : false}
													onChange={(e) => handleChange(e, data.integrationID)}
													name="gilad"
													disabled={!userWritePermission}
												/>
											}
											label={data.status === 1 ? "Enabled" : "Disabled"}
										/>
									</FormControl>
									{data.status === 1 && (
										<div className="zoho-crm integration-box">
											{data.keyValues.map((key: any, index: number) => (
												<div key={key.keyTypeID}>
													{key.keyName === "URL" ? (
														<>
															{errors.keyTypeID !== null &&
																errors.keyTypeID === 3 &&
																errors.integrationID === data.integrationID && (
																	<p className="error">{errors.message}</p>
																)}
															<div
																className="input-keys"
																key={key.keyTypeID}
																style={{ marginBottom: index === data.keyValues.length - 1 ? "0px" : "20px" }}>
																<p className="integration-name">
																	{`${key.keyName} `}
																	<sup style={{ color: "#f54747" }}>*</sup>
																</p>
																<TextField
																	id="outlined-basic"
																	variant="outlined"
																	style={{ maxWidth: "344px" }}
																	value={key.keyValue}
																	name="keyName"
																	InputProps={{ style: { borderRadius: "8px" } }}
																	onChange={(e) => handleKeyValueChange(e, data.integrationID, key.keyTypeID)}
																	disabled={!userWritePermission}
																/>
															</div>
														</>
													) : null}
												</div>
											))}
											{errors.keyTypeID !== null &&
											errors.keyTypeID === 5 &&
											errors.integrationID === data.integrationID ? (
												<p className="error">{errors.message}</p>
											) : null}
											<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "30px" }}>
												{data.keyValues.map((key: any, index: number) => (
													<div key={key.keyTypeID} style={{ display: key.keyName === "URL" ? "none" : "block" }}>
														{(key.keyName === "API_KEY" || key.keyName === "API_KEY_HEADER") && (
															<div
																className="input-keys"
																key={key.keyTypeID}
																style={{
																	marginBottom: index === data.keyValues.length - 1 ? "0px" : "20px",
																	gridTemplateColumns: "0.24fr 1fr",
																}}>
																<p className="integration-name">
																	{key.keyName === "API_KEY" ? <>Header value</> : <>Header name</>}
																</p>
																<TextField
																	id="outlined-basic"
																	variant="outlined"
																	style={{ maxWidth: "344px" }}
																	value={key.keyValue}
																	name="keyName"
																	InputProps={{ style: { borderRadius: "8px" } }}
																	onChange={(e) => handleKeyValueChange(e, data.integrationID, key.keyTypeID)}
																	disabled={!userWritePermission}
																/>
															</div>
														)}
													</div>
												))}
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				))}

			{!loading && customWebhooksData.length > 0 && (
				<div className="submit-btn">
					<Button
						variant="contained"
						className="add mui-button"
						disableRipple
						type="submit"
						onClick={saveUpsertIntegration}
						disabled={!userWritePermission}>
						Submit
					</Button>
				</div>
			)}

			{loading && (
				<div className="centerise_the_content">
					<CircularProgress />
				</div>
			)}
		</div>
	);
};

export default Webhooks;
