import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Dispatch, FC, FormEvent, SetStateAction } from "react";
import { ROUTES_FILTER_COLUMNS, ROUTE_DATA } from "../../../@types";
import IndividualFilter from "../../common/datagrid/IndividualFilter";
type COLUMN_TITLES = "Route Name" | "Category";
type Props = {
	routes: ROUTE_DATA[];
	numFilters: number;
	setFilterOpen: Dispatch<SetStateAction<boolean>>;
	filters: Record<ROUTES_FILTER_COLUMNS, string[]>;
	setFilters: Dispatch<SetStateAction<Record<ROUTES_FILTER_COLUMNS, string[]>>>;
};
const RoutesTableFilter: FC<Props> = ({ numFilters, setFilterOpen, routes, setFilters, filters }) => {
	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
	};
	const resetFilters = () => {
		setFilters({ name: [], category: [] });
	};
	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
		<form onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()} onKeyPress={(e) => e.stopPropagation()}>
			<div className="filter__header">
				<h5>
					<FilterAltOutlinedIcon />
					Filters
					<span className="right-notification center" style={{ display: "flex", padding: 0 }}>
						{numFilters}
					</span>
				</h5>
				<button onClick={() => setFilterOpen(false)}>
					<CloseSharpIcon htmlColor="#42444A" />
				</button>
			</div>
			<div className="accordion__group">
				<IndividualFilter<COLUMN_TITLES, ROUTES_FILTER_COLUMNS>
					filters={filters}
					name="name"
					title="Route Name"
					data={routes.map((r) => r.name)}
					ids={routes.map((r) => r.name)}
					setFilters={setFilters}
				/>
				<IndividualFilter<COLUMN_TITLES, ROUTES_FILTER_COLUMNS>
					filters={filters}
					name="category"
					title="Category"
					data={["Clients", "Sites ", "Sites Clients"]}
					ids={["Clients", "Sites ", "Sites Clients"]}
					setFilters={setFilters}
				/>
			</div>
			<div className="filter__buttons">
				<button onClick={resetFilters} disabled={numFilters===0} type="reset">
					Reset
				</button>
			</div>
		</form>
	);
};

export default RoutesTableFilter;
