import { useEffect } from "react";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import PersonIcon from "@mui/icons-material/Person";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import { Menu, MenuItem } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { APP_DISPATCH, logoutAllSystem, ROOT_STATE } from "../../redux";
import { fetchNotificationCounter } from "../../utils";
import NotificationSidebar from "./NotificationSidebar";
import RightSidebar from "./RightSidebar";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { PRODUCT_ID, UNOLO_BIOMETRIC } from "../../constants";
import HelpProfile from "./HelpProfile";
import TestDataDelete from "./TestDataDelete";
type Props = {
	showMenu: any | undefined;
	setIsOpen: any;
};

const Header: FC<Props> = ({ showMenu, setIsOpen }) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const user = useSelector((state: ROOT_STATE) => state.user);
	const [showNotification, setShowNotification] = useState(false);
	const [showHelp, setShowHelp] = useState(false);

	const [isModuleCleared, setIsModuleCleared] = useState({
		LEAVES: false,
		ATTENDANCE: false,
		EXPENSE: false,
		GEOFENCE: false,
		GPS: false,
	});
	const { data } = useQuery("notification", () => fetchNotificationCounter(user.adminDetails.adminID!), {
		staleTime: 200000,
		cacheTime: 200000,
		refetchOnWindowFocus: true,
		refetchOnReconnect: true,
		refetchInterval: 200000,
	});
	const [expanded, setExpanded] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const router = useRouter();
	const cacheBuster = Date.now();

	const s3ImageUrl = `https://s3.ap-south-1.amazonaws.com/upload.permanent.sensestaff.com/companylogo/${user.adminDetails.companyID}.jpeg?cache=${cacheBuster}`;
	const openSidebar = (e: any) => {
		e.stopPropagation();
		setIsOpen(true);
	};
	const handleClose = () => setAnchorEl(null);

	const handleLogout = () => {
		handleClose();
		dispatch(logoutAllSystem());
	};

	const handleRedirectToBillings = () => {
		handleClose();
		router.push("/billings");
	};
	const showExpiryDays = () => {
		const today = new Date();
		const expDate = new Date(user?.accountExpiredDate || "");

		const diffInDays = Math.floor((expDate.getTime() - today.getTime()) / (1000 * 3600 * 24));
		return diffInDays + 1;
	};
	const handleDismiss = () => {
		localStorage.setItem("lastDismissTime", new Date().toISOString());
		localStorage.setItem("dismissed", "true");

		setShowNotification(false);
	};
	const isDifferenceMoreThan24Hours = () => {
		const lastDismissTime = localStorage.getItem("lastDismissTime");
		const dismissed = localStorage.getItem("dismissed");

		if (dismissed === "true") {
			const lastDismissTimeDate = new Date(lastDismissTime || "");
			const today = new Date();
			const diffInHours = Math.floor((today.getTime() - lastDismissTimeDate.getTime()) / (1000 * 3600));
			if (diffInHours >= 24) {
				localStorage.removeItem("dismissed");
				return true;
			}
			return false;
		}

		return true;
	};
	const productID = window ? localStorage.getItem(PRODUCT_ID) ?? "2" : "2";
	useEffect(() => {
		if (user?.accountExpiredDate && showExpiryDays() <= 7 && isDifferenceMoreThan24Hours()) {
			setShowNotification(true);
		}
	}, [user]);

	return (
		<header className="main-header">
			<div className="flex flex-auto items-center gap-3 md:hidden">
				<button className="hamburger" onClick={openSidebar}>
					<MenuOutlinedIcon />
				</button>
				<Link prefetch={false} href="/" className="h-[20px] w-[20px]">
					<img src={user?.adminDetails?.logoUploaded === 1 ? s3ImageUrl : "/images/logo_sm.png"} alt="" />
				</Link>
			</div>
			<div className="status">
				<TestDataDelete />
				{showNotification && (
					<div className={`notification ${showNotification ? "expanded" : ""}`}>
						<div className="content">
							<InfoRoundedIcon style={{ fontSize: "25px", color: "red" }} />
							{showExpiryDays() > 0 ? (
								<span>
									Your plan is expiring in {showExpiryDays()} days. Please do the payment to use the services
									seamlessly.
								</span>
							) : showExpiryDays() === 0 ? (
								<span>Your plan is expiring today. Please do the payment to use the services seamlessly.</span>
							) : (
								<span>
									Your plan has expired {showExpiryDays() * -1} days ago. Please do the payment to use the services
									seamlessly.
								</span>
							)}
							<button className="dismiss" onClick={() => handleDismiss()}>
								<CancelRoundedIcon />
							</button>
						</div>
					</div>
				)}
				{showMenu?.data?.chats !== 0 && productID !== UNOLO_BIOMETRIC && (
					<Link prefetch={false} href="/chats" passHref legacyBehavior>
						<button className={`center ${router.pathname === "/chats" ? "active" : ""}`}>
							<MessageOutlinedIcon />
						</button>
					</Link>
				)}
				{productID !== UNOLO_BIOMETRIC && (
					<button className={`center ${expanded ? "active" : ""}`} onClick={() => setExpanded((prev) => !prev)}>
						<NotificationsNoneOutlinedIcon />
						{data?.data?.notifications && +data?.data?.notifications !== 0 ? (
							<span className="top-notification">
								{+data?.data?.notifications > 99 ? 99 : +data?.data?.notifications}
							</span>
						) : null}
					</button>
				)}
				<button
					id="basic-button"
					aria-controls={open ? "basic-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					className="center"
					onClick={() => {
						setShowHelp(!showHelp);
					}}
					style={{ paddingLeft: 0 }}>
					<PersonIcon style={{ marginLeft: "20px", marginRight: "2px", color: "#25A8F4" }} />
					<picture>
						<span
							style={{
								color: "#25A8F4",
								display: user?.adminDetails?.email && window.innerWidth >= 550 ? "block" : "none",
							}}>
							{user?.adminDetails?.email}
						</span>
					</picture>
					{/* <KeyboardArrowDownOutlinedIcon /> */}
				</button>
				{showHelp && (
					<RightSidebar
						anchor="right"
						expanded={showHelp}
						setExpanded={setShowHelp}
						crmTemplate={"right-sidebar-profile"}>
						<HelpProfile setShowHelp={setShowHelp} showHelp={showHelp} />
					</RightSidebar>
				)}
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					sx={{ marginTop: "40px", marginRight: "10px" }}
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
					transformOrigin={{ vertical: "top", horizontal: "right" }}>
					<MenuItem
						style={{
							display: user?.adminDetails?.email && window.innerWidth < 550 ? "flex" : "none",
						}}>
						<span style={{ color: "#25A8F4", overflow: "hidden", wordWrap: "break-word", whiteSpace: "normal" }}>
							{user?.adminDetails?.email}
						</span>
					</MenuItem>

					{user.adminDetails.parentID == undefined || user.adminDetails.parentID == null ? (
						<MenuItem onClick={handleRedirectToBillings}>
							<PaymentsOutlinedIcon />
							Billings
						</MenuItem>
					) : null}
					<MenuItem onClick={handleLogout} className="logout">
						<ExitToAppOutlinedIcon />
						Logout
					</MenuItem>
				</Menu>
			</div>
			{expanded && (
				<RightSidebar expanded={expanded} setExpanded={setExpanded}>
					<NotificationSidebar
						setExpanded={setExpanded}
						isModuleCleared={isModuleCleared}
						setIsModuleCleared={setIsModuleCleared}
					/>
				</RightSidebar>
			)}
		</header>
	);
};

export default Header;
