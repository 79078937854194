/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */

"use client";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
// import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import SmartDisplayOutlinedIcon from "@mui/icons-material/SmartDisplayOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import { useDispatch, useSelector } from "react-redux";
import { APP_DISPATCH, logoutAllSystem, ROOT_STATE } from "../../redux";
import { Avatar } from "@mui/material";
import { findAvatarInitials } from "../../utils";
import { useRouter } from "next/router";
import { PRODUCT_ID } from "../../constants";
import Link from "next/link";
import { useState } from "react";
import useAdminEntitlements from "../useAdminEntitlements";
import HelplineNumberModal from "./HelplineNumberModal";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { subscriptionMapping } from "../../constants/subscriptionMapping";

declare global {
	interface Window {
		fwSettings: {
			widget_id: number; // Replace 'number' with the appropriate type if needed
		};
		FreshworksWidget: (...args: any[]) => void; // Replace 'any' with the appropriate function signature if needed
	}
}
interface HelpProfileProps {
	setShowHelp: React.Dispatch<React.SetStateAction<boolean>>;
	showHelp: boolean;
}
const HelpProfile: React.FC<HelpProfileProps> = ({ setShowHelp, showHelp }) => {
	const subsType: "2" | "5" | "1" | "6" | "7" | "8" | "9" = window
		? (localStorage.getItem(PRODUCT_ID) as "2" | "1" | "5" | "6" | "7" | "8" | "9")
		: "2";
	const [helplineNumberModalState, setHelplineNumberModalState] = useState(false);
	const phoneNumbers = ["+91 9599936991"];
	const dispatch = useDispatch<APP_DISPATCH>();
	const router = useRouter();
	const showBilling = useAdminEntitlements("billing:billings", "read");
	const user = useSelector((state: ROOT_STATE) => state.user);
	const cacheBuster = Date.now();
	const handleClose = () => {
		setShowHelp(!showHelp);
	};
	const s3ImageUrl = `https://s3.ap-south-1.amazonaws.com/upload.permanent.sensestaff.com/companylogo/${user?.adminDetails?.companyID}.jpeg?cache=${cacheBuster}`;
	const handleLogout = () => {
		dispatch(logoutAllSystem());
	};
	const handleRedirectToBillings = () => {
		handleClose();
		router.push("/billings");
	};
	const handleRedirectToSettings = () => {
		handleClose();
		router.push("/settings");
	};
	const openFreshdeskWidget = () => {
		if (window.FreshworksWidget) {
			window.FreshworksWidget("open");
			handleClose();
		}
	};
	const handleRedirectToVedioLiberary = () => {
		handleClose();
		router.push("/allFeatures");
	};
	const handleRedirectToWhatsNew = () => {
		handleClose();
		router.push("/whatsNew");
	};
	const handleHelplineNumberModalClose = () => {
		setHelplineNumberModalState(!helplineNumberModalState);
	};
	return (
		<>
			<div className="help_profile_container">
				<div className="profile_section centerise_the_profile_content">
					<div className="profile_pic">
						{user?.adminDetails?.logoUploaded ? (
							<>
								<img width="100%" height="100%" src={s3ImageUrl} alt="profile" />
							</>
						) : (
							<Avatar sx={{ backgroundColor: "#D9F0FD", color: "#25A8F4", width: "100%", height: "100%" }}>
								{findAvatarInitials(`${user?.adminDetails?.firstname} ${user?.adminDetails?.lastname}`)}
							</Avatar>
						)}
					</div>
					<div className="user_name ">{`${user?.adminDetails?.firstname} ${user?.adminDetails?.lastname}`}</div>
					<div className="user_id ">{user?.adminDetails?.email}</div>

					<div className="user_account_operation1">
						<div className="user_operation" onClick={handleRedirectToSettings}>
							Settings
						</div>
						<div className="user_operation" onClick={handleLogout}>
							Log out
						</div>
					</div>
				</div>
				<div className="sections-container">
					<div className="section">
						<div className="heading_section">
							<div>
								<span style={{ fontWeight: "bolder", fontSize: "14px" }}>SUBCRIPTION</span>
								<span className="subscription_type" style={{ marginLeft: "15px" }}>
									<button className="plan_button">{subscriptionMapping[subsType]}</button>
								</span>
							</div>
						</div>

						<div className="options_heading_section">
							{showBilling &&
							user?.adminDetails?.companyID !== 12785 &&
							user?.adminDetails?.companyID !== 15388 &&
							user?.adminDetails?.companyID !== 16440 ? (
								<div onClick={handleRedirectToBillings} role="button" tabIndex={0}>
									<ReceiptLongIcon className="small_icon" sx={{ color: "#9c6b02" }} />
									<a>
										<div>Billing</div>{" "}
									</a>
								</div>
							) : null}
						</div>
					</div>
					<hr className="bottom-line" />

					<div className="section">
						<div className="heading_section">
							<span style={{ fontWeight: "bolder", fontSize: "14px" }}>NEED HELP?</span>
						</div>
						<div className="options_heading_section">
							{/* <div>
                            <QuestionAnswerOutlinedIcon className="small_icon" sx={{ color: "#415587" }} />
                            
                            <a>
                                    <div> Chat with us</div>
                                </a>
                            
                        </div> */}
							<div onClick={handleHelplineNumberModalClose}>
								<HeadsetMicOutlinedIcon className="small_icon" sx={{ color: "#415587" }} />

								<a>
									<div> Talk with us</div>
								</a>
							</div>
							<div
								onClick={() => {
									openFreshdeskWidget();
								}}>
								<EmailOutlinedIcon className="small_icon" sx={{ color: "#415587" }} />

								<a>
									<div> Write to us</div>
								</a>
							</div>
							<div>
								<FeedbackOutlinedIcon className="small_icon" sx={{ color: "#415587" }} />
								<Link href="javascript:userpilot.trigger('survey:1P9DG3_i7y')">
									<div> Feedback</div>
								</Link>
							</div>
						</div>
					</div>
					<hr className="bottom-line" />

					<div className="section">
						<div className="heading_section">
							<span style={{ fontWeight: "bolder", fontSize: "14px" }}>RESOURCES</span>
						</div>
						<div className="options_heading_section">
							<div>
								<QuizOutlinedIcon className="small_icon" sx={{ color: "#975249" }} />
								<Link href="https://support.unolo.com/support/solutions" target="_blank">
									<div>FAQ</div>
								</Link>
							</div>
							<div>
								<DescriptionOutlinedIcon className="small_icon" sx={{ color: "#975249" }} />
								<Link href="https://unolo.com/help" target="_blank">
									<div>Documentation</div>
								</Link>
							</div>
							<div>
								<SmartDisplayOutlinedIcon className="small_icon" sx={{ color: "#975249" }} />
								<a target="_blank" onClick={handleRedirectToVedioLiberary}>
									<div>Videos</div>
								</a>
							</div>
							{/* <div >
                            <RocketLaunchOutlinedIcon className="small_icon" sx={{ color: "#975249" }} />
                            <div>Guided Tours</div>
                        </div> */}
						</div>
					</div>
					<hr className="bottom-line" />

					<div className="section">
						<span style={{ fontWeight: "bolder", fontSize: "14px" }}>NEWS ROOM</span>
						<div className="options_heading_section">
							<div>
								<NewspaperIcon className="small_icon" sx={{ color: "#4a8151" }} />
								{/* <Link href="https://unolo.com/" target="_blank"> */}
								<a target="_blank" onClick={handleRedirectToWhatsNew}>
								<div>Whats New</div>
								</a>
								{/* </Link> */}
							</div>
						</div>
					</div>
				</div>
				<div className="bottom_profile_section">
					<div className="mobile_section">
						<div>MOBILE</div>
						<Link href="https://apps.apple.com/us/app/unolo/id1375541672" target="_blank">
							<img
								src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Apple_logo_grey.svg/1724px-Apple_logo_grey.svg.png"
								className="mobile_section_icons"
								alt=""
							/>
						</Link>
						<Link
							href="https://play.google.com/store/apps/details?id=smartsense.co.in.sensephone&hl=en&pli=1"
							target="_blank">
							<img
								src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_d7G_Go00TZQFIajzffD_18d0rcYiTGq-1lr-b6V5nA&s"
								className="mobile_section_icons"
								alt=""
							/>
						</Link>
					</div>
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
				</div>
				{helplineNumberModalState && (
					<HelplineNumberModal handleClose={handleHelplineNumberModalClose} phoneNumbers={phoneNumbers} />
				)}
			</div>
		</>
	);
};
export default HelpProfile;
