import React, { FC } from "react";
import { Typography, Paper, CircularProgress, Grid, Divider } from "@mui/material";
import { GET_ORDER_DIFF } from "../../../schema/orders";
import { useQuery } from "@apollo/client";
import moment from "moment";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddIcon from "@mui/icons-material/Add";
import {
	showCollection,
	showFields,
	showItems,
	showRecipients,
	transformTheSimpleChange,
} from "./showOrderComplexView";
interface Props {
	orderItemData: any;
}
interface ChangeInfoProps {
	lastModifiedTs: string;
	lastModifiedBy: string;
	diff: any;
}

const ComplexTimeline: FC<Props> = ({ orderItemData }) => {
	const variables = {
		orderID: orderItemData[0].orderID,
	};

	const uniqueOrderID = new Set();
	const { loading: timeLineLoading, data: ordersData } = useQuery(GET_ORDER_DIFF, {
		variables,
	});
	const showSimpleChange = (simple: any) => {
		if (!simple) return;
		return (
			<>
				{Object.keys(simple).map((key: any) => {
					if (simple[key].length === 0) return null;
					return simple[key].map((item: any) => transformTheSimpleChange(item, key));
				})}
			</>
		);
	};
	const showComplexChange = (complex: any) => {
		if (!complex) return null;

		const components = {
			Collection: showCollection,
			"Custom Field": showFields,
			Recipient: showRecipients,
			Items: showItems,
		};

		return (
			<>
				{complex.map((item: any, index: any) => {
					const Component = components[item.key];
					return Component ? (
						<div key={index}>
							<Component {...item} />
						</div>
					) : null;
				})}
			</>
		);
	};

	const ComplexTimelineEvent = (changeInfo: ChangeInfoProps, key: any) => {
		let showDate = false;
		(() => {
			if (!uniqueOrderID.has(changeInfo.lastModifiedTs.split(" ")[0])) {
				uniqueOrderID.add(changeInfo.lastModifiedTs.split(" ")[0]);
				showDate = true;
			}
		})();

		return (
			<Grid container spacing={2} alignItems="stretch" className={`${showDate ? "mt-5" : ""} mb-2`}>
				<Grid xs={0.85} item className="left-grid">
					<span className="mt-3">{moment(changeInfo.lastModifiedTs).format("LT")}</span>
				</Grid>
				<Grid item>
					{showDate && (
						<div className="mt-3" style={{ paddingLeft: "12px", position: "relative" }}>
							<div className="timeLineDate">{moment(changeInfo.lastModifiedTs).format("MMM D, YYYY")}</div>
							<Divider orientation="vertical" className="divider-style-top" />
						</div>
					)}

					<div className="mt-3" style={{ paddingLeft: "12px" }}>
						{ordersData?.get_order_diff?.length !== key + 1 ? (
							<BorderColorIcon className="icon-style" />
						) : (
							<AddIcon className="icon-style" />
						)}
					</div>
					{ordersData?.get_order_diff?.length !== key + 1 && (
						<Divider orientation="vertical" className="divider-style" />
					)}
				</Grid>
				<Grid item xs style={{ height: "100%" }}>
					<Paper elevation={10} variant="outlined" className="mb-4 mt-2">
						<Typography variant="body1" className="p-2">
							{showSimpleChange(changeInfo?.diff?.simple)}
							{showComplexChange(changeInfo?.diff?.complex)}
							<p className={`audit-bottom-line ${changeInfo?.diff === null ? "" : "mt-2"}`}>
								{changeInfo?.diff === null ? "Order Created by" : "Edited by"} {changeInfo.lastModifiedBy}
							</p>
						</Typography>
					</Paper>
				</Grid>
			</Grid>
		);
	};

	return (
		<div>
			{timeLineLoading && (
				<div className="place-in-center">
					<CircularProgress />
				</div>
			)}

			{!timeLineLoading && (
				<div>
					{ordersData?.get_order_diff
						.sort((a: any, b: any) => {
							const dateA: any = new Date(a.lastModifiedTs);
							const dateB: any = new Date(b.lastModifiedTs);
							return dateB - dateA;
						})
						?.map((change: ChangeInfoProps, index: any) => ComplexTimelineEvent(change, index))}
				</div>
			)}
			{!timeLineLoading && ordersData?.get_order_diff.length === 0 && (
				<div className="place-in-center">
					<span>No changes were made to this order</span>
				</div>
			)}
		</div>
	);
};

export default ComplexTimeline;
