import dagre from "dagre";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactFlow, { addEdge, Background, ConnectionLineType, Controls, useEdgesState, useNodesState } from "reactflow";
import { ACCESS_CONTROL_USER, BLOCKS_DATA, PATH_DATA, SAMPLE_DATA, USER_ROLES } from "../../../@types";
import { saveAdminHierarchyNew } from "../../../api/access-control";
import { orgFormStatus } from "../../../constants/organizationConstant";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { convertDataSourceToOrgChartDataSource, getNodesPath } from "../../../utils";
import RoleBox from "./RoleBox";

const NODE_WIDTH = 350;
const NODE_HEIGHT = 350;
type Props = {
	// eslint-disable-next-line no-unused-vars
	openSidebarEditForm: (id?: number | null) => void;
	// eslint-disable-next-line no-unused-vars
	openSidebarAddForm: (managerId: number | null) => void;
	users: ACCESS_CONTROL_USER[];
	refetch: Function;
	// eslint-disable-next-line no-unused-vars
	openEmployeeList: (id: number | null) => void;
	rolesData: USER_ROLES[];
	allExecutives: any[];
	userWritePermission: boolean;
	pullAdminsRefetch: Function;
	rolesRefetch: Function;
	setEmployeeShow:Dispatch<SetStateAction<string>>;
};
const OrgHierarchy = ({
	openSidebarEditForm,
	users,
	openSidebarAddForm,
	openEmployeeList,
	refetch,
	rolesData,
	allExecutives,
	userWritePermission,
	pullAdminsRefetch,
	rolesRefetch,
	setEmployeeShow
}: Props) => {
	const [direction] = useState<"TB" | "LR">("TB");
	const dispatch = useDispatch<APP_DISPATCH>();
	const deleteAdmins = async (id: number | null) => {
		if (id === null || isNaN(id)) return;
		const extraParams = {
			email: null,
			role: orgFormStatus.DELETE,
		};
		const { message, success } = await saveAdminHierarchyNew(orgData, [id], allExecutives, extraParams);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		await refetch();
		await pullAdminsRefetch();
		await rolesRefetch();
	};

	const orgData: SAMPLE_DATA = convertDataSourceToOrgChartDataSource(users);

	const getNodesData = (data: SAMPLE_DATA[]): BLOCKS_DATA[] => {
		const localResult: BLOCKS_DATA[] = [];
		try {
			if (data.length === 0) return [];
			data.forEach((item: SAMPLE_DATA) => {
				if (item.children && item.children?.length > 0) {
					localResult.push(...getNodesData(item.children));
				}
				if (!item.role_box_id) return;
				localResult.push({
					id: item.role_box_id,
					position: { x: 0, y: 0 },
					targetPosition: "top",
					sourcePosition: "bottom",
					data: {
						label: (
							<RoleBox
								user_info={item}
								rolesData={rolesData}
								openSidebarEditForm={openSidebarEditForm}
								openSidebarAddForm={openSidebarAddForm}
								openEmployeeList={openEmployeeList}
								deleteHandler={() => deleteAdmins(item.id)}
								userWritePermission={userWritePermission}
								setEmployeeShow={setEmployeeShow}
							/>
						),
					},
				});
			});
		} catch (error) {
			console.error(`Error in getNodesData: ${error}`);
		}
		return localResult;
	};

	const initialNodes = getNodesData([orgData]);
	const initialEdges = getNodesPath([orgData], []);

	useEffect(() => {
		const { newNodes, newEdges } = getLayoutedElements(initialNodes, initialEdges, direction);
		// @ts-expect-error after adding top and bottom keys in block data type
		setNodes(newNodes);
		setEdges(newEdges);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [users]);

	const { newNodes, newEdges } = getLayoutedElements(initialNodes, initialEdges, direction);

	// @ts-expect-error after adding top and bottom keys in block data type
	const [nodes, setNodes] = useNodesState<BLOCKS_DATA[]>(newNodes);
	const [edges, setEdges] = useEdgesState(newEdges);
	const onConnect = useCallback(
		(params: any) =>
			setEdges((eds) => addEdge({ ...params, type: ConnectionLineType.SmoothStep, animated: true }, eds)),
		[setEdges]
	);
	return (
		<div className="datagrid-table">
			<ReactFlow
				nodes={nodes}
				edges={edges}
				fitView
				connectionLineType={ConnectionLineType.SmoothStep}
				onConnect={onConnect}>
				<Controls />
				<Background color="#e5e5e5" gap={1} />
			</ReactFlow>
		</div>
	);
};

export default OrgHierarchy;

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
const getLayoutedElements = (newNodes: BLOCKS_DATA[], newEdges: PATH_DATA[], direction: "TB" | "LR") => {
	if (newNodes.length === 0 || newEdges.length === 0) return { newNodes, newEdges };
	dagreGraph.setGraph({ rankdir: direction });
	for (const node of newNodes) {
		dagreGraph.setNode(node.id, { width: NODE_WIDTH, height: NODE_HEIGHT });
	}
	for (const edge of newEdges) {
		dagreGraph.setEdge(edge.source, edge.target);
	}

	try {
		// @ts-ignore
		dagreGraph.removeNode(undefined);
		dagreGraph.removeNode("undefined");
		dagre.layout(dagreGraph);
	} catch (error) {
		console.error(`Error in setting Dagre layout: ${error}`);
	}
	newNodes.forEach((node) => {
		const nodeWithPosition = dagreGraph.node(node.id);
		if (nodeWithPosition && !isNaN(nodeWithPosition.x) && !isNaN(nodeWithPosition.y)) {
			node.position = {
				x: nodeWithPosition.x,
				y: nodeWithPosition.y,
			};
		}
		return node;
	});
	return { newNodes, newEdges };
};
