// react
import { FC, useState } from "react";
// mui
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import LockIcon from "@mui/icons-material/Lock";
// redux
import { useDispatch } from "react-redux";
import { APP_DISPATCH, showNotification } from "../../../redux";
// query
import { upsertShiftTemplate } from "../../../api/attendance";
// libraries
import dayjs from "dayjs";
// components
import SubscriptionPlanModal from "../../common/SubscriptionPlanModal";
import TimePicker from "../../common/TimePicker";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
		minWidth: "400px",
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle
			sx={{
				m: 0,
				padding: "0.5rem 1rem",
				fontFamily: "SFUIText-Semibold, Helvetica, Arial, sans-serif",
				fontSize: "16px",
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
			}}
			{...other}>
			{children}
			<IconButton aria-label="close" onClick={onClose}>
				<CloseIcon />
			</IconButton>
		</DialogTitle>
	);
};
type Props = {
	handleClose: () => void;
	// eslint-disable-next-line no-unused-vars
	upsertShift: (shift: any) => void;
	shiftSelectedData: any | null;
	shiftsTemplateData: any;
	subsMetadata: any;
};

const getRandomColor = () => {
	const letters = "0123456789ABCDEF";
	let color = "#";
	for (let i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
};

const ShiftsCreateModal: FC<Props> = ({
	shiftSelectedData,
	handleClose,
	upsertShift,
	shiftsTemplateData,
	subsMetadata,
}) => {
	const [shiftName, setShiftName] = useState(shiftSelectedData ? shiftSelectedData["name"] : "");
	const [startTime, setStartTime] = useState(shiftSelectedData ? shiftSelectedData["start"] : "10:00");
	const [endTime, setEndTime] = useState(shiftSelectedData ? shiftSelectedData["end"] : "18:00");
	const dispatch = useDispatch<APP_DISPATCH>();
	const [error, setError] = useState<null | string>(null);

	const onSave = async (e: { preventDefault: () => void }) => {
		e.preventDefault();
		if (shiftName === "") {
			setError("shift Name is required");
			return;
		} else if (!shiftSelectedData && isDuplicateShift) {
			setError("Shift Name already exists");
			return;
		} else if (startTime === undefined || startTime === "Invalid Date") {
			dispatch(showNotification({ message: "Enter valid start time", severity: "error" }));
			return;
		} else if (endTime === undefined || endTime === "Invalid Date") {
			dispatch(showNotification({ message: "Enter valid end time", severity: "error" }));
			return;
		} else {
			setError(null);
		}

		const sendData = { name: shiftName, startTime: startTime, endTime: endTime };
		if (shiftSelectedData) {
			sendData["shiftTemplateID"] = shiftSelectedData["shiftTemplateID"];
		}
		const { data, success, message } = await upsertShiftTemplate(sendData);
		if (success) {
			const shift = shiftSelectedData
				? { ...shiftSelectedData, name: shiftName, start: startTime, end: endTime }
				: { shiftTemplateID: data.id, name: shiftName, start: startTime, end: endTime, color: getRandomColor() };
			upsertShift(shift);
			handleClose();
		}
		dispatch(showNotification({ message: message, severity: success ? "success" : "error" }));
	};

	const isDuplicateShift = shiftsTemplateData.find(
		(shift: any) => shift.name.toLowerCase() === shiftName.toLowerCase()
	);
	// TODO: This is a temporary solution we need to fix later.
	if (subsMetadata?.noOfShifts >= 1 && shiftsTemplateData.length >= subsMetadata?.noOfShifts && !shiftSelectedData) {
		return (
			<SubscriptionPlanModal
				handleClose={handleClose}
				title="Maximum Field Limit Reached"
				message="You have reached the maximum field limit for your subscription plan.Please upgrade to a Higher Edition, or
				remove the extra fields."
			/>
		);
	} else {
		return (
			<BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
				<BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
					Add Shift Title
				</BootstrapDialogTitle>
				<DialogContent dividers style={{ position: "relative", padding: 0 }}>
					<div
						style={{
							position: "absolute",
							padding: "0.5rem 2rem",
							display: shiftSelectedData?.["readOnly"] ? "flex" : "none",
							zIndex: "1000",
							width: "100%",
							height: "100%",
							backgroundColor: "rgb(255, 255, 255, 0.2)",
							backdropFilter: "blur(8px)",
							alignItems: "center",
							justifyContent: "flex-start",
							paddingTop: "18%",
							fontFamily: "SFUIText-Medium, Helvetica, Arial, sans-serif",
							flexDirection: "column",
							gap: "0.5rem",
							lineHeight: "20px",
							textAlign: "center",
						}}>
						<LockIcon style={{ color: "gray", fontSize: "30px" }} />
						Shift editing restricted. This shift was created by a higher-level administrator and cannot be modified.
					</div>
					<div style={{ margin: "16px" }}>
						<TextField
							sx={{
								width: "100%",
								".MuiInputLabel-root": {
									fontSize: "14px",
									color: "#696969",
									fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
									fontWeight: 400,
								},
								".MuiInputBase-input": {
									fontSize: "14px",
									fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
									fontWeight: 400,
								},
							}}
							id="outlined-basic"
							label="Enter Shift Name"
							variant="outlined"
							disabled={shiftSelectedData?.["readOnly"]}
							value={shiftName}
							onChange={(e) => {
								setShiftName(e.target.value);
							}}
							required
							error={error ? shiftName === "" || (!shiftSelectedData && isDuplicateShift) : false}
							helperText={
								error
									? shiftName === ""
										? "Shift Name is required"
										: isDuplicateShift
										? "Shift Name already exists"
										: ""
									: ""
							}
						/>
						<form>
							<TimePicker
								id="startTime"
								label="Start Time"
								sx={{
									marginTop: "2rem",
									width: "100%",
									".MuiInputLabel-root": {
										fontSize: "14px",
										color: "#696969",
										fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
										fontWeight: 400,
									},
									".MuiInputBase-input": {
										fontSize: "14px",
										fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
										fontWeight: 400,
									},
								}}
								onChange={(value: string) => {
									setStartTime(dayjs(value).format("HH:mm"));
								}}
								value={dayjs()
									.startOf("day")
									.add(startTime.split(":")[0], "hours")
									.add(startTime.split(":")[1], "minutes")}
								disabled={shiftSelectedData?.["readOnly"]}
							/>
							<TimePicker
								id="endTime"
								label="End Time"
								sx={{
									marginTop: "2rem",
									width: "100%",
									".MuiInputLabel-root": {
										fontSize: "14px",
										color: "#696969",
										fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
										fontWeight: 400,
									},
									".MuiInputBase-input": {
										fontSize: "14px",
										fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
										fontWeight: 400,
									},
								}}
								onChange={(value: string) => {
									setEndTime(dayjs(value).format("HH:mm"));
								}}
								value={dayjs().startOf("day").add(endTime.split(":")[0], "hours").add(endTime.split(":")[1], "minutes")}
								disabled={shiftSelectedData?.["readOnly"]}
							/>
						</form>
					</div>
				</DialogContent>
				<DialogActions hidden={shiftSelectedData?.["readOnly"]}>
					<Button
						variant="contained"
						onClick={onSave}
						disabled={shiftSelectedData?.["readOnly"]}
						sx={{
							backgroundColor: "#0F45FF",
							fontSize: "12px",
							fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
							fontWeight: 600,
						}}>
						Save
					</Button>
				</DialogActions>
			</BootstrapDialog>
		);
	}
};
export default ShiftsCreateModal;
