/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ImportExportOutlinedIcon from "@mui/icons-material/ImportExportOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Checkbox, CircularProgress } from "@mui/material";
import "lodash";
import moment from "moment";
import "moment-timezone";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { ACCESS_CONTROL_USER, EXECUTIVE_USER, TASK_TYPE } from "../../../@types";
import useAdminEntitlements, { useAdminCompanySettings } from "../../useAdminEntitlements";

const getStatus = (task: TASK_TYPE) => {
	if (task.checkinTime && task.checkoutTime) {
		return { cls: "completed", value: "Completed" };
	}
	if (!task.checkinTime && !task.checkoutTime) {
		if (task.startTime && moment().valueOf() > task.startTime) {
			return { cls: "delayed", value: "Delayed" };
		}
		if (!task.startTime && !task.endTime) {
			return { cls: "not_started", value: "Not Started" };
		}
		return { cls: "not_started", value: "Not Started" };
	}
	if (task.checkinTime && !task.checkoutTime) {
		return { cls: "in_progress", value: "In Progress" };
	}
	if (!task.checkinTime) {
		return { cls: "not_started", value: "Not Started" };
	}
	if (task.startTime && moment().valueOf() > task.startTime) {
		return { cls: "delayed", value: "Delayed" };
	}
	return { cls: "not_available", value: "Not Available" };
};

const createdBy = (
	task: TASK_TYPE,
	addAdmins: Omit<ACCESS_CONTROL_USER, "employees">[],
	executive: Pick<EXECUTIVE_USER, "employeeID" | "firstName" | "lastName">
) => {
	if (task.createdByAdminID) {
		const admin = addAdmins.find((a) => a.id === task.createdByAdminID);
		return admin ? `${admin.firstname} ${admin.lastname}` : "NA";
	}
	return executive.firstName + " " + executive.lastName;
};

type ADDRESS_DETAIL =
	| {
			address: string;
			lat: number | null;
			lon: number | null;
	  }
	| {};

type Props = {
	tasks: TASK_TYPE[] | null;
	setTasks: Dispatch<SetStateAction<TASK_TYPE[] | null>>;
	isLoading: boolean;
	fieldExecutives: Pick<EXECUTIVE_USER, "employeeID" | "firstName" | "lastName">[];
	filterSelect: "all" | "not_started" | "in_progress" | "completed" | "delayed";
	searchTerm: string;
	allAdmins: Omit<ACCESS_CONTROL_USER, "employees">[];
	selectedTaskIDs: string[] | null;
	setSelectedTaskIDs: Dispatch<SetStateAction<string[] | null>>;
	// eslint-disable-next-line no-unused-vars
	handleSidebarOpen: (
		selectedTaskID: string | null,
		selectedEmployeeID: number,
		selectedAddressDetail: ADDRESS_DETAIL
	) => void;
	isClientAddressMasked: any;
};
const TaskCardContainer: FC<Props> = ({
	isLoading,
	tasks,
	fieldExecutives = [],
	handleSidebarOpen,
	searchTerm,
	filterSelect,
	allAdmins,
	selectedTaskIDs,
	setSelectedTaskIDs,
	isClientAddressMasked,
}) => {
	const loading = isLoading || fieldExecutives?.length === 0;
	const filteredTasks = tasks?.filter((task) => {
		if (filterSelect === "all") return task;
		return getStatus(task).cls === filterSelect;
	});

	const filteredExecutives = fieldExecutives?.filter((executive) =>
		(executive.firstName + executive?.lastName ?? "")
			.replace(/ /g, "")
			?.toLocaleLowerCase()
			?.includes(searchTerm.replace(/ /g, "").toLocaleLowerCase())
	);

	// Filter executives based on whether they have tasks matching the filterSelect criteria
	const executivesWithTasks = filteredExecutives?.filter((executive) =>
		filteredTasks?.some((task) => task.employeeID === executive.employeeID)
	);

	if (loading || tasks === null)
		return (
			<div className="small_circular-spinner">
				<CircularProgress />
			</div>
		);

	return (
		<div className="task_container">
			{executivesWithTasks.length === 0 && filterSelect !== "all" ? (
				<div className="no_data_for_cards">
					<p style={{ color: "gray", textAlign: "center" }}>
						There is no data to display. To see all employees, set Status View to "All"
					</p>
				</div>
			) : (
				(filterSelect === "all" ? filteredExecutives : executivesWithTasks)?.map((executive) => (
					<TaskCard
						handleSidebarOpen={handleSidebarOpen}
						key={executive.employeeID}
						executive={executive}
						allAdmins={allAdmins}
						tasks={filteredTasks ? filteredTasks.filter((task) => task.employeeID === executive.employeeID) : []}
						selectedTaskIDs={selectedTaskIDs}
						setSelectedTaskIDs={setSelectedTaskIDs}
						isClientAddressMasked={isClientAddressMasked}
					/>
				))
			)}
		</div>
	);
};

export default TaskCardContainer;

type Props2 = {
	executive: Pick<EXECUTIVE_USER, "employeeID" | "firstName" | "lastName">;
	tasks: TASK_TYPE[];
	selectedTaskIDs: string[] | null;
	setSelectedTaskIDs: Dispatch<SetStateAction<string[] | null>>;
	// eslint-disable-next-line no-unused-vars
	handleSidebarOpen: (
		selectedTaskID: string | null,
		selectedEmployeeID: number,
		selectedAddressDetail: ADDRESS_DETAIL
	) => void;
	allAdmins: Omit<ACCESS_CONTROL_USER, "employees">[];
	isClientAddressMasked: any;
};
const sortTasksByTime = (tasks: TASK_TYPE[]): TASK_TYPE[] =>
	tasks.sort((a, b) => {
		if (!a.checkinTime || !b.checkinTime) {
			if (!a.checkinTime && !b.checkinTime) {
				return a.startTime! - b.startTime!;
			} else if (!a.checkinTime) {
				return a.startTime! - b.checkinTime!;
			} else {
				return a.checkinTime! - b.startTime!;
			}
		}
		return a.checkinTime! - b.checkinTime!;
	});
const TaskCard: FC<Props2> = ({
	executive,
	tasks,
	handleSidebarOpen,
	allAdmins,
	selectedTaskIDs,
	setSelectedTaskIDs,
	isClientAddressMasked,
}) => {
	const [sortByDate, setSortByDate] = useState(false);
	const [expand, setExpand] = useState(window.innerWidth > 768);
	const sortedTasks = sortByDate ? [...sortTasksByTime([...tasks])] : [...tasks];
	const userWritePermission = useAdminEntitlements("tasks:assign", "write");
	const customTaskFlag = useAdminCompanySettings("customEntityMigrationPhase");
	// o - default
	// 1 - custom and default
	// 2 - custom
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			setSelectedTaskIDs((prev) => {
				if (prev === null) return [e.target.value];
				return [...prev, e.target.value];
			});
		} else {
			setSelectedTaskIDs((prev) => {
				if (prev === null) return null;
				return prev.filter((taskID) => taskID !== e.target.value);
			});
		}
	};
	const handleChangeForAll = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			setSelectedTaskIDs((prev) => {
				if (prev === null)
					return sortedTasks
						.filter((task) => getStatus(task)?.cls !== "completed" && getStatus(task)?.cls !== "in_progress")
						.map((task) => task.taskID);
				if (prev !== null) {
					const filteredPrev = prev.filter(
						(taskID) =>
							!sortedTasks
								.filter((task) => getStatus(task)?.cls !== "completed" && getStatus(task)?.cls !== "in_progress")
								.map((task) => task.taskID)
								.includes(taskID)
					);
					return [
						...filteredPrev,
						...sortedTasks
							.filter((task) => getStatus(task)?.cls !== "completed" && getStatus(task)?.cls !== "in_progress")
							.map((task) => task.taskID),
					];
				}
				return [
					...prev,
					...sortedTasks
						.filter((task) => getStatus(task)?.cls !== "completed" && getStatus(task)?.cls !== "in_progress")
						.map((task) => task.taskID),
				];
			});
		} else {
			setSelectedTaskIDs((prev) => {
				if (prev === null) return null;
				return prev.filter((taskID) => !sortedTasks.map((task) => task.taskID).includes(taskID));
			});
		}
	};
	const checkedIfAllTasksAreSelected = sortedTasks.filter((task) => selectedTaskIDs?.includes(task.taskID));
	return (
		<div className="card_container">
			<div className="title">
				<h3>
					<Checkbox
						checked={checkedIfAllTasksAreSelected.length > 0 && sortedTasks.length > 0}
						onChange={handleChangeForAll}
						inputProps={{ "aria-label": "controlled" }}
					/>
					{executive.firstName + " " + executive.lastName} ({tasks?.length ?? 0})
				</h3>
				<div className="buttons">
					<button className={`${expand ? "active dropdown" : "dropdown"}`} onClick={() => setExpand((e) => !e)}>
						<KeyboardArrowDownOutlinedIcon />
					</button>
					<button className={`${sortByDate ? "active sort" : "sort"}`} onClick={() => setSortByDate((e) => !e)}>
						<ImportExportOutlinedIcon />
					</button>
					<button
						className="add_task"
						onClick={() => handleSidebarOpen(null, executive.employeeID, {})}
						disabled={!userWritePermission}>
						<AddOutlinedIcon />
					</button>
				</div>
			</div>
			{expand && tasks.length > 0 && (
				<ul>
					{sortedTasks.map((task, idx) => {
						const type = task.clientInfo && (task.clientInfo.employeeID === -2 ? "Sites" : "Clients");
						const disallowEdit = !!task.checkinTime && !!task.checkoutTime;
						return (
							<li className="task_card" key={task.taskID}>
								<div className="header">
									<Checkbox
										checked={selectedTaskIDs?.includes(task.taskID) ?? false}
										onChange={handleChange}
										value={task.taskID}
										inputProps={{ "aria-label": "controlled" }}
										disabled={getStatus(task)?.cls === "completed" || getStatus(task)?.cls === "in_progress"}
									/>
									<button
										disabled={!userWritePermission || selectedTaskIDs?.includes(task.taskID)}
										onClick={() => handleSidebarOpen(task.taskID, executive.employeeID, {})}>
										{disallowEdit ? <RemoveRedEyeOutlinedIcon /> : <EditOutlinedIcon />}
									</button>
								</div>
								<div
									role="button"
									tabIndex={0}
									onClick={() =>
										userWritePermission &&
										!selectedTaskIDs?.includes(task.taskID) &&
										handleSidebarOpen(task.taskID, executive.employeeID, {})
									}>
									<div className="description">
										<h4>
											{task.taskDescription && (
												<>
													<span>{customTaskFlag === 2 ? "Task Name" : "Description"}</span>
													{task.taskDescription}
												</>
											)}
										</h4>
									</div>
									<div className="action">
										<div className={getStatus(task)?.cls}>
											<span>Status</span>
											<h6>{getStatus(task)?.value}</h6>
										</div>
										<div>
											<span>Created By</span>
											<h6>{createdBy(task, allAdmins, executive)}</h6>
										</div>
									</div>
									<ul className="footer">
										<li>
											<span>Schedule at</span>
											<h6>
												{task.startTime ? moment(task.startTime).format("hh:mm A") : "NA"} -{" "}
												{task.endTime ? moment(task.endTime).format("hh:mm A") : "NA"}
											</h6>
										</li>
										<li>
											<span>CheckIn Time</span>
											<h6>
												{task.checkinTime ? moment(task.checkinTime).format("hh:mm A") : "NA"} -{" "}
												{task.checkoutTime ? moment(task.checkoutTime).format("hh:mm A") : "NA"}
											</h6>
										</li>
										<li>
											<span>Location</span>
											<h6>{isClientAddressMasked === 1 ? "***** *** **" : task.address ? task.address : "NA"}</h6>
										</li>
										{type && (
											<li>
												<span>{type}</span>
												<h6>{task.clientInfo?.clientName}</h6>
											</li>
										)}
									</ul>
								</div>
							</li>
						);
					})}
				</ul>
			)}
		</div>
	);
};
