import { Modal, Tooltip } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { type COMPLIANCE } from "../../../@types";
import { useEffect, useState } from "react";
import { checkS3LinkValidity } from "../../../utils";
import { AccessTime } from "@mui/icons-material";
import moment from "moment";
import { DATE_SHOW_FORMAT } from "../../../constants";
type Props = {
	onClose: () => void;
	open: boolean;
	evidences: NonNullable<COMPLIANCE["evidence"]>;
};
const ComplianceModal = ({ open, onClose, evidences }: Props) => {
	const [evidencesCopy, setEvidencesCopy] = useState<any>([]);
	useEffect(() => {
		const transformEvidences = async () => {
			const tempEvidence = await Promise.all(
				evidences.map(async (evidence) => {
					const url = evidence.url.includes(".jpg") ? evidence.url : evidence.url + ".jpg";
					const isValidS3Link = await checkS3LinkValidity(url);
					return {
						...evidence,
						url,
						isValidS3Link,
					};
				})
			);
			setEvidencesCopy(tempEvidence);
		};
		transformEvidences();
	}, [evidences]);
	return (
		<Modal open={open} onClose={onClose}>
			<div className="settings_modal">
				<div className="title">
					<h4>Compliance Evidences</h4>
					<button onClick={onClose}>
						<CloseOutlinedIcon htmlColor="#42444A" />
					</button>
				</div>
				<div className="images">
					{evidencesCopy.map((evidence: any, i: any) => (
						<div key={i} style={{ display: "flex", flexDirection: "column" }}>
							<a target="blank" href={process.env.NEXT_PUBLIC_S3_URL + evidence.url} key={i}>
								<img
									src={process.env.NEXT_PUBLIC_S3_URL + evidence.url}
									height={60}
									width={60}
									onError={(e: any) => {
										e.currentTarget.src = "/images/brokenImage.png";
									}}
								/>
								<p style={{ fontSize: "12px", marginTop: "5px" }}>{moment(evidence.date).format(DATE_SHOW_FORMAT)}</p>
							</a>
							<Tooltip
								title="Image has not been uploaded from phone, please ask the user to Sync from the app."
								placement="top"
								sx={{ marginTop: "5px" }}
								hidden={evidence.isValidS3Link}>
								<AccessTime fontSize="small" color="primary" />
							</Tooltip>
						</div>
					))}
				</div>
			</div>
		</Modal>
	);
};

export default ComplianceModal;
