import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import { Modal } from "@mui/material";
type Props = {
	onClose: () => void;
	open: boolean;
	type: "good" | "bad" | "no_data";
};
const goodAppStatus =
	"Employee has followed the instructions shown in the video in the setup guide and has allowed Unolo to run in the background. Location Setting: ";
const goodLocationSetting = "Employee has not disabled GPS while punched in for duty.";
const badAppStatus =
	"Employee has not followed the instructions shown in the video in the setup guide. This means Unolo cannot run in the background.";
const badLocationSetting = "Employee has disabled GPS while punched in for duty.";
const noDataAppStatus =
	"No Data means that the Employee has not punched in for duty yet. Once the Employee punches in for duty, the status will be updated.";
const noDataLocationSetting =
	"No Data means that the Employee has not punched in for duty yet. Once the Employee punches in for duty, the status will be updated.";
const ComplianceStatusModal = ({ open, onClose, type }: Props) => (
	<Modal open={open} onClose={onClose}>
		<div className={`settings_modal status_modal ${type}`}>
			{type === "good" && <ThumbUpAltOutlinedIcon />}
			{type === "bad" && <ThumbDownOffAltOutlinedIcon />}
			{type === "no_data" && <QuestionMarkOutlinedIcon />}
			{type === "good" && <h3>What is Good Status?</h3>}
			{type === "bad" && <h3>What is Bad Status?</h3>}
			{type === "no_data" && <h3>What is No Data?</h3>}
			{type === "good" && (
				<>
					<p>
						<strong>App Setting: </strong>
						{goodAppStatus}
					</p>
					<p>
						<strong>Location Setting: </strong>
						{goodLocationSetting}
					</p>
				</>
			)}
			{type === "bad" && (
				<>
					<p>
						<strong>App Setting: </strong>
						{badAppStatus}
					</p>
					<p>
						<strong>Location Setting: </strong>
						{badLocationSetting}
					</p>
				</>
			)}
			{type === "no_data" && (
				<>
					<p>
						<strong>App Setting: </strong>
						{noDataAppStatus}
					</p>
					<p>
						<strong>Location Setting: </strong>
						{noDataLocationSetting}
					</p>
				</>
			)}
			<p></p>
			<button onClick={onClose}>Okay</button>
		</div>
	</Modal>
);

export default ComplianceStatusModal;
