import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Link from "next/link";
import { FC, useState } from "react";
import DeleteModal from "../../common/DeleteModal";
type Props = {
	link: string;
	name: string;
	deleteHandler: () => void;
	userWritePermission:any;
};
const RoutesActionButtons: FC<Props> = ({ name, link, deleteHandler, userWritePermission}) => {
	const [open, setOpen] = useState(false);
	return (
        <div className="action-buttons">
			<Link prefetch={false} href={link} passHref legacyBehavior>
				<button disabled={!userWritePermission}>
					<EditOutlinedIcon htmlColor="#8F8F8F" />
				</button>
			</Link>
			<button
				onClick={(e) => {
					e.stopPropagation();
					setOpen(true);
				}}
				disabled={!userWritePermission}>
				<DeleteIcon htmlColor="#F54747" />
			</button>
			{open && <DeleteModal deleteHandler={deleteHandler} handleClose={() => setOpen(false)} name={name} />}
		</div>
    );
};

export default RoutesActionButtons;
