/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from "react";
import { GridCloseIcon } from "@mui/x-data-grid-pro";
import { useQuery } from "@apollo/client";
import moment from "moment";

// mui
import { Box, Modal, Skeleton } from "@mui/material";
import AdjustIcon from "@mui/icons-material/Adjust";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import BusinessIcon from "@mui/icons-material/Business";
import EventNoteIcon from "@mui/icons-material/EventNote";
import PersonIcon from "@mui/icons-material/Person";
import LaunchIcon from "@mui/icons-material/Launch";
import AttachFileIcon from "@mui/icons-material/AttachFile";
// schema
import { GET_TASK_BY_ID } from "../../schema/tasks";
import { GET_ALL_ENTITYMETADATA_BY_ENTITY_FOR_CUSTOM_TASK } from "../../schema/entityMetadata";
import { FORM_REDIRECT_URL } from "../../utils";
import { GET_ORDER_BY_ID } from "../../schema/orders";

type Props = {
	id: string;
	openTaskModal: boolean;
	setOpenTaskModal: (open: boolean) => void;
};
// capitalize the first letter of the string
function capitalizeFirstLetter(string: string) {
	return string?.charAt(0).toUpperCase() + string?.slice(1);
}
const TaskDetailModal = ({ id, openTaskModal, setOpenTaskModal }: Props) => {
	const { data: taskData, loading: taskLoading } = useQuery(GET_TASK_BY_ID, {
		variables: {
			taskID: id,
		},
		skip: !openTaskModal,
	});

	const [customEntityID, setCustomEntityID] = useState(null);

	useEffect(() => {
		if (taskData?.get_task?.customEntity?.customEntityID) {
			setCustomEntityID(taskData.get_task.customEntity.customEntityID);
		}
	}, [taskData]);

	const { data: customTaskEntityMetaData } = useQuery(GET_ALL_ENTITYMETADATA_BY_ENTITY_FOR_CUSTOM_TASK, {
		variables: { customEntityID },
		skip: !openTaskModal || !customEntityID,
	});

	// reusing the same task states
	const taskStatus =
		taskData?.get_task?.checkinTime && !taskData?.get_task?.checkoutTime
			? "In Progress"
			: taskData?.get_task?.checkinTime && taskData?.get_task?.checkoutTime
				? "Completed"
				: "Not Started";

	const classNameColor: any = {
		"Not Started": "InActive",
		Pending: "OnLeave",
		Completed: "PunchedIn",
	};

	return (
		<div>
			<Modal
				open={openTaskModal}
				onClose={() => setOpenTaskModal(false)}
				style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<>
					<Box
						sx={{
							backgroundColor: "#FFFFFF",
							borderRadius: "10px",
							width: { xs: "90%", sm: "80%", md: "70%", lg: "60%", xl: "50%" },
							fontFamily: "SFUIText-Medium",
						}}
						className="downloadModalBox">
						<div
							style={{
								display: "flex",
								width: "100%",
								justifyContent: "space-between",
								padding: "10px 25px",
								borderBottom: "1px solid #E0E0E0",
							}}>
							<p className="mt-2" style={{ fontWeight: "regular", fontSize: "1rem" }}>
								{taskData && taskData?.get_task?.customEntity !== null
									? capitalizeFirstLetter(taskData?.get_task.customEntity?.customEntityName)
									: "Task Detail"}
							</p>
							<div style={{ cursor: "pointer" }}>
								<GridCloseIcon onClick={() => setOpenTaskModal(false)} />
							</div>
						</div>

						{taskData && !taskLoading && (
							<div
								className="datagrid-table px-4"
								style={{
									fontFamily: "SFUIText-Regular",
									fontSize: "14px",
									height: "100%",
								}}>
								{/* Status */}
								<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
									<AdjustIcon />
									<div>
										<span className={`role_badge ${classNameColor[taskStatus]}`}>{taskStatus}</span>
									</div>
								</div>
								{/* Check IN time and Check OUT time */}
								<div style={{ display: "flex", alignItems: "center", marginTop: "10px", gap: "5px" }}>
									<QueryBuilderIcon />
									<span style={{ marginLeft: "5px", fontFamily: "SFUIText-Regular", fontSize: "14px" }}>
										{moment(taskData.get_task.checkinTime).format("hh:mm A")}-{" "}
										{moment(taskData.get_task.checkoutTime).format("hh:mm A")}
									</span>
								</div>

								{/* Address */}
								<div style={{ display: "flex", alignItems: "center", marginTop: "10px", gap: "5px" }}>
									<BusinessIcon />
									<span style={{ marginLeft: "5px", fontFamily: "SFUIText-Regular", fontSize: "14px" }}>
										{taskData.get_task.address}
									</span>
								</div>

								{/* Note */}
								{taskData?.get_task.customEntity === null && (
									<div style={{ display: "flex", alignItems: "center", marginTop: "10px", gap: "5px" }}>
										<EventNoteIcon />
										<span style={{ marginLeft: "5px", fontFamily: "SFUIText-Regular", fontSize: "14px" }}>
											{taskData.get_task.taskDescription || "-"}
										</span>
									</div>
								)}

								{/* Client  */}
								{taskData?.get_task?.clientInfo && (
									<div style={{ display: "flex", alignItems: "center", marginTop: "10px", gap: "5px" }}>
										<PersonIcon />
										<span style={{ marginLeft: "5px", fontFamily: "SFUIText-Regular", fontSize: "14px" }}>
											{capitalizeFirstLetter(taskData.get_task.clientInfo.clientName)}
										</span>
									</div>
								)}

								{/* Custom Fields */}
								<hr />

								{/* Custom Fields  */}
								{taskData?.get_task.customFieldsComplex?.length > 0 && customTaskEntityMetaData && (
									<div
										style={{
											height: "49%",
											overflow: "auto",
											padding: "10px",
											fontFamily: "SFUIText-Regular",
											fontSize: "14px",
										}}>
										<CustomFields
											fields={taskData.get_task.customFieldsComplex}
											customEntityMetaData={customTaskEntityMetaData}
										/>
									</div>
								)}
							</div>
						)}

						{taskLoading && (
							<div className="p-4">
								{Array.from({ length: 5 }).map((_, index) => (
									<Skeleton key={index} animation="wave" height={40} sx={{ backgroundColor: "#e8eaed" }} />
								))}
								<div className="d-flex gap-2">
									{Array.from({ length: 3 }).map((_, index) => (
										<Skeleton
											key={index}
											animation="wave"
											height={150}
											width={100}
											sx={{ backgroundColor: "#e8eaed", borderRadius: "5px" }}
										/>
									))}
								</div>
								{Array.from({ length: 2 }).map((_, index) => (
									<Skeleton key={index} animation="wave" height={40} sx={{ backgroundColor: "#e8eaed" }} />
								))}
							</div>
						)}
					</Box>
				</>
			</Modal>
		</div>
	);
};

const CustomFields = ({ fields, customEntityMetaData }: { fields: Array<any>; customEntityMetaData: any }) => {
	const element = fields.map((field, index) => {
		const datePattern = /^\d{4}-\d{2}-\d{2}$/;

		// check if the field is a date
		const isDate = datePattern.test(field.fieldValue) && moment(field.fieldValue, "YYYY-MM-DD").isValid();

		// if all three is present then it is a contact field
		const isContactField = field.fieldValue && field.fieldValue2 && field.fieldValue3;

		const entityMetaData = customEntityMetaData?.get_all_entitymetadata_by_custom_entity.find(
			(metaData: any) => metaData.fieldName === field.fieldName
		);

		// for client and site we are already showing the client name and site name so skipping them
		if (["client", "site"]?.includes(entityMetaData?.dataType)) return <></>;

		// forms
		if (entityMetaData?.dataType === "form") {
			return (
				<div key={index}>
					<p
						style={{
							fontFamily: "SFUIText-Regular",
							fontSize: "14px",
							marginBottom: "20px",
							display: "flex",
							alignItems: "center",
							gap: 2,
						}}>
						<strong>{capitalizeFirstLetter(field.fieldName)}: </strong>
						<span>
							{field?.fieldValue && field?.fieldValue2 ? (
								<a
									href={`${FORM_REDIRECT_URL}/${field?.fieldValue}`}
									target="_blank"
									rel="noreferrer"
									style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "10px" }}>
									Completed <LaunchIcon style={{ fontSize: "15px" }} />
								</a>
							) : (
								"Not Filled"
							)}
						</span>
					</p>
				</div>
			);
		}

		// orders
		if (entityMetaData?.dataType === "order") {
			return (
				<div key={index}>
					<p
						style={{
							fontFamily: "SFUIText-Regular",
							fontSize: "14px",
							marginBottom: "20px",
							display: "flex",
							alignItems: "center",
							gap: 2,
						}}>
						<strong>{capitalizeFirstLetter(field.fieldName)}: </strong>
						<span>{field?.fieldValue ? <OrderDetail orderID={field?.fieldValue} /> : "Not Filled"}</span>
					</p>
				</div>
			);
		}

		return (
			<div key={index}>
				<p style={{ fontFamily: "SFUIText-Regular", fontSize: "14px", marginBottom: "20px" }}>
					<strong>{capitalizeFirstLetter(field.fieldName)}: </strong>
					{field.fieldValue !== null ? (
						<span style={{ fontSize: "14px" }}>
							{isDate
								? moment(field.fieldValue).format("DD-MM-YYYY")
								: isContactField
									? `${field.fieldValue}, +${field.fieldValue2} ${field.fieldValue3}`
									: field.fieldValue}
						</span>
					) : (
						<>
							{field.selectedFieldValues !== null && <br />}
							<div style={{ display: "flex" }}>
								{field.selectedFieldValues?.map((selectedField: any) => (
									<ImageWithLink src={selectedField.fieldValue} key={selectedField.fieldValue} />
								))}
							</div>

							{field.selectedOptionsValues?.join(", ")}
						</>
					)}
				</p>
			</div>
		);
	});

	return <>{element}</>;
};

const ImageWithLink = ({ src }: { src: string }) => {
	const openLink = (e: any) => {
		e.stopPropagation();
		window.open(src, "_blank");
	};
	// Extract file extension from src
	const fileExtension = src.split(".").pop()?.toLowerCase();

	// Define supported image formats
	const imageFormats = ["jpg", "jpeg", "png", "gif", "bmp"];

	// Check if the file is an image
	const isImage = imageFormats.includes(fileExtension || "");

	return (
		<>
			{isImage ? (
				<img
					src={src}
					alt=""
					style={{
						height: "50px",
						width: "50px",
						objectFit: "cover",
						objectPosition: "center",
						border: "1px solid gray",
						borderRadius: "5px",
						cursor: "pointer",
						marginTop: "10px",
					}}
					onClick={openLink}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							openLink(e);
						}
					}}
				/>
			) : (
				<div
					onClick={openLink}
					style={{
						width: "50px",
						height: "50px",
						marginRight: "5px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						border: "1px solid gray",
						borderRadius: "5px",
						cursor: "pointer",
						marginTop: "10px",
					}}>
					<AttachFileIcon />
				</div>
			)}
		</>
	);
};

const OrderDetail = ({ orderID }: { orderID: string }) => {
	const { data: orderData } = useQuery(GET_ORDER_BY_ID, {
		variables: {
			orderID: orderID,
		},
		skip: !orderID,
	});

	return (
		<a
			href={`/order/orders?invoiceNumber=${orderData?.get_order?.invoiceNumber}&processingDate=${orderData?.get_order?.processingDate}`}
			target="_blank"
			rel="noreferrer"
			style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "10px" }}>
			Completed <LaunchIcon style={{ fontSize: "15px" }} />
		</a>
	);
};

export default TaskDetailModal;
