import { Avatar } from "@mui/material";
import React, { FC } from "react";
import { findAvatarInitials } from "../../../utils";
type Props = {
	avatar?: string;
	name: string;
	internalEmployeeID?: string | null;
};

const ImageAndNameCell: FC<Props> = ({ avatar, name, internalEmployeeID }) => (
	<div className="column-image-name">
		{avatar && avatar.length > 0 ? (
			<img src={avatar} height={40} width={40} />
		) : (
			<Avatar sx={{ backgroundColor: "#D9F0FD", color: "#25A8F4" }}>{findAvatarInitials(name)}</Avatar>
		)}
		<h5>
			<div>{name}</div>
			<div style={{ color: "GrayText", fontSize: ".8rem" }}>{internalEmployeeID ? `(${internalEmployeeID})` : ""}</div>
		</h5>
	</div>
);

export default ImageAndNameCell;
