// react
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";

// mui
import ArrowUpIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownIcon from "@mui/icons-material/ArrowDownward";
import InfoIcon from "@mui/icons-material/Info";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Tooltip } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";

// query and mutation
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { APP_DISPATCH, showNotification } from "../../../redux";

// components
import ActionButtons from "./ActionButtons";

// libraries
import moment from "moment";

//constants, types and utils
import { DG_STYLES } from "../../../constants";
import { UPSERT_ENTITYMETADATA_BATCH } from "../../../schema/entityMetadata";
import { CUSTOM_FIELD_METADATA } from "../../../@types";
import CustomColumnMenu from "../../common/datagrid/CustomColumnMenu";
import useAdminEntitlements, { useAdminCompanySettings } from "../../useAdminEntitlements";
import CustomSwitch from "./CustomFieldTableSwitch";

type Props = {
	customFieldMetadata: CUSTOM_FIELD_METADATA[];
	// eslint-disable-next-line no-unused-vars
	openSidebar: (fieldID: string) => void;
	loading: boolean;
	setCustomFieldMetadata: Dispatch<SetStateAction<CUSTOM_FIELD_METADATA[]>>;
	selectedEntity: "Clients" | "Tasks" | "products" | "Orders" | "Advances" | "Expenses" | "Conveyances";
	saveSequenceChange: any;
	subsMetadata: any;
};

// util functions
const getOptions = (customFieldMetadata: CUSTOM_FIELD_METADATA[], fieldID: string) => {
	const options = customFieldMetadata?.find((field) => field.fieldID === fieldID)?.options;
	return options
		? options
				.filter((option) => option.isDeleted !== true && option.softDeleted!==1)
				.map((option) => option.optionValue)
				.join(", ")
		: "-";
};

// util components
const NotAuthorized = () => (
	<div style={{ padding: "5px" }}>
		<NotInterestedIcon />
	</div>
);

const CustomFieldTable: FC<Props> = ({
	selectedEntity,
	customFieldMetadata,
	loading,
	openSidebar,
	setCustomFieldMetadata,
	saveSequenceChange,
	subsMetadata,
}) => {
	const dispatch = useDispatch<APP_DISPATCH>();

	// permissions
	const userWritePermission = useAdminEntitlements("settings:customFields", "write");

	const [upsertEntitymetadataBatch] = useMutation(UPSERT_ENTITYMETADATA_BATCH);

	// states
	const [entity, setEntity] = useState(selectedEntity);
	const [columns, setColumns] = useState<GridColDef[]>([]);
	const clientMasking = useAdminCompanySettings("clientMasking");

	// effects
	const tempColumns = useMemo(
		() => [
			{
				field: "actions",
				sortable: false,
				type: "actions",
				headerName: "Sequence",
				width: 100,
				getActions: (params: GridValueGetterParams) => {
					// index of all elements with isDefault === 1
					const defaultIndexes = customFieldMetadata
						.filter((metadata) => metadata.isDefault === 1)
						.map((metadata) => customFieldMetadata.findIndex((data) => data.fieldID === metadata.fieldID));
					const columnBeforeIsDefault = defaultIndexes.includes(params.row.sequence - 1);
					return [
						<>
							{!params.row.isDefault && (
								<GridActionsCellItem
									icon={
										<Tooltip title="Move to top">
											<ArrowUpIcon />
										</Tooltip>
									}
									label="Move to top"
									onClick={() => handleRowOrderChange(params, "up")}
									key="1"
									disabled={params.row.sequence === 0 || columnBeforeIsDefault || loading || !userWritePermission}
									placeholder={undefined}
									onPointerEnterCapture={undefined}
									onPointerLeaveCapture={undefined}
									showInMenu={undefined}
								/>
							)}
						</>,
						<>
							{!params.row.isDefault && (
								<GridActionsCellItem
									icon={
										<Tooltip title="Move to bottom">
											<ArrowDownIcon />
										</Tooltip>
									}
									label="Move to bottom"
									onClick={() => handleRowOrderChange(params, "down")}
									key="2"
									disabled={params.row.sequence === customFieldMetadata.length - 1 || loading || !userWritePermission}
									placeholder={undefined}
									onPointerEnterCapture={undefined}
									onPointerLeaveCapture={undefined}
									showInMenu={undefined}
								/>
							)}
						</>,
					];
				},
			},
			{
				field: "fieldName",
				sortable: false,
				headerName: "Field Name",
				flex: 1,
				minWidth: 200,
			},
			{
				field: "dataType",
				sortable: false,
				headerName: "Input Type",
				flex: 1,
				minWidth: 100,
				renderCell: (params: GridValueGetterParams) => {
					if (params.row.dataType === "varchar") return "Text";
					if (params.row.dataType === "number") return "Number";
					if (params.row.dataType === "address") return "Address";
					if (params.row.dataType === "date") return "Date";
					if (params.row.dataType === "dropDown") return "DropDown";
					if (params.row.dataType === "checkbox") return "Checkbox";
					if (params.row.dataType === "contact") return "Contact";
					if (params.row.dataType === "photo") return "Photo";
					if (params.row.dataType === "file") return "File";
					if (params.row.dataType === "form") return "Form";
					if (params.row.dataType === "employee") return "Employee";
					if (params.row.dataType === "status") return "Status";
				},
			},
			{
				field: "options",
				sortable: false,
				headerName: "Input Value",
				flex: 1,
				minWidth: 100,
				valueFormatter: (params: any) => getOptions(customFieldMetadata, params.id),
			},
			{
				field: "active",
				sortable: false,
				headerName: "Active",
				minWidth: 125,
				flex: 1,
				renderCell: (params: GridValueGetterParams<any, CUSTOM_FIELD_METADATA>) => {
					if (params["row"]["isMandatory"] && params["row"]["fieldName"] !== "Order Status") {
						return <NotAuthorized />;
					}
					const active = params.row.active === 0 ? false : true;
					return (
						<CustomSwitch
							checked={active}
							disabled={!userWritePermission}
							onClick={(e: any) => {
								e.stopPropagation();
								changeFieldStatus(params.row.fieldID, "active", !active);
							}}
							title={
								selectedEntity !== "Advances"
									? "Changing active status effects the visibility of custom field in targets."
									: undefined
							}
							text=""
							style={{ cursor: !userWritePermission ? "not-allowed" : "pointer", pointerEvents: "auto" }}
						/>
					);
				},
			},
			{
				field: "required",
				sortable: false,
				headerName: "Required",
				flex: 1,
				minWidth: 125,
				renderCell: (params: GridValueGetterParams<any, CUSTOM_FIELD_METADATA>) => {
					if (params["row"]["isMandatory"] && params["row"]["fieldName"] !== "Order Status") {
						return <NotAuthorized />;
					}
					const isRequired = params.row.isRequired === 0 ? false : true;
					return (
						<CustomSwitch
							checked={isRequired}
							disabled={!userWritePermission}
							onClick={(e: any) => {
								e.stopPropagation();
								changeFieldStatus(params.row.fieldID, "isRequired", !isRequired);
							}}
							style={{ cursor: !userWritePermission ? "not-allowed" : "pointer", pointerEvents: "auto" }}
						/>
					);
				},
			},
			{
				field: "isDuplicate",
				sortable: false,
				headerName: "Duplicate Check",
				flex: 1,
				minWidth: 170,
				hide: ["Tasks", "products", "Orders", "Advances", "Expenses", "Conveyances"].includes(selectedEntity),
				renderHeader: () => {
					const text =
						"Prevent duplication of clients. For example, prevent putting in two clients with the same phone number by enabling Duplicate Check on the Contact Number field.";
					return (
						<strong>
							{"Duplicate Check"}
							<span>
								<Tooltip title={text} style={{ cursor: "pointer" }} placement="left">
									<InfoIcon sx={{ marginLeft: "10px", fontSize: "20px", color: "#6f6f6f" }} />
								</Tooltip>
							</span>
						</strong>
					);
				},
				renderCell: (params: GridValueGetterParams<any, CUSTOM_FIELD_METADATA>) => {
					const isDuplicate = params.row.primaryField ? true : false;
					if (
						(params["row"]["dataType"] === "varchar" ||
							params["row"]["dataType"] === "number" ||
							params["row"]["dataType"] === "contact") &&
						selectedEntity === "Clients"
					)
						return (
							<CustomSwitch
								checked={isDuplicate}
								disabled={!userWritePermission}
								onClick={(e: any) => {
									e.stopPropagation();
									changeFieldStatus(params.row.fieldID, "primaryField", !isDuplicate);
								}}
								style={{ cursor: !userWritePermission ? "not-allowed" : "pointer", pointerEvents: "auto" }}
							/>
						);
					return <NotAuthorized />;
				},
			},
			{
				field: "maskField",
				sortable: false,
				headerName: "Enable Masking",
				flex: 1,
				minWidth: 170,
				hide: Boolean(clientMasking && selectedEntity === "Clients") ? false : true,
				renderHeader: () => {
					const text = "Mask the field value in the UI. For example, mask the phone number field.";
					return (
						<strong>
							{"Enable Masking"}
							<span>
								<Tooltip title={text} style={{ cursor: "pointer" }} placement="left">
									<InfoIcon sx={{ marginLeft: "10px", fontSize: "20px", color: "#6f6f6f" }} />
								</Tooltip>
							</span>
						</strong>
					);
				},
				renderCell: (params: GridValueGetterParams<any, CUSTOM_FIELD_METADATA>) => {
					const isMasked = params.row.maskField ? true : false;

					if (selectedEntity === "Clients" && params.row.fieldName !== "Name" && params.row.fieldName !== "Client ID")
						return (
							<CustomSwitch
								checked={isMasked}
								disabled={!userWritePermission}
								onClick={(e: any) => {
									e.stopPropagation();
									changeFieldStatus(params.row.fieldID, "maskField", !isMasked);
								}}
								style={{ cursor: !userWritePermission ? "not-allowed" : "pointer", pointerEvents: "auto" }}
							/>
						);
					return <NotAuthorized />;
				},
			},
			{
				field: "Read Only (Employee)",
				sortable: false,
				headerName: "Read Only (Employee)",
				flex: 1,
				minWidth: 150,
				hide: ["products", "Advances"].includes(selectedEntity),
				renderHeader: () => {
					const text = "If switched on, Employee will only be able to read the field and not edit it.";
					return (
						<strong>
							Read Only
							<span>
								<Tooltip title={text} style={{ cursor: "pointer" }}>
									<InfoIcon sx={{ marginLeft: "10px", fontSize: "20px", color: "#6f6f6f" }} />
								</Tooltip>
							</span>
						</strong>
					);
				},
				renderCell: (params: GridValueGetterParams<any, CUSTOM_FIELD_METADATA>) => {
					const isRead = params.row.write === 1 ? false : true;
					if (params["row"]["isDefault"] !== 1 && params["row"]["isMandatory"] !== 1)
						return (
							<CustomSwitch
								checked={isRead}
								disabled={!userWritePermission || params.row.isRequired ? true : false}
								onClick={(e: any) => {
									e.stopPropagation();
									changeFieldStatus(params.row.fieldID, "write", isRead ? true : false);
								}}
								title={params.row.isRequired ? "Read Only can't be enabled if Required is turned on." : undefined}
								style={{ cursor: !userWritePermission ? "not-allowed" : "pointer", pointerEvents: "auto" }}
							/>
						);
					return <NotAuthorized />;
				},
			},
			{
				field: "action",
				sortable: false,
				headerName: "Action",
				width: 120,
				renderCell: (params: GridValueGetterParams<any, CUSTOM_FIELD_METADATA>) =>
					params.row.isDefault || params.row.isMandatory ? (
						<NotAuthorized />
					) : (
						<ActionButtons
							handleCustomFieldID={() => openSidebar(params.row.fieldID)}
							name={params.row.fieldName}
							deleteHandler={() => deleteCustomField(params.row.fieldID)}
							data={[params.row.fieldID]}
							moduleName={"custom field"}
							userWritePermission={userWritePermission}
						/>
					),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[customFieldMetadata, userWritePermission]
	);

	useEffect(() => {
		if (subsMetadata?.clientDupCheck === 1) {
			setColumns(tempColumns);
		} else {
			const filteredColumns = tempColumns.filter((col) => col.field !== "isDuplicate");
			setColumns(filteredColumns);
		}
		setColumns(tempColumns);
	}, [tempColumns, subsMetadata]);

	useEffect(() => {
		setEntity(selectedEntity);
	}, [selectedEntity]);

	// functions
	const changeFieldStatus = async (fieldID: string, name: string, value: boolean) => {
		if (!fieldID || fieldID.length === 0 || !name || name.length === 0) {
			return;
		}
		const updatedMetadataIndex = customFieldMetadata.findIndex((metadata) => metadata.fieldID === fieldID);
		if (updatedMetadataIndex < 0) return;
		const updatedMetadata = [...customFieldMetadata];

		// if the field is "isRequired" also toggle "write" field
		if (name === "isRequired" && entity !== "products" && entity !== "Advances") {
			const requiredCurrentState = value ? 1 : 0;
			const writeCurrentState = updatedMetadata[updatedMetadataIndex]["write"] ? 1 : 0;
			if (`${requiredCurrentState}${writeCurrentState}` === "10") {
				updatedMetadata[updatedMetadataIndex]["write"] = 1;
			}
			updatedMetadata[updatedMetadataIndex][name] = requiredCurrentState;
		} else updatedMetadata[updatedMetadataIndex][name] = value ? 1 : 0;

		delete updatedMetadata[updatedMetadataIndex].id;
		delete updatedMetadata[updatedMetadataIndex].__reorder__;
		const toUpdateField: CUSTOM_FIELD_METADATA = {
			...updatedMetadata[updatedMetadataIndex],
			options: updatedMetadata[updatedMetadataIndex].options?.map((option: any) => {
				const newOption = {
					...option,
					isDeleted: option.hasOwnProperty("isDeleted") ? option.isDeleted : option.softDeleted ? true : false,
				};
				if (newOption.hasOwnProperty("softDeleted")) {
					delete newOption["softDeleted"];
				}
				return newOption;
			}),
			timeStamp: moment().valueOf(),
		};
		const {
			data: {
				upsert_entitymetadata_batch: { success },
			},
		} = await upsertEntitymetadataBatch({
			variables: {
				input: [toUpdateField],
			},
		});
		dispatch(
			showNotification({
				message: success ? "Custom Field updated successfully" : "Something went wrong",
				severity: success ? "success" : "error",
			})
		);
		if (success) setCustomFieldMetadata(updatedMetadata);
	};

	const deleteCustomField = async (id: string) => {
		if (!id || id.length === 0) return;
		const updatedMetadataIndex = customFieldMetadata.findIndex((metadata) => metadata.fieldID === id);
		if (updatedMetadataIndex < 0) return;
		const updatedMetadata = [...customFieldMetadata];
		delete updatedMetadata[updatedMetadataIndex].id;
		delete updatedMetadata[updatedMetadataIndex].__reorder__;
		const toDeleteField: CUSTOM_FIELD_METADATA = {
			...updatedMetadata[updatedMetadataIndex],
			options: updatedMetadata[updatedMetadataIndex].options?.map((option: any) => {
				const newOption = {
					...option,
					isDeleted: option.hasOwnProperty("isDeleted") ? option.isDeleted : option.softDeleted ? true : false,
				};
				if (newOption.hasOwnProperty("softDeleted")) {
					delete newOption["softDeleted"];
				}
				return newOption;
			}),
			isDeleted: 1,
			timeStamp: moment().valueOf(),
		};
		const {
			data: {
				upsert_entitymetadata_batch: { success },
			},
		} = await upsertEntitymetadataBatch({
			variables: {
				input: [toDeleteField],
			},
		});
		dispatch(
			showNotification({
				message: success ? "Custom Field deleted successfully" : "Something went wrong",
				severity: success ? "success" : "error",
			})
		);
		if (success) {
			updatedMetadata.splice(updatedMetadataIndex, 1);
			// Update sequence according to new index
			const updatedSequence = updatedMetadata.map((metadata, index) => ({
				...metadata,
				sequence: index,
			}));
			setCustomFieldMetadata(updatedSequence);
		}
	};

	const handleRowOrderChange = (params: any, move: string) => {
		// index of all elements with isDefault === 1
		const defaultIndexes = customFieldMetadata
			.filter((metadata) => metadata.isDefault === 1)
			.map((metadata) => customFieldMetadata.findIndex((data) => data.fieldID === metadata.fieldID));

		// if move === "up" then move the row up
		if (move === "up") {
			const updatedMetadata = [...customFieldMetadata];
			const index = updatedMetadata.findIndex((metadata) => metadata.fieldID === params.row.fieldID);
			if (index <= 0 || defaultIndexes.includes(index - 1)) return;
			const temp = updatedMetadata[index];
			updatedMetadata[index] = updatedMetadata[index - 1];
			updatedMetadata[index - 1] = temp;
			updatedMetadata.forEach((x: any, index) => {
				x.sequence = index;
			});
			setCustomFieldMetadata(updatedMetadata);
			saveSequenceChange(updatedMetadata);
		}
		// if move === "down" then move the row down
		else if (move === "down") {
			const updatedMetadata = [...customFieldMetadata];
			const index = updatedMetadata.findIndex((metadata) => metadata.fieldID === params.row.fieldID);
			if (index > updatedMetadata.length - 1 || defaultIndexes.includes(index + 1)) return;
			const temp = updatedMetadata[index];
			updatedMetadata[index] = updatedMetadata[index + 1];
			updatedMetadata[index + 1] = temp;
			updatedMetadata.forEach((x: any, index) => {
				x.sequence = index;
			});
			setCustomFieldMetadata(updatedMetadata);
			saveSequenceChange(updatedMetadata);
		}
	};

	return (
		<div className="datagrid-table custom_datagrid_with_nonDragables">
			<DataGridPro
				sx={DG_STYLES}
				getRowId={(row) => row?.fieldID || 1}
				rows={customFieldMetadata || []}
				columns={columns}
				loading={loading}
				rowsPerPageOptions={[5, 10, 20, 50]}
				rowHeight={50}
				disableSelectionOnClick
				disableColumnFilter
				onRowClick={(params) => userWritePermission && openSidebar(params.row.fieldID)}
				disableColumnMenu
				components={{
					ColumnMenu: CustomColumnMenu,
				}}
				pagination
				getRowClassName={(params) => `custom-field-type--${params.row.isDefault}`}
			/>
		</div>
	);
};

export default CustomFieldTable;
