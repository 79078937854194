import { isValidPhoneNumber, getCountries, getCountryCallingCode } from "react-phone-number-input";

const countryCallingCodes = () => {
    const countries = getCountries();
    const countryCodes = countries.map(country => getCountryCallingCode(country));
    return countryCodes;
}

export const validCountryCallingCode = (code) => {
    const countryCodes = countryCallingCodes();
    const isValid = countryCodes.includes(code);
    return isValid;
}

export const validateContactNumber = (code, number) => {
    const phoneNumber = `+${code}${number}`;
    const isValid = isValidPhoneNumber(phoneNumber);
    return isValid;
}

export const isValidURL = (url) => {
    const urlPattern = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g;
    return urlPattern.test(url);
}

export const formatDateTo_DDMMYYYY = (date) => {
    // Attempt to parse the date with "DD-MM-YYYY" format
    const momentDDMMYYYY = moment(date, "DD-MM-YYYY", true);

    // Attempt to parse the date with "YYYY-MM-DD" format
    const momentYYYYMMDD = moment(date, "YYYY-MM-DD", true);

    // Attempt to parse the date with "MM-DD-YYYY" format
    const momentMMDDYYYY = moment(date, "MM-DD-YYYY", true);

    // Attempt to parse the date with "DD/MM/YYYY" format
    const momentDDMMYYYYSlash = moment(date, "DD/MM/YYYY", true);

    // Attempt to parse the date with "DD/MM/YY" format
    const momentDDMMYYSlash = moment(date, "DD/MM/YY", true);

    if (momentDDMMYYYY.isValid()) {
        // The date is in "DD-MM-YYYY" format
        return momentDDMMYYYY.format("DD-MM-YYYY");
    } else if (momentYYYYMMDD.isValid()) {
        // The date is in "YYYY-MM-DD" format
        return momentYYYYMMDD.format("DD-MM-YYYY");
    } else if (momentMMDDYYYY.isValid()) {
        // The date is in "MM-DD-YYYY" format
        return momentMMDDYYYY.format("DD-MM-YYYY");
    } else if (momentDDMMYYYYSlash.isValid()) {
        // The date is in "MM-DD-YYYY" format
        return momentMMDDYYYY.format("DD-MM-YYYY");
    } else if (momentDDMMYYSlash.isValid()) {
        // The date is in "MM-DD-YYYY" format
        return momentMMDDYYYY.format("DD-MM-YYYY");
    } else {
        const utc_days = Math.floor(date - 25569);
        const utc_value = utc_days * 86400;
        const date_info = new Date(utc_value * 1000);

        const fractional_day = date - Math.floor(date) + 0.0000001;

        let total_seconds = Math.floor(86400 * fractional_day);

        const seconds = total_seconds % 60;

        total_seconds -= seconds;

        const hours = Math.floor(total_seconds / (60 * 60));
        const minutes = Math.floor(total_seconds / 60) % 60;

        const serializedDate = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);

        // return date in format DD-MM-YYYY using moment
        const momentFormattedDate = moment(serializedDate);

        if (momentFormattedDate.isValid()) {
            return momentFormattedDate.format("DD-MM-YYYY");
        } 
        else  if(moment(date).isValid()){
            return moment(date).format("DD-MM-YYYY");
        }
        else {
            return "Invalid date"
        }
    }
};