export const LEAD_OWNERS_TYPE = {
	default: 1,
	oneOwner: 2,
	multipleOwner: 3,
};

export const CRM_AUTH_STATUS = {
	failure: null,
	success: 1,
};
