import { GridColDef, GridSelectionModel, GridValueGetterParams } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import "moment-timezone";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { ACCESS_CONTROL_USER, ADMINS_DATA, SAMPLE_DATA, USER_ROLES } from "../../../@types";
import { saveAdminHierarchyNew } from "../../../api/access-control";
import { DG_STYLES } from "../../../constants";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { convertDataSourceToOrgChartDataSource } from "../../../utils";
import ColumnEmployee from "../../common/datagrid/ColumnEmployee";
import CustomColumnMenu from "../../common/datagrid/CustomColumnMenu";
import ActionButtons from "./ActionButtons";
import { orgFormStatus } from "../../../constants/organizationConstant";
import { Box } from "@mui/material";

const RoleBadge: FC<{ role: string }> = ({ role }) => <span className={"role_badge " + role}>{role}</span>;

type Props = {
	// eslint-disable-next-line no-unused-vars
	openSidebarEditForm: (id: number) => void;
	filteredAdmins: ACCESS_CONTROL_USER[];
	allAdmins: ADMINS_DATA[];
	setUsers: Dispatch<SetStateAction<ACCESS_CONTROL_USER[]>>;
	setSelectedRows: Dispatch<SetStateAction<GridSelectionModel>>;
	selectedRows: GridSelectionModel;
	rolesData: USER_ROLES[];
	loading: boolean;
	allExecutives: any[];
	pullAdminsRefetch: Function;
	userWritePermission: boolean;
	setColumns: Dispatch<SetStateAction<GridColDef[]>>;
};
const AccessControlAdminTable: FC<Props> = ({
	openSidebarEditForm,
	filteredAdmins,
	setSelectedRows,
	selectedRows,
	rolesData,
	allExecutives,
	loading,
	setUsers,
	pullAdminsRefetch,
	allAdmins,
	userWritePermission,
	setColumns,
}) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const [pageSize, setPageSize] = useState(20);
	const [isDeleting, setIsDeleting] = useState(false);
	const tempColumns = useMemo(
		() => [
			{
				field: "employee",
				headerName: "Admin Name",
				flex: 1,
				minWidth: 200,
				renderCell: (params: GridValueGetterParams) => (
					<ColumnEmployee
						name={params.row.firstname + " " + params.row.lastname}
						emailID={params.row.email}
						avatar={params.row.avatar}
					/>
				),
			},
			{
				field: "internalAdminID",
				headerName: "Admin ID",
				flex: 1,
				minWidth: 200,
				renderCell: (params: GridValueGetterParams) => params.row.internalAdminID ? params.row.internalAdminID : "-",
			},
			{
				field: "role",
				headerName: "Role",
				flex: 1,
				minWidth: 200,
				renderCell: (params: GridValueGetterParams) => {
					const role = rolesData?.find((role: USER_ROLES) => role.roleID === params.row.roleID);
					return <RoleBadge role={role?.roleName || "-"} />;
				},
			},
			{
				field: "execVisibilityTo",
				headerName: "Employee Visibility",
				flex: 1,
				minWidth: 200,
				renderCell: (params: GridValueGetterParams) => {
					const execVisibilityTo = params.row.execVisibilities[0] === 1
						? null  // Set to null for the "All" case
						: params.row.execVisibilities.map((executive: number) => allAdmins.find((user: ADMINS_DATA) => executive === user.adminID))

					const execVisibilityAll = params.row.execVisibilities[0] === 1 ? "All" : "No admin found";

					const execVisibilityToName = execVisibilityTo
						? execVisibilityTo?.map((exective: any) => exective?.firstname + " " + exective?.lastname).join(" ,")
						: execVisibilityAll;

					return (
						<Box
							sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
						>
							{execVisibilityToName}
						</Box>
					);;
				},
			},
			{
				field: "reportingManager",
				headerName: "Reports To",
				minWidth: 200,
				flex: 1,
			},
			{
				field: "directReportee",
				headerName: "Direct Reportees",
				flex: 1,
				minWidth: 140,
			},
			{
				field: "totalReportee",
				headerName: "Total Reportees",
				flex: 1,
				minWidth: 140,
			},
			{
				field: "action",
				headerName: "Action",
				width: 120,
				renderCell: (params: GridValueGetterParams) => (
					<ActionButtons
						handleUserId={() => openSidebarEditForm(params.row.id)}
						name={params.row.firstname + " " + params.row.lastname}
						deleteHandler={() => deleteAdmins(params.row.id)}
						// checks if the user you are deleting is a Root Admin or not
						canBeDeleted={filteredAdmins.find((user) => user.id === params.row.id)?.parentId !== null}
						userWritePermission={userWritePermission}
					/>
				),
			},
		],
		[filteredAdmins]
	);

	const deleteAdmins = async (id: number) => {
		if (isNaN(id)) return;
		const orgData = convertDataSourceToOrgChartDataSource(filteredAdmins);
		const extraParams = {
			email: null,
			action: orgFormStatus.DELETE,
		};
		setIsDeleting(true);
		const { message, success } = await saveAdminHierarchyNew(orgData as SAMPLE_DATA, [id], allExecutives, extraParams);
		setIsDeleting(false);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (success) {
			await setUsers((e) => e.filter((i) => i.id !== id));
			await pullAdminsRefetch();
		}
	};

	useEffect(() => {
		if (tempColumns) {
			setColumns(tempColumns);
		}
	}, []);

	return (
		<div className="datagrid-table">
			<DataGridPro
				sx={DG_STYLES}
				rows={filteredAdmins}
				columns={tempColumns}
				loading={loading || isDeleting}
				pageSize={pageSize}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[5, 10, 20, 50]}
				rowHeight={70}
				checkboxSelection
				disableSelectionOnClick
				disableColumnFilter
				onRowClick={(params: any) => userWritePermission && openSidebarEditForm(params.id)}
				selectionModel={selectedRows}
				onSelectionModelChange={(selectionModel) => {
					const elements = selectionModel.filter((each) => {
						const data = filteredAdmins.find((user) => user.id === each)?.parentId !== null;
						if (data) return each;
					});
					setSelectedRows(elements);
				}}
				components={{
					ColumnMenu: CustomColumnMenu,
				}}
				pagination
			/>
		</div>
	);
};

export default AccessControlAdminTable;