import { gql } from "@apollo/client";

export const GET_SKUS = gql`
	query GetSKUs {
		get_skus {
			skuID
			skuName
			skuDescription
			companyID
			internalSkuID
			category
			listPrice
			gstRate
			discRate
			profileID
			active
			skuImage1
			skuImage2
			skuImage3
			customFieldsComplex {
				fieldID
				fieldValue
				fieldValue2
				fieldValue3
				selectedOptions
				fieldName
				selectedOptionsValues
				selectedFieldValues {
					fieldValue
					fieldValue2
				}
				selectedOptions
				fieldName
				selectedOptionsValues
			}
			showDiscAmt
			showGstRate
			lastModifiedTs
			createdTs
			visibility {
				type
				value
			}
		}
	}
`;
export const DELETE_SKUS = gql`
	mutation DeleteSkus($skuIDs: [ID!]!) {
		delete_skus(skuIDs: $skuIDs) {
			rowsDeleted
		}
	}
`;

export const UPSERT_SKUS = gql`
	mutation UpsertSkus($input: [SKUInput!]!) {
		upsert_skus(input: $input) {
			failed
		}
	}
`;

export const UPSERT_SKUS_EXCEL = gql`
	mutation UpsertSkus($input: [SKUInput!]!, $uploadFlag: Boolean) {
		upsert_skus(input: $input, uploadFlag: $uploadFlag) {
			failed
		}
	}
`;

export const GET_DASHBOARD_DATA = gql`
	query getDashboardData(
		$startDate: Date!
		$endDate: Date!
		$limitToCount: Int!
		$groupByKeys: [GroupByKey]!
		$sortByKeys: [SortByKey]
		$orderBy: [OrderBy]
		$customFields: [String!]
		$excludeNotConsidered: Int
	) {
		get_orders_dashboard_data(
			startDate: $startDate
			endDate: $endDate
			limitToCount: $limitToCount
			groupByKeys: $groupByKeys
			sortByKeys: $sortByKeys
			orderBy: $orderBy
			customFields: $customFields
			excludeNotConsidered: $excludeNotConsidered
		) {
			quantity
			value
			groupByKeyComb
			avgPrice
			avgDiscountValue
			avgDiscountPercentage
			orderCount
		}
	}
`;

export const GET_TOP_DATA = gql`
	query getTopData($startDate: Date!, $endDate: Date!) {
		get_orders_dashboard_topstrip_data(startDate: $startDate, endDate: $endDate) {
			quantity
			value
			avgPrice
			avgDiscountValue
			avgDiscountPercentage
		}
	}
`;

export const GET_ORDERS_BY_DATE_RANGE = gql`
	query get_orders_by_date_range($startDate: Date!, $endDate: Date!) {
		get_orders_by_date_range(startDate: $startDate, endDate: $endDate) {
			orderID
			invoiceNumber
			taskID
			clientID
			lat
			lon
			client {
				clientName
			}
			userInfo {
				employeeID
				firstName
				lastName
				profileName
			}
			recipients {
				email
			}
			processingDate
			orderItems {
				skuID
				sku {
					skuID
					profileID
					companyID
					skuName
					skuDescription
					internalSkuID
					active
					category
					listPrice
					gstRate
					discRate
					skuImage1
					skuImage2
					skuImage3
					lastModifiedTs
					createdTs
				}
				productDescription
				quantity
				price
				discAmt
				amount
				totalTaxAmount
			}
			orderValue
			taxValue
			payments {
				amount
				paymentType
				orderPaymentID
			}
			paymentCollected
			customFieldsComplex {
				fieldID
				fieldValue
				fieldValue2
				fieldValue3
				selectedOptions
				fieldName
				selectedOptionsValues
				selectedFieldValues {
					fieldValue
					fieldValue2
				}
			}
			createdTs
			lastModifiedTs
			createdByEmpID
			createdByAdminID
			lastModifiedByEmpID
			lastModifiedByAdminID
			invoiceCommunicationFlag
			otpVerified
		}
	}
`;

export const UPSERT_ORDER_BATCH = gql`
	mutation upsert_order_batch($input: [OrderInput!]!) {
		upsert_order_batch(input: $input) {
			failed
		}
	}
`;

export const GET_ORDER_DIFF = gql`
	query get_order_diff($orderID: ID!) {
		get_order_diff(orderID: $orderID) {
			lastModifiedTs
			lastModifiedBy
			diff {
				simple {
					updated {
						key
						from
						to
					}
					added {
						key
						value
					}
					removed {
						key
						value
					}
				}
				complex {
					key
					added {
						key
						value
					}
					updated {
						key
						type
						added {
							key
							value
						}
						removed {
							key
							value
						}
						updated {
							key
							from
							to
						}
					}
					removed {
						key
						value
					}
				}
			}
		}
	}
`;

export const GET_ORDER_AUDIT_VERSION = gql`
	query get_order_audit_version($orderID: ID!, $lastModifiedTs: String) {
		get_order_audit_version(orderID: $orderID, lastModifiedTs: $lastModifiedTs) {
			Order
		}
	}
`;

export const GET_ORDER_BY_ID = gql`
	query get_order_by_id($orderID: ID!) {
		get_order(orderID: $orderID) {
			orderID
			invoiceNumber
			processingDate
		}
	}
`;
