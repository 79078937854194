import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Dispatch, FC, FormEvent, SetStateAction } from "react";
import { ACCESS_CONTROL_USER, ADMIN_FILTER_COLUMNS, USER_ROLES } from "../../../@types";
import IndividualFilter from "../../common/datagrid/IndividualFilter";
import "moment-timezone";
type COLUMN_TITLES = "Admin Name" | "Role" | "Employee Visibility" | "Reports To" | "Employee";
type Props = {
	numFilters: number;
	setFilterOpen: Dispatch<SetStateAction<boolean>>;
	users: ACCESS_CONTROL_USER[];
	filters: Record<ADMIN_FILTER_COLUMNS, string[]>;
	setFilters: Dispatch<SetStateAction<Record<ADMIN_FILTER_COLUMNS, string[]>>>;
	roles: USER_ROLES[];
};
const FilterAdmins: FC<Props> = ({ numFilters, setFilterOpen, users, setFilters, filters, roles }) => {
	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
	};

	const resetFilters = () => {
		setFilters({
			internalAdminID: [],
			name: [],
			roleName: [],
			execVisibilities: [],
			executiveName: [],
			parentId: [],
			employees: [],
			reportingManager: [],
		});
	};
	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
		<form onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()} onKeyPress={(e) => e.stopPropagation()}>
			<div className="filter__header">
				<h5>
					<FilterAltOutlinedIcon />
					Filters
					<span className="right-notification center" style={{ display: "flex", padding: 0 }}>
						{numFilters}
					</span>
				</h5>
				<button onClick={() => setFilterOpen(false)}>
					<CloseSharpIcon htmlColor="#42444A" />
				</button>
			</div>
			<div className="accordion__group">
				<IndividualFilter<COLUMN_TITLES, ADMIN_FILTER_COLUMNS>
					filters={filters}
					name="name"
					title="Admin Name"
					data={users.map((user) => user.firstname + " " + user.lastname)}
					ids={users.map((user) => user.firstname + " " + user.lastname)}
					setFilters={setFilters}
				/>
				<IndividualFilter<COLUMN_TITLES, ADMIN_FILTER_COLUMNS>
					filters={filters}
					name="roleName"
					title="Role"
					data={roles.map((role) => role.roleName)}
					ids={roles.map((role) => role.roleID)}
					setFilters={setFilters}
				/>
				<IndividualFilter<COLUMN_TITLES, ADMIN_FILTER_COLUMNS>
					filters={filters}
					name="reportingManager"
					title="Reports To"
					data={users.map((user) => {
						if (user.reportingManager === "-") {
							return;
						}
						return user.reportingManager;
					})}
					ids={users.map((user) => {
						if (user.reportingManager === "-") {
							return;
						}
						return user.reportingManager;
					})}
					setFilters={setFilters}
				/>
				<IndividualFilter<COLUMN_TITLES, ADMIN_FILTER_COLUMNS>
					filters={filters}
					name="executiveName"
					title="Employee Visibility"
					data={users.map((user) => user.firstname + " " + user.lastname)}
					ids={users.map((user) => user.firstname + " " + user.lastname)}
					setFilters={setFilters}
				/>
			</div>
			<div className="filter__buttons">
				<button onClick={resetFilters} disabled={numFilters === 0} type="reset">
					Reset
				</button>
			</div>
		</form>
	);
};

export default FilterAdmins;
