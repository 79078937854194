import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { upsertRosterTemplate } from "../../../api/attendance";
import { APP_DISPATCH, showNotification } from "../../../redux";
import TimePicker from "../../common/TimePicker";
import dayjs from "dayjs";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
		minWidth: "400px",
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle
			sx={{
				m: 0,
				padding: "0.5rem 1rem",
				fontFamily: "SFUIText-Semibold, Helvetica, Arial, sans-serif",
				fontSize: "16px",
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
			}}
			{...other}>
			{children}
			<IconButton aria-label="close" onClick={onClose}>
				<CloseIcon />
			</IconButton>
		</DialogTitle>
	);
};
type Props = {
	handleClose: () => void;
	// eslint-disable-next-line no-unused-vars
	upsertRoster: (roster: any) => void;
	rosterSelectedData: any | null;
	rostersTemplateData: any;
};

const getRandomColor = () => {
	const letters = "0123456789ABCDEF";
	let color = "#";
	for (let i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
};

const RostersCreateModal: FC<Props> = ({ rosterSelectedData, handleClose, upsertRoster, rostersTemplateData }) => {
	const [rosterName, setRosterName] = useState(rosterSelectedData ? rosterSelectedData["name"] : "");
	const [startTime, setStartTime] = useState(rosterSelectedData ? rosterSelectedData["start"] : "10:00");
	const [endTime, setEndTime] = useState(rosterSelectedData ? rosterSelectedData["end"] : "18:00");
	const dispatch = useDispatch<APP_DISPATCH>();
	const [error, setError] = useState<null | string>(null);

	const onSave = async (e: { preventDefault: () => void }) => {
		e.preventDefault();
		if (rosterName.trim() === "") {
			setError("Roster Name is required");
			return;
		} else if (!rosterSelectedData && isDuplicateRoster) {
			setError("Roster Name already exists");
			return;
		} else if (startTime === undefined || startTime === "Invalid Date") {
			dispatch(showNotification({ message: "Enter valid start time", severity: "error" }));
			return;
		} else if (endTime === undefined || endTime === "Invalid Date") {
			dispatch(showNotification({ message: "Enter valid end time", severity: "error" }));
			return;
		} else {
			setError(null);
		}
		const sendData = { name: rosterName.trim(), startTime: startTime, endTime: endTime };
		if (rosterSelectedData) {
			sendData["rosterTemplateID"] = rosterSelectedData["rosterTemplateID"];
		}
		const { data, success, message } = await upsertRosterTemplate(sendData);
		if (success) {
			const roster = rosterSelectedData
				? { ...rosterSelectedData, name: rosterName, start: startTime, end: endTime }
				: { rosterTemplateID: data.id, name: rosterName, start: startTime, end: endTime, color: getRandomColor() };
			upsertRoster(roster);
			handleClose();
		}
		dispatch(showNotification({ message: message, severity: success ? "success" : "error" }));
	};

	const isDuplicateRoster = rostersTemplateData.find(
		(roster: any) => roster.name.replace(/ /g, "").toLowerCase() === rosterName.replace(/ /g, "").toLowerCase()
	);

	return (
		<BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
			<BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
				Add Roster Title
			</BootstrapDialogTitle>
			<DialogContent dividers>
				<TextField
					sx={{
						width: "100%",
						".MuiInputLabel-root": {
							fontSize: "14px",
							color: "#696969",
							fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
							fontWeight: 400,
						},
						".MuiInputBase-input": {
							fontSize: "14px",
							fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
							fontWeight: 400,
						},
					}}
					id="outlined-basic"
					label="Enter Roster Name"
					variant="outlined"
					value={rosterName}
					onChange={(e) => {
						setRosterName(e.target.value);
					}}
					error={error ? rosterName.trim() === "" || (!rosterSelectedData && isDuplicateRoster) : false}
					helperText={
						error
							? rosterName.trim() === ""
								? "Shift Name is required"
								: isDuplicateRoster
								? "Shift Name already exists"
								: ""
							: ""
					}
				/>
				<form>
					<TimePicker
						id="startTime"
						label="Start Time"
						sx={{
							marginTop: "2rem",
							width: "100%",
							".MuiInputLabel-root": {
								fontSize: "14px",
								color: "#696969",
								fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
								fontWeight: 400,
							},
							".MuiInputBase-input": {
								fontSize: "14px",
								fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
								fontWeight: 400,
							},
						}}
						onChange={(value: string) => {
							setStartTime(dayjs(value).format("HH:mm"));
						}}
						value={dayjs()
							.startOf("day")
							.add(startTime?.split(":")[0], "hours")
							.add(startTime?.split(":")[1], "minutes")}
					/>
					<TimePicker
						id="endTime"
						label="End Time"
						sx={{
							marginTop: "2rem",
							width: "100%",
							".MuiInputLabel-root": {
								fontSize: "14px",
								color: "#696969",
								fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
								fontWeight: 400,
							},
							".MuiInputBase-input": {
								fontSize: "14px",
								fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
								fontWeight: 400,
							},
						}}
						onChange={(value: string) => {
							setEndTime(dayjs(value).format("HH:mm"));
						}}
						value={dayjs().startOf("day").add(endTime?.split(":")[0], "hours").add(endTime?.split(":")[1], "minutes")}
					/>
				</form>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={onSave}
					sx={{
						backgroundColor: "#0F45FF",
						fontSize: "12px",
						fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
						fontWeight: 600,
					}}>
					Save
				</Button>
			</DialogActions>
		</BootstrapDialog>
	);
};
export default RostersCreateModal;
