import axios from "../../utils/axios";
import { ATTENDANCE_REQUESTS_DATA, LEAVE_TYPE } from "../../@types";
axios.defaults.withCredentials = true;

type ATTENDANCE_STATUS = {
	[key: number]: {
		[key: string]: {
			from: string;
			to: string;
		};
	};
};

type PUBLIC_HOLIDAYS = {
	date: string | Date;
	description: string;
};

type SHIFT_TEMPLATE_DATA = {
	name: string;
	startTime: string;
	endTime: string;
	shiftTemplateID?: number;
};

type ROSTER_TEMPLATE_DATA = {
	name: string;
	startTime: string;
	endTime: string;
	rosterTemplateID?: number;
};

const updateAttendanceStatus = async (body: ATTENDANCE_STATUS): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/attendance/attendanceRegularize2`, {
			data: JSON.stringify(body),
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Changed Attendance Status",
		};
	} catch (error: any) {
		const errorText =
			typeof error.response.data.error === "string"
				? error.response.data.error
				: error?.message || "Error in changed attendance status";
		return { success: false, message: errorText };
	}
};

const updateAttendanceStatusV3 = async (body: ATTENDANCE_STATUS): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/attendance/attendanceRegularize3`, {
			data: JSON.stringify(body),
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Changed Attendance Status",
		};
	} catch (error: any) {
		const errorText =
			typeof error.response.data.error === "string"
				? error.response.data.error
				: error?.message || "Error in changed attendance status";
		return { success: false, message: errorText };
	}
};

const upsertPublicHolidays = async (body: PUBLIC_HOLIDAYS[]): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/publicHolidays/upsertPublicHolidays`, {
			publicHolidays: body,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Upserted Public holidays",
		};
	} catch (error: any) {
		const errorText =
			typeof error.response.data.error === "string"
				? error.response.data.error
				: error?.message || "Error in upserting public holidays";
		return { success: false, message: errorText };
	}
};

const deletePublicHolidays = async (body: string[]): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/publicHolidays/deletePublicHolidays`, {
			holidayIDs: body,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully deleted Public holidays",
		};
	} catch (error: any) {
		const errorText =
			typeof error.response.data.error === "string"
				? error.response.data.error
				: error?.message || "Error in deleting public holidays";
		return { success: false, message: errorText };
	}
};

const approveRejectLeave = async (leaves: LEAVE_TYPE[]): Promise<{ success: boolean; message: string }> => {
	const dataToChange = leaves.map((l) => ({
		empID: l.empID,
		approved: l.approved,
		reason: l.rejectionReason || "",
		dates: JSON.stringify(l.dates),
	}));
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/leaves/lmsApprove/v2`, {
			leaveRequests: dataToChange,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Approved/Rejected Leaves",
		};
	} catch (error: any) {
		const errorText =
			typeof error.response.data.error === "string"
				? error.response.data.error
				: error?.message || "Error in updating leaves";
		return { success: false, message: errorText };
	}
};

export const changeAttendanceRequestV4 = async (
	request: ATTENDANCE_REQUESTS_DATA[]
): Promise<{ success: boolean; message: string }> => {
	const changeRequests = request.map((r) => ({
		overrideReqID: r.overrideReqID,
		employeeID: r.employeeID,
		date: r.date,
		state: r.state,
		fromEmail: false,
		to: r.to,
		from: r.from,
		from1: r.from1,
		to1: r.to1,
		from2: r.from2,
		to2: r.to2,
		reason: r.reason,
		authorizationInput: {
			rejectReason: r.rejectReason,
			comment: r.comment,
		},
	}));
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/attendance/changeAttendanceRequestV4`, {
			changeRequests,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Approved/Rejected Override request",
		};
	} catch (error: any) {
		const errorText =
			typeof error.response.data.error === "string"
				? error.response.data.error
				: error?.message || "Error in updating  Override request";
		return { success: false, message: errorText };
	}
};

const upsertShiftTemplate = async (
	shiftTemplate: SHIFT_TEMPLATE_DATA
): Promise<{ data: any; success: boolean; message: string }> => {
	try {
		const res = await axios({
			method: "post",
			data: shiftTemplate,
			url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/shifts/upsertShiftTemplate`,
		});
		return {
			data: res?.data,
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully created new shift template",
		};
	} catch (error: any) {
		const errorText = "Error in creating new shift template";
		return { data: null, success: false, message: errorText };
	}
};

const deleteShiftTemplate = async (shiftTemplateID: string): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios({
			method: "delete",
			url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/shifts/shiftDefinitions/${shiftTemplateID}`,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully deleted shift template",
		};
	} catch (error: any) {
		const errorText = "Error in deleting shift template";
		return { success: false, message: errorText };
	}
};

const saveUserShifts = async (shiftsData: any, msg: string): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios({
			method: "post",
			data: { shifts: shiftsData },
			url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/shifts/shiftDefinitions`,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || msg,
		};
	} catch (error: any) {
		const errorText = "Error in assigning shifts";
		return { success: false, message: errorText };
	}
};

const upsertRosterTemplate = async (
	rosterTemplate: ROSTER_TEMPLATE_DATA
): Promise<{ data: any; success: boolean; message: string }> => {
	try {
		const res = await axios({
			method: "post",
			data: rosterTemplate,
			url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/rosters/upsertRosterTemplate`,
		});
		return {
			data: res?.data,
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully created/updated new roster template",
		};
	} catch (error: any) {
		const errorText = "Error in creating/updating new roster template";
		return { data: null, success: false, message: errorText };
	}
};

const deleteRosterTemplate = async (rosterTemplateID: string): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios({
			method: "delete",
			url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/rosters/roster/${rosterTemplateID}`,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully deleted roster template",
		};
	} catch (error: any) {
		const errorText = "Error in deleting roster template";
		return { success: false, message: errorText };
	}
};

const saveUserRosters = async (rostersData: any, msg: string): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios({
			method: "post",
			data: { rosters: rostersData },
			url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/rosters/roster`,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || msg,
		};
	} catch (error: any) {
		const errorText = "Error in assigning rosters";
		return { success: false, message: errorText };
	}
};

export {
	updateAttendanceStatus,
	updateAttendanceStatusV3,
	upsertPublicHolidays,
	deletePublicHolidays,
	approveRejectLeave,
	upsertShiftTemplate,
	saveUserShifts,
	upsertRosterTemplate,
	deleteShiftTemplate,
	deleteRosterTemplate,
	saveUserRosters,
};
