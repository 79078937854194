import React, { FC } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialog-paper": {
        maxWidth: "350px",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

type Props = {
    handleClose: () => void;
    phoneNumbers: string[]; // Array of phone numbers
};

const PhoneInfoDialog: FC<Props> = ({ handleClose, phoneNumbers }) => (
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "16px" }}>
            <p style={{ textAlign: "center", margin: "0.5rem 0 1.5rem 0", fontSize: "14px" }}>
                For assistance, please contact our helpline at:
            </p>
            {phoneNumbers.map((phoneNumber, index) => (
                <div key={index} style={{ marginBottom: "1rem" }}>
                    <strong style={{ display: "block" }}>{phoneNumber}</strong>

                </div>
            ))}
            <Button variant="contained" onClick={handleClose} color="primary" style={{textTransform: "capitalize"}}>
                Close
            </Button>
        </div>
    </BootstrapDialog>
);

export default PhoneInfoDialog;
