/**
 * excel utility import
 * todo: make seperate componenent for excel util
 */
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import _ from "lodash";
import { Dispatch, FC, SetStateAction } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#D3DFFD",
		color: "#0500FF",
		fontWeight: "700",
		fontSize: "1rem",
		textAlign: "center",
		borderRight: "1px solid #C4C4C4",
		padding: "1rem 8px",
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 15,
		padding: "2rem 1rem",
		color: "#7a1b1b",
		borderRight: "1px solid #C4C4C4",
	},
}));

const StyledTableRow = styled(TableRow)(() => ({
	verticalAlign: "unset",
}));

type VALIDATION_ERRORS = {
	columnName: string;
	issue: string;
	rowsNumber: number;
};

type Props = {
	errors: VALIDATION_ERRORS[];
	open: boolean;
	setBulkUploadOpen: Dispatch<SetStateAction<boolean>>;
	setErrors: Dispatch<SetStateAction<VALIDATION_ERRORS[]>>;
};

const ErrorShowModal: FC<Props> = ({ errors, open, setBulkUploadOpen, setErrors }) => {
	const handleClose = () => {
		setErrors([]);
		setBulkUploadOpen(false);
	};
 
	let orderedErrors = _.sortBy(errors, function (o) {
		return o.rowsNumber;
	});

	return (
		<>
			<Dialog
				style={{ maxWidth: "700px !important" }}
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}>
				<DialogTitle style={{ color: "#3f51b5 !important" }} id="customized-dialog-title">
					Errors in excel file
				</DialogTitle>
				<DialogContent style={{ minWidth: "500px" }} dividers>
					<TableContainer component={Paper}>
						<Table aria-label="customized table">
							<TableHead>
								<StyledTableRow>
									<StyledTableCell>Rows</StyledTableCell>
									<StyledTableCell align="right">Column</StyledTableCell>
									<StyledTableCell align="right">Issue</StyledTableCell>
								</StyledTableRow>
							</TableHead>
							<TableBody>
								{orderedErrors.map((row) => (
									<StyledTableRow key={row.columnName}>
										<StyledTableCell align="center">{row.rowsNumber}</StyledTableCell>
										<StyledTableCell align="center" component="th" scope="row">
											{row.columnName}
										</StyledTableCell>
										<StyledTableCell align="center">{row.issue}</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ErrorShowModal;
