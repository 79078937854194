import { GridValueGetterParams } from "@mui/x-data-grid-pro";
import { Key, ReactNode } from "react";
import { z } from "zod";
export type SUBMODULE_DATA =
	| {
			readonly label: string;
			readonly icon: JSX.Element;
			readonly path: `/${string}`;
			hide?: boolean;
	  }
	| undefined;
export const ROUTES = Object.freeze({
	HOME: "/",
	CRM: "/crm",
	PAYMENT: "/payment",
	LOGIN: "/login",
} as const);

export type SNACKBAR_SEVERITY_TYPE = "success" | "error" | "warning" | "info";
export type SNACKBAR_TYPE = {
	open: boolean;
	message: string;
	severity: SNACKBAR_SEVERITY_TYPE;
	duration: number;
};

export type ACCESS_CONTROL_USER = {
	internalAdminID: null | string;
	email: string;
	employees: ACCESS_CONTROL_USER[];
	execVisibilities: [
		{ type: "EVERYONE"; value: string } | { type: "SELF"; value?: string } | { type: "ADMIN"; value: string } | number
	];
	executiveName: string[];
	firstname: string;
	id: number | null;
	lastname: string;
	parentId: null | number;
	reportingManager?: string;
	phoneNumber: string;
	roleID: string;
	title: string;
	updated?: true;
	new?: true;
	adminID?: number | null;
};
export type ADMIN_FILTER_COLUMNS =
	| "name"
	| "roleName"
	| "internalAdminID"
	| "execVisibilities"
	| "executiveName"
	| "parentId"
	| "employees"
	| "reportingManager";
export type PERMISSION_CATEGORY = {
	moduleAlias: string;
	moduleName: string;
	functionalities: SPECIFIC_PERMISSION[];
};
export type SPECIFIC_PERMISSION = {
	modFuncAlias: string;
	modFuncID: number;
	modFuncName: string;
	permissionID: string;
	permissions: { read: 1 | 0; write: 0 | 1 };
};
export type PERMISSION_PAYLOAD = {
	operation: "write" | "read";
	operationValue: 1 | 0;
	permissionID: string;
	modFuncID: number;
};

export type ATTENDANCE_STATS_DATA = {
	label: string;
	count: string;
	iconColor: string;
	iconBackgroundColor: string;
	cardBackgroundColor: string;
	countColor: string;
	labelColor: string;
};

export type EXECUTIVE_USER = {
	employeeID: number;
	companyID: number;
	profileID: number;
	parentAdminID: number;
	userID: string;
	firstName: string;
	lastName: string;
	emailID: string;
	mobileNumber: string;
	city: string;
	geofences: number | null;
	geofencePool: null | number;
	imgUrl: string;
	BaseGeoFenceID: null | string;
	attendanceOutRestrictionToSiteID: string;
	attendanceOutRestrictionToSitePoolID: null | string;
	attendanceRestrictionToSiteID: null | string;
	attendanceRestrictionToSitePoolID: null | string;
	autoTaskSitePoolID: null | number;
	baseLocationID: null | number;
	designation: null | string;
	designationID: number | null;
	homeGeofence: null | number;
	homeLocationID: null | number;
	internalEmpID: string;
	isTest: number;
	manufacturer: null | string;
	reportingManager?: string;
	modelNumber: null | string;
	sitePoolID: null | string;
	defaultTransportMode: 0 | 1;
	active: number;
	joiningDate: string;
	team: string;
	productID: null | string;
	profilePicture: null | File;
	profilePictureExtension: null | string;
	rekognitionFaceID: null | string;
};
export type EXECUTIVE_FILTER_COLUMN =
	| "reportingManager"
	| "designationID"
	| "designation"
	| "employeeID"
	| "mobileNumber"
	| "profileID"
	| "city"
	| "userID"
	| "modelNumber"
	| "team"
	| "status";

export type ATTENDANCE_FILTER_COLUMN =
	| "employeeName"
	| "siteName"
	| "address"
	| "eventTypeID"
	| "processingDate"
	| "timestamp";

export type ADMINS_DATA = {
	adminID: number;
	firstname: string;
	lastname: string;
};
export type DESIGNATIONS_DATA = {
	designationID: number;
	name: string;
};
export type BASE_GEOFENCES_DATA = {
	clientID: string;
	clientName: string;
};
export type PROFILES_DATA = {
	profileID: number;
	profileName: string;
};
export type GEOFENCE_DATA = {
	companyID: number;
	geofenceID: number;
	name: string;
	polyline: string;
};
export type SITEPOOLS_DATA = {
	companyID: number;
	insertTime: string;
	name: string;
	sitePoolID: string;
};
export type GEOFENCE_POOLS_DATA = {
	companyID: number;
	geofencePoolID: string;
	insertTime: string;
	name: string;
};
export type USER_ROLES = {
	roleID: string;
	roleName: string;
	roleDescription: string;
	permissions: string;
	createdTs: string;
};
export interface SAMPLE_DATA extends ACCESS_CONTROL_USER {
	role_box_id: string;
	children?: SAMPLE_DATA[];
	reportingManager: string;
	adminID: number;
	directReportee: number;
	totalReportee: number;
}
export type BLOCKS_DATA = {
	id: string;
	data: { label: JSX.Element };
	position: { x: number; y: number };
	targetPosition: "top";
	sourcePosition: "bottom";
};
export type PATH_DATA = {
	id: string;
	source: string;
	target: string;
	type: "smoothstep";
	animated: boolean;
};

type ATTENDANCE_STATUS_ROWS_DYANAMIC = {
	[key: string]: string;
};

export type ATTENDANCE_STATUS_ROWS = ATTENDANCE_STATUS_ROWS_DYANAMIC & {
	employeeID: string;
	id: number;
	name: string;
	totalDays: number;
	totalDaysAbsent: number;
	totalDaysHalfDay: number;
	totalDaysNotWorking: number;
	totalDaysPenalty: number;
	totalDaysPresent: number;
};

export type ATTENDANCE_STATUS_COLUMNS = {
	field: string;
	flex: number;
	headerName: string;
	minWidth: number;
	// eslint-disable-next-line no-unused-vars
	renderCell?: (params: GridValueGetterParams) => JSX.Element;
	editable?: boolean;
	cellClassName?: string;
	sortable?: boolean;
};

export type PUBLIC_HOLIDAYS = {
	holidayID: string;
	companyID?: number;
	date: string | Date;
	description: string;
	visibility:
		| [
				{
					type: "EVERYONE" | "PROFILE";
					value: number;
				}
		  ]
		| [];
};

export type ATTENDANCE_REQUESTS_DATA = {
	employeeID: number;
	firstName: string;
	lastName: string;
	date: string;
	formattedDate: string;
	authHistory: any;
	state: string | number;
	reason: string;
	insertTime: string;
	rejectReason: string | null;
	adminID: number | null;
	from: number;
	to: number;
	fromString: string;
	fromString1: string;
	fromString2: string;
	toString: string;
	toString1: string;
	toString2: string;
	id: number;
	from1?: number;
	to1?: number;
	from2?: number;
	to2?: number;
	session: string;
	status: string;
	comment: string;
	overrideReqID: string;
	authorizationInput?: object;
	pendingAdmin: string;
	submissionDate: string;
	profileName: string;
};
export type ATTENDANCE_REQUEST_COLUMNS =
	| "name"
	| "formattedDate"
	| "from"
	| "to"
	| "pendingWith"
	| "state"
	| "submissionDate"
	| "profileName";

export type USER_SHIFT_TYPE = {
	firstName: string;
	employeeID: `${number}`;
	id: `${number}`;
	lastName: string;
	profileID: number;
	shifts: SHIFT_TYPE[];
};
export type SHIFTS_FILTER_COLUMN = "profileID";

export type SHIFT_TEMPLATE_TYPE = {
	name: string;
	start: `${number}:${number}`;
	end: `${number}:${number}`;
	color: `#${number}`;
	isDefault: 0 | 1;
	isOverNight: boolean;
	shiftTemplateID: `${number}`;
	readOnly?: boolean;
};

type SHIFT_TYPE = {
	mon: 1 | 0;
	tue: 1 | 0;
	wed: 1 | 0;
	fri: 1 | 0;
	sat: 1 | 0;
	sun: 1 | 0;
	thur: 1 | 0;
	shiftTemplateID: number;
	adminID: number | null;
};

export type ROUTE_DATA = {
	companyID: number;
	insertTime: number;
	name: string;
	routeID: string;
	routeDetails: ROUTE_DETAILS_DATA[];
	category: string;
};

export type ROUTES_FILTER_COLUMNS = "name" | "category";
export type ROUTE_DETAILS_DATA = {
	client: CLIENTS_BY_COMPANY;
	endTime: string | null;
	startTime: string | null;
	type: "Client" | "Site";
};
export type CLIENTS_BY_COMPANY = {
	address: string;
	clientID: string;
	clientName: string;
	employeeID: number | null;
	siteTypeID: number | null;
};
type CUSTOM_ENTITY_TYPE = {
	customEntityID: string;
	customEntityName: string;
};

export type ADDRESS_DETAIL =
	| {
			address: string;
			lat: number | null;
			lon: number | null;
	  }
	| {};

export type TASK_TYPE = {
	taskID: string;
	clientID?: null | string;
	routeID?: string;
	employeeID: number;
	date: string;
	customEntity?: null | CUSTOM_ENTITY_TYPE;
	adminAssigned: 0 | 1 | 2 | null;
	checkinTime: number | null;
	checkoutTime: number | null;
	lat: number | null;
	lon: number | null;
	taskDescription?: string | null;
	startTime: number | null;
	endTime: number | null;
	address: string | null;
	timestamp: number;
	lastUpdatedAdminID: number | null;
	inPool: number | null;
	createdByAdminID: number | null;
	createdByEmpID: number | null;
	createdBySourceID: number | null;
	createdTs: number | null;
	lastModifiedByEmpID: number | null;
	lastModifiedByAdminID: number | null;
	lastModifiedBySourceID: number | null;
	lastModifiedTs: number | null;
	customFieldsComplex: null | CUSTOM_FIELD_COMPLEX_TYPE[];
	clientInfo: null | Pick<CLIENTS_DATA, "clientID" | "employeeID" | "clientName">;
	repeatedTaskMetadata: REPEATED_TASKS_METADATA | null;
	owningAdminID?: null | number;
	pendingTaskID?: string;
	rescheduleDate?: any;
};
export type TASK_FILTER_COLUMN =
	| "checkinTime"
	| "checkoutTime"
	| "address"
	| "taskDescription"
	| "clientInfo"
	| "adminAssigned";

export type REPEATED_TASKS_METADATA = {
	repeatedTaskID?: string;
	startDate: string;
	endDate: string;
	sunday: boolean;
	monday: boolean;
	tuesday: boolean;
	wednesday: boolean;
	thursday: boolean;
	friday: boolean;
	saturday: boolean;
	reassignEmpForAllRepeatedTasks: boolean;
};

export type FORM_TEMPLATE_TYPE = {
	companyID: number;
	formTemplateID: string;
	formTitle: string;
	formDescription: string;
	defaultPointValue: number | null;
	isQuizMode: 0 | 1;
	formSections: FORM_SECTION_TYPE[];
	createdTs: number;
	formScore: FORM_SCORE_TYPE[];
	createdByAdminID?: number;
	lastModifiedTs: number;
	lastModifiedByAdminID?: number;
	visibility: {
		visibleToAll: boolean | null;
		teamIDs: number[] | null;
	};
};
export type FORMS_FILTER_COLUMNS = "formTitle" | "createdTs" | "lastModifiedTs";
export type PARTIAL_FORM_TEMPLATE = Pick<
	FORM_TEMPLATE_TYPE,
	"formTemplateID" | "formTitle" | "formDescription" | "lastModifiedTs" | "createdTs"
>;
export type FORM_SECTION_TYPE = {
	formSectionID: string;
	sectionTitle: string;
	sectionDescription: string | null;
	formQuestions: FORM_QUESTION_TYPE[];
	createdTs: number;
	lastModifiedTs: number;
	seqNumber: number;
	lastModifiedByAdminID?: number;
	createdByAdminID?: number;
	nextSection: string;
};

export type FORM_SCORE_TYPE = {
	low: number | null;
	high: number | null;
	result: string | null;
	scoreID: string | null;
};
export type QUESTION_TYPES =
	| "varchar"
	| "mChoice"
	| "cb"
	| "dd"
	| "int"
	| "file"
	| "date"
	| "address"
	| "client"
	| "photo";

export type CustomEntityFieldDataType =
	| "varchar"
	| "number"
	| "date"
	| "contact"
	| "address"
	| "form"
	| "dropDown"
	| "checkbox"
	| "photo"
	| "file"
	| "client"
	| "site"
	| "metaLocation"
	| "time";

export type FORM_QUESTION_TYPE = {
	createdByAdminID?: number;
	questionID: string;
	createdTs: number;
	lastModifiedByAdminID?: number;
	clientsInRange: boolean;
	lastModifiedTs: number;
	options: FORM_OPTIONS_TYPE[];
	question: string;
	questionType: QUESTION_TYPES;
	required: boolean;
	score: number | null;
	sequence: number;
	jumpToSectionBasedOnAnswer?: boolean;
};
export type FORM_OPTIONS_TYPE = {
	correct: null | number;
	optionValue: string;
	createdByAdminID?: number;
	lastModifiedByAdminID?: number;
	createdTs: number;
	lastModifiedTs: number;
	nextSection: string | null;
};

type VISIT = {
	clientID: string;
	clientName: string;
	address: string;
	customEntity: {
		customEntityID: string;
		customEntityName: string;
	};
};
export type PJP_REQUEST_TYPE = {
	actionAdminID?: number;
	adminName: string;
	actionTs: number | string;
	date: string;
	employeeID: string;
	index: number;
	name: string;
	state: 0 | 1 | 2 | 3;
	status: string;
	ts: number;
	tasks: PJP_TASKS_TYPE[];
	journeyDate: string;
	submissionDate: string;
	rejectReason?: string | null | undefined;
};

export type PJP_REQUEST_TYPE_2 = {
	pjpID: string;
	name: string;
	status: string;
	visits: VISIT[];
	createdTs: number;
	lastModifiedTs: number;
	employeeID: number;
	employee: {
		firstName: string;
		lastName: string;
	};
	admin?: string;
	adminName: string;
	date: string;
	rejectReason?: string | null | undefined;
};
export type PJP_TASKS_TYPE = {
	adminAssigned: number;
	date: string;
	ceid?: number;
	dirty: boolean;
	employeeID: number;
	endTime: number;
	eta: number;
	latitude: number;
	longitude: number;
	markerType: string;
	origLat: number;
	origLon: number;
	rted: number;
	rtsd: number;
	selected: false;
	startTime: number;
	status: "Pending" | "Rejected" | "Approved" | "Deleted";
	taskAddress: string;
	taskID: string;
	timeCheckedIn: number;
	timeCheckedOut: number;
	timestamp: number;
	clientInfo: any;
	cts?: number;
	lang?: number;
	lat?: number;
	meid?: number;
	mts?: number;
	synced?: false;
	uniqueID?: string;
};

export type LEAVE_TYPE = {
	dates: string[];
	empID: `${number}`;
	startDate: string;
	endDate: string;
	approved: 0 | 1 | 2 | 3;
	reason: string;
	rangeID: string;
	totalLeavesTaken: number;
	leavesInRange: number;
	name: string;
	rejectionReason?: string;
	leaveStatus: string;
	leaveType: string;
	remarks: string;
	reasons: string | null;
};

export type NEW_LEAVE_TYPE = {
	leaveID: string;
	employeeID: `${number}`;
	startDate: string;
	startSession: string;
	endDate: string;
	endSession: number;
	leaveTypeID: string;
	leaveType: string;
	leaveStatus: "APPROVED" | "PENDING" | "REJECTED";
	remarks: string | null;
	reason: string | null;
	entitlement: number;
	leavesTaken: number;
	leaveBalance: number;
	leaveRange: number;
	name: string;
	createdTs: number;
};

type EXPENSE_DETAILS = {
	expenseID: string | number;
	conveyanceID: string | number;
	advanceExpenseID: string;
	amountClaimed: number;
	description: string;
	category: string;
	advanceConveyanceID: string;
	distanceClaimed: number;
	mode: number;
	linkedOdoID: string;
};

export type EXPENSE_REQUEST_TYPE_V1 = {
	evidences: string;
	date: string; //to use
	fileUploads: any;
	amountClaimed: number;
	description: string;
	employeeID: number;
	expenseSummaryID: string;
	profileID: number;
	createdTs: number;
	lastModifiedTs: number;
	fromDate: string;
	toDate: string;
	categories: string[];
	name: string;
	odoDistance: any;
	userInfo: {
		designationName: string;
		firstName: string;
		lastName: string;
		employeeId: number;
		profileName: String;
		profileId: number;
	};
	status: -1 | 0 | 1 | 2 | 3; // "Pending" | "Rejected" | "Approved" | "Paid Out" | "In Progress";
	type: 0;
	expenseExpenseDetails: EXPENSE_DETAILS[] | null;
	expenseConveyanceDetails: EXPENSE_DETAILS[] | null;
	authorizationInfo: EXPENSE_AUTH;
	customFieldsComplex?: CUSTOM_FIELD_COMPLEX_TYPE[] | null;
	lastApprovedAmount: number | null;
};
export type EXPENSE_REQUEST_TYPE = {
	evidences: string;
	date: string; //to use
	fileUploads: any;
	description: string;
	employeeID: number;
	expenseSummaryID: string;
	profileID: number;
	firstName: string;
	lastName: string;
	amountClaimed?: number | null;
	createdTs: string;
	lastModifiedTs: string;
	profileName: string;
	pendingWith: {
		adminID: number;
		adminName: string;
	} | null;
	nextAdmin: {
		adminID: number;
		adminName: string;
	} | null;
	q1?: null | string;
	q2?: null | string;
	status: -1 | 0 | 1 | 2;
	type: 0;
	expenseDetails: EXPENSES_DETAILS[];
	expenseAuth: EXPENSE_AUTH[];
};

export type EXPENSE_FILTER_COLUMNS = "name" | "amountClaimed" | "pendingWith" | "reimbursementAmount" | "profileID";
export type ADVANCE_FILTER_COLUMNS = "name" | "amountProposed" | "pendingAdmin" | "allottedAmount" | "profileID";

type EXPENSES_DETAILS = {
	expenseID: string;
	amountClaimed: number;
	timestamp: string;
	expenseType: string | null; //!find out later
	expenseSummaryID: string;
	desc: string;
	category: string;
	code: string | null; //!find out later
	expenseName: string;
};
type EXPENSE_AUTH = {
	payoutAdminID: number | null;
	lastApprovedAmount: number | null;
	history: AUTH_INFO[];
	pendingAdmin: {
		adminID: number;
		adminName: string;
	} | null;
	nextAdmin: {
		adminID: number;
		adminName: string;
	} | null;
	actionPerformed: -1 | 0 | 1 | 2;
	adminID: number;
	adminName: string;
	comment: string | null;
	reimbursementAmount: number | null;
	timestamp: string;
};
export type ADVANCE_REQUEST_TYPE = {
	description: string;
	employeeID: number;
	advanceSummaryID: string;
	profileID: number;
	createdTs: number;
	lastModifiedTs: number;
	fromDate: string;
	toDate: string;
	userInfo: {
		designationName: string;
		firstName: string;
		lastName: string;
		employeeId: number;
		profileName: String;
		profileId: number;
	};
	categories: string[];
	status: -1 | 0 | 1 | 2 | 3; // "Pending" | "Rejected" | "Approved" | "Paid Out" | "In Progress";
	type: 0;
	advanceExpenseDetails: ADVANCE_DETAILS[] | null;
	advanceConveyanceDetails: ADVANCE_DETAILS[] | null;
	authorizationInfo: ADVANCE_AUTH;
	amountProposed: number | null;
	customFieldsComplex?: CUSTOM_FIELD_COMPLEX_TYPE[] | null;
};
type ADVANCE_DETAILS = {
	advanceExpenseID: string;
	amountProposed: number;
	description: string;
	category: string;
	advanceConveyanceID: string;
	distanceClaimed: number;
	mode: number;
};

type ADVANCE_AUTH = {
	payoutAdminID: number | null;
	lastApprovedAmount: number | null;
	history: AUTH_INFO[];
	pendingAdmin: {
		adminID: number;
		adminName: string;
	} | null;
	nextAdmin: {
		adminID: number;
		adminName: string;
	} | null;
};

type AUTH_INFO = {
	expenseAuthID: number;
	expenseSummaryID: number | string;
	actionPerformed: -1 | 0 | 1 | 2 | 3; // "Pending" | "Rejected" | "Approved" | "Paid Out" | "In Progress";
	adminName: string;
	adminID: number;
	reimbursementAmount: number | null;
	allottedAmount: number | null;
	comment: string | null;
	timestamp: string;
	advanceAuthID: number;
	rejectReason: string;
	status: number;
	insertionStatus: null | 0 | 1 | 2 | 3 | 4 | 5 | 6;
	lastModifiedTs: number;
	backupApproverAuthID: number;
};
export type CONVEYANCE_TYPE = {
	conveyanceDetails: CONVEYANCE_DETAILS[];
	date: string;
	description: string;
	fileUploads: any;
	distanceCalculated: number | null;
	distanceClaimed?: number | null;
	amountClaimed?: number | null;
	employeeID: number;
	profileID: number;
	evidences: string; //EVIDENCE_TYPE
	expenseAuth: EXPENSE_AUTH[];
	expenseSummaryID: string;
	firstName: string;
	lastName: string;
	createdTs: string;
	lastModifiedTs: string;
	profileName: string;
	pendingWith?: {
		adminID: number;
		adminName: string;
	} | null;
	nextAdmin: {
		adminID: number;
		adminName: string;
	} | null;
	status: -1 | 0 | 1 | 2;
	type: 1;
};
export type CONVEYANCE_TYPE_V1 = {
	expenseSummaryID: string;
	date: string;
	description: string;
	status: -1 | 0 | 1 | 2 | 3; // "Pending" | "Rejected" | "Approved" | "Paid Out" | "In Progress";
	employeeID: number;
	createdTs: number;
	lastModifiedTs: number;
	evidences: string; //EVIDENCE_TYPE
	fileUploads: any;
	distanceCalculated: number | null;
	expenseConveyanceDetails: CONVEYANCE_DETAILS_V1[] | null;
	authorizationInfo: EXPENSE_AUTH;
	categories: string[];
	name: string;
	userInfo: {
		designationName: string;
		firstName: string;
		lastName: string;
		employeeId: number;
		profileName: String;
		profileId: number;
	};
	amountClaimed: number;
	type: number;
	lastApprovedAmount: number | null;
	customFieldsComplex: CUSTOM_FIELD_COMPLEX_TYPE[];
};

export type CONVEYANCE_FILTER_COLUMNS =
	| "date"
	| "name"
	| "distanceCalculated"
	| "distanceClaimed"
	| "amountClaimed"
	| "pendingWith"
	| "profileID";

export type CONVEYANCE_DETAILS = {
	amountClaimed: number;
	readonly conveyanceID: string;
	desc: string | null;
	readonly distanceClaimed: number | null;
	readonly expenseSummaryID: string;
	readonly from: string;
	readonly mode: 1 | 2 | 3;
	readonly timestamp: string;
	readonly to: string;
	readonly inReading?: number;
	readonly outReading?: number;
};
export type CONVEYANCE_DETAILS_V1 = {
	conveyanceID: string | number;
	amountClaimed: number;
	mode: number;
	description: string;
	distanceClaimed: number;
	from: string;
	to: string;
	linkedOdoID: string;
	linkedOdometer: any;
	timeStamp: string | number;
};
export type CONVEYANCE_DETAIL_DESCRIPTION = Readonly<{
	description: string;
	endTime: number;
	label: string;
	lat: number;
	lon: number;
	startTime: number;
}>;
export type EVIDENCE_TYPE = Readonly<{
	description: string;
	date: string;
	dirty: boolean;
	lang: number;
	lat: number;
	latitude: number;
	longitude: number;
	photoID: string;
	photoPath: string;
	photoURL: string;
	synced: false;
	timestamp: number;
	taskType: number;
	expenseType: 1 | 0;
	isValidS3Link?: boolean;
}>;

export type ORDER_COLLECTION_TYPE = {
	[key: string]: ORDER_TYPE;
};
export type ORDER_TYPE = Readonly<{
	orderID: string;
	invoiceNumber: string;
	taskID: string;
	clientID: null | string;
	lat: null | string;
	lon: null | string;
	recipients: any;
	payments: PAYMENT_TYPE[];
	paymentCollected: any;
	employeeID: number;
	orderName: string;
	designationName: string;
	internalEmpID: number | null;
	processingDate: string;
	orderItems: ORDER_ITEM_TYPE[];
	skuID: string;
	address: string;
	client: { clientName: string } | null;
	clientName: string;
	lineItems: LINE_ITEM_TYPE[];
	orderValue: number;
	taxValue: number;
	customFieldsComplex: null | CUSTOM_FIELD_COMPLEX_TYPE[];
	col?: ORDER_COL[];
	createdTs: number;
	lastModifiedTs: number;
	otpVerified: number;
	quantity: number;
	skusQuantity: number;
	userInfo: {
		firstName: string;
		lastName: string;
		employeeId: number;
		profileName: string;
	};
	employeeName: string;
}>;
export type SKU_TYPE = Readonly<{
	skuID: string;
	profileID: number;
	companyID: number;
	skuName: string;
	skuDescription: string;
	internalSkuID: string;
	active: boolean | null;
	category: string;
	listPrice: number;
	gstRate: number | null;
	discRate: number | null;
	skuImage1: string | null;
	skuImage2: string | null;
	skuImage3: string | null;
	lastModifiedTs: number;
	createdTs: number;
}>;
export type ORDER_SKU_TYPE = Readonly<{
	skuID: string;
	sku: SKU_TYPE;
	amount: number;
	discAmt: number;
	price: number;
	productDescription: string;
	quantity: number;
	totalTaxAmount: number;
	discRateCalc: number;
}>;
export type LINE_ITEM_TYPE = Readonly<{
	productDescription: string;
	quantity: number;
	price: number;
	clientName: string | null;
	listPrice: number;
	gstRate: number | null;
	discRate: number | null;
	skuName: string;
	skuIn: string;
	category: string;
	ind: number;
}>;
export type ORDER_ITEM_TYPE = Readonly<{
	processingDate: string;
	profileID: string;
	companyID: string;
	skuID: string;
	sku: any;
	skuName: string;
	skuDescription: string;
	category: string;
	quantity: number;
	active: boolean;
	price: number;
	listPrice: number;
	gstRate: number | null;
	discRate: number | null;
	productDescription: string;
	ind: number;
	amount: number;
	totalTaxAmount: number;
	discAmt: number;
}>;
export type PAYMENT_TYPE = Readonly<{
	amount: number;
	PropTypes: number;
	orderPaymentID: string;
}>;
export type ORDER_COL = Readonly<{
	id: string;
	md: number;
	amt: number;
}>;
export type GRID_ROW_TYPE = {
	keyComb: any;
	product?: string;
	fe?: string;
	team?: string;
	id: number;
	client?: string;
	price?: number;
	discount?: number | string;
	quantity: number;
	inProgressQuantity?: number;
	notStartedQuantity?: number;
	total?: number;
	lastTimeSpanAvgDuration?: number;
	lastTimeSpanTotalDuration?: number;
	lastTimeSpanPrice?: number;
	lastTimeSpanQuantity?: number;
	lastTimeSpanInProgress?: number;
	lastTimeSpanNotStarted?: number;
	lastTimeSpanDiscount?: number | string;
	lastTimeSpantotalPhotosForms?: number;
	lastTimeSpanTotal?: number;
	lastTImeSpanDiscountPercentage?: string;
	noPreviousTimeSpanData?: boolean;
	openCompareMode?: boolean;
	discountPercentage?: string;
	lastTimeSpanRequest?: number;
	request?: number;
	noPreviousRequest?: boolean;
	avgDuration?: number;
	totalDuration?: number;
	totalPhotosForms?: number;
	tasksFinished?: number;
};
export type GRID_COLUMN_TYPE = {
	field: string;
	headerName?: string;
	flex?: number;
	minWidth?: number;
	width?: number;
	valueGetter?: (params: any) => any;
	renderCell?: (params: any) => JSX.Element;
	renderHeader?: (params: any) => JSX.Element;
};
export type GRID_ROW = {
	id: number;
	date: string;
	startTime: string;
	endTime: string;
	scheduledStartTime: string;
	scheduledEndTime: string;
	description: string;
	address: string;
	client: string;
	created: string;
	lastModified: string;
	photos: any;
	forms: any;
	employeeName: string;
	taskType: string;
	teamName: string;
	customFieldsComplex: any;
	taskID: any;
};

export type LINE_ITEMS_DATA = {
	[key: string]: {
		address: string;
		employeeName: string;
		lineItems: LINE_ITEM_TYPE[];
		orderID: string;
		processingDate: string;
		skuID: string;
	};
};

type VISIBILITY = {
	type: string;
	value: number;
};
export type PRODUCT_TYPE = {
	skuID: string;
	skuName: string;
	skuDescription: string;
	skuImage1: string | null | undefined;
	skuImage2: string | null | undefined;
	skuImage3: string | null | undefined;
	customFieldsComplex: any;
	companyID: number;
	internalSkuID: string;
	category: string;
	listPrice: number;
	gstRate: null | number;
	discRate: null | number;
	profileID: number;
	active: boolean | string | number;
	showDiscAmt: number;
	showGstRate: number;
	lastModifiedTs: number;
	createdTs: number;
	visibility: VISIBILITY[];
};
export type PRODUCT_FILTER_COLUMNS = "skuName" | "internalSkuID" | "category" | "active" | "profileID";
export type CHANNEL_MESSAGES = {
	[x: string]: {
		content: string;
		created: {
			nanoseconds: number;
			seconds: number;
		};
		isSenderAdmin: boolean;
		senderID: `${number}`;
		senderName: string;
	}[];
};
export interface CHAT_ROOM {
	team_name: ReactNode;
	employeeID?: number;
	adminID: number;
	img: string;
	groupName: string;
	name: string;
	group: boolean;
	users: Array<number>;
	id: string;
}
export type SELECTED_CHAT_ROOM = {
	ChatRoomMetadata: CHAT_ROOM;
	Messages:
		| {
				created: Key | null | undefined;
				senderName: string;
				content: string;
				senderID: `${number}`;
				url: string;
		  }[]
		| never[];
};

export type EMPLOYEES = {
	adminID: any;
	imgUrl: any;
	firstName: any;
	lastName: any;
	employeeID: any;
	[x: number]: {
		employeeID: number;
		adminID: number;
		img: string;
		name: string;
	};
};

export type CLIENT_CATEGORIES = {
	clientCategoryID: number;
	clientType: string;
};
export type ENTITY_DATATYPE =
	| "varchar"
	| "number"
	| "address"
	| "dropDown"
	| "checkbox"
	| "status"
	| "date"
	| "contact"
	| "photo"
	| "file"
	| "form"
	| "employee"
	| "order"
	| "client"
	| "site"
	| "metaLocation"
	| "time";

export type ENTITY_METADATA = {
	active: 1 | 0;
	dataType: ENTITY_DATATYPE;
	readonly entity: "Clients" | "Sites" | "Orders" | "Tasks" | "products";
	readonly fieldID: string;
	fieldName: string;
	isDefault: 1 | 0;
	isMandatory: 1 | 0;
	isRequired: 1 | 0;
	allowAdminToEditAfterSubmit?: 1 | 0;
	options?: ENTITY_OPTIONS[];
	argsOne?: string;
	sequence: number;
	maskField?: boolean;
};
export type ENTITY_OPTIONS = {
	optionID: number;
	optionValue: string;
	isDeleted?: true | false;
	terminalState?: number;
	considerOption?: number;
	softDeleted?: 0 | 1;
};
export type USERS_DATA = {
	adminID: number;
	androidBuild: number;
	city: string;
	companyID: number;
	deleted: 0 | 1;
	designationID: number;
	emailID: string;
	employeeID: number;
	endTime_to_deco: null | number;
	firstName: string;
	haveLoggedIntoAdminPanel: 0 | 1;
	imeiNumber: null | number;
	imgUrl: string | null;
	internalEmpID: string;
	isTest: 0 | 1;
	lastName: string;
	managerID: number;
	manufacturer: string;
	mobileNumber: string;
	mode: 1 | 2 | 3 | 4;
	modelNumber: string;
	otp: null;
	otpTs: null;
	parentAdminID: number;
	password: string;
	photoPath: null | string;
	productID: number;
	profileID: number;
	profileName: string;
	resetPasswordOTP: null;
	runMode: 1 | 2 | 3 | 4;
	startTime_to_deco: null | number;
	targetCountry: null | string;
	tz: string;
	updateTime: string;
	userID: string;
	workLocation: null | string;
	workLocationType: 0 | 1;
};

export type CLIENTS_DATA = {
	clientID: string;
	companyID: number;
	employeeID: number;
	clientName: string;
	lat: number | string;
	lng: number | string;
	clientCat: null | number;
	internalClientID: string;
	visibleToProfileID: null | number;
	description: string;
	timestamp: number;
	photoPath: null | string;
	visibleTo: string;
	phoneNumber: string;
	address: string;
	canOverride: 0 | 1;
	proprietorName: string;
	radius: number | "";
	email: null | string;
	createdTs: number;
	lastModifiedTs: number | null;
	siteType: number | null;
	city: string;
	pinCode: string | null;
	createdByAdminID: number;
	lastModifiedByAdminID: number;
	otpVerified: number;
	customFields: null | {
		name: string;
		value: string;
	};
	customFieldsComplex?: CUSTOM_FIELD_COMPLEX_TYPE[] | null;
	visibility:
		| [
				{
					type: "EVERYONE" | "PROFILE" | "EMPLOYEE";
					value: number;
				}
		  ]
		| [];
	contact: {
		contactName: string;
		countryCode: string;
		contactNumber: string;
		fieldID: string;
	};
};
export type CLIENT_FILTER_COLUMNS =
	| "clientName"
	| "visibleToProfileID"
	| "proprietorName"
	| "phoneNumber"
	| "city"
	| "pinCode"
	| "visibleTo";
export type PARTIAL_CLIENTS_DATA = Pick<CLIENTS_DATA, "address" | "clientID" | "clientName" | "lat" | "lng" | "radius">;
export type CUSTOM_FIELD_COMPLEX_TYPE = {
	fieldID: string;
	fieldValue: null | string;
	fieldValue2?: null | string;
	fieldValue3?: null | string;
	fieldName?: string;
	selectedOptions: number[] | null;
	selectedOptionsValues?: string[] | string | null;
	selectedFieldValues?:
		| {
				fieldValue: string | undefined;
				fieldValue2: string | undefined;
		  }[]
		| null;
	isRequired?: boolean;
};

export type SITES_DATA = {
	address: string;
	canOverride: number;
	city: string | null;
	clientCat: number;
	clientID: string;
	clientName: string;
	companyID: number;
	createdByAdminID: number;
	createdByEmpID: null | number;
	createdBySourceID: null | number;
	createdTs: string;
	description: string;
	email: null | string;
	employeeID: number; //some weird flags
	externalClientID: null | number;
	internalClientID: "undefined" | `${number}`;
	jobTypeID: number;
	lastModifiedByAdminID: null | number;
	lastModifiedByEmpID: number | null;
	lastModifiedBySourceID: null | number;
	lastModifiedTs: string;
	lastUpdated: string;
	latitude: number | string;
	longitude: number | string;
	origLat: null | number;
	origLon: null | number;
	otpVerified: number;
	phoneNumber: null | string;
	photoPath: null | string;
	pinCode: `${number}`;
	polyline: null;
	proprietorName: null | string;
	radius: number | "";
	siteType: number | null;
	timestamp: number;
	visibleToProfileID: null | number;
};
export type SITE_TYPES_TYPE = {
	companyID: number;
	jobTypeID: number;
	jobDescription: string;
	lastModifiedByAdminID: number;
};
export type SITE_FILTER_COLUMNS = "clientName" | "proprietorName" | "phoneNumber" | "city" | "pincode";
export type SITEPOOLS_DATA_WITH_SITES = SITEPOOLS_DATA & { siteIDs: string[] };

export type ENTITY_TYPE = {
	name: string;
	id?: number;
};
export type NOTIFICATION_TYPE = {
	employeeID: `${number}`;
	state: 1 | 0;
	text: string;
	ts: number;
	type: string;
	url: string;
	tsDateTime: string;
	ref: string;
};

export type CUSTOM_FIELD_METADATA = {
	fieldID: string;
	isMandatory: number;
	active: number;
	fieldName: string;
	dataType: ENTITY_DATATYPE;
	isRequired: number;
	isDefault: number;
	entity: "Clients" | "Tasks" | "Orders" | "Sku" | "Advances" | "Expenses" | "Conveyances";
	options?: ENTITY_OPTIONS[];
	isDeleted?: 0 | 1;
	primaryField?: 0 | 1;
	write?: 0 | 1;
	sequence?: number;
	argsOne?: string | null;
	maskField?: 0 | 1;
	id?: string;
	__reorder__?: boolean;
	timeStamp?: number;
};

export type FEATURE = {
	featureID: number;
	featureName: string;
	featureDescription: string;
	state: number;
	id?: number;
};

export type ATTENDANCE_SETTINGS = {
	profileName: string;
	profileID: number;
	explicitLogin: 0 | 1 | 2;
	attendanceCalcType: 0 | 1 | 2;
	strictMode: 0 | 1;
	stopAttendance: 0 | 1;
	randomReAuth: 0 | 1;
	showAutoTask: 0 | 1;
	inAttFormID: string;
	outAttFormID: string;
	authentication: 0 | 1 | 2;
	locationBasedAutoAttendanceSiteID: null | string;
	locationBasedAutoAttendanceSitePoolID: null | string;
	attendanceLocationOnly: 0 | 1;
	adminNameOnSignInOutEvent: 0 | 1;
	manualAutoSignOutAfter: number;
	forbidPunchOutBeforeShiftEnd: 0 | 1 | null;
};

export type INTEGRATION_INFO = {
	entityName: string;
	integrationID: number;
	methodName: string;
	status: 0 | 1;
	integrationName: string;
	keyValues: {
		keyTypeID: number;
		keyValue: string;
	}[];
};
export type DASHBOARD = {
	readonly employeeID: number;
	readonly userID: string | null;
	readonly companyID: number | null;
	readonly internalEmpID: string | null;
	firstName: string;
	lastName: string | null;
	readonly email: string | null;
	readonly mobileNumber: number | null;
	readonly profileID: number | null;
	readonly profileName: string | null;
	imgUrl: string | null;
	city: string | null;
	lastLocation: LOCATION[] | null;
	lastAttendance: ATTENDANCE[] | null;
	lastAppState: APP_STATE[] | null;
	tasks: TASKS[] | null;
	orders: ORDERS[] | null;
	completedForms: COMPLETED_FORMS[] | null;
	workingStatus: string;
	workingStatusV2: "Punched In" | "On Leave" | "Punched Out" | "Weekly Off" | "Inactive" | "" | null;
};
export const WORKING_STATUS_V2 = {
	WEEKLY_OFF: "Weekly Off",
	PUNCHED_IN: "Punched In",
	PUNCHED_OUT: "Punched Out",
	IN_ACTIVE: "Inactive",
	ON_LEAVE: "On Leave",
	ALL_EMPLOYEES: "",
} as const;
export type DASHBOARD_COUNT = {
	totalUsers: number;
	onlineUsers: number;
	inactiveUsers: number;
	offlineUsers: number;
	onLeaveUsers: number;
	weeklyOffUsers: number;
};
type ATTENDANCE = {
	timestamp: string;
	eventTypeID: number;
	date?: string | null;
	address?: string | null;
	tz?: string | null;
};

type APP_STATE = {
	gpsEnabled: 1 | 0 | undefined;
	batteryPct: number;
	wifiEnabled: number | null;
};

type LOCATION = {
	timestamp: string;
	age: number | null;
	insertTime: string | null;
	date: string | null;
	lat: number;
	lon: number;
};
export type TASKS = {
	taskID: string;
	checkinTime: number | null;
	checkoutTime: number | null;
	taskDescription: string | null;
	startTime: number | null;
	endTime: number | null;
	clientInfo?: any;
	address?: string;
};
export type ORDERS = Readonly<{
	orderID: string;
	orderValue: number;
	client: null | {
		clientID: string;
		clientName: string;
	};
}>;
export type COMPLETED_FORMS = Readonly<{
	completedFormID: string;
	formTitle: string;
	createdTs: number;
}>;
export type USER_ROSTER_TYPE = {
	firstName: string;
	employeeID: `${number}`;
	id: `${number}`;
	lastName: string;
	rosters: ROSTER_TYPE[];
};

export type ROSTER_TEMPLATE_TYPE = {
	name: string;
	start: `${number}:${number}`;
	end: `${number}:${number}`;
	color: `#${number}`;
	isOverNight: boolean;
	rosterTemplateID: `${number}`;
};

type ROSTER_TYPE = {
	date: string;
	rosterTemplateID: number;
	wo: number;
	adminID: number | null;
};
export type FORM_DEPENDENCY_INFO = {
	formTitle: string;
	profileName: string;
	dependencyName: "Attendance In Form" | "Attendance Out Form" | "Task Completion Form";
};
export const Z_EXPENSE_TYPE = z.object({
	expenseOptions: z.object({
		companyID: z.number().nonnegative(),
		usesAdminHierarchy: z.number().min(0).max(2),
		restrictedAdminCount: z.number().nonnegative().nullable(),
		approverOne: z.number().nonnegative().nullable(),
		approverTwo: z.number().nonnegative().nullable(),
		approverThree: z.number().nonnegative().nullable(),
		carRate: z.number().nonnegative().nullable(),
		bikeRate: z.number().nonnegative().nullable(),
		itemizedConveyance: z.number().nonnegative().nullable(),
		lastModifiedByAdminID: z.number().nonnegative().nullable(),
		expenseQuestionOne: z.string().nullable(),
		expenseQuestionTwo: z.string().nullable(),
		overdue: z.number().nonnegative().nullable(),
		backUpApprover1: z.number().nonnegative().nullable(),
		backUpApprover2: z.number().nonnegative().nullable(),
		convLimitOnDistOrAmt: z.number(),
	}),
	expenseCategories: z.array(
		z.object({
			category: z.string(),
			expenseCategoryID: z.string(),
			deleted: z.boolean().optional(),
		})
	),
	expenseLimits: z.array(
		z.object({
			category: z.string().optional(),
			expenseCategoryID: z.string().nullable(),
			expenseLimitID: z.number().nonnegative().optional(),
			designationID: z.number().nonnegative().nullable(),
			profileID: z.number().nonnegative().nullable(),
			expenseLimit: z.number().nonnegative().nullable(),
			expenseTimePeriod: z.number().nullable(),
			companyID: z.number().nonnegative().optional(),
		})
	),
	conveyanceLimits: z.array(
		z.object({
			companyID: z.number().nonnegative().optional(),
			conveyanaceLimitID: z.number().nonnegative().optional(),
			designationID: z.number().nonnegative().nullable(),
			profileID: z.number().nonnegative().nullable(),
			carRate: z.number().nonnegative().nullable(),
			bikeRate: z.number().nonnegative().nullable(),
		})
	),
	expenseCodes: z.array(z.any()),
	conveyanceDistAmtLimits: z.array(
		z.object({
			companyID: z.number().nonnegative().optional(),
			conveyanceCategory: z.string().nullable(),
			conveyanceLimit: z.number().nonnegative().nullable(),
			conveyanceTimePeriod: z.number().nullable(),
			conveyanaceLimitID: z.number().nonnegative().optional(),
			designationID: z.number().nonnegative().nullable(),
			profileID: z.number().nonnegative().nullable(),
		})
	),
	conveyanceCategory: z.array(
		z.object({
			category: z.string(),
		})
	),
});
export type EXPENSE_SETTING = z.infer<typeof Z_EXPENSE_TYPE>;
const Z_LOCATION = z.object({
	employeeID: z.number().optional(),
	eventTypeID: z.number().optional(),
	lat: z.union([z.string(), z.number()]).optional(),
	lon: z.union([z.string(), z.number()]).optional(),
	timestamp: z.string().optional(),
	accuracy: z.number().optional(),
	age: z.number().optional(),
	src: z.number().optional(),
	ts: z.string().optional(),
	filter: z.number(),
});
const Z_EVENTS = z.array(
	z.object({
		eventTypeID: z.number(),
		eventParam: z.union([z.string(), z.number()]).optional(),
		ts: z.string(),
		photoURL: z.string().optional(),
		skip: z.number().optional(),
		skipReason: z.string().optional(),
	})
);
const Z_SHIFTS = z.object({
	startTime: z.string().optional().nullable(),
	endTime: z.string().optional().nullable(),
	name: z.string().optional(),
	duration: z.number().optional(),
});
const Z_PHOTOS = z.object({
	photoID: z.string().uuid(),
	ppath: z.string().optional(),
	photoURL: z.string(),
	description: z.string().nullable(),
	timestamp: z.number(),
	latitude: z.union([z.string(), z.number()]).optional(),
	longitude: z.union([z.string(), z.number()]).optional(),
	employeeID: z.number(),
	address: z.string(),
	date: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
	isSelfie: z.union([z.literal(0), z.literal(1)]).optional(),
});
const Z_FORMS = z.object({
	formTitle: z.string().optional(),
	formID: z.string().uuid(),
	formDescription: z.string().optional(),
	formTemplateID: z.string().uuid(),
	latitude: z.number().nonnegative().optional(),
	longitude: z.number().nonnegative().optional(),
	timestamp: z.number().nonnegative(),
	address: z.string(),
	date: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
	data: z.record(
		z.string().uuid(),
		z.object({
			fieldName: z.string(),
			fieldID: z.string().uuid(),
			req: z.boolean(),
			seq: z.number().nonnegative(),
			dataType: z.union([
				z.literal("varchar"),
				z.literal("mChoice"),
				z.literal("cb"),
				z.literal("dd"),
				z.literal("int"),
				z.literal("file"),
				z.literal("date"),
				z.literal("address"),
				z.literal("client"),
				z.literal("photo"),
			]),
			fieldValue: z.string().nullable(),
		})
	),
});
export const Z_TASKS = z.array(
	z.object({
		adminAssigned: z.union([z.literal(0), z.literal(1), z.literal(2)]),
		taskID: z.string().uuid().optional(),
		employeeID: z.number().nonnegative(),
		date: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
		latitude: z.number(),
		photoID: z.string().uuid().optional(),
		ppath: z.string().optional(),
		photoURL: z.string().optional(),
		formID: z.string().uuid().optional(),
		formDescription: z.string().optional(),
		formTemplateID: z.string().uuid().optional(),
		data: z
			.record(
				z.string().uuid(),
				z.object({
					fieldName: z.string(),
					fieldID: z.string().uuid(),
					req: z.boolean(),
					seq: z.number().nonnegative(),
					dataType: z.union([
						z.literal("varchar"),
						z.literal("mChoice"),
						z.literal("cb"),
						z.literal("dd"),
						z.literal("int"),
						z.literal("file"),
						z.literal("date"),
						z.literal("address"),
						z.literal("client"),
						z.literal("photo"),
					]),
					fieldValue: z.string().nullable(),
				})
			)
			.optional(),
		isSelfie: z.union([z.literal(0), z.literal(1)]).optional(),
		longitude: z.number(),
		checkinTime: z.union([z.string(), z.string().regex(/^\d{2}:\d{2}$/)]).optional(),
		checkin: z.number().optional(),
		checkoutTime: z.union([z.string(), z.string().regex(/^\d{2}:\d{2}$/)]).optional(),
		checkout: z.number().optional(),
		taskDescription: z.string().nullable().optional(),
		used: z.boolean().optional(),
		type: z.union([z.literal("meeting"), z.literal("task"), z.string()]),
		startTime: z.string().optional().nullable(),
		endTime: z.string().optional().nullable(),
		manager: z.string().optional(),
		businessPlace: z.string().nullable().optional(),
		inPool: z.number().optional(),
		address: z.string(),
		linkedToVisit: z.boolean().optional(),
		customEntity: z.any(),
		clientInfo: z
			.object({
				clientID: z.string().uuid(),
				companyID: z.number().nonnegative(),
				clientName: z.string(),
				latitude: z.number(),
				longitude: z.number(),
				phoneNumber: z.string().nullable(),
				proprietorName: z.string().nullable(),
				address: z.string(),
				employeeID: z.number().nullable(),
			})
			.optional(),
		forms: z.array(Z_FORMS).optional(),
		evidence: z.array(Z_PHOTOS).optional(),
		exitTime: z.number().optional().nullable(),
	})
);
export const Z_ODOMETER_READING = z.array(z.object({
	processingDate: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
	odoReadingID: z.string().uuid(),
	employeeID: z.number().int(),
	skip: z.number().int(),
	skipReason: z.string(),
	expenseRate: z.number(),
	modeOfTransport: z.number().int(),
	inReading: z.number(),
	outReading: z.number(),
	inPhotoID: z.string().nullable(),
	outPhotoID: z.string().nullable(),
	inTimestamp: z.string().nullable(),
	outTimestamp: z.string().nullable(),
	inLat: z.number(),
	inLon: z.number(),
	outLat: z.number(),
	outLon: z.number(),
	inAttendanceID: z.string().uuid(),
	outAttendanceID: z.string().uuid().nullable(),
  }));
export const Z_TIMELINE = z.object({
	firstname: z.string(),
	lastname: z.string(),
	date: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
	name: z.string(),
	manufacturer: z.string().nullable(),
	model: z.string().nullable(),
	androidBuild: z.number().nullable(),
	rkDistance: z.string().nullable(),
	timezone: z.string().nullable(),
	email: z.string().nullable(),
	everLoggedIn: z.boolean().optional(),
	invalidUser: z.boolean(),
	odoReadings: z.array(z.number().nullable()),
	odometerCalculatedDistance: z.number().nonnegative(),
	odometerEnabled: z.boolean(),
	hideBadDistance: z.number(),
	complianceRating: z
		.union([z.literal("A"), z.literal("D")])
		.nullable()
		.optional(),
	setupRating: z
		.union([z.literal("A"), z.literal("D")])
		.nullable()
		.optional(), //A-good, D-bad
	autoAttendanceList: z.array(z.any()),
	complianceRatingResons: z.array(z.any()),
	filteredLocations: z.array(Z_LOCATION).optional(),
	attendanceObj: z.object({
		attendanceCounted: z.number(),
		attendanceResultCode: z.number(),
		attendanceResultCode2: z.number(),
		breakTime: z.number().optional(),
		timezone: z.string().optional(),
		totalTimeAttendance: z.number(),
		totalTimeTracked: z.number(),
		attendanceExpectedShifts: z.array(Z_SHIFTS),
		attendanceWorkedShifts: z.array(Z_SHIFTS),
		locationTrackingShifts: z.array(Z_SHIFTS),
		stretchedShifts: z.array(Z_SHIFTS),
		workingDayStatus: z.object({
			code: z.number(),
			reason: z.string(),
			shifts: z.array(Z_SHIFTS).optional(),
		}),
	}),
	allLocations: z.array(
		Z_LOCATION.extend({
			siteName: z.string().nullable().optional(),
			tz: z.string().optional(),
			photoURL: z.string().nullable().optional(),
		})
	),
	visits: z.array(
		z.object({
			startTime: z.string().optional().nullable(),
			endTime: z.string().optional().nullable(),
			locality: z.string(),
			batteryLevel: z.number(),
			duration: z.number(),
			missing: z.union([z.literal(0), z.literal(1)]),
			lowQuality: z.number(),
			significantVisit: z.boolean(),
			city: z.string().nullable(),
			pincode: z.string().nullable().optional(),
			clippedAddress: z.string(),
			delete: z.number().optional(),
			locations: z
				.array(
					Z_LOCATION.extend({
						filter: z.number().optional(),
						tz: z.string().optional(),
						photoURL: z.string().nullable().optional(),
					})
				)
				.optional(),
			bestLocation: z
				.object({ lat: z.union([z.string(), z.number()]), lon: z.union([z.string(), z.number()]) })
				.optional(),
			reasons: z.array(z.any()).optional(),
			events: Z_EVENTS,
			tasks: Z_TASKS.optional(),
			forms: z.array(Z_FORMS).optional(),
			evidence: z.array(Z_PHOTOS).optional(),
			travel: z.object({
				distance: z.number(),
			}),
		})
	),
	travels: z.array(
		z.object({
			id: z.string().uuid(),
			startTime: z.string().optional().nullable(),
			endTime: z.string().optional().nullable(),
			distance: z.number(),
			modeOfTransport: z.string().nullable(),
			duration: z.number(), //in milliseconds
			hide: z.number().nullable(),
			batteryLevel: z.number().nullable(),
			locations: z.array(
				Z_LOCATION.extend({
					bearingTillHere: z.number().optional(),
				})
			),
			events: Z_EVENTS,
			polyline: z.array(z.string()),
		})
	),
	tasks: Z_TASKS,
	coverage: z.object({
		firstSignIn: z.union([z.string(), z.number()]).optional(),
		lastSignOut: z.union([z.string(), z.number()]).optional(),
		firstSignInFormatted: z.union([z.string(), z.string().regex(/^\d{2}:\d{2}:\d{2}$/)]).optional(),
		lastSignOutFormatted: z.union([z.string(), z.string().regex(/^\d{2}:\d{2}:\d{2}$/)]).optional(),
		total: z.number().nonnegative(),
		totalHours: z.string().regex(/^\d{2}:\d{2}$/),
		totalTimeTracked: z.string().regex(/^\d{2}:\d{2}$/),
		totalTimeAttendance: z.string().regex(/^\d{2}:\d{2}$/),
		percentage: z.string(),
	}),
	workingStatus: z.object({
		code: z.number(),
		reason: z.string(),
		shifts: z.array(Z_SHIFTS).optional(),
	}),
	locationTrackingShifts: z
		.object({
			totalTimeAttendance: z.number(),
			attendanceResultCode: z.number(),
			totalTimeTracked: z.number(),
			attendanceCounted: z.number(),
			breakTime: z.number(),
			locationTrackingShifts: z.array(Z_SHIFTS),
			stretchedShifts: z.array(Z_SHIFTS),
			attendanceExpectedShifts: z.array(Z_SHIFTS),
			attendanceWorkedShifts: z.array(Z_SHIFTS),
			timezone: z.string(),
		})
		.optional(),
});
export type TIMELINE = z.infer<typeof Z_TIMELINE>;

export type COMMUNICATION_RULES = {
	readonly ruleID: number;
	readonly companyID: number;
	readonly entityType: string;
	profileID: number;
	what: "TASK_ASSIGNED" | "TASK_STARTED" | "TASK_FINISHED";
	when: "IMMEDIATE" | "EOD";
	how: "EMAIL" | "SMS" | "WA";
	who: "CLIENT" | "RM" | "ADMIN" | "FIXED_CONTACT";
	whoParameter: string | null; //email
	readonly timestamp: number;
	createdTs: number;
	lastModifiedTs: number;
	readonly createdByAdminID: number;
	readonly lastModifiedByAdminID: number;
};

export const Z_COMPLIANCE = z.object({
	employeeName: z.string(),
	empID: z.number().nonnegative(),
	manufacturer: z.string().optional().nullable(),
	complianceRating: z.record(
		z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
		z.union([z.literal("A"), z.literal("D"), z.literal("-")])
	),
	setupRating: z.record(
		z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
		z.union([z.literal("A"), z.literal("D"), z.literal("-")])
	),
	appVersion: z.number().optional().nullable(),
	evidence: z
		.array(
			z.object({
				date: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
				empID: z.number().nonnegative(),
				url: z.string(),
			})
		)
		.optional(),
});
export type COMPLIANCE = Readonly<z.infer<typeof Z_COMPLIANCE>>;

declare global {
	interface Window {
		OneSignal: any[];
		dataLayer: any[];
	}
}

export type NEW_TEMPLATE_DATA = {
	templateID: string;
	templateName: string;
	unoloEntity: string;
};
export type zohoFieldsArr = {
	externalFieldID: string;
	externalFieldName: string;
	isExternalFieldCustom: boolean;
	pickListValues: any;
	systemMandatory: boolean;
};
export type TEMPLATE_DATA = {
	templateID: string;
	templateName: string;
	crmFieldMappingID: number | null;
	internalEntity: string | null;
	externalEntity: string | null;
	crmLayoutID: number | null;
	crmLayoutName: string;
	externalBehaviourID: number;
	crmFieldMapping: [
		{
			crmFieldMappingID: string | null;
			internalFieldID: string | null;
			externalFieldID: string | null;
			externalFieldName: string | null;
			isExternalFieldCustom: number | null;
			externalBehaviourValue: string | null;
		}
	];
};
export type FIELD_MAPPING = {
	crmFieldMappingID: string | null;
	internalFieldID: string | null;
	externalFieldID: string | null;
	externalFieldName: string | null;
	isExternalFieldCustom: number | null;
	internalFieldName?: string | undefined;
	displayName?: string;
	externalBehaviourValue: string | null;
};

type CUSTOM_WEBHOOKS_KEY_TYPE = {
	keyTypeID: number;
	keyName: string;
	keyValue: string;
};
export type CUSTOM_WEBHOOKS = {
	integrationID: number;
	integrationName: string;
	status: number;
	keyValues: CUSTOM_WEBHOOKS_KEY_TYPE[];
	entityName: string;
};

type KEY_VALUE = {
	keyName: string;
	keyTypeID: number;
	keyValue: string;
};

export type HRONE_INTEGRATION = {
	integrationID: number;
	integrationName: string;
	entityName: string;
	status: number;
	keyValues: KEY_VALUE[];
};
type customEntityFieldOption = {
	optionValue: string;
	nextSection: null;
	timestamp: number;
	tempID?: string;
	softDeleted?: 0 | 1;
};

export type CustomEntityField = {
	fieldID: string;
	dataType: CustomEntityFieldDataType;
	fieldName: string;
	options: customEntityFieldOption[];
	sequence: number;
	timestamp: number;
	customEntityFieldSettings: CustomEntityFieldSettingsInput;
};

type CustomEntityFieldSettingsInput = {
	varcharSettings?: CustomEntityFieldSettingsVarCharInput;
	numberSettings?: CustomEntityFieldSettingsNumberInput;
	dateSettings?: CustomEntityFieldSettingsDateInput;
	contactSettings?: CustomEntityFieldSettingsContactInput;
	addressSettings?: CustomEntityFieldSettingsAddressInput;
	formSettings?: CustomEntityFieldSettingsFormInput;
	dropDownSettings?: CustomEntityFieldSettingsDropDownInput;
	checkboxSettings?: CustomEntityFieldSettingsCheckBoxInput;
	photoSettings?: CustomEntityFieldSettingsPhotoInput;
	fileSettings?: CustomEntityFieldSettingsFileInput;
	clientSettings?: CustomEntityFieldSettingsClientInput;
	siteSettings?: CustomEntityFieldSettingsSiteInput;
	locationSettings?: CustomEntityFieldSettingsLocationInput;
	timeSettings?: CustomEntityFieldSettingsTimeInput;
};

type CustomEntityFieldSettingsVarCharInput = {
	required: boolean;
	write: boolean;
};

type CustomEntityFieldSettingsNumberInput = {
	required: boolean;
	write: boolean;
};

type CustomEntityFieldSettingsDateInput = {
	required: boolean;
	write: boolean;
};

type CustomEntityFieldSettingsTimeInput = {
	required: boolean;
	write: boolean;
};

type CustomEntityFieldSettingsContactInput = {
	required: boolean;
	write: boolean;
};

type CustomEntityFieldSettingsAddressInput = {
	required: boolean;
	write: boolean;
};

type CustomEntityFieldSettingsFormInput = {
	required: boolean;
};

type CustomEntityFieldSettingsDropDownInput = {
	required: boolean;
	write: boolean;
};

type CustomEntityFieldSettingsCheckBoxInput = {
	required: boolean;
	write: boolean;
};

type CustomEntityFieldSettingsPhotoInput = {
	required: boolean;
};

type CustomEntityFieldSettingsFileInput = {
	required: boolean;
	write: boolean;
};

type CustomEntityFieldSettingsClientInput = {
	required: boolean;
	clientModification: boolean;
	allowClientSelectionByEmployee: boolean;
	clientGeoValidation: boolean;
	oneTaskPerClientPerDay: boolean;
	isOtpVerificationEnabledForTasks: boolean;
};

type CustomEntityFieldSettingsSiteInput = {
	required: boolean;
	siteGeoValidation: boolean;
	oneTaskPerClientPerDay: boolean;
};

type CustomEntityFieldSettingsLocationInput = {
	metaLocationGeoValidation: boolean;
	metaLocationGeoValidationRadius: number;
};

export type CustomEntitySection = {
	customEntitySectionID: string;
	customEntitySectionTitle: string;
	customEntitySectionDescription: string;
	seqNumber: number;
	nextSection: string;
	createdTs: number;
	lastModifiedTs: number;
	customEntityFields: CustomEntityField[];
};

type visibiltyType = {
	type: "EVERYONE" | "PROFILE" | "EMPLOYEE";
	value: number;
};

export type CustomEntityProps = {
	customEntityID: string;
	customEntityName: string;
	active: string;
	visibility: visibiltyType[];
	allowRescheduleForEmp: boolean;
	canEmployeeStartTheTask: boolean;
	createdTs: number;
	lastModifiedTs: number;
	customEntitySections: CustomEntitySection[];
};

export type moduleFieldsType = {
	module: any;
	columns: moduleColumnFields[];
};

type moduleColumnFields = {
	fieldID: string;
	fieldName: string;
	isDefault: 0 | 1;
	type: string;
	moduleName?: string;
	dataType: string;
	primaryFilter: null | string;
};
export type ColumnFields = {
	fieldID: string;
	fieldName?: string;
	isDefault?: 0 | 1;
	sortOrder: string | null;
	aggregateFunction?: string;
	sequence: number;
	type?: string;
	moduleName: string;
	options: string[] | null;
	primaryFilter?: null | string;
};
export type GROUPTYPE = {
	fieldID: string;
	sortOrder?: string | null;
	sequence?: number;
	aggregateFunction?: string;
	labelID?: string;
	labelName?: string;
	fieldName?: string;
	type?: string;
	moduleName: string;
	options: string[] | null;
	isDefault?: 0 | 1;
	primaryFilter?: null | string;
};
export type customReportFilterType = {
	fieldID: string;
	fieldName: string;
	sequence: number;
	filterValues: string[];
	filterName: string;
	dataType: string;
	filterPrimaryComparator: string;
	isRelative: 0 | 1;
	options: string[] | null;
	moduleID: string;
	moduleType: string;
	primaryFilter: null | string;
};

export type subReports = {
	fields: ColumnFields[];
	columnGroupings: GROUPTYPE[];
	aggregateFunctions: GROUPTYPE[];
	filters: customReportFilterType[];
	filterPattern: string;
	rowGroupings: GROUPTYPE[];
	modulesHierarchy: {};
	subMetaDataName: string;
	detailRows: 0 | 1;
	showGrandTotal: 0 | 1;
	showSubTotal: 0 | 1;
	showRowCount: 0 | 1;
	splitAggregates: 0 | 1;
};

export type fieldsForAggregate = {
	fieldID: string;
	fieldName: string;
	subMetaDataName?: string;
	dataType?: string;
	subMetaDataSequence: number;
	moduleID: string;
	moduleName: string;
	moduleType: string;
};
export type parentAggregates = {
	aggregateName: string;
	aggregateFunction: string;
	fields: fieldsForAggregate[];
};
export type CustomReportMetaData = {
	dataExtractMetaDataID: string;
	name: string;
	description: string;
	downloads?: any;
	isJoined: 0 | 1;
	subMetaData: subReports[];
	parentAggregates?: parentAggregates[];
};

export type AllSavedCustomReports = {
	dataExtractMetaDataID: string;
	name: string;
	description: string;
	adminID: any;
	createdTs: number;
	lastModifiedTs: number;
	downloads: any;
	status: any;
}[];
