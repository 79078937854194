// react
import React, { FC } from "react";
// mui
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

type Props = any;

const PrimaryDatePicker: FC<Props> = (props) => {
	const { value, handleDateChange, label, ...restProps } = props;
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				{...restProps}
				label={label}
				value={value}
				format="DD-MM-YYYY"
				onChange={handleDateChange}
				slotProps={{
					textField: {
						required: true,
						sx: {
							width: "100%",
						},
						className: "date_picker",
					},
				}}
			/>
		</LocalizationProvider>
	);
};

export default PrimaryDatePicker;
