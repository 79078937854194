import { gql } from "@apollo/client";

export const GET_INTEGRATIONS = gql`
	query ($entityName: String!) {
		get_integrations(entityName: $entityName) {
			integrationID
			integrationName
			status
			keyValues {
				keyTypeID
				keyValue
				keyName
			}
			entityName
		}
	}
`;

export const UPSERT_INTEGRATION_STATE = gql`
	mutation upsertIntegrationState($input: IntegrationStateInput!) {
		upsert_integration_state(input: $input) {
			status
			message
		}
	}
`;

export const UPSERT_INTEGRATION = gql`
	mutation upsertIntegration($input: [ApiCredentialInput!]!) {
		upsert_integrations(input: $input) {
			status
			message
		}
	}
`;

export const GET_INTEGRATIONS_BY_COMPANY_ID = gql`
	query getIntegrationsByCompanyID {
		get_integrations_by_companyID {
			integrationID
			integrationName
			displayName
			status
			keyValues {
				keyTypeID
				keyValue
				keyName
			}
			entityName
		}
	}
`;

export const GET_INTEGRATION_LOGS = gql`
	query getIntegrationLog(
		$start: String
		$end: String
		$pageNumber: Int
		$pageSize: Int
		$order: OrderType
		$integrationID: Int
		$status: IntegrationLogStatus
		$eventType: String
	) {
		get_integration_logs(
			start: $start
			end: $end
			pageNumber: $pageNumber
			pageSize: $pageSize
			order: $order
			integrationID: $integrationID
			status: $status
			eventType: $eventType
		) {
			data {
				integrationID
				integrationName
				eventType
				eventID
				status
				errorCode
				errorMessage
				createdAt
				lastModifiedAt
				lastModifiedByAdminID
				lastModifiedBySourceID
				retryCount
			}
			pageContext {
				currPage
				pageSize
				totalPages
				totalRecords
			}
		}
	}
`;

export const GET_INTEGRATION_LOG_AUDIT_TRAIL = gql`
	query getIntegrationLogAuditTrail($eventID: ID!, $integrationID: Int!, $skip: Int, $take: Int) {
		get_integration_log_audit_trail(eventID: $eventID, integrationID: $integrationID, skip: $skip, take: $take) {
			integrationID
			integrationName
			eventType
			eventID
			status
			errorCode
			errorMessage
			createdAt
			lastModifiedAt
			lastModifiedByAdminID
			lastModifiedBySourceID
		}
	}
`;

export const REPLAY_EVENT = gql`
	mutation replayEvent($input: [ReplayEventInput!]!) {
		replay_event(input: $input) {
			success
			data {
				integrationID
				integrationName
				eventType
				eventID
				status
				errorCode
				errorMessage
				createdAt
				lastModifiedAt
				lastModifiedByAdminID
				lastModifiedBySourceID
				retryCount
				displayName
			}
		}
	}
`;
