import React, { FC } from "react";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialog-paper": {
        maxWidth: "80vw",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

type Props = {
    onClose: () => void;
};

const BadAppStatusModal: FC<Props> = ({ onClose }) => (
    <BootstrapDialog onClose={onClose} aria-labelledby="customized-dialog-title" open={true}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "10px", width: "70vw", height: "60vh" }}>
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/6y0EXVDVapQ?si=5C9QC0qlVwJ6DTeY" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen ></iframe>
        </div>
    </BootstrapDialog>
);

export default BadAppStatusModal;
