import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { CircularProgress } from "@mui/material";
import Link from "next/link";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { SITES_DATA } from "../../../@types";
import DeleteModal from "../../common/DeleteModal";
import useAdminEntitlements from "../../useAdminEntitlements";
type Props = {
	expanded: boolean;
	selectedSite: SITES_DATA;
	setSelectedSiteId: Dispatch<SetStateAction<string | null>>;
	removeCircleFromMarkers: () => void;
	// eslint-disable-next-line no-unused-vars
	deleteHandler: (id: string) => Promise<void>;
	isDeleting: boolean;
};
const SiteDetailedSidebarView: FC<Props> = ({
	selectedSite,
	setSelectedSiteId,
	removeCircleFromMarkers,
	deleteHandler,
	expanded,
	isDeleting,
}) => {
	const [open, setOpen] = useState(false);
	const userWritePermission = useAdminEntitlements("clientsAndSites:sites", "write");

	return (
        <div className={expanded ? "view_client-sidebar open" : "view_client-sidebar close"}>
			<div className="user_view">
				<button
					onClick={() => {
						setSelectedSiteId(null);
						// removeCircleFromMarkers();
					}}>
					<ArrowBackOutlinedIcon htmlColor="#000000" />
					Sites
				</button>
			</div>
			<div className="horizontal_padding">
				<small>Site Name</small>
				<h6>{selectedSite.clientName ?? "-"}</h6>
			</div>
			<div className="horizontal_padding">
				<small>Address</small>
				<h6>{selectedSite.address ?? "-"}</h6>
			</div>
			<div className="horizontal_padding">
				<small>Description</small>
				<h6 className="description">{selectedSite.description ?? "-"}</h6>
			</div>
			<div className="horizontal_padding">
				<small>Site ID</small>
				<h6>{selectedSite.internalClientID ?? "-"}</h6>
			</div>
			<div className="horizontal_padding">
				<small>Email </small>
				<h6>{selectedSite.email ?? "-"}</h6>
			</div>
			<div className="two_column">
				<div>
					<small>Contact person</small>
					<h6 className="small">{selectedSite.proprietorName ?? "-"}</h6>
				</div>
				<div>
					<small>Contact number</small>
					<h6 className="small">{selectedSite.phoneNumber ?? "-"}</h6>
				</div>
			</div>
			<div className="two_column">
				<div>
					<small>City</small>
					{selectedSite.city === "" ? "-" : selectedSite.city ?? "-"}
				</div>
				<div>
					<small>Pin code</small>
					<h6 className="small">{selectedSite.pinCode ?? "-"}</h6>
				</div>
			</div>
			<div className="two_column">
				<div>
					<small>Category</small>
					aaaaa
				</div>
				<div>
					<small>Radius (In meters)</small>
					<h6 className="small">{selectedSite.radius ?? "-"}</h6>
				</div>
			</div>
			<div className="two_column">
				<div>
					<small>Latitude</small>
					<h6 className="small">{selectedSite.latitude ?? "-"}</h6>
				</div>
				<div>
					<small>Longitude</small>
					<h6 className="small">{selectedSite.longitude ?? "-"}</h6>
				</div>
			</div>
			<div className="filter__buttons">
				{userWritePermission ? (
					(<Link prefetch={false} href={`/clients_sites/sites/site/${selectedSite.clientID}`}>

                        <EditOutlinedIcon />Edit
                    </Link>)
				) : (
					<button disabled className="add mui-button">
						<EditOutlinedIcon />
						Edit
					</button>
				)}
				<button disabled={!userWritePermission || isDeleting} type="button" onClick={() => setOpen(true)}>
					{isDeleting ? (
						<CircularProgress style={{ width: "20px", height: "20px", color: "white" }} />
					) : (
						<>
							<DeleteOutlineOutlinedIcon /> Delete
						</>
					)}
				</button>
			</div>
			{open && (
				<DeleteModal
					deleteHandler={() => deleteHandler(selectedSite.clientID)}
					handleClose={() => setOpen(false)}
					name={selectedSite.clientName ?? ""}
				/>
			)}
		</div>
    );
};

export default SiteDetailedSidebarView;
