import Link from "next/link";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { FC } from "react";
type Props = {
	title: string;
	description?: string;
	link?: `/${string}`;
	action?: () => void;
	buttonText: string;
	height?: string;
	userWritePermission:any;
};
const NoDataFound: FC<Props> = ({ title, description, link, action, buttonText, height = "400px", userWritePermission }) => (
	<div className="datagrid-table no_data_found" style={{ height: `${height}` }}>
		<div>
			<img src="/images/no-data-found.png" alt="" />
			<h2>{title}</h2>
			{description && description.length > 0 && <p>{description}</p>}
			{link && typeof link === "string" && (
				(<Link
                    prefetch={false}
                    href={link}
                    style={{pointerEvents:!userWritePermission ? "none":"auto", cursor:!userWritePermission ? "not-allowed":"pointer", backgroundColor:!userWritePermission ? "#dfdfdf":"#1976d2"}}>

                    <AddRoundedIcon />
                    {buttonText}

                </Link>)
			)}
			{action && (
				<button type="button" onClick={() => action()} disabled={!userWritePermission}>
					<AddRoundedIcon />
					{buttonText}
				</button>
			)}
		</div>
	</div>
);

export default NoDataFound;
