import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { signInWithCustomToken } from "firebase/auth";
import Router from "next/router";
import { ROUTES } from "../../@types";
import "moment-timezone";
import _ from "lodash";
import firebaseAuth from "../../firebase";
import { ADMIN_ID, FIREBASE_ID_TOKEN, LOCAL_COMPANY_ID, PRODUCT_ID } from "./../../constants/index";
axios.defaults.withCredentials = true;
type INITIAL_STATE = {
	adminDetails: {
		adminID: null | number;
		companyID: null | number;
		email: null | string;
		firstname: null | string;
		lastname: null | string;
		parentID: null | number;
		logoUploaded: null | number;
		execVisibility: number | null;
		roleID: string;
	};
	isLoggedIn: boolean;
	role: string | null;
	loading: boolean;
	currencyCode: string | null;
	tz: string;
	flag: 0 | 1;
	timeZone: null | string;
	isAccountExpired: boolean;
	accountExpiredDate?: string;
	productIDs: any;
};

const initialState: INITIAL_STATE = {
	adminDetails: {
		adminID: null,
		companyID: null,
		email: null,
		logoUploaded: null,
		firstname: null,
		lastname: null,
		parentID: null,
		execVisibility: null,
		roleID: "",
	},
	isLoggedIn: false,
	role: null,
	tz: "",
	flag: 0,
	timeZone: null,
	isAccountExpired: false,
	accountExpiredDate: "",
	currencyCode: null,
	loading: true,
	productIDs: [],
};
// runs on every page change
// we generates promise lifecycle action types based on the action type prefix that is passed in, and returns a thunk action creator that will run the promise callback and dispatch the lifecycle actions based on the returned promise

export const authCheckState = createAsyncThunk("user/authCheckState", async (user: any, thunkAPI) => {
	try {
		const token = localStorage.getItem(FIREBASE_ID_TOKEN);
		const subscriptionStatusCheckTime = localStorage.getItem("subscriptionStatusCheckTime");

		return new Promise(async (resolve, reject) => {
			if (!token) return reject("No token found");
			let currentUser = user;
			if (user?.isAccountExpired) {
				localStorage.removeItem(FIREBASE_ID_TOKEN);
				localStorage.removeItem(LOCAL_COMPANY_ID);
				localStorage.removeItem(ADMIN_ID);
				await axios.get(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/logout`);
				return reject("User Logged out");
			}
			//fetch_current user because it doesn't exists

			if (subscriptionStatusCheckTime) {
				const timeDiff = Math.abs(new Date().getTime() - new Date(subscriptionStatusCheckTime).getTime());
				const diffHours = Math.ceil(timeDiff / (1000 * 60));
				if (diffHours > 1) {
					try {
						const request = await axios.get(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/admin`);
						if (typeof request.data === "string" || request.data?.data?.isAccountExpired) {
							localStorage.removeItem(FIREBASE_ID_TOKEN);
							localStorage.removeItem(LOCAL_COMPANY_ID);
							localStorage.removeItem(ADMIN_ID);
							await axios.get(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/logout`);
							return reject("User Logged out");
						}
						currentUser = request.data.data;
						localStorage.setItem(LOCAL_COMPANY_ID, currentUser?.adminDetails?.companyID);
						localStorage.setItem(ADMIN_ID, currentUser?.adminDetails?.adminID);
						localStorage.setItem("subscriptionStatusCheckTime", new Date().toISOString());
					} catch (error) {
						reject("Backend Error");
					}
				}
			} else {
				try {
					const request = await axios.get(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/admin`);
					if (typeof request.data === "string" || request.data?.data?.isAccountExpired) {
						localStorage.removeItem(FIREBASE_ID_TOKEN);
						localStorage.removeItem(LOCAL_COMPANY_ID);
						localStorage.removeItem(ADMIN_ID);
						await axios.get(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/logout`);
						return reject("User Logged out");
					}
					currentUser = request.data.data;
					localStorage.setItem(LOCAL_COMPANY_ID, currentUser?.adminDetails?.companyID);
					localStorage.setItem(ADMIN_ID, currentUser?.adminDetails?.adminID);
					localStorage.setItem("subscriptionStatusCheckTime", new Date().toISOString());
				} catch (error) {
					reject("Backend Error");
				}
			}

			firebaseAuth.onAuthStateChanged(async (user: any) => {
				if (user) {
					await user.getIdToken().then((idToken: string) => {
						localStorage.setItem(FIREBASE_ID_TOKEN, idToken);
					});
				}
			});

			// @ts-ignore
			if (token) return resolve(currentUser);
		});
	} catch (error) {
		return thunkAPI.rejectWithValue("No token found");
	}
});

export const signInFirebase = createAsyncThunk("user/signInFirebase", async (firebaseCustomToken: string, thunkAPI) => {
	try {
		return new Promise(async (resolve, reject) => {
			signInWithCustomToken(firebaseAuth, firebaseCustomToken)
				.then(async (userCredential) => {
					const user: any = userCredential.user;
					const idToken = await user.getIdToken(true);
					localStorage.setItem(FIREBASE_ID_TOKEN, idToken);
					if (!idToken) reject("No token found");
					// @ts-ignore
					else resolve(idToken);
				})
				.catch((error) => {
					const errorMessage = error.message;
					reject(errorMessage);
				});
		});
	} catch {
		return thunkAPI.rejectWithValue("Not able to firebase sign in");
	}
});

export const logoutAllSystem = createAsyncThunk("user/logoutAllSystem", async (_, thunkAPI) => {
	try {
		return await axios.get(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/logout`);
	} catch (error) {
		return thunkAPI.rejectWithValue("Error logging out");
	}
});

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		removeCredentials: (state = initialState) => {
			if (!Router.asPath?.includes(ROUTES.LOGIN) && !Router.asPath?.includes("/forgot-password")) {
				Router.push(ROUTES.LOGIN + "?redirect=" + Router.asPath);
			}
			localStorage.removeItem(FIREBASE_ID_TOKEN);
			localStorage.removeItem(LOCAL_COMPANY_ID);
			localStorage.removeItem(ADMIN_ID);
			state.adminDetails.adminID = null;
			state.adminDetails.companyID = null;
			state.adminDetails.email = null;
			state.adminDetails.logoUploaded = null;
			state.adminDetails.firstname = null;
			state.adminDetails.lastname = null;
			state.adminDetails.parentID = null;
			state.adminDetails.execVisibility = null;
			state.currencyCode = null;
			state.timeZone = null;
			state.tz = "";
			state.isLoggedIn = false;
			state.role = null;
			state.accountExpiredDate = "";
			state.adminDetails.roleID = "";
			state.productIDs = [];

			if (Router.asPath?.includes(ROUTES.LOGIN)) state.loading = false;
		},
		addUserDetails: (state, action) => {
			if (!action.payload) return;
			const userData = action.payload;
			localStorage.setItem(LOCAL_COMPANY_ID, userData.adminDetails.companyID);
			localStorage.setItem(ADMIN_ID, userData.adminDetails.adminID);
			if (userData.productIDs && userData.productIDs.length > 0) {
				const savedProductID = localStorage.getItem(PRODUCT_ID);
				if (
					!["1", "2", "5", "6", "7", "8", "9"].includes(savedProductID || "") ||
					!userData.productIDs.includes(savedProductID || "")
				) {
					const maxProductID = Math.max(...userData.productIDs.map(Number));
					localStorage.setItem(PRODUCT_ID, maxProductID.toString());
				}
			}
			state.adminDetails.adminID = userData.adminDetails.adminID;
			state.adminDetails.companyID = userData.adminDetails.companyID;
			state.adminDetails.email = userData.adminDetails.email;
			state.adminDetails.logoUploaded = userData.adminDetails.logoUploaded;
			state.adminDetails.firstname = userData.adminDetails.firstname;
			state.adminDetails.lastname = userData.adminDetails.lastname;
			state.adminDetails.parentID = userData.adminDetails.parentID;
			state.adminDetails.execVisibility = userData.adminDetails.execVisibility;
			state.role = userData.role;
			state.currencyCode = userData.currencyCode;
			state.timeZone = userData.timeZone;
			state.tz = userData.tz;
			state.isLoggedIn = true;
			state.loading = false;
			state.accountExpiredDate = userData.accountValidTill;
			state.adminDetails.roleID = userData.adminDetails.roleID;
			state.productIDs = userData.productIDs;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(authCheckState.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(authCheckState.fulfilled, (state, action) => {
			userSlice.caseReducers.addUserDetails(state, action);
			state.isLoggedIn = true;
			if (!Router.asPath?.includes(ROUTES.LOGIN)) state.loading = false;
			else Router.push(ROUTES.HOME);
		});
		builder.addCase(authCheckState.rejected, (state) => {
			state.loading = false;
			userSlice.caseReducers.removeCredentials(state);
		});
		// eslint-disable-next-line no-unused-vars
		builder.addCase(logoutAllSystem.pending, (state: any) => {});
		builder.addCase(logoutAllSystem.fulfilled, (state) => {
			userSlice.caseReducers.removeCredentials(state);
		});

		builder.addCase(signInFirebase.fulfilled, (state) => {
			state.isLoggedIn = true;
			state.loading = false;
			if (Router.asPath?.includes(ROUTES.LOGIN)) {
				const { redirect, selectedDate } = Router.query;
				if (redirect && typeof redirect === "string" && redirect.length > 0) {
					let callbackUrl = redirect;
					if (selectedDate && selectedDate.length > 0) callbackUrl += "&selectedDate=" + selectedDate;
					Router.push(callbackUrl);
				} else {
					Router.push(ROUTES.HOME);
				}
			}
		});
		builder.addCase(signInFirebase.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(signInFirebase.rejected, (state) => {
			state.loading = false;
		});
	},
});

export const { removeCredentials, addUserDetails } = userSlice.actions;

export default userSlice.reducer;
