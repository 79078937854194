import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Dispatch, FC, SetStateAction } from "react";
import { CONVEYANCE_FILTER_COLUMNS, CONVEYANCE_TYPE, PROFILES_DATA } from "../../../@types";
import IndividualFilter from "../../common/datagrid/IndividualFilter";
type COLUMN_TITLES =
	| "Employee Name"
	| "Date Of Conveyance"
	| "Distance Calculated"
	| "Distance Claimed"
	| "Amount Claimed"
	| "Pending With"
	| "Team";
type Props = {
	numFilters: number;
	setFilterOpen: Dispatch<SetStateAction<boolean>>;
	filters: Record<CONVEYANCE_FILTER_COLUMNS, string[]>;
	setFilters: Dispatch<SetStateAction<Record<CONVEYANCE_FILTER_COLUMNS, string[]>>>;
	requests: CONVEYANCE_TYPE[];
	localStorageKey: string;
	resetFilters: () => void;
	profiles: PROFILES_DATA[];
};
const FilterConveyance: FC<Props> = ({
	numFilters,
	setFilterOpen,
	requests,
	setFilters,
	filters,
	localStorageKey,
	resetFilters,
	profiles,
}) => (
	// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
	<form
		onSubmit={(e) => e.preventDefault()}
		onClick={(e) => e.stopPropagation()}
		onKeyPress={(e) => e.stopPropagation()}>
		<div className="filter__header">
			<h5>
				<FilterAltOutlinedIcon />
				Filters
				<span className="right-notification center" style={{ display: "flex", padding: 0 }}>
					{numFilters}
				</span>
			</h5>
			<button onClick={() => setFilterOpen(false)}>
				<CloseSharpIcon htmlColor="#42444A" />
			</button>
		</div>
		<div className="accordion__group">
			<IndividualFilter<COLUMN_TITLES, CONVEYANCE_FILTER_COLUMNS>
				filters={filters}
				name="name"
				title="Employee Name"
				data={requests.map((request) => request.firstName + " " + request.lastName)}
				ids={requests.map((request) => request.firstName + " " + request.lastName)}
				setFilters={setFilters}
				localStorageKey={localStorageKey}
			/>

			<IndividualFilter<COLUMN_TITLES, CONVEYANCE_FILTER_COLUMNS>
				filters={filters}
				name="distanceClaimed"
				title="Distance Claimed"
				data={requests.map((request) => (request.distanceClaimed ? JSON.stringify(request.distanceClaimed) : ""))}
				ids={requests.map((request) => (request.distanceClaimed ? JSON.stringify(request.distanceClaimed) : ""))}
				setFilters={setFilters}
				localStorageKey={localStorageKey}
			/>

			<IndividualFilter<COLUMN_TITLES, CONVEYANCE_FILTER_COLUMNS>
				filters={filters}
				name="amountClaimed"
				title="Amount Claimed"
				data={requests.map((request) => (request.amountClaimed ? JSON.stringify(request.amountClaimed) : ""))}
				ids={requests.map((request) => (request.amountClaimed ? JSON.stringify(request.amountClaimed) : ""))}
				setFilters={setFilters}
				localStorageKey={localStorageKey}
			/>

			<IndividualFilter<COLUMN_TITLES, CONVEYANCE_FILTER_COLUMNS>
				filters={filters}
				name="pendingWith"
				title="Pending With"
				data={requests.map((request) => request.pendingWith?.adminName || "")}
				ids={requests.map((request) => request.pendingWith?.adminName || "")}
				setFilters={setFilters}
				localStorageKey={localStorageKey}
			/>

			<IndividualFilter<COLUMN_TITLES, CONVEYANCE_FILTER_COLUMNS>
				filters={filters}
				name="profileID"
				title="Team"
				setFilters={setFilters}
				data={profiles.map((profile) => profile.profileName)}
				ids={profiles.map((profile) => profile.profileID)}
				localStorageKey={localStorageKey}
			/>
		</div>
		<div className="filter__buttons">
			<button onClick={resetFilters} type="reset" disabled={numFilters === 0}>
				Reset
			</button>
		</div>
	</form>
);

export default FilterConveyance;
