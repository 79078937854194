// react
import React, { useState, useEffect } from "react";
// mui
import { makeStyles } from "@mui/styles";
import { Button, Grid } from "@mui/material";
// libraries
import axios from "axios";
// import { setLogoUploaded } from "../../redux/slices/userSlice";
// redux
import { useSelector, useDispatch } from "react-redux";
import { addUserDetails, showNotification } from "../../redux";
// utils
import useAdminEntitlements from "../useAdminEntitlements";

const useStyles = makeStyles(() => ({
	uploadButton: {
		marginBottom: 2,
		lineHeight: 1.2,
		fontSize: 16,
	},
	imagePreview: {
		width: "100%",
		height: "auto",
		marginTop: 2,
	},
}));

function LogoUploadSection() {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [previewUrl, setPreviewUrl] = useState<string | null>(null);
	const user = useSelector((state: any) => state.user);
	const userWritePermission = useAdminEntitlements("settings:account", "write");

	const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files && event.target.files[0];

		if (file) {
			const fileSize = file.size / 1024; // file size in KB
			const maxSize = 250; // maximum size in KB

			if (fileSize > maxSize) {
				dispatch(
					showNotification({
						message: "File size is too large. Maximum size allowed is 250KB",
						severity: "error",
					})
				);
				return;
			}

			setSelectedFile(file);
			setPreviewUrl(URL.createObjectURL(file));
		}
	};

	const handleUploadClick = () => {
		const formData = new FormData();
		formData.append("companyLogo", selectedFile!);
		try {
			axios
				.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/setCompanyLogo`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.then((response) => {
					if (response.status === 200) {
						dispatch(showNotification({ message: "File uploaded successfully", severity: "success" }));
						dispatch(
							addUserDetails({
								...user,
								adminDetails: {
									...user.adminDetails,
									logoUploaded: 1,
								},
							})
						);
						setSelectedFile(null);
					}
				});
		} catch (error) {
			dispatch(showNotification({ message: "Error uploading file", severity: "error" }));
		}
	};

	const handleDeleteClick = () => {
		try {
			axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/removeCompanyLogo`).then((response) => {
				if (response.status === 200) {
					dispatch(showNotification({ message: "File deleted successfully", severity: "success" }));
					dispatch(
						addUserDetails({
							...user,
							adminDetails: {
								...user.adminDetails,
								logoUploaded: 0,
							},
						})
					);
				}
			});
		} catch (error) {
			dispatch(showNotification({ message: "Error deleting file", severity: "error" }));
		}
		setPreviewUrl(null);
	};
	const cacheBuster = Date.now();

	useEffect(() => {
		if (user && user?.adminDetails?.logoUploaded) {
			const s3ImageUrl = `https://s3.ap-south-1.amazonaws.com/upload.permanent.sensestaff.com/companylogo/${user.adminDetails.companyID}.jpeg?cache=${cacheBuster}`;
			setPreviewUrl(s3ImageUrl);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.adminDetails?.logoUploaded]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<input
					accept="image/*"
					id="logo-upload-input"
					type="file"
					style={{ display: "none" }}
					onChange={handleFileSelect}
					disabled={!userWritePermission}
				/>
				<label htmlFor="logo-upload-input">
					<Button
						variant="contained"
						style={{
							backgroundColor: !userWritePermission ? "#dfdfdf" : "#1976d2",
							color: "#fff",
							pointerEvents: "auto",
							cursor: !userWritePermission ? "not-allowed" : "pointer",
						}}
						component="span"
						className={classes.uploadButton}
						disabled={!userWritePermission}>
						Select File
					</Button>
				</label>
			</Grid>
			{previewUrl && (
				<Grid item xs={12}>
					<img src={previewUrl} style={{ height: "100px" }} alt="Logo Preview" className={classes.imagePreview} />
				</Grid>
			)}

			{selectedFile && (
				<Grid item xs={12}>
					<Button variant="contained" style={{ backgroundColor: "#1976d2", color: "#fff" }} onClick={handleUploadClick}>
						Upload
					</Button>
				</Grid>
			)}
			{user?.adminDetails?.logoUploaded === 1 && !selectedFile && previewUrl && (
				<Grid item xs={12}>
					<Button variant="contained" style={{ backgroundColor: "red", color: "#fff" }} onClick={handleDeleteClick}>
						Delete
					</Button>
				</Grid>
			)}
		</Grid>
	);
}

export default LogoUploadSection;
