import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import { FC } from "react";
import { Layout, SubmoduleTabs } from "..";
import { SUBMODULE_DATA } from "../../@types";
import NotAuthorized from "../common/NotAuthorized";
import useAdminEntitlements from "../useAdminEntitlements";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { ROOT_STATE } from "../../redux";
const formsSubmoduleData: SUBMODULE_DATA[] = [
	{
		label: "Forms",
		icon: <DocumentScannerOutlinedIcon />,
		path: "/form",
	},
	{
		label: "Forms Responses",
		icon: <DocumentScannerOutlinedIcon />,
		path: "/form/responses",
	},
];
type Props = {
	title: string;
	children: any;
	tab: number;
};
const FormLayout: FC<Props> = ({ title, children, tab }) => {
	const {
		susbscriptionMetadata: { data: subsMetadata },
	} = useSelector((state: ROOT_STATE) => ({
		susbscriptionMetadata: state.susbscriptionMetadata,
	}));
	if (subsMetadata?.forms === 0) {
		formsSubmoduleData.forEach((item) => {
			if (item) item.hide = false;
		});
	}
	const router = useRouter();
	const userReadPermission = useAdminEntitlements("forms:forms", "read");
	const userReadPermissionFR = useAdminEntitlements("forms:formResponses", "read");

	const isFormsRead = router.pathname === "/form" && userReadPermission;
	const isFormsCreateRead =
		router.pathname.includes("/form") && !(router.pathname === "/form/responses") && userReadPermission;
	const isFormResponsesRead = router.pathname === "/form/responses" && userReadPermissionFR;

	const { roleID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);
	const filteredFormSubmoduleData = formsSubmoduleData.filter((item) => item && !item.hide);

	return (
		<Layout title={title}>
			<SubmoduleTabs submoduleData={filteredFormSubmoduleData} tab={tab} />
			{isFormsRead || isFormResponsesRead || isFormsCreateRead ? (
				children
			) : (
				<NotAuthorized roleID={roleID} moduleName="forms" />
			)}
		</Layout>
	);
};

export default FormLayout;
