import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { EXPENSE_SETTING } from "../../../@types";
import { addExpenseCategory, deleteExpenseCategory } from "../../../api/settings";
import { APP_DISPATCH, showNotification } from "../../../redux";
import useAdminEntitlements from "../../useAdminEntitlements";
import AddModal from "../AddModal";
import ReadOnlyButton from "../../common/ReadOnlyButton";
type Props = {
	expense: EXPENSE_SETTING | null;
	setExpense: Dispatch<SetStateAction<EXPENSE_SETTING | null>>;
	roleID:string;
	subsMetadata:any;
};
const ExpenseCategories: FC<Props> = ({ expense, setExpense, roleID,subsMetadata}) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const [expandModal, setExpandModal] = useState(false);
	const userWritePermission = useAdminEntitlements("settings:expense", "write");

	const deleteCategory = async (id: string) => {
		if (!id || id.length === 0 || !expense) return;
		if(expense.expenseCategories.length === 1)
		{
			dispatch(showNotification({ message: "At least one category must remain undeletable", severity: "error" }));
			return;
		}
		const updatedExpenses = { ...expense };
		const index = updatedExpenses.expenseCategories.findIndex((cat) => cat.expenseCategoryID === id);
		if (index === -1) return;
		updatedExpenses.expenseCategories[index].deleted = true;
		const { success, message } = await deleteExpenseCategory([updatedExpenses.expenseCategories[index]]);
		dispatch(showNotification({ message: message, severity: success ? "success" : "error" }));
		if (!success) return;
		updatedExpenses.expenseCategories.splice(index, 1);
		setExpense(updatedExpenses);
	};
	const checkIfExpenseCategoryDuplicate = (name:string, expenses:any) => {
		let isDuplicatePresent = false;
		expenses.expenseCategories?.forEach((x: any) => {
			const string1 = x.category.replace(/\s+/g, "").toLowerCase(); //removing spaces in strings
			const string2 = name.replace(/\s+/g, "").toLowerCase();
			if (string1 === string2) {
				// eslint-disable-next-line no-param-reassign
				isDuplicatePresent = true;
			}
		});
		return isDuplicatePresent;
	};
	const addCategory = async (name: string) => {
		if (!name || name.length === 0 || !expense) return;
		const updatedExpenses = { ...expense };
		if(checkIfExpenseCategoryDuplicate(name, updatedExpenses)){
			dispatch(
				showNotification({
					message: `Expense category ${name} already exist. Please create other expense category.`,
					severity: "error",
				})
			);
			return;
		}
		const newExpense = {
			category: name,
			expenseCategoryID: uuid(),
		};
		const { success, message } = await addExpenseCategory([newExpense]);
		dispatch(showNotification({ message: message, severity: success ? "success" : "error" }));
		if (!success) return;
		updatedExpenses.expenseCategories.push(newExpense);
		setExpense(updatedExpenses);
	};
	return (
		<div className="custom-field-settings">
			<div className="custom-field-top">
				<div className="section">
					<div className="section-part">
						<p className="title">Expense categories</p>
						{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="settings"/> : null}
					</div>
					<p className="subtitle">Create Categories of expenses to use for expense creation for Expense Limits. The same will apply to advances as well.</p>
				</div>
				<div className="action-buttons">
					<button disabled={!userWritePermission} className="add mui-button" onClick={() => setExpandModal(true)}>
						<AddIcon /> <div>Add</div>
					</button>
				</div>
			</div>
			<ul className="expense_categories">
				{expense?.expenseCategories?.map((item) => (
					<li className="expense_category" key={item.expenseCategoryID}>
						<p>{item.category}</p>
						<button disabled={!userWritePermission} onClick={() => deleteCategory(item.expenseCategoryID)}>
							<ClearIcon />
						</button>
					</li>
				))}
			</ul>
			{expandModal && (
				<AddModal<EXPENSE_SETTING | null>
					open={expandModal}
					onClose={() => setExpandModal(false)}
					setSelectedSetting={setExpense}
					title="Add Expense Category"
					placeholder="Enter category name"
					type="text"
					submitHandler={(i) => addCategory(i as string)}
					subsMetadata={subsMetadata}
					name="expense categories"
					profiles={expense?.expenseCategories}
				/>
			)}
		</div>
	);
};

export default ExpenseCategories;
