import React, { FC } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
type Props = {
	file: any;
	i: number;
	user?: any;
	isValidLink: boolean;
};
const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: "#42444a",
		opacity: 1,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "rgb(66,68,74, 1)",
		padding: "0.3rem",
		fontSize: "12px",
		lineHeight: "18px",
	},
}));
const FileCard: FC<Props> = ({ file, i, user, isValidLink }) => (
	<>
		{!isValidLink ? (
			<div
				key={file.fileID + i}
				style={{
					display: "flex",
					flexDirection: "column",
					backgroundColor: "#f5f5f5",
					padding: "10px",
					borderRadius: "5px",
				}}>
				<div style={{ display: "grid", gridTemplateColumns: "0.2fr 1fr", alignItems: "center", gap: "10px" }}>
					<img
						src="/images/brokenImage.png"
						style={{ height: "40px", width: "40px", marginRight: "10px", borderRadius: "5px" }}
					/>
					<BootstrapTooltip
						title={
							"Image has not been uploaded from phone, please ask the user to Sync from the app."
						}>
						<p style={{ color: "#42444a", cursor: "help", marginTop: "0px" }}>Broken File</p>
					</BootstrapTooltip>
				</div>
			</div>
		) : (
			<a
				key={file.fileID + i}
				href={
					"https://s3.ap-south-1.amazonaws.com/upload.sensestaff.com/" +
					user?.adminDetails?.companyID +
					"/" +
					file.fileURL
				}
				download={file.fileName ?? "downloaded_file"}
				rel="noreferrer"
				target="_blank"
				style={{
					display: "flex",
					flexDirection: "column",
					backgroundColor: "#f5f5f5",
					padding: "10px",
					borderRadius: "5px",
					minWidth: "250px",
				}}>
				<div style={{ display: "grid", gridTemplateColumns: "0.2fr 1fr", alignItems: "center", gap: "10px" }}>
					<div style={{display:"flex", alignItems:"center", justifyContent:"center"}}><DownloadIcon sx={{ fontSize: "22px" }} /></div>
					<div>
						<p style={{ color: "#42444a", marginTop:"0px" }}>{`File ${i + 1}`}</p>
						<BootstrapTooltip title={file.description ? file.description : "No description found"}>
							<p style={{ marginTop: "2px", fontSize: "12px", color: "#6c757d", cursor: "help" }}>{`${
								file.description ? `${file.description.length > 30 ? `${file.description.slice(0, 30)}...` : file.description}` : `No description found`
							}`}</p>
						</BootstrapTooltip>
					</div>
				</div>
			</a>
		)}
	</>
);

export default FileCard;
