/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@apollo/client";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { CircularProgress, TextField } from "@mui/material";
import { Dispatch, FC, FormEvent, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import { ORDER_SKU_TYPE, ORDER_TYPE } from "../../../@types";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { UPSERT_ORDER_BATCH } from "../../../schema/orders";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import moment from "moment";

type Props = {
	setExpand: Dispatch<SetStateAction<boolean>>;
	orderItemData: ORDER_TYPE[];
	skuDetails: ORDER_SKU_TYPE;
	fetchOrders: () => void;
	currencySymbol: string;
};
const SkuLevelSideForm: FC<Props> = ({ setExpand, orderItemData, skuDetails, fetchOrders, currencySymbol }) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const [upsert_order_batch, { loading, error }] = useMutation(UPSERT_ORDER_BATCH);
	const [skuRowData, setSkuRowData] = useState<ORDER_SKU_TYPE>(skuDetails);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const name = event.target.name;

		setSkuRowData((prev) => {
			if (name === "price") {
				const price = parseFloat(value);
				const discAmt = parseFloat((((prev.sku.listPrice - price) / prev.sku.listPrice) * 100).toFixed(2));
				const amount = parseFloat((price * prev.quantity).toFixed(2));

				return {
					...prev,
					price: price,
					discAmt: discAmt,
					amount: amount,
				};
			} else if (name === "discAmt") {
				const discAmt = parseFloat(value);
				const price = parseFloat((prev.sku.listPrice - (prev.sku.listPrice * discAmt) / 100).toFixed(2));
				const amount = parseFloat((price * prev.quantity).toFixed(2));

				return {
					...prev,
					price: price,
					discAmt: discAmt,
					amount: amount,
				};
			} else if (name === "quantity") {
				const quantity = parseFloat(value);
				const amount = parseFloat((prev.price * quantity).toFixed(2));

				return {
					...prev,
					quantity: quantity,
					amount: amount,
				};
			}

			return prev;
		});
	};
	//calculations needed for selected sku
	const gstRate = skuRowData && skuRowData.sku && skuRowData.sku.gstRate !== null ? skuRowData.sku.gstRate : 0;
	const subTotal = parseFloat((skuRowData?.quantity * (skuRowData?.price === null ? 0 : skuRowData?.price)).toFixed(2));
	const total = parseFloat(((subTotal * (100 + gstRate)) / 100).toFixed(2));
	const totalTaxAmount = parseFloat(((subTotal * gstRate) / 100).toFixed(2));
	const discount = parseFloat(((skuRowData?.sku?.listPrice ?? 0) - (skuRowData?.price ?? 0)).toFixed(2));
	const discountAmount = parseFloat((skuRowData?.quantity * discount).toFixed(2)); //total discount value

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		// input data format to send
		const inputSKU = orderItemData[0].orderItems.map((item) => {
			if (item.skuID === skuRowData?.skuID) {
				return {
					skuID: skuRowData?.skuID,
					amount: subTotal,
					price: skuRowData?.price,
					quantity: skuRowData?.quantity,
					productDescription: skuRowData?.productDescription,
					discAmt: skuRowData?.discAmt,
					totalTaxAmount: totalTaxAmount,
					discountPercentage: skuRowData.discAmt,
					discountAmount: skuRowData.amount,
				};
			} else {
				return {
					skuID: item.skuID,
					amount: item.amount,
					price: item.price,
					quantity: item.quantity,
					productDescription: item.productDescription,
					discAmt: item.discAmt,
					totalTaxAmount: item.totalTaxAmount,
					discountPercentage: item.discAmt,
					discountAmount: item.amount,
				};
			}
		});
		const inputCustomFieldsComplex = orderItemData[0].customFieldsComplex?.map((item) => ({
			fieldID: item.fieldID,
			fieldValue: item.fieldValue,
			selectedOptions: item.selectedOptions,
		}));

		const orderItemsWithTotalTax =
			inputSKU && Array.isArray(inputSKU) ? inputSKU.reduce((acc, item) => acc + item.totalTaxAmount, 0) : 0;

		const orderItemsWithOrderValue =
			inputSKU && Array.isArray(inputSKU) ? inputSKU.reduce((acc, item) => acc + item.amount, 0) : 0;

		const inputRow = {
			orderID: orderItemData[0].orderID,
			taskID: orderItemData[0].taskID,
			clientID: orderItemData[0].clientID,
			lat: orderItemData[0].lat,
			lon: orderItemData[0].lon,
			recipients: orderItemData[0].recipients,
			orderItems: inputSKU,
			orderValue: orderItemsWithOrderValue,
			taxValue: orderItemsWithTotalTax,
			payments: orderItemData[0].payments,
			paymentCollected: orderItemData[0].paymentCollected,
			customFieldsComplex: inputCustomFieldsComplex,
			createdTs: orderItemData[0].createdTs,
			lastModifiedTs: moment().valueOf(),
			otpVerified: orderItemData[0].otpVerified,
		};
		await upsert_order_batch({ variables: { input: [inputRow] } });
		dispatch(
			showNotification({
				message: error ? "SKU details could not be saved" : "SKU details saved successfully",
				severity: error ? "error" : "success",
			})
		);
		setExpand(false);
		fetchOrders();
	};
	return (
		<div className="user_add">
			<div className="user_add-title">
				<h1>SKUs</h1>
				<button onClick={() => setExpand(false)}>
					<CloseOutlinedIcon />
				</button>
			</div>
			<form className="product_add-form" onSubmit={handleSubmit}>
				<Card
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						width: "400px",
						backgroundColor: "#E1F5FE",
					}}>
					<CardContent>
						<Typography variant="h6">{skuRowData?.sku?.skuName || ""}</Typography>
						<Typography variant="body2" color="text.secondary">
							{skuRowData?.sku?.skuDescription || ""}
						</Typography>
					</CardContent>
					<CardContent sx={{ marginLeft: "auto" }}>
						<Typography variant="h6">{`${currencySymbol} ${skuRowData?.sku?.listPrice || ""}`}</Typography>
						<Typography variant="body2" color="text.secondary">
							{`+(${gstRate}% of Tax)`}
						</Typography>
					</CardContent>
				</Card>
				<TextField
					required
					type="number"
					name="price"
					inputProps={{ min: 0, step: "0.01" }}
					placeholder="Selling Price"
					label="Selling Price"
					onChange={handleChange}
					value={skuRowData?.price === null ? 0 : skuRowData?.price}
				/>
				<TextField
					required
					type="number"
					name="quantity"
					placeholder="Quantity"
					inputProps={{ min: 0, step: "0.1" }}
					label="Quantity"
					onChange={handleChange}
					value={skuRowData?.quantity}
				/>
				<TextField
					required
					type="number"
					name="discAmt"
					inputProps={{ min: 0, step: "0.01", max: skuRowData?.sku?.discRate || "" }}
					placeholder="Discount(%)"
					label="Discount(%)"
					onChange={handleChange}
					value={skuRowData?.discAmt}
					helperText={
						skuRowData?.sku?.discRate === null
							? "Discount%"
							: `Maximum ${skuRowData?.sku?.discRate}% discount can be offered`
					}
				/>
				<Card sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: "400px" }}>
					<CardContent>
						<Typography variant="subtitle1">Sub Total</Typography>
						<Typography variant="subtitle1">Discount</Typography>
						<Typography variant="body2" color="text.secondary">
							{`Tax(${skuRowData?.sku?.gstRate === null ? 0 : skuRowData?.sku?.gstRate})%`}
						</Typography>
						<Typography variant="subtitle1" sx={{ marginTop: "20px" }}>
							Total
						</Typography>
					</CardContent>
					<CardContent sx={{ marginLeft: "auto" }}>
						<Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>{`${currencySymbol} ${subTotal}`}</Typography>
						<Typography
							variant="subtitle1"
							sx={{ fontWeight: "bold" }}>{`${currencySymbol} ${discountAmount}`}</Typography>
						<Typography variant="body2" color="text.secondary">
							{`${currencySymbol} ${totalTaxAmount}`}
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{ marginTop: "20px", fontWeight: "bold" }}>{`${currencySymbol} ${total}`}</Typography>
					</CardContent>
				</Card>
				<div className="submit_buttons">
					<button type="reset" disabled={loading} onClick={() => setExpand(false)}>
						Cancel
					</button>
					<button type="submit" disabled={loading}>
						{loading ? <CircularProgress style={{ width: "20px", height: "20px", marginRight: "10px" }} /> : "Save"}
					</button>
				</div>
			</form>
		</div>
	);
};

export default SkuLevelSideForm;
