// react
import { Dispatch, FC, SetStateAction, useRef } from "react";
// mui
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// query
import { ROUTE_DATA } from "../../../@types";
// utils
import { TIME_FULL_FORMAT } from "../../../constants";
// library
import dayjs from "dayjs";
import _cloneDeep from "lodash/cloneDeep";

type Props = {
	currentRoute: ROUTE_DATA;
	setCurrentRoute: Dispatch<SetStateAction<ROUTE_DATA>>;
};
const ReviewRoutes: FC<Props> = ({ currentRoute, setCurrentRoute }) => {
	const dragItem = useRef<number | null>();
	const dragOverItem = useRef<number | null>();
	const dragStart = (position: number) => {
		dragItem.current = position;
	};

	const dragEnter = (position: number) => {
		dragOverItem.current = position;
	};
	const removeRoute = (index: number) => {
		const newRoutes = [...currentRoute.routeDetails];
		newRoutes.splice(index, 1);
		const updatedCurrentRoute = _cloneDeep(currentRoute);
		updatedCurrentRoute.routeDetails = newRoutes;
		setCurrentRoute(updatedCurrentRoute);
	};
	const changeTime = (index: number, newValue: string | null, type: "endTime" | "startTime") => {
		if (!newValue) return;
		const newRoutes = [...currentRoute.routeDetails];
		newRoutes[index][type] = newValue;
		const updatedCurrentRoute = _cloneDeep(currentRoute);
		updatedCurrentRoute.routeDetails = newRoutes;
		setCurrentRoute(updatedCurrentRoute);
	};

	const drop = () => {
		if (dragItem.current === null || dragOverItem.current === null) return;
		const copyListItems = [...currentRoute.routeDetails];
		const dragItemContent = copyListItems[dragItem.current!];
		copyListItems.splice(dragItem.current!, 1);
		copyListItems.splice(dragOverItem.current!, 0, dragItemContent);
		dragItem.current = null;
		dragOverItem.current = null;
		const updatedCurrentRoute = _cloneDeep(currentRoute);
		updatedCurrentRoute.routeDetails = copyListItems;
		setCurrentRoute(updatedCurrentRoute);
	};

	return (
		<div className="review_routes_summary">
			<h2>Review Summary ({currentRoute?.routeDetails?.length || 0})</h2>
			<ul>
				{Array.isArray(currentRoute.routeDetails) &&
					currentRoute.routeDetails.length > 0 &&
					currentRoute.routeDetails.map((item, i) => (
						<li
							draggable
							key={i + "-" + item.client}
							onDragStart={() => dragStart(i)}
							onTouchStart={() => dragStart(i)}
							onDragEnter={() => dragEnter(i)}
							onTouchMove={() => dragEnter(i)}
							onTouchEnd={() => drop()}
							onDragEnd={drop}>
							<div className="details">
								<div className="info">
									<div className="drag_icon">
										<DragIndicatorIcon />
									</div>
									<div className="header">
										<h4>{item.client.clientName ?? "-"}</h4>
										<span className={`role_badge ${item.client.employeeID === -2 ? "Site" : "Client"}`}>
											{item.client.employeeID === -2 ? "Site" : "Client"}
										</span>
									</div>
									<address>{item.client.address}</address>
								</div>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<TimePicker
										label="Start Time"
										value={item.startTime ? dayjs(item.startTime, TIME_FULL_FORMAT) : null}
										onChange={(newValue) => changeTime(i, dayjs(newValue).format(TIME_FULL_FORMAT), "startTime")}
										slotProps={{ textField: { size: "small", sx: { width: 180 } } }}
										ampm
									/>
								</LocalizationProvider>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<TimePicker
										label="End Time"
										value={item.endTime ? dayjs(item.endTime, TIME_FULL_FORMAT) : null}
										onChange={(newValue) => changeTime(i, dayjs(newValue).format(TIME_FULL_FORMAT), "endTime")}
										slotProps={{ textField: { size: "small", sx: { width: 180 } } }}
										ampmInClock
									/>
								</LocalizationProvider>
								<button type="button" onClick={() => removeRoute(i)}>
									<DeleteIcon htmlColor="#F54747" />
								</button>
							</div>
						</li>
					))}
			</ul>
		</div>
	);
};

export default ReviewRoutes;
