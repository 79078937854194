/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import { Avatar } from "@mui/material";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { SAMPLE_DATA, USER_ROLES } from "../../../@types";
import { findAvatarInitials } from "../../../utils";
import DeleteModal from "../../common/DeleteModal";

type Props = {
	// eslint-disable-next-line no-unused-vars
	openSidebarEditForm: (id: number | null) => void;
	// eslint-disable-next-line no-unused-vars
	openSidebarAddForm: (id: number | null) => void;
	// eslint-disable-next-line no-unused-vars
	openEmployeeList: (id: number | null) => void;
	user_info: SAMPLE_DATA;
	deleteHandler: () => void;
	rolesData: USER_ROLES[];
	userWritePermission:boolean;
	setEmployeeShow:Dispatch<SetStateAction<string>>;
};
const RoleBox: FC<Props> = ({
	user_info,
	openSidebarEditForm,
	openSidebarAddForm,
	openEmployeeList,
	rolesData,
	deleteHandler,
	userWritePermission,
	setEmployeeShow
}) => {
	const role = rolesData.find((r: USER_ROLES) => r.roleID === user_info.roleID);
	const [expandModal, setExpandModal] = useState(false);

	return (
		<div className="role_box" onClick={() => userWritePermission && openSidebarEditForm(user_info.id)}>
			<div className="role_title">
				<aside>
					<Avatar sx={{ backgroundColor: "#D9F0FD", color: "#25A8F4" }}>
						{findAvatarInitials(user_info?.firstname + " " + user_info?.lastname)}
					</Avatar>
				</aside>
				<div>
					<span className={`role_badge ${role?.roleName}`}>{role?.roleName}</span>
					<h4>{user_info?.firstname + " " + user_info?.lastname}</h4>
				</div>
			</div>
			<div className="role_details">
				<p>
				Direct Reportees: <span>{user_info?.directReportee}</span>
					<button
						className="list"
						style={{ color: "#0F45FF", display: user_info?.directReportee > 0 ? "block" :"none" }}
						disabled={!userWritePermission}
						onClick={(e) => {
							e.stopPropagation();
							openEmployeeList(user_info.id);
							setEmployeeShow("directEmployee")
						}}>
						View
					</button>
				</p>
			</div>
			<div className="role_details">
				<p>
				Total Reportees: <span>{user_info?.totalReportee}</span>
					<button
						className="list"
						style={{ color: "#0F45FF", display: user_info?.totalReportee > 0 ? "block" :"none"  }}
						disabled={!userWritePermission}
						onClick={(e) => {
							e.stopPropagation();
							openEmployeeList(user_info.id);
							setEmployeeShow("totalEmployee")

						}}>
						View
					</button>
				</p>
			</div>
			<div className="role_buttons">
				{user_info.parentId && (
					<button
						className="delete"
						disabled={!userWritePermission}
						onClick={(e) => {
							e.stopPropagation();
							setExpandModal(true);
						}}>
						<DeleteOutlineOutlinedIcon />
					</button>
				)}
				<button
					className="edit"
					disabled={!userWritePermission}
					onClick={(e) => {
						e.stopPropagation();
						openSidebarEditForm(user_info.id);
					}}>
					<EditOutlinedIcon />
				</button>
				<button
					className="add mui-button"
					disabled={!userWritePermission}
					onClick={(e) => {
						e.stopPropagation();
						openSidebarAddForm(user_info.id);
					}}>
					<PersonAddAlt1OutlinedIcon />
				</button>
			</div>
			{expandModal && (
				<DeleteModal
					name={user_info?.firstname + " " + user_info?.lastname}
					handleClose={() => setExpandModal(false)}
					deleteHandler={() => deleteHandler()}
				/>
			)}
		</div>
	);
};

export default RoleBox;
