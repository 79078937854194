//react
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
//query
import { useQuery } from "@apollo/client";
//library
import GoogleMapReact from "google-map-react";
//types, utils, components
import { CLIENTS_DATA, ENTITY_METADATA, PARTIAL_CLIENTS_DATA } from "../../../@types";
import { deleteSites } from "../../../api/clients_sites";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { GET_ALL_CLIENTS } from "../../../schema";
import { mapOptions, mapStyles } from "../../../utils";
import ClientDetailedSidebarView from "./ClientDetailedSidebarView";
import ClientList from "./ClientList";
import RenderOutOfBoundsMarkers from "../Sites/RegionOutOfBoundMarkers";
//mui
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { CircularProgress } from "@mui/material";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

type Props = {
	clients: CLIENTS_DATA[];
	loading: boolean;
	setVariables: Dispatch<SetStateAction<any>>;
	setClients: Dispatch<SetStateAction<CLIENTS_DATA[]>>;
	entityMetadata: ENTITY_METADATA[];
	filterCount: number;
};

type Location = {
	lat: number;
	lng: number;
	clientID: string;
	clientName: string;
	radius: number;
};

type PointMarkers = {
	[clientID: string]: {
		marker: any;
		circle: any;
		client: Location;
	};
};

const SITE_MARKER_COLOR = "#243140";
const SITE_LABEL_COLOR = "#000000";
const center = { lat: 22.593684, lng: 81.96288 };
const zoom = 5;

// client markers
let clientMarkers: any = {};

const ClientMapView: FC<Props> = ({ clients, loading, setVariables, setClients, entityMetadata, filterCount }) => {
	const prevSelectedClientId = useRef<string | null>(null);
	const [expanded, setExpanded] = useState(false);
	const [selectedClientId, setSelectedClientId] = useState<null | string>(null);
	const { loading: loadingClients, data: dataClients } = useQuery(GET_ALL_CLIENTS);
	const allClients = useRef<PARTIAL_CLIENTS_DATA[]>([]);
	const isLoading = loadingClients || loading;
	const [map, setMap] = useState<any>(null);
	const [maps, setMaps] = useState<any>(null);
	const [isDeletingClient, setIsDeletingClient] = useState(false);
	const dispatch = useDispatch<APP_DISPATCH>();
	const [outOfBoundsMarkers, setOutOfBoundsMarkers] = useState<any>([]);
	const markerClustererRef = useRef<MarkerClusterer | null>(null);

	useEffect(() => {
		if (!map || !markerClustererRef.current) return;

		markerClustererRef.current.clearMarkers();

		if (filterCount > 0) {
			// Clear the existing clusters

			const markers = Object.keys(clientMarkers)
				.map((key) => {
					const client = clients.find((each) => each.clientID === clientMarkers[key]["client"]["clientID"]);
					if (client) {
						const { marker } = clientMarkers[key];
						return marker;
					}
				})
				.filter((each) => each !== undefined);

			if (markers.length > 0) {
				// store fresh markers in reference
				markerClustererRef.current = new MarkerClusterer({ map, markers });
			}
		} else {
			const markers = Object.keys(clientMarkers).map((key) => {
				const { marker } = clientMarkers[key];
				return marker;
			});

			if (markers.length > 0) {
				// store fresh markers in reference
				markerClustererRef.current = new MarkerClusterer({ map, markers });
			}
		}
	}, [clients, allClients, map, maps, loading]);

	useEffect(() => {
		// Check if selectedClientId has changed
		if (prevSelectedClientId.current && prevSelectedClientId.current !== selectedClientId) {
			removeCircleFromMarkers();
		}
		// Update the previous value of selectedClientId
		prevSelectedClientId.current = selectedClientId;
	}, [selectedClientId]);

	useEffect(() => {
		// Update the out of bounds markers whenever clients or map changes
		updateOutOfBoundsMarkers();
	}, [clients, map]);

	const updateOutOfBoundsMarkers = () => {
		if (map && clients?.length > 0) {
			const bounds = map.getBounds();
			const updatedOutOfBoundsMarkers = clients?.filter(
				(client) =>
					// check if a region or point marker is out of bounds
					!bounds.contains({ lat: client.lat, lng: client.lng })
			);
			setOutOfBoundsMarkers(updatedOutOfBoundsMarkers);
		} else {
			setOutOfBoundsMarkers([]);
		}
	};

	useEffect(() => {
		if (!isLoading && dataClients) {
			allClients.current = dataClients?.get_clients_by_adminID;
		}
	}, [dataClients, isLoading]);

	useEffect(() => {
		// reset selected client id to null on filterCount change
		if (!loading && clients) {
			setSelectedClientId(null);
			removeCircleFromMarkers();
		}
	}, [clients, loading]);

	const selectedClient = clients?.find((client) => client.clientID === selectedClientId);

	const genMarker = (maps: any, center: any, name: string, id: string) => {
		var markerIcon2 = {
			url: "/images/clientMarker.png",
			scaledSize: new maps.Size(25, 25),
			labelOrigin: new maps.Point(20, -10),
			anchor: new maps.Point(12.5, 13),
		};

		return new maps.Marker({
			position: center,
			label: {
				text: name,
				color: SITE_LABEL_COLOR,
				fontSize: "16px",
			},
			id: id,
			draggable: false,
			icon: markerIcon2,
		});
	};

	const genCircle = (maps: any, center: any, radius: number) =>
		new maps.Circle({
			strokeColor: SITE_MARKER_COLOR,
			strokeOpacity: 0.4,
			strokeWeight: 2,
			fillColor: SITE_MARKER_COLOR,
			fillOpacity: 0.15,
			center: center,
			radius: radius || 200,
			editable: false,
			draggable: false,
		});

	const zoomToClient = (map: any, maps: any, location: any) => {
		var bounds = new maps.LatLngBounds();
		bounds.extend(location);

		map.fitBounds(bounds);
		map.setZoom(17);
	};

	const onClientClicked = useCallback(
		(clientID: string) => {
			const { client, circle } = clientMarkers[clientID];
			const center = new maps.LatLng(client.lat, client.lng);
			zoomToClient(map, maps, center);
			circle.setMap(map);
		},
		[clientMarkers, maps, map]
	);

	const removeCircleFromMarkers = useCallback(() => {
		// remove circle marker if it exists and not similar to selected client
		if (prevSelectedClientId.current !== null && prevSelectedClientId.current !== selectedClientId) {
			const { circle } = clientMarkers[prevSelectedClientId.current];
			circle.setMap(null);
			return;
		}
		if (selectedClientId) {
			const { circle } = clientMarkers?.[selectedClientId];
			circle.setMap(null);
		}
	}, [clientMarkers, selectedClientId]);

	const handleApiLoaded = useCallback((map: any, maps: any) => {
		setMap(map);
		setMaps(maps);
		const locations: Location[] = [];
		const globalBounds = new maps.LatLngBounds();
		map.setOptions({ styles: mapStyles });

		allClients.current.map((u: any) =>
			locations.push({
				lat: parseFloat(u.lat),
				lng: parseFloat(u.lng),
				clientID: u.clientID,
				clientName: u.clientName,
				radius: u.radius,
			})
		);

		const tempPointMarkers: PointMarkers = {};
		const markers =
			locations &&
			locations.map((location) => {
				const center = new maps.LatLng(location.lat, location.lng);
				const marker = genMarker(
					maps,
					center,
					location.clientName.replace(/(\r\n\t|\n|\r\t)/gm, ""),
					location.clientID
				);
				const circle = genCircle(maps, { lat: location.lat, lng: location.lng }, location.radius);

				tempPointMarkers[location.clientID] = {
					marker: marker,
					circle: circle,
					client: location,
				};

				maps.event.addListener(marker, "click", function () {
					setSelectedClientId(location.clientID);
					setExpanded(true);
					zoomToClient(map, maps, center);
					circle.setMap(map);
				});

				return marker;
			});
		clientMarkers = tempPointMarkers;

		//zoom to fit all markers
		if (markers.length > 0) {
			markerClustererRef.current = new MarkerClusterer({ map, markers });
			markers.forEach((marker: any) => {
				globalBounds.extend(marker.getPosition());
			});
			map.fitBounds(globalBounds);
		}
	}, []);

	const deleteHandler = async (id: string) => {
		if (!id || id.length === 0) return;
		setIsDeletingClient(true);
		const { success, message } = await deleteSites([id]);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		setIsDeletingClient(false);
		if (success) {
			setSelectedClientId(null);
			setClients(clients.filter((client) => client.clientID !== id));
		}
	};

	if (!process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY || loadingClients || loading)
		return (
			<div className="small_circular-spinner">
				<CircularProgress />
			</div>
		);
	return (
		<>
			<div className="sub_header">
				{expanded ? (
					<>
						Clients List
						<button className="for_mobile_view" onClick={() => setExpanded(false)}>
							<CloseIcon />
						</button>
					</>
				) : (
					<button className="for_mobile_view" onClick={() => setExpanded(true)}>
						<MenuIcon />
					</button>
				)}
			</div>
			<div className="datagrid-table">
				<GoogleMapReact
					bootstrapURLKeys={{
						key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY!,
						language: "en",
						region: "IN",
						libraries: ["places", "geometry", "drawing", "visualization"],
					}}
					options={mapOptions}
					center={center}
					yesIWantToUseGoogleMapApiInternals={true}
					defaultZoom={zoom}
					defaultCenter={{ lat: 51.506, lng: -0.169 }}
					onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
					style={{ position: "relative", width: "100%", height: "100%" }}
					onChange={() => updateOutOfBoundsMarkers()}></GoogleMapReact>
				<RenderOutOfBoundsMarkers map={map} maps={maps} outOfBoundsMarkers={outOfBoundsMarkers} />
				{!selectedClientId ? (
					<ClientList
						setSelectedClientId={setSelectedClientId}
						clients={clients}
						setVariables={setVariables}
						onClientClicked={onClientClicked}
						isLoading={isLoading}
						expanded={expanded}
					/>
				) : (
					<ClientDetailedSidebarView
						selectedClientId={selectedClientId}
						setSelectedClientId={setSelectedClientId}
						selectedClient={selectedClient}
						removeCircleFromMarkers={removeCircleFromMarkers}
						deleteHandler={deleteHandler}
						expanded={expanded}
						isDeletingClient={isDeletingClient}
						entityMetadata={entityMetadata}
					/>
				)}
			</div>
		</>
	);
};

export default ClientMapView;
