/**
 * excel utility import
 * todo: make seperate componenent for excel util
 */
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import _ from "lodash";
import { Dispatch, FC, SetStateAction } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#D3DFFD",
		color: "#0500FF",
		fontWeight: "700",
		fontSize: "1rem",
		textAlign: "center",
		borderRight: "1px solid #C4C4C4",
		padding: "1rem 8px",
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 15,
		padding: "2rem 1rem",
		borderRight: "1px solid #C4C4C4",
	},
}));

const StyledTableRow = styled(TableRow)(() => ({
	verticalAlign: "unset",
}));

type Props = {
	open: boolean;
	setSampleModalOpen: Dispatch<SetStateAction<boolean>>;
};
const SamplePhoto: FC<Props> = ({ open, setSampleModalOpen }) => {
	const handleClose = () => {
		setSampleModalOpen(false);
	};
	const samplePhotos: any[] = [
		{
			id: 1,
			verdict: "Accepted",
			photo: "/images/sample1.png",
			reason: "Image contains a single Person.",
		},
		{
			id: 2,
			verdict: "Accepted",
			photo: "/images/sample2.png",
			reason: "Image contains a person facing sideways.",
		},
		{
			id: 3,
			verdict: "Rejected",
			photo: "/images/sample3.png",
			reason: "Image contains two Persons.",
		},
		{
			id: 4,
			verdict: "Rejected",
			photo: "/images/sample4.png",
			reason: "Image is blurred.",
		},
	];
	return (
		<>
			<Dialog
				style={{ maxWidth: "700px !important" }}
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}>
				<DialogTitle style={{ color: "#3f51b5 !important" }} id="customized-dialog-title">
					Sample Photos
				</DialogTitle>
				<DialogContent style={{ minWidth: "500px" }} dividers>
					<TableContainer component={Paper}>
						<Table aria-label="customized table">
							<TableHead>
								<StyledTableRow>
									<StyledTableCell>Photo</StyledTableCell>
									<StyledTableCell align="right">Verdict</StyledTableCell>
									<StyledTableCell align="right">Reason</StyledTableCell>
								</StyledTableRow>
							</TableHead>
							<TableBody>
								{samplePhotos.map((row) => (
									<StyledTableRow key={row.id}>
										<StyledTableCell align="center">
											<img width={80} height={90} src={row.photo} alt="Sample Phot" />
										</StyledTableCell>
										<StyledTableCell
											align="center"
											component="th"
											scope="row"
											sx={{ color: row.verdict == "Accepted" ? "green" : "red" }}>
											{" "}
											{row.verdict}{" "}
										</StyledTableCell>
										<StyledTableCell align="center">{row.reason}</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default SamplePhoto;
