import EditIcon from "@mui/icons-material/Edit";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { DataGridPro, GridColDef, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { DESIGNATIONS_DATA, EXPENSE_SETTING, PROFILES_DATA } from "../../../@types";
import { DG_STYLES } from "../../../constants";
import useAdminEntitlements from "../../useAdminEntitlements";
import useFetch from "../../useFetch";
import ExpenseLimitModal from "./ExpenseLimitModal";
import LimitModeChangeModal from "./LimitModeChangeModal";
import ReadOnlyButton from "../../common/ReadOnlyButton";

type Props = {
	expense: EXPENSE_SETTING | null;
	setExpense: Dispatch<SetStateAction<EXPENSE_SETTING | null>>;
	currencySymbol: string;
	roleID: string;
	subsMetadata: any;
};

type ROWS_DATA = {
	expenseCategoryID: null | string;
	category: string;
	categoryLimitAgainst: null | number;
	expenseLimit: null | number;
	expenseTimePeriod: null | string;
	expenseLimitArray: EXPENSE_SETTING["expenseLimits"];
};

const ExpenseLimits: FC<Props> = ({ expense, currencySymbol, roleID, subsMetadata }) => {
	const [expenseLimits, setExpenseLimits] = useState(expense?.expenseLimits ?? []);
	const expenseCategories = useMemo(() => expense?.expenseCategories ?? [], [expense]);
	const { data: dataProfiles } = useFetch<PROFILES_DATA[]>("/users/profiles");

	const { data: dataDesignation } = useFetch<DESIGNATIONS_DATA[]>("/preferences/designations");
	const userWritePermission = useAdminEntitlements("settings:expense", "write");

	const [selectedExpenseCategory, setSelectedExpenseCategory] = useState({} as ROWS_DATA);
	const [openModeChange, setOpenModeChange] = useState<number>(0);
	const [open, setOpen] = useState(false);
	const handleOpen = (expenseCategory: ROWS_DATA) => {
		setSelectedExpenseCategory(expenseCategory);
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	const [columns, setColumns] = useState<GridColDef[]>([]);
	const tempColumns = useMemo(
		() => [
			{
				field: "category",
				headerName: "Category",
				flex: 1,
				minWidth: 200,
			},
			{
				field: "categoryLimitAgainst",
				headerName: "Against",
				flex: 1,
				minWidth: 150,
				renderCell: (params: GridValueGetterParams) => {
					if (params.row.categoryLimitAgainst == 0) return "Default";
					if (params.row.categoryLimitAgainst == 1) return "Team";
					if (params.row.categoryLimitAgainst == 2) return "Designation";
					return "-";
				},
			},
			{
				field: "expenseLimit",
				headerName: `Limit(${currencySymbol})`,
				flex: 1,
				minWidth: 150,
				renderCell: (params: GridValueGetterParams) => {
					if (params.row.expenseLimit) return params.row.expenseLimit;
					return (
						<div
							style={{
								color: "#1976d2",
								backgroundColor: "#e8edff",
								padding: "8px	13px",
								borderRadius: "20px",
							}}>
							Custom Limit
						</div>
					);
				},
			},
			{
				field: "expenseTimePeriod",
				headerName: "Duration",
				flex: 1,
				minWidth: 150,
				renderCell: (params: GridValueGetterParams) => {
					if (params.row.expenseTimePeriod) return params.row.expenseTimePeriod;
					return (
						<div
							style={{
								color: "#1976d2",
								backgroundColor: "#e8edff",
								padding: "8px	13px",
								borderRadius: "20px",
							}}>
							Custom Duration
						</div>
					);
				},
			},
			{
				field: "state",
				headerName: "Action",
				width: 120,
				renderCell: (params: GridValueGetterParams) => (
					<div className="action-buttons">
						<button
							type="button"
							onClick={() => {
								if (!userWritePermission) return;
								handleOpen(params.row);
							}}
							disabled={!userWritePermission}>
							<EditIcon htmlColor="#8F8F8F" />
						</button>
					</div>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[expense, userWritePermission]
	);

	useEffect(() => {
		setColumns(tempColumns);
	}, [tempColumns]);

	const selectedLimitAgainst = useMemo(
		() => (expenseLimits.length >= 1 ? (expenseLimits[0].expenseCategoryID ? 2 : 1) : 0),
		[expenseLimits]
	);

	const rows = useMemo(() => {
		let formattedRows: ROWS_DATA[] = [];

		if (selectedLimitAgainst === 2) {
			formattedRows = expenseCategories.map((category) => {
				const limitForCategory = expenseLimits.filter(
					(expense) => expense.expenseCategoryID === category.expenseCategoryID
				);
				let initLimit: ROWS_DATA = {
					...category,
					categoryLimitAgainst: null,
					expenseLimit: null,
					expenseTimePeriod: null,
					expenseLimitArray: limitForCategory,
				};

				if (limitForCategory.length > 0) {
					let categoryLimitAgainst;
					if (limitForCategory[0].profileID) {
						categoryLimitAgainst = 1;
					} else if (limitForCategory[0].designationID) {
						categoryLimitAgainst = 2;
					} else {
						categoryLimitAgainst = 0;
						initLimit = {
							...initLimit,
							expenseLimit: limitForCategory[0].expenseLimit,
							expenseTimePeriod: limitForCategory[0].expenseTimePeriod
								? limitForCategory[0].expenseTimePeriod === 1
									? "Weekly"
									: "Monthly"
								: "Daily",
						};
					}
					initLimit = { ...initLimit, categoryLimitAgainst };
				}
				return initLimit;
			});
		} else if (selectedLimitAgainst === 1) {
			let categoryLimitAgainst;
			if (expenseLimits[0].profileID) {
				categoryLimitAgainst = 1;
			} else if (expenseLimits[0].designationID) {
				categoryLimitAgainst = 2;
			} else {
				categoryLimitAgainst = 0;
			}
			formattedRows = [
				{
					expenseCategoryID: null,
					category: "All",
					categoryLimitAgainst,
					expenseLimit: categoryLimitAgainst ? null : expenseLimits[0].expenseLimit,
					expenseTimePeriod: categoryLimitAgainst
						? null
						: expenseLimits[0].expenseTimePeriod
						? expenseLimits[0].expenseTimePeriod === 1
							? "Weekly"
							: "Monthly"
						: "Daily",
					expenseLimitArray: expenseLimits,
				},
			];
		}

		return formattedRows;
	}, [expenseCategories, expenseLimits, selectedLimitAgainst]);

	const limitModeChange = (value: number) => {
		setOpenModeChange(value);
	};

	return (
		<div className="custom-field-settings">
			<div className="custom-field-top">
				<div className="section">
					<div className="section-part">
						<p className="title">Expense limits</p>
						{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="settings" /> : null}
					</div>
					<p className="subtitle">
						Define limits for how much can be claimed. The same will apply to advances as well.
					</p>
				</div>
			</div>
			<div className="table_container">
				<div className="button-group">
					<button
						onClick={(e) => {
							limitModeChange(1);
							e.stopPropagation();
						}}
						disabled={!userWritePermission}
						className={`group ${selectedLimitAgainst === 0 ? "selected" : ""}`}
						style={{ height: "36px" }}>
						None
					</button>
					<button
						disabled={!userWritePermission}
						onClick={(e) => {
							limitModeChange(2);
							e.stopPropagation();
						}}
						className={`group ${selectedLimitAgainst === 1 ? "selected" : ""}`}
						style={{ height: "36px" }}>
						Company Level
					</button>
					<button
						disabled={!userWritePermission}
						onClick={(e) => {
							limitModeChange(3);
							e.stopPropagation();
						}}
						className={`group ${selectedLimitAgainst === 2 ? "selected" : ""}`}
						style={{ height: "36px" }}>
						Category Level
					</button>
				</div>
				{!selectedLimitAgainst ? (
					<div className="no_expense_limit">
						<PostAddOutlinedIcon />
						<span>No expense limit has been set</span>
					</div>
				) : (
					<div className="datagrid-table" style={{ height: "300px" }}>
						<DataGridPro
							sx={DG_STYLES}
							getRowId={(row) => row.expenseCategoryID || "it_will_always_be_one_row"}
							rows={rows}
							columns={columns}
							rowHeight={70}
							disableSelectionOnClick
							disableColumnFilter
							pagination
							onRowClick={(params) => userWritePermission && handleOpen(params.row)}
						/>
					</div>
				)}
			</div>
			{open ? (
				<ExpenseLimitModal
					handleClose={handleClose}
					expenseLimits={expenseLimits}
					selectedExpenseCategory={selectedExpenseCategory}
					setSelectedExpenseCategory={setSelectedExpenseCategory}
					profiles={dataProfiles?.data ?? []}
					designations={dataDesignation?.data ?? []}
					setExpenseLimits={setExpenseLimits}
				/>
			) : null}
			{openModeChange ? (
				<LimitModeChangeModal
					openModeChange={openModeChange}
					setOpenModeChange={setOpenModeChange}
					setExpenseLimits={setExpenseLimits}
					expenseCategories={expenseCategories}
				/>
			) : null}
		</div>
	);
};

export default ExpenseLimits;
