// react
import { Dispatch, FC, SetStateAction } from "react";
// mui
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// library
import dayjs, { Dayjs } from "dayjs";
// query
import { CUSTOM_FIELD_COMPLEX_TYPE, ENTITY_METADATA, ENTITY_OPTIONS, TASK_TYPE } from "../../../@types";

type TASK_ENTITY = Omit<ENTITY_METADATA, "entity"> & { entity: "Tasks" };
type Props = {
	task: TASK_TYPE;
	setTask: Dispatch<SetStateAction<TASK_TYPE>>;
	entityMetaData: TASK_ENTITY[];
	isDisabled: boolean;
};

const CustomFieldsComplexTasks: FC<Props> = ({ task, setTask, entityMetaData, isDisabled }) => {
	const getInputFields = (entity: TASK_ENTITY, field: CUSTOM_FIELD_COMPLEX_TYPE[] | null | undefined) => {
		const map = new Map<typeof entity.dataType, JSX.Element>();
		const currentField = field?.find((f) => f.fieldID === entity.fieldID);
		map.set(
			"varchar",
			<TextField
				disabled={isDisabled}
				key={currentField?.fieldID}
				value={currentField?.fieldValue || ""}
				label={entity.fieldName}
				onChange={(e) => handleChangeCustomFields(entity, e.target.value)}
				required={currentField?.isRequired}
			/>
		);
		map.set(
			"number",
			<TextField
				key={currentField?.fieldID}
				disabled={isDisabled}
				type="number"
				value={currentField?.fieldValue ?? ""}
				label={entity.fieldName}
				onChange={(e) => handleChangeCustomFields(entity, e.target.value)}
				required={currentField?.isRequired}
			/>
		);
		map.set(
			"address",
			<TextField
				key={currentField?.fieldID}
				disabled={isDisabled}
				value={currentField?.fieldValue}
				label={entity.fieldName}
				onChange={(e) => handleChangeCustomFields(entity, e.target.value)}
				required={currentField?.isRequired}
			/>
		);
		map.set(
			"dropDown",
			<FormControl fullWidth key={currentField?.fieldID} required={currentField?.isRequired}>
				<InputLabel id={entity.fieldID}>{entity.fieldName}</InputLabel>
				<Select
					value={
						currentField?.selectedOptions
							? Array.isArray(currentField?.selectedOptions)
								? currentField?.selectedOptions[0]
								: currentField?.selectedOptions
							: ""
					}
					disabled={isDisabled}
					labelId={entity.fieldID}
					label={entity.fieldName}
					onChange={(e) => handleChangeCustomFields(entity, e.target.value)}>
					{entity.options &&
						Array.isArray(entity.options) &&
						entity.options.map((item) => (
							<MenuItem key={item.optionID} value={item.optionID}>
								{item.optionValue}
							</MenuItem>
						))}
				</Select>
			</FormControl>
		);
		map.set(
			"checkbox",
			<FormControl
				fullWidth
				key={currentField?.fieldID}
				className="custom-fields_checkbox_form_control"
				disabled={isDisabled}
				required={currentField?.isRequired}>
				<FormLabel id={entity.fieldID}>{entity.fieldName}</FormLabel>
				<FormGroup className="custom-fields_checkbox_form_group">
					{entity.options &&
						Array.isArray(entity.options) &&
						entity.options.map((item) => (
							<FormControlLabel
								key={item.optionID}
								control={
									<Checkbox
										name={item.optionValue}
										value={item.optionID}
										checked={task?.customFieldsComplex
											?.find((data) => data.fieldID === entity.fieldID)
											?.selectedOptions?.includes(item.optionID)}
										onChange={(e) => handleChangeCustomFields(entity, e.target.value)}
									/>
								}
								label={item.optionValue}
							/>
						))}
				</FormGroup>
			</FormControl>
		);
		map.set(
			"date",
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DesktopDatePicker
					disabled={isDisabled}
					label={`${entity.fieldName}`}
					format="DD-MM-YYYY"
					value={dayjs(currentField?.fieldValue, "DD-MM-YYYY") || null}
					onChange={(e: Dayjs | null) => handleChangeCustomFields(entity, dayjs(e).format("DD-MM-YYYY"))}
					slotProps={{ textField: { variant: "outlined", required: currentField?.isRequired } }}
				/>
			</LocalizationProvider>
		);
		return map.get(entity.dataType);
	};

	const handleChangeCustomFields = (entity: TASK_ENTITY, value: number | string | string[] | number[]) => {
		const { fieldID, fieldName, dataType } = entity;
		const newTask = { ...task };
		const existingFieldIndex = newTask.customFieldsComplex?.findIndex((item) => item.fieldID === fieldID);
		const isText = ["varchar", "number", "address", "date"].includes(dataType);
		const isCheckbox = ["checkbox"].includes(dataType);
		if (
			existingFieldIndex !== undefined &&
			!isNaN(existingFieldIndex) &&
			existingFieldIndex >= 0 &&
			newTask.customFieldsComplex
		) {
			if (isText) {
				newTask.customFieldsComplex[existingFieldIndex].fieldValue = Array.isArray(value)
					? value.join(",")
					: typeof value === "string"
					? value
					: value.toString();
				newTask.customFieldsComplex[existingFieldIndex].selectedOptions = null;
				newTask.customFieldsComplex[existingFieldIndex].selectedOptionsValues = null;
			} else if (isCheckbox) {
				const newValue: any = value;

				const selectedOptions = newTask.customFieldsComplex[existingFieldIndex].selectedOptions;
				if (selectedOptions?.find((each) => each == value)) {
					const index = selectedOptions?.indexOf(parseInt(newValue));
					selectedOptions.splice(index, 1);
					newTask.customFieldsComplex[existingFieldIndex].selectedOptions = selectedOptions;
				} else {
					newTask.customFieldsComplex[existingFieldIndex].selectedOptions?.push(parseInt(newValue));
				}
			} else {
				const optionIds = Array.isArray(value) ? value.map((i) => +i) : [+value];
				newTask.customFieldsComplex[existingFieldIndex].fieldValue = null;
				newTask.customFieldsComplex[existingFieldIndex].selectedOptions = optionIds;
				newTask.customFieldsComplex[existingFieldIndex].selectedOptionsValues = getOptionValuesFromOptionIds(
					optionIds,
					entity.options as ENTITY_OPTIONS[]
				);
			}
		} else {
			const optionIds = Array.isArray(value) ? value.map((i) => +i) : [+value];
			const newField = {
				fieldID: fieldID,
				fieldValue: isText ? (value as string) : null,
				fieldName: fieldName,
				selectedOptions: !isText ? (Array.isArray(value) ? optionIds : [+value]) : null,
				selectedOptionsValues: !isText
					? getOptionValuesFromOptionIds(optionIds, entity.options as ENTITY_OPTIONS[])
					: null,
			};
			if (newTask.customFieldsComplex && Array.isArray(newTask.customFieldsComplex)) {
				newTask.customFieldsComplex.push(newField);
			} else {
				newTask.customFieldsComplex = [newField];
			}
		}
		setTask(newTask);
	};

	const getOptionValuesFromOptionIds = (optionIds: number[], options: ENTITY_OPTIONS[]) => {
		const optionsValues = options?.filter((item) => optionIds.includes(item.optionID));
		return optionsValues?.map((item) => item.optionValue) || [];
	};

	return (
		<div className="group" style={{ padding: "0", backgroundColor: "transparent" }}>
			{entityMetaData &&
				Array.isArray(entityMetaData) &&
				entityMetaData.map(
					(entity: TASK_ENTITY) => entity.active === 1 && getInputFields(entity, task.customFieldsComplex)
				)}
		</div>
	);
};

export default CustomFieldsComplexTasks;
