import { gql } from "@apollo/client";
export const GET_PARTIAL_FORMS = gql`
	query GetFormByCompanyID {
		get_form_metadata_by_companyID_v2 {
			formTemplateID
			formTitle
			visibility {
				visibleToAll
				teamIDs
			}
		}
	}
`;

export const GET_FORMS_BY_COMPANYID = gql`
	query GetFormByCompanyID {
		get_form_metadata_by_companyID_v2 {
			companyID
			formTemplateID
			formTitle
			formDescription
			lastModifiedTs
			createdTs
			isQuizMode
			defaultPointValue
			formScore {
				low
				high
				result
				scoreID
			}
			createdByAdminID
			lastModifiedByAdminID
			visibility {
				visibleToAll
				teamIDs
			}
			formSections {
				formSectionID
				sectionDescription
				seqNumber
				sectionTitle
				lastModifiedTs
				lastModifiedByAdminID
				createdTs
				createdByAdminID
				nextSection
				formQuestions {
					questionType
					question
					questionID
					clientsInRange
					sequence
					score
					required
					createdTs
					lastModifiedTs
					createdByAdminID
					lastModifiedByAdminID
					options {
						optionValue
						correct
						nextSection
						createdTs
						lastModifiedTs
						createdByAdminID
						lastModifiedByAdminID
						createdByAdminID
					}
				}
			}
		}
	}
`;
export const GET_FORM_BY_TEMPLATE_ID = gql`
	query Get_form_metadata_by_templateID_v2($id: String!) {
		get_form_metadata_by_templateID_v2(formTemplateID: $id) {
			companyID
			formTemplateID
			formTitle
			formDescription
			lastModifiedTs
			createdTs
			isQuizMode
			defaultPointValue
			formScore {
				low
				high
				result
				scoreID
			}
			createdByAdminID
			lastModifiedByAdminID
			visibility {
				visibleToAll
				teamIDs
			}
			formSections {
				formSectionID
				sectionDescription
				seqNumber
				sectionTitle
				lastModifiedTs
				lastModifiedByAdminID
				createdTs
				createdByAdminID
				nextSection
				formQuestions {
					questionType
					question
					questionID
					clientsInRange
					sequence
					score
					required
					createdTs
					lastModifiedTs
					createdByAdminID
					lastModifiedByAdminID
					options {
						optionValue
						correct
						nextSection
						createdTs
						lastModifiedTs
						createdByAdminID
						lastModifiedByAdminID
						createdByAdminID
					}
				}
			}
		}
	}
`;
export const DELETE_FORM_TEMPLATE = gql`
	mutation deleteFormTemplates($formTemplateIDs: [ID!]!) {
		delete_batch_form_metadata_v2(formTemplateIDs: $formTemplateIDs) {
			failed,
			formDependencyInfo {
				formTemplateID, 
				inAttendance {
					profileName,
				},
				outAttendance {
					profileName,
				},
				taskCompletion {
					profileName,
				},
			},
			successfullyDeleted
		}
	}
`;

export const UPSERT_FORM_TEMPLATE = gql`
	mutation UpsertFormTemplate($input: FormMetadataInputV2!) {
		insert_form_metadata_v2(input: $input) {
			success
		}
	}
`;
export const GET_FORMS_BY_COMPLETEDFORMID = gql`
	query getForms($completedFormID: String!) {
		get_completedformdatav2_by_completedFormID(completedFormID: $completedFormID) {
			completedFormID
			taskID
			completedFormDataDetails {
				questionID
				question
				questionValue
				questionOption {
					optionValue
				}
				questionFiles {
					fileID
					fileExt
					fileName
					filePath
				}
				required
				sequence
				questionType
			}
			employeeID
			date
			formAddress
			formTitle
			formTemplateID
			formDescription
			lat
			lng
			lastModifiedTs
			createdTs
			processingDate
		}
	}
`;
export const GET_FORM_DATA = gql`
	query getFormData($startDate: Date!, $endDate: Date!, $formTemplateID: String!) {
		get_completedformdata_by_adminID_date_range_v2(
			startDate: $startDate
			endDate: $endDate
			formTemplateID: $formTemplateID
		) {
			employeeID
			completedFormID
			formTemplateID
			formAddress
			lat
			lng
			date
			completedFormDataDetails {
				question
				questionType
				questionID
				questionValue
				sequence
				sectionSeqNumber
				questionOption {
					optionValue
				}
				questionFiles {
					fileID
					fileExt
					fileName
					filePath
				}
			}
			clientInfo {
				clientName
			}
		}
	}
`;
