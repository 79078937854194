/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { LicenseInfo } from "@mui/x-data-grid-pro";
import Head from "next/head";
import { useRouter } from "next/router";
import { FC, ReactNode, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Header, Sidebar, SnackBar } from ".";
import { ADMIN_ID, GA_ID, ONE_SIGNAL_ID, PRODUCT_ID, SIDEBAR_OPEN } from "../constants";
import { APP_DISPATCH, authCheckState, closeNotification, ROOT_STATE } from "../redux";
import { fetchSidebarMetaData } from "../utils";
import Spinner from "./common/Spinner";
import { setSubscriptionMetadata } from "../redux/slices/subscriptionMetadataSlice";
import DecomissionMessageBanner from "./common/DecomissionMessageBanner";
import { useAdminCompanySettings } from "../components/useAdminEntitlements";

LicenseInfo.setLicenseKey(`${process.env.NEXT_PUBLIC_DATAGRID_KEY}`);
type LayoutType = {
	title: string;
	keywords?: string;
	description?: string;
	children: ReactNode;
};

const Layout: FC<LayoutType> = ({ title, children }) => {
	const router = useRouter();
	const dispatch = useDispatch<APP_DISPATCH>();
	const user = useSelector((state: ROOT_STATE) => state.user);
	const notification = useSelector((state: ROOT_STATE) => state.notification);
	const [isOneSignalLoaded, setIsOneSignalLoaded] = useState(false);
	const productID = localStorage.getItem(PRODUCT_ID);
	const customEntityMigrationPhase = useAdminCompanySettings("customEntityMigrationPhase");
	const [isOpen, setIsOpen] = useState<boolean>(JSON.parse(localStorage.getItem(SIDEBAR_OPEN) || "true"));
	useEffect(() => {
		dispatch(authCheckState(user));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, router.pathname]);

	const { data, error } = useQuery("subscriptionMetadata", fetchSidebarMetaData, {
		staleTime: 200000,
		cacheTime: 200000,
		refetchOnWindowFocus: true,
		refetchOnReconnect: true,
		refetchInterval: 200000,
	});

	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://cdn.onesignal.com/sdks/OneSignalSDK.js";
		script.async = true;
		document.head.appendChild(script);

		script.onload = () => {
			// OneSignal SDK is loaded
			setIsOneSignalLoaded(true);
		};

		return () => {
			document.head.removeChild(script);
		};
	}, []);

	useEffect(() => {
		if (isOneSignalLoaded) {
			window.OneSignal = window.OneSignal || [];
			window.OneSignal.push(() => {
				// @ts-ignore
				window.OneSignal?.init({ appId: ONE_SIGNAL_ID });
				// @ts-ignore
				window.OneSignal?.sendTag(ADMIN_ID, localStorage.getItem(ADMIN_ID), (tagsSent) => {
					console.log("Tags sent:", tagsSent);
				});
			});
		}

		return () => {
			window.OneSignal = [];
		};
	}, [isOneSignalLoaded]);

	useEffect(() => {
		window.dataLayer = window.dataLayer || [];
		function gtag() {
			window.dataLayer.push(arguments);
		}
		// @ts-ignore
		gtag("js", new Date());
		// @ts-ignore
		gtag("config", GA_ID, {
			user_id: localStorage.getItem(ADMIN_ID),
		});
		return () => {
			window.dataLayer = [];
		};
	}, []);

	useEffect(() => {
		if (window.FreshworksWidget) {
			window.FreshworksWidget("hide", "launcher");
		}
	}, [window.FreshworksWidget]);

	useEffect(() => {
		const loadFreshdeskWidget = () => {
			window.fwSettings = {
				widget_id: 82000005628,
			};

			if (typeof window.FreshworksWidget !== "function") {
				const n: any = function () {
					n.q.push(arguments);
				};
				n.q = [];
				window.FreshworksWidget = n;
			}
		};
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.src = "https://ind-widget.freshworks.com/widgets/82000005628.js";
		script.async = true;
		script.defer = true;

		document.body.appendChild(script);

		loadFreshdeskWidget();
		return () => {
			// Clean up the script when the component unmounts
			document.body.removeChild(script);
		};
	}, []);

	useEffect(() => {
		if (error) {
			console.log(error);
			return;
		}

		dispatch(setSubscriptionMetadata(data?.data));
	}, [data]);

	return (
		<>
			<Head>
				<title>{title}</title>
				<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
			</Head>
			{/**Both loading and isLoggedIn are required to remove flickering effect. */}
			{user.loading || user.isLoggedIn === false ? (
				<Spinner />
			) : (
				<div className="flex flex-col h-[100dvh]">
					{productID !== "1" && productID !== "6" && !(customEntityMigrationPhase !== 1 || !user.isLoggedIn) && (
						<DecomissionMessageBanner />
					)}
					<div
						className="layout flex-auto"
						style={{
							height:
								productID !== "1" && productID !== "6" && !(customEntityMigrationPhase !== 1 || !user.isLoggedIn)
									? "calc(100dvh - 50px)"
									: "100dvh",
						}}>
						<Sidebar showMenu={data?.data} isOpen={isOpen} setIsOpen={setIsOpen} />
						<main
							style={{
								width: "100%",
								paddingInline: "15px",
								overflowY: "auto",
								height:
									productID !== "1" && productID !== "6" && !(customEntityMigrationPhase !== 1 || !user.isLoggedIn)
										? "calc(100dvh - 50px)"
										: "100dvh",
							}}>
							<Header showMenu={data?.data} setIsOpen={setIsOpen} />
							{children}
						</main>
						{notification.open && (
							<SnackBar
								open={notification.open}
								handleClose={() => dispatch(closeNotification())}
								severity={notification.severity}
								message={notification.message}
							/>
						)}
					</div>
				</div>
			)}
		</>
	);
};
Layout.defaultProps = {
	description: "Employee Tracking Software",
	keywords: "",
};
export default Layout;
