import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { Button, FormControl, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { ChangeEvent, Dispatch, FC, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FORM_OPTIONS_TYPE, FORM_TEMPLATE_TYPE, QUESTION_TYPES } from "../../../../@types";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../../redux";
import { updateFormQuestion } from "../FormUtils";
type Props = {
	formTemplate: FORM_TEMPLATE_TYPE;
	options: FORM_OPTIONS_TYPE[];
	jump: boolean;
	sectionIdx: number;
	questionIdx: number;
	questionType: QUESTION_TYPES;
	setFormTemplate: Dispatch<SetStateAction<FORM_TEMPLATE_TYPE>>;
};
const MultipleOptions: FC<Props> = ({
	formTemplate,
	options,
	jump,
	questionType,
	sectionIdx,
	questionIdx,
	setFormTemplate,
}) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const user = useSelector((state: ROOT_STATE) => state.user);
	const quizMode = Boolean(formTemplate.isQuizMode);
	const addNewOption = () => {
		const { tempFormTemplate, time } = updateFormQuestion(formTemplate, sectionIdx, questionIdx);
		tempFormTemplate.lastModifiedByAdminID = user.adminDetails.adminID!;
		tempFormTemplate.formSections[sectionIdx].lastModifiedByAdminID = user.adminDetails.adminID!;
		tempFormTemplate.formSections[sectionIdx].formQuestions[questionIdx].lastModifiedByAdminID =
			user.adminDetails.adminID!;

		const optionData: FORM_OPTIONS_TYPE = {
			optionValue: "",
			createdByAdminID: user.adminDetails.adminID!,
			lastModifiedByAdminID: user.adminDetails.adminID!,
			createdTs: time,
			lastModifiedTs: time,
			nextSection: jump ? formTemplate["formSections"][0]["formSectionID"] : null,
			correct: quizMode ? 0 : null,
		};
		tempFormTemplate.formSections[sectionIdx].formQuestions[questionIdx].options.push(optionData);
		setFormTemplate(tempFormTemplate);
	};

	const removeOption = (optionIdx: number) => {
		if (options.length <= 1) {
			dispatch(showNotification({ message: "Only Option of a Question cannot be deleted", severity: "error" }));
			return;
		}
		const { tempFormTemplate } = updateFormQuestion(formTemplate, sectionIdx, questionIdx);
		tempFormTemplate.lastModifiedByAdminID = user.adminDetails.adminID!;
		tempFormTemplate.formSections[sectionIdx].lastModifiedByAdminID = user.adminDetails.adminID!;
		tempFormTemplate.formSections[sectionIdx].formQuestions[questionIdx].lastModifiedByAdminID =
			user.adminDetails.adminID!;
		tempFormTemplate.formSections[sectionIdx].formQuestions[questionIdx].options.splice(optionIdx, 1);
		setFormTemplate(tempFormTemplate);
	};

	const changeOptionValue = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, optionIdx: number) => {
		const { tempFormTemplate } = updateFormQuestion(formTemplate, sectionIdx, questionIdx);
		tempFormTemplate.lastModifiedByAdminID = user.adminDetails.adminID!;
		tempFormTemplate.formSections[sectionIdx].lastModifiedByAdminID = user.adminDetails.adminID!;
		tempFormTemplate.formSections[sectionIdx].formQuestions[questionIdx].lastModifiedByAdminID =
			user.adminDetails.adminID!;
		tempFormTemplate.formSections[sectionIdx].formQuestions[questionIdx].options[optionIdx].optionValue =
			e.target.value;
		setFormTemplate(tempFormTemplate);
	};

	const changeJumpToSection = (e: SelectChangeEvent<string | null>, optionIdx: number) => {
		const { tempFormTemplate } = updateFormQuestion(formTemplate, sectionIdx, questionIdx);
		tempFormTemplate.lastModifiedByAdminID = user.adminDetails.adminID!;
		tempFormTemplate.formSections[sectionIdx].lastModifiedByAdminID = user.adminDetails.adminID!;
		tempFormTemplate.formSections[sectionIdx].formQuestions[questionIdx].lastModifiedByAdminID =
			user.adminDetails.adminID!;
		tempFormTemplate.formSections[sectionIdx].formQuestions[questionIdx].options[optionIdx].nextSection =
			e.target.value;
		setFormTemplate(tempFormTemplate);
	};

	return (
		<>
			{options.map((option, idx) => (
				<div key={idx} style={{ display: "flex", alignItems: "center", gap: 10, marginBottom: "1rem" }}>
					<p style={{ flexGrow: 1, alignSelf: "center" }}>{idx + 1}.</p>
					{questionType === "mChoice" && <CircleOutlinedIcon sx={{ color: "rgba(0,0,0,0.54)" }} />}
					{questionType === "cb" && <CheckBoxOutlineBlankIcon sx={{ color: "rgba(0,0,0,0.54)" }} />}
					{questionType === "dd" && <ArrowDropDownCircleIcon sx={{ color: "rgba(0,0,0,0.54)" }} />}
					<TextField
						fullWidth
						required
						variant="standard"
						placeholder={`Option ${idx + 1}`}
						value={option.optionValue}
						onChange={(e) => changeOptionValue(e, idx)}
						inputProps={{ maxLength: 200 }}
						helperText={`${option?.optionValue ? option?.optionValue?.length : 0}/${200}`}
						FormHelperTextProps={{ style: { margin: "4px 0px 0px 0px", fontSize: "14px" } }}
					/>
					{quizMode && option.correct ? <DoneIcon sx={{ color: "#1e8e3e" }} /> : null}
					{options.length > 1 ? (
						<button type="button" onClick={() => removeOption(idx)}>
							<ClearIcon sx={{ color: "rgba(0,0,0,0.54)" }} />
						</button>
					) : null}

					{jump && questionType != "cb" ? (
						<FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
							<Select value={option.nextSection || ""} onChange={(e) => changeJumpToSection(e, idx)} required>
								{formTemplate.formSections
									.filter((section) => section.sectionTitle !== formTemplate.formSections[sectionIdx].sectionTitle)
									.map((section) => (
										<MenuItem key={section.formSectionID} value={section.formSectionID}>
											{section.sectionTitle}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					) : null}
				</div>
			))}
			<div className="question__type">
				<Button type="button" style={{ color: "rgba(0,0,0,0.54)" }} onClick={addNewOption}>
					Add Option
				</Button>
			</div>
		</>
	);
};

export default MultipleOptions;
