import { DataGridPro, GridValueGetterParams } from "@mui/x-data-grid-pro";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ATTENDANCE_STATUS_COLUMNS } from "../../../@types";
import { DATE_TYPE_FORMAT, DG_STYLES } from "../../../constants";
import { setAttendanceStatus } from "../../../redux/slices/Attendance/attendanceStatus";
import { APP_DISPATCH, ROOT_STATE } from "../../../redux/store";
import axios from "../../../utils/axios";
import ImageAndNameCell from "../../common/datagrid/ImageAndNameCell";
import StatusDropdown from "./StatusDropdown";

type Props = {
	startDate: string;
	endDate: string;
	searchTerm: string;
	userWritePermission: boolean;
};

const AttendanceStatusTable: FC<Props> = ({ startDate, endDate, searchTerm, userWritePermission }) => {
	const [pageSize, setPageSize] = useState(10);
	const { attendanceStatus } = useSelector((state: ROOT_STATE) => state.attendanceStatus);
	const [refetch, setRefetch] = useState(0);

	const dispatch = useDispatch<APP_DISPATCH>();
	const [columns, setColumns] = useState<ATTENDANCE_STATUS_COLUMNS[]>([]);
	const [loader, setLoader] = useState(true);

	async function getAttendanceData(start: string, end: string) {
		return axios.get(
			`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/attendance?startDate=${moment(start).format(
				DATE_TYPE_FORMAT
			)}&endDate=${moment(end).format(DATE_TYPE_FORMAT)}`
		);
	}

	async function fetchData() {
		setLoader(true);

		try {
			let data: any = await getAttendanceData(startDate, endDate);
			data = data["data"]["data"]["attendanceMap"];
			if (data) {
				const rows: {}[] = [];
				const columns: ATTENDANCE_STATUS_COLUMNS[] = [
					{
						field: "name",
						headerName: "Employee Name",
						renderCell: (params: GridValueGetterParams) => <ImageAndNameCell name={params.row.name} />,
						flex: 1,
						minWidth: 260,
					},
				];

				Object.keys(data).forEach((employeeID, i) => {
					const obj: any = {
						name: data[employeeID].firstName + " " + (data[employeeID].lastName ? data[employeeID].lastName : ""),
						employeeID: employeeID,
						id: i,
						totalDaysPresent: data[employeeID].totalDaysPresent,
						totalDaysHalfDay: data[employeeID].totalDaysHalfDay,
						totalDaysAbsent: data[employeeID].totalDaysAbsent,
						totalDaysNotWorking: data[employeeID].totalHolidays + data[employeeID].totalWeeklyOff,
						totalDaysPenalty: data[employeeID].totalDaysPenalty,
						totalDays: data[employeeID].totalDays,
					};

					for (var date = moment(startDate); date <= moment(endDate).startOf("day"); date.add(1, "day")) {
						const j = date.format(DATE_TYPE_FORMAT);
						if (i === 0) {
							columns.push({
								field: j,
								headerName: date.format("ddd (DD-MM-YYYY)"),
								flex: 1,
								minWidth: 160,
								editable: true,
								sortable: false,
								renderCell: (params: GridValueGetterParams) => (
									<StatusDropdown setLoader={setLoader} params={params} setRefetch={setRefetch} userWritePermission={userWritePermission} />
								),
							});
						}

						if (data[employeeID][j] && data[employeeID][j]["resultInitials"]) {
							obj[j] = data[employeeID][j]["resultInitials"];
						} else {
							obj[j] = "-";
						}
					}

					rows[i] = obj;
				});

				columns.push({
					field: "totalDaysPresent",
					headerName: "Present",
					minWidth: 100,
					flex: 1,
					cellClassName: "att_green att_bold",
				});
				columns.push({
					field: "totalDaysHalfDay",
					headerName: "Half Day",
					minWidth: 100,
					flex: 1,
					cellClassName: "att_orange att_bold",
				});
				columns.push({
					field: "totalDaysAbsent",
					headerName: "Absent",
					minWidth: 100,
					flex: 1,
					cellClassName: "att_red att_bold",
				});
				columns.push({
					field: "totalDaysNotWorking",
					headerName: "Weekly Off",
					minWidth: 100,
					flex: 1,
					cellClassName: "att_blue att_bold",
				});
				columns.push({
					field: "totalDaysPenalty",
					headerName: "Penalty",
					minWidth: 100,
					flex: 1,
					cellClassName: "att_yellow att_bold",
				});
				columns.push({
					field: "totalDays",
					headerName: "Total",
					minWidth: 100,
					flex: 1,
					cellClassName: "att_bold",
				});
				dispatch(setAttendanceStatus({ attendanceStatus: JSON.parse(JSON.stringify(rows)) }));
				setColumns(columns);
				setLoader(false);
			} else {
				setLoader(false);
			}
		} catch (error) {
			setLoader(false);
		}
	}

	useEffect(() => {
		if (startDate && endDate) {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, endDate, refetch]);

	const filteredUsersBySearch = attendanceStatus.filter((exec) =>
		exec.name.replace(/ /g, "").toLowerCase().includes(searchTerm.replace(/ /g, "").toLowerCase())
	);

	return (
		<div className="datagrid-table">
			<DataGridPro
				sx={DG_STYLES}
				rows={filteredUsersBySearch}
				columns={columns}
				loading={loader}
				pageSize={pageSize}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[5, 10, 20, 50]}
				rowHeight={70}
				disableSelectionOnClick
				disableColumnFilter
				pagination
				isCellEditable={(params) => false}
				getCellClassName={(params) => {
					switch (params.value) {
						case "PR":
							return "att_green";
						case "HD":
							return "att_orange";
						case "AB":
							return "att_red";
						case "CL":
							return "att_blue";
						case "SL":
							return "att_purple";
						case "EL":
							return "att_brown";
						case "WO":
							return "att_blue";
						case "PE":
							return "att_yellow";
						case "PH":
							return "att_blue";
						default:
							return "";
					}
				}}
			/>
		</div>
	);
};

export default AttendanceStatusTable;
