import { ENTITY_METADATA, PRODUCT_TYPE } from "../../../../@types";
import { FC, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { formatDateTo_DDMMYYYY } from "../../../../utils/validations";

type Props = {
	title: string;
	filters: any;
	name: string;
	metadata: ENTITY_METADATA;
	dataType: string;
	data: PRODUCT_TYPE[];
	onChangeCheckbox: any;
};

const IndividualCustomFieldsFilter: FC<Props> = ({
	title,
	filters,
	name,
	data,
	onChangeCheckbox,
	dataType,
	metadata,
}) => {
	const [searchInput, setSearchInput] = useState("");
	const [localList, setLocalList] = useState<string[]>([]);
	const handleSearching = (searchTerm: string) => {
		if (searchTerm === "") {
			const tempArray = getUniqueEntries(metadata);
			setLocalList(tempArray);
			return;
		}
		const list = localList.filter((obj: any) =>
			JSON.stringify(obj).replace(/ /g, "").toLowerCase().includes(searchTerm.replace(/ /g, "").toLowerCase())
		);
		setLocalList(() => [...list]);
	};

	const getUniqueEntries = (metadata: ENTITY_METADATA) => {
		const tempArr: string[] = [];
		const obj = {};
		if (dataType === "dropDown" || dataType === "checkbox") {
			metadata.options?.forEach((item: any) => {
				if (obj[item.optionValue] == undefined && item.optionValue !== null && item.optionValue !== undefined) {
					obj[item.optionValue] = 1;
					tempArr.push(item.optionValue);
				}
			});
		}
		if (dataType === "varchar" || dataType === "number" || dataType === "address") {
			data.forEach((e) =>
				e.customFieldsComplex?.forEach((customField: any) => {
					if (metadata.fieldID === customField.fieldID) {
						if (
							obj[customField.fieldValue] == undefined &&
							customField.fieldValue !== null &&
							customField.fieldValue !== undefined
						) {
							obj[customField.fieldValue] = 1;
							tempArr.push(customField.fieldValue);
						}
					}
				})
			);
		}
		if (dataType === "date") {
			data.forEach((e) =>
				e.customFieldsComplex?.forEach((customField: any) => {
					if (metadata.fieldID === customField.fieldID) {
						if (
							obj[customField.fieldValue] == undefined &&
							customField.fieldValue !== null &&
							customField.fieldValue !== undefined
						) {
							obj[customField.fieldValue] = 1;
							tempArr.push(formatDateTo_DDMMYYYY(customField?.fieldValue));
						}
					}
				})
			);
		}
		// custom fielter for contact person
		if (dataType === "contact" && metadata.fieldName + " Name" === name) {
			data.forEach((e) =>
				e.customFieldsComplex?.forEach((customField: any) => {
					if (metadata.fieldID === customField.fieldID) {
						if (
							obj[customField.fieldValue] == undefined &&
							customField.fieldValue !== null &&
							customField.fieldValue !== undefined
						) {
							obj[customField.fieldValue] = 1;
							tempArr.push(customField?.fieldValue);
						}
					}
				})
			);
		}

		// custom fielter for contact number
		if (dataType === "contact" && metadata.fieldName + " Number" === name) {
			data.forEach((e) =>
				e.customFieldsComplex?.forEach((customField: any) => {
					if (metadata.fieldID === customField.fieldID) {
						if (
							obj[customField.fieldValue3] == undefined &&
							customField.fieldValue3 !== null &&
							customField.fieldValue3 !== undefined
						) {
							obj[customField.fieldValue3] = 1;
							tempArr.push(customField?.fieldValue3);
						}
					}
				})
			);
		}

		return tempArr;
	};

	useEffect(() => {
		const tempArr: string[] = getUniqueEntries(metadata);
		setLocalList([...tempArr]);
	}, [data]);

	return (
		<Accordion sx={{ margin: "0 !important", width: "100%" }}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<h6>{title}</h6>
			</AccordionSummary>
			<AccordionDetails sx={{ margin: 0 }}>
				<input
					type="search"
					className="search"
					placeholder={`Search ${title}`}
					value={searchInput}
					style={{
						width: "100%",
					}}
					onChange={(e) => {
						setSearchInput(e.target.value);
						handleSearching(e.target.value);
					}}
				/>
				<div className="individualFilter_wrapper" style={{ display: "flex", flexDirection: "column" }}>
					{localList?.map((item) => (
						<FormControlLabel
							key={Math.random()}
							name={name}
							value={item}
							control={<Checkbox size="small" />}
							label={item}
							checked={filters[name] !== undefined && filters[name]?.includes(item)}
							onChange={(e) => onChangeCheckbox(e, item, name)}
						/>
					))}
				</div>
			</AccordionDetails>
		</Accordion>
	);
};
export default IndividualCustomFieldsFilter;
