import {
	DataGridPro,
	GridColDef,
	GridInputSelectionModel,
	GridSelectionModel,
	GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import _cloneDeep from "lodash/cloneDeep";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../../useFetch";
import { CONVEYANCE_TYPE, PROFILES_DATA } from "../../../@types";
import { expenseApproveReject, markExpensePaidOut } from "../../../api/expense";
import { DATA_GRID_CUSTOMIZATION_OPTIONS, DATA_GRID_NAMES, DG_STYLES } from "../../../constants";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import ColumnModifierSidebar from "../../common/datagrid/ColumnModifierSidebar";
import CustomColumnMenu from "../../common/datagrid/CustomColumnMenu";
import RightSidebar from "../../common/RightSidebar";
import useAdminEntitlements from "../../useAdminEntitlements";
import ExpenseActionButtons from "../Request/ExpenseActionButtons";
import { currency_map } from "../../../constants/currency_map";
import { formatTheDataFromSlice, getTheSavedColumns, modifyColumOrder, modifyColumnWidth } from "../../../utils";
import { setColumnConfigurations, setColumnSortModel, setPinnedColumns } from "../../../redux/slices/dataGridSlice";
import { formatDateTo_DDMMYYYY } from "../../../utils/validations";

type Props = {
	requests: CONVEYANCE_TYPE[];
	filteredRequests: CONVEYANCE_TYPE[];
	setRequests: Dispatch<SetStateAction<CONVEYANCE_TYPE[]>>;
	setSelectedRows: Dispatch<SetStateAction<GridSelectionModel>>;
	loading: boolean;
	setSelectedConveyanceId: Dispatch<SetStateAction<string | null>>;
	refetch: () => void;
	profiles: PROFILES_DATA[];
	selectedRows: GridInputSelectionModel | undefined;
};
export const findNextApprovalAmount = (request: CONVEYANCE_TYPE) => {
	const expenseAuthIndex = request.expenseAuth.findIndex((e) => e.actionPerformed === -1);
	let amountToBeApproved;
	//if this is not the first approver, then we need to find the reimbursement amount that the previous admin
	//has put in, we have to approve only what they have approved....
	if (expenseAuthIndex > 0) {
		amountToBeApproved = request.expenseAuth[expenseAuthIndex - 1].reimbursementAmount;
	} else {
		//if we are the first approver,then just approve what they have claimed.
		amountToBeApproved = request.amountClaimed || 0;
	}
	return amountToBeApproved;
};
const findAmountClaimedOfRequest = (request: CONVEYANCE_TYPE) => request.amountClaimed;

/**
 * E1 --> Approved by Sid for amount 100
 * E2 --> Approved by Shantanu for amount 90
 * E3 --> Pending with Shashank
 */
const findLastApprovedAmount = (request: CONVEYANCE_TYPE): number | null => {
	//find the last approved entry
	const expenseAuthIndex = request.expenseAuth.reverse().findIndex((e) => e.actionPerformed === 1);
	let amountToBeApproved;
	//return the amount reimbursed for the last approved entry
	if (expenseAuthIndex > -1) {
		amountToBeApproved = request.expenseAuth[expenseAuthIndex].reimbursementAmount!;
	} else {
		amountToBeApproved = null;
	}
	return amountToBeApproved;
};

const ConveyanceTable: FC<Props> = ({
	requests,
	setSelectedRows,
	filteredRequests,
	loading,
	setSelectedConveyanceId,
	refetch,
	profiles,
	selectedRows,
}) => {
	const [isUpdating, setIsUpdating] = useState(false);
	const userWritePermission = useAdminEntitlements("expense:conveyanceRequests", "write");

	const [pageSize, setPageSize] = useState(20);
	const user = useSelector((state: ROOT_STATE) => state.user);
	const dispatch = useDispatch<APP_DISPATCH>();
	const [expandColumn, setExpandColumn] = useState(false);
	const [columns, setColumns] = useState<GridColDef[]>([]);
	const { data: currencyData, loading: isCurrencyLoading } = useFetch<any>("/preferences/account");
	const currencyCode = currency_map[currencyData.data?.currencyCode]?.code || "";
	const tempColumns = useMemo(
		() => [
			{
				field: "date",
				headerName: "Conveyance Date",
				width: 110,
				renderCell: (params: GridValueGetterParams<any, CONVEYANCE_TYPE>) => (
					<>{formatDateTo_DDMMYYYY(params.row.date)}</>
				),
			},
			{
				field: "firstName",
				headerName: "Employee Name",
				width: 150,
				renderCell: (params: GridValueGetterParams<any, CONVEYANCE_TYPE>) => (
					<>{params.row.firstName + " " + params.row.lastName}</>
				),
			},
			{
				field: "description",
				headerName: "Description",
				width: 200,
				sortable: false,
			},
			{
				field: "distanceClaimed",
				headerName: "Claimed Distance (Km)",
				width: 150,
			},
			{
				field: "distanceCalculated",
				headerName: "Calculated Distance (Km)",
				width: 150,
			},
			{
				field: "delta",
				headerName: "Delta(%)",
				width: 100,
				sortable: false,
				renderCell: (params: GridValueGetterParams<any, CONVEYANCE_TYPE>) => {
					const totalDistanceClaimed = params.row.distanceClaimed || 0;
					if (!params.row.distanceCalculated || totalDistanceClaimed == 0) {
						return "-";
					} else if (totalDistanceClaimed > params.row.distanceCalculated) {
						let delta = totalDistanceClaimed - params.row.distanceCalculated;
						delta /= params.row.distanceCalculated;
						delta *= 100;
						return delta.toFixed(2);
					} else {
						let delta = ((params.row.distanceCalculated - totalDistanceClaimed) / totalDistanceClaimed) * 100 * -1;
						delta *= -1;
						return delta.toFixed(2);
					}
				},
			},
			{
				field: "odoReading",
				headerName: "Odometer Reading",
				width: 150,
				sortable: false,
				renderCell: (params: GridValueGetterParams<any, CONVEYANCE_TYPE>) => {
					const { inReading, outReading } = params.row.conveyanceDetails[0];
					const odoReading = outReading && inReading ? outReading - inReading : "-";
					return odoReading;
				},
			},
			{
				field: "categories",
				headerName: "Categories",
				width: 150,
				sortable: false,
				/**Conveyance mode = 1 : Car, 2: Bike, 3: Public Transport*/
				renderCell: (params: GridValueGetterParams<any, CONVEYANCE_TYPE>) => {
					const expenseCat =
						params.row.conveyanceDetails && Array.isArray(params.row.conveyanceDetails)
							? params.row.conveyanceDetails.map((cnv) => (
									<div key={cnv.conveyanceID}>
										{cnv.mode === 1 && <strong>Car: </strong>}
										{cnv.mode === 2 && <strong>Bike: </strong>}
										{cnv.mode === 3 && <strong>Public Transport </strong>}
										<span className="rupee">{cnv.amountClaimed}</span>
									</div>
							  ))
							: [];
					return <div className="expense_categories">{expenseCat.map((cat) => cat)}</div>;
				},
			},
			{
				field: "amountClaimed",
				headerName: `Claimed Amount(${currencyCode})`,
				width: 150,
				renderCell: (params: GridValueGetterParams<any, CONVEYANCE_TYPE>) => (
					<span className="rupee">{findAmountClaimedOfRequest(params.row) || 0}</span>
				),
			},
			{
				field: "reimbursementAmount",
				headerName: `Last Approved Amount(${currencyCode})`,
				width: 150,
				renderCell: (params: GridValueGetterParams<any, CONVEYANCE_TYPE>) => (
					<span className="rupee">
						{params.row.expenseAuth.length > 0 && (findLastApprovedAmount(params.row) || "")}
					</span>
				),
			},
			{
				field: "comment",
				headerName: "Comment",
				width: 200,
				sortable: false,
				renderCell: (params: GridValueGetterParams<any, CONVEYANCE_TYPE>) =>
					params.row.expenseAuth.length > 0 && params.row.expenseAuth.at(-1)?.comment,
			},
			{
				field: "profileID",
				headerName: "Team",
				width: 200,
				renderCell: (params: GridValueGetterParams<any, CONVEYANCE_TYPE>) => {
					const team = profiles.find((profile) => profile.profileID === params.row.profileID);
					return team?.profileName;
				},
			},
			{
				field: "status",
				headerName: "Status",
				width: 130,
				renderCell: (params: GridValueGetterParams<any, CONVEYANCE_TYPE>) => {
					const status =
						params.row.status === 0
							? "Rejected"
							: params.row.status === 1
							? "Approved"
							: params.row.status === 2
							? "Paid Out"
							: "Pending";
					return <span className={`role_badge ${status}`}>{status}</span>;
				},
			},
			{
				field: "pendingWith",
				headerName: "Pending With",
				width: 180,
				sortable: false,
				renderCell: (params: GridValueGetterParams<any, CONVEYANCE_TYPE>) =>
					typeof params.row.pendingWith?.adminName === "string" ? params.row.pendingWith?.adminName : "",
			},
			{
				field: "createdTs",
				headerName: "Submission Date",
				width: 150,
				renderCell: (params: GridValueGetterParams<any, CONVEYANCE_TYPE>) =>
					typeof params.row.createdTs === "string" ? formatDateTo_DDMMYYYY(params.row.createdTs) : "",
			},
			{
				field: "lastModifiedTs",
				headerName: "Last Modified At",
				width: 150,
				renderCell: (params: GridValueGetterParams<any, CONVEYANCE_TYPE>) =>
					typeof params.row.lastModifiedTs === "string" ? formatDateTo_DDMMYYYY(params.row.lastModifiedTs) : "",
			},
			{
				field: "action",
				headerName: "Action",
				width: 150,
				sortable: false,
				renderCell: (params: GridValueGetterParams<any, CONVEYANCE_TYPE>) => (
					<ExpenseActionButtons
						status={params.row.status}
						canAdminApprove={params.row.pendingWith?.adminID === user.adminDetails.adminID || false}
						approveRequest={(reason: string, amount?: number) =>
							approveRequest(params.row.expenseSummaryID, reason, amount)
						}
						amountClaimed={findNextApprovalAmount(params.row) || 0}
						rejectRequest={(reason: string) => rejectRequest(params.row.expenseSummaryID, reason)}
						name={params.row.firstName + " " + params.row.lastName}
						link={`/expense/conveyance_requests/${params.row.expenseSummaryID}`}
						canAdminPayout={
							params.row.status === 1 && params.row.expenseAuth.at(-1)?.adminID === user.adminDetails.adminID
						}
						paidOutCallback={() => paidOutRequest(params.row.expenseSummaryID)}
						userWritePermission={userWritePermission}
					/>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[requests, user.currencyCode, isCurrencyLoading, currencyData?.data, userWritePermission]
	);
	useEffect(() => {
		if (!isCurrencyLoading && currencyData?.data) setColumns(tempColumns);
	}, [tempColumns]);

	const paidOutRequest = async (id: string) => {
		if (!id || id.length === 0) return;
		const updatedRequests = [...requests];
		const requestIndex = updatedRequests.findIndex((r) => r.expenseSummaryID === id);
		if (requestIndex < 0) return;
		setIsUpdating(true);
		updatedRequests[requestIndex].status = 2;
		const { success, message } = await markExpensePaidOut([updatedRequests[requestIndex]]);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		refetch();
		setIsUpdating(false);
	};

	const approveRequest = async (id: string, reason: string, amount?: number) => {
		if (!id || id.length === 0 || amount === undefined || isNaN(amount)) {
			dispatch(showNotification({ message: "Enter an amount", severity: "error" }));
			return;
		}
		const updatedRequests = [...requests];
		const requestIndex = updatedRequests.findIndex((r) => r.expenseSummaryID === id);
		if (requestIndex < 0) return;
		setIsUpdating(true);
		const expenseAuth = _cloneDeep(updatedRequests[requestIndex].expenseAuth);
		//find the index of the expanse_detail with the highest timestamp
		const expenseAuthIndex = expenseAuth.findIndex((e) => e.actionPerformed === -1);
		// if nextAdmin exists don't update the status
		updatedRequests[requestIndex].status = 1;
		updatedRequests[requestIndex].expenseAuth[expenseAuthIndex].comment = reason;
		updatedRequests[requestIndex].expenseAuth[expenseAuthIndex].reimbursementAmount = amount;
		const { success, message } = await expenseApproveReject([updatedRequests[requestIndex]]);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		refetch();
		setIsUpdating(false);
	};

	const rejectRequest = async (id: string, reason: string) => {
		if (!id || id.length === 0) return;
		setIsUpdating(true);
		const updatedRequests = [...requests];
		const requestIndex = updatedRequests.findIndex((r) => r.expenseSummaryID === id);
		if (requestIndex < 0) return;
		const expenseAuth = _cloneDeep(updatedRequests[requestIndex].expenseAuth);
		const expenseAuthIndex = expenseAuth.findIndex((e) => e.actionPerformed === -1);
		updatedRequests[requestIndex].status = 0;
		updatedRequests[requestIndex].expenseAuth[expenseAuthIndex].comment = reason;
		const { success, message } = await expenseApproveReject([updatedRequests[requestIndex]]);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		await refetch();
		setIsUpdating(false);
	};
	const getColum = useSelector((state: any) => state.dataGrid);
	const savedColumnConf = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.expense_conveyance_request,
		DATA_GRID_CUSTOMIZATION_OPTIONS.columns_array
	);
	const savedColumnSortModel = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.expense_conveyance_request,
		DATA_GRID_CUSTOMIZATION_OPTIONS.sort_model
	);
	const savedPinnedColumns = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.expense_conveyance_request,
		DATA_GRID_CUSTOMIZATION_OPTIONS.pinned_column
	);
	const formattedcolumn = formatTheDataFromSlice(savedColumnConf, columns) || [];

	return (
		<div className="datagrid-table">
			<button aria-label="filter" className="filter__button" onClick={() => setExpandColumn((prev) => !prev)}>
				<img src="/images/filter.svg" alt="" />
			</button>
			<DataGridPro
				sx={DG_STYLES}
				rows={filteredRequests}
				getRowId={(row) => row.expenseSummaryID}
				columns={formattedcolumn?.length > 0 ? formattedcolumn : columns}
				sortModel={savedColumnSortModel?.length > 0 ? savedColumnSortModel : []}
				pinnedColumns={savedPinnedColumns && Object.keys(savedPinnedColumns).length > 0 ? savedPinnedColumns : {}}
				loading={loading || isUpdating}
				pageSize={pageSize}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[5, 10, 20, 50]}
				rowHeight={70}
				checkboxSelection
				disableSelectionOnClick
				onRowClick={(params) => userWritePermission && setSelectedConveyanceId(params.id as string)}
				disableColumnFilter
				pagination
				selectionModel={selectedRows}
				onSelectionModelChange={(selectionModel) => setSelectedRows(selectionModel)}
				components={{
					ColumnMenu: CustomColumnMenu,
				}}
				onColumnOrderChange={(params) => {
					const modifiedColumn = modifyColumOrder(
						DATA_GRID_NAMES.expense_conveyance_request,
						params,
						formattedcolumn?.length > 0 ? formattedcolumn : columns
					);
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.expense_conveyance_request,
						columnArray: modifiedColumn,
					};
					dispatch(setColumnConfigurations(newColumnConfigurations));
				}}
				onSortModelChange={(params) => {
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.expense_conveyance_request,
						sortModel: params,
					};
					dispatch(setColumnSortModel(newColumnConfigurations));
				}}
				onColumnWidthChange={(params) => {
					const modifiedColumn = modifyColumnWidth(params, formattedcolumn?.length > 0 ? formattedcolumn : columns);
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.expense_conveyance_request,
						columnArray: modifiedColumn,
					};
					dispatch(setColumnConfigurations(newColumnConfigurations));
				}}
				onPinnedColumnsChange={(params) => {
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.expense_conveyance_request,
						pinnedColumns: params,
					};
					dispatch(setPinnedColumns(newColumnConfigurations));
				}}
			/>
			{expandColumn && (
				<RightSidebar expanded={expandColumn} setExpanded={setExpandColumn}>
					<ColumnModifierSidebar
						setExpanded={setExpandColumn}
						columns={columns}
						localString={DATA_GRID_NAMES.expense_conveyance_request}
					/>
				</RightSidebar>
			)}
		</div>
	);
};

export default ConveyanceTable;
