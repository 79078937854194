// eslint-disable-next-line import/prefer-default-export
export const countries = {
	"America/Aruba": {
		timezones: ["America/Aruba"],
		latlng: [12.5, -69.96666666],
		name: "Aruba",
		country_code: "AW",
		capital: "Oranjestad",
	},
	"Asia/Kabul": {
		timezones: ["Asia/Kabul"],
		latlng: [33, 65],
		name: "Afghanistan",
		country_code: "AF",
		capital: "Kabul",
	},
	"Africa/Luanda": {
		timezones: ["Africa/Luanda"],
		latlng: [-12.5, 18.5],
		name: "Angola",
		country_code: "AO",
		capital: "Luanda",
	},
	"America/Anguilla": {
		timezones: ["America/Anguilla"],
		latlng: [18.25, -63.16666666],
		name: "Anguilla",
		country_code: "AI",
		capital: "The Valley",
	},
	"Europe/Mariehamn": {
		timezones: ["Europe/Mariehamn"],
		latlng: [60.116667, 19.9],
		name: "Åland Islands",
		country_code: "AX",
		capital: "Mariehamn",
	},
	"Europe/Tirane": {
		timezones: ["Europe/Tirane"],
		latlng: [41, 20],
		name: "Albania",
		country_code: "AL",
		capital: "Tirana",
	},
	"Europe/Andorra": {
		timezones: ["Europe/Andorra"],
		latlng: [42.5, 1.5],
		name: "Andorra",
		country_code: "AD",
		capital: "Andorra la Vella",
	},
	"Asia/Dubai": {
		timezones: ["Asia/Dubai"],
		latlng: [24, 54],
		name: "United Arab Emirates",
		country_code: "AE",
		capital: "Abu Dhabi",
	},
	"America/Argentina/Buenos_Aires": {
		timezones: [
			"America/Argentina/Buenos_Aires",
			"America/Argentina/Cordoba",
			"America/Argentina/Salta",
			"America/Argentina/Jujuy",
			"America/Argentina/Tucuman",
			"America/Argentina/Catamarca",
			"America/Argentina/La_Rioja",
			"America/Argentina/San_Juan",
			"America/Argentina/Mendoza",
			"America/Argentina/San_Luis",
			"America/Argentina/Rio_Gallegos",
			"America/Argentina/Ushuaia",
		],
		latlng: [-34, -64],
		name: "Argentina",
		country_code: "AR",
		capital: "Buenos Aires",
	},
	"America/Argentina/Cordoba": {
		timezones: [
			"America/Argentina/Buenos_Aires",
			"America/Argentina/Cordoba",
			"America/Argentina/Salta",
			"America/Argentina/Jujuy",
			"America/Argentina/Tucuman",
			"America/Argentina/Catamarca",
			"America/Argentina/La_Rioja",
			"America/Argentina/San_Juan",
			"America/Argentina/Mendoza",
			"America/Argentina/San_Luis",
			"America/Argentina/Rio_Gallegos",
			"America/Argentina/Ushuaia",
		],
		latlng: [-34, -64],
		name: "Argentina",
		country_code: "AR",
		capital: "Buenos Aires",
	},
	"America/Argentina/Salta": {
		timezones: [
			"America/Argentina/Buenos_Aires",
			"America/Argentina/Cordoba",
			"America/Argentina/Salta",
			"America/Argentina/Jujuy",
			"America/Argentina/Tucuman",
			"America/Argentina/Catamarca",
			"America/Argentina/La_Rioja",
			"America/Argentina/San_Juan",
			"America/Argentina/Mendoza",
			"America/Argentina/San_Luis",
			"America/Argentina/Rio_Gallegos",
			"America/Argentina/Ushuaia",
		],
		latlng: [-34, -64],
		name: "Argentina",
		country_code: "AR",
		capital: "Buenos Aires",
	},
	"America/Argentina/Jujuy": {
		timezones: [
			"America/Argentina/Buenos_Aires",
			"America/Argentina/Cordoba",
			"America/Argentina/Salta",
			"America/Argentina/Jujuy",
			"America/Argentina/Tucuman",
			"America/Argentina/Catamarca",
			"America/Argentina/La_Rioja",
			"America/Argentina/San_Juan",
			"America/Argentina/Mendoza",
			"America/Argentina/San_Luis",
			"America/Argentina/Rio_Gallegos",
			"America/Argentina/Ushuaia",
		],
		latlng: [-34, -64],
		name: "Argentina",
		country_code: "AR",
		capital: "Buenos Aires",
	},
	"America/Argentina/Tucuman": {
		timezones: [
			"America/Argentina/Buenos_Aires",
			"America/Argentina/Cordoba",
			"America/Argentina/Salta",
			"America/Argentina/Jujuy",
			"America/Argentina/Tucuman",
			"America/Argentina/Catamarca",
			"America/Argentina/La_Rioja",
			"America/Argentina/San_Juan",
			"America/Argentina/Mendoza",
			"America/Argentina/San_Luis",
			"America/Argentina/Rio_Gallegos",
			"America/Argentina/Ushuaia",
		],
		latlng: [-34, -64],
		name: "Argentina",
		country_code: "AR",
		capital: "Buenos Aires",
	},
	"America/Argentina/Catamarca": {
		timezones: [
			"America/Argentina/Buenos_Aires",
			"America/Argentina/Cordoba",
			"America/Argentina/Salta",
			"America/Argentina/Jujuy",
			"America/Argentina/Tucuman",
			"America/Argentina/Catamarca",
			"America/Argentina/La_Rioja",
			"America/Argentina/San_Juan",
			"America/Argentina/Mendoza",
			"America/Argentina/San_Luis",
			"America/Argentina/Rio_Gallegos",
			"America/Argentina/Ushuaia",
		],
		latlng: [-34, -64],
		name: "Argentina",
		country_code: "AR",
		capital: "Buenos Aires",
	},
	"America/Argentina/La_Rioja": {
		timezones: [
			"America/Argentina/Buenos_Aires",
			"America/Argentina/Cordoba",
			"America/Argentina/Salta",
			"America/Argentina/Jujuy",
			"America/Argentina/Tucuman",
			"America/Argentina/Catamarca",
			"America/Argentina/La_Rioja",
			"America/Argentina/San_Juan",
			"America/Argentina/Mendoza",
			"America/Argentina/San_Luis",
			"America/Argentina/Rio_Gallegos",
			"America/Argentina/Ushuaia",
		],
		latlng: [-34, -64],
		name: "Argentina",
		country_code: "AR",
		capital: "Buenos Aires",
	},
	"America/Argentina/San_Juan": {
		timezones: [
			"America/Argentina/Buenos_Aires",
			"America/Argentina/Cordoba",
			"America/Argentina/Salta",
			"America/Argentina/Jujuy",
			"America/Argentina/Tucuman",
			"America/Argentina/Catamarca",
			"America/Argentina/La_Rioja",
			"America/Argentina/San_Juan",
			"America/Argentina/Mendoza",
			"America/Argentina/San_Luis",
			"America/Argentina/Rio_Gallegos",
			"America/Argentina/Ushuaia",
		],
		latlng: [-34, -64],
		name: "Argentina",
		country_code: "AR",
		capital: "Buenos Aires",
	},
	"America/Argentina/Mendoza": {
		timezones: [
			"America/Argentina/Buenos_Aires",
			"America/Argentina/Cordoba",
			"America/Argentina/Salta",
			"America/Argentina/Jujuy",
			"America/Argentina/Tucuman",
			"America/Argentina/Catamarca",
			"America/Argentina/La_Rioja",
			"America/Argentina/San_Juan",
			"America/Argentina/Mendoza",
			"America/Argentina/San_Luis",
			"America/Argentina/Rio_Gallegos",
			"America/Argentina/Ushuaia",
		],
		latlng: [-34, -64],
		name: "Argentina",
		country_code: "AR",
		capital: "Buenos Aires",
	},
	"America/Argentina/San_Luis": {
		timezones: [
			"America/Argentina/Buenos_Aires",
			"America/Argentina/Cordoba",
			"America/Argentina/Salta",
			"America/Argentina/Jujuy",
			"America/Argentina/Tucuman",
			"America/Argentina/Catamarca",
			"America/Argentina/La_Rioja",
			"America/Argentina/San_Juan",
			"America/Argentina/Mendoza",
			"America/Argentina/San_Luis",
			"America/Argentina/Rio_Gallegos",
			"America/Argentina/Ushuaia",
		],
		latlng: [-34, -64],
		name: "Argentina",
		country_code: "AR",
		capital: "Buenos Aires",
	},
	"America/Argentina/Rio_Gallegos": {
		timezones: [
			"America/Argentina/Buenos_Aires",
			"America/Argentina/Cordoba",
			"America/Argentina/Salta",
			"America/Argentina/Jujuy",
			"America/Argentina/Tucuman",
			"America/Argentina/Catamarca",
			"America/Argentina/La_Rioja",
			"America/Argentina/San_Juan",
			"America/Argentina/Mendoza",
			"America/Argentina/San_Luis",
			"America/Argentina/Rio_Gallegos",
			"America/Argentina/Ushuaia",
		],
		latlng: [-34, -64],
		name: "Argentina",
		country_code: "AR",
		capital: "Buenos Aires",
	},
	"America/Argentina/Ushuaia": {
		timezones: [
			"America/Argentina/Buenos_Aires",
			"America/Argentina/Cordoba",
			"America/Argentina/Salta",
			"America/Argentina/Jujuy",
			"America/Argentina/Tucuman",
			"America/Argentina/Catamarca",
			"America/Argentina/La_Rioja",
			"America/Argentina/San_Juan",
			"America/Argentina/Mendoza",
			"America/Argentina/San_Luis",
			"America/Argentina/Rio_Gallegos",
			"America/Argentina/Ushuaia",
		],
		latlng: [-34, -64],
		name: "Argentina",
		country_code: "AR",
		capital: "Buenos Aires",
	},
	"Asia/Yerevan": {
		timezones: ["Asia/Yerevan"],
		latlng: [40, 45],
		name: "Armenia",
		country_code: "AM",
		capital: "Yerevan",
	},
	"Pacific/Pago_Pago": {
		timezones: ["Pacific/Pago_Pago"],
		latlng: [-14.33333333, -170],
		name: "American Samoa",
		country_code: "AS",
		capital: "Pago Pago",
	},
	"Antarctica/McMurdo": {
		timezones: [
			"Antarctica/McMurdo",
			"Antarctica/Casey",
			"Antarctica/Davis",
			"Antarctica/DumontDUrville",
			"Antarctica/Mawson",
			"Antarctica/Palmer",
			"Antarctica/Rothera",
			"Antarctica/Syowa",
			"Antarctica/Troll",
			"Antarctica/Vostok",
		],
		latlng: [-90, 0],
		name: "Antarctica",
		country_code: "AQ",
		capital: null,
	},
	"Antarctica/Casey": {
		timezones: [
			"Antarctica/McMurdo",
			"Antarctica/Casey",
			"Antarctica/Davis",
			"Antarctica/DumontDUrville",
			"Antarctica/Mawson",
			"Antarctica/Palmer",
			"Antarctica/Rothera",
			"Antarctica/Syowa",
			"Antarctica/Troll",
			"Antarctica/Vostok",
		],
		latlng: [-90, 0],
		name: "Antarctica",
		country_code: "AQ",
		capital: null,
	},
	"Antarctica/Davis": {
		timezones: [
			"Antarctica/McMurdo",
			"Antarctica/Casey",
			"Antarctica/Davis",
			"Antarctica/DumontDUrville",
			"Antarctica/Mawson",
			"Antarctica/Palmer",
			"Antarctica/Rothera",
			"Antarctica/Syowa",
			"Antarctica/Troll",
			"Antarctica/Vostok",
		],
		latlng: [-90, 0],
		name: "Antarctica",
		country_code: "AQ",
		capital: null,
	},
	"Antarctica/DumontDUrville": {
		timezones: [
			"Antarctica/McMurdo",
			"Antarctica/Casey",
			"Antarctica/Davis",
			"Antarctica/DumontDUrville",
			"Antarctica/Mawson",
			"Antarctica/Palmer",
			"Antarctica/Rothera",
			"Antarctica/Syowa",
			"Antarctica/Troll",
			"Antarctica/Vostok",
		],
		latlng: [-90, 0],
		name: "Antarctica",
		country_code: "AQ",
		capital: null,
	},
	"Antarctica/Mawson": {
		timezones: [
			"Antarctica/McMurdo",
			"Antarctica/Casey",
			"Antarctica/Davis",
			"Antarctica/DumontDUrville",
			"Antarctica/Mawson",
			"Antarctica/Palmer",
			"Antarctica/Rothera",
			"Antarctica/Syowa",
			"Antarctica/Troll",
			"Antarctica/Vostok",
		],
		latlng: [-90, 0],
		name: "Antarctica",
		country_code: "AQ",
		capital: null,
	},
	"Antarctica/Palmer": {
		timezones: [
			"Antarctica/McMurdo",
			"Antarctica/Casey",
			"Antarctica/Davis",
			"Antarctica/DumontDUrville",
			"Antarctica/Mawson",
			"Antarctica/Palmer",
			"Antarctica/Rothera",
			"Antarctica/Syowa",
			"Antarctica/Troll",
			"Antarctica/Vostok",
		],
		latlng: [-90, 0],
		name: "Antarctica",
		country_code: "AQ",
		capital: null,
	},
	"Antarctica/Rothera": {
		timezones: [
			"Antarctica/McMurdo",
			"Antarctica/Casey",
			"Antarctica/Davis",
			"Antarctica/DumontDUrville",
			"Antarctica/Mawson",
			"Antarctica/Palmer",
			"Antarctica/Rothera",
			"Antarctica/Syowa",
			"Antarctica/Troll",
			"Antarctica/Vostok",
		],
		latlng: [-90, 0],
		name: "Antarctica",
		country_code: "AQ",
		capital: null,
	},
	"Antarctica/Syowa": {
		timezones: [
			"Antarctica/McMurdo",
			"Antarctica/Casey",
			"Antarctica/Davis",
			"Antarctica/DumontDUrville",
			"Antarctica/Mawson",
			"Antarctica/Palmer",
			"Antarctica/Rothera",
			"Antarctica/Syowa",
			"Antarctica/Troll",
			"Antarctica/Vostok",
		],
		latlng: [-90, 0],
		name: "Antarctica",
		country_code: "AQ",
		capital: null,
	},
	"Antarctica/Troll": {
		timezones: [
			"Antarctica/McMurdo",
			"Antarctica/Casey",
			"Antarctica/Davis",
			"Antarctica/DumontDUrville",
			"Antarctica/Mawson",
			"Antarctica/Palmer",
			"Antarctica/Rothera",
			"Antarctica/Syowa",
			"Antarctica/Troll",
			"Antarctica/Vostok",
		],
		latlng: [-90, 0],
		name: "Antarctica",
		country_code: "AQ",
		capital: null,
	},
	"Antarctica/Vostok": {
		timezones: [
			"Antarctica/McMurdo",
			"Antarctica/Casey",
			"Antarctica/Davis",
			"Antarctica/DumontDUrville",
			"Antarctica/Mawson",
			"Antarctica/Palmer",
			"Antarctica/Rothera",
			"Antarctica/Syowa",
			"Antarctica/Troll",
			"Antarctica/Vostok",
		],
		latlng: [-90, 0],
		name: "Antarctica",
		country_code: "AQ",
		capital: null,
	},
	"Indian/Kerguelen": {
		timezones: ["Indian/Kerguelen"],
		latlng: [-49.25, 69.167],
		name: "French Southern and Antarctic Lands",
		country_code: "TF",
		capital: "Port-aux-Français",
	},
	"America/Antigua": {
		timezones: ["America/Antigua"],
		latlng: [17.05, -61.8],
		name: "Antigua and Barbuda",
		country_code: "AG",
		capital: "Saint John's",
	},
	"Australia/Lord_Howe": {
		timezones: [
			"Australia/Lord_Howe",
			"Antarctica/Macquarie",
			"Australia/Hobart",
			"Australia/Currie",
			"Australia/Melbourne",
			"Australia/Sydney",
			"Australia/Broken_Hill",
			"Australia/Brisbane",
			"Australia/Lindeman",
			"Australia/Adelaide",
			"Australia/Darwin",
			"Australia/Perth",
			"Australia/Eucla",
		],
		latlng: [-27, 133],
		name: "Australia",
		country_code: "AU",
		capital: "Canberra",
	},
	"Antarctica/Macquarie": {
		timezones: [
			"Australia/Lord_Howe",
			"Antarctica/Macquarie",
			"Australia/Hobart",
			"Australia/Currie",
			"Australia/Melbourne",
			"Australia/Sydney",
			"Australia/Broken_Hill",
			"Australia/Brisbane",
			"Australia/Lindeman",
			"Australia/Adelaide",
			"Australia/Darwin",
			"Australia/Perth",
			"Australia/Eucla",
		],
		latlng: [-27, 133],
		name: "Australia",
		country_code: "AU",
		capital: "Canberra",
	},
	"Australia/Hobart": {
		timezones: [
			"Australia/Lord_Howe",
			"Antarctica/Macquarie",
			"Australia/Hobart",
			"Australia/Currie",
			"Australia/Melbourne",
			"Australia/Sydney",
			"Australia/Broken_Hill",
			"Australia/Brisbane",
			"Australia/Lindeman",
			"Australia/Adelaide",
			"Australia/Darwin",
			"Australia/Perth",
			"Australia/Eucla",
		],
		latlng: [-27, 133],
		name: "Australia",
		country_code: "AU",
		capital: "Canberra",
	},
	"Australia/Currie": {
		timezones: [
			"Australia/Lord_Howe",
			"Antarctica/Macquarie",
			"Australia/Hobart",
			"Australia/Currie",
			"Australia/Melbourne",
			"Australia/Sydney",
			"Australia/Broken_Hill",
			"Australia/Brisbane",
			"Australia/Lindeman",
			"Australia/Adelaide",
			"Australia/Darwin",
			"Australia/Perth",
			"Australia/Eucla",
		],
		latlng: [-27, 133],
		name: "Australia",
		country_code: "AU",
		capital: "Canberra",
	},
	"Australia/Melbourne": {
		timezones: [
			"Australia/Lord_Howe",
			"Antarctica/Macquarie",
			"Australia/Hobart",
			"Australia/Currie",
			"Australia/Melbourne",
			"Australia/Sydney",
			"Australia/Broken_Hill",
			"Australia/Brisbane",
			"Australia/Lindeman",
			"Australia/Adelaide",
			"Australia/Darwin",
			"Australia/Perth",
			"Australia/Eucla",
		],
		latlng: [-27, 133],
		name: "Australia",
		country_code: "AU",
		capital: "Canberra",
	},
	"Australia/Sydney": {
		timezones: [
			"Australia/Lord_Howe",
			"Antarctica/Macquarie",
			"Australia/Hobart",
			"Australia/Currie",
			"Australia/Melbourne",
			"Australia/Sydney",
			"Australia/Broken_Hill",
			"Australia/Brisbane",
			"Australia/Lindeman",
			"Australia/Adelaide",
			"Australia/Darwin",
			"Australia/Perth",
			"Australia/Eucla",
		],
		latlng: [-27, 133],
		name: "Australia",
		country_code: "AU",
		capital: "Canberra",
	},
	"Australia/Broken_Hill": {
		timezones: [
			"Australia/Lord_Howe",
			"Antarctica/Macquarie",
			"Australia/Hobart",
			"Australia/Currie",
			"Australia/Melbourne",
			"Australia/Sydney",
			"Australia/Broken_Hill",
			"Australia/Brisbane",
			"Australia/Lindeman",
			"Australia/Adelaide",
			"Australia/Darwin",
			"Australia/Perth",
			"Australia/Eucla",
		],
		latlng: [-27, 133],
		name: "Australia",
		country_code: "AU",
		capital: "Canberra",
	},
	"Australia/Brisbane": {
		timezones: [
			"Australia/Lord_Howe",
			"Antarctica/Macquarie",
			"Australia/Hobart",
			"Australia/Currie",
			"Australia/Melbourne",
			"Australia/Sydney",
			"Australia/Broken_Hill",
			"Australia/Brisbane",
			"Australia/Lindeman",
			"Australia/Adelaide",
			"Australia/Darwin",
			"Australia/Perth",
			"Australia/Eucla",
		],
		latlng: [-27, 133],
		name: "Australia",
		country_code: "AU",
		capital: "Canberra",
	},
	"Australia/Lindeman": {
		timezones: [
			"Australia/Lord_Howe",
			"Antarctica/Macquarie",
			"Australia/Hobart",
			"Australia/Currie",
			"Australia/Melbourne",
			"Australia/Sydney",
			"Australia/Broken_Hill",
			"Australia/Brisbane",
			"Australia/Lindeman",
			"Australia/Adelaide",
			"Australia/Darwin",
			"Australia/Perth",
			"Australia/Eucla",
		],
		latlng: [-27, 133],
		name: "Australia",
		country_code: "AU",
		capital: "Canberra",
	},
	"Australia/Adelaide": {
		timezones: [
			"Australia/Lord_Howe",
			"Antarctica/Macquarie",
			"Australia/Hobart",
			"Australia/Currie",
			"Australia/Melbourne",
			"Australia/Sydney",
			"Australia/Broken_Hill",
			"Australia/Brisbane",
			"Australia/Lindeman",
			"Australia/Adelaide",
			"Australia/Darwin",
			"Australia/Perth",
			"Australia/Eucla",
		],
		latlng: [-27, 133],
		name: "Australia",
		country_code: "AU",
		capital: "Canberra",
	},
	"Australia/Darwin": {
		timezones: [
			"Australia/Lord_Howe",
			"Antarctica/Macquarie",
			"Australia/Hobart",
			"Australia/Currie",
			"Australia/Melbourne",
			"Australia/Sydney",
			"Australia/Broken_Hill",
			"Australia/Brisbane",
			"Australia/Lindeman",
			"Australia/Adelaide",
			"Australia/Darwin",
			"Australia/Perth",
			"Australia/Eucla",
		],
		latlng: [-27, 133],
		name: "Australia",
		country_code: "AU",
		capital: "Canberra",
	},
	"Australia/Perth": {
		timezones: [
			"Australia/Lord_Howe",
			"Antarctica/Macquarie",
			"Australia/Hobart",
			"Australia/Currie",
			"Australia/Melbourne",
			"Australia/Sydney",
			"Australia/Broken_Hill",
			"Australia/Brisbane",
			"Australia/Lindeman",
			"Australia/Adelaide",
			"Australia/Darwin",
			"Australia/Perth",
			"Australia/Eucla",
		],
		latlng: [-27, 133],
		name: "Australia",
		country_code: "AU",
		capital: "Canberra",
	},
	"Australia/Eucla": {
		timezones: [
			"Australia/Lord_Howe",
			"Antarctica/Macquarie",
			"Australia/Hobart",
			"Australia/Currie",
			"Australia/Melbourne",
			"Australia/Sydney",
			"Australia/Broken_Hill",
			"Australia/Brisbane",
			"Australia/Lindeman",
			"Australia/Adelaide",
			"Australia/Darwin",
			"Australia/Perth",
			"Australia/Eucla",
		],
		latlng: [-27, 133],
		name: "Australia",
		country_code: "AU",
		capital: "Canberra",
	},
	"Europe/Vienna": {
		timezones: ["Europe/Vienna"],
		latlng: [47.33333333, 13.33333333],
		name: "Austria",
		country_code: "AT",
		capital: "Vienna",
	},
	"Asia/Baku": {
		timezones: ["Asia/Baku"],
		latlng: [40.5, 47.5],
		name: "Azerbaijan",
		country_code: "AZ",
		capital: "Baku",
	},
	"Africa/Bujumbura": {
		timezones: ["Africa/Bujumbura"],
		latlng: [-3.5, 30],
		name: "Burundi",
		country_code: "BI",
		capital: "Bujumbura",
	},
	"Europe/Brussels": {
		timezones: ["Europe/Brussels"],
		latlng: [50.83333333, 4],
		name: "Belgium",
		country_code: "BE",
		capital: "Brussels",
	},
	"Africa/Porto-Novo": {
		timezones: ["Africa/Porto-Novo"],
		latlng: [9.5, 2.25],
		name: "Benin",
		country_code: "BJ",
		capital: "Porto-Novo",
	},
	"Africa/Ouagadougou": {
		timezones: ["Africa/Ouagadougou"],
		latlng: [13, -2],
		name: "Burkina Faso",
		country_code: "BF",
		capital: "Ouagadougou",
	},
	"Asia/Dhaka": {
		timezones: ["Asia/Dhaka"],
		latlng: [24, 90],
		name: "Bangladesh",
		country_code: "BD",
		capital: "Dhaka",
	},
	"Europe/Sofia": {
		timezones: ["Europe/Sofia"],
		latlng: [43, 25],
		name: "Bulgaria",
		country_code: "BG",
		capital: "Sofia",
	},
	"Asia/Bahrain": {
		timezones: ["Asia/Bahrain"],
		latlng: [26, 50.55],
		name: "Bahrain",
		country_code: "BH",
		capital: "Manama",
	},
	"America/Nassau": {
		timezones: ["America/Nassau"],
		latlng: [24.25, -76],
		name: "Bahamas",
		country_code: "BS",
		capital: "Nassau",
	},
	"Europe/Sarajevo": {
		timezones: ["Europe/Sarajevo"],
		latlng: [44, 18],
		name: "Bosnia and Herzegovina",
		country_code: "BA",
		capital: "Sarajevo",
	},
	"America/St_Barthelemy": {
		timezones: ["America/St_Barthelemy"],
		latlng: [18.5, -63.41666666],
		name: "Saint Barthélemy",
		country_code: "BL",
		capital: "Gustavia",
	},
	"Europe/Minsk": {
		timezones: ["Europe/Minsk"],
		latlng: [53, 28],
		name: "Belarus",
		country_code: "BY",
		capital: "Minsk",
	},
	"America/Belize": {
		timezones: ["America/Belize"],
		latlng: [17.25, -88.75],
		name: "Belize",
		country_code: "BZ",
		capital: "Belmopan",
	},
	"Atlantic/Bermuda": {
		timezones: ["Atlantic/Bermuda"],
		latlng: [32.33333333, -64.75],
		name: "Bermuda",
		country_code: "BM",
		capital: "Hamilton",
	},
	"America/La_Paz": {
		timezones: ["America/La_Paz"],
		latlng: [-17, -65],
		name: "Bolivia",
		country_code: "BO",
		capital: "Sucre",
	},
	"America/Noronha": {
		timezones: [
			"America/Noronha",
			"America/Belem",
			"America/Fortaleza",
			"America/Recife",
			"America/Araguaina",
			"America/Maceio",
			"America/Bahia",
			"America/Sao_Paulo",
			"America/Campo_Grande",
			"America/Cuiaba",
			"America/Santarem",
			"America/Porto_Velho",
			"America/Boa_Vista",
			"America/Manaus",
			"America/Eirunepe",
			"America/Rio_Branco",
		],
		latlng: [-10, -55],
		name: "Brazil",
		country_code: "BR",
		capital: "Brasília",
	},
	"America/Belem": {
		timezones: [
			"America/Noronha",
			"America/Belem",
			"America/Fortaleza",
			"America/Recife",
			"America/Araguaina",
			"America/Maceio",
			"America/Bahia",
			"America/Sao_Paulo",
			"America/Campo_Grande",
			"America/Cuiaba",
			"America/Santarem",
			"America/Porto_Velho",
			"America/Boa_Vista",
			"America/Manaus",
			"America/Eirunepe",
			"America/Rio_Branco",
		],
		latlng: [-10, -55],
		name: "Brazil",
		country_code: "BR",
		capital: "Brasília",
	},
	"America/Fortaleza": {
		timezones: [
			"America/Noronha",
			"America/Belem",
			"America/Fortaleza",
			"America/Recife",
			"America/Araguaina",
			"America/Maceio",
			"America/Bahia",
			"America/Sao_Paulo",
			"America/Campo_Grande",
			"America/Cuiaba",
			"America/Santarem",
			"America/Porto_Velho",
			"America/Boa_Vista",
			"America/Manaus",
			"America/Eirunepe",
			"America/Rio_Branco",
		],
		latlng: [-10, -55],
		name: "Brazil",
		country_code: "BR",
		capital: "Brasília",
	},
	"America/Recife": {
		timezones: [
			"America/Noronha",
			"America/Belem",
			"America/Fortaleza",
			"America/Recife",
			"America/Araguaina",
			"America/Maceio",
			"America/Bahia",
			"America/Sao_Paulo",
			"America/Campo_Grande",
			"America/Cuiaba",
			"America/Santarem",
			"America/Porto_Velho",
			"America/Boa_Vista",
			"America/Manaus",
			"America/Eirunepe",
			"America/Rio_Branco",
		],
		latlng: [-10, -55],
		name: "Brazil",
		country_code: "BR",
		capital: "Brasília",
	},
	"America/Araguaina": {
		timezones: [
			"America/Noronha",
			"America/Belem",
			"America/Fortaleza",
			"America/Recife",
			"America/Araguaina",
			"America/Maceio",
			"America/Bahia",
			"America/Sao_Paulo",
			"America/Campo_Grande",
			"America/Cuiaba",
			"America/Santarem",
			"America/Porto_Velho",
			"America/Boa_Vista",
			"America/Manaus",
			"America/Eirunepe",
			"America/Rio_Branco",
		],
		latlng: [-10, -55],
		name: "Brazil",
		country_code: "BR",
		capital: "Brasília",
	},
	"America/Maceio": {
		timezones: [
			"America/Noronha",
			"America/Belem",
			"America/Fortaleza",
			"America/Recife",
			"America/Araguaina",
			"America/Maceio",
			"America/Bahia",
			"America/Sao_Paulo",
			"America/Campo_Grande",
			"America/Cuiaba",
			"America/Santarem",
			"America/Porto_Velho",
			"America/Boa_Vista",
			"America/Manaus",
			"America/Eirunepe",
			"America/Rio_Branco",
		],
		latlng: [-10, -55],
		name: "Brazil",
		country_code: "BR",
		capital: "Brasília",
	},
	"America/Bahia": {
		timezones: [
			"America/Noronha",
			"America/Belem",
			"America/Fortaleza",
			"America/Recife",
			"America/Araguaina",
			"America/Maceio",
			"America/Bahia",
			"America/Sao_Paulo",
			"America/Campo_Grande",
			"America/Cuiaba",
			"America/Santarem",
			"America/Porto_Velho",
			"America/Boa_Vista",
			"America/Manaus",
			"America/Eirunepe",
			"America/Rio_Branco",
		],
		latlng: [-10, -55],
		name: "Brazil",
		country_code: "BR",
		capital: "Brasília",
	},
	"America/Sao_Paulo": {
		timezones: [
			"America/Noronha",
			"America/Belem",
			"America/Fortaleza",
			"America/Recife",
			"America/Araguaina",
			"America/Maceio",
			"America/Bahia",
			"America/Sao_Paulo",
			"America/Campo_Grande",
			"America/Cuiaba",
			"America/Santarem",
			"America/Porto_Velho",
			"America/Boa_Vista",
			"America/Manaus",
			"America/Eirunepe",
			"America/Rio_Branco",
		],
		latlng: [-10, -55],
		name: "Brazil",
		country_code: "BR",
		capital: "Brasília",
	},
	"America/Campo_Grande": {
		timezones: [
			"America/Noronha",
			"America/Belem",
			"America/Fortaleza",
			"America/Recife",
			"America/Araguaina",
			"America/Maceio",
			"America/Bahia",
			"America/Sao_Paulo",
			"America/Campo_Grande",
			"America/Cuiaba",
			"America/Santarem",
			"America/Porto_Velho",
			"America/Boa_Vista",
			"America/Manaus",
			"America/Eirunepe",
			"America/Rio_Branco",
		],
		latlng: [-10, -55],
		name: "Brazil",
		country_code: "BR",
		capital: "Brasília",
	},
	"America/Cuiaba": {
		timezones: [
			"America/Noronha",
			"America/Belem",
			"America/Fortaleza",
			"America/Recife",
			"America/Araguaina",
			"America/Maceio",
			"America/Bahia",
			"America/Sao_Paulo",
			"America/Campo_Grande",
			"America/Cuiaba",
			"America/Santarem",
			"America/Porto_Velho",
			"America/Boa_Vista",
			"America/Manaus",
			"America/Eirunepe",
			"America/Rio_Branco",
		],
		latlng: [-10, -55],
		name: "Brazil",
		country_code: "BR",
		capital: "Brasília",
	},
	"America/Santarem": {
		timezones: [
			"America/Noronha",
			"America/Belem",
			"America/Fortaleza",
			"America/Recife",
			"America/Araguaina",
			"America/Maceio",
			"America/Bahia",
			"America/Sao_Paulo",
			"America/Campo_Grande",
			"America/Cuiaba",
			"America/Santarem",
			"America/Porto_Velho",
			"America/Boa_Vista",
			"America/Manaus",
			"America/Eirunepe",
			"America/Rio_Branco",
		],
		latlng: [-10, -55],
		name: "Brazil",
		country_code: "BR",
		capital: "Brasília",
	},
	"America/Porto_Velho": {
		timezones: [
			"America/Noronha",
			"America/Belem",
			"America/Fortaleza",
			"America/Recife",
			"America/Araguaina",
			"America/Maceio",
			"America/Bahia",
			"America/Sao_Paulo",
			"America/Campo_Grande",
			"America/Cuiaba",
			"America/Santarem",
			"America/Porto_Velho",
			"America/Boa_Vista",
			"America/Manaus",
			"America/Eirunepe",
			"America/Rio_Branco",
		],
		latlng: [-10, -55],
		name: "Brazil",
		country_code: "BR",
		capital: "Brasília",
	},
	"America/Boa_Vista": {
		timezones: [
			"America/Noronha",
			"America/Belem",
			"America/Fortaleza",
			"America/Recife",
			"America/Araguaina",
			"America/Maceio",
			"America/Bahia",
			"America/Sao_Paulo",
			"America/Campo_Grande",
			"America/Cuiaba",
			"America/Santarem",
			"America/Porto_Velho",
			"America/Boa_Vista",
			"America/Manaus",
			"America/Eirunepe",
			"America/Rio_Branco",
		],
		latlng: [-10, -55],
		name: "Brazil",
		country_code: "BR",
		capital: "Brasília",
	},
	"America/Manaus": {
		timezones: [
			"America/Noronha",
			"America/Belem",
			"America/Fortaleza",
			"America/Recife",
			"America/Araguaina",
			"America/Maceio",
			"America/Bahia",
			"America/Sao_Paulo",
			"America/Campo_Grande",
			"America/Cuiaba",
			"America/Santarem",
			"America/Porto_Velho",
			"America/Boa_Vista",
			"America/Manaus",
			"America/Eirunepe",
			"America/Rio_Branco",
		],
		latlng: [-10, -55],
		name: "Brazil",
		country_code: "BR",
		capital: "Brasília",
	},
	"America/Eirunepe": {
		timezones: [
			"America/Noronha",
			"America/Belem",
			"America/Fortaleza",
			"America/Recife",
			"America/Araguaina",
			"America/Maceio",
			"America/Bahia",
			"America/Sao_Paulo",
			"America/Campo_Grande",
			"America/Cuiaba",
			"America/Santarem",
			"America/Porto_Velho",
			"America/Boa_Vista",
			"America/Manaus",
			"America/Eirunepe",
			"America/Rio_Branco",
		],
		latlng: [-10, -55],
		name: "Brazil",
		country_code: "BR",
		capital: "Brasília",
	},
	"America/Rio_Branco": {
		timezones: [
			"America/Noronha",
			"America/Belem",
			"America/Fortaleza",
			"America/Recife",
			"America/Araguaina",
			"America/Maceio",
			"America/Bahia",
			"America/Sao_Paulo",
			"America/Campo_Grande",
			"America/Cuiaba",
			"America/Santarem",
			"America/Porto_Velho",
			"America/Boa_Vista",
			"America/Manaus",
			"America/Eirunepe",
			"America/Rio_Branco",
		],
		latlng: [-10, -55],
		name: "Brazil",
		country_code: "BR",
		capital: "Brasília",
	},
	"America/Barbados": {
		timezones: ["America/Barbados"],
		latlng: [13.16666666, -59.53333333],
		name: "Barbados",
		country_code: "BB",
		capital: "Bridgetown",
	},
	"Asia/Brunei": {
		timezones: ["Asia/Brunei"],
		latlng: [4.5, 114.66666666],
		name: "Brunei",
		country_code: "BN",
		capital: "Bandar Seri Begawan",
	},
	"Asia/Thimphu": {
		timezones: ["Asia/Thimphu"],
		latlng: [27.5, 90.5],
		name: "Bhutan",
		country_code: "BT",
		capital: "Thimphu",
	},
	"Europe/Oslo": {
		timezones: ["Europe/Oslo"],
		latlng: [62, 10],
		name: "Norway",
		country_code: "NO",
		capital: "Oslo",
	},
	"Africa/Gaborone": {
		timezones: ["Africa/Gaborone"],
		latlng: [-22, 24],
		name: "Botswana",
		country_code: "BW",
		capital: "Gaborone",
	},
	"Africa/Bangui": {
		timezones: ["Africa/Bangui"],
		latlng: [7, 21],
		name: "Central African Republic",
		country_code: "CF",
		capital: "Bangui",
	},
	"America/St_Johns": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Halifax": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Glace_Bay": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Moncton": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Goose_Bay": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Blanc-Sablon": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Toronto": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Nipigon": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Thunder_Bay": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Iqaluit": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Pangnirtung": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Atikokan": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Winnipeg": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Rainy_River": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Resolute": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Rankin_Inlet": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Regina": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Swift_Current": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Edmonton": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Cambridge_Bay": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Yellowknife": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Inuvik": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Creston": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Dawson_Creek": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Fort_Nelson": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Vancouver": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Whitehorse": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"America/Dawson": {
		timezones: [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson",
		],
		latlng: [60, -95],
		name: "Canada",
		country_code: "CA",
		capital: "Ottawa",
	},
	"Indian/Cocos": {
		timezones: ["Indian/Cocos"],
		latlng: [-12.5, 96.83333333],
		name: "Cocos (Keeling) Islands",
		country_code: "CC",
		capital: "West Island",
	},
	"Europe/Zurich": {
		timezones: ["Europe/Zurich"],
		latlng: [47, 8],
		name: "Switzerland",
		country_code: "CH",
		capital: "Bern",
	},
	"America/Santiago": {
		timezones: ["America/Santiago", "Pacific/Easter"],
		latlng: [-30, -71],
		name: "Chile",
		country_code: "CL",
		capital: "Santiago",
	},
	"Pacific/Easter": {
		timezones: ["America/Santiago", "Pacific/Easter"],
		latlng: [-30, -71],
		name: "Chile",
		country_code: "CL",
		capital: "Santiago",
	},
	"Asia/Shanghai": {
		timezones: ["Asia/Shanghai", "Asia/Urumqi"],
		latlng: [35, 105],
		name: "China",
		country_code: "CN",
		capital: "Beijing",
	},
	"Asia/Urumqi": {
		timezones: ["Asia/Shanghai", "Asia/Urumqi"],
		latlng: [35, 105],
		name: "China",
		country_code: "CN",
		capital: "Beijing",
	},
	"Africa/Abidjan": {
		timezones: ["Africa/Abidjan"],
		latlng: [8, -5],
		name: "Ivory Coast",
		country_code: "CI",
		capital: "Yamoussoukro",
	},
	"Africa/Douala": {
		timezones: ["Africa/Douala"],
		latlng: [6, 12],
		name: "Cameroon",
		country_code: "CM",
		capital: "Yaoundé",
	},
	"Africa/Kinshasa": {
		timezones: ["Africa/Kinshasa", "Africa/Lubumbashi"],
		latlng: [0, 25],
		name: "DR Congo",
		country_code: "CD",
		capital: "Kinshasa",
	},
	"Africa/Lubumbashi": {
		timezones: ["Africa/Kinshasa", "Africa/Lubumbashi"],
		latlng: [0, 25],
		name: "DR Congo",
		country_code: "CD",
		capital: "Kinshasa",
	},
	"Africa/Brazzaville": {
		timezones: ["Africa/Brazzaville"],
		latlng: [-1, 15],
		name: "Republic of the Congo",
		country_code: "CG",
		capital: "Brazzaville",
	},
	"Pacific/Rarotonga": {
		timezones: ["Pacific/Rarotonga"],
		latlng: [-21.23333333, -159.76666666],
		name: "Cook Islands",
		country_code: "CK",
		capital: "Avarua",
	},
	"America/Bogota": {
		timezones: ["America/Bogota"],
		latlng: [4, -72],
		name: "Colombia",
		country_code: "CO",
		capital: "Bogotá",
	},
	"Indian/Comoro": {
		timezones: ["Indian/Comoro"],
		latlng: [-12.16666666, 44.25],
		name: "Comoros",
		country_code: "KM",
		capital: "Moroni",
	},
	"Atlantic/Cape_Verde": {
		timezones: ["Atlantic/Cape_Verde"],
		latlng: [16, -24],
		name: "Cape Verde",
		country_code: "CV",
		capital: "Praia",
	},
	"America/Costa_Rica": {
		timezones: ["America/Costa_Rica"],
		latlng: [10, -84],
		name: "Costa Rica",
		country_code: "CR",
		capital: "San José",
	},
	"America/Havana": {
		timezones: ["America/Havana"],
		latlng: [21.5, -80],
		name: "Cuba",
		country_code: "CU",
		capital: "Havana",
	},
	"America/Curacao": {
		timezones: ["America/Curacao"],
		latlng: [12.116667, -68.933333],
		name: "Curaçao",
		country_code: "CW",
		capital: "Willemstad",
	},
	"Indian/Christmas": {
		timezones: ["Indian/Christmas"],
		latlng: [-10.5, 105.66666666],
		name: "Christmas Island",
		country_code: "CX",
		capital: "Flying Fish Cove",
	},
	"America/Cayman": {
		timezones: ["America/Cayman"],
		latlng: [19.5, -80.5],
		name: "Cayman Islands",
		country_code: "KY",
		capital: "George Town",
	},
	"Asia/Nicosia": {
		timezones: ["Asia/Nicosia"],
		latlng: [35, 33],
		name: "Cyprus",
		country_code: "CY",
		capital: "Nicosia",
	},
	"Europe/Prague": {
		timezones: ["Europe/Prague"],
		latlng: [49.75, 15.5],
		name: "Czech Republic",
		country_code: "CZ",
		capital: "Prague",
	},
	"Europe/Berlin": {
		timezones: ["Europe/Berlin", "Europe/Busingen"],
		latlng: [51, 9],
		name: "Germany",
		country_code: "DE",
		capital: "Berlin",
	},
	"Europe/Busingen": {
		timezones: ["Europe/Berlin", "Europe/Busingen"],
		latlng: [51, 9],
		name: "Germany",
		country_code: "DE",
		capital: "Berlin",
	},
	"Africa/Djibouti": {
		timezones: ["Africa/Djibouti"],
		latlng: [11.5, 43],
		name: "Djibouti",
		country_code: "DJ",
		capital: "Djibouti",
	},
	"America/Dominica": {
		timezones: ["America/Dominica"],
		latlng: [15.41666666, -61.33333333],
		name: "Dominica",
		country_code: "DM",
		capital: "Roseau",
	},
	"Europe/Copenhagen": {
		timezones: ["Europe/Copenhagen"],
		latlng: [56, 10],
		name: "Denmark",
		country_code: "DK",
		capital: "Copenhagen",
	},
	"America/Santo_Domingo": {
		timezones: ["America/Santo_Domingo"],
		latlng: [19, -70.66666666],
		name: "Dominican Republic",
		country_code: "DO",
		capital: "Santo Domingo",
	},
	"Africa/Algiers": {
		timezones: ["Africa/Algiers"],
		latlng: [28, 3],
		name: "Algeria",
		country_code: "DZ",
		capital: "Algiers",
	},
	"America/Guayaquil": {
		timezones: ["America/Guayaquil", "Pacific/Galapagos"],
		latlng: [-2, -77.5],
		name: "Ecuador",
		country_code: "EC",
		capital: "Quito",
	},
	"Pacific/Galapagos": {
		timezones: ["America/Guayaquil", "Pacific/Galapagos"],
		latlng: [-2, -77.5],
		name: "Ecuador",
		country_code: "EC",
		capital: "Quito",
	},
	"Africa/Cairo": {
		timezones: ["Africa/Cairo"],
		latlng: [27, 30],
		name: "Egypt",
		country_code: "EG",
		capital: "Cairo",
	},
	"Africa/Asmara": {
		timezones: ["Africa/Asmara"],
		latlng: [15, 39],
		name: "Eritrea",
		country_code: "ER",
		capital: "Asmara",
	},
	"Africa/El_Aaiun": {
		timezones: ["Africa/El_Aaiun"],
		latlng: [24.5, -13],
		name: "Western Sahara",
		country_code: "EH",
		capital: "El Aaiún",
	},
	"Europe/Madrid": {
		timezones: ["Europe/Madrid", "Africa/Ceuta", "Atlantic/Canary"],
		latlng: [40, -4],
		name: "Spain",
		country_code: "ES",
		capital: "Madrid",
	},
	"Africa/Ceuta": {
		timezones: ["Europe/Madrid", "Africa/Ceuta", "Atlantic/Canary"],
		latlng: [40, -4],
		name: "Spain",
		country_code: "ES",
		capital: "Madrid",
	},
	"Atlantic/Canary": {
		timezones: ["Europe/Madrid", "Africa/Ceuta", "Atlantic/Canary"],
		latlng: [40, -4],
		name: "Spain",
		country_code: "ES",
		capital: "Madrid",
	},
	"Europe/Tallinn": {
		timezones: ["Europe/Tallinn"],
		latlng: [59, 26],
		name: "Estonia",
		country_code: "EE",
		capital: "Tallinn",
	},
	"Africa/Addis_Ababa": {
		timezones: ["Africa/Addis_Ababa"],
		latlng: [8, 38],
		name: "Ethiopia",
		country_code: "ET",
		capital: "Addis Ababa",
	},
	"Europe/Helsinki": {
		timezones: ["Europe/Helsinki"],
		latlng: [64, 26],
		name: "Finland",
		country_code: "FI",
		capital: "Helsinki",
	},
	"Pacific/Fiji": {
		timezones: ["Pacific/Fiji"],
		latlng: [-18, 175],
		name: "Fiji",
		country_code: "FJ",
		capital: "Suva",
	},
	"Atlantic/Stanley": {
		timezones: ["Atlantic/Stanley"],
		latlng: [-51.75, -59],
		name: "Falkland Islands",
		country_code: "FK",
		capital: "Stanley",
	},
	"Europe/Paris": {
		timezones: ["Europe/Paris"],
		latlng: [46, 2],
		name: "France",
		country_code: "FR",
		capital: "Paris",
	},
	"Atlantic/Faroe": {
		timezones: ["Atlantic/Faroe"],
		latlng: [62, -7],
		name: "Faroe Islands",
		country_code: "FO",
		capital: "Tórshavn",
	},
	"Pacific/Chuuk": {
		timezones: ["Pacific/Chuuk", "Pacific/Pohnpei", "Pacific/Kosrae"],
		latlng: [6.91666666, 158.25],
		name: "Micronesia",
		country_code: "FM",
		capital: "Palikir",
	},
	"Pacific/Pohnpei": {
		timezones: ["Pacific/Chuuk", "Pacific/Pohnpei", "Pacific/Kosrae"],
		latlng: [6.91666666, 158.25],
		name: "Micronesia",
		country_code: "FM",
		capital: "Palikir",
	},
	"Pacific/Kosrae": {
		timezones: ["Pacific/Chuuk", "Pacific/Pohnpei", "Pacific/Kosrae"],
		latlng: [6.91666666, 158.25],
		name: "Micronesia",
		country_code: "FM",
		capital: "Palikir",
	},
	"Africa/Libreville": {
		timezones: ["Africa/Libreville"],
		latlng: [-1, 11.75],
		name: "Gabon",
		country_code: "GA",
		capital: "Libreville",
	},
	"Europe/London": {
		timezones: ["Europe/London"],
		latlng: [54, -2],
		name: "United Kingdom",
		country_code: "GB",
		capital: "London",
	},
	"Asia/Tbilisi": {
		timezones: ["Asia/Tbilisi"],
		latlng: [42, 43.5],
		name: "Georgia",
		country_code: "GE",
		capital: "Tbilisi",
	},
	"Europe/Guernsey": {
		timezones: ["Europe/Guernsey"],
		latlng: [49.46666666, -2.58333333],
		name: "Guernsey",
		country_code: "GG",
		capital: "St. Peter Port",
	},
	"Africa/Accra": {
		timezones: ["Africa/Accra"],
		latlng: [8, -2],
		name: "Ghana",
		country_code: "GH",
		capital: "Accra",
	},
	"Europe/Gibraltar": {
		timezones: ["Europe/Gibraltar"],
		latlng: [36.13333333, -5.35],
		name: "Gibraltar",
		country_code: "GI",
		capital: "Gibraltar",
	},
	"Africa/Conakry": {
		timezones: ["Africa/Conakry"],
		latlng: [11, -10],
		name: "Guinea",
		country_code: "GN",
		capital: "Conakry",
	},
	"America/Guadeloupe": {
		timezones: ["America/Guadeloupe"],
		latlng: [16.25, -61.583333],
		name: "Guadeloupe",
		country_code: "GP",
		capital: "Basse-Terre",
	},
	"Africa/Banjul": {
		timezones: ["Africa/Banjul"],
		latlng: [13.46666666, -16.56666666],
		name: "Gambia",
		country_code: "GM",
		capital: "Banjul",
	},
	"Africa/Bissau": {
		timezones: ["Africa/Bissau"],
		latlng: [12, -15],
		name: "Guinea-Bissau",
		country_code: "GW",
		capital: "Bissau",
	},
	"Africa/Malabo": {
		timezones: ["Africa/Malabo"],
		latlng: [2, 10],
		name: "Equatorial Guinea",
		country_code: "GQ",
		capital: "Malabo",
	},
	"Europe/Athens": {
		timezones: ["Europe/Athens"],
		latlng: [39, 22],
		name: "Greece",
		country_code: "GR",
		capital: "Athens",
	},
	"America/Grenada": {
		timezones: ["America/Grenada"],
		latlng: [12.11666666, -61.66666666],
		name: "Grenada",
		country_code: "GD",
		capital: "St. George's",
	},
	"America/Godthab": {
		timezones: ["America/Godthab", "America/Danmarkshavn", "America/Scoresbysund", "America/Thule"],
		latlng: [72, -40],
		name: "Greenland",
		country_code: "GL",
		capital: "Nuuk",
	},
	"America/Danmarkshavn": {
		timezones: ["America/Godthab", "America/Danmarkshavn", "America/Scoresbysund", "America/Thule"],
		latlng: [72, -40],
		name: "Greenland",
		country_code: "GL",
		capital: "Nuuk",
	},
	"America/Scoresbysund": {
		timezones: ["America/Godthab", "America/Danmarkshavn", "America/Scoresbysund", "America/Thule"],
		latlng: [72, -40],
		name: "Greenland",
		country_code: "GL",
		capital: "Nuuk",
	},
	"America/Thule": {
		timezones: ["America/Godthab", "America/Danmarkshavn", "America/Scoresbysund", "America/Thule"],
		latlng: [72, -40],
		name: "Greenland",
		country_code: "GL",
		capital: "Nuuk",
	},
	"America/Guatemala": {
		timezones: ["America/Guatemala"],
		latlng: [15.5, -90.25],
		name: "Guatemala",
		country_code: "GT",
		capital: "Guatemala City",
	},
	"America/Cayenne": {
		timezones: ["America/Cayenne"],
		latlng: [4, -53],
		name: "French Guiana",
		country_code: "GF",
		capital: "Cayenne",
	},
	"Pacific/Guam": {
		timezones: ["Pacific/Guam"],
		latlng: [13.46666666, 144.78333333],
		name: "Guam",
		country_code: "GU",
		capital: "Hagåtña",
	},
	"America/Guyana": {
		timezones: ["America/Guyana"],
		latlng: [5, -59],
		name: "Guyana",
		country_code: "GY",
		capital: "Georgetown",
	},
	"Asia/Hong_Kong": {
		timezones: ["Asia/Hong_Kong"],
		latlng: [22.267, 114.188],
		name: "Hong Kong",
		country_code: "HK",
		capital: "City of Victoria",
	},
	"America/Tegucigalpa": {
		timezones: ["America/Tegucigalpa"],
		latlng: [15, -86.5],
		name: "Honduras",
		country_code: "HN",
		capital: "Tegucigalpa",
	},
	"Europe/Zagreb": {
		timezones: ["Europe/Zagreb"],
		latlng: [45.16666666, 15.5],
		name: "Croatia",
		country_code: "HR",
		capital: "Zagreb",
	},
	"America/Port-au-Prince": {
		timezones: ["America/Port-au-Prince"],
		latlng: [19, -72.41666666],
		name: "Haiti",
		country_code: "HT",
		capital: "Port-au-Prince",
	},
	"Europe/Budapest": {
		timezones: ["Europe/Budapest"],
		latlng: [47, 20],
		name: "Hungary",
		country_code: "HU",
		capital: "Budapest",
	},
	"Asia/Jakarta": {
		timezones: ["Asia/Jakarta", "Asia/Pontianak", "Asia/Makassar", "Asia/Jayapura"],
		latlng: [-5, 120],
		name: "Indonesia",
		country_code: "ID",
		capital: "Jakarta",
	},
	"Asia/Pontianak": {
		timezones: ["Asia/Jakarta", "Asia/Pontianak", "Asia/Makassar", "Asia/Jayapura"],
		latlng: [-5, 120],
		name: "Indonesia",
		country_code: "ID",
		capital: "Jakarta",
	},
	"Asia/Makassar": {
		timezones: ["Asia/Jakarta", "Asia/Pontianak", "Asia/Makassar", "Asia/Jayapura"],
		latlng: [-5, 120],
		name: "Indonesia",
		country_code: "ID",
		capital: "Jakarta",
	},
	"Asia/Jayapura": {
		timezones: ["Asia/Jakarta", "Asia/Pontianak", "Asia/Makassar", "Asia/Jayapura"],
		latlng: [-5, 120],
		name: "Indonesia",
		country_code: "ID",
		capital: "Jakarta",
	},
	"Europe/Isle_of_Man": {
		timezones: ["Europe/Isle_of_Man"],
		latlng: [54.25, -4.5],
		name: "Isle of Man",
		country_code: "IM",
		capital: "Douglas",
	},
	"Asia/Kolkata": {
		timezones: ["Asia/Kolkata"],
		latlng: [20, 77],
		name: "India",
		country_code: "IN",
		capital: "New Delhi",
	},
	"Indian/Chagos": {
		timezones: ["Indian/Chagos"],
		latlng: [-6, 71.5],
		name: "British Indian Ocean Territory",
		country_code: "IO",
		capital: "Diego Garcia",
	},
	"Europe/Dublin": {
		timezones: ["Europe/Dublin"],
		latlng: [53, -8],
		name: "Ireland",
		country_code: "IE",
		capital: "Dublin",
	},
	"Asia/Tehran": {
		timezones: ["Asia/Tehran"],
		latlng: [32, 53],
		name: "Iran",
		country_code: "IR",
		capital: "Tehran",
	},
	"Asia/Baghdad": {
		timezones: ["Asia/Baghdad"],
		latlng: [33, 44],
		name: "Iraq",
		country_code: "IQ",
		capital: "Baghdad",
	},
	"Atlantic/Reykjavik": {
		timezones: ["Atlantic/Reykjavik"],
		latlng: [65, -18],
		name: "Iceland",
		country_code: "IS",
		capital: "Reykjavik",
	},
	"Asia/Jerusalem": {
		timezones: ["Asia/Jerusalem"],
		latlng: [31.47, 35.13],
		name: "Israel",
		country_code: "IL",
		capital: "Jerusalem",
	},
	"Europe/Rome": {
		timezones: ["Europe/Rome"],
		latlng: [42.83333333, 12.83333333],
		name: "Italy",
		country_code: "IT",
		capital: "Rome",
	},
	"America/Jamaica": {
		timezones: ["America/Jamaica"],
		latlng: [18.25, -77.5],
		name: "Jamaica",
		country_code: "JM",
		capital: "Kingston",
	},
	"Europe/Jersey": {
		timezones: ["Europe/Jersey"],
		latlng: [49.25, -2.16666666],
		name: "Jersey",
		country_code: "JE",
		capital: "Saint Helier",
	},
	"Asia/Amman": {
		timezones: ["Asia/Amman"],
		latlng: [31, 36],
		name: "Jordan",
		country_code: "JO",
		capital: "Amman",
	},
	"Asia/Tokyo": {
		timezones: ["Asia/Tokyo"],
		latlng: [36, 138],
		name: "Japan",
		country_code: "JP",
		capital: "Tokyo",
	},
	"Asia/Almaty": {
		timezones: ["Asia/Almaty", "Asia/Qyzylorda", "Asia/Aqtobe", "Asia/Aqtau", "Asia/Oral"],
		latlng: [48, 68],
		name: "Kazakhstan",
		country_code: "KZ",
		capital: "Astana",
	},
	"Asia/Qyzylorda": {
		timezones: ["Asia/Almaty", "Asia/Qyzylorda", "Asia/Aqtobe", "Asia/Aqtau", "Asia/Oral"],
		latlng: [48, 68],
		name: "Kazakhstan",
		country_code: "KZ",
		capital: "Astana",
	},
	"Asia/Aqtobe": {
		timezones: ["Asia/Almaty", "Asia/Qyzylorda", "Asia/Aqtobe", "Asia/Aqtau", "Asia/Oral"],
		latlng: [48, 68],
		name: "Kazakhstan",
		country_code: "KZ",
		capital: "Astana",
	},
	"Asia/Aqtau": {
		timezones: ["Asia/Almaty", "Asia/Qyzylorda", "Asia/Aqtobe", "Asia/Aqtau", "Asia/Oral"],
		latlng: [48, 68],
		name: "Kazakhstan",
		country_code: "KZ",
		capital: "Astana",
	},
	"Asia/Oral": {
		timezones: ["Asia/Almaty", "Asia/Qyzylorda", "Asia/Aqtobe", "Asia/Aqtau", "Asia/Oral"],
		latlng: [48, 68],
		name: "Kazakhstan",
		country_code: "KZ",
		capital: "Astana",
	},
	"Africa/Nairobi": {
		timezones: ["Africa/Nairobi"],
		latlng: [1, 38],
		name: "Kenya",
		country_code: "KE",
		capital: "Nairobi",
	},
	"Asia/Bishkek": {
		timezones: ["Asia/Bishkek"],
		latlng: [41, 75],
		name: "Kyrgyzstan",
		country_code: "KG",
		capital: "Bishkek",
	},
	"Asia/Phnom_Penh": {
		timezones: ["Asia/Phnom_Penh"],
		latlng: [13, 105],
		name: "Cambodia",
		country_code: "KH",
		capital: "Phnom Penh",
	},
	"Pacific/Tarawa": {
		timezones: ["Pacific/Tarawa", "Pacific/Enderbury", "Pacific/Kiritimati"],
		latlng: [1.41666666, 173],
		name: "Kiribati",
		country_code: "KI",
		capital: "South Tarawa",
	},
	"Pacific/Enderbury": {
		timezones: ["Pacific/Tarawa", "Pacific/Enderbury", "Pacific/Kiritimati"],
		latlng: [1.41666666, 173],
		name: "Kiribati",
		country_code: "KI",
		capital: "South Tarawa",
	},
	"Pacific/Kiritimati": {
		timezones: ["Pacific/Tarawa", "Pacific/Enderbury", "Pacific/Kiritimati"],
		latlng: [1.41666666, 173],
		name: "Kiribati",
		country_code: "KI",
		capital: "South Tarawa",
	},
	"America/St_Kitts": {
		timezones: ["America/St_Kitts"],
		latlng: [17.33333333, -62.75],
		name: "Saint Kitts and Nevis",
		country_code: "KN",
		capital: "Basseterre",
	},
	"Asia/Seoul": {
		timezones: ["Asia/Seoul"],
		latlng: [37, 127.5],
		name: "South Korea",
		country_code: "KR",
		capital: "Seoul",
	},
	"Europe/Belgrade": {
		timezones: ["Europe/Belgrade"],
		latlng: [44, 21],
		name: "Serbia",
		country_code: "RS",
		capital: "Belgrade",
	},
	"Asia/Kuwait": {
		timezones: ["Asia/Kuwait"],
		latlng: [29.5, 45.75],
		name: "Kuwait",
		country_code: "KW",
		capital: "Kuwait City",
	},
	"Asia/Vientiane": {
		timezones: ["Asia/Vientiane"],
		latlng: [18, 105],
		name: "Laos",
		country_code: "LA",
		capital: "Vientiane",
	},
	"Asia/Beirut": {
		timezones: ["Asia/Beirut"],
		latlng: [33.83333333, 35.83333333],
		name: "Lebanon",
		country_code: "LB",
		capital: "Beirut",
	},
	"Africa/Monrovia": {
		timezones: ["Africa/Monrovia"],
		latlng: [6.5, -9.5],
		name: "Liberia",
		country_code: "LR",
		capital: "Monrovia",
	},
	"Africa/Tripoli": {
		timezones: ["Africa/Tripoli"],
		latlng: [25, 17],
		name: "Libya",
		country_code: "LY",
		capital: "Tripoli",
	},
	"America/St_Lucia": {
		timezones: ["America/St_Lucia"],
		latlng: [13.88333333, -60.96666666],
		name: "Saint Lucia",
		country_code: "LC",
		capital: "Castries",
	},
	"Europe/Vaduz": {
		timezones: ["Europe/Vaduz"],
		latlng: [47.26666666, 9.53333333],
		name: "Liechtenstein",
		country_code: "LI",
		capital: "Vaduz",
	},
	"Asia/Colombo": {
		timezones: ["Asia/Colombo"],
		latlng: [7, 81],
		name: "Sri Lanka",
		country_code: "LK",
		capital: "Colombo",
	},
	"Africa/Maseru": {
		timezones: ["Africa/Maseru"],
		latlng: [-29.5, 28.5],
		name: "Lesotho",
		country_code: "LS",
		capital: "Maseru",
	},
	"Europe/Vilnius": {
		timezones: ["Europe/Vilnius"],
		latlng: [56, 24],
		name: "Lithuania",
		country_code: "LT",
		capital: "Vilnius",
	},
	"Europe/Luxembourg": {
		timezones: ["Europe/Luxembourg"],
		latlng: [49.75, 6.16666666],
		name: "Luxembourg",
		country_code: "LU",
		capital: "Luxembourg",
	},
	"Europe/Riga": {
		timezones: ["Europe/Riga"],
		latlng: [57, 25],
		name: "Latvia",
		country_code: "LV",
		capital: "Riga",
	},
	"Asia/Macau": {
		timezones: ["Asia/Macau"],
		latlng: [22.16666666, 113.55],
		name: "Macau",
		country_code: "MO",
		capital: null,
	},
	"America/Marigot": {
		timezones: ["America/Marigot"],
		latlng: [18.08333333, -63.95],
		name: "Saint Martin",
		country_code: "MF",
		capital: "Marigot",
	},
	"Africa/Casablanca": {
		timezones: ["Africa/Casablanca"],
		latlng: [32, -5],
		name: "Morocco",
		country_code: "MA",
		capital: "Rabat",
	},
	"Europe/Monaco": {
		timezones: ["Europe/Monaco"],
		latlng: [43.73333333, 7.4],
		name: "Monaco",
		country_code: "MC",
		capital: "Monaco",
	},
	"Europe/Chisinau": {
		timezones: ["Europe/Chisinau"],
		latlng: [47, 29],
		name: "Moldova",
		country_code: "MD",
		capital: "Chișinău",
	},
	"Indian/Antananarivo": {
		timezones: ["Indian/Antananarivo"],
		latlng: [-20, 47],
		name: "Madagascar",
		country_code: "MG",
		capital: "Antananarivo",
	},
	"Indian/Maldives": {
		timezones: ["Indian/Maldives"],
		latlng: [3.25, 73],
		name: "Maldives",
		country_code: "MV",
		capital: "Malé",
	},
	"America/Mexico_City": {
		timezones: [
			"America/Mexico_City",
			"America/Cancun",
			"America/Merida",
			"America/Monterrey",
			"America/Matamoros",
			"America/Mazatlan",
			"America/Chihuahua",
			"America/Ojinaga",
			"America/Hermosillo",
			"America/Tijuana",
			"America/Bahia_Banderas",
		],
		latlng: [23, -102],
		name: "Mexico",
		country_code: "MX",
		capital: "Mexico City",
	},
	"America/Cancun": {
		timezones: [
			"America/Mexico_City",
			"America/Cancun",
			"America/Merida",
			"America/Monterrey",
			"America/Matamoros",
			"America/Mazatlan",
			"America/Chihuahua",
			"America/Ojinaga",
			"America/Hermosillo",
			"America/Tijuana",
			"America/Bahia_Banderas",
		],
		latlng: [23, -102],
		name: "Mexico",
		country_code: "MX",
		capital: "Mexico City",
	},
	"America/Merida": {
		timezones: [
			"America/Mexico_City",
			"America/Cancun",
			"America/Merida",
			"America/Monterrey",
			"America/Matamoros",
			"America/Mazatlan",
			"America/Chihuahua",
			"America/Ojinaga",
			"America/Hermosillo",
			"America/Tijuana",
			"America/Bahia_Banderas",
		],
		latlng: [23, -102],
		name: "Mexico",
		country_code: "MX",
		capital: "Mexico City",
	},
	"America/Monterrey": {
		timezones: [
			"America/Mexico_City",
			"America/Cancun",
			"America/Merida",
			"America/Monterrey",
			"America/Matamoros",
			"America/Mazatlan",
			"America/Chihuahua",
			"America/Ojinaga",
			"America/Hermosillo",
			"America/Tijuana",
			"America/Bahia_Banderas",
		],
		latlng: [23, -102],
		name: "Mexico",
		country_code: "MX",
		capital: "Mexico City",
	},
	"America/Matamoros": {
		timezones: [
			"America/Mexico_City",
			"America/Cancun",
			"America/Merida",
			"America/Monterrey",
			"America/Matamoros",
			"America/Mazatlan",
			"America/Chihuahua",
			"America/Ojinaga",
			"America/Hermosillo",
			"America/Tijuana",
			"America/Bahia_Banderas",
		],
		latlng: [23, -102],
		name: "Mexico",
		country_code: "MX",
		capital: "Mexico City",
	},
	"America/Mazatlan": {
		timezones: [
			"America/Mexico_City",
			"America/Cancun",
			"America/Merida",
			"America/Monterrey",
			"America/Matamoros",
			"America/Mazatlan",
			"America/Chihuahua",
			"America/Ojinaga",
			"America/Hermosillo",
			"America/Tijuana",
			"America/Bahia_Banderas",
		],
		latlng: [23, -102],
		name: "Mexico",
		country_code: "MX",
		capital: "Mexico City",
	},
	"America/Chihuahua": {
		timezones: [
			"America/Mexico_City",
			"America/Cancun",
			"America/Merida",
			"America/Monterrey",
			"America/Matamoros",
			"America/Mazatlan",
			"America/Chihuahua",
			"America/Ojinaga",
			"America/Hermosillo",
			"America/Tijuana",
			"America/Bahia_Banderas",
		],
		latlng: [23, -102],
		name: "Mexico",
		country_code: "MX",
		capital: "Mexico City",
	},
	"America/Ojinaga": {
		timezones: [
			"America/Mexico_City",
			"America/Cancun",
			"America/Merida",
			"America/Monterrey",
			"America/Matamoros",
			"America/Mazatlan",
			"America/Chihuahua",
			"America/Ojinaga",
			"America/Hermosillo",
			"America/Tijuana",
			"America/Bahia_Banderas",
		],
		latlng: [23, -102],
		name: "Mexico",
		country_code: "MX",
		capital: "Mexico City",
	},
	"America/Hermosillo": {
		timezones: [
			"America/Mexico_City",
			"America/Cancun",
			"America/Merida",
			"America/Monterrey",
			"America/Matamoros",
			"America/Mazatlan",
			"America/Chihuahua",
			"America/Ojinaga",
			"America/Hermosillo",
			"America/Tijuana",
			"America/Bahia_Banderas",
		],
		latlng: [23, -102],
		name: "Mexico",
		country_code: "MX",
		capital: "Mexico City",
	},
	"America/Tijuana": {
		timezones: [
			"America/Mexico_City",
			"America/Cancun",
			"America/Merida",
			"America/Monterrey",
			"America/Matamoros",
			"America/Mazatlan",
			"America/Chihuahua",
			"America/Ojinaga",
			"America/Hermosillo",
			"America/Tijuana",
			"America/Bahia_Banderas",
		],
		latlng: [23, -102],
		name: "Mexico",
		country_code: "MX",
		capital: "Mexico City",
	},
	"America/Bahia_Banderas": {
		timezones: [
			"America/Mexico_City",
			"America/Cancun",
			"America/Merida",
			"America/Monterrey",
			"America/Matamoros",
			"America/Mazatlan",
			"America/Chihuahua",
			"America/Ojinaga",
			"America/Hermosillo",
			"America/Tijuana",
			"America/Bahia_Banderas",
		],
		latlng: [23, -102],
		name: "Mexico",
		country_code: "MX",
		capital: "Mexico City",
	},
	"Pacific/Majuro": {
		timezones: ["Pacific/Majuro", "Pacific/Kwajalein"],
		latlng: [9, 168],
		name: "Marshall Islands",
		country_code: "MH",
		capital: "Majuro",
	},
	"Pacific/Kwajalein": {
		timezones: ["Pacific/Majuro", "Pacific/Kwajalein"],
		latlng: [9, 168],
		name: "Marshall Islands",
		country_code: "MH",
		capital: "Majuro",
	},
	"Europe/Skopje": {
		timezones: ["Europe/Skopje"],
		latlng: [41.83333333, 22],
		name: "Macedonia",
		country_code: "MK",
		capital: "Skopje",
	},
	"Africa/Bamako": {
		timezones: ["Africa/Bamako"],
		latlng: [17, -4],
		name: "Mali",
		country_code: "ML",
		capital: "Bamako",
	},
	"Europe/Malta": {
		timezones: ["Europe/Malta"],
		latlng: [35.83333333, 14.58333333],
		name: "Malta",
		country_code: "MT",
		capital: "Valletta",
	},
	"Asia/Rangoon": {
		timezones: ["Asia/Rangoon"],
		latlng: [22, 98],
		name: "Myanmar",
		country_code: "MM",
		capital: "Naypyidaw",
	},
	"Europe/Podgorica": {
		timezones: ["Europe/Podgorica"],
		latlng: [42.5, 19.3],
		name: "Montenegro",
		country_code: "ME",
		capital: "Podgorica",
	},
	"Asia/Ulaanbaatar": {
		timezones: ["Asia/Ulaanbaatar", "Asia/Hovd", "Asia/Choibalsan"],
		latlng: [46, 105],
		name: "Mongolia",
		country_code: "MN",
		capital: "Ulan Bator",
	},
	"Asia/Hovd": {
		timezones: ["Asia/Ulaanbaatar", "Asia/Hovd", "Asia/Choibalsan"],
		latlng: [46, 105],
		name: "Mongolia",
		country_code: "MN",
		capital: "Ulan Bator",
	},
	"Asia/Choibalsan": {
		timezones: ["Asia/Ulaanbaatar", "Asia/Hovd", "Asia/Choibalsan"],
		latlng: [46, 105],
		name: "Mongolia",
		country_code: "MN",
		capital: "Ulan Bator",
	},
	"Pacific/Saipan": {
		timezones: ["Pacific/Saipan"],
		latlng: [15.2, 145.75],
		name: "Northern Mariana Islands",
		country_code: "MP",
		capital: "Saipan",
	},
	"Africa/Maputo": {
		timezones: ["Africa/Maputo"],
		latlng: [-18.25, 35],
		name: "Mozambique",
		country_code: "MZ",
		capital: "Maputo",
	},
	"Africa/Nouakchott": {
		timezones: ["Africa/Nouakchott"],
		latlng: [20, -12],
		name: "Mauritania",
		country_code: "MR",
		capital: "Nouakchott",
	},
	"America/Montserrat": {
		timezones: ["America/Montserrat"],
		latlng: [16.75, -62.2],
		name: "Montserrat",
		country_code: "MS",
		capital: "Plymouth",
	},
	"America/Martinique": {
		timezones: ["America/Martinique"],
		latlng: [14.666667, -61],
		name: "Martinique",
		country_code: "MQ",
		capital: "Fort-de-France",
	},
	"Indian/Mauritius": {
		timezones: ["Indian/Mauritius"],
		latlng: [-20.28333333, 57.55],
		name: "Mauritius",
		country_code: "MU",
		capital: "Port Louis",
	},
	"Africa/Blantyre": {
		timezones: ["Africa/Blantyre"],
		latlng: [-13.5, 34],
		name: "Malawi",
		country_code: "MW",
		capital: "Lilongwe",
	},
	"Asia/Kuala_Lumpur": {
		timezones: ["Asia/Kuala_Lumpur", "Asia/Kuching"],
		latlng: [2.5, 112.5],
		name: "Malaysia",
		country_code: "MY",
		capital: "Kuala Lumpur",
	},
	"Asia/Kuching": {
		timezones: ["Asia/Kuala_Lumpur", "Asia/Kuching"],
		latlng: [2.5, 112.5],
		name: "Malaysia",
		country_code: "MY",
		capital: "Kuala Lumpur",
	},
	"Indian/Mayotte": {
		timezones: ["Indian/Mayotte"],
		latlng: [-12.83333333, 45.16666666],
		name: "Mayotte",
		country_code: "YT",
		capital: "Mamoudzou",
	},
	"Africa/Windhoek": {
		timezones: ["Africa/Windhoek"],
		latlng: [-22, 17],
		name: "Namibia",
		country_code: "NA",
		capital: "Windhoek",
	},
	"Pacific/Noumea": {
		timezones: ["Pacific/Noumea"],
		latlng: [-21.5, 165.5],
		name: "New Caledonia",
		country_code: "NC",
		capital: "Nouméa",
	},
	"Africa/Niamey": {
		timezones: ["Africa/Niamey"],
		latlng: [16, 8],
		name: "Niger",
		country_code: "NE",
		capital: "Niamey",
	},
	"Pacific/Norfolk": {
		timezones: ["Pacific/Norfolk"],
		latlng: [-29.03333333, 167.95],
		name: "Norfolk Island",
		country_code: "NF",
		capital: "Kingston",
	},
	"Africa/Lagos": {
		timezones: ["Africa/Lagos"],
		latlng: [10, 8],
		name: "Nigeria",
		country_code: "NG",
		capital: "Abuja",
	},
	"America/Managua": {
		timezones: ["America/Managua"],
		latlng: [13, -85],
		name: "Nicaragua",
		country_code: "NI",
		capital: "Managua",
	},
	"Pacific/Niue": {
		timezones: ["Pacific/Niue"],
		latlng: [-19.03333333, -169.86666666],
		name: "Niue",
		country_code: "NU",
		capital: "Alofi",
	},
	"Europe/Amsterdam": {
		timezones: ["Europe/Amsterdam"],
		latlng: [52.5, 5.75],
		name: "Netherlands",
		country_code: "NL",
		capital: "Amsterdam",
	},
	"Asia/Kathmandu": {
		timezones: ["Asia/Kathmandu"],
		latlng: [28, 84],
		name: "Nepal",
		country_code: "NP",
		capital: "Kathmandu",
	},
	"Pacific/Nauru": {
		timezones: ["Pacific/Nauru"],
		latlng: [-0.53333333, 166.91666666],
		name: "Nauru",
		country_code: "NR",
		capital: "Yaren",
	},
	"Pacific/Auckland": {
		timezones: ["Pacific/Auckland", "Pacific/Chatham"],
		latlng: [-41, 174],
		name: "New Zealand",
		country_code: "NZ",
		capital: "Wellington",
	},
	"Pacific/Chatham": {
		timezones: ["Pacific/Auckland", "Pacific/Chatham"],
		latlng: [-41, 174],
		name: "New Zealand",
		country_code: "NZ",
		capital: "Wellington",
	},
	"Asia/Muscat": {
		timezones: ["Asia/Muscat"],
		latlng: [21, 57],
		name: "Oman",
		country_code: "OM",
		capital: "Muscat",
	},
	"Asia/Karachi": {
		timezones: ["Asia/Karachi"],
		latlng: [30, 70],
		name: "Pakistan",
		country_code: "PK",
		capital: "Islamabad",
	},
	"America/Panama": {
		timezones: ["America/Panama"],
		latlng: [9, -80],
		name: "Panama",
		country_code: "PA",
		capital: "Panama City",
	},
	"Pacific/Pitcairn": {
		timezones: ["Pacific/Pitcairn"],
		latlng: [-25.06666666, -130.1],
		name: "Pitcairn Islands",
		country_code: "PN",
		capital: "Adamstown",
	},
	"America/Lima": {
		timezones: ["America/Lima"],
		latlng: [-10, -76],
		name: "Peru",
		country_code: "PE",
		capital: "Lima",
	},
	"Asia/Manila": {
		timezones: ["Asia/Manila"],
		latlng: [13, 122],
		name: "Philippines",
		country_code: "PH",
		capital: "Manila",
	},
	"Pacific/Palau": {
		timezones: ["Pacific/Palau"],
		latlng: [7.5, 134.5],
		name: "Palau",
		country_code: "PW",
		capital: "Ngerulmud",
	},
	"Pacific/Port_Moresby": {
		timezones: ["Pacific/Port_Moresby", "Pacific/Bougainville"],
		latlng: [-6, 147],
		name: "Papua New Guinea",
		country_code: "PG",
		capital: "Port Moresby",
	},
	"Pacific/Bougainville": {
		timezones: ["Pacific/Port_Moresby", "Pacific/Bougainville"],
		latlng: [-6, 147],
		name: "Papua New Guinea",
		country_code: "PG",
		capital: "Port Moresby",
	},
	"Europe/Warsaw": {
		timezones: ["Europe/Warsaw"],
		latlng: [52, 20],
		name: "Poland",
		country_code: "PL",
		capital: "Warsaw",
	},
	"America/Puerto_Rico": {
		timezones: ["America/Puerto_Rico"],
		latlng: [18.25, -66.5],
		name: "Puerto Rico",
		country_code: "PR",
		capital: "San Juan",
	},
	"Asia/Pyongyang": {
		timezones: ["Asia/Pyongyang"],
		latlng: [40, 127],
		name: "North Korea",
		country_code: "KP",
		capital: "Pyongyang",
	},
	"Europe/Lisbon": {
		timezones: ["Europe/Lisbon", "Atlantic/Madeira", "Atlantic/Azores"],
		latlng: [39.5, -8],
		name: "Portugal",
		country_code: "PT",
		capital: "Lisbon",
	},
	"Atlantic/Madeira": {
		timezones: ["Europe/Lisbon", "Atlantic/Madeira", "Atlantic/Azores"],
		latlng: [39.5, -8],
		name: "Portugal",
		country_code: "PT",
		capital: "Lisbon",
	},
	"Atlantic/Azores": {
		timezones: ["Europe/Lisbon", "Atlantic/Madeira", "Atlantic/Azores"],
		latlng: [39.5, -8],
		name: "Portugal",
		country_code: "PT",
		capital: "Lisbon",
	},
	"America/Asuncion": {
		timezones: ["America/Asuncion"],
		latlng: [-23, -58],
		name: "Paraguay",
		country_code: "PY",
		capital: "Asunción",
	},
	"Asia/Gaza": {
		timezones: ["Asia/Gaza", "Asia/Hebron"],
		latlng: [31.9, 35.2],
		name: "Palestine",
		country_code: "PS",
		capital: "Ramallah",
	},
	"Asia/Hebron": {
		timezones: ["Asia/Gaza", "Asia/Hebron"],
		latlng: [31.9, 35.2],
		name: "Palestine",
		country_code: "PS",
		capital: "Ramallah",
	},
	"Pacific/Tahiti": {
		timezones: ["Pacific/Tahiti", "Pacific/Marquesas", "Pacific/Gambier"],
		latlng: [-15, -140],
		name: "French Polynesia",
		country_code: "PF",
		capital: "Papeetē",
	},
	"Pacific/Marquesas": {
		timezones: ["Pacific/Tahiti", "Pacific/Marquesas", "Pacific/Gambier"],
		latlng: [-15, -140],
		name: "French Polynesia",
		country_code: "PF",
		capital: "Papeetē",
	},
	"Pacific/Gambier": {
		timezones: ["Pacific/Tahiti", "Pacific/Marquesas", "Pacific/Gambier"],
		latlng: [-15, -140],
		name: "French Polynesia",
		country_code: "PF",
		capital: "Papeetē",
	},
	"Asia/Qatar": {
		timezones: ["Asia/Qatar"],
		latlng: [25.5, 51.25],
		name: "Qatar",
		country_code: "QA",
		capital: "Doha",
	},
	"Indian/Reunion": {
		timezones: ["Indian/Reunion"],
		latlng: [-21.15, 55.5],
		name: "Réunion",
		country_code: "RE",
		capital: "Saint-Denis",
	},
	"Europe/Bucharest": {
		timezones: ["Europe/Bucharest"],
		latlng: [46, 25],
		name: "Romania",
		country_code: "RO",
		capital: "Bucharest",
	},
	"Europe/Kaliningrad": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Europe/Moscow": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Europe/Simferopol": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Europe/Volgograd": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Europe/Kirov": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Europe/Astrakhan": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Europe/Samara": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Europe/Ulyanovsk": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Asia/Yekaterinburg": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Asia/Omsk": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Asia/Novosibirsk": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Asia/Barnaul": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Asia/Tomsk": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Asia/Novokuznetsk": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Asia/Krasnoyarsk": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Asia/Irkutsk": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Asia/Chita": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Asia/Yakutsk": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Asia/Khandyga": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Asia/Vladivostok": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Asia/Ust-Nera": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Asia/Magadan": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Asia/Sakhalin": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Asia/Srednekolymsk": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Asia/Kamchatka": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Asia/Anadyr": {
		timezones: [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr",
		],
		latlng: [60, 100],
		name: "Russia",
		country_code: "RU",
		capital: "Moscow",
	},
	"Africa/Kigali": {
		timezones: ["Africa/Kigali"],
		latlng: [-2, 30],
		name: "Rwanda",
		country_code: "RW",
		capital: "Kigali",
	},
	"Asia/Riyadh": {
		timezones: ["Asia/Riyadh"],
		latlng: [25, 45],
		name: "Saudi Arabia",
		country_code: "SA",
		capital: "Riyadh",
	},
	"Africa/Khartoum": {
		timezones: ["Africa/Khartoum"],
		latlng: [15, 30],
		name: "Sudan",
		country_code: "SD",
		capital: "Khartoum",
	},
	"Africa/Dakar": {
		timezones: ["Africa/Dakar"],
		latlng: [14, -14],
		name: "Senegal",
		country_code: "SN",
		capital: "Dakar",
	},
	"Asia/Singapore": {
		timezones: ["Asia/Singapore"],
		latlng: [1.36666666, 103.8],
		name: "Singapore",
		country_code: "SG",
		capital: "Singapore",
	},
	"Atlantic/South_Georgia": {
		timezones: ["Atlantic/South_Georgia"],
		latlng: [-54.5, -37],
		name: "South Georgia",
		country_code: "GS",
		capital: "King Edward Point",
	},
	"Arctic/Longyearbyen": {
		timezones: ["Arctic/Longyearbyen"],
		latlng: [78, 20],
		name: "Svalbard and Jan Mayen",
		country_code: "SJ",
		capital: "Longyearbyen",
	},
	"Pacific/Guadalcanal": {
		timezones: ["Pacific/Guadalcanal"],
		latlng: [-8, 159],
		name: "Solomon Islands",
		country_code: "SB",
		capital: "Honiara",
	},
	"Africa/Freetown": {
		timezones: ["Africa/Freetown"],
		latlng: [8.5, -11.5],
		name: "Sierra Leone",
		country_code: "SL",
		capital: "Freetown",
	},
	"America/El_Salvador": {
		timezones: ["America/El_Salvador"],
		latlng: [13.83333333, -88.91666666],
		name: "El Salvador",
		country_code: "SV",
		capital: "San Salvador",
	},
	"Europe/San_Marino": {
		timezones: ["Europe/San_Marino"],
		latlng: [43.76666666, 12.41666666],
		name: "San Marino",
		country_code: "SM",
		capital: "City of San Marino",
	},
	"Africa/Mogadishu": {
		timezones: ["Africa/Mogadishu"],
		latlng: [10, 49],
		name: "Somalia",
		country_code: "SO",
		capital: "Mogadishu",
	},
	"America/Miquelon": {
		timezones: ["America/Miquelon"],
		latlng: [46.83333333, -56.33333333],
		name: "Saint Pierre and Miquelon",
		country_code: "PM",
		capital: "Saint-Pierre",
	},
	"Africa/Juba": {
		timezones: ["Africa/Juba"],
		latlng: [7, 30],
		name: "South Sudan",
		country_code: "SS",
		capital: "Juba",
	},
	"Africa/Sao_Tome": {
		timezones: ["Africa/Sao_Tome"],
		latlng: [1, 7],
		name: "São Tomé and Príncipe",
		country_code: "ST",
		capital: "São Tomé",
	},
	"America/Paramaribo": {
		timezones: ["America/Paramaribo"],
		latlng: [4, -56],
		name: "Suriname",
		country_code: "SR",
		capital: "Paramaribo",
	},
	"Europe/Bratislava": {
		timezones: ["Europe/Bratislava"],
		latlng: [48.66666666, 19.5],
		name: "Slovakia",
		country_code: "SK",
		capital: "Bratislava",
	},
	"Europe/Ljubljana": {
		timezones: ["Europe/Ljubljana"],
		latlng: [46.11666666, 14.81666666],
		name: "Slovenia",
		country_code: "SI",
		capital: "Ljubljana",
	},
	"Europe/Stockholm": {
		timezones: ["Europe/Stockholm"],
		latlng: [62, 15],
		name: "Sweden",
		country_code: "SE",
		capital: "Stockholm",
	},
	"Africa/Mbabane": {
		timezones: ["Africa/Mbabane"],
		latlng: [-26.5, 31.5],
		name: "Swaziland",
		country_code: "SZ",
		capital: "Lobamba",
	},
	"America/Lower_Princes": {
		timezones: ["America/Lower_Princes"],
		latlng: [18.033333, -63.05],
		name: "Sint Maarten",
		country_code: "SX",
		capital: "Philipsburg",
	},
	"Indian/Mahe": {
		timezones: ["Indian/Mahe"],
		latlng: [-4.58333333, 55.66666666],
		name: "Seychelles",
		country_code: "SC",
		capital: "Victoria",
	},
	"Asia/Damascus": {
		timezones: ["Asia/Damascus"],
		latlng: [35, 38],
		name: "Syria",
		country_code: "SY",
		capital: "Damascus",
	},
	"America/Grand_Turk": {
		timezones: ["America/Grand_Turk"],
		latlng: [21.75, -71.58333333],
		name: "Turks and Caicos Islands",
		country_code: "TC",
		capital: "Cockburn Town",
	},
	"Africa/Ndjamena": {
		timezones: ["Africa/Ndjamena"],
		latlng: [15, 19],
		name: "Chad",
		country_code: "TD",
		capital: "N'Djamena",
	},
	"Africa/Lome": {
		timezones: ["Africa/Lome"],
		latlng: [8, 1.16666666],
		name: "Togo",
		country_code: "TG",
		capital: "Lomé",
	},
	"Asia/Bangkok": {
		timezones: ["Asia/Bangkok"],
		latlng: [15, 100],
		name: "Thailand",
		country_code: "TH",
		capital: "Bangkok",
	},
	"Asia/Dushanbe": {
		timezones: ["Asia/Dushanbe"],
		latlng: [39, 71],
		name: "Tajikistan",
		country_code: "TJ",
		capital: "Dushanbe",
	},
	"Pacific/Fakaofo": {
		timezones: ["Pacific/Fakaofo"],
		latlng: [-9, -172],
		name: "Tokelau",
		country_code: "TK",
		capital: "Fakaofo",
	},
	"Asia/Ashgabat": {
		timezones: ["Asia/Ashgabat"],
		latlng: [40, 60],
		name: "Turkmenistan",
		country_code: "TM",
		capital: "Ashgabat",
	},
	"Asia/Dili": {
		timezones: ["Asia/Dili"],
		latlng: [-8.83333333, 125.91666666],
		name: "Timor-Leste",
		country_code: "TL",
		capital: "Dili",
	},
	"Pacific/Tongatapu": {
		timezones: ["Pacific/Tongatapu"],
		latlng: [-20, -175],
		name: "Tonga",
		country_code: "TO",
		capital: "Nuku'alofa",
	},
	"America/Port_of_Spain": {
		timezones: ["America/Port_of_Spain"],
		latlng: [11, -61],
		name: "Trinidad and Tobago",
		country_code: "TT",
		capital: "Port of Spain",
	},
	"Africa/Tunis": {
		timezones: ["Africa/Tunis"],
		latlng: [34, 9],
		name: "Tunisia",
		country_code: "TN",
		capital: "Tunis",
	},
	"Europe/Istanbul": {
		timezones: ["Europe/Istanbul"],
		latlng: [39, 35],
		name: "Turkey",
		country_code: "TR",
		capital: "Ankara",
	},
	"Pacific/Funafuti": {
		timezones: ["Pacific/Funafuti"],
		latlng: [-8, 178],
		name: "Tuvalu",
		country_code: "TV",
		capital: "Funafuti",
	},
	"Asia/Taipei": {
		timezones: ["Asia/Taipei"],
		latlng: [23.5, 121],
		name: "Taiwan",
		country_code: "TW",
		capital: "Taipei",
	},
	"Africa/Dar_es_Salaam": {
		timezones: ["Africa/Dar_es_Salaam"],
		latlng: [-6, 35],
		name: "Tanzania",
		country_code: "TZ",
		capital: "Dodoma",
	},
	"Africa/Kampala": {
		timezones: ["Africa/Kampala"],
		latlng: [1, 32],
		name: "Uganda",
		country_code: "UG",
		capital: "Kampala",
	},
	"Europe/Kiev": {
		timezones: ["Europe/Kiev", "Europe/Uzhgorod", "Europe/Zaporozhye"],
		latlng: [49, 32],
		name: "Ukraine",
		country_code: "UA",
		capital: "Kiev",
	},
	"Europe/Uzhgorod": {
		timezones: ["Europe/Kiev", "Europe/Uzhgorod", "Europe/Zaporozhye"],
		latlng: [49, 32],
		name: "Ukraine",
		country_code: "UA",
		capital: "Kiev",
	},
	"Europe/Zaporozhye": {
		timezones: ["Europe/Kiev", "Europe/Uzhgorod", "Europe/Zaporozhye"],
		latlng: [49, 32],
		name: "Ukraine",
		country_code: "UA",
		capital: "Kiev",
	},
	"Pacific/Johnston": {
		timezones: ["Pacific/Johnston", "Pacific/Midway", "Pacific/Wake"],
		latlng: [19.2911437, 166.618332],
		name: "United States Minor Outlying Islands",
		country_code: "UM",
		capital: null,
	},
	"Pacific/Midway": {
		timezones: ["Pacific/Johnston", "Pacific/Midway", "Pacific/Wake"],
		latlng: [19.2911437, 166.618332],
		name: "United States Minor Outlying Islands",
		country_code: "UM",
		capital: null,
	},
	"Pacific/Wake": {
		timezones: ["Pacific/Johnston", "Pacific/Midway", "Pacific/Wake"],
		latlng: [19.2911437, 166.618332],
		name: "United States Minor Outlying Islands",
		country_code: "UM",
		capital: null,
	},
	"America/Montevideo": {
		timezones: ["America/Montevideo"],
		latlng: [-33, -56],
		name: "Uruguay",
		country_code: "UY",
		capital: "Montevideo",
	},
	"America/New_York": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Detroit": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Kentucky/Louisville": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Kentucky/Monticello": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Indiana/Indianapolis": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Indiana/Vincennes": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Indiana/Winamac": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Indiana/Marengo": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Indiana/Petersburg": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Indiana/Vevay": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Chicago": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Indiana/Tell_City": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Indiana/Knox": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Menominee": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/North_Dakota/Center": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/North_Dakota/New_Salem": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/North_Dakota/Beulah": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Denver": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Boise": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Phoenix": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Los_Angeles": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Anchorage": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Juneau": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Sitka": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Metlakatla": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Yakutat": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Nome": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"America/Adak": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"Pacific/Honolulu": {
		timezones: [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu",
		],
		latlng: [38, -97],
		name: "United States",
		country_code: "US",
		capital: "Washington D.C.",
	},
	"Asia/Samarkand": {
		timezones: ["Asia/Samarkand", "Asia/Tashkent"],
		latlng: [41, 64],
		name: "Uzbekistan",
		country_code: "UZ",
		capital: "Tashkent",
	},
	"Asia/Tashkent": {
		timezones: ["Asia/Samarkand", "Asia/Tashkent"],
		latlng: [41, 64],
		name: "Uzbekistan",
		country_code: "UZ",
		capital: "Tashkent",
	},
	"Europe/Vatican": {
		timezones: ["Europe/Vatican"],
		latlng: [41.9, 12.45],
		name: "Vatican City",
		country_code: "VA",
		capital: "Vatican City",
	},
	"America/St_Vincent": {
		timezones: ["America/St_Vincent"],
		latlng: [13.25, -61.2],
		name: "Saint Vincent and the Grenadines",
		country_code: "VC",
		capital: "Kingstown",
	},
	"America/Caracas": {
		timezones: ["America/Caracas"],
		latlng: [8, -66],
		name: "Venezuela",
		country_code: "VE",
		capital: "Caracas",
	},
	"America/Tortola": {
		timezones: ["America/Tortola"],
		latlng: [18.431383, -64.62305],
		name: "British Virgin Islands",
		country_code: "VG",
		capital: "Road Town",
	},
	"America/St_Thomas": {
		timezones: ["America/St_Thomas"],
		latlng: [18.35, -64.933333],
		name: "United States Virgin Islands",
		country_code: "VI",
		capital: "Charlotte Amalie",
	},
	"Asia/Ho_Chi_Minh": {
		timezones: ["Asia/Ho_Chi_Minh"],
		latlng: [16.16666666, 107.83333333],
		name: "Vietnam",
		country_code: "VN",
		capital: "Hanoi",
	},
	"Pacific/Efate": {
		timezones: ["Pacific/Efate"],
		latlng: [-16, 167],
		name: "Vanuatu",
		country_code: "VU",
		capital: "Port Vila",
	},
	"Pacific/Wallis": {
		timezones: ["Pacific/Wallis"],
		latlng: [-13.3, -176.2],
		name: "Wallis and Futuna",
		country_code: "WF",
		capital: "Mata-Utu",
	},
	"Pacific/Apia": {
		timezones: ["Pacific/Apia"],
		latlng: [-13.58333333, -172.33333333],
		name: "Samoa",
		country_code: "WS",
		capital: "Apia",
	},
	"Asia/Aden": {
		timezones: ["Asia/Aden"],
		latlng: [15, 48],
		name: "Yemen",
		country_code: "YE",
		capital: "Sana'a",
	},
	"Africa/Johannesburg": {
		timezones: ["Africa/Johannesburg"],
		latlng: [-29, 24],
		name: "South Africa",
		country_code: "ZA",
		capital: "Pretoria",
	},
	"Africa/Lusaka": {
		timezones: ["Africa/Lusaka"],
		latlng: [-15, 30],
		name: "Zambia",
		country_code: "ZM",
		capital: "Lusaka",
	},
	"Africa/Harare": {
		timezones: ["Africa/Harare"],
		latlng: [-20, 30],
		name: "Zimbabwe",
		country_code: "ZW",
		capital: "Harare",
	},
};
