import React, { useEffect, useState } from "react";
import { useAdminCompanySettings } from "../useAdminEntitlements";
import useAdminInfo from "../useAdminInfo";
import CampaignIcon from "@mui/icons-material/Campaign";

const DecomissionMessageBanner = () => {
	const customEntityMigrationPhase = useAdminCompanySettings("customEntityMigrationPhase");
	const user = useAdminInfo();
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		// Cleanup function to remove the event listener when the component unmounts
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	if (customEntityMigrationPhase !== 1 || !user.isLoggedIn) return null;

	return (
		<div
			style={{
				backgroundColor: "#E1EFFE",
				color: "#1C64F2",
				padding: "10px",
				textAlign: "center",
				fontSize: "14px",
				fontWeight: "bold",
				width: "100%",
				fontFamily: "SFUIText-Light, Helvetica, Arial, sans-serif",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				gap: "0.5rem",
			}}
			className={`${width < 768 ? "d-none" : ""}`}>
			<div
				style={{
					padding: "5px",
					background: "white",
					borderRadius: "10px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<CampaignIcon style={{ width: "20px", height: "20px" }} />
			</div>
			<div>
				The current tasks module will be going away on{" "}
				<span style={{ fontFamily: "SFUIText-Bold, Helvetica, Arial, sans-serif", fontSize: "inherit" }}>
					September 30, 2024
				</span>
				. Use the more powerful and flexible Custom Tasks Module. Learn how to create a{" "}
				<a
					href="https://panel.unolo.com/allFeatures"
					style={{
						borderBottom: "1.5px solid #1C64F2",
						fontSize: "inherit",
						fontFamily: "SFUIText-Bold, Helvetica, Arial, sans-serif",
					}}>
					Custom Task
				</a>
				.
				{/* <span
					style={{
						display: "inline-block",
						fontSize: "20px",
						margin: "0 10px",
					}}>
					|
				</span>
				<a
					href="https://panel.unolo.com/videos/GETTING%20STARTED%20WITH%20UNOLO"
					style={{
						borderBottom: "1.5px solid #1C64F2",
						fontSize: "inherit",
						fontFamily: "SFUIText-Bold, Helvetica, Arial, sans-serif",
					}}>
					Watch more
				</a> */}
			</div>
		</div>
	);
};

export default DecomissionMessageBanner;
