import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { MenuItem, Select } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { z } from "zod";
import { upsertTeamLevelOrderSettings } from "../../../api/settings";
import { DD_STYLES } from "../../../constants";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { sortByString } from "../../../utils";
import useAdminEntitlements from "../../useAdminEntitlements";
import useFetch from "../../useFetch";
import ErrorModal from "../Sms/ErrorModal";
import ReadOnlyButton from "../../common/ReadOnlyButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TeamSelector from "../../common/TeamSelector";

const Z_DATA = z.object({
	teamLevel: z.array(
		z.object({
			profileID: z.number(),
			profileName: z.string(),
			isOtpVerificationEnabledForOrders: z.union([z.literal(0), z.literal(1)]),
			defaultEmailRecipients: z.array(z.string()),
			showOrdersCollectionDetails: z.union([z.literal(0), z.literal(1)]),
			discountEnabledOrders: z.union([z.literal(0), z.literal(1)]),
			showOrdersRecipients: z.union([z.literal(0), z.literal(1)]),
		})
	),
});
const renderSelectValue = (value: null | string) => (
	<div>
		Assigning To: <span style={{ marginLeft: "8px", fontWeight: "bold" }}>{value}</span>
	</div>
);
let once = true;
type Props = {
	roleID: string;
}
const TeamLevelOrderSettings: FC<Props> = ({ roleID }) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const { data, loading, refetch } = useFetch<z.infer<typeof Z_DATA>>("/preferences/orders");
	const [selectedProfileId, setSelectedProfileId] = useState<null | number>(null);
	const allSettings = data?.data?.teamLevel || [];
	const selectedSetting = allSettings?.find((setting) => setting.profileID === selectedProfileId) || null;
	const userWritePermission = useAdminEntitlements("settings:orders", "write");
	const [open, setOpen] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (!loading && data?.data && once) {
			const errors = Z_DATA.safeParse(data.data);
			if (!errors.success) console.error(errors);
			const defaultProfileId =
				data.data.teamLevel.find((setting) => setting.profileName === ("default" || "Default"))?.profileID ||
				data.data.teamLevel[0].profileID;
			setSelectedProfileId(defaultProfileId);
			once = false;
		}
	}, [loading, data?.data]);

	const { data: smsBalance } = useFetch<any>("/messageBalance");

	const handleChange = async (value: number | string, key: keyof z.infer<typeof Z_DATA>["teamLevel"][number], selectTeams?:number[]) => {
		if (!selectedProfileId || !userWritePermission || !key || !selectedSetting) return;
		const newValue = typeof value === "number" ? value : +value;
		const updatedSetting = { ...selectedSetting, [key]: newValue };

		if (updatedSetting.isOtpVerificationEnabledForOrders === 1) {
			// Backend will send null when there is no row for companyID in the smsCredit Table
			if (smsBalance?.smsBalance === null) {
				// If this is a scenario then restrict user from enabling the OTP service
				setOpen(true);
				return;
			}
		}

		const { success, message } = await upsertTeamLevelOrderSettings(
			updatedSetting.defaultEmailRecipients,
			updatedSetting.isOtpVerificationEnabledForOrders,
			updatedSetting.showOrdersCollectionDetails,
			updatedSetting.discountEnabledOrders,
			updatedSetting.showOrdersRecipients,
			selectedProfileId,
			selectTeams
		);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (success) refetch();
	};
	return (
		<div className="team-level-task-settings">
			{open && <ErrorModal handleClose={handleClose} />}
			{expanded && <TeamSelector expanded={expanded} setExpanded={setExpanded} handleChange={handleChange} />}
			<div className="team-header">
				<div className="team-level-header" >
					<PeopleAltOutlinedIcon />
					<p className="title">Team Level Settings</p>
					{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="settings" /> : null}
				</div>
				<button
					className="add mui-button"
					onClick={() => setExpanded(!expanded)}
					disabled={
						(!userWritePermission)
					}>
					<ContentCopyIcon sx={{ marginRight: "5px" }}/> 
					Copy Team Settings
				</button>
			</div>
			<div className="horizontal-line"></div>
			<Select
				onChange={(e) => setSelectedProfileId(+e.target.value!)}
				value={selectedProfileId}
				sx={{
					...DD_STYLES,
					maxWidth: "320px",
					marginBottom: "2rem",
					color: "#0F45FF",
				}}
				renderValue={() => renderSelectValue(selectedSetting?.profileName || null)}>
				{allSettings
					?.sort((a, b) => sortByString(a.profileName, b.profileName))
					.map((profile) => (
						<MenuItem key={profile.profileID} value={profile.profileID}>
							{profile.profileName}
						</MenuItem>
					))}
			</Select>
			<div className="team-level-grid">
				<div className="section">
					<p className="title">OTP Enable/Disable</p>
					<p className="subtitle">Enable / Disable OTP verification while placing the orders.</p>
					<Select
						disabled={!userWritePermission}
						sx={DD_STYLES}
						value={selectedSetting?.isOtpVerificationEnabledForOrders ?? 0}
						onChange={(e) => handleChange(+e.target.value, "isOtpVerificationEnabledForOrders")}>
						<MenuItem value={1}>Enable</MenuItem>
						<MenuItem value={0}>Disable</MenuItem>
					</Select>
				</div>
				<div className="section">
					<p className="title">Collection Module</p>
					<p className="subtitle">Allow employees to record collection details while placing an order.</p>
					<Select
						disabled={!userWritePermission}
						sx={DD_STYLES}
						value={selectedSetting?.showOrdersCollectionDetails ?? 0}
						onChange={(e) => handleChange(+e.target.value, "showOrdersCollectionDetails")}>
						<MenuItem value={1}>Enable</MenuItem>
						<MenuItem value={0}>Disable</MenuItem>
					</Select>
				</div>
				<div className="section">
					<p className="title">Discount Allowed</p>
					<p className="subtitle">Allow employees to sell at a discount while placing orders.</p>
					<Select
						disabled={!userWritePermission}
						sx={DD_STYLES}
						value={selectedSetting?.discountEnabledOrders ?? 0}
						onChange={(e) => handleChange(+e.target.value, "discountEnabledOrders")}>
						<MenuItem value={1}>Enable</MenuItem>
						<MenuItem value={0}>Disable</MenuItem>
					</Select>
				</div>
				<div className="section">
					<p className="title">Custom recepients</p>
					<p className="subtitle">Allow employees to send invoices to customer emails </p>
					<Select
						disabled={!userWritePermission}
						sx={DD_STYLES}
						value={selectedSetting?.showOrdersRecipients ?? 0}
						onChange={(e) => handleChange(+e.target.value, "showOrdersRecipients")}>
						<MenuItem value={1}>Enable</MenuItem>
						<MenuItem value={0}>Disable</MenuItem>
					</Select>
				</div>
			</div>
		</div>
	);
};

export default TeamLevelOrderSettings;
