// react
import { FC, useMemo, useState } from "react";

// library
import axios from "../../../utils/axios";
import { useQuery } from "react-query";
import moment from "moment";
import Link from "next/link";
import "moment-timezone";

// mui
import { Avatar } from "@mui/material";
import RoomIcon from "@mui/icons-material/Room";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

// redux
import { ROOT_STATE } from "../../../redux";
import { useSelector } from "react-redux";

// query
import { DASHBOARD } from "../../../@types";
import { DATE_TYPE_FORMAT } from "../../../constants";

// utils
import { findAvatarInitials, WORKING_STATE } from "../../../utils";
import { showBatteryPct } from "../TimeLine/TimelineUtils";

// components
import Forms from "./Forms";
import Orders from "./Orders";
import Tasks from "./Tasks";

// const showWifiEnabled = (wifiEnabled: number | undefined | null) => {
// 	if (wifiEnabled === null || wifiEnabled === undefined) {
// 		return (
// 			<button title="Wifi Not Available">
// 				<WifiOffOutlinedIcon htmlColor="#f54747" />
// 			</button>
// 		);
// 	}
// 	if (wifiEnabled === 0) {
// 		return (
// 			<button title="Wifi Disabled">
// 				<SignalWifi0BarOutlinedIcon htmlColor="#ef9943" />
// 			</button>
// 		);
// 	}
// 	return (
// 		<button title="Wifi Enabled">
// 			<SignalWifi4BarOutlinedIcon htmlColor="#40a636" />
// 		</button>
// 	);
// };
const showGpsEnabled = (gpsEnabled: number | undefined | null) => {
	// if (gpsEnabled === null || gpsEnabled === undefined) {
	// 	return (
	// 		<button title="Gps Not Available">
	// 			<RoomIcon htmlColor="#adb5bd" />
	// 		</button>
	// 	);
	// }
	if (gpsEnabled === 0) {
		return (
			<button title="Gps Disabled">
				<LocationOffIcon htmlColor="#f54747" />
			</button>
		);
	}
	if (gpsEnabled === 1) {
		return (
			<button title="Gps Enabled">
				<RoomIcon htmlColor="#40a636" />
			</button>
		);
	}
};

type FETCH_STATUS = {
	status: "success";
	locality: string;
};
const fetchReverseGeoCode = async (lat: number, lng: number): Promise<FETCH_STATUS> => {
	const res = await axios.post(
		`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/reverseGeocode`,
		JSON.stringify({ lat, lng }),
		{
			headers: {
				"Content-Type": "application/json",
			},
		}
	);

	return res.data;
};

// let isCancelled = false;
// eslint-disable-next-line no-unused-vars
type Props = { user: DASHBOARD; lastElementRef: ((node: HTMLElement | null) => void) | null };
const DashboardCard: FC<Props> = ({ user, lastElementRef }) => {
	const {
		susbscriptionMetadata: { data: subsMetadata },
	} = useSelector((state: ROOT_STATE) => ({
		susbscriptionMetadata: state.susbscriptionMetadata,
	}));
	const status = useMemo(
		() => WORKING_STATE[typeof user?.workingStatusV2 === "string" ? user?.workingStatusV2 : "Punched Out"],
		[user?.workingStatusV2]
	);

	const [selected, setSelected] = useState<1 | 2 | 3>(1);
	const lat = user?.lastLocation?.[0]?.lat!;
	const lon = user?.lastLocation?.[0]?.lon!;
	const { data, isLoading } = useQuery([lat, lon], () => fetchReverseGeoCode(lat!, lon!), {
		staleTime: 200000,
		cacheTime: 200000,
		enabled: typeof lat === "number" && typeof lon === "number",
	});

	const adminInfo = useSelector((state: ROOT_STATE) => state.user);

	return (
		<div style={{ position: "relative", height: "220px" }}>
			<article className={`dashboard_card ${status}`} ref={lastElementRef}>
				<div className="heading">
					<div className="info">
						{user.imgUrl ? (
							<img src={user.imgUrl} alt="" />
						) : (
							<Avatar>{findAvatarInitials(user.firstName + " " + user.lastName)}</Avatar>
						)}
						<div className="details">
							<h5 style={{ display: "flex", alignItems: "center" }}>{user.firstName + " " + user.lastName}</h5>
							{user.internalEmpID && <span style={{ color: "GrayText", marginTop: "0" }}> ({user.internalEmpID}) </span>}
							<span style={{ marginTop: (user.internalEmpID ? "0px" : "5px") }}>{user.mobileNumber}</span>
						</div>
					</div>
					<div className="icons">
						{/* {showWifiEnabled(user.lastAppState ? user.lastAppState[0].wifiEnabled : undefined)} */}
						{showBatteryPct(user.lastAppState ? user.lastAppState[0].batteryPct * 100 : undefined)}
						{showGpsEnabled(user.lastAppState ? user.lastAppState[0].gpsEnabled : undefined)}
					</div>
				</div>
				<div className="table">
					<div className="table_head">
						<h6>
							Punched {user.lastAttendance?.[0].eventTypeID === 9 ? "OUT" : "IN"} :{" "}
							{user.lastAttendance
								? moment(user.lastAttendance[0].timestamp)
									.tz(adminInfo?.tz || "Asia/Kolkata")
									.calendar()
								: "Never Punched In"}
						</h6>
						<Link
							prefetch={false}
							href={`/dashboard/timeline?employeeID=${user.employeeID}&selectedDate=${moment().format(
								DATE_TYPE_FORMAT
							)}`}
							title="Go To Timeline">
							Timeline<AssignmentOutlinedIcon />

						</Link>
					</div>
					<div className="table_content">
						<div className="row">
							<span>
								<LocationOnOutlinedIcon />
								Location
							</span>
							<span>
								{user.lastLocation && user.lastLocation[0]?.lon && !isLoading
									? data?.locality
									: "Address Not Available"}
							</span>
						</div>
					</div>
				</div>
				{(subsMetadata?.tasks === 1 || subsMetadata?.orders === 1 || subsMetadata?.forms === 1) && (
					<div className="hover_action">
						<ul className="tabs">
							<button className={`${selected === 1 ? "selected" : ""}`} onClick={() => setSelected(1)}>
								Tasks
							</button>
							<button className={`${selected === 2 ? "selected" : ""}`} onClick={() => setSelected(2)}>
								Orders
							</button>
							<button className={`${selected === 3 ? "selected" : ""}`} onClick={() => setSelected(3)}>
								Forms
							</button>
						</ul>
						{selected === 1 && subsMetadata?.tasks === 1 && <Tasks tasks={user.tasks} />}
						{selected === 2 && subsMetadata?.orders === 1 && <Orders orders={user.orders} />}
						{selected === 3 && subsMetadata?.forms === 1 && <Forms forms={user.completedForms} />}
					</div>
				)}
			</article>
		</div>
	);
};

export default DashboardCard;
