import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useState } from "react";
import { FC } from "react";
import { CircularProgress, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { showNotification } from "../../redux";
import axios from "axios";
type Props = {
	setExpanded: any;
};

const PasswordChangeSidebar: FC<Props> = ({ setExpanded }) => {
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const user = useSelector((state: any) => state.user);
	const dispatch = useDispatch();

	const handleSubmit = async (event: any) => {
		event.preventDefault();
		setIsLoading(true);
		// Validate input fields
		if (newPassword !== confirmNewPassword) {
			dispatch(
				showNotification({
					message: `New password and confirm password do not match`,
					severity: "error",
				})
			);
			setIsLoading(false);
			return;
		}
		// validate the current password and new password are not same
		if (newPassword === currentPassword) {
			dispatch(
				showNotification({
					message: `Current password and new password cannot be same`,
					severity: "error",
				})
			);
			setIsLoading(false);
			return;
		}

		// Send request to server to update password
		try {
			const response = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/login/changePassword`, {
				email: user.adminDetails.email,
				oldPassword: currentPassword,
				newPassword,
			});
			if (response?.data?.status === "success") {
				dispatch(
					showNotification({
						message: `Password changed successfully`,
						severity: "success",
					})
				);
			}
			setCurrentPassword("");
			setNewPassword("");
			setConfirmNewPassword("");
		} catch (error: any) {
			dispatch(
				showNotification({
					message: error.response.data.error || "Something went wrong",
					severity: "error",
				})
			);
		}
		setIsLoading(false);
	};
	return (
		<div className="user_add custom_fields">
			<div className="user_add-title">
				<h1>Change Password</h1>
				<button onClick={() => setExpanded(false)}>
					<CloseOutlinedIcon />
				</button>
			</div>
			<form className="user_add-form" onSubmit={handleSubmit}>
				<TextField
					required
					name="Current Password"
					label="Enter Current Password"
					value={currentPassword}
					onChange={(e) => setCurrentPassword(e.target.value)}
				/>
				<TextField
					required
					name="New Password"
					label="Enter New Password"
					value={newPassword}
					type="password"
					onChange={(e) => setNewPassword(e.target.value)}
				/>
				<TextField
					required
					name="Confirm New Password"
					label="Confirm New Password"
					value={confirmNewPassword}
					type="password"
					onChange={(e) => setConfirmNewPassword(e.target.value)}
				/>

				<div className="action_button">
					<button type="reset" onClick={() => setExpanded(false)}>
						Cancel
					</button>
					<button type="submit" disabled={isLoading}>
						{isLoading ? <CircularProgress style={{ width: "15px", height: "15px" }} /> : "Save"}
					</button>
				</div>
			</form>
		</div>
	);
};

export default PasswordChangeSidebar;
