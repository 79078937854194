import { DataGridPro, GridColDef, GridValueGetterParams } from "@mui/x-data-grid-pro";
import moment from "moment";
import { FC, useEffect, useMemo, useState } from "react";
import { LEAVE_TYPE } from "../../../@types";
import { DATE_SHOW_FORMAT, DATE_TIME_SHOW_FORMAT, DG_STYLES, PRODUCT_ID, UNOLO_BIOMETRIC, DATA_GRID_CUSTOMIZATION_OPTIONS, DATA_GRID_NAMES } from "../../../constants";
import ColumnEmployee from "../../common/datagrid/ColumnEmployee";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { useSelector } from "react-redux";
import { formatTheDataFromSlice, getTheSavedColumns, modifyColumOrder, modifyColumnWidth } from "../../../utils";
import { APP_DISPATCH } from "../../../redux";
import { useDispatch } from "react-redux";
import { setColumnConfigurations, setColumnSortModel, setPinnedColumns } from "../../../redux/slices/dataGridSlice";

type Props = {
	attendances: LEAVE_TYPE[];
	loading: boolean;
	datagridHeight:any;
};

const AttendanceTable: FC<Props> = ({ attendances, loading, datagridHeight }) => {
	const [columns, setColumns] = useState<GridColDef[]>([]);
	const [pageSize, setPageSize] = useState(20);
	const [showModal, setShowModal] = useState<string | null>();
	const dispatch = useDispatch<APP_DISPATCH>();
	
	const productID = window ? localStorage.getItem(PRODUCT_ID) ?? "2" : "2";

	const handleClose = () => {
		setShowModal(null);
	}
	const getColum = useSelector((state: any) => state.dataGrid);
	const savedColumnConf = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.punch_event_record,
		DATA_GRID_CUSTOMIZATION_OPTIONS.columns_array
	);
	const savedColumnSortModel = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.punch_event_record,
		DATA_GRID_CUSTOMIZATION_OPTIONS.sort_model
	);
	const savedPinnedColumns = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.punch_event_record,
		DATA_GRID_CUSTOMIZATION_OPTIONS.pinned_column
	);
	const formattedcolumn = formatTheDataFromSlice(savedColumnConf, columns) || [];

	const tempColumns = useMemo(() => {
		const COLUMNS = [
			{
				field: "employee",
				headerName: "Employee Name",
				renderCell: (params: GridValueGetterParams) => (
					<ColumnEmployee name={params.row.userName} avatar={params.row.userInfo.imgUrl} />
				),
				flex: 1,
				valueGetter: (params: GridValueGetterParams) => params.row.userName,
				minWidth: 200,
			},
			{
				field: "eventTypeID",
				headerName: "Event Type",
				minWidth: 150,
				flex: 1,
				renderCell: (params: GridValueGetterParams) => {
					const state =
						params.row.eventTypeID === 8
							? "PunchedIn"
							: params.row.eventTypeID === 9
							? "PunchedOut" : null;
					return <span className={`role_badge ${state}`}>{params.row?.eventTypeID === 8 ? "Punched In" : "Punched Out"}</span>;
				}
			},
			{
				field: "address",
				headerName: "Address",
				flex: 1,
				minWidth: 150,
			},
			{
				field: "siteName",
				headerName: "Attendance Site Name",
				flex: 1,
				minWidth: 100,
			},
			{
				field: "timestamp",
				headerName: "Event Occurred At",
				flex: 1,
				minWidth: 200,
				renderCell: (params: GridValueGetterParams) => moment(params.row.timestamp).format(DATE_TIME_SHOW_FORMAT)
			},
			{
				field: "processingDate",
				headerName: "Date",
				flex: 1,
				minWidth: 150,
				renderCell: (params: GridValueGetterParams) => moment(params.row.processingDate).format(DATE_SHOW_FORMAT)
			},
			{
				field: "photoURL",
				headerName: "Photo",
				flex: 1,
				minWidth: 100,
				renderCell: (params: GridValueGetterParams) => (
					params.row.photoURL 
					? params.row.isPhotoSynced 
						? 
						<button onClick={()=> {setShowModal("https://s3.ap-south-1.amazonaws.com/upload.sensestaff.com/" + params.row.photoURL)}}>
							<img width={100} draggable="false" src={"https://s3.ap-south-1.amazonaws.com/upload.sensestaff.com/" + params.row.photoURL} alt="Attendance Profile"/> 
						</button> 
						: "Not Synced"
					:  null 
				),
			}
		]
		
		const BIOMETRIC_COLUMNS = [
			{
				field: "employeeName",
				headerName: "Employee Name",
				renderCell: (params: GridValueGetterParams) => params.row.userName,
				valueGetter: (params: GridValueGetterParams) => params.row.userName,
				flex: 1,
				minWidth: 200,
			},
			{
				field: "employeePhoto",
				headerName: "Employee Photo",
				renderCell: (params: GridValueGetterParams) => (
					<button onClick={()=> {setShowModal(params.row.userInfo.imgUrl)}}>
						<img width={100} draggable="false" src={params.row.userInfo.imgUrl} alt="Attendance Profile"/> 
					</button> 
				),
				width: 180,
			},
			{
				field: "photoURL",
				headerName: "Attendance Photo",
				width: 180,
				renderCell: (params: GridValueGetterParams) => (
					<button onClick={()=> {setShowModal("https://s3.ap-south-1.amazonaws.com/biometric.unolo.com/" + params.row.photoURL)}}>
						<img width={100} draggable="false"  src={"https://s3.ap-south-1.amazonaws.com/biometric.unolo.com/" + params.row.photoURL} alt="Attendance Profile"/> 
					</button> 
				),
			},
			{
				field: "eventTypeID",
				headerName: "Event Type",
				minWidth: 150,
				flex: 1,
				renderCell: (params: GridValueGetterParams) => {
					const state =
						params.row.eventTypeID === 8
							? "PunchedIn"
							: params.row.eventTypeID === 9
							? "PunchedOut" : null;
					return <span className={`role_badge ${state}`}>{params.row?.eventTypeID === 8 ? "Punched In" : "Punched Out"}</span>;
				}
			},
			{
				field: "siteName",
				headerName: "Attendance Site Name",
				flex: 1,
				minWidth: 100,
				renderCell: (params: GridValueGetterParams) => params.row.siteName
			},
			{
				field: "timestamp",
				headerName: "Event Occurred At",
				flex: 1,
				minWidth: 150,
				renderCell: (params: GridValueGetterParams) => moment(params.row.timestamp).format(DATE_TIME_SHOW_FORMAT)
			},
			{
				field: "processingDate",
				headerName: "Date",
				minWidth: 100,
				renderCell: (params: GridValueGetterParams) => moment(params.row.processingDate).format(DATE_SHOW_FORMAT)
			}
		]
		return productID == UNOLO_BIOMETRIC ? BIOMETRIC_COLUMNS : COLUMNS
	}, [productID]);

	useEffect(() => {
		setColumns(tempColumns);
	}, [tempColumns]);

	return (<>
	{
		showModal ? 
		<ImageShowModal
			imgURL={showModal}
			handleClose={handleClose}
		/>
		: null 
	}
		<div className="datagrid-table">
			<DataGridPro
				sx={DG_STYLES}
				rows={attendances}
				getRowId={(row) => row.id}
				sortModel={savedColumnSortModel?.length > 0 ? savedColumnSortModel : []}
				pinnedColumns={savedPinnedColumns && Object.keys(savedPinnedColumns).length > 0 ? savedPinnedColumns : {}}
				columns={columns}
				loading={loading}
				pageSize={pageSize}
				getRowHeight={() => datagridHeight}  // Increase the height of the datagrid if there is photo which will be only in Biometric
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[5, 10, 20, 50]}
				disableColumnFilter
				pagination
				disableSelectionOnClick
				onColumnOrderChange={(params) => {
					const modifiedColumn = modifyColumOrder(
						DATA_GRID_NAMES.punch_event_record,
						params,
						formattedcolumn?.length > 0 ? formattedcolumn : columns
					);
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.punch_event_record,
						columnArray: modifiedColumn,
					};
					dispatch(setColumnConfigurations(newColumnConfigurations));
				}}
				onSortModelChange={(params) => {
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.punch_event_record,
						sortModel: params,
					};
					dispatch(setColumnSortModel(newColumnConfigurations));
				}}
				onColumnWidthChange={(params) => {
					const modifiedColumn = modifyColumnWidth(params, formattedcolumn?.length > 0 ? formattedcolumn : columns);
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.punch_event_record,
						columnArray: modifiedColumn,
					};
					dispatch(setColumnConfigurations(newColumnConfigurations));
				}}
				onPinnedColumnsChange={(params) => {
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.punch_event_record,
						pinnedColumns: params,
					};
					dispatch(setPinnedColumns(newColumnConfigurations));
				}}
			/>
		</div>
	</>
	);
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialog-paper": {
		maxWidth: "600px"
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

type SubComponentProps = {
	imgURL: string;
	handleClose:any
};

const ImageShowModal: FC<SubComponentProps> = ({ imgURL, handleClose }) => (
		<BootstrapDialog onClose={handleClose} open={true}>
			<div
				style={{
					display: "flex !important",
					paddingRight: "5px",
					paddingTop : "5px",
					width: "100%",
				}}>
				<button onClick={handleClose} 
					style={{
						float:"right"
					}}>
					<CloseSharpIcon htmlColor="#42444A" />
				</button>
			</div>
		
			<div
				style={{
					display: "flex !important",
					padding: "5px",
					alignItems: "center",
					width: "100%",
					justifyContent: "center",
				}}>
				<img width={400} src={imgURL} alt="URL P" />
			</div>
		</BootstrapDialog>
	);

export default AttendanceTable;
