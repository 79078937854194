import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import Link from "next/link";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CircularProgress, FormControlLabel, Switch, TextField } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { GET_INTEGRATIONS, UPSERT_INTEGRATION, UPSERT_INTEGRATION_STATE } from "../../../schema/hrOne";
import useAdminInfo from "../../useAdminInfo";
import { HRONE_INTEGRATION } from "../../../@types";
import useAdminEntitlements from "../../useAdminEntitlements";
import { useSelector } from "react-redux";
import ReadOnlyButton from "../../common/ReadOnlyButton";

const HroneIntegration = () => {
	const {
		data: get_integrations,
		loading,
		refetch,
	} = useQuery(GET_INTEGRATIONS, {
		variables: {
			entityName: "attendance",
		},
	});
	const userWritePermission = useAdminEntitlements("settings:integrations", "write");
	const { roleID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);
	const { companyID } = useAdminInfo();
	const disabled = companyID === 255 || !userWritePermission;
	const dispatch = useDispatch<APP_DISPATCH>();
	const [hrOneIntegrationData, setHrOneIntegrationData] = useState<HRONE_INTEGRATION>({
		integrationID: 9,
		integrationName: "hrOneAttendanceSwipe",
		entityName: "attendance",
		status: 0,
		keyValues: [
			{
				keyName: "API_KEY",
				keyTypeID: 4,
				keyValue: "",
			},
			{
				keyName: "API_KEY_HEADER",
				keyTypeID: 5,
				keyValue: "",
			},
			{
				keyName: "SUBSCRIPTION_KEY",
				keyTypeID: 11,
				keyValue: "",
			},
			{
				keyName: "SUBSCRIPTION_KEY_HEADER",
				keyTypeID: 12,
				keyValue: "",
			},
		],
	});
	const [upsertIntegrationState] = useMutation(UPSERT_INTEGRATION_STATE);
	const [upsertIntegrations] = useMutation(UPSERT_INTEGRATION);

	const enableZohoSwitch = async (prevStatus: 0 | 1) => {
		const newState = prevStatus === 0 ? 1 : 0;
		try {
			const res = await upsertIntegrationState({
				variables: {
					input: { integrationID: hrOneIntegrationData.integrationID, status: newState, entityName: "attendance" },
				},
			});
			if (res) {
				dispatch(showNotification({ message: res.data.upsert_integration_state.message, severity: "success" }));
				refetch();
			}
		} catch (error) {
			dispatch(showNotification({ message: "Something went wrong", severity: "error" }));
			return;
		}
		const clone = { ...hrOneIntegrationData };
		clone.status = newState;
		setHrOneIntegrationData(clone);
	};

	const handleChange = (e: any) => {
		const clone = { ...hrOneIntegrationData };
		const { name, value } = e.target;
		clone.keyValues.map((key) => {
			if (key.keyName === name) {
				key.keyValue = value;
			}
		});
		setHrOneIntegrationData(clone);
	};

	const submit = async (e: any) => {
		e.preventDefault();
		try {
			const res = await upsertIntegrations({
				variables: {
					input: hrOneIntegrationData,
				},
			});
			if (res) {
				dispatch(showNotification({ message: res.data.upsert_integrations.message, severity: "success" }));
			}
		} catch (error) {
			dispatch(showNotification({ message: "Something went wrong", severity: "error" }));
			return;
		}
	};

	useEffect(() => {
		if (!loading && get_integrations) {
			const hrOneInt = get_integrations?.get_integrations.filter((data: any) => data.integrationID === 9);
			if (hrOneInt.length > 0) {
				const clone = { ...hrOneIntegrationData };
				clone.keyValues.map((key) => {
					const isFound: any = hrOneInt[0].keyValues.find((k: any) => k.keyTypeID === key.keyTypeID);
					if (isFound) {
						key.keyValue = isFound.keyValue;
					}
				});
				clone.status = hrOneInt[0].status;
				setHrOneIntegrationData(clone);
			}
		}
	}, [loading, get_integrations]);

	const isloading = loading;

	return (
        <div className="zoho-crm">
			<div className="back-title">
				<Link prefetch={false} passHref href="/settings/integrations" legacyBehavior>
					<ArrowBackIcon />
				</Link>
				<p>HROne Integrations</p>
				{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="integrations" /> : null}
			</div>
			<div className="divider"></div>

			{isloading ? (
				<div className="centerise_the_content" style={{ width: "100%" }}>
					<CircularProgress />
				</div>
			) : (
				<>
					{hrOneIntegrationData.status === 0 && userWritePermission ? (
						<ul className="errors">
							<li>This Integration is disabled</li>{" "}
						</ul>
					) : null}
					<div className="integration_state">
						<FormControlLabel
							control={<Switch checked={hrOneIntegrationData.status === 1} />}
							label={hrOneIntegrationData.status === 1 ? "Enabled" : "Disabled"}
							onChange={() => enableZohoSwitch(hrOneIntegrationData.status as 0 | 1)}
							disabled={disabled}
							sx={{ cursor: !userWritePermission ? "not-allowed !important" : "pointer", pointerEvents: "auto" }}
						/>
					</div>
					<form onSubmit={submit}>
						<div className="crm-form">
							<TextField
								disabled={hrOneIntegrationData.status === 0 || disabled}
								type="API_KEY"
								required
								label="API KEY"
								name="API_KEY"
								onChange={handleChange}
								value={hrOneIntegrationData.keyValues[0].keyValue}
							/>
							<TextField
								required
								name="API_KEY_HEADER"
								disabled={hrOneIntegrationData.status === 0 || disabled}
								label="API KEY HEADER"
								onChange={handleChange}
								value={hrOneIntegrationData.keyValues[1].keyValue}
							/>
							<TextField
								required
								name="SUBSCRIPTION_KEY"
								disabled={hrOneIntegrationData.status === 0 || disabled}
								label="SUBSCRIPTION KEY"
								onChange={handleChange}
								value={hrOneIntegrationData.keyValues[2].keyValue}
							/>
							<TextField
								required
								name="SUBSCRIPTION_KEY_HEADER"
								disabled={hrOneIntegrationData.status === 0 || disabled}
								label="SUBSCRIPTION KEY HEADER"
								onChange={handleChange}
								value={hrOneIntegrationData.keyValues[3].keyValue}
							/>
						</div>
						<div className="integeration_message" style={{ marginTop: "20px" }}>
							<p style={{ fontWeight: 600 }}>
								<i>Note- For successful integration, please make sure to follow the below steps.</i>
							</p>
							<ol type="1">
								<li>
									1. Make sure you set the Employee ID in the page{" "}
									<a
										style={{
											fontSize: "12px",
											fontWeight: "800",
											textDecoration: "underline",
											textUnderlineOffset: "3px",
											color: "#0d6efd",
											cursor: "pointer",
										}}
										href={"https://panel.unolo.com/organization/field_executive"} target="blank">
										https://panel.unolo.com/organization/field_executive
									</a>
									.{" "}
								</li>
								<li>2. Make sure the same Employee ID is mapped to the employee in HrOne. </li>
							</ol>
						</div>
						<button
							type="submit"
							style={{ marginTop: "20px", float: "right" }}
							disabled={hrOneIntegrationData.status === 0 || disabled}>
							Save
						</button>
					</form>
				</>
			)}
		</div>
    );
};

export default HroneIntegration;
