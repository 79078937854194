import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { Menu, MenuItem, TextField } from "@mui/material";
import _cloneDeep from "lodash/cloneDeep";
import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import "moment-timezone";
import { isUuid } from "uuidv4";
import { FORM_TEMPLATE_TYPE } from "../../../@types";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import { updateFormSection, updateFormTemplate } from "./FormUtils";
type Props = {
	id: string; //uuid of current section
	sectionTitle: string;
	sectionDescription: string;
	length: number; //length of all the sections
	index: number; //index of current section in the form
	currentElementId: string | null;
	setFormTemplate: Dispatch<SetStateAction<FORM_TEMPLATE_TYPE>>;
	formTemplate: FORM_TEMPLATE_TYPE;
	userWritePermission:boolean;
};
const FormSectionHeader: FC<Props> = ({
	id,
	sectionTitle,
	sectionDescription,
	length,
	index,
	currentElementId,
	setFormTemplate,
	formTemplate,
	userWritePermission
}) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const user = useSelector((state: ROOT_STATE) => state.user);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleChangeTitle = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const { value, name } = e.target;
		const { tempFormTemplate } = updateFormSection(formTemplate, 0);
		tempFormTemplate.lastModifiedByAdminID = user.adminDetails.adminID!;
		tempFormTemplate.formSections[0].lastModifiedByAdminID = user.adminDetails.adminID!;
		// @ts-ignore
		tempFormTemplate.formSections[index][name] = value;
		// if (name === "sectionTitle" && index === 0) tempFormTemplate.formTitle = value;
		// if (name === "sectionDescription") tempFormTemplate.formDescription = value;
		setFormTemplate(tempFormTemplate);
	};

	const duplicateSection = () => {
		const { tempFormTemplate, time } = updateFormTemplate(formTemplate);
		tempFormTemplate.lastModifiedByAdminID = user.adminDetails.adminID!;
		let tempFormSections = _cloneDeep(tempFormTemplate["formSections"]);
		const newSection = _cloneDeep(tempFormSections[index]);

		newSection.formSectionID = uuidv4();
		newSection.createdByAdminID = user.adminDetails.adminID!;
		newSection.createdTs = time;
		newSection.lastModifiedByAdminID = user.adminDetails.adminID!;
		newSection.lastModifiedTs = time;
		newSection.nextSection = "TERMINATE";
		let tempQuestionsFormSection = _cloneDeep(newSection?.formQuestions) ?? [];
		tempQuestionsFormSection = tempQuestionsFormSection.map((question) => {
			let tempQuestionsFormOption = _cloneDeep(question["options"]);
			tempQuestionsFormOption = tempQuestionsFormOption.map((option) => ({
				...option,
				createdByAdminID: user.adminDetails.adminID!,
				createdTs: time,
				lastModifiedByAdminID: user.adminDetails.adminID!,
				lastModifiedTs: time,
			}));

			return {
				...question,
				questionID: uuidv4(),
				options: tempQuestionsFormOption,
				createdByAdminID: user.adminDetails.adminID!,
				createdTs: time,
				lastModifiedByAdminID: user.adminDetails.adminID!,
				lastModifiedTs: time,
			};
		});
		newSection.formQuestions = tempQuestionsFormSection;
		tempFormSections[index].nextSection = newSection.formSectionID;
		tempFormSections.splice(index + 1, 0, newSection);

		tempFormSections = tempFormSections.map((section, idx) => ({ ...section, seqNumber: idx }));
		tempFormTemplate.formSections = tempFormSections;
		setFormTemplate(tempFormTemplate);
	};

	const deleteSection = () => {
		if (length <= 1) {
			dispatch(showNotification({ message: "Only section cannot be deleted", severity: "error" }));
			return;
		}
		if (index === 0) {
			dispatch(showNotification({ message: "First section cannot be deleted", severity: "error" }));
			return;
		}
		const { tempFormTemplate } = updateFormTemplate(formTemplate);
		let tempFormSections = [...tempFormTemplate.formSections];
		const sectionLinkedToCurrentSection = tempFormSections.findIndex((section) => section.nextSection === id);
		if (sectionLinkedToCurrentSection !== -1) {
			tempFormSections.forEach((section, idx) => {
				if (section.nextSection === tempFormSections[index].formSectionID) {
					tempFormSections[idx].nextSection = tempFormSections[index].nextSection;
				}
			});
		}
		tempFormSections.splice(index, 1);
		tempFormSections = _cloneDeep(tempFormSections).map((section, idx) => {
			const tempFormQuestion = section.formQuestions.map((question) => {
				const tempFormOptions = question["options"].map((option) => {
					if (option.nextSection !== null && isUuid(option.nextSection) && option.nextSection === id) {
						return { ...option, nextSection: tempFormSections[0].formSectionID };
					} else {
						return option;
					}
				});
				return { ...question, options: tempFormOptions };
			});
			return { ...section, formQuestions: tempFormQuestion, seqNumber: idx };
		});
		tempFormTemplate.formSections = [...tempFormSections];
		setFormTemplate({ ...tempFormTemplate });
	};
	return (
		<>
			<div className="form_builder-section--header">
				Section {index + 1} of {length}
			</div>
			<div className="title">
				{currentElementId === id ? (
					<TextField
						fullWidth
						variant="standard"
						placeholder="Enter Title"
						name="sectionTitle"
						required
						value={sectionTitle}
						onChange={handleChangeTitle}
						inputProps={{ maxlength: 200 }}
						helperText={`${sectionTitle ? sectionTitle?.length : 0}/${200}`}
						FormHelperTextProps={{ style: { margin: "4px 0px 0px 0px", fontSize: "14px" } }}
						disabled={!userWritePermission}
					/>
				) : (
					<h4>{sectionTitle || <span className="error">Untitled Section</span>}</h4>
				)}

				<button
					type="button"
					aria-controls={open ? "basic-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					onClick={(e) => setAnchorEl(e.currentTarget)}>
					<MoreVertOutlinedIcon color="action" />
				</button>
				<Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
					<MenuItem onClick={() => setAnchorEl(null)}>
						<button type="button" onClick={duplicateSection} disabled={!userWritePermission}>
							Duplicate Section
						</button>
					</MenuItem>
					<MenuItem onClick={() => setAnchorEl(null)}>
						<button type="button" onClick={deleteSection} disabled={!userWritePermission}>
							Delete Section
						</button>
					</MenuItem>
				</Menu>
			</div>
			{currentElementId === id ? (
				<TextField
					fullWidth
					variant="standard"
					placeholder="Section Description"
					value={sectionDescription}
					name="sectionDescription"
					onChange={handleChangeTitle}
					inputProps={{ maxlength: 200 }}
					helperText={`${sectionDescription ? sectionDescription?.length : 0}/${200}`}
					FormHelperTextProps={{ style: { margin: "4px 0px 0px 0px", fontSize: "14px" } }}
					disabled={!userWritePermission}
				/>
			) : (
				<p>{sectionDescription || "Untitled Description"}</p>
			)}
			<p style={{ textAlign: "right" }}>Section Sequence: {formTemplate.formSections[index].seqNumber + 1}</p>
		</>
	);
};

export default FormSectionHeader;
