import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import { Checkbox, CircularProgress, FormControl, FormHelperText, InputAdornment, InputLabel, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import _cloneDeep from "lodash/cloneDeep";
import { isEqual } from "lodash";
import "moment-timezone";
import "lodash";
import { Dispatch, FC, FormEvent, SetStateAction, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { ACCESS_CONTROL_USER, ADMINS_DATA, SAMPLE_DATA, USER_ROLES } from "../../../@types";
import { saveAdminHierarchyNew } from "../../../api/access-control";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { convertDataSourceToOrgChartDataSource, sortByString } from "../../../utils";
import { orgFormStatus } from "../../../constants/organizationConstant";

type Props = {
	setExpanded: Dispatch<SetStateAction<boolean>>;
	formData: ACCESS_CONTROL_USER;
	setFormData: Dispatch<SetStateAction<ACCESS_CONTROL_USER>>;
	users: ACCESS_CONTROL_USER[];
	allAdmins: ADMINS_DATA[];
	dataRoles: USER_ROLES[];
	adminsData: ADMINS_DATA[];
	setUsers: Dispatch<SetStateAction<ACCESS_CONTROL_USER[]>>;
	allExecutives: any[];
	refetch: Function;
	pullAdminsRefetch: Function;
	rolesRefetch: Function;
	currentAdmin: any;
	displayCurrentAdmin: any;
	checkCurrentAdminVisibility: boolean;
};
const UserSidebarForm: FC<Props> = ({
	setExpanded,
	formData,
	setFormData,
	dataRoles,
	allAdmins,
	adminsData,
	users,
	allExecutives,
	setUsers,
	refetch,
	pullAdminsRefetch,
	rolesRefetch,
	currentAdmin,
	displayCurrentAdmin,
	checkCurrentAdminVisibility
}) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const [isLoading, setIsLoading] = useState(false);
	const [displayUniqueEVAdmins, setDisplayUniqueEVAdmins] = useState<any>([])
	const [selectedEVAdminsValues, setSelectedEVAdminsValues] = useState<any>(formData?.execVisibilities);
	const [displayUniqueRMAdmins, setDisplayUniqueRMAdmins] = useState<any>([])

	const isEdit = users.findIndex((user) => user.id === formData.id) >= 0 ? true : false; // here isEdit variable checks if the adminsis new or existing one.
	const editAdminData = users.find((user) => user.id === formData.id); // this is the current
	const handleChange = (event: any) => {
		const { name, value } = event.target;
		if (name === "execVisibilities") {
			if (value[value.length - 1] === 1) {
				setSelectedEVAdminsValues([1])
				setFormData({ ...formData, [name]: [{ type: "EVERYONE", value: "EVERYONE" }] });
			} else {
				const filteredValues = value.filter((admin: number) => admin !== 1);
				setSelectedEVAdminsValues(filteredValues);
				setFormData({
					...formData, [name]: filteredValues.map((admin: number) => ({ type: "ADMIN", value: admin })
					)
				});
			}
		} else {
			setFormData({ ...formData, [name]: value });
		}
	};


	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsLoading(true);
		const checkDefaultValues = isEqual(editAdminData, formData);
		if (selectedEVAdminsValues.length > 0) {
			if (checkDefaultValues) {
				setIsLoading(false);
				dispatch(showNotification({ message: "Kindly do some changes to save the data", severity: "error" }));
			} else {
				//we are maping the executive visibilty according to backend from [123, 13 ,12 ]=> [{type: "ADMIN", value: 123}, {type: "ADMIN", value: 13}, {type: "ADMIN", value: 12}]
				const updatedUsers = _cloneDeep(users).map((user: any) => ({
					...user,
					execVisibilities: user.execVisibilities.length === 1 && user.execVisibilities[0] === 1 ? [{ type: "EVERYONE", value: "EVERYONE" }] : user.execVisibilities.map((adminID: number) => ({ type: "ADMIN", value: adminID }))
				})
				);
				const index = updatedUsers.findIndex((user) => user.id === formData.id);
				const extraParams = {
					email: formData.email,
					action: isEdit ? orgFormStatus.EDIT : orgFormStatus.ADD, // if isEdit true then the action will be "edit existing one" otherwise it will be "add new one"
					isEmailChanged: formData.email !== users[index]?.email,
				};
				// Trim and normalize the internalAdminID (if present)
				const trimmedInternalAdminId = formData.internalAdminID?.toLowerCase().trim().replace(/\s+/g, " ");

				// Check for duplicates in all cases (edit and add)
				if (trimmedInternalAdminId) {
					const isDuplicate = users.some(
						(user: ACCESS_CONTROL_USER) =>
							user.id !== formData.id &&
							user.internalAdminID?.toLowerCase().trim().replace(/\s+/g, " ") === trimmedInternalAdminId
					);

					if (isDuplicate) {
						setIsLoading(false);
						dispatch(showNotification({ message: "Internal Admin ID is already assigned to another user.", severity: "error" }));
						return; // Prevent saving
					}
				}
				if (index === -1 && isEdit) return;
				if (isEdit) {
					const isArrayofNumbers = (arr: any) => Array.isArray(arr) && arr.every(item => typeof item === "number");
					updatedUsers[index] = {
						...formData,
						internalAdminID: trimmedInternalAdminId ?? "", // Preserve original if null
						firstname: formData.firstname.trim().replace(/\s+/g, " "),
						lastname: formData.lastname.trim().replace(/\s+/g, " "),
						execVisibilities: isArrayofNumbers(formData.execVisibilities)
							? formData.execVisibilities.length === 1 && formData.execVisibilities[0] === 1
								? [{ type: "EVERYONE", value: "EVERYONE" }]
								: formData.execVisibilities.map((adminID) => ({ type: "ADMIN", value: adminID }))
							: formData.execVisibilities,
						updated: true, // Indicate user was updated
					};
					// updatedUsers[index].updated = true; // giving the extra key "updated"
				} else {
					/// in case of adding admin
					// Add new user with normalized internalAdminID and trimmed names
					updatedUsers.push({
						...formData,
						internalAdminID: trimmedInternalAdminId ?? "",
						firstname: formData.firstname.trim().replace(/\s+/g, " "),
						lastname: formData.lastname.trim().replace(/\s+/g, " "),
						new: true,
						id: 1234567890, // Temporary ID 
					});
				}
				const orgData = convertDataSourceToOrgChartDataSource(updatedUsers);
				const { success, message } = await saveAdminHierarchyNew(orgData as SAMPLE_DATA, [], allExecutives, extraParams);
				dispatch(showNotification({ message, severity: success ? "success" : "error" }));
				setIsLoading(false);
				if (!success) return;
				if (isEdit) {
					delete updatedUsers[index].updated; // removing the extra key "updated"
					setUsers(updatedUsers);
					await refetch();
					await pullAdminsRefetch();
					await rolesRefetch();
				} else {
					setUsers(updatedUsers);
					await refetch();
					await pullAdminsRefetch();
					await rolesRefetch();
				}
				setExpanded(false);
			}
		}
	};

	// doing all the computations for the admins on the basis of employee visibility
	//@ts-ignore
	const filteredEVAdmin = formData?.execVisibilities?.map((data: number) => (allAdmins?.find((admin: any) => data === admin.adminID)));
	// doing all the computations for the Reporting Manager
	const filteredRMAdmin = allAdmins.find((admin) => formData?.parentId === admin.adminID);



	useEffect(() => {
		// here we are only interested in the admins which should be shown in the dropdown list of Employee Visibility and Reporting Manager, so we are deriving only those admins below
		const uniqueEVAdminIDs = new Set([
			...(filteredEVAdmin ? filteredEVAdmin.map((exe: any) => exe?.adminID) : []),
			...(checkCurrentAdminVisibility ? [displayCurrentAdmin?.adminID] : []),
			...adminsData.map((adminData) => adminData.adminID)
		]);
		const uniqueEVAdmins = allAdmins.filter((admin) => uniqueEVAdminIDs.has(admin.adminID))
		const displayEVData = uniqueEVAdmins
			?.sort((a, b) => sortByString(a.firstname, b.firstname))
			.map((data) =>
			(
				{ firstName: data.firstname, lastName: data.lastname, adminID: data.adminID }
			)
			);
		// Include "All" option conditionally
		if (currentAdmin?.adminDetails?.parentID === null || formData?.execVisibilities[0] === 1) {
			displayEVData.unshift({ firstName: "All", lastName: "", adminID: 1 });
		}

		const uniqueRMAdminIDs = new Set([
			...(filteredRMAdmin ? [filteredRMAdmin?.adminID] : []),
			...(checkCurrentAdminVisibility ? [displayCurrentAdmin?.adminID] : []),
			...adminsData.map((adminData) => adminData.adminID)
		]);
		const uniqueRMAdmins = allAdmins.filter((admin) => uniqueRMAdminIDs.has(admin.adminID));

		const displayRMData = uniqueRMAdmins
			?.sort((a, b) => sortByString(a.firstname, b.firstname))
			.map((data) => {
				//to avoid the self reporting 
				if (formData.id !== data.adminID) {
					return (
						<MenuItem key={data.adminID} value={data.adminID}>
							{data.firstname} {data.lastname}
						</MenuItem>
					)
				}
			});
		setDisplayUniqueEVAdmins(displayEVData)
		setDisplayUniqueRMAdmins(displayRMData)
	}, [])
	return (
		<div className="user_add admins">
			<div className="user_add-title">
				<h1>{isEdit ? "Edit" : "Add"} Admin</h1>
				<button onClick={() => setExpanded(false)}>
					<CloseOutlinedIcon />
				</button>
			</div>
			<form className="user_add-form" onSubmit={handleSubmit}>
				<TextField
					required
					name="firstname"
					label="Enter First Name"
					value={formData?.firstname || ""}
					disabled={formData.parentId === null || currentAdmin.adminDetails.adminID === formData.id}
					onChange={handleChange}
				/>
				<TextField
					required
					name="lastname"
					label="Enter Last Name"
					value={formData?.lastname || ""}
					disabled={formData.parentId === null || currentAdmin.adminDetails.adminID === formData.id}
					onChange={handleChange}
				/>
				<TextField
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<MailOutlinedIcon />
							</InputAdornment>
						),
					}}
					className="full_width"
					required
					type="email"
					name="email"
					label="Enter Email"
					disabled={formData.parentId === null || currentAdmin.adminDetails.adminID === formData.id}
					value={formData?.email || ""}
					onChange={handleChange}
				/>
				<FormControl className="full_width">
					<TextField
						fullWidth
						name="internalAdminID"
						label="Internal Admin ID"
						value={formData?.internalAdminID || ""}
						disabled={formData.parentId === null || currentAdmin.adminDetails.adminID === formData.id}
						onChange={handleChange}
					/>
				</FormControl>
				<FormControl className="full_width">
					<PhoneInput
						country={"in"}
						preferredCountries={["in"]}
						enableSearch={true}
						value={formData.phoneNumber}
						disabled={formData.parentId === null || currentAdmin.adminDetails.adminID === formData.id}
						onChange={(e) => {
							handleChange({ target: { name: "phoneNumber", value: `+${e}` } });
						}}
						masks={{ ci: ".. .. .. .. .." }}
					/>
				</FormControl>
				<FormControl className="full_width">
					<InputLabel id="select_role">Select/Create Role</InputLabel>
					<Select
						name="roleID"
						disabled={formData.parentId === null || currentAdmin.adminDetails.adminID === formData.id}
						required
						labelId="select_role"
						label="Select/Create Role"
						value={formData?.roleID ?? ""}
						onChange={handleChange}>
						{dataRoles
							?.sort((a, b) => sortByString(a.roleName, b.roleName))
							?.map((role) => (
								<MenuItem key={role.roleID} value={role.roleID}>
									{role.roleName}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				{isEdit && (
					<FormControl className="full_width">
						<InputLabel id="execute_visibility">Employee Visibility</InputLabel>
						<Select
							name="execVisibilities"
							required
							multiple
							labelId="execute_visibility"
							value={selectedEVAdminsValues}
							renderValue={(selected) => {
								if (selected.length === 1 && selected[0] === 1) return "All"
								return (selected.map((person: any) => `${allAdmins.find(admin => admin.adminID === person)?.firstname} ${allAdmins.find(admin => admin.adminID === person)?.lastname}`).join(", "))
							}}
							onChange={handleChange}
							disabled={formData.parentId === null || currentAdmin.adminDetails.adminID === formData.id}
							label="Executive Visibility">
							{
								displayUniqueEVAdmins.map((data: any) => (
									<MenuItem key={data.adminID} value={data.adminID}>
										<Checkbox checked={selectedEVAdminsValues.includes(data.adminID)} />
										<ListItemText primary={data?.firstName + "  " + data?.lastName} />
									</MenuItem>
								))
							}
						</Select>
					</FormControl>
				)}
				<FormControl className="full_width">
					<InputLabel id="reporting_manager">Reporting Manager</InputLabel>
					<Select
						name="parentId"
						required
						disabled={formData.parentId === null || currentAdmin.adminDetails.adminID === formData.id || !checkCurrentAdminVisibility}
						placeholder="sdfg"
						labelId="reporting_manager"
						value={formData?.parentId}
						onChange={handleChange}
						label="Reporting Manager">
						{displayUniqueRMAdmins}
					</Select>
					{!checkCurrentAdminVisibility && (
						<FormHelperText id="reporting-manager-helper-text" >
							You cannot change reporting manager until your employee visibility is set to <strong>All</strong> or to the <strong>currently logged admin</strong>.
						</FormHelperText>
					)}
				</FormControl>
				<button type="reset" onClick={() => setExpanded(false)}>
					Cancel
				</button>
				<button type="submit" disabled={isLoading}>
					{isLoading ? (
						<>
							<CircularProgress style={{ width: "20px", height: "20px", marginRight: "10px" }} />
							Saving...
						</>
					) : (
						"Save"
					)}
				</button>
			</form>
		</div >
	);
};

export default UserSidebarForm;