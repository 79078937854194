import { DataGridPro, GridColDef, type GridValueGetterParams } from "@mui/x-data-grid-pro";
import moment from "moment";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { COMPLIANCE } from "../../../@types";
import { notifyDefaulters } from "../../../api/compliance";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import { DATE_TYPE_FORMAT, DG_STYLES } from "../../../constants";
import { showNotification, type APP_DISPATCH } from "../../../redux";
import CustomColumnMenu from "../../common/datagrid/CustomColumnMenu";
import ComplianceModal from "./ComplianceModal";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";

type Props = {
	loading: boolean;
	complianceStatus: COMPLIANCE[];
	dateRange: string[];
};
const getStatusForRange = (dateRange: string[], rating: Record<string, "D" | "A" | "-">) => {
	const numberOfDays = moment(dateRange[1]).diff(moment(dateRange[0]), "days") + 1;
	const arr: ReactNode[] = [];
	const avg = {
		good: 0,
		bad: 0,
		no_data: 0,
	};
	for (let i = 0; i < numberOfDays; i++) {
		const day = moment(dateRange[0]).add(i, "days").format("MMM DD");
		const date = moment(dateRange[0]).add(i, "days").format(DATE_TYPE_FORMAT);
		const r = rating[date];
		avg[r === "A" ? "good" : r === "D" ? "bad" : "no_data"]++;
		const settingType = ["A", "D"].includes(r) ? (r === "A" ? "good" : "bad") : "no_data";
		arr.push(<button key={i} title={day} className={settingType} />);
	}
	return { arr, avg };
};

const ComplianceTable = ({ loading, complianceStatus, dateRange }: Props) => {
	const [columns, setColumns] = useState<GridColDef[]>([]);
	const [evidences, setEvidences] = useState<null | NonNullable<COMPLIANCE["evidence"]>>(null);
	const [openModal, setOpenModal] = useState(false);
	const dispatch = useDispatch<APP_DISPATCH>();
	const [pageSize, setPageSize] = useState(20);
	const closeModals = () => {
		setOpenModal(false);
		setEvidences(null);
	};
	const openModalEvidence = (evidence: NonNullable<COMPLIANCE["evidence"]>) => {
		setEvidences(evidence);
		setOpenModal(true);
	};
	const tempColumns = useMemo(
		() => [
			{
				field: "employeeName",
				headerName: "Employee",
				flex: 1,
				minWidth: 120,
				renderCell: (params: GridValueGetterParams<any, COMPLIANCE>) => (
					<div className="employeeName">
						<h4>{params.row.employeeName}</h4>
						{params.row.evidence && Array.isArray(params.row.evidence) && (
							<button onClick={() => openModalEvidence(params.row.evidence!)}>
								<AttachmentOutlinedIcon /> Evidence
							</button>
						)}
					</div>
				),
			},
			{
				field: "appVersion",
				headerName: "App Version",
				flex: 1,
				minWidth: 80,
				renderCell: (params: GridValueGetterParams<any, COMPLIANCE>) => (
					<div className="appVersion">
						{params.row.manufacturer === "Apple" && params.row.appVersion ? (
							<AppleIcon sx={{ width: "16px", margin: "0 5px" }} />
						) : params.row.appVersion ? (
							<AndroidIcon sx={{ width: "16px", margin: "0 5px" }} />
						) : (
							"Not Available"
						)}
						{params.row.appVersion && ` (v${params.row.appVersion})`}
					</div>
				),
			},
			{
				field: "app_setting",
				headerName: "App Setting",
				flex: 2,
				minWidth: 450,
				renderCell: (params: GridValueGetterParams<any, COMPLIANCE>) => {
					const { arr, avg } = getStatusForRange(dateRange, params.row.setupRating);
					return (
						<div className="app_settings">
							<div className="app_settings--status">{arr}</div>
							<div className="app_settings--overall">
								<span className="good">{avg.good}</span>
								<span className="bad">{avg.bad}</span>
								<span className="no_data">{avg.no_data}</span>
							</div>
							<button
								onClick={() => handleNotifyDefaulters(params.row.empID, params.row.manufacturer ?? "Apple", 2)}
								className="app_settings--action">
								Notify
							</button>
						</div>
					);
				},
			},
			{
				field: "location_setting",
				headerName: "Location Setting",
				flex: 2,
				minWidth: 450,
				renderCell: (params: GridValueGetterParams<any, COMPLIANCE>) => {
					const { arr, avg } = getStatusForRange(dateRange, params.row.complianceRating);
					return (
						<div className="app_settings">
							<div className="app_settings--status">{arr}</div>
							<div className="app_settings--overall">
								<span className="good">{avg.good}</span>
								<span className="bad">{avg.bad}</span>
								<span className="no_data">{avg.no_data}</span>
							</div>
							<button
								onClick={() => handleNotifyDefaulters(params.row.empID, params.row.manufacturer ?? "Not Found", 4)}
								className="app_settings--action">
								Notify
							</button>
						</div>
					);
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[complianceStatus, dateRange]
	);
	useEffect(() => {
		setColumns(tempColumns);
	}, [tempColumns]);

	const handleNotifyDefaulters = async (empID: number, manufacturer: string, code: 2 | 4) => {
		if (isNaN(empID) || isNaN(code) || !manufacturer || manufacturer.length === 0) return;
		const { success, message } = await notifyDefaulters([{ empID, manufacturer }], code);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (!success) return;
	};

	return (
		<div className="datagrid-table">
			<DataGridPro
				sx={DG_STYLES}
				rows={complianceStatus ?? []}
				getRowId={(row) => row.empID}
				columns={columns}
				loading={loading}
				pageSize={pageSize}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[5, 10, 20, 50]}
				rowHeight={70}
				disableSelectionOnClick
				disableColumnFilter
				pagination
				components={{ ColumnMenu: CustomColumnMenu }}
			/>
			{openModal && evidences && (
				<ComplianceModal open={openModal} onClose={() => closeModals()} evidences={evidences} />
			)}
		</div>
	);
};

export default ComplianceTable;
