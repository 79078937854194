//react
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { GridSelectionModel } from "@mui/x-data-grid-pro";
import { useDispatch } from "react-redux";
//apollo
import { useMutation } from "@apollo/client";
//components
import { REPLAY_EVENT } from "../../../schema/hrOne";
import { showNotification } from "../../../redux";
//mui
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
type ReplayEventInput = {
	integrationID: number;
	eventID: String;
	eventType: string;
	userWritePermission: any;
	setEventsReplayedResponse: Dispatch<SetStateAction<any>>;
	setSelectedRows: Dispatch<SetStateAction<GridSelectionModel>>;
	isSendingEvents: boolean;
};
const ReplayEvent: FC<ReplayEventInput> = ({
	integrationID,
	eventID,
	eventType,
	userWritePermission,
	setEventsReplayedResponse,
	setSelectedRows,
	isSendingEvents,
}) => {
	const [replayTheEventMutation] = useMutation(REPLAY_EVENT);
	const [showPlayButton, setShowPlayButton] = useState(true);
	const dispatch = useDispatch();

	const replayTheEvent = async () => {
		if (!integrationID || !eventID || !eventType) return;
		try {
			setShowPlayButton(false);
			const res = await replayTheEventMutation({
				variables: {
					input: [
						{
							integrationID: integrationID,
							eventID: eventID,
							eventType: eventType,
						},
					],
				},
			});
			if (res?.data?.replay_event[0]?.success) {
				setShowPlayButton(true);
				dispatch(
					showNotification({
						message: "Successfully sent the event.",
						severity: "success",
					})
				);
				setEventsReplayedResponse(res?.data?.replay_event);
				setSelectedRows([]);
			} else {
				setShowPlayButton(true);
				dispatch(
					showNotification({
						message: "Something went wrong. Please contact support",
						severity: "error",
					})
				);
				setSelectedRows([]);
				return;
			}
		} catch (error) {
			dispatch(
				showNotification({
					message: "Something went wrong. Please contact support",
					severity: "error",
				})
			);
			setShowPlayButton(true);
			setSelectedRows([]);

			return;
		}
	};
	return (
		<div>
			<button
				disabled={!showPlayButton || !userWritePermission || isSendingEvents}
				onClick={(e) => {
					e.stopPropagation();
					replayTheEvent();
				}}>
				{showPlayButton ? (
					<PlayCircleIcon htmlColor="#1976d2" fontSize="medium" />
				) : (
					<PauseCircleFilledIcon fontSize="medium" />
				)}
			</button>
		</div>
	);
};

export default ReplayEvent;
