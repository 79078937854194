import DescriptionIcon from "@mui/icons-material/Description";
import PaymentsIcon from "@mui/icons-material/Payments";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import { Layout, SubmoduleTabs } from "..";
import { SUBMODULE_DATA } from "../../@types";
import NotAuthorized from "../common/NotAuthorized";
import useAdminEntitlements, { useAdminCompanySettings } from "../useAdminEntitlements";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { ROOT_STATE } from "../../redux";

type Props = {
	title: string;
	children: any;
	tab: number;
};
const ExpenseLayout = ({ title, children, tab }: Props) => {
	const {
		adminDetails: { roleID },
		susbscriptionMetadata: { data:subsMetadata },
	} = useSelector((state: ROOT_STATE) => ({
		adminDetails: state.user.adminDetails,
		susbscriptionMetadata: state.susbscriptionMetadata,
	}));

	const expenseGQLEnabled = useAdminCompanySettings("expenseGQLEnabled");

	const expenseSubmoduleData: SUBMODULE_DATA[] = [
		{
			label: "Expense Overview",
			icon: <DescriptionIcon />,
			path: "/expense",
		},
		{
			label: "Conveyance Overview",
			icon: <DescriptionIcon />,
			path: "/expense/conveyance",
		},
		expenseGQLEnabled === 1 ?{
			label: "Expense Requests",
			icon: <AccountBalanceWalletIcon />,
			path: "/expense/expense_request_v1",
		}:{
			label: "Expense Requests",
			icon: <AccountBalanceWalletIcon />,
			path: "/expense/expense_request",
		},
		expenseGQLEnabled === 1?{
			label: "Conveyance Requests",
			icon: <TwoWheelerIcon />,
			path: "/expense/conveyance_requests_v1",
		}:{
			label: "Conveyance Requests",
			icon: <TwoWheelerIcon />,
			path: "/expense/conveyance_requests",
		},
		{
			label: "Advance Requests",
			icon: <PaymentsIcon />,
			path: "/expense/advance_requests",
		},
	];
	expenseSubmoduleData.forEach((item) => {
		if(item && item.label === "Advance Requests"){
			if(subsMetadata?.advances === 0){
				item.hide = true;
			}
		}
		else{
			if(item) item.hide = false;
		}
	})

	//look here for changes
	const userReadPermissionER = useAdminEntitlements("expense:expenseRequests", "read");
	const userReadPermissionEO = useAdminEntitlements("expense:expenseOverview", "read");
	const userReadPermissionCO = useAdminEntitlements("expense:conveyanceOverview", "read");
	const userReadPermissionCR = useAdminEntitlements("expense:conveyanceRequests", "read");
	const userReadPermissionAR = useAdminEntitlements("expense:advanceRequests", "read");


	const router = useRouter();

	const isEORead = router.pathname === "/expense" && userReadPermissionEO;
	const isERRead =  router.pathname.includes("expense_request") && userReadPermissionER;
	const isCORead = router.pathname === "/expense/conveyance" && userReadPermissionCO;
	const isCRRead =  router.pathname.includes("conveyance_requests") && userReadPermissionCR;
	const isARRead =  router.pathname.includes("advance_requests") && userReadPermissionAR;

	const filteredExpensesSubmoduleData = expenseSubmoduleData.filter(item => item && !item.hide);
	
	return (
		<Layout title={title}>
			<SubmoduleTabs submoduleData={filteredExpensesSubmoduleData} tab={tab} />
			{isEORead || isERRead || isCORead || isCRRead || isARRead ? children : <NotAuthorized roleID={roleID} moduleName="expense"/>}
		</Layout>
	);
};

export default ExpenseLayout;
