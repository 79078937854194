import Link from "next/link";
import { FC } from "react";

const ThirdPartyIntegrations: FC = () => (
	<div className="integrations">
		<div className="integrations-header">
			<p>CRM Integrations</p>
			<div className="divider"></div>
		</div>
		<div className="integrations-body">
			<div className="zoho-crm integration-box">
				<div className="box-header">
					<img src="/images/zoho-crm.svg" alt="" />
					<Link prefetch={false} href="/settings/integrations/zoho-crm/zoho-credentials" passHref legacyBehavior>
						<button className="connect-button">Connect</button>
					</Link>
				</div>
				<div className="box-body">
					<p className="integration-name">Zoho CRM</p>
					<p className="integration-description">
						Two way sync between Zoho CRM (leads/accounts/contacts/activities) and Unolo (clients/tasks).
					</p>
				</div>
			</div>
		</div>
		<div className="integrations-header">
			<p>HRMS</p>
			<div className="divider"></div>
		</div>

		<div className="integrations-body">
			<div className="zoho-crm integration-box">
				<div className="box-header">
					<img src="/images/greythr.svg" alt="" />
					<Link prefetch={false} href="/settings/integrations/greythr" passHref legacyBehavior>
						<button className="connect-button">Connect</button>
					</Link>{" "}
				</div>
				<div className="box-body">
					<p className="integration-name">GREYTHR</p>
					<p className="integration-description">Sync attendance from Unolo to GreytHR.</p>
				</div>
			</div>
			<div className="zoho-crm integration-box">
				<div className="box-header">
					<img src="/images/hrone.svg" alt="" style={{ width: "58px" }} />
					<Link prefetch={false} href="/settings/integrations/hrone" passHref legacyBehavior>
						<button className="connect-button">Connect</button>
					</Link>{" "}
				</div>
				<div className="box-body">
					<p className="integration-name">HROne</p>
					<p className="integration-description">Sync attendance from Unolo to HROne.</p>
				</div>
			</div>
			<div className="zoho-crm integration-box">
				<div className="box-header">
					<img src="/images/zoho-crm.svg" alt="" />
					<Link prefetch={false} href="/settings/integrations/zoho-people" passHref legacyBehavior>
						<button className="connect-button">Connect</button>
					</Link>{" "}
				</div>
				<div className="box-body">
					<p className="integration-name">Zoho People</p>
					<p className="integration-description">Sync attendance/leaves data from Unolo to Zoho People.</p>
				</div>
			</div>
		</div>
	</div>
);

export default ThirdPartyIntegrations;
