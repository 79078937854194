/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useState } from "react";

import { styled } from "@mui/material/styles";

import BedtimeIcon from "@mui/icons-material/Bedtime";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import ClearIcon from "@mui/icons-material/Clear";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { deleteRosterTemplate } from "../../../api/attendance";
import { BD_ROSTERS } from "../../../constants/url";
import { APP_DISPATCH, ROOT_STATE, setRosterTemplates, showNotification } from "../../../redux";
import BulkDownloadModal from "../../common/BulkDownloadModal";
import useAdminEntitlements from "../../useAdminEntitlements";
import RostersCreateModal from "./RostersCreateModal";
import RosterUploadModal from "./RosterUploadModal";
import ReadOnlyButton from "../../common/ReadOnlyButton";
import moment from "moment";

type Props = {
	data: any;
};
const ClonedComponent: FC<Props> = ({ data }) => (
	<div className="template-card">
		<div className="template-card-left">
			{data["isOverNight"] ? (
				<BedtimeIcon sx={{ fontSize: "18px", color: data["color"] }} />
			) : (
				<WbSunnyIcon sx={{ fontSize: "18px", color: data["color"] }} />
			)}
		</div>

		<div className="template-card-right">
			<Typography className="shiftTemplateName">{data.name === "WO" ? "Weekly Off" : data.name}</Typography>
			{data.name !== "WO" && <Typography className="shiftTemplateTime">{`${data.start} - ${data.end}`}</Typography>}
		</div>
	</div>
);

const CustomCard = styled(Card)({
	boxShadow: "none",
	borderRadius: "12px",
	"& .MuiCardContent-root": {
		padding: "1rem !important",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
	"& .MuiTypography-root": {
		marginBottom: 0,
	},
});

const RostersCreate: FC = () => {
	const { rosterTemplates: rostersTemplateData } = useSelector((state: ROOT_STATE) => state.roster);
	const user = useSelector((state: ROOT_STATE) => state.user);
	const [open, setOpen] = useState(false);
	const [rosterSelectedData, setRosterSelectedData] = useState<any | null>(null);
	const dispatch = useDispatch<APP_DISPATCH>();
	const userWritePermission = useAdminEntitlements("attendance:rosters", "write");
	const [bulkDownloadOpen, setBulkDownloadOpen] = useState(false);
	const [bulkUploadOpen, setBulkUploadOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const upsertRoster = (roster: any) => {
		let clonedRostersTemplateData,
			isPresent = false;

		clonedRostersTemplateData = rostersTemplateData.map((rosterTemplate: any) => {
			if (rosterTemplate["rosterTemplateID"] === roster["rosterTemplateID"]) {
				isPresent = true;
				return { ...rosterTemplate, start: roster["start"], end: roster["end"], name: roster["name"] };
			}
			return { ...rosterTemplate };
		});

		if (!isPresent) {
			clonedRostersTemplateData = [...rostersTemplateData, { ...roster, color: "rgb(122, 86, 71)" }];
		}

		dispatch(setRosterTemplates({ rosterTemplates: clonedRostersTemplateData }));
		handleClose();
	};

	const deleteRosterTemplateHandler = async (rosterTemplateID: string) => {
		const { success, message } = await deleteRosterTemplate(rosterTemplateID);
		if (success) {
			const clonedRostersTemplateData = rostersTemplateData.filter(
				(roster: any) => roster["rosterTemplateID"] !== rosterTemplateID
			);
			dispatch(setRosterTemplates({ rosterTemplates: clonedRostersTemplateData }));
			handleClose();
		}
		dispatch(showNotification({ message: message, severity: success ? "success" : "error" }));
	};

	return (
		<div className="shift-create">
			<CustomCard>
				<CardContent>
					<div className="header">
						<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
							<Typography>Create Rosters</Typography>
							{!userWritePermission ? (
								<ReadOnlyButton roleID={user.adminDetails.roleID} moduleName="attendance" />
							) : null}
						</div>
						<div className="button_group">
							<button className="xls_button" disabled={!userWritePermission} onClick={() => setBulkUploadOpen(true)}>
								<FileUploadOutlinedIcon />
							</button>
							<button className="xls_button" onClick={() => setBulkDownloadOpen(true)}>
								<FileDownloadOutlinedIcon />
							</button>
							<button
								className="add mui-button"
								disabled={!userWritePermission}
								onClick={() => {
									setRosterSelectedData(null);
									handleOpen();
								}}>
								<AddRoundedIcon />
								Add Roster
							</button>
						</div>
					</div>
					<Typography className="roster-message">
						Note: When a roster and a shift exist for the same day for the same employee, the roster takes priority.
					</Typography>
					<Divider sx={{ border: "1px solid #DFDFDF", width: "100%", margin: "1rem 0" }} />
					<div className="shifts-create">
						<Droppable droppableId="droppable-create" isDropDisabled={true} direction="horizontal">
							{(provided: any) => (
								<div
									ref={provided.innerRef}
									// isDraggingOver={snapshot.isDraggingOver}
									className="table">
									{rostersTemplateData.map((data: any) => (
										<Draggable
											key={`${data["rosterTemplateID"]}`}
											draggableId={`${data["rosterTemplateID"]}`}
											isDragDisabled={!userWritePermission}
											index={parseInt(data["rosterTemplateID"])}>
											{(provided, snapshot) => (
												<div
													id={data["rosterTemplateID"]}
													onClick={() => {
														if (data.name === "WO" && data.rosterTemplateID === "-1") return;
														if (userWritePermission) {
															setRosterSelectedData(data);
															handleOpen();
														}
													}}>
													<div
														ref={provided.innerRef}
														className={!userWritePermission ? "template-card-nopermission" : "template-card"}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														id={data["rosterTemplateID"]}>
														<DragIndicatorIcon
															sx={{ color: "#8F8F8F", width: "20px", height: "20px", padding: "0px !important" }}
														/>
														<div className="template-card-left" id={data["rosterTemplateID"]}>
															{data["isOverNight"] ? (
																<BedtimeIcon
																	id={data["rosterTemplateID"]}
																	sx={{ fontSize: "18px", color: data["color"] }}
																/>
															) : (
																<WbSunnyIcon
																	id={data["rosterTemplateID"]}
																	sx={{ fontSize: "18px", color: data["color"] }}
																/>
															)}
														</div>
														<div className="template-card-right" id={data["rosterTemplateID"]}>
															<div className="name_and_clear">
																<Typography id={data["rosterTemplateID"]} className="shiftTemplateName">
																	{data["name"] === "WO" ? "Weekly Off" : data["name"]}
																</Typography>
																{data["start"] ? (
																	<button
																		disabled={!userWritePermission}
																		onClick={(e) => {
																			e.stopPropagation();
																			deleteRosterTemplateHandler(data["rosterTemplateID"]);
																		}}>
																		<ClearIcon />
																	</button>
																) : null}
															</div>
															{data["start"] ? (
																<Typography id={data["rosterTemplateID"]} className="shiftTemplateTime">
																	{/**
																	 * convert 24 hour format to 12 hour format with moment.js
																	 */}
																	{moment(data["start"], "HH:mm:ss").format("hh:mm A")} -{" "}
																	{moment(data["end"], "HH:mm:ss").format("hh:mm A")}
																</Typography>
															) : null}
														</div>
													</div>
													{snapshot.isDragging && <ClonedComponent data={data} />}
												</div>
											)}
										</Draggable>
									))}
								</div>
							)}
						</Droppable>
					</div>
				</CardContent>
			</CustomCard>
			{open ? (
				<RostersCreateModal
					handleClose={handleClose}
					rosterSelectedData={rosterSelectedData}
					upsertRoster={upsertRoster}
					rostersTemplateData={rostersTemplateData}
				/>
			) : null}
			{bulkDownloadOpen && (
				<BulkDownloadModal bulkDownloadUrl={BD_ROSTERS} handleClose={() => setBulkDownloadOpen(false)} />
			)}
			{bulkUploadOpen && <RosterUploadModal handleClose={() => setBulkUploadOpen(false)} />}
		</div>
	);
};

export default RostersCreate;
