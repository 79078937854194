import { gql } from "@apollo/client";

export const GET_ALL_ENTITYMETADATA_BY_ENTITY_FOR_CURRENT_COMPANY = gql`
	query getAllEntityMetadataByEntityForCurrentCompany($entity: String!) {
		get_all_entitymetadata_by_entity_for_current_company(entity: $entity) {
			fieldID
			fieldName
			dataType
			isDefault
			isMandatory
			isRequired
			sequence
			write
			argsOne
			entity
			primaryField
			active
			maskField
			options {
				optionID
				softDeleted
				optionValue
				terminalState
				considerOption
			}
		}
	}
`;
export const GET_ALL_ENTITYMETADATA_BY_ENTITY_FOR_CUSTOM_TASK = gql`
	query getAllEntityMetadataByEntityForCurrentCompany($customEntityID: ID!) {
		get_all_entitymetadata_by_custom_entity(customEntityID: $customEntityID) {
			fieldID
			fieldName
			dataType
			isDefault
			isMandatory
			isRequired
			sequence
			write
			argsOne
			entity
			primaryField
			allowAdminToEditAfterSubmit
			active
			options {
				optionID
				softDeleted
				optionValue
			}
		}
	}
`;

export const UPSERT_ENTITYMETADATA_BATCH = gql`
	mutation UpsertEntitymetadataBatch($input: [entityMetaDataInput!]!) {
		upsert_entitymetadata_batch(input: $input) {
			success
			message
			data {
				fieldID
				fieldName
				dataType
				isRequired
				isMandatory
				active
				isDefault
				entity
				maskField
				options {
					optionID
					optionValue
					terminalState
					considerOption
				}
				primaryField
				sequence
				write
				argsOne
			}
		}
	}
`;

export const GET_ALL_ENTITYMETADATA_FOR_CURRENT_COMPANY = gql`
	query {
		get_all_entitymetadata_count_by_current_company
	}
`;
