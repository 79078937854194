//react
import { FC } from "react";

//types, utils, constants
import { PJP_REQUEST_TYPE_2 } from "../../../@types";

//mui
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import PriorityHighOutlinedIcon from "@mui/icons-material/PriorityHighOutlined";
import { TableBody, Table, TableCell, TableHead, TableRow, TableContainer, Paper } from "@mui/material";
import { pjpStatus } from "../../../constants/tasks";
import { formatDateTo_DDMMYYYY } from "../../../utils/validations";
import { useAdminCompanySettings } from "../../useAdminEntitlements";

type Props = {
	request: PJP_REQUEST_TYPE_2;
};
const PjpStatus: FC<Props> = ({ request }) => {
	const state =
		request.status === pjpStatus[0]
			? "Rejected"
			: request.status === pjpStatus[1]
			? "Approved"
			: request.status === pjpStatus[2]
			? "Pending"
			: "Deleted";
	const icon =
		state === "Rejected" ? (
			<CloseOutlinedIcon htmlColor="#ffffff" />
		) : state === "Approved" ? (
			<DoneOutlinedIcon htmlColor="#ffffff" />
		) : (
			<PriorityHighOutlinedIcon htmlColor="#ffffff" />
		);
	const customTaskFlag = useAdminCompanySettings("customEntityMigrationPhase");

	return (
		<div className="summary">
			<div className="title">
				<picture className={state}>{icon}</picture>
				<div style={{ display: "flex", width: "500px", lineHeight: "25px" }}>
					<div
						className="col"
						style={{ backgroundColor: "#ededed", padding: "10px", borderRadius: "8px", marginRight: "10px" }}>
						<p style={{ fontWeight: "200px !important" }}>Journey Date:</p>
						<time>{formatDateTo_DDMMYYYY(request.date)}</time>
					</div>
					<div className="col" style={{ backgroundColor: "#ededed", padding: "10px", borderRadius: "8px" }}>
						<p>Date of Submission:</p>
						<time>{formatDateTo_DDMMYYYY(request.createdTs)}</time>
					</div>
				</div>

				<div className="col" style={{ padding: "10px 0" }}>
					<span>
						Status: <strong>{state}</strong>
					</span>
				</div>
			</div>
			{Array.isArray(request.visits) && request.visits.length > 0 && (
				<TableContainer component={Paper}>
					<Table sx={{ marginTop: "2rem", minWidth: 550 }}>
						<TableHead sx={{ background: "#F4F4F5", height: "30px" }}>
							<TableRow>
								<TableCell>Client Name</TableCell>
								{[1, 2]?.includes(customTaskFlag) && <TableCell>Task Type</TableCell>}
								<TableCell>Address</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{request.visits.map((task) => (
								<TableRow key={task.clientID} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell>{task.clientName}</TableCell>
									{[1, 2]?.includes(customTaskFlag) && (
										<TableCell>{task.customEntity === null ? "Default" : task.customEntity.customEntityName}</TableCell>
									)}
									<TableCell>{task.address}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</div>
	);
};

export default PjpStatus;
