import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { ENTITY_METADATA } from "../../../@types";
import IndividualFilterClients from "../../common/datagrid/IndividualFilterClients";
import IndividualFilterVisibilityTo from "../../common/datagrid/IndividualFilterVisibilityTo";
import { AccordionData } from "../../../pages/clients_sites/clients";

type Props = {
	setFilterOpen: Dispatch<SetStateAction<boolean>>;
	entityMetadata: ENTITY_METADATA[];
	filterCount: number;
	setFilterCount: Dispatch<SetStateAction<number>>;
	setParameters: Dispatch<
		React.SetStateAction<{
			skip: number;
			take: number;
			filters: AccordionData[];
		}>
	>;
	deleted: number;
	setDeleted: Dispatch<React.SetStateAction<number>>;
};

const FilterClients: FC<Props> = ({
	setFilterOpen,
	entityMetadata,
	filterCount,
	setFilterCount,
	setParameters,
	deleted,
	setDeleted,
}) => {
	const [resetCheckBox, setResetCheckBox] = useState<boolean>(true);
	const handleReset = async () => {
		setParameters((prevState) => ({
			...prevState,
			filters: [],
		}));
		setResetCheckBox(false);
		setFilterCount(0);
	};

	const filterClose = () => setFilterOpen(false);
	return (
		// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
		<form
			onSubmit={(e) => e.preventDefault()}
			onClick={(e) => e.stopPropagation()}
			onKeyPress={(e) => e.stopPropagation()}>
			<div className="filter__header">
				<h5>
					<FilterAltOutlinedIcon />
					Filters
					<span className="right-notification center" style={{ display: "flex", padding: 0 }}>
						{filterCount}
					</span>
				</h5>
				<button onClick={filterClose}>
					<CloseSharpIcon htmlColor="#42444A" />
				</button>
			</div>
			<div className="accordion__group">
				<p>Default Fields</p>
				{entityMetadata
					?.filter(
						(metadata) =>
							metadata.active === 1 &&
							metadata.isDefault === 1 &&
							metadata.dataType !== "photo" &&
							metadata.dataType !== "file" &&
							// remove proprietory name and contact number (old fields)
							metadata.fieldName !== "Contact Number" &&
							metadata.fieldName !== "Contact Person" &&
							!metadata.maskField
					)
					.map((metadata) => {
						if (metadata.dataType === "contact" && metadata.fieldName === "Contact") {
							return (
								<>
									<IndividualFilterClients
										name={metadata.fieldName + " Name"}
										key={metadata.fieldID}
										fieldID={metadata.fieldID}
										setFilterCount={setFilterCount}
										setParameters={setParameters}
										resetCheckBox={resetCheckBox}
										setResetCheckBox={setResetCheckBox}
										deleted={deleted}
										setDeleted={setDeleted}
										dataType={metadata.dataType}
										metadata={metadata}
									/>
									<IndividualFilterClients
										name={metadata.fieldName + " Number"}
										key={metadata.fieldID}
										fieldID={metadata.fieldID}
										setFilterCount={setFilterCount}
										setParameters={setParameters}
										resetCheckBox={resetCheckBox}
										setResetCheckBox={setResetCheckBox}
										deleted={deleted}
										setDeleted={setDeleted}
										dataType={metadata.dataType}
										metadata={metadata}
									/>
								</>
							);
						}
						return (
							<IndividualFilterClients
								name={metadata.fieldName}
								key={metadata.fieldID}
								fieldID={metadata.fieldID}
								setFilterCount={setFilterCount}
								setParameters={setParameters}
								resetCheckBox={resetCheckBox}
								setResetCheckBox={setResetCheckBox}
								deleted={deleted}
								setDeleted={setDeleted}
								dataType={metadata.dataType}
								metadata={metadata}
							/>
						);
					})}
				<IndividualFilterVisibilityTo
					name={"VisibilityTo"}
					setFilterCount={setFilterCount}
					setParameters={setParameters}
					resetCheckBox={resetCheckBox}
					setResetCheckBox={setResetCheckBox}
					deleted={deleted}
					setDeleted={setDeleted}
				/>

				<hr />

				<p>Custom Fields</p>
				{entityMetadata
					?.filter(
						(metadata) =>
							metadata.active === 1 &&
							metadata.isDefault === 0 &&
							metadata.dataType !== "photo" &&
							metadata.dataType !== "file" &&
							metadata.dataType !== "form" &&
							metadata.dataType !== "employee" &&
							!metadata.maskField
					)
					.sort((a, b) => a.fieldName.localeCompare(b.fieldName))
					.map((metadata) => {
						if (metadata.dataType === "contact") {
							return (
								<>
									<IndividualFilterClients
										name={metadata.fieldName + " Name"}
										key={metadata.fieldID}
										fieldID={metadata.fieldID}
										setFilterCount={setFilterCount}
										setParameters={setParameters}
										resetCheckBox={resetCheckBox}
										setResetCheckBox={setResetCheckBox}
										deleted={deleted}
										setDeleted={setDeleted}
										dataType={metadata.dataType}
										metadata={metadata}
									/>
									<IndividualFilterClients
										name={metadata.fieldName + " Number"}
										key={metadata.fieldID}
										fieldID={metadata.fieldID}
										setFilterCount={setFilterCount}
										setParameters={setParameters}
										resetCheckBox={resetCheckBox}
										setResetCheckBox={setResetCheckBox}
										deleted={deleted}
										setDeleted={setDeleted}
										dataType={metadata.dataType}
										metadata={metadata}
									/>
								</>
							);
						}
						return (
							<IndividualFilterClients
								name={metadata.fieldName}
								key={metadata.fieldID}
								fieldID={metadata.fieldID}
								setFilterCount={setFilterCount}
								setParameters={setParameters}
								resetCheckBox={resetCheckBox}
								setResetCheckBox={setResetCheckBox}
								deleted={deleted}
								setDeleted={setDeleted}
								dataType={metadata.dataType}
								metadata={metadata}
							/>
						);
					})}
			</div>
			<div className="filter__buttons">
				<button type="reset" onClick={handleReset} disabled={filterCount === 0}>
					Reset
				</button>
			</div>
		</form>
	);
};

export default FilterClients;
