import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { FC, useState } from "react";
import ApproveModal from "./ApproveModal";
import Link from "next/link";
type Props = {
	approveRequest: () => void;
	// eslint-disable-next-line no-unused-vars
	rejectRequest: (reason: string) => void;
	name: string;
	link?: string;
	userWritePermission:any;
};
const ApproveRejectButtons: FC<Props> = ({ rejectRequest, approveRequest, name, link, userWritePermission }) => {
	const [open, setOpen] = useState(false);
	const [isReject, setIsReject] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};
	return <>
        <div className="action-buttons" style={{display:"flex", gap:"5px"}}>
            {link && typeof link === "string" && link.length > 0 && (
                <button disabled={!userWritePermission} className="view" style={{margin:"0px"}}>
                <Link href={link} style={{pointerEvents: !userWritePermission ? "none" : "auto"}}>

                    <RemoveRedEyeOutlinedIcon htmlColor={!userWritePermission ? "#fff": "#819dff"} />

                </Link>
                </button>
            )}
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                    setIsReject(false);
                }}
                className="view"
                disabled={!userWritePermission}>
                <DoneOutlinedIcon htmlColor="#40A636" />
            </button>
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                    setIsReject(true);
                }}
                className="view"
                disabled={!userWritePermission}>
                <CloseOutlinedIcon htmlColor="#F54747" />
            </button>
        </div>
        {open && (
            <ApproveModal
                isReject={isReject}
                approveHandler={approveRequest}
                rejectHandler={rejectRequest}
                handleClose={handleClose}
                name={name}
            />
        )}
    </>;
};

export default ApproveRejectButtons;
