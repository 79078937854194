// react
import { useState, Dispatch, SetStateAction, useEffect } from "react";
// mui
import {
	DataGridPro,
	GridColDef,
	GridRowParams,
	GridValueFormatterParams,
	GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import DownloadIcon from "@mui/icons-material/Download";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Box, Card, CardContent, Typography } from "@mui/material";
// utils
import { DATA_GRID_CUSTOMIZATION_OPTIONS, DATA_GRID_NAMES, DG_STYLES } from "../../../constants";
import {
	FORM_REDIRECT_URL,
	formatTheDataFromSlice,
	getTheSavedColumns,
	modifyColumOrder,
	modifyColumnWidth,
	ordersCustomColumn,
} from "../../../utils";
import useAdminEntitlements from "../../useAdminEntitlements";
// query
import { CUSTOM_FIELD_COMPLEX_TYPE, ENTITY_METADATA, ORDER_TYPE } from "../../../@types";
// redux
import { APP_DISPATCH } from "../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { setColumnConfigurations, setColumnSortModel, setPinnedColumns } from "../../../redux/slices/dataGridSlice";
// components
import OrderSidebarForm from "./OrderSidebarForm";
import CustomColumnMenu from "../../common/datagrid/CustomColumnMenu";
import CustomDataGridExport from "../../common/datagrid/CustomDataGridExport";
import { ColumnModifierSidebar, OrdersDetailPage, RightSidebar } from "../../../components";

type Props = {
	columns: any[];
	setColumns: any;
	filteredData: ORDER_TYPE[];
	entityMetadata: ENTITY_METADATA[];
	entityLoad: boolean;
	refetchEntity: () => void;
	isLoading: boolean;
	setDisplayTopMenu: Dispatch<SetStateAction<boolean>>;
	fetchOrders: () => void;
	currencySymbol: string;
	searchTerm: any;
	setFilterOpen: Dispatch<SetStateAction<boolean>>;
};

const OrdersTableV2 = ({
	columns,
	setColumns,
	filteredData,
	entityMetadata,
	entityLoad,
	refetchEntity,
	isLoading,
	setDisplayTopMenu,
	fetchOrders,
	currencySymbol,
	searchTerm,
	setFilterOpen,
}: Props) => {
	const userWritePermission = useAdminEntitlements("orders:orders", "write");
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(20);
	const [expandColumn, setExpandColumn] = useState(false);
	const [expand, setExpand] = useState(false);
	const [ordersDetail,  setOrdersDetail] = useState([]);
	const [selectedRow, setSelectedRow] = useState<{ rowId: string | null; orderRow: ORDER_TYPE[] }>({
		rowId: null,
		orderRow: [],
	});
	const [editableRow, setEditableRow] = useState<ORDER_TYPE | null>(null);
	const pageChangeHandler = (newPage: number) => {
		setPage(newPage);
	};
	const pageSizeChangeHandler = (newPageSize: number) => {
		setPageSize(newPageSize);
		setPage(0);
	};
	const [total, setTotal] = useState({
		totalValue: 0,
		totalQuantity: 0,
	});
	const dispatch = useDispatch<APP_DISPATCH>();

	useEffect(() => {
		const filteredOrders: ORDER_TYPE[] = filteredData
		let formattedData :any= filteredOrders.map((row) => {
			const quantity =
				row.orderItems && Array.isArray(row.orderItems)
					? row.orderItems.reduce((acc, item) => acc + item.quantity, 0)
					: 0;

			const orderItemsWithTotalTax =
				row.orderItems && Array.isArray(row.orderItems)
					? row.orderItems.reduce((acc, item) => acc + item.totalTaxAmount, 0)
					: 0;

			const orderItemsWithOrderValue =
				row.orderItems && Array.isArray(row.orderItems)
					? row.orderItems.reduce((acc, item) => acc + item.amount, 0)
					: 0;
			const dateParts = row.processingDate.split("-");
			const processingDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
			return {
				...row,
				processingDate: processingDate,
				orderValue: orderItemsWithOrderValue,
				taxValue: orderItemsWithTotalTax,
				skusQuantity: row.orderItems?.length || 0,
				quantity,
			};
		});
		setOrdersDetail(formattedData);
		setSelectedRow((prevState) => ({
			...prevState,
			orderRow: formattedData.filter((row:any) => row.orderID === prevState.rowId),
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm, filteredData]);

	useEffect(() => {
		let totalValue = 0;
		let totalQuantity = 0;

		ordersDetail.forEach((e:any) => {
			totalValue += e.orderValue + e.taxValue;
			totalQuantity += e.quantity;
		});
		setTotal({ totalValue: totalValue, totalQuantity: totalQuantity });
	}, [ordersDetail]);

	useEffect(() => {
		const cols: GridColDef[] = [
			{ field: "processingDate", headerName: "Date", width: 150 },
			{ field: "invoiceNumber", headerName: "Order Invoice Number", width: 250 },
			{
				field: "employeeName",
				headerName: "Employee Name",
				width: 150,
				valueGetter: (params) =>
					params.row.userInfo ? `${params.row.userInfo?.firstName} ${params.row.userInfo?.lastName}` : "-",
			},
			{
				field: "clientName",
				headerName: "Client Name",
				width: 150,
				valueGetter: (params) => (params.row.clientName ? params.row.clientName : "-"),
			},
			{
				field: "teamName",
				headerName: "Team Name",
				width: 150,
				valueGetter: (params) => (params.row.userInfo.profileName ? params.row.userInfo.profileName : "-"),
			},
			{ field: "skusQuantity", headerName: "Number of SKUs", width: 150 },
			{ field: "quantity", headerName: "Quantity", width: 150 },
			{
				field: "totalOrderAmount",
				headerName: `Total Order Amount (${currencySymbol})`,
				width: 200,
				valueGetter: (params) => `${parseFloat((params.row.orderValue + params.row.taxValue).toFixed(2))}`,
			},
			{ field: "paymentCollected", headerName: `Collection Amount (${currencySymbol})`, width: 200 },
		];

		if (entityMetadata) {
			const missingEntities = entityMetadata.filter((value) => value?.active === 1);
			missingEntities.forEach((entity) => {
				if (entity.dataType === "contact") {
					cols.push({
						field: entity.fieldName + " Name",
						headerName: entity.fieldName + " Name",
						flex: 1,
						minWidth: 250,
						// render custom component
						renderCell: (params: GridValueGetterParams) => {
							const contact = params.row.customFieldsComplex?.find((field: any) => field.fieldID === entity.fieldID);

							return (
								<>
									{contact ? (
										<>
											<span style={{ fontSize: "14px" }}>{contact?.fieldValue}</span>
										</>
									) : (
										"-"
									)}
								</>
							);
						},
					});

					cols.push({
						field: entity.fieldName + " Number",
						headerName: entity.fieldName + " Number",
						flex: 1,
						minWidth: 250,
						// render custom component
						renderCell: (params: GridValueGetterParams) => {
							const contact = params.row.customFieldsComplex?.find((field: any) => field.fieldID === entity.fieldID);

							return (
								<>
									{contact
										? contact && contact?.fieldValue2 && contact?.fieldValue3
											? `+${contact?.fieldValue2} ${contact?.fieldValue3}`
											: "-"
										: "-"}
								</>
							);
						},
					});
				} else if (entity.dataType === "photo") {
					cols.push({
						field: entity.fieldName,
						headerName: entity.fieldName,
						flex: 1,
						minWidth: 200,
						renderCell: (params: GridValueGetterParams) => {
							const photos = params.row.customFieldsComplex?.find(
								(field: any) => field.fieldID === entity.fieldID
							)?.selectedFieldValues;
							return (
								<div style={{ display: "flex", gap: "0.5rem" }}>
									{photos?.length > 0
										? photos.map((photo: any) => (
											// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
											<img
												src={photo.fieldValue}
												key={photo.fieldValue}
												alt=""
												style={{
													flex: 1,
													height: "50px",
													width: "50px",
													objectFit: "cover",
													objectPosition: "center",
													border: "1px solid gray",
													borderRadius: "5px",
													cursor: "pointer",
												}}
												onClick={(e) => {
													e.stopPropagation();
													window.open(photo.fieldValue, "_blank");
												}}
											/>
										))
										: "-"}
								</div>
							);
						},
					});
				} else if (entity.dataType === "file") {
					cols.push({
						field: entity.fieldName,
						headerName: entity.fieldName,
						flex: 1,
						minWidth: 250,
						renderCell: (params: GridValueGetterParams) => {
							const photos = params.row.customFieldsComplex?.find(
								(field: any) => field.fieldID === entity.fieldID
							)?.selectedFieldValues;
							return (
								<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "0.3rem" }}>
									{photos?.length > 0
										? photos.map((photo: any) => (
											// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react/jsx-key
											<div
												style={{
													flex: 1,
													cursor: "pointer",
													padding: "0px 5px",
													border: "1px solid gray",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													gap: "0.2rem",
													fontSize: "10px",
													borderRadius: "30px",
												}}
												onClick={(e) => {
													e.stopPropagation();
													window.open(photo.fieldValue, "_blank");
												}}>
												<DownloadIcon style={{ fontSize: "18px" }} />
												{photo.fieldValue2}
											</div>
										))
										: "-"}
								</div>
							);
						},
					});
				} else if (entity.dataType === "form") {
					cols.push({
						field: entity.fieldName,
						headerName: entity.fieldName,
						flex: 1,
						minWidth: 200,
						renderCell: (params: GridValueGetterParams) => {
							const selectedField: CUSTOM_FIELD_COMPLEX_TYPE | null | undefined =
								params.row.customFieldsComplex && Array.isArray(params.row.customFieldsComplex)
									? params.row.customFieldsComplex.find(
										(field: CUSTOM_FIELD_COMPLEX_TYPE) => field?.fieldID === entity?.fieldID
									)
									: null;
							if (selectedField === null || selectedField === undefined) return <>-</>;
							if (!selectedField.fieldValue || !selectedField.fieldValue2) return <>-</>;
							return (
								<a
									href={`${FORM_REDIRECT_URL}/${selectedField?.fieldValue}?createdTs=${selectedField?.fieldValue2}`}
									target="_blank"
									rel="noreferrer">
									Form Link
								</a>
							);
						},
					});
				} else
					cols.push({
						field: entity.fieldName,
						headerName: entity.fieldName,
						flex: 1,
						minWidth: 200,
						renderCell: (params: GridValueGetterParams) => {
							if (entity.fieldName === "Order Status") {
								const customFields = params.row.customFieldsComplex;
								const orderStatusField = customFields?.find((field: any) => field.fieldName === "Order Status");
								const status = orderStatusField?.selectedOptionsValues[0];
								if (status) {
									return <span className={`role_badge ${status}`}>{status}</span>;
								}
							} else {
								return ordersCustomColumn(params.row.customFieldsComplex, entity);
							}
						},
					});
			});
		}
		cols.push({
			field: "lastModifiedTs",
			headerName: "Last Modified",
			width: 150,
			renderCell: (params: GridValueGetterParams) => {
				const timestamp = params.value as number;
				const date = new Date(timestamp);
				const day = date.getDate().toString().padStart(2, "0"); // Ensure day has two digits
				const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ensure month has two digits
				const year = date.getFullYear();
				const formattedDate = `${day}-${month}-${year}`;
				return formattedDate;
			},
			valueFormatter: (params: GridValueFormatterParams) => {
				const timestamp = params.value as number;
				const date = new Date(timestamp);
				const day = date.getDate().toString().padStart(2, "0"); // Ensure day has two digits
				const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ensure month has two digits
				const year = date.getFullYear();
				const formattedDate = `${day}-${month}-${year}`;
				return formattedDate;
			},
		});

		// this is to add the action field to the columns
		cols.push({
			field: "action",
			headerName: "Action",
			width: 100,
			sortable: false,
			disableExport: true,
			renderCell: (params: GridValueGetterParams) => (
				<div className="action-buttons">
					<button
						onClick={(event) => {
							event.stopPropagation(); // Stop event propagationyy
							const rowData = filteredData.filter((row) => row.orderID === params.row.orderID);
							setEditableRow(rowData[0]);
							setExpand(true);
						}}
						disabled={!userWritePermission}>
						<EditOutlinedIcon htmlColor="#8F8F8F" />
					</button>
				</div>
			),
		});
		setColumns([...cols]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entityMetadata, filteredData]);

	const handleRowClick = (orderId: string) => {
		const rowData = filteredData.filter((row) => row.orderID === orderId);
		setDisplayTopMenu(false);
		setSelectedRow((prevState) => ({
			...prevState,
			rowId: orderId,
			orderRow: rowData,
		}));
	};
	const getColum = useSelector((state: any) => state.dataGrid);
	const savedColumnConf = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.orders_orders,
		DATA_GRID_CUSTOMIZATION_OPTIONS.columns_array
	);
	const savedColumnSortModel = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.orders_orders,
		DATA_GRID_CUSTOMIZATION_OPTIONS.sort_model
	);
	const savedPinnedColumns = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.orders_orders,
		DATA_GRID_CUSTOMIZATION_OPTIONS.pinned_column
	);
	const formattedcolumn = formatTheDataFromSlice(savedColumnConf, columns) || [];
	return (
		<Box>
			{selectedRow.rowId === null ? (
				<>
					<Box className="datagrid-table" position={"relative"}>
						<button className="filter__button" onClick={() => setExpandColumn((prev) => !prev)} aria-label="Filter">
							<img src="/images/filter.svg" alt="" />
						</button>
						<DataGridPro
							sx={DG_STYLES}
							rows={ordersDetail}
							loading={isLoading}
							columns={formattedcolumn?.length > 0 ? formattedcolumn : columns}
							sortModel={savedColumnSortModel?.length > 0 ? savedColumnSortModel : []}
							pinnedColumns={savedPinnedColumns && Object.keys(savedPinnedColumns).length > 0 ? savedPinnedColumns : {}}
							getRowId={(row:any) => row.orderID}
							page={page}
							onPageChange={pageChangeHandler}
							pageSize={pageSize}
							onPageSizeChange={pageSizeChangeHandler}
							rowsPerPageOptions={[5, 10, 20, 50, 100]}
							rowHeight={70}
							checkboxSelection
							disableSelectionOnClick
							disableColumnFilter
							pagination
							onRowClick={(params: GridRowParams) => {
								if (userWritePermission) {
									const orderId = params.row.orderID;
									handleRowClick(orderId);
									setFilterOpen(false);
								}
							}}
							components={{
								ColumnMenu: CustomColumnMenu,
								Toolbar: () => <CustomDataGridExport fileName="Orders_data" />,
							}}
							onColumnOrderChange={(params) => {
								const modifiedColumn = modifyColumOrder(
									DATA_GRID_NAMES.orders_orders,
									params,
									formattedcolumn?.length > 0 ? formattedcolumn : columns
								);
								const newColumnConfigurations = {
									dataGridName: DATA_GRID_NAMES.orders_orders,
									columnArray: modifiedColumn,
								};
								dispatch(setColumnConfigurations(newColumnConfigurations));
							}}
							onSortModelChange={(params) => {
								const newColumnConfigurations = {
									dataGridName: DATA_GRID_NAMES.orders_orders,
									sortModel: params,
								};
								dispatch(setColumnSortModel(newColumnConfigurations));
							}}
							onColumnWidthChange={(params) => {
								const modifiedColumn = modifyColumnWidth(
									params,
									formattedcolumn?.length > 0 ? formattedcolumn : columns
								);
								const newColumnConfigurations = {
									dataGridName: DATA_GRID_NAMES.orders_orders,
									columnArray: modifiedColumn,
								};
								dispatch(setColumnConfigurations(newColumnConfigurations));
							}}
							onPinnedColumnsChange={(params) => {
								const newColumnConfigurations = {
									dataGridName: DATA_GRID_NAMES.orders_orders,
									pinnedColumns: params,
								};
								dispatch(setPinnedColumns(newColumnConfigurations));
							}}
						/>
						{expand && (
							<RightSidebar expanded={expand} setExpanded={setExpand}>
								<OrderSidebarForm
									setExpand={setExpand}
									editableRow={editableRow}
									setEditableRow={setEditableRow}
									entityMetadata={entityMetadata}
									refetchEntity={refetchEntity}
									entityLoad={entityLoad}
									fetchOrders={fetchOrders}
									currencySymbol={currencySymbol}
								/>
							</RightSidebar>
						)}
					</Box>
					<Card sx={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
						<CardContent sx={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: "auto" }}>
							<Typography variant="h5" sx={{ color: "rgba(0, 0, 0, 0.6)", marginRight: "10px" }}>
								Total Quantity
							</Typography>
							<Typography
								variant="h5"
								sx={{
									fontWeight: "bold",
									marginRight: "80px",
								}}>
								{`${parseFloat(total.totalQuantity.toFixed(2))}`}
							</Typography>
						</CardContent>
						<CardContent sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
							<Typography variant="h5" sx={{ color: "rgba(0, 0, 0, 0.6)", marginRight: "10px" }}>
								Total Value
							</Typography>
							<Typography
								variant="h5"
								sx={{
									fontWeight: "bold",
									marginRight: "80px",
								}}>
								{`${currencySymbol} ${parseFloat(total.totalValue.toFixed(2))}`}
							</Typography>
						</CardContent>
					</Card>
				</>
			) : (
				<OrdersDetailPage
					orderItemData={selectedRow.orderRow}
					setSelectedRow={setSelectedRow}
					setDisplayTopMenu={setDisplayTopMenu}
					fetchOrders={fetchOrders}
					currencySymbol={currencySymbol}
				/>
			)}

			{expandColumn && (
				<RightSidebar expanded={expandColumn} setExpanded={setExpandColumn}>
					<ColumnModifierSidebar
						setExpanded={setExpandColumn}
						columns={columns}
						localString={DATA_GRID_NAMES.orders_orders}
					/>
				</RightSidebar>
			)}
		</Box>
	);
};
export default OrdersTableV2;
