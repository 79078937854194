import { CircularProgress } from "@mui/material";
import GoogleMapReact from "google-map-react";
import moment from "moment";
import "moment-timezone";
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { TIMELINE } from "../../../@types";
import { DEFAULT_TIMEZONE, TIME_SHOW_FORMAT } from "../../../constants";
import { mapOptions, mapStyles } from "../../../utils";
import axios from "../../../utils/axios";
import useAdminInfo from "../../useAdminInfo";

const center = { lat: 20.5937, lng: 78.9629 };
const zoom = 5;

const polyMarkerColorGeofence = "#3FC097";
const polyMarkerColorSite = "#243140";
const SITE_MARKER_COLOR = "#243140";
const polygonOptionsGeofence = {
	strokeColor: polyMarkerColorGeofence,
	strokeOpacity: 0.5,
	strokeWeight: 2,
	fillColor: polyMarkerColorGeofence,
	fillOpacity: 0.15,
};

const polygonOptionsSite = {
	strokeColor: polyMarkerColorSite,
	strokeOpacity: 0.5,
	strokeWeight: 2,
	fillColor: polyMarkerColorSite,
	fillOpacity: 0.15,
};

type Props = {
	timeLineData: TIMELINE;
	employeeID: number | undefined;
	showClient: boolean;
	showSite: boolean;
	showAllPoints: boolean;
	showTravelingPoints: boolean;
	showFilteredLocations: boolean;
	visitClickedState: number;
	setVisitClickedState: Dispatch<SetStateAction<number>>;
	geofencesData: any;
	showGeofences: any;
	attendanceRestrictionsData: any;
	showAttendanceRestrictions: any;
};

let visitsMarkers: any[] = [];
let pathLines = [];
let visitCounter = 0;
let allLocationsMarkers: any = [];
let travelPath: any = [];
let travelMarkers: any = [];
let markerInfoWindows: any = [];
let filteredLocationMarkers: any = [];
let visitClicked = -1;
let tasksInfoWindows: any = [];
let visitedClientMarkers: any = [];
let clientMarkers: any = [];
let visitedSiteMarkers: any = [];
let siteMarkers: any = [];
let clients: any = [];
let geofences: any = [];
let geofencesMarkers: any = {};
let attendanceRestrictions: any = [];
let attendanceRestrictionsMarkers: any = {};
let Popup: any;

const icons = {
	visit: {
		name: "visit",
		icon: "/images/blueMarker.png",
	},
	visit2: {
		name: "visit",
		icon: {
			path: "M 125,5 155,90 245,90 175,145 200,230 125,180 50,230 75,145 5,90 95,90 z",
			fillColor: "yellow",
			fillOpacity: 0.8,
			scale: 1,
			strokeColor: "gold",
			strokeWeight: 2,
		},
	},
	visit3: {
		name: "visit",
		icon: {
			path: "M32 18.451l-16-12.42-16 12.42v-5.064l16-12.42 16 12.42zM28 18v12h-8v-8h-8v8h-8v-12l12-9z",
			scale: 1,
			strokeWeight: 2,
			offset: "100%",
		},
	},
	visit4: {
		name: "visit",
		icon: "/images/blueMarker.png",
	},
	travel: {
		name: "travel",
		icon: "https://www.google.com/intl/en_us/mapfiles/ms/micons/pink-dot.png",
	},
	filtered: {
		name: "filtered",
		icon: "https://www.google.com/intl/en_us/mapfiles/ms/micons/yellow-dot.png",
	},
	settling: {
		name: "settling into visit",
		icon: "https://www.google.com/intl/en_us/mapfiles/ms/micons/green-dot.png",
	},
	visitedClient: {
		name: "Client",
		icon: "/images/visitedClientMarker.png",
	},
	client: {
		name: "Client",
		icon: "/images/clientMarker.png",
	},
	visitedSite: {
		name: "Sites",
		icon: "/images/visitedSiteMarker.png",
	},
	site: {
		name: "Sites",
		icon: "/images/siteMarker.png",
	},
	signIn: {
		name: "SignIn icon",
		icon: "/images/markerGreen.png",
	},
};

const TimeLineMap: FC<Props> = ({
	timeLineData,
	employeeID,
	showClient,
	showSite,
	showAllPoints,
	showTravelingPoints,
	showFilteredLocations,
	visitClickedState,
	setVisitClickedState,
	geofencesData,
	showGeofences,
	attendanceRestrictionsData,
	showAttendanceRestrictions,
}) => {
	const [map, setMap] = useState<any>(null);
	const [maps, setMaps] = useState<any>(null);
	const { tz } = useAdminInfo();

	// site related
	const genMarker = (maps: any, center: any, name: string, siteID: string) => {
		var markerIcon2 = {
			url: "/images/siteMarker.png",
			scaledSize: new maps.Size(25, 25),
			labelOrigin: new maps.Point(20, -10),
			anchor: new maps.Point(12.5, 13),
		};

		return new maps.Marker({
			position: center,
			label: {
				text: name,
				color: "#000000",
				fontSize: "16px",
			},
			id: siteID,
			draggable: false,
			icon: markerIcon2,
		});
	};

	const genCircle = (maps: any, center: any, radius: number) =>
		new maps.Circle({
			strokeColor: SITE_MARKER_COLOR,
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor: SITE_MARKER_COLOR,
			fillOpacity: 0.35,
			center: center,
			radius: radius || 200,
			editable: false,
			draggable: false,
		});

	const zoomToSite = (map: any, maps: any, location: any) => {
		var bounds = new maps.LatLngBounds();
		bounds.extend(location);

		map.fitBounds(bounds);
		map.setZoom(17);
	};

	const createPopupClass = (maps: any) => {
		/**
		 * A customized popup on the map.
		 * @param {!google.maps.LatLng} position
		 * @param {!Element} content The bubble div.
		 * @constructor
		 * @extends {google.maps.OverlayView}
		 */
		function Popup(this: any, position: any, content: any) {
			this.position = position;

			content.classList.add("popup-bubble");

			var bubbleAnchor = document.createElement("div");
			bubbleAnchor.classList.add("popup-bubble-anchor");
			bubbleAnchor.appendChild(content);

			this.containerDiv = document.createElement("div");
			this.containerDiv.classList.add("popup-container");
			this.containerDiv.appendChild(bubbleAnchor);

			maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
		}

		Popup.prototype = Object.create(maps.OverlayView.prototype);

		Popup.prototype.onAdd = function () {
			this.getPanes().floatPane.appendChild(this.containerDiv);
		};

		Popup.prototype.setText = function (text: string) {
			this.containerDiv.childNodes[0].childNodes[0].innerHTML = text;
		};

		Popup.prototype.onRemove = function () {
			if (this.containerDiv.parentElement) {
				this.containerDiv.parentElement.removeChild(this.containerDiv);
			}
		};

		Popup.prototype.draw = function () {
			var divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

			var display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? "block" : "none";

			if (display === "block") {
				this.containerDiv.style.left = divPosition.x + "px";
				this.containerDiv.style.top = divPosition.y + "px";
			}
			if (this.containerDiv.style.display !== display) {
				this.containerDiv.style.display = display;
			}
		};

		return Popup;
	};

	const plotClientMarkers = (map: any) => {
		visitedClientMarkers.forEach((m: any) => {
			m.setMap(map);
		});

		clientMarkers.forEach((m: any) => {
			m.setMap(map);
		});
	};

	const plotSiteMarkers = (map: any) => {
		visitedSiteMarkers.forEach((m: any) => {
			m.setMap(map);
		});
		siteMarkers.forEach((m: any) => {
			m.setMap(map);
		});
	};

	const hideSiteMarkers = () => {
		visitedSiteMarkers.forEach((m: any) => {
			m.setMap(null);
		});
		siteMarkers.forEach((m: any) => {
			m.setMap(null);
		});
	};

	const hideClientMarkers = () => {
		visitedClientMarkers.forEach((m: any) => {
			m.setMap(null);
		});

		clientMarkers.forEach((m: any) => {
			m.setMap(null);
		});
	};

	const calcHighestPointOfPolygon = (path: any, maps: any) => {
		var bounds = new maps.LatLngBounds();

		for (var i = 0; i < path.length; i++) {
			bounds.extend(path[i]);
		}

		return bounds.getCenter();
	};

	const zoomToPolygon = useCallback((locations: any, maps: any, map: any) => {
		var bounds = new maps.LatLngBounds();
		locations.map((o: any) => bounds.extend(o));
		map.fitBounds(bounds);
	}, []);

	const addPolygon = useCallback(
		(polygon: any, site: any, maps: any, map: any) => {
			var center = calcHighestPointOfPolygon(polygon.getPath().getArray(), maps);
			var iDiv = document.createElement("div");
			var node = document.createTextNode(site.name);
			//node.id = 'textContent';
			iDiv.append(node);
			var popup = new Popup(center, iDiv);
			popup.setMap(map);

			popup.containerDiv.addEventListener("click", () => {
				zoomToPolygon(polygon.getPath().getArray(), maps, map);
				polygon.setMap(map);
			});

			//used for state management
			return { polygon: polygon, infowindow: popup, site: site };
		},
		[zoomToPolygon]
	);

	useEffect(() => {
		if ((showClient || showSite) && clients.length === 0 && map) {
			const centerLocation = map.getCenter();
			// const zoomLevel = map.getZoom();

			// const metersPerPx = (156543.03392 * Math.cos((centerLocation.lat() * Math.PI) / 180)) / Math.pow(2, zoomLevel);
			// const totalWidth = window.innerWidth / 2;

			// let totalMetersInScreen = metersPerPx * totalWidth;

			// if (totalMetersInScreen < 2000) {
			// 	totalMetersInScreen = 5000;
			// }

			const toSend = {
				latitude: centerLocation.lat(),
				longitude: centerLocation.lng(),
				employeeID: employeeID,
				// radius: totalMetersInScreen / 1000,
				radius: 30000, //radius is in meters
			};

			axios
				.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/clients/getNearbyClients`, JSON.stringify(toSend), {
					headers: {
						"Content-Type": "application/json",
					},
				})
				.then((res) => {
					const clientsFetched = res.data.clients;
					clients = clientsFetched;
					const visitedClients = timeLineData?.tasks.map((o) => {
						if (o && o.clientInfo && o.clientInfo.employeeID) {
							if (o.clientInfo.employeeID > 0) {
								return o.clientInfo.clientID;
							}
						}
					});

					const visitedSites = timeLineData?.tasks.map((o) => {
						if (o && o.clientInfo) {
							if (o.clientInfo.employeeID == -2) {
								return o.clientInfo.clientID;
							}
						}
					});

					clientsFetched.map((client: any) => {
						if (client.employeeID == -2) {
							if (visitedSites.indexOf(client.clientID) > -1) {
								const m = new google.maps.Marker({
									position: new google.maps.LatLng(parseFloat(client.latitude), parseFloat(client.longitude)),
									icon: icons.visitedSite.icon,
									title: "Site : " + client.clientName,
									zIndex: 1,
								});
								visitedSiteMarkers.push(m);
							} else {
								const m = new google.maps.Marker({
									position: new google.maps.LatLng(parseFloat(client.latitude), parseFloat(client.longitude)),
									icon: icons.site.icon,
									title: "Site : " + client.clientName,
									zIndex: 1,
								});
								siteMarkers.push(m);
							}
						} else {
							//in all other cases it's a client - either visible just to this employee, or to everybody, or to this employee's team....
							if (visitedClients.indexOf(client.clientID) > -1) {
								const m = new google.maps.Marker({
									position: new google.maps.LatLng(parseFloat(client.latitude), parseFloat(client.longitude)),
									icon: icons.visitedClient.icon,
									title: "Client : " + client.clientName,
									zIndex: 1,
								});
								visitedClientMarkers.push(m);
							} else {
								const m = new google.maps.Marker({
									position: new google.maps.LatLng(parseFloat(client.latitude), parseFloat(client.longitude)),
									icon: icons.client.icon,
									title: "Client : " + client.clientName,
									zIndex: 1,
								});
								clientMarkers.push(m);
							}
						}
					});

					if (showClient) {
						plotClientMarkers(map);
					}
					if (showSite) {
						plotSiteMarkers(map);
					}
				})
				.catch((e) => console.error(e));
		} else if (clients.length !== 0 && map) {
			if (showClient) {
				plotClientMarkers(map);
			} else {
				hideClientMarkers();
			}
			if (showSite) {
				plotSiteMarkers(map);
			} else {
				hideSiteMarkers();
			}
		}
	}, [showClient, map, employeeID, showSite, timeLineData?.tasks]);

	useEffect(() => {
		if (showAllPoints && map) {
			allLocationsMarkers.forEach((l: any) => {
				if (visitClicked === -1 || l.travelIndex === visitClicked) {
					l.setMap(map);
				} else {
					l.setMap(null);
				}
			});
		} else {
			allLocationsMarkers.map(function (l: any) {
				l.setMap(null);
			});
		}
	}, [map, showAllPoints]);

	useEffect(() => {
		if (showGeofences && map) {
			geofencesData.map((u: any) => {
				if (u["polyline"]) {
					geofences.push({
						geofenceID: u.geofenceID,
						companyID: u.companyID,
						name: u.name,
						polyline: u.polyline,
					});
				}
			});

			geofences.forEach(function (region: any) {
				var decodedPath = maps.geometry.encoding.decodePath(region.polyline);
				const pOpts = Object.assign(polygonOptionsGeofence, { paths: decodedPath, id: region.geofenceID });
				const polygon = new maps.Polygon(pOpts);
				geofencesMarkers[region.geofenceID] = addPolygon(polygon, region, maps, map);

				maps.event.addListener(polygon, "click", function () {
					zoomToPolygon(polygon.getPath().getArray(), maps, map);
				});
				polygon.setMap(map);
			});
		} else {
			Object.keys(geofencesMarkers).forEach((key) => {
				const { polygon, infowindow } = geofencesMarkers[key];
				polygon.setMap(null);
				infowindow.setMap(null);
			});

			geofencesMarkers = {};
			geofences = [];
		}
	}, [map, geofencesData, showGeofences]);

	useEffect(() => {
		if (showAttendanceRestrictions && map) {
			attendanceRestrictionsData.map((u: any) => {
				if (u["polyline"]) {
					attendanceRestrictions.push({
						lat: u.latitude,
						lng: u.longitude,
						clientID: u.clientID,
						name: u.clientName,
						radius: u.radius,
						polyline: u.polyline,
					});
				} else {
					attendanceRestrictions.push({
						lat: u.latitude,
						lng: u.longitude,
						clientID: u.clientID,
						name: u.clientName,
						radius: u.radius,
					});
				}
			});

			attendanceRestrictions.forEach(function (region: any) {
				if (region.polyline) {
					var decodedPath = maps.geometry.encoding.decodePath(region.polyline);
					const pOpts = Object.assign(polygonOptionsSite, { paths: decodedPath, id: region.clientID });
					const polygon = new maps.Polygon(pOpts);
					attendanceRestrictionsMarkers[region.clientID] = addPolygon(polygon, region, maps, map);

					maps.event.addListener(polygon, "click", function () {
						zoomToPolygon(polygon.getPath().getArray(), maps, map);
					});
					polygon.setMap(map);
				} else {
					const center = new maps.LatLng(region.lat, region.lng);
					const marker = genMarker(maps, center, region.name.replace(/(\r\n\t|\n|\r\t)/gm, ""), region.clientID);
					const circle = genCircle(maps, { lat: region.lat, lng: region.lng }, region.radius);

					attendanceRestrictionsMarkers[region.clientID] = {
						marker: marker,
						circle: circle,
						client: location,
					};
					circle.setMap(map);
					marker.setMap(map);

					maps.event.addListener(marker, "click", function () {
						zoomToSite(map, maps, center);
					});
				}
			});
		} else {
			Object.keys(attendanceRestrictionsMarkers).forEach((key) => {
				const { polygon, infowindow } = attendanceRestrictionsMarkers[key];
				if (polygon) {
					polygon.setMap(null);
					infowindow.setMap(null);
				} else {
					const { marker, circle } = attendanceRestrictionsMarkers[key];
					marker.setMap(null);
					circle.setMap(null);
				}
			});

			attendanceRestrictionsMarkers = {};
			attendanceRestrictions = [];
		}
	}, [map, maps, showAttendanceRestrictions, attendanceRestrictionsData]);

	useEffect(() => {
		if (showTravelingPoints && map) {
			travelMarkers.forEach((l: any) => {
				if (visitClicked === -1 || l.travelIndex === visitClicked) {
					l.setMap(map);
				} else {
					l.setMap(null);
				}
			});
		} else {
			travelMarkers.map(function (l: any) {
				l.setMap(null);
			});
		}
	}, [map, showTravelingPoints]);

	useEffect(() => {
		if (showFilteredLocations) {
			filteredLocationMarkers.forEach((l: any) => {
				if (visitClicked === -1 || l.travelIndex === visitClicked) {
					l.setMap(map);
				} else {
					l.setMap(null);
				}
			});
		} else {
			filteredLocationMarkers.forEach((m: any) => {
				m.setMap(null);
			});
		}
	}, [map, showFilteredLocations]);

	const closeAllInfoWindowsGlobal = (indexParam: number | null) => {
		markerInfoWindows.forEach((window: any, index: number | null) => {
			if (index !== indexParam) {
				window.close();
			}
		});
	};

	const unhighlightAllPath = useCallback(() => {
		setTimeout(function () {
			closeAllInfoWindowsGlobal(null);

			travelPath.forEach((travel: any) => {
				travel.setOptions({
					strokeColor: "#FF0000",
					strokeOpacity: 0.6,
					strokeWeight: 4,
					zIndex: 200,
				});
			});
			visitClicked = -1;
			setVisitClickedState(visitClicked);
		}, 50);
	}, [setVisitClickedState]);

	const highlightThisPath = useCallback(
		(m: any) => {
			const index = m.label_less;
			if (isNaN(index)) {
				return;
			}
			const visitIndex = index - 1;
			if (visitIndex == visitClicked) {
				unhighlightAllPath();
				return;
			}

			visitClicked = visitIndex;
			setVisitClickedState(visitClicked);
			closeAllInfoWindowsGlobal(visitIndex);
			const pathIndex = visitIndex;
			travelPath.forEach((travel: any, i: number) => {
				if (i == pathIndex) {
					travel.setOptions({
						strokeColor: "#4e1ede",
						strokeOpacity: 1.0,
						strokeWeight: 4,
						zIndex: 400,
					});
				} else {
					travel.setOptions({
						strokeColor: "#FF0000",
						strokeOpacity: 0.2,
						strokeWeight: 4,
						zIndex: 200,
					});
				}
			});
		},
		[setVisitClickedState, unhighlightAllPath]
	);

	const openInfoWindows = (direction: any, infoWindowList: any, map: any) => {
		closeAllInfoWindowsGlobal(null);
		if (direction == "left") {
			if (visitClicked <= 0) {
				visitClicked = infoWindowList.length;
			}
			visitClicked = visitClicked - 1;
			infoWindowList[visitClicked].open(map);
			map.panTo(infoWindowList[visitClicked].getPosition());
		} else {
			if (visitClicked >= infoWindowList.length || visitClicked == infoWindowList.length - 1) {
				visitClicked = -1;
			}
			visitClicked = visitClicked + 1; // for right
			infoWindowList[visitClicked].open(map);
			map.panTo(infoWindowList[visitClicked].getPosition());
		}
		setVisitClickedState(visitClicked);
	};

	useEffect(() => {
		if (visitClickedState !== -1) {
			closeAllInfoWindowsGlobal(null);
			if (markerInfoWindows[visitClickedState]) {
				markerInfoWindows[visitClickedState]?.open(map);
				map.panTo(markerInfoWindows[visitClickedState].getPosition());
			}
		}
	}, [map, visitClickedState]);

	const lControl = (left: any, id: any, map: any) => {
		const controlUI = document.createElement("div");
		controlUI.style.backgroundColor = "#fff";
		controlUI.style.border = "2px solid #fff";
		controlUI.style.borderRadius = "3px";
		controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
		controlUI.style.cursor = "pointer";
		controlUI.style.marginBottom = "40px";
		controlUI.style.marginLeft = "10px";
		controlUI.style.textAlign = "center";
		controlUI.title = "Left";
		controlUI.id = id;

		left.appendChild(controlUI);
		const controlText = document.createElement("div");
		controlText.style.color = "rgb(25,25,25)";
		controlText.style.fontFamily = "Roboto,Arial,sans-serif";
		controlText.style.fontSize = "16px";
		controlText.style.display = "flex";
		controlText.style.lineHeight = "30px";
		controlText.style.padding = "5px";
		controlText.innerHTML = `<img src="/images/left.svg" width="20px" height="20px">`;
		controlText.classList.add("leftButtonTooltip");
		controlUI.appendChild(controlText);
		controlUI.addEventListener("click", function () {
			openInfoWindows("left", markerInfoWindows, map);
		});
	};

	const rControl = (right: any, id: any, map: any) => {
		const controlUI = document.createElement("div");
		controlUI.style.backgroundColor = "#fff";
		controlUI.style.border = "2px solid #fff";
		controlUI.style.borderRadius = "3px";
		controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
		controlUI.style.cursor = "pointer";
		controlUI.style.marginBottom = "40px";
		controlUI.style.marginRight = "10px";
		controlUI.style.textAlign = "center";
		controlUI.title = "Right";
		controlUI.id = id;
		right.appendChild(controlUI);

		const controlText = document.createElement("div");
		controlText.style.color = "rgb(25,25,25)";
		controlText.style.fontFamily = "Roboto,Arial,sans-serif";
		controlText.style.fontSize = "16px";
		controlText.style.display = "flex";
		controlText.style.lineHeight = "30px";
		controlText.style.padding = "5px";
		controlText.innerHTML = `<img src="/images/right.png" width="20px" height="20px">`;
		controlText.classList.add("rightButtonTooltip");
		controlUI.appendChild(controlText);
		controlUI.addEventListener("click", function () {
			openInfoWindows("right", markerInfoWindows, map);
		});
	};

	const googleMapFeatures = (map: any, maps: any) => {
		const left = document.createElement("div");
		const right = document.createElement("div");
		lControl(left, "left2", map);
		rControl(right, "right2", map);
		map.controls[maps.ControlPosition.LEFT_CENTER].push(left);
		map.controls[maps.ControlPosition.RIGHT_CENTER].push(right);
	};

	const drawInfoWindow = (locArray: any, map: any) => {
		for (let k = 0; k < locArray.length; k++) {
			let contentString = `<div class="info_box">
                    <span>Point ${k + 1}</span>
                    <p>${locArray[k]["label_hidden"]}</p>
                </div>`;

			//create info window
			//add signed In and Signed Out text to the infoWindow
			if (locArray[k]["id"] == "login") {
				contentString += `
                        <div class="punch_status">
                            <div class="punched_in"></div>
                            <span>Punched In</span>
                        </div>
                    `;
			} else if (locArray[k]["id"] == "logout") {
				contentString += `
                        <div class="punch_status">
                            <div class="punched_out"></div>
                            <span>Punched Out</span>
                        </div>
                    `;
			}

			const infoWindow = new google.maps.InfoWindow({
				content: contentString,
				pixelOffset: new google.maps.Size(0, -40),
				maxWidth: 350,
			});
			infoWindow.setPosition(locArray[k].getPosition());
			locArray[k].addListener("click", function () {
				infoWindow.open(map);
			});

			markerInfoWindows[k] = infoWindow;
		}
	};

	const generateFormInfoString = (task: any) => {
		const content =
			'<div style="text-align:left">' +
			"<b>Form name:</b>" +
			task.formDescription +
			"<br>" +
			"<b>Uploaded at</b>: " +
			moment(task.timestamp)
				.tz(tz || DEFAULT_TIMEZONE)
				.format("HH:mm") +
			"<br />" +
			"<a href=/form/responses/" +
			task.formID +
			'>More Details <i class="fa fa-external-link" ></i ></a > <br />' +
			"</div>";
		return content;
	};

	const generatePhotoInfoString = (task: any) => {
		const url = task.photoURL.toString();
		const content =
			'<div style="float:left">' +
			'<a target="_blank" href="https://s3.ap-south-1.amazonaws.com/upload.sensestaff.com/' +
			url +
			'">' +
			'<img style="width:90px" src = "https://s3.ap-south-1.amazonaws.com/upload.sensestaff.com/' +
			task.photoURL +
			'" > ' +
			"</a>" +
			"</div>" +
			'<div style="float:right; padding: 10px;"><b>Uploaded At</b><br />' +
			moment(task.timestamp)
				.tz(tz || DEFAULT_TIMEZONE)
				.format("HH:mm") +
			"<br />" +
			"<b>Description</b><br />" +
			task.description +
			"</div>";
		return content;
	};

	//this function generates infoWindow content for meetings/tasks markers
	const generateMeetingInfoString = (task: any) => {
		let taskStatus, type;
		// let photos = 0, forms = 0;
		let startTime, endTime, expectedStart, expectedEnd;

		//**Note** decide the task status here
		if (task.checkout != 0) {
			taskStatus = `<p style="color:green">Completed</p>`;
		} else if (task.checkin != 0 && task.checkout == 0) {
			taskStatus = `<p style="color:grey">In progress</p>`;
		} else {
			taskStatus = `<p style="color:red">Not Started</p>`;
		}
		//**Note** decide the type here
		if (task.clientInfo) {
			type = "Meeting";
			expectedStart = "--";
			expectedEnd = "--";
		} else if (task.expectedStart != "Invalid date" && task.expectedEnd != "Invalid date") {
			type = "Task";
			expectedStart = task.expectedStart;
			expectedEnd = task.expectedEnd;
		} else {
			type = "Task";
			expectedStart = "not given";
			expectedEnd = "not given";
		}
		if (task.checkin && task.checkin != 0) {
			startTime = moment(task.checkin)
				.tz(tz || DEFAULT_TIMEZONE)
				.format("HH:mm");
		} else {
			startTime = "not yet started";
		}
		if (task.checkout && task.checkout != 0) {
			endTime = moment(task.checkout)
				.tz(tz || DEFAULT_TIMEZONE)
				.format("HH:mm");
		} else {
			endTime = "not yet ended";
		}

		//header
		let content = "";
		if (type == "Meeting" && task.adminAssigned == 1) {
			content = `<p class="task_info">Admin assigned ` + type + " with " + task.clientInfo.clientName + "</p>";
		} else if (type == "Meeting" && task.adminAssigned == 0) {
			content = `<p class="task_info">` + type + " with " + task.clientInfo.clientName + "</p>";
		} else if (type != "Meeting" && task.adminAssigned == 1) {
			content = `<p class="task_info">Admin assigned ` + type + "</p>";
		} else if (type == "Meeting" && task.adminAssigned == 2) {
			content = `<p class="task_info">Auto ` + type + " with " + task.clientInfo.clientName + "</p>";
		} else {
			content = `<p class="task_info">` + type + "</p>";
		}

		//table
		if (task.adminAssigned == 1 && type != "Meeting") {
			content +=
				`<table class="table_bordered">` +
				"<tr>" +
				"<th>Start Time</th>" +
				"<th>Expected Start time</th>" +
				"<th>End time</th>" +
				"<th>Expected end time</th>" +
				"<th>Status</th>" +
				"</tr>" +
				"<tr>" +
				"<td>" +
				(startTime ? startTime : "NA") +
				"</td>" +
				"<td>" +
				(expectedStart ? expectedStart : "NA") +
				"</td>" +
				"<td>" +
				(endTime ? endTime : "NA") +
				"</td>" +
				"<td>" +
				(expectedEnd ? expectedEnd : "NA") +
				"</td>" +
				"<td>" +
				taskStatus +
				"</td>" +
				"</tr>" +
				"</table>";
		} else if (task.adminAssigned == 0 && type != "Meeting") {
			content +=
				`<table class="table_bordered">` +
				"<tr>" +
				"<th>Start Time</th>" +
				"<th>End time</th>" +
				"<th>Status</th>" +
				"</tr>" +
				"<tr>" +
				"<td>" +
				(startTime ? startTime : "NA") +
				"</td>" +
				"<td>" +
				(endTime ? endTime : "NA") +
				"</td>" +
				"<td>" +
				taskStatus +
				"</td>" +
				"</tr>" +
				"</table>";
		} else if (type == "Meeting") {
			content +=
				`<table class="table_bordered">` +
				"<tr>" +
				"<th>Start Time</th>" +
				"<th>End time</th>" +
				"<th>Status</th>" +
				"</tr>" +
				"<tr>" +
				"<td>" +
				(startTime ? startTime : "NA") +
				"</td>" +
				"<td>" +
				(endTime ? endTime : "NA") +
				"</td>" +
				"<td>" +
				taskStatus +
				"</td>" +
				"</tr>" +
				"</table>";
		}
		//add task description:
		if (task.taskDescription)
			content +=
				`<div><span class="description_label">Task Description:</span><span class="description_value">` +
				task.taskDescription +
				`</span></div>`;
		return content;
	};

	//function to plot tasks:
	const createTasksData = (tasks: any, map: any, maps: any) => {
		const tasksMarkers: google.maps.Marker[] = [];

		const markerIcon = {
			url: "/images/genericMarker.svg",
			scaledSize: new google.maps.Size(50, 50),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(20, 40),
			labelOrigin: new google.maps.Point(25, 20),
		};

		//loop through tasks and create markers then create InfoWindows
		tasks.forEach((task: any) => {
			let labelText;
			if (task.type == "meeting") {
				if (task.clientInfo) {
					labelText = "M";
				} else {
					labelText = "T";
				}
			} else if (task.type == "photo") {
				labelText = "P";
			} else {
				labelText = "F";
			}

			const markerLatLng = new google.maps.LatLng(task.latitude, task.longitude);

			const marker = new google.maps.Marker({
				animation: google.maps.Animation.DROP,
				position: markerLatLng,
				icon: markerIcon,
				label: {
					text: labelText,
					color: "#eb3a44",
					fontSize: "14px",
					fontWeight: "bold",
				},
				map: map,
			});
			tasksMarkers.push(marker);
			//construct infoWindow
			let content;
			if (task.type == "photo") {
				content = generatePhotoInfoString(task);
			} else if (task.type == "form") {
				content = generateFormInfoString(task);
			} else {
				content = generateMeetingInfoString(task);
			}
			const infowindow = new maps.InfoWindow({
				content: content,
				maxWidth: 350,
			});

			tasksInfoWindows.push(infowindow);
			marker.addListener("click", () => {
				tasksInfoWindows.map((t: any) => {
					t.close();
				});
				infowindow.open(map, marker);
			});
		});
		return tasksMarkers;
	};

	const handleApiLoaded = useCallback(
		(map: any, maps: any) => {
			setMap(map);
			setMaps(maps);

			Popup = createPopupClass(maps);
			map.setOptions({ styles: mapStyles });

			const visitsLocal = timeLineData.visits;
			const globalBounds = new maps.LatLngBounds();
			visitCounter = 0;
			visitsLocal.forEach((visit, visitIndex) => {
				/**
				 * if by any chance there is not lat/lng on visit
				 * if type missing data, don"t do anything
				 */
				const visitCenter = visit.bestLocation;
				if (visit.missing == 1 || !visitCenter?.lat || !visitCenter?.lon) {
					return;
				}

				const events = visit.events && Array.isArray(visit.events) ? visit.events : [];
				const punchInEvent = events.find((event) => event.eventTypeID === 8);
				const punchedOutEvent = events.find((event) => event.eventTypeID === 9);

				const allMarkerPoints = [];

				allMarkerPoints.push({
					...visit.bestLocation,
					type: punchInEvent ? "punchIn" : punchedOutEvent ? "punchOut" : "visit",
					startTime: visit.startTime,
					endTime: visit.endTime,
				});

				allMarkerPoints.forEach((marker) => {
					visitCounter++;
					const center = new maps.LatLng(+marker.lat!, +marker.lon!);
					globalBounds.extend(center);

					const markerIcon = {
						url: "/images/genericMarkerBlue.png",
						scaledSize: new maps.Size(50, 50),
						origin: new maps.Point(0, 0),
						anchor: new maps.Point(20, 40),
						labelOrigin: new maps.Point(25, 20),
					};

					const m = new maps.Marker({
						map: map,
						position: center,
						label_hidden:
							moment(marker.startTime)
								.tz(tz || DEFAULT_TIMEZONE)
								.format(TIME_SHOW_FORMAT) +
							" to " +
							moment(marker.endTime)
								.tz(tz || DEFAULT_TIMEZONE)
								.format(TIME_SHOW_FORMAT),
						label_less: visitCounter.toString(),
						label: {
							text: visitCounter.toString(),
							color: "black",
							fontSize: "14px",
							fontWeight: "bold",
						},
						title:
							moment(marker.startTime)
								.tz(tz || DEFAULT_TIMEZONE)
								.format(TIME_SHOW_FORMAT) +
							"-" +
							moment(marker.endTime)
								.tz(tz || DEFAULT_TIMEZONE)
								.format(TIME_SHOW_FORMAT),
						zIndex: 2,
						scale: 2,
						icon: markerIcon,
					});

					maps.event.addListener(m, "click", () => {
						highlightThisPath(m);
					});
					if (marker.type === "punchIn") {
						m.icon.url = "/images/genericMarkerGreen.png";
						m["id"] = "login";
					} else if (marker.type === "punchOut") {
						m.icon.url = "/images/genericMarkerRed.png";
						m["id"] = "logout";
					}

					visitsMarkers.push(m);

					const lineSymbol = {
						path: maps.SymbolPath.FORWARD_CLOSED_ARROW,
					};
					if (visitIndex != visitsLocal.length) {
						const nextVisit = visitsLocal[visitIndex + 1];
						if (nextVisit && nextVisit.missing == 0) {
							const nextLoc = nextVisit.bestLocation;
							if (!nextLoc?.lat || !nextLoc?.lon) {
								return;
							}
							const line = new maps.Polyline({
								path: [
									{
										lat: +visitCenter?.lat,
										lng: +visitCenter?.lon,
									},
									{
										lat: +nextLoc?.lat,
										lng: +nextLoc?.lon,
									},
								],
								icons: [
									{
										icon: lineSymbol,
										scale: 5,
										offset: "100%",
									},
								],
							});
							pathLines.push(line);
						}
					}
				});
			});

			visitsMarkers.map((m) => {
				m.setMap(map);
			});

			const tasksMarkers = createTasksData(timeLineData?.tasks ?? [], map, maps);
			tasksMarkers.map((m) => {
				m.setMap(map);
			});
			const uniqueLocations = {} as { [x: string]: TIMELINE["allLocations"] };

			timeLineData.allLocations.forEach((loc) => {
				if (!loc.lat || !loc.lon) {
					return;
				}
				const key = loc.lat + "," + loc.lon;
				if (key in uniqueLocations) {
					uniqueLocations[key].push(loc);
				} else {
					uniqueLocations[key] = [loc];
				}
			});

			Object.keys(uniqueLocations).forEach((key) => {
				const locs = uniqueLocations[key];
				const lat = +locs[0].lat!;
				const lon = +locs[0].lon!;
				let label = "";
				const ts: number[] = [];
				locs.forEach((loc) => {
					const tsM = moment(loc.timestamp, "YYYY-MM-DD HH:mm:ss").tz(tz || DEFAULT_TIMEZONE);
					label += tsM.format("HH:mm:ss") + " (" + loc.accuracy!.toFixed(0) + "m),";
					ts.push(tsM.valueOf());
				});

				const marker = new maps.Marker({
					position: new maps.LatLng(lat, lon),
					icon: icons.settling.icon,
					label: label,
					title: label,
					ts: ts, //this is used for filtering later on.....
				});

				allLocationsMarkers.push(marker);
			});

			var uniqueFilteredLocations = {};

			//all locations new code :
			if (timeLineData.filteredLocations) {
				timeLineData.filteredLocations.map((loc) => {
					const key = loc.lat + "," + loc.lon;
					if (key in uniqueFilteredLocations) {
						uniqueFilteredLocations[key].push(loc);
					} else {
						uniqueFilteredLocations[key] = [loc];
					}
				});
			}

			Object.keys(uniqueFilteredLocations).forEach(function (key) {
				const locs = uniqueFilteredLocations[key];
				const lat = parseFloat(locs[0].lat);
				const lon = parseFloat(locs[0].lon);
				let label = "";
				const ts: number[] = [];
				locs.forEach((loc: any) => {
					const tsM = moment(loc.timestamp, "YYYY-MM-DD HH:mm:ss").tz(tz || DEFAULT_TIMEZONE);
					label += loc.timestamp.split(" ")[1] + " (" + loc.accuracy.toFixed(1) + "m),";
					ts.push(tsM.valueOf());
				});
				const marker = new maps.Marker({
					position: new maps.LatLng(lat, lon),
					icon: icons.filtered.icon,
					label: label,
					title: label,
					ts: ts,
				});
				filteredLocationMarkers.push(marker);
			});

			timeLineData.travels.map((travel, travelIndex) => {
				if (!travel.polyline || !travel.polyline[0]) {
					return;
				}
				travel.polyline.forEach(function (poly) {
					const resultPoints = maps.geometry.encoding.decodePath(poly);
					const flightPath = new maps.Polyline({
						path: resultPoints,
						geodesic: true,
						strokeColor: "#FF0000",
						strokeOpacity: 0.6,
						strokeWeight: 4,
						zIndex: 200,
						map: map,
					});
					travelPath.push(flightPath);
					// addPathKm(resultPoints, travel)
				});
				travel.locations.map(function (point) {
					const m = new maps.Marker({
						position: new maps.LatLng(+point.lat!, +point.lon!),
						icon: icons.travel.icon,
						title: point.timestamp,
						label:
							moment(point.timestamp)
								.tz(tz || DEFAULT_TIMEZONE)
								.format("HH:mm:ss") +
							" (" +
							point.accuracy!.toFixed(0) +
							"m)",
						zIndex: 1,
						travelIndex: travelIndex, //record which travel a marker is associated with for later
					});
					travelMarkers.push(m);
				});

				const travelStart = moment(`${timeLineData.date} + " " + ${travel.startTime}`, "YYYY-MM-DD HH:mm")
					.tz(tz || DEFAULT_TIMEZONE)
					.valueOf();
				const travelEnd = moment(`${timeLineData.date} + " " + ${travel.endTime}`, "YYYY-MM-DD HH:mm")
					.tz(tz || DEFAULT_TIMEZONE)
					.valueOf();

				allLocationsMarkers.forEach((m: any) => {
					if (m.ts.find((t: any) => travelStart <= t && t <= travelEnd)) {
						m.travelIndex = travelIndex;
					}
				});

				filteredLocationMarkers.forEach((m: any) => {
					if (m.ts.find((t: any) => travelStart <= t && t <= travelEnd)) {
						m.travelIndex = travelIndex;
					}
				});
			});

			googleMapFeatures(map, maps);
			if (globalBounds?.ab?.lo !== 1) {
				map.fitBounds(globalBounds);
			}
			drawInfoWindow(visitsMarkers, map);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	useEffect(
		() => () => {
			visitsMarkers.map((m) => {
				m.setMap(null);
			});
			visitsMarkers = [];

			pathLines = [];
			visitCounter = 0;
			allLocationsMarkers = [];
			travelPath = [];
			travelMarkers = [];
			markerInfoWindows = [];
			filteredLocationMarkers = [];
			visitClicked = -1;
			setVisitClickedState(visitClicked);
			tasksInfoWindows = [];
			visitedClientMarkers = [];
			clientMarkers = [];
			visitedSiteMarkers = [];
			siteMarkers = [];
			clients = [];
		},
		[setVisitClickedState]
	);

	if (!process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY)
		return (
			<div className="small_circular-spinner">
				<CircularProgress />
			</div>
		);

	return (
		<div className="live_location_map">
			<GoogleMapReact
				bootstrapURLKeys={{
					key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY!,
					language: "en",
					region: "IN",
					libraries: ["places", "geometry", "drawing", "visualization"],
				}}
				options={mapOptions}
				yesIWantToUseGoogleMapApiInternals={true}
				defaultZoom={zoom}
				style={{ position: "relative", width: "100%", height: "100%" }}
				onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
				defaultCenter={center}></GoogleMapReact>
		</div>
	);
};

export default TimeLineMap;
