import { Switch, Typography, styled } from "@mui/material";
import { Stack } from "@mui/system";

const AntSwitch = styled(Switch)(({ theme }) => ({
	width: 28,
	height: 16,
	padding: 0,
	display: "flex",
	"&:active": {
		"& .MuiSwitch-thumb": {
			width: 15,
		},
		"& .MuiSwitch-switchBase.Mui-checked": {
			transform: "translateX(9px)",
		},
	},
	"& .MuiSwitch-switchBase": {
		padding: 2,
		"&.Mui-checked": {
			transform: "translateX(12px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#4e9cea",
			},
		},
		"&.Mui-disabled": {
			color: "#00000061",
			"& + .MuiSwitch-track": {
				backgroundColor: "#00000061",
			},
		},
	},
	"& .MuiSwitch-thumb": {
		boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
		width: 12,
		height: 12,
		borderRadius: 6,
		transition: theme.transitions.create(["width"], {
			duration: 200,
		}),
	},
	"& .MuiSwitch-track": {
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
		boxSizing: "border-box",
	},
}));

const CustomSwitch = (props: any) => {
	const { text, ...restProps } = props;
	return (
		<Stack direction="row" spacing={1} alignItems="center">
			<AntSwitch {...restProps} />
			<Typography sx={{ fontSize: "15px", color: props.isDefault ? "#00000061" : "" }}>{text}</Typography>
		</Stack>
	);
};

export default CustomSwitch;
