import {
	ALL_ROUTES_QUERY,
	DELETE_ROUTE_MUTATION,
	UPSERT_ROUTE_MUTATION,
	GET_TASKS,
	UPSERT_TASK,
	DELETE_TASK,
} from "./tasks";
import {
	GET_CLIENTS,
	GET_ALL_CLIENTS,
	GET_CLIENT_BY_ID,
	UPSERT_CLIENTS,
	DELETE_CLIENTS,
	GET_ALL_SITES,
} from "./sites_clients";
import { GET_SKUS, DELETE_SKUS, UPSERT_SKUS, UPSERT_SKUS_EXCEL } from "./orders";
import {
	GET_FORMS_BY_COMPANYID,
	GET_FORM_BY_TEMPLATE_ID,
	DELETE_FORM_TEMPLATE,
	UPSERT_FORM_TEMPLATE,
	GET_PARTIAL_FORMS,
	GET_FORMS_BY_COMPLETEDFORMID,
	GET_FORM_DATA,
} from "./forms";
import { GET_ALL_EMPLOYEES } from "./common";
import { ALL_DASHBOARD_USERS, GET_FE_STATUS } from "./dashboard";
import { GET_USERS } from "./users";
export {
	ALL_ROUTES_QUERY,
	DELETE_ROUTE_MUTATION,
	UPSERT_ROUTE_MUTATION,
	GET_CLIENTS,
	GET_ALL_CLIENTS,
	GET_CLIENT_BY_ID,
	GET_PARTIAL_FORMS,
	UPSERT_CLIENTS,
	DELETE_CLIENTS,
	GET_ALL_SITES,
	GET_SKUS,
	DELETE_SKUS,
	UPSERT_SKUS,
	UPSERT_SKUS_EXCEL,
	GET_FORMS_BY_COMPANYID,
	DELETE_FORM_TEMPLATE,
	UPSERT_FORM_TEMPLATE,
	GET_FORM_BY_TEMPLATE_ID,
	GET_TASKS,
	UPSERT_TASK,
	DELETE_TASK,
	GET_ALL_EMPLOYEES,
	ALL_DASHBOARD_USERS,
	GET_FE_STATUS,
	GET_FORMS_BY_COMPLETEDFORMID,
	GET_FORM_DATA,
	GET_USERS,
};
