//react
import Link from "next/link";
import { useRouter } from "next/router";
import { FC } from "react";
//mui
import BarChartIcon from "@mui/icons-material/BarChart";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import SmsIcon from "@mui/icons-material/Sms";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import { PRODUCT_ID, UNOLO_BIOMETRIC } from "../../constants";
import { useAdminCompanySettings } from "../useAdminEntitlements";

type Props = {
	subsMetadata: any;
};
const SettingSidebar: FC<Props> = ({ subsMetadata }) => {
	// const leavesV2Active = useAdminCompanySettings("isLeavesV2Enabled");
	//commented for now @Aditya need to check
	const leavesV2Active = true;
	const router = useRouter();
	const productId = (window && (localStorage.getItem(PRODUCT_ID) as "1" | "2" | "5" | "6" | "7" | "8" | "9")) || "2";
	const productIDinLocal = localStorage.getItem(PRODUCT_ID);
	const customEntityMigrationPhase = useAdminCompanySettings("customEntityMigrationPhase");

	return (
		<aside
			className={`settings-sidebar  ${productIDinLocal !== "1" && productIDinLocal !== "6" && !(customEntityMigrationPhase !== 1) ? "height2 " : ".height1"}`}>
			<nav
				style={{
					height: productIDinLocal !== "1" && productIDinLocal !== "6" ? "calc(100vh - 150px)" : "calc(100vh - 100px)",
				}}>
				<Link href="/settings" className={router.pathname === "/settings" ? "active" : ""}>
					<PersonOutlineOutlinedIcon />
					<p>Account</p>
				</Link>
				<Link href="/settings/apiKey" className={router.pathname?.includes("/settings/apiKey") ? "active" : ""}>
					<VpnKeyOutlinedIcon sx={{ transform: "rotate(45deg)" }} />
					<p>API Access Tokens</p>
				</Link>
				{productId !== UNOLO_BIOMETRIC && (
					<Link
						href="/settings/attendance"
						className={router.pathname?.includes("/settings/attendance") ? "active" : ""}>
						<CalendarMonthOutlinedIcon />
						<p>Attendance</p>
					</Link>
				)}
				{subsMetadata?.clients === 1 && (
					<Link href="/settings/clients" className={router.pathname?.includes("/settings/clients") ? "active" : ""}>
						<PeopleAltOutlinedIcon />
						<p>Clients</p>
					</Link>
				)}
				{(subsMetadata?.tasks === 1 || subsMetadata?.orders === 1) && (
					<Link href="/settings/communicationRules" className={router.pathname?.includes("/settings/communicationRules") ? "active" : ""}>
						<ContactMailIcon />
						<p>Communication Rules</p>
					</Link>
				)}
				{subsMetadata?.compliance === 1 && (
					<Link
						href="/settings/compliance"
						className={router.pathname?.includes("/settings/compliance") ? "active" : ""}>
						<CreditScoreOutlinedIcon />
						<p>Compliance</p>
					</Link>
				)}
				{subsMetadata?.noOfCustomFields !== 0 && (
					<Link
						href="/settings/custom-fields"
						className={router.pathname?.includes("/settings/custom-fields") ? "active" : ""}>
						<PlaylistAddIcon />
						<p>Custom Fields</p>
					</Link>
				)}

				{subsMetadata?.expenses === 1 && (
					<Link href="/settings/expenses" className={router.pathname?.includes("/settings/expenses") ? "active" : ""}>
						<PaymentsOutlinedIcon />
						<p>Expense</p>
					</Link>
				)}
				{subsMetadata?.integrations === 1 && (
					<Link
						href="/settings/integrations"
						className={router.pathname?.includes("/settings/integrations") ? "active" : ""}>
						<IntegrationInstructionsOutlinedIcon />
						<p>Integrations</p>
					</Link>
				)}

				{leavesV2Active && (
					<Link href="/settings/leaves" className={router.pathname?.includes("/settings/leaves") ? "active" : ""}>
						<DateRangeIcon />
						<p>Leaves</p>
					</Link>
				)}
				<Link
					href="/settings/public-holidays"
					className={router.pathname?.includes("/settings/public-holidays") ? "active" : ""}>
					<EventAvailableOutlinedIcon />
					<p>Public Holidays</p>
				</Link>
				{subsMetadata?.locationTracking === 1 && (
					<Link
						href="/settings/location-tracking"
						className={router.pathname?.includes("/settings/location-tracking") ? "active" : ""}>
						<RoomOutlinedIcon />
						<p>Location Tracking</p>
					</Link>
				)}
				{subsMetadata?.modules === 1 && (
					<Link href="/settings/modules" className={router.pathname?.includes("/settings/modules") ? "active" : ""}>
						<BarChartIcon />
						<p>Modules</p>
					</Link>
				)}
				{subsMetadata?.odometer === 1 && (
					<Link href="/settings/odo" className={router.pathname?.includes("/settings/odo") ? "active" : ""}>
						<SpeedOutlinedIcon />
						<p>Odometer</p>
					</Link>
				)}

				{subsMetadata?.orders === 1 && (
					<Link href="/settings/orders" className={router.pathname?.includes("/settings/orders") ? "active" : ""}>
						<LocalShippingOutlinedIcon />
						<p>Orders</p>
					</Link>
				)}
				
				{subsMetadata?.clients === 1 && (
					<Link href="/settings/sites" className={router.pathname?.includes("/settings/sites") ? "active" : ""}>
						<LocationCityOutlinedIcon />
						<p>Site Types</p>
					</Link>
				)}
				{subsMetadata?.sms === 1 && (
					<Link href="/settings/sms" className={router.pathname?.includes("/settings/sms") ? "active" : ""}>
						<SmsIcon />
						<p>SMS</p>
					</Link>
				)}
				{subsMetadata?.tasks === 1 && (
					<Link href="/settings/tasks" className={router.pathname?.includes("/settings/tasks") ? "active" : ""}>
						<TaskOutlinedIcon />
						<p>Tasks</p>
					</Link>
				)}
				
			</nav>
		</aside>
	);
};

export default SettingSidebar;
