import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

import userReducer from "../slices/userSlice";
import shiftReducer from "../slices/Attendance/shiftSlice";
import rosterReducer from "../slices/Attendance/rosterSlice";
import notificationReducer from "../slices/notificationSlice";
import attendanceStatusReducer from "../slices/Attendance/attendanceStatus";
import dataGridReducer from "../slices/dataGridSlice";
import subscriptionMetadataSlice from "../slices/subscriptionMetadataSlice";
const persistConfig = {
	key: "root", // key is required
	storage, // storage is the storage mechanism (e.g., local storage)
};
const persistedReducer = persistReducer(
	persistConfig,
	combineReducers({
		user: userReducer,
		shift: shiftReducer,
		roster: rosterReducer,
		notification: notificationReducer,
		attendanceStatus: attendanceStatusReducer,
		dataGrid: dataGridReducer,
		susbscriptionMetadata:subscriptionMetadataSlice
	})
);

export const store = configureStore({
	reducer: persistedReducer,
});
export const persistor = persistStore(store);

export type ROOT_STATE = ReturnType<typeof store.getState>;
export type APP_DISPATCH = typeof store.dispatch;
