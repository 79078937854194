import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import "moment-timezone";
import _ from "lodash";
import { ROSTER_TEMPLATE_TYPE, USER_ROSTER_TYPE } from "../../../@types";
import axios from "../../../utils/axios";

export const getRostersData = createAsyncThunk(
	"rosters/getRostersData",
	async ({ startDate, endDate }: { startDate: number; endDate: number }, thunkAPI) => {
		try {
			const response = await axios.get(
				`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/rosters?fromDate=${startDate}&endDate=${endDate}`
			);
			return response.data;
		} catch (error) {
			return thunkAPI.rejectWithValue("Error fetching rosters data");
		}
	}
);

const formatUserRostersData = (rosters: Record<string, USER_ROSTER_TYPE>[]): USER_ROSTER_TYPE[] => {
	const employeeIDs = Object.keys(rosters);
	return employeeIDs.map((employeeID) => ({ ...rosters[employeeID], employeeID, id: employeeID }));
};

const formatRostersData = (rosters: Record<string, ROSTER_TEMPLATE_TYPE>[]): ROSTER_TEMPLATE_TYPE[] => {
	const rostersTemplateIDs = Object.keys(rosters);
	const formattedData = rostersTemplateIDs.map((rosterTemplateID) => ({
		...rosters[rosterTemplateID],
		rosterTemplateID,
	}));
	formattedData.unshift(formattedData.splice(formattedData.length - 1, 1)[0]);
	return formattedData;
};

const initialState = {
	rosterTemplates: [] as ROSTER_TEMPLATE_TYPE[],
	userRosters: [] as USER_ROSTER_TYPE[],
	filteredUserRosters: [] as USER_ROSTER_TYPE[],
	loading: true,
	searchTerm: "",
};

const rosterSlice = createSlice({
	name: "rosters",
	initialState,
	reducers: {
		setUserRosters: (state, action) => {
			state.userRosters = action.payload.userRosters;
		},
		setRosterTemplates: (state, action) => {
			state.rosterTemplates = action.payload.rosterTemplates;
		},
		setSearchTermRoster: (state, action) => {
			state.searchTerm = action.payload;
			state.filteredUserRosters = state.userRosters.filter((roster) =>
				(roster?.firstName + roster?.lastName)
					.replace(/ /g, "")
					.toLocaleLowerCase()
					?.includes(state.searchTerm.replace(/ /g, "").toLowerCase())
			);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getRostersData.fulfilled, (state, action) => {
			const { rosters, rostersTemplate } = action.payload.data;
			state.rosterTemplates = formatRostersData(rostersTemplate);
			state.userRosters = formatUserRostersData(rosters);
			state.filteredUserRosters = formatUserRostersData(rosters);
			state.loading = false;
		});
		builder.addCase(getRostersData.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getRostersData.rejected, (state) => {
			state.loading = false;
		});
	},
});

export const { setUserRosters, setRosterTemplates, setSearchTermRoster } = rosterSlice.actions;

export default rosterSlice.reducer;
