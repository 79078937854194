import { FC } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

type Props = {
	open: boolean;
	handleClose: () => void;
};

const WaitingDialog: FC<Props> = ({ open, handleClose }) => (
	<Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
		<DialogTitle style={{ color: "black !important" }} id="customized-dialog-title"></DialogTitle>
		<DialogContent style={{ minWidth: "500px" }} dividers>
			<div style={{ textAlign: "center" }}>
				<AccessTimeIcon style={{ fontSize: "50px", margin: "10px 0", color: "#3f51b5" }} />
				<p style={{ color: "#3f51b5", fontWeight: "bold", fontSize: "20px", marginBottom: "15px" }}>
					We are deleting your sample data
				</p>
				<p
					style={{
						color: "#505050",
						fontWeight: "normal",
					}}>
					This may take several minutes, you can move to your next task in the meantime!
				</p>
				<div
					style={{
						marginTop: "25px",
						color: "#505050",
						fontWeight: "normal",
					}}>
					<p>You can reload the tab after a while to see the reflected changes from this deletion process.</p>
				</div>
			</div>
		</DialogContent>
		<DialogActions>
			<Button onClick={handleClose} color="primary">
				Close
			</Button>
		</DialogActions>
	</Dialog>
);

export default WaitingDialog;
