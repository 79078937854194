/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import { FC } from "react";
import { ADVANCE_REQUEST_TYPE } from "../../../@types";
import { DATE_SHOW_FORMAT, TIME_SHOW_FORMAT } from "../../../constants";
import { useSelector } from "react-redux";
import useFetch from "../../useFetch";
import { ROOT_STATE } from "../../../redux";
import { expenseRequestStatus } from "../../../constants/expenseConstant";
import { currency_map } from "../../../constants/currency_map";

type Props = {
	request: ADVANCE_REQUEST_TYPE;
	// expenseQuestionData: any;
};
const CONVEYANCE_MODE = {
	0: "CAR",
	1: "BIKE",
	2: "PUBLIC TRANSPORT",
};
const AdvanceDescription: FC<Props> = ({ request }) => {
	const user = useSelector((state: ROOT_STATE) => state.user);
	const allAdvanceAuth = request.authorizationInfo.history;
	const { data: currencyData, loading: isCurrencyLoading } = useFetch<any>("/preferences/account");
	const currencySymbol = currency_map[currencyData.data?.currencyCode]?.symbol || "";
	const totalUniqueAdvanceRequests = allAdvanceAuth.reduce((accum, curr) => {
		if (curr.adminID === user.adminDetails.adminID && curr.actionPerformed === expenseRequestStatus.PENDING) {
			return accum;
		} else return accum + 1;
	}, 0);

	const getStatus = (actionPerformed: number) => {
		switch (actionPerformed) {
			case expenseRequestStatus.REJECTED:
				return "Rejected";
			case expenseRequestStatus.APPROVED:
				return "Approved";
			case expenseRequestStatus.PAIDOUT:
				return "Paid Out";
			case expenseRequestStatus.IN_PROGRESS:
				return "In Progress";
			default:
				return "Pending";
		}
	};

	const advanceDetails = request.advanceExpenseDetails
		? request.advanceExpenseDetails
		: request.advanceConveyanceDetails;
	return (
		<div className="expanse_description">
			<div className="description">
				<h4>Description</h4>
				<p>{request.description}</p>
			</div>
			{Array.isArray(advanceDetails) && advanceDetails.length > 0 && (
				<TableContainer component={Paper} className="expense_tables" elevation={0} sx={{ marginTop: "40px" }}>
					<Table sx={{ minWidth: 550 }} aria-label="Expense Details">
						<TableHead>
							<TableRow>
								<TableCell>Category</TableCell>
								<TableCell>Amount Proposed</TableCell>
								<TableCell>Description</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Array.isArray(advanceDetails) &&
								!isCurrencyLoading &&
								advanceDetails.map((e) => (
									<TableRow
										key={e.advanceExpenseID ? e.advanceExpenseID : e.advanceConveyanceID}
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
										<TableCell>{e.category ? e.category : CONVEYANCE_MODE[e.mode]}</TableCell>
										<TableCell>
											{currencySymbol} {e.amountProposed}
										</TableCell>

										<TableCell component="th" scope="row">
											{e.description}
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
					<div className="p-3"></div>
				</TableContainer>
			)}
			<h4 style={{ marginTop: "50px", marginBottom: "10px" }}>History</h4>
			{Array.isArray(allAdvanceAuth) && allAdvanceAuth.length > 0 && (
				<TableContainer component={Paper} className="expense_tables" elevation={0}>
					<Table sx={{ minWidth: 550 }} aria-label="Audit Trail">
						<TableHead>
							<TableRow>
								<TableCell>Date</TableCell>
								<TableCell>Time</TableCell>
								<TableCell>Name of Approver</TableCell>
								<TableCell>Comment</TableCell>
								<TableCell>Amount</TableCell>
								<TableCell>Status</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Array.isArray(allAdvanceAuth) &&
								allAdvanceAuth.map((auth, i: number) => {
									const status = getStatus(auth.actionPerformed);
									if (
										auth.actionPerformed === expenseRequestStatus.PENDING &&
										auth.adminID === user?.adminDetails?.adminID
									) {
										return;
									}
									return (
										<>
											<TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
												<TableCell>{moment(auth.lastModifiedTs).format(DATE_SHOW_FORMAT)}</TableCell>
												<TableCell>{moment(auth.lastModifiedTs).format(TIME_SHOW_FORMAT)}</TableCell>
												<TableCell>{auth.adminName}</TableCell>
												<TableCell>{auth.comment || "-"}</TableCell>
												<TableCell>{auth.allottedAmount ?? "-"}</TableCell>
												<TableCell>
													<span className={`role_badge ${status}`}>{status}</span>
												</TableCell>
											</TableRow>

											{auth.insertionStatus === 0 && (
												<TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
													<TableCell>{moment(auth.lastModifiedTs).format(DATE_SHOW_FORMAT)}</TableCell>
													<TableCell>{moment(auth.lastModifiedTs).format(TIME_SHOW_FORMAT)}</TableCell>
													<TableCell colSpan={6} align={"center"}>
														<span className={`role_badge`}>Approval process reset after rejection and updated </span>
													</TableCell>
												</TableRow>
											)}
											{auth.insertionStatus === 1 && (
												<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
													<TableCell>{moment(auth.lastModifiedTs).format(DATE_SHOW_FORMAT)}</TableCell>
													<TableCell>{moment(auth.lastModifiedTs).format(TIME_SHOW_FORMAT)}</TableCell>
													<TableCell colSpan={6} align={"center"}>
														<span className={`role_badge`}>Approval process reinitiated with the new model</span>
													</TableCell>
												</TableRow>
											)}
											{auth.insertionStatus === 2 && (
												<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
													<TableCell>{moment(auth.lastModifiedTs).format(DATE_SHOW_FORMAT)}</TableCell>
													<TableCell>{moment(auth.lastModifiedTs).format(TIME_SHOW_FORMAT)}</TableCell>
													<TableCell colSpan={6} align={"center"}>
														<span className={`role_badge`}>
															Admin deleted, so approval request transferred to parent admin
														</span>
													</TableCell>
												</TableRow>
											)}
											{auth.insertionStatus === 3 && (
												<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
													<TableCell>{moment(auth.lastModifiedTs).format(DATE_SHOW_FORMAT)}</TableCell>
													<TableCell>{moment(auth.lastModifiedTs).format(TIME_SHOW_FORMAT)}</TableCell>
													<TableCell colSpan={6} align={"center"}>
														<span className={`role_badge`}>
															Fixed approver changed hence approval request transferred to the relevant new admin
														</span>
													</TableCell>
												</TableRow>
											)}
											{auth.insertionStatus === 4 && (
												<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
													<TableCell>{moment(auth.lastModifiedTs).format(DATE_SHOW_FORMAT)}</TableCell>
													<TableCell>{moment(auth.lastModifiedTs).format(TIME_SHOW_FORMAT)}</TableCell>
													<TableCell colSpan={6} align={"center"}>
														<span className={`role_badge`}>Parent admin has been changed</span>
													</TableCell>
												</TableRow>
											)}
										</>
									);
								})}
							{totalUniqueAdvanceRequests === 0 && (
								<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell colSpan={6} align={"center"} className="pending_with_others">
										<span>No Data</span>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</div>
	);
};

export default AdvanceDescription;
