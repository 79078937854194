/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel } from "@mui/material";
import { Dispatch, SetStateAction, useMemo, useState, useEffect, useRef } from "react";
import { debounce } from "lodash";
import { GET_FILTER_OPTIONS } from "../../../schema/sites_clients";
import { useQuery } from "@apollo/client";
import { AccordionData } from "../../../pages/clients_sites/clients";
import { CircularProgress, Typography } from "@mui/material";
import { formatDateTo_DDMMYYYY } from "../../../utils/validations";

type Props2 = {
	fieldID: string;
	name: string;
	setFilterCount: Dispatch<SetStateAction<number>>;
	setParameters: Dispatch<
		React.SetStateAction<{
			skip: number;
			take: number;
			filters: AccordionData[];
		}>
	>;
	resetCheckBox: boolean;
	setResetCheckBox: Dispatch<SetStateAction<boolean>>;
	deleted: number;
	setDeleted: Dispatch<SetStateAction<number>>;
	dataType: string;
	metadata?: any;
};
function IndividualFilterClients({
	name,
	fieldID,
	setFilterCount,
	setParameters,
	resetCheckBox,
	setResetCheckBox,
	deleted,
	setDeleted,
	dataType,
	metadata,
}: Props2) {
	const [variable, setVariable] = useState({
		skip: 0,
		take: 20,
		fieldID: "",
		searchKey: "",
		attribute:
			dataType === "contact"
				? metadata?.fieldName + " Name" === name
					? "Contact Name"
					: metadata?.fieldName + " Number" === name
					? "Contact Number"
					: null
				: null,
	});
	const inputRef = useRef<HTMLInputElement>(null);
	const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
	const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
	const [storeLabels, setStoreLabels] = useState<string[]>([]);
	const debouncedResults = useMemo(
		() =>
			debounce(function (e, fieldID) {
				{
					setVariable((prevState) => ({
						...prevState,
						fieldID: fieldID,
						searchKey: e.target.value,
					}));
				}
			}, 500),
		[setVariable]
	);

	const {
		loading: loadingSearchFilter,
		data: filterOptionData,
		refetch: getOptions,
	} = useQuery(GET_FILTER_OPTIONS, { variables: variable, skip: !isAccordionExpanded });

	const optionsData = filterOptionData?.get_clients_for_filter_by_adminID_v2.data;

	useEffect(() => {
		setSelectedLabels(storeLabels);
	}, [variable.searchKey]);
	useEffect(() => {
		if (deleted === 1) {
			getOptions();
		}
	}, [deleted]);
	useEffect(() => {
		if (resetCheckBox === false) {
			setStoreLabels([]);
			setVariable((prev) => ({
				...prev,
				searchKey: "",
			}));
			if (inputRef.current) {
				inputRef.current.value = "";
			}
		}
	}, [resetCheckBox]);

	const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>, fieldID: string) => {
		const label = e.target.value;
		setResetCheckBox(true);
		setDeleted(0);

		let isContactType: boolean = false;
		let contactAttribute: string = "";

		if (metadata?.dataType === "contact") {
			isContactType = true;
			if (metadata?.fieldName === "Contact") {
				contactAttribute = fieldID;
			} else {
				contactAttribute = fieldID.includes(" Name") ? "Contact Name" : "Contact Number";
			}
		}

		if (e.target.checked) {
			if (label) {
				// setSelectedLabels((prevState) => [...prevState, label]);
				setStoreLabels((prevState) => [...prevState, label]);
				setFilterCount((prevState) => prevState + 1);
				setParameters((prevState) => {
					const fieldIndex = prevState.filters.findIndex((item) => item.key === fieldID);
					if (fieldIndex !== -1) {
						return {
							...prevState,
							filters: prevState.filters.map((item, index) => {
								if (index === fieldIndex) {
									return {
										...item,
										value: [...item.value, label],
										attribute: isContactType ? contactAttribute : null,
										originalKey: isContactType ? metadata.fieldID : undefined,
									};
								}
								return item;
							}),
						};
					} else {
						return {
							...prevState,
							filters: [
								...prevState.filters,
								{
									key: fieldID,
									value: [label],
									attribute: isContactType ? contactAttribute : null,
									originalKey: isContactType ? metadata.fieldID : undefined,
								},
							],
						};
					}
				});
			}
		} else {
			// setSelectedLabels(selectedLabels.filter((l) => l !== label));
			setStoreLabels(storeLabels.filter((l) => l !== label));
			setFilterCount((prevState) => prevState - 1);
			setParameters((prevState) => ({
				...prevState,
				filters: prevState.filters
					.map((item) => {
						if (item.key === fieldID) {
							return {
								...item,
								value: item.value.filter((v) => v !== label),
								attribute: isContactType ? contactAttribute : null,
								originalKey: isContactType ? metadata.fieldID : undefined,
							};
						}
						return item;
					})
					.filter((item) => item.value.length > 0),
			}));
		}
	};

	const handleExpand = (fieldID: string) => {
		setVariable((prevState) => ({
			...prevState,
			fieldID: fieldID,
		}));
		setIsAccordionExpanded(!isAccordionExpanded); //this toggles whether to skip the query or not
	};

	return (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={(e) => handleExpand(fieldID)}>
				<h6>{name}</h6>
			</AccordionSummary>
			<AccordionDetails>
				<>
					<input
						type="search"
						className="search"
						placeholder={`Search ${name}`}
						ref={inputRef}
						onChange={(e) => debouncedResults(e, fieldID)}
					/>
					{selectedLabels &&
						selectedLabels.map((item: any, i: any) => {
							const isChecked = storeLabels.includes(item);
							if (dataType === "contact") {
								return (
									<FormControlLabel
										key={i}
										sx={{ width: "100%" }}
										label={item}
										control={
											<Checkbox
												size="small"
												checked={isChecked}
												value={item}
												onChange={(e) => onChangeCheckbox(e, name)}
											/>
										}
									/>
								);
							}
							return (
								<FormControlLabel
									key={i}
									sx={{ width: "100%" }}
									label={item}
									control={
										<Checkbox
											size="small"
											checked={isChecked}
											value={item}
											onChange={(e) => onChangeCheckbox(e, fieldID)}
										/>
									}
								/>
							);
						})}

					{loadingSearchFilter ? (
						<div style={{ display: "grid", justifyContent: "center" }}>
							<CircularProgress size={20} />
						</div>
					) : optionsData?.length === 0 ? (
						<Typography variant="body2">No data found</Typography>
					) : (
						optionsData
							?.filter((item: any) => !selectedLabels.includes(item))
							.map((item: any, i: any) => {
								const isChecked = storeLabels.includes(item);

								let dateItem;
								if (dataType === "date") {
									const dateString = item;
									dateItem = formatDateTo_DDMMYYYY(dateString);
								}
								if (!item) {
									return null;
								}
								if (dataType === "contact") {
									return (
										<FormControlLabel
											key={i}
											sx={{ width: "100%" }}
											label={item}
											control={
												<Checkbox
													size="small"
													checked={isChecked}
													value={item}
													onChange={(e) => onChangeCheckbox(e, name)}
												/>
											}
										/>
									);
								}
								return (
									<FormControlLabel
										key={i}
										sx={{ width: "100%" }}
										label={dataType === "date" ? dateItem : item}
										control={
											<Checkbox
												size="small"
												checked={isChecked}
												value={item}
												onChange={(e) => onChangeCheckbox(e, fieldID)}
											/>
										}
									/>
								);
							})
					)}
				</>
			</AccordionDetails>
		</Accordion>
	);
}
export default IndividualFilterClients;
