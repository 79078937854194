import { useMutation } from "@apollo/client";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import moment from "moment";
import { FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { COMMUNICATION_RULES, PROFILES_DATA } from "../../../@types";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { UPSERT_COMMUNICATION_RULES } from "../../../schema/communication_rules";
type Props = {
	closeSidebar: () => void;
	selectedSetting: null | COMMUNICATION_RULES;
	allTeams: PROFILES_DATA[];
	refetch: any;
	allAdmins: any;
	ruleFor: string;
};
const InvoiceRulesSidebar = ({ closeSidebar, selectedSetting, allTeams, refetch, allAdmins, ruleFor }: Props) => {
	const title = selectedSetting ? "Edit Rule" : "Add Rule";
	const dispatch = useDispatch<APP_DISPATCH>();
	const [upsertRulesBatch] = useMutation(UPSERT_COMMUNICATION_RULES);

	const [currentSetting, setCurrentSetting] = useState<COMMUNICATION_RULES>(
		selectedSetting
			? { ...selectedSetting, lastModifiedTs: moment().valueOf() }
			: ({
				createdTs: moment().valueOf(),
				lastModifiedTs: moment().valueOf(),
			} as COMMUNICATION_RULES)
	);
	const [isSaving, setIsSaving] = useState(false);
	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsSaving(true);
		const toSendData: any = {
			ruleID: currentSetting.ruleID,
			entityType: ruleFor,
			profileID: currentSetting.profileID,
			what: currentSetting.what,
			when: currentSetting.when,
			how: currentSetting.how,
			who: currentSetting.who,
			whoParameter: currentSetting.whoParameter,
			createdTs: currentSetting.createdTs,
			lastModifiedTs: currentSetting.lastModifiedTs,
		};
		if (selectedSetting) toSendData.ruleID = selectedSetting.ruleID;
		await upsertRulesBatch({ variables: { input: [toSendData] } });
		await refetch();
		dispatch(showNotification({ message: "Rules Updated", severity: "success" }));
		setIsSaving(false);
		closeSidebar();
	};
	const handleChange = (key: keyof COMMUNICATION_RULES, value: string | number) => {
		if (!key || key.length === 0) return;
		setCurrentSetting((prev) => ({ ...prev, [key]: value }));
	};
	return (
		<div className="user_add custom_fields">
			<div className="user_add-title">
				<h1>{title}</h1>
				<button onClick={() => closeSidebar()}>
					<CloseOutlinedIcon />
				</button>
			</div>
			<form className="user_add-form" onSubmit={handleSubmit}>
				{ruleFor === "TASK" && <FormControl>
					<InputLabel id="what">What to Send</InputLabel>
					<Select
						required
						labelId="what"
						label="Select any one"
						disabled={isSaving}
						value={currentSetting?.what ?? ""}
						onChange={(e) => handleChange("what", e.target.value)}>
						<MenuItem value="TASK_ASSIGNED">Task Assigned</MenuItem>
						<MenuItem value="TASK_STARTED">Task Start</MenuItem>
						<MenuItem value="TASK_FINISHED">Task Finish</MenuItem>

					</Select>
				</FormControl>}
				<FormControl>
					<InputLabel id="who">Who to Send to</InputLabel>
					<Select
						required
						labelId="who"
						label="Select any one"
						disabled={isSaving}
						value={currentSetting?.who ?? ""}
						onChange={(e) => handleChange("who", e.target.value)}>
						<MenuItem value="CLIENT">Client</MenuItem>
						<MenuItem value="RM" disabled={ruleFor === "TASK"}>Reporting Manager</MenuItem>
						<MenuItem value="ADMIN" disabled={ruleFor === "TASK"}>Admin</MenuItem>
						<MenuItem value="FIXED_CONTACT" disabled={ruleFor === "TASK"}>Fixed Contact</MenuItem>
					</Select>
				</FormControl>
				{currentSetting?.who === "FIXED_CONTACT" && (
					<TextField
						required
						type="Email"
						name="whoParameter"
						label="Enter Email"
						disabled={isSaving}
						value={currentSetting?.whoParameter || ""}
						onChange={(e) => handleChange("whoParameter", e.target.value)}
					/>
				)}
				{currentSetting?.who === "ADMIN" && (
					<FormControl>
						<InputLabel id="whoParameter-ADMIN">Select Admin</InputLabel>
						<Select
							required
							labelId="whoParameter-ADMIN"
							label="Select any one"
							disabled={isSaving}
							value={currentSetting?.whoParameter ?? ""}
							onChange={(e) => handleChange("whoParameter", `${e.target.value}`)}>
							{allAdmins.map((i: any) => (
								<MenuItem key={i.id} value={i.id}>
									{i.firstname} {i.lastname}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}
				<FormControl>
					<InputLabel id="when">When to Send</InputLabel>
					<Select
						required
						labelId="when"
						label="Select any one"
						disabled={isSaving}
						value={currentSetting?.when ?? ""}
						onChange={(e) => handleChange("when", e.target.value)}>
						<MenuItem value="IMMEDIATE">Immediate</MenuItem>
						{ruleFor !== "TASK" && <MenuItem value="EOD">End of day</MenuItem>}
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel id="how">How To Send</InputLabel>
					<Select
						required
						labelId="how"
						label="Select any one"
						disabled={isSaving}
						value={currentSetting?.how ?? ""}
						onChange={(e) => handleChange("how", e.target.value)}>
						<MenuItem value="EMAIL">Email</MenuItem>
						<MenuItem value="SMS" disabled>SMS</MenuItem>
						<MenuItem value="WA">WA</MenuItem>
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel id="profileID">Team</InputLabel>
					<Select
						required
						labelId="profileID"
						label="Select any one"
						disabled={isSaving}
						value={currentSetting?.profileID ?? ""}
						onChange={(e) => handleChange("profileID", e.target.value)}>
						<MenuItem value={-1}>All Teams</MenuItem>
						{allTeams.map((i) => (
							<MenuItem key={i.profileID} value={i.profileID}>
								{i.profileName}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<div className="action_button">
					<button type="reset" onClick={closeSidebar}>
						Cancel
					</button>
					<button type="submit" disabled={isSaving}>
						{isSaving ? <CircularProgress style={{ width: "15px", height: "15px" }} /> : "Save"}
					</button>
				</div>
			</form>
		</div>
	);
};

export default InvoiceRulesSidebar;
