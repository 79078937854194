import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { CircularProgress, Modal } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ADVANCE_REQUEST_TYPE } from "../../../@types";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import { findCurrentAdvanceExpenseConveyanceAuth } from "../../../utils";
import useAdminEntitlements from "../../useAdminEntitlements";
import AdvanceForm from "./AdvanceForm";
import AdvanceDescription from "./AdvanceDescription";
import { APPROVE_OR_REJECT_ADVANCE, MARK_ADVANCE_PAID_OUT } from "../../../schema/expenses";
import { useMutation } from "@apollo/client";
import { formatDateTo_DDMMYYYY } from "../../../utils/validations";

type Props = {
	open: boolean;
	handleClose: () => void;
	selectedExpenseId: string;
	setSelectedExpenseId: Dispatch<SetStateAction<string | null>>;
	filteredAdvances: ADVANCE_REQUEST_TYPE[];
	requests: ADVANCE_REQUEST_TYPE[];
	setRequests: Dispatch<SetStateAction<ADVANCE_REQUEST_TYPE[]>>;
	refetch: () => void;
};

const AdvanceDetailsModal = ({
	open,
	handleClose,
	selectedExpenseId,
	filteredAdvances,
	setSelectedExpenseId,
	requests,
	refetch,
}: Props) => {
	const user = useSelector((state: ROOT_STATE) => state.user);
	const [selectedExpense, setSelectedExpense] = useState(
		requests.find((exp) => exp.advanceSummaryID === selectedExpenseId)!
	);
	const userWritePermission = useAdminEntitlements("expense:advanceRequests", "write");
	const dispatch = useDispatch<APP_DISPATCH>();
	const [isLoading, setIsLoading] = useState(false);
	const [approve_or_reject_advance, { loading: isUpdated, error: authError }] = useMutation(APPROVE_OR_REJECT_ADVANCE);
	const [mark_advance_paid_out, { loading: isPaid, error: paidOutError }] = useMutation(MARK_ADVANCE_PAID_OUT);

	const handleSubmit = async (type: "approve" | "reject") => {
		const state = type === "approve" ? "Approved" : "Rejected";

		if (!type || !selectedExpense || !selectedExpenseId) {
			dispatch(
				showNotification({ message: `Expense or ExpenseId ${selectedExpenseId} not found `, severity: "error" })
			);
			return;
		}
		const newRequest = { ...selectedExpense };
		const pendingExpenseAuth = findCurrentAdvanceExpenseConveyanceAuth(newRequest);
		// if there are multiple pending expense Auth, the throw a error
		if (typeof pendingExpenseAuth === "string") {
			dispatch(showNotification({ message: pendingExpenseAuth, severity: "error" }));
			return;
		}
		// otherwise take the auth and check for amount
		if (pendingExpenseAuth.allottedAmount === null || isNaN(pendingExpenseAuth.allottedAmount)) {
			dispatch(showNotification({ message: "Enter an amount", severity: "error" }));
			return;
		}

		const advancesAuthorizationInput = [
			{
				advanceSummaryID: newRequest.advanceSummaryID,
				allottedAmount: type === "approve" ? pendingExpenseAuth.allottedAmount : null,
			},
		];
		const status = type === "approve" ? 1 : 0;
		const rejectReason = pendingExpenseAuth.rejectReason;
		const comment = pendingExpenseAuth.comment;
		setIsLoading(true);

		const data = await approve_or_reject_advance({
			variables: { advancesAuthorizationInput, status, rejectReason, comment },
		});
		if (data.errors || authError) {
			dispatch(
				showNotification({
					message: "Couldn't Approve Request",
					severity: "error",
				})
			);
		} else {
			dispatch(
				showNotification({
					message: authError ? `Couldn't ${state} Request` : `Successfully ${state} Selected Request`,
					severity: authError ? "error" : "success",
				})
			);
			refetch();
		}
		setIsLoading(false);
	};
	const handlePaidOut = async () => {
		if (!selectedExpense || !selectedExpenseId) return;
		const newRequest = { ...selectedExpense };
		newRequest.status = 2;
		const advanceSummaryIDs = [newRequest.advanceSummaryID];
		setIsLoading(true);
		const data = await mark_advance_paid_out({ variables: { advanceSummaryIDs } });
		if (data.errors || paidOutError) {
			dispatch(
				showNotification({
					message: "Couldn't fulfill Request",
					severity: "error",
				})
			);
		} else {
			showNotification({
				message: paidOutError ? "Couldn't fulfill Request" : "Successfully Paid Out Selected Request",
				severity: paidOutError ? "error" : "success",
			});
			refetch();
		}
		setIsLoading(false);
	};

	const canCurrentAdminPayout =
		selectedExpense &&
		(selectedExpense.status === 1 || selectedExpense.status === 3) &&
		selectedExpense.authorizationInfo?.payoutAdminID === user.adminDetails.adminID;

	const canApprove =
		selectedExpense && selectedExpense.authorizationInfo.pendingAdmin?.adminID === user.adminDetails.adminID!; //here adminID is of a backup approver

	const nextExpense = () => {
		const index = filteredAdvances.findIndex((exp) => exp.advanceSummaryID === selectedExpenseId);
		return filteredAdvances[index + 1]?.advanceSummaryID;
	};

	const prevExpense = () => {
		const index = filteredAdvances.findIndex((exp) => exp.advanceSummaryID === selectedExpenseId);
		return filteredAdvances[index - 1]?.advanceSummaryID;
	};

	useEffect(() => {
		setSelectedExpense(requests.find((exp) => exp.advanceSummaryID === selectedExpenseId)!);
	}, [requests, selectedExpenseId]);

	return (
		<Modal open={open} onClose={handleClose} aria-labelledby="Detailed Expense Modal">
			<section className="view_request expense_modal">
				<section className="modal_title">
					<div className="left">
						<h3>
							{selectedExpense.userInfo.firstName + " " + selectedExpense.userInfo.lastName} (
							{`${formatDateTo_DDMMYYYY(selectedExpense.fromDate)} to ${formatDateTo_DDMMYYYY(selectedExpense.toDate)}`})
						</h3>
						<div className="move_buttons">
							{prevExpense() && (
								<button onClick={() => setSelectedExpenseId(prevExpense())} title="prev">
									<KeyboardArrowLeftOutlinedIcon />
								</button>
							)}
							{nextExpense() && (
								<button onClick={() => setSelectedExpenseId(nextExpense())} className="ml-auto" title="next">
									<KeyboardArrowRightOutlinedIcon />
								</button>
							)}
						</div>
					</div>
					<button onClick={() => setSelectedExpenseId(null)}>
						<CloseIcon />
					</button>
				</section>
				{selectedExpense && Object.keys(selectedExpense).length > 0 ? (
					<form onSubmit={(e) => e.preventDefault()}>
						<header>
							{selectedExpense.status === 2 && <div className="status">Paid Out</div>}
							{selectedExpense.status === 0 && <div className="status Rejected">Rejected</div>}
							{selectedExpense.status === 1 &&
								(canCurrentAdminPayout ? (
									<button className="status" onClick={handlePaidOut}>
										{isLoading || isPaid ? (
											<CircularProgress style={{ width: "20px", height: "20px", color: "white" }} />
										) : (
											"Pay Out"
										)}
									</button>
								) : (
									<div className="status Approved">Approved</div>
								))}
						</header>
						<AdvanceForm request={selectedExpense} setRequest={setSelectedExpense} canApprove={canApprove} />
						{(selectedExpense.status === -1 || selectedExpense.status === 3) && (
							<div style={{ marginLeft: "auto" }} className="approval_actions">
								<button
									type="button"
									data-id="reject"
									className="Rejected"
									disabled={!canApprove || !userWritePermission || isLoading}
									onClick={() => handleSubmit("reject")}>
									{isLoading || isUpdated ? (
										<CircularProgress style={{ width: "20px", height: "20px", color: "white" }} />
									) : (
										"Reject"
									)}
								</button>
								<button
									type="button"
									data-id="approve"
									disabled={!canApprove || !userWritePermission || isLoading}
									onClick={() => handleSubmit("approve")}>
									{isLoading ? (
										<CircularProgress style={{ width: "20px", height: "20px", color: "white" }} />
									) : (
										"Approve"
									)}
								</button>
							</div>
						)}
						{userWritePermission === 0 && (
							<ul className="errors">
								<li>You don't have Permission to Approve/Reject this request</li>
							</ul>
						)}
						<AdvanceDescription request={selectedExpense} />
					</form>
				) : (
					<div className="small_circular-spinner">
						<CircularProgress />
					</div>
				)}
			</section>
		</Modal>
	);
};

export default AdvanceDetailsModal;
