import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import Dialog from "@mui/material/Dialog";
import moment from "moment";
import "moment-timezone";
import { useDispatch } from "react-redux";
import { PRODUCT_TYPE } from "../../../@types";
import { getProductBulkDownload } from "../../../api/orders";
import { DATE_SHOW_FORMAT } from "../../../constants";
import { APP_DISPATCH, showNotification } from "../../../redux";
type Props = {
	handleClose: () => void;
	products: PRODUCT_TYPE[];
};

const BulkDownloadProduct = ({ handleClose, products }: Props) => {
	const dispatch = useDispatch<APP_DISPATCH>();

	const downloadBulkProducts = async () => {
		const { message, success, data } = await getProductBulkDownload(products);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (!success) return;
		const link = document.createElement("a");
		document.body.appendChild(link);
		link.href = window.URL.createObjectURL(new Blob([data]));
		link.setAttribute("download", `products_excel_${moment().format(DATE_SHOW_FORMAT)}.xlsx`);
		link.click();
		link.remove();
	};
	return (
		<Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
			<div className="bulk_download-modal">
				<div className="title">
					<h3>Bulk Download</h3>
					<button title="Close Modal" onClick={() => handleClose()}>
						<CloseOutlinedIcon htmlColor="#8f8f8f" />
					</button>
				</div>
				<div className="action_buttons">
					<button title="Download Report" onClick={downloadBulkProducts}>
						<CloudDownloadOutlinedIcon />
						Download
					</button>
				</div>
			</div>
		</Dialog>
	);
};

export default BulkDownloadProduct;