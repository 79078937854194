import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { CircularProgress, Modal } from "@mui/material";
import Link from "next/link";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CONVEYANCE_TYPE } from "../../../@types";
import { expenseApproveReject, markExpensePaidOut } from "../../../api/expense";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import { findCurrentExpenseConveyanceAuth } from "../../../utils";
import useAdminEntitlements from "../../useAdminEntitlements";
import ConveyanceDescription from "./ConveyanceDescription";
import ConveyanceForm from "./ConveyanceForm";
import { formatDateTo_DDMMYYYY } from "../../../utils/validations";

type Props = {
	open: boolean;
	handleClose: () => void;
	selectedConveyanceId: string;
	filteredConveyances: CONVEYANCE_TYPE[];
	requests: CONVEYANCE_TYPE[];
	setRequests: Dispatch<SetStateAction<CONVEYANCE_TYPE[]>>;
	setSelectedConveyanceId: Dispatch<SetStateAction<string | null>>;
	refetch: () => void;
};
const ConveyanceDetailsModal = ({
	open,
	handleClose,
	selectedConveyanceId,
	setSelectedConveyanceId,
	filteredConveyances,
	requests,
	refetch,
}: Props) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const user = useSelector((state: ROOT_STATE) => state.user);
	const [selectedConveyance, setSelectedConveyance] = useState(
		filteredConveyances.find((exp) => exp.expenseSummaryID === selectedConveyanceId)!
	);
	const userWritePermission = useAdminEntitlements("expense:conveyanceRequests", "write");

	const [isLoading, setIsLoading] = useState(false);
	const handleSubmit = async (type: "approve" | "reject") => {
		if (!type || !selectedConveyance || !selectedConveyanceId) {
			dispatch(
				showNotification({
					message: `Conveyance or ConveyanceId ${selectedConveyanceId} not found `,
					severity: "error",
				})
			);
			return;
		}
		const newRequest = { ...selectedConveyance };
		const pendingConveyanceAuth = findCurrentExpenseConveyanceAuth(newRequest);
		if (typeof pendingConveyanceAuth === "string") {
			dispatch(showNotification({ message: pendingConveyanceAuth, severity: "error" }));
			return;
		}
		if (pendingConveyanceAuth.reimbursementAmount === null || isNaN(pendingConveyanceAuth.reimbursementAmount)) {
			dispatch(showNotification({ message: "Enter an amount", severity: "error" }));
			return;
		}
		setIsLoading(true);
		newRequest.status = type === "reject" ? 0 : 1;
		const { success, message } = await expenseApproveReject([newRequest]);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		refetch();
		setIsLoading(false);
	};

	const handlePaidOut = async () => {
		if (!selectedConveyance || !selectedConveyanceId) {
			dispatch(
				showNotification({
					message: `Conveyance or ConveyanceId ${selectedConveyanceId} not found `,
					severity: "error",
				})
			);
			return;
		}
		setIsLoading(true);
		const newRequest = { ...selectedConveyance };
		newRequest.status = 2;
		const { success, message } = await markExpensePaidOut([newRequest]);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		refetch();
		setIsLoading(false);
	};

	const canCurrentAdminPayout =
		selectedConveyance &&
		selectedConveyance.status === 1 &&
		selectedConveyance.expenseAuth.at(-1)?.adminID === user.adminDetails.adminID;

	const canApprove = selectedConveyance && selectedConveyance.pendingWith?.adminID === user.adminDetails.adminID!;

	const nextConveyance = () => {
		const index = filteredConveyances.findIndex((exp) => exp.expenseSummaryID === selectedConveyanceId);
		return filteredConveyances[index + 1]?.expenseSummaryID;
	};

	const prevConveyance = () => {
		const index = filteredConveyances.findIndex((exp) => exp.expenseSummaryID === selectedConveyanceId);
		return filteredConveyances[index - 1]?.expenseSummaryID;
	};

	useEffect(() => {
		setSelectedConveyance(requests.find((exp) => exp.expenseSummaryID === selectedConveyanceId)!);
	}, [requests, selectedConveyanceId]);
	return (
        <Modal open={open} onClose={handleClose} aria-labelledby="Detailed Conveyance Modal">
			<section className="view_request expense_modal">
				<div className="modal_title">
					<div className="left">
						<h3>
							{selectedConveyance.firstName + " " + selectedConveyance.lastName} {`(${formatDateTo_DDMMYYYY(selectedConveyance.date)})`}
						</h3>
						<div className="move_buttons">
							{prevConveyance() && (
								<button onClick={() => setSelectedConveyanceId(prevConveyance())}>
									<KeyboardArrowLeftOutlinedIcon />
								</button>
							)}
							{nextConveyance() && (
								<button onClick={() => setSelectedConveyanceId(nextConveyance())} className="ml-auto">
									<KeyboardArrowRightOutlinedIcon />
								</button>
							)}
						</div>
					</div>
					<button onClick={() => setSelectedConveyanceId(null)}>
						<CloseIcon />
					</button>
				</div>

				{selectedConveyance && Object.keys(selectedConveyance).length > 0 ? (
					<form onSubmit={(e) => e.preventDefault()}>
						<header>
							<Link
                                href={`/dashboard/timeline?employeeID=${selectedConveyance.employeeID}&selectedDate=${selectedConveyance.date}`}
                                className="timeline">
								Visit Timeline
							</Link>
							{selectedConveyance.status === 2 && <div className="status">Paid Out</div>}
							{selectedConveyance.status === 0 && <div className="status Rejected">Rejected</div>}
							{selectedConveyance.status === 1 &&
								(canCurrentAdminPayout ? (
									<button className="status" onClick={handlePaidOut}>
										{isLoading ? (
											<CircularProgress style={{ width: "20px", height: "20px", color: "white" }} />
										) : (
											"Pay Out"
										)}
									</button>
								) : (
									<div className="status Approved">Approved</div>
								))}
						</header>
						<ConveyanceForm canApprove={canApprove} request={selectedConveyance} setRequest={setSelectedConveyance} />
						{selectedConveyance.status === -1 && canApprove && (
							<div style={{ marginLeft: "auto" }} className="approval_actions">
								<button
									disabled={!userWritePermission || isLoading}
									type="button"
									data-id="reject"
									className="Rejected"
									onClick={() => handleSubmit("reject")}>
									{isLoading ? (
										<CircularProgress style={{ width: "20px", height: "20px", color: "white" }} />
									) : (
										"Reject"
									)}
								</button>
								<button
									disabled={!userWritePermission || isLoading}
									type="button"
									data-id="approve"
									onClick={() => handleSubmit("approve")}>
									{isLoading ? (
										<CircularProgress style={{ width: "20px", height: "20px", color: "white" }} />
									) : (
										"Approve"
									)}
								</button>
							</div>
						)}
						{userWritePermission === 0 && (
							<ul className="errors">
								<li>You don't have Permission to Approve/Reject this request</li>
							</ul>
						)}
						<ConveyanceDescription request={selectedConveyance} setRequest={setSelectedConveyance} />
					</form>
				) : (
					<div className="small_circular-spinner">
						<CircularProgress />
					</div>
				)}
			</section>
		</Modal>
    );
};

export default ConveyanceDetailsModal;
