import { Dialog, DialogContent, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import BlockIcon from "@mui/icons-material/Block";
import { FC } from "react";

const BootstrapDialogLimit = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		backgroundColor: "#fff",
		padding: "16px 16px 24px 16px",
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
	"& .MuiDialog-paper": {
		maxWidth: "550px",
	},
}));
type props = {
	handleClose: () => void;
	name?: string;
	message: string;
	title?: string;
};
const SubscriptionPlanModal: FC<props> = ({ handleClose, name, message, title }: props) => (
	<BootstrapDialogLimit onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
		<DialogContent className="customized-dialog-limit">
			<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
					<BlockIcon htmlColor="#f54747" fontSize="medium" />
					{name && <h5>{`Maximum ${name} limit reached.`}</h5>}
					{title && <h5>{title}</h5>}
				</div>
				<IconButton aria-label="close" onClick={handleClose} style={{ padding: "0px" }}>
					<CloseIcon />
				</IconButton>
			</div>
			<p
				style={{
					marginTop: "12px",
					fontSize: "14px",
					fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
					lineHeight: "22px",
				}}>
				{message}
			</p>
			<p
				style={{
					marginTop: "12px",
					fontSize: "14px",
					fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
					lineHeight: "22px",
				}}>
				To Upgrade your subscription plan, contact us on +91 9599936991 or email us at{" "}
				<a style={{ color: "#1976D2", textDecoration: "underline" }}>suppport@unolo.com</a>.
			</p>
			{/* <DialogActions style={{marginTop:"10px"}}>
					<Button
						variant="contained"
						onClick={() => router.push("/billings")}
						sx={{
							fontSize: "12px",
							fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
							fontWeight: 600,
							textTransform: "capitalize",
						}}>
						Upgrade Now
					</Button>
				</DialogActions> */}
		</DialogContent>
	</BootstrapDialogLimit>
);

export default SubscriptionPlanModal;
