import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { FC, useState } from "react";
import ExpenseModal from "./ExpenseModal";

type Props = {
	canAdminApprove: boolean;
	// eslint-disable-next-line no-unused-vars
	approveRequest: (reason: string, amount?: number) => void;
	// eslint-disable-next-line no-unused-vars
	rejectRequest: (reason: string) => void;
	paidOutCallback: () => void;
	name: string;
	status: -1 | 0 | 1 | 2;
	link: string;
	amountClaimed: number;
	canAdminPayout: boolean;
	userWritePermission:any;
};
const ExpenseActionButtons: FC<Props> = ({
	rejectRequest,
	approveRequest,
	name,
	status,
	amountClaimed = 0,
	canAdminApprove,
	canAdminPayout,
	paidOutCallback,
	userWritePermission
}) => {
	const [open, setOpen] = useState(false);
	const [isReject, setIsReject] = useState(false);
	return (
		<div className="action-buttons">
			<button className="view" disabled={!userWritePermission}>
				<RemoveRedEyeOutlinedIcon htmlColor="#819dff" />
			</button>
			{canAdminPayout && (
				<button
					type="button"
					className="paid_out"
					onClick={(e) => {
						e.stopPropagation();
						paidOutCallback();
					}}
					disabled={!userWritePermission}>
					Pay out
				</button>
			)}
			{status === -1 && canAdminApprove && (
				<>
					<button
						onClick={(e) => {
							e.stopPropagation();
							setIsReject(false);
							setOpen(true);
						}}disabled={!userWritePermission}>
						<DoneOutlinedIcon htmlColor="#40a636" />
					</button>

					<button
						onClick={(e) => {
							e.stopPropagation();
							setIsReject(true);
							setOpen(true);
						}}
						disabled={!userWritePermission}>
						<CloseOutlinedIcon htmlColor="#F54747" />
					</button>
				</>
			)}
			{open && status === -1 && (
				<ExpenseModal
					isReject={isReject}
					approveHandler={approveRequest}
					rejectHandler={rejectRequest}
					handleClose={() => setOpen(false)}
					name={name}
					amountClaimed={amountClaimed}
				/>
			)}
		</div>
	);
};

export default ExpenseActionButtons;
