import { CircularProgress } from "@mui/material";
import { Dispatch, FC, FormEvent, SetStateAction, useState } from "react";
import { resetPasswordApi } from "../../api/login";
type Props = {
	setIsOtpSent: Dispatch<SetStateAction<boolean>>;
	email: string;
	setEmail: Dispatch<SetStateAction<string>>;
};
const EmailVerification: FC<Props> = ({ setIsOtpSent, email, setEmail }) => {
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState("");
	const handleEmailVerification = async (e: FormEvent<HTMLFormElement>) => {
		setErrors("");
		e.preventDefault();
		setLoading(true);
		const { success, message } = await resetPasswordApi(email);
		if (!success) setErrors(message);
		setLoading(false);
		if (success) setIsOtpSent(true);
	};
	return (
		<form className="login_form" onSubmit={handleEmailVerification}>
			<h2>Email verification</h2>
			<div className="container">
				<label htmlFor="email">
					<b>Email</b>
				</label>
				<input
					type="email"
					placeholder="Enter Email"
					name="email"
					required
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<ul className="errors">
					<li>{errors}</li>
				</ul>
				<button type="submit" disabled={loading}>
					{loading ? <CircularProgress style={{ width: "20px", height: "20px", color: "white" }} /> : "Send Reset link"}
				</button>
			</div>
		</form>
	);
};

export default EmailVerification;
