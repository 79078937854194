import React, { useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
	Box,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	Switch,
	TextField,
	Button,
	Checkbox,
} from "@mui/material";
import Link from "next/link";
import "moment-timezone";
import { FC, FormEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { INTEGRATION_INFO } from "../../../@types";
import { enableIntegration, upsertIntegration } from "../../../api/settings";
import { ROOT_STATE, APP_DISPATCH, showNotification } from "../../../redux";
import { useSelector } from "react-redux";
import useAdminInfo from "../../useAdminInfo";
import useFetch from "../../useFetch";
import DoneIcon from "@mui/icons-material/Done";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { CRM_AUTH_STATUS } from "../../../constants/zohoCrmConstants";
import useAdminEntitlements from "../../useAdminEntitlements";
import ReadOnlyButton from "../../common/ReadOnlyButton";
import MessageModal from "./MessageModal";
type Props = {
	zohoPeopleAttendanceData: INTEGRATION_INFO | undefined;
	zohoPeopleLeavesData: INTEGRATION_INFO | undefined;
};

const ZohoPeopleIntegrations: FC<Props> = ({ zohoPeopleAttendanceData, zohoPeopleLeavesData }) => {
	const userWritePermission = useAdminEntitlements("settings:integrations", "write");

	const { companyID } = useAdminInfo();
	const { roleID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);

	const disabled = companyID === 255 || !userWritePermission; // Hardcoded for our company

	const dispatch = useDispatch<APP_DISPATCH>();
	const [isSaving, setIsSaving] = useState(false);
	
	const [zohoPeopleStatus, setZohoPeopleStatus] = useState(zohoPeopleAttendanceData?.status ?? 0);
	const [zohoLeavesStatus, setZohoLeavesStatus] = useState(zohoPeopleLeavesData?.status ?? 0);

	const [openRedirectModal, setOpenRedirectModal] = useState(false);
	const [openMessageModal, setOpenMessageModal] = useState(false);

	const [statusMsgFromAuth, setStatusMsgFromAuth] = useState(0);
	const [superAdmin, setSuperAdmin] = useState(false);

	const [zohoCred, setZohoCred] = useState<Record<string, INTEGRATION_INFO["keyValues"][0]>>({
		email: { keyTypeID: 10, keyValue: zohoPeopleAttendanceData?.keyValues.find((k) => k.keyTypeID === 10)?.keyValue ?? "" },
		clientID: { keyTypeID: 6, keyValue: zohoPeopleAttendanceData?.keyValues.find((k) => k.keyTypeID === 6)?.keyValue ?? "" },
		clientSecret: { keyTypeID: 7, keyValue: zohoPeopleAttendanceData?.keyValues.find((k) => k.keyTypeID === 7)?.keyValue ?? "" },
		dataCenter: { keyTypeID: 8, keyValue: zohoPeopleAttendanceData?.keyValues.find((k) => k.keyTypeID === 8)?.keyValue ?? "" },
		environment: { keyTypeID: 9, keyValue: zohoPeopleAttendanceData?.keyValues.find((k) => k.keyTypeID === 9)?.keyValue ?? "" },
	});

	const { data, loading:oauthStatusloading } = useFetch("/preferences/getCrmOauthStatus");

	const user = useSelector((state: ROOT_STATE) => state.user);

	useEffect(() => {
		const parentID = user?.adminDetails?.parentID;
		setSuperAdmin(parentID === null);
	}, [user?.adminDetails]);

	const handleZohoSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!superAdmin) {
			return;
		}
		setIsSaving(true);
		const { success, message } = await upsertIntegration(8, Object.values(zohoCred));
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		setIsSaving(false);
		setOpenRedirectModal(true);
	};

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setZohoCred((prev) => ({ ...prev, [name]: { keyTypeID: zohoCred[name].keyTypeID, keyValue: value } }));
	};

	const enableZohoAttendancePeopleSwitch = async (prevStatus: 0 | 1) => {
		if (!superAdmin) {
			return;
		}
		if (isNaN(prevStatus)) return;
		const newState = prevStatus === 0 ? 1 : 0;
		const { success, message } = await enableIntegration("attendance", 8, newState);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (!success) return;
		setZohoPeopleStatus(newState);
	};
	const enableZohoPeopleLeavesSwitch = async (prevStatus: 0 | 1) => {
		if (!superAdmin) {
			return;
		}
		if (isNaN(prevStatus)) return;
		const newState = prevStatus === 0 ? 1 : 0;
		// After enabling the Zoho Leaves ask them to authorize
		if(newState == 1 && statusMsgFromAuth == 1){
			setOpenMessageModal(true);
		}
		const { success, message } = await enableIntegration("leaves", 8, newState);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (!success) return;
		setZohoLeavesStatus(newState);
	};

	const checkStatus = (data: any) => {
		if (data?.status) {
			let zohoPeopleStatus: number = 0;
			data.status.forEach((integrationStatus: any) => {
				if (integrationStatus.integrationID == 8 && integrationStatus.crmOauthStatus) {
					zohoPeopleStatus = 1;
				}
			});
			setStatusMsgFromAuth(zohoPeopleStatus);
		}
	};

	useEffect(() => {
		checkStatus(data);
	}, [data]);

	const getStatusFun = () => (
		<>
			<span>{`Zoho People Status: `}</span>
			{
				!oauthStatusloading &&
				<>
					{statusMsgFromAuth === CRM_AUTH_STATUS.success ? (
						<DoneIcon htmlColor="#00ff00" />
					) : (
						<AccessTimeIcon htmlColor="#ff0000" />
					)}
					<span>{statusMsgFromAuth === CRM_AUTH_STATUS.success ? " Successful" : " Pending"}</span>
				</>
			}
			
		</>
	);

	return (
        <div className="zoho-crm">
			<div className="back-title">
				<Link prefetch={false} href="/settings/integrations">

                    <ArrowBackIcon htmlColor="#000" />

                </Link>
				<p>Back</p>
				{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="integrations"/> : null}
			</div>
			<div className="divider"></div>
			<h5>{getStatusFun()}</h5>
			<div className="integration_state">
				<FormControlLabel
					control={<Switch checked={zohoPeopleStatus === 1} />}
					label={zohoPeopleStatus === 1 ? "Enabled" : "Disabled"}
					onChange={() => enableZohoAttendancePeopleSwitch(zohoPeopleStatus as 0 | 1)}
					// disabled={disabled}
					disabled={!superAdmin || disabled}
					sx={{ cursor: !userWritePermission ? "not-allowed !important" : "pointer", pointerEvents: "auto" }}
				/>
			</div>
			
			<form className="crm-form" onSubmit={handleZohoSubmit}>
				<TextField
					disabled={zohoPeopleStatus == 0 || disabled || !superAdmin}
					type="email"
					required
					label="Zoho Email ID"
					name="email"
					onChange={handleChange}
					value={zohoCred.email.keyValue}
				/>
				<TextField
					required
					name="clientID"
					disabled={zohoPeopleStatus == 0 || disabled || !superAdmin}
					label="Client ID"
					onChange={handleChange}
					value={zohoCred.clientID.keyValue}
				/>
				<TextField
					disabled={zohoPeopleStatus == 0 || disabled || !superAdmin}
					required
					name="clientSecret"
					label="Client Secret"
					onChange={handleChange}
					value={zohoCred.clientSecret.keyValue}
				/>
				<FormControl fullWidth>
					<InputLabel id="dataCenter">Data Center</InputLabel>
					<Select
						name="dataCenter"
						required
						labelId="dataCenter"
						disabled={zohoPeopleStatus == 0 || disabled || !superAdmin}
						label="Data Center"
						value={zohoCred.dataCenter.keyValue}
						onChange={handleChange}>
						<MenuItem value="https://accounts.zoho.com/">US</MenuItem>
						<MenuItem value="https://accounts.zoho.com.au/">AU</MenuItem>
						<MenuItem value="https://accounts.zohkeyValueo.eu/">EU</MenuItem>
						<MenuItem value="https://accounts.zoho.in/">IN</MenuItem>
						<MenuItem value="https://accounts.zoho.com.cn/">CN</MenuItem>
						<MenuItem value="https://accounts.zoho.jp/">JP</MenuItem>
					</Select>
				</FormControl>
				<FormControl fullWidth>
					<InputLabel id="environment">ENVIRONMENT</InputLabel>
					<Select
						name="environment"
						required
						labelId="environment"
						disabled={zohoPeopleStatus == 0 || disabled || !superAdmin}
						label="ENVIRONMENT"
						value={zohoCred.environment.keyValue}
						onChange={handleChange}>
						<MenuItem selected value="PRODUCTION">
							Production
						</MenuItem>
						<MenuItem value="SANDBOX">Sandbox</MenuItem>
						<MenuItem value="DEVELOPMENT">Development</MenuItem>
					</Select>
				</FormControl>
				
				
				<button type="submit" disabled={zohoPeopleStatus == 0 || isSaving || disabled || !superAdmin}>
					Next
				</button>
				<FormControl fullWidth>
					<FormControlLabel
						sx={{ alignItems: "flex-end" }}
						control={<Checkbox sx={{ paddingRight: 0, margin:"0px 10px -8px 0px" }} checked={zohoLeavesStatus == 1} />}
						label="Export Approved Leaves"
						disabled={!superAdmin || disabled}
						onChange={() => enableZohoPeopleLeavesSwitch(zohoLeavesStatus as 0 | 1)}
					/>
				</FormControl>
			
				<Modal
					open={openRedirectModal}
					onClose={() => setOpenRedirectModal(false)}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
					style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					<Box sx={{ bgcolor: "background.paper", boxShadow: 24, p: 4, borderRadius: 4 }}>
						<h5 id="modal-title">You will be redirected to the Zoho to complete the authorization.</h5>
						<p style={{ marginTop: 20 }} id="modal-description">
							Please click Okay to continue.
						</p>
						<Button
							href={`${zohoCred?.dataCenter?.keyValue}oauth/v2/auth?scope=${zohoLeavesStatus == 1 ? "ZOHOPEOPLE.leave.READ,ZOHOPEOPLE.forms.CREATE,ZOHOPEOPLE.forms.READ,ZOHOPEOPLE.attendance.CREATE" : "ZOHOPEOPLE.attendance.CREATE"}&client_id=${zohoCred?.clientID?.keyValue}&state=testing&response_type=code&access_type=offline&redirect_uri=${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/preferences/zohoIntegration`}
							style={{ marginTop: 20 }}
							variant="contained"
							target="_blank"
							>
							Okay
						</Button>
					</Box>
				</Modal>
				{
					openMessageModal && 
					<MessageModal
						handleClose={()=>setOpenMessageModal(false)}
					/>
				}
			</form>
			<div className="integeration_message" style={{marginTop:"20px"}}>
				<p style={{ fontWeight: 600 }}>
					<i>Note- For successful integration, please make sure to follow the below steps.</i>
				</p>
				<ol type="1">
					<li>
						1. Make sure you set the Employee ID in the page{" "}
						<a
							style={{
								fontSize: "12px",
								fontWeight: "800",
								textDecoration: "underline",
								textUnderlineOffset: "3px",
								color: "#0d6efd",
								cursor: "pointer",
							}}
							href={"https://panel.unolo.com/organization/field_executive"}
							target="blank">
							https://panel.unolo.com/organization/field_executive
						</a>
						.{" "}
					</li>
					<li>2. Make sure the same Employee ID is mapped to the employee in Zoho people. </li>
					<li>3. In case of approved leaves export, make sure the name of Leave Type is same in Unolo and Zoho People both. </li>
					<li>4. By enabling the integration, attendance export is automatically enabled by default. However, you have the option to control the outflow of approved leaves from Unolo to Zoho. </li>
				</ol>
			</div>
		</div>
    );
};

export default ZohoPeopleIntegrations;
