// react
import { Dispatch, FC, FormEvent, SetStateAction, useState, useEffect } from "react";

// mui
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	styled,
	TooltipProps,
	tooltipClasses,
	Typography,
	Switch,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

// libraries
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import _ from "lodash";

// redux
import { APP_DISPATCH, showNotification } from "../../../redux";

// constants, types and utils
import { validateCustomField } from "../../../utils";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { CUSTOM_FIELD_METADATA, ENTITY_OPTIONS } from "../../../@types";

// query and mutation
import { UPSERT_ENTITYMETADATA_BATCH } from "../../../schema/entityMetadata";

//components
import UploadButton from "../Tasks/CustomTask/RightPart/upload/UploadButton";

const GET_FORM_OPTIONS = gql`
	query GetFormByCompanyID {
		get_form_metadata_by_companyID_v2 {
			formTemplateID
			formTitle
			visibility {
				visibleToAll
			}
		}
	}
`;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 270,
		fontSize: theme.typography.pxToRem(12),
		border: "1px solid #dadde9",
		padding: "10px",
		background: "#fff",
		position: "relative",
		left: "30px",
		boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
	},
}));

type Props = {
	setExpanded: Dispatch<SetStateAction<boolean>>;
	selectedEntity: "Clients" | "Tasks" | "Orders" | "products" | "Advances" | "Expenses" | "Conveyances";
	selectedCustomField: CUSTOM_FIELD_METADATA | undefined;
	customFieldMetadata: CUSTOM_FIELD_METADATA[];
	setCustomFieldMetadata: Dispatch<SetStateAction<CUSTOM_FIELD_METADATA[]>>;
	totalLength: number;
};
const CustomFieldSidebar: FC<Props> = ({
	setExpanded,
	selectedEntity,
	selectedCustomField,
	customFieldMetadata,
	setCustomFieldMetadata,
	totalLength,
}) => {
	const dispatch = useDispatch<APP_DISPATCH>();

	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState<string[]>([]);
	const [customField, setCustomField] = useState<CUSTOM_FIELD_METADATA>({
		fieldID: selectedCustomField?.fieldID ?? uuidv4(),
		isMandatory: selectedCustomField?.isMandatory ?? 0,
		active: selectedCustomField?.active ?? 1,
		fieldName: selectedCustomField?.fieldName ?? "",
		dataType: selectedCustomField?.dataType ?? "varchar",
		isRequired: selectedCustomField?.isRequired ?? 0,
		isDefault: selectedCustomField?.isDefault ?? 0,
		entity: selectedEntity === "products" ? "Sku" : selectedEntity,
		options: selectedCustomField?.options ?? [],
		primaryField: selectedCustomField?.primaryField ?? 0,
		argsOne: selectedCustomField?.argsOne ?? "",
		sequence: selectedCustomField?.sequence ?? totalLength,
		maskField: selectedCustomField?.maskField ?? 0,
		write: selectedCustomField?.write ?? (selectedEntity === "products" ? 0 : 1),
	} as CUSTOM_FIELD_METADATA);
	const [initialOptionState] = useState(_.cloneDeep(selectedCustomField?.options || []));
	const isDefault = customField.isDefault === 1;
	const title = `${selectedCustomField?.fieldID ? "Edit" : "Add"} Custom Field (${selectedEntity})`;

	// load form data
	const [getForms, { data, loading: formDataLoading }] = useLazyQuery(GET_FORM_OPTIONS);

	const [upsertEntitymetadataBatch, { loading }] = useMutation(UPSERT_ENTITYMETADATA_BATCH);

	// effects
	useEffect(() => {
		// reset argsOne when dataType changes
		if (!selectedCustomField) {
			setCustomField((prev) => ({ ...prev, argsOne: null }));
		}

		if (customField?.dataType === "form") {
			getForms();
		}
	}, [customField?.dataType]);

	// functions
	const handleChange = (name: string, value: string | number) => {
		if (name === "fieldName" && String(value).length > 200) {
			dispatch(showNotification({ message: "Maximum character limit exceeded", severity: "error" }));
		} else {
			if (name === "isRequired" && customField.entity !== "Sku" && customField.entity !== "Advances") {
				const requiredCurrentState = value;
				const writeCurrentState = customField.write;
				if (`${requiredCurrentState}${writeCurrentState}` === "10") {
					setCustomField({ ...customField, [name]: Number(value), write: 1 });
				} else setCustomField({ ...customField, [name]: Number(value) });
			} else setCustomField({ ...customField, [name]: value });
		}
	};

	const handleOptionChange = (id: number, e: any, option: string) => {
		const tempCustomField: any = { ...customField };
		const optionIndex = tempCustomField.options.findIndex((opt: any) => opt.optionID === id);
		tempCustomField.options[optionIndex][option] = e.target.value;
		setCustomField(tempCustomField);
	};
	const setDetailsAndSettingsForFields = (e: any, field: any, partName: string, override?: boolean) => {
		const tempCustomField: any = { ...customField };
		if (override && e) {
			tempCustomField.options = [];
			e.map((option: any) => {
				const newOption = {
					optionID: Math.floor(Math.random() * 100000000),
					optionValue: option.optionValue,
					isDeleted: false,
					terminalState: 0,
					considerOption: 1,
				};
				tempCustomField.options = [...tempCustomField.options, newOption];
				setCustomField(tempCustomField);
			});
		}
	};
	const addOption = () => {
		const tempCustomField: any = { ...customField };
		const newOption = {
			optionID: Math.floor(Math.random() * 100000000),
			optionValue: "",
			isDeleted: false,
			terminalState: 0,
			considerOption: 1,
		};
		tempCustomField.options = [...tempCustomField.options, newOption];
		setCustomField(tempCustomField);
	};

	const removeCustomField = (optionID: number) => {
		if (!customField.options || isNaN(optionID)) return;
		const oldOptions = customField.options && Array.isArray(customField.options) ? customField.options : [];
		const optionIndex = oldOptions.findIndex((option) => option.optionID === optionID);
		if (optionIndex === -1) return;
		oldOptions[optionIndex] = { ...oldOptions[optionIndex], isDeleted: true };
		setCustomField({ ...customField, options: oldOptions });
	};
	const processFinalState = (initialOptions: ENTITY_OPTIONS[], newOptions: ENTITY_OPTIONS[] = []) => {
		const optionMap = new Map();
		const newOptionsSet = new Set(newOptions.map((option) => option.optionValue));

		// Process initial options
		initialOptions.forEach((option) => {
			optionMap.set(option.optionValue, {
				...option,
				isDeleted: option.softDeleted === 1 || !newOptionsSet.has(option.optionValue),
			});
		});
		// Group new options by optionValue
		const newOptionGroups = new Map();
		newOptions.forEach((newOption) => {
			if (!newOptionGroups.has(newOption.optionValue)) {
				newOptionGroups.set(newOption.optionValue, []);
			}
			newOptionGroups.get(newOption.optionValue).push(newOption);
		});

		// Process grouped new options
		newOptionGroups.forEach((group, optionValue) => {
			const existingOption = optionMap.get(optionValue);
			const anyNotDeleted = group.some((opt: ENTITY_OPTIONS) => !opt.isDeleted);
			if (existingOption) {
				// Option with this value already exists
				if (existingOption.softDeleted === 1) {
					// If it was soft-deleted, keep it deleted unless explicitly undeleted
					optionMap.set(optionValue, {
						...existingOption,
						isDeleted: !anyNotDeleted,
					});
				} else {
					// If it wasn't soft-deleted, use the new isDeleted value
					optionMap.set(optionValue, {
						...existingOption,
						isDeleted: !anyNotDeleted,
					});
				}
			} else {
				// Completely new option
				const newOption = group[0]; // Take the first option of the group
				optionMap.set(optionValue, {
					...newOption,
					isDeleted: !anyNotDeleted,
				});
			}
		});

		// Check for updated values in initialOptions and newOptions

		initialOptions.forEach((initialOption) => {
			const updatedOption = newOptions.find(
				(newOption) =>
					newOption.optionID === initialOption.optionID && newOption.optionValue !== initialOption.optionValue
			);

			if (updatedOption) {
				// Mark the existing option as deleted
				optionMap.set(initialOption.optionValue, {
					...initialOption,
					isDeleted: true,
				});

				// Check if the updated value already exists in the old state
				const existingUpdatedOption = initialOptions.find((option) => option.optionValue === updatedOption.optionValue);

				// Add the updated option as a new entry with appropriate optionID
				const newOptionWithID = {
					optionID: existingUpdatedOption ? existingUpdatedOption.optionID : Math.floor(Math.random() * 100000000),
					optionValue: updatedOption.optionValue,
					isDeleted: false,
					terminalState: 0,
					considerOption: 1,
				};
				optionMap.set(updatedOption.optionValue, newOptionWithID);
			}
		});
		// Before returning, check for duplicates and adjust optionID if necessary
		optionMap.forEach((option, optionValue) => {
			const duplicate = initialOptions.find(
				(initialOption) => initialOption.optionValue === optionValue && initialOption.optionID !== option.optionID
			);
			if (duplicate) {
				// If a duplicate is found, update the optionID to the old one
				optionMap.set(optionValue, {
					...option,
					optionID: duplicate.optionID,
				});
			}
		});
		// Convert map back to array and clean up
		return Array.from(optionMap.values()).map((option) => {
			const cleanedOption = { ...option };
			delete cleanedOption.softDeleted;
			return cleanedOption;
		});
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsLoading(loading);
		const newErrors = validateCustomField(customField, customFieldMetadata);
		if (newErrors.length > 0) {
			setErrors(newErrors);
			setIsLoading(false);
			return;
		}

		// dont allow user to create any custom fields which have a related suffix to contact
		const doesFieldNameExistInContactField = customFieldMetadata.find((field) => {
			if (field.dataType === "contact") {
				if (
					`${field.fieldName} Name`.trim().toLowerCase() === customField.fieldName.trim().toLowerCase() ||
					`${field.fieldName} Number`.trim().toLowerCase() === customField.fieldName.trim().toLowerCase()
				) {
					return true;
				} else return false;
			}
		});

		if (doesFieldNameExistInContactField && !selectedCustomField?.fieldID) {
			setErrors([`This field name cannot be used.`]);
			setIsLoading(false);
			return;
		}

		const doesFieldNameExist = customFieldMetadata.find(
			(field) => field.fieldName.trim().toLowerCase() === customField.fieldName.trim().toLowerCase()
		);
		if (doesFieldNameExist && doesFieldNameExist?.entity === "Clients" && !selectedCustomField?.fieldID) {
			setErrors([`Field Name '${doesFieldNameExist.fieldName}' already exists`]);
			setIsLoading(false);
			return;
		}

		const isDefaultFieldName =
			customField.fieldName.trim().toLowerCase() === "Contact Number".trim().toLowerCase() ||
			customField.fieldName.trim().toLowerCase() === "Contact Person".trim().toLowerCase();

		if (isDefaultFieldName && customField.entity === "Clients") {
			setErrors([`This field name cannot be used.`]);
			setIsLoading(false);
			return;
		}

		if (
			customField?.dataType === "dropDown" ||
			customField?.dataType === "checkbox" ||
			customField?.dataType === "status"
		) {
			const optionArrayWithEmptyValue = customField.options?.filter((item) => item.optionValue.trim() === "");
			let valueFound = false;
			optionArrayWithEmptyValue?.forEach((item) => {
				if (!item.isDeleted) {
					valueFound = true;
					return;
				}
			});
			if (valueFound) {
				setErrors(["Option value cannot be empty"]);
				setIsLoading(false);
				return;
			}
			// check for duplicate options

			const optionArray = customField.options
				?.map((item) => {
					if (!item?.isDeleted && !item?.softDeleted) {
						return item.optionValue.trim().toLowerCase();
					}
				})
				?.filter((item) => item !== undefined);
			const Duplicates: any = [];
			const isDuplicate = optionArray?.some((item, index) => {
				if (optionArray.indexOf(item) !== index) {
					if (!Duplicates.includes(item)) {
						Duplicates.push(item); // Add the duplicate item to the duplicates array
					}
					return true;
				}
				return false;
			});
			if (isDuplicate) {
				setErrors([`Duplicate option values are not allowed -> ${Duplicates.join(", ")}`]);
				setIsLoading(false);
				return;
			}
		}

		if (customField?.fieldName?.trim() === "") {
			setErrors(["This field name cannot be empty"]);
			setIsLoading(false);
			return;
		}

		if (customField?.fieldName.length > 200) {
			dispatch(showNotification({ message: "Maximum character limit exceeded for field Name", severity: "error" }));
			return;
		}
		if (
			customField.dataType === "dropDown" ||
			customField.dataType === "checkbox" ||
			customField.dataType === "status"
		) {
			const allDeleted = customField.options?.every((item) => item.isDeleted === true);
			if (allDeleted) {
				dispatch(showNotification({ message: "Provide atleast one option before saving", severity: "error" }));
				setIsLoading(false);
				return;
			}
		}
		const finalOptionState = processFinalState(
			[...initialOptionState],
			customField.options?.filter((option) => !option.softDeleted)
		);

		const {
			data: {
				upsert_entitymetadata_batch: { success },
			},
		} = await upsertEntitymetadataBatch({
			variables: {
				input: [
					{
						fieldName: customField.fieldName.replace(/^\s+|\s+$/g, "").replace(/\s+/g, " "),
						isRequired: customField.isRequired,
						dataType: customField.dataType,
						entity: customField.entity,
						active: customField.active,
						fieldID: customField.fieldID,
						options: finalOptionState,
						primaryField: customField.primaryField,
						isDeleted: customField.isDeleted ?? 0,
						write: customField.write ?? 1,
						argsOne: customField.argsOne,
						sequence: customField.sequence,
						timeStamp: moment().valueOf(),
						isMandatory: customField.isMandatory,
						maskField: customField.maskField,
						isDefault: customField.isDefault,
					},
				],
			},
		});

		dispatch(
			showNotification({
				message: success ? "Custom Fields updated successfully" : "Something went wrong",
				severity: success ? "success" : "error",
			})
		);
		const newCustomFieldMetadata = [...customFieldMetadata];
		if (success) {
			const customFieldIndex = newCustomFieldMetadata.findIndex((field) => field.fieldID === customField?.fieldID);
			if (customFieldIndex !== -1)
				newCustomFieldMetadata[customFieldIndex] = {
					...customField,
					options: finalOptionState,
					fieldName: customField.fieldName.replace(/^\s+|\s+$/g, "").replace(/\s+/g, " "),
				};
			else
				newCustomFieldMetadata.push({
					...customField,
					options: finalOptionState,
					fieldName: customField.fieldName.replace(/^\s+|\s+$/g, "").replace(/\s+/g, " "),
				}); //while saving in local state it will remove spaces
			setCustomFieldMetadata(newCustomFieldMetadata);
		}
		setIsLoading(loading);
		setExpanded(false);
	};

	return (
		<div className="user_add custom_fields" style={{ width: "500px" }}>
			<div className="user_add-title">
				<h1>{title}</h1>
				<button onClick={() => setExpanded(false)}>
					<CloseOutlinedIcon />
				</button>
			</div>
			<form className="user_add-form" onSubmit={handleSubmit}>
				<TextField
					required
					name="fieldName"
					label="Enter Field Name"
					disabled={isDefault || selectedCustomField?.fieldName === "Order Status"}
					value={customField?.fieldName || ""}
					onChange={(e) => handleChange("fieldName", e.target.value)}
					onKeyDown={(e) => {
						if (e.type === "keydown" && e.key === "Enter") e.preventDefault();
					}}
					size="small"
				/>
				<hr className="p-0 m-0" />
				<div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
					<div className="d-flex justify-content-between">
						<div className="d-flex">
							<Typography sx={{ fontSize: "14px", color: isDefault ? "#00000061" : "", fontWeight: "bold" }}>
								Active
							</Typography>
							<HtmlTooltip
								title={
									<span style={{ fontSize: "14px", color: "gray" }}>
										Note:
										<div style={{ background: "#c7c7c7", height: "1px", margin: "2px 0 5px 0" }} />
										Changing <b>active</b> status effects the visibility of custom field in targets.
									</span>
								}>
								<InfoIcon sx={{ height: "16px", color: "#9e9e9e", cursor: "pointer" }} />
							</HtmlTooltip>
						</div>

						<AntSwitch
							inputProps={{ "aria-label": "ant design" }}
							checked={customField.active === 0 ? false : true}
							disabled={isDefault}
							onChange={(e: any) => handleChange("active", e.target.checked ? 1 : 0)}
							name="active"
						/>
					</div>
					<div className="d-flex justify-content-between">
						<Typography sx={{ fontSize: "14px", color: isDefault ? "#00000061" : "", fontWeight: "bold" }}>
							Required
						</Typography>
						<AntSwitch
							inputProps={{ "aria-label": "ant design" }}
							checked={customField.isRequired === 0 ? false : true}
							disabled={isDefault}
							onChange={(e: any) => handleChange("isRequired", e.target.checked ? 1 : 0)}
							name="isRequired"
						/>
					</div>

					{customField.entity !== "Sku" && customField.entity !== "Advances" && (
						<div className="d-flex justify-content-between">
							<div className="d-flex">
								{" "}
								<Typography
									sx={{
										fontSize: "14px",
										color: isDefault || customField.isRequired === 1 ? "#00000061" : "",
										fontWeight: "bold",
									}}>
									Read Only (For Employees)
								</Typography>
								<HtmlTooltip
									// @ts-ignore
									placement="bottom-end"
									title={
										<span style={{ fontSize: "14px", color: "gray" }}>
											Note:
											<div style={{ background: "#c7c7c7", height: "1px", margin: "2px 0 5px 0" }} />
											{customField.isRequired === 1 && (
												<>
													1. Read Only can't be enabled if Required is turned on.
													<br />
												</>
											)}
											{customField.isRequired === 1 ? "2." : "1."} Changing <b>Read Only</b> effects employee's ability
											to edit the custom field.
										</span>
									}>
									<InfoIcon sx={{ height: "16px", color: "#9e9e9e", cursor: "pointer" }} />
								</HtmlTooltip>
							</div>
							<AntSwitch
								inputProps={{ "aria-label": "ant design" }}
								checked={customField.write === 0 ? true : false}
								disabled={isDefault || customField.isRequired === 1 ? true : false}
								onChange={(e: any) => handleChange("write", e.target.checked ? 0 : 1)}
								name="write"
							/>
						</div>
					)}
					{(customField?.dataType === "varchar" ||
						customField?.dataType === "number" ||
						customField?.dataType === "contact") &&
						selectedEntity === "Clients" && (
							<div className="d-flex justify-content-between">
								<Typography sx={{ fontSize: "14px", color: isDefault ? "#00000061" : "", fontWeight: "bold" }}>
									Check Duplicate
								</Typography>
								<AntSwitch
									inputProps={{ "aria-label": "ant design" }}
									checked={customField.primaryField === 0 ? false : true}
									disabled={isDefault}
									onChange={(e: any) => {
										handleChange("primaryField", e.target.checked ? 1 : 0);
									}}
									name="isDuplicate"
								/>
							</div>
						)}
					{selectedEntity === "Clients" && (
						<div className="d-flex justify-content-between">
							<div className="d-flex">
								<Typography
									sx={{
										fontSize: "14px",
										color: isDefault || customField.isRequired === 1 ? "#00000061" : "",
										fontWeight: "bold",
									}}>
									Enable Masking
								</Typography>
								<HtmlTooltip
									// @ts-ignore
									placement="bottom-end"
									title={
										<span style={{ fontSize: "14px", color: "gray" }}>
											Note:
											<div style={{ background: "#c7c7c7", height: "1px", margin: "2px 0 5px 0" }} />
											1. Masking will hide the data from the employee.
										</span>
									}>
									<InfoIcon sx={{ height: "16px", color: "#9e9e9e", cursor: "pointer" }} />
								</HtmlTooltip>
							</div>

							<AntSwitch
								inputProps={{ "aria-label": "ant design" }}
								checked={customField.maskField === 0 ? false : true}
								disabled={isDefault}
								onChange={(e: any) => {
									handleChange("maskField", e.target.checked ? 1 : 0);
								}}
								name="maskField"
							/>
						</div>
					)}
				</div>
				<hr className="p-0 m-0" />
				<FormControl size="small">
					<InputLabel id="select_type">Select Type</InputLabel>
					<Select
						labelId="select_type"
						label="Select any one"
						disabled={
							selectedCustomField?.fieldID
								? true
								: false || isDefault || selectedCustomField?.fieldName === "Order Status"
						}
						value={customField?.dataType || ""}
						onChange={(e) => handleChange("dataType", e.target.value)}>
						<MenuItem value="varchar">Text</MenuItem>
						<MenuItem value="number">Number</MenuItem>
						<MenuItem value="address">Address</MenuItem>
						<MenuItem value="date">Date</MenuItem>
						<MenuItem value="dropDown">Drop Down</MenuItem>
						<MenuItem value="checkbox">Checkbox</MenuItem>
						{selectedEntity === "Orders" && selectedCustomField?.fieldID && <MenuItem value="status">Status</MenuItem>}
						{customField?.entity !== "Sku" && <MenuItem value="contact">Contact</MenuItem>}
						{/* TODO: Custom Fields Switched Off */}
						{customField?.entity !== "Sku" && <MenuItem value="file">File</MenuItem>}
						{customField?.entity !== "Sku" && <MenuItem value="photo">Photo</MenuItem>}
						{customField?.entity !== "Sku" && <MenuItem value="form">Form</MenuItem>}
					</Select>
				</FormControl>
				{customField?.dataType === "photo" && (
					<span
						style={{
							fontSize: "14px",
							color: "#56564D",
							backgroundColor: "#FFFFEA",
							padding: "0.8rem",
							borderRadius: "5px",
							fontFamily: "Roboto, Helvetica, Arial, sans-serif",
							border: "1px solid #E5DEC5",
							lineHeight: "1.2rem",
							marginTop: "1rem",
						}}>
						Note:
						<div style={{ background: "#c7c7c7", height: "1px", margin: "2px 0 5px 0" }} />
						1. Max file size supported is 3MB.
						<br />
						2. File format supported are .jpg, .jpeg, .svg, .png, or .bmp.
					</span>
				)}

				{customField?.dataType === "file" && (
					<span
						style={{
							fontSize: "14px",
							color: "#56564D",
							backgroundColor: "#FFFFEA",
							padding: "0.8rem",
							borderRadius: "5px",
							fontFamily: "Roboto, Helvetica, Arial, sans-serif",
							border: "1px solid #E5DEC5",
							lineHeight: "1.2rem",
							marginTop: "1rem",
						}}>
						Note:
						<div style={{ background: "#c7c7c7", height: "1px", margin: "2px auto 5px auto" }} />
						1. Max file size supported is 5MB.
						<br />
						2. File format supported are .pdf, .xlsx, .doc, .xls, .ppt, .pptx, .txt, .jpg, .jpeg, .svg, .png, or .bmp.
					</span>
				)}

				{customField?.dataType === "contact" && (
					<span
						style={{
							fontSize: "14px",
							color: "#56564D",
							backgroundColor: "#FFFFEA",
							padding: "0.8rem",
							borderRadius: "5px",
							fontFamily: "Roboto, Helvetica, Arial, sans-serif",
							border: "1px solid #E5DEC5",
							lineHeight: "1.2rem",
							marginTop: "1rem",
						}}>
						Note:
						<div style={{ background: "#c7c7c7", height: "1px", margin: "2px auto 5px auto" }} />
						1. This will include Contact Name, Country Code and Contact Number.
					</span>
				)}

				{customField?.dataType === "form" && (
					<>
						<FormControl size="small">
							<InputLabel id="select_type">Select Form</InputLabel>
							<Select
								labelId="select_type"
								label="Select any one"
								disabled={
									selectedCustomField?.fieldID
										? true
										: false || isDefault || selectedCustomField?.fieldName === "Order Status"
								}
								value={customField?.argsOne || ""}
								onChange={(e) => handleChange("argsOne", e.target.value)}>
								{formDataLoading && (
									<MenuItem value="" disabled>
										Loading...
									</MenuItem>
								)}
								{data?.get_form_metadata_by_companyID_v2.map((form: any) => {
									if (form?.visibility?.visibleToAll) {
										return (
											<MenuItem
												value={form.formTemplateID}
												key={form.formTemplateID}
												sx={{ width: "300px", lineBreak: "anywhere" }}>
												{form.formTitle}
											</MenuItem>
										);
									}
								})}
							</Select>
						</FormControl>

						<span
							style={{
								fontSize: "14px",
								color: "#56564D",
								backgroundColor: "#FFFFEA",
								padding: "0.8rem",
								borderRadius: "5px",
								fontFamily: "Roboto, Helvetica, Arial, sans-serif",
								border: "1px solid #E5DEC5",
								lineHeight: "1.2rem",
								marginTop: "1rem",
							}}>
							Note:
							<div style={{ background: "#c7c7c7", height: "1px", margin: "2px auto 5px auto" }} />
							1. Only forms that are visible to <b>All Team's</b> can be seleted.
						</span>
					</>
				)}

				{["dropDown", "checkbox", "status"].includes(customField?.dataType) && (
					<div className="options">
						<div
							style={{
								padding: "0.5rem",
								borderRadius: "4px",
								backgroundColor: "#f4f4f5",
							}}>
							<div
								style={{
									fontFamily: "Roboto, Helvetica, Arial, sans-serif",
									marginBottom: "1rem",
									fontWeight: "bold",
								}}>
								Create options
							</div>

							{Array.isArray(customField.options) &&
								customField.options
									.filter((o) => !o.isDeleted)
									.map((option) => {
										if (!option.softDeleted) {
											return (
												<div
													className="d-flex"
													key={option.optionID}
													style={{
														backgroundColor: "white",
														marginBottom: "10px",
														padding: "10px",
														borderRadius: "8px",
														gap: "10px",
													}}>
													<TextField
														disabled={isDefault}
														label="Enter New Option"
														key={option.optionID}
														variant="standard"
														InputLabelProps={{ shrink: true }}
														className={`${customField?.dataType !== "status" ? "w-75" : "35%"}`}
														style={{
															marginTop: "3px",
														}}
														value={option.optionValue}
														onChange={(e) => {
															if (e.target.value.length <= 40) {
																handleOptionChange(option.optionID, e, "optionValue");
															}
														}}
														onKeyDown={(e) => {
															if (e.key === "Enter") {
																e.preventDefault();
																addOption();
															}
														}}
														size="small"
													/>
													<FormControl
														variant="standard"
														className={`${customField?.dataType !== "status" && "d-none"}`}
														sx={{ minWidth: 110 }}>
														<InputLabel id="demo-simple-select-label" shrink style={{ fontSize: "16px" }}>
															Terminal State ?
														</InputLabel>
														<Select
															labelId="demo-simple-select-label"
															id={option.optionID.toString()}
															variant="standard"
															value={option?.terminalState}
															onChange={(e) => {
																handleOptionChange(option.optionID, e, "terminalState");
															}}>
															<MenuItem value={1}>Yes</MenuItem>
															<MenuItem value={0}>No</MenuItem>
														</Select>
													</FormControl>
													<FormControl
														variant="standard"
														className={`${customField?.dataType !== "status" && "d-none"}`}
														sx={{ minWidth: 170 }}>
														<InputLabel id="demo-simple-select-label" shrink style={{ fontSize: "16px" }}>
															Include in Total Order Value ?
														</InputLabel>
														<Select
															labelId="demo-simple-select-label"
															id={option.optionID.toString()}
															variant="standard"
															value={option?.considerOption}
															onChange={(e) => {
																handleOptionChange(option.optionID, e, "considerOption");
															}}>
															<MenuItem value={1}>Yes</MenuItem>
															<MenuItem value={0}>No</MenuItem>
														</Select>
													</FormControl>
													<button
														className=""
														type="button"
														onClick={() => removeCustomField(option.optionID)}
														disabled={isDefault}>
														<CloseOutlinedIcon sx={{ color: "red", marginTop: "0px" }} />
													</button>
												</div>
											);
										}
									})}
							<div style={{ display: "flex", gap: "1rem", alignItems: "flex-start" }}>
								<button
									// type="button"
									className="btn btn-primary btn-sm mt-4"
									style={{
										backgroundColor: "#1976d2",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										gap: "0.5rem",
									}}
									onClick={(e) => {
										e.preventDefault();
										addOption();
									}}>
									<AddOutlinedIcon fontSize="small" /> Add Option
								</button>
								<UploadButton
									selectedField={customField}
									setDetailsAndSettingsForFields={setDetailsAndSettingsForFields}
								/>
							</div>
						</div>
						{customField?.dataType === "status" && (
							<div
								style={{
									fontSize: "14px",
									color: "#56564D",
									backgroundColor: "#FFFFEA",
									padding: "0.8rem",
									borderRadius: "5px",
									fontFamily: "Roboto, Helvetica, Arial, sans-serif",
									border: "1px solid #E5DEC5",
									lineHeight: "1.2rem",
									marginTop: "1rem",
								}}>
								Note:
								<div style={{ background: "#c7c7c7", height: "1px", margin: "2px auto 5px auto" }} />
								<strong>Terminal:</strong> If a Stage is marked as 'Terminal State - Yes', the field employee will not
								be able to edit the order stage once a terminal state is selected.
							</div>
						)}
					</div>
				)}

				<ul className="errors">
					{errors.map((error) => (
						<li key={error}>{error}</li>
					))}
				</ul>
				<div className="action_button">
					<button type="reset" onClick={() => setExpanded(false)} className="cancel_button_custom_fields">
						Cancel
					</button>

					<button type="submit" disabled={isLoading || isDefault}>
						{isLoading ? <CircularProgress style={{ width: "15px", height: "15px" }} /> : "Save"}
					</button>
				</div>
			</form>
		</div>
	);
};

export default CustomFieldSidebar;

const AntSwitch = styled(Switch)(({ theme }) => ({
	width: 28,
	height: 16,
	padding: 0,
	display: "flex",
	"&:active": {
		"& .MuiSwitch-thumb": {
			width: 15,
		},
		"& .MuiSwitch-switchBase.Mui-checked": {
			transform: "translateX(9px)",
		},
	},
	"& .MuiSwitch-switchBase": {
		padding: 2,
		"&.Mui-checked": {
			transform: "translateX(12px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
			},
		},
		"&.Mui-disabled": {
			color: "#00000061",
			"& + .MuiSwitch-track": {
				backgroundColor: "#00000061",
			},
		},
	},
	"& .MuiSwitch-thumb": {
		boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
		width: 12,
		height: 12,
		borderRadius: 6,
		transition: theme.transitions.create(["width"], {
			duration: 200,
		}),
	},
	"& .MuiSwitch-track": {
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
		boxSizing: "border-box",
	},
}));
