import { useQuery } from "react-query";
import { fetchAdminEntitlements, fetchCompanySettings } from "../utils";

export default function useAdminEntitlements(module: string, mode: string) {
	const { data } = useQuery("adminEntitlements", fetchAdminEntitlements, {
		staleTime: 200000,
		cacheTime: 200000,
		refetchOnWindowFocus: true,
		refetchOnReconnect: true,
		refetchInterval: 200000,
	});
	const adminEntitlements = data?.data?.data;

	return adminEntitlements && adminEntitlements[module] ? adminEntitlements[module][mode] : true;
}

export const useAdminCompanySettings = (module: string) => {
	const { data } = useQuery("companySettings", fetchCompanySettings, {
		staleTime: 200000,
		cacheTime: 200000,
		refetchOnWindowFocus: true,
		refetchOnReconnect: true,
		refetchInterval: 200000,
	});
	const companySettings = data?.data?.data;
	return companySettings && companySettings[module] ? companySettings[module] : false;
};
