/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-alert */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DoneIcon from "@mui/icons-material/Done";
import { Button, TextField } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import axios from "../../../utils/axios";
import _ from "lodash";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { useDispatch } from "react-redux";

type Props = {
	geofencePool: any;
	setSelectedGeofencePoolID: any;
	geofences: any;
	setIsGeofencePoolEdit: any;
	setGeofenceIDs: any;
	setGeofencePools: any;
	geofencePools: any;
	geofenceIDs: any;
};

const GeofencePoolEdit: FC<Props> = ({
	geofencePool,
	setSelectedGeofencePoolID,
	geofences,
	setIsGeofencePoolEdit,
	setGeofenceIDs,
	setGeofencePools,
	geofencePools,
	geofenceIDs,
}) => {
	const [geofencePoolName, setGeofencePoolName] = useState<null | string>("");
	const [searchTerm, setSearchTerm] = useState("");
	const [geofencePoolData, setGeofencePoolData] = useState<null | any>(geofencePool);
	const [dataChanged, setDataChanged] = useState({});
	const [isSaving, setIsSaving] = useState(false);
	const dispatch = useDispatch<APP_DISPATCH>();
	useEffect(() => {
		if (geofencePool) {
			setGeofencePoolName(geofencePool.name);
		}
	}, [geofencePool]);

	const onGeofenceSelected = (geofence: any, checked: any) => {
		if (checked) {
			const wasPresent = geofencePool.geofenceList.find((g: any) => g.geofenceID === geofence.geofenceID)
				? true
				: false;
			const tempDataChanged: any = { ...dataChanged };
			if (wasPresent) {
				delete tempDataChanged[geofence.geofenceID];
			} else {
				tempDataChanged[geofence.geofenceID] = { new: true };
			}
			setDataChanged(tempDataChanged);
			setGeofencePoolData({ ...geofencePoolData, geofenceList: [...geofencePoolData.geofenceList, geofence] });
		} else {
			const wasPresent = geofencePool.geofenceList.find((g: any) => g.geofenceID === geofence.geofenceID)
				? true
				: false;
			const tempDataChanged: any = { ...dataChanged };
			if (wasPresent) {
				tempDataChanged[geofence.geofenceID] = { deleted: true };
			} else {
				delete tempDataChanged[geofence.geofenceID];
			}
			setDataChanged(tempDataChanged);
			setGeofencePoolData({
				...geofencePoolData,
				geofenceList: geofencePoolData.geofenceList.filter((g: any) => g.geofenceID !== geofence.geofenceID),
			});
		}
	};

	const debouncedSearch = useMemo(
		() =>
			_.debounce(function (e) {
				const name = e.target.value;
				setSearchTerm(name);
			}, 500),
		[]
	);

	const filteredGeofences = geofences.filter((g: any) =>
		g.name.toLowerCase().replace(/ /g, "").includes(searchTerm.replace(/ /g, "").toLowerCase())
	);

	const onSave = () => {
		const toSend = {
			geofencePoolID: geofencePoolData["geofencePoolID"],
			data: dataChanged,
			name: geofencePoolName,
		};
		setIsSaving(true);

		axios
			.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/geofence/updateGeoFencePool`, toSend)
			.then((res) => {
				if (res?.data?.success == "success") {
					dispatch(showNotification({ message: "Geofence pool updated successfully", severity: "success" }));
				}
				let tempGeofencePools: any = [...geofencePools];
				tempGeofencePools = tempGeofencePools.map((gP: any) => {
					if (gP.geofencePoolID === geofencePoolData.geofencePoolID) {
						return { ...geofencePoolData, name: geofencePoolName };
					}
					return gP;
				});
				setGeofencePools(tempGeofencePools);

				const tempGeofenceIDs = { ...geofenceIDs };
				tempGeofenceIDs[geofencePoolData.geofencePoolID] = geofencePoolData.geofenceList.map((g: any) => g.geofenceID);
				setGeofenceIDs(tempGeofenceIDs);
				setIsGeofencePoolEdit(false);
			})
			.catch(() => {
				dispatch(showNotification({ message: "Error updating geofence pool", severity: "error" }));
			});
		setIsSaving(false);
	};

	const onSaveNew = () => {
		const toSend = {
			name: geofencePoolName,
		};
		setIsSaving(true);
		axios
			.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/geofence/addNewGeofencePool`, toSend)
			.then((res: any) => {
				const newGeofancePoolID = res.data.data.geofencePoolID;

				const toSendPoolDetails = {
					geofencePoolID: newGeofancePoolID,
					data: dataChanged,
					name: geofencePoolName,
				};

				const tempGeofencePools: any = [...geofencePools];
				tempGeofencePools.push({ name: geofencePoolName, geofencePoolID: newGeofancePoolID });
				setGeofencePools(tempGeofencePools);

				axios
					.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/geofence/updateGeoFencePool`, toSendPoolDetails)
					.then((res) => {
						if (res?.data?.success == "success") {
							dispatch(showNotification({ message: "Geofence pool successfully added", severity: "success" }));
						}
						const tempGeofenceIDs = { ...geofenceIDs };
						tempGeofenceIDs[newGeofancePoolID] = geofencePoolData.geofenceList.map((g: any) => g.geofenceID);
						setGeofenceIDs(tempGeofenceIDs);
						setSelectedGeofencePoolID(null);
						setIsGeofencePoolEdit(false);
					})
					.catch(() => {});
			})
			.catch(() => {
				dispatch(showNotification({ message: "Error adding geofence pool", severity: "error" }));
			});
		setIsSaving(false);
	};

	return (
		<div className="user_add geofence_pool_sidebar">
			<div className="geofence_add_edit">
				<ArrowBackIcon
					sx={{ cursor: "pointer" }}
					onClick={() => {
						setIsGeofencePoolEdit(false);
					}}
				/>
				<div>{geofencePoolName}</div>
			</div>
			<div className="geofence_add_edit_form">
				<div
					style={{
						height: "90%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						gap: "1rem",
					}}>
					<TextField
						required
						name="Geofence Pool Name"
						label="Geofence Pool Name"
						onChange={(e) => {
							setGeofencePoolName(e.target.value);
						}}
						inputProps={{ maxLength: 45 }}
						value={geofencePoolName ?? ""}
						id="geofencePoolNameValue"
						error={geofencePoolName === ""}
						helperText={geofencePoolName === "" ? "Geofence Pool Name is required" : ""}
						sx={{ width: "100%" }}
					/>
					<input type="search" onChange={(e) => debouncedSearch(e)} placeholder="Search Here.." className="search" />
					<div style={{ width: "100%", overflow: "auto", flex: "1", height: "100%" }}>
						<ul>
							{filteredGeofences.map((geofence: any) => {
								const checked = geofencePoolData.geofenceList.find((g: any) => g.geofenceID === geofence.geofenceID)
									? true
									: false;
								return (
									<li
										style={{ justifyContent: "flex-start", gap: "1rem" }}
										key={geofence["geofenceID"]}
										onClick={() => onGeofenceSelected(geofence, !checked)}>
										<input
											style={{ width: "auto" }}
											type="checkbox"
											onChange={(e: any) => {
												onGeofenceSelected(geofence, e.target.checked);
											}}
											checked={checked}
										/>
										<p>{geofence["name"]}</p>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
				<div className="footer">
					<Button
						size="medium"
						variant="contained"
						fullWidth
						startIcon={<DoneIcon />}
						onClick={geofencePoolData.geofencePoolID === "-1" ? onSaveNew : onSave}
						disabled={geofencePoolName === "" || isSaving}>
						Save
					</Button>
				</div>
			</div>
		</div>
	);
};

export default GeofencePoolEdit;
