// react
import { FC, memo, useCallback, useMemo, useState, useEffect } from "react";

// library
import GoogleMapReact from "google-map-react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

// mui
import { CircularProgress } from "@mui/material";

// components and utils
import Marker from "./Marker";
import { mapOptions, mapStyles } from "../../../utils";

// query
import { useQuery } from "@apollo/client";
import { GET_ALL_CLIENTS } from "../../../schema/sites_clients";
import { DASHBOARD, PARTIAL_CLIENTS_DATA, SITES_DATA } from "../../../@types";

type Props = {
	filteredMarkers: DASHBOARD[] | undefined;
	showClients: boolean;
	center: {
		lat: number;
		lng: number;
	};
	zoom: number;
	loadingAllData: boolean;
	showSites: boolean;
	sites: SITES_DATA[];
	filteredCards: any;
	variable: any;
};

const SITE_MARKER_COLOR = "#243140";
const polyMarkerColor = "#3FC097";
const polygonOptions = {
	strokeColor: polyMarkerColor,
	strokeOpacity: 0.8,
	strokeWeight: 2,
	fillColor: polyMarkerColor,
	fillOpacity: 0.35,
};
let Popup: any;
const regionMarkers: any = {};

type Location = {
	lat: number;
	lng: number;
	clientID: string;
	clientName: string;
	radius: number;
};

type PointMarkers = {
	[clientID: string]: {
		marker: any;
		circle: any;
		client: Location;
	};
};

const createPopupClass = (maps: any) => {
	/**
	 * A customized popup on the map.
	 * @param {!google.maps.LatLng} position
	 * @param {!Element} content The bubble div.
	 * @constructor
	 * @extends {google.maps.OverlayView}
	 */
	function Popup(this: any, position: any, content: any) {
		this.position = position;

		content.classList.add("popup-bubble");

		var bubbleAnchor = document.createElement("div");
		bubbleAnchor.classList.add("popup-bubble-anchor");
		bubbleAnchor.appendChild(content);

		this.containerDiv = document.createElement("div");
		this.containerDiv.classList.add("popup-container");
		this.containerDiv.appendChild(bubbleAnchor);

		maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
	}

	Popup.prototype = Object.create(maps.OverlayView.prototype);

	Popup.prototype.onAdd = function () {
		this.getPanes().floatPane.appendChild(this.containerDiv);
	};

	Popup.prototype.setText = function (text: string) {
		this.containerDiv.childNodes[0].childNodes[0].innerHTML = text;
	};

	Popup.prototype.onRemove = function () {
		if (this.containerDiv.parentElement) {
			this.containerDiv.parentElement.removeChild(this.containerDiv);
		}
	};

	Popup.prototype.draw = function () {
		var divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

		var display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? "block" : "none";

		if (display === "block") {
			this.containerDiv.style.left = divPosition.x + "px";
			this.containerDiv.style.top = divPosition.y + "px";
		}
		if (this.containerDiv.style.display !== display) {
			this.containerDiv.style.display = display;
		}
	};

	return Popup;
};

const LiveLocationMap: FC<Props> = (props) => {
	const { filteredMarkers, showClients, showSites, sites, center, zoom, loadingAllData, filteredCards, variable } =
		props;

	const { data: dataClients } = useQuery(GET_ALL_CLIENTS);
	const [map, setMap] = useState<any>(null);
	const [maps, setMaps] = useState<any>(null);
	const key = useMemo(() => JSON.stringify(showSites), [showSites]);

	// and operation of filteredMarkers and filteredCards based on employeeID
	const filteredMarkersWithSearch: any = useMemo(() => {
		if (filteredMarkers && filteredCards && variable.searchKey) {
			const filteredMarkersAndCards = filteredMarkers.filter((marker) =>
				filteredCards.some((card: any) => card.employeeID === marker.employeeID)
			);
			return filteredMarkersAndCards;
		}
		return filteredMarkers;
	}, [filteredMarkers, filteredCards, variable.searchKey]);

	useEffect(() => {
		if (map && maps && filteredMarkersWithSearch.length > 0) {
			const globalBounds = new maps.LatLngBounds();
			filteredMarkersWithSearch.forEach((user: any) => {
				if (!user?.lastLocation) return;
				const lat = user?.lastLocation?.[0]?.lat || 0;
				const lng = user?.lastLocation?.[0]?.lon || 0;
				const center = new maps.LatLng(lat, lng);
				globalBounds.extend(center);
			});
			map.fitBounds(globalBounds);
		}
	}, [map, maps, variable.searchKey, variable.workingStatusFilter]);

	const genMarker = (maps: any, center: any, name: string, siteID: string) => {
		var markerIcon2 = {
			url: "/images/siteMarker.png",
			scaledSize: new maps.Size(15, 15),
			labelOrigin: new maps.Point(20, -10),
			anchor: new maps.Point(6.5, 6.5),
		};

		return new maps.Marker({
			position: center,
			id: siteID,
			draggable: false,
			icon: markerIcon2,
			title: name,
		});
	};

	const genCircle = (maps: any, center: any, radius: number) =>
		new maps.Circle({
			strokeColor: SITE_MARKER_COLOR,
			strokeOpacity: 0.4,
			strokeWeight: 2,
			fillColor: SITE_MARKER_COLOR,
			fillOpacity: 0.15,
			center: center,
			radius: radius || 200,
			editable: false,
			draggable: false,
		});

	const zoomToSite = (map: any, maps: any, location: any) => {
		var bounds = new maps.LatLngBounds();
		bounds.extend(location);

		map.fitBounds(bounds);
		map.setZoom(17);
	};

	const zoomToPolygon = useCallback((locations: any, maps: any, map: any) => {
		var bounds = new maps.LatLngBounds();
		locations.map((o: any) => bounds.extend(o));
		map.fitBounds(bounds);
	}, []);

	const calcHighestPointOfPolygon = (path: any, maps: any) => {
		var bounds = new maps.LatLngBounds();

		for (var i = 0; i < path.length; i++) {
			bounds.extend(path[i]);
		}

		return bounds.getCenter();
	};

	const addPolygon = useCallback(
		(polygon: any, site: any, maps: any, map: any) => {
			var center = calcHighestPointOfPolygon(polygon.getPath().getArray(), maps);
			var iDiv = document.createElement("div");
			var node = document.createTextNode(site.clientName);
			iDiv.append(node);
			var popup = new Popup(center, iDiv);
			popup.setMap(map);
			popup.containerDiv.addEventListener("click", () => {
				// sidebarDrawerHandlerFunction(site);
				zoomToPolygon(polygon.getPath().getArray(), maps, map);
				polygon.setMap(map);
			});

			//used for state management
			return { polygon: polygon, infowindow: popup, site: site };
		},
		[zoomToPolygon]
	);

	const handleApiLoaded = (map: any, maps: any) => {
		setMap(map);
		setMaps(maps);
		const globalBounds = new maps.LatLngBounds();
		Popup = createPopupClass(maps);
		map.setOptions({ styles: mapStyles });
		const locations: Location[] = [];
		const regions: any = [];

		if (showSites) {
			sites.map((u: any) => {
				if (u["polyline"]) {
					regions.push({
						lat: u.latitude,
						lng: u.longitude,
						clientID: u.clientID,
						clientName: u.clientName,
						radius: u.radius,
						polyline: u.polyline,
					});
				} else {
					locations.push({
						lat: u.latitude,
						lng: u.longitude,
						clientID: u.clientID,
						clientName: u.clientName,
						radius: u.radius,
					});
				}
			});
		}

		/**
		 * markers draw
		 */
		const tempPointMarkers: PointMarkers = {};

		const markers =
			locations &&
			locations.map((location) => {
				const center = new maps.LatLng(location.lat, location.lng);
				const marker = genMarker(
					maps,
					center,
					location.clientName.replace(/(\r\n\t|\n|\r\t)/gm, ""),
					location.clientID
				);
				const circle = genCircle(maps, { lat: location.lat, lng: location.lng }, location.radius);

				tempPointMarkers[location.clientID] = {
					marker: marker,
					circle: circle,
					client: location,
				};

				maps.event.addListener(marker, "click", function () {
					// sidebarDrawerHandlerFunction(location);
					zoomToSite(map, maps, center);
					circle.setMap(map);
				});

				return marker;
			});
		new MarkerClusterer({ map, markers });

		regions.forEach(function (region: any) {
			var decodedPath = maps.geometry.encoding.decodePath(region.polyline);
			const pOpts = Object.assign(polygonOptions, { paths: decodedPath, id: region.clientID });
			const polygon = new maps.Polygon(pOpts);
			regionMarkers[region.clientID] = addPolygon(polygon, region, maps, map);
			maps.event.addListener(polygon, "click", function () {
				// setDrawerOpen(true);
				zoomToPolygon(polygon.getPath().getArray(), maps, map);
				polygon.setMap(map);
			});
		});

		filteredMarkersWithSearch?.forEach((user: any) => {
			if (!user?.lastLocation) return;
			if (user?.lastLocation?.[0]?.lat && user?.lastLocation?.[0]?.lon) {
				const center = new maps.LatLng(user?.lastLocation?.[0]?.lat, user?.lastLocation?.[0]?.lon);
				globalBounds.extend(center);
			}
		});

		if (globalBounds?.ab?.lo !== 1) {
			map.fitBounds(globalBounds);
		}
	};

	const handleClick = useCallback(
		(lat: any, lng: any, accuracy: number) => {
			if (map && maps) {
				const globalBounds = new maps.LatLngBounds();
				const center = new maps.LatLng(lat, lng);
				if (accuracy) {
					const circle = new maps.Circle({ center: center, radius: accuracy }).getBounds();
					globalBounds.union(circle);
				}
				globalBounds.extend(center);

				if (globalBounds?.ab?.lo !== 1) {
					map.fitBounds(globalBounds);
				}
			}
		},
		[map, maps]
	);

	if (!process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY || loadingAllData)
		return (
			<div className="small_circular-spinner">
				<CircularProgress />
			</div>
		);

	return (
		<div className="live_location_map">
			<GoogleMapReact
				bootstrapURLKeys={{
					key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY!,
					language: "en",
					region: "IN",
					libraries: ["places", "geometry", "drawing", "visualization"],
				}}
				key={key}
				options={mapOptions}
				center={center}
				yesIWantToUseGoogleMapApiInternals={true}
				zoom={zoom}
				onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
				style={{ position: "relative", width: "100%", height: "100%" }}
				// ref={() => {
				// 	if (map && maps && filteredMarkersWithSearch.length > 0) {
				// 		const globalBounds = new maps.LatLngBounds();
				// 		filteredMarkersWithSearch.forEach((user: any) => {
				// 			const lat = user?.lastLocation?.[0]?.lat || 0;
				// 			const lng = user?.lastLocation?.[0]?.lon || 0;
				// 			const center = new maps.LatLng(lat, lng);
				// 			globalBounds.extend(center);
				// 		});
				// 		map.fitBounds(globalBounds);
				// 	}
				// }}
			>
				{filteredMarkersWithSearch?.map((user: any, index: number) => {
					if (!user?.lastLocation) {
						return null;
					}
					return (
						<Marker
							lat={user?.lastLocation ? user?.lastLocation[0]?.lat : null}
							lng={user?.lastLocation ? user?.lastLocation[0]?.lon : null}
							key={index}
							name={user?.lastName ? user?.firstName + " " + user?.lastName : user?.firstName}
							type="user"
							user={user}
							accuracy={user?.lastLocation?.[0]?.accuracy}
							handleClick={handleClick}
							maps={maps}
							map={map}
						/>
					);
				})}
				{showClients
					? dataClients?.get_clients_by_adminID?.map((client: PARTIAL_CLIENTS_DATA, index: number) => (
							<Marker
								lat={client?.lat}
								lng={client?.lng}
								key={index}
								name={client?.clientName}
								type="client"
								map={map}
								handleClick={handleClick}
								maps={maps}
							/>
					  ))
					: null}
			</GoogleMapReact>
		</div>
	);
};

export default memo(LiveLocationMap);
