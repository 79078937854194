import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import { FC } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialog-paper": {
		maxWidth: "350px",
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

type Props = {
	handleClose: () => void;
};

const ErrorModal: FC<Props> = ({  handleClose }) => <BootstrapDialog onClose={handleClose} open={true}>
			<div
				style={{
					display: "flex !important",
					padding: "16px",
					alignItems: "center",
					width: "100%",
					justifyContent: "center",
				}}>
			</div>
			<h5
					style={{
						margin: "0.3rem 0 0.3rem 0",
						fontSize: "16px",
						fontFamily: "SFUIText-Bold, Helvetica, Arial, sans-serif",
						fontWeight: 400,
						color:"black",
						textAlign:"center"
					}}>
					No Active SMS Pack
				</h5>
            <p
				style={{
					textAlign: "center",
					margin: "1.5rem 0 0.5rem 0",
                    padding:"6px",
					fontSize: "14px",
					fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
				}}>
            Your company has not purchased any SMS pack. Contact <span style={{fontStyle:"bold", textDecoration:"underline"}}>support@unolo.com </span>  to purchase a pack and then enable this SMS feature.
			</p>
			<DialogActions
				sx={{
					justifyContent: "center",
					marginBottom: "1rem",
				}}>
				<Button variant="contained" onClick={handleClose} color="inherit">
					Cancel
				</Button>
			 
			</DialogActions>
</BootstrapDialog>

export default ErrorModal;
