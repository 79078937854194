import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import { EXPENSE_SETTING } from "../../../@types";
import { upsertExpenseApproval } from "../../../api/settings";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { sortByString } from "../../../utils";
import useAdminEntitlements from "../../useAdminEntitlements";
import useFetch from "../../useFetch";
import ReadOnlyButton from "../../common/ReadOnlyButton";
type Props = {
	expense: EXPENSE_SETTING | null;
	setExpense: Dispatch<SetStateAction<EXPENSE_SETTING | null>>;
	roleID: string;
};
function ExpenseBackupApproval({ expense, setExpense, roleID }: Props) {
	const dispatch = useDispatch<APP_DISPATCH>();
	const { data: dataAdmins, loading: loadingAdmins } = useFetch<any>("/admin/pullAdmins");
	const userWritePermission = useAdminEntitlements("settings:expense", "write");
	const [loading, setLoading] = useState(false);
	const allAdmins: any = dataAdmins?.data?.dataSource ?? [];
	const isLoading = loadingAdmins || loading;
	const backApproverOneAdmins =
		allAdmins
			.filter((admin: any) => admin.id !== expense?.expenseOptions?.backUpApprover2)
			.sort((a: any, b: any) => sortByString(a.firstname, b.firstname)) ?? [];
	const backApproverTwoAdmins =
		allAdmins
			.filter((admin: any) => admin.id !== expense?.expenseOptions?.backUpApprover1)
			.sort((a: any, b: any) => sortByString(a.firstname, b.firstname)) ?? [];
	backApproverOneAdmins.unshift({ id: null, firstname: "None", lastname: "" });
	backApproverTwoAdmins.unshift({ id: null, firstname: "None", lastname: "" });

	const updateAdminHierarchy = async (key: keyof EXPENSE_SETTING["expenseOptions"], value: number | string) => {
		if (!key || key.length === 0 || expense === null || value === undefined) return;
		const newExpense = { ...expense };
		const tempExpenseOptions = { ...newExpense.expenseOptions, [key]: value };
		if (key === "backUpApprover1" && value === null) {
			tempExpenseOptions.backUpApprover2 = null;
		}
		const { success, message } = await upsertExpenseApproval(
			tempExpenseOptions.usesAdminHierarchy,
			tempExpenseOptions.approverOne,
			tempExpenseOptions.approverTwo,
			tempExpenseOptions.approverThree,
			tempExpenseOptions.backUpApprover1,
			tempExpenseOptions.backUpApprover2,
			tempExpenseOptions.restrictedAdminCount,
			tempExpenseOptions.expenseQuestionOne,
			tempExpenseOptions.expenseQuestionTwo
		);
		dispatch(showNotification({ message: message, severity: success ? "success" : "error" }));
		setLoading(false);
		if (!success) return;
		setExpense({ ...newExpense, expenseOptions: tempExpenseOptions });
	};
	return (
		<div className="custom-field-settings">
			<div className="custom-field-top expense_approval">
				<div className="section">
					<div className="section-part">
						<p className="title">Expense Backup Approval</p>
						{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="settings" /> : null}
					</div>
					<p className="subtitle">
						Define Backup approvers who can take act on expenses if the listed approvers are not available.
					</p>
				</div>
			</div>
			<div className="approval_methods" style={{ alignItems: "flex-start" }}>
				<div style={{ display: "grid", gap: "15px", marginTop: 10 }}>
					<div className="authorization_approvers" style={{ gridTemplateColumns: "1fr 1fr" }}>
						<FormControl>
							<InputLabel id="backUpApprover1">Backup Approver One</InputLabel>
							<Select
								labelId="backUpApprover1"
								label="Backup Approver One"
								onChange={(e: any) => updateAdminHierarchy("backUpApprover1", e.target.value)}
								value={expense?.expenseOptions?.backUpApprover1 ?? ""}
								disabled={isLoading || !userWritePermission}>
								{backApproverOneAdmins.map((admin: any) => (
									<MenuItem key={admin.id} value={admin.id}>
										{admin.firstname + " " + admin?.lastname}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl>
							<InputLabel id="backUpApprover2">Backup Approver Two</InputLabel>
							<Select
								labelId="backUpApprover2"
								label="Backup Approver Two"
								onChange={(e: any) => updateAdminHierarchy("backUpApprover2", e.target.value)}
								value={expense?.expenseOptions?.backUpApprover2 ?? ""}
								disabled={isLoading || !userWritePermission || expense?.expenseOptions?.backUpApprover1 === null}>
								{backApproverTwoAdmins.map((admin: any) => (
									<MenuItem key={admin.id} value={admin.id}>
										{admin.firstname + " " + admin?.lastname}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ExpenseBackupApproval;
