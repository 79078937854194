// Apollo
import { ApolloClient, concat, createHttpLink, DefaultOptions, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
// Libraries
import { reject } from "lodash";
// Constants
import { PRODUCT_ID } from "../constants";
// Utils
import firebaseAuth from "../firebase";
// Redux
import { logoutAllSystem, store } from "../redux";

const getIdToken = () =>
	new Promise(async (resolve) => {
		let idToken = "";

		firebaseAuth.onAuthStateChanged(async (user) => {
			if (user) {
				// Get the user's ID token
				const data = await user.getIdToken().then(function (newId) {
					return newId;
				});
				idToken = data;
				// Save the token to local storage
				localStorage.setItem("firebaseIdToken", data);
				resolve(idToken);
			} else {
				// No user details found, so log out
				// Dispatch the logoutAllSystem action here
				try {
					store.dispatch(logoutAllSystem());
				} catch (error) {
					console.error("Error logging out:", error);
				}
				// No user is signed in
				resolve(null);
			}
		});
	});

function createClient() {
	const COMPANY_ID = localStorage.getItem("companyID");

	// eslint-disable-next-line no-param-reassign
	try {
		return new Promise(async (resolve) => {
			// const httpLink = new HttpLink({
			// 	uri: process.env.NEXT_PUBLIC_APOLLO_URI + `?cid=${COMPANY_ID}`,
			// });

			const httpLink = createHttpLink({
				uri: ({ getContext }) => {
					const { apiName } = getContext();
					if (apiName === "customReportEndpoint") {
						return process.env.NEXT_PUBLIC_CUSTOM_REPORT_APOLLO_URI + `?cid=${COMPANY_ID}`;
					}

					return process.env.NEXT_PUBLIC_APOLLO_URI + `?cid=${COMPANY_ID}`;
				},
			});

			const authLink = setContext((_, { headers }) => ({
				headers: {
					...headers,
				},
			}));

			const wsLink = new WebSocketLink({
				uri: process.env.NEXT_PUBLIC_APOLLO_WS_URL + `?cid=${COMPANY_ID}`,
				options: {
					// try connecting max 5 times then stop
					reconnect: true,
					reconnectionAttempts: 5,
					connectionParams: async () => {
						const idToken = await getIdToken();
						return {
							authToken: idToken,
							// connection will be pinged every 30 seconds
							keepAlive: 60000,
						};
					},
				},
			});

			const retryLink = new RetryLink({
				attempts: {
					max: 0,
				},
			});

			// * A function that's called for each operation to execute
			// * The Link to use for an operation if the function returns a "truthy" value
			// * The Link to use for an operation if the function returns a "falsy" value
			const splitLink = retryLink.split(
				({ query }) => {
					const definition = getMainDefinition(query);
					return definition.kind === "OperationDefinition" && definition.operation === "subscription";
				},
				wsLink,
				authLink.concat(httpLink)
			);

			const defaultOptions: DefaultOptions = {
				watchQuery: {
					fetchPolicy: "no-cache",
					errorPolicy: "all",
				},
				query: {
					fetchPolicy: "no-cache",
					errorPolicy: "all",
				},
				mutate: {
					errorPolicy: "all",
				},
			};

			const authMiddleware = setContext(async (_, { headers }) => {
				const idToken = await getIdToken();
				return {
					headers: {
						...headers,
						authorization: idToken ? `${idToken}` : "",
						productID: localStorage.getItem(PRODUCT_ID) || "2",
					},
				};
			});

			const client = new ApolloClient({
				// connectToDevTools: true,
				link: concat(authMiddleware, splitLink),
				cache: new InMemoryCache({
					addTypename: false,
				}),
				defaultOptions,
			});

			return resolve(client);
		});
	} catch (error) {
		console.error(error);
		return reject("Error connecting to firebase");
	}
}

export default createClient;
