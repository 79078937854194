/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
//react
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
//moment
import moment from "moment";
//apollo client
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
//components
import { DD_STYLES, DG_STYLES } from "../../../constants";
import CustomDateRange from "../../common/CustomDateRange";
import { formatDateTo_DDMMYYYY } from "../../../utils/validations";
import {
	GET_INTEGRATION_LOG_AUDIT_TRAIL,
	GET_INTEGRATION_LOGS,
	GET_INTEGRATIONS_BY_COMPANY_ID,
	REPLAY_EVENT,
} from "../../../schema/hrOne";
import ReplayEvent from "./ReplayEvent";
import { showNotification } from "../../../redux";
//mui
import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	InputLabel,
	LinearProgress,
	MenuItem,
	Modal,
	Select,
	SelectChangeEvent,
	Skeleton,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DateRangeIcon from "@mui/icons-material/DateRange";
import {
	DataGridPro,
	GridCloseIcon,
	GridOverlay,
	GridRowParams,
	GridSelectionModel,
	GridValueGetterParams,
} from "@mui/x-data-grid-pro";

import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import IntegrationLogsFilter from "./IntegrationLogsFilter";

const STATUS_CONST = ["ALL", "SUCCESS", "PENDING", "FAILED", "TIMEOUT"];
function capitalizeFirstLetter(string: string) {
	return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
}

type replayEventProps = {
	integrationID: number | null;
	eventType: string;
	eventID: string;
};
type obj = {
	integrationID: number;
	eventType: number;
	status: number;
};
type integrationNamesObj = {
	integrationID: number;
	integrationName: string;
	displayName: string;
};

type Props = {
	userWritePermission: any;
};
const IntegrationLogs: FC<Props> = ({ userWritePermission }) => {
	const [allIntegrationsName, setAllIntegrationsName] = useState<integrationNamesObj[] | []>([]);
	const [isReplayed, setIsReplayed] = useState<number>(0);
	const [rows, setRows] = useState<any>([]);
	const [auditTrailRows, setAuditTrailRows] = useState([]);
	const [allEventTypes, setAllEventTypes] = useState<any[]>([]);
	const [integrationObj, setIntegrationObj] = useState<obj>({
		integrationID: -1,
		eventType: -1,
		status: 0,
	});
	const [eventsReplayedResponse, setEventsReplayedResponse] = useState<any>([]);
	const [showLogsAuditTrail, setShowLogsAuditTrail] = useState(false);
	const [allIntegrationsData, setAllIntegrationsData] = useState([]);
	const [showDate, setShowDate] = useState(false);
	const [dateRange, setDateRange] = useState<Array<number>>(() => [
		moment().startOf("week").valueOf(),
		moment().valueOf(),
	]);
	const [pageSize, setPageSize] = useState(5);
	const [pageNumber, setPageNumber] = useState(0);
	const [sortModel, setSortModel] = React.useState<any>([
		{
			field: "lastModifiedAt",
			sort: "desc",
		},
	]);
	const [selectedRows, setSelectedRows] = useState<GridSelectionModel>([]);
	const [open, setOpen] = useState(false);
	const [isSendingEvents, setIsSendingEvents] = useState(false);
	const { data: get_integrations_by_company_id, loading } = useQuery(GET_INTEGRATIONS_BY_COMPANY_ID);
	const {
		data: get_integration_logs,
		loading: loadinglogs,
		refetch,
	} = useQuery(GET_INTEGRATION_LOGS, {
		variables: {
			start: moment(dateRange[0]).format("YYYY-MM-DD"),
			end: moment(dateRange[1]).format("YYYY-MM-DD"),
			pageNumber: pageNumber + 1,
			pageSize: pageSize,
			order: sortModel[0].sort.toUpperCase(),
			integrationID: integrationObj.integrationID === -1 ? null : integrationObj.integrationID,
			status: integrationObj.status === 0 ? null : STATUS_CONST[integrationObj.status],
			eventType: integrationObj.eventType === -1 ? null : allEventTypes[integrationObj.eventType],
		},
	});

	const [get_logs_audit_trail, { loading: loadingAuditTrails }] = useLazyQuery(GET_INTEGRATION_LOG_AUDIT_TRAIL);
	const [replayTheEventMutation] = useMutation(REPLAY_EVENT);

	useEffect(() => {
		if (!loading && get_integrations_by_company_id) {
			const uniqueIntegrations: any = Array.from(
				new Map(
					get_integrations_by_company_id?.get_integrations_by_companyID.map(
						(item: { integrationName: any; integrationID: any; displayName: string }) => [
							item.integrationName,
							{
								integrationID: item.integrationID,
								integrationName: item.integrationName,
								displayName: item.displayName,
							},
						]
					)
				).values()
			);
			const entityNamesByIntegrationID: any = get_integrations_by_company_id?.get_integrations_by_companyID.reduce(
				(acc: any, item: any) => {
					// Check if the integrationID is already in the accumulator
					if (!acc[item.integrationID]) {
						acc[item.integrationID] = [];
					}
					// Push the entityName for the integrationID
					acc[item.integrationID].push(item.entityName);
					return acc;
				},
				{}
			);
			const uniqueEntityName: any[] = [...new Set(Object.values(entityNamesByIntegrationID).flat())];
			setAllEventTypes(uniqueEntityName);
			setAllIntegrationsName(uniqueIntegrations);
			setAllIntegrationsData(get_integrations_by_company_id?.get_integrations_by_companyID);
		}
	}, [loading]);

	useEffect(() => {
		if (!loadinglogs && get_integration_logs) {
			const tempRows: any = [];
			const filteredData = get_integration_logs?.get_integration_logs.data.filter((obj: any) => {
				if (isReplayed === 0) return true;
				else if (isReplayed === 1) return obj.retryCount >= 1;
				else if (isReplayed === 2) return obj.retryCount === 0;
			});
			filteredData?.forEach((int: any) => {
				const obj = {
					integrationName: allIntegrationsName.find((x) => x.integrationID === int.integrationID)?.displayName,
					lastModifiedAt: moment(int.lastModifiedAt, "YYYY-MM-DD HH:mm:ss.SSS").isValid()
						? moment(int.lastModifiedAt, "YYYY-MM-DD HH:mm:ss.SSS").format("DD-MM-YYYY HH:mm:ss.SSS")
						: int.lastModifiedAt,
					entityName: int.eventType,
					status: int.status,
					errorMessage: int.errorMessage,
					eventID: int.eventID,
					integrationID: int.integrationID,
					id: int.eventID + ":" + int.integrationID + ":" + int.eventType,
					retryCount: int.retryCount,
					eventReplayed: int.retryCount >= 1 ? "Yes" : "No",
				};

				tempRows.push(obj);
			});

			setRows(tempRows);
			setEventsReplayedResponse([]);
			setSelectedRows([]);
		}
	}, [loadinglogs, get_integration_logs?.get_integration_logs, allIntegrationsName, isReplayed]);

	const rowCountRef = React.useRef(get_integration_logs?.get_integration_logs?.pageContext.totalRecords || 0);

	const rowCount = React.useMemo(() => {
		if (get_integration_logs?.get_integration_logs?.pageContext.totalRecords !== undefined) {
			rowCountRef.current = get_integration_logs?.get_integration_logs?.pageContext.totalRecords;
		}
		return rowCountRef.current;
	}, [get_integration_logs?.get_integration_logs?.pageContext.totalRecords]);

	const dispatch = useDispatch();
	const handleupdateTheIntegrationObj = (event: SelectChangeEvent<any>, name: string) => {
		if (event.target.value === -1) {
			const entityNamesByIntegrationID: any = allIntegrationsData.reduce((acc: any, item: any) => {
				// Check if the integrationID is already in the accumulator
				if (!acc[item.integrationID]) {
					acc[item.integrationID] = [];
				}
				// Push the entityName for the integrationID
				acc[item.integrationID].push(item.entityName);
				return acc;
			}, {});
			const uniqueEntityName: any[] = [...new Set(Object.values(entityNamesByIntegrationID).flat())];
			setAllEventTypes(uniqueEntityName);
			setIntegrationObj((prev: any) => ({
				...prev,
				[name]: event.target.value,
				eventType: -1,
			}));
		} else {
			const entityNamesForIntegrationID = allIntegrationsData
				.filter((item: any) => item.integrationID === event.target.value)
				.map((item: any) => item.entityName);
			setAllEventTypes(entityNamesForIntegrationID);
			setIntegrationObj((prev: any) => ({
				...prev,
				[name]: event.target.value,
				eventType: entityNamesForIntegrationID.length > 1 ? -1 : 0,
			}));
		}
	};

	// replay the event
	const replayTheBulkEvent = async () => {
		const sendDataTobackend: replayEventProps[] = [];

		selectedRows.forEach((r: any) => {
			const splittedValue = r.split(":");
			const obj: replayEventProps = {
				integrationID: null,
				eventID: "",
				eventType: "",
			};
			obj.integrationID = Number(splittedValue[1]);
			obj.eventID = splittedValue[0];
			obj.eventType = splittedValue[2];
			sendDataTobackend.push(obj);
		});
		setIsSendingEvents(true);
		try {
			// setShowPlayButton(false);
			const res = await replayTheEventMutation({
				variables: {
					input: sendDataTobackend,
				},
			});
			if (res?.data?.replay_event) {
				setEventsReplayedResponse(res.data.replay_event);
				setIsSendingEvents(false);
			} else {
				dispatch(
					showNotification({
						message: "Something went wrong. Please contact support",
						severity: "error",
					})
				);
				return;
			}
		} catch (error) {
			dispatch(
				showNotification({
					message: "Something went wrong. Please contact support",
					severity: "error",
				})
			);
			return;
		}
	};

	const COLUMNS = [
		{
			field: "integrationName",
			headerName: "Integration Name",
			sortable: false,
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridValueGetterParams) => <div>{params?.row?.integrationName}</div>,
		},
		{
			field: "lastModifiedAt",
			headerName: "Occurred At",
			sortable: true,
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridValueGetterParams) => <div>{params?.row?.lastModifiedAt}</div>,
		},
		{
			field: "entityName",
			headerName: "Entity Name",
			sortable: false,
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridValueGetterParams) => <div>{capitalizeFirstLetter(params?.row?.entityName)}</div>,
		},
		{
			field: "status",
			headerName: "Status",
			sortable: false,
			flex: 1,
			minWidth: 150,
			renderCell: (params: any) => {
				const classNameColor: any = {
					PENDING: "InActive",
					TIMEOUT: "OnLeave",
					FAILED: "PunchedOut",
					SUCCESS: "PunchedIn",
				};
				return (
					<span className={`role_badge ${classNameColor[params.row.status]}`} style={{ fontSize: "0.8rem" }}>
						{params.row.status}
					</span>
				);
			},
		},
		{
			field: "retryCount",
			headerName: "Retry Count",
			sortable: false,
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridValueGetterParams) => <div>{params?.row?.retryCount}</div>,
		},
		{
			field: "eventReplayed",
			headerName: "Event Replayed",
			sortable: false,
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridValueGetterParams) => <div>{params?.row?.eventReplayed}</div>,
		},
		{
			field: "errorMessage",
			headerName: "Error Message",
			sortable: false,
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridValueGetterParams) => <div>{params?.row?.errorMessage}</div>,
		},
		{
			field: "replayeEvent",
			headerName: "Replay Event",
			sortable: false,
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridValueGetterParams) => (
				<ReplayEvent
					integrationID={params.row.integrationID}
					eventID={params.row.eventID}
					eventType={params.row.entityName}
					userWritePermission={userWritePermission}
					setEventsReplayedResponse={setEventsReplayedResponse}
					setSelectedRows={setSelectedRows}
					isSendingEvents={isSendingEvents}
				/>
			),
		},
	];

	const auditTrailColumns = [
		{
			field: "lastModifiedAt",
			headerName: "Occurred At",
			sortable: false,
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridValueGetterParams) => <div>{params?.row?.lastModifiedAt}</div>,
		},
		{
			field: "entityName",
			headerName: "Entity Name",
			sortable: false,
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridValueGetterParams) => <div>{capitalizeFirstLetter(params?.row?.entityName)}</div>,
		},
		{
			field: "status",
			headerName: "Status",
			sortable: false,
			flex: 1,
			minWidth: 150,
			renderCell: (params: any) => {
				const classNameColor: any = {
					PENDING: "InActive",
					TIMEOUT: "OnLeave",
					FAILED: "PunchedOut",
					SUCCESS: "PunchedIn",
				};
				return (
					<span className={`role_badge ${classNameColor[params.row.status]}`} style={{ fontSize: "12px" }}>
						{params.row.status}
					</span>
				);
			},
		},
		{
			field: "errorMessage",
			headerName: "Error Message",
			sortable: false,
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridValueGetterParams) => <div>{params?.row?.errorMessage}</div>,
		},
	];

	// fetch the audit trail of selected integration
	const fetchLogsAuditTrail = (eventID: string, integrationID: number) => {
		get_logs_audit_trail({
			variables: {
				eventID: eventID,
				integrationID: integrationID,
			},
		})
			.then((data) => {
				const tempRows: any = [];
				data?.data?.get_integration_log_audit_trail?.forEach((int: any, index: number) => {
					const obj = {
						lastModifiedAt: moment(int.lastModifiedAt).format("DD-MM-YYYY HH:mm:ss.SSS"),
						entityName: int.eventType,
						status: int.status,
						errorMessage: int.errorMessage,
						id: index,
					};

					tempRows.push(obj);
				});

				setAuditTrailRows(tempRows);
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});
		setShowLogsAuditTrail(true);
	};
	const loadingData = loading || loadinglogs;
	return (
		<div className="pt-2">
			{loading ? (
				<div className="flex items-center justify-center mt-2 p-3 border border-#e5e7eb-600 rounded-md min-h-[calc(100vh-245px)]">
					<CircularProgress />
				</div>
			) : (
				<div className="mt-2 p-3 border border-#e5e7eb-600 rounded-md min-h-[calc(100vh-245px)]">
					<div className="flex gap-3 flex-wrap">
						{eventsReplayedResponse.length === 0 && (
							<>
								<FormControl style={{ width: 160 }}>
									<InputLabel id="integration_name">Integration name</InputLabel>
									<Select
										name="integrationName"
										label="Integration name"
										labelId="integration_name"
										sx={DD_STYLES}
										value={integrationObj.integrationID}
										onChange={(e) => handleupdateTheIntegrationObj(e, "integrationID")}>
										<MenuItem value={-1}>All</MenuItem>
										{allIntegrationsName.map((obj, index) => (
											<MenuItem value={obj.integrationID} key={index}>
												{obj.displayName}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<FormControl style={{ width: 160 }}>
									<InputLabel id="entity_name">Entity name</InputLabel>
									<Select
										name="entity name"
										label="Entity name"
										labelId="entity_name"
										sx={DD_STYLES}
										value={allEventTypes.length > 1 ? integrationObj.eventType : 0}
										onChange={(e) =>
											setIntegrationObj((prev: any) => ({
												...prev,
												["eventType"]: e.target.value,
											}))
										}>
										{allEventTypes.length > 1 && <MenuItem value={-1}>All</MenuItem>}
										{allEventTypes.map((entity, index) => (
											<MenuItem value={index} key={index}>
												{capitalizeFirstLetter(entity)}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<FormControl style={{ width: 160 }}>
									<InputLabel id="status">Status</InputLabel>
									<Select
										name="status"
										label="Status"
										labelId="status"
										sx={DD_STYLES}
										value={integrationObj.status}
										onChange={(e) =>
											setIntegrationObj((prev: any) => ({
												...prev,
												["status"]: e.target.value,
											}))
										}>
										{STATUS_CONST.map((status, index) => (
											<MenuItem value={index} key={index}>
												{capitalizeFirstLetter(status)}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<FormControl style={{ width: 160 }}>
									<InputLabel id="replayed">Event replayed</InputLabel>
									<Select
										name="replayed"
										label="Replayed"
										labelId="replayed"
										sx={DD_STYLES}
										value={isReplayed}
										onChange={(e: any) => setIsReplayed(e.target.value)}>
										{["All", "Yes", "No"].map((st, index) => (
											<MenuItem value={index} key={index}>
												{st}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<div className="date-range-picker" onClick={() => setShowDate((s) => !s)}>
									<p>{`${formatDateTo_DDMMYYYY(dateRange[0])} to ${formatDateTo_DDMMYYYY(dateRange[1])}`}</p>
									<DateRangeIcon sx={{ color: "#819DFF", marginLeft: "10px" }} />
								</div>
								{showDate ? (
									<CustomDateRange
										dateRange={dateRange}
										setDateRange={setDateRange}
										controlDisplay={setShowDate}
										localString={"integration_logs_date"}
									/>
								) : null}
							</>
						)}
						{eventsReplayedResponse.length > 0 && (
							<IntegrationLogsFilter eventsReplayedResponse={eventsReplayedResponse} setRows={setRows} />
						)}
						{selectedRows.length > 0 && !isSendingEvents && (
							<button
								disabled={!userWritePermission}
								style={{ textTransform: "capitalize" }}
								onClick={() => {
									replayTheBulkEvent();
									setOpen(true);
								}}
								className="px-2  border !border-primary-600 text-primary-600 bg-primary-700 rounded-lg h-[36px] font-regular">
								<span style={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
									<PlayCircleIcon sx={{ fontSize: "15px", marginRight: "5px" }} />
									Replay Event
								</span>
							</button>
						)}
						{eventsReplayedResponse.length > 0 && (
							<div
								style={{
									display: "flex",
									backgroundColor: "rgb(225, 239, 254)",
									alignItems: "center",
									border: "1px dashed #1976d2",
									borderRadius: "4px",
								}}>
								{/*eslint-disable-next-line jsx-a11y/click-events-have-key-events*/}
								<div // eslint-disable-line jsx-a11y/no-static-element-interactions
									onClick={() => {
										setIntegrationObj({
											integrationID: -1,
											eventType: -1,
											status: 0,
										});
										setSelectedRows([]);
										refetch();
									}}
									style={{
										fontSize: "14px",
										fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
										padding: "4px",
										cursor: "pointer",
									}}>
									Show all logs
								</div>
							</div>
						)}
					</div>
					<div className="mt-3 h-[calc(100vh-245px-84px)] datagrid-table">
						<DataGridPro
							sx={DG_STYLES}
							rowCount={rowCount}
							rows={rows}
							columns={COLUMNS}
							loading={loadingData}
							paginationMode="server"
							rowHeight={55}
							getRowId={(row) => row.id}
							sortingOrder={["desc", "asc"]}
							sortModel={sortModel}
							onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
							disableSelectionOnClick
							disableColumnFilter
							disableColumnMenu
							pagination
							hideFooter={eventsReplayedResponse.length > 0 ? true : false}
							hideFooterPagination={eventsReplayedResponse.length > 0 ? true : false}
							rowsPerPageOptions={[5, 10, 20, 50, 100]}
							onPageSizeChange={(newPageSize) => {
								setPageSize(newPageSize);
								setPageNumber(0); // Reset to first page when page size changes
							}}
							onPageChange={(newPageNumber) => setPageNumber(newPageNumber)}
							page={pageNumber}
							pageSize={pageSize}
							onRowClick={(params: GridRowParams<any>) => {
								fetchLogsAuditTrail(params.row.eventID, params.row.integrationID);
							}}
							onSelectionModelChange={(selectionModel) => {
								setSelectedRows(selectionModel);
							}}
							checkboxSelection
							components={{
								NoRowsOverlay: function CustomNoRowsOverlay() {
									return <GridOverlay>No Logs found</GridOverlay>;
								},
								LoadingOverlay: () => (
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											height: "100%",
										}}>
										<CircularProgress />
									</div>
								),
							}}
						/>
					</div>
				</div>
			)}

			{!loading && get_integrations_by_company_id?.get_integrations_by_companyID.length === 0 && (
				<div className="mt-2 p-3 border border-#e5e7eb-600 rounded-md min-h-[calc(100vh-245px)] flex items-center justify-center">
					<p>No integration is enabled for your company.</p>
				</div>
			)}

			<Modal
				open={showLogsAuditTrail}
				onClose={() => setShowLogsAuditTrail(false)}
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					// minWidth: "400px",
					minHeight: "600px",
				}}>
				<>
					<Box
						sx={{
							backgroundColor: "#FFFFFF",
							borderRadius: "10px",
							width: { xs: "90%", sm: "50%", md: "50%", lg: "40%", xl: "50%" },
							height: { xs: "50%", sm: "50%", md: "50%", lg: "40%", xl: "50%" },

							fontFamily: "SFUIText-Medium",
						}}
						className="downloadModalBox">
						<div
							style={{
								display: "flex",
								width: "100%",
								height: "10%",
								justifyContent: "space-between",
								padding: "16px",
								alignItems: "center",
							}}>
							<p>Audit trail</p>
							<div style={{ cursor: "pointer" }}>
								<GridCloseIcon onClick={() => setShowLogsAuditTrail(false)} />
							</div>
						</div>
						<hr style={{ padding: 0 }} />
						<div
							className="datagrid-table p-3 m-0"
							style={{
								height: "85%",
							}}>
							{loadingAuditTrails ? (
								<div className="flex-auto">
									<Skeleton
										variant="rectangular"
										width={"100%"}
										height={25}
										sx={{ borderRadius: "8px", marginTop: "10px" }}
										animation="wave"
									/>
									<Skeleton
										variant="rectangular"
										width={"100%"}
										height={25}
										sx={{ borderRadius: "8px", marginTop: "10px" }}
										animation="wave"
									/>
									<Skeleton
										variant="rectangular"
										width={"100%"}
										height={25}
										sx={{ borderRadius: "8px", marginTop: "10px" }}
										animation="wave"
									/>
									<Skeleton
										variant="rectangular"
										width={"100%"}
										height={25}
										sx={{ borderRadius: "8px", marginTop: "10px" }}
										animation="wave"
									/>
									<Skeleton
										variant="rectangular"
										width={"100%"}
										height={25}
										sx={{ borderRadius: "8px", marginTop: "10px" }}
										animation="wave"
									/>
								</div>
							) : (
								<DataGridPro
									sx={DG_STYLES}
									rows={auditTrailRows}
									columns={auditTrailColumns}
									getRowId={(row) => row.id}
									rowHeight={55}
									disableSelectionOnClick
									disableColumnFilter
									hideFooter
									disableColumnMenu
									components={{
										NoRowsOverlay: function CustomNoRowsOverlay() {
											return <GridOverlay>No Logs found</GridOverlay>;
										},
									}}
								/>
							)}
						</div>
					</Box>
				</>
			</Modal>
			<Modal
				open={open}
				onClose={() => setOpen(false)}
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					// minWidth: "400px",
					minHeight: "600px",
				}}
				className="p-2">
				<Box
					sx={{
						width: { xs: "50%", sm: "30%", md: "30%", lg: "25%", xl: "25%" },
						height: { xs: "40%", sm: "30%", md: "30%", lg: "25%", xl: "25%" },
						position: "relative",
					}}
					className="downloadModalBox bg-primary-800 rounded-lg">
					<div
						style={{
							display: "flex",
							width: "100%",
							height: "10%",
							justifyContent: "space-between",
							alignItems: "center",
						}}
						className="py-4 px-2">
						<p>{isSendingEvents ? `Replaying events` : `Events summary`}</p>
					</div>
					<hr style={{ padding: 0 }} />
					<Box
						sx={{
							fontFamily: "SFUIText-Medium",
							display: "flex", // Flexbox for center alignment
							flexDirection: "column",
							justifyContent: "center", // Center vertically
							alignItems: "center", // Center horizontally
							padding: "16px", // Padding for spacing inside the modal box
							height: "65%",
						}}>
						{isSendingEvents && (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center", // Center horizontally
									gap: "10px",
									alignItems: "center",
									flexWrap: "wrap",
									marginTop: "16px", // Add some margin on top of the text
								}}>
								<LinearProgress sx={{ width: "20px", height: "20px" }} />
								<LinearProgress sx={{ width: "20px", height: "17px" }} />
								<LinearProgress sx={{ width: "20px", height: "14px" }} />
								<LinearProgress sx={{ width: "20px", height: "11px" }} />
								<LinearProgress sx={{ width: "20px", height: "8px" }} />
								<LinearProgress sx={{ width: "10px", height: "6px" }} />
							</Box>
						)}
						{!isSendingEvents && (
							<div>
								<div className="flex gap-1 items-center">
									<CheckCircleIcon htmlColor="#40a636" fontSize="small" />
									<p>{eventsReplayedResponse.filter((obj: any) => obj.success).length} event(s) successfully sent</p>
								</div>
								<div className="flex gap-1 items-center">
									<CancelIcon htmlColor="#f54747" fontSize="small" />
									{eventsReplayedResponse.filter((obj: any) => !obj.success).length} failed events
								</div>
							</div>
						)}
					</Box>
					{!isSendingEvents && (
						// <Box
						// 	sx={{
						// 		position: "absolute",
						// 		bottom: 10,
						// 		right: 10,
						// 	}}>
						// 	<button onClick={() => setOpen(false)} className="flex flex-end">
						// 		Close
						// 	</button>
						// </Box>
						<Button
							variant="outlined"
							color="secondary"
							type="button"
							sx={{ textTransform: "none", position: "absolute", bottom: 10, right: 10 }}
							onClick={() => {
								setOpen(false);
								// showRecentEventsReplayed();
							}}>
							Close
						</Button>
					)}
				</Box>
			</Modal>
		</div>
	);
};

export default IntegrationLogs;
