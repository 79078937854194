import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { FormControlLabel, Switch } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import _cloneDeep from "lodash/cloneDeep";
import { Dispatch, FC, SetStateAction } from "react";
import { useDispatch } from "react-redux";
import { APP_DISPATCH } from "../../../redux";
import { useSelector } from "react-redux";
import { DATA_GRID_CUSTOMIZATION_OPTIONS, DATA_GRID_NAMES } from "../../../constants";
import { getTheSavedColumns } from "../../../utils";
import { setColumnConfigurations, setColumnSortModel, setPinnedColumns } from "../../../redux/slices/dataGridSlice";

type Props = {
	setExpanded: Dispatch<SetStateAction<boolean>>;
	localString?: string;
	columns: GridColDef[];
};
const ColumnModifierSidebar: FC<Props> = ({ setExpanded, localString, columns }) => {
	const getColum = useSelector((state: any) => state.dataGrid);
	let savedColumnConf: any = [];
	if (localString !== undefined) {
		savedColumnConf = getTheSavedColumns(
			getColum,
			DATA_GRID_NAMES[localString],
			DATA_GRID_CUSTOMIZATION_OPTIONS.columns_array
		);
	}
	const elementsToBeAdded: any = [];
	const elementsToBeRemoved: any = [];

	if (columns.length > 0 && savedColumnConf.length > 0) {
		columns.forEach((column: any) => {
			if (!savedColumnConf.find((col: any) => col.field === column.field)) {
				elementsToBeAdded.push(column);
			}
		});
	}
	if (savedColumnConf.length > 0 && columns.length > 0) {
		savedColumnConf.forEach((col: any) => {
			if (!columns.find((column: any) => col.field === column.field)) {
				elementsToBeRemoved.push(col);
			}
		});
	}

	const finalResult = savedColumnConf.filter((col: any) => !elementsToBeRemoved.includes(col));
	finalResult.splice(finalResult.length / 2, 0, ...elementsToBeAdded);

	savedColumnConf = finalResult;

	const dispatch = useDispatch<APP_DISPATCH>();

	const hideColumn = (e: any) => {
		if (localString === undefined) return;
		const { name, checked } = e.target;
		const newColumns = _cloneDeep(savedColumnConf.length > 0 ? savedColumnConf : columns);
		const columnIdx = newColumns.findIndex((column: GridColDef) => column.field === name);
		newColumns[columnIdx].hide = !checked;
		const newColumnConfigurations = {
			dataGridName: DATA_GRID_NAMES[localString],
			columnArray: newColumns,
		};
		dispatch(setColumnConfigurations(newColumnConfigurations));
	};

	const resetColumns = () => {
		if (localString === undefined) return;
		const newColumns = _cloneDeep(columns);
		newColumns.forEach((column: GridColDef) => {
			column.hide = false;
		});
		const newColumnConfigurations = {
			dataGridName: DATA_GRID_NAMES[localString],
			columnArray: newColumns,
		};
		// clear the pinned columns as well sort model
		const newColumnConfigurationsSort = {
			dataGridName: DATA_GRID_NAMES[localString],
			sortModel: [],
		};
		const newColumnConfigurationsPinned = {
			dataGridName: DATA_GRID_NAMES[localString],
			pinnedColumns: { left: [], right: [] },
		};
		dispatch(setColumnSortModel(newColumnConfigurationsSort));
		dispatch(setColumnConfigurations(newColumnConfigurations));
		dispatch(setPinnedColumns(newColumnConfigurationsPinned));
	};

	return (
		<section className="column_list" style={{ width: "auto", marginRight: "-15px" }}>
			<header>
				<h1>Edit Columns</h1>
				<button
					onClick={() => resetColumns()}
					style={{
						color: "white",
						fontSize: "12px",
						padding: "6px 12px",
						borderRadius: "5px",
						backgroundColor: "#f54747",
						fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
					}}>
					Reset Columns
				</button>
				<button onClick={() => setExpanded(false)}>
					<CloseOutlinedIcon />
				</button>
			</header>
			<ul>
				{(savedColumnConf?.length > 0 ? savedColumnConf : columns).map((column: any, idx: any) => (
					<li key={idx}>
						<FormControlLabel
							control={<Switch checked={!column.hide} />}
							name={column.field}
							label={column.headerName}
							onChange={hideColumn}
						/>
					</li>
				))}
			</ul>
		</section>
	);
};
export default ColumnModifierSidebar;
