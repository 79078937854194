import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import moment from "moment";
import "moment-timezone";
import { useSelector } from "react-redux";
import { DASHBOARD, TASKS } from "../../../@types";
import { CHECKOUT_TIME_OFFSET, TIME_SHOW_FORMAT } from "../../../constants";
import { ROOT_STATE } from "../../../redux";

type Props = {
	tasks: DASHBOARD["tasks"];
};
const getTaskStatus = (task: TASKS) => {
	if (!task.checkinTime && !task.checkoutTime && !task.startTime && !task.endTime) {
		return { cls: "not_available", value: "Not Available" };
	}
	if (!task.checkinTime) {
		return { cls: "pending", value: "Not Started" };
	}
	if (task.checkinTime && !task.checkoutTime) {
		return { cls: "progress", value: "In Progress" };
	}
	if (task.checkinTime > task.endTime! + CHECKOUT_TIME_OFFSET * 1000 * 60) {
		return { cls: "delayed", value: "Delayed" };
	}
	if (task.checkinTime && task.checkoutTime) {
		return { cls: "completed", value: "Completed" };
	}
	return { cls: "not_available", value: "Not Available" };
};
const Tasks = ({ tasks }: Props) => {
	const adminInfo = useSelector((state: ROOT_STATE) => state.user);
	const showTaskInfo = (task: any) => {
		if (task?.taskDescription !== null && task?.taskDescription !== "") return task?.taskDescription;
		if (task?.clientInfo !== null) return `Task against ${task?.clientInfo?.clientName}`;
		return `Task created at ${task?.address}`;
	};
	return (
		<div className="sub_table">
			<div className="rows head">
				<span>Task Name</span>
				<span>Starting Time</span>
			</div>
			{tasks &&
				Array.isArray(tasks) &&
				tasks.map((task) => {
					const status = getTaskStatus(task);
					return (
						<div className="rows" key={task.taskID}>
							<span>
								<i className={`${status.cls}`}>
									<DoneOutlinedIcon />
								</i>
								{showTaskInfo(task)}
							</span>
							<span>
								{task.checkinTime
									? moment(task.checkinTime)
											.tz(adminInfo?.tz || "Asia/Kolkata")
											.format(TIME_SHOW_FORMAT)
									: "Not started yet"}
							</span>
						</div>
					);
				})}
		</div>
	);
};

export default Tasks;
