import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import WarningIcon from "@mui/icons-material/Warning";
import {
	Button,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Input,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
} from "@mui/material";
import _cloneDeep from "lodash/cloneDeep";
import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { FORM_TEMPLATE_TYPE, PROFILES_DATA } from "../../../@types";
import useFetch from "../../useFetch";
import { getProfileNamesFromIds, MenuProps, updateFormTemplate } from "./FormUtils";
import { useSelector } from "react-redux";
import { ROOT_STATE } from "../../../redux";
type Props = {
	formTemplate: FORM_TEMPLATE_TYPE;
	setFormTemplate: Dispatch<SetStateAction<FORM_TEMPLATE_TYPE>>;
	userWritePermission: boolean;
};
const FormSettings: FC<Props> = ({ formTemplate, setFormTemplate, userWritePermission }) => {
	const { data: dataProfiles, loading: loadingProfiles } = useFetch<PROFILES_DATA[]>("/users/profiles");
	const quizMode = formTemplate.isQuizMode ? true : false;
	const [currentRowId, setCurrentRowId] = useState("");
	const allProfiles = dataProfiles.data || [];
	const {
		susbscriptionMetadata: { data:subsMetadata },
	} = useSelector((state: ROOT_STATE) => ({
		susbscriptionMetadata: state.susbscriptionMetadata,
	}));
	const loading = loadingProfiles;
	const changeQuizMode = (e: any) => {
		const { tempFormTemplate } = updateFormTemplate(formTemplate);
		tempFormTemplate.isQuizMode = e.target.checked ? 1 : 0;
		setFormTemplate(tempFormTemplate);
	};

	const changeVisibility = (e: any) => {
		const { tempFormTemplate } = updateFormTemplate(formTemplate);
		tempFormTemplate.visibility.visibleToAll = e.target.checked;
		if (e.target.checked) {
			tempFormTemplate.visibility.teamIDs = [];
		}
		setFormTemplate(tempFormTemplate);
	};

	const handleChangeVisibleTeams = (e: SelectChangeEvent<number[] | null>) => {
		const { tempFormTemplate } = updateFormTemplate(formTemplate);
		const teamIds =
			e.target.value !== null && Array.isArray(e.target.value) ? e.target.value : [parseInt(e.target.value || "")];
		tempFormTemplate.visibility.teamIDs = teamIds;
		setFormTemplate(tempFormTemplate);
	};

	const changeFormScore = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { tempFormTemplate } = updateFormTemplate(formTemplate);
		tempFormTemplate.defaultPointValue = parseInt(e.target.value);
		setFormTemplate(tempFormTemplate);
	};

	const addFormScoreRow = () => {
		const { tempFormTemplate } = updateFormTemplate(formTemplate);
		tempFormTemplate.formScore.push({
			low: 0,
			high: 0,
			result: "",
			scoreID: uuidv4(),
		});
		setFormTemplate(tempFormTemplate);
	};
	const removeOption = (index: number) => {
		if (isNaN(index)) return;
		const { tempFormTemplate } = updateFormTemplate(formTemplate);
		tempFormTemplate.formScore.splice(index, 1);
		setFormTemplate(tempFormTemplate);
	};
	const updateFormScore = (index: number, key: "low" | "high" | "result", value: number | string) => {
		if (!["low", "high", "result"]?.includes(key)) return;
		const { tempFormTemplate } = updateFormTemplate(formTemplate);
		// @ts-ignore
		tempFormTemplate.formScore[index][key] = value;
		setFormTemplate(tempFormTemplate);
	};

	const shouldShowWarning = (rowScoreID: string | null, value: number | string | null, isResult: boolean = false) => {
		let numberOfOccurrence = 0;
		if (!rowScoreID || !value) return false;
		const formScore = _cloneDeep(formTemplate["formScore"]);
		formScore.forEach((d) => {
			if (isResult) {
				if (value === d.result && d.scoreID !== rowScoreID) {
					numberOfOccurrence += 1;
				}
			} else {
				if (d.high && d.low && value >= d.low && value <= d.high && d.scoreID !== rowScoreID) {
					numberOfOccurrence += 1;
				}
			}
		});
		return numberOfOccurrence === 1 && rowScoreID === currentRowId;
	};
	if (loading)
		return (
			<div className="small_circular-spinner">
				<CircularProgress />
			</div>
		);

	return (
		<div className="form_settings">
			<h3>Settings</h3>
			<hr />
			{subsMetadata?.formQuizMode === 1 && (
					<div className="form_settings-quiz">
					<label htmlFor="quiz">
						<h4>Make this a quiz</h4>
						<p>Assign point values, set answers and automatically provide feedback</p>
					</label>
					<Switch checked={quizMode} id="quiz" onClick={changeQuizMode} disabled={!userWritePermission} />
				</div>
			)}
			{quizMode && subsMetadata?.formQuizMode === 1  && (
				<div className="form_settings-score">
					<label htmlFor="score">
						<h4>Default value for all the questions</h4>
					</label>
					<div style={{ marginLeft: "auto" }}>
						<FormControl variant="filled">
							<Input
								id="filled-adornment-score"
								required
								type="number"
								value={formTemplate?.defaultPointValue}
								placeholder="1"
								onChange={changeFormScore}
								disabled={!userWritePermission}
							/>
							<FormHelperText id="filled-adornment-score">points</FormHelperText>
						</FormControl>
					</div>
					<label htmlFor="score">
						<h4>Form Score Rule</h4>
						<p>Define the result of the quiz based on the points scored</p>
					</label>
					<div></div>
					<Table sx={{ marginTop: "1rem", gridColumn: "span 2/auto" }}>
						<TableHead>
							<TableRow>
								<TableCell>From</TableCell>
								<TableCell align="left">To</TableCell>
								<TableCell align="left">Result</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{formTemplate.formScore.map((row, i) => (
								<TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell component="th" scope="row">
										<div style={{ display: "flex", alignItems: "center" }}>
											<TextField
												variant="standard"
												type="number"
												value={row.low}
												onChange={(e) => updateFormScore(i, "low", parseInt(e.target.value))}
												onFocus={() => setCurrentRowId(row.scoreID || "")}
												disabled={!userWritePermission}
											/>
											{shouldShowWarning(row.scoreID, row.low) && (
												<Tooltip title="Duplicate options not supported">
													<WarningIcon sx={{ color: "#ff7700" }} />
												</Tooltip>
											)}
										</div>
									</TableCell>
									<TableCell align="left">
										<div style={{ display: "flex", alignItems: "center" }}>
											<TextField
												type="number"
												variant="standard"
												value={row.high}
												onChange={(e) => updateFormScore(i, "high", parseInt(e.target.value))}
												onFocus={() => setCurrentRowId(row.scoreID || "")}
												disabled={!userWritePermission}
											/>
											{shouldShowWarning(row.scoreID, row.high) && (
												<Tooltip title="Form score rule should have exclusive range">
													<WarningIcon sx={{ color: "#ff7700" }} />
												</Tooltip>
											)}
										</div>
									</TableCell>
									<TableCell align="left">
										<div style={{ display: "flex", alignItems: "center" }}>
											<TextField
												variant="standard"
												value={row.result}
												onChange={(e) => updateFormScore(i, "result", e.target.value)}
												onFocus={() => setCurrentRowId(row.scoreID || "")}
												disabled={!userWritePermission}
											/>
											{shouldShowWarning(row.scoreID, row.result) && (
												<Tooltip title="Form score rule should have exclusive range">
													<WarningIcon sx={{ color: "#ff7700" }} />
												</Tooltip>
											)}
										</div>
									</TableCell>
									<TableCell>
										<button
											title="Remove"
											type="button"
											onClick={() => removeOption(i)}
											disabled={!userWritePermission}>
											<ClearIcon sx={{ color: "rgba(0,0,0,0.54)" }} />
										</button>
									</TableCell>
								</TableRow>
							))}
							<Button
								variant="text"
								sx={{ marginTop: "1rem" }}
								onClick={addFormScoreRow}
								size="small"
								startIcon={<AddIcon />}
								disabled={!userWritePermission}>
								Add Row
							</Button>
						</TableBody>
					</Table>
				</div>
			)}
			{subsMetadata?.formQuizMode === 1 && <hr />}
			<div className="form_settings-visibility">
				<label htmlFor="score">
					<h4>Visible To</h4>
				</label>
				<div>
					<FormControlLabel
						control={<Checkbox checked={formTemplate.visibility.visibleToAll || false} size="small" />}
						onClick={changeVisibility}
						label="All Teams"
					/>
					<p className="or">OR</p>
					<FormControl fullWidth>
						<InputLabel id="team">Select Team</InputLabel>
						<Select
							labelId="team"
							multiple
							value={formTemplate.visibility.teamIDs}
							onChange={handleChangeVisibleTeams}
							input={<OutlinedInput label="Team" />}
							renderValue={(selected) => getProfileNamesFromIds(allProfiles, selected)}
							MenuProps={MenuProps}
							disabled={formTemplate.visibility.visibleToAll || false || !userWritePermission}>
							{allProfiles &&
								Array.isArray(allProfiles) &&
								allProfiles.map((item) => (
									<MenuItem key={item.profileID} value={item.profileID}>
										<Checkbox
											checked={
												formTemplate.visibility.teamIDs
													? formTemplate.visibility.teamIDs.indexOf(item.profileID) > -1
													: false
											}
										/>
										<ListItemText primary={item.profileName} />
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</div>
			</div>
		</div>
	);
};

export default FormSettings;
