/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel } from "@mui/material";
import { Dispatch, SetStateAction, useMemo, useState, useEffect, useRef } from "react";
import { debounce } from "lodash";
import { GET_FILTER_OPTIONS } from "../../../schema/sites_clients";
import { useQuery } from "@apollo/client";
import { AccordionData } from "../../../pages/clients_sites/clients";
import { CircularProgress, Typography } from "@mui/material";

type Props2 = {
    name: string;
    setFilterCount: Dispatch<SetStateAction<number>>;
    setParameters: Dispatch<
        React.SetStateAction<{
            skip: number;
            take: number;
            filters: AccordionData[];
        }>
    >;
    resetCheckBox: boolean;
    setResetCheckBox: Dispatch<SetStateAction<boolean>>;
    deleted: number;
    setDeleted: Dispatch<SetStateAction<number>>;
};
function IndividualFilterClients({
    name,
    setFilterCount,
    setParameters,
    resetCheckBox,
    setResetCheckBox,
    deleted,
    setDeleted,
}: Props2) {
    const [variable, setVariable] = useState({
        skip: 0,
        take: 20,
        fieldID: "",
        searchKey: "",
    });
    const inputRef = useRef<HTMLInputElement>(null);
	const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
    const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
    const [storeLabels, setStoreLabels] = useState<string[]>([]);
    const debouncedResults = useMemo(
        () =>
            debounce(function (e, fieldID) {
                {
                    setVariable((prevState) => ({
                        ...prevState,
                        fieldID: fieldID,
                        searchKey: e.target.value,
                    }));
                }
            }, 500),
        [setVariable]
    );

    const {
        loading: loadingSearchFilter,
        data: filterOptionData,
        refetch: getOptions,
    } = useQuery(GET_FILTER_OPTIONS, { variables: variable, skip: !isAccordionExpanded, });

    const optionsData = filterOptionData?.get_clients_for_filter_by_adminID_v2.data;
    const optionsIds = filterOptionData?.get_clients_for_filter_by_adminID_v2.optionalSearchKey;

    const newOptions = optionsData?.map((name: any, index: any) => `${name}: ${optionsIds[index]}`)

    useEffect(() => {
        setSelectedLabels(storeLabels);
    }, [variable.searchKey]);
    useEffect(() => {
        if (deleted === 1) {
            getOptions();
        }
    }, [deleted]);
    useEffect(() => {
        if (resetCheckBox === false) {
            setStoreLabels([]);
            setVariable((prev) => ({
                ...prev,
                searchKey: "",
            }));
            if (inputRef.current) {
                inputRef.current.value = "";
            }
        }
    }, [resetCheckBox]);

    const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>, fieldID: string) => {
        const value = e.target.value
        setResetCheckBox(true);
        setDeleted(0);
        if (e.target.checked) {
            if (value) {
                setStoreLabels((prevState) => [...prevState, value]);
                setFilterCount((prevState) => prevState + 1);
                setParameters((prevState: any) => {
                    const fieldIndex = prevState.filters.findIndex((item: { key: string }) => item.key === fieldID);
                    if (fieldIndex !== -1) {
                        return {
                            ...prevState,
                            filters: prevState.filters.map((item: { value: any }, index: any) => {
                                if (index === fieldIndex) {
                                    return {
                                        ...item,
                                        value: [...item.value, value.match(/(\d+)$/)?.[1]],
                                    };
                                }
                                return item;
                            }),
                        };
                    } else {
                        return {
                            ...prevState,
                            filters: [...prevState.filters, { key: fieldID, value: [value.match(/(\d+)$/)?.[1]] }],
                        };
                    }
                });
            }
        } else {
            setStoreLabels(storeLabels.filter((l) => l !== value));
            setFilterCount((prevState) => prevState - 1);
            setParameters((prevState) => ({
                ...prevState,
                filters: prevState.filters
                    .map((item) => {
                        if (item.key === fieldID) {
                            return {
                                ...item,
                                value: item.value.filter((v) => v !== value.match(/(\d+)$/)?.[1]),
                            };
                        }
                        return item;
                    })
                    .filter((item) => item.value.length > 0),
            }));
        }
    };

    const handleExpand = (fieldID: string) => {
        setVariable((prevState) => ({
            ...prevState,
            fieldID: fieldID,
        }));
		setIsAccordionExpanded(!isAccordionExpanded); //this toggles whether to skip the query or not
    };

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                onClick={(e) => {
                    handleExpand("visibility_employees");
                }}>
                <h6>Employee</h6>
            </AccordionSummary>
            <AccordionDetails>
                <>
                    <input
                        type="search"
                        className="search"
                        placeholder={`Search ${name}`}
                        ref={inputRef}
                        onChange={(e) => debouncedResults(e, "visibility_employees")}
                    />
                    {selectedLabels &&
                        selectedLabels.map((item: any, index: any) => {
                            const isChecked = storeLabels.includes(item);

                            return (
                                <FormControlLabel
                                    key={index}
                                    sx={{ width: "100%" }}
                                    label={item.match(/^(.+):[^:]+$/)[1]}
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={isChecked}
                                            value={item}
                                            onChange={(e) => onChangeCheckbox(e, "visibility_employees")}
                                        />
                                    }
                                />
                            );
                        })}

                    {loadingSearchFilter ? (
                        <div style={{ display: "grid", justifyContent: "center" }}>
                            <CircularProgress size={20} />
                        </div>
                    ) : newOptions?.length === 0 ? (
                        <Typography variant="body2">No data found</Typography>
                    ) : (
                        newOptions
                            ?.filter((item: any) => !selectedLabels.includes(item))
                            .map((item: any, index: any) => {
                                const isChecked = storeLabels.includes(item);
                                return (
                                    <FormControlLabel
                                        key={index}
                                        sx={{ width: "100%" }}
                                        label={item.match(/^(.+):[^:]+$/)[1]}
                                        control={
                                            <Checkbox
                                                size="small"
                                                checked={isChecked}
                                                value={item}
                                                onChange={(e) => onChangeCheckbox(e, "visibility_employees")}
                                            />
                                        }
                                    />
                                );
                            })
                    )}
                </>
            </AccordionDetails>
        </Accordion>
    );
}
export default IndividualFilterClients;