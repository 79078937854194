import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import { FC, useState } from "react";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { useDispatch } from "react-redux";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialog-paper": {
		maxWidth: "350px",
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

type Props = {
	isReject: boolean;
	// eslint-disable-next-line no-unused-vars
	approveHandler: (reason: string, amount?: number) => void;
	// eslint-disable-next-line no-unused-vars
	rejectHandler: (reason: string) => void;
	handleClose: () => void;
	name: string;
	amountAlloted: number;
};

const AdvanceModal: FC<Props> = ({
	isReject = false,
	approveHandler,
	rejectHandler,
	handleClose,
	name,
	amountAlloted = 0,
}) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const approve = () => {
		if (isReject) {
			rejectHandler(reason);
		} else {
			approveHandler(reason, amount);
		}
		handleClose();
	};
	const [reason, setReason] = useState("");
	const [amount, setAmount] = useState(amountAlloted);
	const handleReasonChange = (e:any) => {
		const inputValue = e.target.value;
		if (inputValue.length <= 100) {
		  setReason(inputValue);
		}
		else{
			dispatch(
				showNotification({
					message: "cannot exceed 100 characters",
					severity: "error",
				})
			);
		}
	  };
	return (
		<BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
			<div
				style={{
					display: "flex !important",
					padding: "16px",
					alignItems: "center",
					width: "100%",
					justifyContent: "center",
				}}>
				{isReject ? (
					<CancelOutlinedIcon sx={{ width: "100%", height: "56px", marginTop: "1rem", color: "#f54747" }} />
				) : (
					<CheckCircleRoundedIcon sx={{ width: "100%", height: "56px", marginTop: "1rem", color: "#40A636" }} />
				)}
				<p
					style={{
						textAlign: "center",
						margin: "1.5rem 0 0.5rem 0",
						fontSize: "14px",
						fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
					}}>
					{isReject ? (
						<>
							Are you sure you want to reject request from <strong>{name ?? "Unnamed User(s)"}</strong>
						</>
					) : (
						<>
							Are you sure you want to approve request from <strong>{name ?? "Unnamed User(s)"} </strong>
						</>
					)}
				</p>
				{!isReject && (
					<input
						placeholder="Enter Reimbursement Amount"
						className="reason"
						type="number"
						min={0}
						value={amount}
						onChange={(e) => setAmount(parseFloat(e.target.value))}
					/>
				)}
				<input
					placeholder="Enter Reason"
					className="reason"
					value={reason}
					onChange={(e) => handleReasonChange(e)}
				/>
			</div>
			<DialogActions
				sx={{
					justifyContent: "center",
					marginBottom: "1rem",
				}}>
				<Button variant="contained" onClick={handleClose} color="inherit">
					Cancel
				</Button>
				<Button variant="contained" onClick={approve} color={isReject ? "error" : "success"}>
					{isReject ? "Reject" : "Approve"}
				</Button>
			</DialogActions>
		</BootstrapDialog>
	);
};

export default AdvanceModal;
