import { FC, MouseEvent, useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector, useDispatch } from "react-redux";
import { APP_DISPATCH, ROOT_STATE, setSearchTerm, setUserShifts, showNotification } from "../../../redux";
import { USER_SHIFT_TYPE } from "../../../@types";
import { saveUserShifts } from "../../../api/attendance";
import useAdminEntitlements from "../../useAdminEntitlements";

type Props = {
	shifts: USER_SHIFT_TYPE["shifts"];
	employeeID: number;
	field: string;
};

const ShiftsDragCell: FC<Props> = ({ employeeID, field, shifts }) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const {
		userShifts: userShiftsData,
		shiftTemplates: shiftsTemplate,
		searchTerm,
	} = useSelector((state: ROOT_STATE) => state.shift);
	const userWritePermission = useAdminEntitlements("attendance:shifts", "write");
	const [hasDraggableItems, setHasDraggableItems] = useState({
		mon: false,
		tue: false,
		wed: false,
		thur: false,
		fri: false,
		sat: false,
		sun: false,
	  });
	  
	  useEffect(() => {
		// Check if there are any draggable items associated with each day
		const newHasDraggableItems = {};
	  
		Object.keys(hasDraggableItems).forEach(day => {
		  const hasItems = shifts.some(shift => shift[day] === 1);
		  newHasDraggableItems[day] = hasItems;
		});
	  
		setHasDraggableItems(prevState => ({
		  ...prevState,
		  ...newHasDraggableItems,
		}));
	  }, [field, shifts]);

	const getShiftData = (shiftTemplateID: string | number) =>
		shiftsTemplate.find((shiftTemplate:any) => String(shiftTemplate["shiftTemplateID"]) === String(shiftTemplateID));

	const removeShift = async (e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>) => {
		// @ts-ignore
		const [employeeID, day, shiftTemplateID] = e.target.id.split("-");

		let clonedUserShiftsData = userShiftsData.map((user: any) => {
			if (user["employeeID"] === employeeID) {
				let userShifts = user["shifts"];
				userShifts = userShifts.map((shift: { [x: string]: number }) => {
					if (shift["shiftTemplateID"] === parseInt(shiftTemplateID)) {
						return { ...shift, [day]: 0 };
					}
					return { ...shift };
				});
				return { ...user, shifts: [...userShifts] };
			} else {
				return { ...user };
			}
		});

		/**
		 * check if shift is removed from all the the days
		 */
		clonedUserShiftsData = clonedUserShiftsData.map((user) => {
			let shifts = user["shifts"];
			shifts = shifts.filter(
				(shift: any) =>
					shift["mon"] || shift["tue"] || shift["wed"] || shift["thur"] || shift["fri"] || shift["sat"] || shift["sun"]
			);
			return { ...user, shifts: shifts };
		});

		const dataForBackend = {};
		clonedUserShiftsData.forEach((user) => {
			if (user.employeeID === employeeID) {
				dataForBackend[employeeID] = user["shifts"];
			}
		});

		if (Object.keys(dataForBackend).length > 0) {
			const { success, message } = await saveUserShifts({ ...dataForBackend }, "Successfully removed shifts");
			if (success) {
				dispatch(setUserShifts({ userShifts: clonedUserShiftsData }));
				dispatch(setSearchTerm(searchTerm));
			}
			dispatch(showNotification({ message: message, severity: success ? "success" : "error" }));
		}
	};
	return (
		<>
			<Droppable droppableId={`${employeeID}-${field}`} direction="horizontal">
				{(provided: any) => (
					<div {...provided.droppableProps} ref={provided.innerRef} className="shift-drag-cell">
						{!hasDraggableItems[field] && <span className="placeholder-content">Weekly Off</span>}

						{shifts &&
							Array.isArray(shifts) &&
							shifts.map((shift, idx) => (
								<>
									{shift[field] ? (
										<Draggable
											key={`${employeeID}-${field}-${shift["shiftTemplateID"]}`}
											draggableId={`${employeeID}-${field}-${shift["shiftTemplateID"]}`}
											isDragDisabled={!userWritePermission}
											index={idx}>
											{(provided) => (
												<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
													<div key={shift["shiftTemplateID"]} className="column-drag-cell">
														<h5
															style={{
																color: getShiftData(shift["shiftTemplateID"])?.color,
																whiteSpace: "nowrap",
															}}>
															{getShiftData(shift["shiftTemplateID"])?.name}
														</h5>
														<ClearIcon
															id={`${employeeID}-${field}-${shift["shiftTemplateID"]}`}
															onClick={(e) => {
																if (userWritePermission) {
																	removeShift(e);
																}
															}}
															sx={{ fontSize: "14px", cursor: "pointer" }}
														/>
													</div>
												</div>
											)}
										</Draggable>
									) : null}
								</>
							))}
					</div>
				)}
			</Droppable>
		</>
	);
};

export default ShiftsDragCell;
