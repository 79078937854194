// react
import { FC } from "react";
// mui
import {
	GridCsvExportOptions,
	GridCsvGetRowsToExportParams,
	gridRowIdsSelector,
	useGridApiContext,
} from "@mui/x-data-grid-pro";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

type Props = {
	fileName?: string;
};

const CustomDataGridExport: FC<Props> = ({ fileName }) => {
	const apiRef = useGridApiContext();

	const handleExport = (options: GridCsvExportOptions) => apiRef.current.exportDataAsCsv(options);

	const getRowsFromCurrentPage = ({ apiRef }: GridCsvGetRowsToExportParams) => gridRowIdsSelector(apiRef);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row-reverse",
				padding: "0px",
				height: "0px",
				position: "unset",
			}}>
			<button
				style={{
					position: "absolute",
					right: "0px",
					top: "-48.50px",
					color: "#1976d2",
					backgroundColor: "#f7f8ff",
					border: "1px solid #819dff",
					borderRadius: "8px",
					width: "40px",
					padding: "5px 0",
					marginLeft: "12px",
				}}
				color="primary"
				onClick={() =>
					handleExport({
						getRowsToExport: getRowsFromCurrentPage,
						utf8WithBom: true,
						fileName: fileName ? `${fileName}` : undefined,
					})
				}>
				<FileDownloadOutlinedIcon
					style={{
						fontSize: "1.5rem",
					}}
				/>
			</button>
		</div>
	);
};

export default CustomDataGridExport;
