import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useId, useMemo } from "react";
import { useDispatch } from "react-redux";
import { ADVANCE_REQUEST_TYPE } from "../../../@types";
import { showNotification } from "../../../redux";
import { findCurrentAdvanceExpenseConveyanceAuth } from "../../../utils";
import { findLastApprovedAmount } from "./AdvanceRequestTable";

type Props = {
	request: ADVANCE_REQUEST_TYPE;
	setRequest: Dispatch<SetStateAction<ADVANCE_REQUEST_TYPE>>;
	canApprove: boolean;
};
const AdvanceForm: FC<Props> = ({ request, setRequest, canApprove }) => {
	const [amount, comment] = useId();
	const dispatch = useDispatch();

	const isPending = request.status === -1 || request.status === 3;
	const currentExpenseAuth = useMemo(() => findCurrentAdvanceExpenseConveyanceAuth(request), [request]);
	if (isPending) {
		//only apply the validation if the current expense is pending...
		if (typeof currentExpenseAuth === "string") {
			dispatch(showNotification({ message: currentExpenseAuth, severity: "error" }));
		}
	}

	const amountProposed = useMemo(() => {
		if (request.advanceExpenseDetails && Array.isArray(request.advanceExpenseDetails)) {
			return request.advanceExpenseDetails.reduce((acc: number, curr) => acc + curr.amountProposed, 0);
		} else if (request.advanceConveyanceDetails && Array.isArray(request.advanceConveyanceDetails)) {
			return request.advanceConveyanceDetails.reduce((acc: number, curr) => acc + curr.amountProposed, 0);
		} else {
			return 0;
		}
	}, [request.advanceExpenseDetails, request.advanceConveyanceDetails]);

	const lastApprovedExpense = findLastApprovedAmount(request);
	const status =
		request.status === 0
			? "Rejected"
			: request.status === 1
			? "Approved"
			: request.status === 2
			? "PaidOut"
			: "Pending";
	const icon =
		status === "Rejected" ? (
			<CloseOutlinedIcon htmlColor="#ffffff" />
		) : status === "Approved" ? (
			<DoneOutlinedIcon htmlColor="#ffffff" />
		) : (
			<CurrencyRupeeOutlinedIcon htmlColor="#ffffff" />
		);
	useEffect(() => {
		const updatedRequest = { ...request };
		const expenseAuthIndex = request.authorizationInfo.history.findIndex((e) => e.actionPerformed === -1);
		if (expenseAuthIndex === -1 || typeof currentExpenseAuth === "string") return;
		updatedRequest.authorizationInfo.history[expenseAuthIndex].allottedAmount =
			currentExpenseAuth?.allottedAmount ?? lastApprovedExpense;
		setRequest(updatedRequest);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentExpenseAuth, lastApprovedExpense]);

	const changeExpenseValue = (e: ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		if (name === "allottedAmount" && isNaN(+value)) return;

		const updatedRequest = { ...request };
		const expenseAuthIndex = request.authorizationInfo.history.findIndex((e) => e.actionPerformed === -1);
		if (expenseAuthIndex === -1) return;
		updatedRequest.authorizationInfo.history[expenseAuthIndex][name] = name === "comment" ? value : parseFloat(value);
		if (name === "comment") {
			if (value.length <= 100) {
				setRequest(updatedRequest);
			} else {
				dispatch(showNotification({ message: "cannot exceed 100 characters", severity: "error" }));
			}
		} else {
			setRequest(updatedRequest);
		}
	};
	
	if (typeof currentExpenseAuth === "string") return <></>;

	return (
		<div className="amount_claimed">
			<div className="claimed">
				<div className="amount">
					<picture className={status}>{icon}</picture>
					<div className="title">
						<p>Total Amount Proposed</p>
						<h1 className="rupee">{amountProposed}</h1>
					</div>
				</div>
				<div className="with_label">
					<label htmlFor={`${amount}-${lastApprovedExpense}`}>Enter Approval Amount</label>
					<input
						id={`${amount}-${lastApprovedExpense}`}
						name="allottedAmount"
						placeholder="Enter Amount"
						type="number"
						required
						min={0}
						value={currentExpenseAuth?.allottedAmount?.toString() ?? lastApprovedExpense?.toString() ?? ""}
						disabled={!isPending || !canApprove}
						onChange={changeExpenseValue}
					/>
				</div>
			</div>
			<div className="form_control" style={{ position: "relative" }}>
				<label htmlFor={comment} style={{ position: "absolute", top: "12px", left: "10px" }}>
					Add Comment
				</label>
				<input
					id={comment}
					name="comment"
					type="text"
					placeholder="Add comment..."
					disabled={!isPending || !canApprove}
					onChange={changeExpenseValue}
					value={currentExpenseAuth?.comment || ""}
				/>
			</div>
		</div>
	);
};

export default AdvanceForm;
