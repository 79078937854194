import { Dispatch, FC, SetStateAction, useMemo } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { SITEPOOLS_DATA_WITH_SITES, SITES_DATA } from "../../../@types";
import _cloneDeep from "lodash/cloneDeep";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
type Props = {
	sitePool: SITEPOOLS_DATA_WITH_SITES;
	sites: SITES_DATA[];
	setSitePool: Dispatch<SetStateAction<SITEPOOLS_DATA_WITH_SITES>>;
	userWritePermission:any;
};
const SitepoolWithSitesView: FC<Props> = ({ sitePool, sites, setSitePool, userWritePermission}) => {
	const sitesPresent = useMemo(
		() => (sites && Array.isArray(sites) ? sites.filter((site) => sitePool?.siteIDs?.includes(site?.clientID)) : []),
		[sitePool.siteIDs, sites]
	);
	const removeSiteFromSitePool = (id: string) => {
		if (!id || id.length === 0) return;
		const newSitePool = _cloneDeep(sitePool);
		newSitePool.siteIDs = _cloneDeep(newSitePool).siteIDs.filter((siteID) => siteID !== id);
		setSitePool(newSitePool);
	};
	return (
		<div className="review_routes">
			<h2>Review Summary ({sitePool?.siteIDs?.length || 0})</h2>
			{sitesPresent.length === 0 && (
				<h3 style={{ textAlign: "center", marginTop: "45%", color: "#1976d2" }}>No Result Found</h3>
			)}

			<ul>
				{Array.isArray(sitesPresent) &&
					sitesPresent.length > 0 &&
					sitesPresent.map((item) => (
						<li key={item.clientID}>
							<div className="details">
								<div className="info">
									<h4>{item.clientName}</h4>
									<span className={`role_badge ${item.employeeID === -2 ? "Site" : "Client"}`}>
										{item.employeeID === -2 ? "Site" : "Client"}
									</span>
									<address>{item.address}</address>
								</div>

								<div className="icon_and_info">
									<Person2OutlinedIcon />
									{item.proprietorName ?? "-"}
								</div>
								<div className="icon_and_info">
									<LocalPhoneOutlinedIcon />
									{item.phoneNumber ?? "-"}
								</div>
								<button type="button" onClick={() => removeSiteFromSitePool(item.clientID)} disabled={!userWritePermission}>
									<DeleteIcon htmlColor="#F54747" />
								</button>
							</div>
						</li>
					))}
			</ul>
		</div>
	);
};

export default SitepoolWithSitesView;
