import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useId, useMemo } from "react";
import { useDispatch } from "react-redux";
import { EXPENSE_REQUEST_TYPE_V1 } from "../../../@types";
import { showNotification } from "../../../redux";
import { findCurrentAdvanceExpenseConveyanceAuth } from "../../../utils";



type Props = {
	selectedExpense: EXPENSE_REQUEST_TYPE_V1;
	setSelectedExpense: Dispatch<SetStateAction<EXPENSE_REQUEST_TYPE_V1>>;
	canApprove: boolean;
};
const ExpenseFormV1: FC<Props> = ({ selectedExpense, setSelectedExpense, canApprove }) => {

	// const [approve_or_reject_advance, { loading: isUpdated, error: authError }] = useMutation(APPROVE_OR_REJECT_ADVANCE);
	// const [mark_advance_paid_out, { loading: isPaid, error: paidOutError }] = useMutation(MARK_ADVANCE_PAID_OUT);

	const [amount, comment] = useId();
	const dispatch = useDispatch();

	const isPending = selectedExpense.status === -1 || selectedExpense.status === 3;

	const currentExpenseAuth = useMemo(() => findCurrentAdvanceExpenseConveyanceAuth(selectedExpense), [selectedExpense]);
	if (isPending) {
		//only apply the validation if the current expense is pending...
		if (typeof currentExpenseAuth === "string") {
			dispatch(showNotification({ message: currentExpenseAuth, severity: "error" }));
		}
	}
	const amountClaimed = useMemo(() => {
		if (selectedExpense.expenseExpenseDetails && Array.isArray(selectedExpense.expenseExpenseDetails)) {
			return selectedExpense.expenseExpenseDetails.reduce((acc: number, curr) => acc + curr.amountClaimed, 0);
		} else {
			return 0;
		}
	}, [selectedExpense.expenseExpenseDetails]);

	const lastApprovedExpense = selectedExpense.authorizationInfo.lastApprovedAmount; // looking for the lastApprovedAmount

	const status =
		selectedExpense.status === 0
			? "Rejected"
			: selectedExpense.status === 1
				? "Approved"
				: selectedExpense.status === 2
					? "PaidOut"
					: "Pending";
	const icon =
		status === "Rejected" ? (
			<CloseOutlinedIcon htmlColor="#ffffff" />
		) : status === "Approved" ? (
			<DoneOutlinedIcon htmlColor="#ffffff" />
		) : (
			<DoneAllIcon htmlColor="#ffffff" />
		);

	const changeExpenseValue = (e: ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		if (name === "reimbursementAmount" && isNaN(+value)) return;

		const updatedRequest : any= { ...selectedExpense };
		const expenseAuthIndex = selectedExpense.authorizationInfo.history.findIndex((e) => e.actionPerformed === -1);
		if (expenseAuthIndex === -1) return;
		updatedRequest.authorizationInfo.history[expenseAuthIndex][name] = name === "comment" ? value : parseFloat(value);
		if (name === "comment") {
			if (value.length <= 100) {
				setSelectedExpense(updatedRequest);
			} else {
				dispatch(showNotification({ message: "cannot exceed 100 characters", severity: "error" }));
			}
		} else {
			setSelectedExpense(updatedRequest);
		}
	};

	useEffect(() => {
		const updatedRequest = { ...selectedExpense };
		const expenseAuthIndex = selectedExpense.authorizationInfo.history.findIndex((e) => e.actionPerformed === -1);
		if (expenseAuthIndex === -1 || typeof currentExpenseAuth === "string") return;
		updatedRequest.authorizationInfo.history[expenseAuthIndex].reimbursementAmount =
		lastApprovedExpense ?? currentExpenseAuth?.reimbursementAmount ?? amountClaimed;
		setSelectedExpense(updatedRequest);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentExpenseAuth, lastApprovedExpense]);
	
	if (typeof currentExpenseAuth === "string") return <></>;

	return (
		<div className="amount_claimed">
			<div className="claimed">
				<div className="amount">
					<picture className={status}>{icon}</picture>
					<div className="title">
						<p>Total Amount Claimed</p>
						<h1 className="rupee">{amountClaimed}</h1>
					</div>
				</div>
				<div className="with_label">
					<label htmlFor={`${amount}-${lastApprovedExpense}`}>Enter Approval Amount</label>
					<input
						id={`${amount}-${lastApprovedExpense}`}
						name="reimbursementAmount"
						placeholder="Enter Amount"
						type="number"
						required
						min={0}
						value={currentExpenseAuth?.reimbursementAmount?.toString() ?? lastApprovedExpense?.toString() ?? ""}
						disabled={!isPending || !canApprove}
						onChange={changeExpenseValue}
					/>
				</div>
			</div>
			<div className="form_control" style={{ position: "relative" }}>
				<label htmlFor={comment} style={{ position: "absolute", top: "12px", left: "10px" }}>
					Add Comment
				</label>
				<input
					id={comment}
					name="comment"
					type="text"
					placeholder="Add comment..."
					disabled={!isPending || !canApprove}
					onChange={changeExpenseValue}
					value={currentExpenseAuth?.comment || ""}
				/>
			</div>
		</div>
	);
};

export default ExpenseFormV1;
