/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import ClearAllOutlinedIcon from "@mui/icons-material/ClearAllOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Avatar, CircularProgress } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import moment from "moment";
import Link from "next/link";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useMutation, useQuery as useQueryApolo } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { NOTIFICATION_TYPE } from "../../@types";
import { dismissAllNotifications, dismissNotification } from "../../api/notification";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../redux";
import { fetchNotificationCounter, findAvatarInitials } from "../../utils";
import NotificationTable from "../Settings/Notification/NotificationTable";
import { GET_NOTIFICATION_STATUS, UPSERT_SUBSCRIBED_NOTIFS_MODULE } from "../../schema/settings";
axios.defaults.withCredentials = true;

type Props = {
	setExpanded: Dispatch<SetStateAction<boolean>>;
	setIsModuleCleared: Dispatch<SetStateAction<any>>;
	isModuleCleared: any;
};
const route = new Map<1 | 2 | 3 | 4 | 5, string>();
route.set(1, "/attendance/leaves");
route.set(2, "/attendance/status");
route.set(3, "/expense/expense_request");
route.set(4, "/clients_sites/geofences");
route.set(5, "/");
const fetchNotifications = async (adminID: number): Promise<AxiosResponse<any>> =>
	await axios({
		method: "post",
		url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/chat/getAllNotifications`,
		headers: { "content-type": `application/x-www-form-urlencoded; charset=UTF-8` },
		data: `adminID=${adminID}`,
	});
const NotificationSidebar: FC<Props> = ({ setExpanded, isModuleCleared, setIsModuleCleared }) => {
	const { data, isLoading } = useQuery("allNotification", () => fetchNotifications(adminID!), {
		staleTime: 200000,
		cacheTime: 200000,
		refetchOnWindowFocus: true,
		refetchOnReconnect: true,
		refetchInterval: 200000,
		onSuccess(data) {
			setNotifications(JSON.parse(data.data.outputJson));
		},
	});
	const [searchTerm, setSearchTerm] = useState({
		1: "",
		2: "",
		3: "",
		4: "",
		5: "",
	});
	const { refetch } = useQuery("notification", () => fetchNotificationCounter(adminID!));
	const dispatch = useDispatch<APP_DISPATCH>();
	const {
		user,
		susbscriptionMetadata: { data: subsMetadata },
	} = useSelector((state: ROOT_STATE) => ({
		user: state.user,
		susbscriptionMetadata: state.susbscriptionMetadata,
	}));

	const [updateNotificationStatus] = useMutation(UPSERT_SUBSCRIBED_NOTIFS_MODULE);
	const [notificationsData, setNotificationsData] = useState<any>([]);
	const {
		data: notificationData,
		loading,
		refetch: refetchNotificationStatus,
	} = useQueryApolo(GET_NOTIFICATION_STATUS);
	const [selected, setSelected] = useState<1 | 2 | 3 | 4 | 5>(1);
	const [notifications, setNotifications] = useState<NOTIFICATION_TYPE[]>([]);
	const [showSettings, setShowSettings] = useState(false);
	const leaves = notifications.filter((x) => x.type === "LEAVES");
	const attendance = notifications.filter((x) => x.type === "ATTENDANCE");
	const expense = notifications.filter((x) => x.type === "EXPENSE");
	const geofence = notifications.filter((x) => x.type === "GEOFENCE");
	const gps = notifications.filter((x) => x.type === "GPS");
	const list = new Map<1 | 2 | 3 | 4 | 5, NOTIFICATION_TYPE[]>();
	list.set(1, leaves);
	list.set(2, attendance);
	list.set(3, expense);
	list.set(4, geofence);
	list.set(5, gps);
	useEffect(() => {
		if (data?.data.outputJson) {
			setNotifications(JSON.parse(data.data.outputJson));
		}
	}, [data?.data.outputJson]);

	const { firstname, lastname, adminID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);

	const handleDismissNotification = async (refernce: string) => {
		const refIndex = notifications.findIndex((x) => x.ref === refernce);
		if (refIndex < 0) return;

		const { success, message } = await dismissNotification(refernce);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (!success) return;
		notifications.splice(refIndex, 1);
		setNotifications([...notifications]);
		await refetch();
	};

	const handleDismissAllNotifications = async (listNotification: NOTIFICATION_TYPE[] | undefined) => {
		if (!listNotification) return;
		const moduleName = listNotification[0].type;
		if (moduleName === "LEAVES") {
			setIsModuleCleared({ ...isModuleCleared, LEAVES: true });
		}
		if (moduleName === "ATTENDANCE") {
			setIsModuleCleared({ ...isModuleCleared, ATTENDANCE: true });
		}
		if (moduleName === "EXPENSE") {
			setIsModuleCleared({ ...isModuleCleared, EXPENSE: true });
		}
		if (moduleName === "GEOFENCE") {
			setIsModuleCleared({ ...isModuleCleared, GEOFENCE: true });
		}
		if (moduleName === "GPS") {
			setIsModuleCleared({ ...isModuleCleared, GPS: true });
		}
		const refernce = listNotification.map((x) => x.ref);
		const { success, message } = await dismissAllNotifications(refernce);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (!success) return;
		const updatedList = notifications.filter((x) => !refernce?.includes(x.ref));
		setNotifications(updatedList);
		await refetch();
	};

	const updateTheStatusOfNotification = async (status: number, id: number) => {
		const updatedStates = notificationsData.map((item: any) => {
			if (item.moduleID === id) {
				return {
					...item,
					status: status === 1 ? 0 : 1,
				};
			}
			return item;
		});
		setNotificationsData(updatedStates);
		try {
			const transformedRequest = [
				{
					adminID: user?.adminDetails?.adminID,
					moduleID: id,
					status: status === 1 ? 0 : 1,
				},
			];
			const response = await updateNotificationStatus({
				variables: {
					input: transformedRequest,
				},
			});
			if (response?.data?.upsert_subscribed_notifs_module?.rowsReplaced !== null) {
				dispatch(showNotification({ message: "Notification settings applied successfully.", severity: "success" }));
			}
			if (response?.data?.upsert_subscribed_notifs_module?.rowsReplaced !== null) {
				refetchNotificationStatus();
			}
		} catch (error) {
			dispatch(showNotification({ message: "Request failed, Please try later.", severity: "error" }));
		}
	};

	const checkIsModuleCleared = (moduleName: any) => isModuleCleared[moduleName] || false;

	const handleSearchTermChange = (event: any) => {
		const { name, value } = event.target;
		setSearchTerm((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};
	useEffect(() => {
		if (notificationData?.get_notifications_module_by_adminID) {
			setNotificationsData(notificationData?.get_notifications_module_by_adminID);
		}
	}, [notificationData]);
	return (
		<section className="column_list notifications">
			<header>
				<div style={{ display: "flex", alignItems: "baseline" }}>
					<h1>Notifications</h1>
					<div
						role="button"
						tabIndex={0}
						onClick={() => setShowSettings(!showSettings)}
						style={{ color: "#1976d2", marginLeft: "10px", cursor: "pointer" }}>
						<p style={{ fontSize: "14px" }}>Edit Preferences</p>
					</div>
				</div>

				<button onClick={() => setExpanded(false)}>
					<CloseOutlinedIcon />
				</button>
			</header>
			{showSettings && (
				<>
					<p
						style={{
							margin: "20px 0 40px 0",
							fontSize: "14px",
							color: "#8f8f8f",
						}}>{`Control which notifications you want to receive.`}</p>
					<NotificationTable
						features={notificationsData}
						loading={loading}
						updateTheStatusOfNotification={updateTheStatusOfNotification}
					/>
				</>
			)}
			{!showSettings && (
				<>
					<ul className="tabs">
						<li onClick={() => setSelected(1)} className={`${selected === 1 ? "active" : ""}`}>
							Leaves
							{leaves.length === 0
								? null
								: !checkIsModuleCleared("LEAVES") && (
										<span className="p-1">{leaves.length > 99 ? "99+" : leaves.length}</span>
								  )}
						</li>
						<li onClick={() => setSelected(2)} className={`${selected === 2 ? "active" : ""}`}>
							Attendance
							{attendance.length === 0
								? null
								: !checkIsModuleCleared("ATTENDANCE") && (
										<span className="p-1">{attendance.length > 99 ? "99+" : attendance.length}</span>
								  )}
						</li>
						{subsMetadata?.expenses === 1 && (
							<li onClick={() => setSelected(3)} className={`${selected === 3 ? "active" : ""}`}>
								Expense
								{expense.length === 0
									? null
									: !checkIsModuleCleared("EXPENSE") && (
											<span className="p-1">{expense.length > 99 ? "99+" : expense.length}</span>
									  )}
							</li>
						)}
						{subsMetadata?.clients === 1 && (
							<li onClick={() => setSelected(4)} className={`${selected === 4 ? "active" : ""}`}>
								Geofence
								{geofence.length === 0
									? null
									: !checkIsModuleCleared("GEOFENCE") && (
											<span className="p-1">{geofence.length > 99 ? "99+" : geofence.length}</span>
									  )}
							</li>
						)}
						<li onClick={() => setSelected(5)} className={`${selected === 5 ? "active" : ""}`}>
							GPS
							{gps.length === 0
								? null
								: !checkIsModuleCleared("GPS") && <span className="p-1">{gps.length > 99 ? "99+" : gps.length}</span>}
						</li>
					</ul>
					<ul className="notifications">
						{list.get(selected) && list.get(selected)!.length > 0 && (
							<>
								<button className="clear_all" onClick={() => handleDismissAllNotifications(list.get(selected))}>
									<ClearAllOutlinedIcon />
									CLEAR ALL
								</button>

								<input
									className="search"
									placeholder="Search Here..."
									value={searchTerm[selected.toString()]}
									name={selected.toString()}
									onChange={handleSearchTermChange}
									style={{
										width: "100%",
										border: "none",
										outline: "none",
										margin: "10px 0px",
										borderBottom: "1px solid #DFDFDF",
									}}
								/>
							</>
						)}
						{!isLoading && list.get(selected) !== undefined ? (
							!checkIsModuleCleared(list?.get(selected)?.[0]?.type) &&
							list
								.get(selected)
								?.filter((x) => x.text.toLowerCase().includes(searchTerm[selected].toLowerCase()))
								.map((item, i) => (
									<Link href={item?.url ?? "/"} passHref key={i} legacyBehavior>
										<li onClick={() => setExpanded(false)}>
											<Avatar>{findAvatarInitials(firstname + " " + lastname || "U")}</Avatar>
											<div>
												<h3>{item.text}</h3>
												<span>{moment(item.ts).fromNow()}</span>
											</div>
											<button
												className="delete_notification"
												onClick={(e) => {
													e.stopPropagation();
													handleDismissNotification(item.ref);
												}}>
												<CloseOutlinedIcon />
											</button>
										</li>
									</Link>
								))
						) : (
							<div className="small_circular-spinner">
								<CircularProgress />
							</div>
						)}
					</ul>
				</>
			)}
		</section>
	);
};

export default NotificationSidebar;
