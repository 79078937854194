import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { FormControl, IconButton, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import "moment-timezone";
import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from "react";
import BlockIcon from "@mui/icons-material/Block";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialogLimit = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		backgroundColor: "#ededed",
		padding: "16px 16px 24px 16px",
		minWidth: "550px",
		position: "relative",
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
	"& .MuiDialog-paper": {
		maxWidth: "550px",
		height: "180px",
	},
}));

interface Props<T> {
	editValue?: any;
	editHierarchicalParentNodeID?: string | null;
	onClose: () => void;
	setSelectedSetting: Dispatch<SetStateAction<T>>;
	open: boolean;
	title: string;
	placeholder: string;
	type: "text" | "number" | "email";
	// eslint-disable-next-line no-unused-vars
	submitHandler: (input: number | string, hierarchicalParentNodeID?: string | null) => Promise<void>;
	subsMetadata: any;
	profiles: any;
	name: string;
	isTeamHierarchyPresent?: boolean;
	teamHierarchyData?: any;
}
function AddModal<T>({
	editValue,
	editHierarchicalParentNodeID,
	open,
	onClose,
	title,
	placeholder,
	type,
	submitHandler,
	subsMetadata,
	profiles,
	name,
	teamHierarchyData,
	isTeamHierarchyPresent,
}: Props<T>) {
	const [input, setInput] = useState(type === "number" ? 0 : "");
	const [hierarchicalParentNodeID, setHierarchicalParentNodeID] = useState<string | null | undefined>(
		isTeamHierarchyPresent ? editHierarchicalParentNodeID : null
	);

	useEffect(() => {
		if (isTeamHierarchyPresent && editHierarchicalParentNodeID) {
			setHierarchicalParentNodeID(editHierarchicalParentNodeID);
		} else if (isTeamHierarchyPresent) {
			setHierarchicalParentNodeID(null);
		} else {
			setHierarchicalParentNodeID(editHierarchicalParentNodeID);
		}
	}, [editHierarchicalParentNodeID, isTeamHierarchyPresent])

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		await submitHandler(input, hierarchicalParentNodeID);
		onClose();
	};

	const handleParentNodeIdChange = (e: any) => {
		setHierarchicalParentNodeID(e.target.value as string);
	};

	useEffect(() => {
		if (editValue) {
			setInput(editValue);
		} else {
			setInput(type === "number" ? 0 : "");
		}
	}, [editValue, type]);

	if (
		(name === "teams" &&  profiles.length >= subsMetadata?.noOfTeams && subsMetadata?.noOfTeams !== -1) ||
		(name === "designations" && profiles.length >= subsMetadata?.noOfDesignation && subsMetadata?.noOfDesignation !== -1)
	) {
		return (
			<BootstrapDialogLimit onClose={onClose} aria-labelledby="customized-dialog-title" open={true}>
				<DialogContent className="customized-dialog-limit">
					<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
							<BlockIcon htmlColor="#f54747" fontSize="medium" />
							<h5>{`Maximum Field Limit Reached`}</h5>
						</div>
						<IconButton aria-label="close" onClick={onClose} style={{ padding: "0px" }}>
							<CloseIcon />
						</IconButton>
					</div>
					<p
						style={{
							marginTop: "12px",
							fontSize: "14px",
							fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
							lineHeight: "22px",
						}}>
						{`You have reached the maximum field limit for your subscription plan. Please upgrade to a Higher Edition, or remove the extra fields.`}
					</p>
					<p
						style={{
							marginTop: "12px",
							fontSize: "14px",
							fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
							lineHeight: "22px",
						}}>
						To Upgrade your subscription plan, contact us on +91 9599936991 or email us at{" "}
						<a style={{ color: "#1976D2", textDecoration: "underline" }}>suppport@unolo.com</a>.
					</p>
					{/* <DialogActions style={{position:"absolute", right:"10px", bottom:"10px"}}>
						<Button
							variant="contained"
							onClick={() => router.push("/billings")}
							sx={{
								fontSize: "12px",
								fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
								fontWeight: 600,
								textTransform: "capitalize",
							}}>
							Upgrade Now
						</Button>
					</DialogActions> */}
				</DialogContent>
			</BootstrapDialogLimit>
		);
	} else {
		return (
			<Modal open={open} onClose={onClose}>
				<div className="settings_modal">
					<div className="title">
						<h4>{title}</h4>
						<button onClick={onClose}>
							<CloseOutlinedIcon htmlColor="#42444A" />
						</button>
					</div>
					<form onSubmit={handleSubmit}>
						<TextField
							required
							type={type}
							label={placeholder}
							value={input}
							inputProps={{ maxLength: 50, minLength: 3 }}
							onChange={(e) => setInput(type === "number" ? +e.target.value : e.target.value)}
							// eslint-disable-next-line jsx-a11y/no-autofocus
							autoFocus
							disabled={name === "teams" && type === "text" && input.toLocaleString().toLowerCase() === "default"}
						/>
						{isTeamHierarchyPresent && name === "teams" && (
							<FormControl fullWidth required={isTeamHierarchyPresent}>
								<InputLabel id="reporting-to-label">Reporting To</InputLabel>
								<Select
									labelId="reporting-to-label"
									id="reporting-to-select"
									value={hierarchicalParentNodeID}
									label="Reporting To"
									required={isTeamHierarchyPresent}
									onChange={handleParentNodeIdChange}>
									{/* loop over the teams from last layer */}
									{teamHierarchyData[teamHierarchyData.length - 1].nodes?.map((data: any) => (
										<MenuItem key={data.id} value={data.id}>
											{data.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
						<button type="submit">Save</button>
					</form>
				</div>
			</Modal>
		);
	}
}

export default AddModal;
