import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Dispatch, FC, FormEvent, SetStateAction } from "react";
import { EXECUTIVE_FILTER_COLUMN, EXECUTIVE_USER } from "../../../@types";
import IndividualFilter from "../../common/datagrid/IndividualFilter";
import { PRODUCT_ID, UNOLO_BIOMETRIC } from "../../../constants";
type COLUMN_TITLES =
	| "Reporting Manager"
	| "Mobile Number"
	| "Team"
	| "Designation"
	| "Employee Name"
	| "User ID"
	| "City"
	| "Model Number"
	| "Status";
type Props = {
	numFilters: number;
	setFilterOpen: Dispatch<SetStateAction<boolean>>;
	executives: EXECUTIVE_USER[];
	filters: Record<EXECUTIVE_FILTER_COLUMN, string[]>;
	setFilters: Dispatch<SetStateAction<Record<EXECUTIVE_FILTER_COLUMN, string[]>>>;
};
const FilterFieldExecutive: FC<Props> = ({ numFilters, setFilterOpen, executives, setFilters, filters }) => {
	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};
	const productID = window ? localStorage.getItem(PRODUCT_ID) ?? "2" : "2";

	const resetFilters = () => {
		setFilters({
			reportingManager: [],
			designationID: [],
			designation: [],
			employeeID: [],
			mobileNumber: [],
			profileID: [],
			city: [],
			modelNumber: [],
			userID: [],
			team: [],
			status: [],
		});
	};
	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
		<form onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()} onKeyPress={(e) => e.stopPropagation()}>
			<div className="filter__header">
				<h5>
					<FilterAltOutlinedIcon />
					Filters
					<span className="right-notification center" style={{ display: "flex", padding: 0 }}>
						{numFilters}
					</span>
				</h5>
				<button onClick={() => setFilterOpen(false)}>
					<CloseSharpIcon htmlColor="#42444A" />
				</button>
			</div>
			<div className="accordion__group">
				<IndividualFilter<COLUMN_TITLES, EXECUTIVE_FILTER_COLUMN>
					filters={filters}
					setFilters={setFilters}
					name="employeeID"
					title="Employee Name"
					data={executives.map((user) => user.firstName + " " + user.lastName)}
					ids={executives.map((user) => user.firstName + " " + user.lastName)}
				/>
				<IndividualFilter<COLUMN_TITLES, EXECUTIVE_FILTER_COLUMN>
					filters={filters}
					setFilters={setFilters}
					name="team"
					title="Team"
					data={executives.map((user) => user.team)}
					ids={executives.map((user) => user.team)}
				/>
				<IndividualFilter<COLUMN_TITLES, EXECUTIVE_FILTER_COLUMN>
					filters={filters}
					setFilters={setFilters}
					name="reportingManager"
					title="Reporting Manager"
					data={executives.map((exec) => exec.reportingManager ?? "")}
					ids={executives.map((exec) => JSON.stringify(exec.parentAdminID) ?? "")}
				/>
				<IndividualFilter<COLUMN_TITLES, EXECUTIVE_FILTER_COLUMN>
					filters={filters}
					setFilters={setFilters}
					name="designation"
					title="Designation"
					data={executives.map((user) => user.designation ?? "")}
					ids={executives.map((user) => user.designation ?? "")}
				/>
				<IndividualFilter<COLUMN_TITLES, EXECUTIVE_FILTER_COLUMN>
					filters={filters}
					setFilters={setFilters}
					name="mobileNumber"
					title="Mobile Number"
					data={executives.map((exec) => exec.mobileNumber ?? "")}
					ids={executives.map((exec) => exec.mobileNumber ?? "")}
				/>
				<IndividualFilter<COLUMN_TITLES, EXECUTIVE_FILTER_COLUMN>
					filters={filters}
					setFilters={setFilters}
					name="city"
					title="City"
					data={executives.map((exec) => exec.city ?? "")}
					ids={executives.map((exec) => exec.city ?? "")}
				/>
				{
				productID === UNOLO_BIOMETRIC ? null :
				<>
					<IndividualFilter<COLUMN_TITLES, EXECUTIVE_FILTER_COLUMN>
						filters={filters}
						setFilters={setFilters}
						name="modelNumber"
						title="Model Number"
						data={executives.map((exec) => exec.modelNumber ?? "")}
						ids={executives.map((exec) => exec.modelNumber ?? "")}
					/>
					<IndividualFilter<COLUMN_TITLES, EXECUTIVE_FILTER_COLUMN>
						filters={filters}
						setFilters={setFilters}
						name="userID"
						title="User ID"
						data={executives.map((exec) => exec.userID ?? "")}
						ids={executives.map((exec) => exec.userID ?? "")}
					/>
					<IndividualFilter<COLUMN_TITLES, EXECUTIVE_FILTER_COLUMN>
						filters={filters}
						setFilters={setFilters}
						name="status"
						title="Status"
						data={["Active", "Inactive"]}
						ids={["Active", "Inactive"]}
					/>
				</>
				}
				
			</div>
			<div className="filter__buttons">
				<button onClick={resetFilters} disabled={numFilters===0} type="reset">
					Reset
				</button>
			</div>
		</form>
	);
};

export default FilterFieldExecutive;
