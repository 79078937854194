/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import DoneIcon from "@mui/icons-material/Done";
import _cloneDeep from "lodash/cloneDeep";
import { Dispatch, FC, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { FORM_OPTIONS_TYPE, FORM_TEMPLATE_TYPE } from "../../../../@types";
import { ROOT_STATE } from "../../../../redux";
import { updateFormQuestion } from "../FormUtils";
type Props = {
	formTemplate: FORM_TEMPLATE_TYPE;
	options: FORM_OPTIONS_TYPE[];
	sectionIdx: number;
	questionIdx: number;
	setFormTemplate: Dispatch<SetStateAction<FORM_TEMPLATE_TYPE>>;
};
const MultipleOptionsQuizMode: FC<Props> = ({ formTemplate, options, sectionIdx, questionIdx, setFormTemplate }) => {
	const user = useSelector((state: ROOT_STATE) => state.user);
	const handleOptionCorrectChange = (isSelected: number, index: number) => {
		if (index < 0) return;
		const { tempFormTemplate } = updateFormQuestion(formTemplate, sectionIdx, questionIdx);
		tempFormTemplate.lastModifiedByAdminID = user.adminDetails.adminID!;
		tempFormTemplate.formSections[sectionIdx].lastModifiedByAdminID = user.adminDetails.adminID!;
		tempFormTemplate.formSections[sectionIdx].formQuestions[questionIdx].lastModifiedByAdminID =
			user.adminDetails.adminID!;
		const newOptions = _cloneDeep(options);
		newOptions[index].correct = isSelected === 1 ? 0 : 1; //flip the value...
		tempFormTemplate.formSections[sectionIdx].formQuestions[questionIdx].options = newOptions;
		setFormTemplate(tempFormTemplate);
	};
	return (
		<>
			{options.map((option, i) => {
				const isSelected = option.correct ?? 0;
				return (
					<div
						key={option.optionValue}
						onClick={() => handleOptionCorrectChange(isSelected, i)}
						style={{
							display: "flex",
							justifyContent: "space-between",
							width: "98%",
							backgroundColor: isSelected ? "#e6f4ea" : "inherit",
							alignItems: "center",
							padding: "1px 10px",
							marginTop: "1rem",
							cursor: "pointer",
						}}>
						<div style={{ display: "flex", alignItems: "center", gap: 20, paddingBlock: 10 }}>
							<p style={{ alignSelf: "center" }}>{i + 1}.</p>
							<p>{option.optionValue}</p>
						</div>
						{isSelected ? <DoneIcon sx={{ color: "#1e8e3e" }} /> : null}
					</div>
				);
			})}
		</>
	);
};

export default MultipleOptionsQuizMode;
