import axios from "axios";

// eslint-disable-next-line import/prefer-default-export
export const clientImageUpload = async (
	image: any,
	extension: ".jpg" | ".jpeg" | ".svg" | ".png" | ".bmp",
	clientID: string
): Promise<{ success: boolean; message: string }> => {
	const formData = new FormData();
	formData.append("photo", image, image.name);
	formData.append("clientID", clientID);
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/clients/clientPhoto`, formData, {
			headers: {
				clientID,
			},
		});
		return { success: res?.data?.success ?? true, message: res?.data?.message || "Client Photo Uploaded" };
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Uploading Client Photo";
		return { success: false, message: errorText };
	}
};
