import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { FC, useState } from "react";
import DeleteModal from "../../common/DeleteModal";
type Props = {
	handleUserId: () => void;
	deleteHandler: () => void;
	name: string;
	canBeDeleted?: boolean;
	userWritePermission:boolean;
};
const ActionButtons: FC<Props> = ({ handleUserId, deleteHandler, name, canBeDeleted, userWritePermission }) => {
	const [open, setOpen] = useState(false);
	return (
		<div className="action-buttons">
			<button
				onClick={(e) => {
					e.stopPropagation();
					handleUserId();
				}}
				disabled={!userWritePermission}>
				<EditOutlinedIcon htmlColor="#8F8F8F" />
			</button>
			<button
				onClick={(e) => {
					e.stopPropagation();
					setOpen(true);
				}}
				disabled={!userWritePermission}>
				<DeleteIcon htmlColor="#F54747" />
			</button>
			{open && (
				<DeleteModal
					deleteHandler={deleteHandler}
					handleClose={() => setOpen(false)}
					name={name}
					canBeDeleted={canBeDeleted}
				/>
			)}
		</div>
	);
};

export default ActionButtons;
