// react
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";

// libraries
import moment from "moment";
import Link from "next/link";

// mui
import { CircularProgress } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

// query
import { GET_CLIENT_BY_ID } from "../../../schema";
import { useQuery } from "@apollo/client";
import { CLIENTS_DATA, ENTITY_METADATA } from "../../../@types";

// components
import DeleteModal from "../../common/DeleteModal";
import useAdminEntitlements from "../../useAdminEntitlements";

// utils and types
import { FORM_REDIRECT_URL } from "../../../utils";

type Props = {
	selectedClient: CLIENTS_DATA | undefined;
	setSelectedClientId: Dispatch<SetStateAction<string | null>>;
	removeCircleFromMarkers: () => void;
	selectedClientId: string;
	// eslint-disable-next-line no-unused-vars
	deleteHandler: (id: string) => Promise<void>;
	expanded: boolean;
	entityMetadata: ENTITY_METADATA[];
	isDeletingClient: boolean;
};
const ClientDetailedSidebarView: FC<Props> = ({
	selectedClient,
	setSelectedClientId,
	removeCircleFromMarkers,
	deleteHandler,
	selectedClientId,
	expanded,
	entityMetadata,
	isDeletingClient,
}) => {
	const [open, setOpen] = useState(false);
	const [defaultContactCustomField, setDefaultContactCustomField] = useState<any>(null);
	const { loading: loading, data: data } = useQuery(GET_CLIENT_BY_ID, {
		skip: selectedClient ? true : false,
		variables: { id: selectedClientId },
	});
	const currentClient = selectedClient || data?.get_client;
	const userWritePermission = useAdminEntitlements("clientsAndSites:clients", "write");

	useEffect(() => {
		if (currentClient) {
			const defaultContact = entityMetadata?.find(
				(metadata) =>
					metadata.active === 1 &&
					metadata.isDefault === 1 &&
					metadata.dataType === "contact" &&
					metadata.fieldName === "Contact"
			);
			const data = currentClient?.customFieldsComplex?.find((each: any) => each.fieldID === defaultContact?.fieldID);
			setDefaultContactCustomField(data);
		}
	}, [entityMetadata]);

	if (loading)
		return (
			<div className="view_client-sidebar">
				<div className="small_circular-spinner">
					<CircularProgress />
				</div>
			</div>
		);

	return (
        <div className={expanded ? "view_client-sidebar open" : "view_client-sidebar close"}>
			<div className="user_view">
				<button
					onClick={() => {
						setSelectedClientId(null);
						removeCircleFromMarkers();
					}}>
					<ArrowBackOutlinedIcon htmlColor="#000000" />
					Client
				</button>
			</div>
			<div className="horizontal_padding">
				<small>Client Name</small>
				<h6>{currentClient.clientName ?? "-"}</h6>
			</div>
			<div className="horizontal_padding">
				<small>Address</small>
				<h6>{currentClient.address ?? "-"}</h6>
			</div>
			<div className="horizontal_padding">
				<small>Description</small>
				<h6 className="description">{currentClient.description ?? "-"}</h6>
			</div>
			<div className="horizontal_padding">
				<small>Client ID</small>
				<h6>{currentClient.internalClientID ?? "-"}</h6>
			</div>
			<div className="horizontal_padding">
				<small>Email Address</small>
				<h6>{currentClient.email ?? "-"}</h6>
			</div>
			<div className="two_column">
				<div style={{ display: "flex", flexDirection: "column", fontSize: "12px" }}>
					<span style={{ fontSize: "inherit" }}>
						<small>Contact Name</small>
						{defaultContactCustomField?.fieldValue ?? "-"}
					</span>
				</div>
				<div>
					<span style={{ fontSize: "inherit" }}>
						<small>Contact Number</small>
						{!defaultContactCustomField?.fieldValue2 || !defaultContactCustomField?.fieldValue3
							? "-"
							: `+${defaultContactCustomField?.fieldValue2} ${defaultContactCustomField?.fieldValue3}`}
					</span>
				</div>
			</div>
			<div className="two_column">
				<div>
					<small>Visible To</small>
					<h6 className="small">{currentClient.visibleTo ?? "-"}</h6>
				</div>
				<div>
					<small>Can Exec change location</small>
					<h6 className="small">Yes</h6>
				</div>
			</div>
			<div className="two_column">
				<div>
					<small>City</small>
					{currentClient.city ?? "-"}
				</div>
				<div>
					<small>Pin code</small>
					<h6 className="small">{currentClient.pinCode ?? "-"}</h6>
				</div>
			</div>
			<div className="two_column">
				<div>
					<small>Category</small>
					aaaaa
				</div>
				<div>
					<small>Radius (In meters)</small>
					<h6 className="small">{currentClient.radius ?? "-"}</h6>
				</div>
			</div>
			<div className="two_column">
				<div>
					<small>Latitude</small>
					<h6 className="small">{currentClient.lat ?? "-"}</h6>
				</div>
				<div>
					<small>Longitude</small>
					<h6 className="small">{currentClient.lng ?? "-"}</h6>
				</div>
			</div>
			<div className="entityMetadata_column">
				{entityMetadata
					?.filter((d) => d.active && d.isDefault === 0)
					?.map((eD, idx) => (
						<div key={idx}>
							<small>{eD?.fieldName ?? ""}</small>
							<div
								className="small"
								style={{ display: "flex", flexDirection: "column", gap: "5px", lineBreak: "anywhere" }}>
								{getEMValues(currentClient, eD)}
							</div>
						</div>
					))}
			</div>
			<div className="filter__buttons">
				{userWritePermission ? (
					(<Link href={`/clients_sites/clients/${currentClient.clientID}`} passHref>

                        <EditOutlinedIcon />Edit
                    </Link>)
				) : (
					<button disabled className="add mui-button">
						<EditOutlinedIcon />
						Edit
					</button>
				)}
				<button disabled={!userWritePermission || isDeletingClient} type="button" onClick={() => setOpen(true)}>
					{isDeletingClient ? (
						<CircularProgress style={{ width: "15px", height: "15px", color: "white" }} />
					) : (
						<>
							Delete <DeleteOutlineOutlinedIcon />
						</>
					)}
				</button>
			</div>
			{open && (
				<DeleteModal
					deleteHandler={() => deleteHandler(currentClient.clientID)}
					handleClose={() => setOpen(false)}
					name={currentClient.clientName ?? ""}
				/>
			)}
		</div>
    );
};

export default ClientDetailedSidebarView;

const getEMValues = (currentClient: CLIENTS_DATA, eD: ENTITY_METADATA) => {
	const fd = currentClient?.customFieldsComplex?.find((d: any) => d.fieldID === eD?.fieldID);

	if (!fd) return "-";
	if (eD.dataType === "date") return moment(fd.fieldValue).format("DD-MM-YYYY");
	if (eD.dataType === "photo" || eD.dataType === "file") {
		return (
			<>
				{fd.selectedFieldValues?.map((d: any, idx: number) => (
					<a key={idx} href={d.fieldValue} target="_blank" rel="noreferrer" style={{ fontSize: "12px" }}>
						{d.fieldValue2}
					</a>
				))}
			</>
		);
	}
	if (eD.dataType === "contact") {
		if (!fd.fieldValue && !fd.fieldValue2 && !fd.fieldValue3) return "-";
		return (
			<div style={{ display: "flex", flexDirection: "column", fontSize: "12px" }}>
				<span style={{ fontSize: "inherit" }}>
					<b>Name:</b> {fd.fieldValue ?? "-"}
				</span>
				<span style={{ fontSize: "inherit" }}>
					<b>Number:</b> {!fd.fieldValue2 || !fd.fieldValue3 ? "-" : `+${fd.fieldValue2} ${fd.fieldValue3}`}
				</span>
			</div>
		);
	}
	if (eD.dataType === "form") {
		if (!fd.fieldValue || !fd.fieldValue2) return "-";
		return (
			// @ts-ignore
			<a
				href={`${FORM_REDIRECT_URL}/${fd?.fieldValue}?createdTs=${fd?.fieldValue2}`}
				target="_blank"
				rel="noreferrer"
				style={{ fontSize: "12px" }}>
				Form Link
			</a>
		);
	}
	if (fd.fieldValue)
		return <div style={{ display: "flex", flexDirection: "column", fontSize: "12px" }}>{fd.fieldValue}</div>;
	if (fd.selectedOptionsValues)
		return Array.isArray(fd.selectedOptionsValues) ? fd.selectedOptionsValues?.join(", ") : fd.selectedOptionsValues;
	return "-";
};
