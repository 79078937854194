import { SITES_DATA } from "../../@types";
import axios from "../../utils/axios";
import { SITEPOOLS_DATA_WITH_SITES } from "./../../@types/index";
axios.defaults.withCredentials = true;

export const deleteSites = async (clientID: string[]): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/clients/deleteClients`, {
			clientIDs: clientID,
		});
		return { success: res?.data?.success ?? true, message: res?.data?.message || "Successfully Deleted Site" };
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Deleting Site";
		return { success: false, message: errorText };
	}
};
export const updateSites = async (body: SITES_DATA): Promise<{ success: boolean; message: string }> => {
	const data = {
		clientID: body.clientID,
		address: body.address,
		canOverride: body.canOverride,
		city: body.city,
		clientName: body.clientName,
		companyID: body.companyID,
		description: body.description,
		employeeID: body.employeeID,
		internalClientID: body.internalClientID,
		jobTypeID: body.jobTypeID,
		latitude: body.latitude,
		longitude: body.longitude,
		phoneNumber: body.phoneNumber,
		pinCode: body.pinCode,
		proprietorName: body.proprietorName,
		radius: body.radius,
		siteType: body.siteType,
		polyline: body.polyline,
	};

	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/clients/updateClient`, data);
		return { success: res?.data?.success ?? true, message: res?.data?.message || "Successfully Updated Site" };
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Updating Site";
		return { success: false, message: errorText };
	}
};
export const toDeleteSitePool = async (sitePoolID: string): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/clients/deleteSitePool`, {
			sitePoolID,
		});
		return { success: res?.data?.success ?? true, message: res?.data?.message || "Successfully Deleted SitePool" };
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Deleting SitePool";
		return { success: false, message: errorText };
	}
};
export const upsertNewSitePool = async (
	sitePool: SITEPOOLS_DATA_WITH_SITES
): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/sites/upsertSitePools`, {
			name: sitePool.name,
			sitePoolID: sitePool.sitePoolID,
			sites: sitePool.siteIDs,
		});
		return { success: res?.data?.success ?? true, message: res?.data?.message || "Successfully Updated SitePool" };
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Updating SitePool";
		return { success: false, message: errorText };
	}
};

export const deleteGeoFencePoolById = async (id: string): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios({
			method: "POST",
			url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/clients/delteGeofencePool`,
			data: `geofencePoolID=${id}`,
			headers: { "content-type": `application/x-www-form-urlencoded; charset=UTF-8` },
		});
		return { success: res?.data?.success ?? true, message: res?.data?.message || "Geofence pool successfully deleted" };
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Deleting GeofencePool";
		return { success: false, message: errorText };
	}
};

export const customFieldImageUpload = async (
	image: any,
	assetname: string,
	entitytype: string
): Promise<{ status: string; imageUrl: string }> => {
	const formData = new FormData();
	formData.append("asset", image, image.name);
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/assetUpload`, formData, {
			headers: {
				assetname,
				entitytype,
			},
		});

		return { status: res.data?.status, imageUrl: res.data?.assetUrl };
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Uploading Image";
		return { status: "failed", imageUrl: errorText };
	}
};
