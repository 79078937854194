import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import { FC, useEffect, useState } from "react";
import useFetch from "../useFetch";
import {
	deleteTestData,
} from "../../api/field-executive";
import { FE_TEST_DELETE_WARNING } from "../../constants";
import WaitingDialog from "../Organization/WaitingDialog";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialog-paper": {
		width: "650px",
		borderRadius: "10px",
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
		display: "flex",
		justifyContent: "flex-end",
		paddingRight: "1.5rem",
		gap: "0.5rem",
	},
}));

type Props = {
	deleteHandler: () => void;
	handleClose: () => void;
	warningText?: string;
};

const TestDataDeleteModal: FC<Props> = ({ deleteHandler, handleClose, warningText }) => (
	<BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
			<div
				style={{
					display: "flex",
					padding: "16px",
					alignItems: "center",
					width: "100%",
					justifyContent: "center",
					flexDirection: "column",
				}}>
					<p
						style={{
							textAlign: "center",
							margin: "1.5rem 0 0.5rem 0",
							fontSize: "16px",
							fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
						}}>
							<p style={{ lineHeight: "1.5rem" }}>
								Are you sure you want to delete <strong>Sample Data</strong>.
							</p>
					</p>
					{warningText && warningText.length > 0 && (
						<p
							className="font-m-12"
							style={{
								color: "#ef9943"
							}}>
							<WarningOutlinedIcon sx={{ width: "15px", margin: "-2px 5px auto 0" }} />
							{warningText}
						</p>
					)}
			</div>
				<DialogActions
					sx={{
						justifyContent: "center",
						marginBottom: "1rem",
					}}>
					<button
						style={{
							textTransform: "capitalize",
							background: "#f5f5f5",
							color: "black",
							border: "1px solid #f5f5f5",
							padding: "5px 14px",
							borderRadius: "4px",
							boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
							fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
						}}
						onClick={(e) => {
							e.stopPropagation();
							handleClose();
						}}>
							Cancel
					</button>
					<button
						onClick={(e) => {
							e.stopPropagation();
							deleteHandler();
							handleClose();
						}}
						style={{
							textTransform: "capitalize",
							background: "transparent linear-gradient(180deg, #FF4657 0%, #E23041 100%)",
							color: "#fff",
							border: "1px solid #f54747",
							padding: "5px 14px",
							borderRadius: "4px",
							boxShadow: "2px 2px 4px #9e0e1a",
							fontFamily: "SFUIText-Medium, Helvetica, Arial, sans-serif",
						}}>
						<span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Delete</span>
					</button>
				</DialogActions>
		</BootstrapDialog>
);

const TestDataDelete = ( ) => {
	const [hasTestData, setHasTestData] = useState<number>(-1);
	const [openTestDataDeleteModal, setOpenTestDataDeleteModal] = useState(false);
	const [openTestDataDeleteDialog, setOpenTestDataDeleteDialog] = useState(false);

	const { data: dataHasTest } = useFetch<any>("/onboarding/hasTestData");
	useEffect(() => {
		if (dataHasTest) {
			if (dataHasTest?.hasTestData) {
				setHasTestData(1);
			} else {
				setHasTestData(0);
			}
		} else {
			setHasTestData(-1);
		}
	}, [dataHasTest]);


	const deleteTestDataHandler = async () => {
		const { success } = await deleteTestData();
		if (!success) return;
		setOpenTestDataDeleteDialog((prev) => !prev);
		setHasTestData(0);
	};
	return <>
		{openTestDataDeleteModal && (
			<TestDataDeleteModal
				deleteHandler={deleteTestDataHandler}
				handleClose={() => setOpenTestDataDeleteModal(false)}
				warningText={FE_TEST_DELETE_WARNING}
			/>
		)}
		{openTestDataDeleteDialog && (
			<WaitingDialog
				open={openTestDataDeleteDialog}
				handleClose={() => {
					setOpenTestDataDeleteDialog(false);
				}}
			/>
		)}
		{
			hasTestData == 1 &&
			<button
				className="action fe_delete"
				onClick={() => setOpenTestDataDeleteModal(true)}>
				Delete Sample Data
			</button>
		}
	</>
	
}
export default TestDataDelete;