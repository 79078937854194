import { FC } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { ATTENDANCE_STATS_DATA } from "../../@types";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";

const CustomCard = styled(Card)({
	boxShadow: "0px 1px 80px rgba(207, 207, 207, 0.8)",
	borderRadius: "12px",
	width: "100%",
	"& .MuiTypography-root": {
		marginBottom: 0,
	},
	"& .MuiCardContent-root: last-child": {
		paddingBottom: "16px",
	},
});

type Props = {
	statsData: ATTENDANCE_STATS_DATA[];
};

const StatsCard: FC<Props> = ({ statsData }) => (
	<div className="inline-stats-card">
		{statsData.map((data, idx) => (
			<CustomCard key={idx} sx={{ background: data["cardBackgroundColor"] }}>
				<CardContent>
					<div className="inline-stats-card-body">
						<div className="inline-stats-card-icon-wrapper" style={{ background: data["iconBackgroundColor"] }}>
							<PeopleOutlineOutlinedIcon sx={{ color: data["iconColor"] }} />
						</div>
						<div>
							<Typography className="inline-stats-card-label" sx={{ color: data["labelColor"] }}>
								{data["label"]}
							</Typography>
							<Typography className="inline-stats-card-count" sx={{ color: data["countColor"] }}>
								{data["count"]}
							</Typography>
						</div>
					</div>
				</CardContent>
			</CustomCard>
		))}
	</div>
);

export default StatsCard;
