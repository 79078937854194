import axios from "axios";
import { PRODUCT_ID } from "../constants";
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
	(config: any) => {
		config.headers.productid = localStorage.getItem(PRODUCT_ID)!;
		return config;
	},
	(error) => Promise.reject(error)
);
axios.interceptors.response.use(
	function (res: any) {
		return res;
	},
	(error) => Promise.reject(error)
);
export default axios;
