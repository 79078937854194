/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Button, TextField } from "@mui/material";
import _cloneDeep from "lodash/cloneDeep";
import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from "react";
import { SITEPOOLS_DATA_WITH_SITES, SITES_DATA } from "../../../@types";
type Props = {
	sitePool: SITEPOOLS_DATA_WITH_SITES;
	sites: SITES_DATA[];
	setSitePool: Dispatch<SetStateAction<SITEPOOLS_DATA_WITH_SITES>>;
	userWritePermission:any;
};
const AddSitesToSitepool: FC<Props> = ({ sitePool, sites, setSitePool, userWritePermission }) => {
	const [searchTerm, setSearchTerm] = useState("");
	const debouncedChanges = (e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value);

	const handleSiteCheckboxChange = (id: string) => {
		if(!userWritePermission) return;
		if (!id || id.length === 0) return;
		const newSitePool = _cloneDeep(sitePool);
		const alreadyPresentSite = newSitePool.siteIDs.find((siteID) => siteID === id);
		if (!alreadyPresentSite) {
			newSitePool.siteIDs.push(id);
			setSitePool(newSitePool);
		}
	};

	const filteredSites =
		sites && Array.isArray(sites)
			? sites.filter((site) =>
					site.clientName
						?.replace(/ /g, "")
						.toLocaleLowerCase()
						.includes(searchTerm.replace(/ /g, "").toLocaleLowerCase())
			  )
			: [];
	return (
		<div className="routes_list-container">
			<TextField
				fullWidth
				required
				label="Site Pool Name"
				name="name"
				value={sitePool?.name ?? ""}
				onChange={(e) => setSitePool({ ...sitePool, name: e.target.value })}
				disabled={!userWritePermission}
			/>
			<div className="routes_list-box">
				<input className="search" type="search" placeholder="Search Here.." onChange={debouncedChanges} />
				<ul>
					{filteredSites.map((site) => (
						<li key={site.clientID} onClick={() => handleSiteCheckboxChange(site.clientID)}>
							<div className="details">
								<h4>{site.clientName}</h4>
								<address>{site.address}</address>
							</div>
							<Button
								variant="text"
								className="mui-button"
								disabled={!userWritePermission}
								onClick={() => userWritePermission && handleSiteCheckboxChange(site.clientID)}
								sx={{ margin: 0, padding: 0, minWidth: "auto" }}>
								<AddOutlinedIcon sx={{ marginRight: 1 }} />
							</Button>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default AddSitesToSitepool;
