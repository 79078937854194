/* eslint-disable react-hooks/exhaustive-deps */
import { GridColDef, GridValueGetterParams, GridRowClassNameParams } from "@mui/x-data-grid";
import { DataGridPro, GridRowParams } from "@mui/x-data-grid-pro";
import "moment-timezone";
import { useRouter } from "next/router";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { EXECUTIVE_USER } from "../../../@types";
import { deleteMultipleUsers } from "../../../api/field-executive";
import { DATA_GRID_CUSTOMIZATION_OPTIONS, DATA_GRID_NAMES, DG_STYLES, PRODUCT_ID, UNOLO_BIOMETRIC } from "../../../constants";
import { APP_DISPATCH, showNotification } from "../../../redux";
import ColumnEmployee from "../../common/datagrid/ColumnEmployee";
import ColumnModifierSidebar from "../../common/datagrid/ColumnModifierSidebar";
import CustomColumnMenu from "../../common/datagrid/CustomColumnMenu";
import RightSidebar from "../../common/RightSidebar";
import useAdminEntitlements from "../../useAdminEntitlements";
import ColumnActionButtons from "../ColumnActionButtons";

import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { useSelector } from "react-redux";
import { formatTheDataFromSlice, getTheSavedColumns, modifyColumOrder, modifyColumnWidth } from "../../../utils";
import { setColumnConfigurations, setColumnSortModel, setPinnedColumns } from "../../../redux/slices/dataGridSlice";
import { formatDateTo_DDMMYYYY } from "../../../utils/validations";

const IOSSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 22,
		height: 22,
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));

type Props = {
	executives: EXECUTIVE_USER[];
	setExecutives: Dispatch<SetStateAction<EXECUTIVE_USER[]>>;
	setSelectedRows: Dispatch<SetStateAction<any>>;
	loading: boolean;
	filteredExecutives: EXECUTIVE_USER[];
	singleActivateHandler: (employeeID: number) => void;
	singleDeactivateHandler: (employeeID: number) => void;
};
const FieldExecutiveTable: FC<Props> = ({
	executives,
	setSelectedRows,
	setExecutives,
	loading,
	filteredExecutives,
	singleActivateHandler,
	singleDeactivateHandler,
}) => {
	const productID = window ? localStorage.getItem(PRODUCT_ID) ?? "2" : "2";

	const [isSaving, setIsSaving] = useState(false);
	const [expandColumn, setExpandColumn] = useState(false);
	const dispatch = useDispatch<APP_DISPATCH>();
	const [pageSize, setPageSize] = useState(20);
	const router = useRouter();
	const [columns, setColumns] = useState<GridColDef[]>([]);
	const userWritePermission = useAdminEntitlements("organization:employees", "write");
	const BIOMETRIC_COLUMNS = [
		{
			field: "name",
			headerName: "Employee Name",
			width: 180,
		},
		{
			field: "imgUrl",
			headerName: "Photo",
			renderCell: (params: GridValueGetterParams) => (
				<img draggable="false" width={100} src={params.row.imgUrl} alt="Employee Profile" />
			),
			width: 180,
		},
		{
			field: "internalEmpID",
			headerName: "Employee ID",
			width: 150,
		},
	
		{
			field: "mobileNumber",
			headerName: "Phone Number (with country code)",
			width: 150,
			sortable: false,
		},
		{
			field: "joiningDate",
			headerName: "Joining Date",
			width: 150,
			renderCell: (params: GridValueGetterParams) =>
				params.row.joiningDate ? formatDateTo_DDMMYYYY(params.row.joiningDate) : "-",
		},
		{
			field: "reportingManager",
			headerName: "Reporting Manager",
			width: 150,
		},
		{
			field: "team",
			headerName: "Team",
			width: 150,
		},
		{
			field: "designation",
			headerName: "Designation",
			width: 120,
		},
		{
			field: "baseSite",
			headerName: "Base Site",
			width: 120,
		},
		{
			field: "city",
			headerName: "City",
			width: 120,
		},
		{
			field: "action",
			headerName: "Action",
			width: 100,
			sortable: false,
			renderCell: (params: GridValueGetterParams) =>
				(
					<ColumnActionButtons
						link={`/organization/field_executive/${params.row.employeeID}`}
						name={params.row.name}
						deleteHandler={(transferID: number | null, transfer: boolean) => {
							deleteExecutive(params.row.employeeID, transferID, transfer);
						}}
						isTest={params?.row?.isTest}
						selectedRows={[params.row.employeeID]}
						executives={executives}
						userWritePermission={userWritePermission}
					/>
				) 
		},
	];
	
	const COLUMNS = [
		{
			field: "employee",
			headerName: "Employee",
			renderCell: (params: GridValueGetterParams) => (
				<ColumnEmployee name={params.row.name} emailID={params.row.emailID} avatar={params.row.imgUrl} />
			),
			valueGetter: (params: GridValueGetterParams) => params.row.name,
			sortComparator: (v1: string, v2: string) => v1.localeCompare(v2),
			width: 180,
		},
		{
			field: "userID",
			headerName: "User ID",
			width: 100,
		},
		{
			field: "internalEmpID",
			headerName: "Employee ID",
			width: 150,
		},
	
		{
			field: "mobileNumber",
			headerName: "Phone Number (with country code)",
			width: 150,
			sortable: false,
		},
		{
			field: "joiningDate",
			headerName: "Joining Date",
			width: 150,
			renderCell: (params: GridValueGetterParams) =>
				params.row.joiningDate ? formatDateTo_DDMMYYYY(params.row.joiningDate) : "-",
		},
		{
			field: "reportingManager",
			headerName: "Reporting Manager",
			width: 150,
		},
		{
			field: "team",
			headerName: "Team",
			width: 150,
		},
		{
			field: "designation",
			headerName: "Designation",
			width: 120,
		},
		{
			field: "baseSite",
			headerName: "Base Site",
			width: 120,
		},
		{
			field: "sitePool",
			headerName: "Site Pool",
			width: 120,
		},
		{
			field: "city",
			headerName: "City",
			width: 120,
		},
		{
			field: "attendanceRestrictionToSiteID",
			headerName: "Attendance Restriction IN (Site)",
			width: 150,
		},
		{
			field: "attendanceRestrictionToSitePoolID",
			headerName: "Attendance Restriction IN (SitePool)",
			width: 150,
		},
		{
			field: "attendanceOutRestrictionToSiteID",
			headerName: "Attendance Restriction OUT (Site)",
			width: 150,
		},
		{
			field: "attendanceOutRestrictionToSitePoolID",
			headerName: "Attendance Restriction OUT (SitePool)",
			width: 150,
		},
		{
			field: "geofences",
			headerName: "Geofence",
			width: 120,
		},
		{
			field: "geofencePool",
			headerName: "Geofence Pool",
			width: 120,
		},
		{
			field: "modelNumber",
			headerName: "Device",
			width: 100,
		},
		{
			field: "defaultTransportMode",
			headerName: "Default Transport Mode",
			width: 100,
			renderCell: (params: GridValueGetterParams) => {
				if (params.row.defaultTransportMode === 1) return "Car";
				else return "Bike";
			},
		},
		{
			field: "active",
			headerName: "Active",
			sortable: false,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip
					TransitionComponent={Fade}
					TransitionProps={{ timeout: 600 }}
					componentsProps={{
						tooltip: {
							sx: {
								bgcolor: "common.black",
								"& .MuiTooltip-arrow": {
									color: "common.black",
								},
								color: "white",
							},
						},
					}}
					title={
						params.row.active ? (
							<div style={{ fontSize: "0.85rem", padding: "0.3rem", textAlign: "center" }}>
								<p>Revoking access for an Employee keeps their data but prevents them from logging in.</p>{" "}
								<p style={{ marginTop: "0.85rem", color: "#ef9943" }}>
									Please note this does not reduce your license cost.
								</p>
							</div>
						) : (
							<div style={{ fontSize: "0.85rem", padding: "0.3rem", textAlign: "center" }}>
								<p>Granting Access to an Employee allows them to log in.</p>
							</div>
						)
					}
					placement="left">
					<FormControlLabel
						onClick={(e) => {
							e.stopPropagation();
							if(!userWritePermission) return;
							if (params.row.active == 0) singleActivateHandler(params.row.employeeID);
							else singleDeactivateHandler(params.row.employeeID);
						}}
						control={<IOSSwitch sx={{ m: 1 }} checked={params.row.active == 1} style={{cursor: !userWritePermission ? "not-allowed" :"pointer"}}/>}
						label=""
						style={{ cursor: !userWritePermission ? "not-allowed" :"pointer"}}
					/>
				</Tooltip>
			),
			width: 150,
		},
		{
			field: "action",
			headerName: "Action",
			width: 100,
			sortable: false,
			renderCell: (params: GridValueGetterParams) =>
				(
					<ColumnActionButtons
						link={`/organization/field_executive/${params.row.employeeID}`}
						name={params.row.name}
						deleteHandler={(transferID: number | null, transfer: boolean) => {
							deleteExecutive(params.row.employeeID, transferID, transfer);
						}}
						isTest={params?.row?.isTest}
						selectedRows={[params.row.employeeID]}
						executives={executives}
						userWritePermission={userWritePermission}
					/>
				) 
		},
	];
	const tempColumns = useMemo(
		() => productID === UNOLO_BIOMETRIC ? BIOMETRIC_COLUMNS : COLUMNS,
		[executives, productID, userWritePermission]
	);

	const deleteExecutive = async (id: number, transferID: number | null, transfer: boolean) => {
		const execIndex = executives.findIndex((exec) => exec.employeeID === id);
		if (isNaN(id) || execIndex < 0 || (transfer && (transferID === null || isNaN(transferID)))) return;
		setIsSaving(true);
		const toDelete: any = {
			employeeID: executives[execIndex].employeeID,
			newOwnerID: transfer && transferID ? transferID : "None",
			parentAdminID: executives[execIndex].parentAdminID,
			userID: executives[execIndex].userID,
			rekognitionFaceID: executives[execIndex].rekognitionFaceID,
		};
		const { success, message } = await deleteMultipleUsers([toDelete]);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		setIsSaving(false);
		if (!success) return;
		setExecutives(executives.filter((exec) => exec.employeeID !== id));
	};

	useEffect(() => {
		setColumns(tempColumns);
	}, [tempColumns]);

	const getColum = useSelector((state: any) => state.dataGrid);
	const savedColumnConf = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.organization_employees,
		DATA_GRID_CUSTOMIZATION_OPTIONS.columns_array
	);
	const savedColumnSortModel = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.organization_employees,
		DATA_GRID_CUSTOMIZATION_OPTIONS.sort_model
	);
	const savedPinnedColumns = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.organization_employees,
		DATA_GRID_CUSTOMIZATION_OPTIONS.pinned_column
	);
	const formattedcolumn = formatTheDataFromSlice(savedColumnConf, columns) || [];

	return (
		<div className="datagrid-table">
			<button className="filter__button" onClick={() => setExpandColumn((prev) => !prev)}>
				<img src="/images/filter.svg" alt="" />
			</button>
			<DataGridPro
				sx={DG_STYLES}
				rows={filteredExecutives}
				columns={formattedcolumn?.length > 0 ? formattedcolumn : columns}
				sortModel={savedColumnSortModel?.length > 0 ? savedColumnSortModel : []}
				pinnedColumns={savedPinnedColumns && Object.keys(savedPinnedColumns).length > 0 ? savedPinnedColumns : {}}
				loading={loading || isSaving}
				pageSize={pageSize}
				getRowId={(row) => row.employeeID}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[5, 10, 20, 50]}
				rowHeight={70}
				checkboxSelection
				disableSelectionOnClick
				disableColumnFilter
				getRowHeight={() => productID === UNOLO_BIOMETRIC ? 180 : null }  // Increase the height of the datagrid if there is photo which will be only in Biometric
				onRowClick={(params: GridRowParams<EXECUTIVE_USER>) => {
					if (userWritePermission && params.row.isTest === 0) {
						router.push(`/organization/field_executive/${params.id}`);
					}
				}}
				onSelectionModelChange={(selectionModel) => {
					setSelectedRows(selectionModel);
				}}
				pagination
				components={{ ColumnMenu: CustomColumnMenu }}
				getRowClassName={(param: GridRowClassNameParams) =>
					param.row.active === 0 ? "deactivated_fieldexecutive-column" : ""
				}
				onColumnOrderChange={(params) => {
					const modifiedColumn = modifyColumOrder(
						DATA_GRID_NAMES.organization_employees,
						params,
						formattedcolumn?.length > 0 ? formattedcolumn : columns
					);
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.organization_employees,
						columnArray: modifiedColumn,
					};
					dispatch(setColumnConfigurations(newColumnConfigurations));
				}}
				onSortModelChange={(params) => {
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.organization_employees,
						sortModel: params,
					};
					dispatch(setColumnSortModel(newColumnConfigurations));
				}}
				onColumnWidthChange={(params) => {
					const modifiedColumn = modifyColumnWidth(params, formattedcolumn?.length > 0 ? formattedcolumn : columns);
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.organization_employees,
						columnArray: modifiedColumn,
					};
					dispatch(setColumnConfigurations(newColumnConfigurations));
				}}
				onPinnedColumnsChange={(params) => {
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.organization_employees,
						pinnedColumns: params,
					};
					dispatch(setPinnedColumns(newColumnConfigurations));
				}}
			/>
			{expandColumn && (
				<RightSidebar expanded={expandColumn} setExpanded={setExpandColumn}>
					<ColumnModifierSidebar
						setExpanded={setExpandColumn}
						columns={columns}
						localString={DATA_GRID_NAMES.organization_employees}
					/>
				</RightSidebar>
			)}
		</div>
	);
};

export default FieldExecutiveTable;
