import { GridColumnMenuContainer, GridFilterMenuItem, SortGridMenuItems } from "@mui/x-data-grid";
import { GridColumnPinningMenuItems } from "@mui/x-data-grid-pro";

const CustomColumnMenu = (props: any) => {
	const { hideMenu, currentColumn } = props;
	return (
		// @ts-ignore
		<GridColumnMenuContainer hideMenu={hideMenu} currentColumn={currentColumn}>
			<SortGridMenuItems onClick={hideMenu} column={currentColumn} />
			<GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
			<GridColumnPinningMenuItems onClick={hideMenu} column={currentColumn} />
		</GridColumnMenuContainer>
	);
};

export default CustomColumnMenu;
