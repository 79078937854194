/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */

//react
import { Dispatch, FC, FormEvent, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Link from "next/link";

//apollo
import { useMutation, useQuery } from "@apollo/client";

//moment, lodash
import moment from "moment";
import _cloneDeep from "lodash/cloneDeep";
import dayjs from "dayjs";

//types, constants, utils
import { ENTITY_METADATA, PRODUCT_TYPE, PROFILES_DATA } from "../../../@types";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { UPSERT_SKUS } from "../../../schema";
import { productImageUpload } from "../../../api/field-executive";
import CustomFieldsComplex from "../../common/CustomFieldsComplex";
import { GET_ALL_ENTITYMETADATA_BY_ENTITY_FOR_CURRENT_COMPANY } from "../../../schema/entityMetadata";

//mui
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormLabel,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Card,
	CardContent,
	Typography,
	Button,
	Box,
	Checkbox,
	OutlinedInput,
	ListSubheader,
	InputAdornment,
	ListItemText,
	Chip,
	SelectChangeEvent,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

type Props = {
	setExpanded: Dispatch<SetStateAction<boolean>>;
	profiles: PROFILES_DATA[];
	formData: PRODUCT_TYPE;
	setFormData: Dispatch<SetStateAction<PRODUCT_TYPE>>;
	products: PRODUCT_TYPE[];
	setProducts: Dispatch<SetStateAction<PRODUCT_TYPE[]>>;
	refetchProducts: () => void;
	selectedProfiles: any;
	setSelectedProfiles: Dispatch<SetStateAction<any>>;
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	autoFocus: false,
};
const containsText = (text: string, searchText: string) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

const ProductSidebarForm: FC<Props> = ({
	setExpanded,
	formData,
	profiles,
	setFormData,
	products,
	setProducts,
	refetchProducts,
	selectedProfiles,
	setSelectedProfiles,
}) => {
	const isEdit = useMemo(() => !!formData.skuName, []);
	const dispatch = useDispatch<APP_DISPATCH>();
	const [upsertSKU, { loading, error }] = useMutation(UPSERT_SKUS);

	const {
		data: entityMetadata,
		loading: loadingEntity,
		refetch: refetchEntity,
	} = useQuery(GET_ALL_ENTITYMETADATA_BY_ENTITY_FOR_CURRENT_COMPANY, {
		variables: { entity: "Sku" },
	});

	const imageRef = useRef<HTMLInputElement>(null);
	const [photos, setPhotos] = useState([formData.skuImage1, formData.skuImage2, formData.skuImage3]);
	const [activeIndex, setActiveIndex] = useState(0);
	const [imageLoading, setImageLoading] = useState(false);
	// any error related to file or image upload
	const [imageOrFileError, setImageOrFileError] = useState("");
	// any custom error related to custom fields
	const [customFieldErrors, setCustomFieldErrors] = useState<any[]>([]);
	// custom fields which are suppose to be filled
	const [missingEntities, setMissingEntities] = useState<ENTITY_METADATA[]>([]);
	const [isEveryoneChecked, setIsEveryoneChecked] = useState(false);
	const [searchTextforProfiles, setSearchTextforProfiles] = useState("");

	useEffect(() => {
		const updatedMissingEntities = entityMetadata?.get_all_entitymetadata_by_entity_for_current_company?.filter(
			(value: ENTITY_METADATA) => value.isMandatory === 0 && value.active === 1
		);
		if (updatedMissingEntities && updatedMissingEntities.length > 0) {
			setMissingEntities(updatedMissingEntities);
		}
	}, [loadingEntity, entityMetadata]);

	const handleNext = () => {
		setActiveIndex((prevIndex: number) => (prevIndex + 1) % photos.length);
	};
	const handlePrev = () => {
		setActiveIndex((prevIndex: number) => (prevIndex - 1 + photos.length) % photos.length);
	};

	const checkDateFormat = (date: string | null) => {
		if (date === null) return null;
		const isValidDayJsDate = dayjs.isDayjs(date);

		// Check if the date is already in the correct format
		if (dayjs(date, "DD-MM-YYYY", true).isValid()) {
			// if a dayjs object then convert into string
			if (isValidDayJsDate) {
				return dayjs(date).format("YYYY-MM-DD");
			} else {
				return date;
			}
		} else if (dayjs(date, "YYYY-MM-DD", true).isValid()) {
			if (isValidDayJsDate) {
				return dayjs(date).format("YYYY-MM-DD");
			} else {
				return date;
			}
		}
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (imageOrFileError.length > 0) {
			dispatch(
				showNotification({
					message: imageOrFileError,
					severity: "error",
				})
			);
			return;
		}

		if (customFieldErrors.length > 0) {
			dispatch(
				showNotification({
					message: customFieldErrors[0],
					severity: "error",
				})
			);
			return;
		}

		if(formData.visibility.length === 0){
			dispatch(
				showNotification({
					message: "Visible To is mandatory field.",
					severity: "error" ,
				})
			);
			return;
		}

		const upDatedFormData = {
			skuName: formData.skuName,
			skuID: formData.skuID,
			companyID: formData.companyID,
			profileID: formData.profileID,
			skuDescription: formData.skuDescription,
			internalSkuID: formData.internalSkuID,
			active: formData.active,
			category: formData.category,
			listPrice: formData.listPrice,
			gstRate: formData.gstRate,
			discRate: formData.discRate,
			showDiscAmt: formData.showDiscAmt,
			showGstRate: formData.showGstRate,
			skuImage1: photos[0],
			skuImage2: photos[1],
			skuImage3: photos[2],
			customFieldsComplex: formData.customFieldsComplex,
			lastModifiedTs: moment().valueOf(),
			createdTs: isEdit ? formData.createdTs : moment().valueOf(),
			visibility: formData.visibility,
		};
		// upDatedFormData.lastModifiedTs = moment().valueOf();
		upDatedFormData.active = formData.active ? true : false;
		if (upDatedFormData.listPrice === 0) {
			return dispatch(
				showNotification({
					message: "List Price has to be greater than 0",
					severity: "error",
				})
			);
		}

		const dateTypeCustomFields = missingEntities?.filter((value: ENTITY_METADATA) => value.dataType === "date");
		const inputCustomFieldsComplex = formData.customFieldsComplex?.map((item: any) => {
			const data = dateTypeCustomFields.find((value: ENTITY_METADATA) => value.fieldID === item.fieldID);
			return {
				fieldID: item.fieldID,
				fieldValue: data ? checkDateFormat(item.fieldValue) : item.fieldValue,
				fieldValue2: item.fieldValue2,
				fieldValue3: item.fieldValue3,
				selectedOptions: item.selectedOptions,
				selectedFieldValues: item.selectedFieldValues,
			};
		});

		upDatedFormData.customFieldsComplex = inputCustomFieldsComplex;
		await upsertSKU({ variables: { input: [upDatedFormData] } });
		dispatch(
			showNotification({
				message: error ? "SKU could not be saved" : "SKU saved successfully",
				severity: error ? "error" : "success",
			})
		);

		const updatedProducts = _cloneDeep(products);
		const index = updatedProducts.findIndex((p) => p.skuID === formData.skuID);
		if (index < 0) {
			updatedProducts.splice(0, 0, formData);
		} else {
			updatedProducts[index] = { ...formData };
		}
		setProducts(updatedProducts);
		refetchProducts();
	};
	const handleImageClick = () => {
		imageRef.current?.click();
	};

	const handlePhotoUpload = async (event: any, activeIndex: number, skuID: string) => {
		// if (imageChoose) {
		const file = event.target.files ? event.target.files[0] : null;
		if (!file) return;
		if (file.size > 500000) {
			dispatch(showNotification({ message: "File size should not be greater than 500KB", severity: "error" }));
			return;
		}
		const extension = file.name.split(".").reverse()[0];
		if (extension != "jpg" && extension != "jpeg" && extension != "svg" && extension != "png" && extension != "bmp") {
			dispatch(showNotification({ message: "Incorrect: File should be .jpg/.jpeg/.svg/.png/.bmp", severity: "error" }));
			return;
		}
		setImageLoading(true);
		const { status, imageUrl } = await productImageUpload(file, activeIndex, extension, skuID);
		let isUpload = false;
		if (status === "success") {
			isUpload = true;
		}
		dispatch(
			showNotification({
				message: isUpload ? "Image Uploaded Successfully" : "Image Upload Failed",
				severity: isUpload ? "success" : "error",
			})
		);

		if (isUpload) {
			setFormData((prev) => ({
				...prev,
				[`skuImage${activeIndex + 1}`]: imageUrl,
			}));
			setPhotos((prevPhotos) => {
				const updatedPhotos = [...prevPhotos];
				updatedPhotos[activeIndex] = imageUrl;
				return updatedPhotos;
			});
		}
		setImageLoading(false);
	};
	const handleImageDelete = (activeIndex: number) => {
		setFormData((prev) => ({
			...prev,
			[`skuImage${activeIndex + 1}`]: null,
		}));
		setPhotos((prevPhotos) => {
			const updatedPhotos = [...prevPhotos];
			updatedPhotos[activeIndex] = null;
			return updatedPhotos;
		});
	};

	const handleChange = (event: any) => {
		let value: boolean | string | number = event.target.value as string;
		if (event.target.name === "listPrice" || event.target.name === "gstRate" || event.target.name === "discRate") {
			value = parseFloat(value);
		}
		if (event.target.name === "active") {
			if (typeof value !== "boolean") {
				value = value === "true";
			}
		}
		setFormData((prev) => ({
			...prev,
			[event.target.name]: value,
		}));
	};

	const renderImageContent = (handleImageClick: any) => {
		if (imageLoading) {
			return <CircularProgress />;
		}

		if (photos[activeIndex]) {
			return (
				// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
				<img
					src={photos[activeIndex] as string}
					alt=""
					onClick={handleImageClick}
					style={{ width: "60%", height: "225px", objectFit: "cover" }}
				/>
			);
		}

		return (
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<UploadFileIcon onClick={handleImageClick} style={{ fontSize: 150 }} />
				<Typography variant="h6" component="div">
					Upload Image
				</Typography>
			</Box>
		);
	};
	// handle visibility checkbox
	const handleChangeVisibilityCheckbox = (event: any) => {
		const { checked } = event.target;
		setIsEveryoneChecked((prev) => !prev);
		if (checked) {
			setFormData((prev: any) => ({
				...prev,
				visibility: [{ type: "EVERYONE", value: 1 }],
			}));
			setSelectedProfiles([]);
		} else {
			setFormData((prev: any) => ({
				...prev,
				visibility: [],
			}));
		}
	};
	const handleProfileListItems = (each: any) => (
		<MenuItem key={each.profileID} value={each.profileID}>
			{/* @ts-ignore */}
			<Checkbox checked={selectedProfiles.indexOf(each.profileID) > -1} />
			<ListItemText primary={`${each.profileName}`} />
		</MenuItem>
	);
	const displayedOptionsProfiles = useMemo(
		() => profiles.filter((option) => containsText(option.profileName, searchTextforProfiles)),
		[searchTextforProfiles, profiles]
	);
	const handleSelectProfileChange = (event: SelectChangeEvent<typeof selectedProfiles>) => {
		const {
			target: { value },
		} = event;
		/* @ts-ignore */
		setSelectedProfiles(typeof value === "string" ? value.split(",").map((number) => Number(number)) : value);
		setFormData((prev: any) => ({
			...prev,
			visibility:
				typeof value === "string"
					? value.split(",").map((profileID) => ({ type: "PROFILE", value: Number(profileID) }))
					: value.map((profileID: any) => ({ type: "PROFILE", value: profileID })),
		}));
	};
	useEffect(() => {
		const selectedProfiles: number[] = [];
		formData.visibility?.forEach((visibility: any) => {
			if (visibility.type == "PROFILE") {
				selectedProfiles.push(visibility.value);
			}
			if (visibility.type == "EVERYONE") {
				setIsEveryoneChecked(true);
			}
		});
		setSelectedProfiles(selectedProfiles);
	}, [formData]);

	return (
        <div className="user_add tasks_edit" id="user_add">
			<div className="user_add-title">
				<h1>{isEdit ? "Edit" : "Add"} Product</h1>
				<button onClick={() => setExpanded(false)}>
					<CloseOutlinedIcon />
				</button>
			</div>
			<form className="product_add-form" onSubmit={handleSubmit}>
				<TextField
					required
					name="skuName"
					placeholder="SKU Name"
					inputProps={{ maxLength: 100 }}
					label="SKU Name"
					value={formData?.skuName || ""}
					onChange={handleChange}
					helperText={`${formData?.skuName ? formData?.skuName?.length : 0}/${100}`}
				/>
				<Card>
					<CardContent>
						<Typography variant="h6" component="p">
							{`Sku Image ${activeIndex + 1}`}
						</Typography>

						<Box
							sx={{
								width: "100%",
								height: "250px",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								cursor: "pointer",
							}}>
							<Button size="small" sx={{ borderRadius: "50%" }} onClick={handlePrev} disabled={activeIndex === 0}>
								<ArrowLeftIcon sx={{ fontSize: "50px" }} />
							</Button>
							{renderImageContent(handleImageClick)}
							<Button
								size="small"
								sx={{ borderRadius: "50%" }}
								onClick={handleNext}
								disabled={activeIndex === photos.length - 1}>
								<ArrowRightIcon sx={{ fontSize: "50px", padding: 0, margin: 0 }} />
							</Button>
						</Box>

						<label htmlFor="upload-photo">
							<input
								style={{ display: "none" }}
								onChange={(e) => handlePhotoUpload(e, activeIndex, formData?.skuID)}
								id="upload-photo"
								name="upload-photo"
								type="file"
								ref={imageRef}
							/>
						</label>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
								marginTop: "5px",
							}}>
							<Button
								color="primary"
								size="small"
								variant="contained"
								onClick={handleImageClick}
								sx={{ textTransform: "none", marginBottom: "5px" }}>
								{photos[activeIndex] ? "Change Image" : "Upload Image"}
							</Button>
							<Button
								color="error"
								size="small"
								variant="contained"
								onClick={() => handleImageDelete(activeIndex)}
								disabled={photos[activeIndex] === null || photos[activeIndex] === ""}
								sx={{ textTransform: "none" }}>
								Delete Image
							</Button>
						</Box>
					</CardContent>
				</Card>
				<TextField
					required
					name="internalSkuID"
					inputProps={{ maxLength: 100 }}
					placeholder="SKU ID"
					onChange={handleChange}
					label="SKU ID"
					value={formData?.internalSkuID || ""}
					helperText={`${formData?.internalSkuID ? formData?.internalSkuID?.length : 0}/${100}`}
				/>
				<TextField
					required
					rows={3}
					inputProps={{ maxLength: 400 }}
					name="skuDescription"
					placeholder="SKU Description"
					label="SKU Description"
					onChange={handleChange}
					value={formData?.skuDescription || ""}
					helperText={`${formData?.skuDescription ? formData?.skuDescription?.length : 0}/${400}`}
					multiline
				/>
				<TextField
					required
					name="category"
					placeholder="Category"
					inputProps={{ maxLength: 100 }}
					label="Category"
					onChange={handleChange}
					value={formData?.category || ""}
					helperText={`${formData?.category ? formData?.category?.length : 0}/${100}`}
					multiline
				/>
				<TextField
					required
					type="number"
					name="listPrice"
					placeholder="List Price"
					inputProps={{ min: 0, step: "0.01" }}
					label="List Price"
					onChange={handleChange}
					value={formData.listPrice === null ? 0 : formData.listPrice}
				/>
				<FormControl>
					<InputLabel id="show-gst-rate">Show Tax Rate</InputLabel>
					<Select
						name="showGstRate"
						labelId="show-Gst-rate"
						label="Show Tax Rate"
						value={formData.showGstRate}
						onChange={handleChange}>
						<MenuItem value={1}>Enable</MenuItem>
						<MenuItem value={0}>Disable</MenuItem>
					</Select>
				</FormControl>
				{formData.showGstRate === 1 && (
					<TextField
						required
						type="number"
						name="gstRate"
						inputProps={{ min: 0, step: "0.01", max: 100 }}
						placeholder="Tax(%)"
						label="Tax(%)"
						onChange={handleChange}
						value={formData.gstRate === null ? 0 : formData.gstRate}
					/>
				)}
				<FormControl>
					<InputLabel id="max-discount-rate">Show Discount Rate</InputLabel>
					<Select
						name="showDiscAmt"
						labelId="show-discount-rate"
						label="Show Discount Rate"
						value={formData.showDiscAmt}
						onChange={handleChange}>
						<MenuItem value={1}>Enable</MenuItem>
						<MenuItem value={0}>Disable</MenuItem>
					</Select>
				</FormControl>
				{formData.showDiscAmt === 1 && (
					<TextField
						required
						type="number"
						name="discRate"
						inputProps={{ min: 0, step: "0.01", max: 100 }}
						placeholder="Maximum Discount(%)"
						label="Maximum Discount(%)"
						onChange={handleChange}
						value={formData.discRate === null ? 0 : formData.discRate}
					/>
				)}

				<div className="custom_fields_complex">
					<div className="title">
						<h5>Custom Fields</h5>
						<Link
                            prefetch={false}
                            href="/settings/custom-fields?entity=products"
                            target="blank ">

                            <AddIcon />

                        </Link>
						<button disabled={loadingEntity} className="refetch" type="button" onClick={() => refetchEntity()}>
							<RefreshOutlinedIcon />
						</button>
					</div>
					{missingEntities && Array.isArray(missingEntities) && missingEntities.length > 0 && (
						<CustomFieldsComplex
							fieldsData={formData}
							setFieldsData={setFormData}
							missingEntities={missingEntities}
							isDisabled={false}
							setCustomFieldErrors={setCustomFieldErrors}
							setImageOrFileError={setImageOrFileError}
						/>
					)}
				</div>
				<div className="group_public_holiday">
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<Typography sx={{ fontSize: "16px" }}>Visible To</Typography>
						<FormControl sx={{ margin: "10px  0 10px 0" }}>
							<FormControlLabel
								control={<Checkbox checked={isEveryoneChecked} />}
								label="Everyone"
								onChange={(e) => handleChangeVisibilityCheckbox(e)}
							/>
						</FormControl>
					</div>
					<FormControl sx={{ margin: "10px  0 10px 0" }} fullWidth>
						<InputLabel id="team">Select Teams</InputLabel>
						<Select
							labelId="team"
							id="team"
							multiple
							value={selectedProfiles}
							onClose={() => setSearchTextforProfiles("")}
							onChange={handleSelectProfileChange}
							input={<OutlinedInput id="select-multiple-chip" label="Select Teams" />}
							disabled={isEveryoneChecked}
							renderValue={(selected) => (
								<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
									{selected.map((value: any) => {
										const currentUser = profiles.find((d) => d.profileID === value);
										return <Chip key={value} label={`${currentUser?.profileName}`} />;
									})}
								</Box>
							)}
							MenuProps={MenuProps}>
							<ListSubheader>
								<TextField
									size="small"
									placeholder="Type to search..."
									fullWidth
									value={searchTextforProfiles}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
										style: { border: "none", outline: "none" },
									}}
									onChange={(e) => setSearchTextforProfiles(e.target.value)}
									onKeyDown={(e) => {
										if (e.key !== "Escape") {
											e.stopPropagation();
										}
									}}
								/>
							</ListSubheader>
							<MenuItem disabled={true}>
								<ListItemText primary={<em>You can select multiple teams at once.</em>} />
							</MenuItem>
							{/* show default list of profiles if no search term */}
							{searchTextforProfiles.length === 0
								? profiles?.map((each) => handleProfileListItems(each))
								: displayedOptionsProfiles?.map((each) => handleProfileListItems(each))}
						</Select>
					</FormControl>
				</div>
				<FormControl>
					<FormLabel id="active" required>
						Active
					</FormLabel>
					<RadioGroup row aria-labelledby="active" value={formData.active} name="active" onChange={handleChange}>
						<FormControlLabel value={true} control={<Radio />} label="Yes" />
						<FormControlLabel value={false} control={<Radio />} label="No" />
					</RadioGroup>
				</FormControl>

				<div className="submit_buttons">
					<button type="reset" disabled={loading} onClick={() => setExpanded(false)}>
						Cancel
					</button>
					<button type="submit" disabled={loading}>
						{loading ? <CircularProgress style={{ width: "20px", height: "20px", marginRight: "10px" }} /> : "Save"}
					</button>
				</div>
			</form>
		</div>
    );
};

export default ProductSidebarForm;
