import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Dispatch, FC, SetStateAction, useState } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";

type Props = {
	approveHandler: () => void;
	// eslint-disable-next-line no-unused-vars
	rejectHandler: (reason: string) => void;
	name: string;
	userWritePermission: any;
	loading: boolean;
	setIsRequestLoading: Dispatch<SetStateAction<boolean>>;
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialog-paper": {
		maxWidth: "350px",
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

const ApproveRejectOverrideRequest: FC<Props> = ({
	approveHandler,
	rejectHandler,
	name,
	userWritePermission,
	loading,
	setIsRequestLoading
}) => {
	const [open, setOpen] = useState(false);
	const [isReject, setIsReject] = useState(false);
	const [reason, setReason] = useState("");
	const handleConfirm = () => {
		setIsRequestLoading(true);;
		if (isReject) {
			rejectHandler(reason); // Pass the reason to the reject handler
		} else {
			approveHandler();
		}
		setOpen(false);
	};

	return (
		<>
			<div className="action-buttons">
				<button
					onClick={(e) => {
						e.stopPropagation();
						setOpen(true);
						setIsReject(false);
					}}
					disabled={!userWritePermission || loading}>
					<DoneIcon htmlColor="#40A636" />
				</button>
				<button
					onClick={(e) => {
						e.stopPropagation();
						setOpen(true);
						setIsReject(true);
					}}
					disabled={!userWritePermission || loading}>
					<CloseIcon htmlColor="#F54747" />
				</button>
			</div>
			{open && (
				<BootstrapDialog onClose={() => setOpen(false)} open={true}>
					<div
						style={{
							display: "flex !important",
							padding: "16px",
							alignItems: "center",
							width: "100%",
							justifyContent: "center",
						}}>
						{isReject ? (
							<CancelOutlinedIcon sx={{ width: "100%", height: "56px", marginTop: "1rem", color: "#f54747" }} />
						) : (
							<CheckCircleRoundedIcon sx={{ width: "100%", height: "56px", marginTop: "1rem", color: "#40A636" }} />
						)}
						<p
							style={{
								textAlign: "center",
								margin: "1.5rem 0 0.5rem 0",
								fontSize: "14px",
								fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
							}}>
							{isReject ? (
								<>
									Are you sure you want to reject request from <strong>{name ?? "Unnamed User(s)"}</strong>
								</>
							) : (
								<>
									Are you sure you want to approve request from <strong>{name ?? "Unnamed User(s)"} </strong>
								</>
							)}
						</p>
						{isReject && (
							<input
								placeholder="Enter RehandleConfirmason"
								className="reason"
								value={reason}
								onChange={(e) => setReason(e.target.value)}
							/>
						)}
					</div>
					<DialogActions sx={{ justifyContent: "center", marginBottom: "1rem" }}>
						<Button variant="contained" onClick={handleConfirm} color={isReject ? "error" : "success"}>
							{isReject ? "Reject" : "Approve"}
						</Button>
						<Button variant="contained" onClick={() => setOpen(false)} color="inherit">
							Cancel
						</Button>
					</DialogActions>
				</BootstrapDialog>
			)}
		</>
	);
};

export default ApproveRejectOverrideRequest;
