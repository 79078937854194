import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const TimePicker = (props: any) => {
	const { onChange, value, ...rest } = props;
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DesktopTimePicker
				value={value}
				onChange={onChange}
				slotProps={{ textField: { variant: "outlined", ...rest } }}
			/>
		</LocalizationProvider>
	);
};

export default TimePicker;
