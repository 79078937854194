import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FC } from "react";
import { DataGridPro, GridColDef, GridRowParams } from "@mui/x-data-grid-pro";
import { DG_STYLES } from "../../../constants";
import { ORDER_SKU_TYPE, ORDER_TYPE } from "../../../@types";
import { Box, Card, CardContent, Typography } from "@mui/material";
import useAdminEntitlements from "../../useAdminEntitlements";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RightSidebar from "../../common/RightSidebar";
import SkuLevelSideForm from "./SkuLevelSideForm";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ComplexTimeline from "./OrderTimeline";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const collectionMethods = {
	0: "Cash",
	1: "Debit Card",
	2: "Credit Card",
	3: "Cheque",
	4: "Wallets",
	5: "UPI",
	6: "PayPal",
};

type Props = {
	orderItemData: ORDER_TYPE[];
	setSelectedRow: Dispatch<SetStateAction<{ rowId: string | null; orderRow: ORDER_TYPE[] }>>;
	setDisplayTopMenu: Dispatch<SetStateAction<boolean>>;
	fetchOrders: () => void;
	currencySymbol: string;
};

const OrdersDetailPage: FC<Props> = ({
	orderItemData,
	setSelectedRow,
	setDisplayTopMenu,
	fetchOrders,
	currencySymbol,
}) => {
	const userWritePermission = useAdminEntitlements("orders:products", "write");
	const [skuPage, setSkuPage] = useState(0);
	const [skuPageSize, setSkuPageSize] = useState(20);
	const [paymentPage, setPaymentPage] = useState(0);
	const [paymentPageSize, setPaymentPageSize] = useState(20);
	const [expand, setExpand] = useState(false);
	const [skuColumns, setSkuColumns] = useState<any[]>([]);
	const [paymentColumns, setPaymentColumns] = useState<any[]>([]);
	const [skuDetails, setSkuDetails] = useState<ORDER_SKU_TYPE>({
		skuID: "",
		sku: {
			skuID: "",
			profileID: -1,
			companyID: 0,
			skuName: "",
			skuDescription: "",
			internalSkuID: "",
			active: null,
			category: "",
			listPrice: 0,
			gstRate: null,
			discRate: null,
			skuImage1: null,
			skuImage2: null,
			skuImage3: null,
			lastModifiedTs: 0,
			createdTs: 0,
		},
		productDescription: "",
		quantity: 0,
		price: 0,
		discAmt: 0,
		amount: 0,
		totalTaxAmount: 0,
		discRateCalc: 0,
	});
	const [showTimeLine, setShowTimeLine] = useState(false);
	const skuPageChangeHandler = (newPage: number) => {
		setSkuPage(newPage);
	};
	const skuPageSizeChangeHandler = (newPageSize: number) => {
		setSkuPageSize(newPageSize);
		setSkuPage(0);
	};
	const paymentPageChangeHandler = (newPage: number) => {
		setPaymentPage(newPage);
	};
	const paymentPageSizeChangeHandler = (newPageSize: number) => {
		setPaymentPageSize(newPageSize);
		setPaymentPage(0);
	};

	const handleDownloadSkus = () => {
		//download the table data in csv content
		const csvContent = generateSkusCSVContent();
		const downloadLink = document.createElement("a");
		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
		const url = URL.createObjectURL(blob);

		downloadLink.href = url;
		downloadLink.download = "Skus_data"; // Set the desired filename for the downloaded file
		downloadLink.click();
	};
	const flattenedOrderItems = orderItemData[0].orderItems?.map((orderItem) => ({
		...orderItem,
		...orderItem.sku,
		total: orderItem.amount + orderItem.totalTaxAmount,
	}));

	const generateSkusCSVContent = () => {
		const header = skuColumns.filter((column) => column.headerName !== "Action").map((column) => column.headerName);
		const dataRows = flattenedOrderItems.map((row: any) => {
			const filteredRow = skuColumns.reduce((acc, column) => {
				if (column.field in row) {
					acc[column.field] = row[column.field];
				}
				return acc;
			}, {});
			const rowValues = skuColumns.map((column) => {
				let value;
				if (filteredRow[column.field] === null || filteredRow[column.field] === undefined) {
					value = "";
				} else {
					value = filteredRow[column.field];
				}
				return `"${value.toString().replace(/"/g, '""')}"`;
			});
			return rowValues.join(",");
		});
		const csvContent = "\uFEFF" + [header, ...dataRows].join("\n");
		return csvContent;
	};
	const handleDownloadPayment = () => {
		//download the table data in csv content
		const csvContent = generatePaymentCSVContent();
		const downloadLink = document.createElement("a");
		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
		const url = URL.createObjectURL(blob);

		downloadLink.href = url;
		downloadLink.download = "collections_data"; // Set the desired filename for the downloaded file
		downloadLink.click();
	};
	const generatePaymentCSVContent = () => {
		const header = paymentColumns.map((column) => column.headerName);
		const dataRows = paymentData[0].map((row: any) => {
			const filteredRow = paymentColumns.reduce((acc, column) => {
				if (column.field in row) {
					acc[column.field] = row[column.field];
				}
				return acc;
			}, {});
			const rowValues = paymentColumns.map((column) => {
				let value;
				if (filteredRow[column.field] === null || filteredRow[column.field] === undefined) {
					value = "";
				} else if (column.field === "paymentType") {
					value = collectionMethods[filteredRow["paymentType"]];
				} else {
					value = filteredRow[column.field];
				}
				return `"${value.toString().replace(/"/g, '""')}"`;
			});
			return rowValues.join(",");
		});
		const csvContent = "\uFEFF" + [header, ...dataRows].join("\n");
		return csvContent;
	};

	const paymentData = orderItemData.map((order: any) =>
		order.payments.map((payment: any) => ({
			employeeName: order.employeeName,
			...payment,
		}))
	);
	useEffect(() => {
		const skuCols: GridColDef[] = [
			{
				field: "skuName",
				headerName: "SKU NAME",
				flex: 1,
				minWidth: 150,
				valueGetter: (params: any) => params.row.skuName || "SKU Deleted!!",
			},
			{
				field: "skuDescription",
				headerName: "Product Description",
				flex: 1,
				minWidth: 150,
				valueGetter: (params: any) => params.row.skuDescription || "-",
			},
			{
				field: "category",
				headerName: "Category",
				flex: 1,
				minWidth: 150,
				valueGetter: (params: any) => params.row.category || "-",
			},
			{
				field: "quantity",
				headerName: "Quantity",
				flex: 1,
				minWidth: 150,
			},
			{
				field: "listPrice",
				headerName: `List Price (${currencySymbol})`,
				flex: 1,
				minWidth: 150,
				valueGetter: (params: any) => params.row.listPrice || "-",
			},
			{
				field: "price",
				headerName: `Selling Price (${currencySymbol})`,
				flex: 1,
				minWidth: 150,
			},
			{
				field: "gstRate",
				headerName: "Tax Rate (%)",
				flex: 1,
				minWidth: 150,
				valueGetter: (params: any) => params.row.gstRate || "-",
			},
			{
				field: "discAmt",
				headerName: `Discount (%)`,
				flex: 1,
				minWidth: 150,
				valueGetter: (params: any) => params.row.discAmt || "-",
			},
			{
				field: "total",
				headerName: `Item Value (${currencySymbol})`,
				flex: 1,
				minWidth: 150,
				valueGetter: (params: any) => params.row.total?.toFixed(2),
			},
		];
		skuCols.push({
			field: "action",
			headerName: "Action",
			minWidth: 100,
			renderCell: () =>
				userWritePermission ? (
					<div className="action-buttons">
						<button
							onClick={() => {
								setExpand(true);
							}}>
							<EditOutlinedIcon htmlColor="#8F8F8F" />
						</button>
					</div>
				) : null,
		});
		setSkuColumns(skuCols);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderItemData]);

	useEffect(() => {
		const paymentCols: GridColDef[] = [
			{
				field: "employeeName",
				headerName: "Employee Name",
				flex: 1,
				minWidth: 150,
				valueGetter: (params: any) => params.row.employeeName,
			},
			{
				field: "paymentType",
				headerName: "Collection Mode",
				flex: 1,
				minWidth: 150,
				valueGetter: (params: any) => {
					const paymentMode = collectionMethods[params.row.paymentType];
					return paymentMode;
				},
			},
			{
				field: "amount",
				headerName: "Collection Amount",
				flex: 1,
				minWidth: 150,
				valueGetter: (params: any) => params.row.amount,
			},
		];
		setPaymentColumns(paymentCols);
	}, [orderItemData]);

	const handleClickBackButton = () => {
		setDisplayTopMenu(true);
		setSelectedRow((prevState) => ({
			...prevState,
			rowId: null,
		}));
	};

	return (
		<>
			<section className="datagrid-common-style">
				<div className="title mb-2">
					<button className="add" onClick={handleClickBackButton} style={{ padding: "5px 10px" }}>
						<KeyboardBackspaceIcon sx={{ fontSize: "12px" }} />
						Back
					</button>
					<div className="button-group">
						<button
							onClick={() => {
								setShowTimeLine(false);
								fetchOrders();
							}}
							className={`group ${!showTimeLine ? "selected" : ""}`}>
							Overview
						</button>
						<button
							onClick={() => {
								setShowTimeLine(true);
							}}
							className={`group ${showTimeLine ? "selected" : ""}`}>
							Timeline
						</button>
					</div>
				</div>
				<div className="my-3 border-b" />

				<span>
					<div>
						Client Name: <span>{`${orderItemData[0]?.client?.clientName}`}</span>
					</div>
				</span>

				{!showTimeLine && (
					<>
						<div className="title mt-4">
							<Typography variant="h6">SKU Detail</Typography>
							<button onClick={handleDownloadSkus}>
								<FileDownloadOutlinedIcon />
							</button>
						</div>
						<Box className="datagrid-table" sx={{ height: "400px" }}>
							<DataGridPro
								sx={DG_STYLES}
								rows={flattenedOrderItems}
								// multple rows of same skuID can exist in the ddatagrid causing issue
								getRowId={(row) => `${row.skuID}-${Math.random().toFixed(6)}`}
								columns={skuColumns}
								page={skuPage}
								onPageChange={skuPageChangeHandler}
								pageSize={skuPageSize}
								onPageSizeChange={skuPageSizeChangeHandler}
								rowsPerPageOptions={[5, 10, 20, 50, 100]}
								rowHeight={70}
								onRowClick={(params: GridRowParams) => {
									setSkuDetails(params.row);
									setExpand(true);
								}}
								checkboxSelection
								disableSelectionOnClick
								disableColumnFilter
								pagination
							/>
						</Box>
						<Card sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
							<CardContent sx={{ marginLeft: "auto", display: "flex", flexDirection: "row", alignItems: "center" }}>
								<Typography variant="h6" sx={{ color: "rgba(0, 0, 0, 0.6)", marginRight: "60px" }}>
									Total Value
								</Typography>
								<Typography
									variant="h6"
									sx={{
										fontWeight: "light",
										marginRight: "80px",
									}}>{`${currencySymbol} ${parseFloat(
									(orderItemData[0].orderValue + orderItemData[0].taxValue).toFixed(2)
								)}`}</Typography>
							</CardContent>
						</Card>

						<div className="title" style={{ marginTop: "40px" }}>
							<Typography variant="h6">Collection Details</Typography>
							<button onClick={handleDownloadPayment}>
								<FileDownloadOutlinedIcon />
							</button>
						</div>
						<Box className="datagrid-table" sx={{ height: "400px" }}>
							<DataGridPro
								sx={DG_STYLES}
								rows={paymentData[0]}
								getRowId={(row) => row.orderPaymentID}
								columns={paymentColumns}
								page={paymentPage}
								onPageChange={paymentPageChangeHandler}
								pageSize={paymentPageSize}
								onPageSizeChange={paymentPageSizeChangeHandler}
								rowsPerPageOptions={[5, 10, 20, 50, 100]}
								rowHeight={70}
								disableSelectionOnClick
								disableColumnFilter
								pagination
							/>
						</Box>
						<Card sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
							<CardContent sx={{ marginLeft: "auto", display: "flex", flexDirection: "row", alignItems: "center" }}>
								<Typography variant="h6" sx={{ color: "rgba(0, 0, 0, 0.6)", marginRight: "60px" }}>
									Total Collection
								</Typography>
								<Typography
									variant="h6"
									sx={{
										fontWeight: "bold",
										marginRight: "80px",
									}}>{`${currencySymbol} ${orderItemData[0].paymentCollected}`}</Typography>
							</CardContent>
						</Card>
					</>
				)}
				{showTimeLine && <ComplexTimeline orderItemData={orderItemData} />}
			</section>

			{expand && (
				<RightSidebar expanded={expand} setExpanded={setExpand}>
					<SkuLevelSideForm
						setExpand={setExpand}
						orderItemData={orderItemData}
						skuDetails={skuDetails}
						fetchOrders={fetchOrders}
						currencySymbol={currencySymbol}
					/>
				</RightSidebar>
			)}
		</>
	);
};

export default OrdersDetailPage;
