/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

//react
import { FC, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";

// utils, constants, components
import { deleteShiftTemplate } from "../../../api/attendance";
import { BD_SHIFTS } from "../../../constants/url";
import { APP_DISPATCH, ROOT_STATE, setShiftTemplates, showNotification } from "../../../redux";
import BulkDownloadModal from "../../common/BulkDownloadModal";
import useAdminEntitlements from "../../useAdminEntitlements";
import ShiftCreateModal from "./ShiftCreateModal";
import ShiftUploadModal from "./ShiftUploadModal";
import ReadOnlyButton from "../../common/ReadOnlyButton";

//mui
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import ClearIcon from "@mui/icons-material/Clear";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import LockIcon from "@mui/icons-material/Lock";

// library
import moment from "moment";

type Props = {
	data: any;
};

const ClonedComponent: FC<Props> = ({ data }) => (
	<div className="template-card">
		<div className="template-card-left">
			{data["isOverNight"] ? (
				<BedtimeIcon sx={{ fontSize: "18px", color: data["color"] }} />
			) : (
				<WbSunnyIcon sx={{ fontSize: "18px", color: data["color"] }} />
			)}
		</div>
		<div className="template-card-right">
			<Typography className="shiftTemplateName">{data.name}</Typography>
			<Typography className="shiftTemplateTime">{`${data.start} - ${data.end}`}</Typography>
		</div>
	</div>
);

const CustomCard = styled(Card)({
	boxShadow: "none",
	borderRadius: "12px",
	"& .MuiCardContent-root": {
		padding: "1rem !important",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
	"& .MuiTypography-root": {
		marginBottom: 0,
	},
});

type shiftProps = {
	subsMetadata: any;
};
const ShiftsCreate: FC<shiftProps> = ({ subsMetadata }) => {
	const { shiftTemplates: shiftsTemplateData } = useSelector((state: ROOT_STATE) => state.shift);
	const user = useSelector((state: ROOT_STATE) => state.user);
	const [open, setOpen] = useState(false);
	const [shiftSelectedData, setShiftSelectedData] = useState<any | null>(null);
	const dispatch = useDispatch<APP_DISPATCH>();
	const userWritePermission = useAdminEntitlements("attendance:shifts", "write");
	const [bulkDownloadOpen, setBulkDownloadOpen] = useState(false);
	const [bulkUploadOpen, setBulkUploadOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const upsertShift = (shift: any) => {
		let clonedShiftsTemplateData,
			isPresent = false;

		clonedShiftsTemplateData = shiftsTemplateData.map((shiftTemplate: any) => {
			if (shiftTemplate["shiftTemplateID"] === shift["shiftTemplateID"]) {
				isPresent = true;
				return { ...shiftTemplate, start: shift["start"], end: shift["end"], name: shift["name"] };
			}
			return { ...shiftTemplate };
		});

		if (!isPresent) {
			clonedShiftsTemplateData = [...shiftsTemplateData, { ...shift, color: "rgb(122, 86, 71)" }];
		}

		dispatch(setShiftTemplates({ shiftTemplates: clonedShiftsTemplateData }));
		handleClose();
	};

	const deleteShiftTemplateHandler = async (shiftTemplateID: string) => {
		const { success, message } = await deleteShiftTemplate(shiftTemplateID);
		if (success) {
			const clonedShiftsTemplateData = shiftsTemplateData.filter(
				(shift: any) => shift["shiftTemplateID"] !== shiftTemplateID
			);
			dispatch(setShiftTemplates({ shiftTemplates: clonedShiftsTemplateData }));
			handleClose();
		}
		dispatch(showNotification({ message: message, severity: success ? "success" : "error" }));
	};

	return (
		<div className="shift-create">
			<CustomCard>
				<CardContent>
					<div className="header">
						<div className="read-only-part">
							<Typography>Create Shifts</Typography>
							{!userWritePermission ? (
								<ReadOnlyButton roleID={user.adminDetails.roleID} moduleName="attendance" />
							) : null}
						</div>
						<div className="button_group">
							<button className="xls_button" disabled={!userWritePermission} onClick={() => setBulkUploadOpen(true)}>
								<FileUploadOutlinedIcon />
							</button>
							<button className="xls_button" onClick={() => setBulkDownloadOpen(true)}>
								<FileDownloadOutlinedIcon />
							</button>
							<button
								className="add mui-button"
								disabled={!userWritePermission}
								onClick={() => {
									setShiftSelectedData(null);
									handleOpen();
								}}>
								<AddRoundedIcon />
								Add Shift
							</button>
						</div>
					</div>
					{subsMetadata?.rosters === 1 && (
						<Typography className="roster-message">
							Note: When a roster and a shift exist for the same day for the same employee, the roster takes priority.
						</Typography>
					)}
					<Divider sx={{ border: "1px solid #DFDFDF", width: "100%", margin: "1rem 0" }} />
					<div className="shift-create">
						<Droppable droppableId="droppable-create" isDropDisabled={true} direction="horizontal">
							{(provided: any) => (
								<div
									ref={provided.innerRef}
									// isDraggingOver={snapshot.isDraggingOver}
									className="table"
									style={{ padding: "1rem 0" }}>
									{shiftsTemplateData.map((data: any) => (
										<Draggable
											key={`${data["shiftTemplateID"]}`}
											draggableId={`${data["shiftTemplateID"]}`}
											isDragDisabled={!userWritePermission}
											index={parseInt(data["shiftTemplateID"])}>
											{(p, snapshot) => (
												<div
													id={data["shiftTemplateID"]}
													onClick={() => {
														if (userWritePermission) {
															setShiftSelectedData(data);
															handleOpen();
														}
													}}
													style={{ position: "relative" }}>
													{data?.readOnly && (
														<LockIcon
															style={{
																position: "absolute",
																top: "-5px",
																left: "-5px",
																color: "gray",
																background: "white",
																height: "18px",
																width: "18px",
																padding: "2px",
																borderRadius: "100%",
																boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
															}}
															titleAccess="Edit permission restricted"
														/>
													)}

													<div
														ref={p.innerRef}
														className={!userWritePermission ? "template-card-nopermission" : "template-card"}
														{...p.draggableProps}
														{...p.dragHandleProps}
														id={data["shiftTemplateID"]}>
														<DragIndicatorIcon
															sx={{ color: "#8F8F8F", width: "20px", height: "20px", padding: "0px !important" }}
														/>

														<div className="template-card-left" id={data["shiftTemplateID"]}>
															{data["isOverNight"] ? (
																<BedtimeIcon
																	id={data["shiftTemplateID"]}
																	sx={{ fontSize: "18px", color: data["color"] }}
																/>
															) : (
																<WbSunnyIcon
																	id={data["shiftTemplateID"]}
																	sx={{ fontSize: "18px", color: data["color"] }}
																/>
															)}
														</div>
														<div className="template-card-right" id={data["shiftTemplateID"]}>
															<div className="name_and_clear">
																<Typography id={data["shiftTemplateID"]} className="shiftTemplateName">
																	{data["name"]}
																</Typography>
																{data["isDefault"] && data["isDefault"] === 1 ? null : (
																	<button
																		disabled={!userWritePermission || data?.readOnly}
																		onClick={(e) => {
																			e.stopPropagation();
																			deleteShiftTemplateHandler(data["shiftTemplateID"]);
																		}}>
																		<ClearIcon />
																	</button>
																)}
															</div>
															<Typography id={data["shiftTemplateID"]} className="shiftTemplateTime">
																{/**
																 * convert 24 hour format to 12 hour format with moment.js
																 */}
																{moment(data["start"], "HH:mm:ss").format("hh:mm A")} -{" "}
																{moment(data["end"], "HH:mm:ss").format("hh:mm A")}
															</Typography>
														</div>
													</div>
													{snapshot.isDragging && <ClonedComponent data={data} />}
												</div>
											)}
										</Draggable>
									))}
								</div>
							)}
						</Droppable>
					</div>
				</CardContent>
			</CustomCard>
			{open ? (
				<ShiftCreateModal
					handleClose={handleClose}
					shiftSelectedData={shiftSelectedData}
					upsertShift={upsertShift}
					shiftsTemplateData={shiftsTemplateData}
					subsMetadata={subsMetadata}
				/>
			) : null}
			{bulkDownloadOpen && (
				<BulkDownloadModal bulkDownloadUrl={BD_SHIFTS} handleClose={() => setBulkDownloadOpen(false)} />
			)}
			{bulkUploadOpen && <ShiftUploadModal handleClose={() => setBulkUploadOpen(false)} />}
		</div>
	);
};

export default ShiftsCreate;
