//react
import { Dispatch, FC, SetStateAction } from "react";

//moment
import "moment-timezone";

//types, constants, utils
import { ENTITY_METADATA, PRODUCT_TYPE, PROFILES_DATA } from "../../../@types";

//mui
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import IndividualCustomFieldsFilter from "./Filter/IndividualCustomFiledsFilter";
import IndividualDefaultFilter from "./Filter/IndividualDefaultFilter";
import IndividualVisibiltyFilter from "./Filter/IndividualVisibiltyFilter";

type Props = {
	numFilters: number;
	setFilterOpen: Dispatch<SetStateAction<boolean>>;
	products: PRODUCT_TYPE[];
	setProducts: Dispatch<SetStateAction<PRODUCT_TYPE[]>>;
	filters: any;
	setFilters: Dispatch<SetStateAction<any>>;
	profiles: PROFILES_DATA[];
	entityMetadata: ENTITY_METADATA[];
	localStorageKey?: string;
};
const FilterProducts: FC<Props> = ({
	numFilters,
	setFilterOpen,
	products,
	setProducts,
	setFilters,
	filters,
	entityMetadata,
	localStorageKey,
	profiles
}) => {
	const deselectAllAndPutOriginalRows = () => {
		setFilters({});
	};
	const ResetAllAndPutOriginalRows = () => {
		setFilters({});
		setProducts(() => [...products]);
	};

	const onChangeCheckbox = (e: { target: { checked: any } }, item: string, name: string) => {
		if (!e.target.checked && Object.keys(filters).length == 1 && filters[Object.keys(filters)[0]].length == 1) {
			deselectAllAndPutOriginalRows();
			return; //if checkbox is unchecked and filter has one key only then set the filters to empty
		} else if (!e.target.checked) {
			// if filter is unchecked , remove that filter from filters obj
			let tempArray = products.filter((e) => JSON.stringify(e) != JSON.stringify(item));
			const tempFilters = filters;
			tempArray = tempFilters[name].filter((item1: string) => item != item1);
			tempFilters[name] = tempArray;
			if (tempFilters[name].length === 0) {
				delete tempFilters[name];
			}
			if (localStorageKey && localStorageKey.length > 0)
				localStorage.setItem(localStorageKey, JSON.stringify(tempFilters));
			setFilters({ ...tempFilters });
		} else {
			//if checkbox is selected , add the filter key and values
			const tempFilters = filters;
			if (tempFilters[name] == undefined) {
				tempFilters[name] = [];
				tempFilters[name].push(item);
			} else {
				tempFilters[name].push(item);
			}
			if (localStorageKey && localStorageKey.length > 0)
				localStorage.setItem(localStorageKey, JSON.stringify(tempFilters));
			setFilters({ ...tempFilters });
		}
	};

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
		<form
			onSubmit={(e) => e.preventDefault()}
			onClick={(e) => e.stopPropagation()}
			onKeyPress={(e) => e.stopPropagation()}>
			<div className="filter__header">
				<h5>
					<FilterAltOutlinedIcon />
					Filters
					<span className="right-notification center" style={{ display: "flex", padding: 0 }}>
						{numFilters}
					</span>
				</h5>
				<button onClick={() => setFilterOpen(false)}>
					<CloseSharpIcon htmlColor="#42444A" />
				</button>
			</div>
			<div className="accordion__group">
				<p>Default Fields</p>
				<IndividualDefaultFilter
					filters={filters}
					name="skuName"
					title="SKU Name"
					data={products}
					onChangeCheckbox={onChangeCheckbox}
				/>
				<IndividualDefaultFilter
					filters={filters}
					name="internalSkuID"
					title="SKU ID"
					data={products}
					onChangeCheckbox={onChangeCheckbox}
				/>
				<IndividualDefaultFilter
					filters={filters}
					name="category"
					title="Category"
					data={products}
					onChangeCheckbox={onChangeCheckbox}
				/>
				<IndividualDefaultFilter
					filters={filters}
					name="active"
					title="Active"
					data={products}
					onChangeCheckbox={onChangeCheckbox}
				/>
				<IndividualVisibiltyFilter
					filters={filters}
					name="visibility"
					title="Visible To"
					data={products}
					profiles={profiles}
					onChangeCheckbox={onChangeCheckbox}
				/>
				<p>Custom Fields</p>
				{entityMetadata
					.filter(
						(metadata) =>
							metadata.active === 1 &&
							metadata.isDefault === 0 &&
							metadata.dataType !== "photo" &&
							metadata.dataType !== "file" &&
							metadata.dataType !== "form" &&
							metadata.dataType !== "employee"
					)
					.sort((a, b) => a.fieldName.localeCompare(b.fieldName))
					.map((metadata: any) => {
						if (metadata.dataType === "contact") {
							return (
								<>
									<IndividualCustomFieldsFilter
										name={metadata.fieldName + " Name"}
										key={metadata.fieldID}
										title={metadata.fieldName + " Name"}
										filters={filters}
										metadata={metadata}
										dataType={metadata.dataType}
										data={products}
										onChangeCheckbox={onChangeCheckbox}
									/>
									<IndividualCustomFieldsFilter
										name={metadata.fieldName + " Number"}
										key={metadata.fieldID}
										title={metadata.fieldName + " Number"}
										filters={filters}
										metadata={metadata}
										dataType={metadata.dataType}
										data={products}
										onChangeCheckbox={onChangeCheckbox}
									/>
								</>
							);
						}
						return (
							<IndividualCustomFieldsFilter
								name={metadata.fieldName}
								key={metadata.fieldID}
								title={metadata.fieldName}
								filters={filters}
								metadata={metadata}
								dataType={metadata.dataType}
								data={products}
								onChangeCheckbox={onChangeCheckbox}
							/>
						);
					})}
			</div>
			<div className="filter__buttons">
				<button onClick={() => ResetAllAndPutOriginalRows()} disabled={numFilters === 0}>
					Reset
				</button>
			</div>
		</form>
	);
};

export default FilterProducts;
