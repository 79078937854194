import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { CircularProgress, Dialog, IconButton, MenuItem, Select,Typography } from "@mui/material";
import __debounce from "lodash/debounce";
import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { PROFILES_DATA } from "../../../@types";
import { updateTeamLevelLocationSettings } from "../../../api/settings";
import { DD_STYLES } from "../../../constants";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import { sortByString } from "../../../utils";
import useAdminEntitlements from "../../useAdminEntitlements";
import useFetch from "../../useFetch";
import { useSelector } from "react-redux";
import ReadOnlyButton from "../../common/ReadOnlyButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TeamSelector from "../../common/TeamSelector";
import InfoIcon from "@mui/icons-material/Info";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";

type DATA = {
	teamLevel: {
		profileID: number;
		profileName: string;
		showDistance: 0 | 1;
		updateFrequency: number;
		updateFrequencySec: number;
		distanceBetweenTasksOnly: 0 | 1;
	}[];
};
type Props = {
	subsMetadata: any;
};
const renderSelectValue = (value: null | string) => (
	<div>
		Assigning To: <span style={{ marginLeft: "8px", fontWeight: "bold" }}>{value}</span>
	</div>
);
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: "1rem",
	},
}));
const TeamLevelLocTrackingSettings: FC<Props> = ({ subsMetadata }) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const { data: profileData, loading: profileLoading } = useFetch<PROFILES_DATA[]>("/users/profiles");
	const { data, loading, refetch } = useFetch<DATA>("/preferences/location-tracking");
	const [profiles, setProfiles] = useState<PROFILES_DATA[]>([]);
	const [selectedProfileId, setSelectedProfileId] = useState<PROFILES_DATA["profileID"] | null>(null);
	const [selectedSetting, setSelectedSetting] = useState<null | DATA["teamLevel"][0]>(null);
	const userWritePermission = useAdminEntitlements("settings:locationTracking", "write");
	const { roleID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);
	const isLoading = profileLoading || loading;
	const currentProfile = useMemo(() => {
		if (selectedProfileId === null) return undefined;
		return profiles.find((profile) => profile.profileID === selectedProfileId);
	}, [selectedProfileId, profiles]);
	const [expanded, setExpanded] = useState(false);
	const [openImage, setOpenImage] = useState(false);

	useEffect(() => {
		if (!profileLoading && profileData?.data) {
			setProfiles(profileData.data);
			setSelectedProfileId(profileData.data[0].profileID);
		}
	}, [profileLoading, profileData?.data]);

	useEffect(() => {
		if (!loading && data?.data && selectedProfileId) {
			const currSettings = data.data.teamLevel.find((setting) => setting.profileID === selectedProfileId) || null;
			const updateFrequencyElement = document.querySelectorAll(
				".team-level-task-settings input[name='updateFrequency']"
			)[0] as HTMLInputElement;
			const updateFrequencySecElement = document.querySelectorAll(
				".team-level-task-settings input[name='updateFrequencySec']"
			)[0] as HTMLInputElement;
			if (updateFrequencyElement && updateFrequencySecElement) {
				updateFrequencyElement.value = `${currSettings?.updateFrequency}`;
				updateFrequencySecElement.value = `${currSettings?.updateFrequencySec}`;
			}
			setSelectedSetting(currSettings);
		}
	}, [data?.data, loading, selectedProfileId]);

	const handleChange = async (value: number | string, label: string, selectTeams?: number[]) => {
		const newValue = typeof value === "string" ? parseInt(value) : value;
		const profileID = profiles.find((profile) => profile.profileID === selectedProfileId);
		if (!profileID || !selectedSetting) {
			dispatch(showNotification({ message: "Client Side error, Please reload the page", severity: "error" }));
			return;
		}
		const updatedSettings = { ...selectedSetting, [label]: newValue };
		const { success, message } = await updateTeamLevelLocationSettings(
			updatedSettings,
			profileID.profileID,
			selectTeams
		);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (success) refetch();
	};

	const changeCurrentProfile = (value: number | null) => {
		if (value === null) return;
		setSelectedProfileId(value);
		const updateFrequencyElement = document.querySelectorAll(
			".team-level-task-settings input[name='updateFrequency']"
		)[0] as HTMLInputElement;
		const updateFrequencySecElement = document.querySelectorAll(
			".team-level-task-settings input[name='updateFrequencySec']"
		)[0] as HTMLInputElement;
		if (updateFrequencyElement && updateFrequencySecElement) {
			updateFrequencyElement.value = `${selectedSetting?.updateFrequency}`;
			updateFrequencySecElement.value = `${selectedSetting?.updateFrequencySec}`;
		}
	};

	const debouncedChanges = __debounce(async function (e) {
		let value = e.target.value;
		let name = e.target.name;
		if (value === "") {
			value = 0;
		}
		handleChange(value, name);
	}, 500);

	if (!currentProfile) {
		return (
			<div className="small_circular-spinner">
				<CircularProgress />
			</div>
		);
	}
	return (
		<div className="team-level-task-settings">
			{expanded && <TeamSelector expanded={expanded} setExpanded={setExpanded} handleChange={handleChange} />}
			<div className="team-header">
				<div className="team-level-header">
					<PeopleAltOutlinedIcon />
					<p className="title">Team Level Settings</p>
					{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="settings" /> : null}
				</div>
				<button className="add mui-button" onClick={() => setExpanded(!expanded)} disabled={!userWritePermission}>
					<ContentCopyIcon sx={{ marginRight: "5px" }} />
					Copy Team Settings
				</button>
			</div>
			<div className="horizontal-line"></div>
			<Select
				onChange={(e) => changeCurrentProfile(+e.target.value!)}
				value={selectedProfileId}
				disabled={isLoading}
				sx={{
					...DD_STYLES,
					maxWidth: "320px",
					marginBottom: "2rem",
					color: "#0F45FF",
				}}
				renderValue={() => renderSelectValue(currentProfile?.profileName)}>
				{profiles
					?.sort((a, b) => sortByString(a.profileName, b.profileName))
					.map((profile) => (
						<MenuItem key={profile.profileID} value={profile.profileID}>
							{profile.profileName}
						</MenuItem>
					))}
			</Select>
			<div className="team-level-grid">
				<div className="section">
					<p className="title">Location Update Interval</p>
					<p className="subtitle">
						How often a location update will be published from employee's device.
						<br /> Longer intervals mean less battery consumption.
					</p>
					{subsMetadata?.locationTrackingMinUpdateFrequency === 5 && (
						<Typography style={{ color: "#1976d2", fontSize: "14px" }}>
							Note: The minimum update frequency for your edition is x minutes. Please upgrade to a higher edition for a
							faster update frequency.
						</Typography>
					)}
					<div className="location_update_interval">
						<div>
							<label className="subtitle" htmlFor="updateFrequencySec">
								Mins
							</label>
							<input
								disabled={!userWritePermission || isLoading}
								type="number"
								name="updateFrequency"
								min={subsMetadata?.locationTrackingMinUpdateFrequency === 5 ? 5 : 1}
								onChange={debouncedChanges}
							/>
						</div>
						<div>
							<label className="subtitle" htmlFor="updateFrequencySec">
								Secs
							</label>
							<input
								type="number"
								id="updateFrequencySec"
								name="updateFrequencySec"
								min={0}
								max={60}
								onChange={debouncedChanges}
								disabled={!userWritePermission || isLoading}
							/>
						</div>
					</div>
				</div>
				<div className="section">
					<p className="title">Show Distance</p>
					<p className="subtitle">Show distance calculations to employees in employee dashboard</p>
					<Select
						value={selectedSetting?.showDistance ?? 0}
						sx={{ ...DD_STYLES }}
						disabled={!userWritePermission || isLoading}
						onChange={(e) => handleChange(e.target.value, "showDistance")}>
						<MenuItem value={1}>On</MenuItem>
						<MenuItem value={0}>Off</MenuItem>
					</Select>
				</div>
				{subsMetadata?.calculateDistanceBWTasks === 1 && (
					<div className="section">
						<div style={{ display: "flex", gap: "5px" }}>
							<p className="title">Distance Calculation</p>
							<button style={{ display: "flex", alignSelf: "flex-start" }} onClick={() => setOpenImage(true)}>
								<InfoIcon sx={{ fontSize: "14px", opacity: "0.5" }} />
							</button>
						</div>
						<div style={{ marginBottom: "6px" }}>
							<li style={{ margin: "0px" }}>
								<span className="subtitle">
									<b>From Punch-In to Punch-Out</b> - Distance will be calculated from Punch-In to Punch-out.
								</span>
							</li>
							<li style={{ margin: "0px" }}>
								<span className="subtitle">
									<b>Between Tasks only</b> - {`Distance will be calculated from 1st Task to Nth Task.`}.
								</span>
							</li>
						</div>

						<Select
							value={selectedSetting?.distanceBetweenTasksOnly ?? 0}
							sx={DD_STYLES}
							disabled={!userWritePermission || isLoading}
							onChange={(e) => handleChange(e.target.value, "distanceBetweenTasksOnly")}>
							<MenuItem value={1}>Between Tasks only</MenuItem>
							<MenuItem value={0}>From Punch-In to Punch-Out</MenuItem>
						</Select>
					</div>
				)}
			</div>
			{openImage && (
				<BootstrapDialog aria-labelledby="customized-dialog-title" open={true} maxWidth={`md`}>
					<IconButton
						aria-label="close"
						onClick={() => {
							setOpenImage(false);
						}}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							zIndex: 1,
							color: (theme: any) => theme.palette.grey[500],
						}}>
						<CloseIcon />
					</IconButton>

					<img className="d-block w-100" src={"/images/distance_calculation.png"} />
				</BootstrapDialog>
			)}
		</div>
	);
};
export default TeamLevelLocTrackingSettings;
