// react
import { useState } from "react";
// libraries
import dayjs from "dayjs";
import PhoneInput from "react-phone-input-2";
// mui
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
// query
import { ADMINS_DATA, EXECUTIVE_USER } from "../../../@types";
// constants
import { PRODUCT_ID, UNOLO_BIOMETRIC } from "../../../constants";
// components
import SamplePhoto from "./SamplePhoto";

type Props = {
	// eslint-disable-next-line no-unused-vars
	handleChange: (event: any) => void;
	profileImage: string;
	adminId?: string | string[];
	// eslint-disable-next-line no-unused-vars
	onFileChange: (event: any) => void;
	formData: EXECUTIVE_USER;
	adminsData: ADMINS_DATA[];
	// eslint-disable-next-line no-unused-vars
	handleDateChange: (date: any) => void;
	joiningDate: string;
};

const CreateUserForm = ({
	handleChange,
	profileImage,
	onFileChange,
	formData,
	adminsData,
	adminId,
	handleDateChange,
	joiningDate,
}: Props) => {
	const productID = window ? localStorage.getItem(PRODUCT_ID) ?? "2" : "2";
	const [sampleModalOpen, setSampleModalOpen] = useState<boolean>(false);
	return (
		<div className="field-container">
			<div className="basic-form">
				<div className="image">
					<label className="image-input" htmlFor="img">
						<CloudUploadOutlinedIcon />
						<input type="file" name="img" accept="image/png, image/gif, image/jpeg" onChange={onFileChange} />
						{profileImage && <img src={profileImage} alt="" />}
					</label>
					<label htmlFor="img">Click to upload or drag and drop</label>
					<label htmlFor="img">
						SVG, PNG, JPG, JPEG (<span>Max. size 250*250px</span>)
					</label>
					{productID == UNOLO_BIOMETRIC ? (
						<>
							<label htmlFor="img">
								Image should be of good quality and must contain a single person facing forward.
							</label>
							<br />
							<div
								className="sampleDiv"
								role="button"
								tabIndex={0}
								onClick={() => setSampleModalOpen(true)}
								onKeyDown={() => setSampleModalOpen(true)}>
								View Samples
							</div>
						</>
					) : null}
				</div>
				{sampleModalOpen && <SamplePhoto setSampleModalOpen={setSampleModalOpen} open={sampleModalOpen} />}
				<div className="text-field">
					<TextField
						required
						name="firstName"
						label="First Name"
						onChange={handleChange}
						value={formData.firstName ?? ""}
						size="small"
						inputProps={{ maxLength: 45 }}
						helperText={`${formData.firstName ? formData.firstName.length : 0}/${45}`}
						FormHelperTextProps={{ style: { margin: "4px 0px 0px 0px", fontSize: "12px" } }}
					/>
					<TextField
						required
						label="Last Name"
						name="lastName"
						onChange={handleChange}
						value={formData?.lastName ?? ""}
						size="small"
						inputProps={{ maxLength: 45 }}
						helperText={`${formData.lastName ? formData.lastName.length : 0}/${45}`}
						FormHelperTextProps={{ style: { margin: "4px 0px 0px 0px", fontSize: "12px" } }}
					/>

					<FormControl>
						<PhoneInput
							country={"in"}
							preferredCountries={["in"]}
							enableSearch={true}
							inputProps={{
								minLength: 10,
								maxLength: 20,
								required: true,
							}}
							value={formData.mobileNumber}
							onChange={(e) => handleChange({ target: { name: "mobileNumber", value: `+${e}` } })}
							masks={{ ci: ".. .. .. .. .." }}
						/>
					</FormControl>
					<TextField
						required
						label="Email address"
						type="email"
						name="emailID"
						value={formData.emailID ?? ""}
						onChange={handleChange}
						size="small"
						inputProps={{ maxLength: 45 }}
						helperText={`${formData.emailID ? formData.emailID.length : 0}/${45}`}
						FormHelperTextProps={{ style: { margin: "4px 0px 0px 0px", fontSize: "12px" } }}
					/>
					<FormControl>
						<InputLabel id="reporting_manager">Select Reporting Manager</InputLabel>
						<Select
							onChange={handleChange}
							name="parentAdminID"
							required
							value={adminId ?? formData?.parentAdminID ?? ""}
							labelId="reporting_manager"
							size="small"
							label="Select Reporting Manager">
							{adminsData.map((admin) => (
								<MenuItem key={admin.adminID} value={admin.adminID}>
									{admin.firstname} {admin.lastname}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<TextField
						label="Internal Employee ID"
						name="internalEmpID"
						value={formData.internalEmpID ?? ""}
						onChange={handleChange}
						size="small"
						inputProps={{ maxLength: 45 }}
						helperText={`${formData.internalEmpID ? formData.internalEmpID.length : 0}/${45}`}
						FormHelperTextProps={{ style: { margin: "4px 0px 0px 0px", fontSize: "12px" } }}
					/>
					<TextField label="User ID" name="userID" disabled value={formData.userID ?? ""} size="small" />
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							label={"Joining Date"}
							value={dayjs(joiningDate, "YYYY-MM-DD")}
							format="DD-MM-YYYY"
							onChange={handleDateChange}
							shouldDisableDate={(date) => {
								const currentYear = dayjs().year();
								return date.year() > currentYear;
							  }}
							slotProps={{
								textField: {
									variant: "outlined",
									required: true,
									className: "date_picker",
									sx: {
										width: "100%",
									},
								},
							}}
						/>
					</LocalizationProvider>
				</div>
			</div>
		</div>
	);
};

export default CreateUserForm;
