/* eslint-disable no-unused-vars */
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import { cloneDeep, isEqual } from "lodash";
import moment from "moment";
import { Dispatch, SetStateAction, useId, useState } from "react";
import { useDispatch } from "react-redux";
import { isUuid } from "uuidv4";
import * as XLSX from "xlsx";
import { CLIENTS_BY_COMPANY, ROUTE_DATA, ROUTE_DETAILS_DATA } from "../../../@types";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { CloudDownloadOutlined } from "@mui/icons-material";
import { BD_ROUTES } from "../../../constants/url";
import { PRODUCT_ID } from "../../../constants";

import ErrorShowModal from "./ErrorShowModal";

type Props = {
	handleClose: () => void;
	setCurrentRoute: Dispatch<SetStateAction<ROUTE_DATA>>;
	currentRoute: ROUTE_DATA;
	clientsByCompany: CLIENTS_BY_COMPANY[];
};

type VALIDATION_ERRORS = {
	columnName: string;
	issue: string;
	rowsNumber: number;
};

const RouteBulkUploadModal = ({ handleClose, currentRoute, setCurrentRoute, clientsByCompany }: Props) => {
	const [upload] = useId();
 
	const dispatch = useDispatch<APP_DISPATCH>();
	const [loader, setLoader] = useState<boolean>(false);
	const [errors, setErrors] = useState<VALIDATION_ERRORS[]>([]);

	const onFileChange = (event: any) => {
		const file = event.target.files ? event.target.files[0] : null;
		if (!file) return;
		if (file?.name.split(".")?.[1] != "xls" && file?.name.split(".")?.[1] != "xlsx") {
			dispatch(showNotification({ message: "File type should be .xls or .xlsx", severity: "error" }));
 			return;
		}
		if (file.size > 500000*2) {
			dispatch(showNotification({ message: "File size should not be greater than 1MB", severity: "error" }));
 			return;
		}
		setLoader(true);
		importExcel(file);
  	};
	function importExcel(file: any) {
		readExcel(file, (err: unknown, resp: any) => {
			if (err) {
				console.log(err);
			} else {
				const rows = resp.rows;
				const excelToList:ROUTE_DETAILS_DATA[] = [];
				const errors: VALIDATION_ERRORS[] = [];

				// 9th index or 10th Row must contain the required header, otherwise we will consider it incorrect format
				if(rows.length > 9 && rows[9]){
					let headers = ["Client", "Site", "Start Time", "End Time"];

					if(!isEqual(rows[9], headers)){
						dispatch(showNotification({ message: "Excel Format is not Valid", severity: "error" }));
						setLoader(false);
 						return;
					}
				}

				if(clientsByCompany.length <=0 ){
					dispatch(showNotification({ message: "Cannot perform upload, Please wait while clients are being fetched.", severity: "warning" }));
					setLoader(false);
					return;
				}
				// Do not change the starting index for iteration
				// Backend and Frontend both must agree on the same structure of excel sheet
				for (let i = 10; i < rows.length; i++) {
					// Skip the row If entire Row is Empty
					if (!rows[i].length) continue;

					// Extract the client and site from the row
					const client = rows[i][0];
					const site = rows[i][1];

					// If both client and site exist then there is an error
					if (client && site) {
						errors.push({
							columnName: "Client/Site",
							issue: "A route entry cannot contain both client and site at same time",
							rowsNumber: i + 1
						});
					}

					if (!client && !site) {
						errors.push({
							columnName: "Client/Site",
							issue: "A route entry must contain either client or site in a row",
							rowsNumber: i + 1
						});
					}
					
					let clientID:string = "";
					if(client){
						const result = client.split(/\n/).reverse();
						if(result[0]){
							clientID = result[0].split(" ")[1];
						}

					} else if(site){
						const result = site.split(/\n/).reverse();
						if(result[0]){
							clientID = result[0].split(" ")[1];
						}
					}
				 
					const clientDetails = isUuid(clientID)
						? clientsByCompany.find((c) => c.clientID === clientID)
						: null


					if (!clientDetails) {
						errors.push({
							columnName: `${client ? "Client" : "Site"}`,
							issue: `Client not found in the system`,
							rowsNumber: i + 1
						});
 					}
					else{
						/* 
						 Check of Time
						 1. Time is Excel Object type, Just convert to string and format it in hh:mm format
						 2. Time is undefined or "" then mark it as empty 
						 3. There is something in the field, so only consider if the type is string, because 10 could be number but we don't want it to be considered as time
						 4. If row is string then proceed with value, this value then will be verified with moment isValid method
						 5. If the string is not the type then mark as incorrect, and it will fail the isValid check.
						*/

						const routeDetailsObject:ROUTE_DETAILS_DATA = {
						
							client: clientDetails,
							startTime:
								typeof rows[i][2] === "object"
									? moment(new Date(rows[i][2].toString())).format("HH:mm")
									:
										rows[i][2] === undefined || rows[i][2] === ""
										? "empty"
											:
												typeof rows[i][2] === "string" 
												? rows[i][2]
													:
													"incorrect"
											,
							endTime:
								typeof rows[i][3] === "object"
									? moment(new Date(rows[i][3].toString())).format("HH:mm")
									: 
										rows[i][3] === undefined || rows[i][3] === ""
										? "empty"
											:
												typeof rows[i][3] === "string" 
												? rows[i][3]
													:
													"incorrect",
							type: clientDetails.employeeID === -2 ? "Site" : "Client"
						};

						if (routeDetailsObject.startTime !== "empty" && !moment(routeDetailsObject.startTime, "HH:mm").isValid()) {
							errors.push({
								columnName: "Start Time",
								issue: `Please enter the start time of client ${routeDetailsObject.client.clientName} in HH:mm format`,
								rowsNumber: i + 1
							});
						}
						if (routeDetailsObject.endTime !== "empty" && !moment(routeDetailsObject.endTime, "HH:mm").isValid()) {
							errors.push({
								columnName: "End Time",
								issue: `Please enter the end time of client ${routeDetailsObject.client.clientName} in HH:mm format`,
								rowsNumber: i + 1
							});
						}
						if (routeDetailsObject.startTime === "empty") routeDetailsObject.startTime = null;
						if (routeDetailsObject.endTime === "empty") routeDetailsObject.endTime = null;
						excelToList.push(routeDetailsObject);
					}
				}

				setLoader(false);
				
				// If there is any error then set Errors
				// Error component will render
				if(errors.length > 0)
					setErrors(errors);
				else {
					// If there is atleast one row then proceed
					if(excelToList.length > 0)
						onExcelUpload(excelToList);
					else{
						dispatch(showNotification({ message: "No rows found in the excel sheet", severity: "error" }));
 						return;
					}
				}
			}
		});
	}
	function onExcelUpload(routeDetails: ROUTE_DETAILS_DATA[]) {
		const selectedRoute = cloneDeep(currentRoute);
		selectedRoute.routeDetails = [...routeDetails];
 		setCurrentRoute(selectedRoute);
		handleClose();
	}
	return <>
		{
			(errors && errors.length > 0) ?
				<ErrorShowModal
					setErrors={setErrors}
					open={true}
					setBulkUploadOpen={handleClose}
					errors={errors}
				/> : null	
		}
		<Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
			<div
				style={{
					width: "min(120vw, 550px)",
					minHeight: "200px",
				}}
				className="bulk_download-modal">
				<div className="title">
					<h3>Bulk Upload</h3>
					<button onClick={handleClose}>
						<CloseOutlinedIcon htmlColor="#8f8f8f" />
					</button>
				</div>
				
				<div className="action_buttons">
					<a
						href={`${BD_ROUTES}?productid=${localStorage.getItem(PRODUCT_ID) ?? "2"}`}
						download
						rel="noreferrer"
						target="_blank"
						title="Download Template for Bulk Upload">
						<CloudDownloadOutlined  style={{ width: "20px", height: "20px", marginRight: "10px", color: "white" }}/> Template
					</a>
					<label htmlFor={upload}>
 						<input type="file" accept=".xls,.xlsx" id={upload} onChange={onFileChange} />
						{loader ? (
							<CircularProgress
								sx={{
									width: "20px !important",
									height: "20px !important",
									color: "white",
								}}
							/>
						) : (
							<CloudUploadOutlinedIcon style={{ width: "20px !important", height: "20px !important", color: "white" }}  />
						)}
						Upload Route
					</label>
				</div>
			</div>
		</Dialog>
	</>;
};

export default RouteBulkUploadModal;

export function readExcel(file: any, callback: Function) {
	return new Promise(function (resolve, reject) {
		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		reader.onload = function (e) {
			const bstr = e.target!.result;
			const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array", cellDates: true });

			const wsName = wb.SheetNames[0];
			const ws = wb.Sheets[wsName];

			const json = XLSX.utils.sheet_to_json(ws, { header: 1 });
			const cols = makeCols(ws["!ref"]);

			const data = { rows: json, cols: cols };

			resolve(data);
			return callback(null, data);
		};
		if (file && rABS) reader.readAsBinaryString(file);
		else reader.readAsArrayBuffer(file);
	});
}
function makeCols(refStr: any) {
	const o = [],
		C = XLSX.utils.decode_range(refStr).e.c + 1;
	for (let i = 0; i < C; ++i) {
		o[i] = { name: XLSX.utils.encode_col(i), key: i };
	}
	return o;
}
