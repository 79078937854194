import { FORM_REDIRECT_URL } from "../../../utils";

const getKey = (key: string) => {
	switch (key) {
		case "paymentCollected":
			return "Total Collection";
		case "taxValue":
			return "Total Tax amount";
		case "orderValue":
			return "Order Value";
		case "internalOrderID":
			return "Order ID";
		case "invoiceCommunicationFlag":
			return "Invoice Communication Flag";
		case "invoiceCommunicationFlagEod":
			return "Invoice Communication Flag EOD";
		case "lat":
			return "Latitude";
		case "lon":
			return "Longitude";
		case "clientName":
			return "Client Name";
		default:
			return key;
	}
};

export const showRecipients = (item: any) => {
	const { removed, added, updated, key } = item;

	const removedElements = removed?.map((removedArray: any, index: any) => {
		const recipientName = removedArray?.find((item: any) => item.key === "recipient");
		return (
			<div key={index} style={{ fontSize: "14px" }}>
				Recipient <strong>{recipientName?.value}</strong> was removed
			</div>
		);
	});
	const updatedElements = updated?.map((item: any, index: any) => {
		if (item === null) return null;
		return (
			<div key={index} style={{ fontSize: "14px" }}>
				{item?.added.map((addedItem: any, index: any) => (
					<div key={index}>
						<strong>{addedItem?.value}</strong> added in {item?.key}
					</div>
				))}
				{item?.updated.map((updatedItem: any, index: any) => (
					<div key={index}>
						{item?.key} updated from <strong>{updatedItem?.from}</strong> to <strong>{updatedItem?.to}</strong>
					</div>
				))}
				{item?.removed.map((removedItem: any, index: any) => (
					<div key={index}>
						<strong>{removedItem?.value}</strong> removed from {key}
					</div>
				))}
			</div>
		);
	});

	const addedElements = added?.map((addedArray: any, index: any) => {
		const recipientName = addedArray?.find((item: any) => item.key === "recipient");
		return (
			<div key={index} style={{ fontSize: "14px" }}>
				Recipient <strong>{recipientName?.value}</strong> was added
			</div>
		);
	});

	return (
		<>
			{removedElements}
			{updatedElements}
			{addedElements}
		</>
	);
};

export const showItems = (item: any) => {
	const { removed, added, updated, key } = item;

	const removedElements = removed?.map((removedArray: any, index: any) => {
		const productDescription = removedArray?.find((item: any) => item.key === "productDescription");
		return (
			<div key={index} style={{ fontSize: "14px" }}>
				Product <strong>{productDescription?.value}</strong> was removed from order
			</div>
		);
	});

	const updatedElements = updated?.map((item: any, index: any) => {
		if (item === null) return null;
		return (
			<div key={index} style={{ fontSize: "14px" }}>
				{item?.added.map((addedItem: any, index: any) => (
					<div key={index}>
						<strong>{addedItem?.value}</strong> added in {item?.key}
					</div>
				))}
				{item?.updated.map((updatedItem: any, index: any) => (
					<div key={index}>
						{item?.key}'s quantity updated from <strong>{updatedItem?.from}</strong> to{" "}
						<strong>{updatedItem?.to}</strong>
					</div>
				))}
				{item?.removed.map((removedItem: any, index: any) => (
					<div key={index}>
						<strong>{removedItem?.value}</strong> removed from {key}
					</div>
				))}
			</div>
		);
	});

	const addedElements = added?.map((addedArray: any, index: any) => {
		const productDescription = addedArray?.find((item: any) => item.key === "productDescription");
		const quantity = addedArray?.find((item: any) => item.key === "quantity");
		return (
			<div key={index} style={{ fontSize: "14px" }}>
				Product <strong>{productDescription?.value}</strong> with quantity <strong>{quantity?.value}</strong> was added
				to order
			</div>
		);
	});

	return (
		<>
			{removedElements}
			{updatedElements}
			{addedElements}
		</>
	);
};

export const showCollection = (item: any) => {
	const { removed, added, updated, key } = item;

	const removedElements = removed?.map((removedArray: any, index: any) => {
		const paymentMode = removedArray?.find((item: any) => item.key === "paymentType");
		const amount = removedArray?.find((item: any) => item.key === "amount");

		return (
			<div key={index} style={{ fontSize: "14px" }}>
				Collection <strong>{paymentMode?.value}</strong> <strong>{amount?.value}</strong> was removed
			</div>
		);
	});

	const updatedElements = updated?.map((item: any, index: any) => {
		if (item === null) return null;
		return (
			<div key={index} style={{ fontSize: "14px" }}>
				{item?.added.map((addedItem: any, index: any) => (
					<div key={index}>
						<strong>{addedItem?.value}</strong> added in {item?.key}
					</div>
				))}
				{item?.updated.map((updatedItem: any, index: any) => (
					<div key={index}>
						<strong>{item?.key}</strong> collection was updated from <strong>{updatedItem?.from}</strong> to{" "}
						<strong>{updatedItem?.to}</strong>
					</div>
				))}
				{item?.removed.map((removedItem: any, index: any) => (
					<div key={index}>
						<strong>{removedItem?.value}</strong> was removed from {key}
					</div>
				))}
			</div>
		);
	});

	const addedElements = added?.map((addedArray: any, index: any) => {
		const paymentMode = addedArray?.find((item: any) => item.key === "paymentType");
		const amount = addedArray?.find((item: any) => item.key === "amount");

		return (
			<div key={index} style={{ fontSize: "14px" }}>
				Collection <strong>{paymentMode?.value}</strong> <strong>{amount?.value}</strong> was added
			</div>
		);
	});

	return (
		<>
			{removedElements}
			{updatedElements}
			{addedElements}
		</>
	);
};

export const showFields = (item: any) => {
	const { removed, added, updated } = item;

	const removedElements = removed?.map((removedArray: any, index: any) => {
		const fieldName = removedArray?.find((item: any) => item.key === "fieldName");
		const fieldValue = removedArray?.find((item: any) => item.key === "fieldValue" || item.key === "optionValue");
		const type = removedArray?.find((item: any) => item.key === "type");
		if (type.value === "photo" || type.value === "file" || type.value === "form" || type.value === "contact") {
			return (
				<div key={index} style={{ fontSize: "14px" }}>
					<strong>{fieldName?.value}</strong> field removed
				</div>
			);
		}
		return (
			<div key={index} style={{ fontSize: "14px" }}>
				{fieldName?.value} <strong>{fieldValue?.value}</strong> field removed
			</div>
		);
	});

	const updatedElements = updated?.map((item: any, index: any) => {
		if (item === null) return null;

		if (item.type === "photo") {
			const formattedUpdated = item?.updated.reduce((result: any, item: any) => {
				result[item.key] = { from: item?.from.split(","), to: item?.to.split(",") };
				return result;
			}, {});
			const formattedRemoved = item?.removed.reduce((result: any, item: any) => {
				result[item.key] = { value: item.value?.split(",") };
				return result;
			}, {});
			const formattedAdded = item?.added.reduce((result: any, item: any) => {
				result[item.key] = { value: item.value?.split(",") };
				return result;
			}, {});

			return (
				<div key={index}>
					{Object.keys(formattedUpdated).length !== 0 && (
						<div style={{ fontSize: "14px" }}>
							{item.key} updated from
							<div
								style={{
									display: "flex",
									gap: "1rem",
									alignItems: "center",
									justifyContent: "flex-start",
									margin: "0.5rem 0 0.5rem 0.5rem",
									flexWrap: "wrap",
								}}>
								{formattedUpdated.fieldValue.from.map((url: string, i: number) => (
									<a href={url} target="_blank" key={index} rel="noreferrer">
										<img
											src={url}
											alt={formattedUpdated.fieldValue2.from[i]}
											style={{ height: "80px", width: "80px", borderRadius: "5px" }}
										/>
									</a>
								))}
								to
								{formattedUpdated.fieldValue.to.map((url: string, i: number) => (
									<a href={url} target="_blank" key={index} rel="noreferrer">
										<img
											src={url}
											key={index}
											alt={formattedUpdated.fieldValue2.to[i]}
											style={{ height: "80px", width: "80px", borderRadius: "5px" }}
										/>
									</a>
								))}
							</div>
						</div>
					)}
					{Object.keys(formattedRemoved).length !== 0 && (
						<div>
							{item.key} field removed from
							<div
								style={{
									display: "flex",
									gap: "1rem",
									alignItems: "center",
									justifyContent: "flex-start",
									margin: "0.5rem 0 0.5rem 0.5rem",
									flexWrap: "wrap",
								}}>
								{formattedUpdated.fieldValue.from.map((url: string, i: number) => (
									<a href={url} target="_blank" key={index} rel="noreferrer">
										<img
											src={url}
											alt={formattedUpdated.fieldValue2.from[i]}
											style={{ height: "80px", width: "80px", borderRadius: "5px" }}
										/>
									</a>
								))}
								to
								{formattedUpdated.fieldValue.to.map((url: string, i: number) => (
									<a href={url} target="_blank" key={index} rel="noreferrer">
										<img
											src={url}
											key={index}
											alt={formattedUpdated.fieldValue2.to[i]}
											style={{ height: "80px", width: "80px", borderRadius: "5px" }}
										/>
									</a>
								))}
							</div>
						</div>
					)}
					{Object.keys(formattedAdded).length !== 0 && (
						<div>
							{item.key} added from
							<div
								style={{
									display: "flex",
									gap: "1rem",
									alignItems: "center",
									justifyContent: "flex-start",
									margin: "0.5rem 0 0.5rem 0.5rem",
									flexWrap: "wrap",
								}}>
								{formattedUpdated.fieldValue.from.map((url: string, i: number) => (
									<a href={url} target="_blank" key={index} rel="noreferrer">
										<img
											src={url}
											alt={formattedUpdated.fieldValue2.from[i]}
											style={{ height: "80px", width: "80px", borderRadius: "5px" }}
										/>
									</a>
								))}
								to
								{formattedUpdated.fieldValue.to.map((url: string, i: number) => (
									<a href={url} target="_blank" key={index} rel="noreferrer">
										<img
											src={url}
											key={index}
											alt={formattedUpdated.fieldValue2.to[i]}
											style={{ height: "80px", width: "80px", borderRadius: "5px" }}
										/>
									</a>
								))}
							</div>
						</div>
					)}
				</div>
			);
		}

		if (item.type === "file") {
			const formattedUpdated = item?.updated.reduce((result: any, item: any) => {
				result[item.key] = { from: item?.from.split(","), to: item?.to.split(",") };
				return result;
			}, {});
			const formattedAdded = item?.added.reduce((result: any, item: any) => {
				result[item.key] = { value: item.value?.split(",") };
				return result;
			}, {});
			return (
				<div key={index}>
					{Object.keys(formattedUpdated).length !== 0 && (
						<div style={{ fontSize: "14px" }}>
							{item.key} updated from
							<div
								style={{
									display: "flex",
									gap: "1rem",
									alignItems: "center",
									justifyContent: "flex-start",
									margin: "0.5rem 0 0.5rem 0.5rem",
									flexWrap: "wrap",
								}}>
								{formattedUpdated.fieldValue.from.map((url: string, i: number) => (
									<a
										href={url}
										target="_blank"
										key={index}
										rel="noreferrer"
										style={{
											padding: "0.5rem",
											border: "0.5px solid gray",
											background: "rgb(243, 243, 243)",
											borderRadius: "5px",
											fontSize: "14px",
											lineBreak: "anywhere",
										}}>
										{formattedUpdated.fieldValue2.from[i]}
									</a>
								))}
								to
								{formattedUpdated.fieldValue.to.map((url: string, i: number) => (
									<a
										href={url}
										target="_blank"
										key={index}
										rel="noreferrer"
										style={{
											padding: "0.5rem",
											border: "0.5px solid gray",
											background: "rgb(243, 243, 243)",
											borderRadius: "5px",
											fontSize: "14px",
											lineBreak: "anywhere",
										}}>
										{formattedUpdated.fieldValue2.to[i]}
									</a>
								))}
							</div>
						</div>
					)}
					{Object.keys(formattedAdded).length !== 0 && (
						<div>
							{item.key} added from
							<div
								style={{
									display: "flex",
									gap: "1rem",
									alignItems: "center",
									justifyContent: "flex-start",
									margin: "0.5rem 0 0.5rem 0.5rem",
								}}>
								{formattedUpdated.fieldValue.from.map((url: string, i: number) => (
									<a
										href={url}
										target="_blank"
										key={index}
										rel="noreferrer"
										style={{
											padding: "0.5rem",
											border: "0.5px solid gray",
											background: "rgb(243, 243, 243)",
											borderRadius: "5px",
											fontSize: "14px",
											lineBreak: "anywhere",
										}}>
										{formattedUpdated.fieldValue2.from[i]}
									</a>
								))}
								to
								{formattedUpdated.fieldValue.to.map((url: string, i: number) => (
									<a
										href={url}
										target="_blank"
										key={index}
										rel="noreferrer"
										style={{
											padding: "0.5rem",
											border: "0.5px solid gray",
											background: "rgb(243, 243, 243)",
											borderRadius: "5px",
											fontSize: "14px",
											lineBreak: "anywhere",
										}}>
										{formattedUpdated.fieldValue2.from[i]}
									</a>
								))}
							</div>
						</div>
					)}
				</div>
			);
		}

		if (item.type === "form") {
			const formattedUpdated = item?.updated.reduce((result: any, item: any) => {
				result[item.key] = { from: item?.from.split(","), to: item?.to.split(",") };
				return result;
			}, {});
			return (
				<div key={index}>
					{Object.keys(formattedUpdated).length !== 0 && (
						<div>
							{item.key} updated
							<div
								style={{
									display: "flex",
									gap: "1rem",
									alignItems: "center",
									justifyContent: "flex-start",
									margin: "0.5rem 0 0.5rem 0.5rem",
									flexWrap: "wrap",
								}}>
								to
								{formattedUpdated.fieldValue.to.map((url: string, i: number) => (
									<a
										href={`${FORM_REDIRECT_URL}/${url}?createdTs=${formattedUpdated.fieldValue2.from[i]}`}
										target="_blank"
										rel="noreferrer"
										style={{
											padding: "0.5rem",
											border: "0.5px solid gray",
											background: "rgb(243, 243, 243)",
											borderRadius: "5px",
											fontSize: "14px",
										}}
										key={i}>
										Form Response Link
									</a>
								))}
							</div>
						</div>
					)}
				</div>
			);
		}

		if (item.type === "contact") {
			const formattedUpdated = item?.updated.reduce((result: any, item: any) => {
				result[item.key] = { from: item?.from.split(","), to: item?.to.split(",") };
				return result;
			}, {});

			return (
				<div key={index} style={{ fontSize: "14px" }}>
					{Object.keys(formattedUpdated).length !== 0 && (
						<div>
							{item.key} updated
							{formattedUpdated?.fieldValue && (
								<div
									style={{
										display: "flex",
										gap: "1rem",
										alignItems: "center",
										justifyContent: "flex-start",
										margin: "0.5rem 0 0.5rem 0.5rem",
										flexWrap: "wrap",
									}}>
									Contact Name from
									{formattedUpdated?.fieldValue?.from.map((data: string, i: number) => (
										<span key={i}>{data}</span>
									))}
									to
									{formattedUpdated?.fieldValue?.to.map((data: string, i: number) => (
										<span key={i}>{data}</span>
									))}
								</div>
							)}
							{formattedUpdated?.fieldValue2 && (
								<div
									style={{
										display: "flex",
										gap: "1rem",
										alignItems: "center",
										justifyContent: "flex-start",
										margin: "0.5rem 0 0.5rem 0.5rem",
										flexWrap: "wrap",
									}}>
									Country Code from
									{formattedUpdated?.fieldValue2?.from.map((data: string, i: number) => (
										<span key={i}>{data}</span>
									))}
									to
									{formattedUpdated?.fieldValue2?.to.map((data: string, i: number) => (
										<span key={i}>{data}</span>
									))}
								</div>
							)}
							{formattedUpdated?.fieldValue3 && (
								<div
									style={{
										display: "flex",
										gap: "1rem",
										alignItems: "center",
										justifyContent: "flex-start",
										margin: "0.5rem 0 0.5rem 0.5rem",
										flexWrap: "wrap",
									}}>
									Contact Number from
									{formattedUpdated?.fieldValue3?.from.map((data: string, i: number) => (
										<span key={i} style={{ fontSize: "inherit" }}>
											{data}
										</span>
									))}
									to
									{formattedUpdated?.fieldValue3?.to.map((data: string, i: number) => (
										<span key={i} style={{ fontSize: "inherit" }}>
											{data}
										</span>
									))}
								</div>
							)}
						</div>
					)}
				</div>
			);
		}

		return (
			<div key={index} style={{ fontSize: "14px" }}>
				{item?.added.map((addedItem: any, index: any) => (
					<div key={index}>
						<strong>{addedItem?.value}</strong> added in {item?.key}
					</div>
				))}
				{item?.updated.map((updatedItem: any, index: any) => (
					<div key={index} style={{ fontSize: "14px" }}>
						{item?.key} updated from <strong>{updatedItem?.from}</strong> to <strong>{updatedItem?.to}</strong>
					</div>
				))}
				{item?.removed.map((removedItem: any, index: any) => (
					<div key={index}>
						<strong>{removedItem?.value}</strong> removed from {item?.key} field
					</div>
				))}
			</div>
		);
	});

	const addedElements = added?.map((addedArray: any, index: any) => {
		const fieldName = addedArray?.find((item: any) => item.key === "fieldName");
		const fieldValue = addedArray?.find((item: any) => item.key === "optionValue" || item.key === "fieldValue");
		const type = addedArray?.find((item: any) => item.key === "type");

		if (type.value === "photo") {
			return (
				<div key={index} style={{ fontSize: "14px" }}>
					{fieldName?.value} was added
					<div
						style={{
							display: "flex",
							gap: "1rem",
							alignItems: "center",
							justifyContent: "flex-start",
							margin: "0.5rem 0 0.5rem 0.5rem",
						}}>
						<a href={fieldValue?.value} target="_blank" key={index} rel="noreferrer">
							<img
								src={fieldValue?.value}
								key={index}
								alt={""}
								style={{ height: "80px", width: "80px", borderRadius: "5px" }}
							/>
						</a>
					</div>
				</div>
			);
		}

		if (type.value === "form") {
			const fieldValue2 = addedArray?.find((item: any) => item.key === "fieldValue2");
			return (
				<div key={index} style={{ fontSize: "14px" }}>
					{fieldName?.value} was added
					<div
						style={{
							display: "flex",
							gap: "1rem",
							alignItems: "center",
							justifyContent: "flex-start",
							margin: "0.5rem 0 0.5rem 0.5rem",
						}}>
						<a
							href={`${FORM_REDIRECT_URL}/${fieldValue?.value}?createdTs=${fieldValue2.value}`}
							target="_blank"
							rel="noreferrer"
							style={{
								padding: "0.5rem",
								border: "0.5px solid gray",
								background: "rgb(243, 243, 243)",
								borderRadius: "5px",
								fontSize: "14px",
							}}>
							Link to Form Response
						</a>
					</div>
				</div>
			);
		}

		if (type.value === "file") {
			const fieldValue2 = addedArray?.find((item: any) => item.key === "fieldValue2");

			return (
				<div key={index} style={{ fontSize: "14px" }}>
					{fieldName?.value} was added
					<div
						style={{
							display: "flex",
							gap: "1rem",
							alignItems: "center",
							justifyContent: "flex-start",
							margin: "0.5rem 0 0.5rem 0.5rem",
						}}>
						<a
							href={fieldValue.value}
							target="_blank"
							rel="noreferrer"
							style={{
								padding: "0.5rem",
								border: "0.5px solid gray",
								background: "rgb(243, 243, 243)",
								borderRadius: "5px",
								fontSize: "14px",
								lineBreak: "anywhere",
							}}>
							{fieldValue2.value}
						</a>
					</div>
				</div>
			);
		}

		if (type.value === "contact") {
			const fieldValue2 = addedArray?.find((item: any) => item.key === "fieldValue2");
			const fieldValue3 = addedArray?.find((item: any) => item.key === "fieldValue3");

			return (
				<div key={index} style={{ fontSize: "14px" }}>
					{fieldName?.value} was added
					<div style={{ marginLeft: "0.5rem", display: "flex", flexDirection: "column", fontSize: "inherit" }}>
						<span style={{ fontSize: "inherit" }}>Contact Name - {fieldValue?.value}</span>
						<span style={{ fontSize: "inherit" }}>Country Code - {fieldValue2?.value ?? "-"}</span>
						<span style={{ fontSize: "inherit" }}>Contact Number - {fieldValue3?.value ?? "-"}</span>
					</div>
				</div>
			);
		}

		return (
			<div key={index} style={{ fontSize: "14px" }}>
				{fieldName?.value} <strong>{fieldValue?.value}</strong> was added
			</div>
		);
	});

	return (
		<>
			{removedElements}
			{updatedElements}
			{addedElements}
		</>
	);
};

export const transformTheSimpleChange = (item: any, action: string) => {
	const updatedKey = getKey(item.key);
	if (action === "added") {
		return (
			<div key={updatedKey} style={{ fontSize: "14px" }}>
				{updatedKey} of <strong>{item.value}</strong> was added
			</div>
		);
	}
	if (action === "removed") {
		return (
			<div key={updatedKey} style={{ fontSize: "14px" }}>
				{updatedKey} <strong>{item.value}</strong> was removed
			</div>
		);
	}
	if (action === "updated") {
		return (
			<div key={updatedKey} style={{ fontSize: "14px" }}>
				{updatedKey} was updated from <strong>{item.from}</strong> to <strong>{item.to}</strong>
			</div>
		);
	}
};
