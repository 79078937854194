/* eslint-disable react-hooks/exhaustive-deps */
//react
import { useRouter } from "next/router";
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { APP_DISPATCH, showNotification } from "../../../redux";

//apollo
import { useMutation } from "@apollo/client";

//lodash, moment
import _cloneDeep from "lodash/cloneDeep";
import moment from "moment";

//types, utils, constants
import { PJP_REQUEST_TYPE_2 } from "../../../@types";
import { DG_STYLES } from "../../../constants";
import ApproveRejectButtons from "../../common/ApproveRejectButtons";
import ColumnEmployee from "../../common/datagrid/ColumnEmployee";
import CustomColumnMenu from "../../common/datagrid/CustomColumnMenu";
import useAdminEntitlements from "../../useAdminEntitlements";
import { UPDATE_PJP_STATUS } from "../../../schema/tasks";
import { pjpStatus } from "../../../constants/tasks";

//mui
import { DataGridPro, GridColDef, GridSelectionModel, GridValueGetterParams } from "@mui/x-data-grid-pro";

type Props = {
	requests: PJP_REQUEST_TYPE_2[];
	setRequests: Dispatch<SetStateAction<PJP_REQUEST_TYPE_2[]>>;
	filteredRequests: PJP_REQUEST_TYPE_2[];
	setSelectedRows: Dispatch<SetStateAction<GridSelectionModel>>;
	isLoading: boolean;
	refetch:any;
};
const PjpTable: FC<Props> = ({ requests, filteredRequests, setRequests, setSelectedRows, isLoading, refetch }) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const [pageSize, setPageSize] = useState(20);
	const router = useRouter();
	const userWritePermission = useAdminEntitlements("tasks:pjpRequests", "write");
	const [updatePJPStatus] = useMutation(UPDATE_PJP_STATUS);

	const tempColumns = useMemo(
		() => [
			{
				field: "employee",
				headerName: "Employee Name",
				renderCell: (params: GridValueGetterParams) => (
					<ColumnEmployee name={params.row.name} avatar={params.row.imgUrl} />
				),
				flex: 1,
				minWidth: 220,
			},
			{
				field: "date",
				headerName: "Journey Date",
				flex: 1,
				minWidth: 150,
				renderCell: (params: GridValueGetterParams) => (
					<time className="accepted-rejected">
						{params.row.date ? moment(params.row.date).format("DD-MM-YYYY") : ""}
					</time>
				),
			},
			{
				field: "ts",
				headerName: "Date of submission",
				flex: 1,
				minWidth: 150,
				renderCell: (params: GridValueGetterParams) => (
					<time className="accepted-rejected">
						{params.row.createdTs ? moment(params.row.createdTs).format("DD-MM-YYYY") : ""}
					</time>
				),
			},
			{
				field: "approved/rejected",
				headerName: "Approved/Rejected",
				flex: 1,
				minWidth: 150,
				renderCell: (params: GridValueGetterParams) => (
					<div className="accepted-rejected">
						<h5>
							<span>By: </span>
							{params.row.admin ?? ""}
						</h5>
						<time>
							<span>At: </span>
							{params.row.lastModifiedTs && (params.row.status === pjpStatus[0] || params.row.status === pjpStatus[1])
								? moment(params.row.lastModifiedTs).format("DD-MM-YYYY")
								: ""}
						</time>
					</div>
				),
			},
			{
				field: "state",
				headerName: "Status",
				flex: 1,
				minWidth: 100,
				renderCell: (params: GridValueGetterParams) => {
					const state =
						params.row.status === pjpStatus[0]
							? "Rejected"
							: params.row.status === pjpStatus[1]
							? "Approved"
							: params.row.status === pjpStatus[2]
							? "Pending"
							: "Deleted";
					return <span className={`role_badge ${state}`}>{state}</span>;
				},
			},
			{
				field: "action",
				headerName: "Action",
				width: 150,
				renderCell: (params: GridValueGetterParams) =>
					params.row.status === pjpStatus[2] && (
						<ApproveRejectButtons
							approveRequest={() => approveRequest(params.row.pjpID)}
							rejectRequest={(reason: string) => rejectRequest(params.row.pjpID, reason)}
							name={params.row.name}
							link={`/tasks/pjp-requests/${params.row.pjpID}`}
							userWritePermission={userWritePermission}
						/>
					),
			},
		],
		[requests, userWritePermission]
	);
	const [columns, setColumns] = useState<GridColDef[]>([]);

	useEffect(() => {
		setColumns(tempColumns);
	}, [tempColumns]);

	const approveRequest = useCallback(
		async (id: string) => {
			if (id.length === 0) return;
			const updatedRequests = _cloneDeep(requests);
			const requestIndex = updatedRequests.findIndex((r) => r.pjpID === id);
			if (requestIndex < 0) return;
			updatedRequests[requestIndex].status =
				updatedRequests[requestIndex].status === pjpStatus[2] ? pjpStatus[1] : updatedRequests[requestIndex].status;
			try {
				const { data } = await updatePJPStatus({
					variables: { pjpIDs: id, status: updatedRequests[requestIndex].status },
				});

				if (data?.update_pjp_status?.success) {
					dispatch(showNotification({ message: "Successfuly approved the request", severity: "success" }));
				} else {
					dispatch(showNotification({ message: data?.update_pjp_status?.message, severity: "error" }));
					return;
				}
			} catch (error) {
				dispatch(showNotification({ message: "Something went wrong. Please try again later.", severity: "error" }));
				return;
			}

			setRequests(updatedRequests);
			refetch();
		},
		[dispatch, requests, setRequests]
	);

	const rejectRequest = async (id: string, reason: string) => {
		if (id.length === 0) return;
		const updatedRequests = _cloneDeep(requests);
		const requestIndex = updatedRequests.findIndex((r) => r.pjpID === id);
		if (requestIndex < 0) return;
		updatedRequests[requestIndex].status =
			updatedRequests[requestIndex].status === pjpStatus[2] ? pjpStatus[0] : updatedRequests[requestIndex].status;
		updatedRequests[requestIndex].rejectReason = reason;
		try {
			const { data } = await updatePJPStatus({
				variables: { pjpIDs: [id], status: updatedRequests[requestIndex].status, reason: reason },
			});
			if (data?.update_pjp_status?.success) {
				dispatch(showNotification({ message: "Successfuly rejected the request", severity: "success" }));
			} else {
				dispatch(showNotification({ message: data?.update_pjp_status?.message, severity: "error" }));
				return;
			}
		} catch (error) {
			dispatch(showNotification({ message: "Something went wrong. Please try again later.", severity: "error" }));
			return;
		}

		setRequests(updatedRequests);
		refetch();
	};

	const sortedRequests = [...filteredRequests].sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf());

	return (
		<div className="datagrid-table">
			<DataGridPro
				sx={DG_STYLES}
				rows={sortedRequests}
				getRowId={(row) => row.pjpID}
				columns={columns}
				loading={isLoading}
				pageSize={pageSize}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[5, 10, 20, 50]}
				rowHeight={70}
				checkboxSelection
				disableSelectionOnClick
				disableColumnFilter
				pagination
				onRowClick={(params) => userWritePermission && router.push(`/tasks/pjp-requests/${params.row.pjpID}`)}
				onSelectionModelChange={(selectionModel) => setSelectedRows(selectionModel)}
				components={{
					ColumnMenu: CustomColumnMenu,
				}}
			/>
		</div>
	);
};

export default PjpTable;
