import ClearIcon from "@mui/icons-material/Clear";
import { FC } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { USER_ROSTER_TYPE } from "../../../@types";
import { saveUserRosters } from "../../../api/attendance";
import { APP_DISPATCH, ROOT_STATE, setSearchTermRoster, setUserRosters, showNotification } from "../../../redux";
import useAdminEntitlements from "../../useAdminEntitlements";

type Props = {
	rosters: USER_ROSTER_TYPE["rosters"];
	employeeID: number;
	field: string;
};

const RostersDragCell: FC<Props> = ({ employeeID, field, rosters }) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const {
		userRosters: userRostersData,
		rosterTemplates: rostersTemplate,
		searchTerm,
	} = useSelector((state: ROOT_STATE) => state.roster);
	const userWritePermission = useAdminEntitlements("attendance:rosters", "write");

	const getRosterData = (rosterTemplateID: string | number) =>
		rostersTemplate.find(
			(rosterTemplate: any) => String(rosterTemplate["rosterTemplateID"]) === String(rosterTemplateID)
		);

	const removeRoster = async (e: any) => {
		const [employeeID, day, rosterTemplateID] = e.target.id.split("_");

		const clonedUserRostersData = userRostersData.map((user: any) => {
			if (user["employeeID"] === employeeID.toString()) {
				let userRosters = user["rosters"];
				userRosters = userRosters.filter((roster: any) => {
					if (roster["date"] === day && roster["rosterTemplateID"].toString() === rosterTemplateID) {
						return false;
					}
					return roster;
				});
				return { ...user, rosters: [...userRosters] };
			} else {
				return { ...user };
			}
		});

		const dataForBackend = {};
		clonedUserRostersData.forEach((user: any) => {
			if (user.employeeID === employeeID) {
				dataForBackend[employeeID] = user["rosters"];
			}
		});

		if (Object.keys(dataForBackend).length > 0) {
			const { success, message } = await saveUserRosters({ ...dataForBackend }, "Successfully removed rosters");
			if (success) {
				dispatch(setUserRosters({ userRosters: clonedUserRostersData }));
				dispatch(setSearchTermRoster(searchTerm));
			}
			dispatch(showNotification({ message: message, severity: success ? "success" : "error" }));
		}
	};

	return (
		<>
			<Droppable droppableId={`${employeeID}_${field}`} direction="horizontal">
				{(provided: any) => (
					<div {...provided.droppableProps} ref={provided.innerRef} className="shift-drag-cell">
						{rosters &&
							Array.isArray(rosters) &&
							rosters.map((roster, idx) => (
								<>
									{roster["date"] === field ? (
										<Draggable
											key={`${employeeID}_${field}_${roster["rosterTemplateID"]}`}
											draggableId={`${employeeID}_${field}_${roster["rosterTemplateID"]}`}
											isDragDisabled={!userWritePermission}
											index={idx}>
											{(provided) => (
												<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
													<div key={roster["rosterTemplateID"]} className="column-drag-cell">
														<h5
															style={{
																color: getRosterData(roster["rosterTemplateID"])?.color,
																whiteSpace: "nowrap",
															}}>
															{getRosterData(roster["rosterTemplateID"])?.name === "WO"
																? "Weekly Off"
																: getRosterData(roster["rosterTemplateID"])?.name}
														</h5>
														<ClearIcon
															id={`${employeeID}_${field}_${roster["rosterTemplateID"]}`}
															onClick={(e) => {
																if (userWritePermission) {
																	removeRoster(e);
																}
															}}
															sx={{ fontSize: "14px", cursor: "pointer" }}
														/>
													</div>
												</div>
											)}
										</Draggable>
									) : null}
								</>
							))}
					</div>
				)}
			</Droppable>
		</>
	);
};

export default RostersDragCell;
