import { persistor } from "./store/index";
import { setAttendanceStatus } from "./slices/Attendance/attendanceStatus";
import {
	getRostersData,
	setRosterTemplates,
	setSearchTermRoster,
	setUserRosters,
} from "./slices/Attendance/rosterSlice";
import { getShiftsData, setSearchTerm, setShiftTemplates, setUserShifts } from "./slices/Attendance/shiftSlice";
import { closeNotification, showNotification } from "./slices/notificationSlice";
import { addUserDetails, authCheckState, logoutAllSystem, removeCredentials, signInFirebase } from "./slices/userSlice";
import { APP_DISPATCH, ROOT_STATE, store } from "./store";

export {
	store,
	persistor,
	authCheckState,
	logoutAllSystem,
	removeCredentials,
	signInFirebase,
	setUserShifts,
	setShiftTemplates,
	setUserRosters,
	setRosterTemplates,
	showNotification,
	closeNotification,
	setSearchTerm,
	getShiftsData,
	addUserDetails,
	getRostersData,
	setSearchTermRoster,
	setAttendanceStatus,
};
export type { ROOT_STATE, APP_DISPATCH };
