type Props = {
	lat: number | null;
	lng: number | null;
	key: number;
	text: string;
};

export default function Marker(props: Props) {
	const { key, text } = props;

	return (
		<div
			className="marker"
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column",
			}}>
			<span
				style={{
					background: "rgb(173, 216, 230)",
					textAlign: "center",
					marginTop: "8px",
					minWidth: "100px",
					fontSize: "14px",
				}}>
				{text}
			</span>
			<img key={key} width="32px" height="32px" src="/images/blueMarker.png" />
		</div>
	);
}
