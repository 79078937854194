// react
import React, { useState } from "react";
// mui
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
// components
import UploadModal from "./UploadModal";
// utils
import useAdminEntitlements from "../../../../../useAdminEntitlements";

const UploadModalAndButton = ({ selectedField, setDetailsAndSettingsForFields }: any) => {
	const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState<boolean>(false);
	const userWritePermission = useAdminEntitlements("settings:tasks", "write");
	return (
		<div>
			{bulkUploadModalOpen && (
				<UploadModal
					setBulkUploadModalOpen={setBulkUploadModalOpen}
					selectedField={selectedField}
					setDetailsAndSettingsForFields={setDetailsAndSettingsForFields}
				/>
			)}
			<button
				className="btn btn-primary btn-sm mt-4"
				style={{
					backgroundColor: "#1976d2",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					gap: "0.5rem",
				}}
				disabled={!userWritePermission || selectedField?.fieldName.trim().length === 0}
				onClick={(e) => { e.preventDefault(); setBulkUploadModalOpen(true); }}>
				Upload Options <FileUploadOutlinedIcon fontSize="small" />
			</button>
		</div>
	);
};

export default UploadModalAndButton;
