/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Button } from "@mui/material";
import Link from "next/link";
import { Dispatch, FC, memo, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import { SITEPOOLS_DATA_WITH_SITES, SITES_DATA } from "../../../@types";
import { toDeleteSitePool } from "../../../api/clients_sites";
import { APP_DISPATCH, showNotification } from "../../../redux";
import DeleteModal from "../../common/DeleteModal";
import useAdminEntitlements from "../../useAdminEntitlements";
type Props = {
	sitePools: SITEPOOLS_DATA_WITH_SITES[];
	filteredSitePools: SITEPOOLS_DATA_WITH_SITES[];
	sites: SITES_DATA[];
	setSitePools: Dispatch<SetStateAction<SITEPOOLS_DATA_WITH_SITES[]>>;
};
const SitePoolColumn: FC<Props> = ({ sitePools, sites, filteredSitePools, setSitePools }) => {
	const [expand, setExpand] = useState(false);
	const dispatch = useDispatch<APP_DISPATCH>();
	const [deleteSitePool, setDeleteSitePool] = useState<null | SITEPOOLS_DATA_WITH_SITES>(null);
	const userWritePermission = useAdminEntitlements("clientsAndSites:sites", "write");

	const [selectedSitePoolId, setSelectedSitePoolId] = useState<string | null>(
		() => filteredSitePools[0]?.sitePoolID || null
	);
	const selectedSitePool = filteredSitePools.find((sitePool) => sitePool.sitePoolID === selectedSitePoolId);

	const sitesInSitePool = selectedSitePool?.siteIDs.map((siteID) => sites.find((site) => site.clientID === siteID));

	const handleDeleteSitePool = async (pool: SITEPOOLS_DATA_WITH_SITES) => {
		const { success, message } = await toDeleteSitePool(pool.sitePoolID);
		dispatch(showNotification({ message: message, severity: success ? "success" : "error" }));
		if (!success) return;
		const updatedSitePool = sitePools.filter((s) => s.sitePoolID !== pool.sitePoolID);
		setSitePools(updatedSitePool);
		setDeleteSitePool(null);
		setExpand(false);
	};
	if (filteredSitePools.length === 0)
		return (
			<div className="nothing__found" style={{ height: "100%" }}>
				No Result Found
			</div>
		);

	return (
        <div className="main_content">
			<div className="routes_list-container">
				<div className="routes_list-box" style={{ marginTop: "0" }}>
					<ul style={{ marginBlock: "0" }}>
						{filteredSitePools.map((item) => (
							<li key={item.sitePoolID} onClick={() => userWritePermission && setSelectedSitePoolId(item.sitePoolID)}>
								<div className="details">
									<h4>{item.name}</h4>
								</div>
								<span className="role_badge">{item.siteIDs.length} Sites</span>
								<Button
									variant="text"
									sx={{ minWidth: "auto", backgroundColor:"transparent !important" }}
									disabled={!userWritePermission}
									onClick={(e) => {
										e.stopPropagation();
										setExpand(true);
										setDeleteSitePool(item);
									}}>
									<DeleteOutlinedIcon />
								</Button>
								{userWritePermission ? (
									<Link
                                        prefetch={false}
                                        href={`/clients_sites/sites/sitepool/${item.sitePoolID}`}
                                        passHref
                                        legacyBehavior>
										<Button variant="text" sx={{ minWidth: "auto"}}>
											<ModeEditOutlineOutlinedIcon />
										</Button>
									</Link>
								) : (
									<Button disabled={!userWritePermission} variant="text" sx={{ minWidth: "auto"}}>
										<ModeEditOutlineOutlinedIcon />
									</Button>
								)}
							</li>
						))}
					</ul>
				</div>
			</div>
			<div className="review_routes" style={{ paddingInline: 10 }}>
				{selectedSitePoolId && selectedSitePool && (
					<>
						<h2>
							{selectedSitePool.name}
							<span className="role_badge" style={{ marginLeft: 10 }}>
								{selectedSitePool.siteIDs.length} Sites
							</span>
						</h2>
						<ul>
							{sitesInSitePool &&
								Array.isArray(sitesInSitePool) &&
								sitesInSitePool.map((site) => (
									<li key={site?.clientID}>
										<div className="details">
											<div className="info">
												<h4>{site?.clientName}</h4>
												<address>{site?.address || "-"}</address>
											</div>
											<h5>
												{site?.proprietorName ? (
													<>
														<PersonOutlineOutlinedIcon />
														{site?.proprietorName}
													</>
												) : (
													"-"
												)}
											</h5>
											<h5>
												{site?.phoneNumber ? (
													<>
														<CallOutlinedIcon /> {site?.phoneNumber}
													</>
												) : (
													"-"
												)}
											</h5>
										</div>
									</li>
								))}
						</ul>
					</>
				)}
			</div>
			{expand && deleteSitePool && (
				<DeleteModal
					handleClose={() => setExpand(false)}
					name={deleteSitePool?.name}
					deleteHandler={() => handleDeleteSitePool(deleteSitePool)}
				/>
			)}
		</div>
    );
};

export default memo(SitePoolColumn);
