// react
import { FC, useEffect, useState } from "react";

// mui
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import { Button, CircularProgress, Table, TableCell, TableRow } from "@mui/material";
import { TableBody, TableHead } from "@mui/material";

// query
import { useLazyQuery } from "@apollo/client";
import { do_entities_have_dependencies } from "../../schema/common";

// constants
import { DATA_TYPES } from "../../constants/dependentOn";

// redux
import { useDispatch } from "react-redux";
import { showNotification } from "../../redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialog-paper": {
		width: "850px",
		borderRadius: "10px",
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
		display: "flex",
		justifyContent: "flex-end",
		paddingRight: "1.5rem",
		gap: "0.5rem",
	},
}));

type Props = {
	deleteHandler: () => void;
	handleClose: () => void;
	name: string;
	warningText?: string;
	canBeDeleted?: boolean;
	moduleName?: string;
	data?: any;
	dataType?: string;
};

const DeleteModal: FC<Props> = ({
	deleteHandler,
	handleClose,
	name,
	warningText,
	canBeDeleted,
	moduleName,
	data: entityIDs,
}) => {
	const dispatch = useDispatch();
	const [fetchData, { data, loading, error }] = useLazyQuery(do_entities_have_dependencies);

	const [isLinkedToTargets, setIsLinkedToTargets] = useState<any>([]);

	useEffect(() => {
		if (moduleName) {
			fetchData({
				variables: {
					input: {
						entityIDs: entityIDs,
						entityType: DATA_TYPES[moduleName],
					},
				},
			});
		}
	}, [moduleName]);

	useEffect(() => {
		if (!loading && error?.message) {
			dispatch(
				showNotification({
					message: "Something went wrong",
					severity: "error",
				})
			);

			handleClose();
		}

		if (!loading && data) {
			setIsLinkedToTargets(data.do_entities_have_dependencies);
		}
	}, [loading, data]);

	const addPrefixDependency = (dep: any) => {
		if (dep?.dependentType === DATA_TYPES.attendance) {
			return "Team Name -";
		} else if (dep?.dependentType === DATA_TYPES.targets) {
			return "Target Name -";
		} else if (dep?.dependentType === DATA_TYPES["custom field"]) {
			return "Field Name -";
		}
		return "";
	};

	return (
		<BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true} maxWidth={"md"}>
			<div
				style={{
					display: "flex",
					padding: "16px",
					alignItems: "center",
					width: "100%",
					justifyContent: "center",
					flexDirection: "column",
				}}>
				{loading ? (
					<>
						<CircularProgress />
					</>
				) : isLinkedToTargets?.length > 0 ? (
					<div style={{ width: "100%" }}>
						<h5 style={{ marginBottom: "10px" }}>Unable to Delete</h5>
						<p style={{ fontFamily: "SFUIText-Regular", fontSize: "14px", lineHeight: "18px", color: "grey" }}>
							These {moduleName}'s can't be deleted as other modules are dependant/linked to them.
						</p>
						<Table sx={{ width: "100%", marginTop: "1rem" }} aria-label="simple table">
							<TableHead sx={{ background: "#f2f2f2" }}>
								<TableRow sx={{ padding: "10px" }}>
									<TableCell
										style={{
											fontSize: "16px",
											padding: "1rem",
											border: "1px solid rgba(224, 224, 224, 1)",
											width: "40%",
										}}>
										{`${moduleName?.toLowerCase().charAt(0).toUpperCase()}${moduleName?.slice(1)}`}
									</TableCell>
									<TableCell
										style={{
											fontSize: "16px",
											padding: "1rem",
											border: "1px solid rgba(224, 224, 224, 1)",
											width: "30%",
										}}>
										Dependant Module
									</TableCell>
									<TableCell
										style={{
											fontSize: "16px",
											padding: "1rem",
											border: "1px solid rgba(224, 224, 224, 1)",
											width: "30%",
										}}>
										Details
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{isLinkedToTargets?.map((entity: any) => (
									<>
										<TableRow key={entity.entityID} style={{ fontSize: "14px" }}>
											<TableCell
												style={{ fontSize: "inherit", border: "1px solid rgba(224, 224, 224, 1)" }}
												rowSpan={entity?.dependencies.length + 1 ?? 0}>
												{entity?.entityName}
											</TableCell>
										</TableRow>

										{entity?.dependencies.map((dependencie: any) => (
											<>
												<TableRow style={{ fontSize: "14px" }}>
													<TableCell style={{ fontSize: "inherit", border: "1px solid rgba(224, 224, 224, 1)" }}>
														<span
															key={dependencie.dependentID}
															style={{ marginBottom: "0.2rem", display: "block", fontSize: "inherit" }}>
															{dependencie?.dependentType?.replace("_", " ")?.toLowerCase().charAt(0).toUpperCase()}
															{dependencie?.dependentType?.replace("_", " ")?.toLowerCase()?.slice(1)}
														</span>
													</TableCell>

													<TableCell style={{ fontSize: "inherit", border: "1px solid rgba(224, 224, 224, 1)" }}>
														<span
															key={dependencie.dependentID}
															style={{ marginBottom: "0.2rem", display: "block", fontSize: "inherit" }}>
															{addPrefixDependency(dependencie)} {dependencie.dependentEntityName}
															{dependencie?.dependentType === DATA_TYPES.attendance ? (
																<span style={{ color: "gray", fontSize: "14px" }}> (Team)</span>
															) : (
																""
															)}{" "}
															{dependencie?.dependentType === DATA_TYPES["custom field"] ? (
																<span style={{ color: "gray", fontSize: "14px" }}>
																	(
																	{`${dependencie?.parentModule
																		?.toLowerCase()
																		.charAt(0)
																		.toUpperCase()}${dependencie?.parentModule?.toLowerCase()?.slice(1)}`}
																	)
																</span>
															) : (
																""
															)}
														</span>
													</TableCell>
												</TableRow>
											</>
										))}
									</>
								))}
							</TableBody>
						</Table>
					</div>
				) : (
					<>
						{/* <DeleteForeverOutlinedIcon sx={{ width: "100%", height: "56px", marginTop: "1rem", color: "#f54747" }} /> */}
						<p
							style={{
								textAlign: "center",
								margin: "1.5rem 0 0.5rem 0",
								fontSize: "18px",
								fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
							}}>
							{canBeDeleted !== undefined && !canBeDeleted && "This user is a super admin and cannot be deleted."}
							{canBeDeleted !== undefined && canBeDeleted && (
								<p style={{ lineHeight: "1.5rem" }}>
									The <strong>{name ?? "Selected options "}</strong> will be deleted and cannot be retrieved later. Are
									you sure you want to delete?
								</p>
							)}
							{canBeDeleted === undefined && (
								<p style={{ lineHeight: "1.5rem" }}>
									<strong>{name ?? "Unnamed User(s)"}</strong> will be deleted and cannot be retrieved later. Are
									you sure you want to delete?
								</p>
							)}
						</p>
						{warningText && warningText.length > 0 && (
							<p
								className="font-m-12"
								style={{
									color: "#ef9943",
								}}>
								<WarningOutlinedIcon sx={{ width: "15px", margin: "0 4px 1px 0" }} />
								{warningText}
							</p>
						)}
					</>
				)}
			</div>
			{isLinkedToTargets.length === 0 && !loading && (
				<DialogActions
					sx={{
						justifyContent: "center",
						marginBottom: "1rem",
					}}>
					<button
						style={{
							textTransform: "capitalize",
							background: "#f5f5f5",
							color: "black",
							border: "1px solid #f5f5f5",
							padding: "5px 14px",
							borderRadius: "4px",
							boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)",
							fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
						}}
						onClick={(e) => {
							e.stopPropagation();
							handleClose();
						}}>
						{canBeDeleted !== undefined && !canBeDeleted && "OK"}
						{canBeDeleted !== undefined && canBeDeleted && "Cancel"}
						{canBeDeleted === undefined && "Cancel"}
					</button>
					{canBeDeleted !== undefined && canBeDeleted && (
						<button
							onClick={(e) => {
								e.stopPropagation();
								deleteHandler();
								handleClose();
							}}
							style={{
								textTransform: "capitalize",
								background: "transparent linear-gradient(180deg, #FF4657 0%, #E23041 100%)",
								color: "#fff",
								border: "1px solid #f54747",
								padding: "5px 14px",
								borderRadius: "4px",
								boxShadow: "2px 2px 4px #9e0e1a",
								fontFamily: "SFUIText-Medium, Helvetica, Arial, sans-serif",
							}}>
							Delete
						</button>
					)}
					{canBeDeleted === undefined && (
						<button
							onClick={(e) => {
								e.stopPropagation();
								deleteHandler();
								handleClose();
							}}
							style={{
								textTransform: "capitalize",
								background: "transparent linear-gradient(180deg, #FF4657 0%, #E23041 100%)",
								color: "#fff",
								border: "1px solid #f54747",
								padding: "5px 14px",
								borderRadius: "4px",
								boxShadow: "2px 2px 4px #9e0e1a",
								fontFamily: "SFUIText-Medium, Helvetica, Arial, sans-serif",
							}}>
							<span style={{ display: "flex", alignItems: "center", fontSize: "16px" }}>Delete</span>
						</button>
					)}
				</DialogActions>
			)}

			{isLinkedToTargets.length > 0 && !loading && (
				<DialogActions sx={{ padding: "0 16px 16px 16px !important" }}>
					<Button
						onClick={(e) => {
							e.stopPropagation();
							handleClose();
						}}
						style={{ color: "gray", border: "1.5px solid gray" }}
						color="error"
						variant="outlined">
						Cancel
					</Button>
				</DialogActions>
			)}
		</BootstrapDialog>
	);
};

export default DeleteModal;
