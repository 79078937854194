import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "moment-timezone";
import { ChangeEvent, MouseEvent, useState } from "react";
import { useSelector } from "react-redux";
import { TABLE_STYLE } from "../../../constants";
import { ROOT_STATE } from "../../../redux";
import ShiftsDragCell from "../../Attendance/Shifts/ShiftsDragCell";
import ImageCell from "../../common/datagrid/ImageAndNameCell";

interface TablePaginationActionsProps {
	count: number;
	page: number;
	rowsPerPage: number;
	onPageChange: (
		// eslint-disable-next-line no-unused-vars
		event: MouseEvent<HTMLButtonElement>,
		// eslint-disable-next-line no-unused-vars
		newPage: number
	) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}
const ShiftsTable = () => {
	const { filteredUserShifts: userShiftsData } = useSelector((state: ROOT_STATE) => state.shift);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userShiftsData.length) : 0;

	const handleChangePage = (_event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<div className="shift-table">
			<TableContainer component={Paper}>
				<Table sx={TABLE_STYLE} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Employee</TableCell>
							<TableCell>Monday</TableCell>
							<TableCell>Tuesday</TableCell>
							<TableCell>Wednesday</TableCell>
							<TableCell>Thursday</TableCell>
							<TableCell>Friday</TableCell>
							<TableCell>Saturday</TableCell>
							<TableCell>Sunday</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0
							? userShiftsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							: userShiftsData
						).map((userShift) => (
							<TableRow key={userShift["employeeID"]} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
								<TableCell component="th" scope="row" sx={{ minWidth: "180px" }}>
									<ImageCell name={`${userShift["firstName"]} ${userShift["lastName"]}`} avatar={userShift["avatar"]} />
								</TableCell>
								<TableCell component="th" scope="row" sx={{ minWidth: "180px" }}>
									<ShiftsDragCell employeeID={+userShift["employeeID"]} field="mon" shifts={userShift["shifts"]} />
								</TableCell>
								<TableCell component="th" scope="row" sx={{ minWidth: "180px" }}>
									<ShiftsDragCell employeeID={+userShift["employeeID"]} field="tue" shifts={userShift["shifts"]} />
								</TableCell>
								<TableCell component="th" scope="row" sx={{ minWidth: "180px" }}>
									<ShiftsDragCell employeeID={+userShift["employeeID"]} field="wed" shifts={userShift["shifts"]} />
								</TableCell>
								<TableCell component="th" scope="row" sx={{ minWidth: "180px" }}>
									<ShiftsDragCell employeeID={+userShift["employeeID"]} field="thur" shifts={userShift["shifts"]} />
								</TableCell>
								<TableCell component="th" scope="row" sx={{ minWidth: "180px" }}>
									<ShiftsDragCell employeeID={+userShift["employeeID"]} field="fri" shifts={userShift["shifts"]} />
								</TableCell>
								<TableCell component="th" scope="row" sx={{ minWidth: "180px",width:"auto", overflow:"hidden" }}>
									<ShiftsDragCell employeeID={+userShift["employeeID"]} field="sat" shifts={userShift["shifts"]} />
								</TableCell>
								<TableCell component="th" scope="row" sx={{ minWidth: "180px", width:"auto", overflow:"hidden" }}>
									<ShiftsDragCell employeeID={+userShift["employeeID"]} field="sun" shifts={userShift["shifts"]} />
								</TableCell>
							</TableRow>
						))}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={6} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<TableFooter>
					<TableRow>
						<TablePagination
							// rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
							rowsPerPageOptions={[5, 10]}
							colSpan={3}
							count={userShiftsData.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: {
									"aria-label": "rows per page",
								},
								native: true,
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</div>
		</div>
	);
};

export default ShiftsTable;
