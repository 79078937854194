import { gql } from "@apollo/client";
export const ALL_DASHBOARD_USERS = gql`
	query GetUsersByCompanyIdV2(
		$skip: Int
		$take: Int
		$date: Date
		$searchKey: String
		$workingStatusFilter: String
		$filters: [genericFilter!]
	) {
		get_users_by_admin_id_v2(
			skip: $skip
			take: $take
			searchKey: $searchKey
			workingStatusFilter: $workingStatusFilter
			filters: $filters
		) {
			data {
				employeeID
				userID
				companyID
				internalEmpID
				firstName
				lastName
				email
				mobileNumber
				parentAdminID
				profileID
				profileName
				imgUrl
				city
				workingStatus
				workingStatusV2
				lastLocation {
					timestamp
					accuracy
					insertTime
					date
					lat
					lon
				}
				lastAttendance {
					eventTypeID
					timestamp
					date
					address
					tz
					siteID
					siteName
				}
				lastAppState {
					gpsEnabled
					batteryPct
					wifiEnabled
				}
				tasks(date: $date) {
					taskID
					checkinTime
					taskDescription
					checkoutTime
					startTime
					endTime
					clientInfo {
						clientName
					}
					address
				}
				orders(date: $date) {
					orderID
					orderValue
					client {
						clientID
						clientName
					}
				}
				completedForms(date: $date) {
					completedFormID
					formTitle
					createdTs
				}
				lastPunchedIn {
					employeeID
					timestamp
					address
					lat
					lon
					eventTypeID
					siteID
					siteName
				}
				lastPunchedOut {
					timestamp
					address
					lat
					lon
					eventTypeID
					siteID
					siteName
				}
			}
			count
			unpaginatedCount
		}
	}
`;

export const ALL_USERS_FOR_MAP = gql`
	query GetUsersByCompanyIdV2($date: Date) {
		get_users_by_admin_id_v2 {
			data {
				firstName
				lastName
				employeeID
				lastLocation {
					lat
					lon
					accuracy
				}
				lastAppState {
					gpsEnabled
					batteryPct
					wifiEnabled
				}
				tasks(date: $date) {
					taskID
					checkinTime
					taskDescription
					checkoutTime
					startTime
					endTime
				}
				workingStatusV2
			}
			count
		}
	}
`;

export const ALL_USERS_FOR_CARD = gql`
	query GetUsersByCompanyIdV2 {
		get_users_by_admin_id_v2 {
			data {
				employeeID
				workingStatusV2
				lastAttendance {
					eventTypeID
					timestamp
				}
			}
		}
	}
`;

export const GET_FE_STATUS = gql`
	query GetAttendanceSummary($teamFilter: Boolean, $filters: [genericFilter!]) {
		get_attendance_summary(teamFilter: $teamFilter, filters: $filters) {
			profile {
				profileID
				profileName
			}
			attendanceSummary {
				totalUsers
				onlineUsers
				offlineUsers
				inactiveUsers
			}
		}
	}
`;

export const GET_SITE_WISE_STATUS = gql`
	query GetSiteWiseAttendanceSummary($siteFilter: Boolean) {
		get_attendance_summary(siteFilter: $siteFilter) {
			siteInfo {
				clientID
				clientName
			}
			attendanceSummary {
				totalUsers
				onlineUsers
				offlineUsers
				inactiveUsers
			}
		}
	}
`;

export const WATCH_APP_STATE_EVENT = gql`
	subscription WatchAppStateEvent {
		appState {
			employeeID
			gpsEnabled
			batteryPct
			wifiEnabled
		}
	}
`;

export const WATCH_ATTENDANCE_EVENT = gql`
	subscription WatchAttendanceEvent {
		attendance {
			employeeID
			eventTypeID
			timestamp
		}
	}
`;

export const WATCH_LOCATION_EVENT = gql`
	subscription WatchLocationEvent {
		location {
			employeeID
			timestamp
			age
			insertTime
			date
			lat
			lon
		}
	}
`;

export const WATCH_TASK_ADDED_EVENT = gql`
	subscription {
		task_add {
			taskID
			checkinTime
			taskDescription
			checkoutTime
			startTime
			endTime
			employeeID
		}
	}
`;

export const WATCH_TASK_UPDATE_EVENT = gql`
	subscription {
		task_update {
			taskID
			checkinTime
			taskDescription
			checkoutTime
			startTime
			endTime
			employeeID
		}
	}
`;

export const WATCH_TASK_REMOVE_EVENT = gql`
	subscription {
		task_delete {
			taskID
			checkinTime
			taskDescription
			checkoutTime
			startTime
			endTime
			employeeID
		}
	}
`;

export const WATCH_FORM_ADDED_EVENT = gql`
	subscription {
		completed_formdata_add {
			employeeID
			completedFormID
			formDescription
			createdTs
		}
	}
`;

export const WATCH_ORDER_ADDED_EVENT = gql`
	subscription {
		order_add {
			employeeID
			client {
				clientName
			}
			orderValue
			orderID
		}
	}
`;
