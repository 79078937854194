export const BD_FIELD_EXECUTIVE_TEMPLATE = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/users/downloadUsers?isTemplate=1`;
export const BD_FIELD_EXECUTIVE = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/users/downloadUsers`;

export const BD_BIOMETRIC_USERS_TEMPLATE = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/users/downloadBiometricUsers?isTemplate=1`;
export const BD_BIOMETRIC_USERS = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/users/downloadBiometricUsers`;

export const BD_TASKS_TEMPLATE = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/tasks/downloadTaskExcelTemplateNew`;

export const BD_CLIENTS = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/clients/downloadClients`;
export const BD_CLIENTS_TEMPLATE = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/clients/downloadClients?isTemplate=1`;

export const BD_SITES = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/sites/downloadSites`;

export const BD_PRODUCTS_TEMPLATE = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/orders/downloadSKUsTemplate`;

export const BD_SHIFTS = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/shifts/downloadShifts`;

export const BD_ROSTERS = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/rosters/downloadRosters`;

export const BD_LEAVES_TEMPLATE = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/leaves/downloadLeaves`;

export const BD_BIOMETRIC_LEAVES_TEMPLATE = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/leaves/downloadBiometricLeaves`;

export const BD_ROUTES = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/routes/downloadRoute`;

export const BD_SHIFTS_TEMPLATE = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/shifts/downloadShiftsTemplate`;

export const BD_ROSTERS_TEMPLATE = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/rosters/downloadRostersTemplate`;

export const BD_SITES_TEMPLATE = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/sites/downloadSitesTemplate`;




