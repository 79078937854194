import axios, { AxiosResponse } from "axios";
import _cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import {
	ACCESS_CONTROL_USER,
	ADVANCE_REQUEST_TYPE,
	CONVEYANCE_TYPE,
	CUSTOM_FIELD_METADATA,
	EXPENSE_REQUEST_TYPE,
	PATH_DATA,
	SAMPLE_DATA,
	SITEPOOLS_DATA_WITH_SITES,
	SITES_DATA,
	TASK_TYPE,
	NEW_TEMPLATE_DATA,
	EXPENSE_REQUEST_TYPE_V1,
	CONVEYANCE_TYPE_V1,
	subReports,
} from "../@types";
import {
	CLIENTS_DATA,
	CUSTOM_FIELD_COMPLEX_TYPE,
	ENTITY_METADATA,
	FORM_TEMPLATE_TYPE,
	PROFILES_DATA,
	USERS_DATA,
} from "./../@types/index";
import _ from "lodash";
import { LEAD_OWNERS_TYPE } from "../constants/zohoCrmConstants";
import { GridColDef } from "@mui/x-data-grid-pro";
import { DATA_GRID_NAMES } from "../constants";
import { formatDateTo_DDMMYYYY } from "./validations";
import dayjs from "dayjs";
axios.defaults.withCredentials = true;
type ACCESS<T extends ACCESS_CONTROL_USER | null> = T extends null
	? null
	: ACCESS_CONTROL_USER & {
			adminID: number;
			role_box_id: string;
	  };
export const convertDataSourceToOrgChartDataSource = function (dataSource: ACCESS_CONTROL_USER[]): SAMPLE_DATA {
	if (Object.keys(dataSource).length === 0) return {} as SAMPLE_DATA;
	const clonedDataSource = _cloneDeep(dataSource);
	const rootNode = findRootNode(clonedDataSource) as ACCESS<ReturnType<typeof findRootNode>>;
	if (!rootNode) {
		return {} as SAMPLE_DATA;
	}
	rootNode.adminID = rootNode.id!;
	rootNode.role_box_id = "admin_" + rootNode.id;
	// @ts-expect-error don't know why this is not working
	let orgChartDataSource: SAMPLE_DATA = { ...rootNode };

	try {
		const queue: SAMPLE_DATA[] = [];
		queue.push(orgChartDataSource);
		while (queue.length !== 0) {
			const currentNode = queue.pop();
			// @ts-ignore
			const children = findChildren(currentNode.adminID, clonedDataSource).map((child) => {
				child.adminID = child.id;
				child.role_box_id = "admin_" + child.id;
				return child;
			});
			if (currentNode) currentNode.children = children;
			queue.push(...children);
		}
	} catch (error) {
		console.error(`error in convertDataSourceToOrgChartDataSource: ${error}`);
		orgChartDataSource = {} as SAMPLE_DATA;
	}

	return orgChartDataSource;
};

export const convertOrgChartDataSourceToDataSource = function (orgChartDataSource: any) {
	const newOrgChartDataSource = _cloneDeep(orgChartDataSource);
	const dataSource: any = [];

	dfs(newOrgChartDataSource, null, dataSource);
	return dataSource;
};
const findRootNode = function (dataSource: ACCESS_CONTROL_USER[]): ACCESS_CONTROL_USER | null {
	for (const key in dataSource) {
		const currentNode = dataSource[key];
		return currentNode;
	}
	return null;
};
const dfs = function (ds: any, parentID: any, result: any) {
	const { firstname, lastname, adminID, phoneNumber, title, email } = ds;

	try {
		const admin: any = { firstname, lastname, adminID, phoneNumber, email, title, parentId: parentID };
		if (ds.deleted) {
			admin.deleted = true;
		} else if (ds.new) {
			admin.new = true;
		} else if (ds.parentId !== parentID || ds.updated) {
			admin.updated = true;
		}
		result.push(admin);
		if (ds.children && Array.isArray(ds.children)) {
			for (const child of ds.children) {
				dfs(child, ds.adminID, result);
			}
		}
	} catch (error) {
		console.error(`error in dfs: ${error}`);
	}

	return result;
};
const findChildren = function (id: string, dataSource: any) {
	if (typeof dataSource !== "object") throw new Error(`Invalid dataSource. DataSource must be of type object`);

	const children = [];
	for (const key in dataSource) {
		const currentNode = dataSource[key];
		if (currentNode.parentId === id) {
			children.push(currentNode);
		}
	}

	return children;
};
export const getNodesPath = (data: SAMPLE_DATA[], result: PATH_DATA[]): PATH_DATA[] => {
	if (data.length === 0) return [];
	try {
		for (const element of data) {
			if (element?.children && Array.isArray(element.children) && element.children.length > 0) {
				for (let j = 0; j < element.children.length; j++) {
					result.push({
						id: `e${element.role_box_id}-${element.children[j].role_box_id}`,
						source: element.role_box_id,
						target: element.children[j].role_box_id,
						type: "smoothstep",
						animated: true,
					});
				}
				getNodesPath(element.children, result);
			}
		}
	} catch (error) {
		console.error(`error in getNodesPath: ${error}`);
	}
	return result;
};

export const findAvatarInitials = (name: string): string => {
	const avatar = name?.toLocaleUpperCase().split(" ") ?? "";
	const name1 = avatar?.length > 0 ? avatar[0].charAt(0) : "";
	const name2 = avatar?.length > 1 ? avatar[1].charAt(0) : "";
	return name1 + name2;
};

export const formatDataCompatibleToBackend = (formattedData: FORM_TEMPLATE_TYPE): FORM_TEMPLATE_TYPE => {
	// if (!formattedData.isQuizMode) {
	// 	formattedData.defaultPointValue = null;
	// 	formattedData.formScore = [];
	// }

	delete formattedData.createdByAdminID;
	delete formattedData.lastModifiedByAdminID;

	const formattedDataSections = formattedData.formSections.map((section) => {
		let tempQuestions = _cloneDeep(section.formQuestions);

		delete section.createdByAdminID;
		delete section.lastModifiedByAdminID;

		tempQuestions = tempQuestions.map((question) => {
			// if (!formattedData.isQuizMode) {
			// 	question.score = null;
			// } else {
			// 	question.score = question.score ? question.score : ;
			// }

			delete question.createdByAdminID;
			delete question.lastModifiedByAdminID;
			delete question.jumpToSectionBasedOnAnswer;

			let tempOptions = _cloneDeep(question.options);
			tempOptions = tempOptions.map((option) => {
				if (!formattedData.isQuizMode) {
					option.correct = null;
				}
				//  else {
				// 	option.correct = option.correct ? 1 : 0;
				// }

				delete option.createdByAdminID;
				delete option.lastModifiedByAdminID;

				return option;
			});
			return { ...question, options: tempOptions };
		});

		return { ...section, formQuestions: tempQuestions };
	});
	return { ...formattedData, formSections: formattedDataSections };
};
export const handleClientsVisibleTo = (
	client: CLIENTS_DATA,
	users: USERS_DATA[],
	profiles: PROFILES_DATA[]
): string => {
	const { visibility } = client;

	if (visibility?.length === 0) {
		return "-";
	} else if (visibility?.length === 1 && visibility[0].type === "EVERYONE" && visibility[0].value === 1) {
		return "Everyone";
	} else {
		const employeesVisibleTo = visibility?.filter((item) => item.type === "EMPLOYEE");
		const profilesVisibleTo = visibility?.filter((item) => item.type === "PROFILE");

		const employees = employeesVisibleTo?.map((item) => {
			const employee = users?.find((user) => user.employeeID === item.value);
			if (employee) return employee.firstName + " " + employee.lastName;
		});

		const teams = profilesVisibleTo?.map((item) => {
			const team = profiles?.find((profile) => profile.profileID === item.value);
			if (team) return team.profileName;
		});
		return [...employees, ...teams].join(", ");
	}
};

export const clientsCustomColumn = (
	customFieldsComplex: CUSTOM_FIELD_COMPLEX_TYPE,
	entity: ENTITY_METADATA,
	contactField?: string,
	row?: any
): string => {
	const name: CUSTOM_FIELD_COMPLEX_TYPE | null | undefined =
		customFieldsComplex && Array.isArray(customFieldsComplex)
			? customFieldsComplex.find((field: CUSTOM_FIELD_COMPLEX_TYPE) => field?.fieldID === entity?.fieldID)
			: null;
	if (entity.dataType === "date") {
		return name?.fieldValue ? formatDateTo_DDMMYYYY(name?.fieldValue) : "-";
	}
	if (entity.dataType === "contact" && entity.fieldName !== "Contact") {
		if (contactField === "Name") {
			return name?.fieldValue ? name?.fieldValue : "-";
		} else if (contactField === "Number") {
			return name?.fieldValue2 && name?.fieldValue3 ? `+${name?.fieldValue2} ${name.fieldValue3}` : "-";
		}
	}
	if (entity.dataType === "photo" || entity.dataType === "file") {
		return name?.selectedFieldValues?.map((e) => e.fieldValue).join(", ") ?? "-";
	}
	if (entity.dataType === "form") {
		return name?.fieldValue ? `${FORM_REDIRECT_URL}/${name?.fieldValue}` : "-";
	}
	if (entity.dataType === "order") {
		return name?.fieldValue !== undefined ? "https://panel.unolo.com/order/orders" : "-";
	}
	if (entity.dataType === "client" || entity.dataType === "site") {
		return row?.clientName ? row?.clientName : "-";
	}
	if (entity.dataType === "time") {
		return name?.fieldValue ? moment(+name?.fieldValue).format("hh:mm A") : "-";
	}
	if (!name || name === null || (name?.fieldValue === null && name?.selectedOptionsValues === null)) {
		return "-";
	}
	if (name?.fieldValue) {
		return name.fieldValue;
	}

	if (name?.fieldValue === null && typeof name?.selectedOptionsValues === "string") {
		return name.selectedOptionsValues;
	}
	if (name?.fieldValue === null && name?.selectedOptionsValues && name?.selectedOptionsValues?.length > 0) {
		return Array.isArray(name.selectedOptionsValues)
			? name.selectedOptionsValues.join(", ")
			: name.selectedOptionsValues;
	}
	return "-";
};

export const ordersCustomColumn = (
	customFieldsComplex: CUSTOM_FIELD_COMPLEX_TYPE,
	entity: ENTITY_METADATA
): string | string[] => {
	const name: CUSTOM_FIELD_COMPLEX_TYPE | null | undefined =
		customFieldsComplex && Array.isArray(customFieldsComplex)
			? customFieldsComplex.find((field: CUSTOM_FIELD_COMPLEX_TYPE) => field?.fieldID === entity?.fieldID)
			: null;
	if (entity.dataType === "date") {
		return name?.fieldValue ? formatDateTo_DDMMYYYY(name?.fieldValue) : "-";
	}
	if (entity.dataType === "contact") {
		const contactName: string = name?.fieldValue ? name?.fieldValue : "-";
		const contactNumber: string = name?.fieldValue3
			? `${name?.fieldValue2 ? "+" : ""}${name?.fieldValue2 ?? ""} ${name.fieldValue3}`
			: "-";
		return [contactName, contactNumber];
	}
	if (entity.dataType === "photo" || entity.dataType === "file") {
		return name?.selectedFieldValues?.map((e) => e.fieldValue).join(", ") ?? "-";
	}
	if (entity.dataType === "form") {
		return name?.fieldValue && name.fieldValue2
			? `${FORM_REDIRECT_URL}/${name?.fieldValue}?createdTs=${name?.fieldValue2}`
			: "-";
	}
	if (!name || name === null || (name?.fieldValue === null && name?.selectedOptionsValues === null)) {
		return "-";
	}
	if (name?.fieldValue && name?.selectedOptionsValues === null) {
		return name.fieldValue;
	}
	if (name?.fieldValue === null && typeof name?.selectedOptionsValues === "string") {
		return name.selectedOptionsValues;
	}
	if (name?.fieldValue === null && name?.selectedOptionsValues && name?.selectedOptionsValues?.length > 0) {
		return Array.isArray(name.selectedOptionsValues)
			? name.selectedOptionsValues.join(", ")
			: name.selectedOptionsValues;
	}
	return "-";
};

export const productsCustomColumn = (
	customFieldsComplex: CUSTOM_FIELD_COMPLEX_TYPE,
	entity: ENTITY_METADATA
): string | string[] => {
	const name: CUSTOM_FIELD_COMPLEX_TYPE | null | undefined =
		customFieldsComplex && Array.isArray(customFieldsComplex)
			? customFieldsComplex.find((field: CUSTOM_FIELD_COMPLEX_TYPE) => field?.fieldID === entity?.fieldID)
			: null;
	if (entity.dataType === "contact") {
		const contactName: string = name?.fieldValue ? name?.fieldValue : "-";
		const contactNumber: string = name?.fieldValue3 ? name?.fieldValue3 : "-";
		return [contactName, contactNumber];
	}
	if (entity.dataType === "date") {
		return name?.fieldValue ? formatDateTo_DDMMYYYY(name?.fieldValue) : "-";
	}
	if (!name || name === null || (name?.fieldValue === null && name?.selectedOptionsValues === null)) {
		return "-";
	}
	if (name?.fieldValue) {
		return name.fieldValue;
	}
	if (name?.fieldValue === null && typeof name?.selectedOptionsValues === "string") {
		return name.selectedOptionsValues;
	}
	if (name?.fieldValue === null && name?.selectedOptionsValues && name?.selectedOptionsValues?.length > 0) {
		return Array.isArray(name.selectedOptionsValues)
			? name.selectedOptionsValues.join(", ")
			: name.selectedOptionsValues;
	}
	return "-";
};
export const advanceCustomColumns = (
	customFieldsComplex: CUSTOM_FIELD_COMPLEX_TYPE,
	entity: ENTITY_METADATA
): string | string[] => {
	const name: CUSTOM_FIELD_COMPLEX_TYPE | null | undefined =
		customFieldsComplex && Array.isArray(customFieldsComplex)
			? customFieldsComplex.find((field: CUSTOM_FIELD_COMPLEX_TYPE) => field?.fieldID === entity?.fieldID)
			: null;
	if (entity.dataType === "contact") {
		const contactName: string = name?.fieldValue ? name?.fieldValue : "-";
		const contactNumber: string = name?.fieldValue3
			? `${name?.fieldValue2 ? "+" : ""}${name?.fieldValue2 ?? ""} ${name.fieldValue3}`
			: "-";
		return [contactName, contactNumber];
	}
	if (entity.dataType === "date") {
		return name?.fieldValue ? formatDateTo_DDMMYYYY(name?.fieldValue) : "-";
	}
	if (!name || name === null || (name?.fieldValue === null && name?.selectedOptionsValues === null)) {
		return "-";
	}
	if (name?.fieldValue) {
		return name.fieldValue;
	}
	if (name?.fieldValue === null && typeof name?.selectedOptionsValues === "string") {
		return name.selectedOptionsValues;
	}
	if (name?.fieldValue === null && name?.selectedOptionsValues && name?.selectedOptionsValues?.length > 0) {
		return Array.isArray(name.selectedOptionsValues)
			? name.selectedOptionsValues.join(", ")
			: name.selectedOptionsValues;
	}
	return "-";
};
export const validateSitePool = (sitePool: SITEPOOLS_DATA_WITH_SITES): string[] => {
	const errors: string[] = [];
	if (!sitePool.name) errors.push("Name is required");
	if (sitePool.siteIDs.length === 0) errors.push("At least one site is required");
	return errors;
};

export const validateSitePolygon = (site: SITES_DATA): string[] => {
	const errors: string[] = [];
	if (!site.polyline) errors.push("*No polygon created");
	return errors;
};

export const validateForm = (form: FORM_TEMPLATE_TYPE): string[] => {
	const errors: string[] = [];
	if (!form || !form.formTitle || form.formTitle.length === 0) {
		errors.push("Form name is required");
		return errors;
	}
	if (form.formTitle.startsWith(" ")) {
		errors.push("The title of the form should not begin with a space or be left blank");
		return errors;
	}
	if (form.formDescription.startsWith(" ")) {
		errors.push("The description of the form should not begin with a space or be left blank");
		return errors;
	}
	if (!form.formSections || form.formSections.length === 0) {
		errors.push("Form must have at least one section");
		return errors;
	}
	if (form.visibility.teamIDs?.length === 0 && form.visibility.visibleToAll === false) {
		errors.push("Select at least one team");
		return errors;
	}
	form.formSections.forEach((section) => {
		if (!section.sectionTitle || section.sectionTitle.length === 0) {
			errors.push(`Section Title for Section ${section.seqNumber + 1} is required`);
		}
		if (
			(!section.nextSection || section.nextSection.length === 0) &&
			section.seqNumber !== form.formSections.length - 1
		) {
			errors.push(`Section ${section.seqNumber + 1} must have jump to section`);
		}
		if (section.sectionTitle.startsWith(" ")) {
			errors.push(
				`The section title for section ${section.seqNumber + 1} should not begin with a space or be left blank`
			);
			return errors;
		}
		if (section.formQuestions.length === 0) {
			errors.push(`Section ${section.seqNumber + 1} must have at least one question`);
		}
		section.formQuestions.forEach((question, qIdx) => {
			if (!question.question || question.question.length === 0) {
				errors.push(`Question Title for Question ${qIdx + 1} in Section ${section.seqNumber + 1} is required`);
				return errors;
			}
			if (question.question.startsWith(" ")) {
				errors.push(
					`The question title for question ${qIdx + 1} in section ${
						section.seqNumber + 1
					} should not begin with a space or be left blank`
				);
				return errors;
			}
			if (question.questionType === "mChoice" || "cb" || "dd") {
				const seen = new Set();
				question.options.map((value) => {
					if (seen.has(value.optionValue.replace(/\s+/g, "").toLowerCase())) {
						errors.push(
							`Multiple choice options must be unique for Question ${qIdx + 1} in Section ${section.seqNumber + 1}.`
						);
					} else {
						seen.add(value.optionValue.replace(/\s+/g, "").toLowerCase());
					}
				});
			}
		});
	});
	return [...new Set(errors)];
};
export const validateTasks = (task: TASK_TYPE, taskType?: string): string[] => {
	const errors: string[] = [];
	if (taskType === "routes") return errors;
	if (Number.isNaN(task.startTime) || task.startTime === 0 || task.startTime === null) {
		errors.push("Enter valid start time");
	}
	if (Number.isNaN(task.endTime) || task.endTime === 0 || task.endTime === null) {
		errors.push("Enter valid end time");
	}
	if (task.startTime && task.endTime) {
		if (task?.startTime > task?.endTime) {
			errors.push("Start time should be less than end time");
		}
	}
	if (task.checkinTime) errors.push("Task is in Progress or Completed and Hence cannot be edited");
	if (!task.clientID && !task.routeID && !task.address)
		errors.push("Select at least one client or site or route or location");
	if (!task.taskDescription) errors.push("Enter description");
	if (!task.date || moment(task.date).valueOf < moment().valueOf) errors.push("Choose a date before or after Today");
	if (!task.repeatedTaskMetadata) return errors;
	if (
		!task.repeatedTaskMetadata.endDate ||
		moment(task.repeatedTaskMetadata.endDate).valueOf() < moment(task.date).valueOf()
	)
		errors.push("Enter End date same day or after Start Date");
	return errors;
};
export const validateCustomTasks = (
	task: TASK_TYPE,
	taskType?: string,
	isAdminOverrideFieldPresent?: boolean
): string[] => {
	const errors: string[] = [];
	if (taskType === "routes" || task?.routeID) return errors;
	if (Number.isNaN(task.startTime) || task.startTime === 0 || task.startTime === null) {
		if (task?.adminAssigned !== 0 && !isAdminOverrideFieldPresent) errors.push("Enter valid start time");
	}
	if (Number.isNaN(task.endTime) || task.endTime === 0 || task.endTime === null) {
		if (task?.adminAssigned !== 0 && !isAdminOverrideFieldPresent) errors.push("Enter valid end time");
	}
	if (task.startTime && task.endTime) {
		if (task?.startTime > task?.endTime) {
			errors.push("Start time should be less than end time");
		}
	}
	// is fieldpresent is a flag to edit the task which is completed
	if (task.checkinTime && !isAdminOverrideFieldPresent)
		errors.push("Task is in Progress or Completed and Hence cannot be edited");
	// if (!task.clientID && !task.routeID && !task.address)
	// 	errors.push("Select at least one client or site or route or location");
	// if (!task.taskDescription) errors.push("Enter description");
	if (!task.date || moment(task.date).valueOf < moment().valueOf) errors.push("Choose a date before or after Today");
	if (!task.repeatedTaskMetadata) return errors;
	if (
		!task.repeatedTaskMetadata.endDate ||
		moment(task.repeatedTaskMetadata.endDate).valueOf() < moment(task.date).valueOf()
	)
		errors.push("Enter End date same day or after Start Date");
	return errors;
};

export const getSiteType = (sites: any, siteTypes: any, clientID: any) => {
	const site = sites.find((d: any) => d.clientID === clientID);
	const data = siteTypes.find((d: any) => d.jobTypeID === site?.jobTypeID);
	return { label: data?.jobDescription, value: data?.jobTypeID };
};

export const validateCustomField = (field: CUSTOM_FIELD_METADATA, fields: CUSTOM_FIELD_METADATA[]): string[] => {
	const errors: string[] = [];
	const fixedColumns = [
		"clientName",
		"visibleTo",
		"proprietorName",
		"phoneNumber",
		"address",
		"lat",
		"lng",
		"radius",
		"city",
		"pinCode",
	];
	if (fixedColumns.includes(field.fieldName)) errors.push(`Field name ${field.fieldName} is reserved`);
	if (!field.fieldName) errors.push("Enter field name");
	if (!field.dataType) errors.push("Select Field  Data Type");
	// to avoid user from creating/updating a custom field with same name
	const findExisting = fields.find(
		(f) => f.fieldName.trim().toLowerCase() === field.fieldName.trim().toLowerCase() && f.fieldID !== field.fieldID
	);
	if (findExisting) errors.push("Field name already exists");
	if (["dropDown", "checkbox"]?.includes(field.dataType) && (!field.options || field.options.length === 0))
		errors.push("Enter at least one option value");
	if (field.dataType === "form" && !field.argsOne) {
		errors.push("Please Select Form");
	}
	if (field.dataType === "employee" && !field.argsOne) {
		errors.push("Please Select Employee Option");
	}
	return errors;
};

export const fetchSidebarMetaData = async (): Promise<any> =>
	axios.get(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/admin/subscriptionMetadata`);

export const fetchNotificationCounter = async (
	adminID: number
): Promise<AxiosResponse<{ notifications: `${number}` | null }>> =>
	await axios({
		method: "post",
		url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/chat/getNotificationsCounter`,
		headers: { "content-type": `application/x-www-form-urlencoded; charset=UTF-8` },
		data: `adminID=${adminID}`,
	});

export const WORKING_STATE = Object.freeze({
	["Punched In"]: "punch_in",
	["Punched Out"]: "punch_out",
	["Inactive"]: "in_active",
	["On Leave"]: "on_leave",
	["Weekly Off"]: "not_working",
} as const);

export const ALL_USER_STATUS_COUNT = Object.freeze({
	["Punched In"]: "onlineUsers",
	["Punched Out"]: "offlineUsers",
	["Inactive"]: "inactiveUsers",
	["On Leave"]: "onLeaveUsers",
	["Weekly Off"]: "weeklyOffUsers",
} as const);

// admin entitlements
export const fetchAdminEntitlements = async (): Promise<any> =>
	axios.get(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/admin/entitlements`);

export const fetchCompanySettings = async (): Promise<any> =>
	axios.get(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/admin/companySettings`);

export const mapOptions = {
	fullscreenControl: true,
	mapTypeControl: true,
	streetViewControl: true,
	scaleControl: true,
	zoomControl: true,
	clickableIcons: true,
	gestureHandling: "greedy",
	disableDoubleClickZoom: true,
	mapTypeControlOptions: {
		position: 3, // Set the position to bottom right
	},
	styles: [
		{
			featureType: "poi.business",
			elementType: "labels",
			stylers: [
				{
					visibility: "off",
				},
			],
		},
	],
};
export const mapStyles = [
	{
		featureType: "poi.business",
		stylers: [
			{
				visibility: "on",
			},
		],
	},
	{
		featureType: "transit.station",
		stylers: [
			{
				visibility: "on",
			},
		],
	},
	{
		featureType: "road.highway",
		stylers: [
			{
				visibility: "on",
			},
		],
	},
];

export const sortByString = (a: string, b: string) => {
	const nameA = a.toUpperCase();
	const nameB = b.toUpperCase();
	if (nameA < nameB) {
		return -1;
	}
	if (nameA > nameB) {
		return 1;
	}
	return 0;
};

export const formattedClientData = (client: CLIENTS_DATA) => {
	const newClient = _cloneDeep(client);
	if (newClient.customFieldsComplex && Array.isArray(newClient.customFieldsComplex)) {
		newClient.customFieldsComplex.forEach((field) => {
			delete field.fieldName;
			delete field.selectedOptionsValues;
			return field;
		});
	}
	if (typeof newClient.lat === "string") {
		newClient.lat = parseFloat(newClient.lat);
	}
	if (typeof newClient.lng === "string") {
		newClient.lng = parseFloat(newClient.lng);
	}
	return newClient;
};

export const excelSheetNotes = [
	"#### !!! Warning: Do Not delete this SECTION !!!",
	"#### Warning: Do Not Change/Edit/Delete column names",
	"#### Warning: Do Not Change the RouteID",
	"#### You can rename the Route by overwriting the existing name",
	"#### You can edit the start and end times. Keep the format in HH:mm:ss (24 hour format)",
	"#### You can remove a client/site by simple removing the entire row for the client/site",
	"#### You can add an existing client/site not a part of this route by filling it’s name in the Name column",
	"#### When Adding a new Client/Site, also add type as 'Client' or 'Site' in the Type column",
];

// find the current expense/conveyance AUth
export const findCurrentExpenseConveyanceAuth = (request: EXPENSE_REQUEST_TYPE | CONVEYANCE_TYPE) => {
	const pendingExpenses = request.expenseAuth.filter((expAuth) => expAuth.actionPerformed === -1);
	// if there are multiple pending expense Auth, the throw a error

	if (pendingExpenses.length !== 1) {
		const message = "Backend Error about Auth, Please Contact Support";
		return message;
	}
	return pendingExpenses[0];
};
// find the current expense/conveyance/advance AUth
export const findCurrentAdvanceExpenseConveyanceAuth = (
	request: ADVANCE_REQUEST_TYPE | EXPENSE_REQUEST_TYPE_V1 | CONVEYANCE_TYPE_V1
) => {
	const pendingExpenses = request.authorizationInfo.history.filter((expAuth) => expAuth.actionPerformed === -1);
	// if there are multiple pending advance expense Auth, then throw a error

	if (pendingExpenses.length > 1) {
		const message = "Backend Error about Auth, Please Contact Support";
		return message;
	}
	return pendingExpenses[0];
};
export const checkDateFormat = (date: string | null) => {
	if (date === null) return null;
	const isValidDayJsDate = dayjs.isDayjs(date);

	// Check if the date is already in the correct format
	if (dayjs(date, "DD-MM-YYYY", true).isValid()) {
		// if a dayjs object then convert into string
		if (isValidDayJsDate) {
			return dayjs(date).format("YYYY-MM-DD");
		} else {
			return date;
		}
	} else if (dayjs(date, "YYYY-MM-DD", true).isValid()) {
		if (isValidDayJsDate) {
			return dayjs(date).format("YYYY-MM-DD");
		} else {
			return date;
		}
	}
};
export function convertToInternationalCurrencySystem(labelValue: number, decimals = 0) {
	const trillions = 1e12;
	const billions = 1e9;
	const millions = 1e6;
	const thousands = 1e3;
	const options = { trillion: "T", billion: "B", million: "M", thousand: "K" } as const;

	if (labelValue >= trillions) {
		return (labelValue / trillions).toFixed(decimals) + options.trillion;
	} else if (labelValue >= billions) {
		return (labelValue / billions).toFixed(decimals) + options.billion;
	} else if (labelValue >= millions) {
		return (labelValue / millions).toFixed(decimals) + options.million;
	} else if (labelValue >= thousands) {
		return (labelValue / thousands).toFixed(decimals) + options.thousand;
	} else {
		return labelValue.toFixed(decimals);
	}
}
export const validateCrmTemplateData = (templateData: any, templateType: string) => {
	const errors: string[] = [];
	if (templateType === "custom" && templateData.length === 0) {
		errors.push("Please chose custom field from dropdown.");
		return errors;
	}
	templateData.map((template: any) => {
		if (template.templateID.length === 0 || !template.templateID) {
			errors.push(`Please choose template against the ${template.optionName}.`);
		}
	});
	return errors;
};

export const crmFormatDataCompatibleToBackend = (templateData: any) => {
	const temp = [...templateData];
	temp.map((template: any) => {
		delete template.optionName;
		delete template.unoloEntity;
	});
	return temp;
};

export const crmTemplateFormatDataCompatibleToBackend = (templates: any) => {
	const temp = [...templates];
	temp.map((template: any) => {
		if (template.templateID === "") {
			delete template.templateID;
		}
	});
	return temp;
};
export const validateCRMTemplateName = (templates: NEW_TEMPLATE_DATA[]) => {
	const errors: string[] = [];
	const valueArr = templates.map(function (item) {
		return { templateName: item.templateName };
	});
	valueArr.map((template) => {
		if (template.templateName.length === 0) {
			errors.push(`Please fill template name.`);
			return errors;
		}
	});
	const templateNames = new Set();
	const duplicates = [];

	for (const { templateName } of valueArr) {
		if (templateName === "Default" || templateName === "default") {
			errors.push(`Using ${templateName} name is not allowed`);
			return errors;
		}
		const formattedName = templateName.replace(/\s+/g, "").toLowerCase();
		if (templateNames.has(formattedName)) {
			duplicates.push(templateName);
		} else {
			templateNames.add(formattedName);
		}
	}

	if (duplicates.length > 0) {
		duplicates.map((duplicate) => {
			errors.push(`${duplicate} already exist. Please change the template name.`);
		});
	}

	return errors;
};

export const validateMappingData = (sendDataToBackend: any) => {
	const tempSendDataToBackend = _cloneDeep(sendDataToBackend);
	const newErrors: string[] = [];
	if (tempSendDataToBackend.crmLayoutID === null) {
		newErrors.push(`Please select zoho layout type.`);
	}
	if (!tempSendDataToBackend.templateID) {
		newErrors.push(`Please select template.`);
	}
	tempSendDataToBackend.crmFieldMapping.map((mapping: any) => {
		if (mapping.isSystemMandatory === true && mapping.internalFieldID.length === 0) {
			newErrors.push(`Please map the unolo field against the ${mapping.externalFieldName}.`);
		}
		if (mapping.externalFieldName === "Owner") {
			if (parseInt(tempSendDataToBackend.externalBehaviourID) === 2) {
				if (mapping.externalBehaviourValue === null) {
					newErrors.push(`Please select the owner from "Assign one owner" option.`);
				}
			}
		}
	});

	return newErrors;
};

export const crmFormatMappingDataCompatibleToBackend = (sendDataToBackend: any) => {
	const tempSendDataToBackend = _cloneDeep(sendDataToBackend);
	tempSendDataToBackend.externalBehaviourID = parseInt(tempSendDataToBackend.externalBehaviourID);
	tempSendDataToBackend.crmFieldMapping.map((mapping: any) => {
		delete mapping.isSystemMandatory;
		delete mapping.displayName;
		if (mapping.externalFieldName === "Owner") {
			if (tempSendDataToBackend.externalBehaviourID === LEAD_OWNERS_TYPE.default) {
				mapping.externalBehaviourValue = null;
				mapping.internalFieldID = "Default";
			} else if (tempSendDataToBackend.externalBehaviourID === LEAD_OWNERS_TYPE.multipleOwner) {
				mapping.externalBehaviourValue = null;
				mapping.internalFieldID = "User_Mapping";
			}
		}
	});
	if (tempSendDataToBackend.crmFieldMappingID.length === 0) {
		delete tempSendDataToBackend.crmFieldMappingID;
	}
	return tempSendDataToBackend;
};

export const modifyColumOrder = (componentName: any, params: any, columns: GridColDef[]) => {
	const temp = _.cloneDeep(columns);
	const { field, targetIndex } = params;
	const filteredColumn = temp.filter((column) => column.field !== field);
	const findColumn = temp.find((column) => column.field === field);
	if (findColumn) {
		let destinationIndex = targetIndex - 1;
		if (componentName === DATA_GRID_NAMES.tasks_tasks) {
			// this is the check for those table which has the first column as checkbox
			destinationIndex = targetIndex;
		}
		filteredColumn.splice(destinationIndex, 0, findColumn);
	}
	return filteredColumn;
};
export const modifyColumnWidth = (params: any, columns: GridColDef[]) => {
	const temp = _.cloneDeep(columns);
	const { field, computedWidth } = params.colDef;
	const idx = temp.findIndex((column) => column.field === field);
	if (idx !== -1) {
		temp[idx].width = computedWidth;
	}
	return temp;
};

export const getTheSavedColumns = (getColum: any, dataGridName: string, customizeOptions: string) =>
	getColum?.columnConfigurations?.find((col: any) => col.id === dataGridName)?.[customizeOptions];

export const formatTheDataFromSlice = (columnArray: any, columns: any) => {
	if (!columnArray) return;
	const result = columnArray?.map((col: any) => {
		const column = columns.find((column: any) => column.field === col.field);
		if (column) {
			return { ...column, ...col };
		}
		return col;
	});

	const elementsToBeAdded: any = [];
	const elementsToBeRemoved: any = [];

	if (columns.length > 0 && result.length > 0) {
		columns.forEach((column: any) => {
			if (!result.find((col: any) => col.field === column.field)) {
				elementsToBeAdded.push(column);
			}
		});
	}
	if (result.length > 0 && columns.length > 0) {
		result.forEach((col: any) => {
			if (!columns.find((column: any) => col.field === column.field)) {
				elementsToBeRemoved.push(col);
			}
		});
	}

	if (elementsToBeRemoved.length === 0 && elementsToBeAdded.length === 0) {
		return result;
	}

	const finalResult = result.filter((col: any) => !elementsToBeRemoved.includes(col));
	finalResult.splice(finalResult.length / 2, 0, ...elementsToBeAdded);
	return finalResult;
};

export const validateLatlong = (lat: any, lng: any) => {
	let error = false;
	if (lat) {
		if (+lat > 85 || +lat < -85) {
			error = true;
		}
	}
	if (lng) {
		if (+lng > 180 || +lng < -180) {
			error = true;
		}
	}
	return error;
};

export const isLatLongPresent = (lat: any, lng: any) => {
	let error = "";
	if (!lat || lat.length === 0) {
		error =
			"Please note latitude and longitude is manadatory.  You can either search for the latitude and longitude or enter the correct address.";
	}
	if (!lng || lng.length === 0) {
		error =
			"Please note latitude and longitude is manadatory.  You can either search for the latitude and longitude or enter the correct address.";
	}
	return error;
};

export const checkS3LinkValidity = async (url: string) => {
	try {
		const response = await fetch(process.env.NEXT_PUBLIC_S3_URL + url, {
			method: "HEAD",
			cache: "no-cache",
		});
		if (!response.ok) {
			return false;
		}
	} catch (error) {
		return false;
	}
	return true;
};

export const hideSidebar = () => {
	const sidebar = document.querySelector(".settings-sidebar");
	const setting = document.querySelector(".settings");
	sidebar?.classList.add("d-none");
	setting?.classList.add("settings-full-width");
};

export const showSidebar = () => {
	const sidebar = document.querySelector(".settings-sidebar");
	const setting = document.querySelector(".settings");
	sidebar?.classList.remove("d-none");
	setting?.classList.remove("settings-full-width");
};
export const FORM_REDIRECT_URL =
	process.env.NODE_ENV === "development"
		? "http://65.0.251.69:3000/form/responses"
		: "https://panel.unolo.com/form/responses";
export const BASE_LINK = process.env.NODE_ENV === "development" ? "http://65.0.251.69:3000" : "https://panel.unolo.com";
export const checkImageExists = (url: string) =>
	new Promise((resolve, reject) => {
		fetch(url)
			.then((each) => {
				// Check if the response status is in the range of 200 to 299, indicating success
				if (each.ok) {
					resolve(true);
				} else {
					resolve(false);
				}
			})
			.catch(() => {
				resolve(false);
			});
	});

export const getProfilesIds = (selectedTeams: string[], teamList: any) => {
	const tempArray: string[] = [];
	if (selectedTeams?.includes("all")) {
		return tempArray;
	} else {
		selectedTeams?.forEach((team: string) => {
			teamList?.data?.forEach((teamData: any) => {
				if (teamData?.profileName === team) {
					tempArray.push(teamData?.profileID.toString());
				}
			});
		});
	}
	return tempArray;
};

export const extractAllModulesFromModulesHierarchy = (modulesHierarchy: any) => {
	const result: any = [];

	function traverse(module: any) {
		result.push({
			moduleID: module.moduleID,
			moduleType: module.moduleType,
			moduleName: module.moduleName,
		});

		if (module.linkedModules) {
			module.linkedModules.forEach((linkedModules: any) => {
				traverse(linkedModules);
			});
		}
	}
	traverse(modulesHierarchy);
	return result;
};

// enable showrowcount
export const whetherToEnableRowCount = (reportData: subReports) => {
	if (reportData["columnGroupings"].length >= 1 && reportData.showRowCount === 1) {
		return true;
	} else {
		return false;
	}
};

// enable subtotal
export const whetherToEnableSubTotal = (reportData: subReports) => {
	if (
		reportData["rowGroupings"].length === 0 ||
		(reportData.rowGroupings.length <= 1 && reportData.aggregateFunctions.length === 0) ||
		(reportData.rowGroupings.length > 1 &&
			reportData.columnGroupings.length === 0 &&
			reportData.aggregateFunctions.length === 0)
	) {
		return false;
	} else {
		return true;
	}
};

// enable subtotal
export const whetherToEnableGrandTotal = (reportData: subReports) => {
	if (
		(reportData.rowGroupings.length === 0 && reportData.aggregateFunctions.length === 0) ||
		(reportData.rowGroupings.length >= 1 &&
			reportData.columnGroupings.length === 0 &&
			reportData.aggregateFunctions.length === 0)
	) {
		return false;
	} else {
		return true;
	}
};

// enable the detail rows
export const whetherToEnableTheDetailRows = (reportData: subReports) => {
	if (
		(reportData.showSubTotal === 1 && reportData.detailRows === 1) ||
		(reportData.detailRows === 1 && reportData.showSubTotal === 0) ||
		reportData.showSubTotal === 0
	)
		return true;
	else return false;
};

// whether to enable the splitaggregates
export const whetherToEnableTheSplitAggregates = (reportData: subReports) => {
	if (reportData.columnGroupings.length >= 1 || reportData.aggregateFunctions.length === 0 || reportData.splitAggregates === 0) return false;
	return true;
};
