import { gql } from "@apollo/client";

export const GET_ALL_CLIENTS = gql`
	query GetClients {
		get_clients_by_adminID {
			clientID
			clientName
			lat
			lng
			address
			radius
		}
	}
`;
export const GET_FILTER_OPTIONS = gql`
	query GetFilterOptions($skip: Int, $take: Int, $fieldID: String!, $searchKey: String, $attribute: String) {
		get_clients_for_filter_by_adminID_v2(
			skip: $skip
			take: $take
			fieldID: $fieldID
			searchKey: $searchKey
			attribute: $attribute
		) {
			data
			optionalSearchKey
			count
		}
	}
`;
export const IS_MASKING_LIMIT_REACHED = gql`
	query is_masking_limit_reached {
		is_masking_limit_reached
	}
`;
export const GET_DATA_ON_SELECTIONS = gql`
	query GetDataOnSelection($skip: Int, $take: Int, $filters: [FilterObject]!) {
		get_clients_with_filter_by_adminID_v2(skip: $skip, take: $take, filters: $filters) {
			data {
				clientID
				companyID
				employeeID
				clientName
				description
				email
				canOverride
				lat
				lng
				isDuplicate
				clientCat
				internalClientID
				visibleToProfileID
				timestamp
				photoPath
				phoneNumber
				address
				proprietorName
				radius
				lastModifiedByAdminID
				lastModifiedByEmpID
				createdByAdminID
				createdByEmpID
				createdTs
				lastModifiedTs
				otpVerified
				siteType
				city
				pinCode
				customFieldsComplex {
					fieldID
					fieldValue
					fieldValue2
					fieldValue3
					fieldName
					selectedOptions
					selectedOptionsValues
					selectedFieldValues {
						fieldValue
						fieldValue2
					}
				}
				visibility {
					type
					value
				}
			}
			count
		}
	}
`;

export const GET_CLIENTS = gql`
	query GetClients($skip: Int, $take: Int, $searchKey: String) {
		get_clients_by_adminID_v2(skip: $skip, take: $take, searchKey: $searchKey) {
			data {
				clientID
				companyID
				employeeID
				clientName
				description
				email
				canOverride
				lat
				lng
				clientCat
				internalClientID
				visibleToProfileID
				timestamp
				photoPath
				phoneNumber
				address
				proprietorName
				radius
				lastModifiedByAdminID
				lastModifiedByEmpID
				createdByAdminID
				createdByEmpID
				createdTs
				lastModifiedTs
				siteType
				city
				pinCode
				customFieldsComplex {
					fieldID
					fieldValue
					fieldValue2
					fieldValue3
					fieldName
					selectedOptions
					selectedOptionsValues
					selectedFieldValues {
						fieldValue
						fieldValue2
					}
				}
				visibility {
					type
					value
				}
			}
			count
		}
	}
`;

export const GET_CLIENTS_ON_SEARCH = gql`
	query GetClients($skip: Int, $take: Int, $searchKey: String) {
		get_clients_by_adminID_and_searchKey(skip: $skip, take: $take, searchKey: $searchKey) {
			data {
				clientID
				companyID
				employeeID
				clientName
				description
				email
				canOverride
				lat
				lng
				clientCat
				internalClientID
				visibleToProfileID
				timestamp
				photoPath
				phoneNumber
				address
				proprietorName
				radius
				isDuplicate
				lastModifiedByAdminID
				lastModifiedByEmpID
				createdByAdminID
				createdByEmpID
				createdTs
				lastModifiedTs
				siteType
				city
				pinCode
				otpVerified
				customFieldsComplex {
					fieldID
					fieldValue
					fieldValue2
					fieldValue3
					fieldName
					selectedOptions
					selectedOptionsValues
					selectedFieldValues {
						fieldValue
						fieldValue2
					}
				}
				visibility {
					type
					value
				}
			}
			count
		}
	}
`;

export const GET_CLIENT_BY_ID = gql`
	query GetClientById($id: ID!) {
		get_client(clientID: $id) {
			clientID
			companyID
			canOverride
			employeeID
			clientName
			lat
			lng
			clientCat
			internalClientID
			visibleToProfileID
			description
			email
			timestamp
			photoPath
			phoneNumber
			address
			proprietorName
			radius
			lastModifiedTs
			createdTs
			siteType
			city
			pinCode
			otpVerified
			customFields {
				name
				value
			}
			customFieldsComplex {
				fieldID
				fieldValue
				fieldValue2
				fieldValue3
				fieldName
				selectedOptions
				selectedOptionsValues
				selectedFieldValues {
					fieldValue
					fieldValue2
				}
			}
			visibility {
				type
				value
			}
		}
	}
`;

export const GET_CLIENTS_FILTERED = gql`
	query GetClients($searchKey: String, $searchColumn: String) {
		get_clients_by_adminID(searchKey: $searchKey, searchColumn: $searchColumn) {
			clientID
			canOverride
			companyID
			employeeID
			clientName
			lat
			lng
			origLat
			origLon
			clientCat
			internalClientID
			visibleToProfileID
			description
			timestamp
			photoPath
			phoneNumber
			description
			address
			proprietorName
			radius
			email
			lastModifiedTs
			lastModifiedByEmpID
			lastModifiedByAdminID
			lastModifiedBySourceID
			createdTs
			jobTypeID
			siteType
			city
			pinCode
			polyline
			customFields {
				name
				value
			}
			customFieldsComplex {
				fieldID
				fieldValue
				fieldValue2
				fieldValue3
				fieldName
				selectedOptions
				selectedOptionsValues
				selectedFieldValues {
					fieldValue
					fieldValue2
				}
			}
		}
	}
`;
export const UPSERT_CLIENTS = gql`
	mutation UpsertClientBatch($input: [ClientInput!]!) {
		upsert_client_batch(input: $input) {
			failed
		}
	}
`;
export const DELETE_CLIENTS = gql`
	mutation DeleteClient($clientIDs: [ID!]!) {
		delete_client_batch(clientIDs: $clientIDs) {
			failed
		}
	}
`;

export const GET_ALL_SITES = gql`
	query GetSites {
		get_sites {
			clientID
			clientName
			lat
			lng
			address
		}
	}
`;

export const UPSERT_AND_DELETE_CLIENTS = gql`
	mutation UpsertAndDeleteClients($toUpsert: [ClientInput]!, $toDelete: [ID]!, $uploadFlag: Boolean) {
		upsert_and_delete_client_batch(toUpsert: $toUpsert, toDelete: $toDelete, uploadFlag: $uploadFlag) {
			upsertFailed
			deleteFailed
		}
	}
`;

export const GET_CLIENTS_BY_EMPLOYEE_ID = gql`
	query GetClientsByEmployeeID($employeeID: Int!) {
		get_clients_by_employeeID(employeeID: $employeeID) {
			clientID
			clientName
			lat
			lng
			address
			visibility {
				type
				value
			}
		}
	}
`;
export const GET_CLIENT_DIFF = gql`
	query GetClientDiff($clientID: ID!) {
		get_client_diff(clientID: $clientID) {
			lastModifiedTs
			lastModifiedBy
			diff {
				simple {
					updated {
						key
						from
						to
					}
					added {
						key
						value
					}
					removed {
						key
						value
					}
				}
				complex {
					key
					added {
						key
						value
					}
					updated {
						key
						type
						added {
							key
							value
						}
						removed {
							key
							value
						}
						updated {
							key
							from
							to
						}
					}
					removed {
						key
						value
					}
				}
			}
		}
	}
`;

export const GET_TASKS_BY_CLIENT_ID = gql`
	query GetTasksByClientID($clientID: ID!, $startDate: Date!, $endDate: Date!) {
		get_tasks_by_clientID(clientID: $clientID, startDate: $startDate, endDate: $endDate) {
			taskID
			clientID
			date
			taskDescription
			userInfo {
				firstName
				lastName
			}
			adminAssigned
			checkinTime
			checkoutTime
			startTime
			endTime
			lastUpdatedAdminID
			createdTs
			lastModifiedByAdminID
			lastModifiedByEmpID
			lastModifiedTs
			createdByAdminID
			createdByEmpID
			customEntity {
				customEntityName
				customEntityID
				active
			}
			customFieldsComplex {
				fieldID
				fieldValue
				fieldValue2
				fieldValue3
				selectedOptions
				selectedFieldValues {
					fieldValue
					fieldValue2
				}
			}
			taskDiffHistory {
				lastModifiedTs
				lastModifiedBy
				diff {
					simple {
						updated {
							key
							from
							to
						}
						added {
							key
							value
						}
						removed {
							key
							value
						}
					}
					complex {
						key
						added {
							key
							value
						}
						updated {
							key
							type
							added {
								key
								value
							}
							removed {
								key
								value
							}
							updated {
								key
								from
								to
							}
						}
						removed {
							key
							value
						}
					}
				}
			}
		}
	}
`;

export const GET_ORDERS_BY_CLIENT_ID = gql`
	query GetOrdersByClientID($clientID: ID!, $startDate: Date!, $endDate: Date!) {
		get_orders_by_clientID(clientID: $clientID, startDate: $startDate, endDate: $endDate) {
			orderID
			processingDate
			invoiceNumber
			clientID
			client {
				clientName
			}
			orderItems {
				skuID
				sku {
					skuID
					profileID
					companyID
					skuName
					skuDescription
					internalSkuID
					active
					category
					listPrice
					gstRate
					discRate
					skuImage1
					skuImage2
					skuImage3
					lastModifiedTs
					createdTs
				}
				productDescription
				quantity
				price
				discAmt
				amount
				totalTaxAmount
			}
			orderValue
			taxValue
			userInfo {
				firstName
				lastName
			}
			customFieldsComplex {
				fieldValue
				fieldName
				selectedOptionsValues
			}
			createdTs
			lastModifiedTs
			createdByEmpID
			lastModifiedByEmpID
			lastModifiedByAdminID
			orderDiffHistory {
				lastModifiedTs
				lastModifiedBy
				diff {
					simple {
						updated {
							key
							from
							to
						}
						added {
							key
							value
						}
						removed {
							key
							value
						}
					}
					complex {
						key
						added {
							key
							value
						}
						updated {
							key
							type
							added {
								key
								value
							}
							removed {
								key
								value
							}
							updated {
								key
								from
								to
							}
						}
						removed {
							key
							value
						}
					}
				}
			}
		}
	}
`;
