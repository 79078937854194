import moment from "moment";
import "moment-timezone";
import "lodash";
import { EXECUTIVE_USER, TIMELINE } from "../../../@types";
import { DEFAULT_TIMEZONE, TIME_SHOW_FORMAT } from "../../../constants";
import useAdminInfo from "../../useAdminInfo";
import {
	ADD_TIME_STAMP_GENERIC,
	DisplayForm,
	DisplayJourneyInfo,
	DisplayPhotosInsideVisit,
	EventInsideTask,
	HaltDisplayInfo,
	TasksInsideVisit,
} from "./TimelineUtils";
import _ from "lodash";
type PARTIAL_USER = Pick<EXECUTIVE_USER, "employeeID" | "firstName" | "lastName" | "parentAdminID" | "internalEmpID">;

type Props = {
	visit: TIMELINE["visits"][number];
	active: boolean;
	onClickHandler: () => void;
	previousTravel: number | null;
	timeLineData: TIMELINE;
	selectedEmployee: PARTIAL_USER | null;
	subsMetadata: any;
};
const Visit = ({
	visit,
	active,
	onClickHandler,
	previousTravel,
	timeLineData,
	selectedEmployee,
	subsMetadata,
}: Props) => {
	const { tz, companyID } = useAdminInfo();
	const startTime = moment(visit.startTime)
		.tz(tz || DEFAULT_TIMEZONE)
		.format(TIME_SHOW_FORMAT);
	const tasks: ADD_TIME_STAMP_GENERIC<TIMELINE["visits"][number]["tasks"], "TASK"> =
		visit.tasks && Array.isArray(visit.tasks)
			? visit.tasks.map((t) => ({ ...t, timestamp: t.checkin!, type: "TASK" }))
			: [];
	const forms: ADD_TIME_STAMP_GENERIC<TIMELINE["visits"][number]["forms"], "FORM"> =
		visit.forms && Array.isArray(visit.forms) ? visit.forms.map((t) => ({ ...t, type: "FORM" })) : [];
	const photos: ADD_TIME_STAMP_GENERIC<TIMELINE["visits"][number]["evidence"], "PHOTO"> =
		visit.evidence && Array.isArray(visit.evidence) ? visit.evidence.map((t) => ({ ...t, type: "PHOTO" })) : [];
	const events: ADD_TIME_STAMP_GENERIC<TIMELINE["visits"][number]["events"], "EVENT"> =
		visit.events && Array.isArray(visit.events)
			? visit.events.map((e) => ({
				...e,
				timestamp: moment(e.ts)
					.tz(tz || DEFAULT_TIMEZONE)
					.valueOf(),
				type: "EVENT",
			}))
			: [];
	const event = events
		.filter((e) => e.eventTypeID === 8 || e.eventTypeID === 9)
		.sort((a, b) => a.timestamp - b.timestamp)?.[0];
	let visitHeading = "Halt";
	if (event) {
		visitHeading = event.eventTypeID === 8 ? "Punch In" : "Halt";
	}

	const allHalts = [...tasks, ...forms, ...photos, ...events].sort((a, b) => a.timestamp - b.timestamp);
	if (visit.missing === 1) {
		const getString = () => {
			const ConCatArr = visit?.reasons?.map((obj) => {
				const arr = [...obj.reason];
				const uniqArr = _.uniq(arr).join(", ");
				return uniqArr;
			});

			const str = _.uniq(ConCatArr).join(", ");
			return str;
		};
		return (
			<div className="halt no_data_halt">
				<DisplayJourneyInfo startTime={startTime} duration={visit.duration} />
				<HaltDisplayInfo name="Missing Location Data" batteryLevel={visit.batteryLevel} address={visit.locality}>
					{previousTravel ? (
						<address>Travel: {previousTravel.toFixed(2)} km</address>
					) : visit?.travel?.distance ? (
						<p
							style={{
								fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
								fontSize: "12px",
								margin: "5px 0 0 0",
								whiteSpace: "break-spaces",
							}}>
							<span style={{ color: "gray", fontSize: "12px" }}>Travel:{`  `}</span>
							<b>{visit?.travel?.distance?.toFixed(2)} KM</b>
						</p>
					) : null}
					{visit.missing && visit?.reasons && visit?.reasons?.length > 0 && (
						<address style={{ margin: "5px 0" }}>Reason: {getString()}</address>
					)}
					<div className="all_event_container">
						{visit.events
							?.filter(
								(e: any) =>
									e.eventTypeID === 13 ||
									e.eventTypeID === 8 ||
									e.eventTypeID === 9 ||
									e.eventTypeID === 15 ||
									e.eventTypeID === 25 ||
									e.eventTypeID === 3 ||
									(companyID === 14193 && (e.eventTypeID === 14 || e.eventTypeID === 35))
							)
							.map((event: any, index: number) => (
								<div className="event_container" key={index}>
									<EventInsideTask
										key={event.eventTypeID + "-" + event.timestamp}
										eventTypeID={event.eventTypeID}
										eventParam={event.eventParam}
										ts={event.timestamp}
										photoURL={event.photoURL}
									/>
								</div>
							))}
					</div>
				</HaltDisplayInfo>
			</div>
		);
	}
	return (
		<div
			className={`halt ${visitHeading} ${active ? "active" : ""}`}
			onClick={() => onClickHandler()}
			aria-hidden="true">
			<DisplayJourneyInfo startTime={startTime} duration={visit.duration} />
			<HaltDisplayInfo name={visitHeading} batteryLevel={visit.batteryLevel} address={visit.locality}>
				<div className="action_in_visits">
					{allHalts.map((halt) => {
						if (halt.type === "TASK") {
							if (halt.photoID && halt.photoID.length > 0) {
								if (halt.isSelfie === 1) return <></>;
								// @ts-expect-error error here because sometimes there is photo inside task and sometimes not
								return <DisplayPhotosInsideVisit key={halt.photoID} photos={[halt]} timeLineData={timeLineData} />;
							}
							if (halt.formID && halt.formID.length > 0) {
								// @ts-expect-error error here because sometimes there is form inside task and sometimes not
								return <DisplayForm forms={[halt]} key={halt.formTemplateID} selectedEmployee={selectedEmployee} />;
							}
							return (
								<TasksInsideVisit
									key={halt.taskID}
									task={halt}
									timeLineData={timeLineData}
									selectedEmployee={selectedEmployee}
								/>
							);
						}
						if (halt.type === "FORM")
							return <DisplayForm forms={[halt]} key={halt.formTemplateID} selectedEmployee={selectedEmployee} />;
						if (halt.type === "PHOTO")
							return <DisplayPhotosInsideVisit key={halt.photoID} photos={[halt]} timeLineData={timeLineData} />;
						if (halt.type === "EVENT")
							return (
								<>
									<EventInsideTask
										key={halt.eventTypeID + "-" + halt.timestamp}
										eventTypeID={halt.eventTypeID}
										eventParam={halt.eventParam}
										ts={halt.timestamp}
										photoURL={halt.photoURL}
										skip={halt.skip}
										skipReason={halt.skipReason}
									/>
								</>

							);
					})}
				</div>
			</HaltDisplayInfo>
		</div>
	);
};

export default Visit;
