import { Checkbox, FormControlLabel } from "@mui/material";
import { debounce } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { useDispatch } from "react-redux";
import { EXPENSE_SETTING } from "../../../@types";
import { upsertExpenseApproval } from "../../../api/settings";
import { APP_DISPATCH } from "../../../redux";
import { showNotification } from "../../../redux/slices/notificationSlice";
import useAdminEntitlements from "../../useAdminEntitlements";
import ReadOnlyButton from "../../common/ReadOnlyButton";

type Props = {
	expense: EXPENSE_SETTING | null;
	setExpense: Dispatch<SetStateAction<EXPENSE_SETTING | null>>;
	roleID: string;
};
const ExpenseCustomFields = ({ expense, setExpense, roleID }: Props) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const userWritePermission = useAdminEntitlements("settings:expense", "write");

	const questionOne = expense?.expenseOptions?.expenseQuestionOne;
	const questionTwo = expense?.expenseOptions?.expenseQuestionTwo;

	const updateCustomField = async (key: "expenseQuestionOne" | "expenseQuestionTwo", value: string | null) => {
		if (!expense) return;
		const tempExpense = { ...expense };
		tempExpense.expenseOptions[key] = value;
		const { success, message } = await upsertExpenseApproval(
			tempExpense.expenseOptions.usesAdminHierarchy,
			tempExpense.expenseOptions.approverOne,
			tempExpense.expenseOptions.approverTwo,
			tempExpense.expenseOptions.approverThree,
			tempExpense.expenseOptions.backUpApprover1,
			tempExpense.expenseOptions.backUpApprover2,
			tempExpense.expenseOptions.restrictedAdminCount,
			tempExpense.expenseOptions.expenseQuestionOne,
			tempExpense.expenseOptions.expenseQuestionTwo
		);
		dispatch(showNotification({ message: message, severity: success ? "success" : "error" }));
		if (success) setExpense(tempExpense);
	};

	const debouncedChanges = debounce(async function (key: "expenseQuestionOne" | "expenseQuestionTwo", value: string) {
		updateCustomField(key, value);
	}, 500);

	return (
		<div className="custom-field-settings" style={{ marginTop: 20 }}>
			<div className="custom-field-top">
				<div className="section">
					<div className="section-part">
						<p className="title">Expense Custom Fields</p>
						{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="settings"/> : null}
					</div>
					<p className="subtitle">
						Define custom fields for expense.
					</p>
				</div>
			</div>
			<div className="approval_methods" style={{ marginTop: 10 }}>
				<FormControlLabel
					value="bottom"
					control={
						<Checkbox
							size="small"
							checked={questionOne !== null ? true : false}
							onClick={() => updateCustomField("expenseQuestionOne", questionOne === null ? "" : null)}
							disabled={!userWritePermission}
						/>
					}
					label="Custom Field 1"
				/>
				{questionOne !== null ? (
					<input
						disabled={!userWritePermission}
						name="expenseQuestionOne"
						defaultValue={questionOne}
						min={0}
						onChange={(e) => debouncedChanges("expenseQuestionOne", e.target.value)}
						placeholder="Enter Custom Field 1"
					/>
				) : null}
			</div>
			<div className="approval_methods" style={{ marginTop: 10 }}>
				<FormControlLabel
					value="bottom"
					control={
						<Checkbox
							size="small"
							checked={questionTwo !== null ? true : false}
							onClick={() => updateCustomField("expenseQuestionTwo", questionTwo === null ? "" : null)}
							disabled={!userWritePermission}
						/>
					}
					label="Custom Field 2"
				/>
				{questionTwo !== null ? (
					<input
						disabled={!userWritePermission}
						name="expenseQuestionTwo"
						defaultValue={questionTwo}
						min={0}
						onChange={(e) => debouncedChanges("expenseQuestionTwo", e.target.value)}
						placeholder="Enter Custom Field 2"
					/>
				) : null}
			</div>
		</div>
	);
};

export default ExpenseCustomFields;
