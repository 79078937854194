// react
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
// mui
import {
	DataGridPro,
	GridColDef,
	GridRowParams,
	GridSelectionModel,
	GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import DownloadIcon from "@mui/icons-material/Download";
// library
import _cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { useRouter } from "next/router";
import { DATA_GRID_CUSTOMIZATION_OPTIONS, DATA_GRID_NAMES, DG_STYLES } from "../../../constants";
import { DELETE_CLIENTS } from "../../../schema";
// components
import { AccordionData } from "../../../pages/clients_sites/clients";
import ColumnEmployee from "../../common/datagrid/ColumnEmployee";
import ColumnModifierSidebar from "../../common/datagrid/ColumnModifierSidebar";
import CustomColumnMenu from "../../common/datagrid/CustomColumnMenu";
import RightSidebar from "../../common/RightSidebar";
// utils
import {
	FORM_REDIRECT_URL,
	clientsCustomColumn,
	formatTheDataFromSlice,
	getTheSavedColumns,
	modifyColumOrder,
	modifyColumnWidth,
} from "../../../utils";
import useAdminEntitlements from "../../useAdminEntitlements";
// query
import useFetch from "../../useFetch";
import {
	CLIENTS_DATA,
	CLIENT_CATEGORIES,
	CUSTOM_FIELD_COMPLEX_TYPE,
	ENTITY_METADATA,
	USERS_DATA,
} from "../../../@types";
// redux
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { setColumnConfigurations, setColumnSortModel, setPinnedColumns } from "../../../redux/slices/dataGridSlice";
import DataGridActionButtons from "./DataGridActionButtons";
interface CustomGridColDef extends GridColDef {
	maskField: any; // replace 'any' with your custom property type
}
type VARIABLES = {
	skip: number;
	take: number;
	searchKey: string;
};
type Props = {
	clients: CLIENTS_DATA[];
	loading: boolean;
	entityMetadata: ENTITY_METADATA[];
	allClientsCount: any;
	setVariables: Dispatch<SetStateAction<VARIABLES>>;
	setSelectedRows: Dispatch<SetStateAction<GridSelectionModel>>;
	variables: VARIABLES;
	usersData: USERS_DATA[];
	filterCount: number;
	setParameters: Dispatch<
		React.SetStateAction<{
			skip: number;
			take: number;
			filters: AccordionData[];
		}>
	>;
	searchTerm: any;
	page: number;
	setPage: Dispatch<SetStateAction<number>>;
	pageSize: number;
	setPageSize: Dispatch<SetStateAction<number>>;
	getAllClients: () => void;
	clientCategories: CLIENT_CATEGORIES[];
};

const cacheBuster = Date.now();

const tempColumns: CustomGridColDef[] = [
	{
		field: "clientName",
		headerName: "Client Name",
		width: 220,
		maskField: false,
		renderCell(params) {
			const clientName = params?.row?.clientName;
			let email = params?.row?.email;
			if (this.maskField) {
				email = "**********";
			}
			return (
				<ColumnEmployee
					name={clientName}
					emailID={email}
					avatar={
						process.env.NEXT_PUBLIC_S3_URL +
						"clientPhotos/" +
						params?.row?.companyID +
						"/" +
						params?.row?.clientID +
						".jpg" +
						`?cache=${cacheBuster}`
					}
					clientID={params.row?.clientID}
					isDuplicate={params.row.isDuplicate}
				/>
			);
		},
	},
	{
		field: "visibleTo",
		headerName: "Visible to",
		maskField: false,
		width: 150,
	},
	{
		field: "address",
		headerName: "Address",
		width: 200,
		maskField: false,
		renderCell(params) {
			let address = params?.row?.address;
			if (this.maskField) {
				address = "****";
			}
			return <>{address}</>;
		},
	},
	{
		field: "lat",
		headerName: "Latitude",
		width: 120,
		maskField: false,
		sortable: false,
		renderCell(params) {
			let lat = params?.row?.lat;
			if (this.maskField) {
				lat = "****";
			}
			return <>{lat}</>;
		},
	},
	{
		field: "lng",
		headerName: "Longitude",
		width: 120,
		maskField: false,
		sortable: false,
		renderCell(params) {
			let lng = params?.row?.lng;
			if (this.maskField) {
				lng = "****";
			}
			return <>{lng}</>;
		},
	},
	{
		field: "radius",
		headerName: "Radius(m)",
		maskField: false,
		width: 100,
	},
	{
		field: "city",
		headerName: "City",
		maskField: false,
		width: 150,
		renderCell(params) {
			let city = params?.row?.city;
			if (this.maskField) {
				city = "****";
			}
			return <>{city}</>;
		},
	},
	{
		field: "pinCode",
		headerName: "Pincode",
		maskField: false,
		width: 100,
		renderCell(params) {
			let pinCode = params?.row?.pinCode;
			if (this.maskField) {
				pinCode = "****";
			}
			return <>{pinCode}</>;
		},
	},
	{
		field: "description",
		headerName: "Description",
		maskField: false,
		width: 150,
		sortable: false,
		renderCell(params) {
			let description = params?.row?.description;
			if (this.maskField) {
				description = "****";
			}
			return <>{description}</>;
		},
	},
	{
		field: "email",
		headerName: "Email",
		maskField: false,
		width: 150,
		renderCell(params) {
			let email = params?.row?.email;
			if (this.maskField) {
				email = "**********";
			}
			return <>{email}</>;
		},
	},
	{
		field: "internalClientID",
		headerName: "Client ID",
		maskField: false,
		width: 150,
		renderCell(params) {
			let internalClientID = params?.row?.internalClientID;
			if (this.maskField) {
				internalClientID = "****";
			}
			return <>{internalClientID}</>;
		},
	},
];
const ClientsTable = ({
	clients,
	loading,
	variables,
	allClientsCount,
	setVariables,
	entityMetadata,
	setSelectedRows,
	usersData,
	filterCount,
	setParameters,
	searchTerm,
	page,
	setPage,
	pageSize,
	setPageSize,
	getAllClients,
	clientCategories,
}: Props) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const userWritePermission = useAdminEntitlements("clientsAndSites:clients", "write");
	const { data: dataAdmins } = useFetch<any>("/admin/pullAdmins");
	const [columns, setColumns] = useState<CustomGridColDef[]>([]);
	const [deleteClients] = useMutation(DELETE_CLIENTS);
	const [expandColumn, setExpandColumn] = useState(false);
	const router = useRouter();
	const clientsRef = useRef(clients);
	const pageChangeHandler = (newPage: number) => {
		setPage(newPage);
		setVariables((prev) => ({ ...prev, take: (newPage + 1) * pageSize }));
		setParameters((prev) => ({ ...prev, take: (newPage + 1) * pageSize }));
	};
	const pageSizeChangeHandler = (newPageSize: number) => {
		setPageSize(newPageSize);
		setVariables((v) => ({ ...v, take: newPageSize * (page + 1) }));
		setParameters((prev) => ({ ...prev, take: newPageSize * (page + 1) }));
	};

	/* below two functionalities are when you apply filter*/
	const pageChangeHandlerByFilter = (newPage: number) => {
		if (searchTerm.current) {
			searchTerm.current.value = "";
		}
		setPage(newPage);
		setParameters((prev) => ({ ...prev, take: (newPage + 1) * pageSize }));
	};
	const pageSizeChangeHandlerByFilter = (newPageSize: number) => {
		if (searchTerm.current) {
			searchTerm.current.value = "";
		}
		setParameters((prev) => ({ ...prev, take: newPageSize * (page + 1) }));
		setPageSize(newPageSize);
	};

	const deleteClient = async (id: string) => {
		const clientIndex = clientsRef.current.findIndex((client) => client.clientID === id);
		if (!id || id.length === 0 || clientIndex < 0) return;
		await deleteClients({ variables: { clientIDs: [id] } });
		dispatch(
			showNotification({
				message: `${clientsRef.current[clientIndex].clientName} was deleted successfully.`,
				severity: "success",
			})
		);
		getAllClients();
	};

	const isColumnMasked = (fieldName: string, entityMetadata: ENTITY_METADATA[]) => {
		const entity = entityMetadata.find((value) => value.fieldName === fieldName);
		return entity?.maskField;
	};

	const checkIfAllValuesPresent = () => {
		const newColumns = _cloneDeep(tempColumns);

		// maskField checks for some fixed columns transform starts here

		// masking email
		const emailMasked = entityMetadata?.find((value) => value.fieldName === "Email" && value.active === 1);
		if (emailMasked) {
			const emailIndex = newColumns.findIndex((col) => col.field === "email");
			newColumns[emailIndex].maskField = emailMasked.maskField;
			const clientNameIndex = newColumns.findIndex((col) => col.field === "clientName");
			newColumns[clientNameIndex].maskField = emailMasked.maskField;
		}

		// masking address, lat, lng
		const isAddressMasked = entityMetadata?.find((value) => value.fieldName === "Address" && value.active === 1);
		if (isAddressMasked) {
			const addressIndex = newColumns.findIndex((col) => col.field === "address");
			const latIndex = newColumns.findIndex((col) => col.field === "lat");
			const longIndex = newColumns.findIndex((col) => col.field === "lng");
			newColumns[addressIndex].maskField = isAddressMasked.maskField;
			newColumns[latIndex].maskField = isAddressMasked.maskField;
			newColumns[longIndex].maskField = isAddressMasked.maskField;
		}

		// masking city and pincode
		const cityMasked = entityMetadata?.find((value) => value.fieldName === "City" && value.active === 1);
		if (cityMasked) {
			const cityIndex = newColumns.findIndex((col) => col.field === "city");
			newColumns[cityIndex].maskField = cityMasked.maskField;
		}
		const pincodeMasked = entityMetadata?.find((value) => value.fieldName === "Pincode" && value.active === 1);
		if (pincodeMasked) {
			const pincodeIndex = newColumns.findIndex((col) => col.field === "pinCode");
			newColumns[pincodeIndex].maskField = pincodeMasked.maskField;
		}

		// masking description
		const descriptionMasked = entityMetadata?.find((value) => value.fieldName === "Description" && value.active === 1);
		if (descriptionMasked) {
			const descriptionIndex = newColumns.findIndex((col) => col.field === "description");
			newColumns[descriptionIndex].maskField = descriptionMasked.maskField;
		}

		// masking clientID
		const clientIDMasked = entityMetadata?.find((value) => value.fieldName === "Client ID" && value.active === 1);
		if (clientIDMasked) {
			const clientIDIndex = newColumns.findIndex((col) => col.field === "internalClientID");
			newColumns[clientIDIndex].maskField = clientIDMasked.maskField;
		}

		// maskField checks for some fixed columns transform ends here

		const fixedColumns = newColumns.map((column) => column.headerName);
		const missingEntities = entityMetadata?.filter(
			(value) => !fixedColumns?.includes(value.fieldName) && value.isMandatory === 0 && value.active === 1
		);
		const primaryContactField = entityMetadata?.find(
			(value) =>
				value.fieldName === "Contact" && value.dataType === "contact" && value.active === 1 && value.isMandatory === 1
		);
		if (primaryContactField) {
			newColumns.splice(1, 0, {
				field: "Contact Name",
				headerName: "Contact Name",
				flex: 1,
				minWidth: 250,
				maskField: primaryContactField.maskField,
				// render custom component
				renderCell: (params: GridValueGetterParams) => {
					const contact = params.row?.customFieldsComplex?.find(
						(field: any) => field.fieldID === primaryContactField.fieldID
					);
					if (primaryContactField.maskField) {
						return <>{contact ? "*******" : "-"}</>;
					}

					return <>{contact ? `${contact?.fieldValue}` : "-"}</>;
				},
			});
			newColumns.splice(2, 0, {
				field: "Contact Number",
				headerName: "Contact Number",
				flex: 1,
				minWidth: 250,
				maskField: primaryContactField.maskField,
				// render custom component
				renderCell: (params: GridValueGetterParams) => {
					const contact = params.row?.customFieldsComplex?.find(
						(field: any) => field.fieldID === primaryContactField.fieldID
					);
					if (primaryContactField.maskField) {
						return <>{contact ? "****" : "-"}</>;
					}
					return (
						<>
							{contact && contact?.fieldValue2 && contact?.fieldValue3
								? `+${contact?.fieldValue2} ${contact?.fieldValue3}`
								: "-"}
						</>
					);
				},
			});
		}

		missingEntities.forEach((entity) => {
			if (entity.isDefault !== 1) {
				if (entity.dataType === "contact") {
					newColumns.push({
						field: entity.fieldName + " Name",
						headerName: entity.fieldName + " Name",
						flex: 1,
						minWidth: 250,
						maskField: false,
						// render custom component
						renderCell: (params: GridValueGetterParams) => {
							const contact = params.row?.customFieldsComplex?.find((field: any) => field.fieldID === entity.fieldID);
							if (isColumnMasked(entity.fieldName, entityMetadata)) {
								return <span>****</span>;
							}

							return (
								<>
									{contact ? (
										<>
											<span style={{ fontSize: "14px" }}>{contact?.fieldValue}</span>
										</>
									) : (
										"-"
									)}
								</>
							);
						},
					});

					newColumns.push({
						field: entity.fieldName + " Number",
						headerName: entity.fieldName + " Number",
						flex: 1,
						minWidth: 250,
						maskField: false,
						// render custom component
						renderCell: (params: GridValueGetterParams) => {
							const contact = params.row?.customFieldsComplex?.find((field: any) => field.fieldID === entity.fieldID);
							if (isColumnMasked(entity.fieldName, entityMetadata)) {
								return <span>****</span>;
							}
							return (
								<>
									{contact
										? contact && contact?.fieldValue2 && contact?.fieldValue3
											? `+${contact?.fieldValue2} ${contact?.fieldValue3}`
											: "-"
										: "-"}
								</>
							);
						},
					});
				} else if (entity.dataType === "photo") {
					newColumns.push({
						field: entity.fieldName,
						headerName: entity.fieldName,
						flex: 1,
						minWidth: 200,
						maskField: isColumnMasked(entity.fieldName, entityMetadata),
						renderCell: (params: GridValueGetterParams) => {
							const photos = params.row?.customFieldsComplex?.find(
								(field: any) => field.fieldID === entity.fieldID
							)?.selectedFieldValues;
							if (isColumnMasked(entity.fieldName, entityMetadata)) {
								return <span>****</span>;
							}
							return (
								<div style={{ display: "flex", gap: "0.5rem" }}>
									{photos?.length > 0
										? photos.map((photo: any) => (
												// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
												<img
													src={photo.fieldValue}
													key={photo.fieldValue}
													alt=""
													style={{
														flex: 1,
														height: "50px",
														width: "50px",
														objectFit: "cover",
														objectPosition: "center",
														border: "1px solid gray",
														borderRadius: "5px",
														cursor: "pointer",
													}}
													onClick={(e) => {
														e.stopPropagation();
														window.open(photo.fieldValue, "_blank");
													}}
												/>
										  ))
										: "-"}
								</div>
							);
						},
					});
				} else if (entity.dataType === "file") {
					newColumns.push({
						field: entity.fieldName,
						headerName: entity.fieldName,
						flex: 1,
						minWidth: 250,
						maskField: false,
						renderCell: (params: GridValueGetterParams) => {
							const photos = params.row?.customFieldsComplex?.find(
								(field: any) => field.fieldID === entity.fieldID
							)?.selectedFieldValues;
							if (isColumnMasked(entity.fieldName, entityMetadata)) {
								return <span>****</span>;
							}
							return (
								<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "0.3rem" }}>
									{photos?.length > 0
										? photos.map((photo: any) => (
												// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react/jsx-key
												<div
													style={{
														flex: 1,
														cursor: "pointer",
														padding: "0px 5px",
														border: "1px solid gray",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														gap: "0.2rem",
														fontSize: "10px",
														borderRadius: "30px",
													}}
													onClick={(e) => {
														e.stopPropagation();
														window.open(photo.fieldValue, "_blank");
													}}>
													<DownloadIcon style={{ fontSize: "18px" }} />
													{photo.fieldValue2}
												</div>
										  ))
										: "-"}
								</div>
							);
						},
					});
				} else if (entity.dataType === "form") {
					newColumns.push({
						field: entity.fieldName,
						headerName: entity.fieldName,
						flex: 1,
						minWidth: 200,
						maskField: false,
						renderCell: (params: GridValueGetterParams) => {
							const selectedField: CUSTOM_FIELD_COMPLEX_TYPE | null | undefined =
								params.row?.customFieldsComplex && Array.isArray(params.row?.customFieldsComplex)
									? params.row?.customFieldsComplex.find(
											(field: CUSTOM_FIELD_COMPLEX_TYPE) => field?.fieldID === entity?.fieldID
									  )
									: null;
							if (isColumnMasked(entity.fieldName, entityMetadata)) {
								return <span>****</span>;
							}
							if (selectedField === null || selectedField === undefined) return <>-</>;
							if (!selectedField.fieldValue || !selectedField.fieldValue2) return <>-</>;

							return (
								<a
									href={`${FORM_REDIRECT_URL}/${selectedField?.fieldValue}?createdTs=${selectedField?.fieldValue2}`}
									target="_blank"
									rel="noreferrer">
									Form Link
								</a>
							);
						},
					});
				} else
					newColumns.push({
						field: entity.fieldName,
						headerName: entity.fieldName,
						flex: 1,
						minWidth: 200,
						maskField: false,
						renderCell: (params: GridValueGetterParams) => {
							if (isColumnMasked(entity.fieldName, entityMetadata)) {
								return <span>****</span>;
							}

							return clientsCustomColumn(params.row?.customFieldsComplex, entity);
						},
					});
			}
		});
		const lastModifiedByAdminID = columns.find((col) => col.field === "lastModifiedByAdminID");
		if (!lastModifiedByAdminID) {
			newColumns.push({
				field: "lastModifiedByAdminID",
				headerName: "Last Modified By",
				width: 150,
				maskField: false,
				renderCell: (params: GridValueGetterParams) => {
					const admin = dataAdmins?.data?.dataSource?.find(
						(admin: any) => admin.id === params.row?.lastModifiedByAdminID
					);
					if (!admin) {
						const user = usersData?.find((user: any) => user.employeeID === params.row?.lastModifiedByEmpID);
						return user ? user?.firstName + " " + user?.lastName : "";
					}
					return admin ? admin?.firstname + " " + admin?.lastname : "";
				},
			});
		}
		const createdByAdminID = columns.find((col) => col.field === "createdByAdminID");
		if (!createdByAdminID) {
			newColumns.push({
				field: "createdByAdminID",
				headerName: "Created By",
				width: 150,
				maskField: false,
				renderCell: (params: GridValueGetterParams) => {
					const admin = dataAdmins?.data?.dataSource?.find((admin: any) => admin.id === params.row?.createdByAdminID);
					if (!admin) {
						const user = usersData?.find((user: any) => user.employeeID === params.row?.createdByEmpID);
						return user ? user?.firstName + " " + user?.lastName : "";
					}
					return admin ? admin?.firstname + " " + admin?.lastname : "";
				},
			});
		}
		const createdTs = columns.find((col) => col.field === "createdTs");
		if (!createdTs) {
			newColumns.push({
				field: "createdTs",
				headerName: "Created At",
				width: 150,
				maskField: false,
				renderCell: (params: GridValueGetterParams) =>
					params.row?.createdTs ? moment(params.row?.createdTs).format("DD-MM-YYYY") : "",
			});
		}
		const lastModifiedTs = columns.find((col) => col.field === "lastModifiedTs");
		if (!lastModifiedTs) {
			newColumns.push({
				field: "lastModifiedTs",
				headerName: "Last Modified At",
				width: 150,
				maskField: false,
				renderCell: (params: GridValueGetterParams) =>
					params.row?.lastModifiedTs ? moment(params.row?.lastModifiedTs).format("DD-MM-YYYY") : "",
			});
		}
		const canOverride = columns.find((col) => col.field === "canOverride");
		if (!canOverride) {
			newColumns.push({
				field: "canOverride",
				headerName: "Can exec change location",
				width: 100,
				maskField: false,
				renderCell: (params: GridValueGetterParams) => (params.row?.canOverride === 1 ? "Yes" : "No"),
			});
		}
		const clientCat = columns.find((col) => col.field === "clientCat");
		if (!clientCat && entityMetadata.filter((entity) => entity.fieldName === "Category" && entity.active === 1)) {
			newColumns.push({
				field: "clientCat",
				headerName: "Category",
				width: 100,
				maskField: false,
				renderCell: (params: GridValueGetterParams) => {
					if (params.row?.clientCat === null || params.row?.clientCat === undefined) {
						return "-";
					}
					const obj = clientCategories?.filter((cat) => cat.clientCategoryID === params.row?.clientCat);
					return obj ? obj[0]?.clientType : "-";
				},
			});
		}
		const otpVerified = columns.find((col) => col.field === "otpVerified");
		if (!otpVerified) {
			newColumns.push({
				field: "otpVerified",
				headerName: "Otp Verified",
				width: 100,
				maskField: false,
				renderCell: (params: GridValueGetterParams) => {
					if (
						params.row?.otpVerified === null ||
						params.row?.otpVerified === undefined ||
						params.row?.otpVerified === 0
					) {
						return "No";
					}
					return "Yes";
				},
			});
		}
		const hasAction = columns.find((col) => col.headerName === "Action");
		if (!hasAction) {
			newColumns.push({
				field: "action",
				headerName: "Action",
				width: 100,
				sortable: false,
				maskField: false,
				renderCell: (params: GridValueGetterParams) => (
					<DataGridActionButtons
						name={params.row?.clientName}
						deleteHandler={() => deleteClient(params.row?.clientID)}
						data={[params.row?.clientID]}
						moduleName="client"
						userWritePermission={userWritePermission}
					/>
				),
			});
		}
		setColumns(newColumns);
	};
	useEffect(() => {
		if (entityMetadata?.length > 0 && columns.length <= 10) {
			checkIfAllValuesPresent();
		}
	}, [entityMetadata, variables]);

	useEffect(() => {
		if (clientsRef.current !== clients) {
			clientsRef.current = clients;
		}
	}, [clients]);

	const getColum = useSelector((state: any) => state.dataGrid);
	const savedColumnConf = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.client_and_sites_clients,
		DATA_GRID_CUSTOMIZATION_OPTIONS.columns_array
	);
	const savedColumnSortModel = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.client_and_sites_clients,
		DATA_GRID_CUSTOMIZATION_OPTIONS.sort_model
	);
	const savedPinnedColumns = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.client_and_sites_clients,
		DATA_GRID_CUSTOMIZATION_OPTIONS.pinned_column
	);
	const formattedcolumn = formatTheDataFromSlice(savedColumnConf, columns) || [];
	return (
		<div className="datagrid-table">
			<button className="filter__button" onClick={() => setExpandColumn((prev) => !prev)}>
				<img src="/images/filter.svg" alt="" />
			</button>
			<DataGridPro
				sx={DG_STYLES}
				rows={clients}
				columns={formattedcolumn?.length > 0 ? formattedcolumn : columns}
				loading={loading}
				pageSize={pageSize}
				page={page}
				onPageChange={filterCount === 0 ? pageChangeHandler : pageChangeHandlerByFilter}
				getRowId={(row) => row.clientID}
				onPageSizeChange={filterCount === 0 ? pageSizeChangeHandler : pageSizeChangeHandlerByFilter}
				rowsPerPageOptions={[5, 10, 20, 50, 100]}
				rowHeight={90}
				checkboxSelection
				disableSelectionOnClick
				disableColumnFilter
				disableVirtualization
				sortModel={savedColumnSortModel?.length > 0 ? savedColumnSortModel : []}
				pinnedColumns={savedPinnedColumns && Object.keys(savedPinnedColumns).length > 0 ? savedPinnedColumns : {}}
				rowCount={allClientsCount.current}
				onRowClick={(params: GridRowParams<any>) => {
					if (userWritePermission) {
						// Save clientName to local storage
						localStorage.setItem("clientName", params.row.clientName);
						if (filterCount > 0) {
							window.open(`/clients_sites/clients/clientOverview/${params.id}`);
						} else {
							router.push(`/clients_sites/clients/clientOverview/${params.id}`);
						}
					}
				}}
				onSelectionModelChange={(selectionModel) => setSelectedRows(selectionModel)}
				pagination
				components={{ ColumnMenu: CustomColumnMenu }}
				onColumnOrderChange={(params) => {
					const modifiedColumn = modifyColumOrder(
						DATA_GRID_NAMES.client_and_sites_clients,
						params,
						formattedcolumn?.length > 0 ? formattedcolumn : columns
					);
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.client_and_sites_clients,
						columnArray: modifiedColumn,
					};
					dispatch(setColumnConfigurations(newColumnConfigurations));
				}}
				onSortModelChange={(params) => {
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.client_and_sites_clients,
						sortModel: params,
					};
					dispatch(setColumnSortModel(newColumnConfigurations));
				}}
				onColumnWidthChange={(params) => {
					const modifiedColumn = modifyColumnWidth(params, formattedcolumn?.length > 0 ? formattedcolumn : columns);
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.client_and_sites_clients,
						columnArray: modifiedColumn,
					};
					dispatch(setColumnConfigurations(newColumnConfigurations));
				}}
				onPinnedColumnsChange={(params) => {
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.client_and_sites_clients,
						pinnedColumns: params,
					};
					dispatch(setPinnedColumns(newColumnConfigurations));
				}}
			/>
			{expandColumn && (
				<RightSidebar expanded={expandColumn} setExpanded={setExpandColumn}>
					<ColumnModifierSidebar
						setExpanded={setExpandColumn}
						columns={columns}
						localString={DATA_GRID_NAMES.client_and_sites_clients}
					/>
				</RightSidebar>
			)}
		</div>
	);
};

export default ClientsTable;
