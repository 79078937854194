import { FC, SetStateAction, useCallback, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import GeofenceList from "./GeofenceList";
import GeofenceEdit from "./GeofenceEdit";
import GeofencePoolList from "./GeofencePoolList";
import GeofencePoolEdit from "./GeofencePoolEdit";
import GeofencePoolView from "./GeofencePoolView";
import { mapOptions, mapStyles } from "../../../utils";
import { ROOT_STATE } from "../../../redux";
import { useSelector } from "react-redux";

type Props = {
	geofences: any;
	childFunc: any;
	cancelGeofence: any;
	setIsNewGeofence: any;
	setGeofences: any;
	geofenceIDs: any;
	geofencePools: any;
	setGeofenceIDs: any;
	setGeofencePools: any;
	createGeofencePoolRef: any;
	geoFenceRefetch: any;
	isNewGeofence: boolean;
};

const polyMarkerColor = "#3FC097";
const polygonOptions = {
	strokeColor: polyMarkerColor,
	strokeOpacity: 0.8,
	strokeWeight: 2,
	fillColor: polyMarkerColor,
	fillOpacity: 0.35,
};

let Popup: any;
const regionMarkers: any = {};
const Geofence: FC<Props> = ({
	geofences,
	childFunc,
	cancelGeofence,
	setIsNewGeofence,
	setGeofences,
	geofenceIDs,
	geofencePools,
	setGeofenceIDs,
	setGeofencePools,
	createGeofencePoolRef,
	geoFenceRefetch,
	isNewGeofence,
}) => {
	const [selectedGeofenceID, setSelectedGeofenceID] = useState<null | string>(null);
	const [drawingManager, setDrawingManager] = useState<null | any>(null);
	const [map, setMap] = useState<null | any>(null);
	const [maps, setMaps] = useState<null | any>(null);
	const [edit, setEdit] = useState(false);
	const [isValidPolygon, setIsValidPolygon] = useState<boolean>(true);
	const [isValidSize, setIsValidSize] = useState<boolean>(true);

	const [isGeofenceTab, setIsGeofenceTab] = useState<boolean>(true);
	const [selectedGeofencePoolID, setSelectedGeofencePoolID] = useState<null | string>(null);
	const [isGeofencePoolEdit, setIsGeofencePoolEdit] = useState<boolean>(false);
	const COMPANY_ID = useSelector((state: ROOT_STATE) => state.user.adminDetails.companyID);

	const createPopupClass = (maps: any) => {
		/**
		 * A customized popup on the map.
		 * @param {!google.maps.LatLng} position
		 * @param {!Element} content The bubble div.
		 * @constructor
		 * @extends {google.maps.OverlayView}
		 */
		function Popup(this: any, position: any, content: any) {
			this.position = position;

			content.classList.add("popup-bubble");

			var bubbleAnchor = document.createElement("div");
			bubbleAnchor.classList.add("popup-bubble-anchor");
			bubbleAnchor.appendChild(content);

			this.containerDiv = document.createElement("div");
			this.containerDiv.classList.add("popup-container");
			this.containerDiv.appendChild(bubbleAnchor);

			maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
		}

		Popup.prototype = Object.create(maps.OverlayView.prototype);

		Popup.prototype.onAdd = function () {
			this.getPanes().floatPane.appendChild(this.containerDiv);
		};

		Popup.prototype.setText = function (text: string) {
			this.containerDiv.childNodes[0].childNodes[0].innerHTML = text;
		};

		Popup.prototype.onRemove = function () {
			if (this.containerDiv.parentElement) {
				this.containerDiv.parentElement.removeChild(this.containerDiv);
			}
		};

		Popup.prototype.draw = function () {
			var divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

			var display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? "block" : "none";

			if (display === "block") {
				this.containerDiv.style.left = divPosition.x + "px";
				this.containerDiv.style.top = divPosition.y + "px";
			}
			if (this.containerDiv.style.display !== display) {
				this.containerDiv.style.display = display;
			}
		};

		return Popup;
	};

	const stopEditPolygon = useCallback(() => {
		if (selectedGeofenceID) {
			const geofence = regionMarkers[selectedGeofenceID].geofence;
			const polygon = regionMarkers[selectedGeofenceID].polygon;
			const infowindow = regionMarkers[selectedGeofenceID].infowindow;

			var path = maps.geometry.encoding.decodePath(geofence.polyline);
			polygon.setPath(path);
			infowindow.position = calcHighestPointOfPolygon(path, maps);
			infowindow.draw();

			polygon.setEditable(false);
			polygon.setDraggable(false);
			setEdit(false);
			maps.event.clearListeners(polygon, "drag");
		}
	}, [maps, selectedGeofenceID]);

	const clearSelection = useCallback(() => {
		if (selectedGeofenceID) {
			stopEditPolygon();
			setSelectedGeofenceID(null);
		}
	}, [selectedGeofenceID, stopEditPolygon]);

	useEffect(() => {
		if (maps) {
			maps.event.addListener(map, "click", function () {
				clearSelection();
			});
		}
	}, [maps, map, clearSelection]);

	const addPolygon = useCallback((polygon: any, geofence: any, maps: any, map: any) => {
		var center = calcHighestPointOfPolygon(polygon.getPath().getArray(), maps);
		var iDiv = document.createElement("div");
		var node = document.createTextNode(geofence.name);
		//node.id = 'textContent';
		iDiv.append(node);
		var popup = new Popup(center, iDiv);
		popup.setMap(map);

		popup.containerDiv.addEventListener("click", () => setSelectedGeofenceID(geofence.geofenceID));

		return { polygon: polygon, infowindow: popup, geofence: geofence };
	}, []);

	const polygonSelected = useCallback(
		(geofenceID: SetStateAction<string | null>) => {
			clearSelection();
			setSelectedGeofenceID(geofenceID);
		},
		[clearSelection]
	);

	const zoomToPolygon = useCallback((locations: any, maps: any, map: any) => {
		var bounds = new maps.LatLngBounds();
		locations.map((o: any) => bounds.extend(o));
		map.fitBounds(bounds);
	}, []);

	const drawSites = useCallback(
		(maps: any, map: any) => {
			const globalBounds = new maps.LatLngBounds();
			Popup = createPopupClass(maps);

			geofences.forEach(function (geofence: any) {
				var decodedPath = maps.geometry.encoding.decodePath(geofence.polyline);
				const pOpts = Object.assign(polygonOptions, { paths: decodedPath, id: geofence.geofenceID });
				const polygon = new maps.Polygon(pOpts);
				regionMarkers[geofence.geofenceID] = addPolygon(polygon, geofence, maps, map);

				maps.event.addListener(polygon, "click", function () {
					polygonSelected(polygon.id);
					zoomToPolygon(polygon.getPath().getArray(), maps, map);
				});
				polygon.setMap(map);
			});

			//zoom to fit all polygons
			if (geofences.length > 0) {
				geofences.forEach((region: any) => {
					var decodedPath = maps.geometry.encoding.decodePath(region.polyline);
					decodedPath.forEach((point: any) => {
						globalBounds.extend(point);
					});
					map.fitBounds(globalBounds);
				});
			}
		},
		[addPolygon, geofences, polygonSelected, zoomToPolygon]
	);

	const zoomToClient = (map: any, maps: any, location: any) => {
		var bounds = new maps.LatLngBounds();
		bounds.extend(location);

		map.fitBounds(bounds);
		map.setZoom(17);
	};

	const handleApiLoaded = useCallback(
		(map: any, maps: any) => {
			setMap(map);
			setMaps(maps);
			map.setOptions({ styles: mapStyles });

			const drawingManagerInit = new maps.drawing.DrawingManager({
				drawingMode: null,
				polygonOptions: polygonOptions,
				map: map,
				drawingControl: false,
			});

			setDrawingManager(drawingManagerInit);
			drawSites(maps, map);

			const input = document.getElementById("search_google");
			const searchBox = new maps.places.SearchBox(input);
			map.addListener("bounds_changed", function () {
				searchBox.setBounds(map.getBounds());
			});

			searchBox.addListener("places_changed", function () {
				var places = searchBox.getPlaces();
				if (places.length == 0) {
					return;
				}
				var place = places[0];
				if (!place.geometry) {
					return;
				}
				zoomToClient(map, maps, place.geometry.location);
			});
		},
		[drawSites]
	);

	useEffect(() => {
		if (selectedGeofenceID) {
			const polygon = regionMarkers[selectedGeofenceID].polygon;
			zoomToPolygon(polygon.getPath().getArray(), maps, map);
		}
	}, [selectedGeofenceID, maps, map, zoomToPolygon]);

	const removePolygon = useCallback(
		(polygon: any, infowindow: any, selectedGeofenceID: any) => {
			polygon.setMap(null);
			infowindow.setMap(null);

			delete regionMarkers[selectedGeofenceID];

			maps.event.clearInstanceListeners(polygon);
		},
		[maps]
	);

	const geofenceNamePopupNameChange = useCallback(
		(value: any) => {
			if (selectedGeofenceID) {
				const popup = regionMarkers[selectedGeofenceID].infowindow;
				popup.setText(value);
			}
		},
		[selectedGeofenceID]
	);

	const cancelPolygonEdit = useCallback(() => {
		if (selectedGeofenceID) {
			const geofence = regionMarkers[selectedGeofenceID].geofence;
			const polygon = regionMarkers[selectedGeofenceID].polygon;
			const infowindow = regionMarkers[selectedGeofenceID].infowindow;

			if (geofence.dirty) {
				// $(".businessPlacesList").show();
				// $("#editSiteDetail").hide();
				removePolygon(polygon, infowindow, selectedGeofenceID);
				setSelectedGeofenceID(null);
			} else {
				geofenceNamePopupNameChange(geofence.name);
				var path = maps.geometry.encoding.decodePath(geofence.polyline);
				polygon.setPath(path);
				//revert back infowindow
				infowindow.position = calcHighestPointOfPolygon(path, maps);
				infowindow.draw();

				//stop editing...
				polygon.setEditable(false);
				polygon.setDraggable(false);

				maps.event.clearListeners(polygon, "drag");
			}
		}
		// $("#filterInput , #addPolygonMode").show();
		// $("#cancelSitePool").hide();
	}, [geofenceNamePopupNameChange, maps, removePolygon, selectedGeofenceID]);

	const cancelPolygon = useCallback(() => {
		drawingManager.setDrawingMode(null);
		cancelPolygonEdit();
	}, [drawingManager, cancelPolygonEdit]);

	const calcHighestPointOfPolygon = (path: any, maps: any) => {
		var bounds = new maps.LatLngBounds();

		for (var i = 0; i < path.length; i++) {
			bounds.extend(path[i]);
		}

		return bounds.getCenter();
	};

	const editPolygon = useCallback(
		(geofenceID: any, regionMarkers: any) => {
			setEdit(true);
			const polygon = regionMarkers[geofenceID].polygon;
			const popup = regionMarkers[geofenceID].infowindow;

			//make it editable for now
			polygon.setEditable(true);
			polygon.setDraggable(true);

			maps.event.addListener(polygon, "drag", function () {
				var center = calcHighestPointOfPolygon(polygon.getPath().getArray(), maps);
				popup.position = center;
				popup.draw();
			});

			maps.event.addListener(polygon.getPath(), "set_at", function () {
				handlePolygonSizeChange(polygon);
			});

			maps.event.addListener(polygon.getPath(), "insert_at", function () {
				handlePolygonSizeChange(polygon);
			});
		},
		[maps]
	);

	const handlePolygonSizeChange = (polygon: any) => {
		var array = polygon.getPath().getArray();
		if (Math.round(maps.geometry.spherical.computeArea(array)) > 1500000000) {
			polygon.setOptions({ fillColor: "#c62828", strokeColor: "#c62828" });
			setIsValidSize(false);
		} else {
			polygon.setOptions({ fillColor: polygonOptions.fillColor, strokeColor: polygonOptions.strokeColor });
			setIsValidSize(true);
		}

		if (array && array.length <= 3) {
			setIsValidPolygon(false);
		} else {
			setIsValidPolygon(true);
		}
	};

	const addPolygonMode = useCallback(() => {
		cancelPolygonEdit();
		drawingManager.setDrawingMode(maps.drawing.OverlayType.POLYGON);
		maps.event.addListener(drawingManager, "polygoncomplete", function (polygon: any) {
			//clear the drawing interface now..
			drawingManager.setDrawingMode(null);

			//now remove any listeners!
			maps.event.clearListeners(drawingManager, "polygoncomplete");
			const geofenceID = "fgfgd";
			setSelectedGeofenceID(geofenceID);

			var newSite = { geofenceID: geofenceID, name: "New region", dirty: true, companyID: COMPANY_ID!, new: true };
			regionMarkers[geofenceID] = addPolygon(polygon, newSite, maps, map);

			editPolygon(geofenceID, regionMarkers);
			handlePolygonSizeChange(polygon);
			zoomToPolygon(polygon.getPath().getArray(), maps, map);
		});
	}, [addPolygon, cancelPolygonEdit, drawingManager, editPolygon, map, maps, zoomToPolygon]);

	useEffect(() => {
		childFunc.current = addPolygonMode;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addPolygonMode, childFunc, drawingManager, Popup]);

	const goBackToList = useCallback(() => {
		drawingManager.setDrawingMode(null);
		setIsNewGeofence(false);
		setEdit(false);
		if (selectedGeofenceID) {
			cancelPolygon();
		}
	}, [cancelPolygon, drawingManager, selectedGeofenceID, setIsNewGeofence]);

	useEffect(() => {
		cancelGeofence.current = goBackToList;
	}, [cancelGeofence, goBackToList]);

	const getGeofencePoolDetails = useCallback(() => {
		if (!selectedGeofencePoolID) {
			return {};
		} else if (selectedGeofencePoolID === "-1") {
			return {
				geofencePoolID: "-1",
				name: "New geofence pool",
				geofenceList: [],
			};
		}
		const tempGeofencePool = geofencePools.find(
			(geofencePool: any) => geofencePool.geofencePoolID === selectedGeofencePoolID
		);
		tempGeofencePool["geofenceList"] = geofenceIDs[selectedGeofencePoolID].map((geofenceID: any) =>
			geofences.find((geofence: any) => geofence["geofenceID"] === geofenceID)
		);
		return tempGeofencePool;
	}, [geofenceIDs, geofencePools, geofences, selectedGeofencePoolID]);

	const createGeofencePool = useCallback(() => {
		setIsGeofenceTab(false);
		setSelectedGeofencePoolID("-1");
		setIsGeofencePoolEdit(true);
	}, []);

	useEffect(() => {
		createGeofencePoolRef.current = createGeofencePool;
	}, [createGeofencePool, createGeofencePoolRef]);

	const geofence = selectedGeofenceID
		? regionMarkers[selectedGeofenceID] && regionMarkers[selectedGeofenceID].geofence
		: null;
	const geofencePool = getGeofencePoolDetails();
	return (
		<div style={{ height: "calc(100vh - 260px)", width: "100%", display: "flex", marginTop: "1rem" }}>
			<div>
				{isGeofenceTab ? (
					selectedGeofenceID && edit ? (
						<GeofenceEdit
							geofence={geofence}
							goBackToList={goBackToList}
							geofenceNamePopupNameChange={geofenceNamePopupNameChange}
							regionMarkers={regionMarkers}
							handlePolygonSizeChange={handlePolygonSizeChange}
							maps={maps}
							isValidPolygon={isValidPolygon}
							setSelectedGeofenceID={setSelectedGeofenceID}
							clearSelection={clearSelection}
							setGeofences={setGeofences}
							geofences={geofences}
							setIsNewGeofence={setIsNewGeofence}
							geoFenceRefetch={geoFenceRefetch}
							isValidSize={isValidSize}
							isNewGeofence={isNewGeofence}
						/>
					) : (
						<GeofenceList
							geofences={geofences}
							setSelectedGeofenceID={setSelectedGeofenceID}
							editPolygon={editPolygon}
							regionMarkers={regionMarkers}
							isGeofenceTab={isGeofenceTab}
							setIsGeofenceTab={setIsGeofenceTab}
						/>
					)
				) : selectedGeofencePoolID ? (
					isGeofencePoolEdit ? (
						<GeofencePoolEdit
							geofencePool={geofencePool}
							setSelectedGeofencePoolID={setSelectedGeofencePoolID}
							geofences={geofences}
							setIsGeofencePoolEdit={setIsGeofencePoolEdit}
							geofencePools={geofencePools}
							geofenceIDs={geofenceIDs}
							setGeofenceIDs={setGeofenceIDs}
							setGeofencePools={setGeofencePools}
						/>
					) : (
						<GeofencePoolView
							geofencePool={geofencePool}
							setGeofencePools={setGeofencePools}
							setSelectedGeofencePoolID={setSelectedGeofencePoolID}
							setIsGeofencePoolEdit={setIsGeofencePoolEdit}
						/>
					)
				) : (
					<GeofencePoolList
						geofencePools={geofencePools}
						geofenceIDs={geofenceIDs}
						setSelectedGeofencePoolID={setSelectedGeofencePoolID}
						isGeofenceTab={isGeofenceTab}
						setIsGeofenceTab={setIsGeofenceTab}
					/>
				)}
			</div>
			<GoogleMapReact
				bootstrapURLKeys={{
					key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY!,
					language: "en",
					region: "IN",
					libraries: ["places", "geometry", "drawing", "visualization"],
				}}
				options={mapOptions}
				center={{ lat: 22.593684, lng: 81.96288 }}
				defaultZoom={5}
				style={{ position: "relative", width: "100%", height: "100%" }}
				onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}></GoogleMapReact>
		</div>
	);
};

export default Geofence;
