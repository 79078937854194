//react
import { FC, useEffect, useMemo } from "react";

//redux
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import { useSelector } from "react-redux";
import ReadOnlyButton from "../../common/ReadOnlyButton";
import { useDispatch } from "react-redux";

//mui
import BusinessIcon from "@mui/icons-material/Business";
import { CircularProgress } from "@mui/material";

// utils
import __debounce from "lodash/debounce";
import useAdminEntitlements from "../../useAdminEntitlements";
import { updateCompanyLevelTaskSettings } from "../../../api/settings";
import useFetch from "../../useFetch";

type DATA = {
	companyLevel: {
		taskGeofenceRadius: number;
	};
	teamLevel: {
		profileName: string;
		profileID: number;
		clientMandatoryForTask: 0;
		formTemplateID: 0;
		oneTaskPerClientPerDay: 0;
		taskDescriptions: {
			taskDescription: string;
			taskDescriptionID: number;
		}[];
	}[];
};

const CompanyLevelTaskSettings: FC = () => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const { data, loading } = useFetch<DATA>("/preferences/task");
	const userWritePermission = useAdminEntitlements("settings:tasks", "write");
	const { roleID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);

	// functions
	const debouncedChanges = useMemo(
		() =>
			__debounce(async function (e) {
				const { success, message } = await updateCompanyLevelTaskSettings(+e.target.value);
				dispatch(showNotification({ message, severity: success ? "success" : "error" }));
			}, 500),
		[dispatch]
	);

	//effects
	useEffect(() => {
		if (!loading && data?.data?.companyLevel) {
			const inputElement = document.querySelectorAll(".company-level-task-settings input")[0] as HTMLInputElement;
			if (inputElement) inputElement.value = `${data.data.companyLevel.taskGeofenceRadius}`;
		}
	}, [data?.data, loading]);
	useEffect(() => () => {
		debouncedChanges.cancel();
	});

	return (
		<div className="company-level-task-settings">
			<div className="company-level-header ">
				<BusinessIcon />
				<p className="title">Company Level Settings</p>
				{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="settings" /> : null}
			</div>
			<div className="horizontal-line"></div>
			{loading ? (
				<div style={{ minHeight: "130px" }}>
					<CircularProgress />
				</div>
			) : (
				<div className="section">
					<p className="title">Radius</p>
					<p className="subtitle">
						Note: This value serves two purposes:
						<ul style={{ listStyleType: "square" }}>
							<li>It sets the default boundary for all clients created in the app.</li>
							<li>It determines the distance threshold to start a task assigned at a location.</li>
						</ul>
					</p>
					<input disabled={!userWritePermission} type="number" name="radius" min={0} onChange={debouncedChanges} />
				</div>
			)}
		</div>
	);
};

export default CompanyLevelTaskSettings;
