import axios from "../../utils/axios";
import { PJP_REQUEST_TYPE, PRODUCT_TYPE } from "./../../@types/index";
axios.defaults.withCredentials = true;
export const saveReceiptTemplate = async (body: string): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/orders/saveReceiptTemplate`, {
			toWrite: body,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Saved Receipt Template",
		};
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error.response.data.error === "string"
				? error.response.data.error
				: error?.message || "Error Saving Receipt Template";
		return { success: false, message: errorText };
	}
};


export const getProductBulkDownload = async (
	skus: PRODUCT_TYPE[]
): Promise<{ success: boolean; message: string; data: any }> => {
	try {
		const res = await axios.post(
			`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/orders/downloadSKUs`,
			{
				skus,
			},
			{ responseType: "blob" }
		);
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Downloaded Bulk Products",
			data: res.data,
		};
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Downloading Bulk Products";
		return { success: false, message: errorText, data: undefined };
	}
};

export const resetReceiptTemplate = async (): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.delete(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/orders/receiptTemplate`);

		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully resetted the receipt template.",
		};
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error resetting receipt template";
		return { success: false, message: errorText };
	}
};
export const pjpApproveReject = async (
	request: PJP_REQUEST_TYPE[],
	state: string
): Promise<{ success: boolean; message: string }> => {
	const payload = {
		pjpRequestsForApproval: request,
	};
	try {
		const res = await axios({
			method: "POST",
			data: payload,
			url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/tasks/pjpApproveReject`,
			headers: {
				"Content-Type": "application/json",
			},
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Successfully ${state} selected Request`,
		};
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Deleting Selected Request";
		return { success: false, message: errorText };
	}
};