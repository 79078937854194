import moment from "moment";
import "moment-timezone";
import { useSelector } from "react-redux";
import { DASHBOARD } from "../../../@types";
import { TIME_SHOW_FORMAT } from "../../../constants";
import { ROOT_STATE } from "../../../redux";
type Props = {
	forms: DASHBOARD["completedForms"];
};
const Forms = ({ forms }: Props) => {
	const adminInfo = useSelector((state: ROOT_STATE) => state.user);

	return (
		<div className="sub_table">
			<div className="rows head">
				<span>Form Name</span>
				<span>Time</span>
			</div>
			{forms &&
				Array.isArray(forms) &&
				forms.length > 0 &&
				forms.map((form) => (
					<div className="rows" key={form.completedFormID}>
						<span>{form.formTitle}</span>
						<span>
							{moment(form.createdTs)
								.tz(adminInfo?.tz || "Asia/Kolkata")
								.format(TIME_SHOW_FORMAT)}
						</span>
					</div>
				))}
		</div>
	);
};

export default Forms;
