import { gql } from "@apollo/client";

export const GET_EXPENSE_DASHBOARD_DATA = gql`
	query getExpenseDashboardData(
		$startDate: Date!
		$endDate: Date!
		$limitToCount: Int!
		$groupByKeys: [GroupByKey]!
		$sortByKeys: [SortByKey]
		$orderBy: [OrderBy]
		$expenseOrConveyance: Int!
		$filters: [genericFilter!]
	) {
		get_expenses_dashboard_data(
			startDate: $startDate
			endDate: $endDate
			limitToCount: $limitToCount
			groupByKeys: $groupByKeys
			sortByKeys: $sortByKeys
			orderBy: $orderBy
			expenseOrConveyance: $expenseOrConveyance
			filters: $filters
		) {
			quantity
			value
			groupByKeyComb
		}
	}
`;

export const GET_EXPENSES_BY_ADMIN_ID = gql`
	query GetExpensesByAdminID($filters: ExpenseQueryFilter!, $fromDate: Date!, $toDate: Date!) {
		get_expenses_by_adminID(filters: $filters, fromDate: $fromDate, toDate: $toDate) {
			expenseSummaryID
			status
			date
			description
			employeeID
			createdTs
			lastModifiedTs
			evidences
			fileUploads
			distanceCalculated
			odoDistance
			expenseConveyanceDetails {
				conveyanceID
				amountClaimed
				mode
				distanceClaimed
				description
				linkedOdometer {
					inReading
					outReading
					inPhoto{
						url
						photoID
					}
					outPhoto
					{
						url
						photoID
					}

				}
			}
			expenseExpenseDetails {
				expenseID
				amountClaimed
				description
				category
			}
			userInfo {
				firstName
				lastName
				profileID
				employeeID
				profileName
				designationName
			}
			authorizationInfo {
				history {
					expenseAuthID
					expenseSummaryID
					adminName
					adminID
					reimbursementAmount
					comment
					timestamp
					rejectReason
					insertionStatus
					actionPerformed
					lastModifiedTs
					backupApproverAuth
				}
				pendingAdmin {
					adminName
					adminID
				}
				nextAdmin {
					adminName
					adminID
				}
				lastApprovedAmount
				payoutAdminID
			}
			customFieldsComplex {
				fieldID
				fieldValue
				fieldValue2
				fieldValue3
				selectedOptions
				fieldName
				selectedOptionsValues
				selectedFieldValues {
					fieldValue
					fieldValue2
				}
			}
			lastApprovedAmount
		}
	}
`;

export const GET_EXPENSES_BY_EMPLOYEE_ID = gql`
	query GetExpensesByEmployeeId($filters:ExpenseQueryFilter!, $fromDate: Date!, $toDate: Date!)
	{
		get_expenses_by_employeeID(filters:$filters, fromDate:$fromDate, toDate:$toDate){
			status
			expenseConveyanceDetails {
				amountClaimed	
			}
			authorizationInfo{
				lastApprovedAmount
				payoutAdminID
			}
			expenseExpenseDetails {
				amountClaimed
			}
		}
	}
`
export const GET_ADVANCES_BY_ADMIN_ID = gql`
	query GetAdvancesByAdminID($fromDate: Date!, $toDate: Date!) {
		get_advances_by_adminID(fromDate: $fromDate, toDate: $toDate) {
			advanceSummaryID
			status
			createdTs
			lastModifiedTs
			description
			type
			fromDate
			toDate
			advanceExpenseDetails {
				amountProposed
				description
				advanceExpenseID
				category
			}
			userInfo {
				firstName
				lastName
				profileID
				employeeID
				profileName
				designationName
			}
			advanceConveyanceDetails {
				amountProposed
				description
				distanceClaimed
				advanceConveyanceID
				mode
			}
			authorizationInfo {
				payoutAdminID
				lastApprovedAmount
				history {
					adminName
					adminID
					allottedAmount
					actionPerformed
					comment
					timestamp
					lastModifiedTs
					advanceAuthID
					rejectReason
				}
				pendingAdmin {
					adminName
					adminID
				}
				nextAdmin {
					adminName
					adminID
				}
			}
			customFieldsComplex {
				fieldID
				fieldValue
				fieldValue2
				fieldValue3
				selectedOptions
				fieldName
				selectedOptionsValues
				selectedFieldValues {
					fieldValue
					fieldValue2
				}
			}
		}
	}
`;

export const APPROVE_OR_REJECT_ADVANCE = gql`
	mutation approveOrRejectAdvance(
		$advancesAuthorizationInput: [AdvancesWithAllottedAmount!]!
		$status: Int!
		$rejectReason: String
		$comment: String
	) {
		approve_or_reject_advance(
			advancesAuthorizationInput: $advancesAuthorizationInput
			status: $status
			rejectReason: $rejectReason
			comment: $comment
		) {
			success
		}
	}
`;

export const APPROVE_OR_REJECT_EXPENSE = gql`
	mutation approveOrRejectExpense($expensesAuthorizationInputs: [ExpenseAuthorizationInput!]!, $status: Int!) {
		approve_or_reject_expense(expensesAuthorizationInputs: $expensesAuthorizationInputs, status: $status) {
			success
		}
	}
`;

export const MARK_ADVANCE_PAID_OUT = gql`
	mutation markAdvancePaidOut($advanceSummaryIDs: [ID!]) {
		mark_advance_paid_out(advanceSummaryIDs: $advanceSummaryIDs) {
			success
		}
	}
`;
export const MARK_EXPENSE_PAID_OUT = gql`
	mutation markExpensePaidOut($expenseSummaryIDs: [ID!]) {
		mark_expense_paid_out(expenseSummaryIDs: $expenseSummaryIDs) {
			success
		}
	}
`;
export const UPDATE_EXPENSE_SUMMARY_AND_AUTHORIZATION = gql`
	mutation updateExpenseAuthorization($expenseSummaryAndAuthorizationInput: ExpenseSummaryAndAuthorizationInput) {
		update_expense_summary_and_authorization(
			expenseSummaryAndAuthorizationInput: $expenseSummaryAndAuthorizationInput
		) {
			success
		}
	}
`;
