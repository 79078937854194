import { FC, useState } from "react";
import ThirdPartyIntegrations from "./ThirdPartyIntegrations";
import Webhooks from "./Webhooks";
import useAdminEntitlements from "../../useAdminEntitlements";
import ReadOnlyButton from "../../common/ReadOnlyButton";
import { useSelector } from "react-redux";
import { ROOT_STATE } from "../../../redux";
import IntegrationLogs from "./IntegrationLogs";

const Integrations: FC = () => {
	const [selectedIntegration, setSelectedIntegration] = useState<number>(0);
	const userWritePermission = useAdminEntitlements("settings:integrations", "write");
	const { roleID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);
	return (
		<div className="integrations-settings" style={{ paddingBottom: selectedIntegration === 1 ? "71px" : "25px" }}>
			<div className="header">
				<div className="section">
					<div className="section-part">
						<p className="title">Integrations</p>
						{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="settings" /> : null}
					</div>
					<p className="subtitle">Connect Unolo with other systems with a single click.</p>
				</div>
				<div className="button-group flex-wrap">
					<button
						onClick={() => setSelectedIntegration(0)}
						className={`group ${selectedIntegration === 0 ? "selected" : ""}`}
						style={{ height: "36px" }}>
						Integrations
					</button>
					<button
						onClick={() => setSelectedIntegration(1)}
						className={`group ${selectedIntegration === 1 ? "selected" : ""}`}
						style={{ height: "36px" }}>
						Webhooks
					</button>
					<button
						onClick={() => setSelectedIntegration(2)}
						className={`group ${selectedIntegration === 2 ? "selected" : ""}`}
						style={{ height: "36px" }}>
						Integration Logs
					</button>
				</div>
			</div>
			{selectedIntegration === 0 && <ThirdPartyIntegrations />}
			{selectedIntegration === 1 && <Webhooks userWritePermission={userWritePermission} />}
			{selectedIntegration === 2 && <IntegrationLogs userWritePermission={userWritePermission} />}
		</div>
	);
};

export default Integrations;
