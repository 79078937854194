import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Switch } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { FEATURE } from "../../../@types";
import { changeModuleSettings } from "../../../api/settings";
import { DG_STYLES } from "../../../constants";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { fetchSidebarMetaData } from "../../../utils";
import CustomColumnMenu from "../../common/datagrid/CustomColumnMenu";
import useAdminEntitlements from "../../useAdminEntitlements";

type Props = {
	features: FEATURE[];
	setFeatures: Dispatch<SetStateAction<FEATURE[]>>;
	loading: boolean;
};
const ModuleTable: FC<Props> = ({ features, loading, setFeatures }) => {
	const { refetch } = useQuery("subscriptionMetadata", fetchSidebarMetaData);
	const [pageSize, setPageSize] = useState(20);
	const dispatch = useDispatch<APP_DISPATCH>();
	const [columns, setColumns] = useState<GridColDef[]>([]);
	const userWritePermission = useAdminEntitlements("settings:modules", "write");

	const tempColumns = useMemo(
		() => [
			{
				field: "featureName",
				headerName: "Module",
				sortable: false,
				flex: 1,
				minWidth: 200,
			},
			{
				field: "featureDescription",
				headerName: "Description",
				sortable: false,
				flex: 2,
				minWidth: 200,
				renderCell: () => "",
			},
			{
				field: "state",
				headerName: "Action",
				width: 100,
				sortable: false,
				renderCell: (params: GridValueGetterParams) => {
					if (params["row"]["isMandatory"]) {
						return <NotInterestedIcon />;
					}
					return (
						<Switch
							disabled={!userWritePermission}
							checked={params.row.state}
							onChange={() => changeModuleState(params.row.state, params.row.featureID)}
							sx={{ cursor: !userWritePermission ? "not-allowed !important" : "pointer", pointerEvents: "auto" }}
						/>
					);
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[features, userWritePermission]
	);
	useEffect(() => {
		setColumns(tempColumns);
	}, [tempColumns]);

	const changeModuleState = async (prevState: number, featureID: number) => {
		const newFeatures = [...features];
		const index = newFeatures.findIndex((item) => item.featureID === featureID);
		if (isNaN(prevState) || isNaN(featureID) || index === -1) return;
		const newState = prevState === 1 ? 0 : 1;
		const { success, message } = await changeModuleSettings(featureID, newState);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (!success) return;
		newFeatures[index].state = newState;
		await refetch();
		setFeatures(newFeatures);
	};

	return (
		<div className="datagrid-table">
			<DataGridPro
				sx={DG_STYLES}
				getRowId={(row) => row.featureID}
				rows={features}
				columns={columns}
				loading={loading}
				pageSize={pageSize}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[5, 10, 20, 50]}
				rowHeight={70}
				disableSelectionOnClick
				disableColumnFilter
				pagination
				components={{
					ColumnMenu: CustomColumnMenu,
				}}
			/>
		</div>
	);
};

export default ModuleTable;
