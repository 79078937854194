import { gql } from "@apollo/client";

export const GET_INTEGRATIONS_BY_COMPANY_ID = gql`
	query {
		get_integrations_by_companyID {
			integrationID
			integrationName
			status
			keyValues {
				keyTypeID
				keyName
				keyValue
			}
			entityName
		}
	}
`;

export const UPSERT_ENABLE_WEBHOOK_INTEGRATION = gql`
	mutation upsertIntegrationState($input: IntegrationStateInput!) {
		upsert_integration_state(input: $input) {
			status
			message
		}
	}
`;

export const UPSERT_SUBMIT_WEBHOOK_INTEGRATION = gql`
	mutation upsertIntegrations($input: [ApiCredentialInput!]!) {
		upsert_integrations(input: $input) {
			status
			message
		}
	}
`;
