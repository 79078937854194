import { FC } from "react";

import { styled } from "@mui/material/styles";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

const CustomCard = styled(Card)({
	boxShadow: "none",
	borderRadius: "12px",
	"& .MuiCardContent-root": {
		padding: "1rem !important",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
	"& .MuiTypography-root": {
		marginBottom: 0,
	},
});

const attendanceTypeData = [
	{
		symbol: "PR",
		label: "Present",
		symbolColor: "#40A636",
		backgroundColor: "#ECF6EB",
		tooltip: "Time on attendance is equal to or more than their scheduled shift time",
	},
	{
		symbol: "HD",
		label: "Half Day",
		symbolColor: "#EF9943",
		backgroundColor: "#FEF6EF",
		tooltip: "Time on attendance is between 50% to 100% of their scheduled shift time",
	},
	{
		symbol: "AB",
		label: "Absent",
		symbolColor: "#F54747",
		backgroundColor: "#FEEDED",
		tooltip: "Time on attendance is less than 50% of their scheduled shift time",
	},
	{
		symbol: "CL",
		label: "Casual Leave",
		symbolColor: "#0F45FF",
		backgroundColor: "#E8EDFF",
		tooltip: "Person has approved leave",
	},
	{
		symbol: "SL",
		label: "Sick Leave",
		symbolColor: "#d705f1d9",
		backgroundColor: "#D9F0FD",
		tooltip: "Sick Leave",
	},
	{
		symbol: "EL",
		label: "Earn Leave",
		symbolColor: "#611414c4",
		backgroundColor: "#ECF6EB",
		tooltip: "Earn Leave",
	},
	{
		symbol: "WO",
		label: "Weekly Off",
		symbolColor: "#466bff",
		backgroundColor: "#ECF6EB",
		tooltip: "Weekly Off",
	},
	{
		symbol: "PE",
		label: "Penalty",
		symbolColor: "#ef9943",
		backgroundColor: "#ECF6EB",
		tooltip:
			"If the app settings for an executive is BAD for 5 consecutive days, when they mark their attendance on the sixth day, they will be allowed to log-in but their attendance will be docked by 1 day.",
	},
	{
		symbol: "PH",
		label: "Public Holiday",
		symbolColor: "#466bff",
		backgroundColor: "#ECF6EB",
		tooltip: "Public Holiday",
	},
];

const AttendanceType: FC = () => (
	<div className="attendance-type">
		<CustomCard>
			<CardContent>
				<div className="header">
					<div>
						<Typography>Attendance Type</Typography>
					</div>
				</div>
				<Divider sx={{ border: "1px solid #DFDFDF", width: "100%", margin: "1rem 0" }} />
				<div className="table">
					{attendanceTypeData.map((data) => (
						<div key={data["label"]} className="table-card" style={{ backgroundColor: data["backgroundColor"] }}>
							<div className="table-card-top">
								<Typography sx={{ color: data["symbolColor"], filter: "contrast(0.6)" }}>{data.symbol}</Typography>

								<InfoOutlinedIcon style={{ fontSize: "12px" }} />
							</div>
							<div className="table-card-bottom">
								<Typography>{data.label}</Typography>
							</div>
						</div>
					))}
				</div>
			</CardContent>
		</CustomCard>
	</div>
);

export default AttendanceType;
