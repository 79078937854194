import { FormControl, InputLabel, Select, SelectChangeEvent, MenuItem, CircularProgress } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { DD_STYLES } from "../../../constants";
import moment from "moment";

const STATUS_CONST = ["ALL", "FAILED", "PENDING", "SUCCESS", "TIMEOUT"];
function capitalizeFirstLetter(string: string) {
	return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
}
type obj = {
	integrationID: number;
	eventType: number;
	status: number;
};
type Props = {
	eventsReplayedResponse: any;
	setRows: any;
};
const IntegrationLogsFilter: FC<Props> = ({ eventsReplayedResponse, setRows }) => {
	const [integrationObj, setIntegrationObj] = useState<obj>({
		integrationID: -1,
		eventType: -1,
		status: 0,
	});
	const [allIntegrationsName, setAllIntegrationsName] = useState<any[]>([]);
	const [allEventTypes, setAllEventTypes] = useState<any>([]);

	useEffect(() => {
		const uniqueIntegrations: any = Array.from(
			new Map(
				eventsReplayedResponse
					.filter((obj: any) => obj.data !== null)
					.map((item: any) => [
						item.data.integrationName,
						{
							integrationID: item.data.integrationID,
							integrationName: item.data.integrationName,
							displayName: item.data.displayName, // assuming displayName is part of your data
						},
					])
			).values()
		);
		const entityNamesByIntegrationID: any = eventsReplayedResponse
			.filter((obj: any) => obj.data !== null)
			.reduce((acc: any, item: any) => {
				// Check if the integrationID is already in the accumulator
				if (!acc[item.data.integrationID]) {
					acc[item.data.integrationID] = [];
				}
				// Push the entityName for the integrationID
				acc[item.data.integrationID].push(item.data.eventType);
				return acc;
			}, {});
		const uniqueEntityName: any[] = [...new Set(Object.values(entityNamesByIntegrationID).flat())];

		setAllEventTypes(uniqueEntityName);
		setAllIntegrationsName(uniqueIntegrations);
	}, [eventsReplayedResponse]);

	useEffect(() => {
		const resultantMatches = eventsReplayedResponse.filter((obj: any) => {
			// Check each condition individually and combine using AND operation
			const matchesIntegration =
				integrationObj.integrationID === -1 || obj?.data?.integrationID === integrationObj.integrationID;

			const matchesEntity =
				integrationObj.eventType === -1 || obj?.data?.eventType === allEventTypes[integrationObj.eventType];

			const matchesStatus = integrationObj.status === 0 || obj?.data?.status === STATUS_CONST[integrationObj.status];

			// Return true only if all conditions are met
			return matchesIntegration && matchesEntity && matchesStatus;
		});

		const tempRows: any = [];
		resultantMatches
			?.filter((obj: any) => obj.data !== null)
			.forEach((int: any) => {
				const obj = {
					integrationName: allIntegrationsName.find((x) => x.integrationID === int.data.integrationID)?.displayName,
					lastModifiedAt: moment(int.data.lastModifiedAt, "YYYY-MM-DD HH:mm:ss.SSS").isValid()
						? moment(int.data.lastModifiedAt, "YYYY-MM-DD HH:mm:ss.SSS").format("DD-MM-YYYY HH:mm:ss.SSS")
						: int.data.lastModifiedAt,
					entityName: int.data.eventType,
					status: int.data.status,
					errorMessage: int.data.errorMessage,
					eventID: int.data.eventID,
					integrationID: int.data.integrationID,
					id: int.data.eventID + ":" + int.data.integrationID + ":" + int.data.eventType,
					retryCount: int.data.retryCount,
					eventReplayed: int.data.retryCount >= 1 ? "Yes" : "No",
				};

				tempRows.push(obj);
			});

		setRows(tempRows);
	}, [integrationObj, allEventTypes, allIntegrationsName, eventsReplayedResponse]);

	const handleupdateTheIntegrationObj = (event: SelectChangeEvent<any>, name: string) => {
		if (event.target.value === -1) {
			const entityNamesByIntegrationID: any = eventsReplayedResponse.reduce((acc: any, item: any) => {
				// Check if the integrationID is already in the accumulator
				if (!acc[item.data.integrationID]) {
					acc[item.data.integrationID] = [];
				}
				// Push the entityName for the integrationID
				acc[item.data.integrationID].push(item.data.eventType);
				return acc;
			}, {});
			const uniqueEntityName: any[] = [...new Set(Object.values(entityNamesByIntegrationID).flat())];
			setAllEventTypes(uniqueEntityName);
			setIntegrationObj((prev: any) => ({
				...prev,
				[name]: event.target.value,
				eventType: uniqueEntityName.length > 1 ? -1 : 0,
			}));
		} else {
			const entityNamesForIntegrationID = eventsReplayedResponse
				.filter((obj: any) => obj.data !== null)

				.filter((item: any) => item.data.integrationID === event.target.value)
				.map((item: any) => item.data.eventType);
			setAllEventTypes(entityNamesForIntegrationID);
			setIntegrationObj((prev: any) => ({
				...prev,
				[name]: event.target.value,
				eventType: entityNamesForIntegrationID.length > 1 ? -1 : 0,
			}));
		}
	};

	return (
		<div>
			{allIntegrationsName.length > 0 && allEventTypes.length > 0 && (
				<div className="flex gap-3 flex-wrap">
					<FormControl style={{ width: 160 }}>
						<InputLabel id="integration_name">Integration name</InputLabel>
						<Select
							name="integrationName"
							label="Integration name"
							labelId="integration_name"
							sx={DD_STYLES}
							value={
								allIntegrationsName.length > 1 ? integrationObj.integrationID : allIntegrationsName[0].integrationID
							}
							onChange={(e) => handleupdateTheIntegrationObj(e, "integrationID")}>
							{allIntegrationsName.length > 1 && <MenuItem value={-1}>All</MenuItem>}
							{allIntegrationsName.map((obj, index) => (
								<MenuItem value={obj.integrationID} key={index}>
									{obj.displayName}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl style={{ width: 160 }}>
						<InputLabel id="entity_name">Entity name</InputLabel>
						<Select
							name="entity name"
							label="Entity name"
							labelId="entity_name"
							sx={DD_STYLES}
							value={allEventTypes.length > 1 ? integrationObj.eventType : 0}
							onChange={(e) =>
								setIntegrationObj((prev: any) => ({
									...prev,
									["eventType"]: e.target.value,
								}))
							}>
							{allEventTypes.length > 1 && <MenuItem value={-1}>All</MenuItem>}
							{allEventTypes.map((entity: any, index: number) => (
								<MenuItem value={index} key={index}>
									{capitalizeFirstLetter(entity)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl style={{ width: 160 }}>
						<InputLabel id="status">Status</InputLabel>
						<Select
							name="status"
							label="Status"
							labelId="status"
							sx={DD_STYLES}
							value={integrationObj.status}
							onChange={(e) =>
								setIntegrationObj((prev: any) => ({
									...prev,
									["status"]: e.target.value,
								}))
							}>
							{STATUS_CONST.map((status, index) => (
								<MenuItem value={index} key={index}>
									{capitalizeFirstLetter(status)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			)}
			{(allIntegrationsName.length === 0 || allEventTypes.length === 0) && (
				<div className="flex items-center justify-center">
					<CircularProgress />
				</div>
			)}
		</div>
	);
};

export default IntegrationLogsFilter;
