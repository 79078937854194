import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Dispatch, FC, FormEvent, SetStateAction } from "react";
import { PROFILES_DATA, SHIFTS_FILTER_COLUMN } from "../../../@types";
import IndividualFilter from "../../common/datagrid/IndividualFilter";
type COLUMN_TITLES = "Team";
type Props = {
	numFilters: number;
	setFilterOpen: Dispatch<SetStateAction<boolean>>;
	filters: Record<SHIFTS_FILTER_COLUMN, string[]>;
	setFilters: Dispatch<SetStateAction<Record<SHIFTS_FILTER_COLUMN, string[]>>>;
	profiles: PROFILES_DATA[];
	localStorageKey: string;
};
const FilterShifts: FC<Props> = ({ numFilters, setFilterOpen, setFilters, filters, profiles }) => {
	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const resetFilters = () => {
		setFilters({ profileID: [] });
	};
	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
		<form onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()} onKeyPress={(e) => e.stopPropagation()}>
			<div className="filter__header">
				<h5>
					<FilterAltOutlinedIcon />
					Filters
					<span className="right-notification center" style={{ display: "flex", padding: 0 }}>
						{numFilters}
					</span>
				</h5>
				<button onClick={() => setFilterOpen(false)}>
					<CloseSharpIcon htmlColor="#42444A" />
				</button>
			</div>
			<div className="accordion__group">
				<IndividualFilter<COLUMN_TITLES, SHIFTS_FILTER_COLUMN>
					filters={filters}
					setFilters={setFilters}
					name="profileID"
					title="Team"
					data={profiles.map((profile) => profile.profileName)}
					ids={profiles.map((profile) => profile.profileID)}
				/>
			</div>
			<div className="filter__buttons">
				<button onClick={resetFilters} type="reset">
					Reset
				</button>
			</div>
		</form>
	);
};

export default FilterShifts;
