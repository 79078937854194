import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import { Dispatch, FC, SetStateAction } from "react";
import { useDispatch } from "react-redux";
import { EXPENSE_SETTING } from "../../../@types";
import { addExpenseLimit } from "../../../api/settings";
import { showNotification } from "../../../redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialog-paper": {
		maxWidth: "350px",
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

type Props = {
	openModeChange: number;
	setOpenModeChange: Dispatch<SetStateAction<number>>;
	setExpenseLimits: Dispatch<SetStateAction<EXPENSE_SETTING["expenseLimits"]>>;
	expenseCategories: EXPENSE_SETTING["expenseCategories"];
};

const LimitModeChangeModal: FC<Props> = ({
	openModeChange,
	setOpenModeChange,
	setExpenseLimits,
	expenseCategories,
}) => {
	const dispatch = useDispatch();
	const saveExpenseLimit = async () => {
		const dataTosend = {},
			tempExpenseLimits = [];

		dataTosend["expenseLimitEnable"] = openModeChange !== 1 ? 1 : 0;

		if (dataTosend["expenseLimitEnable"]) {
			const expenseAgainstMetadata = {};
			expenseAgainstMetadata["expenseAgainstAllCategories"] = openModeChange === 2 ? 1 : 0;
			const expenseCatogoryPayload = [];
			if (expenseAgainstMetadata["expenseAgainstAllCategories"]) {
				expenseCatogoryPayload.push({
					setAgainst: "all",
					payload: [
						{
							expenseLimit: 200,
							duration: 0,
						},
					],
				});
				tempExpenseLimits.push({
					designationID: null,
					profileID: null,
					expenseLimit: 200,
					expenseTimePeriod: 0,
					expenseCategoryID: null,
				});
			} else {
				expenseCategories.forEach((category) => {
					expenseCatogoryPayload.push({
						setAgainst: "all",
						expenseCategoryID: category.expenseCategoryID,
						payload: [
							{
								expenseLimit: 200,
								duration: 0,
							},
						],
					});

					tempExpenseLimits.push({
						designationID: null,
						profileID: null,
						expenseLimit: 200,
						expenseTimePeriod: 0,
						expenseCategoryID: category.expenseCategoryID,
					});
				});
			}

			expenseAgainstMetadata["expenseCatogoryPayload"] = expenseCatogoryPayload;
			dataTosend["expenseAgainstMetadata"] = expenseAgainstMetadata;
		}

		const { success, message } = await addExpenseLimit(dataTosend);
		if (success) {
			// setSelectedLimitAgainst(openModeChange-1);
			setExpenseLimits([...tempExpenseLimits]);
			setOpenModeChange(0);
		}
		dispatch(showNotification({ message: message, severity: success ? "success" : "error" }));
	};

	const approve = () => {
		saveExpenseLimit();
	};

	return (
		<BootstrapDialog onClose={() => setOpenModeChange(0)} aria-labelledby="customized-dialog-title" open={true}>
			<div
				style={{
					display: "flex !important",
					padding: "16px",
					alignItems: "center",
					width: "100%",
					justifyContent: "center",
				}}>
				<p
					style={{
						textAlign: "center",
						margin: "1.5rem 0 0.5rem 0",
						fontSize: "14px",
						fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
					}}>
					Old rules will be deleted, are you sure you want to switch?
				</p>
			</div>
			<DialogActions
				sx={{
					justifyContent: "center",
					marginBottom: "1rem",
				}}>
				<Button variant="contained" onClick={() => setOpenModeChange(0)} color="inherit">
					Cancel
				</Button>
				<Button variant="contained" onClick={approve} color={"success"}>
					OK
				</Button>
			</DialogActions>
		</BootstrapDialog>
	);
};

export default LimitModeChangeModal;
