/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { SITES_DATA } from "../../../@types";

type Props = {
	sites: SITES_DATA[];
	expanded: boolean;
	setSelectedSiteId: Dispatch<SetStateAction<string | null>>;
	// eslint-disable-next-line no-unused-vars
	onSiteClicked: (clientID: string) => void;
};
const SitesList: FC<Props> = ({ sites, setSelectedSiteId, onSiteClicked, expanded }) => {
	const [searchTerm, setSearchTerm] = useState("");

	const filteredList = sites.filter((site) =>
		site.clientName?.replace(/ /g, "").toLocaleLowerCase()?.includes(searchTerm.replace(/ /g, "").toLocaleLowerCase())
	);
	const list = filteredList.length > 0 ? filteredList : sites;
	return (
		<div className={expanded ? "user_add client_sidebar open" : "user_add client_sidebar close"}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<input
					type="search"
					placeholder="Search Here.."
					className="search"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
				/>
			</div>

			<ul>
				{list.map((site) => (
					<li
						style={{ gridTemplateColumns: "1fr" }}
						key={site.clientID}
						onClick={() => {
							setSelectedSiteId(site.clientID);
							onSiteClicked(site.clientID);
						}}>
						<div>
							<h3>{site.clientName}</h3>
							<small>
								<AddLocationAltOutlinedIcon /> {site.address}
							</small>
						</div>
					</li>
				))}
			</ul>
		</div>
	);
};

export default SitesList;
