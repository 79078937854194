//react
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

//apollo
import { useMutation } from "@apollo/client";

//moment
import "moment-timezone";
import _ from "lodash";
import moment from "moment";

//types, constants, utils
import { setColumnConfigurations, setColumnSortModel, setPinnedColumns } from "../../../redux/slices/dataGridSlice";
import { CUSTOM_FIELD_COMPLEX_TYPE, ENTITY_METADATA, PRODUCT_TYPE, PROFILES_DATA } from "../../../@types";
import { DATA_GRID_CUSTOMIZATION_OPTIONS, DATA_GRID_NAMES, DG_STYLES } from "../../../constants";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { DELETE_SKUS } from "../../../schema";
import ColumnModifierSidebar from "../../common/datagrid/ColumnModifierSidebar";
import CustomColumnMenu from "../../common/datagrid/CustomColumnMenu";
import DataGridActionButtons from "../../common/datagrid/DatagridActionButton";
import RightSidebar from "../../common/RightSidebar";
import useAdminEntitlements from "../../useAdminEntitlements";
import {
	FORM_REDIRECT_URL,
	formatTheDataFromSlice,
	getTheSavedColumns,
	modifyColumOrder,
	modifyColumnWidth,
	ordersCustomColumn,
} from "../../../utils";

//mui
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import DownloadIcon from "@mui/icons-material/Download";
import { DataGridPro, GridColDef, GridSelectionModel, GridValueGetterParams } from "@mui/x-data-grid-pro";

type Props = {
	profiles: PROFILES_DATA[];
	products: PRODUCT_TYPE[];
	setProducts: Dispatch<SetStateAction<PRODUCT_TYPE[]>>;
	setSelectedRows: Dispatch<SetStateAction<GridSelectionModel>>;
	isLoading: boolean;
	// eslint-disable-next-line no-unused-vars
	openSidebarForm: (id?: string | undefined) => void;
	entityMetadata: ENTITY_METADATA[];
};
const ProductsTable: FC<Props> = ({
	profiles,
	products,
	setProducts,
	entityMetadata,
	setSelectedRows,
	openSidebarForm,
	isLoading,
}) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const [deleteSKUMutation, { error }] = useMutation(DELETE_SKUS);
	const [expandColumn, setExpandColumn] = useState(false);
	const [pageSize, setPageSize] = useState(20);
	const [columns, setColumns] = useState<GridColDef[]>([]);
	const userWritePermission = useAdminEntitlements("orders:products", "write");

	const tempColumns = useMemo(() => {
		const columns: any = [
			{
				field: "skuName",
				headerName: "SKU Name",
				width: 200,
			},
			{
				field: "skuImages",
				headerName: "SKU Images",
				headerClassName: "center-align",
				cellClassName: "center-align",
				sortable: false,
				width: 200,
				renderCell: (params: GridValueGetterParams) => {
					const skuImages = [params.row.skuImage1, params.row.skuImage2, params.row.skuImage3];
					const checkImages = skuImages.every((value) => value === null || value === undefined || value === "");

					const imageLinks = skuImages.filter((image) => {
						if (image === null || image === "") {
							return false;
						} else {
							return true;
						}
					});

					return (
						<AvatarGroup>
							{!checkImages ? (
								imageLinks.map((image, index) => (
									<div
										style={{
											marginRight: "-40px",
											position: "relative",
										}}
										key={index}>
										<Avatar sx={{ width: 55, height: 55 }} src={image} />
									</div>
								))
							) : (
								<Avatar sx={{ width: 55, height: 55 }}>N/A</Avatar>
							)}
						</AvatarGroup>
					);
				},
			},

			{
				field: "internalSkuID",
				headerName: "SKU ID",
				width: 150,
			},
			{
				field: "skuDescription",
				headerName: "SKU Description",
				width: 200,
				sortable: false,
			},
			{
				field: "category",
				headerName: "Category",
				width: 150,
			},
			{
				field: "listPrice",
				headerName: "List Price",
				width: 100,
			},
			{
				field: "showGstRate",
				headerName: "Tax Applicable",
				width: 200,
				renderCell: (params: GridValueGetterParams) => {
					const status = params.row.showGstRate === 1 ? "Yes" : "No";
					return <span className={`role_badge ${status}`}>{status === "Yes" ? "Enabled" : "Disabled"}</span>;
				},
			},
			{
				field: "gstRate",
				headerName: "Tax %",
				width: 100,
				renderCell: (params: GridValueGetterParams) => (params.row.showGstRate === 1 ? params.row.gstRate ?? 0 : "-"),
			},
			{
				field: "showDiscAmt",
				headerName: "Discount Applicable",
				width: 200,
				renderCell: (params: GridValueGetterParams) => {
					const status = params.row.showDiscAmt === 1 ? "Yes" : "No";
					return <span className={`role_badge ${status}`}>{status === "Yes" ? "Enabled" : "Disabled"}</span>;
				},
			},
			{
				field: "discRate",
				headerName: "Max Discount Rate %",
				width: 200,
				renderCell: (params: GridValueGetterParams) => (params.row.showDiscAmt === 1 ? params.row.discRate ?? 0 : "-"),
			},
		];

		if (entityMetadata) {
			const missingEntities = entityMetadata.filter((value) => value.active === 1);
			missingEntities.forEach((entity) => {
				if (entity.dataType === "contact") {
					columns.push({
						field: entity.fieldName + " Name",
						headerName: entity.fieldName + " Name",
						flex: 1,
						minWidth: 250,
						// render custom component
						renderCell: (params: GridValueGetterParams) => {
							const contact = params.row.customFieldsComplex?.find((field: any) => field.fieldID === entity.fieldID);

							return (
								<>
									{contact ? (
										<>
											<span style={{ fontSize: "14px" }}>{contact?.fieldValue}</span>
										</>
									) : (
										"-"
									)}
								</>
							);
						},
					});

					columns.push({
						field: entity.fieldName + " Number",
						headerName: entity.fieldName + " Number",
						flex: 1,
						minWidth: 250,
						// render custom component
						renderCell: (params: GridValueGetterParams) => {
							const contact = params.row.customFieldsComplex?.find((field: any) => field.fieldID === entity.fieldID);

							return (
								<>
									{contact
										? contact && contact?.fieldValue2 && contact?.fieldValue3
											? `+${contact?.fieldValue2} ${contact?.fieldValue3}`
											: "-"
										: "-"}
								</>
							);
						},
					});
				} else if (entity.dataType === "photo") {
					columns.push({
						field: entity.fieldName,
						headerName: entity.fieldName,
						flex: 1,
						minWidth: 200,
						renderCell: (params: GridValueGetterParams) => {
							const photos = params.row.customFieldsComplex?.find(
								(field: any) => field.fieldID === entity.fieldID
							)?.selectedFieldValues;
							return (
								<div style={{ display: "flex", gap: "0.5rem" }}>
									{photos?.length > 0
										? photos.map((photo: any) => (
												// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
												<img
													src={photo.fieldValue}
													key={photo.fieldValue}
													alt=""
													style={{
														flex: 1,
														height: "50px",
														width: "50px",
														objectFit: "cover",
														objectPosition: "center",
														border: "1px solid gray",
														borderRadius: "5px",
														cursor: "pointer",
													}}
													onClick={(e) => {
														e.stopPropagation();
														window.open(photo.fieldValue, "_blank");
													}}
												/>
										  ))
										: "-"}
								</div>
							);
						},
					});
				} else if (entity.dataType === "file") {
					columns.push({
						field: entity.fieldName,
						headerName: entity.fieldName,
						flex: 1,
						minWidth: 250,
						renderCell: (params: GridValueGetterParams) => {
							const photos = params.row.customFieldsComplex?.find(
								(field: any) => field.fieldID === entity.fieldID
							)?.selectedFieldValues;
							return (
								<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "0.3rem" }}>
									{photos?.length > 0
										? photos.map((photo: any) => (
												// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react/jsx-key
												<div
													style={{
														flex: 1,
														cursor: "pointer",
														padding: "0px 5px",
														border: "1px solid gray",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														gap: "0.2rem",
														fontSize: "10px",
														borderRadius: "30px",
													}}
													onClick={(e) => {
														e.stopPropagation();
														window.open(photo.fieldValue, "_blank");
													}}>
													<DownloadIcon style={{ fontSize: "18px" }} />
													{photo.fieldValue2}
												</div>
										  ))
										: "-"}
								</div>
							);
						},
					});
				} else if (entity.dataType === "form") {
					columns.push({
						field: entity.fieldID,
						headerName: entity.fieldName,
						flex: 1,
						minWidth: 200,
						renderCell: (params: GridValueGetterParams) => {
							const selectedField: CUSTOM_FIELD_COMPLEX_TYPE | null | undefined =
								params.row.customFieldsComplex && Array.isArray(params.row.customFieldsComplex)
									? params.row.customFieldsComplex.find(
											(field: CUSTOM_FIELD_COMPLEX_TYPE) => field?.fieldID === entity?.fieldID
									  )
									: null;
							if (selectedField === null || selectedField === undefined) return <>-</>;
							if (!selectedField.fieldValue || !selectedField.fieldValue2) return <>-</>;
							return (
								<a
									href={`${FORM_REDIRECT_URL}/${selectedField?.fieldValue}?createdTs=${selectedField?.fieldValue2}`}
									target="_blank"
									rel="noreferrer">
									Form Link
								</a>
							);
						},
					});
				} else
					columns.push({
						field: entity.fieldID,
						headerName: entity.fieldName,
						flex: 1,
						minWidth: 200,
						renderCell: (params: GridValueGetterParams) => ordersCustomColumn(params.row.customFieldsComplex, entity),
					});
			});
		}
		const action = {
			field: "action",
			headerName: "Action",
			width: 150,
			sortable: false,
			renderCell: (params: GridValueGetterParams) => (
				<DataGridActionButtons
					handleUserId={() => openSidebarForm(params.row.id)}
					name={params.row.skuName}
					deleteHandler={() => deleteExecutives(params.row.skuID)}
					userWritePermission={userWritePermission}
				/>
			),
		};
		const active = {
			field: "active",
			headerName: "Active",
			width: 80,
			sortable: false,
			renderCell: (params: GridValueGetterParams) => {
				const status = params.row.active ? "Yes" : "No";
				return <span className={`role_badge ${status}`}>{status}</span>;
			},
		};
		const team = {
			field: "visibleTo",
			headerName: "Visible To",
			width: 150,
			renderCell: (params: GridValueGetterParams) => {
				const visibility = params.row.visibility;
				let visibleTo = "-";
				if (visibility?.length === 0) {
					visibleTo = "-";
				} else if (visibility?.length === 1 && visibility[0].type === "EVERYONE" && visibility[0].value === 1) {
					visibleTo = "Everyone";
				} else {
					const profilesVisibleTo = visibility?.filter((item: any) => item.type === "PROFILE");

					const teams = profilesVisibleTo?.map((item: any) => {
						const team = profiles?.find((profile) => profile.profileID === item.value);
						if (team) return team.profileName;
					});
					visibleTo = [teams].join(", ");
				}
				return <span style={{fontSize:"0.8rem", fontFamily:"SFUIText-Medium,Helvetica,Arial,sans-serif"}}>{visibleTo}</span>;
			},
		};
		columns.push(team, active, action);
		return columns;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [products]);

	const deleteExecutives = async (skuID: string) => {
		const newProducts = products.filter((product) => product.skuID !== skuID);
		await deleteSKUMutation({ variables: { skuIDs: [skuID] } });
		dispatch(
			showNotification({
				message: error ? "Failed to delete Product" : "Selected Product Deleted Successfully",
				severity: error ? "error" : "success",
			})
		);
		setProducts(newProducts);
	};

	useEffect(() => {
		setColumns(tempColumns);
	}, [tempColumns]);

	const sortProducts = _.orderBy(products, [(item) => moment(item.lastModifiedTs)], ["desc"]);
	const getColum = useSelector((state: any) => state.dataGrid);
	const savedColumnConf = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.orders_products,
		DATA_GRID_CUSTOMIZATION_OPTIONS.columns_array
	);
	const savedColumnSortModel = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.orders_products,
		DATA_GRID_CUSTOMIZATION_OPTIONS.sort_model
	);
	const savedPinnedColumns = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.orders_products,
		DATA_GRID_CUSTOMIZATION_OPTIONS.pinned_column
	);
	const formattedcolumn = formatTheDataFromSlice(savedColumnConf, columns) || [];
	return (
		<div className="datagrid-table">
			<button className="filter__button" onClick={() => setExpandColumn((prev) => !prev)} aria-label="Filter">
				<img src="/images/filter.svg" alt="" />
			</button>
			<DataGridPro
				sx={DG_STYLES}
				rows={sortProducts}
				columns={formattedcolumn?.length > 0 ? formattedcolumn : columns}
				sortModel={savedColumnSortModel?.length > 0 ? savedColumnSortModel : []}
				pinnedColumns={savedPinnedColumns && Object.keys(savedPinnedColumns).length > 0 ? savedPinnedColumns : {}}
				loading={isLoading}
				pageSize={pageSize}
				getRowId={(row) => row.skuID}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[5, 10, 20, 50]}
				rowHeight={80}
				checkboxSelection
				disableSelectionOnClick
				disableColumnFilter
				onRowClick={(params) => userWritePermission && openSidebarForm(params.id as string)}
				onSelectionModelChange={(selectionModel) => setSelectedRows(selectionModel)}
				pagination
				components={{ ColumnMenu: CustomColumnMenu }}
				onColumnOrderChange={(params) => {
					const modifiedColumn = modifyColumOrder(
						DATA_GRID_NAMES.orders_products,
						params,
						formattedcolumn?.length > 0 ? formattedcolumn : columns
					);
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.orders_products,
						columnArray: modifiedColumn,
					};
					dispatch(setColumnConfigurations(newColumnConfigurations));
				}}
				onSortModelChange={(params) => {
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.orders_products,
						sortModel: params,
					};
					dispatch(setColumnSortModel(newColumnConfigurations));
				}}
				onColumnWidthChange={(params) => {
					const modifiedColumn = modifyColumnWidth(params, formattedcolumn?.length > 0 ? formattedcolumn : columns);
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.orders_products,
						columnArray: modifiedColumn,
					};
					dispatch(setColumnConfigurations(newColumnConfigurations));
				}}
				onPinnedColumnsChange={(params) => {
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.orders_products,
						pinnedColumns: params,
					};
					dispatch(setPinnedColumns(newColumnConfigurations));
				}}
			/>
			{expandColumn && (
				<RightSidebar expanded={expandColumn} setExpanded={setExpandColumn}>
					<ColumnModifierSidebar
						setExpanded={setExpandColumn}
						columns={columns}
						localString={DATA_GRID_NAMES.orders_products}
					/>
				</RightSidebar>
			)}
		</div>
	);
};

export default ProductsTable;
