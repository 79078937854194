import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useId, useMemo } from "react";
import { useDispatch } from "react-redux";
import { CONVEYANCE_TYPE } from "../../../@types";
import { showNotification } from "../../../redux";
import { findCurrentExpenseConveyanceAuth } from "../../../utils";
import { findNextApprovalAmount } from "../Conveyance/ConveyanceTable";
type Props = {
	request: CONVEYANCE_TYPE;
	setRequest: Dispatch<SetStateAction<CONVEYANCE_TYPE>>;
	canApprove: boolean;
};
const ConveyanceForm: FC<Props> = ({ request, setRequest, canApprove }) => {
	const [amount, comment] = useId();
	const dispatch = useDispatch();

	const isPending = request.status === -1;
	const currentConveyanceAuth = useMemo(() => findCurrentExpenseConveyanceAuth(request), [request]);
	if (isPending) {
		if (typeof currentConveyanceAuth === "string") {
			dispatch(showNotification({ message: currentConveyanceAuth, severity: "error" }));
		}
	}

	const amountClaimed = useMemo(
		() =>
			request.conveyanceDetails && Array.isArray(request.conveyanceDetails)
				? request.conveyanceDetails.reduce((acc: number, curr) => acc + curr.amountClaimed, 0)
				: 0,
		[request.conveyanceDetails]
	);
	const lastApprovedConveyance = findNextApprovalAmount(request);

	const status =
		request.status === 0
			? "Rejected"
			: request.status === 1
			? "Approved"
			: request.status === 2
			? "PaidOut"
			: "Pending";
	const icon =
		status === "Rejected" ? (
			<CloseOutlinedIcon htmlColor="#ffffff" />
		) : status === "Approved" ? (
			<DoneOutlinedIcon htmlColor="#ffffff" />
		) : (
			<CurrencyRupeeOutlinedIcon htmlColor="#ffffff" />
		);

	const changeExpenseValue = (e: ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		if (name === "reimbursementAmount" && isNaN(+value)) return;
		const updatedRequest = { ...request };
		const expenseAuthIndex = request.expenseAuth.findIndex((e) => e.actionPerformed === -1);
		updatedRequest.expenseAuth[expenseAuthIndex][name] = name === "comment" ? value : parseFloat(value);
		setRequest(updatedRequest);
	};
	useEffect(() => {
		const updatedRequest = { ...request };
		const expenseAuthIndex = request.expenseAuth.findIndex((e) => e.actionPerformed === -1);
		if (expenseAuthIndex === -1 || typeof currentConveyanceAuth === "string") return;
		updatedRequest.expenseAuth[expenseAuthIndex].reimbursementAmount =
			currentConveyanceAuth?.reimbursementAmount ?? lastApprovedConveyance;
		setRequest(updatedRequest);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentConveyanceAuth, lastApprovedConveyance, setRequest]);

	if (typeof currentConveyanceAuth === "string") return <></>;
	return (
		<div className="amount_claimed">
			<div className="claimed">
				<div className="amount">
					<picture className={status}>{icon}</picture>
					<div className="title">
						<p>Total Amount Claimed</p>
						<h1 className="rupee">{amountClaimed}</h1>
					</div>
				</div>
				<div className="with_label">
					<label htmlFor={amount}>Enter Approval Amount</label>
					<input
						id={amount}
						name="reimbursementAmount"
						placeholder="Enter Approval Amount"
						type="number"
						min={0}
						value={currentConveyanceAuth?.reimbursementAmount?.toString() ?? lastApprovedConveyance?.toString() ?? ""}
						disabled={!isPending || !canApprove}
						onChange={changeExpenseValue}
					/>
				</div>
			</div>
			<div className="form_control" style={{ position: "relative" }}>
				<label htmlFor={comment} style={{ position: "absolute", top: "12px", left: "10px" }}>
					Enter Comment
				</label>
				<input
					id={comment}
					name="comment"
					type="text"
					placeholder="Enter Comment"
					disabled={!isPending || !canApprove}
					onChange={changeExpenseValue}
					value={currentConveyanceAuth?.comment || ""}
				/>
			</div>
		</div>
	);
};

export default ConveyanceForm;
