/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Box, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState, useEffect, useRef } from "react";
import { AccordionData } from "../../../pages/clients_sites/clients";
import IndividualFilterVisibilityEmployee from "../../../components/common/datagrid/IndividualFilterVisibilityEmployee";
import IndividualFilterVisibilityTeam from "../../../components/common/datagrid/IndividualFilterVisibilityTeam";
type Props2 = {
	name: string;
	setFilterCount: Dispatch<SetStateAction<number>>;
	setParameters: Dispatch<
		React.SetStateAction<{
			skip: number;
			take: number;
			filters: AccordionData[];
		}>
	>;
	resetCheckBox: boolean;
	setResetCheckBox: Dispatch<SetStateAction<boolean>>;
	deleted: number;
	setDeleted: Dispatch<SetStateAction<number>>;
};
function IndividualFilterClients({
	name,
	setFilterCount,
	setParameters,
	resetCheckBox,
	setResetCheckBox,
	deleted,
	setDeleted,
}: Props2) {
	const [isChecked, setIsChecked] = useState<boolean>(false);
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (resetCheckBox === false) {
			setIsChecked(false)
			if (inputRef.current) {
				inputRef.current.value = "";
			}
		}
	}, [resetCheckBox]);

	const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>, fieldID: string) => {
		setResetCheckBox(true);
		setIsChecked(!isChecked);
		setDeleted(0);
		if (e.target.checked) {
			setFilterCount((prevState) => prevState + 1);
			setParameters((prevState) => {
				const fieldIndex = prevState.filters.findIndex((item) => item.key === fieldID);
				if (fieldIndex !== -1) {
					return {
						...prevState,
						filters: prevState.filters.map((item, index) => {
							if (index === fieldIndex) {
								return {
									...item,
									value: ["1"],
								};
							}
							return item;
						}),
					};
				} else {
					return {
						...prevState,
						filters: [...prevState.filters, { key: fieldID, value: ["1"] }],
					};
				}
			});
		} else {
			setFilterCount((prevState) => prevState - 1);
			setParameters((prevState) => ({
				...prevState,
				filters: prevState.filters.map((item) => {
					if (item.key === fieldID) {
						return {
							...item,
							value: ["0"],
						};
					}
					return item;
				}),
			}));
		}
	};

	return (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<h6>Visible To</h6>
			</AccordionSummary>
			<AccordionDetails sx={{ margin: 0, padding: 0 }}>
				<>
					<FormControlLabel
						sx={{
							border: "1px solid #dfdfdf",
							width: "100%",
							marginLeft: "15px",
							borderBottom: "None",
							height: "48px",
							display: "flex",
							justifyContent: "space-between",
							flexDirection: "row-reverse",
							padding: "0 23px 0 15px"
						}}
						label={
							<Typography variant="h6" style={{ fontSize: "14px" }}>
								Everyone
							</Typography>}

						control={
							<Checkbox
								size="small"
								checked={isChecked}
								value={"Everyone"}
								onChange={(e) => onChangeCheckbox(e, "visibility_everyone")}
							/>
						}

					// labelPlacement="start"
					/>
				</>
			</AccordionDetails>
			<Box sx={{ margin: 0, padding: 0, marginLeft: "15px", borderLeft: "1px solid #dfdfdf" }}>
				<IndividualFilterVisibilityEmployee
					name={"Employee"}
					setFilterCount={setFilterCount}
					setParameters={setParameters}
					resetCheckBox={resetCheckBox}
					setResetCheckBox={setResetCheckBox}
					deleted={deleted}
					setDeleted={setDeleted}
				/>
				<IndividualFilterVisibilityTeam
					name={"Team"}
					setFilterCount={setFilterCount}
					setParameters={setParameters}
					resetCheckBox={resetCheckBox}
					setResetCheckBox={setResetCheckBox}
					deleted={deleted}
					setDeleted={setDeleted}
				/>
			</Box>
		</Accordion>
	);
}
export default IndividualFilterClients;