import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import useFetch from "../useFetch";
import { PROFILES_DATA, USERS_DATA } from "../../@types";
import RightSidebar from "./RightSidebar";
import { GridCloseIcon } from "@mui/x-data-grid-pro";
import {
	Box,
	Button,
	Checkbox,
	Chip,
	CircularProgress,
	Dialog,
	DialogActions,
	FormControl,
	FormControlLabel,
	InputAdornment,
	InputLabel,
	ListItemText,
	ListSubheader,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	TextField,
	styled,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	autoFocus: false,
};

type Props = {
	expanded: boolean;
	setExpanded: Dispatch<SetStateAction<boolean>>;
	handleChange: any;
};
type ModalProps = {
	handleClose: () => void;
	handleChange: any;
	selectTeams: number[] | null;
};
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialog-paper": {
		maxWidth: "400px",
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(2),
	},
}));

const ChangeTeamsModal: FC<ModalProps> = ({ handleClose, handleChange, selectTeams }) => (
	<BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
		<div
			style={{
				display: "flex",
				padding: "16px",
				alignItems: "center",
				width: "100%",
				justifyContent: "center",
				flexDirection: "column",
			}}>
			<p className="font-m-16" style={{ lineHeight: "22px" }}>
				Are you sure you want to copy team settings to selected teams.
			</p>
		</div>
		<DialogActions
			sx={{
				justifyContent: "center",
				marginBottom: "1rem",
			}}>
			<Button
				variant="contained"
				onClick={(e) => {
					e.stopPropagation();
					handleClose();
				}}
				color="inherit">
				No
			</Button>
			<Button
				variant="contained"
				onClick={(e) => {
					e.stopPropagation();
					handleChange(null, "teamsSelectionChanges", selectTeams);
					handleClose();
				}}>
				Yes
			</Button>
		</DialogActions>
	</BootstrapDialog>
);

const TeamSelector = ({ expanded, setExpanded, handleChange }: Props) => {
	const { data: dataClient, error: errorClient } = useFetch<any>("/clients/clientCategories");
	const {
		data: dataProfiles,
		loading: loadingProfiles,
		error: errorProfiles,
	} = useFetch<PROFILES_DATA[]>("/users/profiles");
	const { data: dataUsers, error: errorUsers } = useFetch<any>("/users");

	const [profiles, setProfiles] = useState<PROFILES_DATA[]>([]);
	const users = useRef<USERS_DATA[]>([]);

	// component states
	const [searchTextforProfiles, setSearchTextforProfiles] = useState("");
	const [selectTeams, setSelectTeams] = useState<number[] | null>([]);
	const [allTeams, setAllTeams] = useState<number[] | null>([]);
	const [isAllChecked, setIsAllChecked] = useState(false);
	const [open, setOpen] = useState(false);

	// loading and error states
	const error = errorClient || errorProfiles || errorUsers;

	// check if all data is fetched
	const isData = dataClient?.clientCategories && dataProfiles?.data && dataUsers?.data;

	//funtions
	const containsText = (text: string, searchText: string) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
	const handleSelectProfileChange = (event: SelectChangeEvent<typeof selectTeams>) => {
		const {
			target: { value },
		} = event;
		/* @ts-ignore */
		setSelectTeams(typeof value === "string" ? value.split(",").map((number) => Number(number)) : value);
		setIsAllChecked(false);
	};
	const handleSelectProfileToAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		const isChecked = event.target.checked;
		// If 'All' is checked, call handleChange with allTeams
		if (isChecked) {
			setSelectTeams([]);
		}
		setIsAllChecked(isChecked);
	};

	//funtions for components
	const handleProfileListItems = (each: any) => (
		<MenuItem key={each.profileID} value={each.profileID}>
			{/* @ts-ignore */}
			<Checkbox checked={selectTeams.indexOf(each.profileID) > -1} />
			<ListItemText primary={`${each.profileName}`} />
		</MenuItem>
	);
	useEffect(() => {
		if (!loadingProfiles && !error && isData) {
			const profileIDs = dataProfiles?.data?.map((profile) => profile.profileID);
			setProfiles(dataProfiles.data);
			setAllTeams(profileIDs);
			users.current = dataUsers.data;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingProfiles, error, isData]);

	const displayedOptionsProfiles = useMemo(
		() => profiles.filter((option) => containsText(option.profileName, searchTextforProfiles)),
		[searchTextforProfiles, loadingProfiles]
	);

	return (
		<div>
			{expanded && (
				<RightSidebar expanded={expanded} setExpanded={setExpanded}>
					<div className="sd-top-header">
						<p style={{ fontSize: "16px" }}>Settings</p>
						<GridCloseIcon sx={{ cursor: "pointer" }} onClick={() => setExpanded(false)} />
					</div>
					<hr />
					{!loadingProfiles ? (
						<section style={{ width: "19rem" }}>
							<p
								style={{
									color: "gray",
									fontSize: "14px",
									lineHeight: "18px",
									fontFamily: "SFUIText-Regular, Helvetica, Arial, sans-serif",
								}}>
								<strong>Note:</strong> Please note that if you choose <strong>"All"</strong>, the team settings will be applied to all teams. If you
								select specific teams, the settings will only be applied to the selected teams.
							</p>
							<div className="group">
								<div className="d-flex align-items-center justify-content-between mt-4 mb-2">
									<p>Teams</p>
									<FormControlLabel
										sx={{ alignItems: "d-flex flex-end" }}
										control={<Checkbox size="small" checked={isAllChecked} onChange={handleSelectProfileToAll} />}
										label="All Teams"
									/>
								</div>

								<FormControl fullWidth>
									<InputLabel id="team">Select Teams</InputLabel>
									<Select
										labelId="team"
										id="team"
										multiple
										value={selectTeams?.filter((each) => each !== 1)}
										onClose={() => setSearchTextforProfiles("")}
										placeholder="select teams"
										onChange={handleSelectProfileChange}
										input={<OutlinedInput id="select-multiple-chip" label="Select Teams" />}
										renderValue={(selected) => (
											<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
												{selected.map((value: any) => {
													const currentUser = profiles?.find((d) => d.profileID === value);
													return <Chip key={value} label={`${currentUser?.profileName}`} />;
												})}
											</Box>
										)}
										MenuProps={MenuProps}>
										<ListSubheader>
											<TextField
												size="small"
												placeholder="Type to search..."
												fullWidth
												value={searchTextforProfiles}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<SearchIcon />
														</InputAdornment>
													),
													style: { border: "none", outline: "none" },
												}}
												onChange={(e) => setSearchTextforProfiles(e.target.value)}
												onKeyDown={(e) => {
													if (e.key !== "Escape") {
														e.stopPropagation();
													}
												}}
											/>
										</ListSubheader>
										<MenuItem disabled={true}>
											<ListItemText primary={<em>You can select multiple teams at once.</em>} />
										</MenuItem>
										{/* show default list of profiles if no search term */}
										{searchTextforProfiles.length === 0
											? profiles?.map((each) => handleProfileListItems(each))
											: displayedOptionsProfiles?.map((each) => handleProfileListItems(each))}
									</Select>
								</FormControl>
							</div>
							<div className="action-button">
								<button type="reset" onClick={() => setExpanded(false)}>
									Cancel
								</button>
								<button type="submit" disabled={(selectTeams?.length||isAllChecked) ? false : true} onClick={() => setOpen(true)}>
									Save
								</button>
							</div>
						</section>
					) : (
						<div className="centerise_the_content">
							<CircularProgress />
						</div>
					)}
				</RightSidebar>
			)}
			{open && (
				<ChangeTeamsModal
					handleClose={() => setOpen(false)}
					handleChange={handleChange}
					selectTeams={isAllChecked ? allTeams : selectTeams}
				/>
			)}
		</div>
	);
};

export default TeamSelector;
