import {
	DataGridPro,
	GridColDef,
	GridRowParams,
	GridSelectionModel,
	GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import "lodash";
import "moment-timezone";
import { useRouter } from "next/router";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { PARTIAL_FORM_TEMPLATE } from "../../../@types";
import { DG_STYLES } from "../../../constants";
import CustomColumnMenu from "../../common/datagrid/CustomColumnMenu";
import DataGridActionButtons from "../../common/datagrid/DatagridActionButton";
import useAdminEntitlements from "../../useAdminEntitlements";
import { formatDateTo_DDMMYYYY } from "../../../utils/validations";

type Props = {
	setSelectedRows: Dispatch<SetStateAction<GridSelectionModel>>;
	forms: PARTIAL_FORM_TEMPLATE[];
	setForms: Dispatch<SetStateAction<PARTIAL_FORM_TEMPLATE[]>>;
	loading: boolean;
	selectedRows: any;
	deleteForm: (id: string) => void;
};
const FormsTable: FC<Props> = ({ setSelectedRows, forms, deleteForm, loading, selectedRows }) => {
	const [pageSize, setPageSize] = useState<number>(20);
	const router = useRouter();
	const userWritePermission = useAdminEntitlements("forms:forms", "write");
	const tempColumns = useMemo(
		() => [
			{
				field: "formTitle",
				headerName: "Form Title",
				flex: 1,
				minWidth: 200,
			},
			{
				field: "formDescription",
				headerName: "Form Description",
				flex: 2,
				minWidth: 200,
			},
			{
				field: "lastModifiedTs",
				headerName: "Last Modified At",
				flex: 1,
				minWidth: 130,
				renderCell: (params: GridValueGetterParams) => (
					<time>{formatDateTo_DDMMYYYY(params.row.lastModifiedTs)}</time>
				),
			},
			{
				field: "createdTs",
				headerName: "Create At",
				flex: 1,
				minWidth: 120,
				renderCell: (params: GridValueGetterParams) => (
					<time>{formatDateTo_DDMMYYYY(params.row.createdTs)}</time>
				),
			},
			{
				field: "action",
				headerName: "Action",
				minWidth: 100,
				renderCell: (params: GridValueGetterParams) =>
					params.row.formTemplateID !== "b3a09fb7-97db-4846-b80a-cc46e71e1b47" ? (
						<DataGridActionButtons
							link={`/form/${params.row.formTemplateID}`}
							name={params.row.formTitle}
							deleteHandler={() => deleteForm(params.row.formTemplateID)}
							moduleName="form"
							data={[params.row.formTemplateID]}
							userWritePermission={userWritePermission}
						/>
					) : null,
			},
		],
		[forms, userWritePermission]
	);
	const [columns, setColumns] = useState<GridColDef[]>([]);

	useEffect(() => {
		setColumns(tempColumns);
	}, [tempColumns]);

	return (
		<div className="datagrid-table">
			<DataGridPro
				sx={DG_STYLES}
				rows={forms}
				columns={columns}
				loading={loading}
				pageSize={pageSize}
				getRowId={(row) => row.formTemplateID}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[5, 10, 20, 50]}
				rowHeight={70}
				checkboxSelection
				disableSelectionOnClick
				disableColumnFilter
				pagination
				onRowClick={(params: GridRowParams) => userWritePermission && router.push(`/form/${params.id}`)}
				onSelectionModelChange={(selectionModel) => setSelectedRows(selectionModel)}
				selectionModel={selectedRows}
				components={{
					ColumnMenu: CustomColumnMenu,
				}}
			/>
		</div>
	);
};

export default FormsTable;
