import { DataGridPro, GridColDef, GridSelectionModel, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ADVANCE_REQUEST_TYPE, CUSTOM_FIELD_COMPLEX_TYPE, ENTITY_METADATA, PROFILES_DATA } from "../../../@types";
import { DATA_GRID_CUSTOMIZATION_OPTIONS, DATA_GRID_NAMES, DG_STYLES } from "../../../constants";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import ColumnModifierSidebar from "../../common/datagrid/ColumnModifierSidebar";
import CustomColumnMenu from "../../common/datagrid/CustomColumnMenu";
import RightSidebar from "../../common/RightSidebar";
import useAdminEntitlements from "../../useAdminEntitlements";
import AdvanceActionButtons from "./AdvanceActionButtons";
import { APPROVE_OR_REJECT_ADVANCE, MARK_ADVANCE_PAID_OUT } from "../../../schema/expenses";
import { useMutation } from "@apollo/client";
import DownloadIcon from "@mui/icons-material/Download";
import {
	FORM_REDIRECT_URL,
	advanceCustomColumns,
	formatTheDataFromSlice,
	getTheSavedColumns,
	modifyColumOrder,
	modifyColumnWidth,
} from "../../../utils";
import { setColumnConfigurations, setColumnSortModel, setPinnedColumns } from "../../../redux/slices/dataGridSlice";
import { formatDateTo_DDMMYYYY } from "../../../utils/validations";
import CustomDataGridExport from "../../common/datagrid/CustomDataGridExport";

type Props = {
	requests: ADVANCE_REQUEST_TYPE[];
	filteredRequests: ADVANCE_REQUEST_TYPE[];
	setRequests: Dispatch<SetStateAction<ADVANCE_REQUEST_TYPE[]>>;
	setSelectedRows: Dispatch<SetStateAction<GridSelectionModel>>;
	loading: boolean;
	setSelectedExpenseId: Dispatch<SetStateAction<string | null>>;
	refetch: () => void;
	profiles: PROFILES_DATA[];
	entityMetadata: ENTITY_METADATA[];
	currencyCode: string;
	currencyLoading: boolean;
};
const CONVEYANCE_MODE = {
	0: "CAR",
	1: "BIKE",
	2: "PUBLIC TRANSPORT",
};

const findProposedAmount = (request: ADVANCE_REQUEST_TYPE) => request.amountProposed;

export const findLastApprovedAmount = (request: ADVANCE_REQUEST_TYPE): number | null => {
	//find the last approved index
	const advanceAuthIndex = request.authorizationInfo.history.findIndex((e) => e.actionPerformed === 1);
	let amountToBeApproved;
	//we will get it the lastApprovedAmount
	if (advanceAuthIndex !== -1 && request.authorizationInfo.lastApprovedAmount) {
		amountToBeApproved = request.authorizationInfo.lastApprovedAmount;
	} else {
		//if we dot get the lastApprovedAmount then we will take all the amount as proposed
		amountToBeApproved = request.amountProposed || 0;
	}
	return amountToBeApproved;
};

const AdvanceRequestTable: FC<Props> = ({
	requests,
	setSelectedRows,
	filteredRequests,
	loading,
	setSelectedExpenseId,
	refetch,
	entityMetadata,
	currencyCode,
	currencyLoading,
}) => {
	const [isUpdating, setIsUpdating] = useState(false);
	const [pageSize, setPageSize] = useState(20);
	const user = useSelector((state: ROOT_STATE) => state.user);
	const dispatch = useDispatch<APP_DISPATCH>();
	const [expandColumn, setExpandColumn] = useState(false);
	const userWritePermission = useAdminEntitlements("expense:advanceRequests", "write");
	const [columns, setColumns] = useState<GridColDef[]>([]);

	const [approve_or_reject_advance, { loading: isUpdated, error: authError }] = useMutation(APPROVE_OR_REJECT_ADVANCE);
	const [mark_advance_paid_out, { loading: isMarked }] = useMutation(MARK_ADVANCE_PAID_OUT);

	const tempColumns = useMemo(
		() => {
			const cols: any = [
				{
					field: "duration",
					headerName: "Duration",
					width: 250,
					sortable: false,
					renderCell: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) => {
						const fromDateParts = params.row.fromDate.split("-");
						const toDateParts = params.row.toDate.split("-");

						const formattedFromDate = `${fromDateParts[2]}-${fromDateParts[1]}-${fromDateParts[0]}`;
						const formattedToDate = `${toDateParts[2]}-${toDateParts[1]}-${toDateParts[0]}`;

						return (
							<>
								{formattedFromDate} to {formattedToDate}
							</>
						);
					},
					valueGetter: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) => {
						const fromDateParts = params.row.fromDate.split("-");
						const toDateParts = params.row.toDate.split("-");

						const formattedFromDate = `${fromDateParts[2]}-${fromDateParts[1]}-${fromDateParts[0]}`;
						const formattedToDate = `${toDateParts[2]}-${toDateParts[1]}-${toDateParts[0]}`;

						return `${formattedFromDate} to ${formattedToDate}`;
					},
				},
				{
					field: "firstName",
					headerName: "Employee Name",
					width: 150,
					valueGetter: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) =>
						params.row.userInfo?.firstName + " " + params.row.userInfo?.lastName,
				},
				{
					field: "designationName",
					headerName: "Designation",
					width: 150,
					renderCell: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) => (
						<>{params.row?.userInfo?.designationName}</>
					),
				},
				{
					field: "description",
					headerName: "Description",
					width: 250,
				},
				{
					field: "categories",
					headerName: "Categories",
					width: 200,
					sortable: false,
					renderCell: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) => {
						const expenseCat =
							params.row.advanceExpenseDetails && Array.isArray(params.row.advanceExpenseDetails)
								? params.row.advanceExpenseDetails.map((exp) => (
										<div key={exp.advanceExpenseID}>
											<strong>{exp.category}: </strong>
											<span className="rupee">{exp.amountProposed}</span>
										</div>
								  ))
								: ["-"];
						return <div className="expense_categories">{expenseCat.map((cat) => cat)}</div>;
					},
					valueGetter: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) => {
						const expenseCat =
							params.row.advanceExpenseDetails && Array.isArray(params.row.advanceExpenseDetails)
								? params.row.advanceExpenseDetails.map((exp) => `${exp.category}: ${exp.amountProposed}`)
								: ["-"];
						return expenseCat.join(" | ");
					},
				},
				{
					field: "mode",
					headerName: "Mode",
					width: 200,
					sortable: false,
					renderCell: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) => {
						const expenseCat =
							params.row.advanceConveyanceDetails && Array.isArray(params.row.advanceConveyanceDetails)
								? params.row.advanceConveyanceDetails.map((exp) => (
										<div key={exp.advanceConveyanceID}>
											<strong>{CONVEYANCE_MODE[exp.mode]}: </strong>
											<span className="rupee">{exp.amountProposed}</span>
										</div>
								  ))
								: ["-"];
						return <div className="expense_categories">{expenseCat.map((cat) => cat)}</div>;
					},
					valueGetter: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) => {
						const expenseCat =
							params.row.advanceConveyanceDetails && Array.isArray(params.row.advanceConveyanceDetails)
								? params.row.advanceConveyanceDetails.map(
										(exp) => `${CONVEYANCE_MODE[exp.mode]} : ${exp.amountProposed}`
								  )
								: ["-"];
						return expenseCat.join(" | ");
					},
				},
				{
					field: "amountProposed",
					headerName: `Amount Proposed (${currencyCode})`,
					width: 200,
					renderCell: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) => {
						const amount = findProposedAmount(params.row);
						return <span className="rupee">{amount ? amount : "-"}</span>;
					},
				},
				{
					field: "allottedAmount",
					headerName: `Last Approved Amount(${currencyCode})`,
					width: 200,
					renderCell: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) => (
						<span className="rupee">{params.row?.authorizationInfo?.lastApprovedAmount ?? "-"}</span>
					),
					valueGetter: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) =>
						params.row?.authorizationInfo?.lastApprovedAmount ? params.row?.authorizationInfo?.lastApprovedAmount : "-",
				},
				{
					field: "comment",
					headerName: "Comment",
					width: 200,
					sortable: false,
					renderCell: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) => {
						if (params.row.authorizationInfo.history) {
							const firstAuth = params.row.authorizationInfo.history.at(0)?.comment || "-";
							return firstAuth;
						}
					},
					valueGetter: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) => {
						if (params.row.authorizationInfo.history) {
							const firstAuth = params.row.authorizationInfo.history.at(0)?.comment || "-";
							return firstAuth;
						}
					},
				},
				{
					field: "pendingWith",
					headerName: "Pending With",
					width: 150,
					valueGetter: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) =>
						typeof params.row.authorizationInfo?.pendingAdmin?.adminName === "string"
							? params.row.authorizationInfo?.pendingAdmin?.adminName
							: "-",
				},
				{
					field: "profileID",
					headerName: "Team",
					width: 200,
					valueGetter: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) => params.row.userInfo?.profileName,
				},
				{
					field: "createdTs",
					headerName: "Submission Date",
					width: 150,
					renderCell: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) =>
						params.row.createdTs ? formatDateTo_DDMMYYYY(params.row.createdTs) : "-",
					valueGetter: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) =>
						params.row.createdTs ? formatDateTo_DDMMYYYY(params.row.createdTs) : "-",
				},
				{
					field: "lastModifiedTs",
					headerName: "Last Modified At",
					width: 150,
					renderCell: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) => {
						const lastModifiedTs = Math.max(
							params.row.lastModifiedTs,
							params.row.authorizationInfo.history.at(0)?.lastModifiedTs ?? 0
						);
						return <>{formatDateTo_DDMMYYYY(lastModifiedTs)}</>;
					},
					valueGetter: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) => {
						const lastModifiedTs = Math.max(
							params.row.lastModifiedTs,
							params.row.authorizationInfo.history.at(0)?.lastModifiedTs ?? 0
						);
						return lastModifiedTs ? formatDateTo_DDMMYYYY(lastModifiedTs) : "-";
					},
				},
				{
					field: "type",
					headerName: "Type",
					width: 150,
					renderCell: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) =>
						params.row.type ? "Conveyance" : "Expense",
					valueGetter: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) =>
						params.row.type ? "Conveyance" : "Expense",
				},
				{
					field: "status",
					headerName: "Status",
					width: 130,
					renderCell: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) => {
						const status =
							params.row.status === -1
								? "Pending"
								: params.row.status === 0
								? "Rejected"
								: params.row.status === 1
								? "Approved"
								: params.row.status === 2
								? "Paid Out"
								: params.row.status === 3
								? "In Progress"
								: params.row.status === 4
								? "Deleted"
								: "Pending";
						return <span className={`role_badge ${status}`}>{status}</span>;
					},
					valueGetter: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) => {
						const status =
							params.row.status === -1
								? "Pending"
								: params.row.status === 0
								? "Rejected"
								: params.row.status === 1
								? "Approved"
								: params.row.status === 2
								? "Paid Out"
								: params.row.status === 3
								? "In Progress"
								: params.row.status === 4
								? "Deleted"
								: "Pending";
						return status;
					},
				},
				{
					field: "action",
					headerName: "Action",
					width: 150,
					sortable: false,
					disableExport: true,
					renderCell: (params: GridValueGetterParams<any, ADVANCE_REQUEST_TYPE>) => (
						<AdvanceActionButtons
							isMarked={isMarked}
							isUpdated={isUpdated}
							status={params.row.status}
							canAdminApprove={
								params.row.authorizationInfo?.pendingAdmin?.adminID === user.adminDetails.adminID && !isMarked
							}
							approveRequest={(reason: string, amount?: number) =>
								approveRequest(params.row.advanceSummaryID, reason, amount)
							}
							amountAlloted={findLastApprovedAmount(params.row) || 0}
							rejectRequest={(reason: string, amount?: number) =>
								rejectRequest(params.row.advanceSummaryID, reason, amount)
							}
							name={params.row.userInfo?.firstName + " " + params.row.userInfo?.lastName}
							canAdminPayout={
								params.row.status === 1 &&
								params.row.authorizationInfo?.payoutAdminID === user.adminDetails.adminID &&
								!isUpdated
							}
							paidOutCallback={() => paidOutRequest(params.row.advanceSummaryID)}
							userWritePermission={userWritePermission}
						/>
					),
				},
			];
			if (entityMetadata) {
				const missingEntities = entityMetadata.filter((value) => value.active === 1);

				// Find the index of the "Pending With" column
				const pendingWithIndex = cols.findIndex((column: any) => column.field === "pendingWith");

				// If "Pending With" column is found, insert customFields columns before it
				if (pendingWithIndex !== -1) {
					missingEntities.forEach((entity) => {
						if (entity.dataType === "contact") {
							cols.splice(pendingWithIndex, 0, {
								field: entity.fieldName + " Number",
								headerName: entity.fieldName + " Number",
								flex: 1,
								minWidth: 250,
								// render custom component
								renderCell: (params: GridValueGetterParams) => {
									const contact = params.row.customFieldsComplex?.find(
										(field: any) => field.fieldID === entity.fieldID
									);

									return (
										<>
											{contact
												? contact && contact?.fieldValue3
													? `${contact?.fieldValue2 ? "+" : ""}${contact?.fieldValue2 ?? ""} ${contact?.fieldValue3}`
													: "-"
												: "-"}
										</>
									);
								},
							});

							cols.splice(pendingWithIndex, 0, {
								field: entity.fieldName + " Name",
								headerName: entity.fieldName + " Name",
								flex: 1,
								minWidth: 250,
								// render custom component
								renderCell: (params: GridValueGetterParams) => {
									const contact = params.row.customFieldsComplex?.find(
										(field: any) => field.fieldID === entity.fieldID
									);

									return (
										<>
											{contact ? (
												<>
													<span style={{ fontSize: "14px" }}>{contact?.fieldValue}</span>
												</>
											) : (
												"-"
											)}
										</>
									);
								},
							});
						} else if (entity.dataType === "photo") {
							cols.splice(pendingWithIndex, 0, {
								field: entity.fieldName,
								headerName: entity.fieldName,
								flex: 1,
								minWidth: 200,
								renderCell: (params: GridValueGetterParams) => {
									const photos = params.row.customFieldsComplex?.find(
										(field: any) => field.fieldID === entity.fieldID
									)?.selectedFieldValues;
									return (
										<div style={{ display: "flex", gap: "0.5rem" }}>
											{photos?.length > 0
												? photos.map((photo: any) => (
														// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
														<img
															src={photo.fieldValue}
															key={photo.fieldValue}
															alt=""
															style={{
																flex: 1,
																height: "50px",
																width: "50px",
																objectFit: "cover",
																objectPosition: "center",
																border: "1px solid gray",
																borderRadius: "5px",
																cursor: "pointer",
															}}
															onClick={(e) => {
																e.stopPropagation();
																window.open(photo.fieldValue, "_blank");
															}}
														/>
												  ))
												: "-"}
										</div>
									);
								},
								valueGetter: (params: GridValueGetterParams) => {
									const photos = params.row.customFieldsComplex?.find(
										(field: any) => field.fieldID === entity.fieldID
									)?.selectedFieldValues;
									return photos?.length > 0 ? photos.map((photo: any) => photo.fieldValue).join(", ") : "-";
								},
							});
						} else if (entity.dataType === "file") {
							cols.splice(pendingWithIndex, 0, {
								field: entity.fieldName,
								headerName: entity.fieldName,
								flex: 1,
								minWidth: 250,
								renderCell: (params: GridValueGetterParams) => {
									const photos = params.row.customFieldsComplex?.find(
										(field: any) => field.fieldID === entity.fieldID
									)?.selectedFieldValues;
									return (
										<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "0.3rem" }}>
											{photos?.length > 0
												? photos.map((photo: any) => (
														// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react/jsx-key
														<div
															style={{
																flex: 1,
																cursor: "pointer",
																padding: "0px 5px",
																border: "1px solid gray",
																display: "flex",
																justifyContent: "center",
																alignItems: "center",
																gap: "0.2rem",
																fontSize: "10px",
																borderRadius: "30px",
															}}
															onClick={(e) => {
																e.stopPropagation();
																window.open(photo.fieldValue, "_blank");
															}}>
															<DownloadIcon style={{ fontSize: "18px" }} />
															{photo.fieldValue2}
														</div>
												  ))
												: "-"}
										</div>
									);
								},
								valueGetter: (params: GridValueGetterParams) => {
									const photos = params.row.customFieldsComplex?.find(
										(field: any) => field.fieldID === entity.fieldID
									)?.selectedFieldValues;
									return photos?.length > 0 ? photos.map((photo: any) => photo.fieldValue).join(", ") : "-";
								},
							});
						} else if (entity.dataType === "form") {
							cols.splice(pendingWithIndex, 0, {
								field: entity.fieldName,
								headerName: entity.fieldName,
								flex: 1,
								minWidth: 200,
								renderCell: (params: GridValueGetterParams) => {
									const selectedField: CUSTOM_FIELD_COMPLEX_TYPE | null | undefined =
										params.row.customFieldsComplex && Array.isArray(params.row.customFieldsComplex)
											? params.row.customFieldsComplex.find(
													(field: CUSTOM_FIELD_COMPLEX_TYPE) => field?.fieldID === entity?.fieldID
											  )
											: null;
									if (selectedField === null || selectedField === undefined) return <>-</>;
									if (!selectedField.fieldValue || !selectedField.fieldValue2) return <>-</>;
									return (
										<a
											href={`${FORM_REDIRECT_URL}/${selectedField?.fieldValue}?createdTs=${selectedField?.fieldValue2}`}
											target="_blank"
											rel="noreferrer">
											Form Link
										</a>
									);
								},
								valueGetter: (params: GridValueGetterParams) => {
									const selectedField: CUSTOM_FIELD_COMPLEX_TYPE | null | undefined =
										params.row.customFieldsComplex && Array.isArray(params.row.customFieldsComplex)
											? params.row.customFieldsComplex.find(
													(field: CUSTOM_FIELD_COMPLEX_TYPE) => field?.fieldID === entity?.fieldID
											  )
											: null;
									if (selectedField === null || selectedField === undefined) return "-";
									if (!selectedField.fieldValue || !selectedField.fieldValue2) return "-";
									return `${FORM_REDIRECT_URL}/${selectedField?.fieldValue}?createdTs=${selectedField?.fieldValue2}`;
								},
							});
						} else {
							cols.splice(pendingWithIndex, 0, {
								field: entity.fieldName,
								headerName: entity.fieldName,
								flex: 1,
								minWidth: 200,
								renderCell: (params: GridValueGetterParams) => (
									<>{advanceCustomColumns(params.row.customFieldsComplex, entity)}</>
								),
							});
						}
					});
				}
			}
			return cols;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[requests, user.currencyCode, currencyLoading, entityMetadata, userWritePermission]
	);

	// to get the columns for the grid either from local or default columns

	useEffect(() => {
		if (!currencyLoading) setColumns(tempColumns);
	}, [tempColumns, currencyLoading]);

	const approveRequest = async (id: string, reason: string, amount?: number) => {
		if (!id || id.length === 0 || amount === undefined || isNaN(amount)) {
			dispatch(showNotification({ message: "Enter an amount", severity: "error" }));
			return;
		}
		const requestIndex = requests.findIndex((r) => r.advanceSummaryID === id);
		if (requestIndex < 0) return;
		setIsUpdating(true);
		const advancesAuthorizationInput = [
			{
				advanceSummaryID: id,
				allottedAmount: amount,
			},
		];
		const status = 1;
		const rejectReason = null; // it can be for approved/rejected reason
		const comment = reason;
		const data = await approve_or_reject_advance({
			variables: {
				advancesAuthorizationInput,
				status,
				rejectReason,
				comment,
			},
		});

		if (data.errors || authError) {
			dispatch(
				showNotification({
					message: "Couldn't Approve Request",
					severity: "error",
				})
			);
		} else {
			dispatch(
				showNotification({
					message: "Successfully Approved Selected Request",
					severity: "success",
				})
			);
			refetch();
		}
		setIsUpdating(false);
	};
	const rejectRequest = async (id: string, reason: string, amount?: number) => {
		if (!id || id.length === 0) return;
		const requestIndex = requests.findIndex((r) => r.advanceSummaryID === id);
		if (requestIndex < 0) return;
		setIsUpdating(true);
		const advancesAuthorizationInput = [
			{
				advanceSummaryID: id,
				allottedAmount: null,
			},
		];
		const status = 0;
		const rejectReason = reason; // it can be for approved/rejected reason
		const comment = reason;
		const data = await approve_or_reject_advance({
			variables: {
				advancesAuthorizationInput,
				status,
				rejectReason,
				comment,
			},
		});
		if (data.errors || authError) {
			dispatch(
				showNotification({
					message: "Couldn't Reject Request",
					severity: "error",
				})
			);
		} else {
			dispatch(
				showNotification({
					message: "Successfully Rejected Selected Request",
					severity: "success",
				})
			);
			refetch();
		}
		setIsUpdating(false);
	};

	const paidOutRequest = async (id: string) => {
		if (!id || id.length === 0) return;
		const requestIndex = requests.findIndex((r) => r.advanceSummaryID === id);
		if (requestIndex < 0) return;
		setIsUpdating(true);
		const advanceSummaryIDs = id;
		const data = await mark_advance_paid_out({ variables: { advanceSummaryIDs } });
		if (data.errors || authError) {
			dispatch(
				showNotification({
					message: "Couldn't Reject Request",
					severity: "error",
				})
			);
		} else {
			dispatch(
				showNotification({
					message: "Successfully Paid Out Selected Request",
					severity: "success",
				})
			);
			refetch();
		}

		setIsUpdating(false);
	};

	const getColum = useSelector((state: any) => state.dataGrid);
	const savedColumnConf = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.expense_advance_request,
		DATA_GRID_CUSTOMIZATION_OPTIONS.columns_array
	);
	const savedColumnSortModel = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.expense_advance_request,
		DATA_GRID_CUSTOMIZATION_OPTIONS.sort_model
	);
	const savedPinnedColumns = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.expense_advance_request,
		DATA_GRID_CUSTOMIZATION_OPTIONS.pinned_column
	);
	const formattedcolumn = formatTheDataFromSlice(savedColumnConf, columns) || [];

	return (
		<div className="datagrid-table">
			<button className="filter__button" onClick={() => setExpandColumn((prev) => !prev)} aria-label="filter">
				<img src="/images/filter.svg" alt="" />
			</button>
			<DataGridPro
				sx={DG_STYLES}
				rows={filteredRequests}
				getRowId={(row) => row.advanceSummaryID}
				columns={formattedcolumn?.length > 0 ? formattedcolumn : columns}
				sortModel={savedColumnSortModel?.length > 0 ? savedColumnSortModel : []}
				pinnedColumns={savedPinnedColumns && Object.keys(savedPinnedColumns).length > 0 ? savedPinnedColumns : {}}
				loading={loading || isUpdating}
				pageSize={pageSize}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[5, 10, 20, 50]}
				rowHeight={70}
				checkboxSelection
				disableSelectionOnClick
				onRowClick={(params) => setSelectedExpenseId(params.id as string)}
				disableColumnFilter
				pagination
				onSelectionModelChange={(selectionModel) => setSelectedRows(selectionModel)}
				components={{
					ColumnMenu: CustomColumnMenu,
					Toolbar: () => <CustomDataGridExport fileName="Advances_requests_data" />,
				}}
				onColumnOrderChange={(params) => {
					const modifiedColumn = modifyColumOrder(
						DATA_GRID_NAMES.expense_advance_request,
						params,
						formattedcolumn?.length > 0 ? formattedcolumn : columns
					);
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.expense_advance_request,
						columnArray: modifiedColumn,
					};
					dispatch(setColumnConfigurations(newColumnConfigurations));
				}}
				onSortModelChange={(params) => {
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.expense_advance_request,
						sortModel: params,
					};
					dispatch(setColumnSortModel(newColumnConfigurations));
				}}
				onColumnWidthChange={(params) => {
					const modifiedColumn = modifyColumnWidth(params, formattedcolumn?.length > 0 ? formattedcolumn : columns);
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.expense_advance_request,
						columnArray: modifiedColumn,
					};
					dispatch(setColumnConfigurations(newColumnConfigurations));
				}}
				onPinnedColumnsChange={(params) => {
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.expense_advance_request,
						pinnedColumns: params,
					};
					dispatch(setPinnedColumns(newColumnConfigurations));
				}}
			/>

			{expandColumn && (
				<RightSidebar expanded={expandColumn} setExpanded={setExpandColumn}>
					<ColumnModifierSidebar
						setExpanded={setExpandColumn}
						columns={columns}
						localString={DATA_GRID_NAMES.expense_advance_request}
					/>
				</RightSidebar>
			)}
		</div>
	);
};

export default AdvanceRequestTable;
