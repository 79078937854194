import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import BarChartIcon from "@mui/icons-material/BarChart";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import TouchAppOutlinedIcon from "@mui/icons-material/TouchAppOutlined";
import { useRouter } from "next/router";
import { FC } from "react";
import { Layout, SubmoduleTabs } from "..";
import { SUBMODULE_DATA } from "../../@types";
import NotAuthorized from "../common/NotAuthorized";
import useAdminEntitlements from "../useAdminEntitlements";
import { useSelector } from "react-redux";
import { ROOT_STATE } from "../../redux";
import RecentActorsIcon from "@mui/icons-material/RecentActors";

type Props = {
	title: string;
	children: any;
	tab: number;
};
const AttendanceLayout: FC<Props> = ({ title, children, tab }) => {
	// const leavesV2Active = useAdminCompanySettings("isLeavesV2Enabled");
	//commented for now @Aditya need to check
	// const leavesV2Active = true;

	const attendanceSubmoduleData: SUBMODULE_DATA[] = [
		{
			label: "Overview",
			icon: <BarChartIcon />,
			path: "/attendance",
		},
		{
			label: "Shifts",
			icon: <AccessTimeIcon />,
			path: "/attendance/shifts",
		},
		// !leavesV2Active
		// 	? {
		// 			label: "Attendance Status",
		// 			icon: <EditOutlinedIcon />,
		// 			path: "/attendance/status",
		// 	  }
		// 	: {
		// 			label: "Attendance Status",
		// 			icon: <EditOutlinedIcon />,
		// 			path: "/attendance/status-beta",
		// 	  },
		{
			label: "Attendance Status",
			icon: <EditOutlinedIcon />,
			path: "/attendance/status-beta",
		},
		{
			label: "Roster",
			icon: <AssignmentIndOutlinedIcon />,
			path: "/attendance/rosters",
		},
		// !leavesV2Active
		// 	? {
		// 		label: "Leaves",
		// 		icon: <PersonOffOutlinedIcon />,
		// 		path: "/attendance/leaves",
		// 	}
		// 	: undefined,
		{
			label: "Override Request",
			icon: <TouchAppOutlinedIcon />,
			path: "/attendance/override",
		},
		{
			label: "Punch Event Record",
			icon: <RecentActorsIcon />,
			path: "/attendance/record",
		},
	];

	const {
		susbscriptionMetadata: { data: subsMetadata },
	} = useSelector((state: ROOT_STATE) => ({
		susbscriptionMetadata: state.susbscriptionMetadata,
	}));

	attendanceSubmoduleData.forEach((item: any) => {
		if (item && item.label === "Roster") {
			if (subsMetadata?.rosters === 0) {
				item.hide = true;
			}
		}
		else if (item && item.label === "Punch Event Record") {
			if (subsMetadata?.punchEventRecord === 0) {
				item.hide = true;
			}
		}
		else if (item && item.label === "Override Request") {
			if (subsMetadata?.attendanceOverrideRequest === 0) {
				item.hide = true;
			}
		}
		else {
			if (item) item.hide = false;
		}
	});

	const userReadPermissionAS = useAdminEntitlements("attendance:attendanceStatus", "read");
	const userReadPermissionShifts = useAdminEntitlements("attendance:shifts", "read");
	const userReadPermissionRosters = useAdminEntitlements("attendance:rosters", "read");
	const userReadPermissionOverview = useAdminEntitlements("attendance:overview", "read");
	const userReadPermissionAO = useAdminEntitlements("attendance:overrideRequest", "read");

	const router = useRouter();
	const isAORead = router.pathname === "/attendance" && userReadPermissionOverview;
	// const isASRead = router.pathname === "/attendance/status" && userReadPermissionAS && !leavesV2Active;
	// const isASBRead = router.pathname.includes("status-beta") && userReadPermissionAS && leavesV2Active;
	const isASBRead = router.pathname.includes("status-beta") && userReadPermissionAS;

	const isShiftsRead = router.pathname.includes("shifts") && userReadPermissionShifts;
	const isRostersRead = router.pathname.includes("rosters") && userReadPermissionRosters;
	// const isPHRead = router.pathname.includes("public_holidays") && userReadPermissionAS;
	// const isLeavesRead = router.pathname.includes("leaves") && userReadPermissionAS && !leavesV2Active;
	const isAORRead = router.pathname.includes("override") && userReadPermissionAO;
	const isARRead = router.pathname.includes("record") && userReadPermissionAS;

	const filteredAttendanceSubmoduleData = attendanceSubmoduleData.filter(item => item && !item.hide);
	const adminInfo = useSelector((state: ROOT_STATE) => state.user);

	return (
		// <Layout title={title}>
		// 	<SubmoduleTabs submoduleData={filteredAttendanceSubmoduleData} tab={tab} />
		// 	{isAORead || isASRead || isASBRead || isShiftsRead || isRostersRead || isLeavesRead || isAORRead || isARRead ? (
		// 		children
		// 	) : (
		// 		<NotAuthorized roleID={adminInfo.adminDetails.roleID} moduleName="attendance" />
		// 	)}
		// </Layout>
		<Layout title={title}>
			<SubmoduleTabs submoduleData={filteredAttendanceSubmoduleData} tab={tab} />
			{isAORead || isASBRead || isShiftsRead || isRostersRead || isAORRead || isARRead ? (
				children
			) : (
				<NotAuthorized roleID={adminInfo.adminDetails.roleID} moduleName="attendance" />
			)}
		</Layout>
	);
};

export default AttendanceLayout;
