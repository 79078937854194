import {
	DataGridPro,
	GridColDef,
	GridRowParams,
	GridSelectionModel,
	GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { useRouter } from "next/router";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { SITES_DATA } from "../../../@types";
import { deleteSites } from "../../../api/clients_sites";
import { DATA_GRID_CUSTOMIZATION_OPTIONS, DATA_GRID_NAMES, DG_STYLES } from "../../../constants";
import { APP_DISPATCH, showNotification } from "../../../redux";
import ColumnModifierSidebar from "../../common/datagrid/ColumnModifierSidebar";
import CustomColumnMenu from "../../common/datagrid/CustomColumnMenu";
import DataGridActionButtons from "../../common/datagrid/DatagridActionButton";
import RightSidebar from "../../common/RightSidebar";
import useAdminEntitlements from "../../useAdminEntitlements";
import { useSelector } from "react-redux";
import { formatTheDataFromSlice, getTheSavedColumns, modifyColumOrder, modifyColumnWidth } from "../../../utils";
import { setColumnConfigurations, setColumnSortModel, setPinnedColumns } from "../../../redux/slices/dataGridSlice";

type Props = {
	sites: SITES_DATA[];
	loading: boolean;
	setSites: Dispatch<SetStateAction<SITES_DATA[]>>;
	setSelectedRows: Dispatch<SetStateAction<GridSelectionModel>>;
};
const SitesTable: FC<Props> = ({ sites, loading, setSites, setSelectedRows }) => {
	const [expandColumn, setExpandColumn] = useState(false);
	const [pageSize, setPageSize] = useState(20);
	const router = useRouter();
	const dispatch = useDispatch<APP_DISPATCH>();
	const [columns, setColumns] = useState<GridColDef[]>([]);
	const userWritePermission = useAdminEntitlements("clientsAndSites:sites", "write");

	const tempColumns = useMemo(
		() => [
			{
				field: "clientName",
				headerName: "Site",
				width: 200,
			},
			{
				field: "address",
				headerName: "Address",
				width: 200,
			},
			{
				field: "description",
				headerName: "Description",
				width: 150,
				sortable: false,
			},
			{
				field: "proprietorName",
				headerName: "Contact Person",
				width: 150,
				sortable: false,
			},
			{
				field: "phoneNumber",
				headerName: "Contact Number",
				width: 130,
			},
			{
				field: "latitude",
				headerName: "Latitude",
				width: 140,
				sortable: false,
			},
			{
				field: "longitude",
				headerName: "Longitude",
				width: 140,
				sortable: false,
			},
			{
				field: "radius",
				headerName: "Radius(m)",
				width: 100,
			},
			{
				field: "city",
				headerName: "City",
				width: 150,
			},
			{
				field: "pinCode",
				headerName: "Pincode",
				width: 100,
			},
			{
				field: "action",
				headerName: "Action",
				width: 100,
				sortable: false,

				renderCell: (params: GridValueGetterParams) =>
					(
						<DataGridActionButtons
							link={`/clients_sites/sites/site/${params.row.clientID}`}
							name={params.row.clientName}
							deleteHandler={() => deleteSite(params.row.clientID)}
							userWritePermission={userWritePermission}
						/>
					) 
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[sites, userWritePermission]
	);
	const deleteSite = async (id: string) => {
		if (!id || id.length === 0) return;
		const { success, message } = await deleteSites([id]);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (success) setSites(sites.filter((site) => site.clientID !== id));
	};

	useEffect(() => {
		setColumns(tempColumns);
	}, [tempColumns]);

	const getColum = useSelector((state: any) => state.dataGrid);
	const savedColumnConf = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.client_and_sites_sites,
		DATA_GRID_CUSTOMIZATION_OPTIONS.columns_array
	);
	const savedColumnSortModel = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.client_and_sites_sites,
		DATA_GRID_CUSTOMIZATION_OPTIONS.sort_model
	);
	const savedPinnedColumns = getTheSavedColumns(
		getColum,
		DATA_GRID_NAMES.client_and_sites_sites,
		DATA_GRID_CUSTOMIZATION_OPTIONS.pinned_column
	);
	const formattedcolumn = formatTheDataFromSlice(savedColumnConf, columns) || [];

	return (
		<div className="datagrid-table">
			<button className="filter__button" onClick={() => setExpandColumn((prev) => !prev)}>
				<img src="/images/filter.svg" />
			</button>
			<DataGridPro
				sx={DG_STYLES}
				rows={sites}
				columns={formattedcolumn?.length > 0 ? formattedcolumn : columns}
				loading={columns.length === 0 || loading}
				pageSize={pageSize}
				getRowId={(row) => row.clientID}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[5, 10, 20, 50]}
				rowHeight={70}
				checkboxSelection
				disableSelectionOnClick
				disableColumnFilter
				sortModel={savedColumnSortModel?.length > 0 ? savedColumnSortModel : []}
				pinnedColumns={savedPinnedColumns && Object.keys(savedPinnedColumns).length > 0 ? savedPinnedColumns : {}}
				onRowClick={(params: GridRowParams<any>) => {
					if (!userWritePermission) return;
					if (params.row.polyline) {
						router.push(`/clients_sites/sites/site_polygon/${params.id}`);
					} else {
						router.push(`/clients_sites/sites/site/${params.id}`);
					}
				}}
				onSelectionModelChange={(selectionModel) => setSelectedRows(selectionModel)}
				pagination
				components={{ ColumnMenu: CustomColumnMenu }}
				onColumnOrderChange={(params) => {
					const modifiedColumn = modifyColumOrder(
						DATA_GRID_NAMES.client_and_sites_sites,
						params,
						formattedcolumn?.length > 0 ? formattedcolumn : columns
					);
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.client_and_sites_sites,
						columnArray: modifiedColumn,
					};
					dispatch(setColumnConfigurations(newColumnConfigurations));
				}}
				onSortModelChange={(params) => {
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.client_and_sites_sites,
						sortModel: params,
					};
					dispatch(setColumnSortModel(newColumnConfigurations));
				}}
				onColumnWidthChange={(params) => {
					const modifiedColumn = modifyColumnWidth(params, formattedcolumn?.length > 0 ? formattedcolumn : columns);
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.client_and_sites_sites,
						columnArray: modifiedColumn,
					};
					dispatch(setColumnConfigurations(newColumnConfigurations));
				}}
				onPinnedColumnsChange={(params) => {
					const newColumnConfigurations = {
						dataGridName: DATA_GRID_NAMES.client_and_sites_sites,
						pinnedColumns: params,
					};
					dispatch(setPinnedColumns(newColumnConfigurations));
				}}
			/>
			{expandColumn && (
				<RightSidebar expanded={expandColumn} setExpanded={setExpandColumn}>
					<ColumnModifierSidebar
						setExpanded={setExpandColumn}
						columns={columns}
						localString={DATA_GRID_NAMES.client_and_sites_sites}
					/>
				</RightSidebar>
			)}
		</div>
	);
};

export default SitesTable;
