import { gql } from "@apollo/client";

export const ALL_ROUTES_QUERY = gql`
	query {
		get_routes {
			routeID
			name
			companyID
			insertTime
			routeDetails {
				client {
					clientID
					clientName
					employeeID
					address
					siteTypeID
				}
				startTime
				endTime
				type
			}
		}
		get_clients_by_companyID {
			clientID
			clientName
			address
			siteTypeID
			employeeID
		}
		get_sitetypes {
			siteTypeID
			siteDescription
		}
	}
`;
export const DELETE_ROUTE_MUTATION = gql`
	mutation DeleteRoute($routeIDs: [ID!]!) {
		delete_routes(routeIDs: $routeIDs) {
			rowsDeleted
		}
	}
`;

export const UPSERT_ROUTE_MUTATION = gql`
	mutation UpsertRoute($input: RouteInput!) {
		upsert_route(input: $input) {
			rowsUpdated
		}
	}
`;

export const GET_TASKS = gql`
	query GetTask($date: Date!, $taskType: TaskType) {
		get_tasks_by_adminID_date(date: $date, taskType: $taskType) {
			clientInfo {
				clientID
				employeeID
				clientName
			}
			taskID
			clientID
			customEntity {
				customEntityID
				customEntityName
			}
			employeeID
			date
			adminAssigned
			checkinTime
			checkoutTime
			lat
			lon
			taskDescription
			startTime
			endTime
			address
			timestamp
			lastUpdatedAdminID
			inPool
			createdByAdminID
			createdByEmpID
			createdBySourceID
			createdTs
			lastModifiedByEmpID
			lastModifiedByAdminID
			lastModifiedBySourceID
			lastModifiedTs
			repeatedTaskMetadata {
				repeatedTaskID
				startDate
				endDate
				sunday
				monday
				tuesday
				wednesday
				thursday
				friday
				saturday
			}
			customFieldsComplex {
				fieldID
				fieldValue
				fieldValue2
				fieldValue3
				selectedOptions
				selectedFieldValues {
					fieldValue
					fieldValue2
				}
			}
		}
	}
`;

export const GET_TASK_BY_ID = gql`
	query GetTask($taskID: ID!) {
		get_task(taskID: $taskID) {
			clientInfo {
				clientID
				employeeID
				clientName
				polyline
			}
			customEntity {
				customEntityID
				customEntityName
			}
			taskID
			clientID
			employeeID
			date
			adminAssigned
			checkinTime
			checkoutTime
			lat
			lon
			taskDescription
			startTime
			endTime
			address
			timestamp
			lastUpdatedAdminID
			inPool
			createdByAdminID
			createdByEmpID
			createdBySourceID
			createdTs
			lastModifiedByEmpID
			lastModifiedByAdminID
			lastModifiedBySourceID
			lastModifiedTs
			formsv2 {
				completedFormID
				formTitle
				formTemplateID
				createdTs
			}
			photos {
				url
			}
			customFields {
				name
				value
			}
			repeatedTaskMetadata {
				repeatedTaskID
				startDate
				endDate
			}

			customFieldsComplex {
				fieldID
				fieldValue
				fieldValue2
				fieldValue3
				selectedOptions
				selectedOptionsValues
				selectedFieldValues {
					fieldValue
					fieldValue2
				}
				fieldName
			}
		}
	}
`;

export const GET_TASKS_BY_ADMINID = gql`
	query getTasksByAdminID(
		$startDate: Date!
		$endDate: Date!
		$searchKey: String
		$take: Int
		$skip: Int
		$customTaskID: ID
		$taskType: TaskType
	) {
		get_tasks_by_adminID(
			startDate: $startDate
			endDate: $endDate
			searchKey: $searchKey
			take: $take
			skip: $skip
			customTaskID: $customTaskID
			taskType: $taskType
		) {
			data {
				clientInfo {
					clientID
					employeeID
					clientName
					polyline
				}
				taskID
				clientID
				employeeID
				date
				adminAssigned
				checkinTime
				checkoutTime
				lat
				lon
				taskDescription
				startTime
				endTime
				address
				timestamp
				lastUpdatedAdminID
				inPool
				createdByAdminID
				createdByEmpID
				createdBySourceID
				createdTs
				lastModifiedByEmpID
				lastModifiedByAdminID
				lastModifiedBySourceID
				lastModifiedTs
				formsv2 {
					completedFormID
					formTitle
					formTemplateID
					createdTs
				}
				photos {
					url
				}
				customFields {
					name
					value
				}
				repeatedTaskMetadata {
					repeatedTaskID
					startDate
					endDate
				}
				customFieldsComplex {
					fieldID
					fieldValue
					fieldValue2
					fieldValue3
					selectedOptions
					selectedOptionsValues
					selectedFieldValues {
						fieldValue
						fieldValue2
					}
					fieldName
				}
			}
			count
		}
	}
`;

export const GET_TASKS_WITH_FILTERS = gql`
	query getTasksWithFilters(
		$startDate: Date!
		$endDate: Date!
		$filters: [FilterObject]!
		$take: Int
		$skip: Int
		$customTaskID: ID
		$taskType: TaskType
	) {
		get_tasks_with_filter_by_adminID_date_range(
			startDate: $startDate
			endDate: $endDate
			filters: $filters
			take: $take
			skip: $skip
			customTaskID: $customTaskID
			taskType: $taskType
		) {
			data {
				clientInfo {
					clientID
					employeeID
					clientName
					polyline
				}
				taskID
				clientID
				employeeID
				date
				adminAssigned
				checkinTime
				checkoutTime
				lat
				lon
				taskDescription
				startTime
				endTime
				address
				timestamp
				lastUpdatedAdminID
				inPool
				createdByAdminID
				createdByEmpID
				createdBySourceID
				createdTs
				lastModifiedByEmpID
				lastModifiedByAdminID
				lastModifiedBySourceID
				lastModifiedTs
				formsv2 {
					completedFormID
					formTitle
					formTemplateID
					createdTs
				}
				photos {
					url
				}
				customFields {
					name
					value
				}
				repeatedTaskMetadata {
					repeatedTaskID
					startDate
					endDate
				}
				photos {
					url
				}
				customFields {
					name
					value
				}
				repeatedTaskMetadata {
					repeatedTaskID
					startDate
					endDate
				}
				customFieldsComplex {
					fieldID
					fieldValue
					fieldValue2
					fieldValue3
					selectedOptions
					selectedOptionsValues
					selectedFieldValues {
						fieldValue
						fieldValue2
					}
					fieldName
				}
			}
			count
		}
	}
`;

export const GET_All_CUSTOM_TASK_BY_EMPLOYEE = gql`
	query getCustomTaskByEmployeeID($employeeID: Int!, $filters: [FilterMetadata!]) {
		get_custom_entities_by_employee(employeeID: $employeeID, filters: $filters) {
			customEntityID
			customEntityName
			active
			createdTs
			lastModifiedTs
			visibility {
				type
				value
				name
			}
		}
	}
`;

export const GET_All_CUSTOM_TASK = gql`
	query getCustomTask($filters: [FilterMetadata!]) {
		get_custom_entities(filters: $filters) {
			customEntityID
			customEntityName
			active
			createdTs
			lastModifiedTs
			createdByAdminID
			reducedAuditFields {
				createdByAdminName
				lastModifiedByAdminName
			}
			visibility {
				type
				value
				name
			}
		}
	}
`;

export const GET_CUSTOM_TYPE_ID = gql`
	query getCustomTaskById($filters: [FilterMetadata!]) {
		get_custom_entities(filters: $filters) {
			customEntityID
			customEntityName
			active
			lastModifiedTs
			createdTs
			allowRescheduleForEmp
			canEmployeeStartTheTask
			visibility {
				type
				value
			}
			customEntitySections {
				customEntitySectionTitle
				customEntitySectionDescription
				customEntitySectionID
				nextSection
				seqNumber
				createdTs
				lastModifiedTs
				customEntityFields {
					fieldID
					fieldName
					dataType
					sequence
					timestamp
					customEntityFieldSettings {
						... on CustomEntityFieldSettingsVarChar {
							required
							write
							editAfterSubmit
							allowAdminToEditAfterSubmit
						}
						... on CustomEntityFieldSettingsClient {
							isOtpVerificationEnabledForTasks
							clientGeoValidation
							clientModification
							allowClientSelectionByEmployee
							required
							oneTaskPerClientPerDay
						}
						... on CustomEntityFieldSettingsPhoto {
							required
							write
							editAfterSubmit
							selfiePhoto
							taskPhoto
							allowAdminToEditAfterSubmit
						}
						... on CustomEntityFieldSettingsDate {
							required
							write
							editAfterSubmit
							allowAdminToEditAfterSubmit
						}
						... on CustomEntityFieldSettingsNumber {
							required
							write
							editAfterSubmit
							allowAdminToEditAfterSubmit
						}
						... on CustomEntityFieldSettingsCheckBox {
							required
							write
							editAfterSubmit
							allowAdminToEditAfterSubmit
						}
						... on CustomEntityFieldSettingsDropDown {
							required
							write
							editAfterSubmit
							allowAdminToEditAfterSubmit
						}
						... on CustomEntityFieldSettingsLocation {
							metaLocationGeoValidation
							metaLocationGeoValidationRadius
						}
						... on CustomEntityFieldSettingsSite {
							siteGeoValidation
							oneTaskPerClientPerDay
							required
						}
						... on CustomEntityFieldSettingsAddress {
							required
							write
							editAfterSubmit
							allowAdminToEditAfterSubmit
						}
						... on CustomEntityFieldSettingsFile {
							required
							write
							editAfterSubmit
							allowAdminToEditAfterSubmit
						}
						... on CustomEntityFieldSettingsContact {
							required
							write
							editAfterSubmit
							allowAdminToEditAfterSubmit
						}
						... on CustomEntityFieldSettingsForm {
							required
							editAfterSubmit
						}
						... on CustomEntityFieldSettingsOrder {
							required
						}
						... on CustomEntityFieldSettingsTime {
							required
							editAfterSubmit
							write
							allowAdminToEditAfterSubmit
						}
					}

					options {
						optionValue
						nextSection
						timestamp
						softDeleted
					}
				}
			}
		}
	}
`;

export const UPSERT_TASK_TYPE = gql`
	mutation UpsertCustomEntity($input: CustomEntityMetadataInput!) {
		upsert_custom_entity(input: $input) {
			data {
				customEntityID
			}
		}
	}
`;

export const UPDATE_TASK_TYPE_STATUS = gql`
	mutation UpdateCustomEntitiesStatusBatch($customEntityIDs: [ID!]!, $status: CustomEntityStatus!) {
		update_custom_entities_status_batch(customEntityIDs: $customEntityIDs, status: $status) {
			rowsUpdated
			message
		}
	}
`;

export const UPSERT_TASK = gql`
	mutation UpdateTask($input: TaskInput!, $fromDate: Date) {
		upsert_task(input: $input, fromDate: $fromDate) {
			data {
				clientInfo {
					clientID
					employeeID
					clientName
				}
				taskID
				clientID
				employeeID
				date
				adminAssigned
				checkinTime
				checkoutTime
				lat
				lon
				taskDescription
				startTime
				endTime
				address
				timestamp
				lastUpdatedAdminID
				inPool
				createdByAdminID
				createdByEmpID
				createdBySourceID
				createdTs
				lastModifiedByEmpID
				lastModifiedByAdminID
				lastModifiedBySourceID
				lastModifiedTs
				repeatedTaskMetadata {
					repeatedTaskID
					startDate
					endDate
					sunday
					monday
					tuesday
					wednesday
					thursday
					friday
					saturday
				}
				customFieldsComplex {
					fieldID
					fieldValue
					fieldValue2
					fieldValue3
					selectedOptions
					selectedFieldValues {
						fieldValue
						fieldValue2
					}
				}
			}
		}
	}
`;

export const DELETE_TASK = gql`
	mutation DeleteTask($taskID: ID!, $repeatedTaskID: ID, $date: Date) {
		delete_task(taskID: $taskID, repeatedTaskID: $repeatedTaskID, date: $date) {
			rowsDeleted
		}
	}
`;

export const MULTI_DELETE_TASK = gql`
	mutation MultiDeleteTask($taskIDs: [ID!], $repeatedTaskIDs: [ID!], $date: Date) {
		delete_task_batch(taskIDs: $taskIDs, repeatedTaskIDs: $repeatedTaskIDs, date: $date) {
			failed
		}
	}
`;

//Overview Screens
export const GET_TOPSTRIP_DATA = gql`
	query getTaskDashboardTopstripData($startDate: Date!, $endDate: Date!, $taskType: TaskType) {
		get_task_dashboard_topstrip_data(startDate: $startDate, endDate: $endDate, taskType: $taskType) {
			taskWithPhotos
			taskWithForms
			otpUnverifiedTask
			avgTimeSpendPerTask
			quantity
			taskNotYetStarted
			taskDelayed
			taskInProgress
			taskCompleted
		}
	}
`;
export const GET_DASHBOARD_DATA = gql`
	query getDashboardData(
		$startDate: Date!
		$endDate: Date!
		$limitToCount: Int!
		$groupByKeys: [GroupByKey]!
		$customFields: [String!]
		$sortByKeys: [SortByKey]
		$orderBy: [OrderBy]
		$customTaskFilter: String
	) {
		get_task_dashboard_data(
			startDate: $startDate
			endDate: $endDate
			limitToCount: $limitToCount
			groupByKeys: $groupByKeys
			customFields: $customFields
			sortByKeys: $sortByKeys
			orderBy: $orderBy
			customTaskFilter: $customTaskFilter
		) {
			quantity
			avgTaskDuration
			totalTasksDuration
			avgTaskDelays
			totalPhotos
			totalForms
			avgTaskStartDelay
			avgTaskEndDelay
			tasksFinished
			tasksNotStarted
			taskInProgress
			groupByKeyComb
		}
	}
`;

export const GET_UNASSIGNED_TASKS = gql`
	query GetPendingTask($date: Date!) {
		get_pending_tasks_by_adminID_date(date: $date) {
			pendingTaskID
			owningAdminID
			date
			startTime
			endTime
			taskDescription
			lat
			lon
			address
			createdTs
			customFieldsComplex {
				fieldID
				fieldValue
				fieldValue2
				fieldValue3
				selectedFieldValues {
					fieldValue
					fieldValue2
				}
				fieldName
				selectedOptions
			}
		}
	}
`;

export const UPSERT_PENDING_TASK = gql`
	mutation UpsertPendingTask($input: PendingTaskInput!) {
		upsert_pending_task(input: $input) {
			data {
				pendingTaskID
				owningAdminID
				date
				startTime
				endTime
				taskDescription
				lat
				lon
				address
				createdTs
				customFieldsComplex {
					fieldID
					fieldValue
					fieldValue2
					fieldValue3
					selectedOptions
					selectedFieldValues {
						fieldValue
						fieldValue2
					}
				}
			}
		}
	}
`;

// pjp queries
export const GET_PJP_REQUESTS = gql`
	query getPJPRequestData($startDate: Date!, $endDate: Date!, $taskType: TaskType) {
		get_pjp_get_adminID_date(startDate: $startDate, endDate: $endDate, taskType: $taskType) {
			pjpID
			status
			reason
			admin
			visits {
				clientID
				clientName
				address
				customEntity {
					customEntityID
					customEntityName
				}
			}
			createdTs
			lastModifiedTs
			employeeID
			employee {
				firstName
				lastName
			}
			date
		}
	}
`;

export const GET_PJP_REQUESTS_BY_ID = gql`
	query getPJPRequestDataByID($pjpID: ID!) {
		get_pjp(pjpID: $pjpID) {
			pjpID
			status
			createdTs
			lastModifiedTs
			visits {
				clientID
				clientName
				address
				customEntity {
					customEntityID
					customEntityName
				}
			}
			admin
			reason
			date
			employeeID
			employee {
				firstName
				lastName
			}
			reason
		}
	}
`;

export const UPDATE_PJP_STATUS = gql`
	mutation updatePJPStatus($pjpIDs: [ID!], $status: PJPStatus!, $reason: String) {
		update_pjp_status(pjpIDs: $pjpIDs, status: $status, reason: $reason) {
			success
			message
		}
	}
`;
