import { useMutation } from "@apollo/client";
import {
	DataGridPro,
	GridColDef,
	GridRowParams,
	GridSelectionModel,
	GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import _cloneDeep from "lodash/cloneDeep";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useEffect, useMemo, useState, type FC } from "react";
import { useDispatch } from "react-redux";
import { ROUTE_DATA } from "../../../@types";
import { DG_STYLES } from "../../../constants";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { DELETE_ROUTE_MUTATION } from "../../../schema";
import CustomColumnMenu from "../../common/datagrid/CustomColumnMenu";
import useAdminEntitlements from "../../useAdminEntitlements";
import RoutesActionButtons from "./RoutesActionButton";

type Props = {
	routes: ROUTE_DATA[];
	setRoutes: Dispatch<SetStateAction<ROUTE_DATA[]>>;
	setSelectedRows: Dispatch<SetStateAction<GridSelectionModel>>;
	loading: boolean;
};
const RoutesTable: FC<Props> = ({ routes, setSelectedRows, setRoutes, loading }) => {
	const router = useRouter();
	const dispatch = useDispatch<APP_DISPATCH>();
	const [deleteRouteMutation, { data }] = useMutation(DELETE_ROUTE_MUTATION);
	const [pageSize, setPageSize] = useState(20);
	const [columns, setColumns] = useState<GridColDef[]>([]);
	const userWritePermission = useAdminEntitlements("tasks:routes", "write");

	const tempColumns = useMemo(
		() => [
			{
				field: "name",
				headerName: "Route Name",
				flex: 1,
				minWidth: 200,
			},
			{
				field: "category",
				headerName: "Category",
				flex: 1,
				minWidth: 180,
			},
			{
				field: "action",
				headerName: "Action",
				minWidth: 150,
				renderCell: (params: GridValueGetterParams) =>
						<RoutesActionButtons
							name={params.row.name}
							deleteHandler={() => deleteHandler(params.row.routeID)}
							link={`/tasks/routes/${params.row.routeID}`}
							userWritePermission={userWritePermission}
						/>
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[routes, userWritePermission]
	);

	useEffect(() => {
		setColumns(tempColumns);
	}, [tempColumns]);

	const deleteHandler = async (id: string) => {
		if (!id || id.length === 0) return;
		const newRoutes = _cloneDeep(routes);
		const index = newRoutes.findIndex((route) => route.routeID === id);
		if (index < 0) return;
		await deleteRouteMutation({ variables: { routeIDs: [id] } });
		dispatch(
			showNotification({
				message: data?.delete_routes?.rowsDeleted === 0 ? "Route Could not be deleted" : "Route Deleted successfully",
				severity: data?.delete_routes?.rowsDeleted === 0 ? "error" : "success",
			})
		);
		newRoutes.splice(index, 1);
		setRoutes(newRoutes);
	};
	return (
		<div className="datagrid-table">
			<DataGridPro
				sx={DG_STYLES}
				rows={routes}
				loading={loading}
				columns={columns}
				pageSize={pageSize}
				getRowId={(row) => row.routeID}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[5, 10, 20, 50]}
				rowHeight={70}
				checkboxSelection
				disableSelectionOnClick
				disableColumnFilter
				pagination
				onRowClick={(params: GridRowParams<any>) => userWritePermission && router.push(`/tasks/routes/${params.id}`)}
				onSelectionModelChange={(selectionModel) => setSelectedRows(selectionModel)}
				components={{
					ColumnMenu: CustomColumnMenu,
				}}
			/>
		</div>
	);
};
export default RoutesTable;
