import { SNACKBAR_TYPE } from "./../../@types/index";
import { createSlice } from "@reduxjs/toolkit";

const initialState: SNACKBAR_TYPE = {
	open: false,
	message: "",
	severity: "success",
	duration: 4000,
};

const notificationSlice = createSlice({
	name: "notification",
	initialState,
	reducers: {
		showNotification: (state: SNACKBAR_TYPE = initialState, action) => {
			state.open = true;
			state.message = action.payload.message;
			state.severity = action.payload.severity || "success";
			state.duration = action.payload.duration || 4000;
		},
		closeNotification: (state: SNACKBAR_TYPE) => {
			state.open = false;
			state.message = "";
		},
	},
});

export const { showNotification, closeNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
