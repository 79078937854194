/* eslint-disable no-alert */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Button, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROOT_STATE, showNotification } from "../../../redux";
import axios from "../../../utils/axios";

type Props = {
	geofence: any;
	goBackToList: any;
	geofenceNamePopupNameChange: any;
	regionMarkers: any;
	handlePolygonSizeChange: any;
	maps: any;
	setSelectedGeofenceID: any;
	isValidPolygon: boolean;
	clearSelection: any;
	setGeofences: any;
	geofences: any;
	setIsNewGeofence: any;
	geoFenceRefetch: any;
	isValidSize: boolean;
	isNewGeofence: boolean;
};

const GeofenceEdit: FC<Props> = ({
	geofence,
	goBackToList,
	geofenceNamePopupNameChange,
	regionMarkers,
	maps,
	isValidPolygon,
	clearSelection,
	setGeofences,
	geofences,
	setIsNewGeofence,
	geoFenceRefetch,
	isValidSize,
	isNewGeofence,
}) => {
	const [geofenceName, setGeofenceName] = useState<null | string>("");
	const user = useSelector((state: ROOT_STATE) => state.user);
	const [isSaving, setIsSaving] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (geofence) {
			setGeofenceName(geofence.name);
		}
	}, [geofence]);

	const onSave = async () => {
		if (geofenceName === "") {
			return;
		}
		const geofenceID = geofence.geofenceID;
		const polygon = regionMarkers[geofenceID].polygon;
		const path = polygon.getPath().getArray();

		//validation checks
		//make sure polygon is big enough
		const area = Math.round(maps.geometry.spherical.computeArea(path));
		//return area is in m2

		// allow overide for company id - 15681 and 13486
		if (area < 5000 && user?.adminDetails?.companyID !== 15681 && user?.adminDetails?.companyID !== 13486) {
			alert("This region is too small (" + area + " sq m), please increase to more than 5,000 sq m");
			return;
		}

		const toSend: any = {
			geofenceID: geofenceID,
			companyID: user.adminDetails.companyID,
			name: geofenceName,
			polyline: maps.geometry.encoding.encodePath(path),
			new: geofence.dirty ? true : false,
		};
		setIsSaving(true);

		await axios
			.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/geofence/updateGeofence`, toSend)
			.then((response) => {
				const data = response.data;
				if (toSend.new === true) {
					delete toSend["new"];
					setGeofences([...geofences, { ...toSend, geofenceID: data.id }]);

					regionMarkers[geofenceID]["geofence"]["polyline"] = toSend["polyline"];
					regionMarkers[data.id] = regionMarkers[geofenceID];
					delete regionMarkers[data.id]["geofence"]["new"];
					delete regionMarkers[data.id]["geofence"]["dirty"];

					clearSelection();

					delete regionMarkers[geofenceID];

					setIsNewGeofence(false);
				} else {
					const geofenceIndex = geofences.findIndex((g: any) => g.geofenceID === geofenceID);
					geofences[geofenceIndex] = { ...toSend };
					setGeofences([...geofences]);
					setIsNewGeofence(false);
				}
				dispatch(showNotification({ message: "Geofence saved", severity: "success" }));
			})
			.catch((e) => {
				dispatch(showNotification({ message: "Error saving geofence", severity: "error" }));
			})
			.finally(() => {
				setIsSaving(false);
			});
		geoFenceRefetch();
	};

	const onDelete = async () => {
		const geofenceID = geofence.geofenceID;

		const toSend = {
			geofenceID: geofenceID,
		};

		await axios
			.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/geofence/deleteGeofences`, toSend)
			.then((response) => {
				const data = response.data;
				if (data.status === "success") {
					dispatch(showNotification({ message: "Geofence deleted", severity: "success" }));
					delete regionMarkers[geofenceID];
				}

				const geofenceIndex = geofences.findIndex((g: any) => g.geofenceID === geofenceID);
				geofences.splice(geofenceIndex, 1);
				setGeofences([...geofences]);
				setIsNewGeofence(false);
			})
			.catch((e) => {
				dispatch(showNotification({ message: "Error deleting geofence", severity: "error" }));
			})
			.finally(() => {
				setIsSaving(false);
			});
		geoFenceRefetch();
	};

	return (
		<div className="user_add geofence_sidebar">
			<div className="geofence_add_edit">
				<ArrowBackIcon
					sx={{ cursor: "pointer" }}
					onClick={() => {
						goBackToList();
					}}
				/>
				<div>{geofenceName}</div>
			</div>
			<div className="geofence_add_edit_form">
				<div style={{ width: "100%" }}>
					<TextField
						required
						name="Geofence Name"
						label="Geofence Name"
						inputProps={{ maxLength: 45 }}
						onChange={(e) => {
							setGeofenceName(e.target.value);
							geofenceNamePopupNameChange(e.target.value);
						}}
						value={geofenceName ?? ""}
						id="geofenceNameValue"
						error={geofenceName === ""}
						helperText={geofenceName === "" ? "Geofence Name is required" : ""}
						sx={{ width: "100%" }}
					/>
					{isValidPolygon ? null : (
						<div style={{ display: "flex", alignItems: "center", marginTop: "1rem", color: "red" }}>
							<div>
								<WarningAmberIcon sx={{ fontSize: "16px", marginRight: "8px" }} />
							</div>
							<p style={{ fontSize: "16px" }}>Geofence need to have at least 4 points</p>
						</div>
					)}
					{!isValidSize && isNewGeofence && (
						<p style={{ color: "red", padding: "10px 0", fontSize: "12px" }}>
							This region is too large, please decrease to less than 1,500sq Km
						</p>
					)}
				</div>
				<div style={{ display: "grid", gridTemplateColumns: `${isNewGeofence ? "1fr" : "1fr 1fr"}`, gap: "10px" }}>
					<Button
						size="medium"
						variant="contained"
						startIcon={<CheckIcon />}
						onClick={onSave}
						disabled={geofenceName === "" || !isValidPolygon || isSaving || !isValidSize}>
						Save
					</Button>
					{!isNewGeofence && (
						<Button size="medium" variant="contained" color="error" onClick={onDelete} disabled={isSaving}>
							Delete
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};

export default GeofenceEdit;
