/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";

type Props2 = {
	title: string;
	data: any;
	filters: any;
	name: string;
	onChangeCheckbox: any;
};
function IndividualDefaultFilter({ title, data, name, filters, onChangeCheckbox }: Props2) {
	const [searchInput, setSearchInput] = useState("");
	const [localList, setLocalList] = useState<string[]>([]);

	const getUniqueEntries = (data: any[]) => {
		let tempArr: string[] = [];
		const obj : any= {};
		
		data.forEach((item: any) => {
			if (obj[item[name]] == undefined && item[name] !== null && item[name] !== undefined) {
				obj[item[name]] = 1; 
				tempArr.push(item[name]);
			}
		});
		if(name === "categories") {
			tempArr = [...new Set(tempArr.flat())] // this category logic is different in both expense and conveyance and also this flattening is used for this categories only
		}
		return tempArr;
	};
	const handleSearching = (searchTerm: string) => {
		if (searchTerm === "") {
			const tempArray = getUniqueEntries(data);
			setLocalList(tempArray);
			return;
		}
		const list = data.filter((obj: any) =>
			JSON.stringify(obj[name]).replace(/ /g, "").toLowerCase().includes(searchTerm.replace(/ /g, "").toLowerCase())
		);
		const list1 = getUniqueEntries(list);
		setLocalList(() => [...list1]);
	};
	useEffect(() => {
		const tempArr: string[] = getUniqueEntries(data);
		setLocalList([...tempArr]);
	}, [data]);

	return (
		<Accordion sx={{ margin: "0 !important", width: "100%" }}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<h6>{title}</h6>
			</AccordionSummary>
			<AccordionDetails sx={{ margin: 0 }}>
				<input
					type="search"
					className="search"
					placeholder={`Search ${title}`}
					value={searchInput}
					style={{
						width: "100%",
					}}
					onChange={(e) => {
						setSearchInput(e.target.value);
						handleSearching(e.target.value);
					}}
				/>
				<div className="individualFilter_wrapper" style={{ display: "flex", flexDirection: "column" }}>
					{localList?.map((item) => {
						let label;
						if (name === "active") {
							label = item ? "Yes" : "No";
						} else {
							label = item;
						}
						return (
							<FormControlLabel
								key={Math.random()}
								name={name}
								value={item}
								control={<Checkbox size="small" />}
								label={label}
								checked={filters[name] !== undefined && filters[name]?.includes(item)}
								onChange={(e) => onChangeCheckbox(e, item, name)}
							/>
						);
					})}
				</div>
			</AccordionDetails>
		</Accordion>
	);
}
export default IndividualDefaultFilter;
