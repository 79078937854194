import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import { DESIGNATIONS_DATA, EXPENSE_SETTING, PROFILES_DATA } from "../../../@types";
import { addExpenseLimit } from "../../../api/settings";
import { TABLE_STYLE } from "../../../constants";
import { showNotification } from "../../../redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialog-paper": {
		minWidth: "650px",
		padding: theme.spacing(2),
	},
}));

type ROWS_DATA = {
	expenseCategoryID: null | string;
	category: string;
	categoryLimitAgainst: null | number;
	expenseLimit: null | number;
	expenseTimePeriod: null | string;
	expenseLimitArray: EXPENSE_SETTING["expenseLimits"];
};

type Props = {
	handleClose: () => void;
	expenseLimits: EXPENSE_SETTING["expenseLimits"];
	selectedExpenseCategory: ROWS_DATA;
	setSelectedExpenseCategory: Dispatch<SetStateAction<ROWS_DATA>>;
	profiles: PROFILES_DATA[];
	designations: DESIGNATIONS_DATA[];
	setExpenseLimits: Dispatch<SetStateAction<EXPENSE_SETTING["expenseLimits"]>>;
};

const ExpenseLimitModal: FC<Props> = ({
	handleClose,
	expenseLimits,
	selectedExpenseCategory,
	setSelectedExpenseCategory,
	profiles,
	designations,
	setExpenseLimits,
}) => {
	const dispatch = useDispatch();
	const [loader, setLoader] = useState<boolean>(false);

	const changeLimits = (data: any) => {
		let changedData = { ...selectedExpenseCategory };
		if (data.categoryLimitAgainst || data.categoryLimitAgainst === 0) {
			changedData = { ...changedData, categoryLimitAgainst: data.categoryLimitAgainst };
			const tempExpenseLimits = [];
			if (data.categoryLimitAgainst === 0) {
				tempExpenseLimits.push({
					designationID: null,
					profileID: null,
					expenseLimit: 200,
					expenseTimePeriod: 0,
					expenseCategoryID: selectedExpenseCategory.expenseCategoryID,
				});
				changedData = {
					...changedData,
					expenseLimit: 200,
					expenseTimePeriod: "Daily",
				};
			} else if (data.categoryLimitAgainst === 1) {
				profiles.forEach((profile) => {
					tempExpenseLimits.push({
						designationID: null,
						profileID: profile.profileID,
						expenseLimit: 200,
						expenseTimePeriod: 0,
						expenseCategoryID: selectedExpenseCategory.expenseCategoryID,
					});
				});
				changedData = {
					...changedData,
					expenseLimit: null,
					expenseTimePeriod: null,
				};
			} else {
				designations.forEach((designation) => {
					tempExpenseLimits.push({
						designationID: designation.designationID,
						profileID: null,
						expenseLimit: 200,
						expenseTimePeriod: 0,
						expenseCategoryID: selectedExpenseCategory.expenseCategoryID,
					});
				});
				changedData = {
					...changedData,
					expenseLimit: null,
					expenseTimePeriod: null,
				};
			}

			changedData = {
				...changedData,
				categoryLimitAgainst: data.categoryLimitAgainst,
				expenseLimitArray: tempExpenseLimits,
			};
		} else if (data.profileID) {
			let limitData = selectedExpenseCategory.expenseLimitArray;
			let isPresent = false;
			limitData = limitData.map((limit) => {
				if (limit.profileID === data.profileID) {
					isPresent = true;
					if (data.expenseLimit || data.expenseLimit === 0) {
						return { ...limit, expenseLimit: data.expenseLimit };
					} else {
						return { ...limit, expenseTimePeriod: data.expenseTimePeriod };
					}
				}
				return { ...limit };
			});
			if (!isPresent) {
				if (data.expenseLimit || data.expenseLimit === 0) {
					limitData.push({
						designationID: null,
						profileID: data.profileID,
						expenseLimit: data.expenseLimit,
						expenseTimePeriod: 0,
						expenseCategoryID: selectedExpenseCategory.expenseCategoryID,
					});
				} else {
					limitData.push({
						designationID: null,
						profileID: data.profileID,
						expenseLimit: null,
						expenseTimePeriod: data.expenseTimePeriod,
						expenseCategoryID: selectedExpenseCategory.expenseCategoryID,
					});
				}
			}
			changedData = { ...changedData, expenseLimitArray: limitData };
		} else if (data.designationID) {
			let limitData = selectedExpenseCategory.expenseLimitArray;
			let isPresent = false;
			limitData = limitData.map((limit) => {
				if (limit.designationID === data.designationID) {
					isPresent = true;
					if (data.expenseLimit || data.expenseLimit === 0) {
						return { ...limit, expenseLimit: data.expenseLimit };
					} else {
						return { ...limit, expenseTimePeriod: data.expenseTimePeriod };
					}
				}
				return { ...limit };
			});

			if (!isPresent) {
				if (data.expenseLimit || data.expenseLimit === 0) {
					limitData.push({
						designationID: data.designationID,
						profileID: null,
						expenseLimit: data.expenseLimit,
						expenseTimePeriod: 0,
						expenseCategoryID: selectedExpenseCategory.expenseCategoryID,
					});
				} else {
					limitData.push({
						designationID: data.designationID,
						profileID: null,
						expenseLimit: null,
						expenseTimePeriod: data.expenseTimePeriod,
						expenseCategoryID: selectedExpenseCategory.expenseCategoryID,
					});
				}
			}
			changedData = { ...changedData, expenseLimitArray: limitData };
		} else if (data.expenseLimit || data.expenseLimit === 0 || data.expenseTimePeriod || data.expenseTimePeriod === 0) {
			let limitData = selectedExpenseCategory.expenseLimitArray;
			limitData = limitData.map((limit) => {
				if (data.expenseLimit || data.expenseLimit === 0) {
					return { ...limit, expenseLimit: data.expenseLimit };
				} else {
					return { ...limit, expenseTimePeriod: data.expenseTimePeriod };
				}
			});
			if (limitData?.length < 1) {
				limitData.push({
					designationID: null,
					profileID: null,
					expenseLimit: data.expenseLimit || data.expenseLimit === 0 ? data.expenseLimit : 200,
					expenseTimePeriod: data.expenseTimePeriod || data.expenseTimePeriod === 0 ? data.expenseTimePeriod : 0,
					expenseCategoryID: selectedExpenseCategory.expenseCategoryID,
				});
			}
			changedData = { ...changedData, expenseLimitArray: limitData };
		}
		setSelectedExpenseCategory({ ...changedData });
	};

	const saveExpenseLimit = async () => {
		const dataTosend = {};
		dataTosend["expenseLimitEnable"] = expenseLimits.length ? 1 : 0;

		if (dataTosend["expenseLimitEnable"]) {
			const expenseAgainstMetadata = {};
			expenseAgainstMetadata["expenseAgainstAllCategories"] = selectedExpenseCategory.category === "All" ? 1 : 0;
			const expenseCatogoryPayload = [],
				tempObj = {};

			tempObj["setAgainst"] = selectedExpenseCategory.categoryLimitAgainst
				? selectedExpenseCategory.categoryLimitAgainst === 1
					? "profile"
					: "designation"
				: "all";

			const payload = selectedExpenseCategory?.expenseLimitArray?.map((limit) => ({
				expenseLimit: limit.expenseLimit,
				duration: limit.expenseTimePeriod,
				profileID: limit.profileID,
				designationID: limit.designationID,
			}));

			tempObj["payload"] = payload;
			tempObj["expenseCategoryID"] = selectedExpenseCategory.expenseCategoryID;
			expenseCatogoryPayload.push(tempObj);
			expenseAgainstMetadata["expenseCatogoryPayload"] = expenseCatogoryPayload;
			dataTosend["expenseAgainstMetadata"] = expenseAgainstMetadata;
		}
		setLoader(true);
		const { success, message } = await addExpenseLimit(dataTosend);
		setLoader(false);
		if (success) {
			let tempData = expenseLimits.filter((d) => d.expenseCategoryID !== selectedExpenseCategory.expenseCategoryID);
			tempData = [...tempData, ...selectedExpenseCategory["expenseLimitArray"]];
			setExpenseLimits([...tempData]);
			handleClose();
		}
		dispatch(showNotification({ message: message, severity: success ? "success" : "error" }));
	};

	return (
		<BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
			<div className="expense_limit_modal_body">
				<div className="modal_header">
					<span>Expense limit</span>
					<CloseIcon onClick={handleClose} />
				</div>
				<div className="divider"></div>
				<div className="modal_mid">
					<div className="boxes category">
						<p>Category:</p>
						<span>{selectedExpenseCategory.category ?? "ALL"}</span>
					</div>
					<div className="boxes against">
						<p>Against:</p>
						<select
							onChange={(e) => changeLimits({ categoryLimitAgainst: +e.target.value })}
							value={selectedExpenseCategory.categoryLimitAgainst ?? 0}>
							<option value={0}>Default</option>
							<option value={1}>Team</option>
							<option value={2}>Designation</option>
						</select>
					</div>
				</div>
				<div className="modal_table">
					<TableContainer component={Paper} sx={{ height: "400px" }}>
						<Table sx={{ ...TABLE_STYLE, maxHeight: "200px" }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>
										{selectedExpenseCategory.categoryLimitAgainst
											? selectedExpenseCategory.categoryLimitAgainst == 1
												? "Team"
												: "Designation"
											: ""}
									</TableCell>
									<TableCell>Limit</TableCell>
									<TableCell>Duration</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{selectedExpenseCategory.categoryLimitAgainst ? (
									selectedExpenseCategory.categoryLimitAgainst == 1 ? (
										profiles.map((profile) => (
											<TableRow key={profile.profileID}>
												<TableCell>{profile.profileName}</TableCell>
												<TableCell>
													<input
														type="number"
														value={selectedExpenseCategory.expenseLimitArray
															.find((d) => d.profileID === profile.profileID)
															?.expenseLimit?.toString()}
														onChange={(e) =>
															changeLimits({ profileID: profile.profileID, expenseLimit: +e.target.value })
														}
														required
														min={0}
													/>
												</TableCell>
												<TableCell>
													<select
														value={selectedExpenseCategory.expenseLimitArray
															.find((d) => d.profileID === profile.profileID)
															?.expenseTimePeriod?.toString()}
														onChange={(e) =>
															changeLimits({ profileID: profile.profileID, expenseTimePeriod: +e.target.value })
														}>
														<option value={0}>Daily</option>
														<option value={1}>Weekly</option>
														<option value={2}>Monthly</option>
													</select>
												</TableCell>
											</TableRow>
										))
									) : (
										designations.map((designation) => (
											<TableRow key={designation.designationID}>
												<TableCell>{designation.name}</TableCell>
												<TableCell>
													<input
														type="number"
														value={selectedExpenseCategory.expenseLimitArray
															.find((d) => d.designationID === designation.designationID)
															?.expenseLimit?.toString()}
														onChange={(e) =>
															changeLimits({ designationID: designation.designationID, expenseLimit: +e.target.value })
														}
														required
														min={0}
													/>
												</TableCell>
												<TableCell>
													<select
														value={selectedExpenseCategory.expenseLimitArray
															.find((d) => d.designationID === designation.designationID)
															?.expenseTimePeriod?.toString()}
														onChange={(e) =>
															changeLimits({
																designationID: designation.designationID,
																expenseTimePeriod: +e.target.value,
															})
														}>
														<option value={0}>Daily</option>
														<option value={1}>Weekly</option>
														<option value={2}>Monthly</option>
													</select>
												</TableCell>
											</TableRow>
										))
									)
								) : (
									<TableRow key={"company"}>
										<TableCell>{"All"}</TableCell>
										<TableCell>
											<input
												value={selectedExpenseCategory.expenseLimitArray[0]?.["expenseLimit"]?.toString()}
												type="number"
												onChange={(e) => changeLimits({ expenseLimit: +e.target.value })}
												min={0}
											/>
										</TableCell>
										<TableCell>
											<select
												value={selectedExpenseCategory.expenseLimitArray[0]?.["expenseTimePeriod"]?.toString()}
												onChange={(e) => changeLimits({ expenseTimePeriod: +e.target.value })}>
												<option value={0}>Daily</option>
												<option value={1}>Weekly</option>
												<option value={2}>Monthly</option>
											</select>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</div>
			<DialogActions
				sx={{
					justifyContent: "flex-end",
				}}>
				<Button
					variant="contained"
					onClick={saveExpenseLimit}
					color="primary"
					sx={{
						marginTop: "10px",
						borderRadius: "8px",
					}}>
					{!loader ? "Save" : <CircularProgress size={20} sx={{ color: "white" }} />}
				</Button>
			</DialogActions>
		</BootstrapDialog>
	);
};

export default ExpenseLimitModal;
