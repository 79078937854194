import axios from "../../utils/axios";
axios.defaults.withCredentials = true;

export const dismissNotification = async (refernce: string): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios({
			method: "POST",
			url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/chat/dismissNotification`,
			data: `refernce=${refernce}`,
			headers: { "content-type": `application/x-www-form-urlencoded; charset=UTF-8` },
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Successfully Dismissed Selected Notification`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Dismissing Notification";
		return { success: false, message: errorText };
	}
};
export const dismissAllNotifications = async (refernce: string[]): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios({
			method: "POST",
			data: `reference=${JSON.stringify(refernce)}`,
			headers: { "content-type": `application/x-www-form-urlencoded; charset=UTF-8` },
			url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/chat/dismissAllNotifications`,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Successfully Dismissed Selected Notifications`,
		};
	} catch (error: any) {
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Dismissing Notifications";
		return { success: false, message: errorText };
	}
};
