// react
import { Dispatch, FC, SetStateAction } from "react";
// mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

type VALIDATION_COLUMN_ERRORS = {
	incorrectColumnName: string;
	issue: string;
	missingColumns: string;
	extraColumns: string;
};
type Props = {
	open: boolean;
	setBulkUploadOpen: Dispatch<SetStateAction<boolean>>;
	columnErrors: VALIDATION_COLUMN_ERRORS[];
	setColumnErrors: Dispatch<SetStateAction<VALIDATION_COLUMN_ERRORS[]>>;
};

const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#D3DFFD",
		color: "#0500FF",
		fontWeight: "700",
		fontSize: "1rem",
		textAlign: "center",
		borderRight: "1px solid #C4C4C4",
		padding: "1rem 8px",
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 15,
		padding: "2rem 1rem",
		color: "#7a1b1b",
		borderRight: "1px solid #C4C4C4",
	},
}));

const StyledTableRow = styled(TableRow)(() => ({
	verticalAlign: "unset",
}));
const ErrorMessageShowModal: FC<Props> = ({ open, setBulkUploadOpen, columnErrors, setColumnErrors }) => {
	const handleClose = () => {
		setBulkUploadOpen(false);
		setColumnErrors([]);
	};

	return (
		<>
			<Dialog
				style={{ maxWidth: "700px !important" }}
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}>
				<DialogTitle style={{ color: "#3f51b5 !important" }} id="customized-dialog-title">
					Error in excel file
				</DialogTitle>
				<DialogContent
					style={{ minWidth: "500px", fontSize: "14px", fontFamily: "SFUIText-Medium, Helvetica, Arial, sans-serif" }}>
					<TableContainer component={Paper}>
						<Table aria-label="customized table">
							<TableHead>
								<StyledTableRow>
									<StyledTableCell>
										{columnErrors[0].incorrectColumnName === null
											? "Incorrect column"
											: columnErrors[0].missingColumns.length > 0
											? "Missing columns"
											: columnErrors[0].incorrectColumnName.length > 0
											? "Incorrect column Name"
											: "Extra columns"}
									</StyledTableCell>
									<StyledTableCell align="right">Issue</StyledTableCell>
								</StyledTableRow>
							</TableHead>
							<TableBody>
								{columnErrors.map((row: any, index: number) => (
									<StyledTableRow key={index}>
										<StyledTableCell align="center">
											{row.incorrectColumnName === null
												? row.incorrectColumnName
												: row.missingColumns.length > 0
												? row.missingColumns
												: row.incorrectColumnName.length > 0
												? row.incorrectColumnName
												: row.extraColumns}
										</StyledTableCell>
										<StyledTableCell align="center" component="th" scope="row">
											{row.issue}
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ErrorMessageShowModal;
