import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import PeopleOutlineSharpIcon from "@mui/icons-material/PeopleOutlineSharp";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import { useRouter } from "next/router";
import { FC } from "react";
import { Layout, SubmoduleTabs } from "..";
import { SUBMODULE_DATA } from "../../@types";
import NotAuthorized from "../common/NotAuthorized";
import useAdminEntitlements from "../useAdminEntitlements";
import { useSelector } from "react-redux";
import { ROOT_STATE } from "../../redux";
const orderSubmoduleData: SUBMODULE_DATA[] = [
	{
		label: "Overview",
		icon: <PeopleOutlineSharpIcon />,
		path: "/order",
	},
	{
		label: "Orders",
		icon: <ArticleOutlinedIcon />,
		path: "/order/orders",
	},
	{
		label: "Products",
		icon: <LanOutlinedIcon />,
		path: "/order/products",
	},
	{
		label: "Receipt Template ",
		icon: <ReceiptLongOutlinedIcon />,
		path: "/order/receipt_template",
	},
];
type Props = {
	title: string;
	children: any;
	tab: number;
};
const OrderLayout: FC<Props> = ({ title, children, tab }) => {
	const userReadPermissionOverview = useAdminEntitlements("orders:overview", "read");
	const userReadPermissionOrders = useAdminEntitlements("orders:orders", "read");
	const userReadPermissionProducts = useAdminEntitlements("orders:products", "read");
	const userReadPermissionReceipt = useAdminEntitlements("orders:receiptTemplate", "read");

	const router = useRouter();
	const isOrderORead = router.pathname === "/order" && userReadPermissionOverview;
	const isOrderRead = router.pathname.includes("orders") && userReadPermissionOrders;
	const isProductRead = router.pathname.includes("products") && userReadPermissionProducts;
	const isReceiptRead = router.pathname.includes("receipt_template") && userReadPermissionReceipt;
	const { roleID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);
	return (
		<Layout title={title}>
			<SubmoduleTabs submoduleData={orderSubmoduleData} tab={tab} />
			{isOrderORead || isOrderRead || isProductRead || isReceiptRead ? children : <NotAuthorized roleID={roleID} moduleName= "orders"/>}
		</Layout>
	);
};

export default OrderLayout;
