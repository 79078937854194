import EditIcon from "@mui/icons-material/Edit";
import { DataGridPro, GridColDef, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { DESIGNATIONS_DATA, EXPENSE_SETTING, PROFILES_DATA } from "../../../@types";
import { DG_STYLES } from "../../../constants";
import useAdminEntitlements from "../../useAdminEntitlements";
import useFetch from "../../useFetch";
import ConveyanceModal from "./ConveyanceLimitModal";
import ReadOnlyButton from "../../common/ReadOnlyButton";

type Props = {
	expense: EXPENSE_SETTING | null;
	setExpense: Dispatch<SetStateAction<EXPENSE_SETTING | null>>;
	currencySymbol: string;
	roleID:string;
};

type ROWS_DATA = {
	limitAgainst: null | number;
	carRate: null | number;
	bikeRate: null | number;
	conveyanceLimitArray: EXPENSE_SETTING["conveyanceLimits"];
};

const TravelConveyance: FC<Props> = ({ expense, currencySymbol, roleID }) => {
	const [conveyanceLimits, setConveyanceLimits] = useState(expense?.conveyanceLimits ?? []);
	const { data: dataProfiles } = useFetch<PROFILES_DATA[]>("/users/profiles");
	const { data: dataDesignation } = useFetch<DESIGNATIONS_DATA[]>("/preferences/designations");
	const [open, setOpen] = useState(false);
	const userWritePermission = useAdminEntitlements("settings:expense", "write");

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => setOpen(false);

	const [columns, setColumns] = useState<GridColDef[]>([]);
	const tempColumns = useMemo(
		() => [
			{
				field: "limitAgainst",
				headerName: "Against",
				flex: 1,
				minWidth: 150,
				renderCell: (params: GridValueGetterParams) => {
					if (params.row.limitAgainst == 0) return "Default";
					if (params.row.limitAgainst == 1) return "Team";
					if (params.row.limitAgainst == 2) return "Designation";
					return "-";
				},
			},
			{
				field: "carRate",
				headerName: `Car Rate(${currencySymbol})`,
				flex: 1,
				minWidth: 150,
				renderCell: (params: GridValueGetterParams) => {
					if (params.row.carRate) return params.row.carRate;
					return (
						<div
							style={{
								color: "#1976d2",
								backgroundColor: "#e8edff",
								padding: "8px	13px",
								borderRadius: "20px",
							}}>
							Custom Car Rate
						</div>
					);
				},
			},
			{
				field: "bikeRate",
				headerName: `Bike Rate(${currencySymbol})`,
				flex: 1,
				minWidth: 150,
				renderCell: (params: GridValueGetterParams) => {
					if (params.row.bikeRate) return params.row.bikeRate;
					return (
						<div
							style={{
								color: "#1976d2",
								backgroundColor: "#e8edff",
								padding: "8px	13px",
								borderRadius: "20px",
							}}>
							Custom Bike Rate
						</div>
					);
				},
			},
			{
				field: "state",
				headerName: "Action",
				width: 120,
				renderCell: () =>
					(
						<div className="action-buttons">
							<button type="button" onClick={() => handleOpen()} disabled={!userWritePermission}>
								<EditIcon htmlColor="#8F8F8F" />
							</button>
						</div>
					) 
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[expense, userWritePermission]
	);

	useEffect(() => {
		setColumns(tempColumns);
	}, [tempColumns]);

	const rows = useMemo(() => {
		let formattedRows: ROWS_DATA[] = [];

		let limitAgainst;
		if (conveyanceLimits[0].profileID) {
			limitAgainst = 1;
		} else if (conveyanceLimits[0].designationID) {
			limitAgainst = 2;
		} else {
			limitAgainst = 0;
		}
		formattedRows = [
			{
				limitAgainst,
				carRate: limitAgainst ? null : conveyanceLimits[0].carRate,
				bikeRate: limitAgainst ? null : conveyanceLimits[0].bikeRate,
				conveyanceLimitArray: conveyanceLimits,
			},
		];

		return formattedRows;
	}, [conveyanceLimits]);

	return (
		<div className="custom-field-settings">
			<div className="custom-field-top">
				<div className="section">
				<div className="section-part">
					<p className="title">Travel conveyance</p>
					{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="settings"/> : null}
					</div>
					<p className="subtitle">Define Reimbursement rates for travel by Car or Bike. The same will apply to advances as well.</p>
				</div>
			</div>
			<div className="table_container">
				<div className="datagrid-table" style={{ height: "300px" }}>
					<DataGridPro
						sx={DG_STYLES}
						getRowId={(row) => row.expenseCategoryID || "it_will_always_be_one_row"}
						rows={rows}
						columns={columns}
						rowHeight={70}
						disableSelectionOnClick
						onRowClick={() => userWritePermission && handleOpen()}
						disableColumnFilter
						pagination
					/>
				</div>
			</div>
			{open ? (
				<ConveyanceModal
					handleClose={handleClose}
					profiles={dataProfiles?.data ?? []}
					designations={dataDesignation?.data ?? []}
					setConveyanceLimits={setConveyanceLimits}
					row={rows[0]}
				/>
			) : null}
		</div>
	);
};

export default TravelConveyance;
