/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
// react
import { Dispatch, FC, FormEvent, SetStateAction, useState } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
// utils
import { validateTasks } from "../../../utils";
import { DATE_TYPE_FORMAT } from "../../../constants";
// components
import SearchBox from "./SearchBox";
import CustomFieldsComplexTasks from "./CustomFieldsComplexTasks";
// query
import { useMutation, useQuery } from "@apollo/client";
import { UPSERT_PENDING_TASK } from "../../../schema/tasks";
import { ACCESS_CONTROL_USER, TASK_TYPE } from "../../../@types";
import { GET_ALL_ENTITYMETADATA_BY_ENTITY_FOR_CURRENT_COMPANY } from "../../../schema/entityMetadata";
// libraries
import dayjs from "dayjs";
import moment from "moment";
import Link from "next/link";
import ReactSelect from "react-select";
import GoogleMapReact from "google-map-react";
// mui
import AddIcon from "@mui/icons-material/Add";
import { CircularProgress, TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const mapOptions = {
	fullscreenControl: false,
	mapTypeControl: false,
	styles: [],
	streetViewControl: true,
};

type Props = {
	setExpandedPT: Dispatch<SetStateAction<boolean>>;
	pendingTaskID: null | string;
	setPTasks: Dispatch<SetStateAction<TASK_TYPE[] | null>>;
	PTasks: TASK_TYPE[];
	date: string; //selected date from filter,
	allAdmins: Omit<ACCESS_CONTROL_USER, "employees">[];
};

const PendingTaskFormSidebar: FC<Props> = ({ setExpandedPT, pendingTaskID, PTasks, date, setPTasks, allAdmins }) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const {
		data: entityMetaData,
		loading: loadingEntity,
		refetch: refetchEntity,
	} = useQuery(GET_ALL_ENTITYMETADATA_BY_ENTITY_FOR_CURRENT_COMPANY, {
		variables: { entity: "Tasks" },
	});

	const [upsertPendingTask] = useMutation(UPSERT_PENDING_TASK);
	const user = useSelector((state: ROOT_STATE) => state.user);
	const [errors, setErrors] = useState<string[]>([]);
	const currentTask = PTasks.find((task) => task.pendingTaskID === pendingTaskID);
	const [formData, setFormData] = useState<TASK_TYPE>(currentTask!);
	const [isSaving, setIsSaving] = useState(false);
	const [zoom, setZoom] = useState(17);
	const [center, setCenter] = useState({
		lat: currentTask?.lat || 22.593684,
		lng: currentTask?.lon || 81.96288,
	});

	const [mapApiLoaded, setMapApiLoaded] = useState(false);
	const [map, setMap] = useState<any>(null);
	const [maps, setMaps] = useState<any>(null);
	const [draggable, setDraggable] = useState(true);

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsSaving(true);
		const saveErrors = validateTasks(formData);
		const toSendFormData = { ...formData };
		setErrors([...saveErrors]);
		if (saveErrors.length > 0) {
			setIsSaving(false);
			return;
		}
		const toSend = {
			input: {
				address: toSendFormData.address,
				createdTs: toSendFormData.createdTs,
				date: toSendFormData.date,
				endTime: toSendFormData.endTime,
				lastModifiedTs: moment().valueOf(),
				lat: toSendFormData.lat,
				lon: toSendFormData.lon,
				startTime: toSendFormData.startTime,
				taskDescription: toSendFormData.taskDescription,
				pendingTaskID: toSendFormData.pendingTaskID,
				owningAdminID: toSendFormData.owningAdminID,
				customFieldsComplex: toSendFormData.customFieldsComplex
					? toSendFormData.customFieldsComplex.map((field) => ({
							fieldID: field.fieldID,
							fieldValue: field.fieldValue,
							selectedOptions: field.selectedOptions,
					  }))
					: null,
			},
		};

		const response = await upsertPendingTask({ variables: toSend });
		dispatch(showNotification({ message: "Successfully modified pending task", severity: "success" }));
		let formattedTask = [...PTasks];
		const upComingTasks = response.data.upsert_pending_task?.data.filter((task: TASK_TYPE) => task.date === date);
		if (upComingTasks && upComingTasks.length > 0) {
			formattedTask = formattedTask.map((d) => {
				if (d.pendingTaskID === upComingTasks[0].pendingTaskID) {
					return { ...formData };
				} else {
					return { ...d };
				}
			});

			formattedTask = formattedTask.filter((d) => d.owningAdminID === user.adminDetails.adminID);
			setPTasks([...formattedTask]);
		}

		setIsSaving(false);
		setExpandedPT(false);
	};

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		if (!name || name.length === 0) return;
		setFormData((prevState) => ({ ...prevState, [name]: value }));
	};

	const changeDynamicSelection = (e: any) => {
		const newFormData = { ...formData };

		delete newFormData.routeID;
		delete newFormData.clientID;
		newFormData.lat = e.lat;
		newFormData.lon = e.lng;
		newFormData.address = e.address;
		setFormData(newFormData);
	};

	const generateAddress = (lat: any, lng: any, maps: any) => {
		if (!maps) return;
		const geocoder = new maps.Geocoder();
		geocoder.geocode({ location: { lat: lat, lng: lng } }, (results: any, status: string) => {
			if (status === "OK") {
				if (results[0]) {
					changeDynamicSelection({ lat: lat, lng: lng, address: results[0].formatted_address });
				} else {
					console.log("No results found");
				}
			} else {
				console.log("Geocoder failed due to: " + status);
			}
		});
	};

	const apiHasLoaded = (map: any, maps: any) => {
		setMapApiLoaded(true);
		setMap(map);
		setMaps(maps);
		generateAddress(formData.lat || 22.593684, formData.lon || 81.96288, maps);
	};

	const onPlacesChanged = (place: any) => {
		generateAddress(place.geometry.location.lat(), place.geometry.location.lng(), maps);
	};

	const onMapChanges = (newCenter: any, newZoom: any) => {
		setCenter(newCenter);
		setZoom(newZoom);
	};

	const onMarkerInteraction = (_childKey: any, _childProps: any, mouse: any) => {
		setDraggable(false);
		changeDynamicSelection({ lat: mouse.lat, lng: mouse.lng, address: formData.address });
	};

	const onMarkerInteractionMouseUp = (_childKey: any, _childProps: any, mouse: any) => {
		setDraggable(true);
		generateAddress(mouse.lat, mouse.lng, maps);
	};

	const getValueForOwningAdminID = (allAdmins: Omit<ACCESS_CONTROL_USER, "employees">[], owningAdminID: any) => {
		const adminMatched = allAdmins.find((d) => d.adminID === owningAdminID);
		return adminMatched?.lastname ? adminMatched.firstname + " " + adminMatched.lastname : adminMatched?.firstname;
	};

	return (
        <div className="user_add tasks_edit">
			<div className="user_add-title">
				<h1>Edit Pending Task</h1>
				<button onClick={() => setExpandedPT(false)}>
					<CloseOutlinedIcon />
				</button>
			</div>
			<form className="user_add-form" onSubmit={handleSubmit}>
				<div className="map" id="map2">
					{mapApiLoaded && (
						<SearchBox
							onPlacesChanged={onPlacesChanged}
							onChange={(e: any) => setFormData({ ...formData, address: e.target.value })}
							maps={maps}
							value={formData.address}
							map={map}
						/>
					)}
					<GoogleMapReact
						bootstrapURLKeys={{
							key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY!,
							libraries: ["places"],
						}}
						options={mapOptions}
						center={center}
						zoom={zoom}
						yesIWantToUseGoogleMapApiInternals
						onChange={(e) => onMapChanges(e.center, e.zoom)}
						onChildMouseDown={onMarkerInteraction}
						onChildMouseUp={onMarkerInteractionMouseUp}
						onChildMouseMove={onMarkerInteraction}
						draggable={draggable}
						onGoogleApiLoaded={({ map, maps }) => {
							apiHasLoaded(map, maps);
						}}>
						<Marker lat={formData.lat || 22.593684} lng={formData.lon || 81.96288} />
					</GoogleMapReact>
				</div>
				<TextField
					required
					disabled={isSaving}
					name="taskDescription"
					label="Add Description"
					value={formData?.taskDescription || ""}
					onChange={handleChange}
				/>
				<p>Pending with admin:</p>
				<ReactSelect
					value={{
						value: formData?.owningAdminID,
						label: getValueForOwningAdminID(allAdmins, formData?.owningAdminID),
					}}
					onChange={(e) => {
						setFormData((prevState) => ({ ...prevState, owningAdminID: e?.value! }));
					}}
					isClearable={true}
					placeholder={"Select Admin"}
					className="react-select"
					options={allAdmins?.map((i: Omit<ACCESS_CONTROL_USER, "employees">) => ({
						value: i.adminID,
						label: i.lastname ? i.firstname + " " + i.lastname : i.firstname,
					}))}
				/>
				<div className="custom_fields_complex">
					<div className="title">
						<h5>Custom Fields</h5>
						<Link prefetch={false} href="/settings/custom-fields" target="blank ">

                            <AddIcon />

                        </Link>
						<button disabled={loadingEntity} className="refetch" type="button" onClick={() => refetchEntity()}>
							<RefreshOutlinedIcon />
						</button>
					</div>
					{entityMetaData?.get_all_entitymetadata_by_entity_for_current_company &&
						Array.isArray(entityMetaData?.get_all_entitymetadata_by_entity_for_current_company) &&
						entityMetaData?.get_all_entitymetadata_by_entity_for_current_company.length > 0 && (
							<CustomFieldsComplexTasks
								task={formData}
								setTask={setFormData}
								entityMetaData={entityMetaData.get_all_entitymetadata_by_entity_for_current_company}
								isDisabled={isSaving}
							/>
						)}
				</div>
				<div className="group">
					<label>
						Start date
						<input
							type="date"
							required
							name="date"
							min={moment().format(DATE_TYPE_FORMAT)}
							value={formData?.date || ""}
							disabled={isSaving}
							onChange={handleChange}
						/>
					</label>
					<div className="half">
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<TimePicker
								disabled={isSaving}
								label="Start Time"
								value={dayjs(formData.startTime)}
								onChange={(n) =>
									setFormData((p) => ({
										...p,
										startTime: dayjs(n).valueOf(),
										endTime: dayjs(n).add(30, "minutes").valueOf(),
									}))
								}
								slotProps={{ textField: { variant: "outlined", required: true } }}
							/>
						</LocalizationProvider>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<TimePicker
								disabled={isSaving}
								label="End Time"
								value={dayjs(formData.endTime)}
								onChange={(n) => setFormData((p) => ({ ...p, endTime: dayjs(n).valueOf() }))}
								slotProps={{ textField: { variant: "outlined", required: true } }}
							/>
						</LocalizationProvider>
					</div>
				</div>
				<ul className="errors">
					{errors.map((e, i) => (
						<li key={i}>{e}</li>
					))}
				</ul>
				<div className="action_buttons">
					<button type="reset" disabled={isSaving} onClick={() => setExpandedPT(false)}>
						Cancel
					</button>
					<div>
						<button type="submit" disabled={isSaving}>
							{isSaving ? <CircularProgress style={{ width: "15px", height: "15px" }} /> : "Save"}
						</button>
					</div>
				</div>
			</form>
		</div>
    );
};

export default PendingTaskFormSidebar;

const Marker = ({ lat, lng }: any) => {
	console.log("lat", lat, lng);
	return <div className="wrapper"></div>;
};
