import { MarkerClusterer } from "@googlemaps/markerclusterer";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { CircularProgress } from "@mui/material";
import GoogleMapReact from "google-map-react";
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SITES_DATA } from "../../../@types";
import { deleteSites } from "../../../api/clients_sites";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { mapOptions, mapStyles } from "../../../utils";
import SiteDetailedSidebarView from "./SiteDetailedSidebarView";
import SitesList from "./SitesList";
import RenderOutOfBoundsMarkers from "./RegionOutOfBoundMarkers";

type Props = {
	sites: SITES_DATA[];
	loading: boolean;
	setSites: Dispatch<SetStateAction<SITES_DATA[]>>;
};
const center = { lat: 22.593684, lng: 81.96288 };
const zoom = 5;

type Location = {
	lat: number;
	lng: number;
	clientID: string;
	clientName: string;
	radius: number;
};

type PointMarkers = {
	[clientID: string]: {
		marker: any;
		circle: any;
		client: Location;
	};
};

const SITE_MARKER_COLOR = "#243140";
const SITE_LABEL_COLOR = "#000000";
const polyMarkerColor = "#243140";
const polygonOptions = {
	strokeColor: polyMarkerColor,
	strokeOpacity: 0.5,
	strokeWeight: 2,
	fillColor: polyMarkerColor,
	fillOpacity: 0.15,
};
let Popup: any;
const regionMarkers: any = {};

const createPopupClass = (maps: any) => {
	/**
	 * A customized popup on the map.
	 * @param {!google.maps.LatLng} position
	 * @param {!Element} content The bubble div.
	 * @constructor
	 * @extends {google.maps.OverlayView}
	 */
	function Popup(this: any, position: any, content: any) {
		this.position = position;

		content.classList.add("popup-bubble");

		var bubbleAnchor = document.createElement("div");
		bubbleAnchor.classList.add("popup-bubble-anchor");
		bubbleAnchor.appendChild(content);

		this.containerDiv = document.createElement("div");
		this.containerDiv.classList.add("popup-container");
		this.containerDiv.appendChild(bubbleAnchor);

		maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
	}

	Popup.prototype = Object.create(maps.OverlayView.prototype);

	Popup.prototype.onAdd = function () {
		this.getPanes().floatPane.appendChild(this.containerDiv);
	};

	Popup.prototype.setText = function (text: string) {
		this.containerDiv.childNodes[0].childNodes[0].innerHTML = text;
	};

	Popup.prototype.onRemove = function () {
		if (this.containerDiv.parentElement) {
			this.containerDiv.parentElement.removeChild(this.containerDiv);
		}
	};

	Popup.prototype.draw = function () {
		var divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

		var display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? "block" : "none";

		if (display === "block") {
			this.containerDiv.style.left = divPosition.x + "px";
			this.containerDiv.style.top = divPosition.y + "px";
		}
		if (this.containerDiv.style.display !== display) {
			this.containerDiv.style.display = display;
		}
	};

	return Popup;
};
const SitesMap: FC<Props> = ({ sites, loading, setSites }) => {
	const [expanded, setExpanded] = useState(false);
	const [selectedSiteId, setSelectedSiteId] = useState<null | string>(null);
	const [pointMarkers, setPointMarkers] = useState<PointMarkers>({});
	const [map, setMap] = useState<any>(null);
	const [maps, setMaps] = useState<any>(null);
	const dispatch = useDispatch<APP_DISPATCH>();
	const [isDeleting, setIsDeleting] = useState(false);
	const selectedSite = sites.find((site) => site.clientID === selectedSiteId);

	const [outOfBoundsMarkers, setOutOfBoundsMarkers] = useState<any>([]);

	useEffect(() => {
		// Update the out of bounds markers whenever clients or map changes
		updateOutOfBoundsMarkers();
	}, [sites, map]);

	const updateOutOfBoundsMarkers = () => {
		if (map && sites.length > 0) {
			const bounds = map.getBounds();
			const updatedOutOfBoundsMarkers = sites.filter((client) => {
				// check if a region or point marker is out of bounds
				if (client.polyline) {
					const decodedPath = maps.geometry.encoding.decodePath(client.polyline);
					return !decodedPath.every((point: any) => bounds.contains(point));
				}
				return !bounds.contains({ lat: client.latitude, lng: client.longitude });
			});
			setOutOfBoundsMarkers(updatedOutOfBoundsMarkers);
		} else {
			setOutOfBoundsMarkers([]);
		}
	};

	const genMarker = (maps: any, center: any, name: string, siteID: string) => {
		var markerIcon2 = {
			url: "/images/siteMarker.png",
			scaledSize: new maps.Size(25, 25),
			labelOrigin: new maps.Point(20, -10),
			anchor: new maps.Point(12.5, 13),
		};

		return new maps.Marker({
			position: center,
			label: {
				text: name,
				color: SITE_LABEL_COLOR,
				fontSize: "16px",
			},
			id: siteID,
			draggable: false,
			icon: markerIcon2,
		});
	};

	const genCircle = (maps: any, center: any, radius: number) =>
		new maps.Circle({
			strokeColor: SITE_MARKER_COLOR,
			strokeOpacity: 0.5,
			strokeWeight: 2,
			fillColor: SITE_MARKER_COLOR,
			fillOpacity: 0.15,
			center: center,
			radius: radius || 200,
			editable: false,
			draggable: false,
		});

	const zoomToSite = (map: any, maps: any, location: any) => {
		var bounds = new maps.LatLngBounds();
		bounds.extend(location);

		map.fitBounds(bounds);
		map.setZoom(17);
	};

	const calcHighestPointOfPolygon = (path: any, maps: any) => {
		var bounds = new maps.LatLngBounds();

		for (var i = 0; i < path.length; i++) {
			bounds.extend(path[i]);
		}

		return bounds.getCenter();
	};

	const zoomToPolygon = useCallback((locations: any, maps: any, map: any) => {
		var bounds = new maps.LatLngBounds();
		locations.map((o: any) => bounds.extend(o));
		map.fitBounds(bounds);
	}, []);

	const onSiteClicked = useCallback(
		(siteID: string) => {
			if (pointMarkers[siteID]) {
				const { client, circle } = pointMarkers[siteID];
				const center = new maps.LatLng(client.lat, client.lng);

				zoomToSite(map, maps, center);
				circle.setMap(map);
			} else {
				const { polygon } = regionMarkers[siteID];
				zoomToPolygon(polygon.getPath().getArray(), maps, map);
				polygon.setMap(map);
			}
		},
		[pointMarkers, maps, map, zoomToPolygon]
	);

	const removeCircleFromMarkers = useCallback(() => {
		if (selectedSiteId) {
			if (pointMarkers[selectedSiteId]) {
				const { circle } = pointMarkers[selectedSiteId];
				circle.setMap(null);
			} else {
				const { polygon } = regionMarkers[selectedSiteId];
				polygon.setMap(null);
			}
		}
	}, [pointMarkers, selectedSiteId]);

	const addPolygon = useCallback(
		(polygon: any, site: any, maps: any, map: any) => {
			var center = calcHighestPointOfPolygon(polygon.getPath().getArray(), maps);
			var iDiv = document.createElement("div");
			var node = document.createTextNode(site.clientName);
			//node.id = 'textContent';
			iDiv.append(node);
			var popup = new Popup(center, iDiv);
			popup.setMap(map);

			popup.containerDiv.addEventListener("click", () => {
				setExpanded(true);
				setSelectedSiteId(site.clientID);
				zoomToPolygon(polygon.getPath().getArray(), maps, map);
				polygon.setMap(map);
			});

			//used for state management
			return { polygon: polygon, infowindow: popup, site: site };
		},
		[zoomToPolygon]
	);

	const handleApiLoaded = useCallback(
		(map: any, maps: any) => {
			setMap(map);
			setMaps(maps);
			const globalBounds = new maps.LatLngBounds();
			map.setOptions({ styles: mapStyles });

			Popup = createPopupClass(maps);

			const locations: Location[] = [];
			const regions: any = [];

			sites.map((u: any) => {
				if (u["polyline"]) {
					regions.push({
						lat: u.latitude,
						lng: u.longitude,
						clientID: u.clientID,
						clientName: u.clientName,
						radius: u.radius,
						polyline: u.polyline,
					});
				} else {
					locations.push({
						lat: u.latitude,
						lng: u.longitude,
						clientID: u.clientID,
						clientName: u.clientName,
						radius: u.radius,
					});
				}
			});

			/**
			 * markers draw
			 */
			const tempPointMarkers: PointMarkers = {};

			const markers =
				locations &&
				locations.map((location) => {
					const center = new maps.LatLng(location.lat, location.lng);
					const marker = genMarker(
						maps,
						center,
						location.clientName.replace(/(\r\n\t|\n|\r\t)/gm, ""),
						location.clientID
					);
					const circle = genCircle(maps, { lat: location.lat, lng: location.lng }, location.radius);

					tempPointMarkers[location.clientID] = {
						marker: marker,
						circle: circle,
						client: location,
					};

					circle.setMap(map);
					maps.event.addListener(marker, "click", function () {
						setSelectedSiteId(location.clientID);
						setExpanded(true);
						zoomToSite(map, maps, center);
						circle.setMap(map);
					});

					return marker;
				});

			setPointMarkers(tempPointMarkers);

			new MarkerClusterer({ map, markers });

			regions.forEach(function (region: any) {
				var decodedPath = maps.geometry.encoding.decodePath(region.polyline);
				const pOpts = Object.assign(polygonOptions, { paths: decodedPath, id: region.clientID });
				const polygon = new maps.Polygon(pOpts);
				regionMarkers[region.clientID] = addPolygon(polygon, region, maps, map);
				polygon.setMap(map);

				maps.event.addListener(polygon, "click", function () {
					setSelectedSiteId(polygon.id);
					setExpanded(true);
					zoomToPolygon(polygon.getPath().getArray(), maps, map);
					polygon.setMap(map);
				});
			});

			//zoom to fit all polygons
			if (regions.length > 0) {
				regions.forEach((region: any) => {
					var decodedPath = maps.geometry.encoding.decodePath(region.polyline);
					decodedPath.forEach((point: any) => {
						globalBounds.extend(point);
					});
					map.fitBounds(globalBounds);
				});
			}

			//zoom to fit all markers
			if (markers.length > 0) {
				markers.forEach((marker: any) => {
					globalBounds.extend(marker.getPosition());
				});
				map.fitBounds(globalBounds);
			}
		},
		[addPolygon, sites, zoomToPolygon]
	);

	const deleteHandler = async (id: string) => {
		if (!id || id.length === 0) return;
		setIsDeleting(true);
		const { success, message } = await deleteSites([id]);
		setIsDeleting(false);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (success) setSites(sites.filter((site) => site.clientID !== id));
	};

	if (!process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY || loading)
		return (
			<div className="small_circular-spinner">
				<CircularProgress />
			</div>
		);
	return (
		<>
			<div className="sub_header">
				{expanded ? (
					<>
						Sites List
						<button className="for_mobile_view" onClick={() => setExpanded(false)}>
							<CloseIcon />
						</button>
					</>
				) : (
					<button className="for_mobile_view" onClick={() => setExpanded(true)}>
						<MenuIcon />
					</button>
				)}
			</div>
			<div className="datagrid-table">
				<GoogleMapReact
					bootstrapURLKeys={{
						key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY!,
						language: "en",
						region: "IN",
						libraries: ["geometry"],
					}}
					options={mapOptions}
					center={center}
					yesIWantToUseGoogleMapApiInternals={true}
					defaultZoom={zoom}
					defaultCenter={{ lat: 51.506, lng: -0.169 }}
					style={{ position: "relative", width: "100%", height: "100%" }}
					onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
					onChange={() => updateOutOfBoundsMarkers()}></GoogleMapReact>
				<RenderOutOfBoundsMarkers map={map} maps={maps} outOfBoundsMarkers={outOfBoundsMarkers} />
				{selectedSiteId === null || selectedSite === undefined ? (
					<SitesList
						setSelectedSiteId={setSelectedSiteId}
						sites={sites}
						expanded={expanded}
						onSiteClicked={onSiteClicked}
					/>
				) : (
					<SiteDetailedSidebarView
						setSelectedSiteId={setSelectedSiteId}
						selectedSite={selectedSite}
						isDeleting={isDeleting}
						expanded={expanded}
						removeCircleFromMarkers={removeCircleFromMarkers}
						deleteHandler={deleteHandler}
					/>
				)}
			</div>
		</>
	);
};

export default SitesMap;
