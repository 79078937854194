//react
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
//redux
import { useSelector } from "react-redux";
import { ROOT_STATE } from "../../../redux";
//moment
import moment from "moment";
import "moment-timezone";
//components
import { EXECUTIVE_USER, TIMELINE } from "../../../@types";
import { DEFAULT_TIMEZONE } from "../../../constants";
import { DisplayJourneyInfo, EventInsideTask } from "./TimelineUtils";
import Visit from "./Visit";
import useAdminInfo from "../../useAdminInfo";
import { attendanceStatusTypes } from "../../../constants/attendanceConstant";
//mui
import { Button, Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import EditRoadOutlinedIcon from "@mui/icons-material/EditRoadOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import StayCurrentPortraitOutlinedIcon from "@mui/icons-material/StayCurrentPortraitOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import TaskIcon from "@mui/icons-material/Task";
import ImageIcon from "@mui/icons-material/Image";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import BadAppStatusModal from "../../common/BadAppStatusModal";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import InfoIcon from "@mui/icons-material/Info";

type PARTIAL_USER = Pick<EXECUTIVE_USER, "employeeID" | "firstName" | "lastName" | "parentAdminID" | "internalEmpID">;

type Props = {
	timeLineData: TIMELINE;
	visitClickedState: number;
	setVisitClickedState: Dispatch<SetStateAction<number>>;
	selectedEmployee: PARTIAL_USER | null;
	selectedDate: any;
};

type VisitOrTravel = TIMELINE["visits"] | TIMELINE["travels"];

type CombinedData = VisitOrTravel & {
	startTimeString?: string;
	aggregated_distance?: number;
	distance?: TIMELINE["travels"][number]["distance"];
	events?: any
};

const TimeLineSidebar: FC<Props> = ({
	timeLineData,
	visitClickedState,
	setVisitClickedState,
	selectedEmployee,
	selectedDate,
}) => {
	const { tz, companyID } = useAdminInfo();
	const numberOfMissing = useRef(0);
	const [aggregatedData, setAggregatedData] = useState<any>([]);
	const [expandActivities, setExpandActivities] = useState<boolean>(false);
	const previousTravelDistance = useRef<null | TIMELINE["travels"][number]["distance"]>(null);
	const complianceRating = useMemo(
		() => (timeLineData?.complianceRating ? (timeLineData?.complianceRating === "A" ? "good" : "bad") : undefined),
		[timeLineData]
	);
	const setupRating = useMemo(
		() => (timeLineData?.setupRating ? (timeLineData?.setupRating === "A" ? "good" : "bad") : undefined),
		[timeLineData]
	);
	const [badAppSetting, setBadAppSetting] = useState(false);
	function activitiesCount(tasksCount: any, visit: any) {
		const count = visit.tasks.filter((o: any) => {
			if (
				o.type === "meeting" ||
				(o.type === "photo" && o.category !== "ATTENDANCE" && o.category !== "ODO") ||
				o.type === "form"
			) {
				return o;
			} else {
				return null;
			}
		}).length;
		return tasksCount + count;
	}
	const totalTasks = useMemo(() => {
		//which are considered 'activities'
		if (timeLineData?.visits && Array.isArray(timeLineData.visits)) {
			const tasksToCount = timeLineData.visits.reduce(activitiesCount, 0);
			return tasksToCount;
		} else {
			return 0;
		}
	}, [timeLineData?.visits]);
	const timelineVisits = useMemo(() => {
		if (timeLineData?.visits && Array.isArray(timeLineData.visits)) {
			const visits = timeLineData.visits.reduce(
				(visits: any, currVisits: any) => {
					currVisits.tasks.forEach((o: any) => {
						if (
							o.type === "meeting" ||
							(o.type === "photo" && o.category !== "ATTENDANCE" && o.category !== "ODO") ||
							o.type === "form"
							// o.type === "order" ||
							// o.type === "newClient"
						) {
							visits[o.type] = (visits[o.type] || 0) + 1;
						}
					});
					return visits;
				},
				{
					meeting: 0,
					photo: 0,
					form: 0,
					// order: 0,
					// newClient: 0
				}
			);
			return visits;
		} else {
			return {
				meeting: 0,
				photo: 0,
				form: 0,
				// order: 0,
				// newClient: 0,
			};
		}
	}, [timeLineData?.visits]);
	useEffect(
		() => () => {
			numberOfMissing.current = 0;
			previousTravelDistance.current = null;
		},
		[]
	);

	useEffect(() => {
		const aggregate = async () => {
			const combinedData: any = [...timeLineData.visits, ...timeLineData.travels];

			const newCombinedData: CombinedData[] = combinedData.map((each: any) => {
				const newEach = { ...each }; // create a copy of the object
				newEach.startTimeString = moment(newEach.startTime)
					.tz(tz || DEFAULT_TIMEZONE)
					.format("hh:mm A"); // modify the copy
				return newEach; // return the modified copy
			});

			// Sort the data by start and end times
			newCombinedData.sort((a, b) => {
				const aStartTime = new Date(a["startTime"]).getTime();
				const bStartTime = new Date(b["startTime"]).getTime();
				const aEndTime = new Date(a["endTime"]).getTime();
				const bEndTime = new Date(b["endTime"]).getTime();

				// sorting by start time, end time
				if (aStartTime !== bStartTime) {
					return aStartTime - bStartTime;
				} else {
					return aEndTime - bEndTime;
				}
			});

			let total_distance = 0;

			// Add aggregated_distance property to travel objects
			newCombinedData.forEach((each: any) => {
				if (each.hasOwnProperty("distance")) {
					total_distance += each.distance;
				}
				each.aggregated_distance = total_distance;
			});

			/* So here this newCombinedData has the events array in every object.
				In every object of events we are looking for the eventType === 8(Punched In).
				 We will there is key "id" in every events's objects which we will match it with the inAttendanceID key.
				  This "inAttendanceID" key is present in the other array object named odoReadings
				  	The purpose is to include skip and skipReason key from odoReadings to the event*/

					  newCombinedData.forEach(function (data) {
						data.events.forEach((event:any) => {
						  if (event.eventTypeID === 8 && timeLineData?.odoReadings?.length > 0) {
							const matchingOdoReadings:any = timeLineData?.odoReadings?.filter(
							  (odo:any) => odo.inAttendanceID === event.id
							);
					  
							if (matchingOdoReadings?.length === 1) {
							event.skip = matchingOdoReadings[0].skip;
							  event.skipReason = matchingOdoReadings[0]?.skipReason;
							}
						  }
						});
					  });
			
			// Write the data to a single file
			setAggregatedData([...newCombinedData]);
		};
		async function aggregateData() {
			await aggregate();
		}
		aggregateData();
	}, [timeLineData]);
	const findTotalTravelsTillNow = (nowIndex: number) => {
		let count = 0;
		for (let i = 0; i <= aggregatedData.length; i++) {
			if (nowIndex == i) {
				break;
			}
			if (!aggregatedData[i].hasOwnProperty("distance") && !aggregatedData[i].missing) {
				count += 1;
			}
		}
		return count;
	};
	const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} arrow classes={{ popper: className }} />
	))(({ }) => ({
		[`& .${tooltipClasses.arrow}`]: {
			color: "#42444a",
			opacity: 0.7,
		},
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: "rgb(66,68,74, 0.7)",
			opacity: 0.5,
			padding: "0.3rem",
		},
	}));

	const {
		susbscriptionMetadata: { data: subsMetadata },
	} = useSelector((state: ROOT_STATE) => ({
		susbscriptionMetadata: state.susbscriptionMetadata,
	}));
	
	return (
		<aside className="timeline_sidebar">
			<div className={`timeline_sidebar__header ${timeLineData.odometerEnabled ? "odo_enabled" : ""}`}>
				<div>
					<CalendarMonthOutlinedIcon />
					<h6>Attendance Status</h6>
					{selectedDate === moment(new Date()).format("YYYY-MM-DD") ? (
						<time>-</time>
					) : (
						<div className="timeline_sidebar_attendance_status">
							<BootstrapTooltip
								title={`First half ${attendanceStatusTypes[timeLineData.attendanceObj.attendanceResultCode].label}`}>
								<div
									style={{
										backgroundColor: `${attendanceStatusTypes[timeLineData.attendanceObj.attendanceResultCode].backgroundColor
											}`,
										borderRadius: "8px 0px 0px 8px",
										borderRight: "1px solid rgb(224, 224, 224)",
										cursor: "help",
									}}>
									{timeLineData.attendanceObj.attendanceResultCode !== 5 ? (
										<p
											style={{
												color: `${attendanceStatusTypes[timeLineData.attendanceObj.attendanceResultCode].symbolColor}`,
											}}>
											{attendanceStatusTypes[timeLineData.attendanceObj.attendanceResultCode].symbol}
										</p>
									) : (
										<p
											style={{
												color: `${attendanceStatusTypes[timeLineData.attendanceObj.attendanceResultCode].symbolColor}`,
											}}>
											OL
										</p>
									)}
								</div>
							</BootstrapTooltip>
							<BootstrapTooltip
								title={`Second half ${attendanceStatusTypes[timeLineData.attendanceObj.attendanceResultCode2].label}`}>
								<div
									style={{
										backgroundColor: `${attendanceStatusTypes[timeLineData.attendanceObj?.attendanceResultCode2].backgroundColor
											}`,
										cursor: "help",
										borderRadius: "0px 8px 8px 0px",
									}}>
									{timeLineData.attendanceObj.attendanceResultCode2 !== 5 ? (
										<p
											style={{
												color: `${attendanceStatusTypes[timeLineData.attendanceObj.attendanceResultCode2].symbolColor}`,
											}}>
											{attendanceStatusTypes[timeLineData.attendanceObj.attendanceResultCode2].symbol}
										</p>
									) : (
										<p
											style={{
												color: `${attendanceStatusTypes[timeLineData.attendanceObj.attendanceResultCode2].symbolColor}`,
											}}>
											OL
										</p>
									)}
								</div>
							</BootstrapTooltip>
						</div>
					)}
				</div>
				<div>
					<AccessTimeOutlinedIcon htmlColor="#F54747" />
					<h6>Attendance Hours</h6>
					{selectedDate === moment(new Date()).format("YYYY-MM-DD") ? (
						<time>-</time>
					) : (
						<time>
							{moment.duration(timeLineData.attendanceObj?.totalTimeAttendance).hours() +
								":" +
								`${moment.duration(timeLineData.attendanceObj?.totalTimeAttendance).minutes() < 10
									? "0" + moment.duration(timeLineData.attendanceObj?.totalTimeAttendance).minutes()
									: moment.duration(timeLineData.attendanceObj?.totalTimeAttendance).minutes()
								}`}{" "}
							Hours
						</time>
					)}
				</div>
				<div>
					<AvTimerIcon htmlColor="#1976d2" />
					<h6>Time Tracked</h6>
					<time>
						{moment.duration(timeLineData.attendanceObj?.totalTimeTracked).hours() +
							":" +
							moment.duration(timeLineData.attendanceObj?.totalTimeTracked).minutes()}{" "}
						Hours
					</time>
				</div>
				<div>
					<EditRoadOutlinedIcon htmlColor="#25A8F4" />
					<h6>GPS Distance</h6>
					{setupRating === "bad" ? (
						timeLineData.hideBadDistance === 1 ? (
							<time
								style={{
									color: "rgb(245, 71, 71)",
								}}>
								Not Calculated
							</time>
						) : (
							<time>{timeLineData.rkDistance ? timeLineData.rkDistance : "--"} KM</time>
						)
					) : (
						<time>{timeLineData.rkDistance ? timeLineData.rkDistance : "--"} KM</time>
					)}
				</div>
				{timeLineData.odometerEnabled ? (
					<div>
						<SpeedOutlinedIcon htmlColor="#25A8F4" />
						<h6>Odometer Reading</h6>
						<time>
							{timeLineData.visits?.length > 0 &&
								timeLineData.odometerCalculatedDistance <= 0 &&
								timeLineData.odoReadings?.length === 0 &&
								"-- KM"}

							{timeLineData.visits?.length > 0 &&
								timeLineData.odometerCalculatedDistance <= 0 &&
								timeLineData.odoReadings?.length !== 0 &&
								"0 KM"}

							{timeLineData.visits?.length > 0 &&
								timeLineData.odometerCalculatedDistance > 0 &&
								timeLineData.odometerCalculatedDistance + " KM"}

							{timeLineData.visits?.length <= 0 && "-- KM"}
						</time>
					</div>
				) : null}
				{(subsMetadata?.tasks === 1 || subsMetadata?.forms === 1) && (
					// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
					<div
						onClick={() => setExpandActivities(!expandActivities)}
						style={{
							background: expandActivities ? "white" : "transparent",
							cursor: "pointer",
							position: "relative",
							overflow: "hidden",
						}}>
						<WorkOutlineOutlinedIcon htmlColor="#40A636" />
						<h6>Activities</h6>
						<time>{totalTasks}</time>
						<div
							style={{
								position: "absolute",
								bottom: "-5px",
								left: 0,
								width: "100%",
							}}>
							{expandActivities ? (
								<KeyboardArrowUpIcon style={{ width: "20px", color: "gray" }} />
							) : (
								<KeyboardArrowDownIcon style={{ width: "20px", color: "gray" }} />
							)}
						</div>
					</div>
				)}
			</div>
			{expandActivities && (
				<div className={`timeline_sidebar__expanded_activities`}>
					{Object.keys(timelineVisits).map((key: string, index: number) => (
						<div className="tile" key={index}>
							{key === "meeting" && <TaskIcon htmlColor="#008500" className="tile-icon" />}
							{key === "form" && <LibraryBooksIcon htmlColor="#008500" className="tile-icon" />}
							{key === "photo" && <ImageIcon htmlColor="#008500" className="tile-icon" />}
							{/* {key === "order" && <ShoppingCartIcon htmlColor="#008500" className="tile-icon" />} */}
							{/* {key === "newClient" && <PersonIcon htmlColor="#008500" className="tile-icon" />} */}
							<p>
								{key === "meeting" && "Task"}
								{/* {key === "order" && "Orders"}  */}
								{key === "form" && "Forms"}{" "}
								{/* {key === "newClient" && "New Clients"} */}
								{key === "photo" && "Photos"}
							</p>
							<time>
								{key !== "order" && key != "newClient" && timelineVisits[key]}
								{/* {(key === "order" || key === "newClient") && (
									<Tooltip title="Work in progress.">
										<InfoIcon style={{ width: "20px", color: "gray", cursor: "pointer" }} />
									</Tooltip>
								)} */}
							</time>
						</div>
					))}
				</div>
			)}

			{setupRating === "bad" && timeLineData.hideBadDistance === 1 && (
				<div className="timeline_sidebar__badsettings">
					<div className={setupRating}>
						<p style={{ color: "#f54747" }}>
							Due to bad App Setup, GPS distance was not calculated.
							{
								<Button sx={{ padding: "0px", minWidth: "0px" }} onClick={() => setBadAppSetting(!badAppSetting)}>
									{" "}
									<InfoOutlinedIcon sx={{ fill: "gray", width: "18px" }} />{" "}
								</Button>
							}
						</p>
					</div>
				</div>
			)}
			<div className="timeline_sidebar__settings">
				<div className={complianceRating}>
					<PlaceOutlinedIcon />
					<span>Location Setting :</span>
					<h6>{complianceRating ? complianceRating : "-"}</h6>
				</div>
				<div className={selectedDate === moment(new Date()).format("YYYY-MM-DD") ? "" : setupRating}>
					<StayCurrentPortraitOutlinedIcon />
					<span>App Setup :</span>
					{selectedDate === moment(new Date()).format("YYYY-MM-DD") ? (
						<h6>{"-"}</h6>
					) : (
						<h6>{setupRating ? setupRating : "-"}</h6>
					)}
					{setupRating === "bad" && (
						<Button sx={{ padding: "0px", minWidth: "0px" }} onClick={() => setBadAppSetting(!badAppSetting)}>
							{" "}
							<InfoOutlinedIcon sx={{ width: "18px !important" }} />{" "}
						</Button>
					)}
					{badAppSetting && <BadAppStatusModal onClose={() => setBadAppSetting(!badAppSetting)} />}
				</div>
				<div className="timeline_sidebar_deviceDetails">
					<div>
						<h6>Manufacturer: </h6>
						<span>{timeLineData?.manufacturer?.toUpperCase() ? timeLineData?.manufacturer?.toUpperCase() : "-"}</span>
					</div>

					<div>
						<h6>Model: </h6>
						<span>{timeLineData?.model ? timeLineData?.model : "-"}</span>
					</div>
				</div>
			</div>
			<div className="timeline_sidebar__body">
				{aggregatedData.length
					? aggregatedData.map((data: any, index: number) => (
						<>
							{!data.hasOwnProperty("distance") && (
								<Visit
									visit={data}
									key={index + "-" + data.duration}
									previousTravel={previousTravelDistance.current ?? null}
									active={visitClickedState === index}
									onClickHandler={() => setVisitClickedState(findTotalTravelsTillNow(index))}
									timeLineData={timeLineData}
									selectedEmployee={selectedEmployee}
									subsMetadata={subsMetadata}
								/>
							)}

							{data.hasOwnProperty("distance") &&
								!data.hasOwnProperty("missing") &&
								!aggregatedData[index - 1]?.missing && (
									<div className="route" key={index + "-" + data.id}>
										<DisplayJourneyInfo duration={data.duration} startTime={data.startTimeString} />
										<div
											className="route__info"
											style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
											<div
												style={{
													display: "flex",
													gap: "10px",
													borderBottom:
														data.events?.filter(
															(e: any) =>
																e.eventTypeID === 13 ||
																e.eventTypeID === 8 ||
																e.eventTypeID === 9 ||
																e.eventTypeID === 15 ||
																e.eventTypeID === 25 ||
																e.eventTypeID === 3 ||
																e.eventTypeID === 14 ||
																e.eventTypeID === 35
														).length > 0
															? "2px solid #cdcdcd"
															: "none",
													width: "100%",
													paddingBottom:
														data.events?.filter(
															(e: any) =>
																e.eventTypeID === 13 ||
																e.eventTypeID === 8 ||
																e.eventTypeID === 9 ||
																e.eventTypeID === 15 ||
																e.eventTypeID === 25 ||
																e.eventTypeID === 3 ||
																e.eventTypeID === 14 ||
																e.eventTypeID === 35
														).length > 0
															? "20px"
															: "0px",
												}}>
												<span>Travel: </span>
												<h6 className="distance">{data?.distance?.toFixed(2)} KM</h6>
											</div>

											{data.events
												?.filter(
													(e: any) =>
														e.eventTypeID === 13 ||
														e.eventTypeID === 8 ||
														e.eventTypeID === 9 ||
														e.eventTypeID === 15 ||
														e.eventTypeID === 25 ||
														e.eventTypeID === 3 ||
														(companyID === 14193 && (e.eventTypeID === 14 || e.eventTypeID === 35))
												)
												.map((event: any, index: number) => (
													<div className="event_container" key={index}>
														<EventInsideTask
															key={event.eventTypeID + "-" + event.timestamp}
															eventTypeID={event.eventTypeID}
															eventParam={event.eventParam}
															ts={event.timestamp}
															photoURL={event.photoURL}
														/>
													</div>
												))}
										</div>
									</div>
								)}
						</>
					))
					: null}
			</div>
		</aside>
	);
};

export default TimeLineSidebar;
