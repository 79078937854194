import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Dispatch, FC, SetStateAction } from "react";
import { SITES_DATA, SITE_FILTER_COLUMNS } from "../../../@types";
import IndividualFilter from "../../common/datagrid/IndividualFilter";
type COLUMN_TITLES = "Site" | "Contact Person" | "Contact Number" | "City" | "Pincode";
type Props = {
	numFilters: number;
	setFilterOpen: Dispatch<SetStateAction<boolean>>;
	sites: SITES_DATA[];
	filters: Record<SITE_FILTER_COLUMNS, string[]>;
	setFilters: Dispatch<SetStateAction<Record<SITE_FILTER_COLUMNS, string[]>>>;
};
const FilterSites: FC<Props> = ({ numFilters, setFilterOpen, sites, setFilters, filters }) => {
	const resetFilters = () => {
		setFilters({ clientName: [], proprietorName: [], phoneNumber: [], city: [], pincode: [] });
	};
	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
		<form
			onSubmit={(e) => e.preventDefault()}
			onClick={(e) => e.stopPropagation()}
			onKeyPress={(e) => e.stopPropagation()}>
			<div className="filter__header">
				<h5>
					<FilterAltOutlinedIcon />
					Filters
					<span className="right-notification center" style={{ display: "flex", padding: 0 }}>
						{numFilters}
					</span>
				</h5>
				<button onClick={() => setFilterOpen(false)}>
					<CloseSharpIcon htmlColor="#42444A" />
				</button>
			</div>
			<div className="accordion__group">
				<IndividualFilter<COLUMN_TITLES, SITE_FILTER_COLUMNS>
					filters={filters}
					name="clientName"
					title="Site"
					data={sites.map((site) => site.clientName)}
					ids={sites.map((site) => site.clientName)}
					setFilters={setFilters}
				/>
				<IndividualFilter<COLUMN_TITLES, SITE_FILTER_COLUMNS>
					filters={filters}
					name="proprietorName"
					title="Contact Person"
					data={sites.map((site) => site.proprietorName ?? "")}
					ids={sites.map((site) => site.proprietorName ?? "")}
					setFilters={setFilters}
				/>
				<IndividualFilter<COLUMN_TITLES, SITE_FILTER_COLUMNS>
					filters={filters}
					name="phoneNumber"
					title="Contact Number"
					data={sites.map((site) => site.phoneNumber ?? "")}
					ids={sites.map((site) => site.phoneNumber ?? "")}
					setFilters={setFilters}
				/>
				<IndividualFilter<COLUMN_TITLES, SITE_FILTER_COLUMNS>
					filters={filters}
					name="city"
					title="City"
					data={sites.map((site) => site.city ?? "")}
					ids={sites.map((site) => site.city ?? "")}
					setFilters={setFilters}
				/>
				<IndividualFilter<COLUMN_TITLES, SITE_FILTER_COLUMNS>
					filters={filters}
					name="pincode"
					title="Pincode"
					data={sites.map((site) => site.pinCode ?? "")}
					ids={sites.map((site) => site.pinCode ?? "")}
					setFilters={setFilters}
				/>
			</div>
			<div className="filter__buttons">
				<button onClick={resetFilters} disabled={numFilters === 0} type="reset">
					Reset
				</button>
			</div>
		</form>
	);
};

export default FilterSites;
