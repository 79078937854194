import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { Button, TextField } from "@mui/material";
import GoogleMapReact from "google-map-react";
import __debounce from "lodash/debounce";
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { SITES_DATA } from "../../../@types";
import { mapOptions, mapStyles } from "../../../utils";

type Props = {
	site: SITES_DATA;
	setSite: Dispatch<SetStateAction<SITES_DATA>>;
	errors: string[];
	userWritePermission:any;
	handleKeyPress:any;
};

type RegionMarkers = {
	[clientID: string]: {
		polygon: any;
		infowindow: any;
		site: SITES_DATA;
	};
};

const polyMarkerColor = "#3FC097";

const polygonOptions = {
	strokeColor: polyMarkerColor,
	strokeOpacity: 0.8,
	strokeWeight: 2,
	fillColor: polyMarkerColor,
	fillOpacity: 0.35,
};

let Popup: any;
const regionMarkers: RegionMarkers = {};

let geocoder: any = null;
let drawingManager: any = null;

const SiteCreateMapModalPolygon: FC<Props> = ({ site, setSite, errors, userWritePermission, handleKeyPress }) => {
	const [map, setMap] = useState<any>(null);
	const [maps, setMaps] = useState<any>(null);
	// const [isValidPolygon, setIsValidPolygon] = useState<boolean>(true);
	const [edit, setEdit] = useState<boolean>(false);
	const [addButtonClicked, setAddButtonClicked] = useState(false);

	const createPopupClass = (maps: any) => {
		/**
		 * A customized popup on the map.
		 * @param {!google.maps.LatLng} position
		 * @param {!Element} content The bubble div.
		 * @constructor
		 * @extends {google.maps.OverlayView}
		 */
		function Popup(this: any, position: any, content: any) {
			this.position = position;

			content.classList.add("popup-bubble");

			var bubbleAnchor = document.createElement("div");
			bubbleAnchor.classList.add("popup-bubble-anchor");
			bubbleAnchor.appendChild(content);

			this.containerDiv = document.createElement("div");
			this.containerDiv.classList.add("popup-container");
			this.containerDiv.appendChild(bubbleAnchor);

			maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
		}

		Popup.prototype = Object.create(maps.OverlayView.prototype);

		Popup.prototype.onAdd = function () {
			this.getPanes().floatPane.appendChild(this.containerDiv);
		};

		Popup.prototype.setText = function (text: string) {
			this.containerDiv.childNodes[0].childNodes[0].innerHTML = text;
		};

		Popup.prototype.onRemove = function () {
			if (this.containerDiv.parentElement) {
				this.containerDiv.parentElement.removeChild(this.containerDiv);
			}
		};

		Popup.prototype.draw = function () {
			var divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

			var display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? "block" : "none";

			if (display === "block") {
				this.containerDiv.style.left = divPosition.x + "px";
				this.containerDiv.style.top = divPosition.y + "px";
			}
			if (this.containerDiv.style.display !== display) {
				this.containerDiv.style.display = display;
			}
		};

		return Popup;
	};

	const calcHighestPointOfPolygon = (path: any, maps: any) => {
		const bounds = new maps.LatLngBounds();

		for (let i = 0; i < path.length; i++) {
			bounds.extend(path[i]);
		}

		return bounds.getCenter();
	};

	const addPolygonMode = (map: any, maps: any) => {
		// cancelPolygonEdit();
		setAddButtonClicked(true);
		drawingManager.setDrawingMode(maps.drawing.OverlayType.POLYGON);
		maps.event.addListener(drawingManager, "polygoncomplete", function (polygon: any) {
			drawingManager.setDrawingMode(null);

			maps.event.clearListeners(drawingManager, "polygoncomplete");
			const path = polygon.getPath().getArray();
			const tempSite = { ...site, polyline: maps.geometry.encoding.encodePath(path) };
			setSite((prev) => ({ ...prev, polyline: maps.geometry.encoding.encodePath(path) }));
			regionMarkers[site.clientID] = addPolygon(polygon, tempSite, maps, map);

			editPolygon(site.clientID, regionMarkers);
			handlePolygonSizeChange(polygon);
			zoomToPolygon(polygon.getPath().getArray(), maps, map);
			// reverseGeocode(calcHighestPointOfPolygon(polygon.getPath().getArray()));
		});
	};

	const addPolygon = useCallback((polygon: any, site: any, maps: any, map: any) => {
		const center = calcHighestPointOfPolygon(polygon.getPath().getArray(), maps);
		const iDiv = document.createElement("div");
		const node = document.createTextNode(site?.clientName ?? "");
		iDiv.append(node);
		const popup = new Popup(center, iDiv);
		popup.setMap(map);

		return { polygon: polygon, infowindow: popup, site: site };
	}, []);

	const zoomToPolygon = useCallback((locations: any, maps: any, map: any) => {
		const bounds = new maps.LatLngBounds();
		locations.map((o: any) => bounds.extend(o));
		map.fitBounds(bounds);
	}, []);

	const zoomToClient = (map: any, maps: any, location: any) => {
		const bounds = new maps.LatLngBounds();
		bounds.extend(location);

		map.fitBounds(bounds);
		map.setZoom(17);
	};

	const handlePolygonSizeChange = (polygon: any) => {
		const array = polygon.getPath().getArray();
		if (array && array.length <= 3) {
			// setIsValidPolygon(false);
		} else {
			// setIsValidPolygon(true);
		}
	};

	const removePolygon = useCallback(
		(clientID: any) => {
			const polygon = regionMarkers[clientID].polygon;
			const infowindow = regionMarkers[clientID].infowindow;
			polygon.setMap(null);
			infowindow.setMap(null);

			delete regionMarkers[clientID];
			setSite((prev) => ({ ...prev, polyline: null }));
			maps.event.clearInstanceListeners(polygon);
			setAddButtonClicked(false);
		},
		[maps]
	);

	const stopEditPolygon = useCallback(() => {
		setAddButtonClicked(false);
		if (!regionMarkers[site.clientID]?.polygon) {
			drawingManager.setDrawingMode(null);
			setEdit(false);
			return;
		}
		const polygon = regionMarkers[site.clientID].polygon;
		const infowindow = regionMarkers[site.clientID].infowindow;
		const path = maps.geometry.encoding.decodePath(site.polyline);
		polygon.setPath(path);
		infowindow.position = calcHighestPointOfPolygon(path, maps);
		infowindow.draw();

		polygon.setEditable(false);
		polygon.setDraggable(false);
		setEdit(false);
		maps.event.clearListeners(polygon, "drag");
	}, [maps, site]);

	const editPolygon = useCallback(
		(clientID: any, regionMarkers: any) => {
			setEdit(true);
			const polygon = regionMarkers[clientID].polygon;
			const popup = regionMarkers[clientID].infowindow;
			const path = polygon.getPath().getArray();

			polygon.setEditable(true);
			polygon.setDraggable(true);

			maps.event.addListener(polygon, "drag", function () {
				const center = calcHighestPointOfPolygon(polygon.getPath().getArray(), maps);
				popup.position = center;
				popup.draw();
				setSite((prev) => ({ ...prev, polyline: maps.geometry.encoding.encodePath(path) }));
			});

			maps.event.addListener(polygon.getPath(), "set_at", function () {
				handlePolygonSizeChange(polygon);
				setSite((prev) => ({ ...prev, polyline: maps.geometry.encoding.encodePath(path) }));
			});

			maps.event.addListener(polygon.getPath(), "insert_at", function () {
				handlePolygonSizeChange(polygon);
				setSite((prev) => ({ ...prev, polyline: maps.geometry.encoding.encodePath(path) }));
			});
		},
		[maps]
	);

	const drawPolygon = useCallback(
		(map: any, maps: any) => {
			const decodedPath = maps.geometry.encoding.decodePath(site.polyline);
			const pOpts = Object.assign(polygonOptions, { paths: decodedPath, id: site.clientID });
			const polygon = new maps.Polygon(pOpts);
			regionMarkers[site.clientID] = addPolygon(polygon, site, maps, map);
			const popup = regionMarkers[site.clientID].infowindow;
			popup.setText(site.clientName);
			zoomToPolygon(polygon.getPath().getArray(), maps, map);
			maps.event.addListener(polygon, "click", function () {
				zoomToPolygon(polygon.getPath().getArray(), maps, map);
			});

			polygon.setMap(map);
		},
		[addPolygon, site, zoomToPolygon]
	);

	const handleApiLoaded = useCallback(
		(map: any, maps: any) => {
			setMap(map);
			setMaps(maps);
			geocoder = new google.maps.Geocoder();
			map.setOptions({ styles: mapStyles });

			const drawingManagerInit = new maps.drawing.DrawingManager({
				drawingMode: null,
				polygonOptions: polygonOptions,
				map: map,
				drawingControl: false,
			});

			drawingManager = drawingManagerInit;
			Popup = createPopupClass(maps);

			const input = document.getElementById("search_google");
			const searchBox = new maps.places.SearchBox(input);
			map.addListener("bounds_changed", function () {
				searchBox.setBounds(map.getBounds());
			});

			searchBox.addListener("places_changed", function () {
				var places = searchBox.getPlaces();
				if (places.length == 0) {
					return;
				}
				var place = places[0];
				if (!place.geometry) {
					return;
				}
				zoomToClient(map, maps, place.geometry.location);
				setSite((prev) => ({ ...prev,  address: place.formatted_address }));

			});

			if (site && site.polyline) {
				drawPolygon(map, maps);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const changeAddressAndZoom = (address: string, map: any, maps: any) => {
		geocoder.geocode({ address: address }, function (results: any, status: any) {
			if (status == google.maps.GeocoderStatus.OK) {
				const latitude = results[0].geometry.location.lat();
				const longitude = results[0].geometry.location.lng();
				zoomToClient(map, maps, new google.maps.LatLng(latitude, longitude));
			}
		});
		setSite((prev) => ({ ...prev, address }));
	};

	const debouncedResults = useMemo(
		() =>
			__debounce(function (address, map, maps) {
				changeAddressAndZoom(address, map, maps);
			}, 500),
		[]
	);

	useEffect(() => () => {
		debouncedResults.cancel();
	});

	useEffect(() => {
		const inputElementAddress = document.querySelector(".address_site input") as HTMLInputElement;

		if (inputElementAddress) inputElementAddress.value = `${site?.address ?? ""}`;
	}, [site]);

	return (
		<div className="add_edit_map_dialog grouped-form">
			<div className="group">
				<div className="details">
					<h4>Address</h4>
				</div>
				<TextField
					placeholder="Enter Address"
					fullWidth
					required
					name="address"
					className="address_site"
					onChange={(e) => debouncedResults(e.target.value, map, maps)}
					disabled={!userWritePermission}
				/>
			</div>
			<div className="row" style={{ gridTemplateColumns: "1fr", padding: "10px" }}>
				<TextField
					label="Search Google"
					sx={{ width: "100%", minWidth: "200px" }}
					name="Search Google"
					className="search_google"
					placeholder="Search Google"
					id="search_google"
					type="text"
					disabled={!userWritePermission}
					onKeyDownCapture={handleKeyPress}
				/>
				<div className="action_button">
					<Button
						variant="outlined"
						color={addButtonClicked ? "error" : site && site.polyline ? (edit ? "error" : "primary") : "primary"}
						disabled={!userWritePermission}
						onClick={() =>
							addButtonClicked
								? stopEditPolygon()
								: site && site.polyline
								? edit
									? stopEditPolygon()
									: editPolygon(site.clientID, regionMarkers)
								: addPolygonMode(map, maps)
						}
						startIcon={
							addButtonClicked ? (
								<CloseOutlinedIcon />
							) : site && site.polyline ? (
								edit ? (
									<CloseOutlinedIcon />
								) : (
									<EditIcon />
								)
							) : (
								<AddRoundedIcon />
							)
						}>
						{addButtonClicked
							? "Stop Editing"
							: site && site.polyline
							? edit
								? "Stop Editing"
								: "Start Editing"
							: "Start Editing"}
					</Button>
					{site && site.polyline ? (
						<Button
							variant="outlined"
							color="error"
							disabled={!userWritePermission}
							onClick={() => removePolygon(site.clientID)}
							startIcon={<CloseOutlinedIcon />}>
							Delete Region
						</Button>
					) : null}
				</div>
			</div>
			{site && site.polyline
				? null
				: errors.map((e) => (
						<p key={e} className="error_text">
							*No polygon created
						</p>
				  ))}
			<div className="google_map">
				<GoogleMapReact
					bootstrapURLKeys={{
						key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY!,
						language: "en",
						region: "IN",
						libraries: ["places", "geometry", "drawing", "visualization"],
					}}
					options={mapOptions}
					yesIWantToUseGoogleMapApiInternals={true}
					defaultZoom={5}
					defaultCenter={{ lat: 21.1458, lng: 79.0882 }}
					style={{ position: "relative", width: "100%", height: "100%" }}
					onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}></GoogleMapReact>
			</div>
		</div>
	);
};

export default SiteCreateMapModalPolygon;
