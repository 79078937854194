/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Button, TextField } from "@mui/material";
import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from "react";
import { CLIENTS_BY_COMPANY, ROUTE_DATA } from "../../../@types";
import { DataGridPro, GridColDef, GridValueGetterParams } from "@mui/x-data-grid-pro";
type Props = {
	clientsByCompany: CLIENTS_BY_COMPANY[];
	// eslint-disable-next-line no-unused-vars
	handleAddRoute: (id: string) => void;
	currentRoute: ROUTE_DATA;
	setCurrentRoute: Dispatch<SetStateAction<ROUTE_DATA>>;
};
const CreateRoutesList: FC<Props> = ({ clientsByCompany, handleAddRoute, currentRoute, setCurrentRoute }) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [selected, setSelected] = useState<0 | 1>(0);

	const debouncedChanges = (e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value);
	const client = clientsByCompany.filter(
		(item) =>
			item.employeeID !== -2 &&
			(item.address
				.replace(/ /g, "")
				?.toLocaleLowerCase()
				?.includes(searchTerm.replace(/ /g, "").toLocaleLowerCase()) ||
				item.clientName
					.replace(/ /g, "")
					?.toLocaleLowerCase()
					?.includes(searchTerm.replace(/ /g, "").toLocaleLowerCase()))
	);
	const site = clientsByCompany.filter(
		(item) =>
			item.employeeID === -2 &&
			(item.address
				?.replace(/ /g, "")
				.toLocaleLowerCase()
				?.includes(searchTerm.replace(/ /g, "").toLocaleLowerCase()) ||
				item.clientName
					?.replace(/ /g, "")
					.toLocaleLowerCase()
					?.includes(searchTerm.replace(/ /g, "").toLocaleLowerCase()))
	);
	const list = selected === 1 ? [...client] : [...site];

	const columns: GridColDef[] = [
		{
			field: "clientName",
			headerName: "Client Name",
			description: "This column has a value getter and is not sortable.",
			sortable: false,
			flex: 1,
			renderCell: (params: GridValueGetterParams) => (
				// eslint-disable-next-line jsx-a11y/no-static-element-interactions
				<div
					onClick={() => handleAddRoute(params.row?.clientID)}
					style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flex: "1" }}>
					<div style={{ display: "flex", flexDirection: "column" }}>
						<h6 style={{ fontSize: "14px" }}>{params.row?.clientName}</h6>
						<p style={{ fontSize: "12px", color: "gray", whiteSpace: "break-spaces" }}>{params.row?.address}</p>
					</div>
					<Button
						variant="text"
						onClick={() => handleAddRoute(params.row?.clientID)}
						sx={{ margin: 0, padding: 0, minWidth: "40px" }}>
						<AddOutlinedIcon />
					</Button>
				</div>
			),
			headerClassName: "remove-border",
		},
	];

	return (
		<div className="routes_list-container">
			<TextField
				fullWidth
				required
				label="Enter Route Name"
				name="routeName"
				value={currentRoute.name}
				onChange={(e) => setCurrentRoute({ ...currentRoute, name: e.target.value })}
			/>
			<div className="routes_list-box">
				<div className="button-group">
					<button
						onClick={() => setSelected(0)}
						type="button"
						className={`group ${selected === 0 ? "selected" : ""}`}
						style={{ flex: 1 }}>
						Sites ({site.length})
					</button>
					<button
						type="button"
						onClick={() => setSelected(1)}
						className={`group ${selected === 1 ? "selected" : ""}`}
						style={{ flex: 1 }}>
						Clients ({client.length})
					</button>
				</div>
				<input className="search" type="search" placeholder="Search Here.." onChange={debouncedChanges} />
				<div style={{ height: "calc(100vh - 385px)" }}>
					<DataGridPro
						columns={columns}
						rows={list}
						getRowId={(props) => props.clientID}
						getRowHeight={(params) => (params?.model?.address?.length / 50) * 17 + 50}
						hideFooterSelectedRowCount
						hideFooter
						headerHeight={0}
					/>
				</div>
			</div>
		</div>
	);
};

export default CreateRoutesList;
