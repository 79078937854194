/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { EVIDENCE_TYPE, EXPENSE_REQUEST_TYPE } from "../../../@types";
import { useSelector } from "react-redux";
import useFetch from "../../useFetch";
import ImageCarousel from "./ImageCarousel";
import { ROOT_STATE } from "../../../redux";
import { expenseRequestStatus } from "../../../constants/expenseConstant";
import { currency_map } from "../../../constants/currency_map";
import { AccessTime } from "@mui/icons-material";
import { checkS3LinkValidity } from "../../../utils";
import FileCard from "../../common/FileCard";
import { formatDateTo_DDMMYYYY } from "../../../utils/validations";
import { TIME_SHOW_FORMAT } from "../../../constants";
import moment from "moment";
type Props = {
	request: EXPENSE_REQUEST_TYPE;
	expenseQuestionData: any;
};
const ExpanseDescription: FC<Props> = ({ request, expenseQuestionData }) => {
	const [open, setOpen] = useState(false);
	const user = useSelector((state: ROOT_STATE) => state.user);
	const [evidences, setEvidences] = useState<EVIDENCE_TYPE[]>([]);
	const [fileUploads, setFileUploads] = useState<any[]>([]);
	const [currentIndex, setCurrentIndex] = useState(0);
	const allExpenseAuth = request.expenseAuth;
	const { data: currencyData, loading: isCurrencyLoading } = useFetch<any>("/preferences/account");
	const currencySymbol = currency_map[currencyData.data?.currencyCode]?.symbol || "";
	const totalUniqueExpenseRequests = allExpenseAuth.reduce((accum, curr) => {
		if (curr.adminID === user.adminDetails.adminID && curr.actionPerformed === expenseRequestStatus.PENDING) {
			return accum;
		} else return accum + 1;
	}, 0);

	const getStatus = (actionPerformed: number) => {
		switch (actionPerformed) {
			case expenseRequestStatus.REJECTED:
				return "Rejected";
			case expenseRequestStatus.APPROVED:
				return "Approved";
			case expenseRequestStatus.PAIDOUT:
				return "Paid Out";
			case expenseRequestStatus.PENDING:
				return "Pending";
			default:
				return "Pending";
		}
	};
	useEffect(() => {
		const getEvidences = async () => {
			const evidences: EVIDENCE_TYPE[] =
				request.evidences && typeof request.evidences === "string"
					? request.evidences.length > 0
						? JSON.parse(request.evidences)
						: []
					: [];
			const tranformedEvidences = await Promise.all(
				evidences.map(async (evidence) => {
					const isValidS3Link = await checkS3LinkValidity(evidence.photoURL);
					return {
						...evidence,
						isValidS3Link,
					};
				})
			);

			setEvidences(tranformedEvidences);
		};
		getEvidences();
	}, [request.evidences]);

	useEffect(() => {
		const getFileUploads = async () => {
			const fileUploads: any[] =
				request.fileUploads && typeof request.fileUploads === "string"
					? request.fileUploads.length > 0
						? JSON.parse(request.fileUploads)
						: []
					: [];
			const tranformedFileUploads = await Promise.all(
				fileUploads.map(async (fileUpload) => {
					const isValidS3Link = await checkS3LinkValidity(user?.adminDetails?.companyID + "/" + fileUpload.fileURL);
					return {
						...fileUpload,
						isValidS3Link,
					};
				})
			);
			setFileUploads(tranformedFileUploads);
		};
		getFileUploads();
	}, [request.fileUploads]);

	return (
		<div className="expanse_description">
			<div className="description">
				<h4 style={{ marginRight: "10px",marginBottom:"10px" }}>Description</h4>
				<p>{request.description}</p>
				{expenseQuestionData.expenseQuestionOne ? (
					<>
						<h4 style={{ marginTop: "20px" }}>{expenseQuestionData.expenseQuestionOne}</h4>
						{request.q1 ? <p>{request.q1}</p> : <p>Not Answered</p>}
					</>
				) : null}
				{expenseQuestionData.expenseQuestionTwo ? (
					<>
						<h4 style={{ marginTop: "20px" }}>{expenseQuestionData.expenseQuestionTwo}</h4>
						{request.q2 ? <p>{request.q2}</p> : <p>Not Answered</p>}
					</>
				) : null}
			</div>
			{((Array.isArray(request.expenseDetails) && request.expenseDetails.length > 0) ||
				(Array.isArray(evidences) && evidences.length > 0)) && (
				<TableContainer component={Paper} className="expense_tables" elevation={0} sx={{ marginTop: "20px" }}>
					<Table sx={{ minWidth: 550 }} aria-label="Expense Details">
						<TableHead>
							<TableRow>
								<TableCell>Expense Category</TableCell>
								<TableCell>Amount Claimed</TableCell>
								<TableCell>Expense Description</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Array.isArray(request.expenseDetails) &&
								!isCurrencyLoading &&
								request.expenseDetails.map((expenseDetail) => (
									<TableRow key={expenseDetail.expenseID} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
										<TableCell>{expenseDetail.category}</TableCell>
										<TableCell>
											{currencySymbol} {expenseDetail.amountClaimed}
										</TableCell>

										<TableCell component="th" scope="row">
											{expenseDetail.desc}
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
					<div className="p-3">
						{evidences.length > 0 && (
							<div>
								<p style={{ color: "#6c757d", paddingBottom: "10px" }}>Evidences (click below to view):</p>
								<div className="image_gallery">
									{evidences.map((evidence, i) => (
										<>
											<div key={evidence.photoID} style={{ display: "flex", flexDirection: "column" }}>
												<img
													key={evidence.photoID}
													onClick={() => {
														setCurrentIndex(i);
														setOpen(true);
													}}
													src={process.env.NEXT_PUBLIC_S3_URL + evidence.photoURL}
													alt="evidence"
													onError={(e: any) => {
														e.currentTarget.src = "/images/brokenImage.png";
													}}
												/>

												<Tooltip
													title="Image has not been uploaded from phone, please ask the user to Sync from the app."
													placement="top"
													hidden={evidence.isValidS3Link}
													sx={{ marginTop: "5px" }}>
													<AccessTime fontSize="small" color="primary" />
												</Tooltip>
											</div>
										</>
									))}
								</div>
							</div>
						)}
						{request?.fileUploads !== null && request?.fileUploads !== "" && (
							<div>
								<p style={{ color: "#6c757d"}}>Files (click below to view or download):</p>
								<div
									style={{
										display: "flex",
										flexWrap: "wrap",
										gap: "20px",
										marginTop: "10px",
									}}>
									{fileUploads.map((file: any, i: any) => (
										<>
											<FileCard file={file} user={user} i={i} isValidLink={file.isValidS3Link} />
										</>
									))}
								</div>
							</div>
						)}
					</div>
				</TableContainer>
			)}
			{evidences.length > 0 && (
				<ImageCarousel
					open={open}
					handleClose={() => setOpen(false)}
					evidences={evidences}
					currentIndex={currentIndex}
					setCurrentIndex={setCurrentIndex}
				/>
			)}
			<h4 style={{ marginTop: "30px", marginBottom:"10px" }}>History</h4>
			{Array.isArray(allExpenseAuth) && allExpenseAuth.length > 0 && (
				<TableContainer component={Paper} className="expense_tables" elevation={0}>
					<Table sx={{ minWidth: 550 }} aria-label="Audit Trail">
						<TableHead>
							<TableRow>
								<TableCell>Date</TableCell>
								<TableCell>Time</TableCell>
								<TableCell>Name of Approver</TableCell>
								<TableCell>Comment</TableCell>
								<TableCell>Amount</TableCell>
								<TableCell>Status</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Array.isArray(allExpenseAuth) &&
								allExpenseAuth.map((auth, i: number) => {
									const status = getStatus(auth.actionPerformed);

									if (
										auth.actionPerformed === expenseRequestStatus.PENDING &&
										auth.adminID === user?.adminDetails?.adminID
									)
										return;
									if (
										auth.actionPerformed === expenseRequestStatus.PENDING &&
										auth.adminID !== user?.adminDetails?.adminID
									)
										return (
											<TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
												<TableCell colSpan={6} align={"center"} className="pending_with_others">
													<span className={`role_badge ${status}`}>
														{status} with <b>{auth.adminName}</b>
													</span>
												</TableCell>
											</TableRow>
										);
									return (
										<TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
											<TableCell>{formatDateTo_DDMMYYYY(auth.timestamp)}</TableCell>
											<TableCell>{moment(auth.timestamp).format(TIME_SHOW_FORMAT)}</TableCell>
											<TableCell>{auth.adminName}</TableCell>
											<TableCell>{auth.comment || ""}</TableCell>
											<TableCell>{auth.reimbursementAmount ?? "-"}</TableCell>
											<TableCell>
												<span className={`role_badge ${status}`}>{status}</span>
											</TableCell>
										</TableRow>
									);
								})}
							{totalUniqueExpenseRequests === 0 && (
								<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell colSpan={6} align={"center"} className="pending_with_others">
										<span>No Data</span>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</div>
	);
};

export default ExpanseDescription;
