import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useRouter } from "next/router";
import { FC, useState } from "react";
import { SUBMODULE_DATA } from "../../@types";

const CustomTabs = styled(Tabs)({
	borderBottom: "1px solid #DFDFDF",
	minHeight: "unset",
	button: {
		minHeight: "unset",
		color: "#fff",
		textTransform: "none",
	},
	"& .MuiTab-textColorPrimary": {
		color: "#000",
		fontWeight: "600",
		fontSize: "14px",
		fontFamily: "SFUIText-Semibold, Helvetica, Arial, sans-serif",
	},
	"& .Mui-selected": {
		backgroundColor: "#0F45FF",
		color: "#fff !important",
		borderRadius: "8px 8px 0px 0px",
		borderBottom: "none",
	},
	"& .MuiTabs-flexContainer": {
		gap: "1rem",
		flexWrap: "wrap",
	},
	"& .MuiTabs-indicator": {
		display: "none",
	},
});

type Props = {
	submoduleData: SUBMODULE_DATA[];
	tab: number;
};

const SubmoduleTabs: FC<Props> = ({ submoduleData, tab }) => {
	const [value, setValue] = useState<number>(tab);
	const router = useRouter();

	const handleChange = (_event: any, newValue: any) => {
		setValue(newValue);
	};

	return (
		<header className="submodule-tab">
			<CustomTabs value={value} onChange={handleChange}>
				{submoduleData.map((data: any) => (
					<Tab
						sx={{ display: data.hide ? "none" : "flex" }}
						icon={data.icon}
						key={data.path}
						iconPosition="start"
						label={data.label}
						onClick={() => router.push(data.path)}
					/>
				))}
			</CustomTabs>
		</header>
	);
};

export default SubmoduleTabs;
