/* eslint-disable react-hooks/exhaustive-deps */
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import TrackChangesOutlinedIcon from "@mui/icons-material/TrackChangesOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SummarizeIcon from "@mui/icons-material/Summarize";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Menu, MenuItem } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, MouseEvent, useState } from "react";
import { PRODUCT_ID, SIDEBAR_OPEN } from "../../constants";
import { subscriptionMapping } from "../../constants/subscriptionMapping";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

// import { useAdminCompanySettings } from "../useAdminEntitlements";
import { useSelector } from "react-redux";
type Props = {
	showMenu: any | undefined;
	isOpen: boolean;
	setIsOpen: any;
};
import { useAdminCompanySettings } from "../useAdminEntitlements";

const Sidebar: FC<Props> = ({ showMenu, isOpen, setIsOpen }) => {
	// const leavesV2Active = useAdminCompanySettings("isLeavesV2Enabled");
	//commented for now @Aditya need to check
	const leavesV2Active = true;
	const router = useRouter();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClose = () => setAnchorEl(null);
	const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
	const subsType: "2" | "1" | "5" | "6" | "7" | "8" | "9" = window
		? (localStorage.getItem(PRODUCT_ID) as "2" | "1" | "5" | "6" | "7" | "8" | "9")
		: "2";
	const user = useSelector((state: any) => state.user);
	const cacheBuster = Date.now();
	const productIDinLocal = localStorage.getItem(PRODUCT_ID);
	const s3ImageUrl = `https://s3.ap-south-1.amazonaws.com/upload.permanent.sensestaff.com/companylogo/${user.adminDetails.companyID}.jpeg?cache=${cacheBuster}`;
	const changeSubsType = (value: string) => {
		localStorage.setItem(PRODUCT_ID, value);
		router.push("/").then(() => {
			router.reload();
		});
	};
	const customEntityMigrationPhase = useAdminCompanySettings("customEntityMigrationPhase");

	return (
		<div
			className={`absolute md:relative z-[1000] md:w-auto w-full bg-[#00000070] h-full ${
				isOpen ? "" : "hidden md:flex"
			}`}>
			<aside
				className={`bg-white w-[250px] border-r md:border-none ${
					isOpen ? "md:w-[250px] px-3" : "hidden md:flex md:w-[50px] md:px-1"
				} flex flex-col gap-4 justify-between py-4 h-full ${
					productIDinLocal !== "1" && productIDinLocal !== "6" && !(customEntityMigrationPhase !== 1)
						? "sidebarH2"
						: "sidebarH1"
				}`}>
				<div
					className={`flex items-center justify-between gap-3 ${isOpen ? "" : "flex-col"}`}
					style={{ position: "relative" }}>
					<Link href="/">
						<img
							src={
								user?.adminDetails?.logoUploaded === 1
									? s3ImageUrl
									: isOpen
									? "/images/logo_lg.png"
									: "/images/logo_sm.png"
							}
							width={isOpen ? 112 : 32}
							height={isOpen ? 35 : 32}
							alt={"Unolo Logo"}
							style={{ objectFit: "contain", borderRadius: "4px" }}
						/>
					</Link>
					<button
						id="change_plan"
						onClick={handleClick}
						className={`bg-gray-100 rounded-md border hover:bg-gray-200 justify-center font-medium text-sm ${
							isOpen ? "flex items-center px-2" : "text-xs w-[50px] h-[20px] truncate px-1"
						}`}>
						{subscriptionMapping[subsType]} {isOpen ? <UnfoldMoreIcon style={{ width: "15px" }} /> : null}
					</button>
					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						sx={{ marginTop: "1px", marginLeft: -3 }}>
						{user?.productIDs
							?.filter((x: string) => x !== "3")
							.map((productID: string, index: number) => (
								<MenuItem key={index} onClick={() => changeSubsType(productID)}>
									{" "}
									{subscriptionMapping[productID]} {subsType === productID && <DoneOutlinedIcon />}
								</MenuItem>
							))}
					</Menu>
					{user?.adminDetails?.logoUploaded === 1 && isOpen && (
						<p
							style={{
								color: "#1976d2",
								fontSize: "10px",
								padding: "5px",
								position: "absolute",
								right: "0px",
								bottom: "-8px",
								borderRadius: "4px",
							}}
							className="font-medium">
							powered by Unolo
						</p>
					)}
				</div>

				<nav className="flex-auto flex flex-col gap-2 overflow-auto">
					<Link
						href="/"
						className={`${
							router.pathname === "/" || router.pathname?.includes("/dashboard")
								? "text-primary-600 bg-primary-100"
								: "hover:bg-gray-100 text-gray-500"
						}  p-2 rounded-md flex gap-3 items-center transition-all duration-300 ${
							isOpen ? "" : "items-center justify-center"
						}`}>
						<GridViewOutlinedIcon />
						<p className={`${isOpen ? "" : "md:hidden"}`}>Dashboard</p>
					</Link>
					<Link
						href="/attendance"
						className={`${
							router.pathname?.includes("/attendance") && !router.pathname?.includes("/settings")
								? "text-primary-600 bg-primary-100"
								: "hover:bg-gray-100 text-gray-500"
						} p-2 rounded-md flex gap-3 items-center transition-all duration-300 ${
							isOpen ? "" : "items-center justify-center"
						}`}>
						<CalendarMonthOutlinedIcon />
						{isOpen && <p>Attendance</p>}
					</Link>
					{leavesV2Active && showMenu?.data?.leaves !== 0 && (
						<Link
							href="/leaves/overview"
							className={`${
								router.pathname?.includes("/leaves") && !router.pathname?.includes("/settings")
									? "text-primary-600 bg-primary-100"
									: "hover:bg-gray-100 text-gray-500"
							} p-2 rounded-md flex gap-3 items-center transition-all duration-300 ${
								isOpen ? "" : "items-center justify-center"
							}`}>
							<DateRangeIcon />
							{isOpen && <p>Leaves</p>}
						</Link>
					)}
					<Link
						href="/organization/field_executive"
						className={`${
							router.pathname?.includes("/organization")
								? "text-primary-600 bg-primary-100"
								: "hover:bg-gray-100 text-gray-500"
						} p-2 rounded-md flex gap-3 items-center transition-all duration-300 ${
							isOpen ? "" : "items-center justify-center"
						}`}>
						<PersonOutlineOutlinedIcon />
						{isOpen && <p>Organization</p>}
					</Link>
					{showMenu?.data?.tasks !== 0 && (
						<Link
							href="/tasks/overview"
							className={`${
								router.pathname?.includes("/tasks") && !router.pathname?.includes("/settings")
									? "text-primary-600 bg-primary-100"
									: "hover:bg-gray-100 text-gray-500"
							} p-2 rounded-md flex gap-3 items-center transition-all duration-300 ${
								isOpen ? "" : "items-center justify-center"
							}`}>
							<TaskOutlinedIcon />
							{isOpen && <p>Tasks</p>}
						</Link>
					)}
					{showMenu?.data?.targets !== 0 && (
						<Link
							href="/targets"
							className={`${
								router.pathname?.includes("/targets")
									? "text-primary-600 bg-primary-100"
									: "hover:bg-gray-100 text-gray-500"
							} p-2 rounded-md flex gap-3 items-center transition-all duration-300 ${
								isOpen ? "" : "items-center justify-center"
							}`}>
							<TrackChangesOutlinedIcon />
							{isOpen && <p>Targets</p>}
						</Link>
					)}
					{!isNaN(showMenu?.data?.forms) && showMenu?.data?.forms !== 0 && (
						<Link
							href="/form"
							className={`${
								router.pathname?.includes("/form")
									? "text-primary-600 bg-primary-100"
									: "hover:bg-gray-100 text-gray-500"
							} p-2 rounded-md flex gap-3 items-center transition-all duration-300 ${
								isOpen ? "" : "items-center justify-center"
							}`}>
							<PostAddOutlinedIcon />
							{isOpen && <p>Form</p>}
						</Link>
					)}
					{!isNaN(showMenu?.data?.orders) && showMenu?.data?.orders !== 0 && (
						<Link
							href="/order"
							className={`${
								router.pathname?.includes("/order") && !router?.pathname?.includes("/settings")
									? "text-primary-600 bg-primary-100"
									: "hover:bg-gray-100 text-gray-500"
							} p-2 rounded-md flex gap-3 items-center transition-all duration-300 ${
								isOpen ? "" : "items-center justify-center"
							}`}>
							<LocalShippingOutlinedIcon />
							{isOpen && <p>Order</p>}
						</Link>
					)}
					{showMenu?.data?.expenses !== 0 && (
						<Link
							href="/expense"
							className={`${
								router.pathname?.includes("/expense") && !router.pathname?.includes("/settings")
									? "text-primary-600 bg-primary-100"
									: "hover:bg-gray-100 text-gray-500"
							} p-2 rounded-md flex gap-3 items-center transition-all duration-300 ${
								isOpen ? "" : "items-center justify-center"
							}`}>
							<PaymentsOutlinedIcon />
							{isOpen && <p>Expenses</p>}
						</Link>
					)}
					{(showMenu?.data?.sites !== 0 || showMenu?.data?.clients !== 0) && (
						<Link
							href={subsType === "5" ? "/clients_sites/sites" : "/clients_sites/clients"}
							className={`${
								router.pathname?.includes("/clients_sites")
									? "text-primary-600 bg-primary-100"
									: "hover:bg-gray-100 text-gray-500"
							} p-2 rounded-md flex gap-3 items-center transition-all duration-300 ${
								isOpen ? "" : "items-center justify-center"
							}`}>
							<PeopleAltOutlinedIcon />
							{isOpen && <p>{subsType === "5" ? "Sites" : "Clients & Sites"}</p>}
						</Link>
					)}

					<Link
						href="/reports"
						className={`${
							router.pathname?.includes("/reports")
								? "text-primary-600 bg-primary-100"
								: "hover:bg-gray-100 text-gray-500"
						} p-2 rounded-md flex gap-3 items-center transition-all duration-300 ${
							isOpen ? "" : "items-center justify-center"
						}`}>
						<SummarizeIcon />
						{isOpen && <p>Reports</p>}
					</Link>

					{/* This is section where you put your notification and messagse leave it commented. */}
					{/* <img
					src="/images/diwali.gif"
					alt="Happy Diwali"
					style={{ width: "140px" }}
					className={`${openMobileSidebar ? "d-none" : ""}`}
				/> */}
				</nav>
				<div className={`flex items-center justify-around gap-2 ${isOpen ? "flex-row" : "flex-col"}`}>
					<button
						onClick={() => window.FreshworksWidget("open")}
						className={"cursor-pointer bg-gray-100 p-2 rounded-md transition-all duration-300 hover:bg-gray-200"}>
						<a>
							<HelpOutlineOutlinedIcon />
						</a>
					</button>

					<Link
						href="/settings"
						className={`${
							router.pathname?.includes("/settings") ? "text-primary-600 bg-primary-100" : ""
						} p-2 rounded-md bg-gray-100 cursor-pointer`}>
						<SettingsOutlinedIcon
							className={`${router.pathname?.includes("/settings") ? "text-primary-600 bg-primary-100" : ""}`}
						/>
					</Link>

					<button
						className="bg-gray-100 p-2 rounded-md transition-all duration-300 hover:bg-gray-200 cursor-pointer"
						onClick={() =>
							setIsOpen((prev: boolean) => {
								localStorage.setItem(SIDEBAR_OPEN, JSON.stringify(!prev));
								return !prev;
							})
						}>
						{isOpen ? <ChevronLeftOutlinedIcon /> : <ChevronRightOutlinedIcon />}
					</button>
				</div>
			</aside>
		</div>
	);
};

export default Sidebar;
