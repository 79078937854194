// react
import React, { FC, useState } from "react";
// mui
import { Avatar } from "@mui/material";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { findAvatarInitials } from "../../../utils";
// redux
import { useSelector } from "react-redux";
type Props = {
	avatar: string;
	name: string;
	emailID?: string;
	photoPath?: string;
	clientID?: any;
	isDuplicate?: boolean;
	internalEmpID?: string;
};

const cacheBuster = Date.now();
const ColumnEmployee: FC<Props> = ({ avatar, name, emailID, clientID, isDuplicate, internalEmpID }) => {
	const user = useSelector((state: any) => state.user);
	const [imageError, setImageError] = useState(false);
	return (
		<div className="column-employee">
			<aside>
				{avatar && avatar.length > 0 && !imageError ? (
					clientID ? (
						<img
							src={
								process.env.NEXT_PUBLIC_S3_URL +
								"clientPhotos/" +
								user?.adminDetails?.companyID +
								"/" +
								clientID +
								".jpg" +
								`?cache=${cacheBuster}`
							}
							height={40}
							width={40}
							alt=""
							onError={() => setImageError(true)}
						/>
					) : (
						<img src={avatar} height={40} width={40} alt="" />
					)
				) : (
					<Avatar sx={{ backgroundColor: "#D9F0FD", color: "#25A8F4" }}>{findAvatarInitials(name)}</Avatar>
				)}
			</aside>
			<div className="info">
				<h5>{name}</h5>
				{internalEmpID && <small>{internalEmpID}</small>}
				{emailID && <small>{emailID}</small>}
				{isDuplicate && (
					<span className="role_badge Rejected duplicate_badge">
						<ReportProblemOutlinedIcon sx={{ fontSize: ".5rem" }} />
						Duplicate
					</span>
				)}
			</div>
		</div>
	);
};

export default ColumnEmployee;
