import { TextField } from "@mui/material";
import GoogleMapReact from "google-map-react";
import __debounce from "lodash/debounce";
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { SITES_DATA } from "../../../@types";
import { showNotification } from "../../../redux";
import { mapOptions, mapStyles } from "../../../utils";

type Location = {
	lat: number | string;
	lng: number | string;
	clientID: string;
	clientName: string;
	radius: number | "";
};

type Props = {
	site: SITES_DATA;
	setSite: Dispatch<SetStateAction<SITES_DATA>>;
	newError: string;
	userWritePermission: any;
	handleKeyPress: any;
};

type PointMarkers = {
	[clientID: string]: {
		marker: any;
		circle: any;
		client: Location;
	};
};

type LOCATION_OBJ = {
	type: "latLng" | "address" | "radius";
	latitude?: string | undefined;
	longitude?: string | undefined;
	address?: string;
	radius?: string;
};

const SITE_MARKER_COLOR = "#243140";
let geocoder: any = null;

const SiteCreateMapModal: FC<Props> = ({ site, setSite, newError, userWritePermission, handleKeyPress }) => {
	const [map, setMap] = useState<any>(null);
	const [maps, setMaps] = useState<any>(null);
	const [pointMarkers, setPointMarkers] = useState<PointMarkers>({});
	const currentMarker = useRef<any>(null);
	const currentCircle = useRef<any>(null);
	const dispatch = useDispatch();

	const genMarker = (maps: any, center: any, id: string) => {
		var markerIcon2 = {
			url: "/images/blueMarker.png",
			scaledSize: new maps.Size(32, 32),
			labelOrigin: new maps.Point(20, -10),
		};

		return new maps.Marker({
			position: center,
			id: id,
			draggable: false,
			icon: markerIcon2,
		});
	};

	const genCircle = (maps: any, center: any, radius: number | "") =>
		new maps.Circle({
			strokeColor: SITE_MARKER_COLOR,
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor: SITE_MARKER_COLOR,
			fillOpacity: 0.35,
			center: center,
			radius: radius || 200,
			editable: false,
			draggable: false,
		});

	const zoomToClient = (map: any, maps: any, location: any) => {
		var bounds = new maps.LatLngBounds();
		bounds.extend(location);

		map.fitBounds(bounds);
		map.setZoom(17);
	};

	const extractAddressDetail = (results: any) => {
		const detail = {
			address: undefined,
			city: undefined,
			pincode: undefined,
		};

		detail.address = results[0].formatted_address;

		for (let i = 0; i < results.length; ++i) {
			for (let j = 0; j < results[i].address_components.length; ++j) {
				//check first....
				if (!results[i].address_components[j].types) {
					continue;
				}
				if (!detail.city && results[i].address_components[j].types.indexOf("locality") > -1) {
					//we found the city!
					detail.city = results[i].address_components[j].short_name;
				} else if (!detail.pincode && results[i].address_components[j].types.indexOf("postal_code") > -1) {
					//we found the pincode!
					detail.pincode = results[i].address_components[j].short_name;
				}
			}
		}
		return detail;
	};

	const drawMarkerAndCircle = (site: any, map: any, maps: any) => {
		if (currentMarker.current) {
			currentMarker.current.setMap(null);
		}
		if (currentCircle.current) {
			currentCircle.current.setMap(null);
		}
		const location: Location = {
			lat: site.latitude ?? 21.1458,
			lng: site.longitude ?? 79.0882,
			clientID: site.clientID,
			clientName: site.clientName ?? "",
			radius: site.radius,
		};

		const center = new maps.LatLng(location.lat, location.lng);

		const marker = genMarker(maps, center, location.clientID);
		const circle = genCircle(maps, { lat: location.lat, lng: location.lng }, location.radius);
		currentMarker.current = marker;
		currentCircle.current = circle;
		setPointMarkers({
			[location.clientID]: {
				marker: currentMarker,
				circle: currentCircle,
				client: location,
			},
		});

		zoomToClient(map, maps, center);
		circle.setMap(map);
		marker.setMap(map);
		circle.bindTo("center", marker, "position");

		marker.setDraggable(true);
		circle.setEditable(true);
		circle.setDraggable(true);

		google.maps.event.addListener(circle, "radius_changed", function () {
			let radius = Math.ceil(circle.getRadius());
			if (radius < 50) {
				radius = 50;
				circle.radius = 50;
				dispatch(
					showNotification({
						message: "Radius must be greater than 50",
						severity: "warning",
					})
				);
			} else if (radius > 50000) {
				radius = 50000;
				circle.radius = 50000;
				dispatch(
					showNotification({
						message: "Radius must be lesser than 50000",
						severity: "warning",
					})
				);
			}
			setSite((prev) => ({ ...prev, radius: radius }));
		});

		google.maps.event.addListener(marker, "dragend", function (e: any) {
			const lat = e.latLng.lat(),
				lng = e.latLng.lng();
			geocoder.geocode({ location: e.latLng }, function (results: any, status: any) {
				if (status === "OK" && results.length > 0) {
					const details = extractAddressDetail(results);
					const { address } = details;
					setSite((prev) => ({
						...prev,
						latitude: lat!,
						longitude: lng!,
						address: address!,
					}));
				} else {
					dispatch(
						showNotification({
							message: "No address details found",
							severity: "warning",
						})
					);
				}
			});
		});

		google.maps.event.addListener(map, "click", function (e: any) {
			const lat = e.latLng.lat(),
				lng = e.latLng.lng();
			geocoder.geocode({ location: e.latLng }, function (results: any, status: any) {
				if (status === "OK" && results.length > 0) {
					const details = extractAddressDetail(results);
					const { address } = details;
					setSite((prev) => ({ ...prev, latitude: lat!, longitude: lng!, address: address! }));
					marker.setPosition(new google.maps.LatLng(parseFloat(lat), parseFloat(lng)));
				} else {
					dispatch(
						showNotification({
							message: "No address details found",
							severity: "warning",
						})
					);
				}
			});
		});

		return {
			marker: marker,
			circle: circle,
			client: location,
		};
	};

	const changeCircleAndMarkerFromTab = (
		clientID: string,
		obj: LOCATION_OBJ,
		type: string,
		pointMarkers: PointMarkers[],
		map: any,
		maps: any
	) => {
		let pointMarker = pointMarkers[clientID];

		if (type == "latLng") {
			const { latitude, longitude } = obj;
			if (latitude && longitude) {
				if (!pointMarker?.marker) {
					pointMarker = drawMarkerAndCircle(site, map, maps);
				}
				const { marker } = pointMarker;
				if (marker.current) {
					marker.current.setPosition(new google.maps.LatLng(parseFloat(latitude), parseFloat(longitude)));
				} else {
					marker.setPosition(new google.maps.LatLng(parseFloat(latitude), parseFloat(longitude)));
				}
				zoomToClient(map, maps, marker.current ? marker.current.getPosition() : marker.getPosition());
				setSite((prev) => ({ ...prev, latitude: latitude, longitude: longitude }));
			} else if (latitude) {
				setSite((prev) => ({ ...prev, latitude: latitude, longitude: "" }));
			} else {
				setSite((prev) => ({ ...prev, longitude: longitude!, latitude: "" }));
			}
		} else if (type == "address") {
			const { address } = obj;
			geocoder.geocode({ address: address }, function (results: any, status: any) {
				if (status == google.maps.GeocoderStatus.OK) {
					var latitude = results[0].geometry.location.lat();
					var longitude = results[0].geometry.location.lng();
					if (!pointMarker?.marker) {
						pointMarker = drawMarkerAndCircle(site, map, maps);
					}
					const { marker } = pointMarker;
					if (marker.current) {
						marker.current.setPosition(new google.maps.LatLng(latitude, longitude));
					} else {
						marker.setPosition(new google.maps.LatLng(latitude, longitude));
					}
					zoomToClient(map, maps, marker.current ? marker.current.getPosition() : marker.getPosition());
					setSite((prev) => ({ ...prev, latitude: latitude, longitude: longitude }));
				}
			});
			setSite((prev) => ({ ...prev, address: address! }));
		} else {
			const { radius } = obj;
			if (pointMarker?.circle) {
				if (pointMarker.circle.current) pointMarker?.circle.current.setRadius(radius ? parseFloat(radius) : "");
				else pointMarker?.circle.setRadius(radius ? parseFloat(radius) : "");
			}
			setSite((prev) => ({ ...prev, radius: radius ? parseFloat(radius) : "" }));
		}
	};

	const placesChanged = (lat: any, lng: any, address: any, map: any, maps: any) => {
		setSite((prev) => {
			let tempSite = { ...prev };
			tempSite = { ...tempSite, latitude: lat, longitude: lng, address: address };
			drawMarkerAndCircle(tempSite, map, maps);
			return tempSite;
		});
	};

	const handleApiLoaded = useCallback((map: any, maps: any) => {
		setMap(map);
		setMaps(maps);
		geocoder = new google.maps.Geocoder();
		map.setOptions({ styles: mapStyles });

		new google.maps.drawing.DrawingManager({
			drawingMode: null,
			markerOptions: {
				label: {
					text: "New Client",
					color: "#243140",
					fontSize: "16px",
				},
				draggable: false,
				icon: {
					url: "/images/blueMarker3.png",
					scaledSize: new google.maps.Size(32, 32),
					labelOrigin: new google.maps.Point(20, -10),
				},
			},
			map: map,
			drawingControl: false,
		});

		const input = document.getElementById("search_google");
		const searchBox = new maps.places.SearchBox(input);
		map.addListener("bounds_changed", function () {
			searchBox.setBounds(map.getBounds());
		});

		searchBox.addListener("places_changed", function () {
			var places = searchBox.getPlaces();
			if (places.length == 0) {
				return;
			}
			var place = places[0];
			if (!place.geometry) {
				return;
			}
			zoomToClient(map, maps, place.geometry.location);
			placesChanged(place.geometry.location.lat(), place.geometry.location.lng(), place.formatted_address, map, maps);
		});

		if (site.latitude && site.longitude) {
			drawMarkerAndCircle(site, map, maps);
		}
		google.maps.event.addListener(map, "click", function (e: any) {
			if (currentMarker.current) {
				currentMarker.current.setMap(null);
			}
			if (currentCircle.current) {
				currentCircle.current.setMap(null);
			}
			const lat = e.latLng.lat(),
				lng = e.latLng.lng();
			const center = new maps.LatLng(lat, lng);
			const marker = genMarker(maps, center, site.clientID);
			const circle = genCircle(maps, { lat: lat, lng: lng }, site.radius);
			currentMarker.current = marker;
			currentCircle.current = circle;
			setPointMarkers({
				[site.clientID]: {
					marker: currentMarker,
					circle: currentCircle,
					client: e.latLng,
				},
			});
			circle.setMap(map);
			marker.setMap(map);
			circle.bindTo("center", marker, "position");

			marker.setDraggable(true);
			circle.setEditable(true);
			circle.setDraggable(true);
			geocoder.geocode({ location: e.latLng }, function (results: any, status: any) {
				if (status === "OK" && results.length > 0) {
					const details = extractAddressDetail(results);
					const { address } = details;
					zoomToClient(map, maps, e.latLng);
					setSite((prev) => ({ ...prev, latitude: lat!, longitude: lng!, address: address! }));
					if (marker.current) marker.current.setPosition(new google.maps.LatLng(parseFloat(lat), parseFloat(lng)));
					else marker.setPosition(new google.maps.LatLng(parseFloat(lat), parseFloat(lng)));
				} else {
					dispatch(
						showNotification({
							message: "No address details found",
							severity: "warning",
						})
					);
				}
			});

			google.maps.event.addListener(marker, "dragend", function (e: any) {
				const lat = e.latLng.lat(),
					lng = e.latLng.lng();
				geocoder.geocode({ location: e.latLng }, function (results: any, status: any) {
					if (status === "OK" && results.length > 0) {
						const details = extractAddressDetail(results);
						const { address } = details;
						setSite((prev) => ({ ...prev, latitude: lat!, longitude: lng!, address: address! }));
					} else {
						dispatch(
							showNotification({
								message: "No address details found",
								severity: "warning",
							})
						);
					}
				});
			});
			google.maps.event.addListener(circle, "radius_changed", function () {
				let radius = Math.ceil(circle.getRadius());
				if (radius < 50) {
					radius = 50;
					circle.radius = 50;
					dispatch(
						showNotification({
							message: "Radius must be greater than 50",
							severity: "warning",
						})
					);
				}
				setSite((prev) => ({ ...prev, radius: radius }));
			});
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const debouncedResults = useMemo(
		() =>
			__debounce(function (clientID, obj, type, pointMarkers, map, maps) {
				changeCircleAndMarkerFromTab(clientID, obj, type, pointMarkers, map, maps);
			}, 500),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	useEffect(() => () => {
		debouncedResults.cancel();
	});

	useEffect(() => {
		const inputElementAddress = document.querySelector(".address_site input") as HTMLInputElement;
		const inputElementRadius = document.querySelector(".radius_site input") as HTMLInputElement;
		const inputElementLat = document.querySelector(".lat_site input") as HTMLInputElement;
		const inputElementLng = document.querySelector(".lng_site input") as HTMLInputElement;

		if (inputElementAddress) inputElementAddress.value = `${site?.address ?? ""}`;
		if (inputElementRadius) inputElementRadius.value = `${site?.radius ?? "200"}`;
		if (inputElementLat) inputElementLat.value = `${site?.latitude ?? ""}`;
		if (inputElementLng) inputElementLng.value = `${site?.longitude ?? ""}`;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [site]);

	return (
		<div className="add_edit_map_dialog grouped-form">
			<div className="group">
				<div className="details">
					<h4>Address</h4>
				</div>
				<div className="row">
					<TextField
						placeholder="Enter Address"
						fullWidth
						required
						name="address"
						className="address_site"
						onChange={(e) =>
							debouncedResults(site.clientID, { address: e.target.value }, "address", pointMarkers, map, maps)
						}
						disabled={!userWritePermission}
					/>
					<TextField
						fullWidth
						required
						placeholder="Default Radius(m)"
						name="radius"
						type="number"
						className="radius_site"
						inputProps={{ min: 50 }}
						onChange={(e) => debouncedResults(site.clientID, { radius: e.target.value }, "radius", pointMarkers, map, maps)}
						disabled={!userWritePermission}
					/>
				</div>
				{newError && newError.length > 0 && <p className="error" style={{ marginBottom: "5px" }}>{newError}</p>}
				<div className="row">
					<TextField
						placeholder="Latitude"
						name="lat"
						className="lat_site"
						type="text"
						onChange={(e) =>
							debouncedResults(
								site.clientID,
								{ latitude: e.target.value, longitude: site?.longitude },
								"latLng",
								pointMarkers,
								map,
								maps
							)
						}
						disabled={!userWritePermission}
					/>
					<TextField
						placeholder="Longitude"
						name="lng"
						className="lng_site"
						type="text"
						onChange={(e) =>
							debouncedResults(
								site.clientID,
								{ latitude: site?.latitude, longitude: e.target.value },
								"latLng",
								pointMarkers,
								map,
								maps
							)
						}
						disabled={!userWritePermission}
					/>
				</div>
			</div>
			<div className="row" style={{ gridTemplateColumns: "1fr", padding: "10px" }}>
				<TextField
					label="Search Google"
					sx={{ width: "100%", minWidth: "200px" }}
					placeholder="Search Google"
					name="Search Google"
					className="search_google"
					id="search_google"
					type="text"
					disabled={!userWritePermission}
					onKeyDownCapture={handleKeyPress}
				/>
			</div>
			<div className="google_map">
				<GoogleMapReact
					bootstrapURLKeys={{
						key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY!,
						language: "en",
						region: "IN",
						libraries: ["places", "geometry", "drawing", "visualization"],
					}}
					options={mapOptions}
					yesIWantToUseGoogleMapApiInternals={true}
					defaultZoom={5}
					defaultCenter={{ lat: 21.1458, lng: 79.0882 }}
					style={{ position: "relative", width: "100%", height: "100%" }}
					onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}></GoogleMapReact>
			</div>
		</div>
	);
};

export default SiteCreateMapModal;
