import { TableCell, TableFooter, TableRow } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useRouter } from "next/router";
import { ORDER_TYPE } from "../../../@types";

type Props = {
	orders: ORDER_TYPE[];
};

const columns = [
	{
		label: "Order ID",
		name: "orderID",
		options: {
			viewColumns: false,
			display: false,
			filter: false,
		},
	},
	{
		label: "Date",
		name: "processingDate",
		options: {
			viewColumns: true,
			display: true,
		},
	},
	{
		label: "Employee Name",
		name: "employeeName",
		options: {
			viewColumns: true,
			display: true,
		},
	},
	{
		label: "Client Name",
		name: "clientName",
		options: {
			viewColumns: true,
			display: true,
		},
	},
	{
		label: "Number of SKUs",
		name: "skusQuantity",
		options: {
			viewColumns: true,
			display: true,
		},
	},
	{
		label: "Quantity",
		name: "quantity",
		options: {
			viewColumns: true,
			display: true,
		},
	},
	{
		label: "Order Value(Rs)",
		name: "orderValue",
		options: {
			viewColumns: true,
			display: true,
		},
	},
	{
		label: "Collection Amount(Rs)",
		name: "collectionAmount",
		options: {
			viewColumns: true,
			display: true,
		},
	},
];

const OrdersTable = ({ orders }: Props) => {
	const router = useRouter();

	const options = {
		search: true,
		textLabels: {
			body: {
				noMatch: "No Rows",
			},
		},
		download: true,
		print: false,
		elevation: 1,
		viewColumns: true,
		responsive: "standard",
		fixedHeader: true,
		filter: true,
		selectableRowsHideCheckboxes: true,
		tableBodyMaxHeight: "calc(100vh - 210px)",
		draggableColumns: {
			enabled: true,
		},
		draggable: true,
		rowsPerPageOptions: [10, 20, 50, 100],
		onRowClick: (rowData: string[]) => {
			router.push(`/order/orders/${rowData[0]}?clientName=${rowData[3]}`);
		},
		customTableBodyFooterRender: (opts: any) => {
			let totalLineItemsCount = 0,
				totalQuantityFilteredSum = 0,
				orderTotalFilteredSum = 0,
				collectionAmountFilteredSum = 0;
			opts.data.forEach((data: any) => {
				totalLineItemsCount += parseInt(data["data"][4]);
				totalQuantityFilteredSum += parseInt(data["data"][5]);
				orderTotalFilteredSum += parseFloat(data["data"][6]);
				collectionAmountFilteredSum += parseFloat(data["data"][7]);
			});
			const numberOfSku =
				orders && Array.isArray(orders)
					? orders.reduce((acc, order) => acc + (order.lineItems ? order.lineItems.length : 0), 0)
					: 0;
			const totalQuantity =
				orders && Array.isArray(orders)
					? orders.reduce(
							(acc, order) =>
								acc + (order.lineItems ? order.lineItems.reduce((acc2, lineItem) => acc2 + lineItem.quantity, 0) : 0),
							0
					  )
					: 0;
			const totalOrderValue =
				orders && Array.isArray(orders)
					? orders.reduce(
							(acc, order) =>
								acc + order.lineItems.reduce((acc2, lineItem) => acc2 + lineItem.price * lineItem.quantity, 0),
							0
					  )
					: 0;
			let totalCollectionAmount = 0;
			orders.forEach((order) =>
				order?.col?.forEach((item) => {
					totalCollectionAmount += item.amt;
				})
			);
			return (
				<TableFooter>
					<TableRow>
						<TableCell colSpan={3}>Sub Total (Filtered)</TableCell>
						<TableCell>{totalLineItemsCount}</TableCell>
						<TableCell>{totalQuantityFilteredSum}</TableCell>
						<TableCell>{orderTotalFilteredSum.toFixed(2)}</TableCell>
						<TableCell>{collectionAmountFilteredSum.toFixed(2)}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell colSpan={3}>Sub Total</TableCell>
						<TableCell>{numberOfSku}</TableCell>
						<TableCell>{totalQuantity}</TableCell>
						<TableCell>{totalOrderValue.toFixed(2)}</TableCell>
						<TableCell>{totalCollectionAmount.toFixed(2)}</TableCell>
					</TableRow>
				</TableFooter>
			);
		},
	};

	const data = orders.map((row) => {
		const quantity =
			row.lineItems && Array.isArray(row.lineItems) ? row.lineItems.reduce((acc, sku) => acc + sku.quantity, 0) : 0;
		const orderValue =
			row.lineItems && Array.isArray(row.lineItems)
				? row.lineItems.reduce((acc, sku) => acc + sku.quantity * sku.price, 0)
				: 0;
		const collectionAmount = row.col && Array.isArray(row.col) ? row.col?.reduce((acc, sku) => acc + sku.amt, 0) : 0;
		return {
			...row,
			skusQuantity: row.lineItems.length || 0,
			quantity: quantity,
			orderValue: orderValue,
			collectionAmount: collectionAmount,
		};
	});
	// @ts-ignore
	return <MUIDataTable title="Orders" data={data} columns={columns} options={options} />;
};
export default OrdersTable;
