// react
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
// library
import Link from "next/link";
// @ts-ignore
import en from "react-phone-number-input/locale/en";
import { getCountries, getCountryCallingCode, isValidPhoneNumber } from "react-phone-number-input";
import SubscriptionPlanModal from "../../common/SubscriptionPlanModal";
// query
import useFetch from "../../useFetch";
import { CLIENTS_DATA, CLIENT_CATEGORIES, ENTITY_METADATA, PROFILES_DATA, USERS_DATA } from "../../../@types";
// components
import ClientCreateMapModal from "./ClientCreateMapModal";
import CustomFieldsComplex from "../../common/CustomFieldsComplex";
// mui
import AddIcon from "@mui/icons-material/Add";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import {
	Box,
	Checkbox,
	Chip,
	CircularProgress,
	FormControl,
	FormControlLabel,
	InputAdornment,
	InputLabel,
	ListItemText,
	ListSubheader,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// redux
import { showNotification } from "../../../redux";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// utils
import { clientImageUpload } from "../../../api/client";
import { checkImageExists } from "../../../utils";

const fixedFields = [
	"Pincode",
	"City",
	"Address",
	"Description",
	"Contact Name",
	"Contact Number",
	"Email",
	"Category",
	"Description",
	"City",
];

type Props = {
	client: CLIENTS_DATA;
	setClient: Dispatch<SetStateAction<CLIENTS_DATA>>;
	loadingClients: boolean;
	selectedEmployees: any;
	setSelectedEmployees: Dispatch<SetStateAction<number[] | []>>;
	selectedProfiles: any;
	setSelectedProfiles: Dispatch<SetStateAction<number[] | []>>;
	setImageOrFileError: Dispatch<SetStateAction<any>>;
	setCustomFieldErrors: Dispatch<SetStateAction<any>>;
	dataEntity: any;
	loadingEntity: any;
	refetchEntity: any;
	newError: string;
	handleKeyPress: any;
	subsMetadata: any;
	showMaskedData?: boolean;
	clientMasking?: any;
};

// props and styles for material ui multi select
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	autoFocus: false,
};

// function to check if text contains search text
const containsText = (text: string, searchText: string) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

const ClientForm: FC<Props> = ({
	client,
	setClient,
	loadingClients,
	selectedEmployees,
	setSelectedEmployees,
	selectedProfiles,
	setSelectedProfiles,
	newError,
	setImageOrFileError,
	setCustomFieldErrors,
	dataEntity,
	loadingEntity,
	refetchEntity,
	handleKeyPress,
	subsMetadata,
	showMaskedData,
	clientMasking,
}) => {
	const dispatch = useDispatch();
	// data fetching
	const { data: dataClient, loading: loadingClient, error: errorClient } = useFetch<any>("/clients/clientCategories");
	const {
		data: dataProfiles,
		loading: loadingProfiles,
		error: errorProfiles,
	} = useFetch<PROFILES_DATA[]>("/users/profiles");
	const { data: dataUsers, loading: loadingUsers, error: errorUsers } = useFetch<any>("/users");

	const profiles = useRef<PROFILES_DATA[]>([]);
	const entityMetadata = useRef<ENTITY_METADATA[]>([]);
	const users = useRef<USERS_DATA[]>([]);
	const admin = useSelector((state: any) => state.user);

	// component states
	const [clientCategories, setClientCategories] = useState<CLIENT_CATEGORIES[]>([]);
	const [missingEntities, setMissingEntities] = useState<ENTITY_METADATA[]>([]);
	const [searchTextforEmployees, setSearchTextforEmployees] = useState("");
	const [searchTextforProfiles, setSearchTextforProfiles] = useState("");
	const [hasEffectRun, setHasEffectRun] = useState(false);
	const [open, setOpen] = useState(false);
	const [clientImage, setClientImage] = useState<null | string>(null);

	// loading and error states
	const loading = loadingClient || loadingProfiles || loadingUsers || loadingClients;
	const error = errorClient || errorProfiles || errorUsers;

	// check if all data is fetched
	const isData =
		dataClient?.clientCategories &&
		dataProfiles?.data &&
		dataUsers?.data &&
		dataEntity?.get_all_entitymetadata_by_entity_for_current_company;

	useEffect(() => {
		if (!loadingEntity) {
			const updatedMissingEntities = dataEntity?.get_all_entitymetadata_by_entity_for_current_company?.filter(
				(value: ENTITY_METADATA) =>
					!fixedFields?.includes(value.fieldName) && value.isMandatory === 0 && value.active === 1
			);
			if (updatedMissingEntities && updatedMissingEntities.length > 0) {
				setMissingEntities(updatedMissingEntities);
			}
		}
	}, [loadingEntity, dataEntity]);

	// this effect is important while creating a new client as fieldID is not populated yet.
	useEffect(() => {
		if (!loadingEntity && !loadingClients && !hasEffectRun && client && Object.keys(client).length !== 0) {
			const primaryContactField = dataEntity?.get_all_entitymetadata_by_entity_for_current_company?.find(
				(d: any) => d.fieldName === "Contact"
			)?.fieldID;

			if (primaryContactField) {
				setClient({ ...client, contact: { ...client.contact, fieldID: primaryContactField } });
			}

			// Update the state to indicate that the effect has run
			setHasEffectRun(true);
		}
	}, [loadingEntity, dataEntity, loadingClients, client]);

	useEffect(() => {
		if (!loading && !error && isData) {
			// @ts-ignore
			setClientCategories(dataClient?.clientCategories);
			profiles.current = dataProfiles.data;
			entityMetadata.current = dataEntity.get_all_entitymetadata_by_entity_for_current_company;
			users.current = dataUsers.data;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, error, isData]);

	const cacheBuster = Date.now();

	useEffect(() => {
		const url =
			process.env.NEXT_PUBLIC_S3_URL +
			"clientPhotos/" +
			admin?.adminDetails?.companyID +
			"/" +
			client?.clientID +
			".jpg" +
			`?cache=${cacheBuster}`;
		async function action() {
			const data = await checkImageExists(url);
			if (data) setClientImage(url);
		}
		action();
	}, [admin?.adminDetails?.companyID, client?.clientID]);

	// check if everyone selected or not?
	const visibleToEveryone = client?.visibility?.find((d: any) => d.type === "EVERYONE")?.value === 1 ? true : false;

	// filter employees and profiles for search sections in dropdown
	const displayedOptionsEmployees = useMemo(
		() =>
			users.current.filter((option) => containsText(`${option.firstName} ${option.lastName}`, searchTextforEmployees)),
		[searchTextforEmployees, loadingUsers]
	);
	const displayedOptionsProfiles = useMemo(
		() => profiles.current.filter((option) => containsText(option.profileName, searchTextforProfiles)),
		[searchTextforProfiles, loadingProfiles]
	);

	// handle change in form fields
	const handleChange = (event: any) => {
		const { name, value } = event.target;
		if (name === "employeeID") {
			setClient({ ...client, [name]: value, visibleToProfileID: null });
		} else if (name === "visibleToProfileID") {
			setClient({ ...client, [name]: value, employeeID: -100 });
		} else if (name === "contactName" || name === "countryCode") {
			setClient({ ...client, contact: { ...client.contact, [name]: value } });
		} else if (name === "contactNumber") {
			setClient({ ...client, contact: { ...client.contact, [name]: value.replace(/[^0-9]/g, "") } });
		} else setClient({ ...client, [name]: value });
	};

	// handle visibility checkbox
	const handleChangeVisibilityCheckbox = (event: any) => {
		const { checked } = event.target;
		if (checked) {
			setClient({ ...client, visibility: [{ type: "EVERYONE", value: 1 }] });
			setSelectedEmployees([]);
			setSelectedProfiles([]);
		} else {
			setClient({ ...client, visibility: [] });
		}
	};

	// select employee and profile
	const handleSelectEmployeeChange = (event: SelectChangeEvent<typeof selectedEmployees>) => {
		const {
			target: { value },
		} = event;
		if (
			subsMetadata?.clientVisibility >= 1 &&
			value.length >= 1 &&
			(selectedEmployees.length >= 1 || selectedProfiles.length >= 1)
		) {
			setOpen(true);
			return;
		}
		/* @ts-ignore */
		setSelectedEmployees(typeof value === "string" ? value.split(",").map((number) => Number(number)) : value);
	};
	const handleSelectProfileChange = (event: SelectChangeEvent<typeof selectedProfiles>) => {
		const {
			target: { value },
		} = event;
		if (
			subsMetadata?.clientVisibility >= 1 &&
			value.length >= 1 &&
			(selectedProfiles.length >= 1 || selectedEmployees.length >= 1)
		) {
			setOpen(true);
			return;
		}
		/* @ts-ignore */
		setSelectedProfiles(typeof value === "string" ? value.split(",").map((number) => Number(number)) : value);
	};

	// handle employee and profile dropdown
	const handleEmployeeListItems = (each: any) => (
		<MenuItem key={each.employeeID} value={each.employeeID}>
			{/* @ts-ignore */}
			<Checkbox checked={selectedEmployees.indexOf(each.employeeID) > -1} />
			<ListItemText primary={`${each.firstName} ${each.lastName}`} />
		</MenuItem>
	);
	const handleProfileListItems = (each: any) => (
		<MenuItem key={each.profileID} value={each.profileID}>
			{/* @ts-ignore */}
			<Checkbox checked={selectedProfiles.indexOf(each.profileID) > -1} />
			<ListItemText primary={`${each.profileName}`} />
		</MenuItem>
	);

	// handle visibility
	const clientCategory = dataEntity?.get_all_entitymetadata_by_entity_for_current_company?.filter(
		(d: any) => d.fieldName === "Category"
	);
	// check if fields are required or not
	const isDescriptionRequired =
		entityMetadata.current?.find((d: any) => d.fieldName === "Description")?.isRequired === 1 ? true : false;
	const isEmailRequired =
		entityMetadata.current?.find((d: any) => d.fieldName === "Email")?.isRequired === 1 ? true : false;
	const isCategoryRequired =
		entityMetadata.current?.find((d: any) => d.fieldName === "Category")?.isRequired === 1 ? true : false;
	const isCityRequired =
		entityMetadata.current?.find((d: any) => d.fieldName === "City")?.isRequired === 1 ? true : false;
	const isPinCodeRequired =
		entityMetadata.current?.find((d: any) => d.fieldName === "Pincode")?.isRequired === 1 ? true : false;
	const isContactRequired =
		entityMetadata.current?.find((d: any) => d.fieldName === "Contact")?.isRequired === 1 ? true : false;

	// check if fields are active or not
	if (loading || (client && Object.keys(client).length === 0)) {
		return (
			<div className="small_circular-spinner">
				<CircularProgress />
			</div>
		);
	}

	const onClientImageUpload = async (event: any) => {
		const file = event.target.files ? event.target.files[0] : null;
		if (!file) return;
		if (file.size > 500000) {
			dispatch(showNotification({ message: "File size should not be greater than 500KB", severity: "error" }));
			return;
		}
		const extension = file.name.split(".").reverse()[0];
		if (extension != "jpg" && extension != "jpeg" && extension != "svg" && extension != "png" && extension != "bmp") {
			dispatch(showNotification({ message: "Incorrect: File should be .jpg/.jpeg/.svg/.png/.bmp", severity: "error" }));
			return;
		}
		const { success, message } = await clientImageUpload(file, extension, client?.clientID);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (success) setClientImage(URL.createObjectURL(file));
	};

	const getMaskFieldFromEntity = (fieldName: string) => {
		const field = entityMetadata.current.find((d) => d.fieldName === fieldName);
		return field?.maskField;
	};

	const isEvenOneFieldMasked = dataEntity?.get_all_entitymetadata_by_entity_for_current_company?.some(
		(each: any) => each.maskField && each.active
	);
	return (
		<>
			{clientMasking === 1 && isEvenOneFieldMasked ? (
				<div
					style={{
						padding: "10px",
						borderRadius: "5px",
						background: "#FDF6B2",
						color: "black",
						fontSize: "14px",
						fontFamily: "SFUIText-Regular,Helvetica,Arial,sans-serif",
						marginBottom: "20px",
					}}>
					<strong>Note:</strong> You are not authorized to view this data. If you need to view this data, please click
					on the unmask button.
				</div>
			) : null}
			<div className="field_container_clients">
				<div className="grouped-form">
					<div style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
						<label
							htmlFor="clientImage"
							style={{ alignItems: "center", display: "flex", flexDirection: "column", cursor: "pointer" }}>
							{clientImage && (
								<img
									src={clientImage}
									height={150}
									width={150}
									alt=""
									style={{
										borderRadius: "100px",
										border: `3px ${clientMasking === 1 ? "gray" : "#1976d2"} dashed`,
										objectFit: "cover",
										objectPosition: "center",
									}}
								/>
							)}
							{!clientImage && (
								<div
									style={{
										borderRadius: "100px",
										height: "150px",
										width: "150px",
										border: `3px ${clientMasking === 1 ? "gray" : "#1976d2"} dashed`,
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<CloudUploadIcon style={{ color: clientMasking === 1 ? "gray" : "#1976d2", fontSize: "40px" }} />
								</div>
							)}
						</label>
						<label
							htmlFor="clientImage"
							style={{
								display: "block",
								fontFamily: "SFUIText-Regular,Helvetica,Arial,sans-serif",
								color: "#8f8f8f",
								marginTop: "10px",
								fontSize: "12px",
							}}>
							Click to upload
							<br />
							SVG, PNG, JPG, JPEG (Max. size 250*250px)
						</label>
						<input
							type="file"
							id="clientImage"
							name="clientImage"
							disabled={clientMasking === 1}
							hidden={true}
							onChange={onClientImageUpload}
						/>
					</div>
					<TextField
						label="Client Name"
						name="clientName"
						required
						value={client?.clientName ?? ""}
						onChange={handleChange}
						disabled={clientMasking === 1}
						inputProps={{ maxLength: 150 }}
						helperText={`${client?.clientName ? client?.clientName?.length : 0}/${150}`}
					/>
					<TextField
						label="Client Description"
						name="description"
						required={isDescriptionRequired}
						value={getMaskFieldFromEntity("Description") && !showMaskedData ? "*********" : client?.description ?? ""}
						onChange={handleChange}
						inputProps={{ maxLength: 600 }}
						disabled={clientMasking === 1}
						helperText={`${client?.description ? client?.description?.length : 0}/${600}`}
					/>
					<TextField
						label="Client Email"
						name="email"
						required={isEmailRequired}
						value={getMaskFieldFromEntity("Email") && !showMaskedData ? "*********" : client?.email ?? ""}
						onChange={handleChange}
						disabled={clientMasking === 1}
						inputProps={{ maxLength: 45 }}
						helperText={`${client?.email ? client?.email?.length : 0}/${45}`}
					/>
					<TextField
						label="Client ID"
						name="internalClientID"
						value={client?.internalClientID ?? ""}
						onChange={handleChange}
						disabled={clientMasking === 1}
						inputProps={{ maxLength: 45 }}
						helperText={`${client?.internalClientID ? client?.internalClientID?.length : 0}/${45}`}
					/>
					<section className="contact_details_container">
						<span className="container_title">Contact</span>
						<TextField
							label="Contact Name"
							name="contactName"
							required={isContactRequired}
							value={
								getMaskFieldFromEntity("Contact") && !showMaskedData ? "*********" : client?.contact?.contactName ?? ""
							}
							onChange={handleChange}
							disabled={clientMasking === 1}
							inputProps={{ maxLength: 90 }}
							helperText={`${client?.contact?.contactName ? client?.contact?.contactName?.length : 0}/${90}`}
							sx={{ width: "100%" }}
						/>
						<div className="contact_sub_container">
							<FormControl>
								<InputLabel id="clientCat">Country Code</InputLabel>
								<Select
									value={
										getMaskFieldFromEntity("Contact") && !showMaskedData
											? "*********"
											: client?.contact?.countryCode ?? ""
									}
									labelId="countryCode"
									name="countryCode"
									required={isContactRequired}
									label="Country Code"
									disabled={clientMasking === 1}
									onChange={handleChange}>
									{getCountries().map((country) => (
										<MenuItem key={country} value={getCountryCallingCode(country)}>
											{`${en[country]} +${getCountryCallingCode(country)}`}
										</MenuItem>
									))}
									{getMaskFieldFromEntity("Contact") && !showMaskedData && (
										<MenuItem value="*********">*********</MenuItem>
									)}
								</Select>
							</FormControl>

							<TextField
								label="Contact Number"
								name="contactNumber"
								required={isContactRequired}
								error={
									client?.contact?.contactNumber === ""
										? false
										: !isValidPhoneNumber(`+${client?.contact?.countryCode}${client?.contact?.contactNumber}`)
								}
								value={
									getMaskFieldFromEntity("Contact") && !showMaskedData
										? "*********"
										: client?.contact?.contactNumber ?? ""
								}
								disabled={clientMasking === 1}
								onChange={handleChange}
								sx={{ width: "100%" }}
							/>
						</div>
					</section>

					<FormControl fullWidth>
						<InputLabel id="canOverride" style={{ display: "flex", alignItems: "center", gap: "5px" }}>
							<p>Can employee change location</p>
							<Tooltip
								title={
									<Typography fontSize={10}>
										Allows the employee to select the client even if it is not in range. The location of the client will
										be changed to the task location and then this flag will be set back to No.{" "}
									</Typography>
								}>
								<InfoIcon sx={{ fontSize: "16px" }} />
							</Tooltip>
						</InputLabel>
						<Select
							value={client?.canOverride ?? 0}
							labelId="canOverride"
							label="Can exec change location"
							name="canOverride"
							disabled={clientMasking === 1}
							onChange={(e) => setClient({ ...client, canOverride: e.target.value ? 1 : 0 })}>
							<MenuItem value={1}>Yes</MenuItem>
							<MenuItem value={0}>No</MenuItem>
						</Select>
					</FormControl>

					{clientCategory && clientCategory[0]?.active === 1 && (
						<FormControl>
							<InputLabel id="clientCat">Category</InputLabel>
							<Select
								value={getMaskFieldFromEntity("Category") && !showMaskedData ? "*********" : client?.clientCat ?? ""}
								labelId="clientCat"
								name="clientCat"
								required={isCategoryRequired}
								label="Category"
								disabled={clientMasking === 1}
								onChange={handleChange}>
								{clientCategories.map((item) => (
									<MenuItem key={item.clientCategoryID} value={item.clientCategoryID}>
										{item.clientType}
									</MenuItem>
								))}
								{getMaskFieldFromEntity("Category") && !showMaskedData && (
									<MenuItem value="*********">*********</MenuItem>
								)}
							</Select>
						</FormControl>
					)}
					<div className="group">
						<div className="details">
							<h4>Visible To</h4>
							<FormControlLabel
								sx={{ alignItems: "flex-end" }}
								control={<Checkbox sx={{ paddingRight: 0 }} checked={visibleToEveryone} />}
								label="Everyone"
								disabled={clientMasking === 1}
								onChange={(e) => handleChangeVisibilityCheckbox(e)}
							/>
						</div>
						{/* {!visibleToEveryone && client.employeeID === -100 && client.visibleToProfileID === null && (
						<small className="error">Please Select either a Team or an Employee</small>
					)} */}
						<FormControl fullWidth>
							<InputLabel id="employee">Select Employees</InputLabel>
							<Select
								labelId="employee"
								id="employee"
								multiple
								onClose={() => setSearchTextforEmployees("")}
								value={selectedEmployees}
								onChange={handleSelectEmployeeChange}
								onKeyDown={(e) => {
									e.stopPropagation();
								}}
								input={<OutlinedInput id="select-multiple-chip" label="Select Employees" />}
								disabled={visibleToEveryone || clientMasking === 1}
								renderValue={(selected) => (
									<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
										{selected.map((value: any) => {
											const currentUser = users.current.find((d) => d.employeeID === value);
											return <Chip key={value} label={`${currentUser?.firstName} ${currentUser?.lastName}`} />;
										})}
									</Box>
								)}
								MenuProps={MenuProps}>
								<ListSubheader>
									<TextField
										size="small"
										placeholder="Type to search..."
										fullWidth
										value={searchTextforEmployees}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<SearchIcon />
												</InputAdornment>
											),
											style: { border: "none", outline: "none" },
										}}
										onChange={(e) => setSearchTextforEmployees(e.target.value)}
										onKeyDown={(e) => {
											if (e.key !== "Escape") {
												e.stopPropagation();
											}
										}}
									/>
								</ListSubheader>
								<MenuItem disabled={true}>
									<ListItemText primary={<em>You can select multile employees at once.</em>} />
								</MenuItem>
								{/* show default list of employees if no search term */}
								{searchTextforEmployees.length === 0 && users.current?.map((each) => handleEmployeeListItems(each))}
								{displayedOptionsEmployees?.map((each) => handleEmployeeListItems(each))}
							</Select>
						</FormControl>
						<br />
						<br />
						<FormControl fullWidth>
							<InputLabel id="team">Select Teams</InputLabel>
							<Select
								labelId="team"
								id="team"
								multiple
								value={selectedProfiles}
								onClose={() => setSearchTextforProfiles("")}
								onChange={handleSelectProfileChange}
								input={<OutlinedInput id="select-multiple-chip" label="Select Teams" />}
								disabled={visibleToEveryone || clientMasking === 1}
								renderValue={(selected) => (
									<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
										{selected.map((value: any) => {
											const currentUser = profiles.current.find((d) => d.profileID === value);
											return <Chip key={value} label={`${currentUser?.profileName}`} />;
										})}
									</Box>
								)}
								MenuProps={MenuProps}>
								<ListSubheader>
									<TextField
										size="small"
										placeholder="Type to search..."
										fullWidth
										value={searchTextforProfiles}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<SearchIcon />
												</InputAdornment>
											),
											style: { border: "none", outline: "none" },
										}}
										onChange={(e) => setSearchTextforProfiles(e.target.value)}
										onKeyDown={(e) => {
											if (e.key !== "Escape") {
												e.stopPropagation();
											}
										}}
									/>
								</ListSubheader>
								<MenuItem disabled={true}>
									<ListItemText primary={<em>You can select multile teams at once.</em>} />
								</MenuItem>
								{/* show default list of profiles if no search term */}
								{searchTextforProfiles.length === 0 && profiles.current?.map((each) => handleProfileListItems(each))}
								{displayedOptionsProfiles?.map((each) => handleProfileListItems(each))}
							</Select>
						</FormControl>
					</div>
					<div className="custom_fields_complex">
						<div className="title">
							<h5>City Details</h5>
						</div>
						<TextField
							label="Enter City Name"
							fullWidth
							name="city"
							value={getMaskFieldFromEntity("City") && !showMaskedData ? "*****" : client?.city ?? ""}
							required={isCityRequired}
							onChange={handleChange}
							disabled={clientMasking === 1}
							inputProps={{ maxLength: 45 }}
							helperText={`${client?.city ? client?.city?.length : 0}/${45}`}
						/>
						<TextField
							sx={{ marginTop: "10px" }}
							label="Pincode"
							fullWidth
							name="pinCode"
							required={isPinCodeRequired}
							type={getMaskFieldFromEntity("Pincode") && !showMaskedData ? "string" : "number"}
							value={getMaskFieldFromEntity("Pincode") && !showMaskedData ? "*****" : client?.pinCode ?? ""}
							disabled={clientMasking === 1}
							onChange={(e) => (`${e.target.value}`.length > 10 ? null : handleChange(e))}
							inputProps={{ maxLength: 10 }}
							helperText={`${client?.pinCode ? client?.pinCode?.length : 0}/${10}`}
						/>
					</div>
					<div className="custom_fields_complex">
						<div className="title">
							<h5>Custom Fields</h5>
							<Link
								href="/settings/custom-fields"
								target="blank"
								style={{
									pointerEvents: clientMasking === 1 ? "none" : "auto",
									color: clientMasking === 1 ? "gray" : "#1976d2",
								}}>
								<AddIcon />
							</Link>
							<button disabled={loadingEntity} className="refetch" type="button" onClick={() => refetchEntity()}>
								<RefreshOutlinedIcon />
							</button>
						</div>
						<CustomFieldsComplex
							fieldsData={client}
							setFieldsData={setClient}
							missingEntities={missingEntities}
							setImageOrFileError={setImageOrFileError}
							setCustomFieldErrors={setCustomFieldErrors}
							clientMasking={clientMasking}
							showMaskedData={showMaskedData}
						/>
					</div>
				</div>
				<div className="map_view">
					<ClientCreateMapModal
						client={client}
						setClient={setClient}
						newError={newError}
						handleKeyPress={handleKeyPress}
						getMaskFieldFromEntity={getMaskFieldFromEntity}
						clientMasking={clientMasking}
						showMaskedData={showMaskedData}
						entityMetadata={entityMetadata.current}
					/>
				</div>
				{Boolean(open) && (
					<SubscriptionPlanModal
						title="Maximum number of entries reached"
						message="Only 1 entry can be selected (everyone or 1 employee or 1 team) for you edition. Please upgrade to a higher edition to be able to select multiple entries."
						handleClose={() => setOpen(false)}
					/>
				)}
			</div>
		</>
	);
};

export default ClientForm;
