//react
import { useSelector } from "react-redux";
import { Dispatch, FC, SetStateAction, useCallback } from "react";
//types, components
import { PERMISSION_CATEGORY, USER_ROLES } from "../../../@types";
import AccordionPermission from "./AccordionPermission";
import { ROOT_STATE } from "../../../redux";
//mui
import { TextField } from "@mui/material";
type Props = {
	permissions: PERMISSION_CATEGORY[];
	setPermissions: Dispatch<SetStateAction<PERMISSION_CATEGORY[]>>;
	roleInfo: USER_ROLES;
	setRoleInfo: Dispatch<SetStateAction<USER_ROLES>>;
	moduleName: string;
};
const CreateRolesForm: FC<Props> = ({ permissions, setPermissions, roleInfo, setRoleInfo, moduleName }) => {
	const {
		susbscriptionMetadata: { data: subsMetadata },
	} = useSelector((state: ROOT_STATE) => ({
		susbscriptionMetadata: state.susbscriptionMetadata,
	}));

	const shouldShowAssignPermissions = (moduleAlias: any) => {
		switch (moduleAlias) {
			case "dashboard":
				return true;
			case "attendance":
				return true;
			case "leaves":
				return true;
			case "organization":
				return true;
			case "tasks":
				return subsMetadata?.tasks === 1 ? true : false;
			case "targets":
				return subsMetadata?.targets === 1 ? true : false;
			case "forms":
				return subsMetadata?.forms === 1 ? true : false;
			case "expense":
				return subsMetadata?.expenses === 1 ? true : false;
			case "clientsAndSites":
				return subsMetadata?.sites === 1 ? true : false;
			case "reports":
				return true;
			case "settings":
				return true;
			case "billing":
				return true;
			case "orders":
				return subsMetadata?.orders === 1 ? true : false;
			default:
				return true;
		}
	};
	const getRoles = useCallback(() => {
		const arr = [];
		for (let i = 0; i < permissions.length; i++) {
			if (
				shouldShowAssignPermissions(permissions[i].moduleAlias)
			) {
				arr.push(
					<AccordionPermission
						key={permissions[i].moduleAlias}
						permission={permissions[i]}
						setPermissions={setPermissions}
						moduleName={moduleName}
						isParentEditingAllowed={isParentEditingAllowed}
						subsMetadata={subsMetadata}
					/>
				);
			}
		}
		return arr;
	}, [permissions]);

	const isParentEditingAllowed = roleInfo.roleName === "Company Admin"
	return (
		<>
			<div className="roles_form">
				<div className="basic-info">
					<TextField
						required
						name="roleName"
						label="Role Name"
						inputProps={{ maxLength: 15, minLength: 5 }}
						value={roleInfo.roleName ?? ""}
						onChange={(e) => setRoleInfo((prevState) => ({ ...prevState, roleName: e.target.value }))}
						disabled={roleInfo.roleName === "Company Admin"}
					/>
					<TextField
						label="Role Description"
						name="roleDescription"
						value={roleInfo.roleDescription ?? ""}
						inputProps={{ maxLength: 40, minLength: 5 }}
						onChange={(e) => setRoleInfo((prevState) => ({ ...prevState, roleDescription: e.target.value }))}
						disabled={roleInfo.roleName === "Company Admin"}
					/>
				</div>
				<div className="spacer"></div>
			</div>
			<h5 className="mt-20">Assign Permissions</h5>
			<div className="roles_form">
				<div className="roles_form-permissions">{getRoles().slice(0, getRoles().length / 2)}</div>
				<div className="roles_form-permissions">{getRoles().slice(getRoles().length / 2)}</div>
			</div>
		</>
	);
};

export default CreateRolesForm;
