import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BarChartIcon from "@mui/icons-material/BarChart";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useRouter } from "next/router";
import { FC } from "react";
import { Layout, SubmoduleTabs } from "..";
import { SUBMODULE_DATA } from "../../@types";
import NotAuthorized from "../common/NotAuthorized";
import useAdminEntitlements from "../useAdminEntitlements";
import { useSelector } from "react-redux";
import { ROOT_STATE } from "../../redux";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
// import { useAdminCompanySettings } from "../useAdminEntitlements";

type Props = {
	title: string;
	children: any;
	tab: number;
};
const LeavesLayout: FC<Props> = ({ title, children, tab }) => {
	
	const userReadPermissionOverview = useAdminEntitlements("leaves:overview", "read");
	const userReadPermissionRequests = useAdminEntitlements("leaves:leaveRequests", "read");
	const userReadPermissionCompensatory = useAdminEntitlements("leaves:compensatoryLeaves", "read");
	// const leavesV2Active = useAdminCompanySettings("isLeavesV2Enabled");
	//commented for now @Aditya need to check
	const leavesV2Active = true;

	const router = useRouter();
	const isLORead = router.pathname === "/leaves/overview" && userReadPermissionOverview && leavesV2Active;
	const isLRRead = router.pathname === "/leaves/leave-requests" && userReadPermissionRequests && leavesV2Active;
	const isLARead = router.pathname === "/leaves/add" && userReadPermissionOverview && leavesV2Active;
	const isLCRead = router.pathname === "/leaves/compensatory-leaves" && userReadPermissionCompensatory && leavesV2Active;

	const {
		user,
		susbscriptionMetadata: { data: subsMetadata },
	} = useSelector((state: ROOT_STATE) => ({
		user: state.user,
		susbscriptionMetadata: state.susbscriptionMetadata,
	}));
	const leavesSubmoduleData: SUBMODULE_DATA[] = [
		{
			label: "Overview",
			icon: <BarChartIcon />,
			path: "/leaves/overview",
		},
		subsMetadata?.adminAddLeaveRequest === 1 ? 
		{
			label: "Leaves",
			icon: <PlaylistAddIcon />,
			path: "/leaves/add",
		}:
		{
			label: "Leaves Requests",
			icon: <EditOutlinedIcon />,
			path: "/leaves/leave-requests",
		},
		{
			label: "Compensatory Leaves",
			icon: <AccessTimeIcon />,
			path: "/leaves/compensatory-leaves",
		},
	];
	return (
		<Layout title={title}>
			<SubmoduleTabs submoduleData={leavesSubmoduleData } tab={tab} />
			{isLORead || isLRRead || isLCRead || isLARead ? children : <NotAuthorized roleID={user.adminDetails.roleID} moduleName="leaves"/>}
		</Layout>
	);
};

export default LeavesLayout;
