import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { Modal, Tooltip,TooltipProps, styled, tooltipClasses } from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";
import { EVIDENCE_TYPE } from "../../../@types";
type Props = {
	evidences: EVIDENCE_TYPE[];
	open: boolean;
	// eslint-disable-next-line no-unused-vars
	handleClose: (open: boolean) => void;
	currentIndex: number;
	setCurrentIndex: Dispatch<SetStateAction<number>>;
};
const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: "#42444a",
		opacity: 1,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "rgb(66,68,74, 1)",
		padding: "0.3rem",
		fontSize: "12px",
		lineHeight: "18px",
	},
}));
const ImageCarousel: FC<Props> = ({ evidences, open, handleClose, currentIndex, setCurrentIndex }) => {
	const currentEvidence = evidences[currentIndex];
	const nextIndex = currentIndex < evidences.length - 1 ? currentIndex + 1 : null;
	const prevIndex = currentIndex > 0 ? currentIndex - 1 : null;
	const name = currentEvidence?.description;
	return (
		<Modal open={open} onClose={handleClose} aria-labelledby="Evidence Image Popup">
			<div className="image_carousel">
				{prevIndex !== null && !isNaN(prevIndex) ? (
					<button className="prev_button" onClick={() => setCurrentIndex(prevIndex || 0)}>
						<KeyboardArrowLeftOutlinedIcon />
					</button>
				) : null}
				{nextIndex !== null && !isNaN(nextIndex) ? (
					<button className="after_button" onClick={() => setCurrentIndex(nextIndex || 0)}>
						<KeyboardArrowRightOutlinedIcon />
					</button>
				) : null}
				<div className="title">
				<BootstrapTooltip title={name? name:""}><h3 style={{cursor:"help"}}>{name ? name.length > 80 ? `${name.slice(0, 80)}...` : name :""}</h3></BootstrapTooltip>
					<button onClick={() => handleClose(false)}>
						<CloseSharpIcon htmlColor="#42444A" />
					</button>
				</div>
				<img src={process.env.NEXT_PUBLIC_S3_URL + currentEvidence?.photoURL} width={800} height={800} />
			</div>
		</Modal>
	);
};

export default ImageCarousel;
