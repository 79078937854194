// react
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
// mui
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
// libraries
import _ from "lodash";
import moment from "moment";
// components
import ErrorShowModal from "./ErrorShowModal";
import ErrorMessageShowModal from "./ErrorMessageShowModal";
// constants
import { PARTNAMES } from "../../../../../../constants/tasksConstant";
import { uuid } from "uuidv4";

type VALIDATION_ERRORS = {
	columnName: string;
	issue: string;
	rowsNumber: number;
};

type VALIDATION_COLUMN_ERRORS = {
	incorrectColumnName: string;
	issue: string;
	missingColumns: string;
	extraColumns: string;
};

type Props = {
	headers: any;
	rowsData: any;
	setBulkUploadModalOpen: Dispatch<SetStateAction<boolean>>;
	setShowValidationModal: Dispatch<SetStateAction<boolean>>;
	setDetailsAndSettingsForFields: any;
	selectedField: any;
};

const ExcelProcess: FC<Props> = ({
	setBulkUploadModalOpen,
	headers,
	rowsData,
	selectedField,
	setDetailsAndSettingsForFields,
}) => {
	const [errors, setErrors] = useState<VALIDATION_ERRORS[]>([]);
	const [columnErrors, setColumnErrors] = useState<VALIDATION_COLUMN_ERRORS[]>([]);
	const [validationDone, setValidationDone] = useState(false);
	const [validationDoneAndDateFetched, setValidationDoneAndDateFetched] = useState(false);
	const [userColumns, setUserColumns] = useState<string[]>([]);

	/**
	 * Excel validation checks
	 */

	const validationChecks = async () => {
		const er: any = {};
		const duplicatePositionsList: { [key: string]: object } = {};
		const characterLengthErrors: { [key: string]: number[] } = {};

		// check if each column has any duplicate options
		headers.forEach((each: any, index: number) => {
			const elements: any = {};
			let elements2: any = [];
			rowsData.forEach((eachRow: any, idx: number) => {
				// duplicate check
				elements[eachRow[index]?.toString()] = [...(elements[eachRow[index]?.toString()] || []), idx + 2];
				// character length check
				if (eachRow[index]?.toString().length > 150) {
					elements2 = [...elements2, idx + 2];
				}
			});
			duplicatePositionsList[each] = elements;
			characterLengthErrors[each] = elements2;
		});

		for (const [key, value] of Object.entries(characterLengthErrors)) {
			if (value.length > 0) {
				er[key] = {
					columnName: key,
					issue: `Character length exceeds 150 characters`,
					rowsNumber: value.join(","),
				};
			}
		}

		for (const [key, value] of Object.entries(duplicatePositionsList)) {
			if (Object.keys(value).length > 1) {
				for (const [k, v] of Object.entries(value)) {
					if (v.length > 1) {
						if (k === "undefined") {
							continue;
						} else {
							er[k] = {
								columnName: key,
								issue: `Duplicate option - ${k}`,
								rowsNumber: v.join(","),
							};
						}
					}
				}
			}
		}

		const errorStrings = [];

		for (const e in er) {
			const columnError = er[e];
			errorStrings.push({
				columnName: columnError.columnName,
				issue: columnError.issue,
				rowsNumber: columnError.rowsNumber,
			});
		}

		setErrors(errorStrings);
		setValidationDone(true);
	};

	const validateColumnNames = async () => {
		const errorStrings: VALIDATION_COLUMN_ERRORS[] = [];
		await headers.map((h: any, idx: any) => {
			const originalHeaderName = userColumns.filter((column: any, index: number) => index === idx);
			if (h === null) {
				errorStrings.push({
					incorrectColumnName: h,
					issue: `Excel format is not valid, please ensure the column sequence is correct and there are no missing columns or incorrect column name. Please download the latest excel and try again.`,
					extraColumns: "",
					missingColumns: "",
				});
			} else if (h !== originalHeaderName) {
				errorStrings.push({
					incorrectColumnName: h,
					issue: `Excel format is not valid, please ensure the column sequence is correct and there are no missing columns or incorrect column name. Please download the latest excel and try again.`,
					extraColumns: "",
					missingColumns: "",
				});
			}
		});
		setColumnErrors(errorStrings);
		setValidationDone(true);
	};

	const validateColumnsLength = (col: string) => {
		const errorStrings: VALIDATION_COLUMN_ERRORS[] = [];
		if (col === "missing columns") {
			const columnNames = userColumns.filter((x) => headers.indexOf(x) === -1);
			columnNames.map((col) => {
				errorStrings.push({
					missingColumns: col,
					issue: `You have removed the critical column "${col}" from the template/excel. Please download the latest excel and try again.`,
					incorrectColumnName: "",
					extraColumns: "",
				});
			});
		} else {
			const columnNames = headers.slice(userColumns.length);
			columnNames.map((col: any) => {
				errorStrings.push({
					extraColumns: col,
					issue: `You have added an extra column "${col}" in the file. Please download the latest excel and try again.`,
					incorrectColumnName: "",
					missingColumns: "",
				});
			});
		}

		setColumnErrors(errorStrings);
		setValidationDone(true);
	};

	useEffect(() => {
		if (selectedField) {
			setUserColumns([selectedField.fieldName]);
		}
	}, [selectedField]);

	useEffect(() => {
		if (headers && userColumns && headers.length > 0 && userColumns.length > 0 && rowsData.length > 0) {
			if (userColumns.length === headers.length) {
				if (!_.isEqual(userColumns, headers)) {
					validateColumnNames();
					return;
				} else {
					validationChecks();
				}
			} else if (userColumns.length > headers.length) {
				validateColumnsLength("missing columns");
				return;
			} else {
				validateColumnsLength("extra columns");
				return;
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userColumns, headers, rowsData]);

	useEffect(() => {
		if (validationDone) {
			setValidationDoneAndDateFetched(true);
		}
	}, [validationDone]);

	useEffect(() => {
		if (validationDoneAndDateFetched && errors.length === 0 && columnErrors.length === 0) {
			const data = rowsData
				.filter((row: any) => row[0] !== null && row[0] !== undefined)
				.map((row: any, index: number) => ({
					optionValue: row[0].toString(),
					nextSection: null,
					timestamp: moment().valueOf() + index,
					tempID: uuid(),
				}));
			setDetailsAndSettingsForFields(data, selectedField, PARTNAMES.addOption, true);
			setBulkUploadModalOpen(false);
		}
	}, [validationDoneAndDateFetched]);

	return (
		<>
			{errors && errors.length > 0 ? (
				<ErrorShowModal
					headers={headers}
					setErrors={setErrors}
					open={true}
					setBulkUploadOpen={setBulkUploadModalOpen}
					errors={errors}
				/>
			) : columnErrors && columnErrors.length > 0 ? (
				<ErrorMessageShowModal
					setColumnErrors={setColumnErrors}
					setBulkUploadOpen={setBulkUploadModalOpen}
					open={true}
					columnErrors={columnErrors}
				/>
			) : (
				<Dialog onClose={() => setBulkUploadModalOpen(false)} aria-labelledby="customized-dialog-title" open={true}>
					<DialogTitle style={{ color: "black !important" }} id="customized-dialog-title"></DialogTitle>
					<DialogContent style={{ minWidth: "500px" }} dividers>
						<div style={{ marginBottom: "15px", color: "#3f51b5", fontWeight: "bold" }}>Validating Excel File Data</div>
						<LinearProgress style={{ height: "12px", borderRadius: "7px" }} />
					</DialogContent>
					<DialogActions></DialogActions>
				</Dialog>
			)}
		</>
	);
};

export default ExcelProcess;
