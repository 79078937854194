import { CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";
import PhoneInput from "react-phone-input-2";
import { SITES_DATA, SITE_TYPES_TYPE } from "../../../@types";
import useFetch from "../../useFetch";
import SiteCreateMapModalPolygon from "./SiteCreateMapModalPolygon";

type Props = {
	site: SITES_DATA;
	setSite: Dispatch<SetStateAction<SITES_DATA>>;
	loading: boolean;
	errors: string[];
	userWritePermission: any;
	handleKeyPress: any;
};
const SitesPolygonForm: FC<Props> = ({ site, setSite, loading, errors, userWritePermission, handleKeyPress }) => {
	const { data, loading: loadingSiteTypes } = useFetch<any>("/sites/siteTypes");
	const handleChange = (event: any) => {
		setSite({ ...site, [event.target.name]: event.target.value });
	};
	const allSites: SITE_TYPES_TYPE[] = data?.data || [];
	if (loading || loadingSiteTypes || (site && Object.keys(site).length === 0)) {
		return (
			<div className="small_circular-spinner">
				<CircularProgress />
			</div>
		);
	}
	return (
		<div className="field_container_sites">
			<div className="grouped-form">
				<TextField
					label="Site Name"
					required
					name="clientName"
					value={site.clientName ?? ""}
					onChange={handleChange}
					disabled={!userWritePermission}
				/>
				<TextField
					label="Site ID"
					name="internalClientID"
					value={site.internalClientID ?? ""}
					onChange={handleChange}
					disabled={!userWritePermission}
				/>
				<TextField
					label="Contact Person Name"
					name="proprietorName"
					value={site.proprietorName ?? ""}
					onChange={handleChange}
					disabled={!userWritePermission}
				/>
				<PhoneInput
					country={"in"}
					preferredCountries={["in"]}
					enableSearch={true}
					inputProps={{
						minLength: 10,
						required: true,
					}}
					placeholder="Contact Number"
					value={site.phoneNumber ?? ""}
					onChange={(e) => handleChange({ target: { name: "phoneNumber", value: `+${e}` } })}
					disabled={!userWritePermission}
					masks={{ ci: ".. .. .. .. .." }}
				/>
				<TextField
					label="Description"
					name="description"
					value={site.description ?? ""}
					onChange={handleChange}
					disabled={!userWritePermission}
				/>
				<FormControl>
					<InputLabel id="jobTypeID">Site Type</InputLabel>
					<Select
						value={site.jobTypeID ?? 0}
						required
						labelId="jobTypeID"
						name="jobTypeID"
						label="jobTypeID"
						onChange={handleChange}
						disabled={!userWritePermission}>
						{allSites.length > 0 ? (
							allSites.map((item: any) => (
								<MenuItem key={item.jobTypeID} value={item.jobTypeID}>
									{item.jobDescription}
								</MenuItem>
							))
						) : (
							<MenuItem value={""}>No Site Present</MenuItem>
						)}
					</Select>
				</FormControl>
				<div className="group">
					<div className="details">
						<h4>City Details</h4>
					</div>
					<div style={{ marginBottom: 20 }}>
						<TextField
							label="Enter City Name"
							fullWidth
							name="city"
							value={site.city ?? ""}
							onChange={handleChange}
							disabled={!userWritePermission}
						/>
					</div>
					<TextField
						label="Pincode"
						fullWidth
						name="pinCode"
						type="number"
						value={site.pinCode ?? ""}
						onChange={handleChange}
						disabled={!userWritePermission}
					/>
				</div>
			</div>
			<div className="map_view">
				<SiteCreateMapModalPolygon site={site} setSite={setSite} errors={errors} userWritePermission={userWritePermission} handleKeyPress={handleKeyPress}/>
			</div>
		</div>
	);
};

export default SitesPolygonForm;
