import { useQuery } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { MenuItem, Select, Switch } from "@mui/material";
import { DataGridPro, GridColDef, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { FC, useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { FORM_TEMPLATE_TYPE, PROFILES_DATA } from "../../../@types";
import { addTaskDescriptions, deleteTaskDescriptions, updateTeamLevelTaskDDSettings } from "../../../api/settings";
import { DD_STYLES, DG_STYLES } from "../../../constants";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import { GET_PARTIAL_FORMS } from "../../../schema";
import { sortByString } from "../../../utils";
import CustomColumnMenu from "../../common/datagrid/CustomColumnMenu";
import useAdminEntitlements from "../../useAdminEntitlements";
import useFetch from "../../useFetch";
import AddModal from "../AddModal";
import ErrorModal from "../Sms/ErrorModal";
import { useSelector } from "react-redux";
import ReadOnlyButton from "../../common/ReadOnlyButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TeamSelector from "../../common/TeamSelector";

type DATA = {
	companyLevel: {
		taskGeofenceRadius: number;
	};
	teamLevel: {
		profileName: string;
		profileID: number;
		clientMandatoryForTask: 0 | 1;
		formTemplateID: string | null;
		oneTaskPerClientPerDay: 0 | 1;
		fixedTaskDescriptions: 0 | 1;
		isOtpVerificationEnabledForTasks: 0 | 1;
		taskSelfieEnabled: null | 0 | 1;
		taskDescriptions: {
			taskDescription: string;
			taskDescriptionID: number;
		}[];
	}[];
};

const renderSelectValue = (value: null | string) => (
	<div>
		Assigning To: <span style={{ marginLeft: "8px", fontWeight: "bold" }}>{value}</span>
	</div>
);
const TeamLevelTaskSettings: FC = () => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const { data, loading, refetch } = useFetch<DATA>("/preferences/task");
	const { data: forms } = useQuery<{
		get_form_metadata_by_companyID_v2: Pick<FORM_TEMPLATE_TYPE, "formTemplateID" | "formTitle">[];
	}>(GET_PARTIAL_FORMS);
	const { data: profileData, loading: profileLoading } = useFetch<PROFILES_DATA[]>("/users/profiles");
	const [profiles, setProfiles] = useState<PROFILES_DATA[]>([]);
	const [expandModal, setExpandModal] = useState(false);
	const [selectedProfile, setSelectedProfile] = useState<PROFILES_DATA["profileName"] | null>(null);
	const [selectedSetting, setSelectedSetting] = useState<null | DATA["teamLevel"][0]>(null);
	const [columns, setColumns] = useState<GridColDef[]>([]);
	const userWritePermission = useAdminEntitlements("settings:tasks", "write");
	const { roleID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);
	const [open, setOpen] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const {
		susbscriptionMetadata: { data:subsMetadata },
	} = useSelector((state: ROOT_STATE) => ({
		susbscriptionMetadata: state.susbscriptionMetadata,
	}));
	const { data: balance, loading: isBalanceLoading } = useFetch<any>("/messageBalance");

	const handleClose = () => {
		setOpen(false);
	};
	const tempColumns = useMemo(
		() => [
			{
				field: "taskDescription",
				headerName: "Description Title",
				sortable: false,
				flex: 1,
				minWidth: 200,
			},
			{
				field: "state",
				headerName: "Action",
				width: 90,
				renderCell: (params: GridValueGetterParams) => (
					<div className="action-buttons">
						<button disabled={!userWritePermission} type="button" onClick={() => deleteDescription(params.row)}>
							<DeleteIcon htmlColor="#f54747" />
						</button>
					</div>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedSetting, userWritePermission]
	);
	useEffect(() => {
		setColumns(tempColumns);
	}, [tempColumns]);

	useEffect(() => {
		if (!profileLoading && profileData.data) {
			setProfiles(profileData.data);
			setSelectedProfile(profileData.data[0].profileName);
		}
	}, [profileLoading, profileData]);

	useEffect(() => {
		if (!loading && data?.data && selectedProfile) {
			const currSettings = data.data.teamLevel.find((setting) => setting.profileName === selectedProfile) || null;
			setSelectedSetting(currSettings);
		}
	}, [data?.data, loading, selectedProfile]);

	const deleteDescription = async (desc: DATA["teamLevel"][number]["taskDescriptions"][number]) => {
		if (!desc || !selectedSetting || !selectedSetting.taskDescriptions) return;
		const updatedDescriptions = [...selectedSetting.taskDescriptions];
		const descriptionIndex = updatedDescriptions.findIndex((d) => d.taskDescriptionID === desc.taskDescriptionID);
		if (descriptionIndex < 0) return;
		updatedDescriptions.splice(descriptionIndex, 1);
		const profile = profiles.find((p) => p.profileName === selectedProfile);
		if (!profile || !selectedSetting) {
			dispatch(showNotification({ message: "Client Side error, Please reload the page", severity: "error" }));
			return;
		}
		const { success, message } = await deleteTaskDescriptions([desc.taskDescriptionID], profile.profileID);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (!success) return;
		setSelectedSetting({ ...selectedSetting, taskDescriptions: updatedDescriptions });
	};

	const handleChange = useCallback(
		async (value: number | string | null, key: keyof DATA["teamLevel"][number], selectTeams?:number[]) => {
			if (isBalanceLoading) {
				return;
			}
			if (!key || key.length === 0 || !selectedSetting) {
				dispatch(showNotification({ message: "Error: Please try again or Reload the page", severity: "error" }));
				return;
			}
			const keyValue = value === "None" ? null : value;
			const updatedSettings = { ...selectedSetting, [key]: keyValue };
			const profileID = profiles.find((profile) => profile.profileName === selectedProfile);
			if (!profileID) {
				dispatch(showNotification({ message: "Client Side error, Please reload the page", severity: "error" }));
				return;
			}
			if (updatedSettings.isOtpVerificationEnabledForTasks === 1) {
				// Backend will send null when there is no row for companyID in the smsCredit Table
				if (balance?.data.smsBalance === null) {
					// If this is a scenario then restrict user from enabling the OTP service
					setOpen(true);
					return;
				}
			}
			const { success, message } = await updateTeamLevelTaskDDSettings(updatedSettings, profileID?.profileID!, selectTeams);

			if (success) {
				refetch();
				dispatch(showNotification({ message, severity: success ? "success" : "error" }));
			}
			// setSelectedSetting(updatedSettings);
		},
		[dispatch, selectedSetting]
	);

	const addNewDescription = async (description: string) => {
		if (!selectedSetting || !selectedSetting.taskDescriptions) return;
		const updatedDescriptions = [...selectedSetting.taskDescriptions];
		updatedDescriptions.push({ taskDescription: description, taskDescriptionID: updatedDescriptions.length + 1 });
		const profile = profiles.find((p) => p.profileName === selectedProfile);
		if (!profile || !selectedSetting) {
			dispatch(showNotification({ message: "Client Side error, Please reload the page", severity: "error" }));
			return;
		}
		const { success, message } = await addTaskDescriptions(description, profile.profileID);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (success) setSelectedSetting({ ...selectedSetting, taskDescriptions: updatedDescriptions });
	};

	const findProfileId = (profileName: any) => {
		const id = profileData.data?.find((profile) => profile.profileName === profileName)?.profileID;
		return id;
	};

	return (
		<div className="team-level-task-settings mb-4">
			{open && <ErrorModal handleClose={handleClose} />}
			{expanded && <TeamSelector expanded={expanded} setExpanded={setExpanded} handleChange={handleChange} />}
			<div className="team-header">
			<div className="team-level-header">
				<PeopleAltOutlinedIcon />
				<p className="title">Team Level Settings</p>
				{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="settings" /> : null}
			</div>
				<button
					className="add mui-button"
					onClick={() => setExpanded(!expanded)}
					disabled={
						(!userWritePermission)
					}>
					<ContentCopyIcon sx={{ marginRight: "5px" }}/> 
					Copy Team Settings
				</button>
			</div>
			<div className="horizontal-line"></div>
			<Select
				onChange={(e) => setSelectedProfile(e.target.value)}
				value={selectedProfile}
				sx={{
					...DD_STYLES,
					maxWidth: "320px",
					marginBottom: "2rem",
					color: "#0F45FF",
				}}
				renderValue={(selectedValue) => renderSelectValue(selectedValue)}>
				{profiles
					?.sort((a, b) => sortByString(a.profileName, b.profileName))
					.map((profile) => (
						<MenuItem key={profile.profileID} value={profile.profileName}>
							{profile.profileName}
						</MenuItem>
					))}
			</Select>
			<div className="team-level-grid">
				<div className="section">
					<p className="title">Mandatory Completion Form</p>
					<p className="subtitle">Employee has to complete a form before finishing any task</p>
					<Select
						value={selectedSetting?.formTemplateID || "None"}
						sx={DD_STYLES}
						onChange={(e) => handleChange(e.target.value === "None" ? null : e.target.value, "formTemplateID")}
						disabled={!userWritePermission}>
						<MenuItem value={"None"}>None</MenuItem>
						{forms?.get_form_metadata_by_companyID_v2
							.filter(
								(item: any) =>
									item?.visibility?.teamIDs?.includes(findProfileId(selectedProfile)) ||
									item?.visibility?.visibleToAll === true
							)
							?.sort((a, b) => sortByString(a.formTitle, b.formTitle))
							.map((item) => (
								<MenuItem key={item.formTemplateID} value={item.formTemplateID}>
									{item.formTitle}
								</MenuItem>
							))}
					</Select>
				</div>
				<div className="section">
					<p className="title">Task Limit</p>
					<p className="subtitle">Enforce one task per client per day</p>
					<Select
						value={selectedSetting?.oneTaskPerClientPerDay ?? 0}
						sx={DD_STYLES}
						disabled={!userWritePermission}
						onChange={(e) => handleChange(e.target.value as string, "oneTaskPerClientPerDay")}>
						<MenuItem value={1}>On</MenuItem>
						<MenuItem value={0}>Off</MenuItem>
					</Select>
				</div>
			</div>
			<div className="team-level-grid">
				<div className="section">
					<p className="title">Client Mandatory</p>
					<p className="subtitle">Enforce client selection mandatory while creating task</p>
					<Select
						value={selectedSetting?.clientMandatoryForTask ?? 0}
						sx={DD_STYLES}
						onChange={(e) => handleChange(e.target.value as string, "clientMandatoryForTask")}
						disabled={!userWritePermission}>
						<MenuItem value={1}>On</MenuItem>
						<MenuItem value={0}>Off</MenuItem>
					</Select>
				</div>
			</div>
			<div className="team-level-grid">
				<div className="section">
					<p className="title">OTP Enabled</p>
					<p className="subtitle">Require Employees to Enter in OTP to send to Linked Clients to end the task</p>
					<Select
						value={selectedSetting?.isOtpVerificationEnabledForTasks ?? 0}
						sx={DD_STYLES}
						onChange={(e) => handleChange(+e.target.value, "isOtpVerificationEnabledForTasks")}
						disabled={!userWritePermission}>
						<MenuItem value={1}>Enabled</MenuItem>
						<MenuItem value={0}>Disabled</MenuItem>
					</Select>
				</div>
				<div className="section">
					<p className="title">Task Mandatory Photo</p>
					<p className="subtitle">Require Employees to take a photo when task starts</p>
					<Select
						value={selectedSetting?.taskSelfieEnabled ?? 0}
						sx={DD_STYLES}
						onChange={(e) => handleChange(+e.target.value, "taskSelfieEnabled")}
						disabled={!userWritePermission}>
						<MenuItem value={1}>Enabled</MenuItem>
						<MenuItem value={0}>Disabled</MenuItem>
					</Select>
				</div>
			</div>
			<div className="task-descriptions">
				<div className="top">
					<div className="section">
						<p className="title">Task Descriptions</p>
						<p className="subtitle">Will be shown as a dropdown in task screen to enforce consistency</p>
						<div className="allow">
							<p>
								Allow custom descriptions
								<Switch
									checked={selectedSetting?.fixedTaskDescriptions === 0 ? false : true}
									onClick={(e: any) => handleChange(e.target.checked === false ? 0 : 1, "fixedTaskDescriptions")}
									disabled={!userWritePermission}
									sx={{ cursor: !userWritePermission ? "not-allowed !important" : "pointer", pointerEvents: "auto" }}
								/>
							</p>
						</div>
					</div>
					<div className="action-buttons">
						<button disabled={!userWritePermission} className="add mui-button" onClick={() => setExpandModal(true)}>
							<AddIcon /> <div>Add</div>
						</button>
					</div>
				</div>
				<div className="datagrid-table" style={{ height: "40vh" }}>
					<DataGridPro
						sx={DG_STYLES}
						rows={selectedSetting?.taskDescriptions ?? []}
						columns={columns}
						getRowId={(row) => row.taskDescriptionID}
						loading={loading || profileLoading}
						rowsPerPageOptions={[5, 10, 20, 50]}
						rowHeight={70}
						disableSelectionOnClick
						disableColumnFilter
						components={{
							ColumnMenu: CustomColumnMenu,
						}}
						pagination
					/>
				</div>
			</div>
			{expandModal && (
				<AddModal<DATA["teamLevel"][0] | null>
					open={expandModal}
					onClose={() => setExpandModal(false)}
					setSelectedSetting={setSelectedSetting}
					title="Add Task Description"
					placeholder="Enter Task Description"
					type="text"
					submitHandler={(i) => addNewDescription(i as string)}
					subsMetadata={subsMetadata}
					profiles={selectedSetting?.taskDescriptions}
					name = "custom description"
				/>
			)}
		</div>
	);
};
export default TeamLevelTaskSettings;
