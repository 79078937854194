import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Link from "next/link";
import { FC, useState } from "react";
import DeleteModal from "../DeleteModal";
type Props = {
	link?: string;
	deleteHandler: () => void;
	name: string;
	// eslint-disable-next-line no-unused-vars
	handleUserId?: (id: string) => void;
	moduleName?: string;
	data?: any;
	width?: boolean;
	userWritePermission: any;
};
const DataGridActionButtons: FC<Props> = ({
	link,
	deleteHandler,
	name,
	handleUserId,
	width,
	data,
	moduleName,
	userWritePermission,
}) => {
	const [open, setOpen] = useState(false);
	return (
        <div className="action-buttons">
			{link && link?.length > 0 && (
				<Link href={link} passHref legacyBehavior>
					<button disabled={!userWritePermission}>
						<EditOutlinedIcon htmlColor="#8F8F8F" />
					</button>
				</Link>
			)}
			{/* no need to execute the function as event will propagate up and execute which is clicking on the row itself */}
			{handleUserId && (
				<button onClick={() => {}} style={{ width: width ? "26px" : "" }} disabled={!userWritePermission}>
					<EditOutlinedIcon htmlColor="#8F8F8F" />
				</button>
			)}
			<button
				onClick={(e) => {
					e.stopPropagation();
					setOpen(true);
				}}
				style={{ width: width ? "26px" : "" }}
				disabled={!userWritePermission}>
				<DeleteIcon htmlColor="#F54747" />
			</button>
			{open && (
				<DeleteModal
					deleteHandler={deleteHandler}
					handleClose={() => setOpen(false)}
					name={name}
					data={data}
					moduleName={moduleName}
				/>
			)}
		</div>
    );
};

export default DataGridActionButtons;