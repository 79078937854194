//react, next
import { useRouter } from "next/router";
import { FC } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
//utils, components
import { Layout, SubmoduleTabs } from "..";
import { SUBMODULE_DATA } from "../../@types";
import { fetchSidebarMetaData } from "../../utils";
import NotAuthorized from "../common/NotAuthorized";
import useAdminEntitlements, { useAdminCompanySettings } from "../useAdminEntitlements";
import { ROOT_STATE } from "../../redux";
//mui
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import EarbudsOutlinedIcon from "@mui/icons-material/EarbudsOutlined";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";

type Props = {
	title: string;
	children: any;
	tab: number;
};
const TaskLayout: FC<Props> = ({ title, children, tab }) => {
	const pjpv2 = useAdminCompanySettings("pjpv2");
	const tasksSubmoduleData: SUBMODULE_DATA[] = [
		{
			label: "Overview",
			icon: <BarChartOutlinedIcon />,
			path: "/tasks/overview",
		},
		{
			label: "Assign",
			icon: <AssignmentTurnedInOutlinedIcon />,
			path: "/tasks/assign",
		},
		{
			label: "Tasks",
			icon: <QueryBuilderOutlinedIcon />,
			path: "/tasks/tasks",
		},
		{
			label: "Routes",
			icon: <EarbudsOutlinedIcon />,
			path: "/tasks/routes",
		},
		pjpv2 === 1 ? {
			label: "PJP Requests",
			icon: <QueryBuilderOutlinedIcon />,
			path: "/tasks/pjp-requests",
		} : {
			label: "PJP Requests",
			icon: <QueryBuilderOutlinedIcon />,
			path: "/tasks/pjp-requests-v1",
		},
		
	];
	const { data } = useQuery("subscriptionMetadata", fetchSidebarMetaData, {
		staleTime: 200000,
		cacheTime: 200000,
	});

	if (data?.data?.data?.tasksPJP === 0) {
		const index = tasksSubmoduleData.findIndex((item) => "/tasks/pjp-requests" === item?.path || "/tasks/pjp-requests-v1" === item?.path);
		//@ts-ignore
		tasksSubmoduleData[index].hide = true;
	}

	const userReadPermissionTM = useAdminEntitlements("tasks:assign", "read");
	const userReadPermissionRoutes = useAdminEntitlements("tasks:routes", "read");
	const userReadPermissionPJP = useAdminEntitlements("tasks:pjpRequests", "read");
	const userReadPermissionOverview = useAdminEntitlements("tasks:overview", "read");
	const userReadPermissiontasks = useAdminEntitlements("tasks:tasks", "read");

	const router = useRouter();
	const isTORead = router.pathname.includes("overview") && userReadPermissionOverview;
	const isTMRead = router.pathname.includes("assign") && userReadPermissionTM;
	const isRoutesRead = router.pathname.includes("routes") && userReadPermissionRoutes;
	const isPJPRead = router.pathname.includes("pjp-requests") && userReadPermissionPJP;
	const isTaskSomething = router.pathname === ("/tasks/tasks") && userReadPermissiontasks;

	const { roleID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);

	return (
		<Layout title={title}>
			<SubmoduleTabs submoduleData={tasksSubmoduleData} tab={tab} />
			{isTORead || isTMRead || isRoutesRead || isPJPRead || isTaskSomething ? children : <NotAuthorized roleID={roleID} moduleName="tasks"/>}
		</Layout>
	);
};

export default TaskLayout;
