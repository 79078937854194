import axios from "../../utils/axios";
axios.defaults.withCredentials = true;
type RETURN_TYPE = Promise<{ success: boolean; message: string; data: any }>;
export const loginToSofia = async (email: string, password: string): RETURN_TYPE => {
	try {
		const res = await axios({
			method: "post",
			url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/login`,
			headers: { "content-type": `application/x-www-form-urlencoded; charset=UTF-8` },
			data: `email=${encodeURIComponent(email.trim().toLocaleLowerCase())}&password=${password}`,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Logged In",
			data: res.data,
		};
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Logging In";
		return { success: false, message: errorText, data: "" };
	}
};

export const resetPasswordApi = async (email: string): RETURN_TYPE => {
	try {
		const res = await axios({
			method: "post",
			url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/login/resetPassword`,
			headers: { "content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
			data: `emailID=${encodeURIComponent(email.trim().toLocaleLowerCase())}`,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Sent Reset Password Email",
			data: res.data,
		};
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Sending Reset Password Email";
		return { success: false, message: errorText, data: "" };
	}
};
export const confirmResetPassword = async (email: string, otp: string, password: string): RETURN_TYPE => {
	try {
		const res = await axios({
			method: "post",
			url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/login/confirmResetPassword`,
			headers: { "content-type": "application/x-www-form-urlencoded; charset=UTF-8" },
			data: `emailID=${encodeURIComponent(email.trim().toLocaleLowerCase())}&password=${password}&otp=${otp.trim()}&role=${"admin"}`,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || "Successfully Reset Password",
			data: res.data,
		};
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Resetting Password";
		return { success: false, message: errorText, data: "" };
	}
};
