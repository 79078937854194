import { gql } from "@apollo/client";
gql`
	input NotificationsInput {
		adminID: Int!
		moduleID: Int!
		status: Int!
	}
`;

// eslint-disable-next-line import/prefer-default-export
export const GET_NOTIFICATION_STATUS = gql`
	query GetNotifications {
		get_notifications_module_by_adminID {
			moduleID
			adminID
			moduleName
			status
		}
	}
`;

// gql for upsert_subscribed_notifs_module
export const UPSERT_SUBSCRIBED_NOTIFS_MODULE = gql`
	mutation UpsertSubscribedNotifsModule($input: [NotificationsInput!]!) {
		upsert_subscribed_notifs_module(input: $input) {
			rowsReplaced
		}
	}
`;
