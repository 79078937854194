import { DataGridPro, GridColDef, GridSelectionModel, GridValueGetterParams } from "@mui/x-data-grid-pro";
import _cloneDeep from "lodash/cloneDeep";
import { useRouter } from "next/router";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { USER_ROLES } from "../../../@types";
import { deleteSelectedRoles } from "../../../api/roles";
import { DG_STYLES } from "../../../constants";
import { APP_DISPATCH, showNotification } from "../../../redux";
import CustomColumnMenu from "../../common/datagrid/CustomColumnMenu";
import DataGridActionButtons from "../../common/datagrid/DatagridActionButton";
import useAdminEntitlements from "../../useAdminEntitlements";

type Props = {
	userRoles: USER_ROLES[];
	filteredRoles: USER_ROLES[];
	setUserRoles: Dispatch<SetStateAction<USER_ROLES[]>>;
	setSelectedRows: Dispatch<SetStateAction<GridSelectionModel>>;
	loading: boolean;
};
const RolesTable: FC<Props> = ({ userRoles, setUserRoles, setSelectedRows, loading, filteredRoles,}) => {
	const router = useRouter();
	const [columns, setColumns] = useState<GridColDef[]>([]);
	const userWritePermission = useAdminEntitlements("organization:roles", "write");

	const tempColumns = useMemo(
		() => [
			{
				field: "roleName",
				headerName: "Role Name",
				flex: 1,
				minWidth: 200,
			},
			{
				field: "roleDescription",
				headerName: "Description",
				flex: 2,
				minWidth: 200,
			},
			{
				field: "action",
				headerName: "Action",
				width: 100,
				renderCell: (params: GridValueGetterParams) =>
					(
						<DataGridActionButtons
							link={`/organization/roles/${params.row.roleID}`}
							name={params.row.roleName}
							deleteHandler={() => deleteRoles(params.row.roleID)}
							userWritePermission={userWritePermission}
						/>
					)
			},
		],
		[userRoles, userWritePermission]
	);

	useEffect(() => {
		setColumns(tempColumns);
	}, [tempColumns]);

	const [pageSize, setPageSize] = useState<number>(20);
	const dispatch = useDispatch<APP_DISPATCH>();
	const deleteRoles = async (id: string) => {
		if (!id || id.length === 0) return;
		const newRoles = _cloneDeep(userRoles).filter((user) => user.roleID !== id);
		const { success, message } = await deleteSelectedRoles([id]);
		dispatch(showNotification({ message, severity: success ? "success" : "error" }));
		if (!success) return;
		setUserRoles(newRoles);
		setSelectedRows([]);
	};
	return (
		<div className="datagrid-table">
			<DataGridPro
				sx={DG_STYLES}
				rows={filteredRoles}
				columns={columns}
				loading={loading}
				getRowId={(row) => row.roleID}
				pageSize={pageSize}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[5, 10, 20, 50]}
				rowHeight={70}
				checkboxSelection
				disableSelectionOnClick
				disableColumnFilter
				onRowClick={(params: any) => userWritePermission && router.push(`/organization/roles/${params.id}`)}
				onSelectionModelChange={(selectionModel) => setSelectedRows(selectionModel)}
				components={{
					ColumnMenu: CustomColumnMenu,
				}}
				pagination
			/>
		</div>
	);
};

export default RolesTable;
