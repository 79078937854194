import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useId, useMemo } from "react";
import { useDispatch } from "react-redux";
import { EXPENSE_REQUEST_TYPE } from "../../../@types";
import { showNotification } from "../../../redux";
import { findCurrentExpenseConveyanceAuth } from "../../../utils";
import { findNextApprovalAmount } from "./ExpenseRequestTable";

type Props = {
	request: EXPENSE_REQUEST_TYPE;
	setRequest: Dispatch<SetStateAction<EXPENSE_REQUEST_TYPE>>;
	canApprove: boolean;
};
const ExpenseForm: FC<Props> = ({ request, setRequest, canApprove }) => {
	const [amount, comment] = useId();
	const dispatch = useDispatch();

	const isPending = request.status === -1;
	const currentExpenseAuth = useMemo(() => findCurrentExpenseConveyanceAuth(request), [request]);
	if (isPending) {
		//only apply the validation if the current expense is pending...
		if (typeof currentExpenseAuth === "string") {
			dispatch(showNotification({ message: currentExpenseAuth, severity: "error" }));
		}
	}

	const amountClaimed = useMemo(
		() =>
			request.expenseDetails && Array.isArray(request.expenseDetails)
				? request.expenseDetails.reduce((acc: number, curr) => acc + curr.amountClaimed, 0)
				: 0,
		[request.expenseDetails]
	);
	const lastApprovedExpense = findNextApprovalAmount(request);

	const status =
		request.status === 0
			? "Rejected"
			: request.status === 1
			? "Approved"
			: request.status === 2
			? "PaidOut"
			: "Pending";
	const icon =
		status === "Rejected" ? (
			<CloseOutlinedIcon htmlColor="#ffffff" />
		) : status === "Approved" ? (
			<DoneOutlinedIcon htmlColor="#ffffff" />
		) : (
			<CurrencyRupeeOutlinedIcon htmlColor="#ffffff" />
		);
	useEffect(() => {
		const updatedRequest = { ...request };
		const expenseAuthIndex = request.expenseAuth.findIndex((e) => e.actionPerformed === -1);
		if (expenseAuthIndex === -1 || typeof currentExpenseAuth === "string") return;
		updatedRequest.expenseAuth[expenseAuthIndex].reimbursementAmount =
			currentExpenseAuth?.reimbursementAmount ?? lastApprovedExpense;
		setRequest(updatedRequest);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentExpenseAuth, lastApprovedExpense, setRequest]);

	const changeExpenseValue = (e: ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		if (name === "reimbursementAmount" && isNaN(+value)) return;
		const updatedRequest = { ...request };
		const expenseAuthIndex = request.expenseAuth.findIndex((e) => e.actionPerformed === -1);
		if (expenseAuthIndex === -1) return;

		updatedRequest.expenseAuth[expenseAuthIndex][name] = name === "comment" ? value : parseFloat(value);
		setRequest(updatedRequest);
	};
	if (typeof currentExpenseAuth === "string") return <></>;
	return (
		<div className="amount_claimed">
			<div className="claimed">
				<div className="amount">
					<picture className={status}>{icon}</picture>
					<div className="title">
						<p>Total Amount Claimed</p>
						<h1 className="rupee">{amountClaimed}</h1>
					</div>
				</div>
				<div className="with_label">
					<label htmlFor={`${amount}-${lastApprovedExpense}`}>Enter Approval Amount</label>
					<input
						id={`${amount}-${lastApprovedExpense}`}
						name="reimbursementAmount"
						placeholder="Enter Amount"
						type="number"
						required
						min={0}
						value={currentExpenseAuth?.reimbursementAmount?.toString() ?? lastApprovedExpense?.toString() ?? amountClaimed ??  ""}
						disabled={!isPending || !canApprove}
						onChange={changeExpenseValue}
					/>
				</div>
			</div>
			<div className="form_control" style={{ position: "relative" }}>
				<label htmlFor={comment} style={{ position: "absolute", top: "12px", left: "10px" }}>
					Add Comment
				</label>
				<input
					id={comment}
					name="comment"
					type="text"
					placeholder="Add comment..."
					disabled={!isPending || !canApprove}
					onChange={changeExpenseValue}
					value={currentExpenseAuth?.comment || ""}
				/>
			</div>
		</div>
	);
};

export default ExpenseForm;
