/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel } from "@mui/material";
import _cloneDeep from "lodash/cloneDeep";
import _uniqBy from "lodash/uniqBy";
import moment from "moment";
import { ChangeEvent, Dispatch, SetStateAction, useMemo, useState } from "react";

type Props2<TColumn, FColumns extends string> = {
	title: TColumn;
	data: (string | undefined)[];
	ids: (string | number | null | undefined)[];
	filters: Record<FColumns, string[]>;
	// eslint-disable-next-line no-unused-vars
	name: FColumns;
	setFilters: Dispatch<SetStateAction<Record<FColumns, string[]>>>;
	localStorageKey?: string;
};
function IndividualFilter<TColumn extends string, FColumns extends string>({
	title,
	data,
	name,
	filters,
	setFilters,
	localStorageKey,
}: Props2<TColumn, FColumns>) {
	const [searchInput, setSearchInput] = useState("");
	const filteredData = useMemo(() => _uniqBy(data, (e) => e).filter(Boolean), [data, searchInput, filters]);

	const debouncedChanges = (e: ChangeEvent<HTMLInputElement>) => setSearchInput(e.target.value);
	const onChangeCheckbox = (e: any) => {
		const { name, checked, value }: { name: FColumns; checked: boolean; value: string } = e.target;
		if (!name || !value) return;
		let newValue = value;
		if (name === "date") {
			newValue = moment(value, "DD-MM-YYYY").format("YYYY-MM-DD");
		}
		const newFilters = _cloneDeep(filters);
		if (checked) {
			newFilters[name].push(newValue);
		} else newFilters[name] = _cloneDeep(newFilters[name]).filter((id) => id !== newValue);

		if (localStorageKey && localStorageKey.length > 0)
			localStorage.setItem(localStorageKey, JSON.stringify(newFilters));
		setFilters(newFilters);
	};
	
	return (
        <Accordion sx={{ margin: 0 }}>
			<AccordionSummary style={{ borderTop: "0.5px solid gray" }} expandIcon={<ExpandMoreIcon />}>
				<h6>{title}</h6>
			</AccordionSummary>
			<AccordionDetails>
				{name !== "status" && (
					<input type="search" className="search" placeholder={`Search ${title}`} onChange={debouncedChanges} />
				)}
				{filteredData?.map(
					(item, ids) =>
						item?.toLowerCase().replace(/\s/g, "").includes(searchInput.toLowerCase().replace(/\s/g, "")) && (
							<FormControlLabel
							sx={{ width: "100%" }}
							key={ids}
							name={name}
							value={
								name === "date"
									? moment(filteredData[ids], "YYYY-MM-DD").format("DD-MM-YYYY") || ""
									: filteredData[ids] || ""
							}
							control={<Checkbox size="small" />}
							label={
								name === "date"
									? moment(filteredData[ids], "YYYY-MM-DD").format("DD-MM-YYYY") || ""
									: filteredData[ids] || ""
							}
							checked={filters[name]?.includes(
								// @ts-ignore
								typeof filteredData[ids] === "string" ? filteredData[ids] : JSON.stringify(filteredData[ids])
							)}
							onChange={onChangeCheckbox}
						/>
						)
				)}
			</AccordionDetails>
		</Accordion>
    );
}
export default IndividualFilter;
