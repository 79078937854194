// react
import { Dispatch, FC, SetStateAction, useState } from "react";

// mui
import AddIcon from "@mui/icons-material/Add";
import { MenuItem, Select } from "@mui/material";

// redux
import { ROOT_STATE } from "../../../redux";
import { useSelector } from "react-redux";

// constants, types and utils
import { DD_STYLES } from "../../../constants";
import useAdminEntitlements, { useAdminCompanySettings } from "../../useAdminEntitlements";
import ReadOnlyButton from "../../common/ReadOnlyButton";
import SubscriptionPlanModal from "../../common/SubscriptionPlanModal";

type Props = {
	selectedEntity: "Clients" | "Tasks" | "Orders" | "products" | "Advances" | "Expenses" | "Conveyances";
	setSelectedEntity: Dispatch<SetStateAction<"Clients" | "Tasks" | "Orders" | "products" | "Advances" | "Expenses" | "Conveyances">>;
	// eslint-disable-next-line no-unused-vars
	openSidebar: (fieldID: string) => void;
	allCustomFieldsEntityData: any;
	subsMetadata: any;
};

const CustomFieldSettingsTop: FC<Props> = ({
	selectedEntity,
	setSelectedEntity,
	openSidebar,
	allCustomFieldsEntityData,
	subsMetadata,
}) => {
	const [openDialog, setOpenDialog] = useState(false);
	// permissions
	const userWritePermission = useAdminEntitlements("settings:customFields", "write");

	const { roleID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);
	const customTaskFlag = useAdminCompanySettings("customEntityMigrationPhase");
	return (
		<div className="custom-field-top">
			<div className="section">
				<div className="section-part">
					<p className="title">Custom Fields</p>
					{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="settings" /> : null}
				</div>
				<p className="subtitle">
					{`Customize what fields you capture when creating a new clients${
						customTaskFlag !== 2 ? "/tasks" : ""
					}/orders/products/Advances through the
					mobile app / admin panel`}
				</p>
			</div>
			<div className="action-buttons">
				<Select
					onChange={(e) =>
						setSelectedEntity(e.target.value as "Clients" | "Tasks" | "Orders" | "products" | "Advances" | "Expenses" | "Conveyances")
					}
					value={selectedEntity}
					sx={DD_STYLES}>
					<MenuItem value={"Clients"}>Clients</MenuItem>
					{customTaskFlag !== 2 && <MenuItem value={"Tasks"}>Tasks</MenuItem>}
					<MenuItem value={"Orders"}>Orders</MenuItem>
					<MenuItem value={"products"}>Products</MenuItem>
					<MenuItem value={"Advances"}>Advances</MenuItem>
					<MenuItem value={"Expenses"}>Expenses</MenuItem>
					<MenuItem value={"Conveyances"}>Conveyances</MenuItem>

					

				</Select>

				<button
					disabled={!userWritePermission}
					onClick={
						allCustomFieldsEntityData?.get_all_entitymetadata_count_by_current_company >=
							subsMetadata?.noOfCustomFields && subsMetadata?.noOfCustomFields !== -1
							? () => setOpenDialog(true)
							: () => openSidebar("")
					}
					className="add mui-button">
					<AddIcon sx={{ marginRight: "7px" }} /> <div>Add</div>
				</button>
			</div>
			{openDialog && (
				<SubscriptionPlanModal
					title={"Maximum field limit reached"}
					message={`You have reached the maximum field limit for your subscription plan. Please upgrade to a Higher Edition, or remove the extra fields
.`}
					handleClose={() => setOpenDialog(false)}
				/>
			)}
		</div>
	);
};
export default CustomFieldSettingsTop;
