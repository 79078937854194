import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { FC, useState } from "react";
import ApproveModal from "../ApproveModal";

type Props = {
	approveHandler: () => void;
	rejectHandler: () => void;
};

const ApproveRejectColumn: FC<Props> = ({ approveHandler, rejectHandler }) => {
	const [open, setOpen] = useState(false);
	const [isReject, setIsReject] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<div className="action-buttons">
				<button
					onClick={() => {
						setOpen(true);
						setIsReject(false);
					}}>
					<DoneIcon htmlColor="#40A636" />
				</button>
				<button
					onClick={() => {
						setOpen(true);
						setIsReject(true);
					}}>
					<CloseIcon htmlColor="#F54747" />
				</button>
			</div>
			{open ? (
				<ApproveModal
					isReject={isReject}
					approveHandler={approveHandler}
					rejectHandler={rejectHandler}
					handleClose={handleClose}
					name="TYPE IN SOME NAME"
				/>
			) : null}
		</>
	);
};

export default ApproveRejectColumn;
