/* eslint-disable react-hooks/exhaustive-deps */
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { DataGridPro, GridSelectionModel, GridCloseIcon } from "@mui/x-data-grid-pro";
import _cloneDeep from "lodash/cloneDeep";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { ATTENDANCE_REQUESTS_DATA } from "../../../@types";
import { changeAttendanceRequestV4 } from "../../../api/attendance";
import { DG_STYLES } from "../../../constants";
import { APP_DISPATCH, ROOT_STATE, showNotification } from "../../../redux";
import CustomColumnMenu from "../../common/datagrid/CustomColumnMenu";
import ImageAndNameCell from "../../common/datagrid/ImageAndNameCell";
import useAdminEntitlements from "../../useAdminEntitlements";
import { attendanceStatusTypes } from "../../../constants/attendanceConstant";
import { formatDateTo_DDMMYYYY } from "../../../utils/validations";
import { Box, Modal } from "@mui/material";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import { useSelector } from "react-redux";
import moment from "moment";
import CustomDataGridExport from "../../common/datagrid/CustomDataGridExport";
import ApproveRejectOverrideRequest from "./ApproveRejectOverrideRequest";

type Props = {
	attendanceRequests: ATTENDANCE_REQUESTS_DATA[];
	loading: boolean;
	filteredAttendanceRequests: ATTENDANCE_REQUESTS_DATA[];
	setAttendanceRequests: Dispatch<SetStateAction<ATTENDANCE_REQUESTS_DATA[]>>;
	setSelectedRows: Dispatch<SetStateAction<GridSelectionModel>>;
	attendanceSettings:any;
	refetch: () => void;
};

const AttendanceOverrideTable: FC<Props> = ({
	attendanceRequests,
	setSelectedRows,
	loading,
	filteredAttendanceRequests,
	attendanceSettings,
	refetch,
}) => {
	// const leavesV2Active = useAdminCompanySettings("isLeavesV2Enabled");
	//commented for now @Aditya need to check
	const leavesV2Active = true;
	const dispatch = useDispatch<APP_DISPATCH>();
	const [pageSize, setPageSize] = useState(20);
	const [columns, setColumns] = useState<GridColDef[]>([]);
	const [showHistoryModal, setShowHistoryModal] = useState(false);
	const [historyData, setHistoryData] = useState<any>([]);
	const { adminID } = useSelector((state: ROOT_STATE) => state.user.adminDetails);
	const [isRequestLoading, setIsRequestLoading] = useState(false);

	const [attendanceHistoryColumns] = useState<GridColDef[]>([
		{
			field: "createdTs",
			headerName: "Date",
			flex: 1,
			renderCell: (params: GridValueGetterParams) => moment(params.row.lastModifiedTs).format("DD MMM, YYYY"),
		},
		{
			field: "timestamp",
			headerName: "Time",
			flex: 1,
			renderCell: (params: GridValueGetterParams) => moment(params.row.lastModifiedTs).format("hh:mm A"),
		},
		{
			field: "adminName",
			headerName: "Approver",
			flex: 1,
			renderCell: (params: GridValueGetterParams) => params.row.adminName,
		},
		{
			field: "status",
			headerName: "Status",
			flex: 1,
			renderCell: (params: GridValueGetterParams) => {
				const state =
					params.row.actionPerformed === -1 ? "Pending" : params.row.actionPerformed === 1 ? "Approved" : "Rejected";
				return <span className={`role_badge ${state}`}>{state}</span>;
			},
		},
	]);
	const userWritePermission = useAdminEntitlements("attendance:overrideRequest", "write");

	const tempColumns = useMemo(() => {
		const temp = [
			{
				field: "name",
				headerName: "Employee Name",
				sortable: false,
				renderCell: (params: GridValueGetterParams) => (
					<ImageAndNameCell
						name={params.row.lastName ? params.row.firstName + " " + params.row.lastName : params.row.firstName}
						avatar={params.row.imgUrl}
					/>
				),
				valueGetter: (params: any) =>
					params.row.lastName ? params.row.firstName + " " + params.row.lastName : params.row.firstName,
				flex: 1,
				minWidth: 220,
			},
			{
				field: "date",
				headerName: "Date",
				valueFormatter: (params: GridValueGetterParams) => formatDateTo_DDMMYYYY(params.value),
				valueGetter: (params: any) => formatDateTo_DDMMYYYY(params.value),
				flex: 1,
				minWidth: 100,
			},
			{
				field: "profileName",
				headerName: "Team",
				flex: 1,
				minWidth: 180,
			},
			{
				field: "reason",
				headerName: "Reason",
				flex: 1,
				minWidth: 180,
			},
			{
				field: "rejectReason",
				headerName: "Reject Reason",
				flex: 1,
				minWidth: 180,
			},
			{
				field: "createdTs",
				headerName: "Submission Date",
				flex: 1,
				minWidth: 180,
				renderCell: (params: GridValueGetterParams) => moment(params.row.insertTime).format("DD MMM, YYYY"),
				valueGetter: (params: any) => moment(params.row.insertTime).format("DD MMM, YYYY"),
			},
			{
				field: "PendingWith",
				headerName: "Pending With",
				flex: 1,
				minWidth: 180,
				renderCell: (params: GridValueGetterParams) => {
					const pendingWith = params.row.authHistory?.history?.filter((history: any) => history.actionPerformed === -1);
					return pendingWith?.length > 0 ? pendingWith[0]?.adminName : "-";
				},
				valueGetter: (params: GridValueGetterParams) => {
					const pendingWith = params.row.authHistory?.history?.filter((history: any) => history.actionPerformed === -1);
					return pendingWith?.length > 0 ? pendingWith[0]?.adminName : "-";
				},
			},
			{
				field: "state",
				headerName: "Status",
				sortable: false,
				flex: 1,
				minWidth: 100,
				renderCell: (params: GridValueGetterParams) => {
					const state =
						params.row.state === "REJECTED" 
							? "Rejected"
							: params.row.state === "APPROVED" 
								? "Approved"
								: "Pending";
					return <span className={`role_badge ${state}`}>{state}</span>;
				},
			},
			{
				field: "action",
				headerName: "Action",
				sortable: false,
				disableExport: true,
				renderCell: (params: GridValueGetterParams) => {
					const currentAdminHistory = params?.row?.authHistory?.history?.filter(
						(history: any) => history.adminID === adminID
					);
					const isCurrentAdminBackupApprover =
						adminID === attendanceSettings?.approvalOptions?.backUpApprover1 ||
						adminID === attendanceSettings?.approvalOptions?.backUpApprover2;
					return (currentAdminHistory !== null &&
						currentAdminHistory?.length > 0 &&
						currentAdminHistory[0]?.actionPerformed === -1) ||
						(isCurrentAdminBackupApprover && params?.row?.state === "PENDING") ? (
						<ApproveRejectOverrideRequest
							approveHandler={() => {
								approveHandler(params.row.id)
							}}
							rejectHandler={(reason) => {
								rejectHandler(params.row.id,reason)
							}}
							name={params.row.firstName + " " + params.row.lastName}
							userWritePermission={userWritePermission}
							loading={loading || isRequestLoading}
							setIsRequestLoading={setIsRequestLoading}
						/>
					) : (
						"-"
					);
				},

				minWidth: 100,
			},
		];

		// if leavesV2Active is true then add session, from and to columns
		if (leavesV2Active) {
			temp.splice(2, 0, {
				field: "session",
				headerName: "Session",
				flex: 1,
				minWidth: 100,
			});
			//@ts-ignore
			temp.splice(3, 0, {
				field: "from",
				headerName: "From",
				flex: 1,
				minWidth: 100,
				renderCell: (params: GridValueGetterParams) => (
					<span className="role_badge">
						{params.row.from1 !== null
							? attendanceStatusTypes[params.row.from1]?.label
							: attendanceStatusTypes[params.row.from2]?.label}
					</span>
				),
				valueGetter: (params: GridValueGetterParams) =>
					params.row.from1 !== null
						? attendanceStatusTypes[params.row.from1]?.label
						: attendanceStatusTypes[params.row.from2]?.label,
			});
			//@ts-ignore
			temp.splice(4, 0, {
				field: "to",
				headerName: "To",
				flex: 1,
				minWidth: 100,
				renderCell: (params: GridValueGetterParams) => (
					<span className="role_badge">
						{params.row.to1 !== null
							? attendanceStatusTypes[params.row.to1]?.label
							: attendanceStatusTypes[params.row.to2]?.label}
					</span>
				),
				valueGetter: (params: GridValueGetterParams) =>
					params.row.to1 !== null
						? attendanceStatusTypes[params.row.to1]?.label
						: attendanceStatusTypes[params.row.to2]?.label,
			});
		}

		return temp;
	}, [attendanceRequests, userWritePermission, attendanceSettings, loading, isRequestLoading]);

	const rejectHandler = async (id: number, reason: string) => {
		if (isNaN(id)) return;
		const updatedRequests = _cloneDeep(attendanceRequests);
		const requestIndex = updatedRequests.findIndex((r) => r.id === id);
		if (requestIndex < 0) return;
		updatedRequests[requestIndex].state = 0;
		updatedRequests[requestIndex].rejectReason = reason || "";
		try {
			const { success, message } = await changeAttendanceRequestV4([updatedRequests[requestIndex]]);
			dispatch(showNotification({ message, severity: success ? "success" : "error" }));
			if (!success) return; // if not success then we will return
		} finally {
			setIsRequestLoading(false);
			refetch();
		}
	};
	const approveHandler = async (id: number) => {
		if (isNaN(id)) return;
		const updatedRequests = _cloneDeep(attendanceRequests);
		const requestIndex = updatedRequests.findIndex((r) => r.id === id);
		if (requestIndex < 0) return;
		updatedRequests[requestIndex].state = 1;
		try {
			const { success, message } = await changeAttendanceRequestV4([updatedRequests[requestIndex]]);
			dispatch(showNotification({ message, severity: success ? "success" : "error" }));
			if (!success) return; // if not success then we will return
		} finally {
			setIsRequestLoading(false);
			refetch();
		}
	};
	useEffect(() => {
		setColumns(tempColumns as GridColDef[]);
	}, [tempColumns]);
	return (
		<>
			{" "}
			<Modal
				open={showHistoryModal}
				onClose={() => setShowHistoryModal(false)}
				style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<>
					<Box
						sx={{
							backgroundColor: "#FFFFFF",
							borderRadius: "10px",
							width: { xs: "90%", sm: "50%", md: "50%", lg: "40%", xl: "50%" },
							height: { xs: "50%", sm: "50%", md: "50%", lg: "40%", xl: "50%" },

							fontFamily: "SFUIText-Medium",
						}}
						className="downloadModalBox">
						<div
							style={{
								display: "flex",
								width: "100%",
								height: "10%",
								justifyContent: "space-between",
								padding: "10px",
							}}>
							<p className="mt-2">
								<ViewTimelineIcon style={{ marginRight: "10px" }} />
								Override Request History
							</p>
							<div style={{ cursor: "pointer" }}>
								<GridCloseIcon onClick={() => setShowHistoryModal(false)} />
							</div>
						</div>
						<hr style={{ margin: "10px 0", padding: 0 }} />
						<div
							className="datagrid-table p-4"
							style={{
								height: "80%",
							}}>
							<DataGridPro
								sx={DG_STYLES}
								rows={historyData?.length > 0 ? [...historyData].sort((a, b) => a.timestamp - b.timestamp) : []}
								columns={attendanceHistoryColumns}
								getRowId={(row) => row?.adminID + row?.timestamp}
								rowHeight={60}
								disableSelectionOnClick
								disableColumnFilter
								hideFooter
							/>
						</div>
					</Box>
				</>
			</Modal>
			<div className="datagrid-table">
				<DataGridPro
					sx={DG_STYLES}
					rows={filteredAttendanceRequests}
					columns={columns}
					loading={loading}
					pageSize={pageSize}
					getRowId={(row) => row.id}
					onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
					rowsPerPageOptions={[5, 10, 20, 50]}
					rowHeight={70}
					checkboxSelection
					disableSelectionOnClick
					disableColumnFilter
					pagination
					onRowClick={(e) => {
						setHistoryData(e.row?.authHistory?.history);
						setShowHistoryModal(true);
					}}
					onSelectionModelChange={(selectionModel) => setSelectedRows(selectionModel)}
					components={{
						ColumnMenu: CustomColumnMenu,
						Toolbar: () => <CustomDataGridExport fileName="Override_request_data" />,
					}}
				/>
			</div>
		</>
	);
};

export default AttendanceOverrideTable;
