import { CONVEYANCE_TYPE, EXPENSE_REQUEST_TYPE } from "../../@types";
import axios from "../../utils/axios";
axios.defaults.withCredentials = true;

export const expenseApproveReject = async (
	expense: EXPENSE_REQUEST_TYPE[] | CONVEYANCE_TYPE[]
): Promise<{ success: boolean; message: string }> => {
	const state = expense[0].status === 0 ? "Rejected" : "Approved";
	const toSend = expense.map((expense) => {
		const obj: any = {
			expenseSummaryID: expense.expenseSummaryID,
			employeeID: expense.employeeID,
			date: expense.date,
			status: expense.status,
			type: expense.type,
			firstName: expense.firstName,
			lastName: expense.lastName,
			comment: expense.expenseAuth.find((e) => e.actionPerformed === -1)?.comment,
			reimbursementAmount: expense.expenseAuth.find((e) => e.actionPerformed === -1)?.reimbursementAmount,
		};
		if (expense.type === 0) {
			obj.expenseDetails = expense.expenseDetails.map((i) => ({
				amountClaimed: i.amountClaimed,
			}));
		}
		if (expense.type === 1) {
			obj.expenseDetails = expense.conveyanceDetails.map((i) => ({
				amountClaimed: i.amountClaimed,
			}));
		}
		return obj;
	});

	try {
		/**
		 * temporary fix - remove those that we cannot find a reimbursement amount for....
		 * ideally what should happen is that we should iterate backwards until we find
		 * an amount to approve
		 */
		let toSendWithoutNulls: any[] = [];
		toSend.forEach(function (o) {
			if (o.status === 1 && (o.reimbursementAmount === null || isNaN(o.reimbursementAmount))) {
				console.log("Stripping this expense since it has null reimburesement amount:", o);
			} else {
				toSendWithoutNulls.push(o);
			}
		});

		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/expenses/expenseApproveReject/v2`, {
			expenses: toSendWithoutNulls,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Successfully ${state} selected Request`,
		};
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Updating Selected Request";
		return { success: false, message: errorText };
	}
};

export const markExpensePaidOut = async (
	expenses: EXPENSE_REQUEST_TYPE[] | CONVEYANCE_TYPE[]
): Promise<{ success: boolean; message: string }> => {
	try {
		const res = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/expenses/markExpensesPaidOut`, {
			expenses,
		});
		return {
			success: res?.data?.success ?? true,
			message: res?.data?.message || `Successfully Paid Out selected Request`,
		};
	} catch (error: any) {
		console.error(error);
		const errorText =
			typeof error?.response?.data?.error === "string"
				? error?.response?.data?.error
				: error?.message || "Error Deleting Selected Request";
		return { success: false, message: errorText };
	}
};
