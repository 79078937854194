import { CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, Select } from "@mui/material";
import { cloneDeep, isEqual } from "lodash";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { EXPENSE_SETTING } from "../../../@types";
import { upsertExpenseApproval } from "../../../api/settings";
import { APP_DISPATCH, showNotification } from "../../../redux";
import { sortByString } from "../../../utils";
import useAdminEntitlements from "../../useAdminEntitlements";
import useFetch from "../../useFetch";
import ReadOnlyButton from "../../common/ReadOnlyButton";

type Props = {
	expense: EXPENSE_SETTING | null;
	setExpense: Dispatch<SetStateAction<EXPENSE_SETTING | null>>;
	roleID: string;
	subsMetadata: any;
};
function ExpenseApprovalMethods({ expense, setExpense, roleID, subsMetadata }: Props) {
	const originalApprovalSetting = useRef({
		approverOne: expense?.expenseOptions.approverOne,
		approverTwo: expense?.expenseOptions.approverTwo,
		approverThree: expense?.expenseOptions.approverThree,
		usesAdminHierarchy: expense?.expenseOptions.usesAdminHierarchy ? expense?.expenseOptions.usesAdminHierarchy : 1,
		restrictedAdminCount: expense?.expenseOptions.restrictedAdminCount,
	});
	const dispatch = useDispatch<APP_DISPATCH>();
	const { data: dataAdmins, loading: loadingAdmins } = useFetch<any>("/admin/pullAdmins");
	const allAdmins = dataAdmins?.data?.dataSource ?? [];
	const userWritePermission = useAdminEntitlements("settings:expense", "write");

	const [loading, setLoading] = useState(false);
	const isLoading = loading || loadingAdmins;
	const adminsForApprover1 =
		allAdmins
			.filter(
				(admin: any) =>
					admin.id !== expense?.expenseOptions?.approverTwo && admin.id !== expense?.expenseOptions?.approverThree
			)
			.sort((a: any, b: any) => sortByString(a.firstname, b.firstname)) ?? [];

	const adminsForApprover2 =
		allAdmins
			.filter(
				(admin: any) =>
					admin.id !== expense?.expenseOptions?.approverOne && admin.id !== expense?.expenseOptions?.approverThree
			)
			.sort((a: any, b: any) => sortByString(a.firstname, b.firstname)) ?? [];
	const adminsForApprover3 =
		allAdmins
			.filter(
				(admin: any) =>
					admin.id !== expense?.expenseOptions?.approverOne && admin.id !== expense?.expenseOptions?.approverTwo
			)
			.sort((a: any, b: any) => sortByString(a.firstname, b.firstname)) ?? [];
	adminsForApprover1.unshift({ id: null, firstname: "None", lastname: "" });
	adminsForApprover2.unshift({ id: null, firstname: "None", lastname: "" });
	adminsForApprover3.unshift({ id: null, firstname: "None", lastname: "" });

	const areSettingsChanged = !isEqual(originalApprovalSetting.current, {
		approverOne: expense?.expenseOptions.approverOne,
		approverTwo: expense?.expenseOptions.approverTwo,
		approverThree: expense?.expenseOptions.approverThree,
		usesAdminHierarchy: expense?.expenseOptions.usesAdminHierarchy,
		restrictedAdminCount: expense?.expenseOptions.restrictedAdminCount,
	});

	const updateAdminHierarchy = async (key: keyof EXPENSE_SETTING["expenseOptions"], value: number | string | null) => {
		if (!key || key.length === 0 || expense === null || value === undefined) return;
		const newExpense = { ...expense };
		const tempExpenseOptions = { ...newExpense.expenseOptions, [key]: value };
		if (tempExpenseOptions.usesAdminHierarchy === 2 && tempExpenseOptions.restrictedAdminCount === null) {
			tempExpenseOptions.restrictedAdminCount = 1;
		}
		if (key === "approverOne" && value === null) {
			tempExpenseOptions.approverTwo = null;
			tempExpenseOptions.approverThree = null;
		}
		if (key === "approverTwo" && value === null) {
			tempExpenseOptions.approverThree = null;
		}

		newExpense.expenseOptions = cloneDeep(tempExpenseOptions);
		setExpense(newExpense);
	};

	const saveAdminSettings = async () => {
		setLoading(true);
		if (expense === null) return;
		if (
			(expense?.expenseOptions?.usesAdminHierarchy === 2 || expense?.expenseOptions?.usesAdminHierarchy === 0) &&
			!expense?.expenseOptions?.approverOne &&
			!expense?.expenseOptions?.approverTwo &&
			!expense?.expenseOptions?.approverThree
		) {
			setLoading(false);
			return dispatch(showNotification({ severity: "error", message: "Please select at least one fixed approver." }));
		}
		const { success, message } = await upsertExpenseApproval(
			expense.expenseOptions.usesAdminHierarchy,
			expense.expenseOptions.approverOne,
			expense.expenseOptions.approverTwo,
			expense.expenseOptions.approverThree,
			expense.expenseOptions.backUpApprover1,
			expense.expenseOptions.backUpApprover2,
			expense.expenseOptions.restrictedAdminCount,
			expense.expenseOptions.expenseQuestionOne,
			expense.expenseOptions.expenseQuestionTwo
		);
		dispatch(showNotification({ message: message, severity: success ? "success" : "error" }));
		setLoading(false);
		if (!success) return;
		originalApprovalSetting.current = {
			approverOne: expense?.expenseOptions.approverOne,
			approverTwo: expense?.expenseOptions.approverTwo,
			approverThree: expense?.expenseOptions.approverThree,
			usesAdminHierarchy: expense?.expenseOptions.usesAdminHierarchy,
			restrictedAdminCount: expense?.expenseOptions.restrictedAdminCount,
		};
	};
	return (
		<div className="custom-field-settings">
			<div className="custom-field-top expense_approval">
				<div className="section">
					<div className="section-part">
						<p className="title">Expense approval methods</p>
						{!userWritePermission ? <ReadOnlyButton roleID={roleID} moduleName="settings" /> : null}
					</div>
					{expense?.expenseOptions?.usesAdminHierarchy === 1 && (
						<p className="subtitle">
							Reporting Hierarchy approves expenses starting with the first reporting manager till the highest manager.
						</p>
					)}
					{expense?.expenseOptions?.usesAdminHierarchy === 0 && (
						<p className="subtitle">A list of Admins to approve.</p>
					)}
					{expense?.expenseOptions?.usesAdminHierarchy === 2 && (
						<p className="subtitle">
							A combination of reporting manager and fixed approver. First, reporting manager approves up to a certain
							level in the hierarchy. Then a list of Admins approve.
						</p>
					)}
				</div>
				{isLoading && <CircularProgress style={{ width: "15px", height: "15px" }} />}
				{areSettingsChanged && (
					<button onClick={saveAdminSettings} disabled={isLoading || !userWritePermission}>
						Save
					</button>
				)}
			</div>
			<div className="approval_methods" style={{ marginTop: 10 }}>
				<FormControlLabel
					value="bottom"
					control={
						<Radio
							size="small"
							checked={expense?.expenseOptions?.usesAdminHierarchy === 1}
							onClick={() => updateAdminHierarchy("usesAdminHierarchy", 1)}
							disabled={!userWritePermission}
						/>
					}
					label="Reporting Hierarchy"
				/>
				{expense?.expenseOptions?.usesAdminHierarchy &&
				!isNaN(expense?.expenseOptions?.usesAdminHierarchy) &&
				expense?.expenseOptions?.usesAdminHierarchy === 1 ? (
					<FormControl>
						<InputLabel id="restrictedAdminCount">Number of Admins To Restrict</InputLabel>
						{subsMetadata?.expensesBasicReportingManagerApproval === -1 ? (
							<Select
								labelId="restrictedAdminCount"
								disabled={!userWritePermission}
								label="Number of Admins To Restrict"
								value={expense?.expenseOptions?.restrictedAdminCount ?? -1}
								onChange={(e) =>
									updateAdminHierarchy("restrictedAdminCount", +e.target.value === -1 ? null : +e.target.value)
								}
								sx={{
									cursor: !userWritePermission ? "not-allowed !important" : "pointer",
									pointerEvents: "auto !important",
								}}>
								<MenuItem value={-1}>
									None (The request will be escalated to the highest-level administrator for approval)
								</MenuItem>
								<MenuItem value={1}>1</MenuItem>
								<MenuItem value={2}>2</MenuItem>
								<MenuItem value={3}>3</MenuItem>
								<MenuItem value={4}>4</MenuItem>
								<MenuItem value={5}>5</MenuItem>
								<MenuItem value={6}>6</MenuItem>
							</Select>
						) : (
							<Select
								labelId="restrictedAdminCount"
								disabled={!userWritePermission}
								label="Number of Admins To Restrict"
								value={expense?.expenseOptions?.restrictedAdminCount ?? 1}
								onChange={(e) =>
									updateAdminHierarchy("restrictedAdminCount", +e.target.value === -1 ? null : +e.target.value)
								}
								sx={{
									cursor: !userWritePermission ? "not-allowed !important" : "pointer",
									pointerEvents: "auto !important",
								}}>
								<MenuItem value={1}>1</MenuItem>
							</Select>
						)}
					</FormControl>
				) : null}
			</div>
			{subsMetadata?.expensesBasicReportingManagerApproval === -1 && (
				<div className="approval_methods">
					<FormControlLabel
						value="bottom"
						control={
							<Radio
								size="small"
								checked={expense?.expenseOptions?.usesAdminHierarchy === 0}
								onClick={() => updateAdminHierarchy("usesAdminHierarchy", 0)}
								disabled={!userWritePermission}
							/>
						}
						label="Fixed Approvers"
					/>
					{expense?.expenseOptions?.usesAdminHierarchy === 0 ? (
						<div className="authorization_approvers">
							<FormControl
								error={
									!expense?.expenseOptions?.approverOne &&
									!expense?.expenseOptions?.approverTwo &&
									!expense?.expenseOptions?.approverThree
								}>
								<InputLabel id="approverOne">Level 1 approver</InputLabel>
								<Select
									labelId="approverOne"
									required
									label="Level 1 approver"
									onChange={(e: any) => updateAdminHierarchy("approverOne", e.target.value)}
									value={expense?.expenseOptions?.approverOne ?? ""}
									disabled={isLoading || !userWritePermission}>
									{adminsForApprover1.map((admin: any) => (
										<MenuItem key={admin.id} value={admin.id}>
											{admin.firstname + " " + admin?.lastname}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControl>
								<InputLabel id="approverTwo">Level 2 approver</InputLabel>
								<Select
									labelId="approverTwo"
									label="Level 2 approver"
									onChange={(e: any) => updateAdminHierarchy("approverTwo", e.target.value)}
									value={expense?.expenseOptions?.approverTwo ?? ""}
									disabled={expense?.expenseOptions?.approverOne === null || isLoading || !userWritePermission}>
									{adminsForApprover2.map((admin: any) => (
										<MenuItem key={admin.id} value={admin.id}>
											{admin.firstname + " " + admin?.lastname}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormControl>
								<InputLabel id="approverThree">Level 3 approver</InputLabel>
								<Select
									labelId="approverThree"
									label="Level 3 approver"
									onChange={(e: any) => updateAdminHierarchy("approverThree", e.target.value)}
									value={expense?.expenseOptions?.approverThree ?? ""}
									disabled={
										expense?.expenseOptions?.approverOne === null ||
										expense?.expenseOptions?.approverTwo === null ||
										isLoading ||
										!userWritePermission
									}>
									{adminsForApprover3.map((admin: any) => (
										<MenuItem key={admin.id} value={admin.id}>
											{admin.firstname + " " + admin?.lastname}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
					) : null}
				</div>
			)}
			{subsMetadata?.expensesBasicReportingManagerApproval === -1 && (
				<div className="approval_methods" style={{ alignItems: "start" }}>
					<FormControlLabel
						value="bottom"
						control={
							<Radio
								size="small"
								checked={expense?.expenseOptions?.usesAdminHierarchy === 2}
								onClick={() => updateAdminHierarchy("usesAdminHierarchy", 2)}
								disabled={!userWritePermission}
							/>
						}
						label="Hybrid Approval Model"
					/>
					{expense?.expenseOptions?.usesAdminHierarchy &&
					!isNaN(expense?.expenseOptions?.usesAdminHierarchy) &&
					expense?.expenseOptions?.usesAdminHierarchy === 2 ? (
						<div style={{ display: "grid", gap: "15px" }}>
							<FormControl>
								<InputLabel id="restrictedAdminCount">Number of Reporting Managers to Approve</InputLabel>
								<Select
									labelId="restrictedAdminCount"
									disabled={!userWritePermission}
									required
									label="Number of Reporting Managers to Approve"
									value={expense?.expenseOptions?.restrictedAdminCount ?? 1}
									onChange={(e) =>
										updateAdminHierarchy("restrictedAdminCount", +e.target.value === -1 ? 1 : +e.target.value)
									}>
									<MenuItem disabled value={-1}>
										None
									</MenuItem>
									<MenuItem value={1}>1</MenuItem>
									<MenuItem value={2}>2</MenuItem>
									<MenuItem value={3}>3</MenuItem>
									<MenuItem value={4}>4</MenuItem>
									<MenuItem value={5}>5</MenuItem>
									<MenuItem value={6}>6</MenuItem>
								</Select>
							</FormControl>{" "}
							<div className="authorization_approvers">
								<FormControl
									error={
										!expense?.expenseOptions?.approverOne &&
										!expense?.expenseOptions?.approverTwo &&
										!expense?.expenseOptions?.approverThree
									}>
									<InputLabel id="approverOne">
										Level{" "}
										{expense.expenseOptions.restrictedAdminCount ? expense.expenseOptions.restrictedAdminCount + 1 : 1}{" "}
										approver
									</InputLabel>
									<Select
										labelId="approverOne"
										required
										label="Level 1 approver"
										onChange={(e: any) => updateAdminHierarchy("approverOne", e.target.value)}
										value={expense?.expenseOptions?.approverOne ?? ""}
										disabled={isLoading || !userWritePermission}>
										{adminsForApprover1.map((admin: any) => (
											<MenuItem key={admin.id} value={admin.id}>
												{admin.firstname + " " + admin?.lastname}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<FormControl>
									<InputLabel id="approverTwo">
										Level{" "}
										{expense.expenseOptions.restrictedAdminCount ? expense.expenseOptions.restrictedAdminCount + 2 : 2}{" "}
										approver
									</InputLabel>
									<Select
										labelId="approverTwo"
										label="Level 2 approver"
										onChange={(e: any) => updateAdminHierarchy("approverTwo", e.target.value)}
										value={expense?.expenseOptions?.approverTwo ?? ""}
										disabled={expense?.expenseOptions?.approverOne === null || isLoading || !userWritePermission}>
										{adminsForApprover2.map((admin: any) => (
											<MenuItem key={admin.id} value={admin.id}>
												{admin.firstname + " " + admin?.lastname}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<FormControl>
									<InputLabel id="approverThree">
										Level{" "}
										{expense.expenseOptions.restrictedAdminCount ? expense.expenseOptions.restrictedAdminCount + 3 : 3}{" "}
										approver
									</InputLabel>
									<Select
										labelId="approverThree"
										label="Level 3 approver"
										onChange={(e: any) => updateAdminHierarchy("approverThree", e.target.value)}
										value={expense?.expenseOptions?.approverThree ?? ""}
										disabled={
											expense?.expenseOptions?.approverOne === null ||
											expense?.expenseOptions?.approverTwo === null ||
											isLoading ||
											!userWritePermission
										}>
										{adminsForApprover3.map((admin: any) => (
											<MenuItem key={admin.id} value={admin.id}>
												{admin.firstname + " " + admin?.lastname}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
						</div>
					) : null}
				</div>
			)}
		</div>
	);
}

export default ExpenseApprovalMethods;
