// react
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
// redux
import { APP_DISPATCH, showNotification } from "../../redux";
import { useDispatch } from "react-redux";
// components
import { read, utils } from "xlsx";
// query
import { useQuery } from "@apollo/client";
import { GET_ALL_ENTITYMETADATA_BY_ENTITY_FOR_CUSTOM_TASK } from "../../schema/entityMetadata";

/**
 * this react hooks provide the util of excel upload functionalities
 *
 */

type Props = {
	setShowValidationModal: Dispatch<SetStateAction<boolean>>;
	setHeaders: Dispatch<SetStateAction<any[]>>;
	setRowsData: Dispatch<SetStateAction<any[]>>;
	excelDataOffset: number;
	excelUploadLimit: number;
	entity: string;
	selectedTaskType?: string;
};

const useExcelUploadUtil = ({
	setShowValidationModal,
	setHeaders,
	setRowsData,
	excelDataOffset,
	excelUploadLimit,
	entity,
	selectedTaskType = "Default",
}: Props) => {
	const dispatch = useDispatch<APP_DISPATCH>();
	const [isLocationFieldAvailable, setIsLocationFieldAvailable] = useState(false);
	const {
		data: customTaskEntityMetaData,
		loading: loadingCustomTaskEntity,
		error: customTaskFieldMetadataError,
	} = useQuery(GET_ALL_ENTITYMETADATA_BY_ENTITY_FOR_CUSTOM_TASK, {
		variables: { customEntityID: selectedTaskType },
		skip: selectedTaskType === "Default" || !selectedTaskType,
	});

	useEffect(() => {
		if (customTaskEntityMetaData) {
			const locationField = customTaskEntityMetaData?.get_all_entitymetadata_by_custom_entity?.find((d: any) =>
				["metaLocation", "client", "site"]?.includes(d?.dataType)
			);
			if (locationField) {
				setIsLocationFieldAvailable(true);
			} else {
				setIsLocationFieldAvailable(false);
			}
		}
	}, [loadingCustomTaskEntity, customTaskFieldMetadataError, customTaskEntityMetaData]);

	const uploadExcel = async (event: ChangeEvent<HTMLInputElement>) => {
		setShowValidationModal(true);

		const file = event.target.files ? event.target.files[0] : null;
		if (!file) return;

		readExcel(file, (err: string, resp: any) => {
			if (err) {
				dispatch(
					showNotification({
						message: err,
						severity: "error",
					})
				);
			} else {
				let rows = resp.rows;

				/**
				 * we will put the validation on excel header and number of rows in response so that we can
				 * detect if someone has changed the excel
				 */

				/**
				 * case for handling edge case when no row provided in excel
				 */
				try {
					/**
					 * as product excel do not have NOTES on the top
					 */
					if (rows[0][0] == "#### NOTES:" || window.location.href.includes("products") || entity === "Options") {
					} else {
						rows = rows.slice(2);
					}

					let rowsLength = 0;
					rows.map((d: any) => {
						if (d.length > 0) {
							rowsLength++;
						}
					});
					/**
					 * here need to pass the upload limit
					 */
					if (rowsLength - excelDataOffset > excelUploadLimit) {
						dispatch(
							showNotification({
								message: `Please upload ${entity} less than or equal to ${excelUploadLimit}!`,
								severity: "warning",
							})
						);
						setShowValidationModal(false);
						return;
					}

					/**
					 * here need to pass the slice offset
					 */
					const removeOffset =
						window.location.href.includes("products") || entity === "Options"
							? excelDataOffset
							: window.location.href.includes("field_executive")
							? excelDataOffset - 1
							: excelDataOffset - 2;

					if (selectedTaskType !== "Default") {
						if (isLocationFieldAvailable) {
							rows = rows.slice(removeOffset);
						} else {
							rows = rows.slice(removeOffset - 1);
						}
					} else {
						rows = rows.slice(removeOffset);
					}

					let response = [];
					const headersLength = rows[0].length;
					for (let i = 0; i < rows.length; i++) {
						const row = [];
						for (let j = 0; j < rows[i].length; j++) {
							if (rows[i][j] == undefined) {
								row.push(null);
							} else if (typeof rows[i][j] === "string" && rows[i][j].trim().length === 0) {
								row.push(null);
							} else {
								row.push(rows[i][j]);
							}
						}

						const rowLengthNow = row.length;
						if (rowLengthNow < headersLength) {
							for (let k = 0; k < headersLength - rowLengthNow; k++) {
								row.push(null);
							}
						}

						if (rowLengthNow > 0) {
							response.push(row);
						}
					}

					let headers = response[0];
					headers = headers
						.filter((h) => h !== "Created At" && h !== "Last Modified At")
						.map((d) => {
							if (d) {
								let tt = d.trimEnd();
								if (tt.slice(-1) === ")" && tt.slice(-2, -1) === "*" && tt.slice(-3, -2) === "(") {
									tt = d.slice(0, d.length - 3);
								}
								if (tt && typeof tt == "string") {
									return tt.trimEnd();
								} else {
									return d;
								}
							} else {
								return d;
							}
						});
					// splice the response with header name "Created At" and "Last Modified At" and its values.
					const idx1 = response[0].indexOf("Created At");
					if (idx1 > 1) {
						response.map((r) => r.splice(idx1, 2));
					}
					//splice the response to remove the header name row
					response = response.slice(1);

					if (response.length < 1) {
						dispatch(
							showNotification({
								message: "No Row Present in excel!",
								severity: "warning",
							})
						);
						setShowValidationModal(false);
						return;
					} else {
						setHeaders(headers);
						setRowsData(response);
					}
				} catch (e) {
					dispatch(
						showNotification({
							message:
								"Excel Format is not valid, please download the template, follow the instruction given in the template and then upload again!",
							severity: "warning",
						})
					);
					setShowValidationModal(false);
					return;
				}
			}
		});
	};

	/**
	 * we are using xlsx library here instead of read-excel-file,
	 * because we were facing the issue in date and time parsing in excel
	 * here we are instructing the xlsx to read the xlsx without parsing the date/time itself
	 */

	function readExcel(file: any, callback: any) {
		return new Promise(function (resolve) {
			var reader = new FileReader();
			var rABS = !!reader.readAsBinaryString;
			reader.onload = function (e) {
				/* Parse data */
				var bstr = e?.target?.result;
				var wb = read(bstr, { type: rABS ? "binary" : "array" });

				/* Get first worksheet */
				var wsname = wb.SheetNames[0];
				var ws = wb.Sheets[wsname];

				/* Convert array of arrays */
				var json = utils.sheet_to_json(ws, { header: 1, blankrows: false });
				var cols = makeCols(ws["!ref"]);
				var data = { rows: json, cols: cols };

				resolve(data);
				return callback(null, data);
			};
			if (file && rABS) reader.readAsBinaryString(file);
			else reader.readAsArrayBuffer(file);
		});
	}

	function makeCols(ref: any) {
		var o = [],
			C = utils.decode_range(ref).e.c + 1;
		for (var i = 0; i < C; ++i) {
			o[i] = { name: utils.encode_col(i), key: i };
		}
		return o;
	}

	return {
		uploadExcel,
	};
};

export default useExcelUploadUtil;
