import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_ALL_EMPLOYEES = gql`
	query {
		get_users_by_admin_id {
			employeeID
			firstName
			lastName
			parentAdminID
			internalEmpID
		}
	}
`;

export const GET_ALL_EMPLOYEES_V2 = gql`
	query GetTotalUsersUnderAdmin($adminID: Int) {
		get_total_reportee_users_by_admin_id (adminID: $adminID) {
			employeeID
			firstName
			lastName
	  }
	}
`;
export const do_entities_have_dependencies = gql`
	query do_entities_have_dependencies($input: EntityDependencyInput!) {
		do_entities_have_dependencies(input: $input) {
			entityID
			entityName
			dependencies {
				dependentID
				dependentEntityName
				dependentType
				parentModule
			}
		}
	}
`;
