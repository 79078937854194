import { FC, useEffect, useRef } from "react";

type Props = {
	// eslint-disable-next-line no-unused-vars
	onPlacesChanged: (place: google.maps.places.PlaceResult) => void;
	onChange: any;
	maps: any;
	value: string | null;
	map: any;
	isDisabled?: boolean;
};

const SearchBox: FC<Props> = ({ onPlacesChanged, onChange, maps, value, map, isDisabled }) => {
	const searchBox = useRef<any>(null);
	const inputRef = useRef<any>(null);
	const handleKeyPress = (e: any) => {
		if (e.key === "Enter") {
			e.preventDefault();
		}
	};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleOnPlacesChanged = () => {
		const places = searchBox.current.getPlaces();
		if (places.length === 0) return;
		const place = places[0];
		if (!place.geometry) return;
		if (place.geometry.viewport) {
			map.fitBounds(place.geometry.viewport);
		} else {
			map.setCenter(place.geometry.location);
			map.setZoom(17);
		}
		onPlacesChanged(place);
	};

	const attachSearchBox = async () => {
		const input = inputRef.current;
		searchBox.current = await new maps.places.SearchBox(input);
		searchBox.current.addListener("places_changed", handleOnPlacesChanged);
	};

	useEffect(() => {
		if (!searchBox.current && maps) {
			attachSearchBox();
		}

		return () => {
			if (maps) {
				searchBox.current = null;
				maps.event.clearInstanceListeners(searchBox);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<input
			ref={inputRef}
			className="map-search"
			value={value ?? ""}
			onChange={onChange}
			disabled={isDisabled}
			onKeyDownCapture={handleKeyPress}
		/>
	);
};

export default SearchBox;
