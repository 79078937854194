/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { FC, useState } from "react";
import useAdminEntitlements from "../../useAdminEntitlements";

type Props = {
	geofences: any;
	setSelectedGeofenceID: any;
	editPolygon: any;
	regionMarkers: any;
	isGeofenceTab: boolean;
	setIsGeofenceTab: any;
};
const GeofenceList: FC<Props> = ({
	geofences,
	setSelectedGeofenceID,
	editPolygon,
	regionMarkers,
	isGeofenceTab,
	setIsGeofenceTab,
}) => {
	const [searchTerm, setSearchTerm] = useState("");
	const userWritePermission = useAdminEntitlements("clientsAndSites:geofences", "write");

	const debouncedSearch = (e: any) => setSearchTerm(e.target.value);

	const filteredGeofences = geofences.filter((geofence: any) =>
		geofence.name?.replace(/ /g, "").toLocaleLowerCase()?.includes(searchTerm.replace(/ /g, "").toLocaleLowerCase())
	);
	return (
		<div className="user_add geofence_sidebar">
			<div className="button-group">
				<button
					onClick={() => setIsGeofenceTab(true)}
					className={`group ${isGeofenceTab ? "selected" : ""}`}
					style={{ height: "36px", flex: 1 }}>
					<FormatListBulletedOutlinedIcon />
					Geofence
				</button>
				<button
					onClick={() => setIsGeofenceTab(false)}
					className={`group ${!isGeofenceTab ? "selected" : ""}`}
					style={{ height: "36px", flex: 1 }}>
					<MapOutlinedIcon />
					Geofence Pools
				</button>
			</div>
			<input type="search" onChange={debouncedSearch} placeholder="Search Geofence..." className="search" />
			<ul style={{ height: "85%" }}>
				{filteredGeofences.map((geofence: any) => (
					<li
						key={geofence.geofenceID}
						onClick={() => {
							if (!userWritePermission) return;
							setSelectedGeofenceID(geofence.geofenceID);
						}}>
						<div>
							<h3>{geofence.name}</h3>
						</div>
						<button disabled={!userWritePermission} style={{ padding: "5px", borderRadius: "4px" }}>
							<EditOutlinedIcon
								onClick={(e) => {
									if (!userWritePermission) return;
									e.stopPropagation();
									setSelectedGeofenceID(geofence.geofenceID);
									editPolygon(geofence.geofenceID, regionMarkers);
								}}
							/>
						</button>
					</li>
				))}
			</ul>
		</div>
	);
};

export default GeofenceList;
