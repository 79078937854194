import TaskIcon from "@mui/icons-material/Task";
import { useEffect, useMemo, useState } from "react";
import { DASHBOARD } from "../../../@types";
import { WORKING_STATE } from "../../../utils";
import RoomIcon from "@mui/icons-material/Room";

type Props = {
	lat: number | null | string;
	lng: number | null | string;
	key: number;
	name: string;
	type: "user" | "client" | "active_task";
	user?: DASHBOARD;
	// eslint-disable-next-line no-unused-vars
	handleClick?: (lat: any, lng: any, accuracy: any) => void;
	accuracy?: any;
	map: any;
	maps: any;
};

const Marker = (props: Props) => {
	let { lat, lng, type, name, user, handleClick, accuracy, maps, map } = props;

	const [showAccuracy, setShowAccuracy] = useState(false);

	const activeTask = user?.tasks?.filter((task) => task.checkinTime && !task.checkoutTime) ?? [];

	if (activeTask?.length > 0) {
		type = "active_task";
	}

	const status = useMemo(
		() => WORKING_STATE[typeof user?.workingStatusV2 === "string" ? user?.workingStatusV2 : "Punched Out"],
		[user?.workingStatusV2]
	);

	useEffect(() => {
		if (accuracy && maps) {
			const circle = new maps.Circle({
				strokeColor: " #4A89F3",
				strokeOpacity: 0.6,
				strokeWeight: 2,
				fillColor: " #4A89F3",
				fillOpacity: 0.15,
				center: new maps.LatLng(lat, lng),
				radius: accuracy,
				editable: false,
				draggable: false,
			});
			if (showAccuracy) {
				circle.setMap(map);
			}

			return () => {
				circle.setMap(null); // Clean up the circle when the component unmounts
			};
		}
	}, [accuracy, lat, lng, maps, showAccuracy]);

	return (
		<div>
			<div
				className={`${type === "client" ? "" : "marker"}`}
				style={{ cursor: "pointer" }}
				onClick={() => {
					if (handleClick) handleClick(lat, lng, accuracy);
					setShowAccuracy(!showAccuracy);
				}}
				aria-hidden={true}>
				<div className={`wrapper ${status} ${type}`}>
					{type === "client" ? (
						<img src="/images/clientMarker.png" alt="" width={"15px"} height={"15px"} title={name} />
					) : type === "active_task" ? (
						<TaskIcon />
					) : null}
				</div>
				{type !== "client" && <span className="marker_name">{name}</span>}
			</div>
			{showAccuracy && accuracy > 0 && (
				<div
					style={{
						padding: "0.3rem",
						fontSize: "12px",
						borderRadius: "10px",
						width: "100px",
						position: "absolute",
						top: "-55px",
						left: "-10px",
						transform: "translate(-50%, 50%)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
					{accuracy < 100 ? (
						<RoomIcon style={{ fontSize: "14px", color: "#66bb6a" }} />
					) : accuracy < 500 ? (
						<RoomIcon style={{ fontSize: "14px", color: "#fdd835" }} />
					) : (
						<RoomIcon style={{ fontSize: "14px", color: "#e53935" }} />
					)}
					Accuracy {accuracy?.toFixed()}m
				</div>
			)}
		</div>
	);
};

export default Marker;
