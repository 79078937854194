import { initializeApp } from "firebase/app";
import type { FirebaseApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { collection, CollectionReference, DocumentData, getFirestore } from "firebase/firestore";
const firebaseConfig: FirebaseApp = initializeApp({
	apiKey: process.env.NEXT_PUBLIC_API_KEY,
	authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
	databaseURL: process.env.NEXT_PUBLIC_DATABASE_URL,
	projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
	storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
	messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
});

const firebaseAuth: Auth = getAuth(firebaseConfig);

const firestore = getFirestore(firebaseConfig);
export const db: CollectionReference<DocumentData> = collection(firestore, "chats");
export const siteAttendanceDB: CollectionReference<DocumentData> = collection(firestore, "presence");

// const db = initializeFirestore(firebaseConfig, {
// 	experimentalForceLongPolling: true,
//   });

export default firebaseAuth;
