/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Dispatch, FC, memo, SetStateAction, useEffect, useState } from "react";
import { PERMISSION_CATEGORY, SPECIFIC_PERMISSION } from "../../../@types";
import PermissionFunctionalityTable from "./PermissionFunctionalityTable";
type Props = {
	permission: PERMISSION_CATEGORY;
	setPermissions: Dispatch<SetStateAction<PERMISSION_CATEGORY[]>>;
	moduleName:string;
	isParentEditingAllowed:boolean;
	subsMetadata:any;
};
const AccordionPermission: FC<Props> = ({ permission, setPermissions, moduleName,isParentEditingAllowed, subsMetadata }) => {
	const [expanded, setExpanded] = useState(false);
	useEffect(() => {
	  if(moduleName.toLowerCase() === permission.moduleAlias.toLowerCase()){
		setExpanded(true);
	  }
	}, [permission, moduleName])
	

	const onChange = (e: any) => {
		const { name, checked, value } = e.target;
		if ((value !== "read" && value !== "write") || typeof checked !== "boolean") return;
		const newFunctionality = permission.functionalities.map((item: SPECIFIC_PERMISSION) => {
			if (item.modFuncName === name) {
				// @ts-ignore
				item.permissions[value] = checked ? 1 : 0;
			}
			return item;
		});
		setPermissions((prevState: PERMISSION_CATEGORY[]) =>
			prevState.map((item: PERMISSION_CATEGORY) => {
				if (item.moduleAlias === permission.moduleAlias) {
					return { ...permission, functionalities: newFunctionality };
				}
				return item;
			})
		);
	};
	const checkAllOptions = (e: any) => {
		const { checked, value } = e.target;
		const newFunctionality = permission.functionalities.map((item: SPECIFIC_PERMISSION) => {
			// @ts-ignore
			item.permissions[value] = checked ? 1 : 0;
			return item;
		});
		setPermissions((prevState: PERMISSION_CATEGORY[]) =>
			prevState.map((item: PERMISSION_CATEGORY) => {
				if (item.moduleAlias === permission.moduleAlias) {
					return { ...permission, functionalities: newFunctionality };
				}
				return item;
			})
		);
	};
	return (
		<div className={`accordion ${expanded ? "expanded" : ""}`}>
			<div className="header" onClick={() => setExpanded((expanded) => !expanded)}>
				{expanded ? <RemoveCircleIcon /> : <AddCircleOutlinedIcon />}
				<h5>{permission.moduleName}</h5>
			</div>
			<div className="accordion_content">
				<PermissionFunctionalityTable
					name={permission.moduleName}
					permissions={permission.functionalities}
					onChange={onChange}
					checkAllOptions={checkAllOptions}
					isParentEditingAllowed={isParentEditingAllowed}
					subsMetadata={subsMetadata}
				/>
			</div>
		</div>
	);
};

export default memo(AccordionPermission);
