import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { FC } from "react";

type Props = {
	map: any;
	maps: any;
	outOfBoundsMarkers: any;
};

const RenderOutOfBoundsMarkers: FC<Props> = ({ map, maps, outOfBoundsMarkers }) => {
	const calcHighestPointOfPolygon = (path: any, maps: any) => {
		var bounds = new maps.LatLngBounds();

		for (var i = 0; i < path.length; i++) {
			bounds.extend(path[i]);
		}

		return bounds.getCenter();
	};

	const calculateArrowPosition = (markerPosition: any) => {
		// Calculate the position of the arrow based on the marker's position and the map bounds
		const mapBounds = map.getBounds();
		const sw = mapBounds.getSouthWest();
		const ne = mapBounds.getNorthEast();

		const projection = map.getProjection();
		let markerLatLng;
		if (markerPosition.polyline) {
			const decodedPath = maps.geometry.encoding.decodePath(markerPosition.polyline);
			const center = calcHighestPointOfPolygon(decodedPath, maps);
			markerLatLng = new google.maps.LatLng(center.lat(), center.lng());
		} else {
			if (markerPosition.lat && markerPosition.lng) {
				markerLatLng = new google.maps.LatLng(markerPosition.lat, markerPosition.lng);
			} else {
				markerLatLng = new google.maps.LatLng(markerPosition.latitude, markerPosition.longitude);
			}
		}
		const markerPixel = projection.fromLatLngToPoint(markerLatLng);

		const swLatLng = new google.maps.LatLng(sw.lat(), sw.lng());
		const swPixel = projection.fromLatLngToPoint(swLatLng);

		const neLatLng = new google.maps.LatLng(ne.lat(), ne.lng());
		const nePixel = projection.fromLatLngToPoint(neLatLng);

		const mapWidth = Math.abs(swPixel.x - nePixel.x);
		const mapHeight = Math.abs(nePixel.y - swPixel.y);

		const arrowPosition = {
			x: ((markerPixel.x - swPixel.x) / mapWidth) * 100,
			y: ((markerPixel.y - nePixel.y) / mapHeight) * 100,
		};

		if (arrowPosition.x < 0) {
			return "left";
		} else if (arrowPosition.x > 100) {
			return "right";
		} else if (arrowPosition.y < 0) {
			return "top";
		} else if (arrowPosition.y > 100) {
			return "bottom";
		}
	};

	const data = outOfBoundsMarkers.map((marker: any) => {
		// Calculate the position of the arrow based on the marker's position and the map bounds
		const arrowPosition = calculateArrowPosition(marker);
		// Apply custom CSS to position the arrow at the specified location
		return arrowPosition;
	});

	const top = data.filter((position: string) => position === "top").length;
	const bottom = data.filter((position: string) => position === "bottom").length;
	const left = data.filter((position: string) => position === "left").length;
	const right = data.filter((position: string) => position === "right").length;

	return (
		<>
			{left > 0 && (
				<button
					style={{
						position: "absolute",
						transform: "translate(-50%, -50%)",
						left: "25px",
						top: "50%",
						background: "rgba(0, 0, 0, 0.7)",
						color: "white",
						padding: "0.5rem",
						width: "30px",
						height: "30px",
						borderRadius: "50%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
					onClick={() => map.panBy(-100, 0)}>
					<WestIcon style={{ fontSize: "15px" }} />
				</button>
			)}

			{top > 0 && (
				<button
					style={{
						position: "absolute",
						transform: "translate(-50%, -50%)",
						left: "50%",
						top: "25px",
						background: "rgba(0, 0, 0, 0.7)",
						color: "white",
						padding: "0.5rem",
						width: "30px",
						height: "30px",
						borderRadius: "50%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
					onClick={() => map.panBy(0, -100)}>
					<NorthIcon style={{ fontSize: "15px" }} />
				</button>
			)}

			{right > 0 && (
				<button
					style={{
						position: "absolute",
						transform: "translate(-50%, 50%)",
						right: "0",
						top: "50%",
						background: "rgba(0, 0, 0, 0.7)",
						color: "white",
						padding: "0.5rem",
						width: "30px",
						height: "30px",
						borderRadius: "50%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
					onClick={() => map.panBy(100, 0)}>
					<EastIcon style={{ fontSize: "15px" }} />
				</button>
			)}

			{bottom > 0 && (
				<button
					style={{
						position: "absolute",
						transform: "translate(-50%, -50%)",
						left: "50%",
						bottom: "0",
						background: "rgba(0, 0, 0, 0.7)",
						color: "white",
						padding: "0.5rem",
						width: "30px",
						height: "30px",
						borderRadius: "50%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
					onClick={() => map.panBy(0, 100)}>
					<SouthIcon style={{ fontSize: "15px" }} />
				</button>
			)}
		</>
	);
};

export default RenderOutOfBoundsMarkers;
