import { createSlice } from "@reduxjs/toolkit";
import { DATA_GRID_NAMES } from "../../constants";

interface DataGridState {
	columnConfigurations: any;
}

const initialState: DataGridState = {
	columnConfigurations: Object.keys(DATA_GRID_NAMES).map((dataGridName) => ({
		id: DATA_GRID_NAMES[dataGridName],
		columnArray: [],
		sortModel: [],
		pinnedColumns: {},
	})),
};

const dataGridSlice = createSlice({
	name: "dataGrid",
	initialState,
	reducers: {
		setColumnConfigurations: (state, action) => {
			if (!action.payload) return;
			const { dataGridName, columnArray } = action.payload;
			const dataGridIndex = state.columnConfigurations.findIndex((dataGrid: any) => dataGrid.id === dataGridName);
			if (dataGridIndex < 0) return;
			state.columnConfigurations[dataGridIndex].columnArray = columnArray;
		},
		setColumnSortModel: (state, action) => {
			if (!action.payload) return;
			const { dataGridName, sortModel } = action.payload;
			const dataGridIndex = state.columnConfigurations.findIndex((dataGrid: any) => dataGrid.id === dataGridName);
			if (dataGridIndex < 0) return;
			state.columnConfigurations[dataGridIndex].sortModel = sortModel;
		},
		setPinnedColumns: (state, action) => {
			if (!action.payload) return;
			const { dataGridName, pinnedColumns } = action.payload;
			const dataGridIndex = state.columnConfigurations.findIndex((dataGrid: any) => dataGrid.id === dataGridName);
			if (dataGridIndex < 0) return;
			state.columnConfigurations[dataGridIndex].pinnedColumns = pinnedColumns;
		},
	},
});

export const { setColumnConfigurations, setColumnSortModel, setPinnedColumns } = dataGridSlice.actions;

export default dataGridSlice.reducer;
